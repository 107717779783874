<script>
  import { onMount } from "svelte";

  import {
    ConfirmationNewClient,
    ConfirmationNewDate,
    ConfirmationNewOptions,
    ConfirmationNewType,
  } from "components/confirmationNew";
  import { Modal } from "components/modals";
  import * as confirmationNewStore from "stores/confirmation/confirmation_new.js";
  import { confirmationType } from "stores/dashboard/create_confirmation.js";
  import { t } from "stores/i18n.js";
  import { toasts } from "stores/toasts.js";

  import { Button } from "components";
  import styles from "styleguide/DashboardConfirmationNewModal.json";

  export let shown = false;
  export let projectId;

  const { create, reset, step, touchedSteps, types } = confirmationNewStore;

  function setTouchedSteps(newSteps, currStep) {
    step.set(currStep);
    touchedSteps.set(newSteps);
  }

  function changeStep(offset) {
    if (offset > 0) {
      $touchedSteps[$step].dirty = true;
    } else {
      $touchedSteps[$step + offset].dirty = false;
    }

    if (offset > 0 && !$touchedSteps[$step].valide) {
      return;
    }

    if (offset > 0 && $step === 4) {
      create({
        projectId,
        success: (data) => {
          window.location = data.url;
        },
        error: (response) => {
          if (response.code && response.code.name) {
            toasts.send({
              title: $t("errors.name_is_already_taken"),
              type: "error",
            });
          } else {
            toasts.send({ title: $t("errors.check_entries"), type: "error" });
          }
        },
      });
    } else {
      $step += offset;
      setTouchedSteps($touchedSteps, $step);
    }
  }

  function onHide() {
    shown = false;
    confirmationType.set(null);
  }

  onMount(() => {
    reset();
  });
</script>

<Modal
  style="bottom-between"
  title={$t("dashboard_confirmation_new_modal.title.xba")}
  {shown}
  {onHide}
>
  <div class={styles.wrapper} data-component="DashboardConfirmationNewModal">
    <ConfirmationNewClient
      bind:valide={$touchedSteps[1].valide}
      dirty={$touchedSteps[1].dirty}
      active={$step === 1}
      clientLock={$step !== 1}
    />

    {#if $step > 1}
      <ConfirmationNewType
        bind:valide={$touchedSteps[2].valide}
        bind:dirty={$touchedSteps[2].dirty}
        active={$step === 2}
        {types}
      />
    {/if}

    {#if $step > 2}
      <ConfirmationNewDate
        bind:valide={$touchedSteps[3].valide}
        bind:dirty={$touchedSteps[3].dirty}
        active={$step === 3}
      />
    {/if}

    {#if $step > 3}
      <ConfirmationNewOptions active={$step === 4} />
    {/if}
  </div>

  <svelte:fragment slot="bottom">
    <div>
      {#if $step > 1}
        <Button style="primary-text" click={changeStep.bind(this, -1)}>
          {$t("dashboard_confirmation_new_modal.back")}
        </Button>
      {/if}
    </div>

    <div>
      {#if $step === 4}
        <Button style="primary" click={changeStep.bind(this, 1)}>
          {$t("dashboard_confirmation_new_modal.create")}
        </Button>
      {:else}
        <Button style="primary-tonal" click={changeStep.bind(this, 1)}>
          {$t("dashboard_confirmation_new_modal.next")}
        </Button>
      {/if}
    </div>
  </svelte:fragment>
</Modal>

<style lang="scss">
  .wrapper {
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
</style>
