import Api from "apis/api";
import { buildFormData } from "lib/helpers.js";

class Confirmation {
  constructor() {
    this.endpoint = "/confirmations";
  }

  create(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params, null, true);

    Api.post(`${this.endpoint}`, {
      body: formData,
      success: (response) => {
        success(response.data);
      },
      error,
    });
  }

  getConfirmation(options) {
    const { params, success, error } = options;

    Api.get(`${this.endpoint}/${params.id}`, {
      params,
      success: (response) => {
        success(response.data);
      },
      error,
    });
  }

  getRelease(options) {
    const { params, success, error } = options;

    Api.get(`${this.endpoint}/${params.id}/release`, {
      params,
      success: (response) => {
        success(response.data);
      },
      error,
    });
  }

  edit(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params, null, true);

    Api.patch(this.endpoint + `/${params.id}`, {
      body: formData,
      success,
      error,
    });
  }

  delete(options) {
    const { params, success, error } = options;

    Api.delete(this.endpoint + `/${params.id}`, {
      success,
      error,
    });
  }

  archive(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params);

    Api.post(this.endpoint + `/${params.id}/archive`, {
      body: formData,
      success,
      error,
    });
  }

  uploadLetterhead(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params);

    Api.patch(this.endpoint + `/${params.id}/upload_letterhead`, {
      body: formData,
      success,
      error,
    });
  }

  usePreviousLetterhead(options) {
    const { params, success, error } = options;

    Api.patch(this.endpoint + `/${params.id}/use_previous_letterhead`, {
      success,
      error,
    });
  }

  confirmMailings(options) {
    const { params, success, error } = options;

    Api.post(this.endpoint + `/${params.id}/confirm_mailings`, {
      success,
      error,
    });
  }

  unconfirmMailings(options) {
    const { params, success, error } = options;

    Api.post(this.endpoint + `/${params.id}/unconfirm_mailings`, {
      success,
      error,
    });
  }

  regenerateMailings(options) {
    const { params, success, error } = options;

    Api.post(this.endpoint + `/${params.id}/regenerate_mailings`, {
      success,
      error,
    });
  }

  getActivities(options) {
    const { params, success, error } = options;

    Api.get(`${this.endpoint}/${params.id}/activities`, {
      params,
      success: (response) => {
        success(response.data);
      },
      error,
    });
  }

  sendContactValidations(id) {
    Api.post(`${this.endpoint}/${id}/send_contact_validations`, {
      success: (response) => {},
      error: (response) => {},
    });
  }

  downloadAllResponses(options) {
    const { params, success, error } = options;

    Api.download(
      `${this.endpoint}/${params.id}/download_all_responses`,
      success,
      error,
    );
  }

  testSignMailings(id) {
    Api.post(`${this.endpoint}/${id}/test_sign_mailings`, {
      success: (response) => {},
      error: (response) => {},
    });
  }

  testMailingsSend(id) {
    Api.post(`${this.endpoint}/${id}/test_mailings_send`, {
      success: (response) => {},
      error: (response) => {},
    });
  }

  testResponseReceive(id) {
    Api.post(`${this.endpoint}/${id}/receive_test_answers`, {
      success: (response) => {},
      error: (response) => {},
    });
  }
}

export default new Confirmation();
