<script>
  import { onDestroy } from "svelte";

  import {
    ControlComments,
    ControlSidebarSection,
    ControlStatus,
    ControlUpload,
  } from "apps/pbc";
  import { Modal } from "components/modals";
  import { isAccountFeature } from "stores/account";
  import documentViewerStore from "stores/document_viewer_store.js";
  import { t } from "stores/i18n.js";
  import { isDirtyState } from "stores/modals";
  import { updatePageHistory } from "stores/page_history.js";
  import {
    isNumeration,
    activeControl,
    setActiveControl,
    filteredControls,
    activeControlPermalink,
  } from "stores/pbc.js";
  import { can } from "stores/permission.js";
  import { toasts } from "stores/toasts.js";
  import { user, isUserClient, isUserTeamUser } from "stores/user.js";
  import { get } from "svelte/store";

  import AuditApi from "apis/pbc/audit.js";
  import ControlApi from "apis/pbc/controls.js";
  import { sanitizeRelaxed } from "lib/sanitize.js";

  import {
    Boundary,
    Button,
    IconButton,
    TextEditor,
    TextInput,
  } from "components";
  import styles from "styleguide/ControlModal.json";

  export let audit;

  $: document = documentViewerStore.documentReadable;

  let errorEditName;
  let errorEditDescription;
  let valueEditName;
  let valueEditDescription;
  let isEditName = false;
  let status;
  let value;
  let storageModalKey = "control-modal-text-editor";
  let storageModalContent = [];

  $: hasUnsavedChanges = $activeControlPermalink && isDirtyState(value);

  function changeActiveControl(step) {
    let group = $filteredControls[$activeControl.control_group_id];
    let index = group.findIndex(
      (item) => item.permalink === $activeControl.permalink,
    );
    let newIndex = index + step;

    if (group.length === newIndex) {
      newIndex = 0;
    } else if (newIndex === -1) {
      newIndex = group.length - 1;
    }

    setActiveControl(group[newIndex].permalink);
  }

  function toPrev() {
    changeActiveControl(-1);
  }

  function toNext() {
    changeActiveControl(1);
  }

  function onEditName() {
    errorEditName = false;
    errorEditDescription = false;

    if (valueEditName) {
      ControlApi.edit({
        params: {
          id: $activeControl.permalink,
          name: valueEditName,
          description: valueEditDescription ? valueEditDescription : "",
        },
        success: onEditNameSuccess,
        error: onEditNameError,
      });
      errorEditName = false;
    } else {
      errorEditName = true;
    }
  }

  function onEditNameSuccess() {
    isEditName = false;
  }

  function onEditNameError() {
    errorEditName = true;
  }

  function onDelete() {
    const isConfirmed = confirm($t("control_status.confirm_deletion"));

    if (isConfirmed) {
      ControlApi.delete($activeControl.permalink);
    }
  }

  function onCopyWebLink() {
    navigator.clipboard
      .writeText($activeControl.apps_pbc_shared_control_path)
      .then(
        function () {
          toasts.send({
            title: $t("control_modal.copied_to_clipboard"),
            type: "success",
          });
        },
        function (err) {
          console.error("Async: Could not copy link to clipboard: ", err);
        },
      );
  }

  function onRemind() {
    ControlApi.remindBatch({
      params: {
        ids: [$activeControl.id],
      },
      success: () => {
        toasts.send({
          title: $t("control_modal.remind_success"),
          type: "success",
        });
      },
      error: () => {
        toasts.send({ title: $t("control_modal.remind_error"), type: "error" });
      },
    });
  }

  const onHide = function () {
    if (!$document.id) {
      AuditApi.getAudits();
      AuditApi.getStatuses();
      setActiveControl(null);
      window.removeEventListener("popstate", onHide);
    }
  };

  const unsubscribe = activeControl.subscribe((value) => {
    if (value) {
      valueEditDescription = value.description;
      valueEditName = value.name;
      status = value.status;
      window.addEventListener("popstate", onHide);
    }
  });

  activeControl.subscribe((value) => {
    if (!value) return;
    updatePageHistory([
      {
        path: value?.apps_pbc_control_path,
        titles: [
          { name: audit?.project_name },
          { name: audit?.name },
          { name: value?.control_group_name },
          { name: value?.name },
        ],
      },
    ]);
  });

  onDestroy(() => {
    unsubscribe();
  });

  $: clientUserHasAccessRights =
    $isUserClient &&
    audit.can_client_users_add_controls &&
    $user.permalink === $activeControl?.creator_permalink;
</script>

<Boundary>
  {#if $activeControl}
    <!-- only hide, not mount. mount will make API calls -->
    <div
      data-component="ControlModal"
      class={$document.id ? styles.hidden : ""}
    >
      <Modal
        isWide
        isFullHeight
        isScrollingInsideModal={false}
        bind:shown={$activeControlPermalink}
        {onHide}
        canHide={!$document.id}
        bind:hasUnsavedChanges
        {storageModalKey}
        {storageModalContent}
      >
        <div class={styles.header} slot="header">
          <div class={styles.info}>
            <ControlStatus status={$activeControl.status} size="medium" />
            <span class={styles.category}
              >{$activeControl.control_group_name}</span
            >
          </div>

          {#if $filteredControls[$activeControl.control_group_id].length > 1}
            <div class={styles.switcher}>
              <IconButton
                click={toPrev}
                style={"primary-text small"}
                icon={"stroke_big_left"}
                disabled={$activeControl.position === 1}
              />
              <IconButton
                click={toNext}
                style={"primary-text small"}
                icon={"stroke_big_right"}
                disabled={$activeControl.position ===
                  $filteredControls[$activeControl.control_group_id].length}
              />
            </div>
          {/if}
        </div>

        <div>
          <div>
            <h1>
              {#if isEditName}
                <TextInput
                  bind:value={valueEditName}
                  onEnter={onEditName}
                  invalid={errorEditName}
                  error={[errorEditName]}
                />
              {:else if $isNumeration}
                {$activeControl.nameNumeration}
              {:else}
                {$activeControl.name}
              {/if}
            </h1>
            <p class={styles.description}>
              {#if isEditName}
                <TextEditor
                  allowMentions={$isAccountFeature("mentions")}
                  active={true}
                  style={"toolbar-top"}
                  placeholder={$t("control_modal.enter_description")}
                  bind:value={valueEditDescription}
                  invalid={errorEditDescription}
                  onEnter={onEditName}
                  error={[errorEditDescription]}
                />
              {:else if $activeControl.description}
                <div class={styles["control-comment-message"]}>
                  {@html sanitizeRelaxed($activeControl.description)}
                </div>
              {:else if $user.role !== "client"}
                {$t("control_modal.no_description_yet")}
              {/if}
            </p>

            <div class={styles.buttons}>
              {#if isEditName}
                <div class={styles.button}>
                  <Button click={onEditName} style={"primary"}>
                    {$t("control_modal.save")}
                  </Button>
                </div>
              {:else}
                {#if $can("update", "control") || clientUserHasAccessRights}
                  <div class={styles.button}>
                    <Button
                      click={() => {
                        isEditName = true;
                      }}
                      style={"primary-text"}
                    >
                      {$t("control_modal.edit")}
                    </Button>
                  </div>
                {/if}
                <div class={styles.button}>
                  <Button click={onCopyWebLink} style={"primary-text"}>
                    {$t("control_modal.copy_web_link")}
                  </Button>
                </div>
                {#if $isUserTeamUser && $isAccountFeature("pbc_reminders")}
                  <Button style={"primary-text"} click={onRemind}>
                    {$t("control_modal.remind_user")}
                  </Button>
                {/if}
                {#if $can("destroy", "control") || (clientUserHasAccessRights && $activeControl.documents_count === 0)}
                  <div class={styles.button}>
                    <Button click={onDelete} style={"error-text"}>
                      {$t("control_modal.delete")}
                    </Button>
                  </div>
                {/if}
              {/if}
            </div>
          </div>

          <div class={styles.main}>
            <div class={styles.sidebar}>
              <ControlUpload
                parent_id={$activeControl.permalink}
                status={$activeControl.status}
                showDownloadButton={$activeControl.documents_count > 0}
              />
              <div class={styles.comment}>
                <ControlComments
                  clientUsers={$activeControl.client_users}
                  bind:value
                  bind:storageModalContent
                  {storageModalKey}
                />
              </div>
            </div>

            <ControlSidebarSection {status} control={$activeControl} />
          </div>
        </div>
      </Modal>
    </div>
  {/if}
</Boundary>

<style global lang="scss">
  .hidden {
    display: none;
  }

  .header {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    align-items: center;
    margin-right: 1em;
  }

  .info {
    display: flex;
    margin-top: 2px;
  }

  .switcher {
    display: flex;
    align-items: center;
    gap: 1em;
  }

  h1 {
    font-size: 22px;
    line-height: 32px;
    font-weight: normal;
    margin: 0;
    padding: 0;
  }

  .category {
    color: var(--primary-300);
    font-weight: 500;
    margin-left: 20px;
    flex: 1;
    line-height: 26px;
  }

  .description {
    margin: 16px 0 20px;
  }

  .control-comment-message i {
    font-style: italic;
  }

  .control-comment-message {
    display: block;
  }

  .control-comment-message ul,
  .control-comment-message ol {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }
  .control-comment-message ul {
    list-style-type: disc;
  }

  .control-comment-message ol {
    list-style-type: decimal;
  }

  .buttons {
    display: flex;
    gap: 8px;
  }

  .main {
    display: flex;
    gap: 3em;
    margin-top: 30px;
  }

  .sidebar {
    flex: 1;
  }

  .label {
    font-weight: 600;
  }

  .statuses {
    min-width: 203px;
    max-width: 280px;
    margin-top: 10px;
  }

  .statuses .label {
    margin-top: 25px;
    margin-bottom: 10px;
  }

  .statuses .label:first-child {
    margin-top: 0;
  }

  .comment {
    margin: 30px 0 50px;
  }

  .noyet {
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    color: var(--primary-400);
  }

  .reviewing {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    padding: 10px 25px;
    margin: -20px -25px 20px;

    &.green {
      width: 820px;
      position: sticky;
      top: 0;
      z-index: 4;
      background: var(--green-010);
      color: var(--green-400);
    }

    &.grey {
      background: var(--primary-025);
      color: var(--primary-300);
    }
  }
</style>
