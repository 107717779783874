<script>
  import styles from "styleguide/Pagination.json";
  import { Button } from "components";

  export let click;
  export let pagesNumber;
  export let currentPage;
  export let scrollContainer;

  $: items = buildPages(currentPage, pagesNumber);

  function onClick(item) {
    if (!(item === currentPage) && !(item === "...")) {
      if (click) click(item);

      if (scrollContainer) {
        scrollContainer.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  }

  function getItemStyles(item, currentPage) {
    if (item === currentPage) {
      return "blue small";
    } else {
      return "blue-text small";
    }
  }

  function buildPages(current, last) {
    let delta = 2,
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [],
      l;

    range.push(1);

    for (let i = current - delta; i <= current + delta; i++) {
      if (i >= left && i < right && i < last && i > 1) {
        range.push(i);
      }
    }

    range.push(last);

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push("...");
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  }
</script>

{#if pagesNumber > 1}
  <div data-component="Pagination" class={styles.wrapper}>
    {#each items as item}
      <Button
        style={getItemStyles(item, currentPage)}
        click={onClick.bind(this, item)}
      >
        {item}
      </Button>
    {/each}
  </div>
{/if}

<style lang="scss">
  .wrapper {
    display: flex;
    justify-content: center;
    gap: 8px;
  }
</style>
