<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(98.431373%,10.588235%,13.333333%);fill-opacity:1;"
      d="M 0 0 L 475.714844 0 L 475.714844 360 L 0 360 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 154.285156 L 475.714844 154.285156 L 475.714844 205.714844 L 0 205.714844 Z M 0 154.285156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 154.285156 0 L 205.714844 0 L 205.714844 360 L 154.285156 360 Z M 154.285156 0 "
    />
  </g>
</svg>
