<script>
  import styles from "styleguide/ResponderGeneralMethod.json";
  import { ResponderDescription, ResponderSteps } from "apps/responder";

  export let onCreated;
  export let onChecking;

  function onCreatedHandler(event) {
    onCreated(event.detail.response);
  }
</script>

<div data-component="ResponderGeneralMethod" class={styles.container}>
  <ResponderDescription />
</div>
<div class={styles.container}>
  <ResponderSteps on:created={onCreatedHandler} on:checking={onChecking} />
</div>

<style lang="scss">
  .container {
    padding: 35px;
    border-bottom: solid 1px var(--primary-050);
  }
</style>
