<script>
  import styles from "styleguide/ConfirmationSignerCounterparties.json";

  import { t } from "stores/i18n.js";
  import { signer, client } from "stores/confirmation/confirmation_signer.js";
</script>

<div
  data-component="ConfirmationSignerCounterparties"
  class={styles.counterparties}
>
  <div class={styles.counterpartiesItem}>
    <p class={styles.counterpartiesLabel}>
      {$t("confirmation_signer_counterparties.signer")}
    </p>
    <p class={styles.counterpartiesName}>{$signer.email}</p>
  </div>
  <div class={styles.counterpartiesItem}>
    <p class={styles.counterpartiesLabel}>
      {$t("confirmation_signer_counterparties.client")}
    </p>
    <p class={styles.counterpartiesName}>{$client.official_name}</p>
  </div>
</div>

<style lang="scss">
  .counterparties {
    display: flex;
  }

  .counterpartiesItem {
    width: 50%;
    padding-right: 25px;
  }

  .counterpartiesLabel {
    color: var(--primary-300);
    margin: 0 0 10px;
  }

  .counterpartiesName {
    color: var(--primary-500);
    margin: 0;
    font-weight: 600;
    line-height: 1.57;
  }

  .counterpartiesAddress {
    margin: 0;
    line-height: 1.57;
  }
</style>
