<script>
  import { AuditAccessSelector } from "apps/pbc";
  import { isAccountFeature } from "stores/account";
  import { accessMode } from "stores/client_store";
  import { t } from "stores/i18n.js";
  import { auditId, filteredEntities } from "stores/pbc.js";
  import {
    activeExport,
    controlGroups,
    selectedGroups,
    selectedControls,
    isUserCanDelegate,
  } from "stores/pbc.js";
  import { can } from "stores/permission.js";
  import { toasts } from "stores/toasts.js";
  import { isUserTeamUser } from "stores/user.js";

  import AuditsApi from "apis/pbc/audit.js";
  import AuditApi from "apis/pbc/audit.js";
  import ControlAccessApi from "apis/pbc/control_access_api";
  import ControlGroupApi from "apis/pbc/control_groups.js";
  import ControlApi from "apis/pbc/controls.js";

  import { Boundary, Button, DatePicker, Options } from "components";
  import styles from "styleguide/AuditBottomBar.json";

  let loadingRemind = false;
  let showDatePicker = false;
  let showAccessModal = false;

  export let shown = false;

  function onCancelSelection() {
    selectedGroups.set([]);
    selectedControls.set([]);
  }

  function setStatus(status) {
    ControlApi.updateStatusBatch(
      $selectedControls,
      status,
      $t("audit_bottom_bar.status_update_success"),
      $t("audit_bottom_bar.status_update_error"),
    );
  }

  function updateDueOn(date) {
    showDatePicker = false;
    ControlApi.updateDueOnBatch({
      params: {
        date,
        ids: $selectedControls,
      },
      success: () => {
        AuditsApi.getAudits();
        AuditsApi.getStatuses();
        toasts.send({
          title: $t("audit_bottom_bar.due_on_update_success"),
          type: "success",
        });
      },
      error: () => {
        toasts.send({
          title: $t("audit_bottom_bar.due_on_update_error"),
          type: "error",
        });
      },
    });
  }

  function onDownload() {
    activeExport.set(true);
  }

  function onRemind() {
    loadingRemind = true;
    ControlApi.remindBatch({
      params: {
        ids: $selectedControls,
      },
      success: () => {
        loadingRemind = false;
        toasts.send({
          title: $t("audit_bottom_bar.remind_success"),
          type: "success",
        });
      },
      error: () => {
        loadingRemind = false;
        toasts.send({
          title: $t("audit_bottom_bar.remind_error"),
          type: "error",
        });
      },
    });
  }

  function onDeleteSelection() {
    const isConfirmed = confirm($t("control_status.confirm_deletion"));

    if (isConfirmed) {
      let controlsToDelete = [...$selectedControls];

      if ($selectedGroups.length) {
        $selectedGroups.forEach((groupId) => {
          let group = $controlGroups.find((group) => group.id === groupId);

          controlsToDelete = controlsToDelete.filter((controlId) => {
            return group.controls.every((control) => control.id !== controlId);
          });
        });

        ControlGroupApi.deleteBatch($selectedGroups);
      }

      if (controlsToDelete.length) {
        ControlApi.deleteBatch(
          controlsToDelete,
          $t("audit_bottom_bar.destroy_success"),
          $t("audit_bottom_bar.destroy_error"),
        );
      }
    }
  }

  function onHandleAccessUpdateUsers(allowedUserPermalinks) {
    ControlAccessApi.updateUsers({
      params: {
        control_ids: $selectedControls,
        user_ids: allowedUserPermalinks,
        only_add: true,
      },
      success: () => {
        AuditsApi.getAudits();
        AuditApi.getClientUsers();
        toasts.send({
          title: $t("audit_bottom_bar.client_access_updated"),
          type: "success",
        });
      },
      error: () => {
        toasts.send({
          title: $t("audit_bottom_bar.client_access_error"),
          type: "error",
        });
      },
    });
  }

  function onHandleAccessUpdateTeams(allowedTeamPermalinks) {
    ControlAccessApi.updateTeams({
      params: {
        control_ids: $selectedControls,
        team_ids: allowedTeamPermalinks, // needs to be optional in case you want to delete something
        only_add: true,
      },
      success: () => {
        AuditsApi.getAudits();
        toasts.send({
          title: $t("audit_bottom_bar.team_access_updated"),
          type: "success",
        });
      },
      error: () => {
        toasts.send({
          title: $t("audit_bottom_bar.team_access_error"),
          type: "error",
        });
      },
    });
  }

  function onHandleAccessCreateNewUser(data) {
    ControlAccessApi.addNewUser({
      params: {
        control_ids: data.controlIds,
        email: data.filterQuery,
      },
      success: () => {
        AuditsApi.getAudits();
        AuditsApi.getClientUsers();
        toasts.send({
          title: $t("audit_bottom_bar.add_user_success"),
          type: "success",
        });
      },
      error: () => {
        toasts.send({
          title: $t("audit_bottom_bar.add_user_error"),
          type: "error",
        });
      },
    });
  }

  function onAccessChange(data) {
    showAccessModal = false;

    switch ($accessMode) {
      case "users":
        if (!$filteredEntities.length && !$isUserTeamUser) {
          return onHandleAccessCreateNewUser(data);
        }
        return onHandleAccessUpdateUsers(data.users);
      case "teams":
        onHandleAccessUpdateTeams(data.teams);
        break;
      default:
        break;
    }
  }
</script>

<Boundary>
  {#if shown}
    <div data-component="AuditBottomBar" class={styles["audit-bottom-bar"]}>
      <span class={styles.count}
        >{$selectedControls.length} {$t("audit_bottom_bar.selected")}</span
      >

      <div class={styles.actions}>
        <div class={styles["actions-left"]}>
          <Button style="error-text" click={onCancelSelection}
            >{$t("audit_bottom_bar.cancel_selection")}</Button
          >

          {#if $isUserTeamUser}
            <div class={styles.action}>
              <Options
                style="top acts-as-button no-border"
                title={$t("audit_bottom_bar.status")}
              >
                <Button
                  click={() => setStatus("pending")}
                  style="blue-text option"
                >
                  {$t("audit_bottom_bar.mask_as_pending")}
                </Button>
                <Button
                  click={() => setStatus("accepted")}
                  style="blue-text option"
                >
                  {$t("audit_bottom_bar.mask_as_accepted")}
                </Button>
                <Button
                  click={() => setStatus("rejected")}
                  style="blue-text option"
                >
                  {$t("audit_bottom_bar.mask_as_rejected")}
                </Button>
              </Options>
            </div>

            <div class={styles.action}>
              <Options
                style="top acts-as-button no-border"
                background={false}
                title={$t("audit_bottom_bar.due_on")}
                bind:show={showDatePicker}
                autoclose={false}
              >
                <DatePicker onRemove={updateDueOn} onSelect={updateDueOn} />
              </Options>
            </div>
          {/if}

          {#if $isUserTeamUser || $isUserCanDelegate}
            <div class={styles.action}>
              <Options
                style="top items_wider acts-as-button  no-border"
                background={false}
                title={$isAccountFeature("teams")
                  ? $t("audit_bottom_bar.add_user_or_team")
                  : $t("audit_bottom_bar.add_user")}
                bind:show={showAccessModal}
                autoclose={false}
              >
                <AuditAccessSelector
                  onSave={onAccessChange}
                  controlIds={$selectedControls}
                />
              </Options>
            </div>
          {/if}

          {#if $isUserTeamUser && $isAccountFeature("pbc_reminders")}
            <div class={styles.action}>
              <Button
                style="primary-text"
                click={onRemind}
                loading={loadingRemind}
                >{$t("audit_bottom_bar.remind_user")}</Button
              >
            </div>
          {/if}

          <Button style="primary-text" click={onDownload}
            >{$t("audit_bottom_bar.download")}</Button
          >
        </div>

        {#if $can("destroy", "control")}
          <Button style="error-text" click={onDeleteSelection}
            >{$t("audit_bottom_bar.delete_selection")}</Button
          >
        {/if}
      </div>
    </div>
  {/if}
</Boundary>

<style lang="scss">
  .audit-bottom-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background: #fff;
    border-top: 1px solid var(--primary-050);
    z-index: var(--z-index-bottom-bar);
    padding: 10px 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .count {
    font-size: 16px;
    font-weight: 500;
    margin-right: 20px;
  }

  .action {
    margin-right: 8px;
  }

  .actions {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .actions-left {
    display: flex;
    align-items: center;
    gap: 4px;
  }
</style>
