<script>
  import styles from "styleguide/ConfirmationSignerCropper.json";
  import Cropper from "cropperjs";

  import { isDesktopWidth } from "stores/device.js";
  import { t } from "stores/i18n.js";
  import { toasts } from "stores/toasts.js";
  import { token } from "stores/confirmation/confirmation_signer.js";

  import { Dropzone, LegacyButton as Button } from "components";
  import { Modal } from "components/modals";

  let signatureImg;
  let signatureBlob;
  export let shown;
  export let setSignature;

  let dropzone;
  let canvas;
  let cropper;
  let photo;
  let img;

  const onHide = function () {
    shown = false;
  };

  function clear() {
    if (cropper) {
      cropper.destroy();
    }
    photo = null;
  }

  function uploadNew() {
    cropper.destroy();
    dropzone.click();
  }

  function save() {
    if (!cropper) {
      return toasts.send({
        title: $t("confirmation_signer_cropper.error_no_signature"),
        type: "error",
      });
    }

    signatureImg = cropper.getCroppedCanvas().toDataURL("image/png");
    cropper.getCroppedCanvas().toBlob(
      (blob) => {
        signatureBlob = blob;
        setSignature(signatureImg, signatureBlob, $token);
      },
      "image/png",
      1,
    );

    onHide();
  }

  function onAddFiles(files) {
    img = new Image();
    img.addEventListener("load", onImageLoad, false);
    img.addEventListener("error", onImageError, false);

    if (files && files[0]) {
      img.src = URL.createObjectURL(files[0]);
    }
  }

  function onImageLoad() {
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    cropper = new Cropper(canvas, {
      autoCrop: false,
      zoomable: false,
      ready() {
        this.cropper.move(1, -1);
        this.cropper.crop();
      },
    });
    photo = img.src;
  }

  function onImageError() {
    toasts.send({
      title: $t("confirmation_signer_cropper.error_not_image"),
      type: "error",
    });
  }
</script>

<Modal
  style={$isDesktopWidth ? "" : "mobile"}
  {shown}
  {onHide}
  title={$t("confirmation_signer_cropper.title")}
>
  <div class={styles.wrapper} data-component="ConfirmationSignerCropper">
    <div class={photo ? styles.photo : ""}>
      <canvas bind:this={canvas} class={styles.pad} />

      <div class={styles.dropzone}>
        <Dropzone
          onFiles={onAddFiles}
          bind:this={dropzone}
          accept="image/png, image/gif, image/jpeg"
        />
      </div>
    </div>

    {#if photo}
      <div class={styles.actions}>
        <Button click={clear} style="border red margin-right"
          >{$t("actions.clear")}</Button
        >

        <Button click={uploadNew} style="primary margin-right"
          >{$t("actions.upload_new")}</Button
        >

        <Button click={save} style="icon primary" icon="checkmark_medium_white"
          >{$t("actions.save")}</Button
        >
      </div>
    {/if}
  </div>
</Modal>

<style global lang="scss">
  .pad {
    display: none;
    width: 100%;
    background-color: #fff;
    border: 1px solid var(--blue);
    user-select: none;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .photo {
    max-height: calc(100vh - 180px);

    .pad {
      display: block;
    }

    .dropzone {
      display: none;
    }
  }

  :global(.cropper-container) {
    direction: ltr;
    font-size: 0;
    line-height: 0;
    position: relative;
    -ms-touch-action: none;
    touch-action: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  :global(.cropper-container img) {
    display: block;
    height: 100%;
    image-orientation: 0deg;
    max-height: none !important;
    max-width: none !important;
    min-height: 0 !important;
    min-width: 0 !important;
    width: 100%;
  }

  :global(.cropper-wrap-box),
  :global(.cropper-canvas),
  :global(.cropper-crop-box),
  :global(.cropper-modal) {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  :global(.cropper-wrap-box),
  :global(.cropper-canvas) {
    overflow: hidden;
  }

  :global(.cropper-view-box) {
    display: block;
    height: 100%;
    outline: 1px solid #39f;
    outline-color: rgba(51, 153, 255, 0.75);
    overflow: hidden;
    width: 100%;
  }

  :global(.cropper-dashed) {
    border: 0 dashed #eee;
    display: block;
    opacity: 0.5;
    position: absolute;
  }

  :global(.cropper-dashed.dashed-h) {
    border-bottom-width: 1px;
    border-top-width: 1px;
    height: calc(100% / 3);
    left: 0;
    top: calc(100% / 3);
    width: 100%;
  }

  :global(.cropper-dashed.dashed-v) {
    border-left-width: 1px;
    border-right-width: 1px;
    height: 100%;
    left: calc(100% / 3);
    top: 0;
    width: calc(100% / 3);
  }

  :global(.cropper-center) {
    display: block;
    height: 0;
    left: 50%;
    opacity: 0.75;
    position: absolute;
    top: 50%;
    width: 0;
  }

  :global(.cropper-center::before),
  :global(.cropper-center::after) {
    background-color: #eee;
    content: " ";
    display: block;
    position: absolute;
  }

  :global(.cropper-center::before) {
    height: 1px;
    left: -3px;
    top: 0;
    width: 7px;
  }

  :global(.cropper-center::after) {
    height: 7px;
    left: 0;
    top: -3px;
    width: 1px;
  }

  :global(.cropper-face),
  :global(.cropper-line),
  :global(.cropper-point) {
    display: block;
    height: 100%;
    opacity: 0.1;
    position: absolute;
    width: 100%;
  }

  :global(.cropper-face) {
    background-color: #fff;
    left: 0;
    top: 0;
  }

  :global(.cropper-line) {
    background-color: #39f;
  }

  :global(.cropper-line.line-e) {
    cursor: ew-resize;
    right: -3px;
    top: 0;
    width: 5px;
  }

  :global(.cropper-line.line-n) {
    cursor: ns-resize;
    height: 5px;
    left: 0;
    top: -3px;
  }

  :global(.cropper-line.line-w) {
    cursor: ew-resize;
    left: -3px;
    top: 0;
    width: 5px;
  }

  :global(.cropper-line.line-s) {
    bottom: -3px;
    cursor: ns-resize;
    height: 5px;
    left: 0;
  }

  :global(.cropper-point) {
    background-color: #39f;
    height: 5px;
    opacity: 0.75;
    width: 5px;
  }

  :global(.cropper-point.point-e) {
    cursor: ew-resize;
    margin-top: -3px;
    right: -3px;
    top: 50%;
  }

  :global(.cropper-point.point-n) {
    cursor: ns-resize;
    left: 50%;
    margin-left: -3px;
    top: -3px;
  }

  :global(.cropper-point.point-w) {
    cursor: ew-resize;
    left: -3px;
    margin-top: -3px;
    top: 50%;
  }

  :global(.cropper-point.point-s) {
    bottom: -3px;
    cursor: s-resize;
    left: 50%;
    margin-left: -3px;
  }

  :global(.cropper-point.point-ne) {
    cursor: nesw-resize;
    right: -3px;
    top: -3px;
  }

  :global(.cropper-point.point-nw) {
    cursor: nwse-resize;
    left: -3px;
    top: -3px;
  }

  :global(.cropper-point.point-sw) {
    bottom: -3px;
    cursor: nesw-resize;
    left: -3px;
  }

  :global(.cropper-point.point-se) {
    bottom: -3px;
    cursor: nwse-resize;
    height: 20px;
    opacity: 1;
    right: -3px;
    width: 20px;
  }

  @media (min-width: 768px) {
    :global(.cropper-point.point-se) {
      height: 15px;
      width: 15px;
    }
  }

  @media (min-width: 992px) {
    :global(.cropper-point.point-se) {
      height: 10px;
      width: 10px;
    }
  }

  @media (min-width: 1200px) {
    :global(.cropper-point.point-se) {
      height: 5px;
      opacity: 0.75;
      width: 5px;
    }
  }

  :global(.cropper-point.point-se::before) {
    background-color: #39f;
    bottom: -50%;
    content: " ";
    display: block;
    height: 200%;
    opacity: 0;
    position: absolute;
    right: -50%;
    width: 200%;
  }

  :global(.cropper-invisible) {
    opacity: 0;
  }

  :global(.cropper-bg) {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
  }

  :global(.cropper-hide) {
    display: block;
    height: 0;
    position: absolute;
    width: 0;
  }

  :global(.cropper-hidden) {
    display: none !important;
  }

  :global(.cropper-move) {
    cursor: move;
  }

  :global(.cropper-crop) {
    cursor: crosshair;
  }

  :global(.cropper-disabled .cropper-face),
  :global(.cropper-disabled .cropper-line),
  :global(.cropper-disabled .cropper-point) {
    cursor: not-allowed;
  }
</style>
