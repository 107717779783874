<script>
  import styles from "styleguide/ConfirmationRemindSigners.json";
  import { getContext } from "svelte";

  import { t } from "stores/i18n.js";
  import {
    getItem,
    getActivities,
  } from "stores/confirmation/confirmation_item.js";
  import { activeRemindSignersId } from "stores/confirmation/confirmation.js";
  import { toasts } from "stores/toasts.js";

  import ConfirmationSignersApi from "apis/confirmation/confirmation_signers.js";
  import { distanceOfTimeFromDate, distanceOfTimeInSeconds } from "lib/helpers";

  import { Button, Checkbox, Tooltip } from "components";
  import { Modal } from "components/modals";

  const config = getContext("config");
  const notificationTimeout = 600;

  let list = [];
  let loading = false;
  let areSignersSelected = false;

  function onHide() {
    list = [];
    loading = areSignersSelected = false;
    activeRemindSignersId.set(null);
    getItem();
    getActivities();
  }

  function onShow() {
    if ($activeRemindSignersId) {
      getData($activeRemindSignersId);
    }
  }

  function getData(params) {
    ConfirmationSignersApi.getUnsignedSigners({
      params,
      success: (response) => {
        list = response.data.confirmation_signers.map((signer) => {
          if (signer.notified_at) {
            signer.notified_at = new Date(signer.notified_at);
            signer.notified_seconds_go = distanceOfTimeInSeconds(
              signer.notified_at,
            );
          } else {
            signer.notified_seconds_go = notificationTimeout;
          }
          return signer;
        });
      },
    });
  }

  function check(value, permalink) {
    list.find((item) => item.permalink === permalink).checked = value;
    areSignersSelected = list.some((signer) => signer.checked);
  }

  function submit() {
    if (loading) return;
    loading = true;

    let params = {
      ...$activeRemindSignersId,
      ids: list.filter((item) => item.checked).map((item) => item.permalink),
    };

    if (areSignersSelected) {
      ConfirmationSignersApi.remind({
        params,
        success: () => {
          onHide();
          toasts.send({
            title: $t("confirmation_remind_signers.success"),
            type: "success",
          });
        },
        error: () => {
          toasts.send({ title: $t("errors.check_entries"), type: "error" });
          loading = false;
        },
      });
    }
  }
</script>

{#if $activeRemindSignersId}
  <Modal
    title={$t("confirmation_remind_signers.title")}
    shown={$activeRemindSignersId}
    {onHide}
    {onShow}
  >
    <div data-component="ConfirmationRemindSigners">
      <div class={styles.description}>
        {$t("confirmation_remind_signers.description")}
      </div>
      <div class={styles.list}>
        {#if list.length > 0}
          <table class={styles.grouped}>
            <tr class={`${styles["grouped-head"]} ${styles["grouped-row"]}`}>
              <th class={styles["grouped-item"]}>
                {$t("confirmation_remind_signers.email")}
              </th>
              <th class={styles["grouped-item"]}>
                {$t("confirmation_remind_signers.company")}
              </th>
              <th class={styles["grouped-item"]}>
                {$t("confirmation_remind_signers.remind")}
              </th>
            </tr>
            {#each list as { email, companies_waiting_for_sign, permalink, checked, notified_at, notified_seconds_go }}
              <tr class={styles["grouped-row"]}>
                <td class={styles["grouped-item"]}>{email}</td>
                <td class={styles["grouped-item"]}>
                  {companies_waiting_for_sign}
                </td>
                <td class={styles["grouped-item"]}>
                  {#if notified_seconds_go >= notificationTimeout}
                    <Checkbox
                      toggled={checked}
                      toggle={(value) => check(value, permalink)}
                    />
                  {:else}
                    {$t("confirmation_remind_signers.recently_reminded", {
                      time: distanceOfTimeFromDate(
                        notified_at,
                        config.language,
                      ),
                    })}
                  {/if}
                </td>
              </tr>
            {/each}
          </table>
        {/if}
      </div>
    </div>

    <svelte:fragment slot="bottom">
      {#if !areSignersSelected}
        <Tooltip showArrow>
          <div slot="content">
            <Button style={"primary"} disabled={true} click={""}>
              {$t("confirmation_remind_signers.submit")}
            </Button>
          </div>
          <div slot="tooltip">
            {$t("confirmation_remind_signers.min_one_signer")}
          </div>
        </Tooltip>
      {:else}
        <Button style={"primary"} click={submit}>
          {$t("confirmation_remind_signers.submit")}
        </Button>
      {/if}
    </svelte:fragment>
  </Modal>
{/if}

<style lang="scss">
  .description {
    padding: 0;
  }

  .grouped {
    margin: 0;
    border-collapse: collapse;
  }

  .grouped-row:not(:last-child) {
    border-bottom: 1px solid var(--primary-050);
  }

  .grouped-item {
    text-align: left;
    padding: 15px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
</style>
