<script>
  import styles from "styleguide/PageHistory.json";
  import { fade } from "svelte/transition";

  import { BreadcrumbIconButton } from "components";
  import { Drawer } from "components/pageHistory";

  export let hasOpen = false;

  function onOpen() {
    hasOpen = true;
  }

  function onClose() {
    hasOpen = false;
  }
</script>

<div data-svelte-component="PageHistory">
  <BreadcrumbIconButton click={onOpen} type="history" />
  {#if hasOpen}
    <div
      class={styles.backdrop}
      on:click={onClose}
      transition:fade|global={{ duration: 100 }}
    />

    <Drawer {onClose} />
  {/if}
</div>

<style lang="scss">
  .backdrop {
    position: fixed;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(20, 42, 78, 0.5);
    z-index: 1;
  }
</style>
