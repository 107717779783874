<script>
  import styles from "styleguide/ConfirmationSignerSuccess.json";
  import { t } from "stores/i18n.js";
</script>

<div data-component="ConfirmationSignerSuccess" class={styles.container}>
  <div class={styles.block}>
    <div class={styles.checkmark}>
      <img
        src="/assets/svg_icons/checkmark_big_green.svg"
        class={styles.logo}
        alt="Auditi"
      />
    </div>

    <h2 class={styles.title}>{$t("confirmation_signer_success.title")}</h2>
    <p class={styles.subtitle}>
      {@html $t("confirmation_signer_success.subtitle")}
    </p>
  </div>
</div>

<style lang="scss">
  .block {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 64px 0 104px;
    text-align: center;
  }

  .container {
    padding: 0 35px;
  }

  .title {
    margin: 0 0 16px;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.3;
    color: var(--primary-500);
  }

  .subtitle {
    margin: 0;
    line-height: 1.6;
    color: var(--primary-500);
  }

  .checkmark {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 8px;
    margin-bottom: 20px;
    background: var(--green-010);

    img {
      width: 18px;
      height: 18px;
    }
  }
</style>
