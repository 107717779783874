<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(95.686275%,7.843137%,3.137255%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(12.941176%,52.941176%,23.137255%);fill-opacity:1;"
      d="M 0 239.996094 L 480 239.996094 L 480 360 L 0 360 Z M 0 239.996094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 0 0 L 480 0 L 480 120.003906 L 0 120.003906 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 165.359375 105.792969 C 182.089844 68.847656 228.734375 51.175781 269.480469 66.347656 C 290 73.988281 304.535156 87.164062 312.972656 105.769531 L 165.363281 105.796875 Z M 165.359375 105.792969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 145.882812 110.570312 C 128.292969 109.386719 109.601562 105.726562 106.550781 104.71875 C 103.5 103.714844 103.382812 102.03125 103.757812 100.742188 C 104.132812 99.457031 106.082031 97.992188 108.351562 98.539062 C 112.613281 99.558594 129.945312 103.714844 145.878906 110.570312 Z M 145.882812 110.570312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 239.355469 46.136719 C 236.199219 30.402344 235.402344 13.078125 235.710938 10.148438 C 236.015625 7.21875 237.78125 6.691406 239.253906 6.695312 C 240.722656 6.703125 242.773438 8.042969 242.761719 10.175781 C 242.742188 14.171875 242.664062 30.425781 239.355469 46.136719 Z M 239.355469 46.136719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 248.007812 46.5 C 246.351562 30.578125 247.195312 13.257812 247.777344 10.359375 C 248.363281 7.464844 250.171875 7.078125 251.632812 7.199219 C 253.097656 7.320312 255.015625 8.8125 254.800781 10.9375 C 254.40625 14.917969 252.789062 31.105469 248.007812 46.5 Z M 248.007812 46.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 255.855469 47.464844 C 255.648438 31.472656 258.058594 14.273438 258.902344 11.429688 C 259.746094 8.589844 261.585938 8.335938 263.03125 8.566406 C 264.480469 8.796875 266.253906 10.429688 265.851562 12.527344 C 265.09375 16.464844 262.015625 32.480469 255.855469 47.464844 Z M 255.855469 47.464844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 264.113281 49.140625 C 265.457031 33.191406 269.527344 16.25 270.644531 13.488281 C 271.761719 10.722656 273.617188 10.621094 275.035156 10.964844 C 276.457031 11.308594 278.066406 13.074219 277.460938 15.136719 C 276.324219 18.996094 271.703125 34.703125 264.113281 49.140625 Z M 264.113281 49.140625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 272.25 51.488281 C 275.148438 35.714844 280.863281 19.167969 282.246094 16.503906 C 283.628906 13.839844 285.488281 13.886719 286.867188 14.34375 C 288.25 14.800781 289.679688 16.691406 288.871094 18.695312 C 287.363281 22.449219 281.222656 37.722656 272.253906 51.488281 Z M 272.25 51.488281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 279.832031 54.363281 C 284.210938 38.871094 291.460938 22.828125 293.089844 20.28125 C 294.71875 17.734375 296.566406 17.925781 297.898438 18.488281 C 299.230469 19.050781 300.476562 21.042969 299.484375 22.976562 C 297.628906 26.601562 290.070312 41.339844 279.832031 54.363281 Z M 279.832031 54.363281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 287.292969 57.90625 C 293.152344 42.820312 301.925781 27.414062 303.792969 25.007812 C 305.660156 22.601562 307.484375 22.9375 308.757812 23.601562 C 310.027344 24.269531 311.078125 26.355469 309.902344 28.199219 C 307.703125 31.664062 298.753906 45.746094 287.292969 57.90625 Z M 287.292969 57.90625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 294.351562 62.007812 C 301.636719 47.441406 311.859375 32.789062 313.949219 30.539062 C 316.042969 28.289062 317.824219 28.765625 319.027344 29.53125 C 320.234375 30.292969 321.078125 32.453125 319.730469 34.203125 C 317.203125 37.476562 306.933594 50.800781 294.351562 62.007812 Z M 294.351562 62.007812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 300.996094 66.65625 C 309.65625 52.722656 321.246094 38.933594 323.550781 36.859375 C 325.851562 34.78125 327.578125 35.402344 328.707031 36.257812 C 329.832031 37.113281 330.464844 39.332031 328.953125 40.964844 C 326.125 44.03125 314.609375 56.488281 300.996094 66.65625 Z M 300.996094 66.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 307.183594 71.824219 C 317.152344 58.628906 330.03125 45.8125 332.523438 43.929688 C 335.015625 42.042969 336.679688 42.796875 337.71875 43.738281 C 338.757812 44.679688 339.175781 46.941406 337.511719 48.449219 C 334.398438 51.277344 321.726562 62.777344 307.183594 71.824219 Z M 307.183594 71.824219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 312.871094 77.480469 C 324.078125 65.132812 338.144531 53.390625 340.808594 51.710938 C 343.476562 50.03125 345.058594 50.914062 346.003906 51.933594 C 346.949219 52.957031 347.144531 55.242188 345.34375 56.609375 C 341.964844 59.179688 328.230469 69.625 312.867188 77.484375 Z M 312.871094 77.480469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 317.757812 83.285156 C 330.066406 71.828125 345.171875 61.195312 347.980469 59.722656 C 350.792969 58.253906 352.289062 59.253906 353.136719 60.339844 C 353.984375 61.429688 353.96875 63.722656 352.046875 64.949219 C 348.441406 67.253906 333.792969 76.621094 317.757812 83.285156 Z M 317.757812 83.285156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 322.183594 89.574219 C 335.527344 79.117188 351.578125 69.695312 354.519531 68.445312 C 357.457031 67.199219 358.855469 68.308594 359.597656 69.460938 C 360.335938 70.609375 360.105469 72.894531 358.074219 73.96875 C 354.269531 75.984375 338.789062 84.179688 322.183594 89.574219 Z M 322.183594 89.574219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 326.285156 96.742188 C 340.640625 87.449219 357.585938 79.414062 360.640625 78.417969 C 363.695312 77.421875 364.972656 78.648438 365.589844 79.855469 C 366.210938 81.0625 365.746094 83.316406 363.617188 84.21875 C 359.621094 85.902344 343.371094 92.765625 326.285156 96.742188 Z M 326.285156 96.742188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 329.421875 103.667969 C 344.601562 95.523438 362.242188 88.835938 365.382812 88.082031 C 368.519531 87.328125 369.675781 88.648438 370.179688 89.898438 C 370.683594 91.152344 370.007812 93.359375 367.796875 94.089844 C 363.65625 95.460938 346.816406 101.03125 329.421875 103.664062 Z M 329.421875 103.667969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 331.941406 111.003906 C 347.855469 104.105469 366.078125 98.855469 369.277344 98.355469 C 372.472656 97.855469 373.5 99.261719 373.878906 100.546875 C 374.257812 101.835938 373.371094 103.980469 371.101562 104.53125 C 366.84375 105.566406 349.527344 109.769531 331.941406 111.003906 Z M 331.941406 111.003906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 230.886719 46.433594 C 226.140625 31.03125 223.578125 13.847656 223.585938 10.90625 C 223.59375 7.960938 225.300781 7.289062 226.761719 7.171875 C 228.226562 7.054688 230.40625 8.21875 230.613281 10.339844 C 231 14.324219 232.578125 30.515625 230.886719 46.433594 Z M 230.886719 46.433594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 222.5625 47.410156 C 216.351562 32.441406 212.144531 15.527344 211.871094 12.59375 C 211.59375 9.660156 213.226562 8.855469 214.675781 8.625 C 216.121094 8.390625 218.402344 9.378906 218.8125 11.476562 C 219.582031 15.414062 222.714844 31.417969 222.5625 47.410156 Z M 222.5625 47.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 214.289062 49.058594 C 206.648438 34.644531 200.8125 18.132812 200.25 15.234375 C 199.691406 12.335938 201.238281 11.402344 202.65625 11.054688 C 204.074219 10.707031 206.445312 11.507812 207.058594 13.566406 C 208.207031 17.425781 212.882812 33.117188 214.28125 49.058594 Z M 214.289062 49.058594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 206.488281 51.269531 C 197.527344 37.496094 190.167969 21.492188 189.34375 18.644531 C 188.515625 15.800781 189.972656 14.753906 191.351562 14.296875 C 192.734375 13.84375 195.167969 14.457031 195.972656 16.460938 C 197.480469 20.214844 203.605469 35.492188 206.496094 51.269531 Z M 206.488281 51.269531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 198.871094 54.097656 C 188.632812 41.070312 179.789062 25.699219 178.695312 22.925781 C 177.601562 20.15625 178.953125 19 180.285156 18.4375 C 181.617188 17.878906 184.101562 18.300781 185.089844 20.234375 C 186.945312 23.859375 194.496094 38.601562 198.863281 54.097656 Z M 198.871094 54.097656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 191.167969 57.699219 C 179.683594 45.5625 169.347656 30.972656 167.984375 28.304688 C 166.617188 25.632812 167.851562 24.371094 169.121094 23.703125 C 170.394531 23.035156 172.910156 23.253906 174.085938 25.097656 C 176.296875 28.558594 185.277344 42.625 191.167969 57.699219 Z M 191.167969 57.699219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 184.152344 61.730469 C 171.554688 50.535156 159.875 36.808594 158.257812 34.257812 C 156.640625 31.703125 157.75 30.351562 158.953125 29.585938 C 160.15625 28.820312 162.683594 28.839844 164.03125 30.585938 C 166.5625 33.859375 176.847656 47.171875 184.152344 61.730469 Z M 184.152344 61.730469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 177.585938 66.210938 C 163.980469 56.035156 151.046875 43.261719 149.199219 40.84375 C 147.351562 38.421875 148.324219 36.988281 149.453125 36.132812 C 150.578125 35.277344 153.101562 35.105469 154.609375 36.738281 C 157.4375 39.804688 168.9375 52.273438 177.585938 66.210938 Z M 177.585938 66.210938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 171.203125 71.421875 C 156.652344 62.382812 142.515625 50.707031 140.4375 48.445312 C 138.355469 46.1875 139.1875 44.679688 140.226562 43.738281 C 141.265625 42.792969 143.757812 42.414062 145.421875 43.921875 C 148.539062 46.746094 161.222656 58.234375 171.203125 71.417969 Z M 171.203125 71.421875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 165.6875 76.769531 C 150.355469 68.867188 135.1875 58.308594 132.90625 56.214844 C 130.625 54.121094 131.316406 52.554688 132.261719 51.535156 C 133.210938 50.519531 135.660156 49.953125 137.457031 51.328125 C 140.824219 53.90625 154.523438 64.386719 165.691406 76.769531 Z M 165.6875 76.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 160.410156 82.875 C 144.359375 76.242188 128.21875 66.949219 125.742188 65.046875 C 123.265625 63.144531 123.796875 61.527344 124.640625 60.4375 C 125.484375 59.347656 127.867188 58.585938 129.792969 59.8125 C 133.402344 62.105469 148.074219 71.445312 160.410156 82.875 Z M 160.410156 82.875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 155.675781 89.492188 C 139.035156 84.203125 122.035156 76.265625 119.375 74.574219 C 116.71875 72.882812 117.089844 71.230469 117.820312 70.074219 C 118.554688 68.917969 120.851562 67.964844 122.890625 69.027344 C 126.710938 71.019531 142.253906 79.117188 155.675781 89.492188 Z M 155.675781 89.492188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 151.746094 96.21875 C 134.65625 92.261719 116.960938 85.699219 114.152344 84.226562 C 111.34375 82.75 111.550781 81.074219 112.167969 79.867188 C 112.789062 78.65625 114.984375 77.527344 117.117188 78.421875 C 121.117188 80.105469 137.378906 86.945312 151.746094 96.21875 Z M 151.746094 96.21875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 148.597656 102.957031 C 131.203125 100.316406 112.960938 95.121094 110.023438 93.863281 C 107.089844 92.609375 107.140625 90.921875 107.644531 89.671875 C 108.152344 88.421875 110.238281 87.125 112.445312 87.855469 C 116.585938 89.230469 133.421875 94.808594 148.597656 102.957031 Z M 148.597656 102.957031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,8.235294%,3.529412%);fill-opacity:1;"
      d="M 145.882812 110.570312 C 128.292969 109.386719 109.601562 105.726562 106.550781 104.71875 C 103.5 103.714844 103.382812 102.03125 103.757812 100.742188 C 104.132812 99.457031 106.082031 97.992188 108.351562 98.539062 C 112.613281 99.558594 129.945312 103.714844 145.878906 110.570312 Z M 145.882812 110.570312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 97.039062 106.140625 L 382.960938 106.140625 L 382.960938 115.5625 L 97.039062 115.5625 Z M 97.039062 106.140625 "
    />
  </g>
</svg>
