<script>
  import { toasts } from "stores/toasts.js";
  import { t } from "stores/i18n.js";
  import { item, getItem } from "stores/confirmation/confirmation_item.js";
  import ConfirmationMailingsApi from "apis/confirmation/confirmation_mailings.js";

  import { Toast } from "components";
  import { Modal } from "components/modals";

  export let shown = false;
  export let permalink;

  function onHide() {
    shown = false;
  }

  function markAsSend(id) {
    ConfirmationMailingsApi.mark_as_sent({
      params: {
        id,
        confirmation_item_id: $item.permalink,
      },
      success: () => {
        onHide();
        getItem();
        toasts.send({
          title: $t("confirmation_mailing_mark_as_send_modal.success"),
          type: "success",
        });
      },
    });
  }
</script>

<Modal
  {shown}
  {onHide}
  title={$t("confirmation_mailing_mark_as_send_modal.title")}
>
  <div data-component="ConfirmationMailingMarkAsSendModal">
    <Toast
      type="error"
      message={$t("confirmation_mailing_mark_as_send_modal.description")}
      action={{
        style: "error",
        click: markAsSend.bind(this, permalink),
        button: $t("confirmation_mailing_mark_as_send_modal.send_button"),
      }}
    />
  </div>
</Modal>
