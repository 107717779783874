<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 0.0585938 L 539.910156 0.0585938 L 539.910156 360.003906 L 0 360.003906 Z M 0 0.0585938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,40%);fill-opacity:1;"
      d="M 0 0 L 163.644531 0 L 163.644531 163.652344 L 0 163.652344 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 0 326.863281 L 539.921875 326.863281 L 539.921875 359.996094 L 0 359.996094 Z M 0 326.863281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 0 327.253906 L 539.921875 327.253906 L 539.921875 360.003906 L 0 360.003906 Z M 0 327.253906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 0 261.929688 L 539.921875 261.929688 L 539.921875 294.417969 L 0 294.417969 Z M 0 261.929688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 0 196.355469 L 538.566406 196.355469 L 538.566406 229.191406 L 0 229.191406 Z M 0 196.355469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 163.597656 0.0390625 L 539.886719 0.0390625 L 539.886719 32.730469 L 163.597656 32.730469 Z M 163.597656 0.0390625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 163.597656 130.824219 L 539.886719 130.824219 L 539.886719 163.726562 L 163.597656 163.726562 Z M 163.597656 130.824219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 163.597656 65.644531 L 539.886719 65.644531 L 539.886719 98.335938 L 163.597656 98.335938 Z M 163.597656 65.644531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 116.964844 124.785156 L 81.308594 103 L 45.828125 125 L 59.023438 89.246094 L 23.605469 67.171875 L 67.425781 66.867188 L 81.015625 31.222656 L 94.898438 66.785156 L 138.714844 66.832031 L 103.480469 89.113281 L 116.96875 124.785156 Z M 116.964844 124.785156 "
    />
  </g>
</svg>
