import { writable, derived, get } from "svelte/store";

const currentUser = writable({});
const showWidget = writable(false);
const showUpload = writable(null); // takes an upload permalink
const editUpload = writable(null); // takes an upload permalink
const addedFiles = writable([]);
const uploadedFiles = writable([]);

export {
  currentUser,
  showWidget,
  addedFiles,
  uploadedFiles,
  showUpload,
  editUpload,
};
