<script>
  import styles from "styleguide/Dragicon.json";
  import { iconPath } from "lib/helpers.js";

  export let onSort = () => {};
</script>

<div
  class={styles.dragicon}
  data-component="Dragicon"
  on:mouseover={onSort.bind(this, true)}
  on:mouseout={onSort.bind(this, false)}
>
  <img class={styles.icon} src={iconPath("dragicon")} alt="dragicon" />
</div>

<style lang="scss">
  .dragicon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-self: center;

    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    user-select: none;

    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }

    .icon {
      cursor: move;
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
    }
  }
</style>
