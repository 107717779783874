import Api from "apis/api";
import AuditApi from "apis/pbc/audit.js";

import { get } from "svelte/store";
import {
  audit,
  controlGroups,
  selectedGroups,
  selectedControls,
} from "stores/pbc.js";

class ControlGroupApi {
  constructor() {
    this.endpoint = "/control_groups";
  }

  controlGroup(params) {
    const formData = new FormData();

    formData.append("name", params.name);
    formData.append("audit_id", get(audit).permalink);

    return formData;
  }

  create(params) {
    Api.post(this.endpoint, {
      body: this.controlGroup(params),
      success: (response) => {
        const group = response.data.control_group;
        group.id = group.permalink;
        controlGroups.update((cgs) => [...cgs, response.data.control_group]);
        // AuditApi.getAudits()
      },
      error: (response) => {
        console.error("ERROR", response);
      },
    });
  }

  edit(options) {
    Api.patch(`${this.endpoint}/${options.params.id}`, {
      body: this.controlGroup(options.params),
      success: options.success,
      error: options.error,
    });
  }

  reorder(params) {
    const formData = new FormData();

    formData.append("position", params.position);

    Api.post(`${this.endpoint}/${params.id}/reorder`, {
      body: formData,
      success: () => {
        AuditApi.getAudits();
      },
      error: (response) => {
        console.error("ERROR", response);
      },
    });
  }

  delete(id) {
    Api.delete(`${this.endpoint}/${id}`, {
      success: () => {
        controlGroups.update((cgs) => cgs.filter((g) => g.permalink !== id));
      },
      error: (response) => {
        console.error("ERROR", response);
      },
    });
  }

  deleteBatch(ids) {
    const formData = new FormData();

    for (let id of ids) {
      formData.append("ids[]", id);
    }

    formData.append("audit_id", get(audit).permalink);

    Api.delete(`${this.endpoint}/batch_delete`, {
      body: formData,
      success: () => {
        AuditApi.getAudits();
        AuditApi.getStatuses();
        selectedGroups.set([]);
        selectedControls.set([]);
      },
      error: (response) => {
        console.error("ERROR", response);
      },
    });
  }
}

export default new ControlGroupApi();
