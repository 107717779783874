<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(7.45098%,17.254902%,46.666667%);fill-opacity:1;"
      d="M 0 0 L 479.996094 0 L 479.996094 180.601562 L 0 180.601562 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(75.294118%,0%,6.666667%);fill-opacity:1;"
      d="M 0 180.601562 L 479.996094 180.601562 L 479.996094 360.011719 L 0 360.011719 Z M 0 180.601562 "
    />
  </g>
</svg>
