import Api from "apis/api";

import { t } from "stores/i18n.js";
import { get } from "svelte/store";
import {
  team_user,
  team_user_createable_roles,
  teamUserRole,
} from "stores/team_user.js";
import { buildFormData } from "lib/helpers.js";
import { toasts } from "stores/toasts.js";

class TeamUsersApi {
  constructor() {
    this.endpoint = "/team_users";
  }

  search(options) {
    Api.get(`${this.endpoint}/search`, options);
  }

  settings(options) {
    Api.patch(`${this.endpoint}/settings`, options);
  }

  deactivateNotifications(options) {
    Api.patch(`${this.endpoint}/deactivate_notifications`, options);
  }

  getTeamUser(permalink) {
    Api.get(`${this.endpoint}/${permalink}`, {
      success: (response) => {
        team_user.set(response);
      },
      error: this.error,
    });
  }

  updateTeamUser(teamUser) {
    const formData = new FormData();
    buildFormData(formData, teamUser);

    Api.patch(`${this.endpoint}/${teamUser.permalink}`, {
      body: formData,
      success: (response) => {
        team_user.set(response);
        toasts.send({
          title: get(t)("success.update_successful"),
          type: "success",
        });
      },
      error: this.error,
    });
  }

  getCreateableAdminRoles(teamUser) {
    Api.get(`${this.endpoint}/${teamUser.permalink}/createable`, {
      success: (response) => {
        team_user_createable_roles.set(response);
      },
      error: this.error,
    });
  }

  getAdminUsersForAccount(teamUser) {
    Api.get(`${this.endpoint}/${teamUser.permalink}/admins`, {
      success: (response) => {
        admin_users.set(response);
      },
      error: this.error,
    });
  }
  changePassword(teamUser) {
    const formData = new FormData();
    buildFormData(formData, teamUser);

    Api.patch(`${this.endpoint}/${teamUser.permalink}/password`, {
      body: formData,
      success: (response) => {
        team_user.set(response);
        window.location = "/login";
        toasts.send({
          title: get(t)("success.update_successful"),
          type: "success",
        });
      },
      error: this.error,
    });
  }

  removeAvatar(teamUser) {
    Api.delete(`${this.endpoint}/${teamUser.permalink}/avatar`, {
      success: (response) => {
        team_user.set(response);
        toasts.send({
          title: get(t)("success.update_successful"),
          type: "success",
        });
      },
      error: this.error,
    });
  }

  error() {
    toasts.send({ title: get(t)("errors.unknown"), type: "error" });
  }
}

export default new TeamUsersApi();
