<script>
  import styles from "styleguide/Styleguide.json";

  import { StyleguideColorItem, StyleguideSection } from "apps/styleguide";

  import {
    App,
    Button,
    ContentSidebar,
    Header,
    ProgressRing,
    Tag,
    Toast,
  } from "components";

  export let appConfig;
</script>

<App {...appConfig} container={true}>
  <ContentSidebar>
    <div slot="content" class={styles.content}>
      <Header headline="Styleguide" type="Internal Resources"></Header>

      <StyleguideSection title="Colors">
        <StyleguideColorItem
          colors={[
            "--primary-010",
            "--primary-025",
            "--primary-050",
            "--primary-100",
            "--primary-200",
            "--primary-300",
            "--primary-400",
            "--primary-500",
          ]}
        />
        <StyleguideColorItem
          colors={[
            "--brand-010",
            "--brand-025",
            "--brand-050",
            "--brand-100",
            "--brand-200",
            "--brand-300",
            "--brand-400",
            "--brand-500",
          ]}
        />
        <StyleguideColorItem
          colors={[
            "--blue-010",
            "--blue-025",
            "--blue-050",
            "--blue-100",
            "--blue-200",
            "--blue-300",
            "--blue-400",
            "--blue-500",
          ]}
        />
        <StyleguideColorItem
          colors={[
            "--red-010",
            "--red-025",
            "--red-050",
            "--red-100",
            "--red-200",
            "--red-300",
            "--red-400",
            "--red-500",
          ]}
        />

        <StyleguideColorItem
          colors={[
            "--green-010",
            "--green-025",
            "--green-050",
            "--green-100",
            "--green-200",
            "--green-300",
            "--green-400",
            "--green-500",
          ]}
        />

        <StyleguideColorItem
          colors={[
            "--yellow-010",
            "--yellow-025",
            "--yellow-050",
            "--yellow-100",
            "--yellow-200",
            "--yellow-300",
            "--yellow-400",
            "--yellow-500",
          ]}
        />

        <StyleguideColorItem
          colors={[
            "--orange-010",
            "--orange-025",
            "--orange-050",
            "--orange-100",
            "--orange-200",
            "--orange-300",
            "--orange-400",
            "--orange-500",
          ]}
        />

        <StyleguideColorItem
          colors={[
            "--violet-010",
            "--violet-025",
            "--violet-050",
            "--violet-100",
            "--violet-200",
            "--violet-300",
            "--violet-400",
            "--violet-500",
          ]}
        />
      </StyleguideSection>

      <StyleguideSection title="Tags">
        <div class={styles.row}>
          <Tag color="grey" label="Label" />
          <Tag color="blue" label="Label" />
          <Tag color="green" label="Label" />
          <Tag color="yellow" label="Label" />
          <Tag color="orange" label="Label" />
          <Tag color="red" label="Label" />
        </div>
        <div class={styles.row}>
          <Tag color="grey" label="Label" style="selected" />
          <Tag color="blue" label="Label" style="selected" />
          <Tag color="green" label="Label" style="selected" />
          <Tag color="yellow" label="Label" style="selected" />
          <Tag color="orange" label="Label" style="selected" />
          <Tag color="red" label="Label" style="selected" />
        </div>
      </StyleguideSection>

      <StyleguideSection title="Buttons">
        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Default State</th>
              <th>Small State</th>
              <th>Disabled State</th>
              <th>Small Disabled State</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Primary</td>
              <td><Button style="primary">Button</Button></td>
              <td><Button style="primary small">Button</Button></td>
              <td><Button style="primary" disabled={true}>Button</Button></td>
              <td>
                <Button style="primary small" disabled={true}>Button</Button>
              </td>
            </tr>
            <tr>
              <td>Primary Tonal</td>
              <td><Button style="primary-tonal">Button</Button></td>
              <td><Button style="primary-tonal small">Button</Button></td>
              <td>
                <Button style="primary-tonal" disabled={true}>Button</Button>
              </td>
              <td>
                <Button style="primary-tonal small" disabled={true}>
                  Button
                </Button>
              </td>
            </tr>
            <tr>
              <td>Primary Text (Default)</td>
              <td><Button style="primary-text">Button</Button></td>
              <td><Button style="primary-text small">Button</Button></td>
              <td>
                <Button style="primary-text" disabled={true}>Button</Button>
              </td>
              <td>
                <Button style="primary-text small" disabled={true}>
                  Button
                </Button>
              </td>
            </tr>
            <tr>
              <td>Error</td>
              <td><Button style="error">Button</Button></td>
              <td><Button style="error small">Button</Button></td>
              <td><Button style="error" disabled={true}>Button</Button></td>
              <td>
                <Button style="error small" disabled={true}>Button</Button>
              </td>
            </tr>
            <tr>
              <td>Error Tonal</td>
              <td><Button style="error-tonal">Button</Button></td>
              <td><Button style="error-tonal small">Button</Button></td>
              <td>
                <Button style="error-tonal" disabled={true}>Button</Button>
              </td>
              <td>
                <Button style="error-tonal small" disabled={true}>
                  Button
                </Button>
              </td>
            </tr>
            <tr>
              <td>Error Text</td>
              <td><Button style="error-text">Button</Button></td>
              <td><Button style="error-text small">Button</Button></td>
              <td>
                <Button style="error-text" disabled={true}>Button</Button>
              </td>
              <td>
                <Button style="error-text small" disabled={true}>Button</Button>
              </td>
            </tr>
          </tbody>
        </table>
      </StyleguideSection>

      <StyleguideSection title="Toasts">
        <div class={styles.row}>
          <Toast title="Hello" message="Lorem Ipsum"></Toast>
          <Toast title="Hello" message="Lorem Ipsum" type="warning"></Toast>
          <Toast title="Hello" message="Lorem Ipsum" type="error"></Toast>
          <Toast title="Hello" message="Lorem Ipsum" type="success"></Toast>
        </div>
      </StyleguideSection>

      <StyleguideSection title="Progress Ring">
        <div class={styles.row}>
          <ProgressRing
            acceptedPercent="25"
            pendingPercent="50"
            rejectedPercent="25"
          />
        </div>
      </StyleguideSection>
    </div>
  </ContentSidebar>
</App>

<style scoped lang="scss">
  .row {
    display: flex;
    gap: 0.5em;
    width: 100%;
    margin: 1em 0;
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
</style>
