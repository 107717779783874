import Api from "apis/api";

class ClientUsers {
  constructor() {
    this.endpoint = "/client_users";
  }

  search(options) {
    const { params, success, error } = options;

    Api.get(`${this.endpoint}/search`, {
      params,
      success: (response) => {
        success(response.data.client_users);
      },
      error,
    });
  }
}

export default new ClientUsers();
