<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(25.098039%,65.098039%,100%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 296.589844 268.023438 L 238.578125 230.425781 L 180.851562 268.394531 L 202.320312 206.691406 L 144.699219 168.589844 L 215.988281 168.058594 L 238.097656 106.542969 L 260.683594 167.917969 L 331.972656 168 L 274.648438 206.457031 L 296.59375 268.023438 Z M 296.589844 268.023438 "
    />
  </g>
</svg>
