import { writable } from "svelte/store";

const isVertical = writable(null);
const isDesktopWidth = writable(null);

function getDeviceData() {
  isDesktopWidth.set(window.innerWidth > 1023);
  isVertical.set(window.innerHeight > window.innerWidth);
}

getDeviceData();
window.addEventListener("resize", getDeviceData);
window.addEventListener("orientationchange", getDeviceData);

export { isVertical, isDesktopWidth };
