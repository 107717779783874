<script>
  import clsx from "clsx";
  import { UserPicture } from "components";
  import styles from "styleguide/UserPictures.json";

  export let style = "";
  export let users = [];
  export let limit = 4;
  export let isAvatarClickable;
  export let ignoreLimit = false;

  $: usersShow =
    users.length > limit && !ignoreLimit
      ? getLimitedUsers(users, limit)
      : users;

  function getLimitedUsers(users, limit) {
    const exceedingUsers = users.length > limit ? users.slice(limit) : [];

    return [
      ...users.slice(0, limit),
      {
        name: `+${exceedingUsers.length}`,
        exceedingUsers: exceedingUsers.map((user) => user.name),
      },
    ];
  }
</script>

<div
  data-component="UserPictures"
  class={clsx(
    styles.wrapper,
    style.split(" ").map((x) => styles[x]),
  )}
>
  {#each usersShow as { name, avatar, exceedingUsers }}
    <div class={styles.item}>
      <UserPicture {name} {avatar} {isAvatarClickable} {exceedingUsers} />
    </div>
  {/each}
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: row-reverse;
  }

  .item {
    margin-left: -8px;

    .separate & {
      margin-left: 8px;
    }

    &.item:last-child {
      margin-left: 0;
    }
  }
</style>
