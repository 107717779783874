import Api from "apis/api";
import { buildFormData } from "lib/helpers";
import { auditTemplates } from "stores/pbc.js";

class AuditTemplateApi {
  constructor() {
    this.endpoint = "/audit_templates";
  }

  create(options) {
    const { params, success, error } = options;

    const formData = new FormData();

    formData.append("audit_id", params.audit_id);
    formData.append("name", params.name);
    if (params.description) formData.append("description", params.description);

    Api.post(this.endpoint, {
      body: formData,
      success,
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error test", response);
        }
      },
    });
  }

  edit(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params);

    Api.patch(`${this.endpoint}/${params.id}`, {
      body: formData,
      success,
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error test", response);
        }
      },
    });
  }

  overwrite(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params);

    Api.post(`${this.endpoint}/${params.id}/overwrite`, {
      body: formData,
      success,
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error test", response);
        }
      },
    });
  }

  import(options) {
    const { params, success, error } = options;

    const formData = new FormData();

    formData.append("audit_id", params.audit_id);

    Api.post(`${this.endpoint}/${params.id}/import`, {
      body: formData,
      success,
      error,
    });
  }

  get(type) {
    let endpoint = this.endpoint;

    if (type === "overwrite") endpoint += "/overwrite";

    Api.get(endpoint, {
      success: (response) => {
        auditTemplates.set(response.data);
      },
      error: (response) => {
        console.error("ERROR", response);
      },
    });
  }

  delete(id) {
    Api.delete(`${this.endpoint}/${id}`, {
      success: () => {
        this.get();
      },
      error: (response) => {
        console.error("ERROR", response);
      },
    });
  }
}

export default new AuditTemplateApi();
