<script>
  import styles from "styleguide/ConfirmationContactsEdit.json";
  import { onMount } from "svelte";

  import { theme } from "stores/theme.js";
  import { t } from "stores/i18n.js";
  import { toasts } from "stores/toasts.js";
  import { can } from "stores/permission.js";
  import {
    confirmation,
    usersByTypes,
    confirmationId,
    typeEdit,
    getConfirmation,
  } from "stores/confirmation/confirmation.js";

  import ConfirmationApi from "apis/confirmation/confirmation.js";
  import ConfirmationClientsApi from "apis/confirmation/confirmation_clients.js";
  import { dateTimeFormat, isDateAfterDate } from "lib/helpers.js";

  import {
    Button,
    Checkbox,
    DateInput,
    Dropdown,
    IconButton,
    TextInput,
    Toast,
    Tooltip,
  } from "components";
  import { Modal } from "components/modals";

  let hasImplausibleDates = false;
  let dates = {};
  let deadlines = ["", "3", "7", "14", "21", "30"];
  $: deadlinesDays = combineDeadlinesDays($t);
  $: list = makeList($usersByTypes, $typeEdit.type);

  const fields = [
    "target_date",
    "deadline",
    "response_period",
    "fiscal_year_beginning_date",
    "fixed_response_period",
    "start_sending_at",
  ];

  const onHide = function () {
    resetDatesValidation();
    typeEdit.set({});
  };

  function combineDeadlinesDays() {
    let obj = {};

    if (!$t("datetime.days")) return obj;

    deadlines.forEach((item) => {
      if (item === "") {
        obj[item] = $t("confirmation_contacts_edit.none");
      } else {
        obj[item] = $t("datetime.days").replace("%{count}", item);
      }
    });

    return obj;
  }

  function makeDates(data, type) {
    let newDates = {};
    let oldDates = { ...data };

    fields.forEach((field) => {
      newDates[`${type}_${field}`] = oldDates[`${type}_${field}`];
    });

    dates = newDates;
  }

  function makeList(data, type) {
    if (!type) return;
    let newList = list && Object.keys(list).length ? list : {};

    if (!newList[type]) {
      newList[type] = {};
    }

    newList[type].items = data[type];
    makeDates($confirmation, type);
    return newList;
  }

  function makeAddActive(type) {
    list[type].active = true;
  }

  function answerCheck(id, value) {
    ConfirmationClientsApi.edit({
      params: {
        id,
        confirmation_id: $confirmationId,
        can_see_answer: value,
      },
      success: () => {
        getConfirmation();
      },
    });
  }

  function manageConfirmationItemsCheck(id, value) {
    ConfirmationClientsApi.edit({
      params: {
        id,
        confirmation_id: $confirmationId,
        can_manage_confirmation_items: value,
      },
      success: () => {
        getConfirmation();
      },
    });
  }

  function addClientContacts(type, email) {
    list[type].active = false;

    ConfirmationClientsApi.create({
      params: {
        email,
        confirmation_id: $confirmationId,
        can_see_answer: false,
        can_manage_confirmation_items: true,
        confirmation_type: type,
      },
      success: () => {
        getConfirmation();
      },
    });
  }

  function deleteClientContacts(id) {
    if (confirm($t("questions.sure"))) {
      ConfirmationClientsApi.delete({
        params: {
          id,
          confirmation_id: $confirmationId,
        },
        success: () => {
          getConfirmation();
        },
      });
    }
  }

  function sendInvitation(id, type) {
    if (confirm($t("questions.sure"))) {
      ConfirmationClientsApi.sendInvitation({
        params: {
          id,
          confirmation_id: $confirmationId,
        },
        success: () => {
          list[type].items = list[type].items.map((item) => {
            if (item.permalink === id) {
              item.can_send_invitation_to = false;
              item.last_invitation_sent_at = new Date().toISOString();
            }
            return item;
          });
        },
      });
    }
  }

  function resetDatesValidation() {
    hasImplausibleDates = false;
  }

  function validatePlausibleDates() {
    resetDatesValidation();

    Object.entries(list).forEach(([type, data]) => {
      const beginningDate = dates[`${type}_fiscal_year_beginning_date`];
      const targetYearAndDate = dates[`${type}_target_date`];
      const deadlineDate = dates[`${type}_deadline`];
      const startSendingAtDate = dates[`${type}_start_sending_at`];
      const responsePeriodDate = dates[`${type}_fixed_response_period`];

      hasImplausibleDates =
        isDateAfterDate(beginningDate, targetYearAndDate) ||
        isDateAfterDate(beginningDate, deadlineDate) ||
        isDateAfterDate(startSendingAtDate, responsePeriodDate);
    });
  }

  function submit() {
    validatePlausibleDates();

    if (hasImplausibleDates) return;

    ConfirmationApi.edit({
      params: {
        ...dates,
        id: $confirmationId,
      },
      success: () => {
        getConfirmation();
        toasts.send({
          title: $t("success.update_successful"),
          type: "success",
        });
      },
    });

    typeEdit.set({});
  }

  onMount(async () => {});
</script>

{#if $typeEdit.type}
  <Modal {onHide} shown={$typeEdit.type} title={$typeEdit.title}>
    <div class={styles.body} data-component="ConfirmationContactsEdit">
      {#each Object.entries(list) as [type, data] (type)}
        <div class={styles.inner}>
          <p class={styles.title}>
            {$t("confirmation_contacts_edit.client_title")}
          </p>
          <p class={styles.subtitle}>
            {$t("confirmation_contacts_edit.client_subtitle")}
          </p>
          {#each data.items as { client_user, can_see_answer, can_manage_confirmation_items, permalink, can_send_invitation_to, last_invitation_sent_at }}
            <div class={styles.client}>
              <div class={styles.item}>
                <div class={styles.input}>
                  <TextInput value={client_user.email} disabled={true} />
                </div>

                <Tooltip showArrow>
                  <div slot="content">
                    <IconButton
                      icon={can_send_invitation_to ? "mail" : "white_mail"}
                      disabled={!can_send_invitation_to}
                      style={"primary-text"}
                      click={sendInvitation.bind(this, permalink, type)}
                    />
                  </div>

                  <div slot="tooltip">
                    {#if can_send_invitation_to}
                      {$t("confirmation_contacts_edit.send_invitation_email")}
                    {:else}
                      {$t(
                        "confirmation_contacts_edit.invitation_sent_at",
                      ).replace(
                        "%{timestamp}",
                        dateTimeFormat(last_invitation_sent_at),
                      )}
                    {/if}
                  </div>
                </Tooltip>

                {#if $can("destroy", "confirmation_client_user")}
                  <IconButton
                    style={"error-text"}
                    icon="trash.svg"
                    click={deleteClientContacts.bind(this, permalink)}
                  />
                {/if}
              </div>

              <Checkbox
                toggled={can_see_answer}
                toggle={answerCheck.bind(this, permalink)}
                label={$t("confirmation_contacts_edit.allowed")}
              />

              <div class={styles.row}>
                <Checkbox
                  toggled={can_manage_confirmation_items}
                  toggle={manageConfirmationItemsCheck.bind(this, permalink)}
                  label={$t(
                    "confirmation_contacts_edit.manage_confirmation_items",
                  )}
                />
              </div>
            </div>
          {/each}

          {#if $can("create", "confirmation_client_user")}
            <div class={styles.add}>
              {#if data.active}
                <TextInput
                  onEnter={addClientContacts.bind(this, type)}
                  onIconClick={addClientContacts.bind(this, type)}
                  style={"border medium round-border fullsize"}
                  enterText={$t("confirmation_contacts_edit.add")}
                  placeholder={$t("confirmation_contacts_edit.client_contact")}
                />
              {:else}
                <Button
                  style={"primary-tonal"}
                  click={makeAddActive.bind(this, type)}
                  >{$t(
                    "confirmation_contacts_edit.add_client_contacts",
                  )}</Button
                >
              {/if}
            </div>
          {/if}
        </div>

        <div class={styles.inner}>
          <p class={styles.title}>
            {$t("confirmation_contacts_edit.detail_title")}
          </p>
          <div class={styles.dateInput}>
            <DateInput
              onSelect={resetDatesValidation}
              bind:value={dates[`${type}_fiscal_year_beginning_date`]}
              label={$t("confirmation.settings.fiscal_year_beginning_date")}
            />
          </div>

          <div class={styles.dateInput}>
            <DateInput
              onSelect={resetDatesValidation}
              bind:value={dates[`${type}_target_date`]}
              label={$t("confirmation.settings.target_date")}
            />
          </div>

          {#if $theme !== "eyde"}
            <div class={styles.dateInput}>
              <DateInput
                onSelect={resetDatesValidation}
                bind:value={dates[`${type}_deadline`]}
                label={$t("confirmation.settings.deadline")}
              />
            </div>
          {/if}

          <div class={styles.dateInput}>
            <Dropdown
              placement="top"
              items={deadlinesDays}
              initial={$t("confirmation_contacts_edit.none")}
              bind:selected={dates[`${type}_response_period`]}
              label={$t("confirmation.settings.response_period")}
            />
          </div>

          <div class={styles.dateInput}>
            <DateInput
              onSelect={resetDatesValidation}
              bind:value={dates[`${type}_fixed_response_period`]}
              label={$t("confirmation.settings.fixed_response_period")}
            />
          </div>

          <div class={styles.dateInput}>
            <DateInput
              onSelect={resetDatesValidation}
              bind:value={dates[`${type}_start_sending_at`]}
              label={$t("confirmation.settings.start_sending_at")}
            />
          </div>

          {#if hasImplausibleDates}
            <Toast
              type="error"
              title={$t("confirmation_contacts_edit.toast_error_title")}
            />
          {/if}
        </div>
      {/each}
    </div>

    <svelte:fragment slot="bottom">
      <Button disabled={hasImplausibleDates} style={"primary"} click={submit}>
        {$t("confirmation_contacts_edit.submit")}
      </Button>
    </svelte:fragment>
  </Modal>
{/if}

<style lang="scss">
  .title {
    font-weight: 600;
    font-size: 16px;
    margin-top: 0;
    color: var(--primary-500);
    margin-bottom: 8px;
    &:first-child {
      margin-top: 0;
    }
  }

  .subtitle {
    font-weight: 400;
    margin-top: 8px;
    margin-bottom: 24px;
    color: var(--primary-300);
  }

  .inner {
    padding: 20px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      .title {
        margin-bottom: 16px;
      }
    }
  }

  .client {
    margin-bottom: 20px;
  }

  .item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
    gap: 8px;
  }

  .dateInput {
    margin-bottom: 20px;
  }

  .input {
    flex: 1;
  }

  .add {
    margin-top: 12px;
  }

  .footnote {
    font-size: 12px;
    line-height: 1.5;
    color: var(--primary-300);
    margin-top: 10px;
  }

  .row {
    display: flex;
    align-items: center;
    height: 22px;
    margin-top: 10px;
  }
</style>
