<script>
  export let width = 18;
  export let height = 19;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 18 19"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M17.7697 15.209L10.4737 3.02797C9.97973 2.21497 8.91973 1.95597 8.10673 2.44897C7.86973 2.59297 7.67073 2.79197 7.52773 3.02797L0.231731 15.209C-0.244269 16.032 0.0377306 17.086 0.861731 17.563C1.11773 17.71 1.40773 17.789 1.70373 17.793H16.2957C17.2477 17.782 18.0107 17.003 17.9997 16.051C17.9967 15.755 17.9167 15.465 17.7697 15.209ZM9.00873 15.424C8.41373 15.424 7.92773 14.943 7.92773 14.348C7.92773 13.753 8.40473 13.271 8.99973 13.271H9.00873C9.60373 13.271 10.0857 13.753 10.0857 14.348C10.0857 14.943 9.60373 15.424 9.00873 15.424ZM8.99973 6.59497C8.52373 6.59497 8.13873 6.98097 8.13873 7.45597V10.903C8.13873 11.378 8.52373 11.764 8.99973 11.764C9.47573 11.764 9.86173 11.378 9.86173 10.903V7.45597C9.86173 6.98097 9.47573 6.59497 8.99973 6.59497Z"
  />
</svg>
