import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  // f.input …, …, input_html: { data: { controller: "cleave"}
  // automatically formats input to match date "YYYY-MM-DD"

  connect() {
    this.cleave = new Cleave(this.element, {
      date: true,
      delimiter: "-",
      datePattern: ["Y", "m", "d"],
    });
  }

  disconnect() {
    this.cleave.destroy();
  }
}
