import Api from "apis/api";
import { ipWhitelist, currentIpAddress } from "stores/account.js";
import { toasts } from "stores/toasts.js";
import { t } from "stores/i18n.js";
import { get } from "svelte/store";
import { buildFormData } from "lib/helpers.js";

class AllowedIpAddressesApi {
  constructor() {
    this.endpoint = "/allowed_ip_addresses";
  }

  getAllowedIpList() {
    Api.get(`${this.endpoint}`, {
      success: (response) => {
        ipWhitelist.set(response.data.allowed_ip_addresses);
        currentIpAddress.set(response.data.current_ip_address);
      },
      error: this.error,
    });
  }

  addAllowedIpToList({ data, successCallback, errorCallback }) {
    const formData = new FormData();
    buildFormData(formData, data);

    Api.post(`${this.endpoint}`, {
      body: formData,
      success: (response) => {
        toasts.send({
          title: get(t)("success.update_successful"),
          type: "success",
        });
        ipWhitelist.update((currentIpAddress) => [
          ...currentIpAddress,
          response.data,
        ]);
        successCallback();
      },
      error: (response) => {
        errorCallback(response.errors);
      },
    });
  }

  deleteAllowedIpToList(permalink) {
    Api.delete(`${this.endpoint}/${permalink}`, {
      success: (response) => {
        if (response.code === "success") {
          toasts.send({
            title: get(t)("success.update_successful"),
            type: "success",
          });
          ipWhitelist.update((currentIpAddress) => [
            ...currentIpAddress.filter((item) => item.permalink !== permalink),
          ]);
        }
      },
      error: this.error,
    });
  }

  error() {
    toasts.send({ title: get(t)("errors.unknown"), type: "error" });
  }
}

export default new AllowedIpAddressesApi();
