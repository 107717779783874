<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(0%,49.803922%,100%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(96.862745%,83.921569%,9.411765%);fill-opacity:1;"
      d="M 21.601562 72 L 72 72 L 87.601562 21.601562 L 103.199219 72 L 153.601562 72 L 112.800781 103.199219 L 128.398438 153.601562 L 87.601562 122.398438 L 46.800781 153.601562 L 62.398438 103.199219 Z M 450 0 L 0 270 L 0 360 L 30 360 L 480 90 L 480 0 L 450 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80.784314%,6.27451%,12.941176%);fill-opacity:1;"
      d="M 480 0 L 0 288 L 0 360 L 480 72 L 480 0 "
    />
  </g>
</svg>
