<script>
  export let width = 18;
  export let height = 18;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 18 18"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M9.87472739,0.751208023 L9.4165,0.750045819 C8.20043608,0.746783461 6.99887701,1.0313557 5.91136568,1.58064859 C3.26091699,2.90531303 1.58460582,5.61604891 1.58350005,8.58282046 L1.58971576,8.91436703 C1.62467892,9.78326318 1.80612804,10.6394847 2.12559187,11.4465866 L2.276,11.801 L0.788017489,16.2628393 L0.762281457,16.3601708 C0.65456047,16.9088159 1.18284941,17.3961807 1.73662589,17.2116275 L6.199,15.723 L6.51631738,15.8598264 C7.43795524,16.2296301 8.42323657,16.4198117 9.41854545,16.4170972 C12.3832416,16.4159943 15.0941485,14.7389602 16.4203763,12.0853983 C16.9682193,11.0018799 17.2528164,9.80021611 17.2495515,8.5810741 L17.2495515,8.1671 C17.0293385,4.14692237 13.8533242,0.970242074 9.87472739,0.751208023 Z M9.41447226,2.25007129 L9.818,2.25 L9.79227261,2.24894005 C13.0072548,2.42593475 15.5736383,4.99285652 15.75066,8.20832739 L15.749526,8.5831 C15.7521832,9.56784562 15.522843,10.5361968 15.0801873,11.4116911 C14.0063084,13.5603265 11.8145442,14.9162062 9.41622046,14.9171001 C8.43421506,14.9197762 7.46539076,14.6901019 6.58709083,14.2473744 L6.47702056,14.2024398 C6.32667921,14.154576 6.16397052,14.1550511 6.01237411,14.2055725 L2.685,15.314 L3.79498251,11.9873607 C3.85828636,11.7975291 3.843104,11.5902714 3.75281267,11.4116911 C3.3101528,10.5361885 3.08081956,9.56784197 3.08349722,8.58514359 C3.084394,6.18465065 4.43963616,3.9931103 6.58479833,2.92097631 C7.46424646,2.47678193 8.43270219,2.24741691 9.41447226,2.25007129 Z"
  />
</svg>
