<script>
  import { t } from "stores/i18n.js";

  import { DashboardStatusOverview } from "apps/dashboard";
  import { Boundary, Tag } from "components";

  export let count;
  export let type;
  export let status;
  export let click;
  export let selected;

  $: label =
    status &&
    $t("dashboard_status." + status, { postProcess: "interval", count });
  $: statusColor = status && calculateStatusColor(status);

  function calculateStatusColor(newStatus) {
    switch (newStatus) {
      case "active":
        return "green";
      case "inactive":
        return "yellow";
      case "all_active_count":
        return "green";
      case "all_inactive_count":
        return "yellow";
      case "my_active_count":
        return "blue";
      case "unseen_documents_count":
        return "blue";
      case "unseen_comments_count":
        return "blue";
      case "overdue_controls_count":
        return "red";
      case "waiting_for_review_controls_count":
        return "yellow";
      case "due_date_controls_count":
        return "yellow";
      case "new_responses_count":
        return "blue";
      case "waiting_confirmation_count":
        return "yellow";
      case "bounced_mailings_count":
        return "yellow";
      default:
        return "grey";
    }
  }
</script>

<Boundary>
  <div data-component="DashboardStatus">
    {#if type === "overview"}
      <DashboardStatusOverview
        {label}
        {count}
        {selected}
        color={statusColor}
        {click}
      />
    {:else if type === "group"}
      <Tag {label} style="fullsize" color={statusColor} size="medium" />
    {:else if type === "filter"}
      <Tag
        close={click}
        label="{count} {label}"
        color={statusColor}
        size="medium"
      />
    {:else if type === "count"}
      <Tag label={count} color={statusColor} style="count" size="medium" />
    {:else}
      <Tag label="{count} {label}" color={statusColor} size="small" />
    {/if}
  </div>
</Boundary>
