<script>
  import styles from "styleguide/DashboardProjectItem.json";

  import { t } from "stores/i18n.js";
  import { can } from "stores/permission.js";
  import { isUserTeamUser } from "stores/user.js";

  import { iconPath } from "lib/helpers.js";

  import Audits from "apis/dashboard/audits.js";
  import Reviews from "apis/dashboard/reviews.js";
  import Confirmations from "apis/dashboard/confirmations.js";

  import { DashboardStatus } from "apps/dashboard";
  import { Boundary, Button, ProgressRing, Tag, Tooltip } from "components";

  export let name;
  export let type;
  export let statuses = [];
  export let total_count;
  export let total_completed_count;
  export let statuses_count;
  export let path;
  export let archived;
  export let permalink;
  export let notification_activated;

  let total_accepted_count = 0;
  let total_pending_count = 0;
  let total_rejected_count = 0;

  const cornfirmationItemPendingStates = [
    "waiting_confirmation",
    "waiting_signatures",
    "ready_to_send",
    "sent",
    "reminded",
    "received",
    "not_delivered",
  ];

  $: progressCompleted = getProgress(total_completed_count, total_count);
  $: progressAccepted = getProgress(total_accepted_count, total_count);
  $: progressPending = getProgress(total_pending_count, total_count);
  $: progressRejected = getProgress(total_rejected_count, total_count);

  $: anyStatusPresent = checkStatusPresent(statuses);
  $: notificationStatus = notification_activated ? "off" : "deactivate";

  $: if (statuses_count) {
    for (const [status, value] of Object.entries(statuses_count)) {
      if (cornfirmationItemPendingStates.includes(status)) {
        total_pending_count += value;
      }
    }
    total_rejected_count = statuses_count["rejected"] || 0;
    total_accepted_count = statuses_count["accepted"] || 0;
  } else {
    total_accepted_count = total_completed_count;
  }

  function goToItem(event) {
    event.preventDefault();
    window.location = path;
  }

  function checkStatusPresent(stats) {
    return stats.filter((x) => x.count > 0).length > 0;
  }

  function getProgress(completed, total) {
    if (isNaN(completed) || isNaN(total) || total === 0) {
      return 0;
    } else {
      return Math.floor((completed / total) * 100);
    }
  }

  function unarchive(id, event) {
    event.preventDefault();
    event.stopPropagation();

    let formData = new FormData();

    formData.append("id", id);
    formData.append("archived", false);

    const params = {
      params: formData,
      success: unarchiveSuccess,
      error: unarchiveError,
    };

    if (type === "audits") {
      Audits.archive(params, id);
    } else if (type === "reviews") {
      Reviews.archive(params, id);
    } else if (type == "confirmations") {
      Confirmations.archive(params, id);
    }
  }

  function unarchiveSuccess() {
    archived = false;
  }

  function unarchiveError() {
    alert("Projects archive Error");
  }

  function selectProjectUnitType(type) {
    switch (type) {
      case "audits":
        return "Audit";
      case "reviews":
        return "Review";
      case "confirmations":
        return "Confirmation";
    }
  }
</script>

<Boundary>
  <a
    data-component="DashboardProjectItem"
    href={path}
    class={styles.wrapper}
    on:click={goToItem}
  >
    <div class={styles.inner}>
      <div>
        {#if archived}
          <Tooltip showArrow={true}>
            <div slot="content" class={styles.tooltip}>
              <img
                src={iconPath("archive-box")}
                alt={$t("dashboard_group_item.archived")}
              />
            </div>

            <div slot="tooltip">
              <span class={styles.clarification}>
                <div>{$t("dashboard_group_item.archived")}</div>
              </span>
            </div>
          </Tooltip>
        {:else}
          <Tooltip showArrow={true}>
            <div slot="content" class={styles.tooltip}>
              <ProgressRing
                size="42"
                stroke="4"
                completedPercent={progressCompleted}
                acceptedPercent={progressAccepted}
                pendingPercent={progressPending}
                rejectedPercent={progressRejected}
                showPercent={true}
              />
            </div>

            <div slot="tooltip">
              <span class={styles.clarification}>
                <div>{total_count} {$t("dashboard_group_item.selected")}</div>
                <div>
                  {total_completed_count}
                  {$t("dashboard_group_item.rated")}
                </div>
                {#if statuses_count && Object.keys(statuses_count).length}
                  <div class={styles["status-group"]}>
                    {#if statuses_count["accepted"]}
                      <div class={styles["status-accepted"]}>
                        {statuses_count["accepted"]}
                        {$t("dashboard_group_item.accepted", {
                          postProcess: "interval",
                          count: statuses_count["accepted"],
                        })}
                      </div>
                    {/if}
                    {#each cornfirmationItemPendingStates as state}
                      {#if statuses_count[state]}
                        <div class={styles["status-pending"]}>
                          {statuses_count[state]}
                          {$t(`dashboard_group_item.${state}`, {
                            postProcess: "interval",
                            count: statuses_count[state],
                          })}
                        </div>
                      {/if}
                    {/each}
                    {#if statuses_count["rejected"]}
                      <div class={styles["status-rejected"]}>
                        {statuses_count["rejected"]}
                        {$t("dashboard_group_item.rejected", {
                          postProcess: "interval",
                          count: statuses_count["rejected"],
                        })}
                      </div>
                    {/if}
                  </div>
                {/if}
              </span>
            </div>
          </Tooltip>
        {/if}
      </div>
      <div class={styles.info}>
        <div class={styles.title}>
          <div class={styles.type}>{$t(`products.${type}`)}</div>
          <div class={styles.name}>{name}</div>
        </div>
        {#if anyStatusPresent}
          <div class={styles.statuses}>
            {#each statuses as { status, count }}
              {#if count > 0}
                <div class={styles.status}>
                  <DashboardStatus {status} {count} />
                </div>
              {/if}
            {/each}
          </div>
        {:else}
          <Tag
            color="green"
            size="small"
            label={$t("dashboard_group_item.no_statuses")}
          />
        {/if}
      </div>
    </div>

    <div>
      {#if $can("archive", "project")}
        {#if archived}
          <Button
            click={(event) => {
              unarchive(permalink, event);
            }}
            style="primary-text small"
          >
            {$t("dashboard_group_item.unarchive")}
          </Button>
        {/if}
      {/if}
      {#if $isUserTeamUser && !archived && notificationStatus != "deactivate"}
        <Tooltip showArrow>
          <div slot="content">
            <img
              alt="notifications"
              class={styles["notification-icon"]}
              src={iconPath(`notification-${notificationStatus}`)}
            />
          </div>
          <div slot="tooltip" class={styles["notification-tooltip"]}>
            {$t(`dashboard_group_item.notifications_${notificationStatus}`)}
          </div>
        </Tooltip>
      {/if}
    </div>
  </a>
</Boundary>

<style lang="scss">
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 2px;
  }

  .inner {
    display: flex;
    align-items: center;
  }

  .tooltip {
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .info {
    min-height: 50px;
    margin-left: 35px;
  }

  .title {
    display: flex;
    font-weight: 500;
    font-size: 14px;
    gap: 10px;
  }

  .type {
    color: var(--primary-300);
  }

  .name {
    color: var(--blue-200);
  }

  .statuses {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
  }

  .status {
    margin: 0 10px 5px 0;
  }

  .clarification {
    font-weight: 500;
    white-space: nowrap;
  }

  .notification {
    margin-left: 20px;
  }

  .status-group {
    border-top: 1px solid var(--primary-050);
    margin-top: 5px;
    padding-top: 5px;
  }
  .status-accepted {
    color: var(--green-500);
  }

  .status-pending {
    color: var(--orange-400);
  }

  .status-rejected {
    color: var(--red-100);
  }
  .notification-icon {
    filter: saturate(1) brightness(0.7);
  }
  .notification-tooltip {
    color: var(--primary-500);
  }
</style>
