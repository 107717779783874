<script>
  import { onDestroy, onMount } from "svelte";

  import documentViewerStore from "stores/document_viewer_store.js";
  import { t } from "stores/i18n.js";
  import { downloadFile } from "lib/helpers.js";
  import { imagePath, fixCarrierWaveUrl } from "lib/helpers.js";

  import { LegacyButton as Button } from "components";
  import styles from "styleguide/DocumentThumbnails.json";

  export let documentUrl;
  export let documentId;
  export let documentTitle;
  export let fixUrl = false;
  export let addWatermarks = false;
  export let showOnlyFirstPage = false;
  export let isLoadingThumbnails = true;

  let generatedThumbnails = [];
  let totalPageCount = 0;
  let thumbnailContainer;
  let MAX_PREVIEW_PAGES = 5;

  let unsubscribe;

  const thumbnailStyle = {
    height: "auto",
    border: "solid 1px var(--primary-050)",
    borderRadius: "3px",
    boxShadow: "0 3px 8px 0 rgba(41, 49, 61, 0.06)",
    cursor: "pointer",
    flex: 1,
    maxWidth: showOnlyFirstPage ? "100%" : "calc((100% - 32px) / 3)",
  };

  $: documentUrl = fixUrl ? fixCarrierWaveUrl(documentUrl) : documentUrl;

  documentViewerStore.generateThumbnails(
    documentId,
    documentUrl,
    [],
    addWatermarks,
  );

  onMount(() => {
    unsubscribe = documentViewerStore.thumbnailsReadable.subscribe((data) => {
      const filteredData = data.filter((item) => item.id === documentId)[0];
      generatedThumbnails = filteredData ? filteredData.thumbnails : [];
      totalPageCount = filteredData ? filteredData.pageCount : 0;
      if (
        generatedThumbnails.length ===
          Math.min(totalPageCount, MAX_PREVIEW_PAGES) &&
        generatedThumbnails.length > 0 &&
        isLoadingThumbnails
      ) {
        isLoadingThumbnails = false;
        drawThumbnails();
      }
    });
  });

  function drawThumbnails() {
    generatedThumbnails.forEach((thumbnail, index) => {
      if (index > 0 && showOnlyFirstPage) {
        return false;
      }
      thumbnail.addEventListener("click", () => {
        documentViewerStore.openDocument({
          title: documentTitle,
          url: documentUrl,
          page: index + 1,
          watermarks: addWatermarks,
        });
      });
      Object.assign(thumbnail.style, thumbnailStyle);
      thumbnailContainer.insertBefore(
        thumbnail,
        thumbnailContainer.querySelector("div"),
      );
    });
  }

  function downloadPdf(documentUrl) {
    downloadFile({ url: documentUrl, filename: documentTitle }, null, true);
  }

  onDestroy(() => {
    if (unsubscribe) {
      unsubscribe();
    }
  });
</script>

<div class={styles.wrapper}>
  <div bind:this={thumbnailContainer} class={styles.thumbnails}>
    {#if isLoadingThumbnails}
      <img class={styles.loading} src={imagePath("loading-spinner")} alt={""} />
    {:else if totalPageCount > MAX_PREVIEW_PAGES && !showOnlyFirstPage}
      <div class={styles.truncated}>
        <div class={styles.title}>{$t("document_thumbnails.title")}</div>
        <div class={styles.body}>
          {$t("document_thumbnails.show_first_pages") &&
            $t("document_thumbnails.show_first_pages").replace(
              "%{number}",
              MAX_PREVIEW_PAGES,
            )}
          <Button
            click={downloadPdf.bind(this, documentUrl)}
            style={"no-padding height-auto"}
          >
            {$t("document_thumbnails.download_link_title")}
          </Button>
          {$t("document_thumbnails.download_text")}
        </div>
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  .wrapper {
    width: 100%;
    margin-bottom: 1em;
  }

  .thumbnails {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  .truncated {
    width: calc((100% - 32px) / 3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 3px;
    box-shadow: 0 3px 8px 0 rgba(41, 49, 61, 0.06);
    border: solid 1px var(--primary-050);
    padding: 5%;
  }

  .title {
    font-weight: 600;
  }

  .body {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--primary-300);
  }
</style>
