<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(0%,12.941176%,43.921569%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,69.803922%,5.098039%);fill-opacity:1;"
      d="M -60 164.609375 L 660 164.609375 L 660 195.394531 L -60 195.394531 Z M -60 164.609375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 152.210938 307.960938 L 129.066406 284.777344 L 121.609375 316.675781 L 113.167969 285.023438 L 90.753906 308.914062 L 99.269531 277.28125 L 67.914062 286.773438 L 91.097656 263.632812 L 59.203125 256.171875 L 90.851562 247.726562 L 66.957031 225.316406 L 98.589844 233.828125 L 89.105469 202.476562 L 112.242188 225.660156 L 119.703125 193.761719 L 128.148438 225.414062 L 150.554688 201.523438 L 142.046875 233.15625 L 173.398438 223.664062 L 150.210938 246.804688 L 182.105469 254.265625 L 150.457031 262.710938 L 174.351562 285.121094 L 142.71875 276.609375 Z M 152.210938 307.960938 "
    />
  </g>
</svg>
