import { writable, derived } from "svelte/store";

const permissions = writable({
  account: {},
  attachment: {},
  audit: {},
  audit_export: {},
  audit_template: {},
  client: {},
  client_user: {},
  client_users_control: {},
  confirmation: {},
  confirmation_client_user: {},
  confirmation_export: {},
  confirmation_item: {},
  confirmation_mailing: {},
  confirmation_response: {},
  control: {},
  control_group: {},
  delivery_note: {},
  export: {},
  project: {},
  review: {},
  team_user: {},
});

const can = derived(permissions, ($permissions) => {
  return (action, resource) => {
    if ($permissions[resource] && $permissions[resource][action]) {
      return $permissions[resource][action] === true;
    }
    return false;
  };
});

export { permissions, can };
