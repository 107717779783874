import Api from "apis/api";
import { buildFormData } from "../../lib/helpers";

class ControlAccessApi {
  constructor() {
    this.endpoint = "/controls";
  }

  /**
   * Updates teams in bulk using the provided options.
   *
   * @param {Object} options - The options for updating teams.
   * @param {Object} options.params - The parameters for the update operation.
   * @param {Function} options.success - The success callback function to be called on a successful update.
   * @param {Function} options.error - The error callback function to be called if the update encounters an error.
   */
  updateTeams(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params, null, true);

    Api.post(this.endpoint + "/update_teams_in_bulk", {
      body: formData,
      success,
      error,
    });
  }

  /**
   * Updates multiple users in bulk based on the specified options.
   *
   * @param {Object} options - The options for the user update.
   * @param {Object} options.params - The parameters containing information about the users to be updated.
   * @param {Function} options.success - The callback function to be executed on a successful update.
   * @param {Function} options.error - The callback function to be executed if the update encounters an error.
   */
  updateUsers(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params, null, true);

    Api.post(this.endpoint + "/update_client_users_in_bulk", {
      body: formData,
      success,
      error,
    });
  }

  /**
   * Adds a new client user to control.
   *
   * @param {Object} options - The options for adding a new user.
   * @param {Object} options.params - The parameters for the new user.
   * @param {Function} options.success - The callback function to be executed on a successful request.
   * @param {Function} options.error - The callback function to be executed on an error.
   */
  addNewUser(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params, null, true);

    Api.post(`${this.endpoint}/client_users`, {
      body: formData,
      success,
      error,
    });
  }
}

export default new ControlAccessApi();
