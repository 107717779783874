<script>
  import {
    getSampleStatusCalculateLabel,
    getSampleStatusTagColor,
  } from "apps/sample/helpers";
  import { Tag } from "components";

  export let label;
  export let status;
  export let close = null;
  export let size = "small";
  export let style = "semi-bold";
  export let timeDistance;

  $: tagLabel = getSampleStatusCalculateLabel(label, status, timeDistance);
  $: statusColor = getSampleStatusTagColor(status && status.toLowerCase());
</script>

<span data-component="SampleStatus">
  <Tag {style} {close} label={tagLabel} color={statusColor} {size} />
</span>
