import { Controller } from "stimulus";
import "select2";

export default class extends Controller {
  static targets = [];

  connect() {
    this.initializeSelectize();
  }

  initializeSelectize() {
    $(this.element).select2(
      Object.assign(
        {
          minimumResultsForSearch: -1,
          width: "resolve",
          dropdownParent: this.element.parentNode,
        },
        this.dynamicOptions(),
      ),
    );
  }

  setPlaceholder() {
    if (this.element.getAttribute("data-formtastic")) {
      return this.element.closest(".fragment").querySelector("label")
        .textContent;
    } else {
      return this.element.getAttribute("data-placeholder") || "";
    }
  }

  dynamicOptions() {
    let options = {};
    if (this.element.getAttribute("data-no-placeholder") !== "") {
      options["placeholder"] = this.setPlaceholder();
    }
    return options;
  }
}
