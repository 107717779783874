<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0.0117188 0 L 483.402344 0 L 483.402344 240.074219 L 0.0117188 240.074219 Z M 0.0117188 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 0.0117188 0 L -0.0078125 18.019531 L 115.347656 77.289062 L 158.753906 78.546875 Z M 0.0117188 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,40%);fill-opacity:1;"
      d="M 49.402344 0 L 189.257812 73.050781 L 189.257812 0 Z M 49.402344 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 207.910156 0 L 207.910156 93.253906 L 0.0195312 93.253906 L 0.0195312 146.542969 L 207.910156 146.542969 L 207.910156 239.796875 L 271.878906 239.796875 L 271.878906 146.542969 L 479.765625 146.542969 L 479.765625 93.253906 L 271.878906 93.253906 L 271.878906 0 Z M 207.910156 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,40%);fill-opacity:1;"
      d="M 290.527344 0 L 290.527344 68.832031 L 427.34375 0.535156 Z M 290.527344 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 294.851562 76.335938 L 333.328125 76.027344 L 482.808594 0.535156 L 443.144531 1.179688 Z M 294.851562 76.335938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,40%);fill-opacity:1;"
      d="M 0.0117188 162.082031 L 0.0117188 213.148438 L 95.964844 162.617188 L 0.0117188 162.078125 Z M 0.0117188 162.082031 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 369.496094 164.191406 L 329.5 163.878906 L 480.277344 238.949219 L 479.285156 221.777344 Z M 37.476562 240.21875 L 178.859375 165.992188 L 141.722656 166.242188 L 0.0117188 240.113281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,40%);fill-opacity:1;"
      d="M 481.792969 21.089844 L 367.609375 77.710938 L 480.273438 78.132812 L 480.273438 162.507812 L 384.324219 162.507812 L 479.386719 214.015625 L 480.78125 240.21875 L 430.003906 239.613281 L 291.03125 171.386719 L 291.03125 240.21875 L 189.746094 240.21875 L 189.746094 171.386719 L 59.386719 240.035156 L 0.511719 240.21875 L 0.511719 480.011719 L 960.023438 480.011719 L 960.023438 0.417969 L 482.804688 0 M 0.519531 27.066406 L 0.0117188 76.867188 L 101.027344 78.132812 L 0.527344 27.066406 Z M 0.519531 27.066406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,85.098039%,0%);fill-opacity:1;"
      d="M 0 240.007812 L 506.554688 240.007812 L 506.554688 480.011719 L 0 480.011719 Z M 0 240.007812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,40.392157%);fill-opacity:1;"
      d="M 281.488281 121.238281 C 281.488281 144.09375 262.960938 162.621094 240.105469 162.621094 C 217.25 162.621094 198.722656 144.09375 198.722656 121.238281 C 198.722656 98.382812 217.25 79.855469 240.105469 79.855469 C 262.960938 79.855469 281.488281 98.382812 281.488281 121.238281 Z M 281.488281 121.238281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,95.686275%,5.098039%);fill-opacity:1;"
      d="M 232.417969 110.621094 L 240.378906 86.824219 L 248.335938 110.621094 L 274.160156 110.605469 L 253.253906 125.289062 L 261.253906 149.074219 L 240.375 134.351562 L 219.496094 149.070312 L 227.496094 125.289062 L 206.597656 110.597656 Z M 232.417969 110.621094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,95.686275%,5.098039%);fill-opacity:1;"
      d="M 376.160156 114.519531 L 380.105469 103.378906 L 384.050781 114.519531 L 396.847656 114.511719 L 386.488281 121.382812 L 390.453125 132.519531 L 380.105469 125.628906 L 369.757812 132.519531 L 373.722656 121.382812 L 363.363281 114.507812 Z M 376.160156 114.519531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,95.686275%,5.098039%);fill-opacity:1;"
      d="M 236.160156 197.292969 L 240.105469 186.152344 L 244.050781 197.292969 L 256.847656 197.28125 L 246.488281 204.15625 L 250.453125 215.292969 L 240.105469 208.402344 L 229.757812 215.289062 L 233.722656 204.15625 L 223.363281 197.28125 Z M 236.160156 197.292969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,95.686275%,5.098039%);fill-opacity:1;"
      d="M 236.160156 35.644531 L 240.105469 24.503906 L 244.050781 35.644531 L 256.847656 35.636719 L 246.488281 42.511719 L 250.453125 53.648438 L 240.105469 46.753906 L 229.757812 53.644531 L 233.722656 42.511719 L 223.363281 35.632812 Z M 236.160156 35.644531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,95.686275%,5.098039%);fill-opacity:1;"
      d="M 95.730469 114.519531 L 99.671875 103.378906 L 103.617188 114.519531 L 116.414062 114.511719 L 106.054688 121.382812 L 110.019531 132.519531 L 99.671875 125.628906 L 89.324219 132.519531 L 93.289062 121.382812 L 82.933594 114.507812 Z M 95.730469 114.519531 "
    />
  </g>
</svg>
