import { comments, updateComments } from "apps/sample/stores";
import CommentsApi from "apis/comments/comments.js";

class CommentsService {
  constructor() {}

  getComments({ params = {} }) {
    CommentsApi.getComments({
      params,
      success: (responce) => {
        comments.set(responce);
      },
    });
  }

  onPostComment({ params = {} }) {
    CommentsApi.create({
      params,
      success: ({ comment }) => {
        updateComments(comment);
      },
    });
  }

  onMarkAsRead({ onSuccess, onError, params = {} }) {
    CommentsApi.markAsRead({
      params,
      success: (responce) => {
        onSuccess?.(responce);
      },
      error: (error) => {
        onError?.(error);
      },
    });
  }
}

export default new CommentsService();
