<script>
  import styles from "styleguide/FeatureItem.json";
  import Icons from "components/icons/Icons.svelte";
  import { Button } from "components";

  export let onClick;
  export let title;
  export let icon;
  export let description;
  export let buttonText;
</script>

<div data-component="FeatureItem" class={styles["feature-item"]}>
  <div class={styles["item-title"]}>
    <Icons {icon} width="20" height="20" />
    {title}
  </div>
  <div class={styles["item-description"]}>
    {description}
  </div>
  {#if buttonText}
    <div class={styles["item-button-wrapper"]}>
      <Button style="primary-tonal small" click={onClick}>
        {buttonText}
      </Button>
    </div>
  {/if}
</div>

<style lang="scss">
  .feature-item {
    display: flex;
    flex-direction: column;
    color: var(--primary-500);
    flex: 1 1 50%;

    .item-title {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 1;
      gap: 12px;
      margin-bottom: 8px;
    }

    .item-description {
      font-size: 12px;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 8px;
    }

    .item-button-wrapper {
      justify-content: flex-start;
    }
  }
</style>
