import { get } from "svelte/store";
import { writable, derived } from "svelte/store";

const showWidget = writable(false);
const notifications = writable([]);
const notificationsCount = writable(0);
const notificationsInterval = writable("daily");

const filteredNotifications = derived(
  [notifications],
  ([$notifications], set) => {
    let filtered = $notifications.filter((n) => !n.read);
    set(filtered);
  },
);

const filteredNotificationsCount = derived(
  [notifications],
  ([$notifications], set) => {
    let filtered = $notifications.filter((n) => !n.read);
    set(filtered.length);
  },
);

function webSocketUrl() {
  const protocol = window.location.protocol === "https:" ? "wss://" : "ws://";
  return `${protocol}${window.location.host}/cable`;
}

function addNewNotification(newNotification) {
  let oldNotifications = get(notifications).filter(
    (item) => item.permalink !== newNotification.permalink,
  );
  notifications.set([newNotification, ...oldNotifications]);
  notificationsCount.set(get(notificationsCount) + 1);
}

export {
  showWidget,
  notifications,
  notificationsCount,
  notificationsInterval,
  filteredNotifications,
  filteredNotificationsCount,
  addNewNotification,
  webSocketUrl,
};
