<script>
  import { Modal } from "components/modals";
  import { sandboxDelete } from "stores/dashboard/sandbox.js";
  import { t } from "stores/i18n.js";
  import { Button } from "components";
  import styles from "styleguide/SandboxDeleteModal.json";

  export let onDelete = () => {};

  function onHide() {
    sandboxDelete.set(false);
  }
</script>

{#if $sandboxDelete}
  <Modal
    shown={$sandboxDelete}
    {onHide}
    title={$t("sandbox_delete_modal.delete_title")}
    style="error"
  >
    <div class={styles.wrapper} data-component="SandboxDeleteModal">
      <div class={styles.confirmation}>
        <Button style={"error"} click={onDelete}
          >{$t("sandbox_delete_modal.delete_button")}</Button
        >
      </div>
    </div>
  </Modal>
{/if}

<style lang="scss">
  .wrapper {
    padding-bottom: 16px;
  }

  .description {
    color: var(--primary-500);
    margin-bottom: 16px;
  }

  .confirmation {
    display: flex;
  }

  .input {
    width: 150px;
    margin-right: 12px;
  }
  p {
    margin-top: 0;
  }
</style>
