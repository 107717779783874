<script>
  export let width = 20;
  export let height = 20;
  export let fill = "#052861";
</script>

<svg
  {width}
  {height}
  viewBox={`0 0 ${width} ${height}`}
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M-0.000305176 9.99988C-0.000305176 4.47742 4.47724 -0.00012207 9.99969 -0.00012207C15.5229 -0.00012207 19.9997 4.47705 19.9997 9.99988C19.9997 15.5234 15.5233 19.9999 9.99969 19.9999C4.47687 19.9999 -0.000305176 15.5231 -0.000305176 9.99988ZM18.333 9.99987C18.333 5.39748 14.6024 1.66653 9.99966 1.66653C5.39768 1.66653 1.66633 5.39788 1.66633 9.99987C1.66633 14.6026 5.39728 18.3332 9.99966 18.3332C14.6028 18.3332 18.333 14.603 18.333 9.99987ZM9.99966 8.88909C10.5695 8.88909 11.0391 9.31802 11.1033 9.87062L11.1108 10.0002V13.6669C11.1108 14.2805 10.6133 14.778 9.99966 14.778C9.42984 14.778 8.96021 14.349 8.89603 13.7964L8.88855 13.6669V10.0002C8.88855 9.38655 9.38601 8.88909 9.99966 8.88909ZM11.1208 6.33342C11.1208 5.71977 10.6233 5.22231 10.0097 5.22231L9.87008 5.22979C9.31748 5.29397 8.88855 5.76361 8.88855 6.33342C8.88855 6.94707 9.38601 7.44453 10.0097 7.44453L10.1392 7.43706C10.6918 7.37288 11.1208 6.90324 11.1208 6.33342Z"
    {fill}
  />
</svg>
