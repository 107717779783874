<script>
  export let width = 20;
  export let height = 21;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 20 21"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M9.71929 3.77205C9.91361 3.90393 10.0713 3.90961 10.2673 3.7786C12.2747 2.44061 14.7127 1.75764 17.0909 1.46856C17.4598 1.42373 17.7497 1.41529 17.9608 1.44323C18.9621 1.57729 19.7263 2.36681 19.9481 3.34411C19.9892 3.5246 20.0098 3.79389 20.0101 4.15197C20.0113 6.9083 20.0114 9.66478 20.0106 12.4214C20.0106 12.7633 19.6599 13.0541 19.3272 13.0572C19.0079 13.0598 18.7311 12.8546 18.6451 12.545C18.6244 12.4707 18.6141 12.3419 18.6141 12.1585C18.6135 9.48515 18.6133 6.81165 18.6136 4.13799C18.6136 3.86492 18.5986 3.67526 18.5686 3.569C18.4726 3.22751 18.1944 2.92271 17.8411 2.84061C17.7354 2.81616 17.5809 2.81616 17.3774 2.84061C15.1416 3.11048 12.8385 3.7166 10.9529 5C10.8813 5.04891 10.7983 5.08297 10.7193 5.11965C10.7056 5.12606 10.6988 5.13668 10.6988 5.15153C10.6985 9.6738 10.6985 14.1959 10.6988 18.7179C10.6992 19.9192 9.54505 19.6358 8.91187 19.2179C7.20357 18.0891 5.09134 17.4847 3.07912 17.2096C2.70663 17.1585 2.2944 17.124 1.96383 17.0218C1.01187 16.7284 0.237631 15.9397 0.0406876 14.945C0.0066265 14.7744 -0.0104041 14.5156 -0.0104041 14.1686C-0.0109863 10.7368 -0.0111319 7.3051 -0.0108408 3.87336C-0.0104041 2.42882 1.20619 1.26943 2.67431 1.43974C5.09964 1.72053 7.67257 2.3821 9.71929 3.77205ZM9.27781 5.12227C9.17606 5.07249 9.07955 5.02315 8.98305 4.95808C7.11842 3.69738 4.81143 3.10262 2.60444 2.83843C2.41929 2.81631 2.27693 2.81558 2.17737 2.83625C1.83632 2.90743 1.5599 3.18734 1.44855 3.50961C1.40721 3.62926 1.38654 3.81791 1.38654 4.07555C1.38712 7.48981 1.38683 10.9044 1.38567 14.3192C1.38523 14.9323 1.69265 15.4262 2.25903 15.6472C2.38538 15.6964 2.62278 15.7429 2.97126 15.7869C5.16296 16.0633 7.36733 16.6668 9.27999 17.7948C9.28209 17.7961 9.28449 17.7968 9.28696 17.7968C9.28943 17.7968 9.29188 17.7962 9.29405 17.795C9.29622 17.7937 9.29804 17.7919 9.29933 17.7897C9.30062 17.7876 9.30133 17.7851 9.30139 17.7825V5.16026C9.30139 5.1428 9.29353 5.13013 9.27781 5.12227Z"
  />
  <path
    d="M7.20254 7.22664C7.53703 7.34367 7.81127 7.45502 7.8807 7.84061C7.9299 8.11485 7.8545 8.33639 7.6545 8.50524C7.41302 8.70917 7.15887 8.69563 6.85363 8.58515C5.78114 8.19709 4.68143 7.91237 3.5545 7.731C3.3446 7.69723 3.19992 7.65662 3.12044 7.60917C2.60385 7.30087 2.7069 6.4786 3.29686 6.33843C3.39759 6.31426 3.55756 6.31965 3.77677 6.35459C4.91738 6.53595 6.05931 6.82664 7.20254 7.22664Z"
  />
  <path
    d="M5.51041 10.4362C6.26107 10.6213 6.17854 11.7266 5.41828 11.8056C5.33822 11.8138 5.20634 11.7965 5.02264 11.7537C4.49746 11.6314 3.96908 11.5253 3.43749 11.4353C3.29921 11.412 3.19557 11.3796 3.12657 11.3379C2.72701 11.0969 2.66369 10.5489 3.00386 10.2283C3.21172 10.0327 3.42876 10.0231 3.72264 10.0707C4.32352 10.1685 4.91944 10.2903 5.51041 10.4362Z"
  />
  <path
    d="M13.489 13.276C13.4427 14.2402 12.1196 14.1991 12.0955 13.2948C12.0562 11.8162 13.434 10.5961 14.9021 10.7467C16.8903 10.9511 17.9056 13.2328 16.6947 14.8376C16.2137 15.4751 15.5497 15.8156 14.7025 15.859C13.7986 15.9057 13.6794 14.535 14.6314 14.459C14.7731 14.4479 14.8443 14.4422 14.8449 14.4419C16.3274 14.1895 16.0078 11.9677 14.5152 12.1398C13.937 12.2061 13.5165 12.7022 13.489 13.276Z"
  />
  <path
    d="M14.6581 18.8808C15.1709 18.8808 15.5865 18.4652 15.5865 17.9524C15.5865 17.4397 15.1709 17.024 14.6581 17.024C14.1454 17.024 13.7297 17.4397 13.7297 17.9524C13.7297 18.4652 14.1454 18.8808 14.6581 18.8808Z"
  />
</svg>
