<script>
  import { onMount } from "svelte";

  import { adminPages, currentUpsellModal } from "stores/admin.js";
  import { breadcrumb } from "stores/breadcrumb.js";
  import { t } from "stores/i18n.js";
  import { updatePageHistory } from "stores/page_history.js";
  import { user } from "stores/user.js";
  import { userPath, platformLink } from "lib/helpers.js";

  import { Button, Card } from "components";
  import styles from "styleguide/AdministrationDashboard.json";

  let showLargeCards = false;

  onMount(async () => {
    breadcrumb.set([{ translation: "administration" }]);
    updatePageHistory([
      {
        path: platformLink("account"),
        titles: [
          { translation: "administration" },
          { translation: "overview" },
        ],
      },
    ]);
  });

  $: filteredAdminPages = $adminPages.filter((page) => {
    return page.showCard;
  });

  function onCardClick({ path, hasPermission, featureFlag, svelte }) {
    if (hasPermission) {
      const pageSubRoute = svelte ? "/account/" : "/";
      window.location.href = `${userPath}${pageSubRoute}${path}`;
    } else {
      currentUpsellModal.set(featureFlag);
    }
  }

  function getItemLabelColor(featureFlag) {
    return featureFlag === "enterprise" ? "blue" : "pink";
  }

  function getItemLabel(featureFlag) {
    const label = featureFlag === "enterprise" ? "enterprise" : "addOn";
    return featureFlag && $t(`administrationDashboard.${label}`);
  }

  $: wrapperClass = showLargeCards
    ? styles.wrapper + " " + styles["--large"]
    : styles.wrapper;
</script>

<div data-component="AdministrationDashboard">
  {#if $user}
    <div class={wrapperClass}>
      {#each filteredAdminPages as adminPage}
        <Card
          icon={adminPage.icon}
          header={$t(`administration.${adminPage.path}.title`)}
          onCardClick={() => onCardClick(adminPage)}
          label={getItemLabel(adminPage.featureFlag)}
          labelColor={getItemLabelColor(adminPage.featureFlag)}
          description={$t(`administration.${adminPage.path}.description`)}
        >
          {#if !adminPage.hasPermission}
            <Button style="primary-text small">
              {$t("administrationDashboard.learnMore")}
            </Button>
          {/if}
        </Card>
      {/each}
    </div>
  {/if}
</div>

<style global lang="scss">
  .wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1em;
    grid-auto-rows: 1fr;
  }
</style>
