<script>
  export let width = 20;
  export let height = 21;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 20 21"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M0.0230983 2.98914C0.220042 1.75202 1.14144 0.800489 2.34362 0.548088C2.52965 0.509078 2.78991 0.489572 3.12441 0.489572C6.23125 0.489281 9.33824 0.489136 12.4454 0.489136C12.8524 0.489136 13.1423 0.506457 13.3152 0.541101C14.5126 0.781712 15.3196 1.82058 15.3567 3.03542C15.3646 3.29394 15.3655 3.77196 15.3593 4.46949C15.3561 4.82145 15.3531 5.00602 15.3502 5.0232C15.2358 5.67473 14.3069 5.83499 14.0266 5.21053C13.9855 5.11825 13.9646 4.97866 13.9637 4.79176C13.962 4.36177 13.9618 3.93179 13.9633 3.5018C13.9647 3.1149 13.9436 2.84838 13.9 2.70224C13.7253 2.11927 13.2379 1.88521 12.6524 1.88564C9.49253 1.88681 6.33285 1.88695 3.17332 1.88608C2.86007 1.88608 2.63169 1.90733 2.48816 1.94983C1.99078 2.09656 1.59384 2.50704 1.44668 3.00617C1.4068 3.14125 1.38685 3.3379 1.38685 3.59612C1.38627 8.39525 1.38613 13.1944 1.38642 17.9935C1.38685 18.704 1.76371 19.1136 2.48511 19.1136C5.70461 19.1136 8.92397 19.1136 12.1432 19.1136C12.4637 19.1136 12.6925 19.095 12.8296 19.0577C13.4672 18.8839 13.9082 18.3018 13.9558 17.649C13.9689 17.4731 13.9851 17.3594 14.0043 17.3079C14.2882 16.549 15.3751 16.7944 15.351 17.6249C15.3109 18.9979 14.3598 20.1686 13.0144 20.4507C12.8246 20.4903 12.5508 20.5102 12.193 20.5105C9.04566 20.5111 5.89835 20.5113 2.75105 20.511C2.33649 20.511 2.04639 20.4942 1.88074 20.4608C0.98467 20.2791 0.25978 19.5608 0.0519193 18.6717C0.010289 18.4947 -0.0105261 18.2255 -0.0105261 17.8642C-0.0111084 13.1469 -0.011254 8.42946 -0.0109628 3.71184C-0.0109628 3.37269 0.000390901 3.13179 0.0230983 2.98914Z"
  />
  <path
    d="M10.0127 4.68164H5.33233C4.95658 4.68164 4.65198 4.98624 4.65198 5.36199V5.39605C4.65198 5.7718 4.95658 6.0764 5.33233 6.0764H10.0127C10.3884 6.0764 10.693 5.7718 10.693 5.39605V5.36199C10.693 4.98624 10.3884 4.68164 10.0127 4.68164Z"
  />
  <path
    d="M20.0114 11.4315C20.0114 12.0733 19.885 12.7089 19.6393 13.3019C19.3937 13.895 19.0337 14.4338 18.5798 14.8877C18.1259 15.3415 17.5871 15.7016 16.9941 15.9472C16.4011 16.1928 15.7655 16.3192 15.1236 16.3192C13.8273 16.3192 12.5841 15.8043 11.6674 14.8877C10.7508 13.971 10.2358 12.7278 10.2358 11.4315C10.2358 10.1352 10.7508 8.89193 11.6674 7.9753C12.5841 7.05866 13.8273 6.5437 15.1236 6.5437C15.7655 6.5437 16.4011 6.67013 16.9941 6.91576C17.5871 7.16139 18.1259 7.52143 18.5798 7.9753C19.0337 8.42917 19.3937 8.96799 19.6393 9.561C19.885 10.154 20.0114 10.7896 20.0114 11.4315ZM18.6144 11.4315C18.6144 10.5056 18.2467 9.61774 17.592 8.96308C16.9373 8.30843 16.0494 7.94064 15.1236 7.94064C14.1978 7.94064 13.3099 8.30843 12.6552 8.96308C12.0006 9.61774 11.6328 10.5056 11.6328 11.4315C11.6328 11.8899 11.7231 12.3438 11.8985 12.7674C12.0739 13.1909 12.3311 13.5757 12.6552 13.8999C13.3099 14.5545 14.1978 14.9223 15.1236 14.9223C15.582 14.9223 16.036 14.832 16.4595 14.6566C16.883 14.4812 17.2678 14.224 17.592 13.8999C17.9162 13.5757 18.1733 13.1909 18.3487 12.7674C18.5242 12.3438 18.6144 11.8899 18.6144 11.4315Z"
  />
  <path
    d="M8.61534 8.40527H3.93499C3.55924 8.40527 3.25464 8.70988 3.25464 9.08562V9.11968C3.25464 9.49543 3.55924 9.80003 3.93499 9.80003H8.61534C8.99108 9.80003 9.29569 9.49543 9.29569 9.11968V9.08562C9.29569 8.70988 8.99108 8.40527 8.61534 8.40527Z"
  />
  <path
    d="M14.1502 12.7455C13.8681 12.4619 13.5906 12.1733 13.3178 11.8795C13.1804 11.7316 13.0959 11.6082 13.0641 11.5092C12.8432 10.8197 13.6991 10.2882 14.2296 10.8179C14.3967 10.9848 14.5604 11.1559 14.7205 11.3315C14.7231 11.3343 14.7263 11.3366 14.7298 11.3382C14.7334 11.3398 14.7372 11.3407 14.7411 11.3407C14.745 11.3407 14.7489 11.34 14.7524 11.3385C14.756 11.337 14.7593 11.3347 14.762 11.3319C15.1497 10.9232 15.5336 10.5214 15.9135 10.1267C16.0503 9.9849 16.1669 9.89349 16.2633 9.85244C16.8545 9.60222 17.4737 10.2904 17.1213 10.8594C17.0934 10.9045 16.9995 11.0108 16.8397 11.1782C16.3558 11.685 15.872 12.1917 15.3882 12.6983C15.1209 12.9778 14.8751 13.1572 14.5065 13.0123C14.4069 12.9733 14.2882 12.8843 14.1502 12.7455Z"
  />
  <path
    d="M8.61534 11.6686H3.93499C3.55924 11.6686 3.25464 11.9732 3.25464 12.3489V12.383C3.25464 12.7587 3.55924 13.0633 3.93499 13.0633H8.61534C8.99108 13.0633 9.29569 12.7587 9.29569 12.383V12.3489C9.29569 11.9732 8.99108 11.6686 8.61534 11.6686Z"
  />
  <path
    d="M10.0101 14.9231H3.93848C3.56081 14.9231 3.25464 15.2293 3.25464 15.6069V15.634C3.25464 16.0117 3.56081 16.3179 3.93848 16.3179H10.0101C10.3878 16.3179 10.6939 16.0117 10.6939 15.634V15.6069C10.6939 15.2293 10.3878 14.9231 10.0101 14.9231Z"
  />
</svg>
