<script>
  import styles from "styleguide/ResponderFileItem.json";
  import { fileIcon, humanFileSizeFromByte } from "lib/helpers.js";
  import { ProgressRing } from "components";

  export let name;
  export let size;
  export let progress;

  $: icon = name ? fileIcon(name) : null;
</script>

<div data-component="ResponderFileItem" class={styles.file}>
  <div class={styles.icon}>
    {#if progress}
      <ProgressRing size="32" stroke="4" completedPercent={progress} />
    {:else if icon}
      <img src={icon} alt="" />
    {/if}
  </div>
  <div class={styles.content}>
    <div class={styles.name}>{name}</div>
    <div class={styles.meta}>{humanFileSizeFromByte(size)}</div>
  </div>
  <div class={styles.actions}>
    <slot />
  </div>
</div>

<style lang="scss">
  .file {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 0;
  }

  .icon {
    flex: 0 0 40px;
    margin-right: 5px;

    img {
      width: 28px;
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .name {
    line-height: 22px;
    padding-right: 20px;
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .progress {
    font-size: 12px;
    font-weight: 500;
    color: var(--green-400);
  }

  .meta {
    color: var(--primary-100);
    font-size: 12px;
    line-height: 18px;
  }
</style>
