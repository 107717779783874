import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["circle", "progressCircle", "progressValue"];

  setProgress() {
    let total = parseInt(this.data.get("total")),
      current = parseInt(this.data.get("progress"));
    if (total > 0) {
      this.progressCircleTarget.style.strokeDashoffset =
        (this.circumference * (total - current)) / total;
      this.progressCircleTarget.style.strokeDasharray = this.circumference;
      this.progressValueTarget.textContent =
        ((current * 100) / total).toFixed(0) + "%";
    } else {
      this.progressCircleTarget.style.strokeDashoffset = this.circumference;
      this.progressCircleTarget.style.strokeDasharray = this.circumference;
    }
  }

  connect() {
    this.circumference = this.circleTarget.r.baseVal.value * 2 * Math.PI;
    this.setProgress();
  }
}
