<script>
  import styles from "styleguide/CheckboxRound.json";
  import { sanitizeRelaxed } from "lib/sanitize.js";
  import { InputWrapper } from "components";

  export let label;
  export let uid;
  export let style = "";
  export let toggle;
  export let toggled = false;
  export let disabled = false;
  export let footnotes;
  export let checkmark = "default";

  let checkbox;
  $: cssClasses = getStyles(style, checkmark);

  function getStyles(style, checkmark) {
    let s = style.split(" ");
    if (checkmark) {
      s.push(`checkmark_${checkmark}`);
    }
    return s.map((x) => styles[x]).join(" ");
  }

  function onToggle() {
    // return uniq identifier
    if (toggle) {
      toggle(uid);
    }
  }
</script>

<InputWrapper {footnotes}>
  <label data-component="CheckboxRound" class="{styles.wrapper} {cssClasses}">
    <span class={styles.checkbox}>
      <input
        bind:this={checkbox}
        type="checkbox"
        bind:checked={toggled}
        on:change={onToggle}
        {disabled}
      />
      <span class={styles.circle}>
        <span class={styles.checkmark}></span>
      </span>
    </span>

    {#if label}
      <span class={styles.label}>{@html sanitizeRelaxed(label)}</span>
    {/if}

    <slot />
  </label>
</InputWrapper>

<style lang="scss">
  .wrapper {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
  }

  .checkbox {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover .circle {
      border-color: var(--blue);
    }
  }

  .circle {
    position: relative;
    display: block;
    width: 18px;
    height: 18px;
    border: 1px solid var(--primary-300);
    // outline: 5px solid transparent;
    border-radius: 50%;
    background: #fff;
    user-select: none;

    .sidebar & {
      margin: 0 6px;
    }
  }

  input:checked ~ .circle {
    background: var(--blue);
    border-color: #bcc6d6;

    .checkmark {
      display: block;
    }
  }

  .checkmark {
    position: absolute;
    display: none;
    user-select: none;

    .checkmark_default & {
      width: 16px;
      height: 16px;
      border: 2px solid white;
      border-radius: 50%;
    }
  }

  .top {
    align-items: flex-start;
    padding-top: 14px;
  }

  .label {
    line-height: 18px;
    margin-left: 10px;
    user-select: none;

    .blue & {
      color: var(--blue-200);
    }

    .grey & {
      color: var(--primary-300);
    }

    .label-left-20 & {
      margin-left: 20px;
    }

    .sidebar & {
      font-weight: 500;
      margin-left: 14px;
    }

    .review-type & {
      font-weight: 600;
      margin-left: 8px;
    }
  }
</style>
