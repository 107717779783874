import { Controller } from "stimulus";
import { createConsumer } from "@rails/actioncable";

export default class extends Controller {
  initialize() {
    const protocol = window.location.protocol === "https:" ? "wss://" : "ws://";
    const url = `${protocol}${window.location.host}/cable`;
    this.consumer = createConsumer(url);
  }

  connect() {
    this.subscribeReviewImportProgress();
  }

  disconnect() {
    this.consumer.subscriptions.remove(this.subscription);
  }

  subscribeReviewImportProgress() {
    const channelSettings = {
      channel: "ReviewImportProgressChannel",
      id: this.data.get("id"),
    };
    this.subscription = this.consumer.subscriptions.create(channelSettings, {
      received: (data) => {
        if (data.status === "importing") {
          document.querySelector("[data-import-stats]").innerHTML = data.stats;
        }

        if (data.status === "done") {
          Turbolinks.visit(location.toString());
        }
      },
    });
  }
}
