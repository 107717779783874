<script>
  import styles from "styleguide/QuickshareEdit.json";
  import { onMount, getContext } from "svelte";

  import { editUpload } from "stores/quickshare.js";
  import { t } from "stores/i18n.js";

  import { LegacyButton as Button } from "components";
  import {
    QuickshareHeader,
    QuickshareUploadSettings,
  } from "components/quickshare";

  const api = getContext("oksend");

  let upload = {};
  let settingsComponent;
  let successfullyUpdated = false;

  $: uploadSettings = {
    name: upload.name,
    deleteAfter: upload["self_destruct_after"],
    passwordPresent: upload["password_present"],
    numberDownloads: upload["max_download_count"],
  };

  onMount(async () => {
    getUploadData();
  });

  function onBack() {
    editUpload.set(null);
  }

  function onGetUploadSuccess(response) {
    upload = response;
  }

  function onUpdateUploadSuccess(response) {
    upload = response;
    successfullyUpdated = true;
    setTimeout(function () {
      successfullyUpdated = false;
    }, 1200);
  }

  function getUploadData() {
    api.uploads.get({
      id: $editUpload,
      success: onGetUploadSuccess,
      error: () => console.log("ERROR"),
    });
  }

  function updateUpload() {
    api.uploads.update({
      id: $editUpload,
      settings: settingsComponent.getSettings(),
      success: onUpdateUploadSuccess,
      error: () => console.log("ERROR"),
    });
  }
</script>

<div data-component="QuickshareEdit" class={styles.wrapper}>
  <QuickshareHeader>
    <Button
      click={onBack}
      style="border icon-only"
      icon="stroke_regular_left.svg"
    />
    {upload.name}
  </QuickshareHeader>

  <div class={styles.form}>
    <QuickshareUploadSettings
      bind:this={settingsComponent}
      {...uploadSettings}
    />
  </div>

  <div class={styles.actions}>
    <Button
      click={updateUpload}
      style={successfullyUpdated ? ["success"].join(" ") : "primary"}
    >
      {#if successfullyUpdated}
        {$t("quickshare_edit.submit_success")}
      {:else}
        {$t("quickshare_edit.submit")}
      {/if}
    </Button>
  </div>
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }

  .form {
    flex: 1;
    padding: 20px;
    overflow-y: scroll;
  }

  .actions {
    padding: 20px;
  }
</style>
