<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(0%,21.960784%,59.215686%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 140.003906 L 140.003906 140.003906 L 140.003906 0 L 220.003906 0 L 220.003906 140.003906 L 500.003906 140.003906 L 500.003906 220.003906 L 220.003906 220.003906 L 220.003906 360.007812 L 140.003906 360.007812 L 140.003906 220.003906 L 0 220.003906 Z M 0 140.003906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(84.313725%,15.686275%,15.686275%);fill-opacity:1;"
      d="M 0 159.996094 L 159.996094 159.996094 L 159.996094 0 L 199.996094 0 L 199.996094 159.996094 L 499.996094 159.996094 L 499.996094 199.996094 L 199.996094 199.996094 L 199.996094 359.996094 L 159.996094 359.996094 L 159.996094 199.996094 L 0 199.996094 Z M 0 159.996094 "
    />
  </g>
</svg>
