<script>
  export let width = 18;
  export let height = 18;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 18 18"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M12.9706 3.21343L8.72792 7.45607L4.48528 3.21343C3.8998 2.62795 2.95051 2.62689 2.36396 3.21343C1.77742 3.79998 1.77848 4.74927 2.36396 5.33475L6.6066 9.57739L2.36396 13.82C1.77742 14.4066 1.77848 15.3559 2.36396 15.9414C2.95051 16.5279 3.89874 16.5279 4.48528 15.9414L8.72792 11.6987L12.9706 15.9414C13.5571 16.5279 14.5053 16.5279 15.0919 15.9414C15.6784 15.3548 15.6784 14.4066 15.0919 13.82L10.8492 9.57739L15.0919 5.33475C15.6784 4.74821 15.6784 3.79998 15.0919 3.21343C14.5064 2.62795 13.5571 2.62689 12.9706 3.21343Z"
  />
</svg>
