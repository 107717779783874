<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M -60.585938 228.980469 L 114.738281 228.980469 L 114.738281 360 L -60.585938 360 Z M -60.585938 228.980469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,38.431373%,85.490196%);fill-opacity:1;"
      d="M 114.625 0 L 220.050781 0 L 220.050781 360 L 114.625 360 Z M 114.625 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,38.431373%,85.490196%);fill-opacity:1;"
      d="M -60.585938 130.90625 L 570.585938 130.90625 L 570.585938 229.09375 L -60.585938 229.09375 Z M -60.585938 130.90625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M -60.585938 0.00390625 L 114.738281 0.00390625 L 114.738281 131.023438 L -60.585938 131.023438 Z M -60.585938 0.00390625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 219.9375 228.980469 L 570.585938 228.980469 L 570.585938 360 L 219.9375 360 Z M 219.9375 228.980469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 219.9375 0 L 570.585938 0 L 570.585938 131.019531 L 219.9375 131.019531 Z M 219.9375 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M -60.585938 228.980469 L 114.738281 228.980469 L 114.738281 360 L -60.585938 360 Z M -60.585938 228.980469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,38.431373%,85.490196%);fill-opacity:1;"
      d="M 114.625 0 L 220.050781 0 L 220.050781 360 L 114.625 360 Z M 114.625 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M -60.585938 0.00390625 L 114.738281 0.00390625 L 114.738281 131.023438 L -60.585938 131.023438 Z M -60.585938 0.00390625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 219.9375 228.980469 L 570.585938 228.980469 L 570.585938 360 L 219.9375 360 Z M 219.9375 228.980469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 219.9375 0 L 570.585938 0 L 570.585938 131.019531 L 219.9375 131.019531 Z M 219.9375 0 "
    />
  </g>
</svg>
