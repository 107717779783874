<script>
  export let width = 20;
  export let height = 21;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 20 21"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_1644_24541)">
    <path
      d="M16.3321 3.85802C14.3895 5.55534 12.4491 7.25504 10.5107 8.95712C9.73389 9.63971 8.62764 9.78748 7.7205 9.28123C7.56633 9.19492 7.33151 9.01323 7.01603 8.73614C5.16038 7.10727 3.30621 5.4793 1.45353 3.85221C1.45135 3.85036 1.44869 3.84916 1.44586 3.84876C1.44302 3.84835 1.44013 3.84876 1.43752 3.84992C1.4349 3.85109 1.43267 3.85297 1.43108 3.85535C1.42949 3.85773 1.42861 3.86051 1.42853 3.86337C1.42883 6.79403 1.42868 9.72573 1.42809 12.6585C1.42809 12.9213 1.44594 13.0945 1.48166 13.1781C1.60577 13.4705 1.87139 13.4803 2.183 13.4803C4.12556 13.4794 6.06826 13.4793 8.01112 13.4799C8.19743 13.4799 8.33746 13.5001 8.43121 13.5406C9.01737 13.7946 8.99639 14.6129 8.41559 14.8513C8.32332 14.8894 8.19788 14.9085 8.03925 14.9085C6.00294 14.9082 3.96678 14.908 1.93077 14.908C1.04818 14.908 0.260229 14.2419 0.05889 13.3969C0.0190091 13.2305 -0.000782593 12.9345 -0.000484974 12.5089C0.000407883 9.42855 0.000407883 6.3482 -0.000484974 3.26784C-0.000484974 2.82974 0.0163305 2.53346 0.0499614 2.379C0.207997 1.65489 0.804426 1.05489 1.533 0.890161C1.68538 0.855637 1.97675 0.838375 2.4071 0.838375C6.86514 0.83659 11.3232 0.83659 15.7812 0.838375C16.8991 0.838822 17.7875 1.71784 17.7861 2.84641C17.784 4.51248 17.7837 6.17826 17.7852 7.84373C17.7857 8.41962 17.1812 8.79507 16.6826 8.4723C16.3763 8.27454 16.354 8.01293 16.3553 7.63659C16.3601 6.38034 16.3596 5.12409 16.354 3.86784C16.3537 3.84879 16.3464 3.84552 16.3321 3.85802ZM8.26469 7.93123C8.62585 8.24998 9.13657 8.26873 9.48389 7.96337C11.6342 6.07468 13.7836 4.18421 15.9321 2.29195C15.952 2.27439 15.9501 2.26561 15.9263 2.26561L1.86246 2.27766C1.86135 2.27759 1.86027 2.27787 1.85935 2.27848C1.85844 2.27909 1.85774 2.27998 1.85735 2.28105C1.85697 2.28212 1.8569 2.28331 1.85718 2.28446C1.85745 2.28561 1.85805 2.28667 1.85889 2.28748C3.9955 4.17022 6.13077 6.05147 8.26469 7.93123Z"
    />
    <path
      d="M19.9826 12.8107C20.1116 15.8933 18.3187 18.5344 15.7076 20.0098C15.2214 20.2844 14.9804 20.1268 14.5576 19.8728C12.208 18.4616 10.6129 16.1089 10.5009 13.3469C10.489 13.0585 10.4993 12.7716 10.5317 12.4862C10.5728 12.1277 10.6504 11.8545 10.971 11.6755C12.2418 10.9656 13.5129 10.2564 14.7844 9.54778C15.1223 9.35983 15.3326 9.33439 15.662 9.51742C16.9707 10.2463 18.2787 10.9761 19.5862 11.7067C19.9277 11.8973 19.9652 12.3942 19.9826 12.8107ZM15.233 10.9322L11.9745 12.7505C11.9609 12.7582 11.953 12.77 11.9509 12.7857C11.9298 12.9592 11.9257 13.1713 11.9388 13.4219C12.0549 15.6491 13.2652 17.5094 15.2339 18.5589C15.2461 18.5655 15.2583 18.5655 15.2705 18.5589C17.2964 17.475 18.5263 15.5107 18.5665 13.2005C18.5695 13.0371 18.5618 12.897 18.5433 12.7804C18.5412 12.7658 18.5338 12.7549 18.521 12.7478L15.2679 10.9326C15.2559 10.9261 15.2443 10.9259 15.233 10.9322Z"
    />
  </g>
  <defs>
    <clipPath id="clip0_1644_24541">
      <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
    </clipPath>
  </defs>
</svg>
