<script>
  import styles from "styleguide/QuickshareUploadSettings.json";
  import { t } from "stores/i18n.js";

  import { Dropdown, TextInput } from "components";

  export let name;
  export let numberDownloads;
  export let passwordPresent;
  export let password;
  export let deleteAfter = "172800";

  export function getSettings() {
    return {
      name: name,
      password: password,
      self_destruct: deleteAfter,
      max_download_count: numberDownloads,
    };
  }

  $: deleteOptions = {
    "86400": $t("quickshare_upload_settings.1day"),
    "172800": $t("quickshare_upload_settings.2days"),
    "604800": $t("quickshare_upload_settings.7days"),
    "1209600": $t("quickshare_upload_settings.14days"),
  };

  $: numberOfDownloadsOptions = {
    null: $t("quickshare_upload_settings.unlimited"),
    "1": $t("quickshare_upload_settings.only1"),
  };
</script>

<div data-component="QuickshareUploadSettings" class={styles.wrapper}>
  <div class={styles.option}>
    <label>{$t("quickshare_upload_settings.name_label")}</label>
    <TextInput bind:value={name} placeholder="optional" />
  </div>
  <div class={styles.option}>
    <label>{$t("quickshare_upload_settings.delete_label")}</label>
    <Dropdown bind:selected={deleteAfter} items={deleteOptions} />
  </div>
  <div class={styles.option}>
    {#if passwordPresent}
      <label>{$t("quickshare_upload_settings.new_password_label")}</label>
      <TextInput bind:value={password} />
    {:else}
      <label>{$t("quickshare_upload_settings.password_label")}</label>
      <TextInput bind:value={password} />
    {/if}
  </div>
  <div class={styles.option}>
    <label>{$t("quickshare_upload_settings.number_label")}</label>
    <Dropdown
      bind:selected={numberDownloads}
      items={numberOfDownloadsOptions}
    />
  </div>
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .option {
    flex: 0 0 auto;
    min-width: 0;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
      flex: 0 0 50%;
      padding-right: 10px;
    }
  }
</style>
