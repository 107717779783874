import { t } from "stores/i18n.js";
import { get } from "svelte/store";

export function getSampleResponseToastStatus(response) {
  return [
    {
      condition: response.client_preview,
      props: {
        type: "warning",
        message: get(t)("sample_response.self_sent_request"),
      },
    },
    {
      condition:
        response.received_via === "online_response" &&
        response.email_verified_at === null,
      props: {
        type: "warning",
        message: get(t)("sample_response.not_confirmed_by_advisor"),
      },
    },
    {
      condition:
        response.received_via === "online_response" &&
        response.not_verified_by_advisor,
      props: {
        type: "warning",
        message: get(t)("sample_response.not_confirmed_by_advisor_email"),
      },
    },
  ];
}
