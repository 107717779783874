import { writable, derived } from "svelte/store";

const user = writable({});

const isUserClient = derived(user, ($user) => {
  return $user.role === "client";
});

const isUserTeamUser = derived(user, ($user) => {
  return $user.role === "team";
});

export { user, isUserClient, isUserTeamUser };
