<script>
  import { ControlDownloadUnsafeItem } from "apps/pbc";
  import { ConfirmModal } from "components/modals";
  import documentViewerStore from "stores/document_viewer_store.js";
  import { t } from "stores/i18n.js";
  import { activeDownloadUnsafe } from "stores/pbc.js";
  import { get } from "svelte/store";

  import { Checkbox } from "components";
  import styles from "styleguide/ControlDownloadUnsafe.json";

  const document = get(documentViewerStore.documentReadable);

  $: count = $activeDownloadUnsafe.files
    ? $activeDownloadUnsafe.files.length
    : 1;
  $: countWithVirus = $activeDownloadUnsafe.files
    ? $activeDownloadUnsafe.files.filter((file) => file.virus).length
    : 1;
  $: size = count > 1 ? "small" : "tiny";
  $: title = $t("control_download_unsafe.title", {
    postProcess: "interval",
    count: countWithVirus,
  });
  $: description = $t("control_download_unsafe.description", {
    postProcess: "interval",
    count: countWithVirus,
  });
  $: confirmLabel = $t("control_download_unsafe.confirm");
  $: isSelected =
    count > 1
      ? $activeDownloadUnsafe.files.every((file) => file.checked) !== undefined
      : false;

  function onConfirm() {
    if ($activeDownloadUnsafe.files) {
      $activeDownloadUnsafe.onDownload(
        $activeDownloadUnsafe.files
          .map((file) => {
            if (file.checked) return file.permalink;
          })
          .filter((permalink) => permalink),
      );
    } else {
      $activeDownloadUnsafe.onDownload();
    }
  }

  function onCheckboxToggle(toggled) {
    activeDownloadUnsafe.update((data) => {
      let newData = { ...data };

      newData.files = data.files.map((file) => {
        file.checked = toggled;
        return file;
      });

      return newData;
    });
  }
</script>

<div
  data-component="ControlDownloadUnsafe"
  class={document.url ? styles.hidden : ""}
>
  <ConfirmModal
    {size}
    {title}
    {description}
    {confirmLabel}
    icon="filled"
    style="text-center"
    shown={$activeDownloadUnsafe.onDownload}
    {onConfirm}
  >
    <div>
      {#if count > 1}
        <div class={styles.checkbox}>
          <Checkbox
            style="label-left-20"
            toggled={isSelected}
            toggle={onCheckboxToggle}
            label={$t("control_download_unsafe.uncheck")}
          />
        </div>

        {#each $activeDownloadUnsafe.files as file}
          <ControlDownloadUnsafeItem
            {...file}
            onReport={$activeDownloadUnsafe.onReport}
          />
        {/each}
      {/if}
    </div>
  </ConfirmModal>
</div>

<style lang="scss">
  .hidden {
    display: none;
  }

  .checkbox {
    font-weight: 500;
    padding: 0 20px 15px 20px;
    margin: 25px -20px 15px -20px;
    border-bottom: 1px solid var(--primary-050);
  }
</style>
