import { Controller } from "stimulus";
import Helper from "../../general/helper";

export default class extends Controller {
  static targets = ["copyLink"];

  copyClipboard(e) {
    e.preventDefault();
    Helper.copyToClickboard(this.copyLinkTarget);
  }
}
