<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,76.862745%,100%);fill-opacity:1;"
      d="M 0 -0.0078125 L 480.003906 -0.0078125 L 480.003906 120.316406 L 0 120.316406 Z M 0 -0.0078125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 119.824219 L 480.003906 119.824219 L 480.003906 240.144531 L 0 240.144531 Z M 0 119.824219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,76.862745%,100%);fill-opacity:1;"
      d="M 0 239.652344 L 480.003906 239.652344 L 480.003906 359.976562 L 0 359.976562 Z M 0 239.652344 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,83.921569%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 382.489664 221.32907 C 382.489664 235.895131 370.627145 247.699716 355.991323 247.699716 C 341.355502 247.699716 329.492983 235.895131 329.492983 221.32907 C 329.492983 206.766225 341.355502 194.958425 355.991323 194.958425 C 370.627145 194.958425 382.489664 206.766225 382.489664 221.32907 Z M 382.489664 221.32907 "
      transform="matrix(1.233863,0,0,1.2151,-199.420179,-87.425213)"
    />
    <path
      style="fill:none;stroke-width:0.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 364.430262 195.281739 C 360.088271 194.232454 355.643779 195.700606 354.245649 195.599063 C 352.318609 195.599063 347.455908 193.919361 344.061037 195.599063 "
      transform="matrix(0.952724,0,0,0.923248,-98.154591,-21.684168)"
    />
    <path
      style="fill:none;stroke-width:0.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 338.710425 200.490081 C 343.015515 197.481851 347.824916 201.577444 349.104142 200.803174 C 352.597415 198.50998 355.840583 198.93731 359.182152 201.014723 C 361.338797 202.28825 365.098576 197.30415 369.473367 201.332047 "
      transform="matrix(0.952724,0,0,0.923248,-98.154591,-21.684168)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(93.72549%,75.294118%,0%);fill-opacity:1;stroke-width:0.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 333.880525 205.630728 C 336.156072 203.773326 343.573127 203.70563 351.203386 208.04239 C 352.35961 207.763145 353.093525 206.959257 352.150506 205.838046 C 346.410386 203.845252 339.723146 201.323585 333.564818 203.214836 C 331.883783 204.403743 332.306092 205.173782 333.880525 205.630728 Z M 333.880525 205.630728 "
      transform="matrix(0.952724,0,0,0.923248,-98.154591,-21.684168)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(93.72549%,75.294118%,0%);fill-opacity:1;stroke-width:0.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 333.88186 205.630728 C 336.153308 203.773326 343.574463 203.70563 351.204722 208.04239 C 352.360946 207.763145 353.094861 206.959257 352.147742 205.838046 C 346.407622 203.845252 339.724482 201.323585 333.566154 203.214836 C 331.885119 204.403743 332.303328 205.173782 333.88186 205.630728 Z M 333.88186 205.630728 "
      transform="matrix(-0.952724,0,0,0.923248,576.737895,-21.684168)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(94.117647%,74.901961%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 330.838261 211.829121 C 338.366018 207.001567 348.304626 209.519003 352.470312 212.146445 C 346.377585 210.991386 346.275083 210.466744 341.863391 210.36097 C 338.747326 210.466744 334.16343 210.14942 330.838261 211.829121 Z M 330.838261 211.829121 "
      transform="matrix(0.952724,0,0,0.923248,-98.154591,-21.684168)"
    />
    <path
      style="fill:none;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 348.05862 211.300248 C 344.384944 218.966793 337.98061 219.068337 333.466416 210.881381 "
      transform="matrix(0.952724,0,0,0.923248,-98.154591,-21.684168)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:0.368627;"
      d="M 231.585938 175.566406 C 230.546875 176.703125 228.007812 177.378906 225.914062 177.074219 C 223.816406 176.769531 222.960938 175.605469 223.996094 174.46875 C 225.035156 173.335938 227.574219 172.660156 229.671875 172.960938 C 231.765625 173.265625 232.625 174.433594 231.585938 175.566406 Z M 231.585938 175.566406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;"
      d="M 226.425781 174.109375 C 226.53125 174.375 226.199219 174.726562 225.6875 174.894531 C 225.171875 175.0625 224.667969 174.984375 224.558594 174.71875 C 224.453125 174.457031 224.785156 174.105469 225.296875 173.9375 C 225.8125 173.769531 226.316406 173.847656 226.425781 174.109375 Z M 226.425781 174.109375 "
    />
    <path
      style="fill:none;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 349.182044 224.500922 C 344.942555 231.625901 350.715476 226.59949 351.654395 228.664211 C 353.30673 230.576616 354.954964 230.123901 355.930785 228.664211 C 356.906605 227.014127 363.056733 231.778216 358.858246 224.725164 "
      transform="matrix(0.952724,0,0,0.923248,-98.154591,-21.684168)"
    />
    <path
      style="fill:none;stroke-width:0.625;stroke-linecap:round;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 341.641987 236.309601 C 345.278762 235.894965 351.39199 233.123669 353.569135 235.408401 C 355.442874 233.347911 362.044012 236.009201 366.283501 236.309601 C 363.208436 238.183927 356.980407 236.910401 354.020145 238.898964 C 351.129584 237.134643 344.753951 238.746648 341.641987 236.309601 Z M 341.641987 236.309601 "
      transform="matrix(0.952724,0,0,0.923248,-98.154591,-21.684168)"
    />
    <path
      style="fill:none;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 347.501009 239.580153 C 353.015624 241.831037 354.25385 241.492558 360.215374 239.804395 C 358.977148 243.070715 355.815982 243.756135 354.024245 243.629205 C 352.171006 243.510738 349.637153 243.743442 347.501009 239.580153 Z M 347.501009 239.580153 "
      transform="matrix(0.952724,0,0,0.923248,-98.154591,-21.684168)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 343.171318 258.061095 C 339.194235 268.469319 343.548526 273.419571 341.600986 279.025626 C 339.727247 284.420132 331.576277 297.798507 337.783806 303.611881 C 342.712109 303.840354 336.930988 296.237274 346.795794 282.156555 C 351.367389 275.564681 345.598569 271.388698 350.42027 258.450346 C 350.867179 257.346059 343.798632 256.419473 343.171318 258.061095 Z M 343.171318 258.061095 "
      transform="matrix(0.952724,0,0,0.923248,-98.154591,-21.684168)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 342.967995 258.158106 C 341.442763 269.844087 342.348882 305.735533 346.006158 304.301229 C 350.057043 305.604373 350.590054 269.137512 350.221046 258.356962 C 350.167745 257.159593 343.181199 256.41494 342.967995 258.158106 Z M 342.967995 258.158106 "
      transform="matrix(0.952724,0,0,0.923248,-89.941349,-21.051077)"
    />
    <path
      style="fill:none;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 343.170229 258.060531 C 339.192123 268.469489 343.549535 273.419635 341.602706 279.026513 C 339.730507 284.42228 331.577587 297.802357 337.782887 303.613454 C 342.710753 303.841636 336.929274 296.23728 346.793336 282.157776 C 351.368407 275.56492 345.599824 271.388689 350.41752 258.450832 C 350.86746 257.346105 343.796486 256.417991 343.170229 258.060531 Z M 343.170229 258.060531 "
      transform="matrix(-0.680979,-0.645681,-0.666308,0.659904,627.76047,264.88476)"
    />
    <path
      style="fill:none;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 343.171953 258.059862 C 339.193235 268.470709 343.54894 273.420147 341.600918 279.024765 C 339.730002 284.423686 331.578433 297.800764 337.782808 303.614011 C 342.713416 303.840688 336.930657 296.236531 346.792906 282.159019 C 351.368912 275.564441 345.59895 271.387696 350.418934 258.449133 C 350.86728 257.344425 343.794854 256.420199 343.171953 258.059862 Z M 343.171953 258.059862 "
      transform="matrix(0.666671,0.659553,-0.680616,0.646032,153.46236,-194.031527)"
    />
    <path
      style="fill:none;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 343.170806 258.060621 C 339.194258 268.469161 343.546924 273.417572 341.603284 279.025448 C 339.730366 284.423811 331.577887 297.800288 337.780277 303.61138 C 342.715058 303.841282 336.929681 296.236029 346.79594 282.156763 C 351.369871 275.562013 345.600018 271.388485 350.420912 258.449401 C 350.867275 257.343894 343.796032 256.421067 343.170806 258.060621 Z M 343.170806 258.060621 "
      transform="matrix(-0.00548429,-0.923224,-0.952712,0.00531458,451.305885,507.674745)"
    />
    <path
      style="fill:none;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 342.96898 258.16031 C 341.442462 269.844857 342.350547 305.734959 346.007656 304.301067 C 350.054248 305.60524 350.590021 269.137953 350.219062 258.354912 C 350.165469 257.160137 343.184267 256.418061 342.96898 258.16031 Z M 342.96898 258.16031 "
      transform="matrix(-0.873969,-0.367556,-0.379286,0.846926,626.84049,123.171525)"
    />
    <path
      style="fill:none;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 342.970295 258.159517 C 341.439534 269.843125 342.349397 305.735563 346.006539 304.302025 C 350.056162 305.607254 350.59094 269.136522 350.220936 258.353954 C 350.166054 257.159483 343.184958 256.416894 342.970295 258.159517 Z M 342.970295 258.159517 "
      transform="matrix(-0.678667,-0.647969,-0.668656,0.657665,620.93325,261.5922)"
    />
    <path
      style="fill:none;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 342.969458 258.160944 C 341.442488 269.84215 342.348009 305.737157 346.007796 304.299445 C 350.055555 305.605633 350.589895 269.139516 350.21716 258.353789 C 350.165359 257.159783 343.18517 256.416874 342.969458 258.160944 Z M 342.969458 258.160944 "
      transform="matrix(-0.372265,-0.849844,-0.876983,0.360753,561.08613,396.36927)"
    />
    <path
      style="fill:none;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0823529;stroke-miterlimit:4;"
      d="M 349.182044 224.500922 C 344.942555 231.625901 350.715476 226.59949 351.654395 228.664211 C 353.30673 230.576616 354.954964 230.123901 355.930785 228.664211 C 356.906605 227.014127 363.056733 231.778216 358.858246 224.725164 "
      transform="matrix(0.952724,0,0,0.923248,-98.154591,-21.684168)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(94.117647%,74.901961%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:round;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.0823529;stroke-miterlimit:4;"
      d="M 341.641987 236.309601 C 345.278762 235.894965 351.39199 233.123669 353.569135 235.408401 C 355.442874 233.347911 362.044012 236.009201 366.283501 236.309601 C 363.208436 238.183927 356.980407 236.910401 354.020145 238.898964 C 351.129584 237.134643 344.753951 238.746648 341.641987 236.309601 Z M 341.641987 236.309601 "
      transform="matrix(0.952724,0,0,0.923248,-98.154591,-21.684168)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(94.117647%,74.901961%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.0823529;stroke-miterlimit:4;"
      d="M 347.501009 239.580153 C 353.015624 241.831037 354.25385 241.492558 360.215374 239.804395 C 358.977148 243.070715 355.815982 243.756135 354.024245 243.629205 C 352.171006 243.510738 349.637153 243.743442 347.501009 239.580153 Z M 347.501009 239.580153 "
      transform="matrix(0.952724,0,0,0.923248,-98.154591,-21.684168)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.14902;stroke-miterlimit:4;"
      d="M 343.170229 258.060531 C 339.192123 268.469489 343.549535 273.419635 341.602706 279.026513 C 339.730507 284.42228 331.577587 297.802357 337.782887 303.613454 C 342.710753 303.841636 336.929274 296.23728 346.793336 282.157776 C 351.368407 275.56492 345.599824 271.388689 350.41752 258.450832 C 350.86746 257.346105 343.796486 256.417991 343.170229 258.060531 Z M 343.170229 258.060531 "
      transform="matrix(-0.680979,-0.645681,-0.666308,0.659904,627.76047,264.88476)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.14902;stroke-miterlimit:4;"
      d="M 343.171953 258.059862 C 339.193235 268.470709 343.54894 273.420147 341.600918 279.024765 C 339.730002 284.423686 331.578433 297.800764 337.782808 303.614011 C 342.713416 303.840688 336.930657 296.236531 346.792906 282.159019 C 351.368912 275.564441 345.59895 271.387696 350.418934 258.449133 C 350.86728 257.344425 343.794854 256.420199 343.171953 258.059862 Z M 343.171953 258.059862 "
      transform="matrix(0.666671,0.659553,-0.680616,0.646032,153.46236,-194.031527)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.14902;stroke-miterlimit:4;"
      d="M 343.170806 258.060621 C 339.194258 268.469161 343.546924 273.417572 341.603284 279.025448 C 339.730366 284.423811 331.577887 297.800288 337.780277 303.61138 C 342.715058 303.841282 336.929681 296.236029 346.79594 282.156763 C 351.369871 275.562013 345.600018 271.388485 350.420912 258.449401 C 350.867275 257.343894 343.796032 256.421067 343.170806 258.060621 Z M 343.170806 258.060621 "
      transform="matrix(-0.00548429,-0.923224,-0.952712,0.00531458,451.305885,507.674745)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.14902;stroke-miterlimit:4;"
      d="M 342.96898 258.16031 C 341.442462 269.844857 342.350547 305.734959 346.007656 304.301067 C 350.054248 305.60524 350.590021 269.137953 350.219062 258.354912 C 350.165469 257.160137 343.184267 256.418061 342.96898 258.16031 Z M 342.96898 258.16031 "
      transform="matrix(-0.873969,-0.367556,-0.379286,0.846926,626.84049,123.171525)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.14902;stroke-miterlimit:4;"
      d="M 342.970295 258.159517 C 341.439534 269.843125 342.349397 305.735563 346.006539 304.302025 C 350.056162 305.607254 350.59094 269.136522 350.220936 258.353954 C 350.166054 257.159483 343.184958 256.416894 342.970295 258.159517 Z M 342.970295 258.159517 "
      transform="matrix(-0.678667,-0.647969,-0.668656,0.657665,620.93325,261.5922)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.14902;stroke-miterlimit:4;"
      d="M 342.969458 258.160944 C 341.442488 269.84215 342.348009 305.737157 346.007796 304.299445 C 350.055555 305.605633 350.589895 269.139516 350.21716 258.353789 C 350.165359 257.159783 343.18517 256.416874 342.969458 258.160944 Z M 342.969458 258.160944 "
      transform="matrix(-0.372265,-0.849844,-0.876983,0.360753,561.08613,396.36927)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 342.970108 258.161454 C 341.442722 269.842598 342.348153 305.738847 346.007955 304.299717 C 350.057008 305.607644 350.590112 269.137383 350.217785 258.354158 C 350.167014 257.161034 343.181657 256.418918 342.970108 258.161454 Z M 342.970108 258.161454 "
      transform="matrix(0,-0.923248,0.952724,0,30.88713,501.283305)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 343.170122 258.06069 C 339.193039 268.468913 343.54733 273.419166 341.603889 279.025221 C 339.73015 284.423958 331.57918 297.798102 337.78261 303.611475 C 342.715013 303.839948 336.929792 296.236868 346.794598 282.15615 C 351.370293 275.564275 345.597373 271.388293 350.419073 258.44994 C 350.865983 257.345653 343.797435 256.419068 343.170122 258.06069 Z M 343.170122 258.06069 "
      transform="matrix(-0.952724,0,0,0.923248,578.00892,-21.207231)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 343.170557 258.061703 C 339.192452 268.470661 343.546932 273.41794 341.603063 279.021793 C 339.727905 284.420584 331.577944 297.797637 337.783215 303.614626 C 342.71404 303.839784 336.929602 296.238451 346.793664 282.158948 C 351.371694 275.563068 345.597222 271.386993 350.417848 258.452003 C 350.867817 257.341386 343.796815 256.419163 343.170557 258.061703 Z M 343.170557 258.061703 "
      transform="matrix(0.680979,-0.645681,0.666308,0.659904,-147.632568,265.356855)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 343.170667 258.060885 C 339.191949 268.471732 343.550523 273.418241 341.602501 279.02286 C 339.731585 284.42178 331.577147 297.801787 337.781522 303.615035 C 342.712129 303.841711 336.929371 296.237554 346.794489 282.157113 C 351.370495 275.562535 345.597664 271.388719 350.417648 258.450156 C 350.868863 257.342519 343.796437 256.418294 343.170667 258.060885 Z M 343.170667 258.060885 "
      transform="matrix(-0.666671,0.659553,0.680616,0.646032,326.672805,-193.793058)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 343.170798 258.059277 C 339.194274 268.471916 343.546916 273.416227 341.603276 279.024104 C 339.730359 284.422466 331.577879 297.798943 337.780293 303.614135 C 342.71505 303.839937 336.929697 296.238784 346.795956 282.159518 C 351.369887 275.564768 345.60001 271.387141 350.420928 258.452156 C 350.867267 257.342549 343.796025 256.419723 343.170798 258.059277 Z M 343.170798 258.059277 "
      transform="matrix(0.00548429,-0.923224,0.952712,0.00531458,28.550865,507.674745)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 342.971359 258.16155 C 341.44108 269.844464 342.349165 305.734566 346.006274 304.300674 C 350.056627 305.606479 350.588639 269.13756 350.21768 258.354519 C 350.167848 257.161376 343.182885 256.417667 342.971359 258.16155 Z M 342.971359 258.16155 "
      transform="matrix(0.873969,-0.367556,0.379286,0.846926,-146.761008,123.655725)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 342.969528 258.158761 C 341.441687 269.845246 342.35155 305.737684 346.005772 304.301269 C 350.055394 305.606498 350.590173 269.135766 350.220169 258.353198 C 350.168207 257.161604 343.184191 256.416138 342.969528 258.158761 Z M 342.969528 258.158761 "
      transform="matrix(0.678667,-0.647969,0.668656,0.657665,-141.451755,261.5922)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 342.968849 258.15951 C 341.443481 269.84449 342.349002 305.739497 346.00879 304.301785 C 350.056548 305.607973 350.589287 269.138082 350.218153 258.356129 C 350.166352 257.162123 343.184561 256.415439 342.968849 258.15951 Z M 342.968849 258.15951 "
      transform="matrix(0.372265,-0.849844,0.876983,0.360753,-80.819021,396.36927)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.14902;stroke-miterlimit:4;"
      d="M 343.170557 258.061703 C 339.192452 268.470661 343.546932 273.41794 341.603063 279.021793 C 339.727905 284.420584 331.577944 297.797637 337.783215 303.614626 C 342.71404 303.839784 336.929602 296.238451 346.793664 282.158948 C 351.371694 275.563068 345.597222 271.386993 350.417848 258.452003 C 350.867817 257.341386 343.796815 256.419163 343.170557 258.061703 Z M 343.170557 258.061703 "
      transform="matrix(0.680979,-0.645681,0.666308,0.659904,-147.632568,265.356855)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.14902;stroke-miterlimit:4;"
      d="M 343.170667 258.060885 C 339.191949 268.471732 343.550523 273.418241 341.602501 279.02286 C 339.731585 284.42178 331.577147 297.801787 337.781522 303.615035 C 342.712129 303.841711 336.929371 296.237554 346.794489 282.157113 C 351.370495 275.562535 345.597664 271.388719 350.417648 258.450156 C 350.868863 257.342519 343.796437 256.418294 343.170667 258.060885 Z M 343.170667 258.060885 "
      transform="matrix(-0.666671,0.659553,0.680616,0.646032,326.672805,-193.793058)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.14902;stroke-miterlimit:4;"
      d="M 343.170798 258.059277 C 339.194274 268.471916 343.546916 273.416227 341.603276 279.024104 C 339.730359 284.422466 331.577879 297.798943 337.780293 303.614135 C 342.71505 303.839937 336.929697 296.238784 346.795956 282.159518 C 351.369887 275.564768 345.60001 271.387141 350.420928 258.452156 C 350.867267 257.342549 343.796025 256.419723 343.170798 258.059277 Z M 343.170798 258.059277 "
      transform="matrix(0.00548429,-0.923224,0.952712,0.00531458,28.550865,507.674745)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.14902;stroke-miterlimit:4;"
      d="M 342.971359 258.16155 C 341.44108 269.844464 342.349165 305.734566 346.006274 304.300674 C 350.056627 305.606479 350.588639 269.13756 350.21768 258.354519 C 350.167848 257.161376 343.182885 256.417667 342.971359 258.16155 Z M 342.971359 258.16155 "
      transform="matrix(0.873969,-0.367556,0.379286,0.846926,-146.761008,123.655725)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.14902;stroke-miterlimit:4;"
      d="M 342.969528 258.158761 C 341.441687 269.845246 342.35155 305.737684 346.005772 304.301269 C 350.055394 305.606498 350.590173 269.135766 350.220169 258.353198 C 350.168207 257.161604 343.184191 256.416138 342.969528 258.158761 Z M 342.969528 258.158761 "
      transform="matrix(0.678667,-0.647969,0.668656,0.657665,-141.451755,261.5922)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.14902;stroke-miterlimit:4;"
      d="M 342.968849 258.15951 C 341.443481 269.84449 342.349002 305.739497 346.00879 304.301785 C 350.056548 305.607973 350.589287 269.138082 350.218153 258.356129 C 350.166352 257.162123 343.184561 256.415439 342.968849 258.15951 Z M 342.968849 258.15951 "
      transform="matrix(0.372265,-0.849844,0.876983,0.360753,-80.819021,396.36927)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 342.970192 258.159151 C 341.442806 269.844394 342.348237 305.736543 346.008039 304.301513 C 350.057092 305.60534 350.590196 269.13918 350.217869 258.355955 C 350.167098 257.16283 343.181741 256.416614 342.970192 258.159151 Z M 342.970192 258.159151 "
      transform="matrix(0,0.923248,-0.952724,0,448.860675,-138.314633)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 343.172021 258.05989 C 339.194938 268.468113 343.549228 273.418366 341.601688 279.024421 C 339.727949 284.423158 331.576979 297.801533 337.780408 303.614906 C 342.712811 303.839148 336.93169 296.236068 346.792396 282.159581 C 351.368092 275.563475 345.599271 271.387493 350.420972 258.44914 C 350.867881 257.344853 343.795234 256.418268 343.172021 258.05989 Z M 343.172021 258.05989 "
      transform="matrix(0.952724,0,0,-0.923248,-98.24901,384.16743)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 343.171297 258.059057 C 339.193191 268.468015 343.550603 273.418161 341.603774 279.025039 C 339.728616 284.42383 331.578656 297.800883 337.783955 303.61198 C 342.711821 303.840162 336.930342 296.235805 346.794404 282.156302 C 351.369475 275.563446 345.597933 271.390238 350.418588 258.449357 C 350.868528 257.344631 343.794595 256.419541 343.171297 258.059057 Z M 343.171297 258.059057 "
      transform="matrix(-0.680979,0.645681,-0.666308,-0.659904,627.385215,97.605765)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 343.170998 258.058552 C 339.192281 268.4694 343.547986 273.418837 341.602986 279.026417 C 339.729048 284.422377 331.577479 297.799455 337.781854 303.612702 C 342.712461 303.839379 336.929703 296.235222 346.791952 282.157709 C 351.367958 275.563132 345.597996 271.386386 350.421002 258.450784 C 350.866326 257.343115 343.796922 256.421851 343.170998 258.058552 Z M 343.170998 258.058552 "
      transform="matrix(0.666671,-0.659553,-0.680616,-0.646032,153.087105,556.76052)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 343.170002 258.061483 C 339.193454 268.470022 343.550351 273.418409 341.602456 279.02221 C 339.729539 284.420572 331.577083 297.801149 337.783704 303.612217 C 342.714254 303.842143 336.928877 296.23689 346.795136 282.157624 C 351.369068 275.562874 345.599214 271.389347 350.420108 258.450262 C 350.866471 257.344755 343.795228 256.421929 343.170002 258.061483 Z M 343.170002 258.061483 "
      transform="matrix(-0.00548429,0.923224,-0.952712,-0.00531458,451.209045,-144.716967)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 342.970938 258.159198 C 341.44066 269.842112 342.350822 305.737728 346.009615 304.299955 C 350.056207 305.604127 350.59198 269.136841 350.221021 258.353799 C 350.167428 257.159024 343.182465 256.415315 342.970938 258.159198 Z M 342.970938 258.159198 "
      transform="matrix(-0.873969,0.367556,-0.379286,-0.846926,626.513655,239.319)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 342.969817 258.161604 C 341.442025 269.842198 342.348919 305.73765 346.00903 304.301099 C 350.055732 305.60345 350.590462 269.138609 350.220458 258.356042 C 350.165576 257.16157 343.181559 256.416103 342.969817 258.161604 Z M 342.969817 258.161604 "
      transform="matrix(-0.678667,0.647969,-0.668656,-0.657665,621.211665,101.37042)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 342.968469 258.160683 C 341.441498 269.841889 342.350914 305.735242 346.006807 304.299184 C 350.054565 305.605372 350.588906 269.139255 350.217772 258.357302 C 350.165972 257.163296 343.18418 256.416612 342.968469 258.160683 Z M 342.968469 258.160683 "
      transform="matrix(-0.372265,0.849844,-0.876983,-0.360753,560.57772,-33.411492)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.14902;stroke-miterlimit:4;"
      d="M 343.171297 258.059057 C 339.193191 268.468015 343.550603 273.418161 341.603774 279.025039 C 339.728616 284.42383 331.578656 297.800883 337.783955 303.61198 C 342.711821 303.840162 336.930342 296.235805 346.794404 282.156302 C 351.369475 275.563446 345.597933 271.390238 350.418588 258.449357 C 350.868528 257.344631 343.794595 256.419541 343.171297 258.059057 Z M 343.171297 258.059057 "
      transform="matrix(-0.680979,0.645681,-0.666308,-0.659904,627.385215,97.605765)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.14902;stroke-miterlimit:4;"
      d="M 343.170998 258.058552 C 339.192281 268.4694 343.547986 273.418837 341.602986 279.026417 C 339.729048 284.422377 331.577479 297.799455 337.781854 303.612702 C 342.712461 303.839379 336.929703 296.235222 346.791952 282.157709 C 351.367958 275.563132 345.597996 271.386386 350.421002 258.450784 C 350.866326 257.343115 343.796922 256.421851 343.170998 258.058552 Z M 343.170998 258.058552 "
      transform="matrix(0.666671,-0.659553,-0.680616,-0.646032,153.087105,556.76052)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.14902;stroke-miterlimit:4;"
      d="M 343.170002 258.061483 C 339.193454 268.470022 343.550351 273.418409 341.602456 279.02221 C 339.729539 284.420572 331.577083 297.801149 337.783704 303.612217 C 342.714254 303.842143 336.928877 296.23689 346.795136 282.157624 C 351.369068 275.562874 345.599214 271.389347 350.420108 258.450262 C 350.866471 257.344755 343.795228 256.421929 343.170002 258.061483 Z M 343.170002 258.061483 "
      transform="matrix(-0.00548429,0.923224,-0.952712,-0.00531458,451.209045,-144.716967)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.14902;stroke-miterlimit:4;"
      d="M 342.970938 258.159198 C 341.44066 269.842112 342.350822 305.737728 346.009615 304.299955 C 350.056207 305.604127 350.59198 269.136841 350.221021 258.353799 C 350.167428 257.159024 343.182465 256.415315 342.970938 258.159198 Z M 342.970938 258.159198 "
      transform="matrix(-0.873969,0.367556,-0.379286,-0.846926,626.513655,239.319)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.14902;stroke-miterlimit:4;"
      d="M 342.969817 258.161604 C 341.442025 269.842198 342.348919 305.73765 346.00903 304.301099 C 350.055732 305.60345 350.590462 269.138609 350.220458 258.356042 C 350.165576 257.16157 343.181559 256.416103 342.969817 258.161604 Z M 342.969817 258.161604 "
      transform="matrix(-0.678667,0.647969,-0.668656,-0.657665,621.211665,101.37042)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.14902;stroke-miterlimit:4;"
      d="M 342.968469 258.160683 C 341.441498 269.841889 342.350914 305.735242 346.006807 304.299184 C 350.054565 305.605372 350.588906 269.139255 350.217772 258.357302 C 350.165972 257.163296 343.18418 256.416612 342.968469 258.160683 Z M 342.968469 258.160683 "
      transform="matrix(-0.372265,0.849844,-0.876983,-0.360753,560.57772,-33.411492)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 342.971981 258.159807 C 341.442649 269.841557 342.348768 305.737235 346.006044 304.302931 C 350.056929 305.606074 350.58994 269.139214 350.220932 258.354433 C 350.167631 257.161295 343.181085 256.416642 342.971981 258.159807 Z M 342.971981 258.159807 "
      transform="matrix(-0.952724,0,0,-0.923248,569.644365,383.99796)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 343.168888 258.060436 C 339.191762 268.470553 343.549677 273.419357 341.603423 279.024174 C 339.729097 284.423986 331.575989 297.798465 337.782844 303.612387 C 342.711941 303.841991 336.928185 296.236333 346.794842 282.156639 C 351.368537 275.563701 345.597473 271.389814 350.420796 258.449944 C 350.869281 257.342921 343.795074 256.420402 343.168888 258.060436 Z M 343.168888 258.060436 "
      transform="matrix(0,0.923248,0.952724,0,30.47556,-146.181672)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 343.17049 258.061061 C 339.194373 268.471023 343.546829 273.418055 341.601834 279.023686 C 339.727943 284.423551 331.57757 297.799012 337.782174 303.611812 C 342.713035 303.841722 336.92908 296.237797 346.792615 282.158054 C 351.368072 275.564016 345.598758 271.387473 350.4178 258.452318 C 350.868416 257.344445 343.798397 256.421103 343.17049 258.061061 Z M 343.17049 258.061061 "
      transform="matrix(-0.666308,-0.659904,0.680979,-0.645681,326.188605,557.00262)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 343.169925 258.058615 C 339.192048 268.471221 343.546854 273.417845 341.602884 279.023549 C 339.730697 284.421203 331.579342 297.799672 337.781552 303.614139 C 342.712287 303.841953 336.931905 296.237469 346.79186 282.157622 C 351.36758 275.564327 345.59825 271.387992 350.420011 258.449797 C 350.86762 257.342438 343.797039 256.422296 343.169925 258.058615 Z M 343.169925 258.058615 "
      transform="matrix(0.680616,0.646032,0.666671,-0.659553,-147.626516,97.37577)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 343.169864 258.059513 C 339.191155 268.469757 343.549374 273.416207 341.601296 279.025161 C 339.7299 284.422119 331.57794 297.80123 337.782102 303.61289 C 342.71179 303.842609 336.931926 296.238752 346.793692 282.155899 C 351.368394 275.562904 345.595982 271.390423 350.417051 258.449366 C 350.869895 257.342443 343.795938 256.418474 343.169864 258.059513 Z M 343.169864 258.059513 "
      transform="matrix(-0.952712,-0.00531458,0.00548429,-0.923224,576.24159,386.2737)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 342.969728 258.159972 C 341.442118 269.841923 342.348365 305.736874 346.00567 304.302657 C 350.057306 305.605096 350.58988 269.136726 350.21825 258.355789 C 350.164637 257.161499 343.182616 256.415375 342.969728 258.159972 Z M 342.969728 258.159972 "
      transform="matrix(-0.379286,-0.846926,0.873969,-0.367556,179.9481,556.15527)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 342.968875 258.161089 C 341.442721 269.843915 342.350097 305.738743 346.007098 304.299746 C 350.054538 305.605295 350.588862 269.139405 350.218537 258.356599 C 350.167297 257.160716 343.184733 256.416801 342.968875 258.161089 Z M 342.968875 258.161089 "
      transform="matrix(-0.668656,-0.657665,0.678667,-0.647969,322.290795,551.010645)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 342.969981 258.161077 C 341.442571 269.840894 342.350044 305.73533 346.008643 304.301785 C 350.055376 305.608426 350.591524 269.137954 350.219644 258.356302 C 350.166503 257.160806 343.182486 256.416154 342.969981 258.161077 Z M 342.969981 258.161077 "
      transform="matrix(-0.876983,-0.360753,0.372265,-0.849844,461.38935,492.252975)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.14902;stroke-miterlimit:4;"
      d="M 343.17049 258.061061 C 339.194373 268.471023 343.546829 273.418055 341.601834 279.023686 C 339.727943 284.423551 331.57757 297.799012 337.782174 303.611812 C 342.713035 303.841722 336.92908 296.237797 346.792615 282.158054 C 351.368072 275.564016 345.598758 271.387473 350.4178 258.452318 C 350.868416 257.344445 343.798397 256.421103 343.17049 258.061061 Z M 343.17049 258.061061 "
      transform="matrix(-0.666308,-0.659904,0.680979,-0.645681,326.188605,557.00262)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.14902;stroke-miterlimit:4;"
      d="M 343.169925 258.058615 C 339.192048 268.471221 343.546854 273.417845 341.602884 279.023549 C 339.730697 284.421203 331.579342 297.799672 337.781552 303.614139 C 342.712287 303.841953 336.931905 296.237469 346.79186 282.157622 C 351.36758 275.564327 345.59825 271.387992 350.420011 258.449797 C 350.86762 257.342438 343.797039 256.422296 343.169925 258.058615 Z M 343.169925 258.058615 "
      transform="matrix(0.680616,0.646032,0.666671,-0.659553,-147.626516,97.37577)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.14902;stroke-miterlimit:4;"
      d="M 343.169864 258.059513 C 339.191155 268.469757 343.549374 273.416207 341.601296 279.025161 C 339.7299 284.422119 331.57794 297.80123 337.782102 303.61289 C 342.71179 303.842609 336.931926 296.238752 346.793692 282.155899 C 351.368394 275.562904 345.595982 271.390423 350.417051 258.449366 C 350.869895 257.342443 343.795938 256.418474 343.169864 258.059513 Z M 343.169864 258.059513 "
      transform="matrix(-0.952712,-0.00531458,0.00548429,-0.923224,576.24159,386.2737)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.14902;stroke-miterlimit:4;"
      d="M 342.969728 258.159972 C 341.442118 269.841923 342.348365 305.736874 346.00567 304.302657 C 350.057306 305.605096 350.58988 269.136726 350.21825 258.355789 C 350.164637 257.161499 343.182616 256.415375 342.969728 258.159972 Z M 342.969728 258.159972 "
      transform="matrix(-0.379286,-0.846926,0.873969,-0.367556,179.9481,556.15527)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.14902;stroke-miterlimit:4;"
      d="M 342.968875 258.161089 C 341.442721 269.843915 342.350097 305.738743 346.007098 304.299746 C 350.054538 305.605295 350.588862 269.139405 350.218537 258.356599 C 350.167297 257.160716 343.184733 256.416801 342.968875 258.161089 Z M 342.968875 258.161089 "
      transform="matrix(-0.668656,-0.657665,0.678667,-0.647969,322.290795,551.010645)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.14902;stroke-miterlimit:4;"
      d="M 342.969981 258.161077 C 341.442571 269.840894 342.350044 305.73533 346.008643 304.301785 C 350.055376 305.608426 350.591524 269.137954 350.219644 258.356302 C 350.166503 257.160806 343.182486 256.416154 342.969981 258.161077 Z M 342.969981 258.161077 "
      transform="matrix(-0.876983,-0.360753,0.372265,-0.849844,461.38935,492.252975)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.172549;stroke-miterlimit:4;"
      d="M 328.139895 202.548651 L 324.991029 202.548651 "
      transform="matrix(0.952724,0,0,0.923248,-97.661918,-20.967552)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.172549;stroke-miterlimit:4;"
      d="M 328.14043 202.550906 L 324.987464 202.550906 "
      transform="matrix(0.952724,0,0,0.923248,-98.412428,-19.09854)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.172549;stroke-miterlimit:4;"
      d="M 328.140781 202.550371 L 324.989282 202.550371 "
      transform="matrix(1.054805,0,0,0.923248,-132.230166,-17.33242)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.172549;stroke-miterlimit:4;"
      d="M 328.141033 202.551276 L 324.989497 202.551276 "
      transform="matrix(1.054793,0,0,0.923248,-133.191303,-15.669194)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.172549;stroke-miterlimit:4;"
      d="M 328.140687 202.55074 L 324.988183 202.55074 "
      transform="matrix(1.25892,0,0,0.923248,-200.395842,-13.903074)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.172549;stroke-miterlimit:4;"
      d="M 328.140393 202.550204 L 324.990146 202.550204 "
      transform="matrix(1.39498,0,0,0.923248,-244.944663,-12.136955)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.172549;stroke-miterlimit:4;"
      d="M 328.139931 202.549708 L 324.990085 202.549708 "
      transform="matrix(1.32695,0,0,0.923248,-223.257345,-10.163839)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.172549;stroke-miterlimit:4;"
      d="M 328.140345 202.547899 L 324.989205 202.547899 "
      transform="matrix(1.292935,0,0,0.923248,-211.877435,-8.189514)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.172549;stroke-miterlimit:4;"
      d="M 328.140372 202.548843 L 324.987583 202.548843 "
      transform="matrix(1.32695,0,0,0.923248,-223.043087,-6.319291)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.172549;stroke-miterlimit:4;"
      d="M 328.140817 202.551305 L 324.989522 202.551305 "
      transform="matrix(1.667222,0,0,0.923248,-333.61863,-4.13797)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.172549;stroke-miterlimit:4;"
      d="M 328.140171 202.549742 L 324.988372 202.549742 "
      transform="matrix(1.428995,0,0,0.923248,-255.891215,-1.64434)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0784314;stroke-miterlimit:4;"
      d="M 328.141319 202.549207 L 324.98952 202.549207 "
      transform="matrix(1.428995,0,0,0.923248,-255.78348,0.121779)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0784314;stroke-miterlimit:4;"
      d="M 328.140962 202.550396 L 324.989163 202.550396 "
      transform="matrix(1.428995,0,0,0.923248,-255.03297,2.511306)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0784314;stroke-miterlimit:4;"
      d="M 328.140224 202.550067 L 324.988424 202.550067 "
      transform="matrix(1.428995,0,0,0.923248,-254.926446,4.589735)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0784314;stroke-miterlimit:4;"
      d="M 328.1391 202.549777 L 324.990035 202.549777 "
      transform="matrix(1.428995,0,0,0.923248,-253.639685,6.875159)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0784314;stroke-miterlimit:4;"
      d="M 328.140711 202.550721 L 324.988911 202.550721 "
      transform="matrix(1.428995,0,0,0.923248,-252.352923,8.745381)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0784314;stroke-miterlimit:4;"
      d="M 328.141173 202.548952 L 324.989373 202.548952 "
      transform="matrix(1.428995,0,0,0.923248,-251.173896,10.926702)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0784314;stroke-miterlimit:4;"
      d="M 328.141197 202.549934 L 324.989398 202.549934 "
      transform="matrix(1.428995,0,0,0.923248,-249.7794,13.00392)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0784314;stroke-miterlimit:4;"
      d="M 328.14125 202.549437 L 324.989451 202.549437 "
      transform="matrix(1.428995,0,0,0.923248,-248.814632,14.977035)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0784314;stroke-miterlimit:4;"
      d="M 328.140617 202.551222 L 324.988818 202.551222 "
      transform="matrix(1.428995,0,0,0.923248,-246.778571,17.373825)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0784314;stroke-miterlimit:4;"
      d="M 328.138161 202.552075 L 324.987386 202.552075 "
      transform="matrix(0.918673,0,0,0.923248,-78.447651,19.552725)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0784314;stroke-miterlimit:4;"
      d="M 328.141319 202.549207 L 324.98952 202.549207 "
      transform="matrix(1.428995,0,0,0.923248,-255.78348,0.121779)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0784314;stroke-miterlimit:4;"
      d="M 328.140962 202.550396 L 324.989163 202.550396 "
      transform="matrix(1.428995,0,0,0.923248,-255.03297,2.511306)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0784314;stroke-miterlimit:4;"
      d="M 328.140224 202.550067 L 324.988424 202.550067 "
      transform="matrix(1.428995,0,0,0.923248,-254.926446,4.589735)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0784314;stroke-miterlimit:4;"
      d="M 328.1391 202.549777 L 324.990035 202.549777 "
      transform="matrix(1.428995,0,0,0.923248,-253.639685,6.875159)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0784314;stroke-miterlimit:4;"
      d="M 328.140711 202.550721 L 324.988911 202.550721 "
      transform="matrix(1.428995,0,0,0.923248,-252.352923,8.745381)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0784314;stroke-miterlimit:4;"
      d="M 328.141173 202.548952 L 324.989373 202.548952 "
      transform="matrix(1.428995,0,0,0.923248,-251.173896,10.926702)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0784314;stroke-miterlimit:4;"
      d="M 328.141197 202.549934 L 324.989398 202.549934 "
      transform="matrix(1.428995,0,0,0.923248,-249.7794,13.00392)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0784314;stroke-miterlimit:4;"
      d="M 328.14125 202.549437 L 324.989451 202.549437 "
      transform="matrix(1.428995,0,0,0.923248,-248.814632,14.977035)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0784314;stroke-miterlimit:4;"
      d="M 328.140617 202.551222 L 324.988818 202.551222 "
      transform="matrix(1.428995,0,0,0.923248,-246.778571,17.373825)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0784314;stroke-miterlimit:4;"
      d="M 328.141108 202.549888 L 324.990333 202.549888 "
      transform="matrix(0.918673,0,0,0.923248,-77.981608,19.37115)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.133333;stroke-miterlimit:4;"
      d="M 328.139484 202.550368 L 324.990618 202.550368 "
      transform="matrix(-0.952724,0,0,0.923248,577.28262,-21.445699)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.133333;stroke-miterlimit:4;"
      d="M 328.140019 202.551312 L 324.987054 202.551312 "
      transform="matrix(-0.952724,0,0,0.923248,578.03313,-19.575477)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.133333;stroke-miterlimit:4;"
      d="M 328.139783 202.550776 L 324.988284 202.550776 "
      transform="matrix(-1.054805,0,0,0.923248,611.842395,-17.809358)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.133333;stroke-miterlimit:4;"
      d="M 328.139514 202.548761 L 324.987979 202.548761 "
      transform="matrix(-1.054793,0,0,0.923248,612.810795,-16.147341)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.133333;stroke-miterlimit:4;"
      d="M 328.141337 202.548226 L 324.988834 202.548226 "
      transform="matrix(-1.25892,0,0,0.923248,680.017755,-14.381221)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.133333;stroke-miterlimit:4;"
      d="M 328.139244 202.551921 L 324.988998 202.551921 "
      transform="matrix(-1.39498,0,0,0.923248,724.564155,-12.615102)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.133333;stroke-miterlimit:4;"
      d="M 328.140962 202.550113 L 324.988173 202.550113 "
      transform="matrix(-1.32695,0,0,0.923248,702.871995,-10.640776)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.133333;stroke-miterlimit:4;"
      d="M 328.139319 202.548305 L 324.988179 202.548305 "
      transform="matrix(-1.292935,0,0,0.923248,691.493295,-8.666451)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.133333;stroke-miterlimit:4;"
      d="M 328.138958 202.549249 L 324.989112 202.549249 "
      transform="matrix(-1.32695,0,0,0.923248,702.66621,-6.796228)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.133333;stroke-miterlimit:4;"
      d="M 328.139295 202.55171 L 324.988 202.55171 "
      transform="matrix(-1.667222,0,0,0.923248,813.23328,-4.614908)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.133333;stroke-miterlimit:4;"
      d="M 328.139242 202.550148 L 324.990177 202.550148 "
      transform="matrix(-1.428995,0,0,0.923248,735.507075,-2.121277)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0627451;stroke-miterlimit:4;"
      d="M 328.139543 202.549612 L 324.987744 202.549612 "
      transform="matrix(-1.428995,0,0,0.923248,735.39813,-0.355158)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0627451;stroke-miterlimit:4;"
      d="M 328.139186 202.550802 L 324.990121 202.550802 "
      transform="matrix(-1.428995,0,0,0.923248,734.64762,2.034369)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0627451;stroke-miterlimit:4;"
      d="M 328.139487 202.551784 L 324.987688 202.551784 "
      transform="matrix(-1.428995,0,0,0.923248,734.538675,4.111587)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0627451;stroke-miterlimit:4;"
      d="M 328.140905 202.550183 L 324.989106 202.550183 "
      transform="matrix(-1.428995,0,0,0.923248,733.255545,6.398222)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0627451;stroke-miterlimit:4;"
      d="M 328.13959 202.551127 L 324.98779 202.551127 "
      transform="matrix(-1.428995,0,0,0.923248,731.972415,8.268444)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0627451;stroke-miterlimit:4;"
      d="M 328.140436 202.549357 L 324.988637 202.549357 "
      transform="matrix(-1.428995,0,0,0.923248,730.786125,10.449765)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0627451;stroke-miterlimit:4;"
      d="M 328.139422 202.549326 L 324.990356 202.549326 "
      transform="matrix(-1.428995,0,0,0.923248,729.39405,12.531825)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0627451;stroke-miterlimit:4;"
      d="M 328.139937 202.548829 L 324.988138 202.548829 "
      transform="matrix(-1.428995,0,0,0.923248,728.437755,14.50494)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0627451;stroke-miterlimit:4;"
      d="M 328.140728 202.551032 L 324.988928 202.551032 "
      transform="matrix(-1.428995,0,0,0.923248,726.39201,16.889625)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0627451;stroke-miterlimit:4;"
      d="M 328.141268 202.551885 L 324.990492 202.551885 "
      transform="matrix(-0.918673,0,0,0.923248,558.05988,19.068525)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0627451;stroke-miterlimit:4;"
      d="M 328.139543 202.549612 L 324.987744 202.549612 "
      transform="matrix(-1.428995,0,0,0.923248,735.39813,-0.355158)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0627451;stroke-miterlimit:4;"
      d="M 328.139186 202.550802 L 324.990121 202.550802 "
      transform="matrix(-1.428995,0,0,0.923248,734.64762,2.034369)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0627451;stroke-miterlimit:4;"
      d="M 328.139487 202.551784 L 324.987688 202.551784 "
      transform="matrix(-1.428995,0,0,0.923248,734.538675,4.111587)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0627451;stroke-miterlimit:4;"
      d="M 328.140905 202.550183 L 324.989106 202.550183 "
      transform="matrix(-1.428995,0,0,0.923248,733.255545,6.398222)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0627451;stroke-miterlimit:4;"
      d="M 328.13959 202.551127 L 324.98779 202.551127 "
      transform="matrix(-1.428995,0,0,0.923248,731.972415,8.268444)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0627451;stroke-miterlimit:4;"
      d="M 328.140436 202.549357 L 324.988637 202.549357 "
      transform="matrix(-1.428995,0,0,0.923248,730.786125,10.449765)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0627451;stroke-miterlimit:4;"
      d="M 328.139422 202.549326 L 324.990356 202.549326 "
      transform="matrix(-1.428995,0,0,0.923248,729.39405,12.531825)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0627451;stroke-miterlimit:4;"
      d="M 328.139937 202.548829 L 324.988138 202.548829 "
      transform="matrix(-1.428995,0,0,0.923248,728.437755,14.50494)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0627451;stroke-miterlimit:4;"
      d="M 328.140728 202.551032 L 324.988928 202.551032 "
      transform="matrix(-1.428995,0,0,0.923248,726.39201,16.889625)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.0627451;stroke-miterlimit:4;"
      d="M 328.141268 202.551885 L 324.990492 202.551885 "
      transform="matrix(-0.918673,0,0,0.923248,558.05988,19.068525)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(94.117647%,74.901961%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 330.839635 211.829324 C 338.363292 207.00177 348.305999 209.519206 352.467586 212.146648 C 346.378959 210.991589 346.272357 210.466947 341.864765 210.361172 C 338.7487 210.466947 334.164804 210.149623 330.839635 211.829324 Z M 330.839635 211.829324 "
      transform="matrix(-0.952724,0,0,0.923248,577.7184,-21.922636)"
    />
    <path
      style="fill:none;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:0.388235;stroke-miterlimit:4;"
      d="M 348.060636 211.300654 C 344.38696 218.962968 337.982626 219.068742 333.464332 210.881786 "
      transform="matrix(0.952724,0,0,0.923248,-73.277605,-22.161105)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:0.368627;"
      d="M 256.445312 175.152344 C 255.371094 176.238281 252.746094 176.886719 250.582031 176.597656 C 248.417969 176.304688 247.53125 175.1875 248.605469 174.101562 C 249.675781 173.015625 252.300781 172.367188 254.464844 172.660156 C 256.628906 172.949219 257.515625 174.066406 256.445312 175.152344 Z M 256.445312 175.152344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:1;"
      d="M 251.304688 173.636719 C 251.410156 173.902344 251.082031 174.253906 250.566406 174.421875 C 250.050781 174.589844 249.546875 174.511719 249.441406 174.25 C 249.332031 173.984375 249.664062 173.632812 250.179688 173.464844 C 250.691406 173.296875 251.199219 173.375 251.304688 173.636719 Z M 251.304688 173.636719 "
    />
  </g>
</svg>
