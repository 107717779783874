<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(87.058824%,16.078431%,6.27451%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,87.058824%,0%);fill-opacity:1;"
      d="M 58.257812 133.6875 L 90 36 L 121.742188 133.6875 L 38.644531 73.3125 L 141.355469 73.3125 Z M 58.257812 133.6875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,87.058824%,0%);fill-opacity:1;"
      d="M 197.929688 37.582031 L 164.566406 45.261719 L 187.042969 19.433594 L 184.039062 53.542969 L 166.421875 24.183594 Z M 197.929688 37.582031 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,87.058824%,0%);fill-opacity:1;"
      d="M 231.914062 80.414062 L 198.179688 74.546875 L 228.917969 59.464844 L 212.914062 89.734375 L 208.074219 55.839844 Z M 231.914062 80.414062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,87.058824%,0%);fill-opacity:1;"
      d="M 227.09375 140.171875 L 198.691406 121.054688 L 232.910156 119.828125 L 205.949219 140.933594 L 215.355469 108.011719 Z M 227.09375 140.171875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,87.058824%,0%);fill-opacity:1;"
      d="M 184.761719 179.359375 L 165.945312 150.753906 L 197.980469 162.835938 L 164.960938 171.894531 L 186.351562 145.15625 Z M 184.761719 179.359375 "
    />
  </g>
</svg>
