<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(100%,100%,100%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(58.039216%,67.45098%,83.137255%);fill-opacity:1;"
      d="M 0 0 L 720 0 L 720 124.605469 L 0 124.605469 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(71.764706%,18.823529%,0%);fill-opacity:1;"
      d="M 0 116.355469 L 720 116.355469 L 720 124.609375 L 0 124.609375 Z M 0 116.355469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,66.27451%,0%);fill-opacity:1;"
      d="M 0 243.644531 L 720 243.644531 L 720 360 L 0 360 Z M 0 243.644531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(71.764706%,18.823529%,0%);fill-opacity:1;"
      d="M 0 243.644531 L 720 243.644531 L 720 251.898438 L 0 251.898438 Z M 0 243.644531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,100%,100%);fill-opacity:1;"
      d="M 189.488281 37.042969 L 182.785156 32.429688 L 176.148438 37.148438 L 178.4375 29.308594 L 171.929688 24.429688 L 180.046875 24.191406 L 182.652344 16.449219 L 185.382812 24.144531 L 193.507812 24.246094 L 187.078125 29.234375 Z M 189.488281 37.042969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,100%,100%);fill-opacity:1;"
      d="M 157.871094 99.253906 L 151.167969 94.640625 L 144.53125 99.359375 L 146.820312 91.519531 L 140.308594 86.640625 L 148.425781 86.402344 L 151.03125 78.660156 L 153.765625 86.355469 L 161.890625 86.457031 L 155.460938 91.445312 Z M 157.871094 99.253906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,100%,100%);fill-opacity:1;"
      d="M 189.792969 99.253906 L 183.089844 94.640625 L 176.453125 99.359375 L 178.742188 91.519531 L 172.234375 86.640625 L 180.351562 86.402344 L 182.957031 78.660156 L 185.6875 86.355469 L 193.8125 86.457031 L 187.382812 91.445312 Z M 189.792969 99.253906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,100%,100%);fill-opacity:1;"
      d="M 219.28125 99.253906 L 212.578125 94.640625 L 205.941406 99.359375 L 208.230469 91.519531 L 201.71875 86.640625 L 209.835938 86.402344 L 212.441406 78.660156 L 215.175781 86.355469 L 223.300781 86.457031 L 216.871094 91.445312 Z M 219.28125 99.253906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,100%,100%);fill-opacity:1;"
      d="M 246.648438 99.253906 L 239.945312 94.640625 L 233.308594 99.359375 L 235.597656 91.519531 L 229.085938 86.640625 L 237.207031 86.402344 L 239.808594 78.660156 L 242.542969 86.355469 L 250.667969 86.457031 L 244.238281 91.445312 Z M 246.648438 99.253906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,100%,100%);fill-opacity:1;"
      d="M 124.429688 99.253906 L 117.722656 94.640625 L 111.085938 99.359375 L 113.378906 91.519531 L 106.867188 86.640625 L 114.984375 86.402344 L 117.589844 78.660156 L 120.324219 86.355469 L 128.445312 86.457031 L 122.015625 91.445312 Z M 124.429688 99.253906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,100%,100%);fill-opacity:1;"
      d="M 157.261719 68.375 L 150.558594 63.761719 L 143.921875 68.484375 L 146.210938 60.644531 L 139.703125 55.761719 L 147.820312 55.527344 L 150.425781 47.785156 L 153.15625 55.480469 L 161.28125 55.582031 L 154.851562 60.570312 Z M 157.261719 68.375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,100%,100%);fill-opacity:1;"
      d="M 189.488281 68.375 L 182.785156 63.761719 L 176.148438 68.484375 L 178.4375 60.644531 L 171.929688 55.761719 L 180.046875 55.527344 L 182.652344 47.785156 L 185.382812 55.480469 L 193.507812 55.582031 L 187.078125 60.570312 Z M 189.488281 68.375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,100%,100%);fill-opacity:1;"
      d="M 219.890625 68.375 L 213.183594 63.761719 L 206.546875 68.484375 L 208.835938 60.644531 L 202.328125 55.761719 L 210.445312 55.527344 L 213.050781 47.785156 L 215.78125 55.480469 L 223.90625 55.582031 L 217.476562 60.570312 Z M 219.890625 68.375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,100%,100%);fill-opacity:1;"
      d="M 246.339844 68.375 L 239.636719 63.761719 L 233 68.484375 L 235.289062 60.644531 L 228.78125 55.761719 L 236.898438 55.527344 L 239.503906 47.785156 L 242.234375 55.480469 L 250.359375 55.582031 L 243.929688 60.570312 Z M 246.339844 68.375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,100%,100%);fill-opacity:1;"
      d="M 246.042969 37.042969 L 239.335938 32.429688 L 232.699219 37.148438 L 234.992188 29.308594 L 228.480469 24.429688 L 236.597656 24.191406 L 239.203125 16.449219 L 241.9375 24.144531 L 250.058594 24.246094 L 243.628906 29.234375 Z M 246.042969 37.042969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,100%,100%);fill-opacity:1;"
      d="M 219.589844 37.042969 L 212.882812 32.429688 L 206.246094 37.148438 L 208.539062 29.308594 L 202.027344 24.429688 L 210.144531 24.191406 L 212.75 16.449219 L 215.484375 24.144531 L 223.605469 24.246094 L 217.175781 29.234375 Z M 219.589844 37.042969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 103.371094 18.832031 C 67.492188 9 44.769531 34.398438 45.109375 57.160156 C 45.453125 79.921875 66.429688 104.023438 98.8125 98.011719 C 47.933594 121.851562 21.914062 84.300781 22.957031 54.484375 C 24.742188 19.246094 64.351562 -9.496094 103.371094 18.832031 Z M 103.371094 18.832031 "
    />
  </g>
</svg>
