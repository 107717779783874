<script>
  import styles from "styleguide/ConfirmationSignerFooter.json";
  import { theme } from "stores/theme.js";
  import { t } from "stores/i18n.js";
  import { footerLinks } from "stores/confirmation/confirmation_signer.js";
</script>

<div data-component="ConfirmationSignerFooter" class={styles.footer}>
  <ul class={styles.links}>
    <li class={styles.link}>
      <a
        target="blank"
        href={$footerLinks.privacy
          ? $footerLinks.privacy
          : "https://www.auditi.de/privacy"}
        >{$t("confirmation_signer_footer.privacy")}</a
      >
    </li>
    <li class={styles.link}>
      <a
        target="blank"
        href={$footerLinks.imprint
          ? $footerLinks.imprint
          : "https://www.auditi.de/imprint"}
        >{$t("confirmation_signer_footer.imprint")}</a
      >
    </li>
    {#if $theme != "eyde"}
      <li class={styles.link}>
        <a
          target="blank"
          href={$footerLinks.root
            ? $footerLinks.root
            : "https://www.auditi.de/"}
          >{$t("confirmation_signer_footer.learnmore")}</a
        >
      </li>
    {/if}
  </ul>
</div>

<style lang="scss">
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px;
    background-color: var(--white);
    border-top: 1px solid var(--primary-050);
  }

  .links {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 12px 0;
  }

  .link {
    font-size: 0.75rem;
    font-weight: 500;
    color: var(--primary-300);
    margin: 0 1.5rem 0 0;
  }

  .link a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: #052861;
    }
  }

  .sign {
    text-decoration: none;
  }

  @media only screen and (min-width: 1023px) {
    .footer {
      padding: 15px 35px;
    }
  }
</style>
