import Api from "./../api";
import { get } from "svelte/store";
import { client, users } from "stores/client_store";

class ClientUserApi {
  constructor() {
    this.endpoint = "/client_users";
  }

  getAllForCurrentClient() {
    Api.get(`${this.endpoint}/search/`, {
      params: {
        client_id: get(client).id,
        search: "",
        limit: 1000,
      },
      success: (response) => {
        users.set(response.data.client_users);
      },
    });
  }
}

export default new ClientUserApi();
