import Api from "apis/api";
import { toasts } from "stores/toasts.js";
import { t } from "stores/i18n.js";
import { get } from "svelte/store";
import { buildFormData } from "lib/helpers.js";

import { twoFactorAuth } from "stores/account.js";

class TwoFactorAuthApi {
  constructor() {
    this.endpoint = "/compliance_package";
  }

  getTwoFactorAuth() {
    Api.get(`${this.endpoint}`, {
      success: (response) => {
        twoFactorAuth.set(response.data.compliance_package);
      },
      error: this.error,
    });
  }

  updateTwoFactorAuth(options) {
    const formData = new FormData();
    buildFormData(formData, options);

    Api.put(`${this.endpoint}`, {
      body: formData,
      success: (response) => {
        toasts.send({
          title: get(t)("success.update_successful"),
          type: "success",
        });
        twoFactorAuth.set(response.data.compliance_package);
      },
    });
  }

  error() {
    toasts.send({ title: get(t)("errors.unknown"), type: "error" });
  }
}

export default new TwoFactorAuthApi();
