<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(72.941176%,0%,0%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 232.679688 174.238281 C 232.679688 174.238281 196.386719 158.609375 201.59375 118.113281 C 206.632812 98.621094 215.539062 85.347656 231.671875 78.121094 C 239.234375 75.769531 246.960938 74.761719 254.859375 74.089844 C 252.789062 76.050781 251.050781 78.011719 250.15625 80.476562 C 248.417969 84.898438 249.707031 89.15625 252.003906 93.414062 C 254.914062 98.34375 256.652344 103.441406 257.210938 110.046875 C 258.277344 119.347656 254.300781 128.308594 246.960938 133.742188 C 242.3125 137.328125 236.824219 138.558594 232.34375 142.648438 C 228.871094 146.066406 226.742188 149.480469 225.957031 155.417969 C 225.84375 166.734375 228.925781 168.300781 232.679688 174.238281 Z M 232.679688 174.238281 "
    />
    <path
      style="fill:none;stroke-width:2.931125;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 510.206937 236.182098 L 510.206937 235.834916 "
      transform="matrix(0.483806,0,0,0.483806,-21.052242,1.382062)"
    />
    <path
      style="fill:none;stroke-width:2.931125;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(72.941176%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 514.946373 348.588278 C 485.766949 322.767637 488.318332 257.828482 510.546044 236.182098 "
      transform="matrix(0.483806,0,0,0.483806,-21.052242,1.382062)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(72.941176%,0%,0%);fill-opacity:1;"
      d="M 234.621094 108.617188 L 236.941406 112.5 L 232.636719 110.746094 L 229.421875 114.199219 L 229.984375 109.667969 L 225.675781 107.917969 L 230.328125 106.871094 L 230.878906 102.339844 L 233.191406 106.222656 L 237.839844 105.167969 Z M 234.621094 108.617188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 235.765625 171.558594 C 235.765625 171.558594 240.070312 132.277344 280.3125 125.390625 C 300.429688 124.5 315.730469 129.125 327.363281 142.429688 C 331.828125 148.96875 335.058594 156.066406 338.015625 163.417969 C 335.53125 162.011719 333.148438 160.925781 330.53125 160.792969 C 325.792969 160.425781 322.097656 162.90625 318.703125 166.347656 C 314.84375 170.578125 310.476562 173.730469 304.324219 176.203125 C 295.746094 179.945312 286.011719 178.769531 278.667969 173.347656 C 273.875 169.953125 271.089844 165.066406 265.867188 161.980469 C 261.582031 159.660156 257.691406 158.625 251.785156 159.617188 C 240.9375 162.824219 240.339844 166.230469 235.761719 171.558594 Z M 235.765625 171.558594 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.941176%,0%,0%);fill-opacity:1;stroke-width:2.931125;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 510.206108 236.181836 L 510.210472 235.836944 "
      transform="matrix(0.141765,0.462572,-0.462572,0.141765,326.6835,-121.688571)"
    />
    <path
      style="fill:none;stroke-width:2.931125;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(72.941176%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 514.948068 348.589122 C 485.772983 322.766986 488.320113 257.831486 510.553366 236.178481 "
      transform="matrix(0.141765,0.462572,-0.462572,0.141765,326.6835,-121.688571)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(72.941176%,0%,0%);fill-opacity:1;"
      d="M 299.070312 154.183594 L 296.039062 157.539062 L 296.457031 152.910156 L 292.210938 150.847656 L 296.710938 150.058594 L 297.117188 145.429688 L 299.480469 149.570312 L 303.976562 148.769531 L 300.941406 152.117188 L 303.3125 156.253906 Z M 299.070312 154.183594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 238.476562 174.394531 C 238.476562 174.394531 277.089844 166.003906 296.402344 201.972656 C 303.632812 220.765625 304.109375 236.742188 295.1875 252 C 290.402344 258.3125 284.699219 263.625 278.667969 268.765625 C 279.214844 265.964844 279.484375 263.359375 278.78125 260.835938 C 277.621094 256.226562 274.097656 253.511719 269.753906 251.382812 C 264.519531 249.066406 260.144531 245.929688 255.84375 240.878906 C 249.570312 233.933594 247.59375 224.328125 250.40625 215.644531 C 252.101562 210.023438 255.851562 205.828125 257.121094 199.898438 C 257.957031 195.097656 257.703125 191.078125 254.886719 185.792969 C 248.398438 176.523438 244.980469 177.039062 238.476562 174.390625 Z M 238.476562 174.394531 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.941176%,0%,0%);fill-opacity:1;stroke-width:2.931125;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 510.204999 236.180507 L 510.205112 235.833229 "
      transform="matrix(-0.393596,0.281333,-0.281333,-0.393596,545.412306,167.472748)"
    />
    <path
      style="fill:none;stroke-width:2.931125;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(72.941176%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 514.948638 348.592039 C 485.766957 322.771372 488.317589 257.832238 510.552277 236.18062 "
      transform="matrix(-0.393596,0.281333,-0.281333,-0.393596,545.412306,167.472748)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(72.941176%,0%,0%);fill-opacity:1;"
      d="M 275.054688 228.90625 L 270.910156 227.097656 L 275.429688 226.019531 L 276.039062 221.34375 L 278.21875 225.355469 L 282.738281 224.273438 L 279.5625 227.828125 L 281.746094 231.839844 L 277.609375 230.023438 L 274.4375 233.585938 Z M 275.054688 228.90625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 237.605469 178.375 C 237.605469 178.375 256.503906 213.082031 227.402344 241.714844 C 211.402344 253.933594 196.199219 258.875 179.050781 254.597656 C 171.648438 251.78125 164.945312 247.800781 158.320312 243.453125 C 161.160156 243.191406 163.738281 242.71875 165.964844 241.335938 C 170.0625 238.925781 171.675781 234.78125 172.5 230.015625 C 173.253906 224.339844 175.035156 219.261719 178.675781 213.714844 C 183.578125 205.746094 192.242188 201.152344 201.367188 201.410156 C 207.238281 201.460938 212.316406 203.878906 218.367188 203.429688 C 223.207031 202.886719 226.992188 201.511719 231.273438 197.328125 C 238.347656 188.5 236.894531 185.363281 237.609375 178.375 Z M 237.605469 178.375 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.941176%,0%,0%);fill-opacity:1;stroke-width:2.931125;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 510.20889 236.179974 L 510.210847 235.829434 "
      transform="matrix(-0.380547,-0.29875,0.29875,-0.380547,330.447513,471.017627)"
    />
    <path
      style="fill:none;stroke-width:2.931125;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(72.941176%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 514.953565 348.591279 C 485.773978 322.771751 488.320944 257.828391 510.548094 236.180566 "
      transform="matrix(-0.380547,-0.29875,0.29875,-0.380547,330.447513,471.017627)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(72.941176%,0%,0%);fill-opacity:1;"
      d="M 195.5625 228.792969 L 196.132812 224.308594 L 198.433594 228.34375 L 203.097656 227.613281 L 199.855469 230.828125 L 202.164062 234.863281 L 197.859375 232.816406 L 194.625 236.039062 L 195.207031 231.554688 L 190.898438 229.515625 Z M 195.5625 228.792969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 233.324219 177.625 C 233.324219 177.625 206.871094 206.980469 170.195312 189.046875 C 153.351562 178.011719 143.640625 165.316406 141.976562 147.71875 C 142.175781 139.804688 143.703125 132.160156 145.601562 124.464844 C 146.792969 127.054688 148.09375 129.328125 150.140625 130.96875 C 153.773438 134.035156 158.21875 134.183594 162.988281 133.375 C 168.589844 132.199219 173.972656 132.191406 180.414062 133.78125 C 189.558594 135.761719 196.769531 142.402344 199.5625 151.097656 C 201.464844 156.652344 200.867188 162.242188 203.300781 167.800781 C 205.421875 172.1875 207.976562 175.300781 213.34375 177.949219 C 224.023438 181.6875 226.5 179.273438 233.328125 177.625 Z M 233.324219 177.625 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.941176%,0%,0%);fill-opacity:1;stroke-width:2.931125;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 510.211266 236.175642 L 510.206977 235.836079 "
      transform="matrix(0.155336,-0.458189,0.458189,0.155336,-11.842023,362.422477)"
    />
    <path
      style="fill:none;stroke-width:2.931125;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(72.941176%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 514.95266 348.587141 C 485.772175 322.76697 488.317843 257.826897 510.553421 236.179 "
      transform="matrix(0.155336,-0.458189,0.458189,0.155336,-11.842023,362.422477)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(72.941176%,0%,0%);fill-opacity:1;"
      d="M 171.804688 154.71875 L 176.222656 153.765625 L 173.183594 157.28125 L 175.421875 161.433594 L 171.308594 159.445312 L 168.273438 162.964844 L 168.773438 158.222656 L 164.660156 156.246094 L 169.078125 155.300781 L 169.574219 150.558594 Z M 171.804688 154.71875 "
    />
  </g>
</svg>
