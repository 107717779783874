<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(0%,0%,60%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,80%,0%);fill-opacity:1;"
      d="M 0 0 L 480.003906 360 L 480.003906 0 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 44.910156 52.796875 L 27.757812 40.332031 L 10.601562 52.796875 L 17.15625 32.628906 L 0 20.167969 L 21.203125 20.167969 L 27.757812 0 L 34.308594 20.167969 L 55.511719 20.167969 L 38.359375 32.628906 Z M 44.910156 52.796875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 81.160156 78.734375 L 64.003906 91.199219 L 70.558594 71.03125 L 53.402344 58.566406 L 74.605469 58.566406 L 81.160156 38.402344 L 87.710938 58.566406 L 108.914062 58.566406 L 91.761719 71.03125 L 98.3125 91.199219 Z M 81.160156 78.734375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 117.402344 129.59375 L 123.953125 109.429688 L 106.800781 96.964844 L 128.003906 96.964844 L 134.554688 76.800781 L 141.109375 96.964844 L 162.3125 96.964844 L 145.15625 109.429688 L 151.710938 129.59375 L 134.554688 117.132812 Z M 117.402344 129.59375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 177.355469 147.832031 L 160.203125 135.371094 L 181.40625 135.371094 L 187.957031 115.203125 L 194.511719 135.371094 L 215.714844 135.371094 L 198.558594 147.832031 L 205.113281 168 L 187.957031 155.535156 L 170.804688 168 Z M 177.355469 147.832031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 213.601562 173.769531 L 234.804688 173.769531 L 241.359375 153.601562 L 247.910156 173.769531 L 269.113281 173.769531 L 251.960938 186.230469 L 258.511719 206.398438 L 241.359375 193.933594 L 224.203125 206.398438 L 230.757812 186.230469 Z M 213.601562 173.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 288.207031 212.164062 L 294.761719 192 L 301.3125 212.164062 L 322.515625 212.164062 L 305.363281 224.628906 L 311.914062 244.796875 L 294.761719 232.332031 L 277.605469 244.796875 L 284.160156 224.628906 L 267.003906 212.164062 Z M 288.207031 212.164062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 348.15625 230.40625 L 354.710938 250.570312 L 375.914062 250.570312 L 358.757812 263.035156 L 365.3125 283.199219 L 348.15625 270.738281 L 331.003906 283.199219 L 337.554688 263.035156 L 320.402344 250.570312 L 341.605469 250.570312 Z M 348.15625 230.40625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 401.558594 268.800781 L 408.113281 288.96875 L 429.316406 288.96875 L 412.160156 301.429688 L 418.714844 321.597656 L 401.558594 309.132812 L 384.40625 321.597656 L 390.957031 301.429688 L 373.804688 288.96875 L 395.007812 288.96875 Z M 401.558594 268.800781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 461.515625 327.371094 L 482.71875 327.371094 L 465.5625 339.835938 L 472.117188 360.003906 L 454.960938 347.539062 L 437.808594 360.003906 L 444.359375 339.835938 L 427.207031 327.371094 L 448.410156 327.371094 L 454.960938 307.207031 Z M 461.515625 327.371094 "
    />
  </g>
</svg>
