import { t } from "stores/i18n.js";
import { toasts } from "stores/toasts.js";
import { get } from "svelte/store";
import Api from "apis/api.js";
import { buildFormData } from "lib/helpers.js";

class ReviewClientAccessApi {
  constructor() {
    this.endpoint = "/review_client_users";
  }

  getClients(options) {
    Api.get(`${this.endpoint}`, options);
  }

  grantAccess(options) {
    const { params, success, error } = options;
    const formData = new FormData();
    buildFormData(formData, params);

    Api.post(`${this.endpoint}/`, {
      body: formData,
      success,
      error,
    });
  }

  updateClientAccess({ params, success }) {
    const clientId = params.id;
    const formData = new FormData();
    buildFormData(formData, params);

    Api.patch(`${this.endpoint}/${clientId}`, {
      body: formData,
      success: () => {
        success();
        toasts.send({
          title: get(t)("success.update_successful"),
          type: "success",
        });
      },
      error: () => {},
    });
  }
}

export default new ReviewClientAccessApi();
