<script>
  import clsx from "clsx";
  import styles from "styleguide/Switch.json";

  export let toggled = false;
  export let toggle = undefined;
  export let style = "";
  export let disabled = false;
  export let bgColor = "blue";
  export let input;

  function onToggle() {
    if (toggle && !disabled) {
      toggle(input.checked);
    }
  }
</script>

<label
  data-component="Switch"
  class={clsx(
    styles.switch,
    style.split(" ").map((x) => styles[x]),
    { [styles["active"]]: toggled },
  )}
  style="--background-color:{`var(--${bgColor})`}"
>
  <input
    bind:this={input}
    type="checkbox"
    bind:checked={toggled}
    on:change={onToggle}
    {disabled}
  />
  <span class={styles.slider} />
</label>

<style scoped lang="scss">
  .switch {
    position: relative;
    display: inline-block;
    width: 39px;
    height: 24px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--primary-050);
    transition: 0.2s;

    &.active {
      background-color: var(--green);
    }
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    right: 17px;
  }
  .icon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
    input:checked + .slider & {
      display: none;
    }
  }
  input:checked + .slider {
    background-color: var(--green);
  }
  input:focus + .slider {
    background-color: var(--background-color);
  }
  input:checked + .slider:before {
    transform: translateX(15px);
  }
  /* Rounded sliders */
  .slider {
    border-radius: 32px;
  }
  .slider:before {
    border-radius: 50%;
  }
</style>
