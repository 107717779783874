import Api from "apis/api";
import { get } from "svelte/store";
import { notifications, notificationsCount } from "stores/notification.js";

class NotificationApi {
  constructor() {
    this.endpoint = "/notifications";
  }

  getData() {
    Api.get(this.endpoint, {
      success: (response) => {
        notifications.set(response.data.notifications);
        notificationsCount.set(response.data.notifications_count);
      },
    });
  }

  clearAll() {
    Api.patch(`${this.endpoint}/clear`, {
      success: () => {
        notifications.set([]);
        notificationsCount.set(0);
      },
    });
  }

  clear(id) {
    Api.patch(`${this.endpoint}/${id}/read`, {
      success: () => {
        notifications.set(
          get(notifications).filter((item) => item.permalink !== id),
        );
        notificationsCount.set(get(notificationsCount) - 1);
      },
    });
  }
}

export default new NotificationApi();
