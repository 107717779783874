<script>
  export let container;
</script>

<div id={container ? "container" : ""}>
  <slot />
</div>

<style global lang="scss">
  :global(body) {
    --z-index-navigation: 100;
    --z-index-bottom-bar: 200;
    --z-index-modal: 400;
    --z-index-document-viewer: 500;
    --z-index-popover: 900;
    --z-index-tooltip: 1000;
  }
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    src:
      url("/assets/inter/Inter-Regular.woff2") format("woff2"),
      url("/assets/inter/Inter-Regular.woff") format("woff"),
      url("/assets/inter/Inter-Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: "Inter";
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    src:
      url("/assets/inter/Inter-Medium.woff2") format("woff2"),
      url("/assets/inter/Inter-Medium.woff") format("woff"),
      url("/assets/inter/Inter-Medium.ttf") format("truetype");
  }

  @font-face {
    font-family: "Inter";
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    src:
      url("/assets/inter/Inter-SemiBold.woff2") format("woff2"),
      url("/assets/inter/Inter-SemiBold.woff") format("woff"),
      url("/assets/inter/Inter-SemiBold.ttf") format("truetype");
  }

  :global(body) {
    margin: 0;
    font-size: 14px;
    font-family: Inter, sans-serif;
  }

  :global(body.lock) {
    overflow: hidden;
  }

  input[type="radio"],
  input[type="checkbox"] {
    display: none;
  }
</style>
