<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M -119.046875 0 L 599.40625 0 L 599.40625 359.210938 L -119.046875 359.210938 Z M -119.046875 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 344.839844 123.117188 Z M 294.277344 78.964844 L 285.316406 124.660156 L 347.882812 124.019531 L 341.160156 78.96875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 254.101562 54.550781 L 273.304688 54.550781 L 273.304688 59.351562 L 254.101562 59.351562 Z M 254.101562 54.550781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 231.0625 54.550781 L 249.625 54.550781 L 249.625 59.351562 L 231.0625 59.351562 Z M 231.0625 54.550781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 207.378906 54.230469 L 225.941406 54.230469 L 225.941406 58.710938 L 207.378906 58.710938 Z M 207.378906 54.230469 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 272.167969 70.003906 Z M 207.546875 58.476562 L 207.546875 70.253906 L 273.3125 70.253906 L 273.3125 58.730469 L 207.546875 58.476562 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 215.558594 75.121094 L 207.875 123.695312 L 274.277344 124.015625 L 265.316406 75.445312 M 267.398438 122.152344 Z M 267.398438 122.152344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M -120.066406 270.378906 L 600.074219 270.378906 L 600.074219 360 L -120.066406 360 Z M -120.066406 270.378906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 118.066406 123.1875 L 362.667969 123.1875 L 362.667969 141.71875 L 118.066406 141.71875 Z M 118.066406 123.1875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 127.371094 139.675781 L 122.199219 232.226562 L 95.605469 232.546875 L 95.605469 247.425781 L 386.339844 247.425781 L 386.339844 232.546875 L 359.746094 232.867188 L 354.574219 140.113281 L 127.378906 139.671875 Z M 127.371094 139.675781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 203.765625 164.742188 L 203.765625 180.039062 L 207.519531 180.039062 L 207.519531 164.742188 L 203.765625 164.742188 M 209.398438 164.742188 L 209.398438 180.039062 L 239.425781 180.039062 L 239.425781 164.742188 L 209.398438 164.742188 M 241.304688 164.742188 L 241.304688 180.039062 L 269.453125 180.039062 L 269.453125 164.742188 L 241.304688 164.742188 M 271.332031 164.742188 L 271.332031 180.039062 L 276.960938 180.039062 L 276.960938 164.742188 Z M 271.332031 164.742188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 317.960938 180.007812 Z M 291.019531 164.722656 L 291.019531 180.339844 L 318.4375 180.339844 L 318.4375 165.058594 L 291.019531 164.722656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 282.59375 215.289062 L 282.59375 231.847656 L 303.238281 231.847656 L 303.238281 215.289062 L 282.59375 215.289062 M 305.117188 215.289062 L 305.117188 225.636719 L 308.871094 225.636719 L 308.871094 215.289062 L 305.117188 215.289062 M 331.390625 215.289062 L 331.390625 225.636719 L 335.144531 225.636719 L 335.144531 215.289062 L 331.390625 215.289062 M 337.019531 215.289062 L 337.019531 231.847656 L 357.664062 231.847656 L 357.664062 215.289062 Z M 337.019531 215.289062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 284.464844 198.441406 L 284.464844 213.417969 L 288.21875 213.417969 L 288.21875 198.441406 L 284.464844 198.441406 M 290.09375 198.441406 L 290.09375 213.417969 L 303.230469 213.417969 L 303.230469 198.441406 Z M 290.09375 198.441406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 337.015625 198.441406 L 337.015625 213.417969 L 350.46875 213.417969 L 350.46875 198.441406 L 337.015625 198.441406 M 352.394531 198.441406 L 352.394531 213.417969 L 356.238281 213.417969 L 356.238281 198.441406 Z M 352.394531 198.441406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 283.464844 141.953125 L 283.464844 146.019531 L 355.832031 146.019531 L 355.832031 141.953125 Z M 283.464844 141.953125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 131.34375 198.757812 L 131.34375 213.734375 L 144.03125 213.734375 L 144.03125 198.757812 Z M 131.34375 198.757812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 177.492188 198.441406 L 177.492188 214.058594 L 190.628906 214.058594 L 190.628906 198.441406 L 177.492188 198.441406 M 192.503906 198.441406 L 192.503906 214.058594 L 196.257812 214.058594 L 196.257812 198.441406 Z M 192.503906 198.441406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 202.816406 142.277344 L 202.816406 146.023438 L 277.417969 146.023438 L 277.417969 142.277344 Z M 202.816406 142.277344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 126.972656 142.601562 L 126.972656 146.34375 L 195.179688 146.34375 L 195.179688 142.601562 Z M 126.972656 142.601562 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 280.070312 140.996094 C 272.90625 141.527344 281.019531 208.523438 281.03125 229.414062 C 281.03125 229.414062 289.597656 145.386719 280.070312 140.996094 Z M 280.070312 140.996094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 119.308594 129.8125 L 119.308594 140.082031 L 361.417969 140.082031 L 361.417969 129.8125 Z M 119.308594 129.8125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 198.148438 141.3125 C 190.988281 141.855469 199.097656 210.0625 199.109375 231.335938 C 199.109375 231.335938 207.675781 145.785156 198.148438 141.3125 Z M 198.148438 141.3125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 123.851562 198.757812 L 123.851562 213.734375 L 129.628906 213.734375 L 129.628906 198.757812 Z M 123.851562 198.757812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 123.703125 214.964844 L 123.703125 231.863281 L 144.347656 231.863281 L 144.234375 214.988281 L 123.703125 214.964844 M 143.988281 231.5 Z M 143.988281 231.5 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 379.644531 245.988281 Z M 96.179688 233.210938 L 96.179688 246.265625 L 384.6875 246.265625 L 384.6875 233.492188 L 96.179688 233.210938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 177.46875 215.289062 L 177.46875 231.621094 L 197.472656 231.621094 L 197.472656 215.304688 L 177.46875 215.289062 M 197.125 231.273438 Z M 197.125 231.273438 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 201.789062 214.964844 L 201.789062 231.523438 L 222.433594 231.523438 L 222.324219 214.988281 L 201.789062 214.964844 M 222.074219 231.167969 Z M 222.074219 231.167969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 256.515625 215.289062 L 256.515625 231.847656 L 279.398438 231.847656 L 279.289062 215.308594 L 256.515625 215.289062 M 279 231.492188 Z M 279 231.492188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 158.007812 180.316406 Z M 131.140625 164.40625 L 131.140625 180.664062 L 158.484375 180.664062 L 158.484375 164.753906 L 131.140625 164.40625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 128.621094 180.324219 Z M 125.945312 164.726562 L 125.945312 180.664062 L 128.667969 180.664062 L 128.667969 165.066406 L 125.945312 164.726562 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 188.964844 180.632812 Z M 160.507812 164.722656 L 160.507812 180.980469 L 189.472656 180.980469 L 189.472656 165.070312 L 160.507812 164.722656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 194.210938 180.640625 Z M 190.90625 165.042969 L 190.90625 180.980469 L 194.269531 180.980469 L 194.269531 165.382812 L 190.90625 165.042969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 303.378906 196.664062 Z M 284.351562 182.007812 L 284.351562 196.984375 L 303.714844 196.984375 L 303.714844 182.328125 L 284.351562 182.007812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 355.859375 196.355469 Z M 336.519531 182.324219 L 336.519531 196.660156 L 356.203125 196.660156 L 356.203125 182.632812 L 336.519531 182.324219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 334.257812 196.671875 Z M 306.191406 182.328125 L 306.191406 196.984375 L 334.757812 196.984375 L 334.757812 182.640625 L 306.191406 182.328125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 281.582031 129.320312 Z M 278.277344 122.804688 L 278.277344 129.460938 L 281.640625 129.460938 L 281.640625 122.945312 L 278.277344 122.804688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 288.613281 179.699219 Z M 284.992188 164.726562 L 284.992188 180.023438 L 288.675781 180.023438 L 288.675781 165.054688 L 284.992188 164.726562 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 347.15625 180.015625 Z M 321.101562 165.042969 L 321.101562 180.339844 L 347.617188 180.339844 L 347.617188 165.371094 L 321.101562 165.042969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 355.148438 180.015625 Z M 349.957031 165.042969 L 349.957031 180.339844 L 355.242188 180.339844 L 355.242188 165.371094 L 349.957031 165.042969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 301.183594 163.046875 Z M 284.675781 147.765625 L 284.675781 163.382812 L 301.480469 163.382812 L 301.480469 148.097656 L 284.675781 147.765625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 333.597656 163.054688 Z M 304.140625 148.085938 L 304.140625 163.382812 L 334.121094 163.382812 L 334.121094 148.414062 L 304.140625 148.085938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 353.644531 163.046875 Z M 335.875 147.765625 L 335.875 163.382812 L 353.960938 163.382812 L 353.960938 148.097656 L 335.875 147.765625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 201.789062 197.683594 L 201.789062 212.980469 L 210.273438 212.980469 L 210.273438 197.785156 L 201.789062 197.683594 M 210.125 212.652344 Z M 210.125 212.652344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 212.027344 198.007812 L 212.027344 213.304688 L 222.527344 213.304688 L 222.527344 198.109375 L 212.027344 198.007812 M 222.34375 212.976562 Z M 222.34375 212.976562 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 256.515625 198.332031 L 256.515625 213.628906 L 270.121094 213.628906 L 270.121094 198.316406 L 256.515625 198.332031 M 269.882812 213.300781 Z M 269.882812 213.300781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 278.636719 213.300781 Z M 271.871094 198.332031 L 271.871094 213.628906 L 278.753906 213.628906 L 278.753906 198.65625 L 271.871094 198.332031 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 125.054688 141.636719 L 125.054688 144.742188 L 197.417969 144.742188 L 197.417969 141.636719 Z M 125.054688 141.636719 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 199.292969 141.636719 L 199.292969 144.742188 L 279.339844 144.742188 L 279.339844 141.636719 Z M 199.292969 141.636719 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 281.167969 141.3125 L 281.167969 144.417969 L 357.742188 144.417969 L 357.742188 141.3125 Z M 281.167969 141.3125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 143.691406 196.972656 Z M 124.664062 182.003906 L 124.664062 197.300781 L 144.03125 197.300781 L 144.03125 182.332031 L 124.664062 182.003906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 195.246094 196.972656 Z M 177.789062 182.003906 L 177.789062 197.300781 L 195.554688 197.300781 L 195.554688 182.332031 L 177.789062 182.003906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 146.371094 182.328125 L 146.371094 197.625 L 176.03125 197.625 L 176.144531 182.429688 L 146.371094 182.328125 M 175.511719 197.296875 Z M 175.511719 197.296875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 203.070312 181.363281 L 203.070312 196.660156 L 222.753906 196.660156 L 222.867188 181.464844 L 203.070312 181.363281 M 222.410156 196.332031 Z M 222.410156 196.332031 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 254.539062 196.332031 Z M 224.191406 181.363281 L 224.191406 196.660156 L 255.078125 196.660156 L 254.964844 181.578125 L 224.195312 181.363281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 256.832031 181.6875 L 256.832031 196.984375 L 276.835938 196.984375 L 276.949219 181.675781 L 256.832031 181.6875 M 276.488281 196.65625 Z M 276.488281 196.65625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 223.355469 162.414062 Z M 203.386719 147.445312 L 203.386719 162.742188 L 223.710938 162.742188 L 223.710938 147.773438 L 203.386719 147.445312 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 255.234375 162.722656 Z M 225.148438 147.441406 L 225.148438 163.058594 L 255.769531 163.058594 L 255.769531 147.773438 L 225.148438 147.441406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 275.5625 162.722656 Z M 257.792969 147.441406 L 257.792969 163.058594 L 275.878906 163.058594 L 275.878906 147.773438 L 257.792969 147.441406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 191.230469 128.234375 Z M 139.386719 84.082031 L 131.707031 129.777344 L 194.269531 129.140625 L 187.230469 83.128906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 215.058594 87.074219 L 213.148438 94.5625 L 239.902344 94.5625 L 239.902344 87.074219 L 215.058594 87.074219 M 241.8125 87.074219 L 241.8125 94.5625 L 266.660156 94.5625 L 266.660156 87.074219 Z M 241.8125 87.074219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 213.144531 96.433594 L 213.144531 103.921875 L 222.640625 103.921875 L 222.640625 96.433594 L 213.144531 96.433594 M 224.539062 96.433594 L 224.539062 103.921875 L 254.921875 103.921875 L 254.921875 96.433594 L 224.539062 96.433594 M 256.820312 96.433594 L 256.820312 103.921875 L 268.214844 103.921875 L 268.214844 96.433594 Z M 256.820312 96.433594 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 216.941406 77.714844 L 215.03125 85.203125 L 222.683594 85.203125 L 222.683594 77.714844 L 216.945312 77.714844 M 224.597656 77.714844 L 224.597656 85.203125 L 255.21875 85.203125 L 255.21875 77.714844 L 224.597656 77.714844 M 257.132812 77.714844 L 257.132812 85.203125 L 264.785156 85.203125 L 264.785156 77.714844 Z M 257.132812 77.714844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 207.519531 60.867188 L 207.519531 64.609375 L 239.746094 64.609375 L 239.746094 60.867188 L 207.519531 60.867188 M 241.644531 60.867188 L 241.644531 64.609375 L 271.972656 64.609375 L 271.972656 60.867188 Z M 241.644531 60.867188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 207.519531 66.484375 L 207.519531 70.226562 L 222.535156 70.226562 L 222.535156 66.484375 L 207.519531 66.484375 M 224.414062 66.484375 L 224.414062 70.226562 L 254.441406 70.226562 L 254.441406 66.484375 L 224.414062 66.484375 M 256.316406 66.484375 L 256.316406 70.226562 L 271.332031 70.226562 L 271.332031 66.484375 Z M 256.316406 66.484375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 231.917969 55.25 L 231.917969 58.996094 L 248.8125 58.996094 L 248.8125 55.25 Z M 231.917969 55.25 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 119.308594 124.515625 L 119.308594 128.261719 L 134.324219 128.261719 L 134.324219 124.515625 Z M 119.308594 124.515625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 346.402344 124.515625 L 346.402344 128.261719 L 361.417969 128.261719 L 361.417969 124.515625 Z M 346.402344 124.515625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 149.535156 128.433594 Z M 135.226562 125.363281 L 135.226562 128.5 L 149.789062 128.5 L 149.789062 125.429688 L 135.226562 125.363281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 181.570312 128.761719 Z M 169.144531 126.136719 L 169.144531 128.820312 L 181.789062 128.820312 L 181.789062 126.195312 L 169.144531 126.136719 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 202.84375 129.613281 Z M 199.226562 122.160156 L 199.226562 129.777344 L 202.910156 129.777344 L 202.910156 122.324219 L 199.226562 122.160156 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 147.328125 123.8125 Z M 134.902344 114.609375 L 134.902344 124.015625 L 147.546875 124.015625 L 147.546875 114.8125 L 134.902344 114.609375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 154.792969 123.820312 Z M 148.34375 114.929688 L 148.34375 124.015625 L 154.910156 124.015625 L 154.910156 115.125 L 148.34375 114.929688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 191.511719 124.75 Z M 180.34375 114.292969 L 180.34375 124.976562 L 191.710938 124.976562 L 191.710938 114.523438 L 180.34375 114.292969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 178.171875 123.875 Z M 169.46875 114.75 L 169.46875 124.078125 L 178.328125 124.078125 L 178.328125 114.949219 L 169.46875 114.75 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 195.625 127.757812 Z M 181.628906 123.125 L 181.628906 127.859375 L 195.871094 127.859375 L 195.871094 123.222656 L 181.628906 123.125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 267.398438 122.152344 Z M 215.238281 75.445312 L 207.234375 70.253906 L 273 70.253906 L 265.636719 76.085938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 208.238281 55.25 L 208.238281 58.996094 L 225.128906 58.996094 L 225.128906 55.25 Z M 208.238281 55.25 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 254.636719 55.570312 L 254.636719 59.3125 L 272.488281 59.3125 L 272.488281 55.570312 Z M 254.636719 55.570312 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 262.582031 75.992188 Z M 215.558594 74.800781 L 215.558594 76.015625 L 263.40625 76.015625 L 263.40625 74.828125 L 215.558594 74.800781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 265.050781 75.351562 Z M 212.996094 74.160156 L 212.996094 75.375 L 265.964844 75.375 L 265.964844 74.1875 L 212.996094 74.160156 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 267.242188 74.390625 Z M 212.671875 73.199219 L 212.671875 74.417969 L 268.199219 74.417969 L 268.199219 73.226562 L 212.671875 73.203125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 267.242188 74.390625 Z M 212.671875 73.199219 L 212.671875 74.417969 L 268.199219 74.417969 L 268.199219 73.226562 L 212.671875 73.203125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 268.8125 73.109375 Z M 212.039062 71.921875 L 212.039062 73.136719 L 269.808594 73.136719 L 269.808594 71.945312 L 212.039062 71.921875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 230.878906 128.445312 Z M 219.714844 126.003906 L 219.714844 128.5 L 231.078125 128.5 L 231.078125 126.058594 L 219.714844 126.003906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 259.066406 128.445312 Z M 249.472656 126.003906 L 249.472656 128.5 L 259.238281 128.5 L 259.238281 126.058594 L 249.472656 126.003906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 142.476562 163.039062 Z M 127.222656 147.445312 L 127.222656 163.382812 L 142.746094 163.382812 L 142.746094 147.785156 L 127.222656 147.445312 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 230.695312 124.769531 Z M 209.46875 115.125 L 209.46875 124.980469 L 231.074219 124.980469 L 231.074219 115.335938 L 209.46875 115.125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 272.679688 124.769531 Z M 254.671875 115.125 L 254.671875 124.980469 L 273 124.980469 L 273 115.335938 L 254.671875 115.125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 252.105469 124.769531 Z M 246.914062 115.125 L 246.914062 124.980469 L 252.199219 124.980469 L 252.199219 115.335938 L 246.914062 115.125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 219.339844 128.066406 Z M 207.546875 123.121094 L 207.546875 128.175781 L 219.550781 128.175781 L 219.550781 123.226562 L 207.546875 123.121094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 183.121094 124.515625 L 183.121094 128.582031 L 198.136719 128.582031 L 198.136719 124.515625 L 183.121094 124.515625 M 203.765625 124.515625 L 203.765625 128.582031 L 218.78125 128.582031 L 218.78125 124.515625 Z M 203.765625 124.515625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 273.699219 128.074219 Z M 259.390625 123.441406 L 259.390625 128.175781 L 273.953125 128.175781 L 273.953125 123.539062 L 259.390625 123.441406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 176.113281 163.046875 Z M 144.769531 147.765625 L 144.769531 163.382812 L 176.667969 163.382812 L 176.667969 148.097656 L 144.769531 147.765625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 194.300781 163.046875 Z M 178.105469 147.765625 L 178.105469 163.382812 L 194.589844 163.382812 L 194.589844 148.097656 L 178.105469 147.765625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 239.21875 113.597656 Z M 211.074219 105.203125 L 211.074219 113.78125 L 239.71875 113.78125 L 239.71875 105.386719 L 211.074219 105.203125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 269.613281 113.914062 Z M 241.46875 105.519531 L 241.46875 114.097656 L 270.113281 114.097656 L 270.113281 105.703125 L 241.46875 105.519531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 178.582031 62.550781 L 195.222656 62.550781 L 195.222656 67.351562 L 178.582031 67.351562 Z M 178.582031 62.550781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 154.257812 62.230469 L 172.820312 62.230469 L 172.820312 67.671875 L 154.257812 67.671875 Z M 154.257812 62.230469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 133.777344 62.550781 L 148.5 62.550781 L 148.5 67.351562 L 133.777344 67.351562 Z M 133.777344 62.550781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 193.226562 76.808594 Z M 139.355469 84.402344 L 133.628906 76.335938 L 195.234375 76.65625 L 187.851562 83.390625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 134.324219 72.097656 L 134.324219 75.84375 L 147.464844 75.84375 L 147.464844 72.097656 L 134.324219 72.097656 M 149.339844 72.097656 L 149.339844 75.84375 L 179.367188 75.84375 L 179.367188 72.097656 L 149.339844 72.097656 M 181.246094 72.097656 L 181.246094 75.84375 L 194.382812 75.84375 L 194.382812 72.097656 Z M 181.246094 72.097656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 134.324219 63.378906 L 134.324219 66.667969 L 147.464844 66.667969 L 147.464844 63.378906 L 134.324219 63.378906 M 154.96875 63.378906 L 154.96875 66.667969 L 171.863281 66.667969 L 171.863281 63.378906 Z M 154.96875 63.378906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 179.367188 63.699219 L 179.367188 66.539062 L 194.382812 66.539062 L 194.382812 63.699219 Z M 179.367188 63.699219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 133.777344 67.351562 L 195.222656 67.351562 L 195.222656 76.953125 L 133.777344 76.953125 Z M 133.777344 67.351562 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 159.984375 112.65625 Z M 136.1875 105.332031 L 136.1875 112.8125 L 160.40625 112.8125 L 160.40625 105.492188 L 136.1875 105.332031 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 189.9375 112.65625 Z M 161.960938 105.332031 L 161.960938 112.8125 L 190.433594 112.8125 L 190.433594 105.492188 L 161.960938 105.332031 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 163.042969 71.15625 Z M 134.582031 68.402344 L 134.582031 71.214844 L 163.546875 71.214844 L 163.546875 68.460938 L 134.582031 68.402344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 147.683594 103.367188 Z M 137.460938 95.601562 L 137.460938 103.535156 L 147.867188 103.535156 L 147.867188 95.769531 L 137.460938 95.597656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 177.511719 103.386719 Z M 149.886719 96.507812 L 149.886719 103.539062 L 178.003906 103.539062 L 178.003906 96.65625 L 149.886719 96.507812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 188.992188 103.691406 Z M 180.027344 95.921875 L 180.027344 103.859375 L 189.152344 103.859375 L 189.152344 96.09375 L 180.027344 95.921875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 161.910156 94.746094 Z M 138.742188 87.867188 L 138.742188 94.894531 L 162.320312 94.894531 L 162.320312 88.015625 L 138.742188 87.867188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 187.777344 94.746094 Z M 164.347656 87.867188 L 164.347656 94.894531 L 188.195312 94.894531 L 188.195312 88.015625 L 164.347656 87.867188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 180.542969 86.195312 Z M 146.109375 83.441406 L 146.109375 86.257812 L 181.152344 86.257812 L 181.152344 83.5 L 146.109375 83.441406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 186.820312 86.195312 Z M 181.945312 83.441406 L 181.945312 86.257812 L 186.90625 86.257812 L 186.90625 83.5 L 181.945312 83.441406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 144.886719 86.195312 Z M 139.382812 83.441406 L 139.382812 86.257812 L 144.984375 86.257812 L 144.984375 83.5 L 139.382812 83.441406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 194.085938 71.15625 Z M 165.570312 68.402344 L 165.570312 71.214844 L 194.589844 71.214844 L 194.589844 68.460938 L 165.570312 68.402344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 148.902344 77.210938 Z M 134.90625 73.277344 L 134.90625 77.296875 L 149.148438 77.296875 L 149.148438 73.363281 L 134.90625 73.277344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 177.1875 76.761719 Z M 152.394531 73.140625 L 152.394531 76.839844 L 177.628906 76.839844 L 177.628906 73.21875 L 152.394531 73.140625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 194.019531 76.765625 Z M 180.101562 73.277344 L 180.101562 76.84375 L 194.269531 76.84375 L 194.269531 73.355469 L 180.101562 73.277344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 185.769531 81.941406 Z M 138.75 80.882812 L 138.75 81.960938 L 186.597656 81.960938 L 186.597656 80.90625 L 138.75 80.882812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 188.242188 81.371094 Z M 136.1875 80.3125 L 136.1875 81.394531 L 189.15625 81.394531 L 189.15625 80.335938 L 136.1875 80.3125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 190.429688 80.515625 Z M 135.863281 79.457031 L 135.863281 80.539062 L 191.390625 80.539062 L 191.390625 79.480469 L 135.863281 79.457031 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 190.429688 80.515625 Z M 135.863281 79.457031 L 135.863281 80.539062 L 191.390625 80.539062 L 191.390625 79.480469 L 135.863281 79.457031 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 192 79.378906 Z M 135.230469 78.320312 L 135.230469 79.402344 L 193 79.402344 L 193 78.34375 L 135.230469 78.320312 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 308.976562 128.441406 Z M 298.4375 125.683594 L 298.4375 128.5 L 309.164062 128.5 L 309.164062 125.746094 L 298.4375 125.683594 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 344.398438 128.117188 Z M 328.515625 125.359375 L 328.515625 128.175781 L 344.679688 128.175781 L 344.679688 125.421875 L 328.515625 125.359375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 346.03125 124.152344 Z M 331.851562 115.5 L 331.851562 124.339844 L 346.28125 124.339844 L 346.28125 115.6875 L 331.851562 115.5 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 329.859375 124.144531 Z M 324.0625 115.179688 L 324.0625 124.339844 L 329.960938 124.339844 L 329.960938 115.375 L 324.0625 115.179688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 299.347656 124.445312 Z M 287.554688 114.855469 L 287.554688 124.65625 L 299.558594 124.65625 L 299.558594 115.0625 L 287.554688 114.855469 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 309.023438 124.445312 Z M 301.578125 114.726562 L 301.578125 124.660156 L 309.15625 124.660156 L 309.15625 114.9375 L 301.578125 114.726562 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 298.054688 128.691406 Z M 285.632812 123.117188 L 285.632812 128.816406 L 298.277344 128.816406 L 298.277344 123.242188 L 285.632812 123.117188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 260.707031 124.515625 L 260.707031 128.582031 L 277.308594 128.582031 L 277.308594 124.515625 L 260.707031 124.515625 M 282.84375 124.515625 L 282.84375 128.582031 L 297.601562 128.582031 L 297.601562 124.515625 Z M 282.84375 124.515625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 269.640625 113.914062 Z M 242.832031 105.519531 L 242.832031 114.097656 L 270.117188 114.097656 L 270.117188 105.703125 L 242.832031 105.519531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 332.507812 62.472656 L 349.148438 62.472656 L 349.148438 67.273438 L 332.507812 67.273438 Z M 332.507812 62.472656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 308.1875 62.152344 L 326.75 62.152344 L 326.75 67.59375 L 308.1875 67.59375 Z M 308.1875 62.152344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 287.707031 62.472656 L 302.425781 62.472656 L 302.425781 67.273438 L 287.707031 67.273438 Z M 287.707031 62.472656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 347.15625 76.730469 Z M 293.285156 84.328125 L 287.554688 76.257812 L 349.160156 76.578125 L 341.78125 83.3125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 288.253906 72.019531 L 288.253906 75.765625 L 301.390625 75.765625 L 301.390625 72.019531 L 288.253906 72.019531 M 303.269531 72.019531 L 303.269531 75.765625 L 333.296875 75.765625 L 333.296875 72.019531 L 303.269531 72.019531 M 335.175781 72.019531 L 335.175781 75.765625 L 348.3125 75.765625 L 348.3125 72.019531 Z M 335.175781 72.019531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 288.253906 63.300781 L 288.253906 66.140625 L 301.390625 66.140625 L 301.390625 63.300781 L 288.253906 63.300781 M 308.898438 63.300781 L 308.898438 66.140625 L 325.789062 66.140625 L 325.789062 63.300781 Z M 308.898438 63.300781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 333.296875 63.621094 L 333.296875 66.007812 L 348.3125 66.007812 L 348.3125 63.621094 Z M 333.296875 63.621094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 287.707031 67.273438 L 349.148438 67.273438 L 349.148438 76.875 L 287.707031 76.875 Z M 287.707031 67.273438 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 314.347656 112.570312 Z M 289.476562 104.804688 L 289.476562 112.738281 L 314.789062 112.738281 L 314.789062 104.972656 L 289.476562 104.804688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 343.886719 112.570312 Z M 317.128906 104.804688 L 317.128906 112.738281 L 344.359375 112.738281 L 344.359375 104.972656 L 317.128906 104.804688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 315.199219 71.078125 Z M 288.515625 68.324219 L 288.515625 71.140625 L 315.671875 71.140625 L 315.671875 68.382812 L 288.515625 68.324219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 301.621094 103.289062 Z M 291.398438 95.523438 L 291.398438 103.457031 L 301.800781 103.457031 L 301.800781 95.691406 L 291.398438 95.523438 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 331.453125 103.289062 Z M 304.273438 95.523438 L 304.273438 103.457031 L 331.9375 103.457031 L 331.9375 95.691406 L 304.273438 95.523438 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 342.921875 103.605469 Z M 333.957031 95.839844 L 333.957031 103.773438 L 343.082031 103.773438 L 343.082031 96.007812 L 333.957031 95.839844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 316.289062 93.789062 Z M 292.675781 88.238281 L 292.675781 93.910156 L 316.707031 93.910156 L 316.707031 88.359375 L 292.675781 88.238281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 341.710938 93.789062 Z M 318.730469 88.238281 L 318.730469 93.910156 L 342.121094 93.910156 L 342.121094 88.359375 L 318.730469 88.238281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 334.46875 86.117188 Z M 300.035156 83.363281 L 300.035156 86.179688 L 335.082031 86.179688 L 335.082031 83.421875 L 300.035156 83.363281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 340.757812 86.117188 Z M 335.878906 83.363281 L 335.878906 86.179688 L 340.84375 86.179688 L 340.84375 83.421875 L 335.878906 83.363281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 298.820312 86.117188 Z M 293.316406 83.363281 L 293.316406 86.179688 L 298.921875 86.179688 L 298.921875 83.421875 L 293.316406 83.363281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 348 71.078125 Z M 318.59375 68.324219 L 318.59375 71.140625 L 348.519531 71.140625 L 348.519531 68.382812 L 318.59375 68.324219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 301.945312 76.21875 Z M 288.835938 71.84375 L 288.835938 76.3125 L 302.175781 76.3125 L 302.175781 71.9375 L 288.835938 71.84375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 330.203125 76.226562 Z M 304.519531 72.164062 L 304.519531 76.316406 L 330.660156 76.316406 L 330.660156 72.253906 L 304.519531 72.164062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 347.929688 76.226562 Z M 332.675781 72.292969 L 332.675781 76.3125 L 348.199219 76.3125 L 348.199219 72.378906 L 332.675781 72.292969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 339.707031 81.863281 Z M 292.683594 80.804688 L 292.683594 81.882812 L 340.53125 81.882812 L 340.53125 80.828125 L 292.683594 80.804688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 342.175781 81.292969 Z M 290.121094 80.234375 L 290.121094 81.316406 L 343.089844 81.316406 L 343.089844 80.257812 L 290.121094 80.234375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 344.363281 80.4375 Z M 289.796875 79.378906 L 289.796875 80.460938 L 345.324219 80.460938 L 345.324219 79.402344 L 289.796875 79.378906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 344.363281 80.4375 Z M 289.796875 79.378906 L 289.796875 80.460938 L 345.324219 80.460938 L 345.324219 79.402344 L 289.796875 79.378906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 345.933594 79.300781 Z M 289.164062 78.242188 L 289.164062 79.324219 L 346.933594 79.324219 L 346.933594 78.265625 L 289.164062 78.242188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 339.707031 80.972656 Z M 292.683594 80.703125 L 292.683594 80.980469 L 340.53125 80.980469 L 340.53125 80.710938 L 292.683594 80.703125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 342.175781 80.828125 Z M 290.121094 80.558594 L 290.121094 80.835938 L 343.089844 80.835938 L 343.089844 80.566406 L 290.121094 80.558594 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 344.363281 80.609375 Z M 289.796875 80.339844 L 289.796875 80.617188 L 345.324219 80.617188 L 345.324219 80.347656 L 289.796875 80.339844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 344.363281 80.609375 Z M 289.796875 80.339844 L 289.796875 80.617188 L 345.324219 80.617188 L 345.324219 80.347656 L 289.796875 80.339844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 345.933594 80.320312 Z M 289.164062 80.050781 L 289.164062 80.328125 L 346.933594 80.328125 L 346.933594 80.058594 L 289.164062 80.050781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(50.196078%,50.196078%,50.196078%);fill-opacity:1;"
      d="M 223.109375 325.265625 L 237.140625 325.265625 L 237.140625 345.632812 L 223.109375 345.632812 Z M 223.109375 325.265625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,77.254902%,0%);fill-opacity:1;"
      d="M 224.917969 326.628906 L 237.136719 326.628906 L 237.136719 344.28125 L 224.917969 344.28125 Z M 224.917969 326.628906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(50.196078%,50.196078%,50.196078%);fill-opacity:1;"
      d="M 227.179688 330.242188 L 235.328125 330.242188 L 235.328125 342.464844 L 227.179688 342.464844 Z M 227.179688 330.242188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 228.996094 331.609375 L 233.972656 331.609375 L 233.972656 341.113281 L 228.996094 341.113281 Z M 228.996094 331.609375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(50.196078%,50.196078%,50.196078%);fill-opacity:1;"
      d="M 222.667969 333.425781 L 228.101562 333.425781 L 228.101562 339.761719 L 222.667969 339.761719 Z M 222.667969 333.425781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 222.667969 334.78125 L 228.550781 334.78125 L 228.550781 338.402344 L 222.667969 338.402344 Z M 222.667969 334.78125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(50.196078%,50.196078%,50.196078%);fill-opacity:1;"
      d="M 243.71875 353.667969 L 236.929688 353.667969 L 236.929688 300.714844 L 243.71875 300.714844 Z M 243.71875 353.667969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,77.254902%,0%);fill-opacity:1;"
      d="M 242.359375 352.3125 L 238.285156 352.3125 L 238.285156 302.074219 L 242.359375 302.074219 Z M 242.359375 352.3125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(50.196078%,50.196078%,50.196078%);fill-opacity:1;"
      d="M 259 286.507812 L 255.699219 289.628906 L 237.984375 272.855469 L 241.28125 269.734375 Z M 259 286.507812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(50.196078%,50.196078%,50.196078%);fill-opacity:1;"
      d="M 243.460938 303.828125 L 240.34375 300.417969 L 255.765625 283.527344 L 258.886719 286.941406 Z M 243.460938 303.828125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,77.254902%,0%);fill-opacity:1;"
      d="M 238.757812 273.085938 L 241.453125 270.5 L 258.226562 286.585938 L 255.53125 289.171875 Z M 238.757812 273.085938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,77.254902%,0%);fill-opacity:1;"
      d="M 242.535156 303.894531 L 240.039062 301.132812 L 255.574219 283.945312 L 258.070312 286.707031 Z M 242.535156 303.894531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(50.196078%,50.196078%,50.196078%);fill-opacity:1;"
      d="M 222.265625 288.667969 L 225.382812 285.605469 L 240.804688 300.761719 L 237.6875 303.824219 Z M 222.265625 288.667969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(50.196078%,50.196078%,50.196078%);fill-opacity:1;"
      d="M 239.140625 269.886719 L 242.515625 273.160156 L 224.394531 290.742188 L 221.019531 287.46875 Z M 239.140625 269.886719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,77.254902%,0%);fill-opacity:1;"
      d="M 225.386719 289.332031 L 222.757812 286.703125 L 239.121094 270.34375 L 241.75 272.972656 Z M 225.386719 289.332031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(50.196078%,50.196078%,50.196078%);fill-opacity:1;"
      d="M 238.628906 236.40625 L 242.25 236.40625 L 242.25 280.109375 L 238.628906 280.109375 Z M 238.628906 236.40625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,77.254902%,0%);fill-opacity:1;"
      d="M 239.238281 236.988281 L 241.730469 236.988281 L 241.730469 279.257812 L 239.238281 279.257812 Z M 239.238281 236.988281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,77.254902%,0%);fill-opacity:1;"
      d="M 221.957031 287.550781 L 224.765625 284.746094 L 242.226562 302.207031 L 239.421875 305.015625 Z M 221.957031 287.550781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 303.953125 199.480469 L 335.824219 199.480469 L 335.824219 232.367188 L 303.953125 232.367188 Z M 303.953125 199.480469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 319.90625 186.355469 C 328.640625 186.355469 335.722656 192.4375 335.722656 199.941406 C 335.722656 207.441406 328.640625 213.523438 319.90625 213.523438 C 311.171875 213.523438 304.09375 207.441406 304.09375 199.941406 C 304.09375 192.4375 311.171875 186.355469 319.90625 186.355469 Z M 319.90625 186.355469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 305.097656 200.625 L 335.269531 200.625 L 335.269531 232.378906 L 305.097656 232.378906 Z M 305.097656 200.625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 320.203125 187.949219 C 328.472656 187.949219 335.175781 193.820312 335.175781 201.066406 C 335.175781 208.308594 328.472656 214.183594 320.203125 214.183594 C 311.933594 214.183594 305.230469 208.308594 305.230469 201.066406 C 305.230469 193.820312 311.933594 187.949219 320.203125 187.949219 Z M 320.203125 187.949219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 308.945312 202.558594 L 331.425781 202.558594 L 331.425781 232.375 L 308.945312 232.375 Z M 308.945312 202.558594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 320.199219 190.660156 C 326.359375 190.660156 331.351562 196.175781 331.351562 202.976562 C 331.351562 209.777344 326.359375 215.289062 320.199219 215.289062 C 314.039062 215.289062 309.042969 209.777344 309.042969 202.976562 C 309.042969 196.175781 314.039062 190.660156 320.199219 190.660156 Z M 320.199219 190.660156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 310.234375 203.363281 L 329.949219 203.363281 L 329.949219 231.804688 L 310.234375 231.804688 Z M 310.234375 203.363281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 320.109375 192.015625 C 325.511719 192.015625 329.894531 197.277344 329.894531 203.761719 C 329.894531 210.25 325.511719 215.511719 320.109375 215.511719 C 314.707031 215.511719 310.324219 210.25 310.324219 203.761719 C 310.324219 197.277344 314.707031 192.015625 320.109375 192.015625 Z M 320.109375 192.015625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 222.734375 198.746094 L 255.511719 198.746094 L 255.511719 231.632812 L 222.734375 231.632812 Z M 222.734375 198.746094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 239.144531 185.621094 C 248.125 185.621094 255.40625 191.703125 255.40625 199.207031 C 255.40625 206.707031 248.125 212.789062 239.144531 212.789062 C 230.160156 212.789062 222.878906 206.707031 222.878906 199.207031 C 222.878906 191.703125 230.160156 185.621094 239.144531 185.621094 Z M 239.144531 185.621094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 223.902344 199.886719 L 254.933594 199.886719 L 254.933594 231.644531 L 223.902344 231.644531 Z M 223.902344 199.886719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 239.4375 187.214844 C 247.941406 187.214844 254.835938 193.085938 254.835938 200.332031 C 254.835938 207.574219 247.941406 213.449219 239.4375 213.449219 C 230.933594 213.449219 224.039062 207.574219 224.039062 200.332031 C 224.039062 193.085938 230.933594 187.214844 239.4375 187.214844 Z M 239.4375 187.214844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 227.847656 201.824219 L 250.964844 201.824219 L 250.964844 231.640625 L 227.847656 231.640625 Z M 227.847656 201.824219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 239.417969 189.925781 C 245.753906 189.925781 250.890625 195.441406 250.890625 202.242188 C 250.890625 209.042969 245.753906 214.554688 239.417969 214.554688 C 233.082031 214.554688 227.949219 209.042969 227.949219 202.242188 C 227.949219 195.441406 233.082031 189.925781 239.417969 189.925781 Z M 239.417969 189.925781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 229.175781 202.636719 L 249.449219 202.636719 L 249.449219 231.078125 L 229.175781 231.078125 Z M 229.175781 202.636719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 239.324219 191.285156 C 244.882812 191.285156 249.386719 196.546875 249.386719 203.03125 C 249.386719 209.519531 244.882812 214.78125 239.324219 214.78125 C 233.769531 214.78125 229.265625 209.519531 229.265625 203.03125 C 229.265625 196.546875 233.769531 191.285156 239.324219 191.285156 Z M 239.324219 191.285156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 144.449219 198.859375 L 176.773438 198.859375 L 176.773438 231.746094 L 144.449219 231.746094 Z M 144.449219 198.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 160.628906 185.734375 C 169.488281 185.734375 176.671875 191.816406 176.671875 199.320312 C 176.671875 206.820312 169.488281 212.902344 160.628906 212.902344 C 151.769531 212.902344 144.589844 206.820312 144.589844 199.320312 C 144.589844 191.816406 151.769531 185.734375 160.628906 185.734375 Z M 160.628906 185.734375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 145.570312 200 L 176.167969 200 L 176.167969 231.757812 L 145.570312 231.757812 Z M 145.570312 200 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 160.886719 187.328125 C 169.273438 187.328125 176.070312 193.199219 176.070312 200.445312 C 176.070312 207.6875 169.273438 213.5625 160.886719 213.5625 C 152.5 213.5625 145.703125 207.6875 145.703125 200.445312 C 145.703125 193.199219 152.5 187.328125 160.886719 187.328125 Z M 160.886719 187.328125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 149.480469 201.9375 L 172.277344 201.9375 L 172.277344 231.753906 L 149.480469 231.753906 Z M 149.480469 201.9375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 160.890625 190.039062 C 167.140625 190.039062 172.207031 195.554688 172.207031 202.355469 C 172.207031 209.15625 167.140625 214.667969 160.890625 214.667969 C 154.644531 214.667969 149.578125 209.15625 149.578125 202.355469 C 149.578125 195.554688 154.644531 190.039062 160.890625 190.039062 Z M 160.890625 190.039062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 150.792969 202.75 L 170.789062 202.75 L 170.789062 231.191406 L 150.792969 231.191406 Z M 150.792969 202.75 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 160.804688 191.398438 C 166.285156 191.398438 170.726562 196.660156 170.726562 203.144531 C 170.726562 209.632812 166.285156 214.894531 160.804688 214.894531 C 155.324219 214.894531 150.882812 209.632812 150.882812 203.144531 C 150.882812 196.660156 155.324219 191.398438 160.804688 191.398438 Z M 160.804688 191.398438 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 223.785156 226.597656 L 223.785156 228.222656 L 228.210938 228.222656 L 228.210938 226.597656 Z M 223.785156 226.597656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 145.148438 225.464844 L 145.148438 227.089844 L 149.574219 227.089844 L 149.574219 225.464844 Z M 145.148438 225.464844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 145.148438 213.355469 L 145.148438 214.980469 L 149.574219 214.980469 L 149.574219 213.355469 Z M 145.148438 213.355469 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 145.257812 201.929688 L 145.257812 203.554688 L 149.683594 203.554688 L 149.683594 201.929688 Z M 145.257812 201.929688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 172.1875 225.914062 L 172.1875 227.539062 L 176.613281 227.539062 L 176.613281 225.914062 Z M 172.1875 225.914062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 172.078125 213.695312 L 172.078125 215.320312 L 176.503906 215.320312 L 176.503906 213.695312 Z M 172.078125 213.695312 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 171.738281 202.15625 L 171.738281 203.78125 L 176.164062 203.78125 L 176.164062 202.15625 Z M 171.738281 202.15625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 230.15625 195.488281 L 231.304688 194.339844 L 228.175781 191.210938 L 227.027344 192.359375 Z M 230.15625 195.488281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 250.59375 226.484375 L 250.59375 228.109375 L 255.019531 228.109375 L 255.019531 226.484375 Z M 250.59375 226.484375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 223.898438 213.46875 L 223.898438 215.09375 L 228.324219 215.09375 L 228.324219 213.46875 Z M 223.898438 213.46875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 223.558594 201.816406 L 223.558594 203.445312 L 227.984375 203.445312 L 227.984375 201.816406 Z M 223.558594 201.816406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 250.59375 213.695312 L 250.59375 215.320312 L 255.019531 215.320312 L 255.019531 213.695312 Z M 250.59375 213.695312 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 250.828125 201.25 L 250.828125 202.875 L 255.253906 202.875 L 255.253906 201.25 Z M 250.828125 201.25 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 304.792969 227.046875 L 304.792969 228.671875 L 309.21875 228.671875 L 309.21875 227.046875 Z M 304.792969 227.046875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 304.792969 213.46875 L 304.792969 215.09375 L 309.21875 215.09375 L 309.21875 213.46875 Z M 304.792969 213.46875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 304.902344 200.574219 L 304.902344 202.199219 L 309.328125 202.199219 L 309.328125 200.574219 Z M 304.902344 200.574219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 331.046875 227.160156 L 331.046875 228.785156 L 335.472656 228.785156 L 335.472656 227.160156 Z M 331.046875 227.160156 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 330.933594 213.582031 L 330.933594 215.207031 L 335.359375 215.207031 L 335.359375 213.582031 Z M 330.933594 213.582031 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 331.046875 201.023438 L 331.046875 202.648438 L 335.472656 202.648438 L 335.472656 201.023438 Z M 331.046875 201.023438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 309.875 113.992188 L 323.464844 113.992188 L 323.464844 128.410156 L 309.875 128.410156 Z M 309.875 113.992188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 316.675781 108.242188 C 320.402344 108.242188 323.421875 110.90625 323.421875 114.195312 C 323.421875 117.484375 320.402344 120.148438 316.675781 120.148438 C 312.953125 120.148438 309.933594 117.484375 309.933594 114.195312 C 309.933594 110.90625 312.953125 108.242188 316.675781 108.242188 Z M 316.675781 108.242188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 310.355469 114.492188 L 323.222656 114.492188 L 323.222656 128.410156 L 310.355469 128.410156 Z M 310.355469 114.492188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 316.796875 108.933594 C 320.324219 108.933594 323.183594 111.507812 323.183594 114.683594 C 323.183594 117.859375 320.324219 120.433594 316.796875 120.433594 C 313.269531 120.433594 310.410156 117.859375 310.410156 114.683594 C 310.410156 111.507812 313.269531 108.933594 316.796875 108.933594 Z M 316.796875 108.933594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 311.992188 115.34375 L 321.578125 115.34375 L 321.578125 128.410156 L 311.992188 128.410156 Z M 311.992188 115.34375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 316.792969 110.125 C 319.417969 110.125 321.546875 112.542969 321.546875 115.523438 C 321.546875 118.503906 319.417969 120.921875 316.792969 120.921875 C 314.164062 120.921875 312.035156 118.503906 312.035156 115.523438 C 312.035156 112.542969 314.164062 110.125 316.792969 110.125 Z M 316.792969 110.125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 312.546875 115.695312 L 320.953125 115.695312 L 320.953125 128.164062 L 312.546875 128.164062 Z M 312.546875 115.695312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 316.753906 110.722656 C 319.058594 110.722656 320.925781 113.027344 320.925781 115.871094 C 320.925781 118.714844 319.058594 121.019531 316.753906 121.019531 C 314.449219 121.019531 312.582031 118.714844 312.582031 115.871094 C 312.582031 113.027344 314.449219 110.722656 316.753906 110.722656 Z M 316.753906 110.722656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 320.941406 186.496094 L 319.316406 186.496094 L 319.316406 190.921875 L 320.941406 190.921875 Z M 320.941406 186.496094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 252.121094 192.699219 L 250.972656 191.550781 L 247.84375 194.679688 L 248.992188 195.828125 Z M 252.121094 192.699219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 331.664062 192.585938 L 330.515625 191.4375 L 327.386719 194.566406 L 328.535156 195.714844 Z M 331.664062 192.585938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 311.84375 195.601562 L 312.992188 194.453125 L 309.863281 191.324219 L 308.714844 192.472656 Z M 311.84375 195.601562 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 150.898438 190.308594 L 149.75 191.457031 L 152.878906 194.585938 L 154.027344 193.4375 Z M 150.898438 190.308594 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 240.273438 186.160156 L 238.648438 186.160156 L 238.648438 190.585938 L 240.273438 190.585938 Z M 240.273438 186.160156 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 160.011719 191.265625 L 161.636719 191.265625 L 161.636719 186.839844 L 160.011719 186.839844 Z M 160.011719 191.265625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 168.757812 194.222656 L 169.90625 195.375 L 173.035156 192.246094 L 171.886719 191.09375 Z M 168.757812 194.222656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 312.097656 123.652344 L 312.097656 128.578125 L 328.199219 128.578125 L 328.199219 123.652344 Z M 312.097656 123.652344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 312.617188 124.515625 L 312.617188 128.261719 L 327.632812 128.261719 L 327.632812 124.515625 Z M 312.617188 124.515625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 231.484375 113.796875 L 246.847656 113.796875 L 246.847656 128.636719 L 231.484375 128.636719 Z M 231.484375 113.796875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 239.175781 107.875 C 243.386719 107.875 246.800781 110.621094 246.800781 114.003906 C 246.800781 117.390625 243.386719 120.132812 239.175781 120.132812 C 234.964844 120.132812 231.550781 117.390625 231.550781 114.003906 C 231.550781 110.621094 234.964844 107.875 239.175781 107.875 Z M 239.175781 107.875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 232.027344 114.308594 L 246.574219 114.308594 L 246.574219 128.636719 L 232.027344 128.636719 Z M 232.027344 114.308594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 239.308594 108.589844 C 243.296875 108.589844 246.527344 111.238281 246.527344 114.507812 C 246.527344 117.773438 243.296875 120.425781 239.308594 120.425781 C 235.324219 120.425781 232.089844 117.773438 232.089844 114.507812 C 232.089844 111.238281 235.324219 108.589844 239.308594 108.589844 Z M 239.308594 108.589844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 233.878906 115.1875 L 244.71875 115.1875 L 244.71875 128.640625 L 233.878906 128.640625 Z M 233.878906 115.1875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 239.304688 109.820312 C 242.273438 109.820312 244.683594 112.308594 244.683594 115.378906 C 244.683594 118.445312 242.273438 120.933594 239.304688 120.933594 C 236.335938 120.933594 233.925781 118.445312 233.925781 115.378906 C 233.925781 112.308594 236.335938 109.820312 239.304688 109.820312 Z M 239.304688 109.820312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 234.503906 115.554688 L 244.007812 115.554688 L 244.007812 128.386719 L 234.503906 128.386719 Z M 234.503906 115.554688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 239.261719 110.433594 C 241.867188 110.433594 243.980469 112.804688 243.980469 115.730469 C 243.980469 118.660156 241.867188 121.03125 239.261719 121.03125 C 236.65625 121.03125 234.546875 118.660156 234.546875 115.730469 C 234.546875 112.804688 236.65625 110.433594 239.261719 110.433594 Z M 239.261719 110.433594 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 231.722656 123.847656 L 231.722656 128.773438 L 249.597656 128.773438 L 249.597656 123.847656 Z M 231.722656 123.847656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 231.917969 124.839844 L 231.917969 128.585938 L 248.8125 128.585938 L 248.8125 124.839844 Z M 231.917969 124.839844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 152.882812 113.796875 L 168.246094 113.796875 L 168.246094 128.636719 L 152.882812 128.636719 Z M 152.882812 113.796875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 160.574219 107.875 C 164.785156 107.875 168.199219 110.621094 168.199219 114.003906 C 168.199219 117.390625 164.785156 120.132812 160.574219 120.132812 C 156.363281 120.132812 152.949219 117.390625 152.949219 114.003906 C 152.949219 110.621094 156.363281 107.875 160.574219 107.875 Z M 160.574219 107.875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 153.425781 114.308594 L 167.972656 114.308594 L 167.972656 128.636719 L 153.425781 128.636719 Z M 153.425781 114.308594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 160.707031 108.589844 C 164.695312 108.589844 167.925781 111.238281 167.925781 114.507812 C 167.925781 117.773438 164.695312 120.425781 160.707031 120.425781 C 156.722656 120.425781 153.488281 117.773438 153.488281 114.507812 C 153.488281 111.238281 156.722656 108.589844 160.707031 108.589844 Z M 160.707031 108.589844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 155.277344 115.1875 L 166.113281 115.1875 L 166.113281 128.640625 L 155.277344 128.640625 Z M 155.277344 115.1875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 160.703125 109.820312 C 163.671875 109.820312 166.082031 112.308594 166.082031 115.378906 C 166.082031 118.445312 163.671875 120.933594 160.703125 120.933594 C 157.734375 120.933594 155.324219 118.445312 155.324219 115.378906 C 155.324219 112.308594 157.734375 109.820312 160.703125 109.820312 Z M 160.703125 109.820312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 155.902344 115.554688 L 165.40625 115.554688 L 165.40625 128.386719 L 155.902344 128.386719 Z M 155.902344 115.554688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 160.660156 110.433594 C 163.265625 110.433594 165.375 112.804688 165.375 115.730469 C 165.375 118.660156 163.265625 121.03125 160.660156 121.03125 C 158.054688 121.03125 155.945312 118.660156 155.945312 115.730469 C 155.945312 112.804688 158.054688 110.433594 160.660156 110.433594 Z M 160.660156 110.433594 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.137255%,6.27451%,6.666667%);fill-opacity:1;"
      d="M 150.160156 122.273438 L 150.160156 128.183594 L 165.871094 128.183594 L 165.871094 122.273438 Z M 150.160156 122.273438 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;"
      d="M 164.90625 128.382812 Z M 151.222656 123.121094 L 151.222656 128.5 L 165.148438 128.5 L 165.148438 123.238281 L 151.222656 123.121094 "
    />
  </g>
</svg>
