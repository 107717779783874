class Flash {
  addMessage(message, alertClass, element) {
    if (!element) {
      let modal = document.querySelector(".modal.show");
      if (modal) {
        element = modal;
      } else {
        element = document.querySelector("body");
      }
    }

    if (!alertClass) {
      alertClass = "notice";
    }

    var partialFlashElement = document.createElement("div");
    partialFlashElement.innerHTML = this.flashHTML(message, alertClass);
    element.prepend(partialFlashElement);
  }

  flashHTML(message, alertClass) {
    return `
      <div data-controller="flash-message">
        <div class="alert ${alertClass}" data-flash-message-target="alert">
          ${message}
        </div>
      <div>
    `;
  }
}

export default new Flash();
