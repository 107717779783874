<script>
  import { onMount } from "svelte";

  import { isAccountFeature } from "stores/account";
  import { t } from "stores/i18n.js";
  import { toasts } from "stores/toasts.js";
  import SsoSettingsApi from "apis/sso_settings/sso_settings.js";
  import { validate } from "lib/validate.js";

  import { Button, TextInput } from "components";
  import styles from "styleguide/AdministrationSsoCredential.json";

  let ssoClientId;
  let ssoClientSecret;
  let ssoTenantId;
  let dirty = false;

  $: disabled = !$isAccountFeature("admin_sso");
  $: ssoClientIdErrors = validate(ssoClientId, ["required"]);
  $: ssoClientSecretErrors = validate(ssoClientSecret, ["required"]);
  $: ssoTenantErrors = validate(ssoTenantId, ["required"]);
  $: valide =
    !ssoClientIdErrors.length &&
    !ssoClientSecretErrors.length &&
    !ssoTenantErrors.length;

  onMount(() => {
    if (!disabled) {
      getSsoSetingsData();
    }
  });

  function getSsoSetingsData() {
    SsoSettingsApi.get({
      success: (response) => {
        resetSsoCredentials(response);
      },
    });
  }

  function handleUpdateValue(event, inputValue) {
    const newValue = event.target.value;
    return (inputValue = newValue);
  }

  function resetSsoCredentials(data) {
    ssoClientId = data.client_id;
    ssoClientSecret = data.client_secret;
    ssoTenantId = data.tenant_id;
  }

  function onSubmit() {
    if (!valide) {
      dirty = true;
      return;
    }

    let params = {
      client_id: ssoClientId,
      client_secret: ssoClientSecret,
      tenant_id: ssoTenantId,
    };

    SsoSettingsApi.create({
      params,
      success: (response) => {
        resetSsoCredentials(response);
        toasts.send({
          title: $t("sso_credential.submit_success"),
          type: "success",
        });
      },
      error: () => {
        toasts.send({
          title: $t("sso_credential.submit_error"),
          type: "error",
        });
      },
    });
  }

  function onReset() {
    SsoSettingsApi.delete({
      success: (response) => {
        resetSsoCredentials(response);
      },
    });
  }
</script>

<div data-component="AdministrationSsoCredential">
  <div class={styles.content}>
    <p>
      {$t("sso_credential.description")}
    </p>

    <div class={styles["content-inputs"]}>
      <div class={styles.input}>
        <div class={styles["text-input"]}>
          <TextInput
            bind:value={ssoClientId}
            {disabled}
            label={$t("sso_credential.client_id")}
            errors={dirty ? ssoClientIdErrors : []}
            onChange={(event) => handleUpdateValue(event, ssoClientId)}
          />
        </div>
      </div>

      <div class={styles.input}>
        <div class={styles["text-input"]}>
          <TextInput
            bind:value={ssoClientSecret}
            {disabled}
            label={$t("sso_credential.client_secret")}
            errors={dirty ? ssoClientSecretErrors : []}
            onChange={(event) => handleUpdateValue(event, ssoClientSecret)}
          />
        </div>
      </div>

      <div class={styles.input}>
        <div class={styles["text-input"]}>
          <TextInput
            bind:value={ssoTenantId}
            {disabled}
            label={$t("sso_credential.tenant_id")}
            errors={dirty ? ssoTenantErrors : []}
            onChange={(event) => handleUpdateValue(event, ssoTenantId)}
          />
        </div>
      </div>
    </div>

    {#if !disabled}
      <div class={styles.buttons}>
        <Button style="primary" click={onSubmit}>
          {$t("sso_credential.submit")}
        </Button>

        <Button style="error-text" click={onReset}>
          {$t("sso_credential.reset")}
        </Button>
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  .content {
    width: 679px;
    padding: 24px;
    background: var(--white);
    box-shadow:
      0 6px 8px -6px rgba(24, 39, 75, 0.12),
      0 8px 16px -6px rgba(24, 39, 75, 0.08);
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;

    &-header {
      display: flex;
      gap: 8px;
      align-items: center;
      padding-top: 24px;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: var(--primary-500, #052861);
    }
    p {
      margin-top: 0;
    }

    &-inputs {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  .input {
    flex: 1;
    font-weight: 500;
  }

  .text-input {
    font-weight: 500;
  }

  .buttons {
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
  }
</style>
