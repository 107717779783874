<script>
  import { onMount } from "svelte";

  import { t } from "stores/i18n.js";
  import { users, teams, accessMode } from "stores/client_store";
  import { isAccountFeature } from "stores/account";

  import TeamsApi from "apis/clients/teams_api";
  import ClientUserApi from "apis/clients/client_user_api";

  import {
    Box,
    LegacyButton as Button,
    Smalltabs,
    UserSelector,
  } from "components";

  export let onSave;
  export let selectedUsers;
  export let selectedTeams;
  export let controlIds;

  onMount(async () => {
    ClientUserApi.getAllForCurrentClient();
    TeamsApi.getAll();
    accessMode.set("users");
  });

  function selectTeams() {
    accessMode.set("teams");
  }

  function selectUsers() {
    accessMode.set("users");
  }
</script>

<Box style="shadow">
  {#if $isAccountFeature("teams")}
    <Smalltabs>
      <Button
        style="smalltab fullsize"
        active={$accessMode === "users"}
        click={selectUsers}
      >
        {$t("access_selector.users")}
      </Button>
      <Button
        style="smalltab fullsize"
        active={$accessMode === "teams"}
        click={selectTeams}
      >
        {$t("access_selector.teams")}
      </Button>
    </Smalltabs>
  {/if}

  <UserSelector
    {selectedUsers}
    {selectedTeams}
    {controlIds}
    users={$users}
    teams={$teams}
    mode={$accessMode}
    {onSave}
    style="embedded"
  />
</Box>
