<script>
  import styles from "styleguide/ResponderSpecificMethod.json";
  import { getContext, createEventDispatcher } from "svelte";

  import { samples, reviewType } from "stores/responder.js";
  import { t } from "stores/i18n.js";

  import { validate } from "lib/validate.js";
  import { localizeDate } from "lib/helpers.js";
  import SampleResponses from "apis/responder/specific_method.js";

  import {
    ResponderConfirmationItem,
    ResponderCounterparties,
  } from "apps/responder";
  import { Button, TextInput, Toast } from "components";

  const dispatch = createEventDispatcher();
  const { language } = getContext("config");

  export let mailing;
  export let deadline;
  export let previous_response;
  let valid;
  let advisorEmail = "";
  let samplesValid = [];
  let samplesConfirmed = [];
  let confirmationItems = [];
  let ctaHidden = false;
  let dirty = false;
  $: errors = validate(advisorEmail, ["required"]);

  $: {
    valid =
      samplesValid.every((item) => item) &&
      advisorEmail &&
      !(
        validate(advisorEmail, ["email"]) &&
        validate(advisorEmail, ["email"]).length
      );
  }

  samples.subscribe((value) => {
    confirmationItems = value;
  });

  function closeCTA() {
    ctaHidden = true;
  }

  function onConfirmationItemFillData(index, data, valid) {
    samplesValid[index] = valid;
    samplesConfirmed[index] = data;
  }

  function onSuccess(response) {
    dispatch("success", { response });
    history.pushState(null, null, window.location.pathname + "/success");
  }

  function onSubmit() {
    if (!valid) {
      dirty = true;
      return;
    }

    let formData = new FormData();

    samplesConfirmed.forEach((item, index) => {
      for (let key in item) {
        if (Array.isArray(item[key])) {
          item[key].forEach((file) => {
            formData.append(`samples[${index}][${key}]files[]`, file);
          });
        } else {
          formData.append(`samples[${index}][${key}]`, item[key]);
        }
      }
    });

    formData.append("advisor_email", advisorEmail);
    formData.append("mailing_id", mailing);

    SampleResponses.sendData({
      body: formData,
      success: onSuccess,
      error: (response) => console.log(response),
    });
  }
</script>

<div data-component="ResponderSpecificMethod">
  {#if previous_response}
    <div class={`${styles.cta} ${ctaHidden ? styles.hidden : ""}`}>
      <Toast
        type="warning"
        message={$t("responder_specific_method.cta_title")}
        action={{
          style: "primary-text",
          click: closeCTA,
          button: $t("responder_specific_method.cta_button"),
        }}
      />
    </div>
  {/if}

  <div class={styles.container}>
    <ResponderCounterparties />
  </div>

  <div class={styles.container}>
    <div class={styles.deadline}>
      {@html $t("responder_specific_method") &&
        $t(`responder_specific_method.deadline_${$reviewType}`).replaceAll(
          "%{deadline}",
          localizeDate(deadline),
        )}
    </div>
  </div>

  {#each confirmationItems as { id, amount, reference, item_date, item_number, item_currency }, index (id)}
    <div class={styles.container}>
      <ResponderConfirmationItem
        {index}
        itemId={id}
        {amount}
        {deadline}
        {reference}
        {item_date}
        {item_number}
        {item_currency}
        onFillData={onConfirmationItemFillData}
      />
    </div>
  {/each}

  <div class={styles.container}>
    <div class={styles.advisor}>
      <div class={styles.left}>
        <div class={styles.advisorLabel}>
          {$t("responder_specific_method.confirmation")}
        </div>
      </div>
      <div class={styles.right}>
        <TextInput
          bind:value={advisorEmail}
          style="border medium round-border fullsize"
          label={$t("responder_specific_method.advisor_email")}
          placeholder={$t("responder_specific_method.advisor_placeholder")}
          errors={dirty ? errors : []}
        />
        <div class={styles.successButtonWrapper}>
          <Button click={onSubmit} style="primary"
            >{$t("responder_specific_method.transfer_answer")}</Button
          >
        </div>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  .container {
    padding: 35px;
    line-height: 22px;
    border-bottom: solid 1px var(--primary-050);
    color: var(--primary-500);
  }

  .deadline {
    line-height: 22px;
  }

  .advisor {
    display: flex;
  }

  .left {
    width: 130px;
  }

  .right {
    flex: 1;
  }

  .advisorLabel {
    line-height: 1.57;
    color: var(--primary-300);
    box-sizing: border-box;
  }

  .successButtonWrapper {
    margin-top: 20px;
  }

  .cta {
    width: calc(100vw - 30px);
    position: fixed;
    top: 10px;
    left: 15px;
    z-index: 9999;
    transition: all 1s;
    box-sizing: border-box;

    &.hidden {
      top: -200px;
    }
  }

  @media only screen and (min-width: 1023px) {
    .cta {
      width: 700px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
</style>
