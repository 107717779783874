<script>
  import styles from "styleguide/AuditEmptyState.json";
  import { getContext } from "svelte";

  import { theme } from "stores/theme.js";
  import { t } from "stores/i18n.js";

  import { Button, Toast } from "components";

  const config = getContext("config");
</script>

<div data-component="AuditEmptyState">
  <Toast type="info" title={$t("audit_empty_state.title")}>
    <div class={styles.top}>
      <div class={styles.title}>{$t("audit_empty_state.understand_title")}</div>
      <div class={styles.description}>
        {@html $t("audit_empty_state.understand_description")}
      </div>

      <div class={styles.title}>{$t("audit_empty_state.create_title")}</div>
      <div class={styles.description}>
        {@html $t("audit_empty_state.create_description")}
      </div>

      <div class={styles.title}>{$t("audit_empty_state.invite_title")}</div>
      <div class={styles.description}>
        {@html $t("audit_empty_state.invite_description")}
      </div>
    </div>

    {#if $theme === "aude" && config.language == "de"}
      <div class={styles.bottom}>
        <div class={styles["bottom-left"]}>
          <img
            class={styles["bottom-image"]}
            src="/assets/app/brochure-thumb.jpg"
            alt="brochure"
          />
          <div class={styles["bottom-text"]}>
            {@html $t("audit_empty_state.download_introduction")}
          </div>
        </div>
        <Button
          style="primary-text"
          newtab={true}
          navigate={$t("audit_empty_state.download_pdf_url")}
          >{$t("audit_empty_state.download_pdf")}</Button
        >
      </div>
    {/if}
  </Toast>
</div>

<style lang="scss">
  .top {
    counter-reset: steps;
    padding-bottom: 20px;
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    border-top: 1px solid var(--primary-050);
  }

  .bottom-image {
    height: 60px;
    margin-right: 10px;
  }

  .bottom-left {
    display: flex;
    align-items: center;
  }

  .bottom-text {
    max-width: 90%;
  }

  .title {
    margin: 0 0 10px;
    font-weight: 600;

    &::before {
      counter-increment: steps;
      content: counter(steps) ". ";
    }
  }

  .description {
    margin: 0 0 15px;

    &:last-child {
      margin: 0;
    }
  }
</style>
