import Api from "apis/api";

class BoxyhqApi {
  constructor() {
    this.endpoint = "/boxyhq";
  }

  getDirectories(options) {
    const { success, error } = options;

    Api.get(`${this.endpoint}/directory`, {
      success: (response) => {
        success(response.data);
      },
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  createDirectory(options) {
    const { success, error } = options;

    Api.post(`${this.endpoint}/directory`, {
      success: (response) => {
        success(response.data);
      },
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  updateDirectory(options) {
    const { params, success, error } = options;

    Api.put(`${this.endpoint}/directory`, {
      headers: { "Content-Type": "application/json" },
      body: {
        active: params.active,
      },
      success: (response) => {
        success(response.data);
      },
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error", response);
        }
      },
    });
  }
}

export default new BoxyhqApi();
