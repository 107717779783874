<script>
  import styles from "styleguide/Logo.json";
  import { account } from "stores/account.js";
</script>

<div data-component="Logo">
  <img src={$account.logo} alt={$account.official_name} class={styles.logo} />
</div>

<style lang="scss">
  .logo {
    height: var(--logo-height);
  }
</style>
