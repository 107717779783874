import { t } from "stores/i18n.js";
import { writable, derived, get } from "svelte/store";
import CommentsApi from "apis/comments/comments.js";
import ConfirmationApi from "apis/confirmation/confirmation.js";
import { breadcrumb } from "stores/breadcrumb.js";

const maxSignersPerItem = 3;
const confirmationId = writable(null);
const activities = writable([]);
const comments = writable([]);
// const commentsCount = writable(0)
const sort = writable("name");
const filter = writable(null);
const filterLable = writable(null);
const sidebar = writable({});
const release = writable({});
const confirmation = writable({ counters: {} });
const letterhead = writable({});
const activeExport = writable(false);
const activeRelease = writable(false);
const activeLetterhead = writable(false);
const activeRollForward = writable(false);
const activeDownloadResponses = writable(false);
const activeAddListItem = writable(false);
const activeAddListItemType = writable(null);
const activeSignersEdit = writable(false);
const activeSettingsEdit = writable(false);
const activeRemindSignersId = writable(null);
const activeArchive = writable(false);
const activeDelete = writable(false);
const typeEdit = writable({});

const selectedItems = writable([]);
const confirmationsNewItems = writable([]);
const authorizedSignersCount = writable(0);
const filtersNew = ["new_answers", "new_comments"];
const filtersStatus = ["sent", "received"];

const filtersType = derived([confirmation], ([$confirmation], set) => {
  const filters = $confirmation.available_confirmation_types;
  set(filters ? filters : []);
});

const confirmationsItems = derived(
  [confirmation, sort],
  ([$confirmation, $sort], set) => {
    const items = $confirmation.confirmation_items;
    set(items ? getSorted($sort, [...items]) : []);
  },
);

const groupedConfirmationItems = derived(
  [confirmation],
  ([$confirmation], set) => {
    const groupedItems = {
      bank: [],
      tax_advisor: [],
      lawyer: [],
    };

    $confirmation.confirmation_items.forEach((confirmationItem) => {
      const { confirmation_type, permalink, company, city, companies } =
        confirmationItem;
      groupedItems[confirmation_type].push({
        permalink,
        company,
        city,
        companies: companies.map((c) => c.name).join(", "),
      });
    });

    Object.values(groupedItems).forEach((group) => {
      group.sort((a, b) => a.company.localeCompare(b.company));
    });

    set(groupedItems);
  },
);

const usersByTypes = derived([confirmation], ([$confirmation], set) => {
  let types = $confirmation.available_confirmation_types
    ? $confirmation.available_confirmation_types.reduce(
        (prev, curr) => ({ ...prev, [curr]: [] }),
        {},
      )
    : [];
  let users = $confirmation.confirmation_client_users;

  if (users) {
    users.forEach((item) => {
      if (types[item.confirmation_type])
        types[item.confirmation_type].push(item);
    });
  }

  for (const type in types) {
    types[type].sort((a, b) =>
      a.client_user.email.localeCompare(b.client_user.email),
    );
  }

  set(types);
});

const authorizedSigners = derived([confirmation], ([$confirmation], set) => {
  let types = $confirmation.available_confirmation_types
    ? $confirmation.available_confirmation_types.reduce(
        (prev, curr) => ({ ...prev, [curr]: {} }),
        {},
      )
    : [];

  let items = $confirmation.confirmation_items;
  if (items) {
    items.forEach((item) => {
      item.authorized_signers.forEach((signer) => {
        let type = types[item.confirmation_type];

        if (type) {
          if (type[signer.email]) {
            type[signer.email]["count"] += 1;
          } else {
            type[signer.email] = {
              signer,
              count: 1,
            };
          }
        }
      });
    });
  }

  set(types);
});

confirmation.subscribe((value) => {
  if (value.permalink) {
    confirmationId.set(value.permalink);
  }

  breadcrumb.set([
    { translation: "projects", path: value.dashboard_path },
    { name: value.project_name, path: value.project_path },
    { name: value.name, path: "" },
  ]);
});

authorizedSigners.subscribe((signers) => {
  authorizedSignersCount.set(
    Object.values(signers).reduce(
      (prev, curr) => prev + Object.values(curr).length,
      0,
    ),
  );
});

function resetSelected() {
  selectedItems.set([]);
}

function setFilter(value, label, changeHistory) {
  resetSelected();
  filter.set(value);
  filterLable.set(label);
  getConfirmation();

  if (changeHistory) {
    if (value) {
      let filter_path = get(confirmation)[`${value}_confirmation_path`];

      if (filter_path) {
        history.pushState(null, null, filter_path);
      } else {
        history.pushState(null, null, get(confirmation).root_path);
      }
    } else {
      history.pushState(null, null, get(confirmation).root_path);
    }
  }
  window.scrollTo(0, 0);
}

function checkFilter(setFilter) {
  let path = window.location.pathname;
  let directories = path.split("/");
  let lastDirectory = directories[directories.length - 1];

  if (filtersStatus.includes(lastDirectory)) {
    setFilter(
      lastDirectory,
      get(t)(`confirmation_filters.${lastDirectory}`, false),
    );
  } else if (filtersNew.includes(lastDirectory)) {
    setFilter(
      lastDirectory,
      get(t)(`confirmation_sidebar_status.${lastDirectory}`, false),
    );
  } else if (get(filtersType).includes(lastDirectory)) {
    setFilter(
      lastDirectory,
      get(t)(`confirmation.types.${lastDirectory}`, false),
    );
  } else {
    setFilter(null, null, false);
  }
}

function checkFilterOnMount() {
  setTimeout(() => {
    checkFilter((value, label) => {
      filter.set(value);
      filterLable.set(label);
    }, 100);
  });
}

function checkFilterOnPopstate() {
  checkFilter(setFilter);
}

function getSorted(sort, items) {
  if (sort === "name") {
    return items.sort((a, b) => a.company.localeCompare(b.company));
  } else if (sort === "last-updated-desc") {
    return items.sort((a, b) => {
      return new Date(b.updated_at) - new Date(a.updated_at);
    });
  } else if (sort === "last-created-desc") {
    return items.sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    });
  } else {
    return items;
  }
}

function getConfirmation() {
  const params = {
    id: get(confirmationId),
  };

  if (get(filter)) {
    params.filter_param = get(filter).replace("_", "-");
  }

  ConfirmationApi.getConfirmation({
    params,
    success: (data) => {
      confirmation.set(data.confirmation);
    },
    error: () => {},
  });
}

function getRelease() {
  ConfirmationApi.getRelease({
    params: {
      id: get(confirmationId),
    },
    success: (data) => {
      release.set(data.mailings);
    },
    error: () => {},
  });
}

function createNewConfirmationItem(type) {
  activeAddListItem.set(true);
  activeAddListItemType.set(type);
}

function onPostComment(content, attachments, mentions) {
  CommentsApi.create({
    params: {
      parent_type: "confirmation",
      parent_id: get(confirmationId),
      content,
      attachments,
      mentions,
    },
    success: () => {
      getComments();
    },
  });
}

function getComments() {
  CommentsApi.getComments({
    params: {
      parent_type: "confirmation",
      parent_id: get(confirmationId),
    },
    success: (data) => {
      comments.set(data);
    },
  });
}

function getActivities(page = 1, success) {
  ConfirmationApi.getActivities({
    params: {
      page,
      id: get(confirmationId),
    },
    success: (data) => {
      activities.set({ ...data, loaded: true });
      success();
    },
  });
}

export {
  sort,
  filter,
  filterLable,
  filtersType,
  filtersNew,
  sidebar,
  release,
  comments,
  activities,
  usersByTypes,
  authorizedSigners,
  activeExport,
  activeRelease,
  activeLetterhead,
  activeRollForward,
  activeDownloadResponses,
  activeAddListItem,
  activeAddListItemType,
  activeSignersEdit,
  activeSettingsEdit,
  activeRemindSignersId,
  activeArchive,
  activeDelete,
  typeEdit,
  confirmation,
  selectedItems,
  confirmationId,
  confirmationsItems,
  groupedConfirmationItems,
  confirmationsNewItems,
  maxSignersPerItem,
  setFilter,
  checkFilterOnMount,
  checkFilterOnPopstate,
  resetSelected,
  getRelease,
  getConfirmation,
  createNewConfirmationItem,
  onPostComment,
  getComments,
  getActivities,
  authorizedSignersCount,
  letterhead,
};
