<script>
  import clsx from "clsx";
  import { DownloadsItem } from "components/downloads";
  import { t } from "stores/i18n.js";

  import { auditExportsList } from "stores/pbc.js";

  import AuditApi from "apis/pbc/audit.js";
  import { iconPath } from "lib/helpers.js";

  import { Boundary, Button, IconButton } from "components";
  import styles from "styleguide/Downloads.json";

  export let style = "";

  let isCollapsed = false;
  let hideAllTimeoutID = null;
  let hasCancelAllDownloadsIntent = false;

  const HIDE_TIME = 5000;

  $: checkNewDownloads($auditExportsList);
  $: statusStyles = getStatusStyles($auditExportsList);
  $: downloadsBeenCancelled = $auditExportsList.every(
    (item) => item.status === "cancelled",
  );
  $: downloadsBeenFailed = $auditExportsList.every(
    (item) => item.status === "failed",
  );

  function onCollapse() {
    isCollapsed = !isCollapsed;
  }

  function onCancelAllIntent() {
    hasCancelAllDownloadsIntent = !hasCancelAllDownloadsIntent;
  }

  function onExportCancel(response) {
    auditExportsList.update((auditExports) => {
      let newExport = response.data?.export;

      return auditExports.map((item) => {
        if (item.permalink === newExport.permalink) {
          item.status = newExport.status;
        }

        return item;
      });
    });

    setTimeout(() => {
      auditExportsList.update((items) => {
        return items.filter(
          (item) => item.permalink !== response.data?.export?.permalink,
        );
      });
    }, HIDE_TIME);
  }

  function getStatusStyles(downloads) {
    if (downloads.every((item) => item.status === "done")) {
      return "done";
    } else if (
      downloads.every(
        (item) => item.status === "cancelled" || item.status === "failed",
      )
    ) {
      return "cancelled";
    } else {
      return "processing";
    }
  }

  function onDiscardAllExports() {
    const exportsToBeCancelled = $auditExportsList.filter(
      (auditExport) => auditExport.status === "processing",
    );

    exportsToBeCancelled.forEach((item) =>
      AuditApi.cancelExport({
        params: { id: item.permalink },
        success: (response) => {
          hasCancelAllDownloadsIntent = false;
          onExportCancel(response);
        },
      }),
    );
  }

  function checkNewDownloads(downloads) {
    if (hideAllTimeoutID) clearTimeout(hideAllTimeoutID);

    if (!downloads.some((item) => item.status === "processing")) {
      hideAllTimeoutID = setTimeout(() => {
        auditExportsList.set([]);
      }, HIDE_TIME);
    }

    if (!downloads.every((item) => item.status === "cancelled")) {
      hasCancelAllDownloadsIntent = false;
    }
  }

  $: toggleIcon = isCollapsed ? "stroke-1-5-up" : "stroke-1-5-down";
  $: closeIcon = "red_close";
</script>

{#if $auditExportsList.length > 0}
  <Boundary>
    <div
      data-component="Downloads"
      class={clsx(
        styles.wrapper,
        style.split(" ").map((x) => styles[x]),
        styles[statusStyles],
      )}
    >
      <div class={styles.header}>
        <div class={styles.header_container}>
          {#if hasCancelAllDownloadsIntent}
            <img
              src={iconPath("error_filled")}
              alt={"alert"}
              class={styles.header_icon}
            />
          {/if}
          {$auditExportsList.length}
          {$t("downloads_state.title", {
            postProcess: "interval",
            count: $auditExportsList.length,
          })}
        </div>
        <div class={styles.collapse}>
          {#if !hasCancelAllDownloadsIntent && statusStyles !== "cancelled"}
            <IconButton
              click={onCollapse}
              style={"primary-text small"}
              icon={toggleIcon}
            />
            {#if statusStyles !== "done"}
              <IconButton
                click={onCancelAllIntent}
                style={"error-text small"}
                icon={closeIcon}
              />
            {/if}
          {/if}
        </div>
      </div>
      {#if !isCollapsed}
        {#if hasCancelAllDownloadsIntent}
          <div class={styles.warning}>
            <p class={styles.status}>{$t("downloads_state.confirm_cancel")}</p>
            <div class={styles.buttons}>
              <div class={styles.button}>
                <Button style={"primary-text small"} click={onCancelAllIntent}
                  >{$t("downloads_state.continue")}</Button
                >
              </div>
              <div class={styles.button}>
                <Button style={"error small"} click={onDiscardAllExports}
                  >{$t("downloads_state.discard_all")}</Button
                >
              </div>
            </div>
          </div>
        {:else if downloadsBeenCancelled}
          <div class={styles.warning}>
            <p class={styles.status}>
              {$t("downloads_state.downloads_canceled")}
            </p>
          </div>
        {:else if downloadsBeenFailed}
          <div class={styles.warning}>
            <p class={styles.status}>
              {$t("downloads_state.downloads_failed")}
            </p>
          </div>
        {:else}
          {#each $auditExportsList as auditExport (auditExport.permalink)}
            <DownloadsItem {auditExport} {onExportCancel} />
          {/each}
        {/if}
      {/if}
    </div>
  </Boundary>
{/if}

<style global lang="scss">
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 6px 16px 16px;
    font-weight: bold;
    border-bottom: 1px solid var(--primary-050);

    &::after {
      content: "";
      display: block;
      border-radius: 0 0 8px;
      position: absolute;
      right: 0%;
      top: 0%;
      bottom: 0%;
      width: 100%;
      height: 6px;
      border-radius: 8px 8px 0 0;
      background-color: var(--blue);

      .done & {
        background-color: var(--green);
      }
      .cancelled & {
        background-color: var(--red);
      }
    }
  }
  .header_container {
    display: flex;
    align-items: center;
  }
  .header_icon {
    margin-right: 12px;
  }
  .collapse {
    display: flex;
    align-items: center;
  }
  .wrapper {
    width: 340px;
    background: #fff;
    position: fixed;
    bottom: 0;
    right: 16px;
    box-shadow: 0px 0px 8px 2px rgba(41, 49, 61, 0.1);
    border-radius: var(--border-radius) var(--border-radius) 0 0;

    &.gap-bottom {
      bottom: 58px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
  }
  .warning {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 16px;
  }
  .file {
    display: flex;
    border-bottom: 1px solid var(--primary-050);
    text-align: left;
    padding: 12px 0;
    margin: 0 16px;
  }
  .icon {
    width: 16px;
    margin-right: 8px;
  }
  .name {
    width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .state {
    display: flex;
    margin-left: auto;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 16px;
  }
  .button {
    width: 48%;
  }
  .status {
    margin: 16px;
  }
</style>
