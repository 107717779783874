<script>
  import { ConfirmationStatus } from "apps/confirmation";
  import { ConfirmationItemModal } from "components/modals";
  import {
    filterLable,
    confirmation,
    confirmationId,
    getConfirmation,
    setFilter,
    activeAddListItem,
    activeAddListItemType,
  } from "stores/confirmation/confirmation.js";
  import { t } from "stores/i18n.js";
  import { user } from "stores/user.js";

  import { Button, LegacyButton, ListFilters } from "components";
  import styles from "styleguide/ConfirmationFilters.json";

  function addListItem() {
    activeAddListItem.set(true);
  }
</script>

{#if $filterLable}
  <div class={styles.filter}>
    <ListFilters>
      <ConfirmationStatus
        close={setFilter.bind(this, null, null, true)}
        color={"grey"}
        label={$filterLable}
        size="medium"
      />
    </ListFilters>
  </div>
{/if}

<div class={styles.wrapper} data-component="ConfirmationFilters">
  <div class={styles.item}>
    <LegacyButton
      style={"border fullsize"}
      click={setFilter.bind(
        this,
        "imported",
        $t("confirmation_filters.imported", true),
      )}
    >
      {$t("confirmation.status.imported")}
      {`(${$confirmation.counters.imported_count})`}</LegacyButton
    >
  </div>
  <div class={styles.item}>
    <LegacyButton
      style={"border fullsize"}
      click={setFilter.bind(
        this,
        "sent",
        $t("confirmation_filters.sent", true),
      )}
    >
      {$t("confirmation.status.sent")}
      {`(${$confirmation.counters.sent_count})`}</LegacyButton
    >
  </div>
  <div class={styles.item}>
    <LegacyButton
      style={"border fullsize"}
      click={setFilter.bind(
        this,
        "received",
        $t("confirmation_filters.received", true),
      )}
    >
      {$t("confirmation_filters.received")}
      {`(${$confirmation.counters.received_count})`}</LegacyButton
    >
  </div>

  {#if $user.role !== "client"}
    <div class={styles.item}>
      <Button click={addListItem} style="primary-tonal"
        >{$t("confirmation_filters.add_list_item")}</Button
      >
    </div>
  {/if}
</div>

{#if $activeAddListItem}
  <ConfirmationItemModal
    action={"add"}
    {confirmationId}
    type={$activeAddListItemType}
    hide={() => {
      activeAddListItem.set(false);
    }}
    bind:shown={$activeAddListItem}
    success={getConfirmation}
  />
{/if}

<style lang="scss">
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .item {
    width: 100%;
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }

    &:nth-child(4) {
      width: auto;
      margin-left: 25px;
    }
  }

  .filter {
    margin-bottom: 10px;
  }
</style>
