<script>
  import styles from "styleguide/ConfirmationSignerDraw.json";

  import { onMount, onDestroy } from "svelte";
  import SignaturePad from "signature_pad";

  import { isDesktopWidth, isVertical } from "stores/device.js";
  import { t } from "stores/i18n.js";
  import { toasts } from "stores/toasts.js";
  import { token } from "stores/confirmation/confirmation_signer";

  import { Button } from "components";
  import { Modal } from "components/modals";

  export let shown;
  export let setSignature;

  let signatureImg;
  let signatureBlob;
  let canvas;
  let wrapper;
  let signaturePad;

  const onHide = function () {
    shown = false;
  };

  function clear() {
    signaturePad.clear();
  }

  function save() {
    if (signaturePad.isEmpty()) {
      return toasts.send({
        title: $t("confirmation_signer_draw.error_no_signature"),
        type: "error",
      });
    }

    signatureImg = signaturePad.toDataURL("image/png");
    canvas.toBlob(
      (blob) => {
        signatureBlob = blob;
        setSignature(signatureImg, signatureBlob, $token);
      },
      "image/png",
      1,
    );

    onHide();
  }

  function resizeCanvas() {
    canvas.width = wrapper.offsetWidth;
    canvas.height = wrapper.offsetWidth / 2;
    signaturePad.clear(); // otherwise isEmpty() might return incorrect value
  }

  onMount(async () => {
    signaturePad = new SignaturePad(canvas, {
      backgroundColor: "rgb(255, 255, 255)", // necessary for saving image as JPEG; can be removed is only saving as PNG or SVG
    });

    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();
  });

  onDestroy(() => {
    window.removeEventListener("resize", resizeCanvas);
  });
</script>

<Modal
  style={$isDesktopWidth || !$isVertical ? "small" : "mobile"}
  {shown}
  {onHide}
  title={$t("confirmation_signer_draw.title")}
>
  <div
    bind:this={wrapper}
    class={styles.wrapper}
    data-component="ConfirmationSignerDraw"
  >
    <canvas bind:this={canvas} class={styles.pad} />

    <div class={styles.actions}>
      <Button click={clear} style="error-text">{$t("actions.clear")}</Button>

      <Button click={save} style="primary">{$t("actions.save")}</Button>
    </div>
  </div>
</Modal>

<style lang="scss">
  .pad {
    background-color: #fff;
    border: 1px solid var(--blue);
    user-select: none;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    margin: 8px 0;
    gap: 8px;
  }
</style>
