export const SAMPLE_CONTACT_FIELDS_GROUPS = Object.freeze({
  request_value: ["request_value"],
  contact: [
    "company",
    "street",
    "zip",
    "city",
    "country",
    "language",
    "contact_name",
    "email",
  ],
});
