<script>
  import { getContext } from "svelte";

  import { Modal } from "components/modals";
  import { t } from "stores/i18n.js";
  import {
    audit,
    activeAudit,
    isNumeration,
    improvedClientRights,
  } from "stores/pbc.js";
  import { can } from "stores/permission.js";
  import { isAccountFeature } from "stores/account";
  import { toasts } from "stores/toasts.js";
  import ProjectsApi from "apis/dashboard/projects.js";
  import AuditApi from "apis/pbc/audit.js";

  import { enterYesConfirmation } from "lib/helpers.js";

  import {
    Button,
    Checkbox,
    Dropdown,
    Search,
    TextInput,
    Toast,
  } from "components";
  import styles from "styleguide/AuditModal.json";

  let dirty;
  let dirtyDelete;
  let projectId;
  let valueEditName;
  let confirmationText;
  const { language } = getContext("config");

  function modifySearchResults(response) {
    return response.data.projects.map((item) => {
      return {
        value: item.id,
        label: item.text,
      };
    });
  }

  function setProject(id) {
    projectId = id;
  }

  function onEdit(auditName) {
    dirty = false;

    const params = {
      name: auditName,
      enumerated: $isNumeration,
    };

    if (projectId) params.projectId = projectId;

    if (auditName) {
      AuditApi.edit({
        params,
        success: onEditSuccess,
        error: onEditError,
      });
      dirty = false;
    } else {
      dirty = true;
    }
  }

  function onEditSuccess() {
    AuditApi.getAudits();
    activeAudit.set(null);
  }

  function onEditError() {
    dirty = true;
  }

  function onArchive(archived) {
    const isConfirmed = confirm($t("questions.sure"));

    if (isConfirmed) {
      AuditApi.archive({
        params: {
          archived,
        },
        success: () => {
          if (archived) {
            window.location = $audit.project_path;
          } else {
            onEditSuccess();
            toasts.send({
              title: $t("audit_modal.unarchive_success"),
              type: "success",
            });
          }
        },
      });
    }
  }

  function onDelete() {
    if (enterYesConfirmation(confirmationText, language)) {
      AuditApi.delete();
    } else {
      dirtyDelete = true;
    }
  }

  function onNumerationToggle() {
    AuditApi.edit({
      params: {
        enumerated: $isNumeration,
      },
    });
  }

  function changeClientsRights(value) {
    AuditApi.toggleImprovedRights();
    improvedClientRights.set(value);
  }

  const onHide = function () {
    activeAudit.set(false);
  };

  audit.subscribe((value) => {
    valueEditName = value.name;
  });
</script>

{#if $activeAudit}
  <Modal shown={$activeAudit !== null} {onHide} title={$t("audit_modal.edit")}>
    <div data-component="AuditModal">
      <div class={styles.input}>
        <Dropdown
          initial={$audit.client_name}
          label={$t("audit_modal.client_label")}
          lock={true}
        />
      </div>

      <div class={styles.input}>
        <TextInput
          label={$t("audit_modal.audit_name")}
          bind:value={valueEditName}
          onEnter={onEdit}
          errors={dirty ? [""] : []}
        />
      </div>

      <div class={styles.input}>
        <Search
          clear={false}
          select={setProject}
          {modifySearchResults}
          searchApi={ProjectsApi}
          label={$t("audit_modal.project_label")}
          placeholder={$t("audit_modal.project")}
          footnotes={[$t("audit_modal.project_footnotes")]}
        />
      </div>

      <div class={styles.checkbox}>
        <Checkbox
          toggle={onNumerationToggle}
          bind:toggled={$isNumeration}
          label={$t("audit_modal.numeration")}
          footnotes={[$t("audit_modal.numeration_footnote")]}
        />
      </div>

      {#if $can("archive", "audit")}
        {#if $audit.archived}
          <Button style={"error"} click={onArchive.bind(this, false)}
            >{$t("audit_modal.unarchive_button")}</Button
          >
        {:else}
          <div class={styles.infobox}>
            <Toast
              type="error"
              title={$t("audit_modal.archive_title")}
              message={$t("audit_modal.archive_description")}
            >
              <div class={styles["infobox-warning"]}>
                {@html $t("audit_modal.archive_warning")}
              </div>
              <div class={styles["infobox-confirmation"]}>
                <Button style={"error"} click={onArchive.bind(this, true)}
                  >{$t("audit_modal.archive_button")}</Button
                >
              </div>
            </Toast>
          </div>
        {/if}
      {/if}

      {#if $can("destroy", "audit")}
        <div class={styles.infobox}>
          <Toast
            type="error"
            margin={false}
            title={$t("audit_modal.delete_title")}
            message={$t("audit_modal.delete_description")}
          >
            <div class={styles["infobox-confirmation"]}>
              <div class={styles["infobox-input"]}>
                <TextInput
                  onEnter={onDelete}
                  placeholder={$t("audit_modal.delete_placeholder")}
                  bind:value={confirmationText}
                  errors={dirtyDelete
                    ? [$t("audit_modal.confirmation_error")]
                    : []}
                  style={"border medium round-border"}
                />
              </div>
              <Button style={"error"} click={onDelete}
                >{$t("audit_modal.delete_button")}</Button
              >
            </div>
          </Toast>
        </div>
      {/if}
    </div>
    <svelte:fragment slot="bottom">
      {#if $can("update", "audit")}
        <div class={styles.button}>
          <Button click={onEdit.bind(this, valueEditName)} style={"primary"}>
            {$t("audit_modal.save")}
          </Button>
        </div>
      {/if}
    </svelte:fragment>
  </Modal>
{/if}

<style lang="scss">
  .input,
  .checkbox {
    margin-bottom: 16px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
  }

  .button {
    margin-left: 20px;
  }

  .infobox {
    margin: 20px 0;

    &:last-child {
      margin: 0;
    }
  }

  .infobox-warning {
    color: var(--primary-500);
    font-weight: bold;
    margin-bottom: 15px;
  }

  .infobox-confirmation {
    display: flex;
  }

  .infobox-input {
    width: 150px;
    margin-right: 12px;
  }
</style>
