<script>
  export let width = 18;
  export let height = 18;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 18 18"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M8.9998,0.7503 C9.37949577,0.7503 9.69329096,1.03245388 9.74295338,1.39852944 L9.7498,1.5003 L9.7498,16.5003 C9.7498,16.9145136 9.41401356,17.2503 8.9998,17.2503 C8.62010423,17.2503 8.30630904,16.9681461 8.25664662,16.6020706 L8.2498,16.5003 L8.2498,1.5003 C8.2498,1.08608644 8.58558644,0.7503 8.9998,0.7503 Z M4.9998,5.7503 C5.37949577,5.7503 5.69329096,6.03245388 5.74295338,6.39852944 L5.7498,6.5003 L5.7498,16.5003 C5.7498,16.9145136 5.41401356,17.2503 4.9998,17.2503 C4.62010423,17.2503 4.30630904,16.9681461 4.25664662,16.6020706 L4.2498,16.5003 L4.2498,6.5003 C4.2498,6.08608644 4.58558644,5.7503 4.9998,5.7503 Z M12.9998,9.49085521 C13.3794958,9.49085521 13.693291,9.77300909 13.7429534,10.1390847 L13.7498,10.2408552 L13.7498,16.5003 C13.7498,16.9145136 13.4140136,17.2503 12.9998,17.2503 C12.6201042,17.2503 12.306309,16.9681461 12.2566466,16.6020706 L12.2498,16.5003 L12.2498,10.2408552 C12.2498,9.82664165 12.5855864,9.49085521 12.9998,9.49085521 Z"
  />
</svg>
