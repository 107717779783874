<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(72.941176%,0%,0%);fill-opacity:1;stroke:none;"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,93.72549%,0%);fill-opacity:0.988235;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 281.018622 376.011111 C 281.218622 375.405556 281.624178 369.166667 281.424178 369.166667 C 281.218622 369.166667 271.963067 358.3 272.163067 358.3 C 272.368622 358.3 284.040844 360.916667 284.040844 360.511111 C 284.040844 360.111111 288.668622 349.044444 288.668622 348.838889 C 288.668622 348.638889 294.301956 362.322222 294.301956 362.322222 L 305.774178 367.355556 L 297.724178 373.994444 C 297.724178 373.994444 299.535289 386.877778 299.535289 387.077778 C 299.535289 387.277778 291.485289 379.433333 291.485289 379.433333 L 282.629733 380.438889 C 282.629733 380.438889 281.424178 376.411111 281.018622 376.011111 Z M 281.018622 376.011111 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:2.6358;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 218.657511 206.888889 C 211.013067 210.827778 181.663067 244.377778 177.640844 249.605556 C 174.268622 257.205556 167.668622 266.811111 160.879733 273 C 153.624178 278.483333 149.5964 285.983333 150.590844 293.077778 C 150.513067 301.905556 155.463067 307.922222 159.407511 314.133333 C 161.740844 316.972222 164.879733 318.805556 168.224178 319.027778 C 175.074178 319.933333 175.679733 322.044444 179.107511 323.35 C 192.613067 341.738889 212.763067 355.3 227.274178 366.038889 C 236.524178 370.916667 242.951956 375.788889 245.157511 378.45 C 249.407511 386.561111 248.624178 394.472222 248.040844 398.361111 C 244.451956 411.911111 240.857511 425.455556 237.268622 439.005556 C 235.457511 450.077778 245.074178 447.583333 245.5964 445.372222 C 249.935289 439.738889 256.413067 443.694444 265.668622 411.094444 C 269.913067 405.377778 274.157511 399.666667 278.401956 393.955556 C 278.401956 393.955556 283.301956 391.994444 283.301956 391.505556 C 290.7464 382.561111 285.079733 377.444444 280.851956 375.833333 C 277.751956 374.694444 274.6464 373.55 271.5464 372.405556 C 271.5464 372.405556 260.774178 361.633333 260.285289 361.633333 C 255.163067 346.738889 230.035289 314.944444 224.201956 310.516667 C 220.157511 306.311111 218.235289 304.516667 214.401956 302.172222 C 208.507511 299.35 206.601956 298.433333 202.990844 296.994444 C 199.985289 295.788889 202.124178 292.483333 204.035289 290.977778 C 223.940844 280.144444 239.718622 268.1 258.813067 255.861111 C 259.7964 255.205556 260.774178 254.55 261.751956 253.9 C 259.468622 240.838889 257.179733 227.783333 254.8964 214.722222 C 244.285289 210.972222 233.674178 207.216667 223.068622 203.461111 C 221.3964 204.605556 220.129733 205.744444 218.663067 206.888889 Z M 218.657511 206.888889 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,92.54902%,0%);fill-opacity:1;"
      d="M 227.046875 290.496094 C 227.117188 290.214844 241.054688 287.664062 241.054688 287.664062 C 241.054688 287.664062 239.425781 292.832031 239.355469 292.832031 L 225.488281 297.003906 Z M 227.046875 290.496094 "
    />
    <path
      style="fill:none;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 193.640844 338.511111 C 197.463067 328.344444 208.079733 320.3 215.207511 318.077778 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill:none;stroke-width:2.285;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 244.579733 413.322222 C 245.857511 412.961111 251.540844 411.3 253.1964 411.044444 C 254.963067 410.627778 256.718622 410.166667 258.418622 409.622222 C 260.374178 409.222222 261.601956 408.85 263.635289 408.483333 C 265.229733 407.872222 266.807511 407.455556 268.507511 407.055556 M 240.990844 425.577778 C 241.157511 425.355556 242.507511 424.161111 243.479733 423.583333 C 244.618622 423.222222 248.524178 421.566667 250.001956 421.305556 C 251.579733 420.894444 253.151956 420.433333 254.663067 419.883333 C 256.418622 419.483333 257.513067 419.116667 259.329733 418.744444 C 260.751956 418.138889 262.157511 417.722222 263.679733 417.322222 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill:none;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 249.040844 341.377778 L 248.757511 341.377778 C 249.6464 341.377778 249.101956 341.305556 247.901956 342.805556 C 247.474178 343.955556 243.818622 346.505556 241.068622 346.505556 C 239.740844 346.644444 237.701956 346.788889 236.374178 346.788889 C 235.9964 346.788889 235.474178 346.505556 235.090844 346.505556 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill:none;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.757511 400.022222 L 250.179733 400.022222 C 251.657511 400.022222 253.140844 400.283333 254.451956 400.305556 C 255.874178 400.305556 257.2964 400.705556 258.724178 400.705556 C 260.157511 400.911111 261.890844 400.844444 263.374178 401.055556 C 265.040844 401.111111 266.379733 401.438889 268.113067 401.438889 C 269.513067 401.494444 270.263067 401.727778 271.813067 401.727778 L 268.113067 401.438889 C 269.513067 401.494444 270.263067 401.727778 271.813067 401.727778 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill:none;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 239.079733 434.738889 C 239.229733 434.516667 240.468622 433.322222 241.357511 432.75 C 242.401956 432.388889 245.979733 430.727778 247.335289 430.472222 C 248.779733 430.061111 250.213067 429.594444 251.601956 429.05 C 253.207511 428.65 254.207511 428.277778 255.874178 427.911111 C 257.174178 427.3 258.463067 426.883333 259.857511 426.483333 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill:none;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 265.729733 385.272222 C 264.924178 387.605556 265.968622 388.194444 266.401956 389.2 C 267.151956 390.177778 269.068622 391.666667 272.090844 392.616667 C 273.257511 392.933333 274.157511 393.238889 275.507511 393.755556 C 276.401956 393.838889 276.840844 394.061111 277.501956 394.327778 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill:none;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 153.129733 299.55 L 153.413067 299.55 C 152.524178 299.55 153.057511 299.605556 154.551956 298.411111 C 155.479733 297.377778 156.251956 296.911111 157.113067 295.85 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill:none;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 171.551956 319.822222 C 171.740844 319.822222 188.107511 311.572222 189.857511 309.811111 C 191.090844 308.911111 192.029733 307.966667 193.535289 306.944444 C 194.501956 306.438889 195.1964 305.794444 196.0964 305.238889 C 196.851956 304.144444 197.835289 303.488889 198.374178 302.494444 C 199.379733 301.622222 198.9464 300.677778 199.763067 299.627778 C 200.1464 298.555556 200.868622 296.744444 201.101956 295.761111 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill:none;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 221.951956 308 C 222.040844 308.588889 221.690844 310.4 221.713067 311.783333 C 221.657511 313.383333 219.601956 318.327778 217.113067 319.8 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill:none;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 192.551956 307.822222 C 192.6464 307.822222 195.135289 308.627778 199.301956 309.911111 C 204.313067 311.711111 214.740844 318.283333 215.774178 319.188889 C 216.829733 320.016667 218.718622 320.761111 219.440844 321.75 C 220.574178 322.727778 221.401956 323.855556 222.290844 324.877778 C 223.329733 326.144444 224.213067 327.216667 224.851956 328.294444 C 228.013067 330.861111 236.529733 348.638889 236.801956 349.638889 C 237.279733 350.577778 237.874178 351.888889 238.224178 352.772222 C 238.857511 353.5 239.285289 354.527778 239.935289 355.616667 C 240.529733 357.033333 241.1964 357.677778 241.929733 358.75 C 242.868622 359.405556 244.140844 360.644444 245.340844 361.311111 C 246.624178 362.405556 247.829733 362.855556 248.757511 363.588889 C 250.101956 364.155556 265.101956 373.638889 265.7964 374.111111 C 267.168622 375.216667 271.351956 379.55 268.413067 382.705556 C 267.168622 383.772222 266.0464 385.183333 264.979733 385.788889 C 263.8964 386.872222 262.385289 387.361111 261.140844 387.922222 C 254.379733 389.916667 250.940844 389.2 249.613067 389.2 L 248.185289 389.2 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill:none;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 159.418622 274.288889 C 161.340844 275.044444 160.563067 274.488889 162.2964 275.272222 C 163.457511 275.783333 164.224178 275.794444 165.363067 276.211111 C 166.574178 276.6 169.963067 277.211111 171.590844 278.844444 C 172.779733 279.922222 173.690844 280.833333 175.001956 281.622222 C 176.551956 282.688889 177.674178 283.166667 179.5964 283.966667 C 181.218622 284.572222 183.029733 285.244444 184.668622 285.305556 C 186.374178 285.405556 186.785289 285.316667 188.418622 285.316667 L 192.401956 285.316667 L 188.418622 285.316667 L 192.401956 285.316667 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,90.196078%,2.352941%);fill-opacity:1;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 158.957511 293.277778 C 163.229733 292.994444 170.340844 294.277778 170.485289 294.277778 C 170.485289 294.277778 179.879733 294.133333 179.879733 294.133333 C 184.813067 293.661111 185.901956 291.761111 186.707511 290.433333 C 188.557511 287.588889 189.840844 286.588889 191.263067 284.455556 C 193.540844 282.75 196.674178 286.733333 196.813067 286.733333 C 204.779733 294.277778 198.379733 303.527778 197.951956 303.811111 C 193.968622 307.461111 193.113067 307.7 190.6964 305.377778 C 188.274178 302.527778 187.563067 301.25 185.568622 300.25 C 181.729733 298.4 173.757511 299.827778 173.618622 299.827778 C 173.474178 299.827778 169.490844 301.388889 169.490844 301.388889 C 167.5464 302.1 166.313067 303.811111 162.9464 304.377778 C 159.529733 304.616667 158.390844 304.283333 156.679733 301.533333 C 154.407511 297.977778 155.540844 293.705556 158.957511 293.277778 Z M 158.957511 293.277778 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,93.72549%,0%);fill-opacity:0.988235;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 381.801956 120.661111 C 381.174178 120.783333 375.540844 123.488889 375.635289 123.666667 C 375.735289 123.838889 370.801956 137.233333 370.701956 137.061111 C 370.601956 136.883333 367.163067 125.427778 366.813067 125.622222 C 366.463067 125.822222 354.5464 127.211111 354.368622 127.311111 C 354.1964 127.405556 363.363067 115.788889 363.363067 115.788889 L 362.124178 103.322222 L 371.857511 107.088889 C 371.857511 107.088889 382.201956 99.194444 382.374178 99.094444 C 382.551956 99 379.651956 109.861111 379.651956 109.861111 L 384.868622 117.088889 C 384.868622 117.088889 381.951956 120.111111 381.801956 120.661111 Z M 381.801956 120.661111 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:2.6358;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 264.929733 257.9 C 272.107511 262.633333 315.735289 271.777778 322.268622 272.722222 C 330.5464 271.938889 342.151956 272.977778 350.874178 275.872222 C 359.213067 279.5 367.718622 279.344444 373.418622 274.994444 C 381.151956 270.738889 383.968622 263.472222 387.451956 256.988889 C 388.779733 253.566667 388.840844 249.927778 387.401956 246.905556 C 384.829733 240.494444 386.374178 238.927778 385.829733 235.3 C 395.240844 214.516667 397.185289 190.305556 399.435289 172.394444 C 399.151956 161.944444 400.251956 153.95 401.490844 150.722222 C 406.479733 143.044444 413.757511 139.85 417.429733 138.45 C 431.001956 134.938889 444.574178 131.433333 458.140844 127.922222 C 468.679733 124.077778 461.7964 116.911111 459.613067 117.544444 C 452.568622 116.522222 452.8464 108.938889 419.890844 116.844444 C 412.829733 115.944444 405.768622 115.044444 398.713067 114.144444 C 398.713067 114.144444 394.601956 110.838889 394.174178 111.077778 C 382.729733 108.966667 381.051956 116.416667 381.718622 120.894444 C 382.240844 124.155556 382.763067 127.416667 383.290844 130.683333 C 383.290844 130.683333 379.179733 145.355556 379.418622 145.777778 C 368.9464 157.538889 353.357511 195.305556 352.357511 202.566667 C 351.885289 209.383333 349.307511 208.155556 350.001956 214.783333 C 350.024178 219.961111 349.424178 216.727778 351.151956 227.4 C 351.574178 230.611111 347.6464 230.366667 345.3964 229.438889 C 326.1964 217.4 307.968622 209.544444 287.935289 198.9 C 286.890844 198.366667 285.840844 197.833333 284.790844 197.3 C 274.529733 205.688889 264.263067 214.083333 254.001956 222.472222 C 255.929733 233.561111 257.857511 244.65 259.785289 255.738889 C 261.5964 256.633333 263.213067 257.177778 264.929733 257.9 Z M 264.929733 257.9 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,92.54902%,0%);fill-opacity:1;"
      d="M 358.09375 93.9375 C 357.8125 94.015625 348.761719 83.113281 348.761719 83.113281 C 348.761719 83.113281 354.0625 82 354.097656 82.0625 L 364.53125 92.105469 Z M 358.09375 93.9375 "
    />
    <path
      style="fill:none;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 391.929733 215.211111 C 381.1964 216.855556 368.979733 211.55 363.551956 206.422222 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill:none;stroke-width:2.285;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 432.179733 134.138889 C 431.240844 133.2 427.007511 129.061111 425.974178 127.744444 C 424.7464 126.411111 423.485289 125.105556 422.174178 123.894444 C 420.863067 122.383333 419.940844 121.494444 418.624178 119.9 C 417.313067 118.811111 416.174178 117.638889 414.9964 116.35 M 444.624178 131.261111 C 444.3464 131.227778 442.6464 130.633333 441.668622 130.072222 C 440.790844 129.25 437.435289 126.661111 436.485289 125.494444 C 435.351956 124.327778 434.179733 123.183333 432.957511 122.133333 C 431.751956 120.8 430.8964 120.027778 429.679733 118.622222 C 428.451956 117.683333 427.401956 116.661111 426.307511 115.527778 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill:none;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 367.279733 165.511111 C 367.329733 165.594444 367.374178 165.677778 367.418622 165.755556 C 366.985289 164.983333 367.185289 165.494444 369.079733 165.805556 C 370.290844 165.611111 374.301956 167.55 375.651956 169.944444 C 376.424178 171.033333 377.551956 172.744444 378.201956 173.9 C 378.385289 174.227778 378.3964 174.827778 378.579733 175.155556 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill:none;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 418.529733 137.022222 C 418.485289 136.938889 418.435289 136.855556 418.390844 136.772222 C 418.579733 137.105556 418.018622 136.111111 417.835289 135.783333 C 417.107511 134.494444 416.613067 133.072222 415.990844 131.922222 C 415.290844 130.677778 414.9464 129.238889 414.2464 128 C 413.718622 126.65 412.813067 125.172222 412.268622 123.772222 C 411.501956 122.294444 411.135289 120.966667 410.285289 119.455556 C 409.6464 118.211111 409.479733 117.438889 408.718622 116.088889 L 410.285289 119.455556 C 409.6464 118.211111 409.479733 117.438889 408.718622 116.088889 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill:none;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 453.540844 128.438889 C 453.274178 128.416667 451.624178 127.922222 450.690844 127.433333 C 449.863067 126.694444 446.663067 124.388889 445.774178 123.338889 C 444.707511 122.283333 443.601956 121.255556 442.440844 120.316667 C 441.307511 119.111111 440.4964 118.422222 439.357511 117.15 C 438.185289 116.311111 437.190844 115.394444 436.163067 114.377778 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill:none;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 397.351956 129.45 C 399.779733 129.005556 399.779733 127.811111 400.451956 126.938889 C 400.935289 125.805556 401.290844 123.405556 400.635289 120.3 C 400.340844 119.133333 400.168622 118.2 399.957511 116.766667 C 399.590844 115.95 399.568622 115.455556 399.474178 114.75 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill:none;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 377.818622 269.622222 C 377.774178 269.538889 377.724178 269.455556 377.679733 269.372222 C 378.118622 270.15 377.907511 269.655556 376.129733 268.938889 C 374.774178 268.638889 373.990844 268.194444 372.640844 267.961111 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill:none;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 386.457511 243.622222 C 386.368622 243.455556 371.157511 233.227778 368.763067 232.572222 C 367.374178 231.933333 366.090844 231.577778 364.463067 230.766667 C 363.5464 230.172222 362.6464 229.883333 361.718622 229.366667 C 360.401956 229.25 359.3464 228.716667 358.207511 228.727778 C 356.957511 228.283333 356.3464 229.122222 355.029733 228.922222 C 353.907511 229.116667 351.974178 229.372222 351.001956 229.65 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill:none;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 351.457511 205.477778 C 351.929733 205.111111 353.679733 204.527778 354.874178 203.833333 C 356.2964 203.094444 361.613067 202.466667 364.118622 203.916667 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill:none;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 365.707511 231.2 C 365.663067 231.116667 365.1464 228.55 364.224178 224.288889 C 363.340844 219.038889 363.957511 206.733333 364.235289 205.388889 C 364.4464 204.055556 364.163067 202.044444 364.668622 200.933333 C 364.968622 199.466667 365.5464 198.188889 366.001956 196.916667 C 366.601956 195.388889 367.101956 194.088889 367.724178 193.011111 C 368.413067 188.994444 379.735289 172.861111 380.474178 172.127778 C 381.057511 171.255556 381.907511 170.094444 382.507511 169.355556 C 382.829733 168.45 383.518622 167.572222 384.151956 166.472222 C 385.090844 165.261111 385.329733 164.361111 385.901956 163.2 C 386.013067 162.055556 386.474178 160.338889 386.463067 158.966667 C 386.790844 157.311111 386.590844 156.044444 386.774178 154.872222 C 386.613067 153.422222 387.529733 135.7 387.601956 134.861111 C 387.885289 133.127778 389.618622 127.355556 393.807511 128.372222 C 395.3464 128.933333 397.124178 129.222222 398.174178 129.855556 C 399.651956 130.266667 400.813067 131.344444 401.918622 132.155556 C 406.968622 137.066667 408.029733 140.416667 408.685289 141.577778 C 408.918622 141.988889 409.1464 142.405556 409.379733 142.816667 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill:none;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 352.640844 275.938889 C 352.5964 275.855556 352.5464 275.772222 352.501956 275.694444 C 352.879733 276.361111 352.668622 276.027778 352.190844 274.561111 C 352.063067 273.3 351.674178 271.588889 351.479733 270.388889 C 351.224178 269.144444 350.640844 267.722222 350.7964 266.855556 C 350.351956 265.588889 350.629733 264.544444 350.751956 263.288889 C 351.107511 261.722222 351.268622 260.583333 351.307511 259.055556 C 351.479733 257.183333 351.3464 255.85 351.451956 254.077778 C 350.957511 252.616667 350.857511 251.4 350.101956 249.938889 C 349.429733 248.544444 348.918622 247.255556 348.118622 245.833333 L 346.163067 242.355556 L 348.118622 245.833333 L 346.163067 242.355556 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,90.196078%,2.352941%);fill-opacity:1;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 369.501956 267.6 C 367.163067 264.016667 364.790844 257.188889 364.718622 257.066667 C 364.718622 257.066667 359.990844 248.944444 359.990844 248.944444 C 357.157511 244.877778 354.974178 244.861111 353.418622 244.805556 C 350.035289 244.588889 348.535289 243.961111 345.979733 243.766667 C 343.374178 242.616667 345.313067 237.933333 345.240844 237.811111 C 347.913067 227.172222 359.113067 228.216667 359.568622 228.45 C 364.707511 230.133333 365.329733 230.761111 364.490844 234.011111 C 363.1964 237.516667 362.429733 238.761111 362.535289 240.988889 C 362.807511 245.238889 367.951956 251.488889 368.024178 251.616667 C 368.090844 251.738889 371.413067 254.444444 371.413067 254.444444 C 372.985289 255.794444 375.079733 256.027778 377.224178 258.688889 C 379.101956 261.55 379.374178 262.705556 377.813067 265.538889 C 375.824178 269.266667 371.5464 270.366667 369.501956 267.6 Z M 369.501956 267.6 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,93.72549%,0%);fill-opacity:0.988235;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 238.86121 224.499894 C 238.981902 224.13429 239.226512 220.360638 239.101756 220.361037 C 238.979983 220.359887 233.381197 213.784736 233.50297 213.785886 C 233.624743 213.787036 240.686648 215.368936 240.684417 215.123955 C 240.686717 214.880408 243.485769 208.186261 243.486919 208.064488 C 243.485087 207.944263 246.89564 216.219774 246.89564 216.219774 L 253.834016 219.263125 L 248.964935 223.283141 C 248.964935 223.283141 250.060772 231.075348 250.059622 231.197121 C 250.061454 231.317346 245.190972 226.567953 245.190972 226.567953 L 239.830976 227.177269 C 239.830976 227.177269 239.103891 224.74121 238.86121 224.499894 Z M 238.86121 224.499894 "
      transform="matrix(-0.535584,1.031555,-1.031555,-0.535584,488.088469,-7.562109)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.5945;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 201.140273 122.200204 C 196.515294 124.582565 178.761193 144.876788 176.326224 148.04198 C 174.284304 152.635194 170.293349 158.448621 166.185143 162.190979 C 161.79692 165.510709 159.360256 170.043802 159.965352 174.338122 C 159.914522 179.681124 162.911624 183.316673 165.294292 187.074326 C 166.707868 188.790429 168.606773 189.90238 170.627589 190.038426 C 174.772577 190.582517 175.140793 191.860602 177.213676 192.651231 C 185.386061 203.774791 197.570957 211.978177 206.35071 218.473272 C 211.945337 221.422866 215.836913 224.374371 217.167665 225.982007 C 219.739247 230.891205 219.265005 235.673971 218.914543 238.025745 C 216.741186 246.224035 214.569263 254.417794 212.398889 262.614535 C 211.301453 269.311303 217.119769 267.805131 217.431547 266.465573 C 220.058216 263.056953 223.977338 265.448863 229.578114 245.727719 C 232.145418 242.2704 234.71427 238.816063 237.27859 235.360293 C 237.27859 235.360293 240.241275 234.174232 240.242773 233.878087 C 244.748 228.471692 241.318684 225.37485 238.76049 224.400718 C 236.88601 223.707776 235.008547 223.016382 233.132632 222.327971 C 233.132632 222.327971 226.616271 215.808897 226.320125 215.807398 C 223.223114 206.801082 207.930074 187.381905 204.400427 184.70447 C 200.94161 182.433312 202.357486 181.440688 198.656368 179.840626 C 195.91544 178.31762 197.801164 178.959283 191.659487 176.706475 C 189.841564 175.976595 191.136894 173.978691 192.295455 173.066651 C 204.335061 166.512733 213.878816 159.228747 225.43043 151.822461 C 226.024163 151.427099 226.614914 151.033285 227.208648 150.637922 C 225.825559 142.737358 224.444019 134.839776 223.060931 126.939211 C 216.642439 124.671081 210.223833 122.395437 203.805342 120.127307 C 202.796723 120.817601 202.027119 121.508761 201.140273 122.200204 Z M 201.140273 122.200204 "
      transform="matrix(-0.535584,1.031555,-1.031555,-0.535584,488.088469,-7.562109)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,92.54902%,0%);fill-opacity:1;"
      d="M 116.96875 84.269531 C 117.1875 84.464844 113.027344 98.007812 113.027344 98.007812 C 113.027344 98.007812 109.191406 94.183594 109.226562 94.117188 L 111.910156 79.890625 Z M 116.96875 84.269531 "
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 185.99962 201.820351 C 188.313975 195.673226 194.733378 190.80284 199.04653 189.460909 "
      transform="matrix(-0.535584,1.031555,-1.031555,-0.535584,488.088469,-7.562109)"
    />
    <path
      style="fill:none;stroke-width:1.38225;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 216.820207 247.069109 C 217.594044 246.852883 221.029995 245.849006 222.031718 245.692439 C 223.099694 245.444673 224.161131 245.162433 225.190276 244.832585 C 226.37406 244.589065 227.117726 244.365784 228.346937 244.144119 C 229.310866 243.776183 230.266016 243.522621 231.296536 243.282941 M 214.648417 254.482726 C 214.750118 254.350401 215.567331 253.626949 216.151255 253.278334 C 216.84542 253.06182 219.206632 252.059294 220.10161 251.901348 C 221.055327 251.652317 222.004053 251.371796 222.921922 251.039134 C 223.979515 250.800545 224.64351 250.576974 225.741885 250.351292 C 226.6036 249.98341 227.453553 249.731452 228.374345 249.491941 "
      transform="matrix(-0.535584,1.031555,-1.031555,-0.535584,488.088469,-7.562109)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 219.518019 203.559631 L 219.348179 203.560717 C 219.886322 203.561532 219.554902 203.513974 218.829174 204.420919 C 218.573737 205.11777 216.363539 206.65913 214.698059 206.660468 C 213.896635 206.743333 212.66238 206.831293 211.859696 206.831504 C 211.628194 206.830523 211.311404 206.65798 211.084434 206.658433 "
      transform="matrix(-0.535584,1.031555,-1.031555,-0.535584,488.088469,-7.562109)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 219.350564 239.018699 L 219.521953 239.020596 L 220.210377 239.01912 C 221.104778 239.020518 221.997053 239.178276 222.792452 239.193208 C 223.655248 239.192081 224.515744 239.434501 225.375557 239.434923 C 226.246266 239.558437 227.293358 239.518425 228.189706 239.647561 C 229.198825 239.680279 230.007584 239.881399 231.056797 239.88194 C 231.902041 239.912647 232.358157 240.054507 233.296094 240.052234 L 231.056797 239.88194 C 231.902041 239.912647 232.358157 240.054507 233.296094 240.052234 "
      transform="matrix(-0.535584,1.031555,-1.031555,-0.535584,488.088469,-7.562109)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 213.491354 260.028983 C 213.58101 259.895338 214.330596 259.172918 214.866453 258.826538 C 215.498957 258.607957 217.663255 257.605428 218.483093 257.448627 C 219.355589 257.196324 220.226192 256.918497 221.064389 256.585547 C 222.034796 256.346783 222.641661 256.122581 223.649868 255.898273 C 224.436442 255.531537 225.217221 255.277626 226.058341 255.037827 "
      transform="matrix(-0.535584,1.031555,-1.031555,-0.535584,488.088469,-7.562109)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 229.620982 230.100224 C 229.131446 231.51314 229.763708 231.870273 230.027029 232.479548 C 230.477973 233.070931 231.638614 233.971669 233.46916 234.543525 C 234.174042 234.734203 234.718032 234.921321 235.536023 235.235037 C 236.076402 235.28392 236.343004 235.418146 236.740786 235.578933 "
      transform="matrix(-0.535584,1.031555,-1.031555,-0.535584,488.088469,-7.562109)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 161.49956 178.248502 L 161.670949 178.250399 C 161.132806 178.249583 161.455048 178.28676 162.362429 177.561933 C 162.919573 176.935642 163.385738 176.651955 163.909614 176.012642 "
      transform="matrix(-0.535584,1.031555,-1.031555,-0.535584,488.088469,-7.562109)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 172.639966 190.508302 C 172.754226 190.509566 182.656135 185.519954 183.711625 184.453156 C 184.460977 183.912621 185.02942 183.337265 185.939953 182.720618 C 186.524564 182.417087 186.942492 182.025908 187.490509 181.688362 C 187.943432 181.029087 188.540149 180.632176 188.866135 180.027446 C 189.474383 179.499584 189.213183 178.930862 189.708551 178.29499 C 189.93915 177.645117 190.377916 176.550141 190.519148 175.95424 "
      transform="matrix(-0.535584,1.031555,-1.031555,-0.535584,488.088469,-7.562109)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 203.130814 183.360459 C 203.182831 183.715915 202.972584 184.81342 202.985298 185.647479 C 202.953441 186.614496 201.707818 189.605229 200.203267 190.499997 "
      transform="matrix(-0.535584,1.031555,-1.031555,-0.535584,488.088469,-7.562109)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 185.340767 183.251348 C 185.396348 183.248998 186.905513 183.741576 189.424593 184.516385 C 192.455023 185.605074 198.762714 189.577972 199.387342 190.12462 C 200.02786 190.628936 201.169583 191.077511 201.608596 191.675089 C 202.294024 192.265904 202.795957 192.948202 203.330014 193.568382 C 203.959512 194.335919 204.494027 194.986155 204.879695 195.63415 C 206.792872 197.189315 211.944275 207.942552 212.111348 208.548785 C 212.398073 209.115614 212.755861 209.906835 212.96997 210.443205 C 213.35243 210.880807 213.611449 211.503675 214.003312 212.163601 C 214.365113 213.020899 214.76788 213.410091 215.210677 214.058718 C 215.778868 214.452903 216.547921 215.204785 217.27557 215.607061 C 218.052312 216.271642 218.779447 216.541649 219.342359 216.984016 C 220.154843 217.330885 229.22967 223.066895 229.650393 223.352095 C 230.478299 224.020405 233.00898 226.640917 231.23281 228.55145 C 230.475998 229.194314 229.799083 230.049408 229.155276 230.413968 C 228.500908 231.071804 227.585449 231.365347 226.830021 231.704551 C 222.743327 232.913759 220.662348 232.479501 219.859664 232.479712 L 218.996868 232.480839 "
      transform="matrix(-0.535584,1.031555,-1.031555,-0.535584,488.088469,-7.562109)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 165.630804 163.099822 L 165.802193 163.101719 C 165.336207 163.101306 165.574993 163.087144 166.491703 163.270084 C 167.193886 163.579553 168.210111 163.881228 168.900881 164.132248 C 169.633524 164.369101 170.555168 164.481317 170.966195 164.820778 C 171.766524 164.9619 172.234965 165.419235 172.862407 165.854606 C 173.583338 166.506509 174.13541 166.927997 174.927299 167.402949 C 175.866139 168.051531 176.607555 168.370924 177.509342 168.955434 C 178.427371 169.126328 179.099498 169.436255 180.090606 169.470751 C 181.026476 169.53014 181.857951 169.64373 182.845503 169.642205 L 185.258086 169.643007 L 182.845503 169.642205 L 185.258086 169.643007 "
      transform="matrix(-0.535584,1.031555,-1.031555,-0.535584,488.088469,-7.562109)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 221.239634 142.948562 L 220.895422 142.949299 C 221.531248 142.948626 221.35355 142.927284 220.379473 143.122512 C 219.360819 143.647611 218.006504 144.044046 217.107044 144.499686 C 216.079562 144.840031 214.972981 145.217657 214.006354 145.704384 C 212.915827 146.346322 211.906122 146.866776 210.907138 147.597508 C 209.918656 148.129663 208.944052 148.783362 207.981209 149.321139 C 207.024102 149.84079 206.232075 150.342894 205.399491 151.044032 C 204.704863 151.427404 203.764677 151.972352 202.987962 152.591468 C 202.26288 152.850542 201.737171 153.36963 201.2702 153.797633 C 200.356565 154.605228 199.797763 155.024109 198.859061 155.863653 C 198.362607 156.329684 197.702103 156.88089 196.961237 157.583637 C 196.186016 158.103522 195.344419 159.10092 194.724469 159.650005 C 194.329482 160.165597 193.748133 160.781735 193.177285 161.199296 C 192.734804 161.754694 192.224348 162.485495 191.625221 162.921525 C 191.271016 163.450024 190.651694 164.138893 190.075792 164.816578 C 189.609449 165.384365 188.971832 165.957769 188.526711 166.537258 C 187.928038 167.200258 187.470699 167.865614 186.805893 168.430413 C 186.687208 168.829057 186.434823 169.03583 186.288436 169.293599 "
      transform="matrix(-0.535584,1.031555,-1.031555,-0.535584,488.088469,-7.562109)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,90.196078%,2.352941%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 165.031365 174.460817 C 167.611153 174.28771 171.917487 175.062331 172.00169 175.064053 C 172.00169 175.064053 177.685294 174.97591 177.685294 174.97591 C 180.667689 174.688438 181.32964 173.54196 181.816408 172.736362 C 182.934091 171.016245 183.708052 170.413705 184.570706 169.121367 C 185.946891 168.088762 187.84307 170.500988 187.930256 170.501162 C 192.748259 175.061956 188.875756 180.658626 188.616833 180.830927 C 186.20787 183.039713 185.691462 183.18287 184.227002 181.777193 C 182.763696 180.052828 182.332545 179.280764 181.129853 178.678293 C 178.803966 177.560826 173.982911 178.420469 173.895725 178.420295 C 173.808539 178.420121 171.399892 179.367752 171.399892 179.367752 C 170.221955 179.797574 169.478614 180.831054 167.441038 181.173268 C 165.37341 181.319027 164.684875 181.116074 163.650901 179.452807 C 162.275766 177.300202 162.963906 174.716303 165.031365 174.460817 Z M 165.031365 174.460817 "
      transform="matrix(-0.535584,1.031555,-1.031555,-0.535584,488.088469,-7.562109)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,93.72549%,0%);fill-opacity:0.988235;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 221.290844 199.65 C 219.724178 198.227778 250.7464 210.888889 255.018622 211.033333 C 260.851956 208.9 284.901956 188.55 284.901956 188.55 C 285.140844 190.544444 285.990844 195.711111 288.313067 196.805556 C 279.018622 204.35 270.574178 211.605556 261.279733 219.144444 C 261.940844 231.1 259.763067 243.766667 265.829733 257.144444 C 265.829733 257.144444 258.574178 257.283333 258.574178 257.144444 C 252.174178 250.738889 249.8964 219.861111 249.8964 219.861111 C 239.6964 215.305556 229.4964 210.894444 219.2964 206.338889 C 220.768622 205.388889 221.951956 202.45 221.290844 199.65 Z M 221.290844 199.65 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill:none;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 250.807511 231.3 C 251.307511 230.938889 245.213067 234.127778 243.6464 234.933333 C 215.463067 249.966667 200.957511 271.833333 200.057511 272.661111 C 199.568622 273.594444 198.674178 274.838889 197.851956 276.055556 C 197.190844 277.072222 196.240844 278.127778 195.618622 279.161111 C 194.751956 280.355556 192.940844 282.722222 191.9464 283.766667 C 191.824178 284.444444 192.418622 283.538889 192.224178 283.988889 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
    <path
      style="fill:none;stroke-width:2.066375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 270.068622 213.427778 C 269.524178 213.155556 275.1464 217.111111 276.574178 218.138889 C 302.835289 236.322222 328.918622 239.216667 330.068622 239.644444 C 331.118622 239.65 332.635289 239.877778 334.090844 240.055556 C 335.301956 240.172222 336.674178 240.533333 337.874178 240.616667 C 339.335289 240.838889 342.263067 241.372222 343.651956 241.772222 C 344.307511 241.572222 343.229733 241.461111 343.718622 241.427778 "
      transform="matrix(0.703125,0,0,0.703125,54.57675,0)"
    />
  </g>
</svg>
