import Api from "./../api";
import { get } from "svelte/store";
import { client, teams } from "../../stores/client_store";
import { buildFormData } from "../../lib/helpers";

class TeamsApi {
  constructor() {
    this.endpoint = "/clients";
  }

  getAll() {
    Api.get(`${this.endpoint}/${get(client).id}/teams`, {
      success: (response) => {
        teams.set(response.data.teams);
      },
    });
  }

  /**
   * Create a new team
   */
  create(options) {
    const { params, success, error } = options;
    let formData = new FormData();
    buildFormData(formData, params, null, true);
    Api.post(`${this.endpoint}/${get(client).id}/teams`, {
      body: formData,
      success: (response) => {
        success(response.data.team);
      },
      error,
    });
  }

  get(options) {
    Api.get(
      `${this.endpoint}/${get(client).id}/teams/${params.permalink}`,
      options,
    );
  }

  delete(options) {
    const { params, success, error } = options;
    Api.delete(`${this.endpoint}/${get(client).id}/teams/${params.permalink}`, {
      success: (response) => {
        success(response.data);
      },
      error,
    });
  }

  update(options) {
    const { params, success, error } = options;
    let formData = new FormData();
    buildFormData(formData, params, null, true);
    Api.put(`${this.endpoint}/${get(client).id}/teams/${params.permalink}`, {
      body: formData,
      success: (response) => {
        success(response.data);
      },
      error,
    });
  }
}

export default new TeamsApi();
