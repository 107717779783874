<script>
  import { onMount } from "svelte";

  import { AuditClientAccessModal } from "components/modals";
  import { teams } from "stores/client_store";
  import { t } from "stores/i18n.js";
  import {
    audit,
    auditClientUsers,
    filter,
    resetSelected,
  } from "stores/pbc.js";
  import { toasts } from "stores/toasts.js";
  import { user } from "stores/user.js";

  import TeamsApi from "apis/clients/teams_api";
  import AuditApi from "apis/pbc/audit.js";

  import {
    Boundary,
    Button,
    Counter,
    IconButton,
    SidebarArea,
  } from "components";
  import styles from "styleguide/AuditSidebarClientAccess.json";

  let isAccessModalVisible = false;
  let filteredAccessModalUsers;

  $: sortedUsers = [];
  $: {
    $auditClientUsers,
      $audit,
      (sortedUsers =
        $teams &&
        sortUsers([...$auditClientUsers], $audit.without_assignment_count, $t));
  }

  onMount(async () => {
    TeamsApi.getAll();
  });

  function userInTeam(userPermalink) {
    if (!$teams) return;
    return $teams.some((team) =>
      team.client_users.some((user) => user.permalink === userPermalink),
    );
  }

  function sortUsers(users, withoutAssignmentCount) {
    let sortedUsers = users.sort((a, b) => a.email.localeCompare(b.email));

    if ($user.role !== "client") {
      sortedUsers.push({
        email: $t("control_status.not_assigned"),
        filterKey: "not-assigned",
        controls_count_per_client: withoutAssignmentCount,
      });
    }

    sortedUsers = sortedUsers.map((user) => ({
      ...user,
      isUserInTeam: userInTeam(user.permalink),
    }));

    filteredAccessModalUsers = sortedUsers.filter(
      (user) => user.permalink !== undefined,
    );

    return sortedUsers;
  }

  function setFilter(value) {
    resetSelected();
    filter.set(value);
    AuditApi.goToFilters(value);
    window.scrollTo(0, 0);
  }

  function onDelete(user_id) {
    const isConfirmed = confirm($t("control_status.confirm_deletion"));

    if (isConfirmed) {
      AuditApi.deleteClientAccess({
        params: {
          user_id,
        },
        success: () => {
          AuditApi.getAudits();
          AuditApi.getClientUsers();
          toasts.send({
            title: $t("audit_client_access.client_user_removed_success"),
            type: "success",
          });
        },
        error: () => {
          toasts.send({
            title: $t("audit_client_access.client_user_removed_error"),
            type: "error",
          });
        },
      });
    }
  }

  function onHideModal() {
    isAccessModalVisible = false;
  }
</script>

<Boundary>
  <div data-component="AuditSidebarClientAccess">
    <div class={styles.items}>
      {#each sortedUsers as { email, permalink, filterKey, controls_count_per_client, isUserInTeam }, index (email)}
        <div class={styles.item}>
          <Button
            style={"filter-action"}
            click={() =>
              setFilter(`by-client:${filterKey ? filterKey : email}`)}
          >
            <Counter
              count={controls_count_per_client}
              variant="default"
              overflowCount="999"
              withBorder={$filter === `by-client:${email}`}
            />
            <div class={styles.email}>{email}</div>
          </Button>

          {#if permalink && $user.role !== "client"}
            <div class={styles.delete}>
              <IconButton
                click={onDelete.bind(this, permalink)}
                icon="trash"
                style={"error-text small"}
              />
            </div>
          {/if}
        </div>
      {:else}
        <div class={styles.item}>{$t("audit_client_access.no_yet")}</div>
      {/each}
    </div>
    {#if $user.role !== "client"}
      <div class={styles.access}>
        <SidebarArea>
          <Button
            click={() => (isAccessModalVisible = true)}
            style={"blue-text sidebar-action"}
          >
            {$t("audit_client_access.settings")}
          </Button>
        </SidebarArea>
      </div>
      <AuditClientAccessModal
        clientUsers={filteredAccessModalUsers}
        show={isAccessModalVisible}
        onHide={onHideModal}
      />
    {:else}
      <div class={styles.space}></div>
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .items {
    margin-bottom: 20px;
  }
  .item {
    position: relative;

    &:hover {
      .delete {
        display: block;
      }
    }
  }

  .delete {
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
  }

  .email {
    width: 210px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 30%;
      height: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      pointer-events: none;
    }
  }

  .space {
    margin-bottom: -20px;
  }
</style>
