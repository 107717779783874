<script>
  export let width = 18;
  export let height = 19;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 18 19"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_1644_24552)">
    <rect y="1.74487" width="12.8571" height="1.28571" rx="0.642857" />
    <rect y="6.24487" width="12.8571" height="1.28571" rx="0.642857" />
    <rect y="10.7449" width="12.8571" height="1.28571" rx="0.642857" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.8117 12.2188C17.5606 11.9678 17.1536 11.9678 16.9025 12.2188L12.5357 16.5857L10.7402 14.7902L10.6797 14.7368C10.4275 14.5407 10.0629 14.5585 9.83111 14.7902C9.58006 15.0413 9.58006 15.4483 9.83111 15.6994L12.0811 17.9494C12.1004 17.9687 12.1207 17.9865 12.1417 18.0028L12.1417 18.0029C12.1548 18.0131 12.1682 18.0227 12.182 18.0317C12.4315 18.1965 12.7706 18.169 12.9902 17.9494L17.8117 13.1279L17.8652 13.0674C18.0612 12.8152 18.0434 12.4505 17.8117 12.2188Z"
    />
    <rect y="15.2449" width="7.71429" height="1.28572" rx="0.642858" />
  </g>
  <defs>
    <clipPath id="clip0_1644_24552">
      <rect width="18" height="18" fill="white" transform="translate(0 0.5)" />
    </clipPath>
  </defs>
</svg>
