<script>
  import { getContext } from "svelte";
  import { SampleStatus } from "apps/sample/components";
  import { getSampleStatusTagValue } from "apps/sample/helpers";
  import { SampleService } from "apps/sample/services";
  import { sample } from "apps/sample/stores";
  import { FooterStatusBar } from "components/common";
  import { distanceOfTime } from "lib/helpers.js";

  let show = false;
  let comment = "";
  let confirmationAmount = null;
  let status;
  let actionRequired = false;
  let isSubmitting = false;

  const config = getContext("config");

  $: availableStatuses = $sample.available_statuses;
  $: timeDistance = distanceOfTime(
    new Date($sample.sample_mailings[0]?.sent_at).getTime() / 1000,
    config.language,
  );

  function onSubmit() {
    if (!isSubmitting) {
      isSubmitting = true;

      SampleService.editSample({
        params: {
          id: $sample.permalink,
          status,
          confirmation_amount: confirmationAmount,
          action_required: actionRequired,
          confirmation_comment: comment,
        },
        onSuccess: () => {
          show = false;
          isSubmitting = false;
        },
        onError: () => {
          isSubmitting = false;
        },
      });
    }
  }
</script>

<div data-component="SampleFooter">
  <FooterStatusBar
    bind:show
    bind:comment
    bind:confirmationAmount
    bind:actionRequired
    bind:status
    bind:availableStatuses
    item={sample}
    canManageStatus
    itemType="sample"
    {onSubmit}
  >
    <div slot="status">
      <SampleStatus
        status={getSampleStatusTagValue($sample)}
        {timeDistance}
        size="medium"
      />
    </div>
    <div slot="status_action_required">
      <SampleStatus status="action_required" size="medium" />
    </div>
  </FooterStatusBar>
</div>
