<script>
  import { onDestroy } from "svelte";
  import { SAMPLE_REVIEW_TYPE } from "apps/sample/constants";
  import { t } from "stores/i18n.js";
  import { isUserTeamUser } from "stores/user.js";
  import { slide } from "svelte/transition";

  import { Button, Dropdown, TextInput } from "components";
  import styles from "styleguide/FooterStatusBar.json";

  export let show = false;
  export let item = {};
  export let itemType = "";
  export let actionRequired = false;
  export let comment = "";
  export let confirmationAmount = "";
  export let status;
  export let availableStatuses = [];
  export let canManageStatus = false;
  export let onSubmit = () => {};

  let isSingleReview = $item.review_type === SAMPLE_REVIEW_TYPE.single;
  let selectedStatus = null;

  $: statuses = setStatuses(availableStatuses, $t);
  $: actionRequiredOptions = {
    true: $t("footer_status_bar.action_required"),
    false: $t("footer_status_bar.no_action_required"),
  };
  $: confirmedLabel = getConfirmedLabel($item, isSingleReview);
  $: confirmedValue = getConfirmedValue($item, isSingleReview);
  $: dropdownInitial = availableStatuses[status];
  $: isActiveStatus = status >= 3;

  const unsubscribe = item.subscribe((value) => {
    comment = value.confirmation_comment || "";
    actionRequired = value.action_required;
    status = value.status;
    confirmationAmount = value.status >= 3 ? value.confirmation_amount : "";
  });

  onDestroy(unsubscribe);

  function toggle() {
    show = !show;

    if (show) {
      selectedStatus = availableStatuses[status];
    }
  }

  function setStatuses(availableStatuses) {
    let newStatuses = {};

    if (availableStatuses) {
      Object.values(availableStatuses).forEach((status) => {
        newStatuses[status] = $t(`${itemType}.available_statuses.${status}`);
      });
    }

    return newStatuses;
  }

  function handleDropdownStatusUpdates() {
    status = Object.keys(availableStatuses).find(
      (key) => availableStatuses[key] === selectedStatus,
    );

    confirmationAmount =
      status >= 3 ? getRequestValue($item, isSingleReview) : "";
  }

  function getRequestValue(item, isReview) {
    return item.confirmation_amount ?? (isReview ? item.item : item.balance);
  }

  function getConfirmedLabel(item, isReview) {
    if (item.confirmation_amount) {
      return $t("footer_status_bar.confirmed_value");
    }
    return isReview ? `# ${item.item_number}` : `# ${item.reference}`;
  }

  function getConfirmedValue(item, isReview) {
    const amountValue = getRequestValue(item, isReview);

    const parts = amountValue.split(" ");
    return { number: parts[0], currency: parts.slice(1).join(" ") };
  }
</script>

<div class={styles.wrapper} data-component="FooterStatusBar">
  <div class={styles.top}>
    <div class={styles["top-tags"]}>
      {#if canManageStatus && $isUserTeamUser}
        <Button click={toggle} style="primary-text">
          {show ? $t("footer_status_bar.hide") : $t("footer_status_bar.show")}
        </Button>
      {/if}
      <div class={styles.status}>
        <slot name="status" />
      </div>
      {#if $item.action_required}
        <div class={styles.status}>
          <slot name="status_action_required" />
        </div>
      {/if}
    </div>

    <div class={styles["confirmed"]}>
      <div>{confirmedLabel}</div>
      <div class={styles["confirmed-value"]}>
        <span class={$item.confirmation_amount && styles[`status-${status}`]}
          >{confirmedValue.number}</span
        >
        <span class={styles["confirmed-value--currency"]}
          >{confirmedValue.currency}</span
        >
      </div>
    </div>
  </div>

  {#if show && $isUserTeamUser}
    <div class={styles.bottom} transition:slide={{ y: 200, duration: 130 }}>
      <div class={styles["bottom-status-actions"]}>
        <div class={styles.dropdown}>
          <Dropdown
            style="bottom"
            items={statuses}
            initial={$t(`${itemType}.available_statuses.${dropdownInitial}`)}
            placement="top"
            bind:selected={selectedStatus}
            click={handleDropdownStatusUpdates}
          />
        </div>

        <div class={styles["confirmation-amount"]}>
          <TextInput
            bind:value={confirmationAmount}
            placeholder={isActiveStatus
              ? $t("footer_status_bar.amount_placeholder")
              : "-"}
            disabled={!isActiveStatus}
            onEnter={onSubmit}
          />
        </div>

        <div class={styles.dropdown}>
          <Dropdown
            style="bottom"
            placement="top"
            items={actionRequiredOptions}
            bind:selected={actionRequired}
          />
        </div>

        <div class={styles.submit}>
          <Button click={onSubmit} style="primary"
            >{$t("footer_status_bar.submit")}</Button
          >
        </div>
      </div>

      <div class={styles["bottom-comment"]}>
        <div>{$t("footer_status_bar.comment")}</div>
        <div class={styles["bottom-comment-input"]}>
          <TextInput
            bind:value={comment}
            onEnter={onSubmit}
            placeholder={$t("footer_status_bar.comment_placeholder")}
            footnotes={[$t("footer_status_bar.comment_footnote")]}
          />
        </div>
      </div>
    </div>
  {/if}
</div>

<style lang="scss">
  .wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 1;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .confirmed {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 35px;

    &-value {
      font-family: Courier, sans-serif, serif;
      @include typography-semibold;

      span {
        font-family: inherit;
      }

      &--currency {
        color: var(--primary-300);
      }
    }
  }

  .status-4,
  .status-8 {
    color: var(--green-500);
  }
  .status-5,
  .status-6 {
    color: var(--orange-400);
  }
  .status-7 {
    color: var(--red-200);
  }

  .status {
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }

  .dropdown {
    width: 420px;
  }

  .submit {
    margin-left: 20px;
  }

  .top,
  .bottom {
    border-top: 1px solid var(--primary-050);
  }

  .top {
    display: flex;
    justify-content: space-between;
    min-height: 60px;
    align-items: center;
    padding: 10px var(--page-gutter);

    &-tags {
      display: flex;
      align-items: center;
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px var(--page-gutter);

    &-status-actions {
      display: flex;
      gap: 15px;
    }

    &-comment {
      display: flex;
      align-items: baseline;
      gap: 15px;
      font-weight: 600;
    }

    &-comment-input {
      flex: 1;
    }

    .confirmation-amount {
      flex: 1;
      min-width: 120px;
    }
  }
</style>
