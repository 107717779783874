import { Controller } from "stimulus";
import Api from "../general/api";

const EXPORT_LIMIT = 3;

export default class extends Controller {
  static targets = [
    "list",
    "submitExportButton",
    "warning",
    "checkboxes",
    "inputs",
  ];

  delayedExportLinkClick(e) {
    e.preventDefault();
    if (this.isExportAvailable()) {
      let url = e.target.closest("a").getAttribute("data-url"),
        params = {};

      if (this.hasInputsTarget) {
        this.inputsTargets.forEach((inputTarget) => {
          params[inputTarget.getAttribute("name")] = inputTarget.value;
        });
      }

      if (this.hasCheckboxesTarget) {
        this.checkboxesTargets.forEach((checkboxTarget) => {
          if (checkboxTarget.checked) {
            params[checkboxTarget.getAttribute("name")] = "1";
          }
        });
      }
      Api.post(url, params, this.exportResponse.bind(this));
    }
  }

  exportResponse(response) {
    if (response["template"]) {
      let node = document.createElement("div");
      node.innerHTML = response["template"];
      this.listTarget.prepend(node);
      let noExportBlock = document.querySelector(".export-modal--no-exports");
      if (noExportBlock) {
        noExportBlock.remove();
      }

      if (!this.isExportAvailable()) {
        this.disableExport();
        this.warningTarget.classList.remove("hidden");
      }
    }
  }

  deleteExportFile(e) {
    e.preventDefault();
    this.enableExport();
    let target = e.target.closest("a");
    Api.delete(
      target.getAttribute("data-url"),
      {},
      this.deleteExportResponse.bind(this),
    );
  }

  deleteExportResponse(response) {
    if (response["export_id"]) {
      document.querySelector(`[data-id="${response["export_id"]}"]`).remove();

      let items = document.querySelectorAll(
        ".export-modal--list .export-modal--item",
      );
      if (!items.length) {
        let node = document.createElement("div");
        node.classList.add("export-modal--no-exports");
        node.innerHTML = I18n.t("platform.views.exports_modal.no_exports");
        document.querySelector(".export-modal--list").appendChild(node);
      }
    }
  }

  isExportAvailable() {
    return (
      document.querySelectorAll(".export-modal--item").length < EXPORT_LIMIT
    );
  }

  disableExport() {
    this.submitExportButtonTarget.classList.add("disabled");
    this.warningTarget.classList.remove("hidden");
  }

  enableExport() {
    this.submitExportButtonTarget.classList.remove("disabled");
    this.warningTarget.classList.add("hidden");
  }
}
