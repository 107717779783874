<script>
  import styles from "styleguide/Checkbox.json";
  import { sanitizeRelaxed } from "lib/sanitize.js";
  import { InputWrapper } from "components";

  export let label;
  export let style = "";
  export let toggle;
  export let toggled = false;
  export let disabled = false;
  export let footnotes;
  export let checkmark = "default";

  let checkbox;
  $: cssClasses = getStyles(style, checkmark);

  function getStyles(style, checkmark) {
    let s = style.split(" ");
    if (checkmark) {
      s.push(`checkmark_${checkmark}`);
    }
    return s.map((x) => styles[x]).join(" ");
  }

  function onToggle() {
    if (toggle) {
      toggle(checkbox.checked);
    }
  }
</script>

<InputWrapper {footnotes}>
  <label data-component="Checkbox" class="{styles.wrapper} {cssClasses}">
    <span class={styles.checkbox}>
      <input
        bind:this={checkbox}
        type="checkbox"
        bind:checked={toggled}
        on:change={onToggle}
        {disabled}
      />
      <span class={styles.box}>
        <span class={styles.checkmark}></span>
      </span>
    </span>

    {#if label}
      <span class={styles.label}>{@html sanitizeRelaxed(label)}</span>
    {/if}

    <slot />
  </label>
</InputWrapper>

<style lang="scss">
  .wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .checkbox {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover .box {
      border-color: var(--blue);
    }
  }

  .box {
    position: relative;
    display: block;
    width: 18px;
    height: 18px;
    border: 1px solid var(--primary-300);
    border-radius: 5px;
    background: #fff;
    user-select: none;

    .sidebar & {
      margin: 0 6px;
    }
  }

  input:checked ~ .box {
    background: var(--blue);
    border-color: var(--blue);

    .checkmark {
      display: block;
    }

    .green & {
      background: var(--green-010);
      border-color: var(--green-010);
    }
  }

  input:disabled ~ .box {
    background: var(--primary-300);
    border-color: var(--primary-300);

    .checkmark {
      display: block;
    }
  }

  .checkmark {
    position: absolute;
    display: none;
    user-select: none;

    .checkmark_default & {
      left: 6px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .checkmark_minus & {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 2px;
      background: white;
    }
  }

  .top {
    align-items: flex-start;
    padding-top: 14px;
  }

  .label {
    line-height: 18px;
    margin-left: 10px;
    user-select: none;

    .blue & {
      color: var(--blue-200);
    }

    .grey & {
      color: var(--primary-300);
    }

    .label-left-20 & {
      margin-left: 20px;
    }

    .sidebar & {
      font-weight: 500;
      margin-left: 14px;
    }
  }
</style>
