<script>
  import { Modal } from "components/modals";
  import { t } from "stores/i18n.js";

  import { audit, auditClientUsers } from "stores/pbc.js";
  import { can } from "stores/permission.js";
  import { toasts } from "stores/toasts.js";
  import { user } from "stores/user.js";
  import { isAccountFeature } from "stores/account";


  import AuditApi from "apis/pbc/audit.js";
  import { copyToClickboard, iconPath } from "lib/helpers.js";

  import { Boundary, Button, Switch, Toast, Tooltip } from "components";
  import styles from "styleguide/AuditClientAccessModal.json";

  $: link = window.location.origin + $audit.share_path;

  export let show;
  export let onHide;
  export let clientUsers;

  function onCopy() {
    copyToClickboard(link);
  }

  function reloadAudits() {
    AuditApi.getAudits();
    AuditApi.getClientUsers();
  }

  function onDelete(user_id) {
    const isConfirmed = confirm($t("control_status.confirm_deletion"));

    if (isConfirmed) {
      AuditApi.deleteClientAccess({
        params: {
          user_id,
        },
        success: () => {
          reloadAudits();
          toasts.send({
            title: $t("audit_client_access.client_user_removed_success"),
            type: "success",
          });
        },
        error: () => {
          toasts.send({
            title: $t("audit_client_access.client_user_removed_error"),
            type: "error",
          });
        },
      });
    }
  }

  function updateClientUserRights(params) {
    const action = params.value ? "created" : "deleted";

    AuditApi.updateClientUserRights({
      params,
      success: () => {
        reloadAudits();
        toasts.send({
          title: $t(`audit_client_access_modal.user_${params.type}_${action}`),
          type: "success",
        });
      },
    });
  }

  function getAuditUser(userId) {
    return $auditClientUsers.find((user) => user.permalink === userId);
  }

  function toggleUserRights(auditClientId, rightType) {
    const auditUser = getAuditUser(auditClientId);
    const rightValue = !auditUser.rights[rightType];

    updateClientUserRights({
      client_user_id: auditClientId,
      audit_id: $audit.permalink,
      type: rightType,
      value: rightValue,
    });
  }

  function handleClientSettingsClick() {
    window.location = `${$audit.client_path}`;
  }
</script>

<Boundary>
  <Modal
    shown={show}
    {onHide}
    isWide
    title={$t("audit_client_access_modal.title")}
  >
    {#if $can("update", "client") && $can("update", "client_user")}
      <Toast
        message={$t("audit_client_access_modal.info")}
        action={{
          style: "primary-text",
          click: handleClientSettingsClick,
          button: $t("audit_client_access_modal.client_settings"),
        }}
      />
    {/if}

    <div data-component="AuditClientAccessModal">
      <div class={styles.connection}>
        <div>
          <div class={styles["connection-name"]}>
            {$t("audit_client_access_modal.internet_address")}
          </div>
          <div class={styles["connection-link"]}>{link}</div>
        </div>

        <div class={styles.button}>
          <Button style="primary-tonal" click={onCopy}>
            {$t("audit_client_access_modal.copy_link")}
          </Button>
        </div>
      </div>

      <div class={styles.addresses}>
        <div class={styles["addresses-item"]}>
          <strong>
            {$t("audit_client_access_modal.email_address")}
          </strong>
        </div>
        <div class={styles["addresses-actions"]}>
          <div class={styles["user-rights"]}>
            {#if $isAccountFeature("pbc_delegation")}
              <div class={styles["delegate-title"]}>
                <strong>{$t("audit_client_access_modal.can_delegate")}</strong>
                <Tooltip
                  style="medium"
                  nowrap={false}
                  maxWidth="300px"
                  message={$t(
                    "audit_client_access_modal.tooltip_delegate_message",
                  )}
                >
                  <img src={iconPath("info")} alt="info" />
                </Tooltip>
              </div>
            {/if}

            {#if $isAccountFeature("pbc_client_user_controls") && $can("update", "audit")}
              <div class={styles["delegate-title"]}>
                <strong>{$t("audit_client_access_modal.can_manage")}</strong>
                <Tooltip
                  style="medium"
                  nowrap={false}
                  maxWidth="300px"
                  message={$t(
                    "audit_client_access_modal.tooltip_manage_message",
                  )}
                >
                  <img src={iconPath("info")} alt="info" />
                </Tooltip>
              </div>
            {/if}
          </div>

          <div class={styles["delete"]}>&nbsp;</div>
        </div>
      </div>

      <div>
        {#each clientUsers as { email, permalink, rights, isUserInTeam } (email)}
          <div class={styles.addresses}>
            <div class={styles["addresses-item"]}>
              <Tooltip showArrow message={email}>
                {email}
              </Tooltip>
            </div>

            <div class={styles["addresses-actions"]}>
              <div class={styles["user-rights"]}>
                {#if $isAccountFeature("pbc_delegation")}
                  <div class={styles["switch-box"]}>
                    <Switch
                      bgColor="signal-green-body"
                      toggled={rights.delegation}
                      toggle={() => toggleUserRights(permalink, "delegation")}
                    />
                  </div>
                {/if}
                {#if $isAccountFeature("pbc_client_user_controls") && $can("update", "audit")}
                  <div class={styles["switch-box"]}>
                    <Switch
                      bgColor="signal-green-body"
                      toggled={rights.management}
                      toggle={() => toggleUserRights(permalink, "management")}
                    />
                  </div>
                {/if}
              </div>

              {#if $user.role !== "client"}
                {#if isUserInTeam}
                  <Tooltip
                    showArrow
                    message={$t(
                      "audit_client_access_modal.client_user_in_team",
                    )}
                  >
                    <Button
                      disabled={isUserInTeam}
                      style="error-text small"
                      click={onDelete.bind(this, permalink)}
                    >
                      {$t("audit_client_access_modal.revoke_accesses")}
                    </Button>
                  </Tooltip>
                {:else}
                  <Button
                    style="error-text small"
                    click={onDelete.bind(this, permalink)}
                  >
                    {$t("audit_client_access_modal.revoke_accesses")}
                  </Button>
                {/if}
              {/if}
            </div>
          </div>
        {:else}
          <div class={styles.empty}>
            {$t("audit_client_access_modal.no_client_users_added")}
          </div>
        {/each}
      </div>
    </div>
  </Modal>
</Boundary>

<style lang="scss">
  .connection-name {
    font-weight: 600;
  }

  .connection-link {
    font-weight: 500;
    color: var(--primary-500);
  }

  .button {
    display: flex;
    margin: 16px 0;
  }

  .addresses {
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 12px 0;
    justify-content: space-between;
    border-bottom: 1px solid var(--primary-020, #d3d9e3);

    &:last-child {
      border-bottom: none;

      .addresses-item {
        padding-bottom: 0;
      }

      .delete {
        padding-bottom: 0;
        top: 7px;
      }
    }
  }
  strong {
    font-weight: 600;
  }

  .addresses-item {
    flex: 1;
    display: flex;
    gap: 8px;
    max-width: 325px;
    overflow: hidden;
  }

  .addresses-actions {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }

  .user-rights {
    display: flex;
  }

  .switch-box {
    width: 150px;
  }

  .delegate-title {
    display: flex;
    gap: 8px;
    width: 150px;
  }

  .delete {
    width: 32px;
    justify-content: end;
    display: flex;
  }

  .empty {
    padding: 20px 20px 10px;
    text-align: center;
  }
</style>
