// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import Appsignal from "@appsignal/javascript";
import { installErrorHandler } from "@appsignal/stimulus";

const application = Application.start();
const appsignal = new Appsignal({ key: API_SIGNAL_KEY, revision: APP_VERSION });

const defaultErrorHandler = application.handleError;
const appSignalErrorHandler = (error, message, detail) => {
  defaultErrorHandler(error, message, detail);
  appsignal.sendError(error);
};

application.handleError = appSignalErrorHandler;

const context = import.meta.webpackContext("controllers", {
  regExp: /_controller\.js$/,
});
application.load(definitionsFromContext(context));

const sbaContent = import.meta.webpackContext("controllers/sba", {
  regExp: /_controller\.js$/,
});
application.load(definitionsFromContext(sbaContent));

console.log("Stimulus initialized");
