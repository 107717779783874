<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,35.294118%,63.921569%);fill-opacity:1;"
      d="M 0 0 L 479.898438 0 L 479.898438 360 L 0 360 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(88.627451%,68.235294%,34.117647%);fill-opacity:1;"
      d="M 0 350.308594 L 479.929688 0 L 479.90625 65.1875 L 0 359.996094 L 0 350.304688 Z M 0 350.308594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 16.796875 359.984375 L 479.984375 134.414062 L 479.886719 62.804688 L -0.00390625 360 Z M 16.796875 359.984375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 131.488281 11.371094 L 126.753906 88.464844 L 106.496094 39.300781 L 114.269531 91.632812 L 82.894531 49.347656 L 103.460938 97.601562 L 62.257812 65.640625 L 94.332031 105.800781 L 47.757812 85.160156 L 88.550781 116.050781 L 37.8125 109.339844 L 85.761719 128.34375 L 10.5 135.230469 L 85.941406 140.273438 L 38.132812 159.925781 L 88.363281 153.128906 L 47.71875 183.144531 L 94.886719 162.449219 L 62.714844 202.941406 L 103.644531 171.953125 L 83.367188 219.117188 L 116.128906 178.101562 L 107.261719 229.230469 L 127.871094 181.457031 L 132.527344 256.929688 L 139.796875 181.644531 L 157.566406 229.710938 L 150.789062 177.917969 L 183.390625 218.96875 L 161.96875 171.023438 L 202.882812 204.019531 L 170.355469 162.824219 L 218.980469 183.066406 L 175.945312 151.640625 L 228.355469 160.476562 L 178.183594 141.207031 L 256.222656 136.359375 L 178.183594 129.09375 L 229.546875 111.972656 L 176.320312 118.65625 L 220.296875 87.910156 L 170.730469 107.851562 L 204.960938 66.347656 L 163.277344 98.90625 L 183.335938 49.097656 L 150.976562 91.453125 L 157.925781 38.628906 L 138.675781 88.46875 L 131.488281 11.378906 Z M 131.488281 11.371094 "
    />
  </g>
</svg>
