<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(3.921569%,36.078431%,7.058824%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(98.823529%,0%,5.882353%);fill-opacity:1;"
      d="M 292.992188 180 C 292.992188 124.171875 247.738281 78.917969 191.910156 78.917969 C 136.082031 78.917969 90.824219 124.171875 90.824219 180 C 90.824219 235.828125 136.082031 281.085938 191.910156 281.085938 C 247.738281 281.085938 292.992188 235.828125 292.992188 180 Z M 292.992188 180 "
    />
  </g>
</svg>
