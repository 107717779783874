<script>
  import styles from "styleguide/AuditRollForwardGroup.json";

  import { Boundary, Checkbox } from "components";
  import TriangleRight from "components/icons/TriangleRight.svelte";

  export let open = false;
  export let name;
  export let updateList;
  export let copy_files;
  export let transfer_control;
  export let control_templates = [];
</script>

<Boundary>
  <div
    data-component="AuditRollForwardGroup"
    class={`${styles.wrapper} ${control_templates.every((item) => transfer_control[item.permalink]) ? styles.active : ""}`}
  >
    <div
      class={styles["template-group"]}
      on:click={() => {
        open = !open;
      }}
    >
      <div class={`${styles.icon} ${open ? styles.open : ""}`}>
        <TriangleRight fill="var(--blue-100)" />
      </div>
      {name}
    </div>

    <div class={styles.checkboxes}>
      <div class={styles.checkbox}>
        <Checkbox
          checkmark={control_templates.every(
            (item) => transfer_control[item.permalink],
          )
            ? "default"
            : "minus"}
          toggle={(value) => {
            control_templates.forEach((item) => {
              updateList("transfer_control", item.permalink, value);
            });
          }}
          toggled={control_templates.some(
            (item) => transfer_control[item.permalink],
          ) && control_templates.length}
        />
      </div>
      <div class={styles.checkbox}>
        <Checkbox
          checkmark={control_templates.every(
            (item) => copy_files[item.permalink],
          )
            ? "default"
            : "minus"}
          toggle={(value) => {
            control_templates.forEach((item) => {
              updateList("copy_files", item.permalink, value);
            });
          }}
          toggled={control_templates.some(
            (item) => copy_files[item.permalink],
          ) && control_templates.length}
        />
      </div>
    </div>
  </div>

  {#if open}
    {#each control_templates as { permalink, name }}
      <div
        class={`${styles.wrapper} ${transfer_control[permalink] ? styles.active : ""}`}
      >
        <div class={styles["template-control"]}>{name}</div>
        <div class={styles.checkboxes}>
          <div class={styles.checkbox}>
            <Checkbox
              toggle={(value) =>
                updateList("transfer_control", permalink, value)}
              toggled={transfer_control[permalink]}
            />
          </div>
          <div class={styles.checkbox}>
            <Checkbox
              toggle={(value) => updateList("copy_files", permalink, value)}
              toggled={copy_files[permalink]}
            />
          </div>
        </div>
      </div>
    {/each}
  {/if}
</Boundary>

<style lang="scss">
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    margin: 2px 0;
  }

  .icon {
    margin-right: 10px;
    display: flex;

    &.open {
      transform: rotate(90deg);
    }
  }

  .template-group {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .template-control {
    margin: 0 0 0 40px;
  }

  .checkboxes {
    display: flex;
  }

  .checkbox {
    margin-left: 15px;
  }
</style>
