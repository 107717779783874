<script>
  import { SampleService } from "apps/sample/services";
  import { sample } from "apps/sample/stores";

  import { Button, Options } from "components/shared";
  import { t } from "stores/i18n.js";
  import { fixCarrierWaveUrl } from "lib/helpers.js";
  import { downloadFile } from "lib/helpers.js";

  import styles from "styleguide/SampleMailingActions.json";

  export let mailing;

  $: isCreatingPdf =
    !mailing.mailing_url || !mailing.address_complete || !mailing.stored;

  function handleGetSample() {
    SampleService.getSample({
      params: {
        id: $sample.permalink,
      },
    });
  }

  function handleConfirmSampleMailing(sampleId, mailindId) {
    if (confirm($t("sample_mailing_actions.master_data_validation_message"))) {
      SampleService.confirmSampleMailing({
        params: {
          sample_id: sampleId,
          id: mailindId,
        },
      });
    }
  }

  function downloadPdf(mailingUrl) {
    const fixedUrl = fixCarrierWaveUrl(mailingUrl);

    downloadFile(
      { url: fixedUrl, filename: `${$sample.contact.company}.pdf` },
      null,
      true,
    );
  }

  function switchMailingChannel(mailindId, sentVia) {
    SampleService.switchSampleMailingChannel({
      params: {
        sample_id: $sample.permalink,
        sent_via: sentVia,
        id: mailindId,
      },
      onSuccess: handleGetSample,
    });
  }

  function disableSecondRequestBox(mailind) {
    const requestState = !mailing.ignore_follow_up_request
      ? "added"
      : "removed";
    SampleService.disableSecondRequestBox({
      params: {
        sample_id: $sample.permalink,
        id: mailind.permalink,
        requestState,
      },
    });
  }

  function markAsSent(mailindId) {
    if (confirm($t("sample_mailing_actions.mark_as_sent_explanation"))) {
      SampleService.markAsSent({
        params: {
          sample_id: $sample.permalink,
          id: mailindId,
        },
        onSuccess: () => {
          handleGetSample();
        },
      });
    }
  }

  function deleteSampleMailing(mailindId) {
    if (confirm($t("questions.sure"))) {
      SampleService.deleteSampleMailing({
        params: {
          sample_id: $sample.permalink,
          id: mailindId,
        },
        onSuccess: () => {
          handleGetSample();
        },
      });
    }
  }
</script>

<div class={styles.wrapper} data-component="SampleMailingActions">
  {#if mailing.waiting_confirmation}
    <Button
      style="primary"
      click={handleConfirmSampleMailing.bind(
        this,
        $sample.permalink,
        mailing.permalink,
      )}>{$t("sample_mailing_actions.release_cover_letter")}</Button
    >
  {/if}

  <Options style="no-border acts-as-button left" title={$t("actions.options")}>
    <Button
      style="blue-text option"
      click={downloadPdf.bind(this, mailing.mailing_url)}
      disabled={isCreatingPdf}
      >{$t(
        `actions.${isCreatingPdf ? "creating_pdf" : "download_pdf"}`,
      )}</Button
    >

    {#if !mailing.sent_at}
      <Button
        style="blue-text tooltip-option"
        click={switchMailingChannel.bind(
          this,
          mailing.permalink,
          mailing.sent_via === "email" ? "postal" : "email",
        )}
      >
        {mailing.sent_via === "email"
          ? $t("sample_mailing_actions.switch_to_postal")
          : $t("sample_mailing_actions.switch_to_email")}
      </Button>

      {#if mailing.waiting_confirmation && mailing.second_request}
        <Button
          style="blue-text tooltip-option wrap"
          click={disableSecondRequestBox.bind(this, mailing)}
        >
          {mailing.ignore_follow_up_request
            ? $t("sample_mailing_actions.second_request_box_enabled")
            : $t("sample_mailing_actions.second_request_box_disabled")}
        </Button>
      {/if}

      <Button
        style="blue-text option"
        click={markAsSent.bind(this, mailing.permalink)}
        >{$t("sample_mailing_actions.mark_as_send")}</Button
      >

      <Button
        style="error-text option"
        click={deleteSampleMailing.bind(this, mailing.permalink)}
        >{$t("sample_mailing_actions.remove_cover_letter")}</Button
      >
    {/if}
  </Options>
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    gap: 8px;
  }
</style>
