<script>
  import styles from "styleguide/ListFeatureItems.json";
  import { FeatureItem } from "components/modals/administrationModals/AdministrationUpsellModal";

  export let featureItems;
</script>

<div class={styles.wrapper} data-component="ListFeatureItems">
  {#each featureItems as { icon, title, description }}
    <FeatureItem {icon} {title} {description} />
  {/each}
</div>

<style lang="scss">
  .wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(374px, 1fr));
    gap: 1em;
    margin-bottom: 32px;
  }
</style>
