<script>
  import styles from "styleguide/Notification.json";
  import { onMount } from "svelte";

  import { user } from "stores/user.js";
  import { isUserTeamUser } from "stores/user.js";
  import { webSocketUrl } from "stores/notification.js";
  import {
    showWidget,
    filteredNotificationsCount,
    addNewNotification,
  } from "stores/notification.js";

  import AppCable from "lib/cable";
  import NotificationApi from "apis/notification/notification.js";

  import { BreadcrumbIconButton } from "components";
  import { NotificationWidget } from "components/notification";

  $: notificationStatus = getNotificationStatus(
    $filteredNotificationsCount,
    $user.notificationsToggle,
  );

  function getNotificationStatus(count, toggled) {
    if (!toggled && $isUserTeamUser) {
      return "on-muted";
    } else if (count) {
      return "on";
    } else {
      return "off";
    }
  }

  function setupNotificationChannel() {
    new AppCable(webSocketUrl()).cable.subscriptions.create(
      { channel: "NotificationChannel" },
      {
        received: (data) => {
          addNewNotification(data.notification);
        },
      },
    );
  }

  onMount(async () => {
    NotificationApi.getData();
    setupNotificationChannel();
  });

  function toggleWidget() {
    showWidget.set(!$showWidget);
  }
</script>

<div>
  <div class={styles.notification}>
    <BreadcrumbIconButton
      click={toggleWidget}
      type="notification-{notificationStatus}"
    >
      {#if $filteredNotificationsCount}
        <span class={styles.count}>
          {$filteredNotificationsCount}
        </span>
      {/if}
    </BreadcrumbIconButton>
  </div>

  {#if $showWidget}
    <div class={styles.backdrop} on:click={toggleWidget} />
    <NotificationWidget />
  {/if}
</div>

<style lang="scss">
  .count {
    top: -2px;
    left: 13px;
    vertical-align: text-bottom;
    height: 14px;
    background: var(--red-100);
    min-width: 14px;
    color: #fff;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    position: absolute;
    padding: 0 4px;
    border-radius: 7px;
  }

  .backdrop {
    all: initial;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    z-index: 5;
  }
</style>
