$(document).on("click", "#review-types a", function (event) {
  event.preventDefault();
  $("#review-types a").removeClass("active");
  $(this).addClass("active");
  $("#review_type").val($(this).data("type"));
});

$(document).on("click", "#export-datev-token-copy", function (event) {
  event.preventDefault();

  var token = $("#export-datev-token-hidden");
  token.select();

  try {
    var successful = document.execCommand("copy");

    $(this).addClass("done");
    setTimeout(function () {
      $("#export-datev-token-copy").removeClass("done");
    }, 3000);
  } catch (err) {
    console.log("Ihr Browser unterstütz diese Funktion nicht.");
  }
});
