<script>
  import { onMount } from "svelte";
  import { FrontendRoot } from "apps/frontend";
  import { footerLinks } from "apps/frontend/stores";
  import { App, FrontendWrapper } from "components";

  export let appConfig;
  export let initialState;

  onMount(() => {
    footerLinks.set(initialState.footer_links);
  });
</script>

<App {...appConfig} container={true}>
  <FrontendWrapper {footerLinks}>
    <FrontendRoot />
  </FrontendWrapper>
</App>
