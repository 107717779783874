<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(0%,0%,40%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 0 L 0 22.941406 L 279.433594 205.210938 L 314.609375 205.210938 L 314.609375 182.265625 L 35.171875 0 Z M 314.609375 0 L 314.609375 22.941406 L 35.171875 205.210938 L 0 205.210938 L 0 182.265625 L 279.433594 0 Z M 314.609375 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 131.085938 0 L 131.085938 205.210938 L 183.523438 205.210938 L 183.523438 0 Z M 0 68.402344 L 0 136.804688 L 314.609375 136.804688 L 314.609375 68.402344 Z M 0 68.402344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 0 82.082031 L 0 123.125 L 314.609375 123.125 L 314.609375 82.082031 Z M 141.574219 0 L 141.574219 205.210938 L 173.035156 205.210938 L 173.035156 0 Z M 141.574219 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 0 205.210938 L 104.871094 136.804688 L 128.316406 136.804688 L 23.449219 205.210938 Z M 0 0 L 104.871094 68.402344 L 81.421875 68.402344 L 0 15.296875 Z M 186.289062 68.402344 L 291.160156 0 L 314.609375 0 L 209.738281 68.402344 Z M 314.609375 205.210938 L 209.738281 136.804688 L 233.1875 136.804688 L 314.609375 189.914062 Z M 314.609375 205.210938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 94.132812 312.359375 L 73.527344 310.570312 L 91.3125 300.011719 L 79.867188 282.78125 L 99.210938 290.105469 L 105.546875 270.414062 L 111.882812 290.105469 L 131.222656 282.78125 L 119.777344 300.011719 L 137.566406 310.570312 L 116.957031 312.359375 L 119.796875 332.851562 L 105.546875 317.855469 L 91.292969 332.851562 Z M 94.132812 312.359375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 369.121094 334.273438 L 354.613281 333.011719 L 367.136719 325.574219 L 359.074219 313.445312 L 372.699219 318.601562 L 377.15625 304.734375 L 381.613281 318.601562 L 395.238281 313.445312 L 387.175781 325.574219 L 399.699219 333.011719 L 385.191406 334.273438 L 387.191406 348.695312 L 377.15625 338.140625 L 367.121094 348.695312 Z M 369.121094 334.273438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 369.121094 118.441406 L 354.613281 117.179688 L 367.136719 109.742188 L 359.074219 97.613281 L 372.699219 102.769531 L 377.15625 88.90625 L 381.613281 102.769531 L 395.238281 97.613281 L 387.175781 109.742188 L 399.699219 117.179688 L 385.191406 118.441406 L 387.191406 132.863281 L 377.15625 122.308594 L 367.121094 132.863281 Z M 369.121094 118.441406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 288.1875 206.121094 L 273.675781 204.859375 L 286.199219 197.425781 L 278.140625 185.292969 L 291.761719 190.453125 L 296.21875 176.585938 L 300.675781 190.453125 L 314.300781 185.292969 L 306.238281 197.425781 L 318.761719 204.859375 L 304.253906 206.121094 L 306.257812 220.546875 L 296.21875 209.992188 L 286.183594 220.546875 Z M 288.1875 206.121094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 441.042969 184.539062 L 426.554688 183.277344 L 439.101562 175.839844 L 431.023438 163.710938 L 444.660156 168.871094 L 449.082031 155.003906 L 453.5625 168.871094 L 467.160156 163.710938 L 459.121094 175.839844 L 471.636719 183.277344 L 457.121094 184.539062 L 459.121094 198.960938 L 449.082031 188.40625 L 439.046875 198.960938 Z M 441.042969 184.539062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 422.363281 229.269531 L 415.234375 235.285156 L 417.464844 244.347656 L 409.53125 239.429688 L 401.601562 244.347656 L 403.828125 235.285156 L 396.699219 229.269531 L 406.007812 228.585938 L 409.53125 219.945312 L 413.054688 228.585938 Z M 422.363281 229.269531 "
    />
  </g>
</svg>
