<script>
  import { account, isAccountFeature } from "stores/account";
  import { t } from "stores/i18n.js";
  import { Button } from "components";
  import styles from "styleguide/FrontendRoot.json";
</script>

<div data-component="FrontendRoot" class={styles.container}>
  <div class={styles.subtitle}>
    {$t(`frontend_root.welcome`, { platform_name: $account.platform_name })}
  </div>

  {#if $isAccountFeature("xba") || $isAccountFeature("sba")}
    <div class={styles.title}>{$t("frontend_root.welcome_title")}</div>
    <div class={styles.button}>
      <a class={styles.reply} href="/responder">
        <Button style="primary">
          {$t("frontend_root.reply_button")}
        </Button>
      </a>
    </div>
  {:else}
    <div class={styles.title}>{$t("frontend_root.welcome_pbc_title")}</div>
    <div class={styles.button}>
      <a class={styles.reply} href="/login">
        <Button style="primary">
          {$t("frontend_root.login_button")}
        </Button>
      </a>
    </div>
  {/if}
</div>

<style lang="scss">
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 25px 35px;
    border-bottom: 1px solid var(--primary-050);

    .subtitle {
      @include typography-caption-regular;
      color: var(--primary-300);
    }

    .title {
      @include typography-headline-semibold;
      color: var(--primary-500);
      text-align: center;
      max-width: 540px;
    }

    .button {
      margin: 10px 0;

      .reply {
        text-decoration: none;
      }
    }
  }
</style>
