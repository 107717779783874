import Api from "apis/api";

class SandboxApi {
  constructor() {
    this.endpoint = "/sandbox";
  }

  create({ params, success, error }) {
    Api.post(`${this.endpoint}`, {
      body: params,
      success,
      error,
    });
  }

  get({ params, success, error }) {
    Api.get(`${this.endpoint}`, {
      params,
      success,
      error,
    });
  }

  creationStatus({ params, success, error }) {
    Api.get(`${this.endpoint}/creation_status`, {
      params,
      success,
      error,
    });
  }
}

export default new SandboxApi();
