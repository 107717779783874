<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(9.803922%,61.568627%,0%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,100%,100%);fill-opacity:1;"
      d="M 239.09375 201.585938 C 230.628906 207.476562 221.773438 212.6875 213.308594 218.582031 C 202.703125 219.332031 192.417969 219.4375 181.492188 218.902344 C 181.492188 218.902344 158.671875 215.046875 152.882812 205.082031 C 152.5625 204.117188 160.277344 200.582031 160.277344 200.582031 C 171.953125 208.832031 184.917969 216.117188 209.132812 215.046875 C 179.242188 212.367188 158.992188 195.867188 152.882812 180.65625 C 169.0625 169.296875 195.847656 169.511719 217.808594 185.15625 C 210.953125 176.582031 188.027344 167.046875 170.882812 169.726562 C 168.417969 167.15625 166.277344 163.941406 163.8125 161.371094 C 183.527344 156.976562 210.953125 169.621094 221.023438 180.011719 C 210.417969 162.976562 202.703125 143.691406 215.238281 120.226562 C 217.703125 122.585938 220.488281 124.941406 222.953125 127.300781 C 213.523438 144.546875 216.953125 164.691406 224.558594 178.726562 C 211.382812 151.511719 221.988281 124.621094 239.34375 111.550781 C 259.59375 123.761719 265.378906 156.226562 253.488281 179.367188 C 260.234375 175.511719 265.699219 146.582031 256.378906 127.300781 C 258.523438 124.726562 260.664062 122.15625 262.808594 119.585938 C 276.84375 138.546875 268.378906 169.082031 256.699219 180.652344 C 265.59375 172.9375 288.625 157.832031 315.519531 161.367188 C 313.375 163.941406 309.949219 166.191406 307.804688 168.761719 C 285.734375 167.902344 267.84375 177.332031 259.914062 185.472656 C 279.308594 172.617188 306.089844 166.1875 325.804688 180.652344 C 316.699219 204.972656 290.875 212.257812 269.558594 214.402344 C 287.664062 215.792969 304.484375 210.4375 318.089844 200.902344 C 320.660156 202.292969 323.234375 203.6875 325.804688 205.078125 C 320.019531 210.542969 311.664062 216.007812 299.769531 217.613281 C 288.304688 219.113281 277.484375 218.6875 266.023438 218.902344 C 257.128906 213.117188 247.984375 207.367188 239.09375 201.585938 Z M 239.09375 201.585938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 239.09375 207.269531 L 217.046875 223.859375 L 157.957031 223.632812 C 158.683594 226.015625 160.941406 229.058594 162.273438 230.453125 L 220.910156 230.679688 L 239.09375 216.589844 L 256.59375 230.453125 L 315.910156 230.453125 C 317.617188 228.632812 319.433594 226.132812 321.023438 223.972656 L 260.910156 224.089844 Z M 239.09375 207.269531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 205.230469 264.253906 C 222.199219 272.632812 258.261719 271.945312 274.546875 264.050781 Z M 205.230469 264.253906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 166.199219 236.136719 C 166.882812 236.136719 311.933594 236.023438 311.933594 236.023438 C 309.394531 238.902344 306.402344 242.007812 303.296875 244.660156 L 173.664062 244.09375 C 171.230469 242.40625 167.996094 238.46875 166.207031 236.136719 Z M 166.199219 236.136719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 176.210938 246.59375 L 301.363281 246.933594 C 299.015625 248.753906 297.125 250.910156 294.433594 252.273438 L 183.1875 252.179688 C 180.617188 250.800781 177.984375 248.457031 176.21875 246.59375 Z M 176.210938 246.59375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 187.613281 255.90625 L 289.882812 255.65625 C 287.15625 257.410156 284.085938 259.273438 280.679688 260.910156 L 196.8125 260.910156 C 193.972656 259.925781 190.675781 257.804688 187.609375 255.90625 Z M 187.613281 255.90625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,71.764706%,0%);fill-opacity:1;"
      d="M 293.335938 111.234375 L 289.652344 105.820312 L 283.289062 107.394531 L 287.300781 102.214844 L 283.839844 96.65625 L 290 98.867188 L 294.21875 93.851562 L 294.023438 100.398438 L 300.089844 102.863281 L 293.804688 104.699219 Z M 293.335938 111.234375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,71.764706%,0%);fill-opacity:1;"
      d="M 247.101562 103.523438 L 239.796875 98.523438 L 232.566406 103.640625 L 235.0625 95.148438 L 227.96875 89.855469 L 236.8125 89.601562 L 239.652344 81.214844 L 242.628906 89.550781 L 251.476562 89.660156 L 244.472656 95.066406 Z M 247.101562 103.523438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,71.764706%,0%);fill-opacity:1;"
      d="M 323.992188 147.117188 L 317.964844 144.566406 L 313.46875 149.335938 L 314.03125 142.8125 L 308.113281 140.015625 L 314.488281 138.53125 L 315.316406 132.03125 L 318.699219 137.640625 L 325.132812 136.421875 L 320.847656 141.371094 Z M 323.992188 147.117188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,71.764706%,0%);fill-opacity:1;"
      d="M 185.039062 111.132812 L 188.722656 105.71875 L 195.082031 107.292969 L 191.074219 102.113281 L 194.53125 96.554688 L 188.371094 98.765625 L 184.152344 93.75 L 184.351562 100.296875 L 178.28125 102.761719 L 184.566406 104.597656 Z M 185.039062 111.132812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,71.764706%,0%);fill-opacity:1;"
      d="M 152.945312 146.882812 L 158.976562 144.332031 L 163.46875 149.105469 L 162.90625 142.578125 L 168.828125 139.785156 L 162.453125 138.300781 L 161.625 131.800781 L 158.242188 137.410156 L 151.808594 136.1875 L 156.09375 141.136719 Z M 152.945312 146.882812 "
    />
  </g>
</svg>
