<script>
  import styles from "styleguide/ConfirmationLetterheadPreviewModal.json";

  import { Boundary } from "components";
  import { Modal } from "components/modals";

  export let title;
  export let shown;
  export let letterhead;
</script>

<Boundary>
  <Modal
    {shown}
    {title}
    onHide={() => {
      shown = false;
    }}
    close={true}
  >
    <div data-component="LetterheadPreviewModal">
      <div class={styles.container}>
        <img
          class={styles["letterhead-preview"]}
          src={letterhead.image_url}
          alt="preview"
        />
      </div>
    </div>
  </Modal>
</Boundary>

<style lange="scss">
  .container {
    padding: 0 25px 25px;
    box-sizing: border-box;
  }

  .letterhead-preview {
    width: 100%;
  }
</style>
