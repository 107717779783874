<script>
  import styles from "styleguide/ControlSidebarSection.json";

  import { toasts } from "stores/toasts.js";
  import { t } from "stores/i18n.js";
  import { can } from "stores/permission.js";
  import {
    isUserCanDelegate,
    replaceActiveControlAccess,
    filteredEntities,
    addedFilesControl,
    activeControlComments,
  } from "stores/pbc.js";
  import { accessMode } from "stores/client_store";
  import { isUserTeamUser, isUserClient } from "stores/user";

  import {
    isReviewed,
    iconPath,
    dateTimeFormat,
    isArraysAreEqual,
  } from "lib/helpers.js";

  import ControlAccessApi from "apis/pbc/control_access_api.js";
  import ControlApi from "apis/pbc/controls.js";
  import AuditsApi from "apis/pbc/audit.js";

  import { AuditAccessSelector } from "apps/pbc";
  import {
    Button,
    DatePicker,
    Dropdown,
    IconButton,
    Popover,
    Tag,
    Toast,
    Tooltip,
    UserPicture,
  } from "components";

  export let status;
  export let control;

  let isEditingAccess = false;
  let isShowDatePicker = false;

  $: statuses = {
    pending: $t("control_status.pending"),
    accepted: $t("control_status.accepted"),
    rejected: $t("control_status.rejected"),
  };

  $: canChangeReadyForReview =
    $isUserClient &&
    ($addedFilesControl.some((item) => !isReviewed(item, "team")) ||
      $activeControlComments.some((item) => !isReviewed(item, "team")) ||
      (control && control.status === "waiting_for_review"));

  function updateStatus(status) {
    ControlApi.edit({
      params: {
        id: control.permalink,
        status,
      },
      success: () => {},
      error: () => {},
    });
  }

  function updateDueOn(date) {
    isShowDatePicker = false;
    ControlApi.edit({
      params: {
        id: control.permalink,
        due_on: date,
      },
      success: () => {
        toasts.send({
          title: $t("control_sidebar_section.update_due_on_success"),
          type: "success",
        });
      },
      error: () => {
        toasts.send({
          title: $t("control_sidebar_section.update_due_on_error"),
          type: "error",
        });
      },
    });
  }

  function resetDueOn() {
    updateDueOn("");
  }

  function onHandleAccessUpdateUsers(allowedUserPermalinks) {
    const currentUserPermalinks = getSortedPermalinks(control.client_users);
    if (!isArraysAreEqual(allowedUserPermalinks, currentUserPermalinks)) {
      ControlAccessApi.updateUsers({
        params: {
          control_ids: [control.id],
          user_ids: allowedUserPermalinks,
        },
        success: () => {
          reloadControlAccess();
          toasts.send({
            title: $t("control_sidebar_section.client_access_updated"),
            type: "success",
          });
        },
        error: () => {
          toasts.send({
            title: $t("control_sidebar_section.client_access_error"),
            type: "error",
          });
        },
      });
    }
  }

  function onHandleAccessUpdateTeams(allowedTeamPermalinks) {
    const currentTeamPermalinks = getSortedPermalinks(control.teams);
    if (!isArraysAreEqual(allowedTeamPermalinks, currentTeamPermalinks)) {
      ControlAccessApi.updateTeams({
        params: {
          control_ids: [control.id],
          team_ids: allowedTeamPermalinks,
        },
        success: () => {
          reloadControlAccess();
          toasts.send({
            title: $t("control_sidebar_section.team_access_updated"),
            type: "success",
          });
        },
        error: () => {
          toasts.send({
            title: $t("control_sidebar_section.team_access_error"),
            type: "error",
          });
        },
      });
    }
  }

  function onHandleAccessCreateNewUser(data) {
    ControlAccessApi.addNewUser({
      params: {
        control_ids: data.controlIds,
        email: data.filterQuery,
      },
      success: () => {
        reloadControlAccess();
        toasts.send({
          title: $t("control_sidebar_section.add_user_success"),
          type: "success",
        });
      },
      error: () => {
        toasts.send({
          title: $t("control_sidebar_section.add_user_error"),
          type: "error",
        });
      },
    });
  }

  function onAccessChange(data) {
    isEditingAccess = false;
    switch ($accessMode) {
      case "users":
        if (!$filteredEntities.length && !$isUserTeamUser) {
          return onHandleAccessCreateNewUser(data);
        }
        return onHandleAccessUpdateUsers(data.users);
      case "teams":
        onHandleAccessUpdateTeams(data.teams);
        break;
      default:
        break;
    }
  }

  function getSortedPermalinks(items) {
    return items.map((item) => item.permalink).sort();
  }

  function reloadControlAccess() {
    ControlApi.get(control.id, (response) => {
      replaceActiveControlAccess(response.data.control);
    });
    AuditsApi.getClientUsers();
  }

  function userInTeam(user) {
    return control.teams.reduce((accumulator, team) => {
      if (team.client_user_ids.includes(user.permalink)) {
        const teamName = `<strong>"${team.name}"</strong>`;
        return [
          ...accumulator,
          `${$t("control_sidebar_section.member_of_team")} ${teamName}`,
        ];
      }
      return accumulator;
    }, []);
  }

  function getDateTimeFormat(value) {
    return value
      ? dateTimeFormat(value, "de", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
      : "";
  }

  function findUserEmail(permalink) {
    const foundObject = control.client_users.find(
      (obj) => obj.permalink === permalink,
    );
    return foundObject ? foundObject.email : "Not found";
  }

  function onReviewReady() {
    ControlApi.readyForReview(
      control.permalink,
      !(control.status === "waiting_for_review"),
    );
  }
</script>

<div data-component="ControlSidebarSection" class={styles.sidebar}>
  {#if canChangeReadyForReview}
    {#if control.status === "waiting_for_review"}
      <Toast type="success" message={$t("control_modal.review_unready_title")}>
        <Button style={"primary-text small"} click={onReviewReady}>
          {$t("control_modal.review_unready_button")}
        </Button>
      </Toast>
    {:else if control.status !== "accepted"}
      <Toast type="info" message={$t("control_modal.review_ready_title")}>
        <Button style={"primary small"} click={onReviewReady}>
          {$t("control_modal.review_ready_button")}
        </Button>
      </Toast>
    {/if}
  {/if}

  <div class={styles["sidebar-section"]}>
    <div class={styles["sidebar-section-header"]}>
      <h4>{$t("control_sidebar_section.status")}</h4>
    </div>
    <Dropdown
      placement="bottom"
      click={updateStatus}
      items={statuses}
      initial={$t(`control_status.${status}`)}
      bind:selected={status}
      lock={!$can("update", "control")}
    />
  </div>

  <div class={styles["sidebar-section"]}>
    <div class={styles["sidebar-section-header"]}>
      <h4>{$t("control_sidebar_section.due_on")}</h4>
      <Popover
        bind:open={isShowDatePicker}
        placement="bottom"
        showCloseButton={false}
        offsetPosition={[-146, 8]}
        style={"no-padding no-arrow"}
      >
        {#if $isUserTeamUser}
          <IconButton icon="settings" style={"primary-text small"} />
        {/if}

        <div slot="content">
          <DatePicker
            onRemove={resetDueOn}
            onSelect={updateDueOn}
            value={control.due_on}
          />
        </div>
      </Popover>
    </div>
    {#if control.due_on}
      {getDateTimeFormat(control.due_on)}
    {:else}
      <div class={styles["italic-text"]}>
        {$t("control_sidebar_section.due_on_date")}
      </div>
    {/if}
  </div>

  <div class={styles["sidebar-section"]}>
    <div class={styles["sidebar-section-header"]}>
      <h4>{$t("control_sidebar_section.client_access")}</h4>
      {#if $isUserTeamUser || $isUserCanDelegate}
        <Popover
          bind:open={isEditingAccess}
          placement="bottom"
          showCloseButton={false}
          offsetPosition={[-146, 8]}
          style={"no-padding no-arrow"}
        >
          <IconButton icon="settings" style={"primary-text small"} />
          <div slot="content">
            <AuditAccessSelector
              selectedUsers={control.client_users}
              selectedTeams={control.teams}
              controlIds={[control.permalink]}
              onSave={(data) => {
                onAccessChange(data);
              }}
            />
          </div>
        </Popover>
      {/if}
    </div>
    {#if $isUserCanDelegate && !isEditingAccess}
      <Toast message={$t("control_sidebar_section.can_delegate")} />
    {/if}
    {#if !control.client_users.length && !control.teams.length}
      <div class={styles["italic-text"]}>
        {$t("control_sidebar_section.client_access_empty_state")}
      </div>
    {/if}
    {#if control.client_users.length}
      <div class={styles["section-name"]}>
        {$t("control_sidebar_section.users")}
      </div>
      <ul class={styles["users-list"]}>
        {#each control.client_users as user}
          <li class={styles["user-list-item"]}>
            <UserPicture
              name={user.email}
              smallAvatar
              isAvatarClickable={false}
            />
            <span>
              {user.email}
            </span>
            {#if userInTeam(user).length > 0}
              <Tooltip>
                <div slot="content">
                  <Tag label="Team" style={"grey small"} />
                </div>
                <div slot="tooltip">
                  {#each userInTeam(user) as teamMembership}
                    <p class={styles["tag-tooltip"]}>{@html teamMembership}</p>
                  {/each}
                </div>
              </Tooltip>
            {/if}
          </li>
        {/each}
      </ul>
    {/if}

    {#if control.teams.length}
      <div class={styles["section-name"]}>
        {$t("control_sidebar_section.teams")}
      </div>
      <ul>
        {#each control.teams as team}
          <li class={styles["user-list-item"]}>
            <span>
              {team.name}
            </span>
            <Tooltip placement="top">
              <div slot="content">
                <img src={iconPath("users")} alt="user" />
              </div>
              <div slot="tooltip">
                <span
                  >{team.client_user_ids.length}
                  {$t("control_sidebar_section.members")}</span
                >
                <div class={styles.devider} />
                <ul>
                  {#each team.client_user_ids.slice(0, 10) as user_id}
                    <li>{findUserEmail(user_id)}</li>
                  {/each}
                </ul>

                {#if team.client_user_ids.length > 10}
                  <span
                    >+ {team.client_user_ids.length - 10}
                    {$t("control_sidebar_section.more")}</span
                  >
                {/if}
              </div>
            </Tooltip>
          </li>
        {/each}
      </ul>
    {/if}
  </div>
</div>

<style global lang="scss">
  .sidebar {
    width: 35%;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    position: relative;
  }

  .users-list {
    margin-bottom: 16px;
  }

  .user-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5em;
    gap: 0.5em;
    span {
      flex: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 300px;
    }
  }

  .section-name {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .italic-text {
    color: var(--primary-100);
    font-style: italic;
    font-weight: 400;
  }

  .devider {
    height: 1px;
    width: 100%;
    margin: 8px 0;
    background-color: var(--primary-020);
  }

  .sidebar-section-header {
    display: flex;
    margin-bottom: 0.25em;
    justify-content: space-between;
    align-items: center;
    position: relative;

    h4 {
      font-weight: 600;
      margin: 0;
    }
  }
  .tag-tooltip {
    margin: 0;
  }
</style>
