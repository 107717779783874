<script>
  export let width = 18;
  export let height = 18;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 18 18"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M9 13c.828 0 1.5.672 1.5 1.5S9.828 16 9 16s-1.5-.672-1.5-1.5S8.172 13 9 13zm0-5.5c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5S7.5 9.828 7.5 9 8.172 7.5 9 7.5zM9 2c.828 0 1.5.672 1.5 1.5S9.828 5 9 5s-1.5-.672-1.5-1.5S8.172 2 9 2z"
    transform="rotate(-90 9 9)"
  />
</svg>
