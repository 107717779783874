<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(100%,100%,100%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 60 0 L 420 0 L 420 360 L 60 360 Z M 60 0 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(3.137255%,23.921569%,61.176471%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(3.137255%,23.921569%,61.176471%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 277.28125 340.75 C 277.28125 340.75 288.119792 331.963542 298.666667 331.963542 C 309.213542 331.963542 312.140625 339.286458 319.46875 339.869792 C 326.791667 340.458333 332.942708 332.546875 342.026042 332.255208 C 351.109375 331.963542 362.53125 338.697917 362.53125 338.697917 L 322.6875 350.713542 Z M 277.28125 340.75 "
      transform="matrix(0.75,0,0,0.75,0,0)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(3.137255%,23.921569%,61.176471%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(3.137255%,23.921569%,61.176471%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 254.432292 327.859375 L 389.78125 328.447917 C 389.78125 328.447917 378.0625 315.848958 364.291667 315.557292 C 350.520833 315.260417 354.333333 321.416667 343.786458 322.291667 C 333.239583 323.171875 330.598958 315.848958 320.932292 316.140625 C 311.265625 316.432292 305.697917 322.291667 298.375 322.588542 C 291.052083 322.880208 281.677083 315.260417 276.109375 315.557292 C 270.541667 315.848958 250.619792 324.34375 250.619792 324.34375 Z M 254.432292 327.859375 "
      transform="matrix(0.75,0,0,0.75,0,0)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(3.137255%,23.921569%,61.176471%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(3.137255%,23.921569%,61.176471%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 237.151042 311.75 L 404.140625 312.338542 C 406.776042 308.53125 395.9375 299.447917 385.979167 298.567708 C 377.770833 298.859375 371.916667 307.0625 365.177083 307.359375 C 358.4375 307.651042 350.822917 298.859375 343.203125 299.15625 C 335.588542 299.447917 327.677083 307.359375 320.057292 307.359375 C 312.442708 307.359375 306.875 298.859375 297.208333 298.859375 C 287.541667 298.859375 283.145833 308.234375 275.822917 307.651042 C 268.494792 307.0625 262.052083 298.276042 255.020833 298.276042 C 247.989583 298.276042 236.270833 308.822917 233.927083 308.234375 C 231.583333 307.651042 236.859375 312.630208 237.151042 311.75 Z M 237.151042 311.75 "
      transform="matrix(0.75,0,0,0.75,0,0)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.302083 218.880208 L 339.682292 229.135417 L 339.682292 174.348958 C 322.098958 175.229167 307.453125 140.953125 338.510417 138.609375 C 308.041667 134.213542 304.520833 142.125 301.010417 150.619792 Z M 301.302083 218.880208 "
      transform="matrix(0.75,0,0,0.75,0,0)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(3.137255%,23.921569%,61.176471%);fill-opacity:1;stroke-width:4.9999;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(3.137255%,23.921569%,61.176471%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 276.989583 258.71875 L 363.708333 259.010417 "
      transform="matrix(0.75,0,0,0.75,0,0)"
    />
    <path
      style="fill:none;stroke-width:2.1333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 171.25 126.888905 L 176.719444 134.23335 "
      transform="matrix(1.40625,0,0,1.40625,-30,-0.0000229867)"
    />
    <path
      style="fill:none;stroke-width:2.1333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 171.25 134.238905 L 177.188889 127.052794 "
      transform="matrix(1.40625,0,0,1.40625,-30,-0.0000229867)"
    />
    <path
      style="fill:none;stroke-width:2.1333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 174.380556 126.419461 L 174.225 130.950016 "
      transform="matrix(1.40625,0,0,1.40625,-30,-0.0000229867)"
    />
    <path
      style="fill:none;stroke-width:2.1333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 171.250133 126.888905 L 176.719578 134.23335 "
      transform="matrix(1.40625,0,0,1.40625,-17.6955,-0.0000229867)"
    />
    <path
      style="fill:none;stroke-width:2.1333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 171.250133 134.238905 L 177.189022 127.052794 "
      transform="matrix(1.40625,0,0,1.40625,-17.6955,-0.0000229867)"
    />
    <path
      style="fill:none;stroke-width:2.1333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 174.380689 126.419461 L 174.225133 130.950016 "
      transform="matrix(1.40625,0,0,1.40625,-17.6955,-0.0000229867)"
    />
    <path
      style="fill:none;stroke-width:2.1333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 171.249716 126.888905 L 176.71916 134.23335 "
      transform="matrix(1.40625,0,0,1.40625,-5.171475,-0.0000229867)"
    />
    <path
      style="fill:none;stroke-width:2.1333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 171.249716 134.238905 L 177.188604 127.052794 "
      transform="matrix(1.40625,0,0,1.40625,-5.171475,-0.0000229867)"
    />
    <path
      style="fill:none;stroke-width:2.1333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 174.380271 126.419461 L 174.224716 130.950016 "
      transform="matrix(1.40625,0,0,1.40625,-5.171475,-0.0000229867)"
    />
    <path
      style="fill:none;stroke-width:2.1333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 171.250333 126.888905 L 176.719778 134.23335 "
      transform="matrix(1.40625,0,0,1.40625,7.7925,-0.0000229867)"
    />
    <path
      style="fill:none;stroke-width:2.1333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 171.250333 134.238905 L 177.189222 127.052794 "
      transform="matrix(1.40625,0,0,1.40625,7.7925,-0.0000229867)"
    />
    <path
      style="fill:none;stroke-width:2.1333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 174.380889 126.419461 L 174.225333 130.950016 "
      transform="matrix(1.40625,0,0,1.40625,7.7925,-0.0000229867)"
    />
    <path
      style="fill:none;stroke-width:2.1333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 171.249756 126.888905 L 176.7192 134.23335 "
      transform="matrix(1.40625,0,0,1.40625,20.31675,-0.0000229867)"
    />
    <path
      style="fill:none;stroke-width:2.1333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 171.249756 134.238905 L 177.188644 127.052794 "
      transform="matrix(1.40625,0,0,1.40625,20.31675,-0.0000229867)"
    />
    <path
      style="fill:none;stroke-width:2.1333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 174.380311 126.419461 L 174.224756 130.950016 "
      transform="matrix(1.40625,0,0,1.40625,20.31675,-0.0000229867)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,49.019608%,3.137255%);fill-opacity:1;"
      d="M 164.015625 194.699219 L 191.703125 194.917969 L 191.703125 177.5625 L 160.0625 176.023438 L 164.019531 194.699219 Z M 164.015625 194.699219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,49.019608%,3.137255%);fill-opacity:1;"
      d="M 162.699219 170.753906 L 192.140625 173.609375 L 191.921875 161.304688 L 163.136719 149.878906 L 162.695312 170.753906 Z M 162.699219 170.753906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,49.019608%,3.137255%);fill-opacity:1;"
      d="M 168.628906 146.144531 L 191.261719 157.132812 L 194.492188 153.757812 C 194.492188 153.757812 192.414062 152.324219 192.515625 151.003906 C 192.554688 149.667969 194.609375 149.464844 194.648438 148.019531 C 194.6875 146.683594 192.320312 146.523438 192.296875 145.1875 C 192.140625 143.742188 194.117188 142.1875 194.117188 142.1875 L 173.683594 124.390625 Z M 168.628906 146.144531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,49.019608%,3.137255%);fill-opacity:1;"
      d="M 317.160156 194.917969 L 287.9375 194.917969 L 287.71875 178 L 319.796875 175.582031 Z M 317.160156 194.917969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,49.019608%,3.137255%);fill-opacity:1;"
      d="M 288.15625 174.046875 L 322.875 169.871094 L 315.402344 149.875 L 287.9375 161.519531 L 288.15625 174.042969 Z M 288.15625 174.046875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,49.019608%,3.137255%);fill-opacity:1;"
      d="M 313.425781 144.382812 L 288.375 157.566406 C 288.011719 156.136719 287.699219 154.761719 285.960938 153.609375 C 285.960938 153.609375 287.496094 152.730469 287.496094 151.191406 C 287.496094 149.65625 285.519531 149.433594 285.519531 148.554688 C 285.519531 147.675781 287.332031 146.910156 287.441406 144.929688 C 287.222656 143.558594 285.519531 141.636719 285.847656 141.25 L 305.296875 126.363281 Z M 313.425781 144.382812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,49.019608%,3.137255%);fill-opacity:1;"
      d="M 259.15625 173.714844 L 271.679688 173.167969 L 271.898438 168.109375 Z M 259.15625 173.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,49.019608%,3.137255%);fill-opacity:1;"
      d="M 220.925781 173.332031 L 207.742188 172.949219 L 207.742188 167.675781 Z M 220.925781 173.332031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,49.019608%,3.137255%);fill-opacity:1;"
      d="M 220.867188 171.796875 L 207.738281 165.039062 L 207.738281 156.25 C 207.738281 156.25 206.199219 156.46875 206.421875 154.710938 C 206.492188 151.050781 216.070312 161.394531 220.976562 164.761719 L 220.867188 171.792969 Z M 220.867188 171.796875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,49.019608%,3.137255%);fill-opacity:1;"
      d="M 259.15625 170.96875 L 259.101562 165.257812 C 259.101562 165.257812 270.964844 154.546875 273.492188 152.570312 C 273.492188 154.765625 272.121094 156.46875 272.121094 156.46875 L 272.121094 164.816406 Z M 259.15625 170.96875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,49.019608%,3.137255%);fill-opacity:1;"
      d="M 182.25 122.851562 C 182.46875 123.070312 195.5625 137.621094 195.5625 137.621094 C 195.929688 136.242188 198.949219 136.035156 202.023438 136.253906 C 205.101562 136.472656 207.554688 136.046875 207.554688 138.246094 C 207.554688 140.441406 205.992188 140.128906 205.992188 141.667969 C 205.992188 143.207031 208.332031 143.078125 208.332031 145.050781 C 208.332031 147.03125 206.636719 146.625 206.625 148.15625 C 206.621094 149.421875 208.394531 149.492188 208.394531 149.492188 L 220.867188 161.578125 L 220.921875 148.667969 L 195.214844 108.347656 Z M 182.25 122.851562 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,49.019608%,3.137255%);fill-opacity:1;"
      d="M 202.824219 107.585938 C 203.019531 108.175781 220.234375 143.195312 220.234375 143.195312 C 220.234375 143.195312 220.429688 110.328125 223.363281 108.566406 L 218.472656 99.566406 L 202.820312 107.585938 Z M 202.824219 107.585938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,49.019608%,3.137255%);fill-opacity:1;"
      d="M 278.535156 108.953125 L 259.09375 143.636719 L 259.117188 129.019531 C 259.117188 129.019531 260.730469 126.558594 258.1875 126.757812 C 255.644531 126.949219 252.515625 126.558594 252.515625 126.558594 L 260.339844 99.167969 Z M 278.535156 108.953125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,49.019608%,3.137255%);fill-opacity:1;"
      d="M 298.492188 123.824219 C 298.296875 124.214844 285.375 137.355469 285.375 137.355469 C 284.789062 135.789062 280.882812 136.542969 277.167969 136.542969 C 273.449219 136.542969 272.929688 137.734375 273.128906 138.710938 C 273.519531 141.253906 274.773438 139.378906 274.773438 141.730469 C 274.773438 144.074219 272.964844 143.21875 272.804688 144.9375 C 272.984375 146.910156 275.644531 146.425781 274.085938 147.921875 L 259.125 162.28125 L 259.167969 148.671875 L 286.949219 105.433594 Z M 298.492188 123.824219 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 309.817708 268.401042 C 301.473958 282.223958 279.15625 278.125 273.9375 268.473958 C 272.375 268.03125 273.296875 208.921875 273.296875 208.921875 C 273.296875 208.921875 270.807292 207.786458 270.692708 205.96875 C 270.578125 204.135417 274.083333 203.96875 274.083333 201.619792 C 274.083333 199.270833 270.5 200.192708 270.432292 197.828125 C 270.447917 195.567708 274.25 195.880208 274.083333 193.791667 C 273.880208 191.4375 269.817708 191.786458 269.645833 189.619792 C 269.515625 187.895833 272.552083 186.390625 273.395833 185.614583 C 272.848958 185.645833 270.572917 185.583333 270.5625 185.572917 C 268.427083 185.619792 266.296875 185.661458 264.166667 185.703125 C 259.625 185.708333 264.244792 186.692708 264.177083 189.317708 C 264.135417 191.026042 261.875 192.166667 261.671875 193.65625 C 261.604167 195.182292 264.911458 196.255208 264.953125 198.088542 C 264.989583 199.723958 261.703125 199.838542 261.822917 201.354167 C 262.026042 203.927083 264.734375 204.494792 264.692708 206.052083 C 264.651042 207.609375 261.046875 208.203125 261.036458 209.442708 C 261.166667 211.84375 261.5625 270.223958 261.5625 270.223958 C 267.296875 299.963542 300.427083 307.53125 309.817708 268.401042 Z M 309.817708 268.401042 "
      transform="matrix(0.75,0,0,0.75,0,0)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 331.661458 268.401042 C 340.005208 282.223958 362.322917 278.125 367.541667 268.473958 C 369.104167 268.03125 368.182292 208.921875 368.182292 208.921875 C 368.182292 208.921875 370.671875 207.786458 370.786458 205.96875 C 370.901042 204.135417 367.619792 203.96875 367.619792 201.619792 C 367.619792 199.270833 370.979167 200.192708 371.046875 197.828125 C 371.036458 195.567708 367.520833 195.734375 367.692708 193.645833 C 367.890625 191.291667 370.651042 191.567708 370.807292 189.395833 C 370.9375 187.53125 369.072917 186.390625 368.229167 185.614583 C 368.776042 185.645833 370.90625 185.583333 370.921875 185.572917 C 373.052083 185.619792 375.182292 185.661458 377.3125 185.703125 C 381.854167 185.708333 377.234375 186.692708 377.302083 189.317708 C 377.338542 191.026042 379.604167 192.166667 379.807292 193.65625 C 379.875 195.182292 376.567708 196.255208 376.526042 198.088542 C 376.489583 199.723958 379.776042 199.838542 379.65625 201.354167 C 379.453125 203.927083 376.744792 204.494792 376.786458 206.052083 C 376.828125 207.609375 380.432292 208.203125 380.442708 209.442708 C 380.317708 211.84375 379.921875 270.223958 379.921875 270.223958 C 374.182292 299.963542 341.052083 307.53125 331.661458 268.401042 Z M 331.661458 268.401042 "
      transform="matrix(0.75,0,0,0.75,0,0)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(3.137255%,23.921569%,61.176471%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(3.137255%,23.921569%,61.176471%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.708333 295.588542 L 338.989583 295.567708 C 339.276042 295.276042 330.640625 282.692708 320.354167 283.583333 C 308.895833 283.880208 301.109375 295.588542 301.713542 295.588542 Z M 301.708333 295.588542 "
      transform="matrix(0.75,0,0,0.75,0,0)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(3.137255%,23.921569%,61.176471%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(3.137255%,23.921569%,61.176471%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 420.567708 294.682292 L 369.5625 294.682292 C 369.5625 294.682292 376.166667 290.776042 377.963542 287.177083 C 381.265625 288.979167 380.364583 290.776042 386.963542 291.078125 C 393.567708 291.380208 399.864583 283.578125 406.166667 283.880208 C 412.46875 284.177083 420.567708 294.979167 420.567708 294.682292 Z M 420.567708 294.682292 "
      transform="matrix(0.75,0,0,0.75,0,0)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(3.137255%,23.921569%,61.176471%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(3.137255%,23.921569%,61.176471%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 219.567708 294.682292 L 270.578125 294.682292 C 270.578125 294.682292 263.973958 290.776042 262.177083 287.177083 C 258.875 288.979167 259.776042 290.776042 253.171875 291.078125 C 246.572917 291.380208 240.270833 283.578125 233.973958 283.880208 C 227.671875 284.177083 219.572917 294.979167 219.572917 294.682292 Z M 219.567708 294.682292 "
      transform="matrix(0.75,0,0,0.75,0,0)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(3.137255%,23.921569%,61.176471%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(3.137255%,23.921569%,61.176471%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 223.380208 278.640625 L 259.708333 278.932292 C 259.708333 278.932292 257.364583 273.953125 257.072917 267.802083 C 247.692708 264.578125 240.078125 274.833333 233.338542 275.125 C 226.604167 275.416667 219.572917 267.802083 219.572917 267.802083 Z M 223.380208 278.640625 "
      transform="matrix(0.75,0,0,0.75,0,0)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(3.137255%,23.921569%,61.176471%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(3.137255%,23.921569%,61.176471%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 417.317708 278.640625 L 380.989583 278.932292 C 380.989583 278.932292 383.338542 273.953125 383.630208 267.802083 C 393.005208 264.578125 400.619792 274.833333 407.359375 275.125 C 414.098958 275.416667 421.130208 267.802083 421.130208 267.802083 Z M 417.317708 278.640625 "
      transform="matrix(0.75,0,0,0.75,0,0)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(3.137255%,23.921569%,61.176471%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(3.137255%,23.921569%,61.176471%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 310.96875 278.9375 L 329.427083 278.354167 C 329.427083 278.354167 329.71875 272.786458 320.052083 272.786458 C 310.385417 272.786458 311.260417 279.234375 310.96875 278.9375 Z M 310.96875 278.9375 "
      transform="matrix(0.75,0,0,0.75,0,0)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(3.137255%,23.921569%,61.176471%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(3.137255%,23.921569%,61.176471%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 299.838542 271.03125 C 303.0625 269.270833 305.989583 267.515625 308.041667 263.411458 L 295.447917 263.703125 C 295.447917 263.703125 289.588542 267.223958 286.65625 271.03125 Z M 299.838542 271.03125 "
      transform="matrix(0.75,0,0,0.75,0,0)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(3.137255%,23.921569%,61.176471%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(3.137255%,23.921569%,61.176471%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 340.5625 271.03125 C 337.338542 269.270833 334.40625 267.515625 332.359375 263.411458 L 344.953125 263.703125 C 344.953125 263.703125 350.8125 267.223958 353.744792 271.03125 L 340.557292 271.03125 Z M 340.5625 271.03125 "
      transform="matrix(0.75,0,0,0.75,0,0)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(87.058824%,12.54902%,6.27451%);fill-opacity:1;"
      d="M -30 270 L 510 270 L 510 360 L -30 360 Z M -30 270 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(87.058824%,12.54902%,6.27451%);fill-opacity:1;"
      d="M -30 0 L 510 0 L 510 90 L -30 90 Z M -30 0 "
    />
  </g>
</svg>
