<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(0%,0%,40%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 0 L 0 23.863281 L 262.152344 213.457031 L 295.152344 213.457031 L 295.152344 189.589844 L 32.996094 0 Z M 295.152344 0 L 295.152344 23.863281 L 32.996094 213.457031 L 0 213.457031 L 0 189.589844 L 262.152344 0 Z M 295.152344 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 122.980469 0 L 122.980469 213.457031 L 172.171875 213.457031 L 172.171875 0 Z M 0 71.152344 L 0 142.304688 L 295.152344 142.304688 L 295.152344 71.152344 Z M 0 71.152344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 0 85.382812 L 0 128.074219 L 295.152344 128.074219 L 295.152344 85.382812 Z M 132.816406 0 L 132.816406 213.457031 L 162.332031 213.457031 L 162.332031 0 Z M 132.816406 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 0 213.457031 L 98.382812 142.304688 L 120.382812 142.304688 L 22 213.457031 Z M 0 0 L 98.382812 71.152344 L 76.386719 71.152344 L 0 15.910156 Z M 174.769531 71.152344 L 273.152344 0 L 295.152344 0 L 196.765625 71.152344 Z M 295.152344 213.457031 L 196.765625 142.304688 L 218.765625 142.304688 L 295.152344 197.546875 Z M 295.152344 213.457031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 184.808594 333.359375 L 160.933594 308.234375 L 137.054688 333.359375 L 141.8125 299.027344 L 107.285156 296.023438 L 137.085938 278.335938 L 117.910156 249.46875 L 150.316406 261.742188 L 160.933594 228.746094 L 171.546875 261.742188 L 203.953125 249.46875 L 184.78125 278.335938 L 214.582031 296.023438 L 180.054688 299.027344 Z M 184.808594 333.359375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 368.0625 320.464844 L 372.667969 306.128906 L 377.277344 320.464844 L 391.363281 315.128906 L 383.027344 327.671875 L 395.976562 335.359375 L 380.976562 336.664062 L 383.042969 351.574219 L 372.667969 340.664062 L 362.292969 351.574219 L 364.363281 336.664062 L 349.363281 335.359375 L 362.308594 327.671875 L 353.976562 315.128906 Z M 368.0625 320.464844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 368.0625 97.328125 L 372.667969 82.996094 L 377.277344 97.328125 L 391.363281 91.996094 L 383.027344 104.539062 L 395.976562 112.222656 L 380.976562 113.527344 L 383.042969 128.441406 L 372.667969 117.527344 L 362.292969 128.441406 L 364.363281 113.527344 L 349.363281 112.222656 L 362.308594 104.539062 L 353.976562 91.996094 Z M 368.0625 97.328125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 284.386719 187.976562 L 288.992188 173.640625 L 293.601562 187.976562 L 307.6875 182.644531 L 299.351562 195.183594 L 312.300781 202.871094 L 297.300781 204.175781 L 299.371094 219.089844 L 288.992188 208.175781 L 278.617188 219.089844 L 280.6875 204.175781 L 265.6875 202.871094 L 278.632812 195.183594 L 270.300781 182.644531 Z M 284.386719 187.976562 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 442.453125 165.664062 L 447.027344 151.328125 L 451.660156 165.664062 L 465.714844 160.332031 L 457.402344 172.871094 L 470.347656 180.558594 L 455.339844 181.863281 L 457.402344 196.773438 L 447.027344 185.863281 L 436.652344 196.773438 L 438.714844 181.863281 L 423.738281 180.558594 L 436.707031 172.871094 L 428.359375 160.332031 Z M 442.453125 165.664062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 400.242188 234.328125 L 392.875 228.109375 L 402.496094 227.398438 L 406.140625 218.46875 L 409.78125 227.398438 L 419.40625 228.109375 L 412.035156 234.328125 L 414.339844 243.695312 L 406.140625 238.613281 L 397.9375 243.695312 Z M 400.242188 234.328125 "
    />
  </g>
</svg>
