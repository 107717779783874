<script>
  export let width = 40;
  export let height = 40;
  export let fill = "#2DD280";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 40 41"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M19.999 0.67453C8.95458 0.67453 -0.000976562 9.63009 -0.000976562 20.6745C-0.000976562 31.7212 8.95458 40.6745 19.999 40.6745C31.0457 40.6745 39.999 31.7212 39.999 20.6745C39.999 9.63009 31.0457 0.67453 19.999 0.67453Z"
    {fill}
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M16.9699 30.1697C16.4527 30.1697 15.9356 29.9717 15.5416 29.5778L9.48131 23.5175C8.69145 22.7276 8.69145 21.4509 9.48131 20.6611C10.2712 19.8712 11.5479 19.8712 12.3377 20.6611L16.9699 25.2932L27.6623 14.6007C28.4522 13.8109 29.7289 13.8109 30.5187 14.6007C31.3086 15.3906 31.3086 16.6673 30.5187 17.4572L18.3981 29.5778C18.0042 29.9717 17.487 30.1697 16.9699 30.1697Z"
    fill="white"
  />
</svg>
