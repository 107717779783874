import Api from "apis/api";
import { buildFormData } from "lib/helpers.js";

class ConfirmationAttachments {
  constructor() {
    this.endpoint = "/confirmation_attachments";
  }

  upload(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params, null, true);

    Api.post(this.endpoint, {
      body: formData,
      success,
      error,
    });
  }

  get(options) {
    const { params, success, error } = options;

    Api.get(this.endpoint, {
      params,
      success,
      error,
    });
  }

  delete(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    formData.append("confirmation_id", params.confirmation_id);

    Api.delete(this.endpoint + `/${params.id}`, {
      body: formData,
      success,
      error,
    });
  }

  assign(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    formData.append("confirmation_id", params.confirmation_id);
    formData.append("mailing_id", params.mailing_id);
    formData.append("attachment_ids", params.attachment_ids);

    Api.post(this.endpoint + "/assign_to_mailing", {
      body: formData,
      success,
      error,
    });
  }
}

export default new ConfirmationAttachments();
