<script>
  import styles from "styleguide/ContentSidebar.json";
</script>

<div data-component="ContentSidebar" class={styles["content-sidebar"]}>
  <div class={styles.content}>
    <slot name="content" />
  </div>

  <div class={styles.sidebar}>
    <slot name="sidebar" />
  </div>
</div>

<style lang="scss">
  .content-sidebar {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 0 var(--page-gutter) 40px var(--page-gutter);
  }

  .content {
    flex: 1;
    margin-right: 35px;
  }

  .content > div {
    display: flex;
    flex-direction: column;
  }

  .sidebar {
    flex: 0 0 335px;
  }
</style>
