import Api from "apis/api";

class Projects {
  constructor() {
    this.endpoint = "/projects";
  }

  get(options) {
    Api.get(this.endpoint, {
      params: options.params,
      success: options.success,
      error: options.error,
    });
  }

  getProjectsOverview(options) {
    Api.get(`${this.endpoint}/overview`, {
      params: options.params,
      success: options.success,
      error: options.error,
    });
  }

  getProjectsStatus(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    for (let id of params.ids) {
      formData.append("ids[]", id);
    }

    Api.get(`${this.endpoint}/status`, {
      params: formData,
      success,
      error,
    });
  }

  getProject(options, id) {
    Api.get(`${this.endpoint}/${id}`, {
      params: options.params,
      success: options.success,
      error: options.error,
    });
  }

  create(options) {
    Api.post(this.endpoint, {
      body: options.params,
      success: options.success,
      error: options.error,
    });
  }

  edit(options, id) {
    Api.patch(`${this.endpoint}/${id}`, {
      body: options.params,
      success: options.success,
      error: options.error,
    });
  }

  search(options) {
    Api.get(`${this.endpoint}/search`, {
      params: options.params,
      success: options.success,
      error: options.error,
    });
  }

  getActivities(options, id) {
    Api.get(`${this.endpoint}/${id}/activities`, {
      params: options.params,
      success: options.success,
      error: options.error,
    });
  }

  archive(options, id) {
    Api.post(`${this.endpoint}/${id}/archive`, {
      body: options.params,
      success: options.success,
      error: options.error,
    });
  }

  delete(options, id) {
    Api.delete(`${this.endpoint}/${id}`, {
      body: options.params,
      success: options.success,
      error: options.error,
    });
  }

  createAudit(options, id) {
    Api.post(`${this.endpoint}/${id}/audits`, {
      body: options.params,
      success: options.success,
      error: options.error,
    });
  }
}

export default new Projects();
