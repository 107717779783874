<script>
  import styles from "styleguide/ConfirmationList.json";
  import { isUserTeamUser } from "stores/user.js";
  import { t } from "stores/i18n.js";
  import {
    confirmationsItems,
    selectedItems,
    sort,
  } from "stores/confirmation/confirmation.js";

  import { ConfirmationListBar, ConfirmationListItem } from "apps/confirmation";
  import { Boundary, Button, Checkbox, Options } from "components";

  $: sortTypes = {
    name: $t("sort.sort_name"),
    "last-updated-desc": $t("sort.sort_updated"),
    "last-created-desc": $t("sort.sort_created"),
  };
  $: displayedSort = sortTypes[$sort];

  $: typeChecked =
    $confirmationsItems.every((item) =>
      $selectedItems.includes(item.permalink),
    ) && $selectedItems.length;

  function typeCheck(value) {
    if (value) {
      selectedItems.update(() =>
        $confirmationsItems.map((item) => item.permalink),
      );
    } else {
      selectedItems.update(() => []);
    }
  }
</script>

<Boundary>
  <div data-component="ConfirmationList" class={styles.wrapper}>
    <div class={styles.list}>
      <div class={styles.header}>
        {#if $isUserTeamUser}
          <div class={styles.checkbox}>
            <Checkbox style="blue" toggle={typeCheck} toggled={typeChecked} />
          </div>
        {/if}
        <div class={styles.progress} />
        <div class={styles.middle}>
          <div class={styles.sort}>
            <Options
              style="medium no-border acts-as-button"
              title={displayedSort}
            >
              <Button style="blue-text option" click={() => sort.set("name")}
                >{$t("sort.sort_name")}</Button
              >
              <Button
                style="blue-text option"
                click={() => sort.set("last-updated-desc")}
                >{$t("sort.sort_updated")}</Button
              >
              <Button
                style="blue-text option"
                click={() => sort.set("last-created-desc")}
                >{$t("sort.sort_created")}</Button
              >
            </Options>
          </div>
        </div>

        <div class={styles.type}>
          <div class={styles.label}>{$t("confirmation_list.type")}</div>
        </div>

        <div class={styles.status}>{$t("confirmation_list.status")}</div>
      </div>

      {#each $confirmationsItems as confirmation (confirmation.permalink)}
        <ConfirmationListItem {...confirmation} />
      {/each}
    </div>
  </div>

  <ConfirmationListBar />
</Boundary>

<style lang="scss">
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
  }

  .list {
    background: #fff;
    border: 1px solid var(--primary-050);
    border-radius: var(--border-radius);
  }

  .middle {
    display: flex;
    align-items: center;
    width: calc(100% - 300px);
  }
  .checkbox {
    width: 40px;
  }
  .progress {
    width: 70px;
  }
  .sort {
    height: 30px;
    width: 200px;
    margin-left: -10px;
  }

  .type {
    width: 20%;
  }

  .label {
    padding-left: 5px;
  }

  .status {
    width: 230px;
    padding: 0 10px;
  }
</style>
