<script>
  import Icons from "components/icons/Icons.svelte";
  import { t } from "stores/i18n.js";
  import styles from "styleguide/SandboxLoadingCard.json";

  export let icon;
  export let product;
</script>

<div data-component="SandboxLoadingCard" class={styles.card}>
  <div class={styles.title}>
    <Icons {icon} width="22" height="22" />
    <div>{@html $t(`sandbox_loading_card.${product}.title`)}</div>
  </div>
  <ul class={styles.list}>
    <li>{@html $t(`sandbox_loading_card.${product}.bullet1`)}</li>
    <li>{@html $t(`sandbox_loading_card.${product}.bullet2`)}</li>
    <li>{@html $t(`sandbox_loading_card.${product}.bullet3`)}</li>
  </ul>
</div>

<style lang="scss">
  .card {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    border: 1px solid var(--primary-050);
    border-radius: 16px;
    margin-bottom: 14px;

    .title {
      display: flex;
      gap: 12px;
      font-weight: 600;
      font-size: 16px;
    }

    .list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  ul {
    list-style-type: disc;
    margin-left: 14px;
  }
</style>
