<script>
  import styles from "styleguide/NotificationInterval.json";
  import { t } from "stores/i18n.js";
  import { notificationsInterval } from "stores/notification.js";
  import TeamUsersApi from "apis/users/team_users.js";

  import { LegacyButton as Button } from "components";

  let intervals = ["never", "daily", "hourly"];

  function setInterval(interval) {
    let formData = new FormData();

    formData.append("notify_interval", interval);

    TeamUsersApi.settings({
      body: formData,
      success: () => {
        notificationsInterval.set(interval);
      },
    });
  }
</script>

<div class={styles.wrapper} data-component="NotificationInterval">
  <div class={styles.title}>{$t("notification_interval.send")}</div>
  <div>
    {#each intervals as interval}
      <Button
        style={`${$notificationsInterval === interval ? "active" : ""} medium tab`}
        click={setInterval.bind(this, interval)}
        >{$t(`notification_interval.${interval}`)}</Button
      >
    {/each}
  </div>
</div>

<style lang="scss">
  .wrapper {
    width: 100%;
    padding: 4px;
    border-top: 1px solid var(--primary-050);
  }

  .title {
    white-space: nowrap;
    padding: 4px 6px 0;
    font-weight: 400;
    color: var(--primary-300);
  }
</style>
