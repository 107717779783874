<script>
  import styles from "styleguide/ConfirmationChecklist.json";

  import { user } from "stores/user.js";
  import { t } from "stores/i18n.js";
  import {
    confirmation,
    filter,
    letterhead,
    activeLetterhead,
    createNewConfirmationItem,
  } from "stores/confirmation/confirmation.js";

  import { Boundary, Button, Tag } from "components";

  let list = [];

  confirmation.subscribe((value) => {
    if ($filter) return;
    list = makeList(value, value.available_confirmation_types_client);
  });

  function canManageConfirmationItems() {
    let _user =
      $confirmation.confirmation_client_users &&
      $confirmation.confirmation_client_users.find(
        (item) => item.client_user.permalink === $user.permalink,
      );

    return _user && _user.can_manage_confirmation_items;
  }

  function makeList(value, types) {
    if (!types) return [];

    return types.map((type) => {
      let count = 0;
      const signers = new Map();
      const items = value.confirmation_items;

      if (items) {
        items.forEach((item) => {
          if (item.confirmation_type === type) {
            count++;
            item.authorized_signers.forEach((signer) => {
              signers.set(signer.email, 1);
            });
          }
        });
      }

      return {
        type,
        signers: signers.size,
        count,
      };
    });
  }

  function openLetterheadModal() {
    activeLetterhead.set(true);
  }
</script>

<Boundary>
  <div data-component="ConfirmationChecklist" class={styles.wrapper}>
    <div class={styles.header}>
      <span class={styles["header-title"]}
        >{$t("confirmation_checklist.title")}</span
      >
      <span class={styles["header-subtitle"]}
        >{$t("confirmation_checklist.subtitle")}</span
      >
    </div>

    <div class={styles.list}>
      {#each list as { type, count, signers }}
        <div class={styles.item}>
          <div class={styles["item-inner"]}>
            <div>
              <div class={styles["item-inner-title"]}>
                {$t("confirmation_checklist.add_title") &&
                  $t("confirmation_checklist.add_title").replace(
                    "%{types}",
                    $t(`confirmation.types.${type}s_title`),
                  )}
                {#if count > 0}
                  <Tag
                    color="green"
                    label={$t("confirmation_checklist.done")}
                    size="small"
                  />
                {:else}
                  <Tag
                    color="blue"
                    label={$t("confirmation_checklist.open")}
                    size="small"
                  />
                {/if}
              </div>
              <div class={styles.description}>
                {#if $t("confirmation_checklist.authorized")}
                  {#if count > 0}
                    {$t("confirmation_checklist.authorized")
                      .replace(
                        "%{signers}",
                        $t(
                          `confirmation_checklist.authorized_signer${signers > 1 ? "s" : ""}`,
                        ).replace("%{count}", signers),
                      )
                      .replace(
                        "%{types}",
                        `${count} ${$t(`confirmation.types.${type + (count > 1 ? "s" : "")}`)}`,
                      )}
                  {:else}
                    {$t("confirmation_checklist.authorized_empty")}
                  {/if}
                {/if}
              </div>
            </div>

            {#if canManageConfirmationItems()}
              <Button
                style="primary-text"
                click={createNewConfirmationItem.bind(this, type)}
                >{$t("confirmation_checklist.add")}
              </Button>
            {/if}
          </div>
        </div>
      {/each}
      <div class={styles.item}>
        <div class={styles["item-inner"]}>
          <div>
            <div class={styles["item-inner-title"]}>
              {$t("confirmation_checklist.upload_title")}
              {#if $letterhead.url}
                <Tag
                  color="green"
                  label={$t("confirmation_checklist.done")}
                  size="small"
                />
              {:else}
                <Tag
                  color="blue"
                  label={$t("confirmation_checklist.open")}
                  size="small"
                />
              {/if}
            </div>
            <div class={styles.description}>
              {$t("confirmation_checklist.upload_description")}
            </div>
          </div>
          <Button style="primary-text" click={openLetterheadModal.bind(this)}
            >{$t("confirmation_checklist.upload")}</Button
          >
        </div>
      </div>
    </div>
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    margin-bottom: 20px;
    background: var(--white);
    border-radius: var(--border-radius);
    border: 1px solid var(--primary-050);
  }

  .header {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    color: var(--primary);
  }

  .header-title {
    font-weight: 600;
    margin-right: 12px;
    gap: 8px;
  }

  .header-subtitle {
    color: var(--primary-300);
  }

  .item {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-top: 1px solid var(--primary-050);

    &:last-child {
      border-bottom-left-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }
  }

  .item-inner {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .description {
    color: var(--primary-300);
  }
</style>
