<script>
  import { getContext } from "svelte";
  import { t } from "stores/i18n.js";
  import styles from "styleguide/ConfirmationDeleteModal.json";
  import ConfirmationApi from "apis/confirmation/confirmation.js";
  import {
    confirmationId,
    activeDelete,
  } from "stores/confirmation/confirmation.js";
  import { enterYesConfirmation } from "lib/helpers.js";
  import { toasts } from "stores/toasts.js";

  import { Button, TextInput } from "components";
  import { Modal } from "components/modals";

  let dirty;
  let confirmationText;

  const { language } = getContext("config");

  function onHide() {
    activeDelete.set(false);
  }

  function deleteProject() {
    if (enterYesConfirmation(confirmationText, language)) {
      ConfirmationApi.delete({
        params: {
          id: $confirmationId,
        },
        success: (response) => {
          const path = response.data.url;

          if (path) {
            window.location = path;
          }
        },
        error: () => {
          toasts.send({
            title: $t("confirmation_delete_modal.confirmation_error"),
            type: "error",
          });
        },
      });
    } else {
      dirty = true;
    }
  }
</script>

{#if $activeDelete}
  <Modal
    shown={$activeDelete}
    {onHide}
    title={$t("confirmation_delete_modal.delete_title")}
    style="error"
  >
    <div class={styles.wrapper} data-component="ConfirmationDeleteModal">
      <p>
        {@html $t("confirmation_delete_modal.delete_description")}
      </p>
      <div class={styles.confirmation}>
        <div class={styles.input}>
          <TextInput
            placeholder={$t("confirmation_delete_modal.delete_placeholder")}
            bind:value={confirmationText}
            errors={dirty
              ? [$t("confirmation_delete_modal.confirmation_error")]
              : []}
            style="border medium round-border"
          />
        </div>
        <Button
          style={"error"}
          click={deleteProject.bind(this, $confirmationId)}
          >{$t("confirmation_delete_modal.delete_button")}</Button
        >
      </div>
    </div>
  </Modal>
{/if}

<style lang="scss">
  .wrapper {
    padding-bottom: 16px;
  }

  .description {
    color: var(--primary-500);
    margin-bottom: 16px;
  }

  .confirmation {
    display: flex;
  }

  .input {
    width: 150px;
    margin-right: 12px;
  }
  p {
    margin-top: 0;
  }
</style>
