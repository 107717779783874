<script>
  export let width = 28;
  export let height = 28;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 28 28"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M8.87874 25.692C5.40509 23.9327 3.1943 20.5657 2.83747 16.7244C2.80869 16.4141 2.7943 15.9917 2.7943 15.4571C2.79388 12.3117 2.79345 9.1661 2.79303 6.02028C2.79303 5.64864 2.8216 5.36271 2.87874 5.1625C3.14922 4.22028 3.85589 3.78155 4.79811 3.67488C7.70054 3.34599 10.302 2.29245 12.6026 0.514248C13.2565 0.00821664 13.9689 -0.186069 14.7518 0.151709C14.9071 0.218587 15.1736 0.39319 15.5511 0.675518C17.7433 2.31573 20.204 3.30642 22.9333 3.64758C23.3524 3.69964 23.6457 3.75679 23.8133 3.81901C24.4567 4.05732 24.8701 4.50515 25.0533 5.1625C25.1135 5.37795 25.1435 5.70663 25.1435 6.14853C25.1439 9.20885 25.1431 12.2694 25.141 15.3301C25.1405 15.899 25.1298 16.3219 25.1086 16.5987C24.8349 20.2133 22.8451 23.4527 19.7391 25.3193C19.5012 25.462 19.1281 25.6613 18.6197 25.9174C17.3896 26.5367 16.1577 27.1521 14.9238 27.7638C14.2868 28.08 13.6497 28.08 13.0127 27.7638C11.632 27.0793 10.254 26.3887 8.87874 25.692ZM4.8762 16.7085C5.21589 19.8571 7.0616 22.5041 9.90414 23.939C11.2459 24.6167 12.592 25.2908 13.9422 25.9612C13.9513 25.9658 13.9613 25.9681 13.9714 25.9681C13.9816 25.9681 13.9916 25.9658 14.0007 25.9612C15.3886 25.2692 16.7731 24.5703 18.1543 23.8647C20.9384 22.4431 22.7626 19.7441 23.0648 16.645C23.0927 16.361 23.1071 15.9382 23.108 15.3765C23.1151 12.1646 23.1151 8.95277 23.108 5.74091C23.108 5.72017 23.0976 5.70875 23.0768 5.70663C19.6834 5.35531 16.6527 4.13054 13.9848 2.03234C13.9691 2.02007 13.9532 2.02007 13.9372 2.03234C11.2735 4.13689 8.24509 5.36208 4.85208 5.7079C4.84494 5.70854 4.8383 5.71189 4.83348 5.7173C4.82866 5.72271 4.826 5.72978 4.82605 5.73711C4.82435 8.97012 4.82456 12.2044 4.82668 15.44C4.8271 15.983 4.84361 16.4059 4.8762 16.7085Z"
  />
  <path
    d="M13.7784 17.1067C13.3187 17.614 12.6603 17.6375 12.1892 17.16C11.7287 16.6936 10.757 15.6921 9.27429 14.1556C8.20572 13.0483 9.68953 11.6896 10.6406 12.661C11.4068 13.4436 12.1714 14.2278 12.9346 15.0134C12.9498 15.0286 12.9649 15.0286 12.9797 15.0134C14.2847 13.6839 15.5898 12.3552 16.8952 11.0273C17.2368 10.6803 17.4377 10.4875 17.4978 10.4489C17.9111 10.1854 18.5587 10.2997 18.8508 10.7219C19.1714 11.1854 19.073 11.6985 18.6946 12.087C17.1416 13.6798 15.5824 15.2665 14.0171 16.847C13.9905 16.8737 13.9109 16.9603 13.7784 17.1067Z"
  />
</svg>
