<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <defs>
    <linearGradient
      id="linear0"
      gradientUnits="userSpaceOnUse"
      x1="359.59"
      y1="-5035.4"
      x2="4203.4"
      y2="-5035.4"
      gradientTransform="matrix(0.08535,0,0,0.14208,-225.71,715.44)"
    >
      <stop offset="0" style="stop-color:rgb(0%,0%,0%);stop-opacity:1;" />
      <stop offset="1" style="stop-color:rgb(100%,100%,100%);stop-opacity:1;" />
    </linearGradient>
  </defs>
  <g id="surface1">
    <path
      style="fill-rule:nonzero;fill:rgb(0%,60%,0%);fill-opacity:1;stroke-width:0.13765;stroke-linecap:butt;stroke-linejoin:miter;stroke:url(#linear0);stroke-miterlimit:4;"
      d="M -114.940889 0.066672 L 192.492444 0.066672 L 192.492444 511.933339 L -114.940889 511.933339 Z M -114.940889 0.066672 "
      transform="matrix(0.703125,0,0,0.703125,50.8725,-0.00000372998)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 185.746094 0 L 509.992188 0 L 509.992188 360 L 185.746094 360 Z M 185.746094 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 174.125 136.3125 L 173.460938 149.726562 L 263.640625 140.808594 L 264.304688 127.390625 Z M 174.125 136.3125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 174.933594 137.789062 L 174.421875 148.0625 L 262.832031 139.316406 L 263.34375 129.042969 Z M 174.933594 137.789062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 173.664062 133.53125 L 173.445312 137.933594 L 264.53125 128.925781 L 264.75 124.523438 Z M 173.664062 133.53125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 174.394531 135.03125 L 174.332031 136.28125 L 263.800781 127.429688 L 263.863281 126.183594 Z M 174.394531 135.03125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 172.933594 148.210938 L 172.714844 152.613281 L 263.804688 143.601562 L 264.023438 139.199219 Z M 172.933594 148.210938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 173.664062 149.707031 L 173.601562 150.957031 L 263.074219 142.105469 L 263.132812 140.859375 Z M 173.664062 149.707031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 187.757812 179.152344 L 187.09375 192.566406 L 277.273438 183.648438 L 277.9375 170.230469 Z M 187.757812 179.152344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 188.566406 180.628906 L 188.054688 190.902344 L 276.464844 182.15625 L 276.972656 171.882812 Z M 188.566406 180.628906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 187.292969 176.371094 L 187.074219 180.777344 L 278.164062 171.765625 L 278.382812 167.363281 Z M 187.292969 176.371094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 188.023438 177.871094 L 187.960938 179.121094 L 277.433594 170.269531 L 277.496094 169.023438 Z M 188.023438 177.871094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 186.566406 191.050781 L 186.347656 195.453125 L 277.4375 186.441406 L 277.65625 182.039062 Z M 186.566406 191.050781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 187.296875 192.550781 L 187.234375 193.796875 L 276.703125 184.945312 L 276.765625 183.699219 Z M 187.296875 192.550781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 182.304688 227.433594 L 181.640625 240.847656 L 271.816406 231.925781 L 272.484375 218.511719 Z M 182.304688 227.433594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 183.109375 228.910156 L 182.601562 239.183594 L 271.011719 230.4375 L 271.519531 220.164062 Z M 183.109375 228.910156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 181.839844 224.652344 L 181.621094 229.054688 L 272.710938 220.042969 L 272.929688 215.640625 Z M 181.839844 224.652344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 182.570312 226.152344 L 182.507812 227.398438 L 271.976562 218.546875 L 272.039062 217.300781 Z M 182.570312 226.152344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 181.113281 239.328125 L 180.894531 243.730469 L 271.980469 234.722656 L 272.199219 230.316406 Z M 181.113281 239.328125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 181.84375 240.828125 L 181.78125 242.074219 L 271.25 233.226562 L 271.3125 231.976562 Z M 181.84375 240.828125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 193.148438 224.03125 L 193.8125 237.445312 L 103.632812 228.527344 L 102.96875 215.109375 Z M 193.148438 224.03125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 192.339844 225.507812 L 192.851562 235.78125 L 104.441406 227.035156 L 103.929688 216.761719 Z M 192.339844 225.507812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 193.613281 221.25 L 193.832031 225.65625 L 102.742188 216.644531 L 102.523438 212.242188 Z M 193.613281 221.25 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 192.882812 222.75 L 192.945312 224 L 103.472656 215.148438 L 103.410156 213.902344 Z M 192.882812 222.75 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 194.339844 235.929688 L 194.558594 240.332031 L 103.46875 231.320312 L 103.25 226.917969 Z M 194.339844 235.929688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 193.609375 237.429688 L 193.671875 238.675781 L 104.203125 229.824219 L 104.140625 228.578125 Z M 193.609375 237.429688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 186.851562 185.21875 L 182.882812 197.316406 L 86.726562 187.082031 L 90.695312 174.988281 Z M 186.851562 185.21875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 185.445312 186.53125 L 182.40625 195.792969 L 88.136719 185.761719 L 91.175781 176.496094 Z M 185.445312 186.53125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 188.335938 182.726562 L 187.035156 186.699219 L 89.90625 176.363281 L 91.207031 172.390625 Z M 188.335938 182.726562 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 187.007812 184.058594 L 186.636719 185.183594 L 91.234375 175.03125 L 91.605469 173.90625 Z M 187.007812 184.058594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 183.996094 195.960938 L 182.691406 199.933594 L 85.566406 189.597656 L 86.867188 185.625 Z M 183.996094 195.960938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 182.664062 197.292969 L 182.296875 198.417969 L 86.894531 188.265625 L 87.265625 187.140625 Z M 182.664062 197.292969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 175.035156 183.289062 L 165.257812 193.636719 L 94.542969 145.367188 L 104.320312 135.019531 Z M 175.035156 183.289062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 173.203125 184.019531 L 165.714844 191.945312 L 96.386719 144.625 L 103.875 136.699219 Z M 173.203125 184.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 177.582031 181.421875 L 174.371094 184.816406 L 102.945312 136.0625 L 106.152344 132.664062 Z M 177.582031 181.421875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 175.792969 182.207031 L 174.886719 183.167969 L 104.726562 135.28125 L 105.636719 134.316406 Z M 175.792969 182.207031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 166.882812 192.742188 L 163.671875 196.136719 L 92.246094 147.382812 L 95.453125 143.988281 Z M 166.882812 192.742188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 165.097656 193.527344 L 164.1875 194.488281 L 94.027344 146.601562 L 94.9375 145.640625 Z M 165.097656 193.527344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 264.835938 183.46875 C 264.871094 228.222656 228.5 264.542969 183.648438 264.542969 C 138.796875 264.613281 102.367188 228.351562 102.332031 183.601562 C 102.222656 138.847656 138.535156 102.46875 183.386719 102.398438 C 228.242188 102.253906 264.730469 138.460938 264.835938 183.207031 C 264.839844 183.300781 264.839844 183.375 264.839844 183.46875 Z M 283.898438 183.46875 C 283.941406 238.71875 239.035156 283.558594 183.667969 283.558594 C 128.292969 283.644531 83.3125 238.875 83.277344 183.628906 C 83.140625 128.378906 127.976562 83.46875 183.347656 83.382812 C 238.722656 83.207031 283.765625 127.90625 283.898438 183.152344 C 283.898438 183.261719 283.898438 183.359375 283.898438 183.46875 Z M 283.898438 183.46875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 281.890625 183.472656 C 281.933594 237.617188 237.929688 281.558594 183.664062 281.558594 C 129.398438 281.644531 85.316406 237.769531 85.273438 183.628906 C 85.144531 129.480469 129.082031 85.472656 183.351562 85.386719 C 237.617188 85.214844 281.761719 129.019531 281.890625 183.160156 C 281.894531 183.269531 281.894531 183.363281 281.894531 183.472656 Z M 279.925781 183.472656 C 279.964844 236.535156 236.839844 279.597656 183.664062 279.597656 C 130.484375 279.679688 87.289062 236.683594 87.246094 183.621094 C 87.117188 130.558594 130.175781 87.425781 183.359375 87.339844 C 236.539062 87.171875 279.796875 130.097656 279.925781 183.160156 C 279.925781 183.265625 279.925781 183.359375 279.925781 183.464844 Z M 279.925781 183.472656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 268.867188 183.46875 C 268.902344 230.441406 230.726562 268.558594 183.65625 268.558594 C 136.578125 268.632812 98.335938 230.574219 98.300781 183.605469 C 98.1875 136.632812 136.300781 98.453125 183.382812 98.378906 C 230.460938 98.230469 268.753906 136.230469 268.867188 183.199219 C 268.867188 183.292969 268.867188 183.375 268.867188 183.46875 Z M 267.160156 183.46875 C 267.195312 229.5 229.785156 266.859375 183.652344 266.859375 C 137.519531 266.929688 100.046875 229.632812 100.007812 183.597656 C 99.898438 137.566406 137.25 100.148438 183.386719 100.074219 C 229.523438 99.925781 267.050781 137.167969 267.160156 183.199219 C 267.164062 183.292969 267.164062 183.371094 267.164062 183.464844 Z M 267.160156 183.46875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 179.113281 83.136719 L 191.574219 83.136719 L 191.574219 284.421875 L 179.113281 284.421875 Z M 179.113281 83.136719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 180.566406 85.109375 L 190.109375 85.109375 L 190.109375 282.449219 L 180.566406 282.449219 Z M 180.566406 85.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 176.476562 81.785156 L 180.566406 81.785156 L 180.566406 285.105469 L 176.476562 285.105469 Z M 176.476562 81.785156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 177.945312 83.59375 L 179.101562 83.59375 L 179.101562 283.296875 L 177.945312 283.296875 Z M 177.945312 83.59375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 190.109375 81.785156 L 194.195312 81.785156 L 194.195312 285.105469 L 190.109375 285.105469 Z M 190.109375 81.785156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 191.574219 83.59375 L 192.734375 83.59375 L 192.734375 283.296875 L 191.574219 283.296875 Z M 191.574219 83.59375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 180.242188 200.710938 L 184.867188 213.320312 L 96.101562 231.519531 L 91.472656 218.90625 Z M 180.242188 200.710938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 179.910156 202.359375 L 183.453125 212.019531 L 96.429688 229.859375 L 92.882812 220.199219 Z M 179.910156 202.359375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 179.855469 197.917969 L 181.375 202.058594 L 91.710938 220.4375 L 90.195312 216.296875 Z M 179.855469 197.917969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 179.605469 199.566406 L 180.035156 200.738281 L 91.964844 218.792969 L 91.535156 217.621094 Z M 179.605469 199.566406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 184.917969 211.714844 L 186.4375 215.855469 L 96.773438 234.238281 L 95.257812 230.097656 Z M 184.917969 211.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 184.667969 213.363281 L 185.097656 214.539062 L 97.027344 232.589844 L 96.597656 231.417969 Z M 184.667969 213.363281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 175.535156 163.152344 L 176.136719 176.019531 L 83.066406 186.105469 L 82.46875 173.238281 Z M 175.535156 163.152344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 174.695312 164.75 L 175.152344 174.605469 L 83.910156 184.492188 L 83.449219 174.636719 Z M 174.695312 164.75 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 176.035156 160.359375 L 176.230469 164.582031 L 82.222656 174.769531 L 82.027344 170.546875 Z M 176.035156 160.359375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 175.269531 161.964844 L 175.324219 163.160156 L 82.988281 173.167969 L 82.933594 171.972656 Z M 175.269531 161.964844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 176.691406 174.4375 L 176.886719 178.664062 L 82.878906 188.851562 L 82.683594 184.625 Z M 176.691406 174.4375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 175.925781 176.042969 L 175.980469 177.242188 L 83.644531 187.246094 L 83.589844 186.050781 Z M 175.925781 176.042969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 177.820312 160.671875 L 170.105469 172.632812 L 91.726562 138.113281 L 99.4375 126.152344 Z M 177.820312 160.671875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 176.152344 161.730469 L 170.246094 170.886719 L 93.402344 137.042969 L 99.308594 127.886719 Z M 176.152344 161.730469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 179.980469 158.371094 L 177.445312 162.296875 L 98.277344 127.429688 L 100.808594 123.503906 Z M 179.980469 158.371094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 178.367188 159.472656 L 177.648438 160.582031 L 99.886719 126.332031 L 100.601562 125.222656 Z M 178.367188 159.472656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 171.539062 171.457031 L 169.007812 175.382812 L 89.835938 140.515625 L 92.367188 136.589844 Z M 171.539062 171.457031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 169.929688 172.558594 L 169.210938 173.667969 L 91.445312 139.417969 L 92.164062 138.308594 Z M 169.929688 172.558594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 285.195312 164.320312 L 285.027344 177.183594 L 191.492188 172.648438 L 191.660156 159.789062 Z M 285.195312 164.320312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 284.257812 165.777344 L 284.132812 175.625 L 192.429688 171.179688 L 192.558594 161.332031 Z M 284.257812 165.777344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 285.859375 161.628906 L 285.804688 165.851562 L 191.324219 161.269531 L 191.378906 157.050781 Z M 285.859375 161.628906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 285 163.101562 L 284.984375 164.296875 L 192.183594 159.800781 L 192.199219 158.605469 Z M 285 163.101562 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 285.675781 175.699219 L 285.621094 179.921875 L 191.140625 175.34375 L 191.195312 171.121094 Z M 285.675781 175.699219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 284.816406 177.171875 L 284.800781 178.371094 L 192 173.871094 L 192.015625 172.675781 Z M 284.816406 177.171875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 268.339844 127.222656 L 272.007812 138.738281 L 194.777344 136.308594 L 191.113281 124.789062 Z M 268.339844 127.222656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 268.011719 128.539062 L 270.816406 137.363281 L 195.101562 134.976562 L 192.296875 126.15625 Z M 268.011719 128.539062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 268.082031 124.800781 L 269.285156 128.582031 L 191.277344 126.125 L 190.074219 122.34375 Z M 268.082031 124.800781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 267.824219 126.132812 L 268.164062 127.207031 L 191.539062 124.792969 L 191.199219 123.722656 Z M 267.824219 126.132812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 272.09375 137.402344 L 273.296875 141.179688 L 195.285156 138.726562 L 194.085938 134.945312 Z M 272.09375 137.402344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 271.832031 138.734375 L 272.171875 139.804688 L 195.550781 137.394531 L 195.210938 136.324219 Z M 271.832031 138.734375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 278.730469 218.285156 L 273.167969 230.238281 L 187.074219 198.824219 L 192.636719 186.875 Z M 278.730469 218.285156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 277.234375 219.371094 L 272.976562 228.523438 L 188.574219 197.730469 L 192.832031 188.574219 Z M 277.234375 219.371094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 280.484375 215.964844 L 278.660156 219.890625 L 191.695312 188.160156 L 193.523438 184.238281 Z M 280.484375 215.964844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 279.058594 217.089844 L 278.539062 218.203125 L 193.125 187.039062 L 193.640625 185.925781 Z M 279.058594 217.089844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 274.398438 229.042969 L 272.574219 232.964844 L 185.613281 201.238281 L 187.4375 197.3125 Z M 274.398438 229.042969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M 272.972656 230.167969 L 272.453125 231.28125 L 187.039062 200.113281 L 187.554688 199.003906 Z M 272.972656 230.167969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(96.470588%,96.470588%,98.431373%);fill-opacity:1;"
      d="M 125.488281 117.378906 L 241.03125 117.378906 L 241.03125 202.238281 L 125.488281 202.238281 Z M 125.488281 117.378906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(96.470588%,96.470588%,98.431373%);fill-opacity:1;"
      d="M 241.023438 199.863281 C 241.023438 170.238281 215.15625 146.222656 183.253906 146.222656 C 151.347656 146.222656 125.484375 170.238281 125.484375 199.863281 C 125.484375 229.484375 151.347656 253.5 183.253906 253.5 C 215.15625 253.5 241.023438 229.484375 241.023438 199.863281 Z M 241.023438 199.863281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(96.470588%,96.470588%,98.431373%);fill-opacity:1;"
      d="M 152.0625 142.210938 L 214.457031 142.210938 L 214.457031 188.035156 L 152.0625 188.035156 Z M 152.0625 142.210938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(96.470588%,96.470588%,98.431373%);fill-opacity:1;"
      d="M 214.457031 193.144531 C 214.457031 177.148438 200.488281 164.179688 183.261719 164.179688 C 166.03125 164.179688 152.066406 177.148438 152.066406 193.144531 C 152.066406 209.140625 166.03125 222.109375 183.261719 222.109375 C 200.488281 222.109375 214.457031 209.140625 214.457031 193.144531 Z M 214.457031 193.144531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 128.371094 120.78125 L 238.132812 120.78125 L 238.132812 201.398438 L 128.371094 201.398438 Z M 128.371094 120.78125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 238.125 199.140625 C 238.125 170.996094 213.554688 148.183594 183.242188 148.183594 C 152.933594 148.183594 128.363281 170.996094 128.363281 199.140625 C 128.363281 227.28125 152.933594 250.097656 183.242188 250.097656 C 213.554688 250.097656 238.125 227.28125 238.125 199.140625 Z M 238.125 199.140625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 153.613281 144.371094 L 212.886719 144.371094 L 212.886719 187.90625 L 153.613281 187.90625 Z M 153.613281 144.371094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 212.886719 192.757812 C 212.886719 177.558594 199.621094 165.242188 183.25 165.242188 C 166.882812 165.242188 153.617188 177.558594 153.617188 192.757812 C 153.617188 207.953125 166.882812 220.273438 183.25 220.273438 C 199.621094 220.273438 212.886719 207.953125 212.886719 192.757812 Z M 212.886719 192.757812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(26.666667%,0%,0%);fill-opacity:1;"
      d="M 150.496094 146.835938 L 216.007812 146.835938 L 216.007812 194.953125 L 150.496094 194.953125 Z M 150.496094 146.835938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(26.666667%,0%,0%);fill-opacity:1;"
      d="M 216.003906 193.605469 C 216.003906 176.808594 201.339844 163.191406 183.25 163.191406 C 165.160156 163.191406 150.496094 176.808594 150.496094 193.605469 C 150.496094 210.402344 165.160156 224.019531 183.25 224.019531 C 201.339844 224.019531 216.003906 210.402344 216.003906 193.605469 Z M 216.003906 193.605469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(26.666667%,0%,0%);fill-opacity:1;"
      d="M 165.566406 160.917969 L 200.941406 160.917969 L 200.941406 186.902344 L 165.566406 186.902344 Z M 165.566406 160.917969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(26.666667%,0%,0%);fill-opacity:1;"
      d="M 200.941406 189.796875 C 200.941406 180.726562 193.023438 173.375 183.253906 173.375 C 173.484375 173.375 165.566406 180.726562 165.566406 189.796875 C 165.566406 198.867188 173.484375 206.21875 183.253906 206.21875 C 193.023438 206.21875 200.941406 198.867188 200.941406 189.796875 Z M 200.941406 189.796875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 153.6875 150.597656 L 212.8125 150.597656 L 212.8125 194.023438 L 153.6875 194.023438 Z M 153.6875 150.597656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 212.808594 192.804688 C 212.808594 177.648438 199.574219 165.359375 183.246094 165.359375 C 166.921875 165.359375 153.6875 177.648438 153.6875 192.804688 C 153.6875 207.964844 166.921875 220.253906 183.246094 220.253906 C 199.574219 220.253906 212.808594 207.964844 212.808594 192.804688 Z M 212.808594 192.804688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 167.285156 163.304688 L 199.214844 163.304688 L 199.214844 186.753906 L 167.285156 186.753906 Z M 167.285156 163.304688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 199.214844 189.367188 C 199.214844 181.183594 192.066406 174.546875 183.25 174.546875 C 174.433594 174.546875 167.289062 181.183594 167.289062 189.367188 C 167.289062 197.554688 174.433594 204.191406 183.25 204.191406 C 192.066406 204.191406 199.214844 197.554688 199.214844 189.367188 Z M 199.214844 189.367188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 175.042969 197.019531 L 192.375 197.019531 L 192.375 209.75 L 175.042969 209.75 Z M 175.042969 197.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 192.371094 209.394531 C 192.371094 204.949219 188.492188 201.347656 183.707031 201.347656 C 178.921875 201.347656 175.042969 204.949219 175.042969 209.394531 C 175.042969 213.835938 178.921875 217.441406 183.707031 217.441406 C 188.492188 217.441406 192.371094 213.835938 192.371094 209.394531 Z M 192.371094 209.394531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 179.027344 200.746094 L 188.386719 200.746094 L 188.386719 207.621094 L 179.027344 207.621094 Z M 179.027344 200.746094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 188.386719 208.386719 C 188.386719 205.988281 186.292969 204.042969 183.707031 204.042969 C 181.125 204.042969 179.027344 205.988281 179.027344 208.386719 C 179.027344 210.785156 181.125 212.730469 183.707031 212.730469 C 186.292969 212.730469 188.386719 210.785156 188.386719 208.386719 Z M 188.386719 208.386719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 175.472656 197.53125 L 191.9375 197.53125 L 191.9375 209.625 L 175.472656 209.625 Z M 175.472656 197.53125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 191.9375 209.285156 C 191.9375 205.0625 188.25 201.640625 183.707031 201.640625 C 179.160156 201.640625 175.472656 205.0625 175.472656 209.285156 C 175.472656 213.507812 179.160156 216.929688 183.707031 216.929688 C 188.25 216.929688 191.9375 213.507812 191.9375 209.285156 Z M 191.9375 209.285156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 179.261719 201.070312 L 188.152344 201.070312 L 188.152344 207.601562 L 179.261719 207.601562 Z M 179.261719 201.070312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 188.152344 208.328125 C 188.152344 206.046875 186.160156 204.199219 183.707031 204.199219 C 181.25 204.199219 179.261719 206.046875 179.261719 208.328125 C 179.261719 210.609375 181.25 212.457031 183.707031 212.457031 C 186.160156 212.457031 188.152344 210.609375 188.152344 208.328125 Z M 188.152344 208.328125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 178.792969 201.441406 L 188.621094 201.441406 L 188.621094 208.65625 L 178.792969 208.65625 Z M 178.792969 201.441406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 188.621094 208.457031 C 188.621094 205.9375 186.417969 203.894531 183.707031 203.894531 C 180.992188 203.894531 178.792969 205.9375 178.792969 208.457031 C 178.792969 210.976562 180.992188 213.019531 183.707031 213.019531 C 186.417969 213.019531 188.621094 210.976562 188.621094 208.457031 Z M 188.621094 208.457031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 181.054688 203.550781 L 186.359375 203.550781 L 186.359375 207.449219 L 181.054688 207.449219 Z M 181.054688 203.550781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 186.359375 207.882812 C 186.359375 206.523438 185.171875 205.421875 183.707031 205.421875 C 182.242188 205.421875 181.054688 206.523438 181.054688 207.882812 C 181.054688 209.246094 182.242188 210.347656 183.707031 210.347656 C 185.171875 210.347656 186.359375 209.246094 186.359375 207.882812 Z M 186.359375 207.882812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 179.273438 202.003906 L 188.140625 202.003906 L 188.140625 208.519531 L 179.273438 208.519531 Z M 179.273438 202.003906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 188.140625 208.335938 C 188.140625 206.0625 186.15625 204.21875 183.707031 204.21875 C 181.257812 204.21875 179.273438 206.0625 179.273438 208.335938 C 179.273438 210.609375 181.257812 212.453125 183.707031 212.453125 C 186.15625 212.453125 188.140625 210.609375 188.140625 208.335938 Z M 188.140625 208.335938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 181.3125 203.910156 L 186.101562 203.910156 L 186.101562 207.429688 L 181.3125 207.429688 Z M 181.3125 203.910156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 186.101562 207.820312 C 186.101562 206.59375 185.027344 205.597656 183.707031 205.597656 C 182.382812 205.597656 181.3125 206.59375 181.3125 207.820312 C 181.3125 209.046875 182.382812 210.042969 183.707031 210.042969 C 185.027344 210.042969 186.101562 209.046875 186.101562 207.820312 Z M 186.101562 207.820312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 181.09375 200.625 C 181.09375 199.195312 179.933594 198.039062 178.5 198.039062 C 177.070312 198.039062 175.910156 199.195312 175.910156 200.625 C 175.910156 202.054688 177.070312 203.210938 178.5 203.210938 C 179.933594 203.210938 181.09375 202.054688 181.09375 200.625 Z M 181.09375 200.625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 190.808594 200.625 C 190.808594 199.195312 189.648438 198.039062 188.214844 198.039062 C 186.785156 198.039062 185.625 199.195312 185.625 200.625 C 185.625 202.054688 186.785156 203.210938 188.214844 203.210938 C 189.648438 203.210938 190.808594 202.054688 190.808594 200.625 Z M 190.808594 200.625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 185.863281 206.070312 C 185.863281 204.644531 184.703125 203.484375 183.273438 203.484375 C 181.839844 203.484375 180.679688 204.644531 180.679688 206.070312 C 180.679688 207.5 181.839844 208.65625 183.273438 208.65625 C 184.703125 208.65625 185.863281 207.5 185.863281 206.070312 Z M 185.863281 206.070312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 181.46875 211.347656 C 181.46875 209.917969 180.308594 208.761719 178.878906 208.761719 C 177.445312 208.761719 176.285156 209.917969 176.285156 211.347656 C 176.285156 212.773438 177.445312 213.933594 178.878906 213.933594 C 180.308594 213.933594 181.46875 212.773438 181.46875 211.347656 Z M 181.46875 211.347656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 190.703125 211.515625 C 190.703125 210.089844 189.542969 208.929688 188.113281 208.929688 C 186.679688 208.929688 185.519531 210.089844 185.519531 211.515625 C 185.519531 212.945312 186.679688 214.101562 188.113281 214.101562 C 189.542969 214.101562 190.703125 212.945312 190.703125 211.515625 Z M 190.703125 211.515625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 130.101562 132.625 L 130.101562 134.269531 L 152.070312 134.269531 L 152.070312 132.625 Z M 132.007812 128.089844 L 132.007812 132.664062 L 150.683594 132.664062 L 150.683594 128.089844 Z M 130.359375 143.132812 L 130.359375 144.777344 L 152.332031 144.777344 L 152.332031 143.132812 Z M 136.246094 142.625 C 136.246094 139.640625 138.542969 137.21875 141.378906 137.21875 C 144.214844 137.214844 146.515625 139.632812 146.519531 142.617188 C 146.519531 142.640625 146.519531 142.660156 146.519531 142.683594 M 135.675781 142.921875 C 135.675781 139.605469 138.226562 136.914062 141.378906 136.914062 C 144.527344 136.910156 147.085938 139.597656 147.089844 142.914062 C 147.089844 142.9375 147.089844 142.960938 147.089844 142.988281 M 132.007812 134.71875 L 132.007812 142.941406 L 150.683594 142.941406 L 150.683594 134.71875 Z M 130.101562 126.519531 L 130.101562 128.074219 L 152.070312 128.074219 L 152.070312 126.519531 Z M 130.097656 124.328125 L 130.097656 126.535156 L 131.792969 126.535156 L 131.792969 124.328125 Z M 150.386719 124.328125 L 150.386719 126.535156 L 152.078125 126.535156 L 152.078125 124.328125 Z M 133.472656 124.371094 L 133.472656 126.582031 L 135.164062 126.582031 L 135.164062 124.371094 Z M 146.925781 124.328125 L 146.925781 126.535156 L 148.617188 126.535156 L 148.617188 124.328125 Z M 136.757812 122.246094 L 136.757812 124.453125 L 138.449219 124.453125 L 138.449219 122.246094 Z M 143.503906 122.246094 L 143.503906 124.453125 L 145.199219 124.453125 L 145.199219 122.246094 Z M 140.21875 122.199219 L 140.21875 124.410156 L 141.914062 124.410156 L 141.914062 122.199219 Z M 136.753906 124.46875 L 136.753906 126.582031 L 145.1875 126.582031 L 145.1875 124.46875 Z M 136.753906 124.46875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 136.683594 127.777344 L 138.375 127.777344 L 138.375 129.988281 L 136.683594 129.988281 Z M 136.683594 127.777344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 144.277344 127.734375 L 145.96875 127.734375 L 145.96875 129.941406 L 144.277344 129.941406 Z M 144.277344 127.734375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 214.433594 132.886719 L 214.433594 134.53125 L 236.40625 134.53125 L 236.40625 132.886719 Z M 216.339844 128.351562 L 216.339844 132.929688 L 235.015625 132.929688 L 235.015625 128.351562 Z M 214.691406 143.398438 L 214.691406 145.042969 L 236.664062 145.042969 L 236.664062 143.398438 Z M 220.582031 142.886719 C 220.578125 139.902344 222.878906 137.480469 225.710938 137.480469 C 228.546875 137.476562 230.847656 139.894531 230.851562 142.878906 C 230.851562 142.902344 230.851562 142.921875 230.851562 142.945312 M 220.007812 143.1875 C 220.007812 139.871094 222.5625 137.175781 225.710938 137.175781 C 228.859375 137.171875 231.417969 139.859375 231.421875 143.175781 C 231.421875 143.203125 231.421875 143.222656 231.421875 143.25 M 216.339844 134.984375 L 216.339844 143.203125 L 235.015625 143.203125 L 235.015625 134.984375 Z M 214.433594 126.785156 L 214.433594 128.339844 L 236.402344 128.339844 L 236.402344 126.785156 Z M 214.429688 124.589844 L 214.429688 126.800781 L 216.125 126.800781 L 216.125 124.589844 Z M 234.71875 124.589844 L 234.71875 126.800781 L 236.414062 126.800781 L 236.414062 124.589844 Z M 217.804688 124.636719 L 217.804688 126.84375 L 219.5 126.84375 L 219.5 124.636719 Z M 231.257812 124.589844 L 231.257812 126.800781 L 232.949219 126.800781 L 232.949219 124.589844 Z M 221.089844 122.507812 L 221.089844 124.71875 L 222.78125 124.71875 L 222.78125 122.507812 Z M 227.839844 122.507812 L 227.839844 124.71875 L 229.53125 124.71875 L 229.53125 122.507812 Z M 224.554688 122.464844 L 224.554688 124.671875 L 226.246094 124.671875 L 226.246094 122.464844 Z M 221.085938 124.734375 L 221.085938 126.84375 L 229.519531 126.84375 L 229.519531 124.734375 Z M 221.085938 124.734375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 221.007812 128.042969 L 222.703125 128.042969 L 222.703125 130.25 L 221.007812 130.25 Z M 221.007812 128.042969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 228.601562 127.996094 L 230.292969 127.996094 L 230.292969 130.207031 L 228.601562 130.207031 Z M 228.601562 127.996094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 173.058594 132.886719 L 173.058594 134.53125 L 195.03125 134.53125 L 195.03125 132.886719 Z M 174.964844 128.351562 L 174.964844 132.929688 L 193.640625 132.929688 L 193.640625 128.351562 Z M 173.316406 143.398438 L 173.316406 145.042969 L 195.289062 145.042969 L 195.289062 143.398438 Z M 179.207031 142.886719 C 179.203125 139.902344 181.503906 137.480469 184.335938 137.480469 C 187.171875 137.476562 189.472656 139.894531 189.476562 142.878906 C 189.476562 142.902344 189.476562 142.921875 189.476562 142.945312 M 178.632812 143.1875 C 178.632812 139.871094 181.1875 137.175781 184.335938 137.175781 C 187.484375 137.171875 190.042969 139.859375 190.046875 143.175781 C 190.046875 143.203125 190.046875 143.222656 190.046875 143.25 M 174.964844 134.984375 L 174.964844 143.203125 L 193.640625 143.203125 L 193.640625 134.984375 Z M 173.058594 126.785156 L 173.058594 128.339844 L 195.027344 128.339844 L 195.027344 126.785156 Z M 173.054688 124.589844 L 173.054688 126.800781 L 174.75 126.800781 L 174.75 124.589844 Z M 193.34375 124.589844 L 193.34375 126.800781 L 195.039062 126.800781 L 195.039062 124.589844 Z M 176.429688 124.636719 L 176.429688 126.84375 L 178.125 126.84375 L 178.125 124.636719 Z M 189.882812 124.589844 L 189.882812 126.800781 L 191.574219 126.800781 L 191.574219 124.589844 Z M 179.714844 122.507812 L 179.714844 124.71875 L 181.410156 124.71875 L 181.410156 122.507812 Z M 186.464844 122.507812 L 186.464844 124.71875 L 188.15625 124.71875 L 188.15625 122.507812 Z M 183.179688 122.464844 L 183.179688 124.671875 L 184.871094 124.671875 L 184.871094 122.464844 Z M 179.710938 124.734375 L 179.710938 126.84375 L 188.144531 126.84375 L 188.144531 124.734375 Z M 179.710938 124.734375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 179.632812 128.042969 L 181.328125 128.042969 L 181.328125 130.25 L 179.632812 130.25 Z M 179.632812 128.042969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 187.226562 127.996094 L 188.917969 127.996094 L 188.917969 130.207031 L 187.226562 130.207031 Z M 187.226562 127.996094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 128.257812 184.160156 L 128.257812 185.804688 L 150.226562 185.804688 L 150.226562 184.160156 Z M 130.164062 179.625 L 130.164062 184.199219 L 148.835938 184.199219 L 148.835938 179.625 Z M 128.515625 194.667969 L 128.515625 196.3125 L 150.484375 196.3125 L 150.484375 194.667969 Z M 134.402344 194.160156 C 134.402344 191.175781 136.699219 188.753906 139.535156 188.753906 C 142.367188 188.75 144.671875 191.167969 144.671875 194.152344 C 144.671875 194.175781 144.671875 194.195312 144.671875 194.21875 M 133.832031 194.457031 C 133.828125 191.140625 136.382812 188.449219 139.53125 188.449219 C 142.683594 188.445312 145.242188 191.132812 145.242188 194.449219 C 145.242188 194.476562 145.242188 194.496094 145.242188 194.523438 M 130.164062 186.257812 L 130.164062 194.476562 L 148.835938 194.476562 L 148.835938 186.257812 Z M 128.253906 178.058594 L 128.253906 179.613281 L 150.226562 179.613281 L 150.226562 178.058594 Z M 128.253906 175.863281 L 128.253906 178.074219 L 129.945312 178.074219 L 129.945312 175.863281 Z M 148.542969 175.863281 L 148.542969 178.074219 L 150.234375 178.074219 L 150.234375 175.863281 Z M 131.628906 175.90625 L 131.628906 178.117188 L 133.320312 178.117188 L 133.320312 175.90625 Z M 145.078125 175.863281 L 145.078125 178.070312 L 146.773438 178.070312 L 146.773438 175.863281 Z M 134.914062 173.78125 L 134.914062 175.992188 L 136.605469 175.992188 L 136.605469 173.78125 Z M 141.660156 173.78125 L 141.660156 175.988281 L 143.355469 175.988281 L 143.355469 173.78125 Z M 138.375 173.738281 L 138.375 175.945312 L 140.066406 175.945312 L 140.066406 173.738281 Z M 134.90625 176.007812 L 134.90625 178.117188 L 143.34375 178.117188 L 143.34375 176.007812 Z M 134.90625 176.007812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 134.839844 179.316406 L 136.53125 179.316406 L 136.53125 181.527344 L 134.839844 181.527344 Z M 134.839844 179.316406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 142.429688 179.273438 L 144.125 179.273438 L 144.125 181.480469 L 142.429688 181.480469 Z M 142.429688 179.273438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 216.015625 183.640625 L 216.015625 185.285156 L 237.988281 185.285156 L 237.988281 183.640625 Z M 217.921875 179.101562 L 217.921875 183.679688 L 236.597656 183.679688 L 236.597656 179.101562 Z M 216.277344 194.148438 L 216.277344 195.792969 L 238.246094 195.792969 L 238.246094 194.148438 Z M 222.164062 193.640625 C 222.160156 190.65625 224.460938 188.230469 227.296875 188.230469 C 230.128906 188.226562 232.433594 190.644531 232.433594 193.632812 C 232.433594 193.65625 232.433594 193.675781 232.433594 193.699219 M 221.59375 193.9375 C 221.589844 190.621094 224.144531 187.929688 227.292969 187.929688 C 230.445312 187.921875 233.003906 190.609375 233.003906 193.925781 C 233.003906 193.953125 233.003906 193.976562 233.003906 194 M 217.921875 185.734375 L 217.921875 193.953125 L 236.597656 193.953125 L 236.597656 185.734375 Z M 216.015625 177.535156 L 216.015625 179.089844 L 237.988281 179.089844 L 237.988281 177.535156 Z M 216.015625 175.339844 L 216.015625 177.550781 L 217.707031 177.550781 L 217.707031 175.339844 Z M 236.304688 175.339844 L 236.304688 177.550781 L 237.996094 177.550781 L 237.996094 175.339844 Z M 219.390625 175.386719 L 219.390625 177.59375 L 221.082031 177.59375 L 221.082031 175.386719 Z M 232.839844 175.339844 L 232.839844 177.550781 L 234.535156 177.550781 L 234.535156 175.339844 Z M 222.671875 173.257812 L 222.671875 175.46875 L 224.367188 175.46875 L 224.367188 173.257812 Z M 229.421875 173.257812 L 229.421875 175.46875 L 231.113281 175.46875 L 231.113281 173.257812 Z M 226.136719 173.214844 L 226.136719 175.425781 L 227.828125 175.425781 L 227.828125 173.214844 Z M 222.667969 175.484375 L 222.667969 177.597656 L 231.105469 177.597656 L 231.105469 175.484375 Z M 222.667969 175.484375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 222.59375 178.789062 L 224.285156 178.789062 L 224.285156 180.996094 L 222.59375 180.996094 Z M 222.59375 178.789062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 230.183594 178.742188 L 231.878906 178.742188 L 231.878906 180.953125 L 230.183594 180.953125 Z M 230.183594 178.742188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 147.273438 233.882812 L 148.519531 234.957031 L 162.863281 218.347656 L 161.613281 217.273438 Z M 145.074219 229.480469 L 148.546875 232.46875 L 160.738281 218.351562 L 157.261719 215.363281 Z M 155.421875 240.546875 L 156.667969 241.621094 L 171.011719 225.011719 L 169.761719 223.9375 Z M 158.878906 235.765625 C 156.609375 233.816406 156.269531 230.5 158.121094 228.355469 C 159.96875 226.210938 163.308594 226.046875 165.574219 227.996094 C 165.59375 228.011719 165.609375 228.023438 165.625 228.039062 M 158.730469 236.390625 C 156.210938 234.226562 155.835938 230.539062 157.890625 228.15625 C 159.941406 225.773438 163.652344 225.59375 166.171875 227.757812 C 166.191406 227.773438 166.210938 227.789062 166.230469 227.804688 M 150.109375 233.808594 L 156.347656 239.175781 L 168.539062 225.058594 L 162.296875 219.691406 Z M 142.636719 229.898438 L 143.820312 230.914062 L 158.160156 214.304688 L 156.980469 213.289062 Z M 140.972656 228.46875 L 142.648438 229.910156 L 143.753906 228.628906 L 142.074219 227.1875 Z M 154.214844 213.132812 L 155.890625 214.574219 L 156.996094 213.292969 L 155.320312 211.851562 Z M 143.207031 225.945312 L 144.882812 227.386719 L 145.988281 226.109375 L 144.3125 224.667969 Z M 151.953125 215.75 L 153.632812 217.191406 L 154.734375 215.910156 L 153.058594 214.46875 Z M 143.738281 222.074219 L 145.414062 223.515625 L 146.519531 222.238281 L 144.84375 220.796875 Z M 148.140625 216.972656 L 149.820312 218.417969 L 150.925781 217.136719 L 149.246094 215.695312 Z M 145.964844 219.429688 L 147.640625 220.871094 L 148.746094 219.589844 L 147.070312 218.148438 Z M 145.421875 223.53125 L 147.027344 224.910156 L 152.53125 218.535156 L 150.929688 217.15625 Z M 145.421875 223.53125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 147.886719 225.746094 L 148.992188 224.46875 L 150.667969 225.910156 L 149.5625 227.1875 Z M 147.886719 225.746094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 152.808594 219.980469 L 153.914062 218.699219 L 155.589844 220.140625 L 154.484375 221.421875 Z M 152.808594 219.980469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 220.050781 233.621094 L 218.800781 234.695312 L 204.460938 218.085938 L 205.707031 217.015625 Z M 222.25 229.21875 L 218.773438 232.207031 L 206.585938 218.089844 L 210.058594 215.101562 Z M 211.902344 240.285156 L 210.652344 241.359375 L 196.3125 224.75 L 197.5625 223.679688 Z M 208.445312 235.503906 C 210.714844 233.554688 211.050781 230.238281 209.203125 228.09375 C 207.355469 225.949219 204.015625 225.789062 201.746094 227.734375 C 201.730469 227.75 201.714844 227.761719 201.695312 227.777344 M 208.59375 236.128906 C 211.113281 233.964844 211.488281 230.277344 209.433594 227.898438 C 207.378906 225.511719 203.671875 225.332031 201.148438 227.496094 C 201.128906 227.511719 201.113281 227.527344 201.09375 227.546875 M 217.214844 233.546875 L 210.972656 238.914062 L 198.785156 224.796875 L 205.023438 219.433594 Z M 224.683594 229.636719 L 223.503906 230.652344 L 209.164062 214.046875 L 210.34375 213.03125 Z M 226.351562 228.207031 L 224.675781 229.648438 L 223.570312 228.371094 L 225.246094 226.925781 Z M 213.109375 212.871094 L 211.429688 214.3125 L 210.324219 213.035156 L 212.003906 211.589844 Z M 224.117188 225.6875 L 222.4375 227.128906 L 221.332031 225.847656 L 223.011719 224.40625 Z M 215.367188 215.488281 L 213.691406 216.929688 L 212.585938 215.652344 L 214.265625 214.207031 Z M 223.585938 221.816406 L 221.910156 223.257812 L 220.804688 221.976562 L 222.480469 220.535156 Z M 219.179688 216.714844 L 217.503906 218.15625 L 216.398438 216.875 L 218.078125 215.433594 Z M 221.359375 219.167969 L 219.683594 220.609375 L 218.578125 219.332031 L 220.253906 217.886719 Z M 221.898438 223.269531 L 220.296875 224.648438 L 214.789062 218.273438 L 216.394531 216.894531 Z M 221.898438 223.269531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 219.4375 225.484375 L 218.332031 224.207031 L 216.652344 225.648438 L 217.757812 226.929688 Z M 219.4375 225.484375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 214.515625 219.71875 L 213.410156 218.4375 L 211.734375 219.878906 L 212.835938 221.160156 Z M 214.515625 219.71875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 174.765625 151.808594 L 192.097656 151.808594 L 192.097656 164.535156 L 174.765625 164.535156 Z M 174.765625 151.808594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 192.09375 164.179688 C 192.09375 159.734375 188.214844 156.132812 183.429688 156.132812 C 178.644531 156.132812 174.765625 159.734375 174.765625 164.179688 C 174.765625 168.625 178.644531 172.226562 183.429688 172.226562 C 188.214844 172.226562 192.09375 168.625 192.09375 164.179688 Z M 192.09375 164.179688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 178.75 155.53125 L 188.109375 155.53125 L 188.109375 162.40625 L 178.75 162.40625 Z M 178.75 155.53125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 188.109375 163.171875 C 188.109375 160.773438 186.015625 158.828125 183.429688 158.828125 C 180.847656 158.828125 178.75 160.773438 178.75 163.171875 C 178.75 165.570312 180.847656 167.515625 183.429688 167.515625 C 186.015625 167.515625 188.109375 165.570312 188.109375 163.171875 Z M 188.109375 163.171875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 175.195312 152.316406 L 191.660156 152.316406 L 191.660156 164.410156 L 175.195312 164.410156 Z M 175.195312 152.316406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 191.660156 164.070312 C 191.660156 159.851562 187.972656 156.425781 183.429688 156.425781 C 178.882812 156.425781 175.195312 159.851562 175.195312 164.070312 C 175.195312 168.292969 178.882812 171.714844 183.429688 171.714844 C 187.972656 171.714844 191.660156 168.292969 191.660156 164.070312 Z M 191.660156 164.070312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 178.984375 155.855469 L 187.875 155.855469 L 187.875 162.386719 L 178.984375 162.386719 Z M 178.984375 155.855469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 187.875 163.113281 C 187.875 160.835938 185.882812 158.988281 183.429688 158.988281 C 180.972656 158.988281 178.984375 160.835938 178.984375 163.113281 C 178.984375 165.394531 180.972656 167.242188 183.429688 167.242188 C 185.882812 167.242188 187.875 165.394531 187.875 163.113281 Z M 187.875 163.113281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 178.515625 156.226562 L 188.34375 156.226562 L 188.34375 163.445312 L 178.515625 163.445312 Z M 178.515625 156.226562 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 188.34375 163.242188 C 188.34375 160.722656 186.140625 158.679688 183.429688 158.679688 C 180.714844 158.679688 178.515625 160.722656 178.515625 163.242188 C 178.515625 165.761719 180.714844 167.804688 183.429688 167.804688 C 186.140625 167.804688 188.34375 165.761719 188.34375 163.242188 Z M 188.34375 163.242188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 180.777344 158.339844 L 186.082031 158.339844 L 186.082031 162.234375 L 180.777344 162.234375 Z M 180.777344 158.339844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 186.082031 162.667969 C 186.082031 161.308594 184.894531 160.207031 183.429688 160.207031 C 181.964844 160.207031 180.777344 161.308594 180.777344 162.667969 C 180.777344 164.03125 181.964844 165.132812 183.429688 165.132812 C 184.894531 165.132812 186.082031 164.03125 186.082031 162.667969 Z M 186.082031 162.667969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 178.996094 156.789062 L 187.863281 156.789062 L 187.863281 163.304688 L 178.996094 163.304688 Z M 178.996094 156.789062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 187.863281 163.121094 C 187.863281 160.847656 185.878906 159.003906 183.429688 159.003906 C 180.980469 159.003906 178.996094 160.847656 178.996094 163.121094 C 178.996094 165.394531 180.980469 167.238281 183.429688 167.238281 C 185.878906 167.238281 187.863281 165.394531 187.863281 163.121094 Z M 187.863281 163.121094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 181.035156 158.695312 L 185.824219 158.695312 L 185.824219 162.214844 L 181.035156 162.214844 Z M 181.035156 158.695312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 185.824219 162.605469 C 185.824219 161.378906 184.75 160.382812 183.429688 160.382812 C 182.105469 160.382812 181.035156 161.378906 181.035156 162.605469 C 181.035156 163.832031 182.105469 164.828125 183.429688 164.828125 C 184.75 164.828125 185.824219 163.832031 185.824219 162.605469 Z M 185.824219 162.605469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 180.816406 155.410156 C 180.816406 153.980469 179.65625 152.824219 178.222656 152.824219 C 176.792969 152.824219 175.632812 153.980469 175.632812 155.410156 C 175.632812 156.839844 176.792969 157.996094 178.222656 157.996094 C 179.65625 157.996094 180.816406 156.839844 180.816406 155.410156 Z M 180.816406 155.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 190.53125 155.410156 C 190.53125 153.980469 189.371094 152.824219 187.9375 152.824219 C 186.507812 152.824219 185.347656 153.980469 185.347656 155.410156 C 185.347656 156.839844 186.507812 157.996094 187.9375 157.996094 C 189.371094 157.996094 190.53125 156.839844 190.53125 155.410156 Z M 190.53125 155.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 185.585938 160.855469 C 185.585938 159.429688 184.425781 158.269531 182.996094 158.269531 C 181.5625 158.269531 180.402344 159.429688 180.402344 160.855469 C 180.402344 162.285156 181.5625 163.441406 182.996094 163.441406 C 184.425781 163.441406 185.585938 162.285156 185.585938 160.855469 Z M 185.585938 160.855469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 181.191406 166.132812 C 181.191406 164.703125 180.03125 163.546875 178.601562 163.546875 C 177.167969 163.546875 176.007812 164.703125 176.007812 166.132812 C 176.007812 167.558594 177.167969 168.71875 178.601562 168.71875 C 180.03125 168.71875 181.191406 167.558594 181.191406 166.132812 Z M 181.191406 166.132812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 190.425781 166.300781 C 190.425781 164.875 189.265625 163.714844 187.835938 163.714844 C 186.402344 163.714844 185.242188 164.875 185.242188 166.300781 C 185.242188 167.730469 186.402344 168.890625 187.835938 168.890625 C 189.265625 168.890625 190.425781 167.730469 190.425781 166.300781 Z M 190.425781 166.300781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 174.765625 174.277344 L 192.097656 174.277344 L 192.097656 187.007812 L 174.765625 187.007812 Z M 174.765625 174.277344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 192.09375 186.648438 C 192.09375 182.207031 188.214844 178.605469 183.429688 178.605469 C 178.644531 178.605469 174.765625 182.207031 174.765625 186.648438 C 174.765625 191.09375 178.644531 194.695312 183.429688 194.695312 C 188.214844 194.695312 192.09375 191.09375 192.09375 186.648438 Z M 192.09375 186.648438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 178.75 178.003906 L 188.109375 178.003906 L 188.109375 184.875 L 178.75 184.875 Z M 178.75 178.003906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 188.109375 185.640625 C 188.109375 183.242188 186.015625 181.296875 183.429688 181.296875 C 180.847656 181.296875 178.75 183.242188 178.75 185.640625 C 178.75 188.042969 180.847656 189.988281 183.429688 189.988281 C 186.015625 189.988281 188.109375 188.042969 188.109375 185.640625 Z M 188.109375 185.640625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 175.195312 174.789062 L 191.660156 174.789062 L 191.660156 186.878906 L 175.195312 186.878906 Z M 175.195312 174.789062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 191.660156 186.542969 C 191.660156 182.320312 187.972656 178.898438 183.429688 178.898438 C 178.882812 178.898438 175.195312 182.320312 175.195312 186.542969 C 175.195312 190.761719 178.882812 194.183594 183.429688 194.183594 C 187.972656 194.183594 191.660156 190.761719 191.660156 186.542969 Z M 191.660156 186.542969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 178.984375 178.324219 L 187.875 178.324219 L 187.875 184.855469 L 178.984375 184.855469 Z M 178.984375 178.324219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 187.875 185.582031 C 187.875 183.304688 185.882812 181.457031 183.429688 181.457031 C 180.972656 181.457031 178.984375 183.304688 178.984375 185.582031 C 178.984375 187.863281 180.972656 189.710938 183.429688 189.710938 C 185.882812 189.710938 187.875 187.863281 187.875 185.582031 Z M 187.875 185.582031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 178.515625 178.695312 L 188.34375 178.695312 L 188.34375 185.914062 L 178.515625 185.914062 Z M 178.515625 178.695312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 188.34375 185.710938 C 188.34375 183.191406 186.140625 181.148438 183.429688 181.148438 C 180.714844 181.148438 178.515625 183.191406 178.515625 185.710938 C 178.515625 188.230469 180.714844 190.273438 183.429688 190.273438 C 186.140625 190.273438 188.34375 188.230469 188.34375 185.710938 Z M 188.34375 185.710938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 180.777344 180.808594 L 186.082031 180.808594 L 186.082031 184.707031 L 180.777344 184.707031 Z M 180.777344 180.808594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 186.082031 185.140625 C 186.082031 183.78125 184.894531 182.675781 183.429688 182.675781 C 181.964844 182.675781 180.777344 183.78125 180.777344 185.140625 C 180.777344 186.5 181.964844 187.601562 183.429688 187.601562 C 184.894531 187.601562 186.082031 186.5 186.082031 185.140625 Z M 186.082031 185.140625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 178.996094 179.261719 L 187.863281 179.261719 L 187.863281 185.773438 L 178.996094 185.773438 Z M 178.996094 179.261719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 187.863281 185.589844 C 187.863281 183.316406 185.878906 181.472656 183.429688 181.472656 C 180.980469 181.472656 178.996094 183.316406 178.996094 185.589844 C 178.996094 187.863281 180.980469 189.707031 183.429688 189.707031 C 185.878906 189.707031 187.863281 187.863281 187.863281 185.589844 Z M 187.863281 185.589844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 181.035156 181.167969 L 185.824219 181.167969 L 185.824219 184.683594 L 181.035156 184.683594 Z M 181.035156 181.167969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 185.824219 185.074219 C 185.824219 183.847656 184.75 182.851562 183.429688 182.851562 C 182.105469 182.851562 181.035156 183.847656 181.035156 185.074219 C 181.035156 186.304688 182.105469 187.300781 183.429688 187.300781 C 184.75 187.300781 185.824219 186.304688 185.824219 185.074219 Z M 185.824219 185.074219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 180.816406 177.878906 C 180.816406 176.453125 179.65625 175.292969 178.222656 175.292969 C 176.792969 175.292969 175.632812 176.453125 175.632812 177.878906 C 175.632812 179.308594 176.792969 180.464844 178.222656 180.464844 C 179.65625 180.464844 180.816406 179.308594 180.816406 177.878906 Z M 180.816406 177.878906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 190.53125 177.878906 C 190.53125 176.453125 189.371094 175.292969 187.9375 175.292969 C 186.507812 175.292969 185.347656 176.453125 185.347656 177.878906 C 185.347656 179.308594 186.507812 180.464844 187.9375 180.464844 C 189.371094 180.464844 190.53125 179.308594 190.53125 177.878906 Z M 190.53125 177.878906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 185.585938 183.328125 C 185.585938 181.898438 184.425781 180.742188 182.996094 180.742188 C 181.5625 180.742188 180.402344 181.898438 180.402344 183.328125 C 180.402344 184.753906 181.5625 185.914062 182.996094 185.914062 C 184.425781 185.914062 185.585938 184.753906 185.585938 183.328125 Z M 185.585938 183.328125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 181.191406 188.601562 C 181.191406 187.171875 180.03125 186.015625 178.601562 186.015625 C 177.167969 186.015625 176.007812 187.171875 176.007812 188.601562 C 176.007812 190.03125 177.167969 191.1875 178.601562 191.1875 C 180.03125 191.1875 181.191406 190.03125 181.191406 188.601562 Z M 181.191406 188.601562 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 190.425781 188.773438 C 190.425781 187.34375 189.265625 186.1875 187.835938 186.1875 C 186.402344 186.1875 185.242188 187.34375 185.242188 188.773438 C 185.242188 190.199219 186.402344 191.359375 187.835938 191.359375 C 189.265625 191.359375 190.425781 190.199219 190.425781 188.773438 Z M 190.425781 188.773438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 193.988281 174.277344 L 211.320312 174.277344 L 211.320312 187.007812 L 193.988281 187.007812 Z M 193.988281 174.277344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 211.320312 186.648438 C 211.320312 182.207031 207.441406 178.605469 202.652344 178.605469 C 197.867188 178.605469 193.988281 182.207031 193.988281 186.648438 C 193.988281 191.09375 197.867188 194.695312 202.652344 194.695312 C 207.441406 194.695312 211.320312 191.09375 211.320312 186.648438 Z M 211.320312 186.648438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 197.976562 178.003906 L 207.335938 178.003906 L 207.335938 184.875 L 197.976562 184.875 Z M 197.976562 178.003906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 207.335938 185.640625 C 207.335938 183.242188 205.238281 181.296875 202.65625 181.296875 C 200.070312 181.296875 197.976562 183.242188 197.976562 185.640625 C 197.976562 188.042969 200.070312 189.988281 202.65625 189.988281 C 205.238281 189.988281 207.335938 188.042969 207.335938 185.640625 Z M 207.335938 185.640625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 194.421875 174.789062 L 210.886719 174.789062 L 210.886719 186.878906 L 194.421875 186.878906 Z M 194.421875 174.789062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 210.886719 186.542969 C 210.886719 182.320312 207.199219 178.898438 202.652344 178.898438 C 198.105469 178.898438 194.421875 182.320312 194.421875 186.542969 C 194.421875 190.761719 198.105469 194.183594 202.652344 194.183594 C 207.199219 194.183594 210.886719 190.761719 210.886719 186.542969 Z M 210.886719 186.542969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 198.207031 178.324219 L 207.097656 178.324219 L 207.097656 184.855469 L 198.207031 184.855469 Z M 198.207031 178.324219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 207.097656 185.582031 C 207.097656 183.304688 205.109375 181.457031 202.652344 181.457031 C 200.199219 181.457031 198.207031 183.304688 198.207031 185.582031 C 198.207031 187.863281 200.199219 189.710938 202.652344 189.710938 C 205.109375 189.710938 207.097656 187.863281 207.097656 185.582031 Z M 207.097656 185.582031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 197.742188 178.695312 L 207.566406 178.695312 L 207.566406 185.914062 L 197.742188 185.914062 Z M 197.742188 178.695312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 207.566406 185.710938 C 207.566406 183.191406 205.367188 181.148438 202.652344 181.148438 C 199.941406 181.148438 197.742188 183.191406 197.742188 185.710938 C 197.742188 188.230469 199.941406 190.273438 202.652344 190.273438 C 205.367188 190.273438 207.566406 188.230469 207.566406 185.710938 Z M 207.566406 185.710938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 200 180.808594 L 205.308594 180.808594 L 205.308594 184.707031 L 200 184.707031 Z M 200 180.808594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 205.308594 185.140625 C 205.308594 183.78125 204.121094 182.675781 202.652344 182.675781 C 201.1875 182.675781 200 183.78125 200 185.140625 C 200 186.5 201.1875 187.601562 202.652344 187.601562 C 204.121094 187.601562 205.308594 186.5 205.308594 185.140625 Z M 205.308594 185.140625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 198.21875 179.261719 L 207.089844 179.261719 L 207.089844 185.773438 L 198.21875 185.773438 Z M 198.21875 179.261719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 207.085938 185.589844 C 207.085938 183.316406 205.101562 181.472656 202.652344 181.472656 C 200.203125 181.472656 198.21875 183.316406 198.21875 185.589844 C 198.21875 187.863281 200.203125 189.707031 202.652344 189.707031 C 205.101562 189.707031 207.085938 187.863281 207.085938 185.589844 Z M 207.085938 185.589844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 200.257812 181.167969 L 205.046875 181.167969 L 205.046875 184.683594 L 200.257812 184.683594 Z M 200.257812 181.167969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 205.046875 185.074219 C 205.046875 183.847656 203.976562 182.851562 202.652344 182.851562 C 201.332031 182.851562 200.257812 183.847656 200.257812 185.074219 C 200.257812 186.304688 201.332031 187.300781 202.652344 187.300781 C 203.976562 187.300781 205.046875 186.304688 205.046875 185.074219 Z M 205.046875 185.074219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 200.039062 177.878906 C 200.039062 176.453125 198.878906 175.292969 197.449219 175.292969 C 196.015625 175.292969 194.855469 176.453125 194.855469 177.878906 C 194.855469 179.308594 196.015625 180.464844 197.449219 180.464844 C 198.878906 180.464844 200.039062 179.308594 200.039062 177.878906 Z M 200.039062 177.878906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 209.753906 177.878906 C 209.753906 176.453125 208.59375 175.292969 207.164062 175.292969 C 205.730469 175.292969 204.570312 176.453125 204.570312 177.878906 C 204.570312 179.308594 205.730469 180.464844 207.164062 180.464844 C 208.59375 180.464844 209.753906 179.308594 209.753906 177.878906 Z M 209.753906 177.878906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 204.8125 183.328125 C 204.8125 181.898438 203.652344 180.742188 202.21875 180.742188 C 200.789062 180.742188 199.628906 181.898438 199.628906 183.328125 C 199.628906 184.753906 200.789062 185.914062 202.21875 185.914062 C 203.652344 185.914062 204.8125 184.753906 204.8125 183.328125 Z M 204.8125 183.328125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 200.417969 188.601562 C 200.417969 187.171875 199.257812 186.015625 197.824219 186.015625 C 196.394531 186.015625 195.234375 187.171875 195.234375 188.601562 C 195.234375 190.03125 196.394531 191.1875 197.824219 191.1875 C 199.257812 191.1875 200.417969 190.03125 200.417969 188.601562 Z M 200.417969 188.601562 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 209.652344 188.773438 C 209.652344 187.34375 208.492188 186.1875 207.058594 186.1875 C 205.628906 186.1875 204.46875 187.34375 204.46875 188.773438 C 204.46875 190.199219 205.628906 191.359375 207.058594 191.359375 C 208.492188 191.359375 209.652344 190.199219 209.652344 188.773438 Z M 209.652344 188.773438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 155.539062 174.277344 L 172.871094 174.277344 L 172.871094 187.007812 L 155.539062 187.007812 Z M 155.539062 174.277344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 172.871094 186.648438 C 172.871094 182.207031 168.992188 178.605469 164.207031 178.605469 C 159.417969 178.605469 155.539062 182.207031 155.539062 186.648438 C 155.539062 191.09375 159.417969 194.695312 164.207031 194.695312 C 168.992188 194.695312 172.871094 191.09375 172.871094 186.648438 Z M 172.871094 186.648438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 159.527344 178.003906 L 168.886719 178.003906 L 168.886719 184.875 L 159.527344 184.875 Z M 159.527344 178.003906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 168.886719 185.640625 C 168.886719 183.242188 166.789062 181.296875 164.207031 181.296875 C 161.621094 181.296875 159.527344 183.242188 159.527344 185.640625 C 159.527344 188.042969 161.621094 189.988281 164.207031 189.988281 C 166.789062 189.988281 168.886719 188.042969 168.886719 185.640625 Z M 168.886719 185.640625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 155.972656 174.789062 L 172.4375 174.789062 L 172.4375 186.878906 L 155.972656 186.878906 Z M 155.972656 174.789062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 172.4375 186.542969 C 172.4375 182.320312 168.75 178.898438 164.203125 178.898438 C 159.65625 178.898438 155.972656 182.320312 155.972656 186.542969 C 155.972656 190.761719 159.65625 194.183594 164.203125 194.183594 C 168.75 194.183594 172.4375 190.761719 172.4375 186.542969 Z M 172.4375 186.542969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 159.757812 178.324219 L 168.648438 178.324219 L 168.648438 184.855469 L 159.757812 184.855469 Z M 159.757812 178.324219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 168.652344 185.582031 C 168.652344 183.304688 166.660156 181.457031 164.207031 181.457031 C 161.75 181.457031 159.757812 183.304688 159.757812 185.582031 C 159.757812 187.863281 161.75 189.710938 164.207031 189.710938 C 166.660156 189.710938 168.652344 187.863281 168.652344 185.582031 Z M 168.652344 185.582031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 159.292969 178.695312 L 169.117188 178.695312 L 169.117188 185.914062 L 159.292969 185.914062 Z M 159.292969 178.695312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 169.117188 185.710938 C 169.117188 183.191406 166.917969 181.148438 164.203125 181.148438 C 161.492188 181.148438 159.292969 183.191406 159.292969 185.710938 C 159.292969 188.230469 161.492188 190.273438 164.203125 190.273438 C 166.917969 190.273438 169.117188 188.230469 169.117188 185.710938 Z M 169.117188 185.710938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 161.550781 180.808594 L 166.859375 180.808594 L 166.859375 184.707031 L 161.550781 184.707031 Z M 161.550781 180.808594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 166.859375 185.140625 C 166.859375 183.78125 165.671875 182.675781 164.207031 182.675781 C 162.738281 182.675781 161.550781 183.78125 161.550781 185.140625 C 161.550781 186.5 162.738281 187.601562 164.207031 187.601562 C 165.671875 187.601562 166.859375 186.5 166.859375 185.140625 Z M 166.859375 185.140625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 159.769531 179.261719 L 168.640625 179.261719 L 168.640625 185.773438 L 159.769531 185.773438 Z M 159.769531 179.261719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 168.636719 185.589844 C 168.636719 183.316406 166.652344 181.472656 164.203125 181.472656 C 161.753906 181.472656 159.769531 183.316406 159.769531 185.589844 C 159.769531 187.863281 161.753906 189.707031 164.203125 189.707031 C 166.652344 189.707031 168.636719 187.863281 168.636719 185.589844 Z M 168.636719 185.589844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 161.808594 181.167969 L 166.597656 181.167969 L 166.597656 184.683594 L 161.808594 184.683594 Z M 161.808594 181.167969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,23.137255%,100%);fill-opacity:1;"
      d="M 166.597656 185.074219 C 166.597656 183.847656 165.527344 182.851562 164.203125 182.851562 C 162.882812 182.851562 161.808594 183.847656 161.808594 185.074219 C 161.808594 186.304688 162.882812 187.300781 164.203125 187.300781 C 165.527344 187.300781 166.597656 186.304688 166.597656 185.074219 Z M 166.597656 185.074219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 161.589844 177.878906 C 161.589844 176.453125 160.429688 175.292969 159 175.292969 C 157.566406 175.292969 156.40625 176.453125 156.40625 177.878906 C 156.40625 179.308594 157.566406 180.464844 159 180.464844 C 160.429688 180.464844 161.589844 179.308594 161.589844 177.878906 Z M 161.589844 177.878906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 171.304688 177.878906 C 171.304688 176.453125 170.144531 175.292969 168.714844 175.292969 C 167.28125 175.292969 166.121094 176.453125 166.121094 177.878906 C 166.121094 179.308594 167.28125 180.464844 168.714844 180.464844 C 170.144531 180.464844 171.304688 179.308594 171.304688 177.878906 Z M 171.304688 177.878906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 166.363281 183.328125 C 166.363281 181.898438 165.203125 180.742188 163.769531 180.742188 C 162.339844 180.742188 161.179688 181.898438 161.179688 183.328125 C 161.179688 184.753906 162.339844 185.914062 163.769531 185.914062 C 165.203125 185.914062 166.363281 184.753906 166.363281 183.328125 Z M 166.363281 183.328125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 161.96875 188.601562 C 161.96875 187.171875 160.808594 186.015625 159.375 186.015625 C 157.945312 186.015625 156.785156 187.171875 156.785156 188.601562 C 156.785156 190.03125 157.945312 191.1875 159.375 191.1875 C 160.808594 191.1875 161.96875 190.03125 161.96875 188.601562 Z M 161.96875 188.601562 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 171.203125 188.773438 C 171.203125 187.34375 170.042969 186.1875 168.609375 186.1875 C 167.179688 186.1875 166.019531 187.34375 166.019531 188.773438 C 166.019531 190.199219 167.179688 191.359375 168.609375 191.359375 C 170.042969 191.359375 171.203125 190.199219 171.203125 188.773438 Z M 171.203125 188.773438 "
    />
  </g>
</svg>
