$(document).on("click", "td.permissions-list--row-value", function (event) {
  event.preventDefault();

  var input = $(this).find("input")[0];
  var newValue = $(input).attr("value") != "true";
  $(input).attr("value", newValue);

  $(this).removeClass("true").removeClass("false");
  $(this).addClass(newValue.toString());
});
