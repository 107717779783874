<script>
  import styles from "styleguide/ProductSelectionCard.json";
  import { t } from "stores/i18n.js";
  import { Button, Tag } from "components";

  export let product;

  export let onProductClick;
  export let onLinkClick;
  export let onInitializeSandbox;
</script>

<div data-component="ProductSelectionCard" class={styles.card}>
  <div class={styles["card-content"]}>
    <div class={styles.header}>
      <div class={styles["title"]}>
        {$t(`products.${product}`)}
      </div>
      <div class={styles["title-subtitle"]}>
        {$t(`product_selection_card.${product}.subtitle`)}
      </div>
    </div>

    <div class={styles.description}>
      {$t(`product_selection_card.${product}.description`)}
    </div>

    <div class={styles.description}>
      {$t(`product_selection_card.${product}.benefits`)}
    </div>
    <div>
      <Button click={onProductClick} link={onLinkClick} style={"primary-tonal"}>
        {$t(`product_selection_card.${product}.cta`)}
      </Button>
    </div>
  </div>
  <div class={styles.divider} />

  <div class={styles["try-it-section"]}>
    <div class={styles.title}>
      {$t("product_selection_card.demo_title")}
      <Tag
        color="blue"
        label={$t("product_selection_card.demo_tag")}
        size="small"
      />
    </div>

    <div class={styles.description}>
      {$t(`product_selection_card.${product}.demo`)}
    </div>

    <Button style={"primary-text"} click={onInitializeSandbox}>
      {$t(`product_selection_card.${product}.try`)}
    </Button>
  </div>
</div>

<style lang="scss">
  .card {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid var(--primary-050);
    background: #fff;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 16px;
    flex: 1;

    & > :last-child {
      display: flex;
      flex: 1;
      align-items: flex-end;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  .title {
    color: var(--primary-500);
    font-size: 22px;
    font-weight: 600;
  }

  .title-subtitle {
    color: var(--primary-300);
    font-size: 12px;
    font-weight: 400;
  }

  .description {
    color: var(--primary-500);
    font-size: 14px;
    font-weight: 400;
  }

  .checkmark-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 8px;
  }

  .checkmark-item {
    display: flex;
    gap: 8px;
    align-items: flex-start;

    img {
      margin-top: 2px;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background: var(--primary-050);
  }

  .try-it-section {
    display: flex;
    padding-right: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

    .title {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      gap: 8px;
      color: var(--primary-500);
      font-size: 14px;
      font-weight: 600;
    }
    .description {
      min-height: 110px;
    }
  }
</style>
