<script>
  import styles from "styleguide/Radio.json";

  export let value;
  export let checked;
  export let style = "";
  export let onToggle = () => {};

  function getStyles() {
    return style
      .split(" ")
      .map((x) => styles[x])
      .join(" ");
  }
</script>

<label data-component="Radio" class="{styles.wrapper} {getStyles()}">
  <input
    type="radio"
    on:change={onToggle.bind(this, value)}
    bind:group={checked}
    {value}
  />
  <div class={styles.radio}></div>
</label>

<style lang="scss">
  .wrapper {
    display: block;
    width: 28px;
    height: 28px;
    position: relative;
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) + .radio {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    // display: inline-block;
    color: var(--primary-500);
  }

  [type="radio"]:checked + .radio:before,
  [type="radio"]:not(:checked) + .radio:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid var(--primary-050);
    border-radius: 100%;
    background: #fff;
  }

  [type="radio"]:checked + .radio:after,
  [type="radio"]:not(:checked) + .radio:after {
    content: "";
    width: 12px;
    height: 12px;
    background: var(--blue);
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked) + .radio:after {
    opacity: 0;
    transform: scale(0);
  }

  [type="radio"]:checked + .radio:after {
    opacity: 1;
    transform: scale(1);
  }
</style>
