<script>
  import styles from "styleguide/AdministrationApi.json";
  import { onMount } from "svelte";

  import { t } from "stores/i18n.js";
  import { breadcrumb } from "stores/breadcrumb.js";
  import { updatePageHistory } from "stores/page_history.js";
  import { currentAdminPage } from "stores/admin.js";
  import { user } from "stores/user.js";

  import { userPath, dateTimeFormat } from "lib/helpers.js";
  import AccountApi from "apis/admin/accounts.js";

  import { Button, Toast } from "components";

  let apiKey;
  let apiKeyPresent = false;
  let apiKeyGeneratedAt;

  onMount(async () => {
    breadcrumb.set([
      {
        translation: "administration",
        click: () => {
          currentAdminPage.set("dashboard");
          window.history.pushState("", userPath + "/account/");
        },
      },
      { translation: "api" },
    ]);

    updatePageHistory([
      {
        path: userPath + "/account/api",
        titles: [{ translation: "administration" }, { translation: "api" }],
      },
    ]);

    AccountApi.hasApiKey({
      params: {
        user: $user,
      },
      success: function (data) {
        apiKeyPresent = data.has_key;
        apiKeyGeneratedAt = data.generated_at;
      },
    });
  });

  function generateApiKey() {
    if (apiKeyPresent ? confirm($t("administration_api.alert")) : true) {
      AccountApi.generateApiKey({
        params: {
          user: $user,
        },
        success: function (data) {
          apiKey = data.api_key;
          apiKeyPresent = true;
          apiKeyGeneratedAt = data.generated_at;
        },
      });
    }
  }

  function confirmApiKey() {
    apiKey = null;
  }

  function deleteApiKey() {
    if (confirm($t("administration_api.alert"))) {
      AccountApi.deleteApiKey({
        params: {
          user: $user,
        },
        success: function (data) {
          apiKey = apiKeyPresent = apiKeyGeneratedAt = null;
        },
      });
    }
  }
</script>

<div class={styles.content}>
  <div class={styles.section}>
    <div class={styles["section-header"]}>
      {$t("administration_api.header")}
    </div>
    <div class={styles["section-description"]}>
      {$t("administration_api.description")}
    </div>
    {#if apiKey}
      <Toast
        title={$t("administration_api.key_title")}
        message={apiKey}
        margin={false}
      >
        <p>
          {$t("administration_api.key_description")}
        </p>
        <Button click={confirmApiKey} style="primary">
          {$t("administration_api.key_confirm")}
        </Button>
      </Toast>
    {/if}
    {#if apiKeyPresent && !apiKey}
      {@html $t("administration_api.key_present", {
        date: dateTimeFormat(apiKeyGeneratedAt),
      })}
    {/if}
  </div>
  {#if !apiKey}
    <div class={styles.buttons}>
      <Button click={generateApiKey} style="primary">
        {$t(
          apiKeyPresent
            ? "administration_api.rotate"
            : "administration_api.generate",
        )}
      </Button>
      {#if apiKeyPresent}
        <Button click={deleteApiKey} style="error-text"
          >{$t("administration_api.delete")}
        </Button>
      {/if}
    </div>
  {/if}
</div>

<style scoped lang="scss">
  .content {
    width: 679px;
    padding: 24px;
    background: var(--white);
    box-shadow:
      0px 6px 8px -6px rgba(24, 39, 75, 0.12),
      0px 8px 16px -6px rgba(24, 39, 75, 0.08);
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
  }

  .section {
    &-header {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--primary-500);
      padding-bottom: 8px;
    }

    &-description {
      padding-bottom: 16px;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 16px;
  }
</style>
