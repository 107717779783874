<script>
  import styles from "styleguide/DashboardFilters.json";
  import {
    projectsSelectedFilters,
    filterRemove,
  } from "stores/dashboard/project.js";

  import { DashboardStatus } from "apps/dashboard";
  import { Boundary } from "components";
</script>

<Boundary>
  <div class={styles.wrapper} data-component="DashboardFilters">
    {#each Object.entries($projectsSelectedFilters) as [status, count] (status)}
      <div class={styles.item}>
        <DashboardStatus
          type="filter"
          {status}
          {count}
          click={filterRemove.bind(this, status)}
        />
      </div>
    {/each}
  </div>
</Boundary>

<style lang="scss">
  .item {
    display: flex;
    align-items: center;
    margin: 0 10px 0 0;
  }

  .label {
    font-weight: 500;
    margin: 0 10px 0 0;
  }
</style>
