<script>
  export let width = "640px";
  export let height = "480px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  id="flag-icons-gb-eng"
  {width}
  {height}
  {style}
  viewBox="0 0 640 480"
>
  <path fill="#fff" d="M0 0h640v480H0z" />
  <path fill="#ce1124" d="M281.6 0h76.8v480h-76.8z" />
  <path fill="#ce1124" d="M0 201.6h640v76.8H0z" />
</svg>
