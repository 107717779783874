import { Controller } from "stimulus";
import "select2";
import Api from "../general/api";

export default class extends Controller {
  static targets = [
    "input",
    "list",
    "userAdd",
    "withControlIcon",
    "auditClient",
    "searchPlaceholder",
  ];

  connect() {
    this.initializeSelectize();
  }

  disconnect() {
    let hiddenInput = this.element.querySelector(".selectized");
    if (hiddenInput) {
      hiddenInput.selectize.destroy();
    }
  }

  initializeSelectize() {
    let self = this;
    if (this.inputTarget.tagName === "SELECT") {
      this.inputTarget.classList.add("search-select2-container");
      $(this.inputTarget)
        .select2({
          theme: "default",
          width: "resolve",
          allowClear: false,
          dropdownParent: this.inputTarget.parentNode,
          templateResult: self.formatOption.bind(self),
          ajax: {
            minimumInputLength: 2,
            placeholder:
              self.inputTarget.getAttribute("data-placeholder") || "",
            url: $(this.element).data("url"),
            type: "POST",
            data: (term) => {
              return {
                search: term["term"],
                name_id: self.hasAuditClientTarget ? true : null,
              };
            },
            processResults: (data) => {
              return {
                results: data,
              };
            },
          },
        })
        .on("select2:select", (e) => {
          if (this.data.get("redirect")) {
            Api.redirect(e.params.data.url);
          }
          if (this.hasUserAddTarget) {
            this.userAddRequest(e.params.data);
          }
          if (this.hasListTarget) {
            this.onItemAdd(e.params.data);
          }
        })
        .on("select2:open", (e) => {
          let searchField = e.target
            .closest("div")
            .querySelector(".select2-search__field");
          if (this.hasSearchPlaceholder) {
            searchField.setAttribute(
              "placeholder",
              this.searchPlaceholderTarget.value,
            );
          } else {
            searchField.setAttribute(
              "placeholder",
              I18n.t("platform.views.type_to_search"),
            );
          }
        });
    }
  }

  onItemAdd(data) {
    let picture = "",
      newNode = document.createElement("li");

    if (data.avatar != null) {
      picture = `<div class="user-picture"><img src="${escape(data.avatar)}" alt=""></div>`;
    }

    newNode.innerHTML = `
      <input type="hidden" name="project[team_user_ids][]" value="${data.id}" />
      ${picture} ${data.text}
      <a href="#" class="btn icon" data-action="project-form-users#removeProjectTeamUser"><div class="close-icon"></div></a>
    `;

    this.listTarget.appendChild(newNode);
    $(this.inputTarget).select2("close");
    $(this.inputTarget).val(null).trigger("change");
  }

  formatOption(state) {
    if (!this.hasUserAddTarget) return state.text;

    let $state = $(
      `<span><div class="select2-user-add-option-icon"></div><span class="select2-user-add-text">${state.text}</span></span>`,
    );
    return $state;
  }

  userAddRequest(data) {
    let controlController =
      this.application.getControllerForElementAndIdentifier(
        document.querySelector(`[data-controller="modal control-modal"]`),
        "control-modal",
      );
    controlController.addUser(data.id);
  }

  removeProjectTeamUser(e) {
    e.preventDefault();
    let closeIconNode = e.target.closest("a"),
      teamUserNode = e.target.closest("li");

    closeIconNode.remove();
    teamUserNode.classList.add("remove");
    teamUserNode.querySelector(`input[type="hidden"]`).remove();
  }
}
