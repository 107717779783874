<script>
  import { ConfirmationNewBlock } from "components/confirmationNew";
  import {
    isConfirmationType,
    isReviewType,
    activeStore,
  } from "stores/dashboard/create_confirmation.js";

  import { dashboardProjectState } from "stores/dashboard/project.js";
  import { t } from "stores/i18n.js";
  import { theme } from "stores/theme.js";

  import { Checkbox, Toast } from "components";
  import styles from "styleguide/ConfirmationNewOptions.json";

  export let active;

  let invitation;
  let openCreditorTemplate;
  let openDebitorTemplate;
  let enableSpecificResponses;

  const { usePreviousLetterhead, selectedType } = $activeStore;

  $: if ($isConfirmationType) {
    ({ invitation } = $activeStore);
  } else if ($isReviewType) {
    ({ openCreditorTemplate, openDebitorTemplate, enableSpecificResponses } =
      $activeStore);
  }

  $: if ($selectedType === "open") {
    if ($theme !== "eyde") {
      openCreditorTemplate.set(true);
    }

    if ($theme === "ebde") {
      openDebitorTemplate.set(true);
    }
  }
</script>

<ConfirmationNewBlock
  {active}
  edit={() => {
    active = true;
  }}
>
  <div data-component="ConfirmationNewOptions" class={styles.wrapper}>
    <Checkbox
      toggled={$usePreviousLetterhead}
      toggle={(value) => {
        usePreviousLetterhead.set(value);
      }}
      label={$t("confirmation_new_options.label_letterhead")}
    />

    {#if $isConfirmationType}
      <Checkbox
        toggled={$invitation}
        toggle={(value) => {
          invitation.set(value);
        }}
        label={$t("confirmation_new_options.label_invitations")}
      />
    {/if}

    {#if $isReviewType}
      {#if $dashboardProjectState.enable_specific_responses}
        <Checkbox
          toggled={$enableSpecificResponses}
          toggle={(value) => {
            enableSpecificResponses.set(value);
          }}
          label={$t("confirmation_new_options.digital_responses")}
        />
      {/if}

      {#if $selectedType === "open"}
        {#if $theme === "ebde"}
          <Checkbox
            toggled={$openDebitorTemplate}
            toggle={(value) => {
              openDebitorTemplate.set(value);
            }}
            label={$t("confirmation_new_options.templates_debtor_requests")}
          />
        {/if}

        {#if $theme !== "eyde"}
          <Checkbox
            toggled={$openCreditorTemplate}
            toggle={(value) => {
              openCreditorTemplate.set(value);
            }}
            label={$t("confirmation_new_options.templates_creditor_requests")}
          />
        {/if}
      {/if}
    {/if}

    <div class={styles["toast-margin"]}>
      <Toast type="info" title={$t("confirmation_new_options.infobox_title")}>
        <ul>
          <li class={styles.item}>
            {$t("confirmation_new_options.infobox_item1")}
          </li>
          <li class={styles.item}>
            {$t("confirmation_new_options.infobox_item2")}
          </li>
          <li class={styles.item}>
            {$t("confirmation_new_options.infobox_item3")}
          </li>
        </ul>
      </Toast>
    </div>
  </div>
</ConfirmationNewBlock>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
  }

  .input {
    margin-bottom: 20px;
  }

  ul {
    display: block;
    list-style: disc;
  }

  .toast-margin {
    margin: 20px 0;
  }
</style>
