<script>
  export let width = 19;
  export let height = 18;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 19 18"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <rect width="18" height="18" {fill} rx="9" />
  <path
    fill="#ffffff"
    d="M12.76 8.133L7.1 4.865c-.667-.384-1.5.096-1.5.867v6.535c0 .77.833 1.251 1.5.866l5.66-3.268c.666-.384.666-1.347 0-1.732"
  />
</svg>
