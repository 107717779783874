import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "input",
    "list",
    "userAdd",
    "withControlIcon",
    "auditClient",
  ];

  connect() {
    this.initializeWithArchivedCheckbox();
  }

  initializeWithArchivedCheckbox() {
    let checkbox = document.querySelector(".show-archived-checkbox input");
    const url = new URL(window.location),
      withArchived = url.searchParams.get("with_archived");
    if (withArchived) {
      checkbox.checked = true;
    }
  }

  withArchivedCheckboxClick() {
    let checkbox = document.querySelector(".show-archived-checkbox input");

    const url = new URL(window.location);
    const withArchived = url.searchParams.get("with_archived");

    if (withArchived) {
      checkbox.checked = false;
      url.searchParams.delete("with_archived");
    } else {
      checkbox.checked = true;
      url.searchParams.set("with_archived", "true");
    }

    window.location.href = url;
  }
}
