import { writable } from "svelte/store";

function createToastStore() {
  const toasts = writable([]);

  function send({
    title,
    message = null,
    type = "info",
    close = true,
    action = null,
    duration = 3000,
    additionalLineDuration = 1000,
  }) {
    let toast = {
      id: new Date().getTime(),
      type,
      message,
      close,
      title,
      action,
      duration,
    };
    if (toast.message) {
      let rows = Math.max(Math.ceil(toast.message.length / 35), 4);
      toast.duration += rows * additionalLineDuration;
    }
    toasts.update((state) => {
      return [toast, ...state];
    });

    setTimeout(() => {
      removeToast(toast.id);
    }, toast.duration);
  }

  function close(toastId) {
    removeToast(toastId);
  }

  function removeToast(toastId) {
    toasts.update((state) => {
      return [...state.filter((toast) => toast.id !== toastId)];
    });
  }

  const { subscribe } = toasts;

  return {
    subscribe,
    close,
    send,
  };
}

export const toasts = createToastStore();
