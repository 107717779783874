<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.588235%,0%,6.666667%);fill-opacity:1;"
      d="M 0 0 L 479.96875 0 L 479.96875 186.710938 L 0 186.710938 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 180 L 479.96875 180 L 479.96875 360 L 0 360 Z M 0 180 "
    />
  </g>
</svg>
