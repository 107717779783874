<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.980392%,0%,3.137255%);fill-opacity:1;"
      d="M 0.675781 0 L 480.675781 0 L 480.675781 360 L 0.675781 360 Z M 0.675781 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0.675781 25.714844 L 480.675781 25.714844 L 480.675781 51.429688 L 0.675781 51.429688 Z M 0.675781 25.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0.675781 77.144531 L 480.675781 77.144531 L 480.675781 102.859375 L 0.675781 102.859375 Z M 0.675781 77.144531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0.675781 128.574219 L 480.675781 128.574219 L 480.675781 154.285156 L 0.675781 154.285156 Z M 0.675781 128.574219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0.675781 180 L 480.675781 180 L 480.675781 205.714844 L 0.675781 205.714844 Z M 0.675781 180 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0.675781 231.425781 L 480.675781 231.425781 L 480.675781 257.140625 L 0.675781 257.140625 Z M 0.675781 231.425781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0.675781 282.855469 L 480.675781 282.855469 L 480.675781 308.570312 L 0.675781 308.570312 Z M 0.675781 282.855469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0.675781 334.28125 L 480.675781 334.28125 L 480.675781 359.996094 L 0.675781 359.996094 Z M 0.675781 334.28125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,2.352941%,53.72549%);fill-opacity:1;"
      d="M 0 0 L 183.878906 0 L 183.878906 106.042969 L 0 106.042969 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,93.333333%,0%);fill-opacity:1;"
      d="M 93.351562 90.132812 C 73.566406 101.140625 48.832031 93.703125 38.144531 73.761719 C 27.453125 53.824219 34.832031 28.894531 54.617188 18.121094 C 66.851562 11.460938 80.285156 11.328125 92.492188 17.878906 C 85.605469 16.222656 79.453125 16.3125 72.769531 18.296875 C 53.300781 24.082031 42.109375 44.867188 47.789062 64.691406 C 53.46875 84.519531 73.878906 95.917969 93.347656 90.132812 Z M 93.351562 90.132812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,93.333333%,0%);fill-opacity:1;"
      d="M 126.726562 40.699219 L 147.617188 28.828125 L 131.019531 46.203125 L 154.992188 44.574219 L 132.5 53.027344 L 154.804688 61.960938 L 130.875 59.816406 L 147.09375 77.539062 L 126.464844 65.226562 L 133.386719 88.234375 L 120.144531 68.1875 L 116.394531 91.917969 L 113.160156 68.113281 L 99.488281 87.871094 L 106.902344 65.015625 L 86.011719 76.882812 L 102.609375 59.511719 L 78.636719 61.140625 L 101.128906 52.6875 L 78.824219 43.753906 L 102.753906 45.898438 L 86.535156 28.175781 L 107.164062 40.488281 L 100.246094 17.480469 L 113.488281 37.527344 L 117.238281 13.796875 L 120.46875 37.601562 L 134.140625 17.847656 Z M 126.726562 40.699219 "
    />
  </g>
</svg>
