<script>
  import styles from "styleguide/DownloadsItem.json";

  import { t } from "stores/i18n.js";
  import { iconPath, fileIcon } from "lib/helpers.js";

  import AuditApi from "apis/pbc/audit.js";

  import { LegacyButton as Button, SpinnerAlt } from "components";

  export let auditExport;
  export let onExportCancel;

  let hasCancelIntent = false;
  let exportCancelConfirmed = false;
  let showRedAlert = false;

  function toggleCancelIntent() {
    hasCancelIntent = true;
  }

  function cancelToggleCancelIntent() {
    if (exportCancelConfirmed) {
      return;
    }
    hasCancelIntent = false;
  }

  function confirmExportCancelConfirmed() {
    exportCancelConfirmed = true;
    hasCancelIntent = true;
  }

  function rejectExportCancelConfirmed() {
    exportCancelConfirmed = false;
    hasCancelIntent = false;
  }

  function acceptExportCancelConfirmation() {
    AuditApi.cancelExport({
      params: { id: auditExport.permalink },
      success: (response) => {
        hasCancelIntent = true;
        exportCancelConfirmed = false;
        showRedAlert = true;
        auditExport.status = response.data?.export?.status;

        onExportCancel(response);
      },
    });
  }
</script>

<div data-component="DownloadsItem" class={styles["downloads-item"]}>
  <div class={styles.file}>
    <img
      class={styles.icon}
      src={fileIcon(auditExport.filename ? auditExport.filename : "")}
      alt={auditExport.filename}
    />
    <div class={styles.content}>
      <div class={styles.name}>{auditExport.filename}</div>
      <div class={styles.status}>
        {$t(`downloads_item.${auditExport.status}`)}
      </div>
    </div>
    <div class={styles.state}>
      {#if auditExport.status === "done"}
        <img
          src={iconPath("checkmark_round_green")}
          alt="checkmark_round_green"
        />
      {:else if auditExport.status == "cancelled"}
        <img src={iconPath("error_filled")} alt={"alert"} />
      {:else if auditExport.status == "processing"}
        <!-- svelte-ignore a11y-mouse-events-have-key-events -->
        <div
          class={styles.progress}
          on:mouseover={toggleCancelIntent}
          on:mouseleave={cancelToggleCancelIntent}
        >
          {#if !hasCancelIntent}
            <SpinnerAlt />
          {:else}
            <img
              src={exportCancelConfirmed || showRedAlert
                ? iconPath("error_filled")
                : iconPath("red_close")}
              alt={exportCancelConfirmed || showRedAlert
                ? "alert"
                : "red_close"}
              on:click={confirmExportCancelConfirmed}
            />
          {/if}
        </div>
      {/if}
    </div>
  </div>
  {#if exportCancelConfirmed && auditExport.status === "processing"}
    <div class={styles.cancel}>
      <div class={styles.button}>
        <Button
          style={"medium fullsize border"}
          click={rejectExportCancelConfirmed}
          >{$t("downloads_item.continue")}</Button
        >
      </div>
      <div class={styles.button}>
        <Button
          style={"medium fullsize red background"}
          click={acceptExportCancelConfirmation}
          >{$t("downloads_item.cancel")}</Button
        >
      </div>
    </div>
  {/if}
</div>

<style global lang="scss">
  .downloads-item {
    border-bottom: 1px solid var(--primary-050);
    padding: 12px 16px;
    gap: 8px;
    cursor: pointer;
  }
  .file {
    display: flex;
    align-items: start;

    gap: 8px;
  }
  .content {
    flex: 2;
  }
  .status {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--primary-300);
  }
  .icon {
    width: 18px;
    margin: 4px 0;
  }
  .name {
    width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    color: var(--primary-500) #052861;
  }
  .progress {
    height: 18px;
    width: 18px;
    text-align: center;
  }
  .state {
    align-self: center;
  }
  .cancel {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }
  .button {
    width: 48%;
  }
</style>
