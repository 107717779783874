import i18next from "i18next";
import intervalPlural from "i18next-intervalplural-postprocessor";
import { writable, derived } from "svelte/store";
import onError from "apis/appsignal/appsignal.js";

const t = writable(function (key) {});

const translationCache = {};

const currentLanguage = derived(t, ($t, set) => {
  set(i18next.language);
});

async function fetchTranslations(locale) {
  if (translationCache[locale]) {
    return translationCache[locale];
  }

  const translationFileUrl = `/translations/${locale}.json`;

  try {
    const response = await fetch(translationFileUrl, { cache: "no-cache" });
    const translations = await response.json();
    translationCache[locale] = translations;
    return translations;
  } catch (error) {
    console.error(`Error fetching translations for ${locale}:`, error);
    return {};
  }
}

async function setupI18n({ withLocale } = { withLocale: "en" }) {
  const translations = await fetchTranslations(withLocale);

  return i18next.use(intervalPlural).init({
    lng: withLocale,
    fallbackLng: "en",
    debug: true,
    saveMissing: true,
    missingKeyHandler: function (
      lngs,
      ns,
      key,
      fallbackValue,
      updateMissing,
      options,
    ) {
      const translationError = new FrontendTranslationError(
        [lngs.join(), key].join("."),
      );
      onError(translationError);
    },
    resources: {
      [withLocale]: {
        translation: translations,
      },
    },
  });
}

const changeLanguage = async (newLanguage) => {
  return setupI18n({ withLocale: newLanguage }).then((_t) => {
    t.set(_t);
    i18next.changeLanguage(newLanguage);
  });
};

const initializeI18N = async function (language) {
  return setupI18n({ withLocale: language }).then((_t) => {
    t.set(_t);
  });
};

class FrontendTranslationError extends Error {
  constructor(message) {
    super(message);
    this.name = "FrontendTranslationError";
  }
}

export { initializeI18N, t, changeLanguage, currentLanguage };
