<script>
  import styles from "styleguide/ConfirmationDetailBar.json";
  import { slide } from "svelte/transition";

  import { user } from "stores/user.js";
  import { t } from "stores/i18n.js";
  import { toasts } from "stores/toasts.js";
  import { can } from "stores/permission.js";
  import { item, getItem } from "stores/confirmation/confirmation_item.js";

  import ConfirmationItemsApi from "apis/confirmation/confirmation_items.js";

  import { ConfirmationStatus } from "apps/confirmation";
  import { Button, Dropdown, TextInput } from "components";

  let show = false;
  let comment = "";
  let currentStatus;
  let actionRequired = false;
  $: statuses = setStatuses($item.available_change_statuses, $t);
  $: isSelectUnlocked =
    ["received", "accepted", "rejected", "not_delivered"].includes(
      $item.status,
    ) || $item.is_confirmation_com;

  $: actionRequiredOptions = {
    true: $t("confirmation.status.action_required"),
    false: $t("confirmation.status.no_action_required"),
  };

  item.subscribe((value) => {
    comment = value.comment || "";
    currentStatus = setCurrentStatus($item.status);
    actionRequired = $item.action_required;
  });

  function toggle() {
    show = !show;
  }

  function setStatuses(availableStatuses) {
    let newstatuses = {};

    if (availableStatuses) {
      availableStatuses.forEach((status) => {
        newstatuses[status] = $t(`confirmation.status.${status}`);
      });
    }

    return newstatuses;
  }

  function setCurrentStatus(initialStatus) {
    return ["accepted", "rejected"].includes(initialStatus)
      ? initialStatus
      : "no-status";
  }

  function submit() {
    ConfirmationItemsApi.editStatus({
      params: {
        comment: comment,
        status: currentStatus,
        id: $item.permalink,
        action_required: actionRequired,
      },
      success: () => {
        getItem();
        show = false;
        toasts.send({
          title: $t("success.update_successful"),
          type: "success",
        });
      },
    });
  }
</script>

<div class={styles.wrapper} data-component="ConfirmationDetailBar">
  <div class={styles.top}>
    {#if $can("status", "confirmation_item") && $user.role !== "client"}
      <Button click={toggle} style="primary-text">
        {show
          ? $t("confirmation_detail_bar.hide")
          : $t("confirmation_detail_bar.show")}
      </Button>
    {/if}
    <div class={styles.status}>
      <ConfirmationStatus
        status={$item.sending_scheduled ? "sending_scheduled" : $item.status}
        size={"medium"}
      />
    </div>
    {#if actionRequired && actionRequired !== "false"}
      <div class={styles.status}>
        <ConfirmationStatus status={"action_required"} size={"medium"} />
      </div>
    {/if}
  </div>

  {#if show && $user.role !== "client"}
    <div class={styles.bottom} transition:slide={{ y: 200, duration: 130 }}>
      <div class={styles.dropdown}>
        <Dropdown
          style="bottom"
          items={statuses}
          initial={$t(`confirmation.status.${$item.status}`)}
          lock={!isSelectUnlocked}
          placement="top"
          bind:selected={currentStatus}
        />
      </div>

      <div class={styles.comment}>
        <TextInput
          bind:value={comment}
          onEnter={submit}
          placeholder={$t("confirmation_detail_bar.comment_placeholder")}
          footnotes={[$t("confirmation_detail_bar.comment_footnote")]}
        />
      </div>

      <div class={styles.dropdown}>
        <Dropdown
          style="bottom"
          placement="top"
          items={actionRequiredOptions}
          bind:selected={actionRequired}
        />
      </div>

      <div class={styles.submit}>
        <Button click={submit} style="primary"
          >{$t("confirmation_detail_bar.submit")}</Button
        >
      </div>
    </div>
  {/if}
</div>

<style lang="scss">
  .wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 1;
  }

  .status {
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }

  .dropdown {
    width: 250px;
  }

  .comment {
    flex: 1;
    margin: 0 15px;
  }

  .submit {
    margin-left: 20px;
  }

  .top,
  .bottom {
    display: flex;
    border-top: 1px solid var(--primary-050);
  }

  .top {
    min-height: 60px;
    align-items: center;
    padding: 10px var(--page-gutter);
  }

  .bottom {
    padding: 15px var(--page-gutter);
  }
</style>
