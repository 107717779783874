import { Controller } from "stimulus";
import camelCase from "lodash.camelcase";
import Api from "../../apis/api";
import Helper from "../../general/helper";

const COUNT_KEYS = [
  "new_answers",
  "new_comments",
  "master_data_missing",
  "master_data_not_validated",
  "need_for_action",
  "without_answer",
];

export default class extends Controller {
  static targets = [
    "newAnswers",
    "newComments",
    "masterDataMissing",
    "masterDataNotValidated",
    "needForAction",
    "withoutAnswer",
  ];

  connect() {
    const permalink = this.data.get("permalink");
    const status = parseInt(this.data.get("status"));

    this.fetchCounts({
      url: `/reviews/${permalink}/sample_counts`,
      params: status > 0 ? { status } : {},
    });
  }

  fetchCounts({ url, params }) {
    Api.get(url, {
      params,
      success: (response) => {
        const counts = Helper.dig(["data", "counts"], response);

        if (!counts) {
          return;
        }

        for (const key of COUNT_KEYS) {
          this.setCount(camelCase(`${key}_target`), counts[key]);
        }
      },
      error: (response) => {
        console.error("Error", response);
      },
    });
  }

  setCount(targetName, value) {
    if (value !== undefined && value !== null) {
      this[targetName].textContent = value;
    }
  }
}
