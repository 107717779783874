<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(0%,30.588235%,0%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 155.136719 96.675781 C 174.753906 106.132812 198.113281 96.628906 206.5625 83.570312 C 215.226562 71.382812 216.292969 50.921875 201.3125 32.144531 C 224.5625 51.429688 229.703125 73.285156 218.136719 91.285156 C 202.707031 111.855469 168.476562 111.261719 155.136719 96.675781 Z M 155.136719 96.675781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,100%,100%);fill-opacity:1;"
      d="M 189.960938 48.820312 L 193.910156 48.867188 L 190.785156 51.351562 L 191.957031 55.230469 L 188.699219 52.9375 L 185.472656 55.285156 L 186.585938 51.386719 L 183.425781 48.960938 L 187.367188 48.84375 L 188.632812 44.992188 Z M 189.960938 48.820312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,100%,100%);fill-opacity:1;"
      d="M 170.476562 73.246094 L 174.425781 73.296875 L 171.300781 75.777344 L 172.472656 79.660156 L 169.214844 77.363281 L 165.988281 79.710938 L 167.101562 75.816406 L 163.9375 73.386719 L 167.882812 73.269531 L 169.148438 69.421875 Z M 170.476562 73.246094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,100%,100%);fill-opacity:1;"
      d="M 171.761719 53.96875 L 175.710938 54.015625 L 172.585938 56.5 L 173.757812 60.378906 L 170.5 58.085938 L 167.273438 60.433594 L 168.386719 56.535156 L 165.222656 54.109375 L 169.167969 53.992188 L 170.433594 50.140625 Z M 171.761719 53.96875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,100%,100%);fill-opacity:1;"
      d="M 171.761719 34.675781 L 175.710938 34.726562 L 172.585938 37.207031 L 173.757812 41.089844 L 170.5 38.792969 L 167.273438 41.140625 L 168.386719 37.246094 L 165.222656 34.816406 L 169.167969 34.699219 L 170.433594 30.851562 Z M 171.761719 34.675781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,100%,100%);fill-opacity:1;"
      d="M 150.105469 59.109375 L 154.054688 59.160156 L 150.929688 61.640625 L 152.101562 65.519531 L 148.84375 63.226562 L 145.617188 65.574219 L 146.730469 61.675781 L 143.570312 59.25 L 147.511719 59.132812 L 148.777344 55.285156 Z M 150.105469 59.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 3.425781 0 L 119.140625 0 L 119.140625 359.988281 L 3.425781 359.988281 Z M 3.425781 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 3.429688 93.855469 L 3.429688 91.28125 L 8.210938 84.855469 L 7.246094 83.570312 L 10.140625 79.710938 L 9.175781 78.425781 L 7.246094 80.996094 L 6.28125 79.710938 L 13.03125 70.710938 L 13.996094 71.996094 L 13.03125 73.285156 L 15.019531 75.882812 L 19.78125 69.425781 L 14.941406 63.171875 L 13.03125 65.570312 L 13.996094 66.855469 L 13.03125 68.140625 L 6.28125 59.140625 L 7.246094 57.855469 L 9.175781 60.425781 L 10.140625 59.140625 L 7.246094 55.285156 L 8.210938 53.996094 L 3.429688 47.570312 L 3.429688 45 L 10.140625 53.996094 L 9.175781 55.285156 L 12.070312 59.140625 L 10.140625 61.710938 L 12.070312 64.28125 L 14.960938 60.425781 L 21.75 69.578125 L 14.960938 78.425781 L 12.070312 74.570312 L 10.140625 77.140625 L 12.070312 79.710938 L 9.175781 83.570312 L 10.140625 84.855469 Z M 3.429688 93.855469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 3.425781 61.710938 L 4.390625 64.285156 L 4.390625 68.140625 L 7.285156 68.140625 L 9.214844 69.425781 L 7.285156 70.710938 L 4.390625 70.710938 L 4.390625 74.570312 L 3.425781 77.140625 Z M 3.425781 61.710938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 3.429688 155.566406 L 3.429688 152.996094 L 8.210938 146.566406 L 7.246094 145.28125 L 10.140625 141.425781 L 9.175781 140.136719 L 7.246094 142.710938 L 6.28125 141.425781 L 13.03125 132.425781 L 13.996094 133.710938 L 13.03125 134.996094 L 15.019531 137.59375 L 19.78125 131.136719 L 14.941406 124.882812 L 13.03125 127.28125 L 13.996094 128.566406 L 13.03125 129.851562 L 6.28125 120.851562 L 7.246094 119.566406 L 9.175781 122.140625 L 10.140625 120.851562 L 7.246094 116.996094 L 8.210938 115.710938 L 3.429688 109.28125 L 3.429688 106.710938 L 10.140625 115.710938 L 9.175781 116.996094 L 12.070312 120.851562 L 10.140625 123.425781 L 12.070312 125.996094 L 14.960938 122.140625 L 21.75 131.292969 L 14.960938 140.136719 L 12.070312 136.28125 L 10.140625 138.851562 L 12.070312 141.425781 L 9.175781 145.28125 L 10.140625 146.566406 Z M 3.429688 155.566406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 3.425781 123.425781 L 4.390625 125.996094 L 4.390625 129.851562 L 7.285156 129.851562 L 9.214844 131.140625 L 7.285156 132.425781 L 4.390625 132.425781 L 4.390625 136.28125 L 3.425781 138.851562 Z M 3.425781 123.425781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 3.429688 217.28125 L 3.429688 214.707031 L 8.210938 208.28125 L 7.246094 206.992188 L 10.140625 203.136719 L 9.175781 201.851562 L 7.246094 204.421875 L 6.285156 203.136719 L 13.035156 194.136719 L 13.996094 195.421875 L 13.035156 196.707031 L 15.019531 199.304688 L 19.785156 192.851562 L 14.941406 186.597656 L 13.035156 188.996094 L 13.996094 190.28125 L 13.035156 191.566406 L 6.285156 182.566406 L 7.246094 181.28125 L 9.175781 183.851562 L 10.140625 182.566406 L 7.246094 178.710938 L 8.210938 177.421875 L 3.429688 170.996094 L 3.429688 168.421875 L 10.140625 177.421875 L 9.175781 178.710938 L 12.070312 182.566406 L 10.140625 185.136719 L 12.070312 187.707031 L 14.960938 183.851562 L 21.75 193.003906 L 14.960938 201.851562 L 12.070312 197.996094 L 10.140625 200.566406 L 12.070312 203.136719 L 9.175781 206.992188 L 10.140625 208.28125 Z M 3.429688 217.28125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 3.429688 185.136719 L 4.390625 187.710938 L 4.390625 191.566406 L 7.285156 191.566406 L 9.214844 192.851562 L 7.285156 194.136719 L 4.390625 194.136719 L 4.390625 197.996094 L 3.429688 200.566406 Z M 3.429688 185.136719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 3.429688 275.132812 L 3.429688 272.5625 L 8.210938 266.132812 L 7.246094 264.847656 L 10.140625 260.988281 L 9.175781 259.703125 L 7.246094 262.277344 L 6.285156 260.988281 L 13.035156 251.992188 L 13.996094 253.277344 L 13.035156 254.5625 L 15.019531 257.160156 L 19.785156 250.703125 L 14.941406 244.449219 L 13.035156 246.847656 L 13.996094 248.132812 L 13.035156 249.417969 L 6.285156 240.417969 L 7.246094 239.132812 L 9.175781 241.707031 L 10.140625 240.417969 L 7.246094 236.5625 L 8.210938 235.277344 L 3.429688 228.847656 L 3.429688 226.277344 L 10.140625 235.277344 L 9.175781 236.5625 L 12.070312 240.417969 L 10.140625 242.992188 L 12.070312 245.5625 L 14.960938 241.707031 L 21.75 250.859375 L 14.960938 259.703125 L 12.070312 255.847656 L 10.140625 258.417969 L 12.070312 260.988281 L 9.175781 264.847656 L 10.140625 266.132812 Z M 3.429688 275.132812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 3.429688 242.992188 L 4.390625 245.5625 L 4.390625 249.417969 L 7.285156 249.417969 L 9.214844 250.707031 L 7.285156 251.992188 L 4.390625 251.992188 L 4.390625 255.847656 L 3.429688 258.417969 Z M 3.429688 242.992188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 119.136719 93.855469 L 119.136719 91.28125 L 114.355469 84.855469 L 115.316406 83.570312 L 112.425781 79.710938 L 113.390625 78.425781 L 115.316406 80.996094 L 116.28125 79.710938 L 109.53125 70.710938 L 108.570312 71.996094 L 109.53125 73.285156 L 107.546875 75.882812 L 102.78125 69.425781 L 107.625 63.171875 L 109.53125 65.570312 L 108.570312 66.855469 L 109.53125 68.140625 L 116.28125 59.140625 L 115.316406 57.855469 L 113.390625 60.425781 L 112.425781 59.140625 L 115.316406 55.285156 L 114.355469 53.996094 L 119.136719 47.570312 L 119.136719 45 L 112.425781 53.996094 L 113.390625 55.285156 L 110.496094 59.140625 L 112.425781 61.710938 L 110.496094 64.28125 L 107.605469 60.425781 L 100.816406 69.578125 L 107.605469 78.425781 L 110.496094 74.570312 L 112.425781 77.140625 L 110.496094 79.710938 L 113.390625 83.570312 L 112.425781 84.855469 Z M 119.136719 93.855469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 119.136719 61.710938 L 118.171875 64.285156 L 118.171875 68.140625 L 115.28125 68.140625 L 113.351562 69.425781 L 115.28125 70.710938 L 118.171875 70.710938 L 118.171875 74.570312 L 119.136719 77.140625 Z M 119.136719 61.710938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 119.457031 155.566406 L 119.457031 152.996094 L 114.671875 146.566406 L 115.636719 145.28125 L 112.746094 141.425781 L 113.707031 140.140625 L 115.636719 142.710938 L 116.601562 141.425781 L 109.851562 132.425781 L 108.886719 133.710938 L 109.851562 134.996094 L 107.863281 137.59375 L 103.101562 131.140625 L 107.945312 124.882812 L 109.851562 127.28125 L 108.886719 128.566406 L 109.851562 129.851562 L 116.601562 120.855469 L 115.636719 119.566406 L 113.707031 122.140625 L 112.746094 120.855469 L 115.636719 116.996094 L 114.671875 115.710938 L 119.457031 109.28125 L 119.457031 106.710938 L 112.746094 115.710938 L 113.707031 116.996094 L 110.816406 120.855469 L 112.746094 123.425781 L 110.816406 125.996094 L 107.921875 122.140625 L 101.136719 131.292969 L 107.921875 140.140625 L 110.816406 136.28125 L 112.746094 138.851562 L 110.816406 141.425781 L 113.707031 145.28125 L 112.746094 146.566406 Z M 119.457031 155.566406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 119.457031 123.425781 L 118.492188 125.996094 L 118.492188 129.855469 L 115.601562 129.855469 L 113.671875 131.140625 L 115.601562 132.425781 L 118.492188 132.425781 L 118.492188 136.28125 L 119.457031 138.855469 Z M 119.457031 123.425781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 119.136719 217.28125 L 119.136719 214.707031 L 114.355469 208.28125 L 115.316406 206.992188 L 112.425781 203.136719 L 113.390625 201.851562 L 115.316406 204.421875 L 116.28125 203.136719 L 109.53125 194.136719 L 108.570312 195.421875 L 109.53125 196.707031 L 107.546875 199.304688 L 102.78125 192.851562 L 107.625 186.597656 L 109.53125 188.996094 L 108.570312 190.28125 L 109.53125 191.566406 L 116.28125 182.566406 L 115.316406 181.28125 L 113.390625 183.851562 L 112.425781 182.566406 L 115.316406 178.710938 L 114.355469 177.421875 L 119.136719 170.996094 L 119.136719 168.421875 L 112.425781 177.421875 L 113.390625 178.710938 L 110.496094 182.566406 L 112.425781 185.136719 L 110.496094 187.707031 L 107.605469 183.851562 L 100.816406 193.003906 L 107.605469 201.851562 L 110.496094 197.996094 L 112.425781 200.566406 L 110.496094 203.136719 L 113.390625 206.992188 L 112.425781 208.28125 Z M 119.136719 217.28125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 119.136719 185.136719 L 118.171875 187.710938 L 118.171875 191.566406 L 115.28125 191.566406 L 113.351562 192.851562 L 115.28125 194.136719 L 118.171875 194.136719 L 118.171875 197.996094 L 119.136719 200.566406 Z M 119.136719 185.136719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 119.136719 275.132812 L 119.136719 272.5625 L 114.355469 266.132812 L 115.316406 264.847656 L 112.425781 260.988281 L 113.390625 259.703125 L 115.316406 262.277344 L 116.28125 260.988281 L 109.53125 251.992188 L 108.570312 253.277344 L 109.53125 254.5625 L 107.546875 257.160156 L 102.78125 250.703125 L 107.625 244.449219 L 109.53125 246.847656 L 108.570312 248.132812 L 109.53125 249.417969 L 116.28125 240.417969 L 115.316406 239.132812 L 113.390625 241.707031 L 112.425781 240.417969 L 115.316406 236.5625 L 114.355469 235.277344 L 119.136719 228.847656 L 119.136719 226.277344 L 112.425781 235.277344 L 113.390625 236.5625 L 110.496094 240.417969 L 112.425781 242.992188 L 110.496094 245.5625 L 107.605469 241.707031 L 100.816406 250.859375 L 107.605469 259.703125 L 110.496094 255.847656 L 112.425781 258.417969 L 110.496094 260.988281 L 113.390625 264.847656 L 112.425781 266.132812 Z M 119.136719 275.132812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 119.136719 242.992188 L 118.171875 245.5625 L 118.171875 249.417969 L 115.28125 249.417969 L 113.351562 250.707031 L 115.28125 251.992188 L 118.171875 251.992188 L 118.171875 255.847656 L 119.136719 258.417969 Z M 119.136719 242.992188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 3.429688 24.425781 L 3.429688 21.855469 L 8.214844 15.425781 L 7.25 14.140625 L 10.140625 10.285156 L 9.179688 9 L 7.25 11.570312 L 6.285156 10.285156 L 13.035156 1.285156 L 14 2.570312 L 13.035156 3.855469 L 15.023438 6.453125 L 19.785156 0 L 21.75 0 L 14.964844 9 L 12.070312 5.140625 L 10.140625 7.714844 L 12.070312 10.285156 L 9.179688 14.140625 L 10.140625 15.425781 Z M 3.429688 24.425781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 3.429688 0 L 9.214844 0 L 7.289062 1.285156 L 4.394531 1.285156 L 4.394531 5.144531 L 3.429688 7.714844 Z M 3.429688 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 119.136719 24.425781 L 119.136719 21.855469 L 114.351562 15.425781 L 115.316406 14.140625 L 112.421875 10.285156 L 113.386719 9 L 115.316406 11.570312 L 116.28125 10.285156 L 109.53125 1.285156 L 108.566406 2.570312 L 109.53125 3.855469 L 107.542969 6.453125 L 102.78125 0 L 100.8125 0 L 107.601562 9 L 110.496094 5.140625 L 112.421875 7.714844 L 110.496094 10.285156 L 113.386719 14.140625 L 112.421875 15.425781 Z M 119.136719 24.425781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 119.136719 0 L 113.347656 0 L 115.277344 1.285156 L 118.171875 1.285156 L 118.171875 5.144531 L 119.136719 7.714844 Z M 119.136719 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 51.648438 354.863281 C 51.648438 354.863281 55.800781 347.574219 66.261719 345.363281 C 76.722656 343.15625 78.550781 350.222656 84.527344 350.222656 C 90.503906 350.222656 99.96875 345.363281 99.96875 345.363281 C 99.96875 345.363281 90.503906 354.640625 83.199219 354.421875 C 75.890625 354.199219 73.402344 349.5625 65.929688 350.222656 C 58.457031 350.886719 51.648438 355.082031 51.648438 354.863281 Z M 51.648438 354.863281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 71.84375 354.863281 C 71.84375 354.863281 67.691406 347.574219 57.230469 345.363281 C 46.769531 343.15625 44.941406 350.222656 38.964844 350.222656 C 32.988281 350.222656 23.523438 345.363281 23.523438 345.363281 C 23.523438 345.363281 32.988281 354.640625 40.292969 354.421875 C 47.601562 354.199219 50.089844 349.5625 57.5625 350.222656 C 65.035156 350.886719 71.84375 355.082031 71.84375 354.863281 Z M 71.84375 354.863281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 37.636719 342.804688 C 36.714844 340.875 34.410156 332.460938 35.640625 331.410156 C 37.480469 330.707031 42.394531 339.296875 41.933594 347.011719 C 38.710938 348.765625 27.8125 346.308594 27.964844 344.03125 C 28.730469 340.875 34.871094 341.929688 37.636719 342.804688 Z M 37.636719 342.804688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 31.046875 335.449219 C 30.445312 333.308594 29.496094 324.445312 30.859375 323.753906 C 32.769531 323.574219 36.246094 333.328125 34.601562 340.75 C 31.175781 341.578125 20.894531 336.175781 21.394531 333.988281 C 22.636719 331.113281 28.480469 333.832031 31.046875 335.449219 Z M 31.046875 335.449219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 25.695312 327.222656 C 25.289062 325.003906 25.132812 316.046875 26.550781 315.578125 C 28.464844 315.703125 31.046875 325.941406 28.753906 333.046875 C 25.277344 333.328125 15.546875 326.335938 16.242188 324.246094 C 17.726562 321.585938 23.289062 325.210938 25.695312 327.222656 Z M 25.695312 327.222656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 21.476562 318.460938 C 21.332031 316.183594 22.21875 307.304688 23.671875 307.132812 C 25.550781 307.652344 26.910156 318.300781 23.820312 324.847656 C 20.355469 324.40625 11.550781 315.488281 12.480469 313.566406 C 14.257812 311.246094 19.332031 315.976562 21.476562 318.460938 Z M 21.476562 318.460938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 18.539062 309.28125 C 18.640625 307 20.476562 298.382812 21.933594 298.492188 C 23.734375 299.363281 23.9375 310.164062 20.171875 316.050781 C 16.789062 314.949219 9.039062 304.441406 10.167969 302.714844 C 12.175781 300.757812 16.6875 306.410156 18.539062 309.28125 Z M 18.539062 309.28125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 19.359375 298.839844 C 19.109375 307.976562 17.449219 308.085938 14.417969 301.328125 C 13.074219 298.777344 12.910156 288.179688 14.117188 287.78125 C 15.867188 287.746094 19.3125 295.304688 19.359375 298.839844 Z M 19.359375 298.839844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 85.335938 343.765625 C 86.257812 341.839844 88.558594 333.425781 87.332031 332.371094 C 85.488281 331.671875 80.578125 340.261719 81.039062 347.972656 C 84.261719 349.726562 95.160156 347.273438 95.007812 344.992188 C 94.238281 341.839844 88.097656 342.890625 85.335938 343.765625 Z M 85.335938 343.765625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 91.921875 336.414062 C 92.527344 334.273438 93.476562 325.40625 92.113281 324.714844 C 90.199219 324.535156 86.722656 334.292969 88.367188 341.710938 C 91.792969 342.539062 102.078125 337.140625 101.578125 334.953125 C 100.335938 332.074219 94.492188 334.792969 91.921875 336.414062 Z M 91.921875 336.414062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 97.277344 328.183594 C 97.683594 325.964844 97.839844 317.011719 96.421875 316.539062 C 94.507812 316.664062 91.925781 326.902344 94.21875 334.011719 C 97.691406 334.289062 107.425781 327.300781 106.730469 325.207031 C 105.242188 322.546875 99.679688 326.171875 97.277344 328.183594 Z M 97.277344 328.183594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 101.496094 319.421875 C 101.640625 317.144531 100.753906 308.265625 99.300781 308.09375 C 97.421875 308.613281 96.058594 319.261719 99.152344 325.808594 C 102.617188 325.367188 111.417969 316.449219 110.492188 314.527344 C 108.710938 312.207031 103.640625 316.9375 101.496094 319.421875 Z M 101.496094 319.421875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 104.429688 310.246094 C 104.328125 307.960938 102.492188 299.347656 101.035156 299.453125 C 99.234375 300.328125 99.035156 311.125 102.800781 317.011719 C 106.183594 315.914062 113.929688 305.402344 112.804688 303.675781 C 110.796875 301.722656 106.285156 307.375 104.429688 310.246094 Z M 104.429688 310.246094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 103.613281 299.800781 C 103.859375 308.941406 105.523438 309.046875 108.554688 302.292969 C 109.894531 299.738281 110.0625 289.140625 108.851562 288.746094 C 107.105469 288.707031 103.660156 296.265625 103.613281 299.800781 Z M 103.613281 299.800781 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;stroke-width:0.13333;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3.542393 290.549095 L -8.858385 290.549095 L -8.858385 294.09395 L -14.174377 294.09395 L -14.174377 297.635264 L -35.434612 297.635264 L -38.977363 301.180119 L -37.204121 302.950776 L -28.345379 302.950776 L -26.57587 301.180119 L -35.434612 301.180119 L -33.661371 299.409462 L -21.259878 299.409462 L -26.57587 304.721433 L -38.977363 304.721433 L -42.520113 302.950776 L -46.062864 304.721433 L -58.464356 304.721433 L -63.780349 299.405921 L -51.378856 299.409462 L -49.605614 301.180119 L -58.464356 301.176578 L -56.694848 302.950776 L -47.836105 302.950776 L -46.062864 301.180119 L -49.605614 297.635264 L -69.096341 297.635264 L -69.096341 294.09395 L -74.4086 294.09395 L -74.4086 290.549095 L -79.724592 290.549095 L -79.724592 274.6061 L -74.4086 274.6061 L -74.4086 271.061245 L -69.096341 271.061245 L -69.096341 267.519931 L -49.605614 267.519931 L -46.062864 263.975076 L -47.836105 262.204419 L -56.694848 262.204419 L -58.464356 263.978617 L -49.605614 263.975076 L -51.378856 265.745733 L -63.780349 265.749274 L -58.464356 260.433762 L -46.062864 260.433762 L -42.520113 262.204419 L -38.977363 260.433762 L -26.57587 260.433762 L -21.259878 265.745733 L -33.661371 265.745733 L -35.434612 263.975076 L -26.57587 263.975076 L -28.345379 262.204419 L -37.204121 262.204419 L -38.977363 263.975076 L -35.434612 267.519931 L -14.174377 267.519931 L -14.174377 271.061245 L -8.858385 271.061245 L -8.858385 274.6061 L -3.542393 274.6061 Z M -3.542393 290.549095 "
      transform="matrix(1.046371,0,0,1.103051,104.843376,-89.279552)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,60%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 375.588641 499.667257 L 375.588641 524.470619 L 386.220768 524.470619 L 386.220768 531.489503 L 396.845429 531.489503 L 396.845429 538.579214 L 432.280875 538.579214 L 446.459533 549.210238 L 460.630725 538.579214 L 499.605236 538.579214 L 499.605236 531.56033 L 510.237363 531.489503 L 510.237363 524.406876 L 520.862024 524.406876 L 520.862024 499.603514 L 510.237363 499.603514 L 510.237363 492.513803 L 499.605236 492.58463 L 499.605236 485.431176 L 460.630725 485.431176 L 446.459533 474.800152 L 432.280875 485.431176 L 396.845429 485.431176 L 396.845429 492.58463 L 386.220768 492.58463 L 386.220768 499.667257 Z M 375.588641 499.667257 "
      transform="matrix(0.523178,0,0,0.551526,-173.222547,-59.96597)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 49.234375 209.71875 L 38.109375 219.488281 L 38.109375 209.71875 Z M 49.234375 209.71875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 71.46875 209.714844 L 82.59375 219.488281 L 82.59375 209.714844 Z M 71.46875 209.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 49.234375 235.125 L 38.109375 225.355469 L 38.109375 235.125 Z M 49.234375 235.125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 71.472656 235.125 L 82.59375 225.355469 L 82.59375 235.125 Z M 71.472656 235.125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 75.183594 215.578125 L 71.476562 219.488281 L 78.890625 219.488281 Z M 75.183594 215.578125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 45.523438 215.578125 L 41.816406 219.488281 L 49.230469 219.488281 Z M 45.523438 215.578125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 45.523438 229.265625 L 41.816406 225.359375 L 49.230469 225.359375 Z M 45.523438 229.265625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 75.183594 229.265625 L 71.476562 225.359375 L 78.890625 225.359375 Z M 75.183594 229.265625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 60.351562 239.035156 L 56.644531 235.125 L 64.058594 235.125 Z M 60.351562 239.035156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 60.351562 219.492188 L 56.644531 215.582031 L 64.058594 215.582031 Z M 60.351562 219.492188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 60.351562 205.808594 L 56.644531 209.714844 L 64.058594 209.714844 Z M 60.351562 205.808594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 60.351562 225.347656 L 56.644531 229.257812 L 64.058594 229.257812 Z M 60.351562 225.347656 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;stroke-width:0.13333;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 467.716321 506.693224 L 481.894979 506.693224 L 481.894979 510.234538 L 467.716321 510.234538 Z M 467.716321 506.693224 "
      transform="matrix(0.523178,0,0,0.551526,-173.222547,-59.96597)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(80%,0%,0%);fill-opacity:1;stroke-width:0.13333;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 414.570618 510.24162 L 421.656214 510.24162 L 421.656214 513.782934 L 414.570618 513.782934 Z M 414.570618 510.24162 "
      transform="matrix(0.523178,0,0,0.551526,-173.222547,-59.96597)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(80%,0%,0%);fill-opacity:1;stroke-width:0.13333;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 471.262852 510.24162 L 478.348448 510.24162 L 478.348448 513.782934 L 471.262852 513.782934 Z M 471.262852 510.24162 "
      transform="matrix(0.523178,0,0,0.551526,-173.222547,-59.96597)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(60%,0%,0%);fill-opacity:1;stroke-width:0.13333;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 439.366471 488.979572 L 453.545129 488.979572 L 453.545129 492.520886 L 439.366471 492.520886 Z M 439.366471 488.979572 "
      transform="matrix(0.523178,0,0,0.551526,-173.222547,-59.96597)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(60%,0%,0%);fill-opacity:1;stroke-width:0.13333;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 439.366471 496.0622 L 453.545129 496.0622 L 453.545129 499.603514 L 439.366471 499.603514 Z M 439.366471 496.0622 "
      transform="matrix(0.523178,0,0,0.551526,-173.222547,-59.96597)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(60%,0%,0%);fill-opacity:1;stroke-width:0.13333;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 435.827406 492.520886 L 457.09166 492.520886 L 457.09166 496.0622 L 435.827406 496.0622 Z M 435.827406 492.520886 "
      transform="matrix(0.523178,0,0,0.551526,-173.222547,-59.96597)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(60%,0%,0%);fill-opacity:1;stroke-width:0.13333;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 439.366471 524.406876 L 453.545129 524.406876 L 453.545129 527.955272 L 439.366471 527.955272 Z M 439.366471 524.406876 "
      transform="matrix(0.523178,0,0,0.551526,-173.222547,-59.96597)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(60%,0%,0%);fill-opacity:1;stroke-width:0.13333;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 439.366471 531.496586 L 453.545129 531.496586 L 453.545129 535.044982 L 439.366471 535.044982 Z M 439.366471 531.496586 "
      transform="matrix(0.523178,0,0,0.551526,-173.222547,-59.96597)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(60%,0%,0%);fill-opacity:1;stroke-width:0.13333;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 435.827406 527.94819 L 457.09166 527.94819 L 457.09166 531.496586 L 435.827406 531.496586 Z M 435.827406 527.94819 "
      transform="matrix(0.523178,0,0,0.551526,-173.222547,-59.96597)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;stroke-width:0.13333;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 467.716321 513.782934 L 481.894979 513.782934 L 481.894979 517.324248 L 467.716321 517.324248 Z M 467.716321 513.782934 "
      transform="matrix(0.523178,0,0,0.551526,-173.222547,-59.96597)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;stroke-width:0.13333;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 411.01662 506.693224 L 425.195279 506.693224 L 425.195279 510.234538 L 411.01662 510.234538 Z M 411.01662 506.693224 "
      transform="matrix(0.523178,0,0,0.551526,-173.222547,-59.96597)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;stroke-width:0.13333;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 411.01662 513.782934 L 425.195279 513.782934 L 425.195279 517.324248 L 411.01662 517.324248 Z M 411.01662 513.782934 "
      transform="matrix(0.523178,0,0,0.551526,-173.222547,-59.96597)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;stroke-width:0.13333;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 407.477556 510.24162 L 414.563152 510.24162 L 414.563152 513.782934 L 407.477556 513.782934 Z M 407.477556 510.24162 "
      transform="matrix(0.523178,0,0,0.551526,-173.222547,-59.96597)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;stroke-width:0.13333;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 421.648748 510.24162 L 428.734344 510.24162 L 428.734344 513.782934 L 421.648748 513.782934 Z M 421.648748 510.24162 "
      transform="matrix(0.523178,0,0,0.551526,-173.222547,-59.96597)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;stroke-width:0.13333;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 464.16979 510.24162 L 471.255386 510.24162 L 471.255386 513.782934 L 464.16979 513.782934 Z M 464.16979 510.24162 "
      transform="matrix(0.523178,0,0,0.551526,-173.222547,-59.96597)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;stroke-width:0.13333;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.348448 510.24162 L 485.434044 510.24162 L 485.434044 513.782934 L 478.348448 513.782934 Z M 478.348448 510.24162 "
      transform="matrix(0.523178,0,0,0.551526,-173.222547,-59.96597)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 56.246094 232.203125 C 56.246094 231.933594 56.039062 231.714844 55.78125 231.714844 C 55.527344 231.714844 55.320312 231.933594 55.320312 232.203125 C 55.320312 232.472656 55.527344 232.691406 55.78125 232.691406 C 56.039062 232.691406 56.246094 232.472656 56.246094 232.203125 Z M 56.246094 232.203125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 60.863281 232.203125 C 60.863281 231.933594 60.65625 231.714844 60.402344 231.714844 C 60.144531 231.714844 59.9375 231.933594 59.9375 232.203125 C 59.9375 232.472656 60.144531 232.691406 60.402344 232.691406 C 60.65625 232.691406 60.863281 232.472656 60.863281 232.203125 Z M 60.863281 232.203125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 65.484375 232.160156 C 65.484375 231.890625 65.277344 231.671875 65.019531 231.671875 C 64.765625 231.671875 64.558594 231.890625 64.558594 232.160156 C 64.558594 232.429688 64.765625 232.648438 65.019531 232.648438 C 65.277344 232.648438 65.484375 232.429688 65.484375 232.160156 Z M 65.484375 232.160156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 58.09375 230.253906 C 58.09375 229.984375 57.886719 229.765625 57.632812 229.765625 C 57.375 229.765625 57.167969 229.984375 57.167969 230.253906 C 57.167969 230.523438 57.375 230.742188 57.632812 230.742188 C 57.886719 230.742188 58.09375 230.523438 58.09375 230.253906 Z M 58.09375 230.253906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 63.601562 230.214844 C 63.601562 229.945312 63.390625 229.726562 63.136719 229.726562 C 62.878906 229.726562 62.671875 229.945312 62.671875 230.214844 C 62.671875 230.484375 62.878906 230.703125 63.136719 230.703125 C 63.390625 230.703125 63.601562 230.484375 63.601562 230.214844 Z M 63.601562 230.214844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 58.054688 234.109375 C 58.054688 233.839844 57.84375 233.621094 57.589844 233.621094 C 57.332031 233.621094 57.125 233.839844 57.125 234.109375 C 57.125 234.378906 57.332031 234.597656 57.589844 234.597656 C 57.84375 234.597656 58.054688 234.378906 58.054688 234.109375 Z M 58.054688 234.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 63.601562 234.191406 C 63.601562 233.921875 63.390625 233.703125 63.136719 233.703125 C 62.878906 233.703125 62.671875 233.921875 62.671875 234.191406 C 62.671875 234.460938 62.878906 234.679688 63.136719 234.679688 C 63.390625 234.679688 63.601562 234.460938 63.601562 234.191406 Z M 63.601562 234.191406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 70.988281 222.339844 C 70.988281 222.070312 70.78125 221.851562 70.523438 221.851562 C 70.269531 221.851562 70.0625 222.070312 70.0625 222.339844 C 70.0625 222.609375 70.269531 222.828125 70.523438 222.828125 C 70.78125 222.828125 70.988281 222.609375 70.988281 222.339844 Z M 70.988281 222.339844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 75.613281 222.339844 C 75.613281 222.070312 75.40625 221.851562 75.152344 221.851562 C 74.894531 221.851562 74.6875 222.070312 74.6875 222.339844 C 74.6875 222.609375 74.894531 222.828125 75.152344 222.828125 C 75.40625 222.828125 75.613281 222.609375 75.613281 222.339844 Z M 75.613281 222.339844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 80.234375 222.300781 C 80.234375 222.03125 80.027344 221.8125 79.769531 221.8125 C 79.515625 221.8125 79.304688 222.03125 79.304688 222.300781 C 79.304688 222.570312 79.515625 222.789062 79.769531 222.789062 C 80.027344 222.789062 80.234375 222.570312 80.234375 222.300781 Z M 80.234375 222.300781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 72.835938 220.390625 C 72.835938 220.121094 72.628906 219.902344 72.375 219.902344 C 72.117188 219.902344 71.910156 220.121094 71.910156 220.390625 C 71.910156 220.660156 72.117188 220.878906 72.375 220.878906 C 72.628906 220.878906 72.835938 220.660156 72.835938 220.390625 Z M 72.835938 220.390625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 78.34375 220.351562 C 78.34375 220.082031 78.132812 219.863281 77.878906 219.863281 C 77.621094 219.863281 77.414062 220.082031 77.414062 220.351562 C 77.414062 220.621094 77.621094 220.839844 77.878906 220.839844 C 78.132812 220.839844 78.34375 220.621094 78.34375 220.351562 Z M 78.34375 220.351562 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 72.800781 224.246094 C 72.800781 223.976562 72.59375 223.757812 72.339844 223.757812 C 72.082031 223.757812 71.875 223.976562 71.875 224.246094 C 71.875 224.515625 72.082031 224.734375 72.339844 224.734375 C 72.59375 224.734375 72.800781 224.515625 72.800781 224.246094 Z M 72.800781 224.246094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 78.34375 224.328125 C 78.34375 224.058594 78.132812 223.839844 77.878906 223.839844 C 77.621094 223.839844 77.414062 224.058594 77.414062 224.328125 C 77.414062 224.597656 77.621094 224.816406 77.878906 224.816406 C 78.132812 224.816406 78.34375 224.597656 78.34375 224.328125 Z M 78.34375 224.328125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 56.203125 212.640625 C 56.203125 212.371094 55.996094 212.152344 55.742188 212.152344 C 55.484375 212.152344 55.277344 212.371094 55.277344 212.640625 C 55.277344 212.910156 55.484375 213.128906 55.742188 213.128906 C 55.996094 213.128906 56.203125 212.910156 56.203125 212.640625 Z M 56.203125 212.640625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 60.828125 212.640625 C 60.828125 212.371094 60.621094 212.152344 60.367188 212.152344 C 60.109375 212.152344 59.902344 212.371094 59.902344 212.640625 C 59.902344 212.910156 60.109375 213.128906 60.367188 213.128906 C 60.621094 213.128906 60.828125 212.910156 60.828125 212.640625 Z M 60.828125 212.640625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 65.449219 212.597656 C 65.449219 212.328125 65.242188 212.109375 64.984375 212.109375 C 64.730469 212.109375 64.523438 212.328125 64.523438 212.597656 C 64.523438 212.871094 64.730469 213.089844 64.984375 213.089844 C 65.242188 213.089844 65.449219 212.871094 65.449219 212.597656 Z M 65.449219 212.597656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 58.054688 210.691406 C 58.054688 210.421875 57.84375 210.203125 57.589844 210.203125 C 57.332031 210.203125 57.125 210.421875 57.125 210.691406 C 57.125 210.960938 57.332031 211.179688 57.589844 211.179688 C 57.84375 211.179688 58.054688 210.960938 58.054688 210.691406 Z M 58.054688 210.691406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 63.558594 210.652344 C 63.558594 210.382812 63.351562 210.164062 63.09375 210.164062 C 62.839844 210.164062 62.628906 210.382812 62.628906 210.652344 C 62.628906 210.921875 62.839844 211.140625 63.09375 211.140625 C 63.351562 211.140625 63.558594 210.921875 63.558594 210.652344 Z M 63.558594 210.652344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 58.019531 214.546875 C 58.019531 214.277344 57.808594 214.058594 57.554688 214.058594 C 57.296875 214.058594 57.089844 214.277344 57.089844 214.546875 C 57.089844 214.816406 57.296875 215.035156 57.554688 215.035156 C 57.808594 215.035156 58.019531 214.816406 58.019531 214.546875 Z M 58.019531 214.546875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 63.558594 214.628906 C 63.558594 214.359375 63.351562 214.140625 63.09375 214.140625 C 62.839844 214.140625 62.628906 214.359375 62.628906 214.628906 C 62.628906 214.898438 62.839844 215.117188 63.09375 215.117188 C 63.351562 215.117188 63.558594 214.898438 63.558594 214.628906 Z M 63.558594 214.628906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 41.382812 222.503906 C 41.382812 222.230469 41.175781 222.011719 40.921875 222.011719 C 40.664062 222.011719 40.457031 222.230469 40.457031 222.503906 C 40.457031 222.773438 40.664062 222.992188 40.921875 222.992188 C 41.175781 222.992188 41.382812 222.773438 41.382812 222.503906 Z M 41.382812 222.503906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 46.003906 222.503906 C 46.003906 222.230469 45.796875 222.011719 45.539062 222.011719 C 45.285156 222.011719 45.078125 222.230469 45.078125 222.503906 C 45.078125 222.773438 45.285156 222.992188 45.539062 222.992188 C 45.796875 222.992188 46.003906 222.773438 46.003906 222.503906 Z M 46.003906 222.503906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 50.621094 222.460938 C 50.621094 222.191406 50.414062 221.972656 50.160156 221.972656 C 49.902344 221.972656 49.695312 222.191406 49.695312 222.460938 C 49.695312 222.730469 49.902344 222.949219 50.160156 222.949219 C 50.414062 222.949219 50.621094 222.730469 50.621094 222.460938 Z M 50.621094 222.460938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 43.234375 220.554688 C 43.234375 220.285156 43.027344 220.066406 42.769531 220.066406 C 42.515625 220.066406 42.304688 220.285156 42.304688 220.554688 C 42.304688 220.824219 42.515625 221.042969 42.769531 221.042969 C 43.027344 221.042969 43.234375 220.824219 43.234375 220.554688 Z M 43.234375 220.554688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 48.738281 220.515625 C 48.738281 220.242188 48.53125 220.023438 48.273438 220.023438 C 48.019531 220.023438 47.8125 220.242188 47.8125 220.515625 C 47.8125 220.785156 48.019531 221.003906 48.273438 221.003906 C 48.53125 221.003906 48.738281 220.785156 48.738281 220.515625 Z M 48.738281 220.515625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 43.191406 224.410156 C 43.191406 224.140625 42.984375 223.921875 42.726562 223.921875 C 42.472656 223.921875 42.265625 224.140625 42.265625 224.410156 C 42.265625 224.679688 42.472656 224.898438 42.726562 224.898438 C 42.984375 224.898438 43.191406 224.679688 43.191406 224.410156 Z M 43.191406 224.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 48.738281 224.492188 C 48.738281 224.222656 48.53125 224.003906 48.273438 224.003906 C 48.019531 224.003906 47.8125 224.222656 47.8125 224.492188 C 47.8125 224.761719 48.019531 224.980469 48.273438 224.980469 C 48.53125 224.980469 48.738281 224.761719 48.738281 224.492188 Z M 48.738281 224.492188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 67.660156 179.828125 L 70.847656 178.332031 L 74.039062 179.828125 Z M 67.660156 179.828125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 58.09375 179.828125 L 61.285156 178.332031 L 64.472656 179.828125 Z M 58.09375 179.828125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 48.53125 179.828125 L 51.71875 178.332031 L 54.90625 179.828125 Z M 48.53125 179.828125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 67.660156 145.464844 L 70.847656 146.960938 L 74.039062 145.464844 Z M 67.660156 145.464844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 58.09375 145.464844 L 61.285156 146.960938 L 64.472656 145.464844 Z M 58.09375 145.464844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 48.53125 145.464844 L 51.71875 146.960938 L 54.90625 145.464844 Z M 48.53125 145.464844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;"
      d="M 65.003906 184.3125 L 61.28125 187.300781 L 57.5625 184.3125 L 53.84375 187.300781 L 50.121094 184.3125 L 50.121094 185.804688 L 52.601562 187.300781 L 51.363281 188.792969 L 51.363281 190.289062 L 52.601562 190.289062 L 53.84375 188.792969 L 55.082031 190.289062 L 56.324219 190.289062 L 56.324219 188.792969 L 55.082031 187.300781 L 57.5625 185.804688 L 60.042969 187.300781 L 58.800781 188.792969 L 58.800781 190.289062 L 60.042969 190.289062 L 61.28125 188.792969 L 62.523438 190.289062 L 63.761719 190.289062 L 63.761719 188.792969 L 62.523438 187.300781 L 65.003906 185.804688 L 67.484375 187.300781 L 66.242188 188.792969 L 66.242188 190.289062 L 67.484375 190.289062 L 68.722656 188.792969 L 69.960938 190.289062 L 71.203125 190.289062 L 71.203125 188.792969 L 69.960938 187.300781 L 72.441406 185.804688 L 72.441406 184.3125 L 68.722656 187.300781 Z M 65.003906 184.3125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;"
      d="M 65.003906 140.972656 L 61.285156 137.984375 L 57.5625 140.972656 L 53.84375 137.984375 L 50.125 140.972656 L 50.125 139.480469 L 52.601562 137.984375 L 51.363281 136.492188 L 51.363281 134.996094 L 52.601562 134.996094 L 53.84375 136.492188 L 55.082031 134.996094 L 56.324219 134.996094 L 56.324219 136.492188 L 55.082031 137.984375 L 57.5625 139.480469 L 60.042969 137.984375 L 58.804688 136.492188 L 58.804688 134.996094 L 60.042969 134.996094 L 61.285156 136.492188 L 62.523438 134.996094 L 63.761719 134.996094 L 63.761719 136.492188 L 62.523438 137.984375 L 65.003906 139.480469 L 67.484375 137.988281 L 66.242188 136.492188 L 66.242188 134.996094 L 67.484375 134.996094 L 68.722656 136.492188 L 69.964844 134.996094 L 71.203125 134.996094 L 71.203125 136.492188 L 69.964844 137.988281 L 72.441406 139.480469 L 72.441406 140.972656 L 68.722656 137.988281 Z M 65.003906 140.972656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;"
      d="M 93.179688 165.882812 L 97.164062 162.644531 L 93.179688 159.40625 L 97.164062 156.167969 L 93.179688 152.933594 L 95.171875 152.933594 L 97.164062 155.089844 L 99.15625 154.011719 L 101.148438 154.011719 L 101.148438 155.089844 L 99.15625 156.167969 L 101.148438 157.246094 L 101.148438 158.328125 L 99.15625 158.328125 L 97.164062 157.246094 L 95.171875 159.40625 L 97.164062 161.566406 L 99.15625 160.484375 L 101.148438 160.484375 L 101.148438 161.566406 L 99.15625 162.644531 L 101.148438 163.722656 L 101.148438 164.800781 L 99.15625 164.800781 L 97.164062 163.722656 L 95.171875 165.882812 L 97.164062 168.039062 L 99.15625 166.960938 L 101.148438 166.960938 L 101.148438 168.039062 L 99.15625 169.121094 L 101.148438 170.199219 L 101.148438 171.277344 L 99.15625 171.277344 L 97.164062 170.199219 L 95.171875 172.355469 L 93.179688 172.355469 L 97.164062 169.121094 Z M 93.179688 165.882812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;"
      d="M 29.390625 165.882812 L 25.40625 162.644531 L 29.390625 159.40625 L 25.40625 156.167969 L 29.390625 152.933594 L 27.402344 152.933594 L 25.40625 155.089844 L 23.414062 154.011719 L 21.421875 154.011719 L 21.421875 155.089844 L 23.414062 156.167969 L 21.421875 157.246094 L 21.421875 158.328125 L 23.414062 158.328125 L 25.40625 157.246094 L 27.398438 159.40625 L 25.40625 161.566406 L 23.414062 160.484375 L 21.421875 160.484375 L 21.421875 161.566406 L 23.414062 162.644531 L 21.421875 163.722656 L 21.421875 164.800781 L 23.414062 164.800781 L 25.40625 163.722656 L 27.398438 165.882812 L 25.40625 168.039062 L 23.414062 166.960938 L 21.421875 166.960938 L 21.421875 168.039062 L 23.414062 169.121094 L 21.421875 170.199219 L 21.421875 171.277344 L 23.414062 171.277344 L 25.40625 170.199219 L 27.402344 172.355469 L 29.390625 172.355469 L 25.40625 169.121094 Z M 29.390625 165.882812 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -26.575606 214.370389 L -24.804501 214.370389 L -24.804501 216.139635 L -26.575606 216.139635 Z M -26.575606 214.370389 "
      transform="matrix(0.899862,0,0,0.843403,118.676101,-3.960806)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -28.34671 216.139635 L -26.575606 216.139635 L -26.575606 217.913513 L -28.34671 217.913513 Z M -28.34671 216.139635 "
      transform="matrix(0.899862,0,0,0.843403,118.676101,-3.960806)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -37.206574 224.99976 L -35.435469 224.99976 L -35.435469 226.773637 L -37.206574 226.773637 Z M -37.206574 224.99976 "
      transform="matrix(0.899862,0,0,0.843403,118.676101,-3.960806)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -35.431128 223.230514 L -33.660024 223.230514 L -33.660024 224.99976 L -35.431128 224.99976 Z M -35.431128 223.230514 "
      transform="matrix(0.899862,0,0,0.843403,118.676101,-3.960806)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -93.899283 223.230514 L -92.128178 223.230514 L -92.128178 224.99976 L -93.899283 224.99976 Z M -93.899283 223.230514 "
      transform="matrix(0.899862,0,0,0.843403,118.676101,-3.960806)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -102.759146 214.370389 L -100.988042 214.370389 L -100.988042 216.139635 L -102.759146 216.139635 Z M -102.759146 214.370389 "
      transform="matrix(0.899862,0,0,0.843403,118.676101,-3.960806)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -100.97936 216.139635 L -99.208255 216.139635 L -99.208255 217.913513 L -100.97936 217.913513 Z M -100.97936 216.139635 "
      transform="matrix(0.899862,0,0,0.843403,118.676101,-3.960806)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -100.97936 177.169909 L -99.208255 177.169909 L -99.208255 178.943787 L -100.97936 178.943787 Z M -100.97936 177.169909 "
      transform="matrix(0.899862,0,0,0.843403,118.676101,-3.960806)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -102.759146 178.939155 L -100.988042 178.939155 L -100.988042 180.713033 L -102.759146 180.713033 Z M -102.759146 178.939155 "
      transform="matrix(0.899862,0,0,0.843403,118.676101,-3.960806)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -92.123837 168.309785 L -90.352733 168.309785 L -90.352733 170.083662 L -92.123837 170.083662 Z M -92.123837 168.309785 "
      transform="matrix(0.899862,0,0,0.843403,118.676101,-3.960806)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -93.899283 170.079031 L -92.128178 170.079031 L -92.128178 171.852908 L -93.899283 171.852908 Z M -93.899283 170.079031 "
      transform="matrix(0.899862,0,0,0.843403,118.676101,-3.960806)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -37.206574 168.309785 L -35.435469 168.309785 L -35.435469 170.083662 L -37.206574 170.083662 Z M -37.206574 168.309785 "
      transform="matrix(0.899862,0,0,0.843403,118.676101,-3.960806)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -35.431128 170.079031 L -33.660024 170.079031 L -33.660024 171.852908 L -35.431128 171.852908 Z M -35.431128 170.079031 "
      transform="matrix(0.899862,0,0,0.843403,118.676101,-3.960806)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -28.34671 177.169909 L -26.575606 177.169909 L -26.575606 178.943787 L -28.34671 178.943787 Z M -28.34671 177.169909 "
      transform="matrix(0.899862,0,0,0.843403,118.676101,-3.960806)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -26.575606 178.939155 L -24.804501 178.939155 L -24.804501 180.713033 L -26.575606 180.713033 Z M -26.575606 178.939155 "
      transform="matrix(0.899862,0,0,0.843403,118.676101,-3.960806)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -92.123837 224.99976 L -90.352733 224.99976 L -90.352733 226.773637 L -92.123837 226.773637 Z M -92.123837 224.99976 "
      transform="matrix(0.899862,0,0,0.843403,118.676101,-3.960806)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 56.417969 162.644531 L 51.71875 162.640625 L 51.71875 159.707031 L 54.851562 159.707031 L 54.851562 156.953125 L 57.984375 156.953125 L 57.984375 154.425781 L 61.121094 154.425781 L 61.121094 157.585938 C 58.523438 157.585938 56.417969 159.726562 56.417969 162.644531 Z M 56.417969 162.644531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 65.816406 162.644531 L 70.515625 162.640625 L 70.515625 159.707031 L 67.382812 159.707031 L 67.382812 156.953125 L 64.25 156.953125 L 64.25 154.425781 L 61.117188 154.425781 L 61.117188 157.585938 C 63.710938 157.585938 65.816406 159.726562 65.816406 162.644531 Z M 65.816406 162.644531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 65.816406 162.640625 L 70.515625 162.644531 L 70.515625 165.578125 L 67.382812 165.578125 L 67.382812 168.332031 L 64.25 168.332031 L 64.25 170.859375 L 61.117188 170.859375 L 61.117188 167.699219 C 63.710938 167.699219 65.816406 165.558594 65.816406 162.640625 Z M 65.816406 162.640625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 56.417969 162.640625 L 51.71875 162.644531 L 51.71875 165.578125 L 54.851562 165.578125 L 54.851562 168.332031 L 57.984375 168.332031 L 57.984375 170.859375 L 61.121094 170.859375 L 61.121094 167.699219 C 58.523438 167.699219 56.417969 165.558594 56.417969 162.640625 Z M 56.417969 162.640625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;"
      d="M 65.816406 162.644531 L 61.117188 162.644531 L 61.117188 157.585938 C 63.710938 157.585938 65.816406 159.851562 65.816406 162.644531 Z M 65.816406 162.644531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 65.816406 162.640625 L 61.117188 162.640625 L 61.117188 167.699219 C 63.710938 167.699219 65.816406 165.433594 65.816406 162.640625 Z M 65.816406 162.640625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;"
      d="M 56.417969 162.640625 L 61.121094 162.640625 L 61.121094 167.699219 C 58.523438 167.699219 56.417969 165.433594 56.417969 162.640625 Z M 56.417969 162.640625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 56.417969 162.644531 L 61.121094 162.644531 L 61.121094 157.585938 C 58.523438 157.585938 56.417969 159.851562 56.417969 162.644531 Z M 56.417969 162.644531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;"
      d="M 66.066406 181.320312 L 61.285156 178.328125 L 56.5 181.320312 L 51.71875 178.328125 L 46.933594 181.320312 L 46.933594 179.824219 L 50.125 178.328125 L 48.527344 176.835938 L 48.527344 175.339844 L 50.125 175.339844 L 51.71875 176.835938 L 53.3125 175.339844 L 54.90625 175.339844 L 54.90625 176.835938 L 53.3125 178.328125 L 56.5 179.824219 L 59.6875 178.328125 L 58.09375 176.835938 L 58.09375 175.339844 L 59.6875 175.339844 L 61.285156 176.835938 L 62.878906 175.339844 L 64.472656 175.339844 L 64.472656 176.835938 L 62.878906 178.328125 L 66.066406 179.824219 L 69.253906 178.332031 L 67.660156 176.835938 L 67.660156 175.339844 L 69.253906 175.339844 L 70.847656 176.835938 L 72.441406 175.339844 L 74.039062 175.339844 L 74.039062 176.835938 L 72.441406 178.332031 L 75.632812 179.824219 L 75.632812 181.320312 L 70.847656 178.332031 Z M 66.066406 181.320312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;"
      d="M 66.066406 143.96875 L 61.285156 146.957031 L 56.5 143.96875 L 51.71875 146.957031 L 46.933594 143.96875 L 46.933594 145.464844 L 50.125 146.957031 L 48.527344 148.453125 L 48.527344 149.945312 L 50.125 149.945312 L 51.71875 148.453125 L 53.3125 149.945312 L 54.90625 149.945312 L 54.90625 148.453125 L 53.3125 146.957031 L 56.5 145.464844 L 59.6875 146.957031 L 58.09375 148.453125 L 58.09375 149.945312 L 59.6875 149.945312 L 61.285156 148.453125 L 62.878906 149.945312 L 64.472656 149.945312 L 64.472656 148.453125 L 62.878906 146.957031 L 66.066406 145.464844 L 69.253906 146.957031 L 67.660156 148.453125 L 67.660156 149.945312 L 69.253906 149.945312 L 70.847656 148.453125 L 72.441406 149.945312 L 74.039062 149.945312 L 74.039062 148.453125 L 72.441406 146.957031 L 75.632812 145.464844 L 75.632812 143.96875 L 70.847656 146.957031 Z M 66.066406 143.96875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;"
      d="M 83.597656 166.378906 L 81.207031 162.640625 L 83.597656 158.90625 L 81.207031 155.171875 L 83.597656 151.433594 L 82.402344 151.433594 L 81.207031 153.925781 L 80.011719 152.679688 L 78.816406 152.679688 L 78.816406 153.925781 L 80.011719 155.171875 L 78.816406 156.414062 L 78.816406 157.660156 L 80.011719 157.660156 L 81.207031 156.414062 L 82.402344 158.90625 L 81.207031 161.398438 L 80.011719 160.152344 L 78.816406 160.152344 L 78.816406 161.398438 L 80.011719 162.640625 L 78.816406 163.886719 L 78.816406 165.132812 L 80.011719 165.132812 L 81.207031 163.886719 L 82.402344 166.378906 L 81.207031 168.867188 L 80.011719 167.621094 L 78.816406 167.621094 L 78.816406 168.867188 L 80.011719 170.113281 L 78.816406 171.359375 L 78.816406 172.605469 L 80.011719 172.605469 L 81.207031 171.359375 L 82.402344 173.847656 L 83.597656 173.847656 L 81.207031 170.113281 Z M 83.597656 166.378906 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;stroke-width:0.42667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -100.977907 177.168909 L -108.066667 184.255156 L -108.066667 210.830898 L -100.977907 217.917144 L -69.089344 217.917144 L -62.004926 210.830898 L -62.004926 184.255156 L -69.089344 177.168909 Z M -102.753353 171.851908 L -120.468739 189.572157 L -120.468739 205.513897 L -102.753353 223.234145 L -67.318239 223.234145 L -49.602853 205.513897 L -49.602853 189.572157 L -67.318239 171.851908 Z M -102.753353 171.851908 "
      transform="matrix(0.899862,0,0,0.843403,137.807607,-3.959962)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -93.900509 147.047056 L -90.357215 152.361794 L -86.813921 147.047056 Z M -93.900509 147.047056 "
      transform="matrix(0.71989,0,0,0.562263,121.22692,98.645084)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -93.900509 147.047923 L -90.357215 152.362661 L -86.813921 147.047923 Z M -93.900509 147.047923 "
      transform="matrix(0.71989,0,0,-0.562263,121.22692,226.648372)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -93.895293 147.047923 L -90.351999 152.362661 L -86.808704 147.047923 Z M -93.895293 147.047923 "
      transform="matrix(0.71989,0,0,-0.562263,131.430197,226.648372)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -93.895293 147.047056 L -90.351999 152.361794 L -86.808704 147.047056 Z M -93.895293 147.047056 "
      transform="matrix(0.71989,0,0,0.562263,131.430197,98.645084)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -93.900404 147.047923 L -90.35711 152.362661 L -86.813816 147.047923 Z M -93.900404 147.047923 "
      transform="matrix(0.71989,0,0,-0.562263,116.125282,226.648372)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -93.900614 147.047923 L -90.35732 152.362661 L -86.814026 147.047923 Z M -93.900614 147.047923 "
      transform="matrix(0.71989,0,0,-0.562263,126.328559,226.648372)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -93.895398 147.047923 L -90.352104 152.362661 L -86.808809 147.047923 Z M -93.895398 147.047923 "
      transform="matrix(0.71989,0,0,-0.562263,136.531835,226.648372)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;stroke-width:0.26668;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -104.53016 180.709788 L -100.984867 177.164884 L -104.53016 177.164884 Z M -104.53016 180.709788 "
      transform="matrix(1.349721,0,0,-1.26502,183.231054,405.441393)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;"
      d="M 38.964844 166.378906 L 41.355469 162.640625 L 38.964844 158.90625 L 41.355469 155.171875 L 38.964844 151.433594 L 40.160156 151.433594 L 41.355469 153.925781 L 42.550781 152.679688 L 43.75 152.679688 L 43.75 153.925781 L 42.550781 155.171875 L 43.75 156.414062 L 43.75 157.660156 L 42.550781 157.660156 L 41.355469 156.414062 L 40.160156 158.90625 L 41.355469 161.398438 L 42.550781 160.152344 L 43.75 160.152344 L 43.75 161.398438 L 42.550781 162.640625 L 43.75 163.886719 L 43.75 165.132812 L 42.550781 165.132812 L 41.355469 163.886719 L 40.160156 166.378906 L 41.355469 168.867188 L 42.550781 167.621094 L 43.75 167.621094 L 43.75 168.867188 L 42.550781 170.113281 L 43.75 171.359375 L 43.75 172.605469 L 42.550781 172.605469 L 41.355469 171.359375 L 40.160156 173.847656 L 38.964844 173.847656 L 41.355469 170.113281 Z M 38.964844 166.378906 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -76.178846 154.132154 L -69.094428 154.132154 L -67.323323 155.902389 L -69.094428 157.672625 L -76.178846 157.672625 Z M -76.178846 154.132154 "
      transform="matrix(0.899862,0,0,0.75908,153.749682,41.610794)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;stroke-width:0.4;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -99.212806 180.712033 L -102.755015 184.255156 L -102.755015 210.830898 L -99.212806 214.374021 L -104.530461 214.374021 L -104.52612 180.712033 Z M -99.212806 180.712033 "
      transform="matrix(0.899862,0,0,0.843403,131.430197,-3.959962)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -93.900404 147.047056 L -90.35711 152.361794 L -86.813816 147.047056 Z M -93.900404 147.047056 "
      transform="matrix(0.71989,0,0,0.562263,116.125282,98.645084)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -93.900614 147.047056 L -90.35732 152.361794 L -86.814026 147.047056 Z M -93.900614 147.047056 "
      transform="matrix(0.71989,0,0,0.562263,126.328559,98.645084)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -93.895398 147.047056 L -90.352104 152.361794 L -86.808809 147.047056 Z M -93.895398 147.047056 "
      transform="matrix(0.71989,0,0,0.562263,136.531835,98.645084)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;stroke-width:0.4;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -99.213486 180.712033 L -102.755695 184.255156 L -102.755695 210.830898 L -99.213486 214.374021 L -104.526799 214.374021 L -104.526799 180.712033 Z M -99.213486 180.712033 "
      transform="matrix(-0.899862,0,0,0.843403,-8.860496,-3.959962)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;stroke-width:0.26668;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -104.529664 180.709788 L -100.987265 177.164884 L -104.529664 177.164884 Z M -104.529664 180.709788 "
      transform="matrix(-1.349721,0,0,-1.26502,-60.667884,405.441393)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;stroke-width:0.26668;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -104.529664 180.708981 L -100.987265 177.167166 L -104.529664 177.167166 Z M -104.529664 180.708981 "
      transform="matrix(-1.349721,0,0,1.26502,-60.667884,-80.151311)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;stroke-width:0.26668;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -104.53016 180.708981 L -100.984867 177.167166 L -104.53016 177.167166 Z M -104.53016 180.708981 "
      transform="matrix(1.349721,0,0,1.26502,183.231054,-80.151311)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;stroke-width:0.42667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -100.977907 177.168909 L -108.066667 184.255156 L -108.066667 210.830898 L -100.977907 217.917144 L -69.089344 217.917144 L -62.004926 210.830898 L -62.004926 184.255156 L -69.089344 177.168909 Z M -100.982248 175.395032 L -109.837771 184.255156 L -109.837771 210.830898 L -100.977907 219.68639 L -69.089344 219.68639 L -60.233821 210.830898 L -60.233821 184.255156 L -69.093685 175.395032 Z M -100.982248 175.395032 "
      transform="matrix(0.899862,0,0,0.843403,137.807607,-3.959962)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -76.18238 154.131317 L -69.093621 154.131317 L -67.322517 155.901435 L -69.093621 157.671553 L -76.18238 157.671553 Z M -76.18238 154.131317 "
      transform="matrix(0.899862,0,0,0.75913,153.748956,44.291129)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -76.178846 154.131734 L -69.094428 154.131734 L -67.323323 155.90197 L -69.094428 157.672205 L -76.178846 157.672205 Z M -76.178846 154.131734 "
      transform="matrix(0.899862,0,0,0.75908,153.749682,46.990019)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -76.178846 154.130898 L -69.094428 154.130898 L -67.323323 155.901015 L -69.094428 157.671133 L -76.178846 157.671133 Z M -76.178846 154.130898 "
      transform="matrix(0.899862,0,0,0.75913,153.749682,49.670354)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -76.178846 154.131737 L -69.094428 154.131737 L -67.323323 155.901855 L -69.094428 157.671973 L -76.178846 157.671973 Z M -76.178846 154.131737 "
      transform="matrix(0.899862,0,0,0.75913,153.749682,38.911904)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -76.182751 154.132154 L -69.093992 154.132154 L -67.322888 155.902389 L -69.093992 157.672625 L -76.182751 157.672625 Z M -76.182751 154.132154 "
      transform="matrix(-0.899862,0,0,0.75908,-31.182883,41.610794)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -76.182751 154.130206 L -69.093992 154.130206 L -67.322888 155.900324 L -69.093992 157.670442 L -76.182751 157.670442 Z M -76.182751 154.130206 "
      transform="matrix(-0.899862,0,0,0.75913,-31.182883,44.291972)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -76.182751 154.131734 L -69.093992 154.131734 L -67.322888 155.90197 L -69.093992 157.672205 L -76.182751 157.672205 Z M -76.182751 154.131734 "
      transform="matrix(-0.899862,0,0,0.75908,-31.182883,46.990019)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -76.182751 154.129787 L -69.093992 154.129787 L -67.322888 155.899904 L -69.093992 157.675168 L -76.182751 157.675168 Z M -76.182751 154.129787 "
      transform="matrix(-0.899862,0,0,0.75913,-31.182883,49.671197)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -76.182751 154.130626 L -69.093992 154.130626 L -67.322888 155.900744 L -69.093992 157.670862 L -76.182751 157.670862 Z M -76.182751 154.130626 "
      transform="matrix(-0.899862,0,0,0.75913,-31.182883,38.912747)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(59.215686%,0%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -100.97936 178.939155 L -100.97936 216.144267 L -92.123837 216.144267 L -92.123837 225.004391 L -35.431128 225.004391 L -35.431128 216.144267 L -26.571265 216.144267 L -26.571265 178.939155 L -35.431128 178.939155 L -35.431128 170.083662 L -92.123837 170.083662 L -92.123837 178.939155 Z M -99.208255 180.713033 L -99.208255 214.375021 L -90.348392 214.375021 L -90.348392 223.230514 L -37.202233 223.230514 L -37.202233 214.375021 L -28.342369 214.375021 L -28.342369 180.713033 L -37.202233 180.713033 L -37.202233 171.852908 L -90.348392 171.852908 L -90.348392 180.713033 Z M -99.208255 180.713033 "
      transform="matrix(0.899862,0,0,0.843403,118.676101,-3.960806)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;stroke-width:0.26667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -28.348504 69.094565 L -33.662041 62.009693 L -33.662041 56.692427 L -38.975577 51.379977 L -49.606991 51.379977 L -58.467226 46.062711 L -86.810428 46.062711 L -95.670663 51.379977 L -106.297736 51.379977 L -111.615614 56.692427 L -111.615614 62.009693 L -116.92915 69.094565 L -116.92915 83.269125 L -111.615614 90.353997 L -111.615614 95.666446 L -106.297736 100.983713 L -95.670663 100.983713 L -86.810428 106.296162 L -58.467226 106.296162 L -49.606991 100.983713 L -38.975577 100.983713 L -33.662041 95.671263 L -33.662041 90.353997 L -28.348504 83.269125 Z M -28.348504 69.094565 "
      transform="matrix(0.899824,0,0,0.811037,126.645396,-25.788254)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 23.035156 36 L 23.035156 40.308594 L 27.816406 46.058594 L 27.816406 50.367188 L 32.601562 54.679688 L 42.164062 54.679688 L 50.136719 58.988281 L 61.296875 58.988281 L 61.296875 36 Z M 23.035156 36 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 47.828125 46.054688 L 42.007812 49.75 L 38.527344 49.75 L 39.6875 50.980469 L 37.367188 53.441406 L 38.527344 54.675781 L 42.007812 53.441406 L 43.164062 54.675781 L 43.703125 51.382812 L 50.121094 47.488281 Z M 47.828125 46.054688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 41.457031 43.179688 L 35.632812 46.875 L 32.152344 46.875 L 33.3125 48.105469 L 30.996094 50.570312 L 32.152344 51.800781 L 35.632812 50.570312 L 36.792969 51.800781 L 37.328125 48.511719 L 43.75 44.613281 Z M 41.457031 43.179688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 38.265625 37.433594 L 32.441406 41.128906 L 28.964844 41.128906 L 30.121094 42.363281 L 27.804688 44.824219 L 28.964844 46.054688 L 32.441406 44.824219 L 33.601562 46.054688 L 34.136719 42.765625 L 40.558594 38.871094 Z M 38.265625 37.433594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 99.53125 36 L 99.53125 31.6875 L 94.75 25.941406 L 94.75 21.628906 L 89.96875 17.320312 L 80.402344 17.320312 L 72.429688 13.007812 L 61.269531 13.007812 L 61.273438 36 Z M 99.53125 36 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 61.285156 58.988281 L 58.335938 58.988281 L 54.910156 56.113281 L 49.476562 56.113281 L 46.527344 54.675781 L 48.003906 53.242188 L 50.953125 54.675781 L 53.90625 54.675781 L 56.859375 50.367188 L 61.285156 51.804688 L 61.285156 54.675781 L 56.859375 54.675781 Z M 61.285156 58.988281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 23.035156 36 L 23.035156 31.6875 L 27.816406 25.941406 L 27.816406 21.628906 L 32.601562 17.320312 L 42.164062 17.320312 L 50.136719 13.007812 L 61.296875 13.007812 L 61.296875 36 Z M 23.035156 36 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 61.285156 13.007812 L 58.335938 13.007812 L 54.910156 15.878906 L 49.476562 15.878906 L 46.527344 17.316406 L 48.003906 18.753906 L 50.953125 17.316406 L 53.90625 17.316406 L 56.859375 21.628906 L 61.285156 20.191406 L 61.285156 17.316406 L 56.859375 17.316406 Z M 61.285156 13.007812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 61.285156 13.007812 L 64.234375 13.007812 L 67.660156 15.878906 L 73.09375 15.878906 L 76.046875 17.316406 L 74.570312 18.753906 L 71.617188 17.316406 L 68.664062 17.316406 L 65.710938 21.628906 L 61.285156 20.191406 L 61.285156 17.316406 L 65.710938 17.316406 Z M 61.285156 13.007812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 99.53125 36 L 99.53125 40.308594 L 94.75 46.058594 L 94.75 50.367188 L 89.96875 54.679688 L 80.402344 54.679688 L 72.429688 58.988281 L 61.269531 58.988281 L 61.273438 36 Z M 99.53125 36 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 61.285156 58.988281 L 64.234375 58.988281 L 67.660156 56.113281 L 73.09375 56.113281 L 76.046875 54.675781 L 74.570312 53.242188 L 71.617188 54.675781 L 68.664062 54.675781 L 65.710938 50.367188 L 61.285156 51.804688 L 61.285156 54.675781 L 65.710938 54.675781 Z M 61.285156 58.988281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 41.457031 28.8125 L 35.632812 25.117188 L 32.152344 25.117188 L 33.3125 23.886719 L 30.996094 21.421875 L 32.152344 20.191406 L 35.632812 21.421875 L 36.792969 20.191406 L 37.328125 23.480469 L 43.75 27.378906 Z M 41.457031 28.8125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 74.738281 25.941406 L 80.5625 22.246094 L 84.039062 22.246094 L 82.878906 21.015625 L 85.199219 18.550781 L 84.039062 17.320312 L 80.5625 18.550781 L 79.402344 17.320312 L 78.867188 20.609375 L 72.445312 24.507812 Z M 74.738281 25.941406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 47.828125 25.941406 L 42.007812 22.246094 L 38.527344 22.246094 L 39.6875 21.015625 L 37.367188 18.550781 L 38.527344 17.320312 L 42.007812 18.550781 L 43.164062 17.320312 L 43.703125 20.609375 L 50.121094 24.507812 Z M 47.828125 25.941406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 38.265625 34.5625 L 32.441406 30.867188 L 28.964844 30.867188 L 30.121094 29.636719 L 27.804688 27.171875 L 28.964844 25.941406 L 32.441406 27.171875 L 33.601562 25.941406 L 34.136719 29.234375 L 40.558594 33.128906 Z M 38.265625 34.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 25.867188 36.007812 L 27.34375 36.007812 L 28.820312 37.441406 L 30.292969 36.007812 L 61.292969 36.003906 L 61.292969 56.121094 L 59.816406 56.121094 L 61.292969 57.558594 L 61.292969 58.996094 L 56.863281 54.6875 L 56.863281 53.25 L 58.339844 53.25 L 58.339844 54.6875 L 59.816406 54.6875 L 59.816406 53.25 L 47.832031 46.902344 L 37.734375 46.902344 L 37.734375 40.675781 L 33.246094 37.441406 L 30.292969 37.441406 L 28.820312 38.878906 L 30.292969 40.316406 L 28.820312 40.316406 Z M 25.867188 36.007812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 25.867188 35.992188 L 27.34375 35.992188 L 28.820312 34.554688 L 30.292969 35.992188 L 61.292969 35.992188 L 61.292969 15.875 L 59.816406 15.875 L 61.292969 14.4375 L 61.292969 13 L 56.863281 17.3125 L 56.863281 18.75 L 58.339844 18.75 L 58.339844 17.3125 L 59.816406 17.3125 L 59.816406 18.75 L 47.832031 25.09375 L 37.734375 25.09375 L 37.734375 31.324219 L 33.246094 34.554688 L 30.292969 34.554688 L 28.820312 33.117188 L 30.292969 31.679688 L 28.820312 31.679688 Z M 25.867188 35.992188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;"
      d="M 32.59375 35.992188 L 61.292969 35.992188 L 61.292969 53.234375 L 48.539062 46.050781 L 38.972656 46.050781 L 38.972656 40.300781 Z M 32.59375 35.992188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 81.113281 28.8125 L 86.933594 25.117188 L 90.414062 25.117188 L 89.253906 23.886719 L 91.570312 21.421875 L 90.414062 20.191406 L 86.933594 21.421875 L 85.773438 20.191406 L 85.238281 23.480469 L 78.820312 27.378906 Z M 81.113281 28.8125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 84.304688 34.5625 L 90.125 30.867188 L 93.605469 30.867188 L 92.445312 29.636719 L 94.761719 27.171875 L 93.605469 25.941406 L 90.125 27.171875 L 88.964844 25.941406 L 88.429688 29.234375 L 82.011719 33.128906 Z M 84.304688 34.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 96.703125 35.992188 L 95.226562 35.992188 L 93.75 34.554688 L 92.273438 35.992188 L 61.273438 35.992188 L 61.273438 15.875 L 62.75 15.875 L 61.273438 14.4375 L 61.273438 13 L 65.703125 17.3125 L 65.703125 18.75 L 64.226562 18.75 L 64.226562 17.3125 L 62.75 17.3125 L 62.75 18.75 L 74.734375 25.09375 L 84.832031 25.09375 L 84.832031 31.324219 L 89.320312 34.554688 L 92.273438 34.554688 L 93.75 33.117188 L 92.273438 31.679688 L 93.75 31.679688 Z M 96.703125 35.992188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;"
      d="M 89.972656 36.007812 L 61.277344 36.007812 L 61.277344 18.765625 L 74.027344 25.949219 L 83.59375 25.949219 L 83.59375 31.695312 Z M 89.972656 36.007812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 74.738281 46.054688 L 80.5625 49.75 L 84.039062 49.75 L 82.878906 50.980469 L 85.199219 53.441406 L 84.039062 54.675781 L 80.5625 53.441406 L 79.402344 54.675781 L 78.867188 51.382812 L 72.445312 47.488281 Z M 74.738281 46.054688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 81.113281 43.179688 L 86.933594 46.875 L 90.414062 46.875 L 89.253906 48.105469 L 91.570312 50.570312 L 90.414062 51.800781 L 86.933594 50.570312 L 85.773438 51.800781 L 85.238281 48.511719 L 78.820312 44.613281 Z M 81.113281 43.179688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 84.304688 37.433594 L 90.125 41.128906 L 93.605469 41.128906 L 92.445312 42.363281 L 94.761719 44.824219 L 93.605469 46.054688 L 90.125 44.824219 L 88.964844 46.054688 L 88.429688 42.765625 L 82.011719 38.871094 Z M 84.304688 37.433594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 96.703125 36.007812 L 95.226562 36.007812 L 93.75 37.441406 L 92.273438 36.007812 L 61.273438 36.003906 L 61.273438 56.121094 L 62.75 56.121094 L 61.273438 57.558594 L 61.273438 58.996094 L 65.703125 54.6875 L 65.703125 53.25 L 64.226562 53.25 L 64.226562 54.6875 L 62.75 54.6875 L 62.75 53.25 L 74.734375 46.902344 L 84.832031 46.902344 L 84.832031 40.675781 L 89.320312 37.441406 L 92.273438 37.441406 L 93.75 38.878906 L 92.273438 40.316406 L 93.75 40.316406 Z M 96.703125 36.007812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 89.972656 35.992188 L 61.277344 35.992188 L 61.277344 53.234375 L 74.027344 46.050781 L 83.59375 46.050781 L 83.59375 40.300781 Z M 89.972656 35.992188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 32.59375 36.007812 L 61.292969 36.007812 L 61.292969 18.765625 L 48.539062 25.949219 L 38.972656 25.949219 L 38.972656 31.695312 Z M 32.59375 36.007812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 64.433594 36 L 72.441406 31.6875 L 77.222656 31.6875 L 75.628906 30.25 L 78.816406 27.378906 L 77.222656 25.941406 L 72.441406 27.378906 L 70.847656 25.941406 L 70.109375 29.78125 L 61.28125 34.324219 Z M 64.433594 36 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 58.132812 35.992188 L 50.125 40.300781 L 45.34375 40.300781 L 46.9375 41.738281 L 43.75 44.613281 L 45.34375 46.050781 L 50.125 44.613281 L 51.722656 46.050781 L 52.457031 42.210938 L 61.285156 37.664062 Z M 58.132812 35.992188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;"
      d="M 58.132812 36 L 50.125 31.6875 L 45.34375 31.6875 L 46.9375 30.25 L 43.75 27.378906 L 45.34375 25.941406 L 50.125 27.378906 L 51.722656 25.941406 L 52.457031 29.78125 L 61.285156 34.324219 Z M 58.132812 36 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;"
      d="M 64.433594 35.992188 L 72.441406 40.300781 L 77.222656 40.300781 L 75.628906 41.738281 L 78.816406 44.613281 L 77.222656 46.050781 L 72.441406 44.613281 L 70.847656 46.050781 L 70.109375 42.210938 L 61.28125 37.664062 Z M 64.433594 35.992188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 51.71875 36 L 61.285156 28.816406 L 70.851562 36 L 61.285156 43.183594 Z M 51.71875 36 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 30.289062 38.871094 L 31.761719 40.308594 L 30.289062 41.742188 L 28.8125 41.742188 L 24.382812 35.996094 L 25.859375 35.996094 L 28.8125 40.308594 L 30.289062 40.308594 L 28.8125 38.871094 L 30.289062 37.433594 L 33.238281 37.433594 L 33.238281 38.871094 Z M 30.289062 38.871094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 30.289062 33.125 L 31.761719 31.6875 L 30.289062 30.25 L 28.8125 30.25 L 24.382812 35.996094 L 25.859375 35.996094 L 28.8125 31.6875 L 30.289062 31.6875 L 28.8125 33.125 L 30.289062 34.558594 L 33.238281 34.558594 L 33.238281 33.125 Z M 30.289062 33.125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 92.28125 38.871094 L 90.804688 40.308594 L 92.28125 41.742188 L 93.757812 41.742188 L 98.1875 35.996094 L 96.710938 35.996094 L 93.757812 40.308594 L 92.28125 40.308594 L 93.757812 38.871094 L 92.28125 37.433594 L 89.332031 37.433594 L 89.332031 38.871094 Z M 92.28125 38.871094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 92.28125 33.125 L 90.804688 31.6875 L 92.28125 30.25 L 93.757812 30.25 L 98.1875 35.996094 L 96.710938 35.996094 L 93.757812 31.6875 L 92.28125 31.6875 L 93.757812 33.125 L 92.28125 34.558594 L 89.332031 34.558594 L 89.332031 33.125 Z M 92.28125 33.125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 59.808594 30.25 L 62.761719 30.25 L 62.761719 41.746094 L 59.808594 41.746094 Z M 59.808594 30.25 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 55.378906 33.125 L 67.1875 33.125 L 67.1875 38.871094 L 55.378906 38.871094 Z M 55.378906 33.125 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;stroke-width:0.070263;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 333.06975 170.080199 L 322.43986 175.39466 L 322.43986 184.253057 L 333.06975 189.567517 L 343.699639 189.567517 L 354.329529 184.253057 L 354.329529 175.39466 L 343.699639 170.080199 Z M 333.06975 170.080199 "
      transform="matrix(2.499585,0,0,2.704884,-784.563472,-205.476921)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 31.289062 278.628906 L 32.898438 278.628906 L 32.898438 275.679688 L 36.113281 275.679688 L 36.113281 278.628906 L 37.71875 278.628906 L 37.71875 271.753906 L 36.113281 271.753906 L 36.113281 274.699219 L 32.898438 274.699219 L 32.898438 271.753906 L 31.289062 271.753906 Z M 31.289062 278.628906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 72.003906 301.546875 L 73.609375 301.546875 L 73.609375 298.597656 L 76.824219 298.597656 L 76.824219 301.546875 L 78.429688 301.546875 L 78.429688 294.671875 L 76.824219 294.671875 L 76.824219 297.617188 L 73.609375 297.617188 L 73.609375 294.671875 L 72.003906 294.671875 Z M 72.003906 301.546875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 46.289062 267.167969 L 47.898438 267.167969 L 47.898438 264.222656 L 51.113281 264.222656 L 51.113281 267.167969 L 52.71875 267.167969 L 52.71875 260.292969 L 51.113281 260.292969 L 51.113281 263.242188 L 47.898438 263.242188 L 47.898438 260.292969 L 46.289062 260.292969 Z M 46.289062 267.167969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 69.863281 267.167969 L 71.46875 267.167969 L 71.46875 264.222656 L 74.683594 264.222656 L 74.683594 267.167969 L 76.289062 267.167969 L 76.289062 260.292969 L 74.683594 260.292969 L 74.683594 263.242188 L 71.46875 263.242188 L 71.46875 260.292969 L 69.863281 260.292969 Z M 69.863281 267.167969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 84.855469 278.628906 L 86.460938 278.628906 L 86.460938 275.679688 L 89.675781 275.679688 L 89.675781 278.628906 L 91.28125 278.628906 L 91.28125 271.753906 L 89.675781 271.753906 L 89.675781 274.699219 L 86.460938 274.699219 L 86.460938 271.753906 L 84.855469 271.753906 Z M 84.855469 278.628906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 84.855469 290.085938 L 86.460938 290.085938 L 86.460938 287.140625 L 89.675781 287.140625 L 89.675781 290.085938 L 91.28125 290.085938 L 91.28125 283.210938 L 89.675781 283.210938 L 89.675781 286.15625 L 86.460938 286.15625 L 86.460938 283.210938 L 84.855469 283.210938 Z M 84.855469 290.085938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 61.285156 306.132812 L 61.285156 280.921875 L 99.855469 280.921875 L 99.855469 292.382812 L 74.140625 306.132812 Z M 61.285156 306.132812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 61.285156 255.707031 L 61.285156 280.917969 L 22.714844 280.917969 L 22.714844 269.460938 L 48.429688 255.707031 Z M 61.285156 255.707031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 61.285156 255.707031 L 61.285156 280.917969 L 99.855469 280.917969 L 99.855469 269.460938 L 74.140625 255.707031 Z M 61.285156 255.707031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 61.285156 306.132812 L 61.285156 280.921875 L 22.714844 280.921875 L 22.714844 292.382812 L 48.429688 306.132812 Z M 61.285156 306.132812 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;stroke-width:0.13333;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -177.158036 368.501282 L -155.901483 368.501282 L -177.158036 357.872231 Z M -152.359621 366.725747 L -152.359621 384.444858 L -186.018073 384.444858 L -186.018073 370.270777 L -193.10718 370.270777 L -201.961834 364.956252 L -201.961834 352.557706 L -196.649041 347.237141 L -203.732765 347.237141 L -203.732765 364.956252 L -205.509079 364.956252 L -205.509079 345.467646 L -187.789004 345.467646 Z M -152.359621 366.725747 "
      transform="matrix(0.725695,0,0,0.646812,210.422131,32.254332)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 48.40625 272.894531 L 48.40625 288.933594 L 52.261719 288.933594 L 61.261719 293.519531 L 70.261719 288.933594 L 74.121094 288.933594 L 74.121094 272.894531 L 70.261719 272.894531 L 61.261719 268.308594 L 52.261719 272.894531 Z M 48.40625 272.894531 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;stroke-width:0.13333;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -177.158879 368.497726 L -155.902325 368.497726 L -177.158879 357.868675 Z M -152.355081 366.728231 L -152.360463 384.447341 L -186.018916 384.447341 L -186.018916 370.273261 L -193.102639 370.273261 L -201.962677 364.958735 L -201.962677 352.55415 L -196.649884 347.239625 L -203.733608 347.239625 L -203.733608 364.958735 L -205.504539 364.958735 L -205.504539 345.470129 L -187.789847 345.470129 Z M -152.355081 366.728231 "
      transform="matrix(-0.725695,0,0,-0.646812,-87.85243,529.587118)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.13333;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -177.158879 368.501282 L -155.902325 368.501282 L -177.158879 357.872231 Z M -152.355081 366.725747 L -152.360463 384.444858 L -186.018916 384.444858 L -186.018916 370.270777 L -193.102639 370.270777 L -201.962677 364.956252 L -201.962677 352.557706 L -196.649884 347.237141 L -203.733608 347.237141 L -203.733608 364.956252 L -205.504539 364.956252 L -205.504539 345.467646 L -187.789847 345.467646 Z M -152.355081 366.725747 "
      transform="matrix(-0.725695,0,0,0.646812,-87.85243,32.254332)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.13333;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -177.159036 368.497726 L -155.902483 368.497726 L -177.159036 357.868675 Z M -152.355238 366.728231 L -152.360621 384.447341 L -186.019073 384.447341 L -186.019073 370.273261 L -193.102797 370.273261 L -201.962834 364.958735 L -201.962834 352.55415 L -196.650041 347.239625 L -203.733765 347.239625 L -203.733765 364.958735 L -205.504696 364.958735 L -205.504696 345.470129 L -187.790004 345.470129 Z M -152.355238 366.728231 "
      transform="matrix(0.725695,0,0,-0.646812,210.422857,529.587118)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 30.433594 290.09375 L 32.039062 290.09375 L 32.039062 286.65625 L 35.257812 286.65625 L 35.257812 290.09375 L 36.863281 290.09375 L 36.863281 282.074219 L 35.257812 282.074219 L 35.257812 285.511719 L 32.039062 285.511719 L 32.039062 282.074219 L 30.433594 282.074219 Z M 30.433594 290.09375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 45.855469 301.550781 L 47.460938 301.550781 L 47.460938 298.113281 L 50.675781 298.113281 L 50.675781 301.550781 L 52.285156 301.550781 L 52.285156 293.53125 L 50.675781 293.53125 L 50.675781 296.96875 L 47.460938 296.96875 L 47.460938 293.53125 L 45.855469 293.53125 Z M 45.855469 301.550781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 30.433594 271.753906 L 32.039062 271.753906 L 32.039062 275.195312 L 35.257812 275.195312 L 35.257812 271.753906 L 36.863281 271.753906 L 36.863281 279.777344 L 35.257812 279.777344 L 35.257812 276.339844 L 32.039062 276.339844 L 32.039062 279.777344 L 30.433594 279.777344 Z M 30.433594 271.753906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 45.855469 260.296875 L 47.460938 260.296875 L 47.460938 263.734375 L 50.675781 263.734375 L 50.675781 260.296875 L 52.285156 260.296875 L 52.285156 268.316406 L 50.675781 268.316406 L 50.675781 264.878906 L 47.460938 264.878906 L 47.460938 268.316406 L 45.855469 268.316406 Z M 45.855469 260.296875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 70.289062 301.550781 L 71.894531 301.550781 L 71.894531 298.113281 L 75.109375 298.113281 L 75.109375 301.550781 L 76.71875 301.550781 L 76.71875 293.53125 L 75.109375 293.53125 L 75.109375 296.96875 L 71.894531 296.96875 L 71.894531 293.53125 L 70.289062 293.53125 Z M 70.289062 301.550781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 85.71875 279.773438 L 87.324219 279.773438 L 87.324219 276.335938 L 90.539062 276.335938 L 90.539062 279.773438 L 92.144531 279.773438 L 92.144531 271.753906 L 90.539062 271.753906 L 90.539062 275.191406 L 87.324219 275.191406 L 87.324219 271.753906 L 85.71875 271.753906 Z M 85.71875 279.773438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 70.289062 301.550781 L 71.894531 301.550781 L 71.894531 298.113281 L 75.109375 298.113281 L 75.109375 301.550781 L 76.71875 301.550781 L 76.71875 293.53125 L 75.109375 293.53125 L 75.109375 296.96875 L 71.894531 296.96875 L 71.894531 293.53125 L 70.289062 293.53125 Z M 70.289062 301.550781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 70.289062 301.550781 L 71.894531 301.550781 L 71.894531 298.113281 L 75.109375 298.113281 L 75.109375 301.550781 L 76.71875 301.550781 L 76.71875 293.53125 L 75.109375 293.53125 L 75.109375 296.96875 L 71.894531 296.96875 L 71.894531 293.53125 L 70.289062 293.53125 Z M 70.289062 301.550781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 85.71875 290.085938 L 87.324219 290.085938 L 87.324219 286.648438 L 90.539062 286.648438 L 90.539062 290.085938 L 92.144531 290.085938 L 92.144531 282.066406 L 90.539062 282.066406 L 90.539062 285.503906 L 87.324219 285.503906 L 87.324219 282.066406 L 85.71875 282.066406 Z M 85.71875 290.085938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 70.289062 268.316406 L 71.894531 268.316406 L 71.894531 264.878906 L 75.109375 264.878906 L 75.109375 268.316406 L 76.71875 268.316406 L 76.71875 260.292969 L 75.109375 260.292969 L 75.109375 263.730469 L 71.894531 263.730469 L 71.894531 260.292969 L 70.289062 260.292969 Z M 70.289062 268.316406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;"
      d="M 62.570312 280.921875 L 67.714844 276.742188 L 70.285156 276.742188 L 70.285156 278.832031 L 67.714844 278.832031 L 67.714844 279.875 L 71.570312 279.875 L 71.570312 275.699219 L 67.714844 275.695312 L 67.714844 272.5625 L 62.570312 272.5625 L 62.570312 275.695312 L 63.859375 275.695312 L 63.859375 273.605469 L 66.429688 273.605469 L 66.429688 275.695312 L 61.285156 279.875 L 56.144531 275.699219 L 56.144531 273.609375 L 58.714844 273.609375 L 58.714844 275.699219 L 60 275.699219 L 60 272.5625 L 54.859375 272.5625 L 54.859375 275.699219 L 51 275.699219 L 51 279.875 L 54.859375 279.875 L 54.859375 278.832031 L 52.285156 278.832031 L 52.285156 276.742188 L 54.859375 276.742188 L 60 280.921875 L 54.859375 285.097656 L 52.285156 285.097656 L 52.285156 283.007812 L 54.859375 283.007812 L 54.859375 281.964844 L 51 281.964844 L 51 286.144531 L 54.859375 286.144531 L 54.859375 289.277344 L 60 289.277344 L 60 286.144531 L 58.714844 286.144531 L 58.714844 288.234375 L 56.144531 288.234375 L 56.144531 286.144531 L 61.285156 281.964844 L 66.429688 286.144531 L 66.429688 288.234375 L 63.859375 288.234375 L 63.859375 286.144531 L 62.574219 286.144531 L 62.574219 289.277344 L 67.714844 289.277344 L 67.714844 286.144531 L 71.570312 286.144531 L 71.570312 281.964844 L 67.714844 281.964844 L 67.714844 283.011719 L 70.285156 283.011719 L 70.285156 285.097656 L 67.714844 285.097656 Z M 62.570312 280.921875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 25.210938 103.253906 L 27.699219 100.742188 L 42.628906 100.742188 L 53.824219 89.429688 L 68.757812 89.429688 L 79.953125 100.742188 L 94.886719 100.742188 L 97.375 103.253906 L 94.886719 105.765625 L 79.953125 105.765625 L 68.757812 117.078125 L 53.824219 117.078125 L 42.628906 105.765625 L 27.699219 105.765625 Z M 25.210938 103.253906 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;stroke-width:0.28986;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 372.050633 391.538557 L 373.952733 389.593695 L 396.789492 389.593695 L 413.914171 370.100438 L 432.940949 370.100438 L 450.065628 389.593695 L 472.902387 389.593695 L 474.804487 391.538557 L 472.902387 393.489796 L 450.065628 393.489796 L 434.843049 411.025437 L 412.012071 411.025437 L 396.789492 393.489796 L 373.952733 393.489796 Z M 372.050633 391.538557 "
      transform="matrix(0.675651,0,0,0.612592,-224.798393,-136.353249)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 67.503906 104.507812 L 72.480469 109.535156 L 69.992188 112.046875 L 68.746094 110.792969 L 67.503906 110.792969 L 67.503906 112.046875 L 68.746094 113.304688 L 71.238281 113.304688 L 73.726562 110.792969 L 73.726562 108.277344 L 68.75 103.253906 L 73.726562 98.226562 L 73.726562 95.710938 L 71.238281 93.199219 L 68.75 93.199219 L 67.503906 94.457031 L 67.503906 95.710938 L 68.75 95.710938 L 69.992188 94.457031 L 72.480469 96.96875 L 67.503906 101.996094 L 62.527344 96.96875 L 67.503906 91.941406 L 67.503906 90.6875 L 66.261719 90.6875 L 61.285156 95.710938 L 56.304688 90.6875 L 55.0625 90.6875 L 55.0625 91.941406 L 60.039062 96.96875 L 55.0625 101.996094 L 50.085938 96.96875 L 52.574219 94.457031 L 53.816406 95.710938 L 55.0625 95.710938 L 55.0625 94.457031 L 53.816406 93.199219 L 51.328125 93.199219 L 48.839844 95.710938 L 48.839844 98.226562 L 53.816406 103.253906 L 48.839844 108.28125 L 48.839844 110.792969 L 51.328125 113.304688 L 53.816406 113.304688 L 55.0625 112.050781 L 55.0625 110.792969 L 53.816406 110.792969 L 52.574219 112.050781 L 50.085938 109.535156 L 55.0625 104.507812 L 60.039062 109.535156 L 55.0625 114.5625 L 55.0625 115.820312 L 56.304688 115.820312 L 61.28125 110.792969 L 66.261719 115.820312 L 67.503906 115.820312 L 67.503906 114.5625 L 62.527344 109.535156 Z M 61.28125 98.226562 L 56.304688 103.253906 L 61.28125 108.28125 L 66.261719 103.253906 Z M 61.28125 98.226562 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 53.828125 117.070312 L 53.828125 127.125 L 55.070312 125.867188 L 56.316406 127.125 L 57.558594 125.867188 L 58.804688 127.125 L 60.050781 125.867188 L 61.292969 127.125 L 62.535156 125.867188 L 63.78125 127.125 L 65.023438 125.867188 L 66.269531 127.125 L 67.511719 125.867188 L 68.757812 127.125 L 68.757812 117.070312 Z M 53.828125 117.070312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 68.75 89.445312 L 68.75 79.390625 L 67.507812 80.648438 L 66.261719 79.390625 L 65.019531 80.648438 L 63.773438 79.390625 L 62.527344 80.648438 L 61.285156 79.390625 L 60.039062 80.648438 L 58.796875 79.390625 L 57.550781 80.648438 L 56.308594 79.390625 L 55.0625 80.648438 L 53.820312 79.390625 L 53.820312 89.445312 Z M 68.75 89.445312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 89.902344 100.757812 L 89.902344 90.703125 L 88.660156 91.960938 L 87.414062 90.703125 L 86.171875 91.960938 L 84.925781 90.703125 L 83.683594 91.960938 L 82.4375 90.703125 L 81.195312 91.960938 L 79.949219 90.703125 L 79.949219 100.757812 Z M 89.902344 100.757812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 42.625 100.757812 L 42.625 90.703125 L 41.378906 91.960938 L 40.136719 90.703125 L 38.890625 91.960938 L 37.648438 90.703125 L 36.402344 91.960938 L 35.160156 90.703125 L 33.914062 91.960938 L 32.671875 90.703125 L 32.671875 100.757812 Z M 42.625 100.757812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 89.902344 105.765625 L 89.902344 115.816406 L 88.660156 114.558594 L 87.414062 115.816406 L 86.171875 114.558594 L 84.925781 115.816406 L 83.683594 114.558594 L 82.4375 115.816406 L 81.195312 114.558594 L 79.949219 115.816406 L 79.949219 105.765625 Z M 89.902344 105.765625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,60%,0%);fill-opacity:1;"
      d="M 42.625 105.765625 L 42.625 115.816406 L 41.378906 114.558594 L 40.136719 115.816406 L 38.890625 114.558594 L 37.648438 115.816406 L 36.402344 114.558594 L 35.160156 115.816406 L 33.914062 114.558594 L 32.671875 115.816406 L 32.671875 105.765625 Z M 42.625 105.765625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;"
      d="M 68.753906 117.070312 L 68.753906 123.355469 L 70 122.097656 L 71.242188 123.355469 L 72.488281 122.097656 L 73.730469 123.355469 L 74.976562 122.097656 L 76.21875 123.355469 L 77.464844 122.097656 L 78.707031 123.355469 L 79.953125 122.097656 L 79.953125 105.761719 Z M 68.753906 117.070312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;"
      d="M 53.816406 117.070312 L 53.816406 123.355469 L 52.570312 122.097656 L 51.328125 123.355469 L 50.082031 122.097656 L 48.839844 123.355469 L 47.59375 122.097656 L 46.351562 123.355469 L 45.105469 122.097656 L 43.863281 123.355469 L 42.617188 122.097656 L 42.617188 105.761719 Z M 53.816406 117.070312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;"
      d="M 53.816406 89.445312 L 53.816406 83.160156 L 52.570312 84.417969 L 51.328125 83.160156 L 50.082031 84.417969 L 48.839844 83.160156 L 47.59375 84.417969 L 46.351562 83.160156 L 45.105469 84.417969 L 43.863281 83.160156 L 42.617188 84.417969 L 42.617188 100.753906 Z M 53.816406 89.445312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;"
      d="M 68.753906 89.445312 L 68.753906 83.160156 L 70 84.417969 L 71.242188 83.160156 L 72.488281 84.417969 L 73.730469 83.160156 L 74.976562 84.417969 L 76.21875 83.160156 L 77.464844 84.417969 L 78.707031 83.160156 L 79.953125 84.417969 L 79.953125 100.753906 Z M 68.753906 89.445312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;"
      d="M 32.664062 113.308594 L 31.421875 112.050781 L 30.179688 113.308594 L 28.933594 112.050781 L 27.6875 113.308594 L 26.445312 112.050781 L 25.199219 113.308594 L 23.957031 112.050781 L 22.710938 113.308594 L 22.710938 93.203125 L 23.957031 94.460938 L 25.199219 93.203125 L 26.445312 94.460938 L 27.6875 93.203125 L 28.933594 94.460938 L 30.175781 93.203125 L 31.421875 94.460938 L 32.664062 93.203125 L 32.664062 100.742188 L 27.6875 100.742188 L 25.199219 103.257812 L 27.6875 105.769531 L 32.664062 105.769531 Z M 32.664062 113.308594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;"
      d="M 89.90625 113.308594 L 91.148438 112.050781 L 92.390625 113.308594 L 93.636719 112.050781 L 94.882812 113.308594 L 96.125 112.050781 L 97.371094 113.308594 L 98.613281 112.050781 L 99.859375 113.308594 L 99.859375 93.203125 L 98.613281 94.460938 L 97.371094 93.203125 L 96.125 94.460938 L 94.882812 93.203125 L 93.636719 94.460938 L 92.394531 93.203125 L 91.148438 94.460938 L 89.90625 93.203125 L 89.90625 100.742188 L 94.882812 100.742188 L 97.371094 103.257812 L 94.882812 105.769531 L 89.90625 105.769531 Z M 89.90625 113.308594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 47.699219 117.871094 C 47.699219 117.871094 46.664062 118.863281 46.664062 119.363281 C 46.644531 119.824219 47.769531 120.863281 48.21875 120.851562 C 48.804688 120.863281 49.773438 119.859375 49.773438 119.363281 C 49.773438 118.863281 48.738281 117.871094 48.738281 117.871094 L 48.738281 115.886719 C 48.738281 115.886719 50.988281 119.300781 50.988281 117.8125 C 50.988281 116.742188 48.738281 114.890625 48.738281 114.890625 L 47.699219 114.890625 C 47.699219 114.890625 45.449219 117.058594 45.449219 117.8125 C 45.449219 119.300781 47.699219 115.886719 47.699219 115.886719 Z M 47.699219 117.871094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 60.664062 121.707031 C 60.664062 121.707031 59.417969 123.097656 59.417969 123.792969 C 59.394531 124.441406 60.746094 125.894531 61.285156 125.878906 C 61.988281 125.894531 63.152344 124.488281 63.152344 123.792969 C 63.152344 123.097656 61.90625 121.707031 61.90625 121.707031 L 61.90625 118.925781 C 61.90625 118.925781 64.109375 123.476562 64.109375 121.390625 C 64.109375 120.042969 61.90625 117.535156 61.90625 117.535156 L 60.664062 117.535156 C 60.664062 117.535156 58.121094 120.671875 58.460938 121.390625 C 58.460938 123.476562 60.664062 118.925781 60.664062 118.925781 Z M 60.664062 121.707031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;"
      d="M 50.09375 103.253906 C 50.09375 102.558594 48.980469 101.996094 47.605469 101.996094 C 46.230469 101.996094 45.117188 102.558594 45.117188 103.253906 C 45.117188 103.949219 46.230469 104.511719 47.605469 104.511719 C 48.980469 104.511719 50.09375 103.949219 50.09375 103.253906 Z M 50.09375 103.253906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;"
      d="M 63.78125 103.253906 C 63.78125 102.558594 62.664062 101.996094 61.292969 101.996094 C 59.917969 101.996094 58.804688 102.558594 58.804688 103.253906 C 58.804688 103.949219 59.917969 104.511719 61.292969 104.511719 C 62.664062 104.511719 63.78125 103.949219 63.78125 103.253906 Z M 63.78125 103.253906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,30.588235%,0%);fill-opacity:1;"
      d="M 77.460938 103.253906 C 77.460938 102.558594 76.34375 101.996094 74.972656 101.996094 C 73.597656 101.996094 72.484375 102.558594 72.484375 103.253906 C 72.484375 103.949219 73.597656 104.511719 74.972656 104.511719 C 76.34375 104.511719 77.460938 103.949219 77.460938 103.253906 Z M 77.460938 103.253906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 47.4375 88.75 C 47.4375 88.75 46.402344 87.757812 46.402344 87.257812 C 46.382812 86.796875 47.507812 85.757812 47.957031 85.769531 C 48.546875 85.757812 49.511719 86.761719 49.511719 87.257812 C 49.511719 87.757812 48.476562 88.75 48.476562 88.75 L 48.476562 90.734375 C 48.476562 90.734375 50.726562 87.320312 50.726562 88.8125 C 50.726562 89.878906 48.476562 91.730469 48.476562 91.730469 L 47.4375 91.730469 C 47.4375 91.730469 45.1875 89.5625 45.1875 88.8125 C 45.1875 87.320312 47.4375 90.734375 47.4375 90.734375 Z M 47.4375 88.75 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 60.402344 84.910156 C 60.402344 84.910156 59.15625 83.519531 59.15625 82.824219 C 59.132812 82.175781 60.484375 80.722656 61.023438 80.738281 C 61.730469 80.722656 62.890625 82.128906 62.890625 82.824219 C 62.890625 83.519531 61.644531 84.910156 61.644531 84.910156 L 61.644531 87.691406 C 61.644531 87.691406 63.847656 83.140625 63.847656 85.226562 C 63.847656 86.574219 61.644531 89.082031 61.644531 89.082031 L 60.402344 89.082031 C 60.402344 89.082031 57.859375 85.945312 58.199219 85.226562 C 58.199219 83.140625 60.402344 87.691406 60.402344 87.691406 Z M 60.402344 84.910156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 74.1875 117.871094 C 74.1875 117.871094 73.152344 118.863281 73.152344 119.363281 C 73.132812 119.824219 74.257812 120.863281 74.707031 120.851562 C 75.292969 120.863281 76.261719 119.859375 76.261719 119.363281 C 76.261719 118.863281 75.226562 117.871094 75.226562 117.871094 L 75.226562 115.886719 C 75.226562 115.886719 77.476562 119.300781 77.476562 117.8125 C 77.476562 116.742188 75.226562 114.890625 75.226562 114.890625 L 74.1875 114.890625 C 74.1875 114.890625 71.9375 117.058594 71.9375 117.8125 C 71.9375 119.300781 74.1875 115.886719 74.1875 115.886719 Z M 74.1875 117.871094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 73.925781 88.75 C 73.925781 88.75 72.890625 87.757812 72.890625 87.257812 C 72.871094 86.796875 73.996094 85.757812 74.445312 85.769531 C 75.03125 85.757812 76 86.761719 76 87.257812 C 76 87.757812 74.964844 88.75 74.964844 88.75 L 74.964844 90.734375 C 74.964844 90.734375 77.214844 87.320312 77.214844 88.8125 C 77.214844 89.878906 74.964844 91.730469 74.964844 91.730469 L 73.925781 91.730469 C 73.925781 91.730469 71.675781 89.5625 71.675781 88.8125 C 71.675781 87.320312 73.925781 90.734375 73.925781 90.734375 Z M 73.925781 88.75 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 36.878906 110.875 C 36.878906 110.875 35.5 111.804688 35.5 112.273438 C 35.472656 112.707031 36.96875 113.679688 37.566406 113.667969 C 38.347656 113.679688 39.636719 112.738281 39.636719 112.273438 C 39.636719 111.804688 38.257812 110.875 38.257812 110.875 L 38.257812 109.011719 C 38.257812 109.011719 40.699219 112.058594 40.699219 110.660156 C 40.699219 109.757812 38.257812 108.078125 38.257812 108.078125 L 36.878906 108.078125 C 36.878906 108.078125 34.058594 110.179688 34.4375 110.660156 C 34.4375 112.058594 36.878906 109.011719 36.878906 109.011719 Z M 36.878906 110.875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 36.585938 96.640625 C 36.585938 96.640625 35.210938 95.710938 35.210938 95.242188 C 35.183594 94.808594 36.679688 93.835938 37.277344 93.847656 C 38.058594 93.835938 39.34375 94.777344 39.34375 95.242188 C 39.34375 95.710938 37.964844 96.640625 37.964844 96.640625 L 37.964844 98.503906 C 37.964844 98.503906 40.40625 95.457031 40.40625 96.851562 C 40.40625 97.757812 37.964844 99.4375 37.964844 99.4375 L 36.585938 99.4375 C 36.585938 99.4375 33.769531 97.335938 34.144531 96.855469 C 34.144531 95.457031 36.585938 98.503906 36.585938 98.503906 Z M 36.585938 96.640625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 84.664062 110.574219 C 84.664062 110.574219 83.285156 111.507812 83.285156 111.972656 C 83.257812 112.40625 84.757812 113.382812 85.355469 113.371094 C 86.136719 113.382812 87.421875 112.441406 87.421875 111.972656 C 87.421875 111.507812 86.042969 110.574219 86.042969 110.574219 L 86.042969 108.710938 C 86.042969 108.710938 88.484375 111.761719 88.484375 110.363281 C 88.484375 109.460938 86.042969 107.78125 86.042969 107.78125 L 84.664062 107.78125 C 84.664062 107.78125 81.847656 109.882812 82.222656 110.363281 C 82.222656 111.761719 84.664062 108.710938 84.664062 108.710938 Z M 84.664062 110.574219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;"
      d="M 84.375 96.34375 C 84.375 96.34375 82.996094 95.414062 82.996094 94.945312 C 82.96875 94.511719 84.464844 93.539062 85.0625 93.550781 C 85.84375 93.539062 87.132812 94.480469 87.132812 94.945312 C 87.132812 95.414062 85.753906 96.34375 85.753906 96.34375 L 85.753906 98.207031 C 85.753906 98.207031 88.195312 95.160156 88.195312 96.558594 C 88.195312 97.460938 85.753906 99.140625 85.753906 99.140625 L 84.375 99.140625 C 84.375 99.140625 81.554688 97.039062 81.933594 96.558594 C 81.933594 95.160156 84.375 98.207031 84.375 98.207031 Z M 84.375 96.34375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 26.710938 110.71875 C 26.261719 109.859375 26.382812 109.464844 26.382812 108.96875 C 26.359375 108.503906 27.625 107.605469 28.074219 107.617188 C 28.664062 107.605469 29.632812 108.609375 29.632812 109.105469 C 29.632812 109.601562 29.65625 110.03125 29.414062 110.886719 L 28.765625 109.433594 C 27.980469 111.492188 27.9375 111.542969 27.46875 109.386719 Z M 26.710938 110.71875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 26.753906 96.277344 C 26.304688 97.136719 26.425781 97.53125 26.425781 98.027344 C 26.402344 98.492188 27.667969 99.390625 28.121094 99.378906 C 28.707031 99.390625 29.675781 98.386719 29.675781 97.890625 C 29.675781 97.394531 29.699219 96.964844 29.457031 96.109375 L 28.808594 97.5625 C 28.023438 95.503906 27.980469 95.453125 27.515625 97.609375 Z M 26.753906 96.277344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 93.820312 110.539062 C 93.375 109.675781 93.492188 109.285156 93.492188 108.785156 C 93.472656 108.324219 94.738281 107.421875 95.1875 107.433594 C 95.773438 107.421875 96.742188 108.425781 96.742188 108.921875 C 96.742188 109.417969 96.769531 109.847656 96.527344 110.703125 L 95.878906 109.253906 C 95.09375 111.3125 95.050781 111.359375 94.582031 109.203125 Z M 93.820312 110.539062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 93.859375 96.089844 C 93.410156 96.953125 93.53125 97.34375 93.53125 97.839844 C 93.507812 98.304688 94.773438 99.207031 95.222656 99.195312 C 95.8125 99.207031 96.78125 98.203125 96.78125 97.703125 C 96.78125 97.207031 96.804688 96.78125 96.5625 95.921875 L 95.914062 97.375 C 95.128906 95.316406 95.085938 95.269531 94.617188 97.425781 Z M 93.859375 96.089844 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(59.607843%,0%,0%);fill-opacity:1;stroke-width:0.13333;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 115.158953 177.161412 L 115.158953 171.84566 L 99.215192 171.84566 L 99.215192 161.214155 L 85.042362 161.214155 L 85.042362 157.672252 L 72.640463 157.672252 L 72.640463 125.783535 L 85.042362 125.783535 L 85.042362 122.241632 L 99.215192 122.241632 L 99.215192 111.610127 L 115.158953 111.610127 L 115.158953 106.294375 L 136.420889 106.294375 L 136.420889 111.610127 L 152.36465 111.610127 L 152.36465 122.241632 L 166.53748 122.241632 L 166.53748 125.783535 L 178.939379 125.783535 L 178.939379 157.672252 L 166.53748 157.672252 L 166.53748 161.214155 L 152.36465 161.214155 L 152.36465 171.84566 L 136.420889 171.84566 L 136.420889 177.161412 Z M 113.388022 178.929465 L 113.388022 173.61951 L 97.444261 173.61951 L 97.444261 162.988005 L 83.271431 162.988005 L 83.271431 159.446102 L 70.869532 159.446102 L 70.869532 124.009685 L 83.271431 124.009685 L 83.271431 120.467782 L 97.444261 120.467782 L 97.444261 109.836277 L 113.388022 109.836277 L 113.388022 104.520525 L 138.19182 104.520525 L 138.19182 109.836277 L 154.135581 109.836277 L 154.135581 120.467782 L 168.308411 120.467782 L 168.308411 124.009685 L 180.71031 124.009685 L 180.71031 159.446102 L 168.308411 159.446102 L 168.308411 162.988005 L 154.135581 162.988005 L 154.135581 173.61951 L 138.19182 173.61951 L 138.19182 178.929465 Z M 113.388022 178.929465 "
      transform="matrix(0.725695,0,0,0.673852,-30,7.990494)"
    />
  </g>
</svg>
