<script>
  import styles from "styleguide/RestrictedAccess.json";

  import { t } from "stores/i18n.js";
  import { user } from "stores/user";
  import { platformLink } from "lib/helpers";

  import { App, Toast } from "components";

  export let appConfig;

  $: {
    $user;
  }
</script>

<App {...appConfig}>
  <!--
    Need to check account store for account
    <MainNavigation showActions="false"></MainNavigation> -->
  <div class={styles.stage}>
    <div class={styles.content}>
      <Toast
        title={$t("restricted_access.title")}
        message={$t("restricted_access.message")}
        description={$t("restricted_access.description", {
          email: $user.help.email,
        })}
        type="error"
        action={{
          style: "new-settings-primary",
          button: $t("restricted_access.cta"),
          click: () => {
            window.location.href = platformLink("dashboard");
          },
        }}
      ></Toast>
    </div>
  </div>
</App>

<style lang="scss">
  .stage {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: #f9f9fa;
    align-items: center;
    justify-content: center;
  }
  .content {
    max-width: 640px;
  }
</style>
