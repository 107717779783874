<script>
  import { Box } from "components/index.js";
  import styles from "styleguide/StyleguideSection.json";

  export let title;
</script>

<Box {title}>
  <div class={styles.box}>
    <slot />
  </div>
</Box>

<style lang="scss">
  .box {
    padding: 1.5em;
  }
</style>
