import {
  audit,
  auditId,
  auditStatuses,
  auditComments,
  auditActivities,
  auditClientUsers,
} from "stores/pbc.js";
import { get } from "svelte/store";
import Api from "apis/api";

class AuditsApi {
  constructor() {
    this.endpoint = "/audits";
  }

  updateClientUserRights(options) {
    const { params, success, error } = options;
    const formData = new FormData();

    formData.append("client_user_id", options.params.client_user_id);
    formData.append(options.params.type, options.params.value);

    Api.patch(`${this.endpoint}/${params.audit_id}/client_user_rights`, {
      body: formData,
      success,
      error,
    });
  }

  goToFilters(filterValue) {
    history.pushState(
      null,
      null,
      `${get(audit).filter_path.replace(":filter_param", filterValue)}`,
    );
  }

  goToShow() {
    history.pushState(null, null, `${get(audit).pbc_path}`);
  }

  getAudits(params = {}) {
    Api.get(`${this.endpoint}/${get(audit).permalink}`, {
      params,
      success: (response) => {
        audit.set(response.data.audit);
      },
      error: (response) => {
        console.error("Error", response);
      },
    });
  }

  getStatuses() {
    Api.get(`${this.endpoint}/${get(audit).permalink}/audit_sidebar`, {
      success: (response) => {
        auditStatuses.set(response.data.sidebar_data);
      },
      error: (response) => {
        console.error("Error", response);
      },
    });
  }

  getComments() {
    Api.get(`${this.endpoint}/${get(audit).permalink}/comments`, {
      success: (response) => {
        auditComments.set(response.data.sidebar_comments);
      },
      error: (response) => {
        console.error("Error", response);
      },
    });
  }

  getActivities(page = 1) {
    Api.get(`${this.endpoint}/${get(audit).permalink}/activities`, {
      params: {
        page,
      },
      success: (response) => {
        auditActivities.set({ ...response.data, loaded: true });
      },
      error: (response) => {
        console.error("Error", response);
      },
    });
  }

  getClientUsers() {
    Api.get(`${this.endpoint}/${get(audit).permalink}/audit_client_users`, {
      success: (response) => {
        auditClientUsers.set(response.data.sidebar_client_user_data);
      },
      error: (response) => {
        console.error("Error", response);
      },
    });
  }

  deleteClientAccess(options) {
    const { params, success, error } = options;

    Api.delete(
      `${this.endpoint}/${get(audit).permalink}/remove_client_access?user_id=${params.user_id}`,
      {
        success,
        error: (response) => {
          console.error("Error", response);
        },
      },
    );
  }

  edit(options) {
    const { params, success, error } = options;
    const formData = new FormData();
    if (params.name) formData.append("name", options.params.name);
    if (params.enumerated !== undefined)
      formData.append("enumerated", options.params.enumerated);
    if (params.projectId)
      formData.append("project_id", options.params.projectId);

    Api.patch(`${this.endpoint}/${get(audit).permalink}`, {
      body: formData,
      success,
      error,
    });
  }

  delete() {
    Api.delete(`${this.endpoint}/${get(audit).permalink}`, {
      success: (response) => {
        window.location = response.data.path;
      },
      error: (response) => {
        console.error("Error", response);
      },
    });
  }

  archive(options) {
    const { params, success } = options;
    const formData = new FormData();

    formData.append("archived", params.archived);

    Api.post(`${this.endpoint}/${get(audit).permalink}/archive`, {
      body: formData,
      success: success,
      error: (response) => {
        console.error("Error", response);
      },
    });
  }

  createExport(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    formData.append("audit_id", get(audit).permalink);
    formData.append("shorten_name", params.shorten_name);
    formData.append("include_original_files", params.include_original_files);
    formData.append("nested_controls", params.nested_controls);
    formData.append("export_comments_logs", params.export_comments_logs);

    for (let id of params.control_ids) {
      formData.append("control_ids[]", id);
    }

    Api.post(`${this.endpoint}/${get(audit).permalink}/exports`, {
      body: formData,
      success,
      error: (response) => {
        console.error("Error", response);
      },
    });
  }

  getExport(options) {
    const { params, success, error } = options;

    Api.get(`${this.endpoint}/${get(audit).permalink}/exports/${params.id}`, {
      success,
      error: (response) => {
        console.error("Error", response);
      },
    });
  }

  checkExport(options) {
    const { params, success, error } = options;

    Api.get(
      `${this.endpoint}/${get(audit).permalink}/exports/${params.id}/check`,
      {
        success,
        error: (response) => {
          console.error("Error", response);
        },
      },
    );
  }

  deleteExport(options) {
    const { params, success, error } = options;

    Api.delete(
      `${this.endpoint}/${get(audit).permalink}/exports/${params.id}`,
      {
        success,
        error: (response) => {
          console.error("Error", response);
        },
      },
    );
  }

  cancelExport(options) {
    const { params, success } = options;

    Api.get(`${this.endpoint}/${get(audit)}/exports/${params.id}/cancel`, {
      success,
      error: (response) => {
        console.error("Error", response);
      },
    });
  }

  getExports(options) {
    const { params, success, error } = options;

    Api.get(`${this.endpoint}/${get(audit).permalink}/exports`, {
      success,
      error: (response) => {
        console.error("Error", response);
      },
    });
  }

  markAttachmentsAsRead() {
    Api.post(
      `${this.endpoint}/${get(audit).permalink}/mark_attachments_as_read`,
      {
        success: () => {
          this.getAudits();
          this.getStatuses();
        },
        error: (response) => {
          console.error("Error", response);
        },
      },
    );
  }

  markCommentsAsRead() {
    Api.post(`${this.endpoint}/${get(audit).permalink}/mark_comments_as_read`, {
      success: () => {
        this.getAudits();
        this.getStatuses();
      },
      error: (response) => {
        console.error("Error", response);
      },
    });
  }

  getExportExcel(options) {
    Api.download(`${this.endpoint}/${get(audit).permalink}/export_xlsx`);
  }

  toggleImprovedRights() {
    Api.patch(
      `${this.endpoint}/${get(audit).permalink}/toggle_client_user_manage_rights`,
      {
        success: () => {
          this.getAudits(),
            toasts.send({
              title: get(t)("success.update_successful"),
              type: "success",
            });
        },
        error: this.error,
      },
    );
  }
}

export default new AuditsApi();
