<script>
  import { Modal } from "components/modals";
  import { t } from "stores/i18n.js";
  import { can } from "stores/permission.js";

  import Client from "apis/clients/client_api.js";
  import Projects from "apis/dashboard/projects.js";

  import { Button, Search, TextInput } from "components";
  import styles from "styleguide/DashboardAuditNewModal.json";

  export let shown;
  export let projectId;
  export let projectName;
  export let onCancel;

  let dirty;
  let auditName;
  let clientName;
  let clientOfficialName;
  let newClient = false;

  $: valid = checkValid(auditName, clientName, clientOfficialName);

  function checkValid(auditName, clientName, clientOfficialName) {
    if (newClient) {
      return auditName && clientName && clientOfficialName;
    } else {
      return auditName && clientName;
    }
  }

  function modifySearchResults(response) {
    return combineUsers(response.data.clients);
  }

  function combineUsers(users) {
    return users.map((item) => {
      const user = {};

      user.label = item.text;
      user.value = item.text;

      return user;
    });
  }

  function selectUser(name) {
    clientName = name;
  }

  function onSubmit() {
    let formData = new FormData();

    formData.append("project_id", projectId);
    formData.append("name", auditName);
    formData.append("client_name", clientName);

    if (newClient) formData.append("client_official_name", clientOfficialName);

    dirty = false;

    const params = {
      params: formData,
      success: onAuditCreateSuccess,
      error: onAuditCreateError,
    };

    Projects.createAudit(params, projectId);
  }

  function onAuditCreateSuccess(response) {
    const path = response.data.path;

    if (path) {
      window.location = path;
    }
  }

  function onAuditCreateError() {
    dirty = true;
  }
</script>

<Modal {shown} onHide={onCancel} title={$t("dashboard_audit_new.for_project")}>
  <div data-component="DashboardAuditNew" class={styles.wrapper}>
    <div class={styles.inner}>
      <div class={styles.input}>
        <TextInput
          label={$t("dashboard_audit_new.project_name")}
          bind:value={projectName}
          style={"border medium round-border fullsize"}
          disabled={true}
        />
      </div>
      <div class={styles.input}>
        <TextInput
          label={$t("dashboard_audit_new.audit_name")}
          bind:value={auditName}
          errors={!auditName && dirty
            ? [$t("dashboard_audit_new.name_error")]
            : []}
          style={"border medium round-border fullsize"}
        />
      </div>
      <div class={styles.input}>
        {#if newClient}
          <TextInput
            label={$t("dashboard_audit_new.client_internal_name")}
            footnotes={[$t("dashboard_audit_new.internal_name_footnote")]}
            errors={!clientName && dirty
              ? [$t("dashboard_audit_new.name_error")]
              : []}
            bind:value={clientName}
            style={"border medium round-border fullsize"}
          />
        {:else}
          <Search
            clear={false}
            searchApi={Client}
            {modifySearchResults}
            select={selectUser}
            style={"border medium round-border fullsize"}
            label={$t("dashboard_audit_new.client_name")}
            errors={!clientName && dirty
              ? [$t("dashboard_audit_new.name_error")]
              : []}
          />
        {/if}
      </div>
      {#if newClient}
        <div class={styles.input}>
          <TextInput
            label={$t("dashboard_audit_new.client_official_name")}
            footnotes={[$t("dashboard_audit_new.official_name_footnote")]}
            bind:value={clientOfficialName}
            style={"border medium round-border fullsize"}
          />
        </div>
      {/if}
    </div>
    {#if $can("create", "client")}
      <div class={styles["button-wrapper"]}>
        <Button
          style="primary-text small"
          click={() => {
            newClient = !newClient;
          }}
        >
          {#if newClient}
            {$t("dashboard_audit_new.existing_client")}
          {:else}
            {$t("dashboard_audit_new.new_client")}
          {/if}
        </Button>
      </div>
    {/if}
  </div>
  <svelte:fragment slot="bottom">
    <div class={styles.submit}>
      <Button style={"primary"} click={onSubmit}>
        {$t("dashboard_audit_new.create")}
      </Button>
    </div>
  </svelte:fragment>
</Modal>

<style lang="scss">
  .title {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }

  .info {
    color: var(--primary-200);
  }

  .project {
    font-size: 18px;
  }

  .input {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .submit {
    display: flex;
    justify-content: right;
  }

  .button-wrapper {
    padding-top: 8px;
  }
</style>
