import Api from "apis/api";
import { buildFormData } from "lib/helpers.js";

class Review {
  constructor() {
    this.endpoint = "/reviews";
  }

  create(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params, null, true);

    Api.post(`${this.endpoint}`, {
      body: formData,
      success: (response) => {
        success(response.data);
      },
      error,
    });
  }
}

export default new Review();
