<script>
  import { onMount, setContext } from "svelte";

  import {
    ConfirmationDetail,
    ConfirmationMailing,
    ConfirmationResponses,
    ConfirmationSidebarOverview,
  } from "apps/confirmation";
  import { initializeNavigation } from "helpers/apps";
  import {
    item,
    entries,
    letterhead,
    comments,
    getComments,
    onPostComment,
  } from "stores/confirmation/confirmation_item.js";
  import { companies } from "stores/confirmation/confirmation_new.js";
  import { t } from "stores/i18n.js";

  import { App, Box, ContentSidebar, Header, Toast } from "components";
  import styles from "styleguide/ConfirmationItem.json";

  export let appConfig;
  export let initialState;

  onMount(async () => {
    item.set(initialState.item);
    companies.set(
      initialState.item.companies ? initialState.item.companies : [],
    );
    letterhead.set(initialState.letterhead);
    comments.set(initialState.comments);

    initializeNavigation({
      state: initialState.item,
      path: `${initialState.item.apps_confirmation_path}/samples/${initialState.item.permalink}`,
      type: "confirmation",
    });
  });

  setContext("comments", comments);
  setContext("getComments", getComments);
  setContext("onPostComment", onPostComment);
  setContext("parent", item);
  setContext("parent_type", "confirmation_items");
  setContext("comments_visible", true);
</script>

<App {...appConfig}>
  <div class={styles.wrapper}>
    <ContentSidebar>
      <div slot="content" data-component="ConfirmationIndex">
        <Header
          type={$t(`confirmation.types.${$item.confirmation_type}`)}
          headline={$item.company}
          client_name={$item.client_name}
          client_official_name={$item.client_official_name}
        />
        <ConfirmationDetail />

        {#each $entries as entry}
          <Box style="margin-bottom">
            <div class={styles.block}>
              {#if entry.type === "mailings"}
                <ConfirmationMailing {...entry} companies={$item.companies} />
              {:else if entry.type === "responses"}
                <ConfirmationResponses {...entry} />
              {/if}
            </div>
          </Box>
        {:else}
          <div class={styles["toast-margin-top"]}>
            <Toast
              type="info"
              title={$t("confirmation_item_show.empty_mailing_box_title")}
            />
          </div>
        {/each}
      </div>
      <div slot="sidebar" data-component="ConfirmationIndex">
        <ConfirmationSidebarOverview statuses={false} margined={true} />
      </div>
    </ContentSidebar>
  </div>
</App>

<style global lang="scss">
  .wrapper {
    margin-bottom: 180px;
  }

  .block {
    &:not(:first-child) {
      border-top: 1px solid var(--primary-050);
    }
  }

  .toast-margin-top {
    margin-top: 20px;
  }
</style>
