import { writable } from "svelte/store";

const storageName = "modals-content";
const modalsStore = writable([]);

const getModalsContentFromStorage = () =>
  JSON.parse(localStorage.getItem(storageName)) || [];
const setModalsContentToStorage = (value) =>
  localStorage.setItem(storageName, JSON.stringify(value));

modalsStore.set(getModalsContentFromStorage());
modalsStore.subscribe((value) => setModalsContentToStorage(value));

const updateModalsContent = (value) => {
  modalsStore.update((prevValues) => {
    const contentMap = new Map(prevValues.map((item) => [item.key, item]));
    (value || []).forEach((item) => contentMap.set(item.key, item));
    return Array.from(contentMap.values()).slice(0, 20);
  });
};

const discardModalsContent = (storageItemId) => {
  modalsStore.update((prevValues) =>
    prevValues.filter((item) => item.key !== storageItemId),
  );
};

const getModalContentByKey = (key) => {
  const storedData = getModalsContentFromStorage();
  const item = storedData.find((item) => item.key === key);
  return item ? item.state : undefined;
};

const isDirtyState = (content) => {
  if (!content) return false;
  return Object.values(content).some((value) =>
    Array.isArray(value)
      ? value.length > 0
      : value !== null && value !== undefined && value !== "",
  );
};

export {
  modalsStore,
  setModalsContentToStorage,
  isDirtyState,
  updateModalsContent,
  discardModalsContent,
  getModalContentByKey,
};
