<script>
  import { iconByKey } from "components/icons/countries";
  export let icon;
  export let style;
  export let width;
  export let height;
  export let stroke;
  export let strokeWidth;
</script>

<div data-component={`Countries-Icons-${icon}`} style="display: contents;">
  <svelte:component
    this={iconByKey[icon]}
    {width}
    {height}
    {style}
    {stroke}
    stroke-width={strokeWidth}
  />
</div>
