<script>
  import { getContext, onMount, onDestroy } from "svelte";
  import { SampleMailingActions } from "apps/sample/components/mailing";
  import {
    getSampleMailingHeaderStatus,
    getSampleMailingToastStatus,
  } from "apps/sample/helpers";
  import { SampleService } from "apps/sample/services";
  import { sample } from "apps/sample/stores";
  import clsx from "clsx";
  import { isAccountFeature } from "stores/account";
  import documentViewerStore from "stores/document_viewer_store.js";
  import { t } from "stores/i18n.js";

  import { isUserClient, isUserTeamUser } from "stores/user";

  import {
    distanceOfTime,
    dateTimeFormat,
    fixCarrierWaveUrl,
  } from "lib/helpers.js";
  import { DocumentThumbnails, Tooltip, Toast, Box } from "components";

  import styles from "styleguide/SampleMailing.json";

  export let mailing;

  let isLoadingThumbnails = true;
  let polling;
  let pollingCount = 0;

  const config = getContext("config");
  const MAX_POLLING_COUNT = 20;

  $: sentDistanceTime = distanceOfTime(
    new Date(mailing.sent_at).getTime() / 1000,
    config.language,
  );
  $: mailingStatus = getSampleMailingHeaderStatus(mailing);
  $: isMailingReady =
    mailing.mailing_url && mailing.stored && mailing.address_complete;

  $: if (!isMailingReady) {
    startPolling(mailing);
  }

  onMount(() => {
    if ($isUserClient) {
      documentViewerStore.update({
        showDownloadButton: false,
      });
    }
  });

  onDestroy(stopPolling);

  function checkSampleMailingStatus() {
    const params = {
      sample_id: $sample.permalink,
      id: mailing.permalink,
    };

    SampleService.checkSampleMailingsStatus({
      params,
      onSuccess: (response) => {
        if (response.stored) {
          stopPolling();
          isLoadingThumbnails = true;
        }
      },
      onError: stopPolling,
    });
  }

  function startPolling() {
    if (polling || !mailing.address_complete) return;

    polling = setInterval(() => {
      if (pollingCount >= MAX_POLLING_COUNT) {
        stopPolling();
        return;
      }

      pollingCount++;
      checkSampleMailingStatus();
    }, 2000);
  }

  function stopPolling() {
    clearInterval(polling);
    polling = null;
  }
</script>

<Box>
  <div class={styles.wrapper} data-component="SampleMailing">
    <div class={styles.header}>
      <div class={styles.title}>
        <div class={styles["title-type"]}>{$t("sample_mailing.request")}</div>

        {#if mailingStatus && mailing.sent_at === null}
          <div
            class={clsx(
              styles["title-status"],
              styles[`title-status--${mailingStatus.color}`],
            )}
          >
            {mailingStatus.message}
          </div>
        {:else}
          <Tooltip message={dateTimeFormat(mailing.sent_at)}>
            <div class={styles["title-status"]}>
              {$t("sample_mailing.sent")}
              {sentDistanceTime}
            </div>
          </Tooltip>
        {/if}

        <div class={styles["title-status"]}>
          {$t(`sample.via.${mailing.sent_via}`)}
        </div>
      </div>

      {#if $isUserTeamUser}
        <div class={styles.actions}>
          <SampleMailingActions bind:mailing />
        </div>
      {/if}
    </div>

    {#each getSampleMailingToastStatus($isUserTeamUser, $sample, mailing) as toast}
      {#if toast.condition}
        <Toast {...toast.props} />
      {/if}
    {/each}

    {#if isMailingReady}
      <DocumentThumbnails
        bind:isLoadingThumbnails
        documentUrl={fixCarrierWaveUrl(mailing.mailing_url)}
        documentId={mailing.permalink}
        documentTitle={`${$sample.contact.company}.pdf`}
        addWatermarks={$isUserClient && $isAccountFeature("mailing_watermarks")}
      />
    {/if}
  </div>
</Box>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 14px 28px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      display: flex;
      align-items: center;
      gap: 14px;
      &-type {
        @include typography-headline-semibold;
      }

      &-status {
        @include typography-footnote-medium;
        color: var(--primary-200);
        &--green {
          @include typography-semibold;
          color: var(--green-100);
        }
        &--yellow {
          @include typography-semibold;
          color: var(--orange-400);
        }
        &--orange {
          @include typography-semibold;
          color: var(--orange-100);
        }
      }
    }
  }
</style>
