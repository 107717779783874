import Api from "apis/api";

class SignupApi {
  constructor() {
    this.endpoint = "/quickshare/signups";
  }

  signup(options) {
    Api.post(this.endpoint, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: {},
      success: options.success,
      error: options.error,
    });
  }
}

export default new SignupApi();
