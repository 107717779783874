<script>
  import { onMount } from "svelte";
  import clsx from "clsx";
  import { t } from "stores/i18n.js";
  import { createPopperActions } from "svelte-popperjs";

  import { dateTimeFormat } from "lib/helpers.js";

  import { DatePicker, TextInput } from "components";
  import styles from "styleguide/DateInput.json";

  export let style = "";
  export let min = undefined;
  export let max = undefined;
  export let value = null;
  export let deleteText = null;
  export let onSelect = null;
  export let onRemove = null;
  export let show = false;
  export let disabled = false;
  export let required = false;
  export let dirty = false;
  export let lock = false;
  export let label = undefined;
  export let errors = undefined;
  export let optional = undefined;
  export let footnotes = undefined;
  export let dynamicPosition = true;
  export let placement = "bottom-start";

  let input;
  let initialInputValue;
  let allowedAutoPlacements = ["bottom-start"];
  if (dynamicPosition) {
    allowedAutoPlacements.push("top-start");
  }

  const extraOpts = {
    modifiers: [
      {
        name: "flip",
        options: { allowedAutoPlacements: allowedAutoPlacements },
      },
    ],
  };

  let inputValue = "";

  const [popperRef, popperContent] = createPopperActions({
    strategy: "fixed",
    placement: placement,
  });

  $: {
    if (input !== document.activeElement) {
      inputValue = getDateTimeFormat(value);
    }
  }

  function getDateTimeFormat(value) {
    return value
      ? dateTimeFormat(value, "de", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
      : "";
  }

  function toggle(value) {
    if (!(lock || disabled)) {
      show = value;

      if (!show) {
        setValue(input.value);
      }
    }
  }

  function getValue(result) {
    return result.split("-").reverse().join("-");
  }

  function validateResult(inputValue) {
    let result = inputValue.replaceAll(".", "-").replaceAll("/", "-");
    return result.match(/^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[0-2])-\d{4}$/)
      ? result
      : false;
  }

  function handlerInputKeydown() {
    setTimeout(() => {
      let result = validateResult(input.value);

      if (result) {
        value = getValue(result);
      }
    }, 100);
  }

  function setValue(inputValue) {
    errors = [];

    if (initialInputValue === inputValue) {
      return;
    }

    if (!inputValue) {
      if (onSelect) onSelect("");
      value = "";
      initialInputValue = "";
      return;
    }

    let result = validateResult(inputValue);

    if (result) {
      if (onSelect) onSelect(result);
      value = getValue(result);
      initialInputValue = inputValue;
    } else {
      errors = [$t("date_input.invalid_format")];
    }
  }

  onMount(() => {
    initialInputValue = getDateTimeFormat(value);
  });
</script>

<div
  data-component="DateInput"
  class={clsx(style.split(" ").map((x) => styles[x]))}
  use:popperRef
>
  {#if show}
    <div
      class={styles.backdrop}
      on:click={() => {
        toggle(false);
      }}
    />
  {/if}
  <TextInput
    enterIcon="calendar"
    style={"border medium round-border"}
    {lock}
    {label}
    {optional}
    {footnotes}
    maxlength={10}
    bind:input
    value={inputValue}
    onFocus={() => {
      toggle(true);
    }}
    onIconClick={() => {
      toggle(true);
    }}
    onEnter={() => {
      toggle(false);
    }}
    onKeydown={handlerInputKeydown}
    placeholder={$t("date_input.placeholder")}
    errors={errors || (dirty && required ? [$t("validation.required")] : [])}
  />

  {#if show && !disabled}
    <div use:popperContent={extraOpts} class={styles.inner}>
      <DatePicker
        {min}
        {max}
        {onSelect}
        {onRemove}
        {deleteText}
        bind:show
        bind:value
      />
    </div>
  {/if}
</div>

<style global lang="scss">
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  .inner {
    z-index: 3;
  }
</style>
