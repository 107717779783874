import Api from "apis/api";

class Reviews {
  constructor() {
    this.endpoint = "/reviews";
  }

  archive(options, id) {
    Api.post(`${this.endpoint}/${id}/archive`, {
      body: options.params,
      success: options.success,
      error: options.error,
    });
  }
}

export default new Reviews();
