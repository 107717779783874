<script>
  import styles from "styleguide/ConfirmationSidebarSigners.json";
  import { t } from "stores/i18n.js";
  import {
    authorizedSigners,
    activeSignersEdit,
    activeRemindSignersId,
    confirmationId,
  } from "stores/confirmation/confirmation.js";

  import { ConfirmationRemindSigners } from "apps/confirmation";
  import { Button, SidebarArea } from "components";
</script>

<div data-component="ConfirmationSidebarSigners">
  {#each Object.entries($authorizedSigners) as [type, items] (type)}
    {#if Object.values(items).length}
      <SidebarArea style="border-top padding all">
        <div class={styles.title}>{$t(`confirmation.types.${type}`)}</div>

        {#each Object.entries(items) as [email, item] (email)}
          <div class={styles.item}>{email}</div>
        {/each}
      </SidebarArea>
    {/if}
  {/each}

  <SidebarArea>
    <Button
      style="blue-text sidebar-action last-but-only-child"
      click={() => activeSignersEdit.set(true)}
      >{$t("confirmation_sidebar_signers.edit")}</Button
    >
  </SidebarArea>

  <SidebarArea>
    <Button
      style="blue-text sidebar-action"
      click={() =>
        activeRemindSignersId.set({ confirmation_id: $confirmationId })}
      >{$t("confirmation_sidebar_signers.remind")}</Button
    >
  </SidebarArea>
</div>

<ConfirmationRemindSigners />

<style lang="scss">
  .item {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .title {
    font-weight: 600;
    margin-bottom: 8px;
  }
</style>
