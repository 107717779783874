<script>
  import styles from "styleguide/PageSwitcher.json";
  import { t } from "stores/i18n.js";
  import { IconButton } from "components";

  export let page;
  export let pagesNumber;
  export let changePage;

  $: text = buildText(page, pagesNumber, $t);

  function buildText(page, pagesNumber) {
    return (
      $t("page_switcher.page") &&
      $t("page_switcher.page")
        .replace("%{currentPage}", page)
        .replace("%{pagesNumber}", pagesNumber)
    );
  }
</script>

<div data-component="PageSwitcher" class={styles.wrapper}>
  <IconButton
    style="primary-text"
    icon="stroke_thin_left"
    click={() => {
      changePage(-1);
    }}
  />
  <div class={styles.pages}>{text}</div>
  <IconButton
    style="primary-text"
    icon="stroke_thin_right"
    click={() => {
      changePage(1);
    }}
  />
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pages {
    margin: 0 12px;
    white-space: nowrap;
  }
</style>
