<script>
  import styles from "styleguide/RespondDescription.json";
  import { theme } from "stores/theme.js";
  import { t } from "stores/i18n.js";
  import { imagePath } from "lib/helpers.js";
</script>

<div data-component="RespondDescription" class={styles.section}>
  <h1 class={styles.title}>{$t("responder_description.title")}</h1>
  <div class={styles.content}>{$t("responder_description.content")}</div>
  {#if $theme === "aude"}
    <div class={styles.partner}>
      <img src={imagePath("datev")} class={styles.datev} alt="DATEV eG" />
      <p class={styles.cooperation}>
        {$t("responder_description.cooperation")}
      </p>
    </div>
  {/if}
</div>

<style lang="scss">
  .section {
    padding: 25px 0;
  }

  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: var(--primary-500);
    margin: 0 0 12px;
  }

  .content {
    line-height: 22px;
    color: var(--primary-500);
  }

  .partner {
    display: flex;
    align-items: center;
    margin: 18px 0 0;
  }

  .datev {
    width: 42px;
    height: 10px;
  }

  .cooperation {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: var(--primary-500);
    margin: 0 12px;
  }
</style>
