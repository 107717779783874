<script>
  import styles from "styleguide/ProfileSettings.json";
  import { onMount } from "svelte";

  import { t } from "stores/i18n.js";
  import { breadcrumb } from "stores/breadcrumb.js";
  import { updatePageHistory } from "stores/page_history.js";
  import {
    team_user,
    settingsPage,
    scimActive,
    teamUserRole,
  } from "stores/team_user.js";

  import { userPath, csrfToken } from "lib/helpers.js";
  import TeamUsersApi from "apis/users/team_users.js";

  import { App, Button, Card, ContentSidebar } from "components";
  import {
    ProfilePersonalData,
    ProfilePersonalization,
    ProfileSecurity,
  } from "components/profile";

  export let appConfig;
  export let initialState;

  let currentTeamUser;

  team_user.subscribe((team_user) => {
    if (team_user) {
      currentTeamUser = team_user;

      const profilePath =
        userPath + "/team_users/" + currentTeamUser.permalink + "/edit";

      breadcrumb.set([
        { translation: "administration", path: userPath + "/account" },
        { translation: "employees", path: userPath + "/team_users" },
        { name: currentTeamUser.name },
      ]);

      updatePageHistory([
        {
          path: profilePath,
          titles: [
            { translation: "administration" },
            { translation: "employees" },
            { name: currentTeamUser.name },
          ],
        },
      ]);
    }
  });

  settingsPage.set(initialState.page || "overview");
  onMount(async () => {
    TeamUsersApi.getTeamUser(initialState.team_user_id);
    scimActive.set(initialState.scim_active);
    teamUserRole.set(initialState.team_user_role_name);
  });

  function setPage(value) {
    settingsPage.set(value);
    window.history.pushState(
      "",
      "",
      window.location.href + "/" + $settingsPage,
    );
  }

  function signOut() {
    fetch(`${userPath}/logins/sign_out`, {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": csrfToken(),
      },
    }).then(() => {
      window.location = "/login";
    });
  }
</script>

<App {...appConfig} container={true}>
  <div class={styles.wrapper}>
    <ContentSidebar>
      <div slot="content">
        <div class={styles.content}>
          <div class={styles.title}>
            {$t(`profile_settings.${$settingsPage}`)}
          </div>

          {#if currentTeamUser}
            <div class={styles["user-data"]}>
              <div class={styles.username}>
                {currentTeamUser.name}
              </div>
              <div class={styles.spacer}>.</div>
              <div class={styles["user-email"]}>
                {currentTeamUser.email}
              </div>
            </div>
            {#if $settingsPage === "overview"}
              <div class={styles.cards}>
                <Card
                  icon={"personal"}
                  header={$t("profile_settings.personal")}
                  link={"personal"}
                  onCardClick={setPage}
                  description={$t("profile_settings.personal_desc")}
                />
                <Card
                  icon={"security"}
                  header={$t("profile_settings.security")}
                  link={"security"}
                  onCardClick={setPage}
                  description={$t("profile_settings.security_desc")}
                />
                <Card
                  icon={"personalization"}
                  header={$t("profile_settings.personalization")}
                  link={"personalization"}
                  onCardClick={setPage}
                  description={$t("profile_settings.personalization_desc")}
                />
              </div>
            {:else if $settingsPage === "personal"}
              <ProfilePersonalData />
            {:else if $settingsPage === "security"}
              <ProfileSecurity />
            {:else if $settingsPage === "personalization"}
              <ProfilePersonalization />
            {/if}
          {/if}

          {#if $settingsPage === "overview"}
            <div class={styles.footer}>
              <div class={styles["footer-text"]}>
                {$t("profile_settings.log_out_desc")}
              </div>
              <Button style="error-text small" click={signOut}>
                {$t("profile_settings.log_out_btn_text")}
              </Button>
            </div>
          {/if}
        </div>
      </div>
    </ContentSidebar>
  </div>
</App>

<style scoped lang="scss">
  .wrapper {
    padding: 40px 0;
  }
  .content {
    padding-bottom: 60px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
  }

  .body {
    height: 80%;
  }

  .cards {
    max-width: 950px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1em;
  }

  .title {
    font-size: 22px;
    line-height: 33px;
    font-feature-settings: "ss01" on;
    color: var(--primary-500);
    padding: 0px;
    margin: 0px;
  }

  .user-data {
    display: flex;
    margin: 0 0 32px 0;
  }

  .username {
    font-size: 14px;
    color: var(--primary-500);
  }

  .spacer {
    font-size: 14px;
    bottom: 3px;
    margin: 0px 8px;
  }

  .user-email {
    font-weight: 400;
    font-size: 14px;
    color: var(--primary-500);
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 8px;
    width: 100%;
    padding-bottom: 24px;
  }

  .footer-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--primary-500);
    padding-bottom: 4px;
  }
</style>
