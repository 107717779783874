import { get } from "svelte/store";
import Api from "apis/api";
import AppCable from "lib/cable";

import { t } from "stores/i18n.js";
import { webSocketUrl } from "stores/notification.js";
import { toasts } from "stores/toasts.js";

import documentViewerStore from "../../stores/document_viewer_store.js";

class AttachmentAnnotationHandler {
  constructor() {
    this.endpoint = "/attachments";
    this.subscription = null;
  }

  /**
   * Subscribe to AnnotationChannel
   */
  subcribe(webViewerInstance, parentType, parentId) {
    this.document = get(documentViewerStore.documentReadable);

    if (!this.document.id || this.subscription) {
      return;
    }
    this.subscription = new AppCable(webSocketUrl()).cable.subscriptions.create(
      {
        channel: "AnnotationChannel",
        attachment_id: this.document.id,
        parent_type: parentType,
        parent_id: parentId,
      },
      {
        connected: () => {
          console.log("AnnotationChannel connected successfully");
        },
        received: async ({ data, action, annotation }) => {
          console.log("AnnotationChannel received");
          const { annotationManager } = webViewerInstance;

          const annotationsList =
            await annotationManager.importAnnotationCommand(data);
          annotationsList.forEach((annotItem) => {
            annotationManager.redrawAnnotation(annotItem);
          });

          if (action === "delete") {
            const deletedAnnotation = annotationManager.getAnnotationById(
              annotation.annotation_id,
            );

            if (deletedAnnotation) {
              await annotationManager.importAnnotationCommand(
                `<delete><id>${annotation.annotation_id}</id></delete>`,
              );
            }
          }
        },
      },
    );
  }

  /**
   * Unsubscribe from AnnotationChannel
   */
  unsubscribe() {
    if (this.subscription) {
      this.subscription.consumer.disconnect();
      this.subscription = null;
    }
  }

  /**
   * Creates a new annotation for a document.
   *
   * @param {Object} params - The parameters for creating the annotation.
   * @param {Array} params.annotations - An array of annotations.
   * @param {string} params.xfdfString - The XFDF string representing the annotation data.
   * @param {string} params.parentType - The type of the parent element.
   * @param {string} params.parentId - The ID of the parent element.
   */
  onCreate({ annotations, xfdfString, parentType, parentId }) {
    if (!annotations) return;
    const annotationId = annotations[0].Id;
    Api.post(`${this.endpoint}/${this.document.id}/annotations`, {
      headers: { "Content-Type": "application/json" },
      body: {
        data: xfdfString,
        annotation_id: annotationId,
        parent_type: parentType,
        parent_id: parentId,
      },
      success: () => {
        toasts.send({
          message: get(t)("annotations.create.success"),
          type: "success",
        });
      },
      error: () => {
        toasts.send({
          message: get(t)("annotations.create.error"),
          type: "error",
        });
      },
    });
  }

  /**
   * Update annotations with new data.
   *
   * @param {Object} params - The parameters for the update operation.
   * @param {Array} params.annotations - The array of annotations to be updated.
   * @param {string} params.xfdfString - The XFDF string containing updated data.
   * @param {string} params.parentType - The type of the parent element.
   * @param {string} params.parentId - The ID of the parent element.
   */
  onUpdate({ annotations, xfdfString, parentType, parentId }) {
    const annotationId = annotations[0].Id;
    Api.put(
      `${this.endpoint}/${this.document.id}/annotations/${annotationId}`,
      {
        headers: { "Content-Type": "application/json" },
        body: {
          data: xfdfString,
          parent_type: parentType,
          parent_id: parentId,
        },
        success: () => {
          toasts.send({
            message: get(t)("annotations.update.success"),
            type: "success",
          });
        },
        error: () => {
          toasts.send({
            message: get(t)("annotations.update.error"),
            type: "error",
          });
        },
      },
    );
  }

  /**
   * Deletes annotations associated with a specific parent in the document.
   *
   * @param {Object} params - The parameters for the deletion.
   * @param {Array} params.annotations - An array of annotation objects to be deleted.
   * @param {string} params.parentType - The type of the parent entity.
   * @param {string} params.parentId - The ID of the parent entity.
   */
  onDelete({ annotations, parentType, parentId }) {
    annotations.forEach((annotationItem) => {
      Api.delete(
        `${this.endpoint}/${this.document.id}/annotations/${annotationItem.Id}`,
        {
          headers: { "Content-Type": "application/json" },
          body: {
            parent_type: parentType,
            parent_id: parentId,
          },
          success: () => {
            toasts.send({
              message: get(t)("annotations.delete.success"),
              type: "success",
            });
          },
          error: () => {
            toasts.send({
              message: get(t)("annotations.delete.errpr"),
              type: "error",
            });
          },
        },
      );
    });
  }
}
const attachmentsApiInstance = new AttachmentAnnotationHandler();

export default attachmentsApiInstance;
