<script>
  import styles from "styleguide/ResponderSteps.json";
  import { createEventDispatcher } from "svelte";

  import { t } from "stores/i18n.js";
  import { addedFiles } from "stores/responder.js";

  import SampleResponseSubmissions from "apis/responder/general_method.js";

  import { ResponderQrcodeExample, ResponderUploadItem } from "apps/responder";
  import { Checkbox, Dropzone, LegacyButton as Button, Tag } from "components";

  let dropzone;
  let qrcodeConfirmation = false;

  let files;
  let addedFilesCount = 0;

  addedFiles.subscribe((value) => {
    files = value;
    addedFilesCount = value.length;
  });

  const dispatch = createEventDispatcher();

  function addFiles(files) {
    const newFilesArray = [...$addedFiles, ...files];
    addedFiles.set(Array.from(newFilesArray));
  }

  function addFileButtonHandler(event) {
    event.preventDefault();
    dropzone.click();
  }

  function onQrcodeConfirmationToggle(toggled) {
    qrcodeConfirmation = toggled;
  }

  function onCreateSuccess(response) {
    dispatch("created", { response });
  }

  function onSubmit() {
    let formData = new FormData();

    for (let file of files) {
      formData.append("files[]", file);
    }

    dispatch("checking");

    SampleResponseSubmissions.create({
      body: formData,
      success: onCreateSuccess,
      error: (response) => console.log(response),
    });
  }
</script>

<form data-component="ResponderSteps" on:submit|preventDefault={onSubmit}>
  <div class={styles.step}>
    <div class={styles.stepInner}>
      <div class={styles.top}>
        <div class={styles.tagWrapper}>
          <Tag color="blue" label="Step 1" size="medium" />
        </div>
        <h4 class={styles.title}>
          {$t("responder_steps.fill_and_scan_title")}
        </h4>
      </div>

      <p class={styles.stepText}>
        {@html $t("responder_steps.fill_and_scan_subtitle")}
      </p>
    </div>
  </div>

  <div class={styles.step}>
    <div class={styles.stepInner}>
      <div class={styles.top}>
        <div class={styles.tagWrapper}>
          <Tag color="blue" label="Step 2" size="medium" />
        </div>
        <h4 class={styles.title}>
          {$t("responder_steps.upload_answer_title")}
        </h4>
      </div>

      <p class={styles.stepText}>
        {@html $t("responder_steps.upload_answer_subtitle")}
      </p>

      <div class={styles.addFile}>
        <div class={styles.addFileBottom}>
          {#if addedFilesCount < 10}
            <div class={styles.dropzoneWrapper}>
              <Dropzone
                bind:this={dropzone}
                onFiles={addFiles}
                accept="application/pdf"
              />
            </div>
          {/if}

          {#if addedFilesCount > 0}
            <Dropzone
              bind:this={dropzone}
              onFiles={addFiles}
              accept="application/pdf"
            >
              <div class={styles.dropzoneBody}>
                {#each $addedFiles as addedFile, index (addedFile)}
                  <ResponderUploadItem file={addedFile} {index} />
                {/each}
              </div>
            </Dropzone>
          {/if}
        </div>
      </div>
    </div>
  </div>

  <div class={styles.step}>
    <div class={styles.stepInner}>
      <div class={styles.top}>
        <div class={styles.tagWrapper}>
          <Tag color="blue" label="Step 3" size="medium" />
        </div>
        <h4 class={styles.title}>{$t("responder_steps.confirm_title")}</h4>
      </div>

      <p class={styles.stepText}>
        {@html $t("responder_steps.confirm_subtitle")}
      </p>

      <div class={styles.qrcodeConfirmation}>
        <Checkbox
          toggle={onQrcodeConfirmationToggle}
          label={$t("responder_steps.confirm_qrcode")}
        />
      </div>

      <div class={styles.successWrapper}>
        <Button style="success" disabled={!(qrcodeConfirmation && files.length)}
          >{$t("responder_steps.transfer_answer")}</Button
        >
      </div>
    </div>

    <ResponderQrcodeExample />
  </div>
</form>

<style lang="scss">
  .step {
    display: flex;
    align-items: flex-start;
    margin-bottom: 3rem;
    color: var(--primary-500);
  }

  .stepText {
    line-height: 1.57;
  }

  .top {
    display: flex;
    align-items: center;
  }

  .title {
    margin: 0 0 0 1.2rem;
    font-size: 18px;
    font-weight: 600;
  }

  .tagWrapper {
    white-space: nowrap;
  }

  .addFile {
    margin-top: 3rem;
    border: 1px solid var(--primary-050);
    border-radius: var(--border-radius);
  }

  .addFileTop {
    padding: 10px 20px;
    border-bottom: 1px solid var(--primary-050);
  }

  .dropzoneWrapper {
    padding: 20px;
  }

  .dropzoneBody {
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 0 20px 10px;
    box-sizing: border-box;
  }

  .qrcodeConfirmation {
    display: flex;
    align-items: flex-start;
    margin: 30px 0;
  }

  .successWrapper {
    margin-top: 30px;
  }
</style>
