import { writable } from "svelte/store";

const storageName = "auditi-page-history";

const pageHistory = writable([]);

const setPageHistoryToStorage = (value) =>
  localStorage.setItem(storageName, JSON.stringify(value || []));

const getPageHistoryFromStorage = () =>
  JSON.parse(localStorage.getItem(storageName) || null) || [];

const updatePageHistory = (value) => {
  pageHistory.update((prevValues) => {
    const fullHistory = [...(value || []), ...(prevValues || [])];
    const updatedHistory = [
      ...new Map(fullHistory.map((item) => [item["path"], item])).values(),
    ]
      .filter(Boolean)
      .slice(0, 20);

    setPageHistoryToStorage(updatedHistory);

    return updatedHistory;
  });
};

const initialPageHistory = getPageHistoryFromStorage();

pageHistory.set(initialPageHistory);

export {
  pageHistory,
  updatePageHistory,
  setPageHistoryToStorage,
  getPageHistoryFromStorage,
};
