<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <defs>
    <g>
      <symbol overflow="visible" id="glyph0-0">
        <path
          style="stroke:none;"
          d="M 0.546875 1.9375 L 0.546875 -7.75 L 6.046875 -7.75 L 6.046875 1.9375 Z M 1.171875 1.328125 L 5.4375 1.328125 L 5.4375 -7.140625 L 1.171875 -7.140625 Z M 1.171875 1.328125 "
        />
      </symbol>
      <symbol overflow="visible" id="glyph0-1">
        <path
          style="stroke:none;"
          d="M 0.515625 0 L 0.515625 -0.65625 L 1.546875 -0.65625 L 1.546875 -7.375 L 0.515625 -7.375 L 0.515625 -8.015625 L 4.640625 -8.015625 L 4.640625 -7.375 L 3.609375 -7.375 L 3.609375 -0.734375 L 6.765625 -0.734375 L 6.765625 -2 L 7.5 -2 L 7.5 0 Z M 0.515625 0 "
        />
      </symbol>
      <symbol overflow="visible" id="glyph0-2">
        <path style="stroke:none;" d="" />
      </symbol>
      <symbol overflow="visible" id="glyph0-3">
        <path
          style="stroke:none;"
          d="M 0.515625 0 L 0.515625 -0.65625 L 1.546875 -0.65625 L 1.546875 -7.375 L 0.515625 -7.375 L 0.515625 -8.015625 L 4.640625 -8.015625 L 4.640625 -7.375 L 3.609375 -7.375 L 3.609375 -0.65625 L 4.640625 -0.65625 L 4.640625 0 Z M 0.515625 0 "
        />
      </symbol>
      <symbol overflow="visible" id="glyph0-4">
        <path
          style="stroke:none;"
          d="M 0.515625 0 L 0.515625 -0.65625 L 1.546875 -0.65625 L 1.546875 -7.375 L 0.515625 -7.375 L 0.515625 -8.015625 L 5.265625 -8.015625 C 6.242188 -8.015625 6.976562 -7.847656 7.46875 -7.515625 C 7.957031 -7.191406 8.203125 -6.695312 8.203125 -6.03125 C 8.203125 -5.5625 8.03125 -5.1875 7.6875 -4.90625 C 7.351562 -4.625 6.851562 -4.445312 6.1875 -4.375 C 7 -4.300781 7.617188 -4.085938 8.046875 -3.734375 C 8.472656 -3.378906 8.6875 -2.910156 8.6875 -2.328125 C 8.6875 -1.523438 8.382812 -0.9375 7.78125 -0.5625 C 7.1875 -0.1875 6.25 0 4.96875 0 Z M 3.609375 -4.671875 L 4.3125 -4.671875 C 4.925781 -4.671875 5.378906 -4.78125 5.671875 -5 C 5.972656 -5.226562 6.125 -5.570312 6.125 -6.03125 C 6.125 -6.5 5.976562 -6.835938 5.6875 -7.046875 C 5.40625 -7.265625 4.945312 -7.375 4.3125 -7.375 L 3.609375 -7.375 Z M 3.609375 -0.65625 L 4.375 -0.65625 C 5.0625 -0.65625 5.566406 -0.789062 5.890625 -1.0625 C 6.222656 -1.332031 6.390625 -1.753906 6.390625 -2.328125 C 6.390625 -2.898438 6.222656 -3.328125 5.890625 -3.609375 C 5.554688 -3.890625 5.050781 -4.03125 4.375 -4.03125 L 3.609375 -4.03125 Z M 3.609375 -0.65625 "
        />
      </symbol>
      <symbol overflow="visible" id="glyph0-5">
        <path
          style="stroke:none;"
          d="M 0.515625 0 L 0.515625 -0.65625 L 1.546875 -0.65625 L 1.546875 -7.375 L 0.515625 -7.375 L 0.515625 -8.015625 L 7.71875 -8.015625 L 7.71875 -6.125 L 6.984375 -6.125 L 6.984375 -7.28125 L 3.609375 -7.28125 L 3.609375 -4.703125 L 5.703125 -4.703125 L 5.703125 -5.734375 L 6.453125 -5.734375 L 6.453125 -2.953125 L 5.703125 -2.953125 L 5.703125 -3.984375 L 3.609375 -3.984375 L 3.609375 -0.734375 L 7.078125 -0.734375 L 7.078125 -1.890625 L 7.828125 -1.890625 L 7.828125 0 Z M 0.515625 0 "
        />
      </symbol>
      <symbol overflow="visible" id="glyph0-6">
        <path
          style="stroke:none;"
          d="M 5.84375 -3.921875 C 6.15625 -3.867188 6.421875 -3.757812 6.640625 -3.59375 C 6.859375 -3.4375 7.050781 -3.203125 7.21875 -2.890625 L 8.390625 -0.65625 L 9.203125 -0.65625 L 9.203125 0 L 6.5625 0 L 5.296875 -2.421875 C 5.046875 -2.921875 4.832031 -3.238281 4.65625 -3.375 C 4.488281 -3.519531 4.25 -3.59375 3.9375 -3.59375 L 3.609375 -3.59375 L 3.609375 -0.65625 L 4.640625 -0.65625 L 4.640625 0 L 0.515625 0 L 0.515625 -0.65625 L 1.546875 -0.65625 L 1.546875 -7.375 L 0.515625 -7.375 L 0.515625 -8.015625 L 5.09375 -8.015625 C 6.019531 -8.015625 6.738281 -7.828125 7.25 -7.453125 C 7.757812 -7.078125 8.015625 -6.546875 8.015625 -5.859375 C 8.015625 -5.304688 7.832031 -4.867188 7.46875 -4.546875 C 7.101562 -4.222656 6.5625 -4.015625 5.84375 -3.921875 Z M 3.609375 -4.234375 L 4.3125 -4.234375 C 4.8125 -4.234375 5.195312 -4.367188 5.46875 -4.640625 C 5.738281 -4.910156 5.875 -5.300781 5.875 -5.8125 C 5.875 -6.3125 5.738281 -6.695312 5.46875 -6.96875 C 5.195312 -7.238281 4.8125 -7.375 4.3125 -7.375 L 3.609375 -7.375 Z M 3.609375 -4.234375 "
        />
      </symbol>
      <symbol overflow="visible" id="glyph0-7">
        <path
          style="stroke:none;"
          d="M 2 0 L 2 -0.65625 L 3.0625 -0.65625 L 3.0625 -7.28125 L 0.859375 -7.28125 L 0.859375 -6.015625 L 0.125 -6.015625 L 0.125 -8.015625 L 8.078125 -8.015625 L 8.078125 -6.015625 L 7.34375 -6.015625 L 7.34375 -7.28125 L 5.140625 -7.28125 L 5.140625 -0.65625 L 6.203125 -0.65625 L 6.203125 0 Z M 2 0 "
        />
      </symbol>
      <symbol overflow="visible" id="glyph0-8">
        <path
          style="stroke:none;"
          d="M -0.09375 0 L -0.09375 -0.65625 L 0.5625 -0.65625 L 3.59375 -8.015625 L 4.875 -8.015625 L 7.890625 -0.65625 L 8.65625 -0.65625 L 8.65625 0 L 4.90625 0 L 4.90625 -0.65625 L 5.6875 -0.65625 L 5.03125 -2.28125 L 2.015625 -2.28125 L 1.34375 -0.65625 L 2.296875 -0.65625 L 2.296875 0 Z M 2.28125 -2.9375 L 4.765625 -2.9375 L 3.53125 -6 Z M 2.28125 -2.9375 "
        />
      </symbol>
      <symbol overflow="visible" id="glyph0-9">
        <path
          style="stroke:none;"
          d="M 0.84375 -0.390625 L 0.84375 -2.28125 L 1.53125 -2.28125 C 1.632812 -1.6875 1.878906 -1.238281 2.265625 -0.9375 C 2.648438 -0.644531 3.1875 -0.5 3.875 -0.5 C 4.425781 -0.5 4.847656 -0.601562 5.140625 -0.8125 C 5.429688 -1.019531 5.578125 -1.328125 5.578125 -1.734375 C 5.578125 -2.054688 5.476562 -2.304688 5.28125 -2.484375 C 5.082031 -2.671875 4.675781 -2.835938 4.0625 -2.984375 L 2.859375 -3.28125 C 2.078125 -3.488281 1.523438 -3.769531 1.203125 -4.125 C 0.878906 -4.476562 0.71875 -4.984375 0.71875 -5.640625 C 0.71875 -6.441406 0.984375 -7.0625 1.515625 -7.5 C 2.046875 -7.945312 2.785156 -8.171875 3.734375 -8.171875 C 4.203125 -8.171875 4.691406 -8.128906 5.203125 -8.046875 C 5.710938 -7.960938 6.238281 -7.84375 6.78125 -7.6875 L 6.78125 -5.921875 L 6.09375 -5.921875 C 5.988281 -6.472656 5.757812 -6.875 5.40625 -7.125 C 5.0625 -7.382812 4.566406 -7.515625 3.921875 -7.515625 C 3.390625 -7.515625 2.988281 -7.421875 2.71875 -7.234375 C 2.445312 -7.054688 2.3125 -6.78125 2.3125 -6.40625 C 2.3125 -6.070312 2.40625 -5.816406 2.59375 -5.640625 C 2.78125 -5.472656 3.234375 -5.296875 3.953125 -5.109375 L 5.15625 -4.8125 C 5.90625 -4.625 6.445312 -4.332031 6.78125 -3.9375 C 7.113281 -3.550781 7.28125 -3.015625 7.28125 -2.328125 C 7.28125 -1.515625 7 -0.894531 6.4375 -0.46875 C 5.882812 -0.0507812 5.066406 0.15625 3.984375 0.15625 C 3.460938 0.15625 2.941406 0.109375 2.421875 0.015625 C 1.910156 -0.0664062 1.382812 -0.203125 0.84375 -0.390625 Z M 0.84375 -0.390625 "
        />
      </symbol>
    </g>
  </defs>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(9.803922%,71.372549%,93.72549%);fill-opacity:1;"
      d="M 0 180 L 480 180 L 480 360 L 0 360 Z M 0 180 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 0 L 480 0 L 480 180 L 0 180 Z M 0 0 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,86.666667%,0%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(49.019608%,42.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 469.458911 343.698595 C 500.94905 320.144309 534.874977 272.075804 495.117543 246.798643 C 487.713249 245.363436 479.445954 245.794936 475.481154 248.946764 C 473.376809 247.752321 471.703963 247.849253 469.602745 250.094305 C 468.120635 248.277626 466.638525 247.464654 463.580502 248.374557 C 457.464454 245.269631 451.923741 244.600493 444.660153 246.510976 C 410.068215 267.294906 431.233615 316.607883 469.458911 343.698595 Z M 469.458911 343.698595 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(30.980392%,83.137255%,41.960784%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(22.745098%,61.568627%,30.980392%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 527.69894 290.198815 C 527.69894 290.198815 531.244747 285.433551 531.366693 285.433551 C 534.909372 283.357347 535.278336 280.668288 535.278336 280.668288 C 538.821016 279.567649 537.720378 276.634698 537.964269 276.268861 C 539.677763 274.436548 539.064908 272.601109 538.821016 271.99138 C 538.821016 271.503597 540.0436 266.982225 538.333233 266.369369 C 538.41453 261.563457 535.440931 262.010591 533.07706 265.146785 C 530.550594 265.881586 529.981514 267.957791 530.875783 270.281013 C 527.576995 270.281013 527.455049 275.046277 528.433742 277.735336 C 524.034315 277.613391 526.479483 282.5006 526.354411 282.744492 C 524.887935 283.479293 527.455049 290.445834 527.69894 290.198815 Z M 527.69894 290.198815 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(30.980392%,83.137255%,41.960784%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(22.745098%,61.568627%,30.980392%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 499.94847 331.141312 C 500.683271 331.588446 501.418072 332.035581 502.149747 332.482715 C 502.596881 334.15556 504.144654 335.21555 505.326589 334.927883 C 505.898796 337.494997 508.669153 337.12916 510.704709 335.662684 C 512.333779 338.026555 514.331812 338.067204 517.061521 337.251106 C 519.503562 339.04277 522.683531 338.270447 524.759736 336.394359 C 526.998534 338.226671 528.505658 336.885268 529.646945 334.927883 C 531.397961 335.378144 532.783139 335.090477 533.433516 333.339462 C 536.857377 333.054922 535.14701 329.837431 532.336005 328.452252 C 534.574803 326.494867 537.06062 322.464404 533.558589 321.851549 C 532.457951 321.038577 530.381746 321.201172 528.793325 321.973495 C 528.383713 320.18183 525.897896 319.734696 523.171314 321.729603 C 522.23327 319.734696 518.975131 320.669613 517.427358 322.09544 C 515.469973 320.385073 513.149877 320.385073 509.726016 322.342459 Z M 499.94847 331.141312 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(30.980392%,83.137255%,41.960784%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(22.745098%,61.568627%,30.980392%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 499.94847 325.891392 C 500.358083 323.649467 498.81031 319.575229 501.171054 319.168743 C 500.72392 315.419693 501.377424 311.185988 505.695553 311.592474 C 506.386579 308.046667 506.223985 304.747879 510.338872 304.260096 C 510.338872 304.260096 513.63766 292.772183 517.061521 300.714289 C 518.402924 303.037512 518.159032 306.824083 515.469973 306.092409 C 516.001531 308.944063 515.063487 311.426753 511.927293 311.345455 C 513.312471 313.343489 512.865337 316.073197 511.314437 317.33643 C 507.527866 320.188084 503.738168 323.039738 499.94847 325.891392 Z M 499.94847 325.891392 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(30.980392%,83.137255%,41.960784%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(22.745098%,61.568627%,30.980392%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 519.869399 311.589347 C 521.051335 311.345455 522.23327 311.101564 523.415206 310.857672 C 527.079831 308.412504 528.546307 307.433812 530.37862 310.122871 C 533.433516 309.472494 536.366468 309.797683 536.244522 312.202202 C 539.871626 312.446094 539.830977 314.766189 539.424491 316.598502 C 539.993571 319.81912 538.608393 324.015304 536.366468 318.921725 C 529.281108 314.603595 525.247519 315.172675 514.003497 317.699141 C 515.957755 315.663585 517.915141 313.628029 519.869399 311.589347 Z M 519.869399 311.589347 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(30.980392%,83.137255%,41.960784%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(22.745098%,61.568627%,30.980392%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 521.711092 308.040413 C 521.833038 307.918468 532.220313 305.229408 531.119674 302.052565 C 534.052626 301.561656 534.665481 298.628705 534.787427 298.628705 C 540.89722 296.674446 540.531383 293.12864 540.531383 293.12864 C 542.282399 291.255678 545.377944 289.382717 544.686918 286.04328 C 544.890161 282.253582 545.21535 279.933486 540.165546 282.375528 C 536.375848 282.009691 535.031318 284.207841 533.93068 287.509756 C 532.139016 285.389776 529.246713 288.772988 528.921524 291.787237 C 528.921524 291.787237 524.278206 296.308609 524.278206 296.430555 C 524.278206 296.5525 520.366563 303.640987 520.366563 303.640987 C 520.813697 305.107462 521.260831 306.573938 521.711092 308.040413 Z M 521.711092 308.040413 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(30.980392%,83.137255%,41.960784%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(22.745098%,61.568627%,30.980392%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 521.94873 299.979488 C 519.46604 298.106527 518.080862 295.864601 518.527996 293.625803 C 516.898926 291.424526 515.757639 290.079996 517.305412 288.125738 C 517.305412 288.125738 516.082828 283.726311 516.082828 283.604366 C 512.905985 282.381781 514.250515 279.692722 515.107262 278.717156 C 513.600138 276.637825 513.55949 274.436548 514.985316 272.48229 C 514.941541 268.570646 517.711898 270.040248 519.994472 272.48229 C 519.994472 272.48229 523.78417 275.171349 520.973164 277.616518 C 523.78417 278.59521 524.63779 281.037252 523.049369 282.015944 C 525.494537 283.113456 525.860374 285.314733 524.515844 286.781208 C 527.001661 288.779242 526.063617 291.261932 526.839067 293.503857 C 525.209997 295.661359 523.5778 297.821987 521.94873 299.979488 Z M 521.94873 299.979488 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(30.980392%,83.137255%,41.960784%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(22.745098%,61.568627%,30.980392%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 526.351284 281.768926 C 526.229338 281.64698 522.195749 276.515879 523.296387 276.146915 C 523.052495 274.558494 521.829911 272.848127 522.561586 271.259706 C 520.566679 269.264799 520.52603 266.900928 522.073803 265.027966 C 520.729273 263.195653 521.339002 260.750485 523.174441 259.162064 C 522.602234 256.147816 524.722214 255.453663 526.595175 254.884583 C 527.980354 250.034895 530.219152 251.298128 531.482385 255.006529 C 533.399121 256.676247 533.111455 259.202713 532.461077 261.116322 C 534.740525 262.664095 533.358473 264.577705 532.339132 265.393803 C 530.344225 270.85322 528.346191 276.30951 526.351284 281.768926 Z M 526.351284 281.768926 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(30.980392%,83.137255%,41.960784%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(22.745098%,61.568627%,30.980392%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 525.741555 255.978968 L 522.317694 252.558234 C 523.202582 250.744682 523.915496 247.549078 521.342128 246.079476 C 519.922555 242.624347 512.827815 238.340613 511.686528 246.570386 C 510.604651 244.087696 508.347091 241.642528 506.677373 244.494182 C 502.968972 241.317339 500.974065 242.292905 502.887675 246.326494 C 502.887675 246.326494 501.177308 249.015554 505.69868 251.091758 C 506.064517 251.457595 504.232205 255.978968 509.610324 256.100914 C 508.590983 257.648686 510.260701 259.809314 512.421329 259.64672 C 510.873557 261.56033 513.478192 263.599012 515.107262 262.823563 C 514.416236 264.943542 514.456885 265.959757 517.430485 266.247424 C 518.531123 267.507529 519.628635 268.770762 520.729273 270.033995 C 521.626668 271.256579 522.520937 272.479163 523.418333 273.69862 C 524.190656 267.792069 524.966105 261.885519 525.741555 255.978968 Z M 525.741555 255.978968 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.3333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(22.745098%,61.568627%,30.980392%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 527.820886 288.041314 C 527.992861 287.866212 534.387195 273.176442 535.250195 268.683211 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.3333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(22.745098%,61.568627%,30.980392%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 528.508785 261.951182 C 528.508785 261.951182 529.546887 273.873722 526.607682 282.688209 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.3333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(22.745098%,61.568627%,30.980392%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 508.80986 249.850413 C 508.80986 249.850413 521.945603 262.467106 523.152553 267.651363 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.3333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(22.745098%,61.568627%,30.980392%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 517.971423 248.471488 C 517.971423 248.471488 518.834424 258.840002 522.461528 269.036541 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.3333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(22.745098%,61.568627%,30.980392%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 541.128605 286.140211 C 541.128605 286.140211 527.995988 297.199751 521.598527 306.880366 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.3333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(22.745098%,61.568627%,30.980392%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 528.68076 324.859544 C 528.68076 324.859544 511.395735 327.279697 503.794451 327.451672 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.3333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(22.745098%,61.568627%,30.980392%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 522.980579 332.288853 C 522.980579 332.288853 501.549399 331.769801 499.992246 330.215775 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.3333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(22.745098%,61.568627%,30.980392%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 512.258735 304.460212 C 512.258735 304.632187 501.37117 322.605111 501.027221 328.48352 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(39.607843%,78.039216%,100%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(49.019608%,42.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 469.340092 340.230959 C 446.66444 318.843554 423.441596 278.704649 446.542495 258.421009 C 450.651128 260.750485 455.491435 258.664901 462.170309 256.032124 C 464.193357 258.236527 466.766725 258.789973 469.340092 257.132762 C 472.219888 258.358473 474.364882 257.37978 475.775075 255.847642 C 482.332003 259.64672 490.727497 261.607232 492.688009 257.87069 C 515.42307 279.43945 492.381581 319.762837 469.340092 340.230959 Z M 469.340092 340.230959 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(56.078431%,78.039216%,32.54902%);fill-opacity:1;"
      d="M 237.816406 249.304688 C 225.652344 237.074219 214.550781 223.515625 206.054688 206.0625 C 207.507812 204.835938 208.183594 204.457031 208.949219 202.3125 C 213.3125 202.925781 215.609375 203.078125 221.351562 202.082031 C 222.5 206.371094 222.730469 209.96875 225.484375 213.566406 C 227.398438 209.816406 229.3125 206.066406 231.226562 202.3125 C 235.132812 203.234375 239.953125 203.460938 243.628906 202.3125 C 246.003906 205.527344 245.160156 210.351562 249.371094 214.027344 C 251.894531 206.601562 254.421875 206.066406 256.949219 202.082031 C 260.703125 203.386719 263.074219 202.847656 266.136719 201.855469 C 267.746094 203.691406 266.941406 203.660156 269.867188 206.359375 C 262.195312 221.597656 251.671875 236.90625 237.816406 249.304688 Z M 237.816406 249.304688 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.880763 239.299029 C 442.880763 240.409048 441.980241 241.30957 440.870222 241.30957 C 439.76333 241.30957 438.862808 240.409048 438.862808 239.299029 C 438.862808 238.192137 439.76333 237.291615 440.870222 237.291615 C 441.980241 237.291615 442.880763 238.192137 442.880763 239.299029 Z M 442.880763 239.299029 "
      transform="matrix(1.249275,0,0,1.249275,-348.791584,-175.723732)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.878753 239.300105 C 442.878753 240.410124 441.981358 241.307519 440.871339 241.307519 C 439.76132 241.307519 438.863925 240.410124 438.863925 239.300105 C 438.863925 238.190086 439.76132 237.292691 440.871339 237.292691 C 441.981358 237.292691 442.878753 238.190086 442.878753 239.300105 Z M 442.878753 239.300105 "
      transform="matrix(1.249275,0,0,1.249275,-351.410167,-181.506326)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.878985 239.298997 C 442.878985 240.409016 441.98159 241.309538 440.871571 241.309538 C 439.761552 241.309538 438.864157 240.409016 438.864157 239.298997 C 438.864157 238.192105 439.761552 237.291583 440.871571 237.291583 C 441.98159 237.291583 442.878985 238.192105 442.878985 239.298997 Z M 442.878985 239.298997 "
      transform="matrix(1.249275,0,0,1.249275,-354.465144,-186.852598)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.881074 239.301467 C 442.881074 240.408359 441.980552 241.308881 440.870533 241.308881 C 439.760514 241.308881 438.863119 240.408359 438.863119 239.301467 C 438.863119 238.191448 439.760514 237.290926 440.870533 237.290926 C 441.980552 237.290926 442.881074 238.191448 442.881074 239.301467 Z M 442.881074 239.301467 "
      transform="matrix(1.249275,0,0,1.249275,-356.538066,-192.090059)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.878699 239.299207 C 442.878699 240.409226 441.981304 241.309748 440.871285 241.309748 C 439.761266 241.309748 438.863871 240.409226 438.863871 239.299207 C 438.863871 238.189188 439.761266 237.291792 440.871285 237.291792 C 441.981304 237.291792 442.878699 238.189188 442.878699 239.299207 Z M 442.878699 239.299207 "
      transform="matrix(1.249275,0,0,1.249275,-356.210881,-197.763017)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.881351 239.299601 C 442.881351 240.409619 441.980829 241.310142 440.87081 241.310142 C 439.760791 241.310142 438.863396 240.409619 438.863396 239.299601 C 438.863396 238.189582 439.760791 237.292186 440.87081 237.292186 C 441.980829 237.292186 442.881351 238.189582 442.881351 239.299601 Z M 442.881351 239.299601 "
      transform="matrix(1.249275,0,0,1.249275,-342.964194,-207.146321)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.879345 239.301007 C 442.879345 240.407899 441.981949 241.308421 440.87193 241.308421 C 439.761912 241.308421 438.861389 240.407899 438.861389 239.301007 C 438.861389 238.190988 439.761912 237.290466 440.87193 237.290466 C 441.981949 237.290466 442.879345 238.190988 442.879345 239.301007 Z M 442.879345 239.301007 "
      transform="matrix(1.249275,0,0,1.249275,-347.918718,-206.273078)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.881093 239.301277 C 442.881093 240.408169 441.980571 241.308691 440.870552 241.308691 C 439.760533 241.308691 438.863137 240.408169 438.863137 239.301277 C 438.863137 238.191258 439.760533 237.290736 440.870552 237.290736 C 441.980571 237.290736 442.881093 238.191258 442.881093 239.301277 Z M 442.881093 239.301277 "
      transform="matrix(1.249275,0,0,1.249275,-353.374027,-202.999977)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.879818 239.300151 C 442.879818 240.41017 441.979296 241.307566 440.872404 241.307566 C 439.762385 241.307566 438.861863 240.41017 438.861863 239.300151 C 438.861863 238.190133 439.762385 237.292737 440.872404 237.292737 C 441.979296 237.292737 442.879818 238.190133 442.879818 239.300151 Z M 442.879818 239.300151 "
      transform="matrix(1.249275,0,0,1.249275,-336.571653,-207.037634)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.880861 239.300905 C 442.880861 240.410924 441.980338 241.30832 440.87032 241.30832 C 439.763428 241.30832 438.862905 240.410924 438.862905 239.300905 C 438.862905 238.190887 439.763428 237.290364 440.87032 237.290364 C 441.980338 237.290364 442.880861 238.190887 442.880861 239.300905 Z M 442.880861 239.300905 "
      transform="matrix(1.249275,0,0,1.249275,-329.588581,-206.491701)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.880748 239.300253 C 442.880748 240.410272 441.980225 241.307667 440.870207 241.307667 C 439.763315 241.307667 438.862792 240.410272 438.862792 239.300253 C 438.862792 238.190234 439.763315 237.292839 440.870207 237.292839 C 441.980225 237.292839 442.880748 238.190234 442.880748 239.300253 Z M 442.880748 239.300253 "
      transform="matrix(1.249275,0,0,1.249275,-323.36969,-206.819011)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,86.666667%,0%);fill-opacity:1;stroke-width:0.25989;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(49.019608%,42.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.880225 239.300354 C 442.880225 240.409586 441.98065 241.30916 440.871419 241.30916 C 439.762188 241.30916 438.862613 240.409586 438.862613 239.300354 C 438.862613 238.191123 439.762188 237.291549 440.871419 237.291549 C 441.98065 237.291549 442.880225 238.191123 442.880225 239.300354 Z M 442.880225 239.300354 "
      transform="matrix(3.20464,0,0,3.20464,-1174.865536,-678.742637)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.879374 239.299702 C 442.879374 240.409721 441.981979 241.310243 440.87196 241.310243 C 439.761941 241.310243 438.861419 240.409721 438.861419 239.299702 C 438.861419 238.189683 439.761941 237.292288 440.87196 237.292288 C 441.981979 237.292288 442.879374 238.189683 442.879374 239.299702 Z M 442.879374 239.299702 "
      transform="matrix(1.249275,0,0,1.249275,-302.094537,-206.927698)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.878914 239.301456 C 442.878914 240.408348 441.981518 241.30887 440.8715 241.30887 C 439.761481 241.30887 438.864085 240.408348 438.864085 239.301456 C 438.864085 238.191437 439.761481 237.290915 440.8715 237.290915 C 441.981518 237.290915 442.878914 238.191437 442.878914 239.301456 Z M 442.878914 239.301456 "
      transform="matrix(1.249275,0,0,1.249275,-295.547086,-206.383014)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.879352 239.300804 C 442.879352 240.410823 441.981956 241.308218 440.871938 241.308218 C 439.761919 241.308218 438.861396 240.410823 438.861396 239.300804 C 438.861396 238.190785 439.761919 237.290263 440.871938 237.290263 C 441.981956 237.290263 442.879352 238.190785 442.879352 239.300804 Z M 442.879352 239.300804 "
      transform="matrix(1.249275,0,0,1.249275,-289.219509,-206.710324)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.88096 239.299702 C 442.88096 240.409721 441.980437 241.310243 440.870419 241.310243 C 439.7604 241.310243 438.863004 240.409721 438.863004 239.299702 C 438.863004 238.189683 439.7604 237.292288 440.870419 237.292288 C 441.980437 237.292288 442.88096 238.189683 442.88096 239.299702 Z M 442.88096 239.299702 "
      transform="matrix(1.249275,0,0,1.249275,-283.873861,-206.927698)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.88105 239.301558 C 442.88105 240.40845 441.980528 241.308972 440.870509 241.308972 C 439.76049 241.308972 438.863094 240.40845 438.863094 239.301558 C 438.863094 238.191539 439.76049 237.291017 440.870509 237.291017 C 441.980528 237.291017 442.88105 238.191539 442.88105 239.301558 Z M 442.88105 239.301558 "
      transform="matrix(1.249275,0,0,1.249275,-277.217724,-206.164391)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.87898 239.298701 C 442.87898 240.408719 441.981585 241.309242 440.871566 241.309242 C 439.761547 241.309242 438.864151 240.408719 438.864151 239.298701 C 438.864151 238.191809 439.761547 237.291286 440.871566 237.291286 C 441.981585 237.291286 442.87898 238.191809 442.87898 239.298701 Z M 442.87898 239.298701 "
      transform="matrix(1.249275,0,0,1.249275,-271.980763,-202.89129)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,0%,0%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(67.45098%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 440.848567 231.1208 C 442.562061 234.372686 444.272428 237.752772 445.982795 241.007785 L 493.547883 241.007785 C 495.383322 237.837196 497.218762 234.666607 499.054201 231.496018 C 495.842964 229.285361 493.632307 227.571867 489.042144 228.616222 C 486.453143 224.901568 483.617123 224.194908 479.402178 224.610774 C 478.151453 223.3194 477.022673 222.52519 474.89644 222.234396 C 471.557003 222.315693 468.220693 222.400117 464.884383 222.484541 C 462.254733 222.731559 460.250445 224.735847 460.125373 224.735847 C 455.869779 224.235557 451.989404 224.360629 450.988824 228.49115 C 447.108448 227.531218 444.353725 228.575574 440.848567 231.1208 Z M 440.848567 231.1208 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.879869 239.299859 C 442.879869 240.409878 441.979347 241.307273 440.872455 241.307273 C 439.762436 241.307273 438.861913 240.409878 438.861913 239.299859 C 438.861913 238.18984 439.762436 237.292445 440.872455 237.292445 C 441.979347 237.292445 442.879869 238.18984 442.879869 239.299859 Z M 442.879869 239.299859 "
      transform="matrix(1.249275,0,0,1.249275,-269.798279,-197.435707)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.881072 239.299094 C 442.881072 240.409113 441.98055 241.309635 440.870531 241.309635 C 439.760512 241.309635 438.863117 240.409113 438.863117 239.299094 C 438.863117 238.189075 439.760512 237.29168 440.870531 237.29168 C 441.98055 237.29168 442.881072 238.192202 442.881072 239.299094 Z M 442.881072 239.299094 "
      transform="matrix(1.249275,0,0,1.249275,-269.362282,-191.544126)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.880836 239.300453 C 442.880836 240.410471 441.980313 241.307867 440.870294 241.307867 C 439.763402 241.307867 438.86288 240.410471 438.86288 239.300453 C 438.86288 238.190434 439.763402 237.289911 440.870294 237.289911 C 441.980313 237.289911 442.880836 238.190434 442.880836 239.300453 Z M 442.880836 239.300453 "
      transform="matrix(1.249275,0,0,1.249275,-271.217456,-186.088792)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.879396 239.299954 C 442.879396 240.409973 441.982 241.307368 440.871982 241.307368 C 439.761963 241.307368 438.861441 240.409973 438.861441 239.299954 C 438.861441 238.189935 439.761963 237.29254 440.871982 237.29254 C 441.982 237.29254 442.879396 238.189935 442.879396 239.299954 Z M 442.879396 239.299954 "
      transform="matrix(1.249275,0,0,1.249275,-273.508626,-181.615513)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.87988 239.298607 C 442.87988 240.408626 441.979358 241.309148 440.872466 241.309148 C 439.762447 241.309148 438.861925 240.408626 438.861925 239.298607 C 438.861925 238.191715 439.762447 237.291193 440.872466 237.291193 C 441.979358 237.291193 442.87988 238.191715 442.87988 239.298607 Z M 442.87988 239.298607 "
      transform="matrix(1.249275,0,0,1.249275,-276.235793,-175.941955)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.45098;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.880894 239.299279 C 442.880894 240.409728 441.979844 241.308663 440.87151 241.308663 C 439.761061 241.308663 438.862126 240.409728 438.862126 239.299279 C 438.862126 238.190944 439.761061 237.292009 440.87151 237.292009 C 441.979844 237.292009 442.880894 238.190944 442.880894 239.299279 Z M 442.880894 239.299279 "
      transform="matrix(1.846803,0,0,1.846803,-575.988086,-326.122275)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.37398;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.880437 239.300551 C 442.880437 240.409127 441.980597 241.308967 440.872022 241.308967 C 439.761692 241.308967 438.861852 240.409127 438.861852 239.300551 C 438.861852 238.190222 439.761692 237.292136 440.872022 237.292136 C 441.980597 237.292136 442.880437 238.190222 442.880437 239.300551 Z M 442.880437 239.300551 "
      transform="matrix(2.226958,0,0,2.226958,-743.615806,-425.627029)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.41441;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.879105 239.300096 C 442.879105 240.409943 441.98112 241.307927 440.871274 241.307927 C 439.761427 241.307927 438.863442 240.409943 438.863442 239.300096 C 438.863442 238.190249 439.761427 237.292265 440.871274 237.292265 C 441.98112 237.292265 442.879105 238.190249 442.879105 239.300096 Z M 442.879105 239.300096 "
      transform="matrix(2.009709,0,0,2.009709,-648.046268,-381.927389)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,86.666667%,0%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(49.019608%,42.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 468.511487 205.518451 C 468.511487 204.965005 468.492726 203.020127 468.533374 202.941956 L 466.38838 202.904435 L 466.44779 200.997078 L 468.311371 200.975191 L 468.333258 199.308599 L 470.972289 199.308599 L 470.972289 200.834484 L 473.054747 200.834484 L 473.010971 202.863786 L 470.850343 202.841898 L 470.831582 205.559099 Z M 468.511487 205.518451 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,86.666667%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 445.738904 241.010911 C 441.48331 233.791099 435.226556 223.979158 439.103805 219.729818 C 444.481925 213.641912 457.08611 219.148231 465.140782 215.599298 C 465.725496 222.484541 463.805632 233.875523 466.891797 236.255028 C 465.931865 237.08676 464.971934 237.92162 464.015129 238.756479 C 462.260986 236.505173 458.959071 233.484671 454.875453 238.881551 C 452.539723 236.839742 449.885058 237.199326 448.634333 239.916527 C 447.443017 240.075994 447.511807 240.55127 445.738904 241.010911 Z M 445.738904 241.010911 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,86.666667%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 493.838676 240.760766 C 498.097396 233.540954 504.351024 223.729013 500.473775 219.479673 C 495.098782 213.391767 482.49147 218.898086 474.439925 215.349153 C 473.855211 222.234396 475.775075 233.625378 472.685783 236.004883 C 473.645715 236.836615 474.605646 237.671474 475.565578 238.506334 C 478.335935 234.613451 481.794191 234.485251 484.702127 238.631406 C 487.040984 236.589597 489.461137 236.867883 490.946374 239.666382 C 492.13769 239.825849 492.0689 240.301125 493.838676 240.760766 Z M 493.838676 240.760766 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,86.666667%,0%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(49.019608%,42.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 445.610704 246.389031 C 462.717501 244.638015 478.570446 245.263378 493.175792 246.389031 C 493.801155 244.597366 494.426517 242.799449 495.05188 241.007785 C 478.4485 238.002917 466.973094 237.627699 445.235487 240.632567 C 445.360559 242.55243 445.485632 244.472294 445.610704 246.389031 Z M 445.610704 246.389031 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(78.039216%,43.137255%,18.039216%);fill-opacity:1;"
      d="M 235.589844 247.03125 C 235.746094 245.960938 235.898438 244.960938 236.050781 243.890625 C 238.175781 244.460938 239.796875 244.242188 241.5625 243.589844 C 242.175781 242.667969 242.789062 241.75 243.398438 240.832031 C 242.941406 240.296875 242.480469 239.761719 242.023438 239.226562 C 240.808594 239.484375 239.816406 240.613281 239.035156 241.59375 C 237.816406 241.652344 236.738281 241.0625 235.589844 240.832031 C 235.207031 239.222656 234.824219 237.617188 234.441406 236.007812 C 233.304688 234.664062 230.941406 234.757812 231.457031 237.15625 C 231.535156 237.769531 231.609375 238.382812 231.6875 238.996094 C 232.148438 240.144531 232.316406 241.722656 233.066406 242.582031 L 233.066406 244.464844 C 233.90625 245.324219 234.75 246.175781 235.589844 247.03125 Z M 235.589844 247.03125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(78.039216%,43.137255%,18.039216%);fill-opacity:1;"
      d="M 236.503906 233.316406 C 234.90625 234.109375 233.007812 232.730469 230.992188 231.9375 C 229.152344 231.8125 227.675781 233.34375 225.480469 232.855469 C 225.878906 231.664062 226.855469 231.476562 227.546875 230.789062 C 227.042969 227.691406 228.695312 226.65625 229.15625 226.65625 C 229.613281 226.65625 231.449219 227.113281 231.449219 227.113281 C 232.0625 227.191406 232.675781 227.269531 233.289062 227.34375 C 234.359375 229.335938 235.863281 231.109375 236.503906 233.316406 Z M 236.503906 233.316406 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,88.235294%,0%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(91.372549%,74.901961%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 459.681365 359.829826 C 463.921324 352.941456 493.454078 339.11156 521.170154 309.25049 C 492.960042 336.41312 475.32794 343.079486 452.242676 359.664105 C 454.722239 359.720388 457.201802 359.773544 459.681365 359.829826 Z M 459.681365 359.829826 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(61.568627%,28.627451%,8.627451%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(40.784314%,18.823529%,5.490196%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 500.279912 328.070781 C 500.279912 329.25897 499.316854 330.218902 498.128665 330.218902 C 496.943602 330.218902 495.980544 329.25897 495.980544 328.070781 C 495.980544 326.882592 496.943602 325.919533 498.128665 325.919533 C 499.316854 325.919533 500.279912 326.882592 500.279912 328.070781 Z M 500.279912 328.070781 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(61.568627%,28.627451%,8.627451%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(40.784314%,18.823529%,5.490196%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 520.920009 306.709901 C 520.920009 308.054431 519.828751 309.145689 518.484221 309.145689 C 517.136565 309.145689 516.045307 308.054431 516.045307 306.709901 C 516.045307 305.365371 517.136565 304.274113 518.484221 304.274113 C 519.828751 304.274113 520.920009 305.365371 520.920009 306.709901 Z M 520.920009 306.709901 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.422824)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(61.568627%,28.627451%,8.627451%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(40.784314%,18.823529%,5.490196%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 527.228735 289.220596 C 527.228735 290.408785 526.268803 291.368717 525.080614 291.368717 C 523.892425 291.368717 522.929367 290.408785 522.929367 289.220596 C 522.929367 288.032407 523.892425 287.069348 525.080614 287.069348 C 526.268803 287.069348 527.228735 288.032407 527.228735 289.220596 Z M 527.228735 289.220596 "
      transform="matrix(1.249275,0,0,1.249275,-347.966178,-174.780904)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(61.568627%,28.627451%,8.627451%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(40.784314%,18.823529%,5.490196%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 521.060715 266.291199 C 521.060715 267.476261 520.097657 268.43932 518.909467 268.43932 C 517.721278 268.43932 516.75822 267.476261 516.75822 266.291199 C 516.75822 265.10301 517.721278 264.139951 518.909467 264.139951 C 520.097657 264.139951 521.060715 265.10301 521.060715 266.291199 Z M 521.060715 266.291199 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(66.27451%,66.27451%,66.27451%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 446.680074 301.899352 L 446.680074 292.806578 L 445.754538 292.037382 L 445.754538 289.876754 L 447.452397 289.72354 L 447.758825 279.552015 L 446.680074 278.932906 L 446.526861 277.238173 C 446.526861 277.238173 447.758825 277.700942 447.758825 277.394514 C 447.758825 277.084959 448.06838 275.390226 448.06838 275.390226 C 448.06838 275.390226 447.296057 275.233886 447.296057 274.927458 C 447.296057 274.617903 448.221594 273.848707 448.221594 273.848707 C 448.221594 273.848707 447.758825 273.385939 447.605611 273.076384 C 447.452397 272.769957 447.142843 271.381651 447.142843 271.381651 L 447.605611 269.686918 L 447.296057 268.761382 L 446.526861 267.373076 L 447.605611 266.294326 C 447.605611 266.294326 447.296057 264.909147 447.296057 264.599593 C 447.296057 264.293165 448.06838 263.058074 448.221594 262.90486 C 448.374807 262.748519 449.609899 261.053786 449.609899 261.053786 L 452.230168 260.437804 L 455.313207 260.900572 L 457.00794 261.979323 L 457.314367 264.752807 C 457.314367 264.752807 457.00794 266.44754 456.851599 266.44754 C 456.698385 266.44754 455.466421 267.066649 455.466421 267.066649 C 455.466421 267.066649 453.924901 267.219863 453.768561 267.066649 C 453.615347 266.913435 454.234456 268.914595 454.234456 268.914595 L 454.234456 270.765669 L 454.078115 273.076384 C 454.078115 273.076384 454.078115 274.155135 453.924901 274.311476 C 453.768561 274.46469 453.462133 274.927458 453.462133 274.927458 L 453.308919 277.238173 L 455.772848 277.854155 L 455.619634 279.242461 L 453.924901 279.395674 L 454.234456 288.798003 L 456.698385 289.260771 L 456.698385 291.881041 L 455.619634 292.497023 L 455.313207 301.899352 Z M 446.680074 301.899352 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(66.27451%,66.27451%,66.27451%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 484.139301 302.058819 L 484.139301 292.966045 L 483.213764 292.196849 L 483.213764 290.039348 L 484.911624 289.883007 L 485.218051 279.711483 L 484.139301 279.0955 L 483.986087 277.397641 C 483.986087 277.397641 485.218051 277.860409 485.218051 277.553981 C 485.218051 277.244427 485.527606 275.549694 485.527606 275.549694 C 485.527606 275.549694 484.75841 275.39648 484.75841 275.086925 C 484.75841 274.780498 485.68082 274.008175 485.68082 274.008175 C 485.68082 274.008175 485.218051 273.545406 485.064838 273.235852 C 484.911624 272.929424 484.602069 271.541119 484.602069 271.541119 L 485.064838 269.846386 L 484.75841 268.920849 L 483.986087 267.532544 L 485.064838 266.453793 C 485.064838 266.453793 484.75841 265.068615 484.75841 264.75906 C 484.75841 264.452633 485.527606 263.217541 485.68082 263.064327 C 485.837161 262.911113 487.069125 261.216381 487.069125 261.216381 L 489.689395 260.597271 L 492.772433 261.06004 L 494.467166 262.13879 L 494.773594 264.915401 C 494.773594 264.915401 494.467166 266.610134 494.313952 266.610134 C 494.157611 266.610134 492.925647 267.226116 492.925647 267.226116 C 492.925647 267.226116 491.384128 267.37933 491.230914 267.226116 C 491.074573 267.072902 491.693682 269.07719 491.693682 269.07719 L 491.693682 270.925137 L 491.540468 273.235852 C 491.540468 273.235852 491.540468 274.314602 491.384128 274.470943 C 491.230914 274.624157 490.921359 275.086925 490.921359 275.086925 L 490.768146 277.397641 L 493.235201 278.013623 L 493.078861 279.401928 L 491.384128 279.558269 L 491.693682 288.95747 L 494.157611 289.420239 L 494.157611 292.040508 L 493.078861 292.656491 L 492.772433 302.058819 Z M 484.139301 302.058819 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(66.27451%,66.27451%,66.27451%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 465.18143 302.3715 L 465.18143 293.2756 L 464.255893 292.506404 L 464.255893 290.348902 L 465.950626 290.192562 L 466.260181 280.021037 L 465.18143 279.405055 L 465.02509 277.710322 C 465.02509 277.710322 466.260181 278.169964 466.260181 277.863536 C 466.260181 277.553981 466.566609 275.859248 466.566609 275.859248 C 466.566609 275.859248 465.797412 275.706035 465.797412 275.39648 C 465.797412 275.090052 466.719822 274.317729 466.719822 274.317729 C 466.719822 274.317729 466.260181 273.854961 466.10384 273.548533 C 465.950626 273.238979 465.644199 271.850673 465.644199 271.850673 L 466.10384 270.15594 L 465.797412 269.230404 L 465.02509 267.845225 L 466.10384 266.766475 C 466.10384 266.766475 465.797412 265.378169 465.797412 265.068615 C 465.797412 264.762187 466.566609 263.530223 466.719822 263.373882 C 466.876163 263.220668 468.108128 261.525935 468.108128 261.525935 L 470.728397 260.906826 L 473.811436 261.369594 L 475.506169 262.451472 L 475.815723 265.224955 C 475.815723 265.224955 475.506169 266.919688 475.352955 266.919688 C 475.199741 266.919688 473.964649 267.535671 473.964649 267.535671 C 473.964649 267.535671 472.42313 267.688885 472.269917 267.535671 C 472.116703 267.382457 472.732685 269.386744 472.732685 269.386744 L 472.732685 271.234691 L 472.579471 273.548533 C 472.579471 273.548533 472.579471 274.627284 472.42313 274.780498 C 472.269917 274.933712 471.963489 275.39648 471.963489 275.39648 L 471.807148 277.710322 L 474.274204 278.326304 L 474.12099 279.711483 L 472.42313 279.867823 L 472.732685 289.270152 L 475.199741 289.729793 L 475.199741 292.35319 L 474.12099 292.969172 L 473.811436 302.3715 Z M 465.18143 302.3715 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 212.191406 202.121094 L 212.191406 193.167969 L 217.207031 193.167969 L 217.207031 202.480469 Z M 212.191406 202.121094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 235.828125 202.832031 L 236.007812 193.699219 L 240.484375 193.699219 L 240.484375 202.652344 Z M 235.828125 202.832031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 259.113281 202.660156 L 258.757812 194.0625 L 263.59375 193.882812 L 263.59375 202.839844 Z M 259.113281 202.660156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 213.441406 175.613281 L 216.304688 175.613281 L 216.304688 180.269531 L 213.441406 180.269531 Z M 213.441406 175.613281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 236.191406 175.800781 L 240.128906 175.800781 L 240.128906 180.277344 L 236.191406 180.277344 Z M 236.191406 175.800781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 259.464844 176.148438 L 262.867188 176.148438 L 262.867188 180.445312 L 259.464844 180.445312 Z M 259.464844 176.148438 "
    />
    <path
      style="fill:none;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(65.882353%,65.882353%,65.882353%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 451.151418 264.299419 C 453.615347 267.072902 453.768561 267.072902 453.768561 267.072902 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(65.882353%,65.882353%,65.882353%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 469.490179 264.299419 C 469.952948 265.224955 470.722144 267.229243 471.647681 267.382457 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(65.882353%,65.882353%,65.882353%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 489.220373 264.75906 C 489.220373 264.75906 489.989569 266.916562 491.071446 267.226116 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(72.54902%,46.666667%,0%);fill-opacity:1;"
      d="M 211.65625 145.515625 C 220.664062 142.605469 209.550781 136.78125 207.703125 145.515625 C 204.789062 145.988281 204.414062 148.148438 198.113281 147.210938 C 182.878906 172.039062 194.164062 212.097656 238.738281 253.574219 C 158.898438 185.628906 191.34375 135.371094 213.453125 137.265625 C 225.480469 138.085938 219.414062 153.085938 211.65625 145.515625 Z M 211.65625 145.515625 "
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(49.019608%,42.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 450.538562 246.339002 C 450.538562 246.339002 456.410718 247.921169 456.410718 250.403859 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(49.019608%,42.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 456.410718 245.88874 C 456.410718 245.88874 460.475576 247.696039 461.379225 249.278206 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(49.019608%,42.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 488.479318 246.570386 C 488.479318 246.570386 483.285681 247.248904 482.156901 248.827945 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(49.019608%,42.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 480.56848 247.020647 C 480.56848 247.020647 477.635529 249.503337 477.860659 250.181855 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(49.019608%,42.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 479.899342 257.86131 C 479.44908 257.633052 477.416652 254.925232 477.866913 251.535766 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(49.019608%,42.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 460.019061 256.060265 C 460.019061 256.060265 461.376098 254.478097 461.376098 251.542019 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(49.019608%,42.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 469.50894 250.410113 L 469.737198 256.95766 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(49.019608%,42.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 475.381096 256.060265 C 475.381096 255.606877 477.188394 251.316889 475.152839 249.28446 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(49.019608%,42.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 462.961393 248.380811 C 462.961393 248.380811 461.379225 252.670799 462.508005 256.285396 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(49.019608%,42.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 474.480574 252.220538 C 474.480574 252.220538 471.994757 252.89593 470.865977 254.027836 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(49.019608%,42.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 463.858788 252.889676 C 463.858788 252.664545 466.569735 252.214284 467.473385 253.793325 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(78.039216%,43.137255%,18.039216%);fill-opacity:1;"
      d="M 225.472656 226.394531 C 225.617188 226.46875 228.28125 225.890625 228.28125 224.59375 C 229.652344 223.730469 228.640625 221.277344 228.640625 221.277344 C 227.800781 221.109375 226.960938 220.941406 226.121094 220.773438 L 222.660156 216.882812 C 222.492188 215.683594 222.902344 214.554688 222.15625 213.28125 C 219.78125 213.90625 218.410156 215.898438 217.617188 218.179688 C 218.195312 218.902344 218.265625 219.695312 219.347656 220.34375 C 220.476562 220.535156 221.246094 219.863281 222.300781 220.054688 C 222.851562 221.015625 222.683594 221.832031 222.878906 222.71875 C 224.320312 223.730469 224.605469 225.171875 225.472656 226.394531 Z M 225.472656 226.394531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(78.039216%,43.137255%,18.039216%);fill-opacity:1;"
      d="M 222.375 209.753906 L 222.375 205.144531 C 221.269531 205.09375 220.164062 205.046875 219.058594 205 C 218.652344 205.695312 217.882812 206.03125 217.402344 206.65625 C 216.632812 207.039062 215.863281 207.425781 215.097656 207.808594 C 216.058594 208.960938 217.234375 209.609375 217.980469 210.1875 C 219.660156 210.691406 221.054688 210.691406 222.375 209.753906 Z M 222.375 209.753906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(78.039216%,43.137255%,18.039216%);fill-opacity:1;"
      d="M 211.992188 216.601562 C 211.390625 215.589844 210.792969 214.582031 210.191406 213.574219 C 211.296875 213.214844 212.6875 213.355469 213.722656 213.789062 C 213.722656 213.789062 214.515625 215.664062 213.9375 216.382812 C 213.648438 217.03125 211.917969 216.742188 211.992188 216.601562 Z M 211.992188 216.601562 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(78.039216%,43.137255%,18.039216%);fill-opacity:1;"
      d="M 239.375 219.699219 C 240.335938 219.21875 241.226562 218.378906 241.394531 217.175781 C 240.289062 215.90625 239.183594 214.632812 238.078125 213.359375 C 237.070312 213.335938 236.0625 213.3125 235.054688 213.289062 C 234.359375 212.566406 233.230469 212.421875 232.316406 212.421875 C 232.316406 212.421875 233.253906 213.863281 234.476562 214.152344 C 235.34375 216.09375 239.089844 219.699219 239.375 219.699219 Z M 239.375 219.699219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(78.039216%,43.137255%,18.039216%);fill-opacity:1;"
      d="M 242.625 220.125 C 242.625 220.050781 245.578125 219.042969 247.164062 219.1875 C 247.019531 217.960938 249.398438 215.152344 249.398438 215.152344 L 253.574219 220.628906 C 252.808594 221.277344 251.460938 221.058594 250.40625 221.277344 C 250.40625 221.277344 248.386719 223.222656 248.171875 223.292969 C 247.953125 223.367188 244.28125 224.230469 242.480469 223.148438 C 241.6875 221.636719 242.695312 219.90625 242.625 220.125 Z M 242.625 220.125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(78.039216%,43.137255%,18.039216%);fill-opacity:1;"
      d="M 244.996094 210.542969 C 245.503906 208.789062 245.644531 207.035156 244.996094 204.847656 C 244.996094 204.847656 240.242188 204.777344 240.242188 204.847656 C 240.242188 204.921875 237.074219 206.289062 237.074219 206.363281 C 237.074219 206.433594 238.082031 209.460938 239.304688 209.171875 C 239.859375 210.78125 241.492188 210.371094 242.257812 210.972656 C 243.171875 210.828125 244.085938 210.683594 244.996094 210.542969 Z M 244.996094 210.542969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(78.039216%,43.137255%,18.039216%);fill-opacity:1;"
      d="M 263.664062 205.71875 C 263.015625 207.714844 262.652344 209.851562 263.589844 211.773438 C 264.453125 211.914062 265.464844 212.492188 266.253906 212.347656 C 267.527344 210.042969 268.800781 207.738281 270.074219 205.429688 C 267.433594 204.589844 265.296875 204.6875 263.664062 205.71875 Z M 263.664062 205.71875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(72.54902%,46.666667%,0%);fill-opacity:1;"
      d="M 264.835938 144.890625 C 255.828125 141.980469 266.941406 136.15625 268.785156 144.890625 C 271.703125 145.363281 272.078125 147.523438 278.378906 146.585938 C 293.613281 171.414062 282.328125 211.472656 237.753906 252.949219 C 317.59375 185.003906 285.148438 134.746094 263.039062 136.640625 C 251.011719 137.460938 257.078125 152.460938 264.835938 144.890625 Z M 264.835938 144.890625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(78.039216%,43.137255%,18.039216%);fill-opacity:1;"
      d="M 265.75 213.503906 C 265.605469 213.503906 263.445312 215.085938 263.445312 215.085938 C 262.410156 215.496094 261.378906 215.902344 260.34375 216.3125 C 259.242188 216.335938 258.136719 216.359375 257.03125 216.382812 C 256.792969 215.613281 256.550781 214.847656 256.308594 214.078125 L 258.832031 211.84375 C 256.695312 211.53125 254.558594 212.371094 252.851562 213.933594 C 252.851562 213.933594 252.851562 216.457031 254.292969 217.679688 C 255.160156 218.761719 257.679688 220.707031 257.679688 220.707031 C 259.265625 221.019531 260.777344 220.753906 261.785156 219.914062 C 263.105469 217.777344 264.429688 215.640625 265.75 213.503906 Z M 265.75 213.503906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(78.039216%,43.137255%,18.039216%);fill-opacity:1;"
      d="M 248.308594 236.125 C 249.390625 236.414062 256.8125 226.542969 256.8125 226.542969 C 256.164062 224.695312 254.722656 223.351562 253.136719 222.507812 C 253.136719 222.507812 249.53125 226.757812 249.460938 228.199219 C 248.886719 229.496094 247.230469 234.46875 247.949219 235.117188 C 247.875 235.261719 247.589844 237.277344 248.308594 236.125 Z M 248.308594 236.125 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.784314%,0%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,25.490196%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 439.019381 330.600373 C 430.123597 322.86151 418.122886 318.596536 401.369419 324.387395 C 405.878284 326.3354 410.74986 327.004538 414.892888 329.137024 C 422.935052 329.624807 430.977217 330.11259 439.019381 330.600373 Z M 439.019381 330.600373 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.3333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,64.313725%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 413.251311 325.109689 C 430.795862 325.475526 435.914455 329.862445 435.001426 329.130771 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.784314%,0%);fill-opacity:1;stroke-width:1.3333;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,25.490196%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 439.378965 331.510276 C 434.141552 332.545251 426.890471 337.607562 424.757985 337.357417 C 419.029662 336.691406 413.679684 334.37131 408.126463 332.604661 C 405.818875 331.872986 403.495652 332.604661 401.178684 332.604661 C 420.858848 323.28363 427.55961 324.562496 439.378965 331.510276 Z M 439.378965 331.510276 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.784314%,0%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,25.490196%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 426.408942 317.799199 C 426.408942 317.799199 419.830126 318.712228 416.906556 318.89671 C 413.979858 318.715355 409.730519 315.988773 405.024664 311.035901 C 402.570116 308.346841 396.9825 308.659523 396.9825 308.659523 C 409.352174 306.04238 418.976506 308.537577 426.408942 317.799199 Z M 426.408942 317.799199 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.784314%,0%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,25.490196%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 417.450621 305.010531 C 408.492301 304.460212 397.34521 296.421174 394.787477 286.915661 C 394.78435 287.037607 398.073758 288.985612 397.526565 289.476521 C 412.210082 293.191176 413.185648 296.177283 417.450621 305.010531 Z M 417.450621 305.010531 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.784314%,0%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,25.490196%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 432.440566 320.360059 C 433.719432 312.258485 434.085269 306.896 430.245542 302.083834 C 427.07808 298.425462 426.286996 296.233565 424.032564 291.114972 C 423.363426 301.777406 421.046457 310.610654 432.440566 320.360059 Z M 432.440566 320.360059 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.784314%,0%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,25.490196%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 418.360524 297.881396 C 424.998749 288.194528 426.158797 280.880911 425.120695 269.367983 C 424.93934 270.281013 422.747444 275.946799 422.562962 275.946799 C 412.694738 282.159778 417.081657 291.849773 418.360524 297.881396 Z M 418.360524 297.881396 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.784314%,0%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,25.490196%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 412.519636 251.088631 C 419.160988 260.7755 418.126013 269.915176 417.087911 281.43123 C 416.906556 280.518201 414.711533 274.852414 414.530178 274.852414 C 404.661954 268.636309 411.24077 257.120255 412.519636 251.088631 Z M 412.519636 251.088631 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.784314%,0%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,25.490196%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 418.000941 266.078576 C 434.085269 257.977002 427.869164 247.680405 430.792735 239.760186 C 419.645644 248.046242 418.182296 256.87949 418.000941 266.078576 Z M 418.000941 266.078576 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.3333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,64.313725%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 420.18971 264.071161 C 422.200251 258.405375 426.221333 248.718507 426.587171 248.718507 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.3333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,64.313725%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 415.44008 275.949926 C 415.074243 271.928844 413.063702 261.144464 412.882347 260.046952 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.784314%,0%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,25.490196%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 416.168628 294.038542 C 407.213434 293.49135 397.526565 281.246748 394.968832 271.741235 C 394.965705 271.863181 398.25824 273.814312 397.707921 274.302095 C 410.565378 280.027291 411.903654 285.205294 416.168628 294.038542 Z M 416.168628 294.038542 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.784314%,0%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,25.490196%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 413.510836 285.630541 C 406.869484 275.943672 405.712563 268.633182 406.747539 257.117128 C 406.932021 258.030157 409.123917 263.695944 409.305272 263.695944 C 419.176622 269.912049 414.789703 279.598917 413.510836 285.630541 Z M 413.510836 285.630541 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.784314%,0%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,25.490196%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 419.461162 268.639436 C 436.092684 261.269536 430.97409 253.530673 435.908202 245.429099 C 424.761111 253.715155 419.642518 259.44035 419.461162 268.639436 Z M 419.461162 268.639436 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.3333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,64.313725%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 420.18971 266.988478 C 426.953008 260.228307 429.329386 254.562521 429.329386 254.562521 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,88.235294%,0%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(91.372549%,74.901961%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 479.36153 359.989294 C 482.05997 360.045576 484.75841 360.098732 487.459977 360.155015 C 460.572507 334.974785 409.055127 324.012177 416.21553 277.6728 C 408.833123 327.923821 458.311821 332.551505 479.36153 359.989294 Z M 479.36153 359.989294 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(61.568627%,28.627451%,8.627451%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(40.784314%,18.823529%,5.490196%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 420.439855 267.720153 C 420.439855 269.14598 419.286061 270.299774 417.860234 270.299774 C 416.434407 270.299774 415.280613 269.14598 415.280613 267.720153 C 415.280613 266.294326 416.434407 265.140532 417.860234 265.140532 C 419.286061 265.140532 420.439855 266.294326 420.439855 267.720153 Z M 420.439855 267.720153 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(61.568627%,28.627451%,8.627451%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(40.784314%,18.823529%,5.490196%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 418.860814 281.909633 C 418.860814 283.257289 417.769556 284.34542 416.4219 284.34542 C 415.07737 284.34542 413.986112 283.257289 413.986112 281.909633 C 413.986112 280.565103 415.07737 279.473845 416.4219 279.473845 C 417.769556 279.473845 418.860814 280.565103 418.860814 281.909633 Z M 418.860814 281.909633 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(61.568627%,28.627451%,8.627451%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(40.784314%,18.823529%,5.490196%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 421.149642 302.130736 C 421.149642 303.318925 420.186583 304.278857 418.998394 304.278857 C 417.813332 304.278857 416.850273 303.318925 416.850273 302.130736 C 416.850273 300.942547 417.813332 299.979488 418.998394 299.979488 C 420.186583 299.979488 421.149642 300.942547 421.149642 302.130736 Z M 421.149642 302.130736 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(61.568627%,28.627451%,8.627451%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(40.784314%,18.823529%,5.490196%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 440.069517 324.49058 C 440.069517 325.678769 439.106459 326.6387 437.918269 326.6387 C 436.733207 326.6387 435.770149 325.678769 435.770149 324.49058 C 435.770149 323.30239 436.733207 322.339332 437.918269 322.339332 C 439.106459 322.339332 440.069517 323.30239 440.069517 324.49058 Z M 440.069517 324.49058 "
      transform="matrix(1.249275,0,0,1.249275,-348.861596,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(61.568627%,28.627451%,8.627451%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(40.784314%,18.823529%,5.490196%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.649612 331.229794 C 442.649612 332.417983 441.686553 333.381042 440.498364 333.381042 C 439.313302 333.381042 438.350243 332.417983 438.350243 331.229794 C 438.350243 330.041605 439.313302 329.078546 440.498364 329.078546 C 441.686553 329.078546 442.649612 330.041605 442.649612 331.229794 Z M 442.649612 331.229794 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-175.367414)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 452.13011 349.589512 C 450.716791 348.307518 449.816268 347.488293 449.262822 346.090608 C 446.451817 345.78418 443.640812 345.480879 440.829806 345.174451 C 440.792285 346.778507 440.754763 348.385689 440.717241 349.989744 C 444.519446 349.858418 448.324778 349.723965 452.13011 349.589512 Z M 452.13011 349.589512 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 390.359909 343.911219 C 395.256499 344.814868 402.642032 343.457831 405.052806 346.622166 C 407.948235 349.902193 395.9913 354.94887 397.701667 357.788017 C 401.400687 361.699661 405.206019 360.089352 409.345921 357.92247 C 410.321486 355.808744 411.075049 352.037807 411.606607 350.918408 C 410.099483 347.450771 406.053386 345.752912 407.085234 340.518626 C 413.914195 337.967146 427.037432 338.179769 428.447625 339.161589 C 429.557643 341.337851 428.56019 342.329051 428.788447 344.061306 C 427.656541 346.246948 424.748604 349.952222 424.742351 351.909608 C 431.902754 354.432946 433.791349 351.521883 440.235712 351.693857 C 447.78384 351.784535 452.377129 353.813837 454.015579 350.812096 C 452.893053 348.223094 445.970288 350.32744 443.340638 348.663975 C 442.027376 348.219968 441.176883 347.163105 440.041849 345.99993 C 438.906816 344.833629 435.714339 344.774219 435.235937 341.906931 C 436.571086 335.77525 445.419969 336.713294 446.867683 335.772123 C 454.553391 336.300554 462.239099 336.828986 469.92168 337.35429 C 474.099102 337.235472 476.462974 344.752331 470.822202 347.016144 C 465.18143 349.276831 448.49988 343.661073 441.264433 347.519561 C 440.892343 345.946774 435.692452 343.564142 435.317234 343.489099 C 433.10345 344.151983 428.78532 343.911219 428.78532 343.911219 C 427.728458 345.868604 426.524634 347.303811 425.470898 349.261196 C 420.683747 347.163105 416.156121 350.893393 410.928088 349.786501 C 410.928088 349.786501 403.01725 350.69015 402.792119 350.69015 C 402.563862 350.69015 397.592228 350.239889 397.592228 350.239889 C 394.956325 350.993451 392.320421 351.747013 389.68139 352.497449 C 391.563732 350.840237 393.449201 349.183026 395.331542 347.525815 C 393.674331 346.321992 392.01712 345.115042 390.359909 343.911219 Z M 390.359909 343.911219 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 389.611332 342.709359 C 389.611332 343.913183 388.635767 344.888749 387.431943 344.888749 C 386.22812 344.888749 385.252554 343.913183 385.252554 342.709359 C 385.252554 341.505536 386.22812 340.52997 387.431943 340.52997 C 388.635767 340.52997 389.611332 341.505536 389.611332 342.709359 Z M 389.611332 342.709359 "
      transform="matrix(1.249275,0,0,1.249275,-348.180916,-174.673391)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 389.440626 353.720033 C 389.440626 354.923856 388.46506 355.899422 387.261237 355.899422 C 386.057413 355.899422 385.081847 354.923856 385.081847 353.720033 C 385.081847 352.516209 386.057413 351.540644 387.261237 351.540644 C 388.46506 351.540644 389.440626 352.516209 389.440626 353.720033 Z M 389.440626 353.720033 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 452.131553 349.591098 C 450.715106 348.309105 449.81771 347.486753 449.261138 346.092194 C 446.453259 345.785766 443.642254 345.479339 440.831248 345.172911 C 440.793727 346.780093 440.756205 348.384148 440.715556 349.991331 C 444.520888 349.856878 448.32622 349.725551 452.131553 349.591098 Z M 452.131553 349.591098 "
      transform="matrix(-1.249275,0,0,1.249275,824.524145,-174.911201)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 390.361351 343.909678 C 395.257941 344.813327 402.640348 343.459417 405.051121 346.620626 C 407.94655 349.90378 395.989615 354.950457 397.703109 357.786477 C 401.402129 361.701247 405.207462 360.087812 409.347363 357.924057 C 410.319802 355.810331 411.073364 352.039394 411.608049 350.916867 C 410.100925 347.452358 406.051701 345.754498 407.086676 340.520212 C 413.91251 337.968733 427.035747 338.178229 428.449067 339.163175 C 429.559085 341.339438 428.561632 342.327511 428.786763 344.062892 C 427.657983 346.248535 424.746919 349.950682 424.740666 351.911194 C 431.904196 354.434533 433.792791 351.523469 440.234027 351.692317 C 447.785282 351.786122 452.375444 353.812297 454.017021 350.810556 C 452.894495 348.221554 445.968603 350.329026 443.34208 348.665562 C 442.025691 348.221554 441.178325 347.164691 440.043291 345.99839 C 438.905131 344.835215 435.712655 344.775806 435.234252 341.908518 C 436.572528 335.773709 445.421411 336.711753 446.865998 335.773709 C 454.551706 336.299014 462.237414 336.827446 469.923122 337.355877 C 474.100545 337.237058 476.461289 344.753918 470.820517 347.014604 C 465.182872 349.27529 448.501322 343.659533 441.265875 347.521148 C 440.890658 345.945234 435.690767 343.565729 435.315549 343.490685 C 433.101765 344.15357 428.783636 343.909678 428.783636 343.909678 C 427.7299 345.87019 426.526076 347.302271 425.47234 349.262783 C 420.682062 347.161564 416.157563 350.891853 410.92953 349.788088 C 410.92953 349.788088 403.015565 350.691737 402.790435 350.691737 C 402.565304 350.691737 397.593671 350.238349 397.593671 350.238349 C 394.95464 350.991911 392.318736 351.745473 389.682832 352.499035 C 391.565174 350.841824 393.447516 349.184613 395.332984 347.527402 C 393.675773 346.320452 392.018562 345.113501 390.361351 343.909678 Z M 390.361351 343.909678 "
      transform="matrix(-1.249275,0,0,1.249275,824.524145,-174.911201)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 389.609648 342.710946 C 389.609648 343.914769 388.634082 344.890335 387.430259 344.890335 C 386.226435 344.890335 385.25087 343.914769 385.25087 342.710946 C 385.25087 341.507123 386.226435 340.531557 387.430259 340.531557 C 388.634082 340.531557 389.609648 341.507123 389.609648 342.710946 Z M 389.609648 342.710946 "
      transform="matrix(-1.249275,0,0,1.249275,824.022561,-174.624592)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.66667;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 389.438941 353.718492 C 389.438941 354.922316 388.463375 355.897881 387.259552 355.897881 C 386.055729 355.897881 385.080163 354.922316 385.080163 353.718492 C 385.080163 352.517796 386.055729 351.539103 387.259552 351.539103 C 388.463375 351.539103 389.438941 352.517796 389.438941 353.718492 Z M 389.438941 353.718492 "
      transform="matrix(-1.249275,0,0,1.249275,824.524145,-174.911201)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 237.738281 247.007812 C 235.316406 246.554688 234.453125 246.683594 232.8125 246.519531 C 231.527344 250.472656 230.242188 254.425781 228.957031 258.378906 C 234.910156 258.914062 240.421875 258.914062 240.421875 258.867188 C 236.8125 258.136719 237.691406 247.054688 237.738281 247.007812 Z M 237.738281 247.007812 "
    />
    <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
      <use xlink:href="#glyph0-1" x="211.75" y="255.697243" />
    </g>
    <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
      <use xlink:href="#glyph0-2" x="219.660156" y="255.697243" />
    </g>
    <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
      <use xlink:href="#glyph0-3" x="218.039062" y="256.130836" />
    </g>
    <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
      <use xlink:href="#glyph0-2" x="223.300781" y="256.130836" />
    </g>
    <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
      <use xlink:href="#glyph0-4" x="221.832031" y="256.669899" />
    </g>
    <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
      <use xlink:href="#glyph0-2" x="231.34375" y="256.669899" />
    </g>
    <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
      <use xlink:href="#glyph0-5" x="229.207031" y="257.212868" />
    </g>
    <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
      <use xlink:href="#glyph0-2" x="237.78125" y="257.212868" />
    </g>
    <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
      <use xlink:href="#glyph0-6" x="236.363281" y="257.322243" />
    </g>
    <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
      <use xlink:href="#glyph0-2" x="245.714844" y="257.322243" />
    </g>
    <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
      <use xlink:href="#glyph0-7" x="243.414062" y="257.107399" />
    </g>
    <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
      <use xlink:href="#glyph0-2" x="251.785156" y="257.107399" />
    </g>
    <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
      <use xlink:href="#glyph0-8" x="250.027344" y="256.021461" />
    </g>
    <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
      <use xlink:href="#glyph0-2" x="258.757812" y="256.021461" />
    </g>
    <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
      <use xlink:href="#glyph0-9" x="257.835937" y="255.697243" />
    </g>
    <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
      <use xlink:href="#glyph0-2" x="265.960938" y="255.697243" />
    </g>
    <path
      style="fill:none;stroke-width:1.3333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,64.313725%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 418.000941 331.328921 C 424.032564 332.060595 432.440566 331.513403 436.095811 331.328921 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.3333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,64.313725%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 408.861265 310.670064 C 413.976732 314.691146 425.126949 317.067524 424.942467 317.067524 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.3333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,64.313725%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 431.339927 318.171289 C 429.695223 309.763288 427.869164 307.752747 426.405815 302.449671 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.3333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,64.313725%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 400.268781 292.941031 C 408.861265 296.777631 410.140131 299.335365 415.624562 303.175092 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.3333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,64.313725%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 418.729488 294.770217 C 419.461162 284.351674 420.924511 281.609458 423.482245 279.23308 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.3333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,64.313725%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 400.268781 277.960467 C 403.376834 281.434357 413.614021 292.21561 413.614021 292.21561 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
    <path
      style="fill:none;stroke-width:1.3333;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,64.313725%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 409.408457 268.4487 C 413.248184 271.190916 413.248184 281.059139 413.248184 281.059139 "
      transform="matrix(1.249275,0,0,1.249275,-348.6825,-174.96)"
    />
  </g>
</svg>
