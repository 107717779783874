<script>
  import styles from "styleguide/ResponderQrcodeMissing.json";
  import { createEventDispatcher } from "svelte";

  import { record, addedFiles } from "stores/responder.js";
  import { t } from "stores/i18n.js";

  import SampleResponseSubmissions from "apis/responder/general_method.js";

  import { ResponderQrcodeExample } from "apps/responder";
  import { LegacyButton as Button, TextInput } from "components";

  const dispatch = createEventDispatcher();

  let pincode;
  let recordValue;
  let wrongPincode = false;

  record.subscribe((value) => {
    recordValue = value;
  });

  function onRestart() {
    addedFiles.set([]);
    dispatch("restart");
  }

  function onSubmit() {
    wrongPincode = false;

    let formData = new FormData();

    formData.append("pin", pincode.toUpperCase().trim().replaceAll(" ", ""));
    formData.append("id", recordValue);

    SampleResponseSubmissions.pinSubmit({
      body: formData,
      success: onSuccess,
      error: onError,
    });
  }

  function onSuccess(response) {
    if (response.code === "success") {
      dispatch("success");
    }
  }

  function onError() {
    wrongPincode = true;
  }
</script>

<div data-component="ResponderQrcodeMissing" class={styles.container}>
  <div class={styles.block}>
    <div>
      <p class={styles.title}>
        <span class={styles.error}
          >{$t("responder_qrcode_missing.qr_code_missing")}</span
        >
        {$t("responder_qrcode_missing.upload_cover_page")}
      </p>
      <div>
        <Button style="primary" click={onRestart}
          >{$t("responder_qrcode_missing.upload_new")}</Button
        >
      </div>
      <p class={styles.alternativelyText}>
        <b>{$t("responder_qrcode_missing.alternatively")}:</b>
        {$t("responder_qrcode_missing.enter_pin_appeal")}
      </p>
      <form
        class={styles.alternativelyForm}
        on:submit|preventDefault={onSubmit}
      >
        <div class={styles.pincodeInput}>
          <TextInput
            bind:value={pincode}
            errors={wrongPincode
              ? [$t("responder_qrcode_missing.wrong_pin")]
              : []}
            style="border medium round-border fullsize"
            placeholder={$t("responder_qrcode_missing.enter_pin_placeholder")}
          />
        </div>
        <Button style="success" disabled={!pincode}
          >{$t("responder_qrcode_missing.submit")}</Button
        >
      </form>
    </div>
    <div class={styles.qrcode}>
      <ResponderQrcodeExample />
    </div>
  </div>
</div>

<style lang="scss">
  .block {
    display: flex;
    justify-content: space-between;
    min-height: 205px;
  }

  .container {
    padding: 35px;
    border-bottom: solid 1px var(--primary-050);
  }

  .title {
    margin: 0 0 25px;
    font-size: 22px;
    line-height: 1.45;
    color: var(--primary-500);
  }

  .error {
    font-weight: 600;
    color: var(--red-200);
  }

  .qrcode {
    margin-left: 30px;
  }

  .alternativelyText {
    margin: 40px 0 10px;
    font-size: 14px;
    line-height: 1.57;
    color: var(--primary-500);
  }

  .alternativelyForm {
    display: flex;
  }

  .pincodeInput {
    flex: 1;
    margin-right: 10px;
  }
</style>
