<script>
  import styles from "styleguide/HelpVideo.json";
  import { onMount } from "svelte";
  import Player from "@vimeo/player";

  import { t } from "stores/i18n.js";

  import { Modal } from "components/modals";

  export let id;
  export let shown;
  export let onHide;
  export let navigation = [];
  export let title;

  let helpPlayer;

  function init(node) {
    helpPlayer = new Player(node);

    return {
      destroy() {},
    };
  }

  onMount(async () => {
    title = $t("help_video.default_title");
  });

  function seek(time) {
    helpPlayer.setCurrentTime(time);
  }
</script>

<Modal isWide {shown} {onHide}>
  <div data-component="HelpVideo" class={styles.wrapper}>
    <iframe
      src={`https://player.vimeo.com/video/${id}`}
      width="720"
      height="400"
      frameborder="0"
      webkitallowfullscreen=""
      mozallowfullscreen=""
      allowfullscreen=""
      data-ready="true"
      {title}
      use:init
    />
    <div class={styles.navigation}>
      <div class={styles.title}>{$t("help_video.navigation")}</div>
      {#each navigation as { name, time }}
        <div class={styles.item} on:click={seek.bind(this, time)}>{name}</div>
      {/each}
    </div>
  </div>
</Modal>

<style lang="scss">
  .wrapper {
    display: flex;
  }

  .navigation {
    padding: 20px 25px;
  }

  .title {
    font-weight: 500;
    margin-bottom: 20px;
  }

  .item {
    padding: 10px 0 10px 0;
    border-top: 1px solid var(--primary-050);
    color: var(--blue-200);
    cursor: pointer;
  }
</style>
