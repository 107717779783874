import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal", "previewImage"];

  connect() {
    this.sidebar = $(this.element)
      .find(".sample-document-modal--sidebar")
      .find("ul");
    this.content = $(this.element).find(".sample-document-modal--content");

    this.setupModal();
  }

  setupModal() {
    this.previewImageTargets.forEach(
      function (element, index) {
        $(this.content).append($(element).clone());
        $(this.sidebar).append(`
        <li>
          <a href="#" class="btn medium"
             data-action="sample-document#showPage"
             data-index="${index}">
            ${index + 1}
            <span data-index="${index}">Page / Seite</span>
          </a>
        </li>
      `);
      }.bind(this),
    );
  }

  outsideCheck(event) {
    if ($(event.target).hasClass("sample-document-modal")) {
      this.hidePreview(event);
    }
  }

  hidePreview(event) {
    event.preventDefault();
    $(this.modalTarget).removeClass("show");
    $("html,body").removeClass("no-scroll");
  }

  rotatePreview(event) {
    event.preventDefault();

    let imgNode = $(this.content).find("img"),
      rotateClass = imgNode
        .attr("class")
        .split(/\s+/)
        .find((el) => {
          return el.includes("rotated");
        }),
      rotateAngle = rotateClass ? parseInt(rotateClass.match(/\d+/)) : 0,
      newRotateClass = "";

    if (rotateAngle !== 270) {
      newRotateClass = `rotated-${rotateAngle + 90}`;
    }
    imgNode.removeClass(rotateClass);
    imgNode.addClass(newRotateClass);
  }

  showPage(event) {
    event.preventDefault();
    let index = $(event.target).data("index");
    $(this.content).find("img").get(index).scrollIntoView();
  }
}
