<script>
  export let width = 28;
  export let height = 28;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 28 28"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <mask
    id="mask0_1361_216"
    style="mask-type:luminance"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="28"
    height="28"
  >
    <path
      d="M2.01246e-05 0.964968L0.00318146 27.9995L27.0377 27.9963L27.0345 0.961806L2.01246e-05 0.964968Z"
      fill="white"
    />
  </mask>
  <g mask="url(#mask0_1361_216)">
    <path
      d="M5.34575 12.4783C5.54046 12.2663 5.76726 12.0629 6.04618 11.9827C7.0525 11.6933 7.98908 12.695 7.50807 13.6642C7.43516 13.8108 7.26412 14.0172 6.99495 14.2834C6.27335 14.9967 5.5527 15.7113 4.83301 16.4269C4.38917 16.868 3.89719 17.1046 3.29805 16.8137C3.1518 16.7428 2.96582 16.5931 2.74013 16.3648C1.99964 15.616 1.25878 14.8685 0.517534 14.1221C0.31437 13.9171 0.180705 13.7448 0.116539 13.6055C-0.429446 12.4119 1.09112 11.3309 2.07122 12.23C2.24994 12.3938 2.42084 12.5651 2.58391 12.7438C2.62629 12.7899 2.65264 12.7821 2.66294 12.7202C2.88426 11.3716 3.33945 10.1076 4.02854 8.92839C6.11299 5.36039 10.019 3.30772 14.1417 3.5077C17.2106 3.65569 20.0076 5.13252 21.9668 7.45656C22.147 7.67038 22.2628 7.85785 22.314 8.01898C22.719 9.30078 21.0558 10.1934 20.1937 9.14128C16.8276 5.03323 10.5456 4.8146 7.04586 8.89481C6.1372 9.95449 5.51818 11.1627 5.18881 12.5195C5.16248 12.6283 5.18825 12.6426 5.26614 12.5625C5.29211 12.5357 5.31864 12.5076 5.34575 12.4783Z"
    />
    <path
      d="M21.7485 16.445C21.6668 16.5229 21.5849 16.6002 21.5028 16.677C20.2514 17.845 18.6298 16.1069 19.7609 14.9778C20.5664 14.1743 21.3726 13.3714 22.1793 12.569C22.622 12.1291 23.1169 11.873 23.7154 12.1594C23.8766 12.2364 24.1151 12.4348 24.4309 12.7543C25.1259 13.4576 25.8219 14.1607 26.5189 14.8636C26.7121 15.0583 26.8412 15.2252 26.9061 15.3641C27.4825 16.6012 25.8668 17.6868 24.8844 16.6932C24.7374 16.5446 24.5924 16.3948 24.4496 16.2436C24.408 16.199 24.3818 16.2068 24.3712 16.2671C23.9778 18.5166 23.0083 20.4554 21.4628 22.0833C16.895 26.8951 9.16603 26.5214 4.96822 21.4053C4.05968 20.2989 5.38145 18.809 6.54139 19.5769C6.64449 19.6449 6.81042 19.8112 7.03917 20.0757C10.3892 23.9473 16.3258 24.0204 19.7941 20.2948C20.799 19.2152 21.4758 17.9464 21.8247 16.4885C21.8487 16.3876 21.8233 16.3732 21.7485 16.445Z"
    />
  </g>
</svg>
