<script>
  import styles from "styleguide/ConfirmationRollForwardGroup.json";
  import { Boundary, Checkbox } from "components";

  export let name;
  export let checked;
  export let permalink;
  export let updateList;
</script>

<Boundary>
  <div data-component="ConfirmationRollForwardGroup" class={styles.wrapper}>
    <div class={styles["template-group"]}>
      {name}
    </div>

    <div class={styles.checkboxes}>
      <div class={styles.checkbox}>
        <Checkbox
          toggle={(value) => updateList(value, permalink)}
          toggled={checked}
        />
      </div>
    </div>
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    margin: 2px 0;

    &.active {
      background-color: var(--blue-010);
    }
  }

  .icon {
    margin-right: 10px;

    &.open {
      transform: rotate(90deg);
    }
  }

  .template-group {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .template-control {
    margin: 0 0 0 40px;
  }

  .checkboxes {
    display: flex;
  }

  .checkbox {
    margin-left: 15px;
  }
</style>
