<script>
  import { onMount } from "svelte";
  import clsx from "clsx";
  import { toasts } from "stores/toasts.js";
  import { iconPath } from "lib/helpers.js";
  import { Button } from "components";
  import styles from "styleguide/Toast.json";

  let progressBarDuration = 0;

  export let id;
  export let type = "info";
  export let close;
  export let title;
  export let action;
  export let message;
  export let description;
  export let duration;
  export let inline = true;
  export let isActionShown = true;
  export let margin = true;
  export let hideIcon = false;
  export let isSandboxBtnDelete = false;

  onMount(() => {
    progressBarDuration = Math.ceil(duration / 1000);
  });
</script>

<div
  data-component="Toast"
  class={`${styles.toast} ${styles[type]} ${inline ? styles.inline : ""} ${margin ? "" : styles["no-margin-bottom"]}`}
>
  <div class={styles.frame}>
    <div class={styles["content-frame"]}>
      <div class={styles.content}>
        {#if !hideIcon}
          <div
            class={clsx(
              styles.icon,
              isSandboxBtnDelete && styles["icon-sandbox"],
            )}
          >
            <img src={iconPath(`${type}_filled`)} alt={type} />
          </div>
        {/if}
        <div
          class={clsx(
            styles.inner,
            isSandboxBtnDelete && styles["inner-sandbox"],
          )}
        >
          {#if title}
            <div class={styles.title}>{title}</div>
          {/if}
          {#if message}
            <div class={styles.message}>{@html message}</div>
          {/if}
          {#if description}
            <div class={styles.description}>{@html description}</div>
          {/if}
          {#if action && isActionShown}
            <div class={styles.action}>
              <Button
                style={action.style}
                icon={action.icon}
                click={action.click}>{action.button}</Button
              >
            </div>
          {/if}
          {#if action && isSandboxBtnDelete}
            <Button style={action.style} icon={action.icon} click={action.click}
              >{action.button}</Button
            >
          {/if}
          {#if $$slots.default}
            <div class={styles.slot}>
              <slot />
            </div>
          {/if}
        </div>
      </div>
      {#if close}
        <div class={styles.close}>
          <button on:click={() => toasts.close(id)}>
            <img src={iconPath("grey_close")} alt="" />
          </button>
        </div>
      {/if}
    </div>
    <span class={styles["closing-progress-bar"]}>
      <span
        class={styles.bar}
        style={`animation-duration: ${progressBarDuration}s;`}
      />
    </span>
  </div>
</div>

<style lang="scss">
  .toast {
    margin-bottom: 16px;
    border-radius: 8px;
    background: #fff;
    position: relative;
    width: 100%;

    &.inline {
      border: 1px solid var(--primary-050);
      border-top: 0;
      border-radius: 6px 6px 8px 8px;
    }

    &:not(.inline) {
      box-shadow: -2px 3px 25px rgba(5, 40, 97, 0.1);
    }
  }

  .frame {
    .inline & {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .content-frame {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .content {
    display: flex;
    flex-direction: row;
    padding: 16px;
    width: 100%;

    .toast:not(.inline) & {
      align-items: center;
    }
  }

  .closing-progress-bar {
    display: block;
    height: 5px;
    position: relative;

    .inline & {
      width: calc(100% + 2px);
      margin-left: -1px;
    }

    .bar {
      display: block;
      border-radius: 0 0 8px;
      position: absolute;
      right: 0%;
      top: 0%;
      bottom: 0%;
      height: inherit;

      .inline & {
        width: 100%;
        border-radius: 8px 8px 0 0;
      }

      .toast:not(.inline) & {
        animation-name: progress;
        animation-timing-function: steps(1000);
        animation-fill-mode: forwards;
      }

      .error & {
        background-color: var(--red-100);
      }
      .warning & {
        background-color: var(--orange-100);
      }
      .info & {
        background-color: var(--blue);
      }
      .success & {
        background-color: var(--green-100);
      }
    }
  }

  @keyframes progress {
    0% {
      width: 100%;
    }
    100% {
      width: 0%;
    }
  }

  .inner {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1;

    &-sandbox {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .title {
    font-weight: 600;
  }

  .message {
    word-break: break-word;
  }
  .description {
    word-break: break-word;
    margin-top: 10px;
  }

  .action {
    margin-top: 16px;
  }

  .close {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 8px 8px 0 0;

    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0;
      border-radius: var(--border-radius);
      cursor: pointer;
      width: 30px;
      height: 30px;
      border: 2px solid transparent;
      background: transparent;

      &:hover {
        background: var(--primary-025);
      }

      &:focus-visible {
        border: 2px solid var(--blue);
      }

      &:active {
        background: var(--primary-050);
      }
    }
  }

  .icon {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    margin-right: 16px;

    &-sandbox {
      align-items: center;
    }

    img {
      width: 32px;
      height: 32px;
    }

    .inline & {
      margin-right: 16px;

      img {
        width: 18px;
        height: 18px;
        margin: 2px 0;
      }
    }
  }

  .no-margin-bottom {
    margin-bottom: 0;
  }
</style>
