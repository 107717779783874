<script>
  import styles from "styleguide/ConfirmationLetterheadModal.json";
  import { getContext } from "svelte";

  import { t } from "stores/i18n.js";
  import {
    confirmationId,
    activeLetterhead,
    letterhead,
  } from "stores/confirmation/confirmation.js";
  import { toasts } from "stores/toasts.js";

  import { downloadFile } from "lib/helpers.js";
  import ConfirmationApi from "apis/confirmation/confirmation";
  import UploadApiConstructor from "apis/upload.js";
  import LetterheadUploadHandler from "apis/confirmation/letterhead_upload_handler";

  import { Button, Dropzone, FileItem, Toast } from "components";
  import {
    ConfirmationLetterheadPreviewModal,
    ConfirmModal,
    Modal,
  } from "components/modals";

  let dropzone;
  let uppyWrapper;
  let loadingState = false;
  let showLetterheadReplace = false;
  let showLetterheadPreview = false;
  let acceptedReplaced = false;
  let letterheadFile;
  let UploadApi = new UploadApiConstructor();

  const { language } = getContext("config");

  $: onCreate($activeLetterhead);

  confirmationId.subscribe((id) => {
    LetterheadUploadHandler.id = id;
    LetterheadUploadHandler.loadingStateFunc = setLetterheadUploadLoadingState;
    UploadApi.apiHandler = LetterheadUploadHandler;
    UploadApi.onBeforeFileAdded = beforeLetterheadUpload;
  });

  t.subscribe((translation) => {
    LetterheadUploadHandler.successMessage = $t(
      "confirmation.letterhead.upload_success",
    );
    LetterheadUploadHandler.errorMessage = $t("errors.check_entries");
  });

  function onAddLetterhead(files) {
    if (files.length === 0) return;
    UploadApi.onAddFiles([files[0]]);
    loadingState = true;
  }

  function onCreate(active) {
    if (active) {
      setTimeout(() => {
        UploadApi.setupUppy(uppyWrapper);
      }, 100);
    }
  }

  function onHide() {
    UploadApi.closeUppy();
    activeLetterhead.set(false);
  }

  function setLetterheadUploadLoadingState(newLoadingState) {
    loadingState = newLoadingState;
  }

  function downloadLetterhead() {
    downloadFile({ url: $letterhead.url, filename: "Letterhead" }, null, true);
  }

  function usePreviousLetterhead() {
    setLetterheadUploadLoadingState(true);
    ConfirmationApi.usePreviousLetterhead({
      params: { id: $confirmationId },
      success: (response) => {
        setLetterheadUploadLoadingState(false);
        console.log(response);
        letterhead.set(response.data.letterhead);
        toasts.send({
          title: $t("confirmation.letterhead.upload_success"),
          type: "success",
        });
      },
      error: () => {
        setLetterheadUploadLoadingState(false);
        toasts.send({ title: $t("errors.unknown"), type: "error" });
      },
    });
  }

  function beforeLetterheadUpload(currentFile) {
    if (!currentFile) {
      loadingState = false;
      return false;
    } else if (letterheadFile == currentFile) {
      letterheadFile = null;
      return true;
    } else if ($letterhead.url) {
      letterheadFile = currentFile;
      showLetterheadReplace = true;
      if (acceptedReplaced) {
        acceptedReplaced = false;
        return true;
      }
      return false;
    } else {
      return true;
    }
  }

  function onLetterheadReplaceConfirm() {
    acceptedReplaced = true;
    UploadApi.uppy.addFile(letterheadFile);
    letterheadFile = null;
  }
</script>

<Modal
  style={$letterhead.url ? "big no-header-border" : "big"}
  shown={$activeLetterhead}
  {onHide}
  title={$t("confirmation_letterhead_modal.title")}
>
  <div data-component="ConfirmationLetterheadModal">
    {#if $letterhead.url}
      <Toast
        message={$t("confirmation_letterhead_modal.uploaded_lettehead")}
        type="success"
      />
    {/if}
    <div class={styles.container}>
      <div class={styles.headline}>
        {$t("confirmation_letterhead_modal.requirements_headline")}
      </div>
      <div class={styles.description}>
        {@html $t("confirmation_letterhead_modal.requirements_description")}
      </div>
      {#if !$letterhead.url && $letterhead.previous_exists}
        <p>{$t("confirmation_letterhead_modal.use_previous_intro")}</p>
        <Button style={"primary-text small"} click={usePreviousLetterhead}>
          {$t("confirmation_letterhead_modal.use_previous")}
        </Button>
      {/if}

      <div class={styles.details}>
        <div class={styles.information}>
          <div class={styles.label}>
            {$t("confirmation_letterhead_modal.file_format")}
          </div>
          <div class={styles.value}>PDF</div>

          <div class={styles.label}>
            {$t("confirmation_letterhead_modal.file_size")}
          </div>
          <div class={styles.value}>
            {$t("confirmation_letterhead_modal.maximum_size")}
          </div>

          <div class={styles.label}>
            {$t("confirmation_letterhead_modal.leave_blank")}
          </div>
          <div class={styles.value}>
            {$t("confirmation_letterhead_modal.leave_blank_value")}
          </div>

          <a href="/assets/Beispiel-Briefkopf.pdf" download>
            <Button style={"primary-text small"}>
              {$t("confirmation_letterhead_modal.download_example")}
            </Button>
          </a>
        </div>
        <div class={styles.example}>
          <img
            src={`/assets/gateway/letterhead-${language}.png`}
            alt="Briefkopf"
          />
        </div>
      </div>
    </div>

    <div class={styles.container}>
      <div class={styles.dropzone}>
        <Dropzone
          uppy={true}
          onFiles={onAddLetterhead}
          bind:this={dropzone}
          bind:uppyWrapper
          bind:loadingState
        />
      </div>

      {#if $letterhead.url}
        <div class={styles["file-list"]}>
          <FileItem name={$letterhead.filename} size={$letterhead.filesize}>
            <div class={styles.items}>
              <Button
                style={"primary-text small"}
                click={() => {
                  showLetterheadPreview = true;
                }}
                disabled={loadingState}
              >
                {$t("confirmation_letterhead_modal.preview")}
              </Button>
              <Button
                style={"primary-text small"}
                click={downloadLetterhead.bind(this)}
                disabled={loadingState}
              >
                {$t("confirmation_letterhead_modal.download")}
              </Button>
            </div>
          </FileItem>
        </div>
      {/if}
    </div>
  </div>
</Modal>

<ConfirmationLetterheadPreviewModal
  bind:shown={showLetterheadPreview}
  title={$t("confirmation_letterhead_modal.letterhead_preview")}
  letterhead={$letterhead}
/>

<ConfirmModal
  bind:shown={showLetterheadReplace}
  onConfirm={onLetterheadReplaceConfirm}
  confirmLabel={$t("confirmation_letterhead_modal.replace")}
  title={$t("confirmation_letterhead_modal.confirm_modal_title")}
  description={$t("confirmation_letterhead_modal.confirm_modal_description")}
/>

<style lang="scss">
  .container {
    padding: 8px 0;
  }

  .headline {
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 16px;
  }

  .dropzone {
    margin-top: 20px;
  }

  .file-list {
    margin-top: 15px;
  }

  .items {
    display: flex;
    gap: 8px;
  }

  .info {
    margin-bottom: 8px;
  }

  .info-title {
    border-top: 4px;
    font-weight: 600;
  }

  .info-description {
    color: var(--primary-500);
    border-bottom: 4px;
    width: 70%;
  }

  .info-button {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
  }

  .details {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 15px;
    gap: 1em;
  }

  .information {
    min-width: 30%;
  }

  .example {
    img {
      width: 100%;
    }
  }

  .label {
    color: var(--primary-300);
  }

  .value {
    margin-bottom: 8px;
  }
</style>
