<script>
  import { afterUpdate } from "svelte";

  import { isAccountFeature } from "stores/account";
  import documentViewerStore from "stores/document_viewer_store.js";
  import { t } from "stores/i18n.js";
  import {
    setFilesToControl,
    getAddedFilesControl,
    activeControl,
  } from "stores/pbc.js";
  import { permissions } from "stores/permission.js";
  import { get } from "svelte/store";

  import AttachmentAnnotationApi from "apis/attachments/attachment_annotation_api.js";
  import AttachmentsApi from "apis/attachments/attachments.js";
  import { iconPath, imagePath, fileIcon } from "lib/helpers.js";

  import { KeyEvent, LegacyButton as Button } from "components";
  import WebViewer from "./components/WebViewer.svelte";
  import styles from "styleguide/DocumentViewer.json";

  const document = documentViewerStore.documentReadable;
  const thumbnails = documentViewerStore.thumbnailsReadable;
  const isAnnotationsEnabled = documentViewerStore.isAnnotationsEnabledReadable;
  const showDownloadButton = documentViewerStore.showDownloadButtonReadable;

  let isDownloadingPdf = false;
  let isLoadingDocument = false;
  let relatedDocuments = null;
  let parentType = "control";
  let thumbnailsSubscribed = false;
  let loaderSubscribed = false;

  // Queque Params
  let currentThumbnailDocument = null;
  let previewContainer;

  let webViewerInstance = null;
  let webViewerReadyResolve = () => {};
  let webViewerReadyReject = () => {};

  let loadingTimeOut;

  document.subscribe((doc) => {
    if (doc.url) {
      loadDocumentToWebViewer();
      getRelatedDocuments();
    }
  });

  $: if (webViewerInstance !== null) {
    afterUpdate(() => {
      const { documentViewer } = webViewerInstance.Core;

      if (!loaderSubscribed) {
        loaderSubscribed = true;
        documentViewer.addEventListener("annotationsLoaded", drawAnnotations);
      }

      if (!thumbnailsSubscribed) {
        thumbnails.subscribe(async () => {
          await pickDocumentForPreviewGeneration();
        });
        thumbnailsSubscribed = true;
      }
    });
  }

  // Preview Actions
  async function pickDocumentForPreviewGeneration() {
    const thumbs = get(thumbnails);
    if (!currentThumbnailDocument && !!thumbs.length) {
      const newThumbnailDocument = thumbs.find(
        (item) => !item.thumbnails.length,
      );
      if (newThumbnailDocument) {
        currentThumbnailDocument = newThumbnailDocument;
        await generatePreviewsForDocument();
      }
    }
  }

  async function generatePreviewsForDocument() {
    if (!webViewerInstance) return;

    const { documentViewer } = webViewerInstance.Core;
    if (currentThumbnailDocument?.url) {
      await documentViewer.loadDocument(currentThumbnailDocument.url);
    }
  }

  async function drawAnnotations() {
    if (!webViewerInstance) return;

    const { documentViewer } = webViewerInstance.Core;
    const doc = documentViewer.getDocument();
    if (!doc) return;
    const pageCount = doc?.getPageCount();
    let tempThumbnails = [];

    documentViewerStore.updatePageCountForThumbnails(
      currentThumbnailDocument?.id,
      pageCount,
    );

    const thumbnailCount = Math.min(pageCount, 5);

    for (let i = 1; i <= thumbnailCount; i++) {
      const pageNumber = i;
      const zoom = 1;
      const resolver = new Promise((resolve) => {
        doc.loadCanvas({
          pageNumber,
          zoom,
          drawProgressive: async (thumbnail) => {
            tempThumbnails.push(thumbnail);
            await documentViewer.getAnnotationManager().drawAnnotations({
              pageNumber,
              overrideCanvas: thumbnail,
              majorRedraw: false,
            });
            if (pageNumber === thumbnailCount) {
              resolve({
                id: currentThumbnailDocument?.id,
                thumbnails: tempThumbnails,
              });
            }
          },
        });
      });
      resolver.then(async ({ id, thumbnails }) => {
        documentViewerStore.addGeneratedThumbnails(id, thumbnails);
        currentThumbnailDocument = null;
        await pickDocumentForPreviewGeneration();
      });
    }
  }
  // Preview Actions END

  function onDocumentViewerClose() {
    AttachmentAnnotationApi.unsubscribe();
    documentViewerStore.closeDocument();
  }

  function onNavigateToDocument(attachment) {
    AttachmentAnnotationApi.unsubscribe();
    documentViewerStore.openDocument({
      title: attachment.filename,
      url: attachment.url,
      id: attachment.permalink,
    });
  }

  /**
   * Event handler when webviewer has been loaded
   * @param {any} event CustomEvent from Webviewer instance
   * @returns {void}
   */
  function onWebViewerReady(event) {
    const elementsToOpen = ["leftPanel"];
    webViewerInstance = event.detail.instance;
    const { annotationManager, documentViewer } = webViewerInstance.Core;

    documentViewer.addEventListener("finishedRendering", () => {
      if (isLoadingDocument) {
        //added timeout because finishedRendering handling 3 times
        loadingTimeOut = setTimeout(() => {
          isLoadingDocument = false;
          webViewerInstance.UI.openElements(elementsToOpen);
        }, 550);
      } else {
        clearTimeout(loadingTimeOut);
      }
    });

    annotationManager.addEventListener("annotationChanged", () => {
      let annotationsWithSubject = annotationManager
        .getAnnotationsList()
        .filter((annotation) => !!annotation.Subject);
      if (annotationsWithSubject.length) {
        elementsToOpen.push("notesPanel");
      }
    });

    webViewerInstance.UI.addEventListener("documentLoaded", () => {
      documentViewer.setCurrentPage($document.page, true);

      if (currentThumbnailDocument?.watermarks || $document.watermarks) {
        documentViewer.setWatermark({
          diagonal: {
            fontSize: 25,
            fontFamily: "sans-serif",
            color: "#e5243a",
            opacity: 30,
            text: get(t)("file_preview.preview"),
          },
          custom: (ctx, pageNumber, pageWidth, pageHeight) => {
            ctx.fillStyle = "#e5243a";
            ctx.font = "10pt Arial";
            ctx.globalAlpha = 1;
            ctx.save();
            ctx.translate(pageWidth / 2, 10);
            ctx.fillText(get(t)("file_preview.preview_top"), 0, 0);
            ctx.restore();
          },
        });
      }
      if ($isAnnotationsEnabled) {
        AttachmentAnnotationApi.subcribe(
          webViewerInstance.Core,
          parentType,
          $activeControl.permalink,
        );
      }
    });
    if ($document.url) {
      loadDocumentToWebViewer();
    }
    annotationManager.addEventListener("annotationChanged", () => {
      let annotationsWithSubject = annotationManager
        .getAnnotationsList()
        .filter((annotation) => !!annotation.Subject);
      if (annotationsWithSubject.length > 0)
        webViewerInstance.UI.openElements(["notesPanel"]);
    });
    webViewerReadyResolve();
  }

  function onWebViewerError() {
    webViewerReadyReject();
  }

  /**
   * EventListener called when a document changed in the Webviewer
   * @param {any} event
   * @returns {any}
   */
  function onDocumentChange(event) {
    if (!$document.url) return;

    getRelatedDocuments();

    const { annotationManager } = event.detail.instance.Core;

    annotationManager.addEventListener(
      "annotationChanged",
      async (annotations, action, { imported }) => {
        if (imported) return;

        const xfdfString = await annotationManager.exportAnnotationCommand();
        const parentId = $activeControl.permalink;
        const params = { annotations, xfdfString, parentType, parentId };

        if (action === "add") {
          AttachmentAnnotationApi.onCreate(params);
        } else if (action === "modify") {
          AttachmentAnnotationApi.onUpdate(params);
        } else if (action === "delete") {
          AttachmentAnnotationApi.onDelete(params);
        }
      },
    );
  }

  function getRelatedDocuments() {
    if (!$activeControl) return;
    relatedDocuments = null;

    AttachmentsApi.preview({
      params: {
        id: $document.id,
        parent_type: parentType,
        parent_id: $activeControl.permalink,
      },
      success: function (documents) {
        relatedDocuments = documents;
      },
    });
  }

  function loadDocumentToWebViewer() {
    if (!webViewerInstance) return;
    if (!isLoadingDocument) {
      isLoadingDocument = true;
    }
    if (!$activeControl) {
      webViewerInstance.UI.loadDocument($document.url);
      return;
    }

    AttachmentsApi.getById({
      params: {
        id: $document.id,
        parent_id: $activeControl.permalink,
        parent_type: parentType,
      },
      success: (file) => {
        webViewerInstance.UI.loadDocument(file.data.attachment.url);
      },
    });
  }

  // Document Actions
  function onDocumentDelete() {
    const isConfirmed = confirm($t("control_status.confirm_deletion"));

    if (isConfirmed) {
      AttachmentsApi.delete({
        params: {
          id: $document.id,
          parent_id: $activeControl.permalink,
          parent_type: parentType,
        },
        success: () => {
          const newFilesArray = getAddedFilesControl(
            $activeControl.permalink,
          ).filter((item) => item.permalink !== $document.id);
          setFilesToControl(newFilesArray, $activeControl.permalink);
          onDocumentViewerClose();
        },
      });
    }
  }

  function onDocumentArchive() {
    AttachmentsApi.archiveBatch({
      params: {
        ids: [$document.id],
        parent_id: $activeControl.permalink,
        parent_type: parentType,
        archived: !$document.is_archived,
      },
      success: (attachments) => {
        setFilesToControl(attachments, $activeControl.permalink);
      },
    });
  }

  function onDocumentMarkAsRead() {
    AttachmentsApi.markAsRead({
      params: {
        id: $document.id,
        parent_id: $activeControl.permalink,
        parent_type: parentType,
        is_read: !$document.is_read,
      },
      success: () => {
        getAttachments();
      },
    });
  }

  async function onDocumentDownload() {
    isDownloadingPdf = true;

    await webViewerInstance?.UI.downloadPdf({
      includeAnnotations: true,
      filename: $document.title,
    });

    isDownloadingPdf = false;
  }

  function getAttachments() {
    AttachmentsApi.get({
      params: {
        parent_id: $activeControl.permalink,
        parent_type: parentType,
      },
      success: (files) => {
        setFilesToControl(files, $activeControl.permalink);
      },
    });
  }
</script>

<div class="{styles.box} {styles.hidden}">
  <WebViewer
    on:ready={onWebViewerReady}
    on:error={onWebViewerError}
    on:changed={onDocumentChange}
  />
</div>

<div bind:this={previewContainer} class={styles.container} />

{#if $document.url}
  <div data-component="DocumentViewer" class={styles.modal}>
    <div class={styles.box}>
      <div class={`${styles.header} ${isLoadingDocument ? styles.hidden : ""}`}>
        <div class={styles.info}>
          <img src={fileIcon($document.title)} alt={$document.title} />
          <div class={styles.filename}>{$document.title}</div>
        </div>

        <div class={styles.controls}>
          <div class={styles.actions}>
            {#if $activeControl}
              {#if $permissions["attachment"]["mark_as_read"]}
                <Button
                  style="icon-only border medium"
                  icon="checkmark_medium"
                  click={onDocumentMarkAsRead}
                />
              {/if}

              {#if $permissions["attachment"]["archive"]}
                <Button
                  style="icon-only border medium"
                  icon="icon-archive-box.svg"
                  click={onDocumentArchive}
                />
              {/if}

              {#if $showDownloadButton}
                <Button
                  icon="download"
                  loading={isDownloadingPdf}
                  style="icon-only border medium"
                  click={onDocumentDownload}
                />
              {/if}

              {#if $permissions["attachment"]["destroy"]}
                <Button
                  style="icon-only border medium"
                  icon="trash"
                  click={() => onDocumentDelete()}
                />
              {/if}

              <div class={styles.change}>
                {#if relatedDocuments}
                  <Button
                    style="icon-only border medium"
                    icon="stroke_thin_left"
                    click={onNavigateToDocument.bind(
                      this,
                      relatedDocuments.prev_attachment,
                    )}
                  />
                  <Button
                    style="icon-only border medium"
                    icon="stroke_thin_right"
                    click={onNavigateToDocument.bind(
                      this,
                      relatedDocuments.next_attachment,
                    )}
                  />
                {:else}
                  <img
                    class={styles.loading}
                    src={imagePath("loading-spinner")}
                    alt="loading"
                  />
                {/if}
              </div>
            {:else if $isAccountFeature("mailing_allow_clientuser_download")}
              {#if $showDownloadButton}
                <Button
                  icon="download"
                  loading={isDownloadingPdf}
                  style="icon-only border medium"
                  click={onDocumentDownload}
                />
              {/if}
            {/if}
          </div>

          <Button style="close" click={onDocumentViewerClose}>
            <img src={iconPath("red_close")} alt="" />
          </Button>
        </div>
      </div>

      <div class={styles.content}>
        {#if isLoadingDocument}
          <div class={styles.loading}>
            <img src={imagePath("loading-spinner")} alt="loading" />
          </div>
        {/if}

        <div class={`${styles.box} ${isLoadingDocument ? styles.hidden : ""}`}>
          <WebViewer
            on:ready={onWebViewerReady}
            on:error={onWebViewerError}
            on:changed={onDocumentChange}
          />
        </div>
      </div>
    </div>
  </div>
  <KeyEvent key={27} event={onDocumentViewerClose} />
{/if}

<style lang="scss">
  .hidden {
    visibility: hidden;
    position: absolute;
    top: -9999px;
    left: -9999px;
    opacity: 0;
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: var(--z-index-document-viewer);
    background: var(--primary-025);
    overflow-y: scroll;
  }

  .box {
    z-index: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    // transition: opacity 50ms ease-in-out 450ms;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--primary-050);
    padding: 15px;
    background: #fff;
    // transition: opacity 50ms ease-in-out 450ms;
  }

  .content {
    flex: 1;
    position: relative;
    overflow: scroll;
  }

  .info {
    display: flex;
    align-items: center;
  }

  .filename {
    margin-left: 10px;
  }

  .controls {
    display: flex;
  }

  .actions {
    margin-right: 25px;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 8px;

    .loading {
      width: 30px;
      height: 30px;
    }

    .change {
      display: flex;
      gap: 8px;
      width: 68px;
      margin-left: 25px;
    }
  }

  .image {
    display: block;
    transform-origin: top center;
    margin: 0 auto;
  }

  .image-wrapper {
    margin: 30px auto;
  }

  .error,
  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .error {
    font-size: 24px;
    color: var(--red-200);
  }

  .warning {
    max-width: 700px;
    margin: 0 auto;
  }
</style>
