<script>
  import styles from "styleguide/ConfirmationFileItem.json";
  import { ControlFileItem } from "apps/pbc";
  import { Button, Tooltip } from "components";

  export let attachment;
  export let disabled = false;
  export let tooltipMessage = "";
  export let buttonTitle = "";
  export let handleDelete = () => {};
</script>

<div data-component="ConfirmationFileItem" class={styles.wrapper}>
  <ControlFileItem
    preview
    file
    name={attachment.filename}
    uploadedSize={attachment.filesize}
    stored={attachment.is_stored}
    virus={attachment.virus}
    uploadFailed={attachment.upload_failed}
    is_stored={attachment.is_stored}
    created_at={attachment.created_at}
    virus_scanning={attachment.virus_scanning}
    url={attachment.url}
  >
    <div class={styles.actions}>
      <Tooltip
        message={disabled && tooltipMessage}
        maxWidth="300px"
        nowrap={false}
      >
        <Button {disabled} style="error-text small" click={handleDelete}>
          {buttonTitle}
        </Button>
      </Tooltip>
    </div>
  </ControlFileItem>
</div>

<style lang="scss">
  .wrapper {
    width: 100%;
  }
</style>
