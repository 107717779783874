$(document).on("turbolinks:load", function () {
  function setTooltipPosition(anchor, tooltip) {
    let target_width =
      anchor.width() + anchor.css("padding-left").replace("px", "") * 2;
    let page_offset = $(document).scrollTop();
    let targetHeight =
      anchor.height() + anchor.css("padding-bottom").replace("px", "") * 2 + 5;

    let top;

    if (anchor[0].classList.contains("tooltip-bottom")) {
      top = anchor.offset()["top"] - page_offset + targetHeight + "px";
      $(tooltip).addClass("bottom");
    } else {
      top = anchor.offset()["top"] - page_offset - targetHeight + "px";
      $(tooltip).addClass("top");
    }

    let tooltip_width =
      tooltip.width() + tooltip.css("padding-left").replace("px", "") * 2;
    let left_value =
      anchor.offset()["left"] + target_width / 2 - tooltip_width / 2;
    if (left_value < 0) {
      left_value = 0;
    }

    $(tooltip).css("top", top);
    $(tooltip).css("left", left_value + "px");
  }

  // handles dom elements getting removed by ajax
  function checkToolipExistence(anchor, tooltip) {
    if (
      document.body.contains($(anchor)[0]) &&
      document.body.contains($(tooltip)[0])
    ) {
      setTimeout(function () {
        checkToolipExistence(anchor, tooltip);
      }, 500);
    } else {
      tooltip.remove();
      $(document).off("scroll");
    }
  }

  $(document).on("mouseover", "[data-tooltip]", function (event) {
    var self = $(this);
    var anchor = self.find("[data-tooltip-anchor]");
    var tooltip = $(`<div class="tooltip">${self.data("tooltip")}</div>`);

    if (anchor.length === 0) {
      anchor = self;
    }

    if ($.trim(self.data("tooltip")).length > 0) {
      $("body").append(tooltip);
    }

    setTooltipPosition(anchor, tooltip);
    checkToolipExistence(anchor, tooltip);

    self.on("mouseout", function (event) {
      tooltip.remove();
      $(document).off("scroll");
    });

    self.on("remove", function (event) {
      console.log("remove");
      tooltip.remove();
      $(document).off("scroll");
    });

    $(document).on("scroll", function (event) {
      setTooltipPosition(anchor, tooltip);
    });
  });
});

$(document).on("turbolinks:before-visit", function (event) {
  $(document)
    .find(".tooltip")
    .each(function () {
      $(this).remove();
    });
});
