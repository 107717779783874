<script>
  import styles from "styleguide/ConfirmationSignersNewEmail.json";
  import { createEventDispatcher } from "svelte";
  import { t } from "stores/i18n.js";
  import { groupedConfirmationItems } from "stores/confirmation/confirmation.js";
  import { validate } from "lib/validate.js";
  import { Button, Checkbox, TextInput } from "components";

  export let email;
  export let dirty;
  export let signer;
  export let isTypesOpen;
  export let confirmationCount;
  export let onRemove;
  export let onToggleOpen;
  export let onInputChange;

  let selectedIds = [];
  const dispatch = createEventDispatcher();

  function handleToggle(id) {
    if (signer) selectedIds = signer.confirmation_item_ids;

    if (!selectedIds.includes(id)) {
      selectedIds.push(id);
    } else {
      selectedIds.splice(selectedIds.indexOf(id), 1);
    }

    dispatch("selectedIdsChanged", selectedIds);
  }
</script>

<div class={styles.inner} data-component="ConfirmationSignersNewEmail">
  <div class={styles.item}>
    {#if signer}
      <div class={styles.title}>
        {email}
        <span class={styles.count}>({confirmationCount})</span>
      </div>
    {:else}
      <div class={styles.textfield}>
        <TextInput
          bind:value={email}
          onInput={onInputChange}
          placeholder={$t("confirmation_signer_new_email.placeholder")}
          errors={dirty ? validate(email, ["email"]) : []}
        />
      </div>
    {/if}

    <div class={styles.buttons}>
      <Button
        style={isTypesOpen ? "error-text small" : "primary-text small"}
        click={onToggleOpen}
      >
        {isTypesOpen
          ? $t("confirmation_signer_new_email.cancel")
          : $t("confirmation_signer_new_email.edit")}
      </Button>
    </div>
  </div>

  {#if isTypesOpen}
    <div class={styles.types}>
      {#each Object.entries($groupedConfirmationItems) as [type, confirmations]}
        {#if confirmations.length > 0}
          <div>
            <div class={styles.title}>{$t(`confirmation.types.${type}`)}</div>
            {#each confirmations as confirmation}
              <div class={styles.checkboxes}>
                <Checkbox
                  toggled={signer
                    ? signer.confirmation_item_ids.includes(
                        confirmation.permalink,
                      )
                    : false}
                  toggle={() => handleToggle(confirmation.permalink)}
                >
                  <div class={styles.label}>
                    <div class={styles["label-item"]}>
                      {confirmation.company}, {confirmation.city}
                    </div>
                    <div class={styles["label-companies"]}>
                      {confirmation.companies}
                    </div>
                  </div>
                </Checkbox>
              </div>
            {/each}
          </div>
        {/if}
      {/each}
      <div class={styles.button}>
        <Button style={"error-text small"} click={onRemove}>
          {$t("confirmation_signer_new_email.delete")}
        </Button>
      </div>
    </div>
  {/if}
</div>

<style lang="scss">
  .title {
    font-weight: 500;
    font-size: 16px;
  }

  .textfield {
    width: 250px;
  }

  .inner {
    margin: 16px 0;
    border-radius: 8px;
    border: 1px solid var(--primary-050);
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
  }

  .buttons {
    display: flex;
  }

  .input {
    flex: 1;
    margin-right: 10px;
  }

  .types {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border-top: 1px solid var(--primary-050);
  }

  .count {
    margin-left: 8px;
    font-weight: 400;
    color: var(--primary-300);
  }

  .checkboxes {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
  }

  .label {
    font-size: 14px;
    line-height: 18px;
    margin-left: 10px;
    user-select: none;
    color: var(--primary-500);
    display: flex;
    max-width: 400px;
    gap: 6px;

    &-item {
      font-weight: 400;
      flex: 1;
      white-space: nowrap;
    }
    &-companies {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: var(--primary-300);
    }
  }
  .delete-button {
    margin-top: 14px;
  }
</style>
