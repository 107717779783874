<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(100%,100%,100%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,58.431373%,26.27451%);fill-opacity:1;"
      d="M -30 252 L 510 252 L 510 360 L -30 360 Z M -30 252 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,12.54902%,62.352941%);fill-opacity:1;"
      d="M -30 0 L 510 0 L 510 108 L -30 108 Z M -30 0 "
    />
    <path
      style="fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;stroke-width:0.879545;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 224.573983 108.913455 C 223.067991 108.944949 221.602082 110.611275 221.602082 110.611275 L 221.730922 128.405463 L 216.113513 134.292004 L 220.660121 134.292004 L 220.622901 144.498968 L 193.715454 180.728679 L 189.738604 179.354389 L 182.786989 194.185265 C 182.786989 194.185265 199.982788 204.950534 224.937598 204.655634 C 252.331771 204.332104 267.214183 193.687085 267.214183 193.687085 L 260.030657 179.048037 L 256.511902 180.576934 L 229.260884 144.536188 L 229.223663 134.025735 L 233.770272 134.025735 L 227.580242 128.213635 L 227.617462 110.482436 C 227.617462 110.482436 226.077113 108.879098 224.573983 108.913455 Z M 224.573983 108.913455 "
      transform="matrix(1.364341,0,0,1.364341,-66.676788,-33.91152)"
    />
    <path
      style="fill:none;stroke-width:4.397728;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 233.95351 151.519297 L 215.349064 151.519297 C 215.349064 151.519297 208.595003 137.289673 209.691572 127.343252 C 210.805319 117.216455 216.594514 112.42362 224.287673 112.34059 C 233.386616 112.237518 238.156546 116.798442 239.596687 126.936691 C 241.025376 136.986184 233.95351 151.519297 233.95351 151.519297 Z M 233.95351 151.519297 "
      transform="matrix(1.364341,0,0,1.364341,-66.676788,-33.91152)"
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 195.34375 219.082031 C 194.929688 219.636719 191.886719 225.722656 191.886719 225.722656 L 197.144531 224.617188 Z M 195.34375 219.082031 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 198.386719 226.96875 L 192.855469 228.765625 L 199.496094 231.394531 Z M 198.386719 226.96875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 200.878906 219.222656 L 203.644531 227.382812 L 210.421875 225.445312 L 208.625 221.574219 Z M 200.878906 219.222656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 205.164062 230.289062 L 206.132812 233.605469 L 215.125 235.820312 L 211.527344 228.210938 Z M 205.164062 230.289062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 215.125 223.371094 L 218.304688 230.980469 L 225.082031 228.351562 L 222.871094 225.03125 Z M 215.125 223.371094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 219.550781 233.746094 L 220.65625 236.648438 L 231.167969 238.171875 L 226.742188 231.253906 Z M 219.550781 233.746094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 229.648438 225.585938 L 233.519531 232.363281 L 243.339844 228.765625 L 242.234375 226.136719 Z M 229.648438 225.585938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 235.320312 234.851562 L 237.394531 238.449219 L 249.566406 238.03125 L 245 231.253906 Z M 235.320312 234.851562 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 248.183594 225.859375 L 251.5 231.53125 L 259.246094 227.796875 L 257.171875 224.753906 Z M 248.183594 225.859375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 260.90625 230.148438 L 253.160156 234.023438 L 255.238281 237.339844 L 265.609375 235.679688 Z M 260.90625 230.148438 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 263.398438 224.203125 L 267.410156 229.179688 L 273.910156 224.0625 L 271.695312 221.710938 Z M 263.398438 224.203125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 275.292969 226.828125 L 269.066406 231.53125 L 270.726562 234.4375 L 279.304688 232.222656 Z M 275.292969 226.828125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 283.867188 217.007812 L 285.390625 219.222656 L 281.101562 225.585938 L 276.675781 219.914062 Z M 283.867188 217.007812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 286.773438 222.542969 L 289.402344 227.796875 L 284.144531 229.59375 L 283.730469 227.105469 Z M 286.773438 222.542969 "
    />
  </g>
</svg>
