import ADIcon from "components/icons/countries/AD.svelte";
import AEIcon from "components/icons/countries/AE.svelte";
import AFIcon from "components/icons/countries/AF.svelte";
import AGIcon from "components/icons/countries/AG.svelte";
import AIIcon from "components/icons/countries/AI.svelte";
import ALIcon from "components/icons/countries/AL.svelte";
import AMIcon from "components/icons/countries/AM.svelte";
import AOIcon from "components/icons/countries/AO.svelte";
import AQIcon from "components/icons/countries/AQ.svelte";
import ARIcon from "components/icons/countries/AR.svelte";
import ASIcon from "components/icons/countries/AS.svelte";
import ATIcon from "components/icons/countries/AT.svelte";
import AUIcon from "components/icons/countries/AU.svelte";
import AWIcon from "components/icons/countries/AW.svelte";
import AXIcon from "components/icons/countries/AX.svelte";
import AZIcon from "components/icons/countries/AZ.svelte";
import BAIcon from "components/icons/countries/BA.svelte";
import BBIcon from "components/icons/countries/BB.svelte";
import BDIcon from "components/icons/countries/BD.svelte";
import BEIcon from "components/icons/countries/BE.svelte";
import BFIcon from "components/icons/countries/BF.svelte";
import BGIcon from "components/icons/countries/BG.svelte";
import BHIcon from "components/icons/countries/BH.svelte";
import BIIcon from "components/icons/countries/BI.svelte";
import BJIcon from "components/icons/countries/BJ.svelte";
import BLIcon from "components/icons/countries/BL.svelte";
import BMIcon from "components/icons/countries/BM.svelte";
import BNIcon from "components/icons/countries/BN.svelte";
import BOIcon from "components/icons/countries/BO.svelte";
import BQIcon from "components/icons/countries/BQ.svelte";
import BRIcon from "components/icons/countries/BR.svelte";
import BSIcon from "components/icons/countries/BS.svelte";
import BTIcon from "components/icons/countries/BT.svelte";
import BVIcon from "components/icons/countries/BV.svelte";
import BWIcon from "components/icons/countries/BW.svelte";
import BYIcon from "components/icons/countries/BY.svelte";
import BZIcon from "components/icons/countries/BZ.svelte";
import CAIcon from "components/icons/countries/CA.svelte";
import CCIcon from "components/icons/countries/CC.svelte";
import CDIcon from "components/icons/countries/CD.svelte";
import CFIcon from "components/icons/countries/CF.svelte";
import CGIcon from "components/icons/countries/CG.svelte";
import CHIcon from "components/icons/countries/CH.svelte";
import CIIcon from "components/icons/countries/CI.svelte";
import CKIcon from "components/icons/countries/CK.svelte";
import CLIcon from "components/icons/countries/CL.svelte";
import CMIcon from "components/icons/countries/CM.svelte";
import CNIcon from "components/icons/countries/CN.svelte";
import COIcon from "components/icons/countries/CO.svelte";
import CRIcon from "components/icons/countries/CR.svelte";
import CUIcon from "components/icons/countries/CU.svelte";
import CVIcon from "components/icons/countries/CV.svelte";
import CWIcon from "components/icons/countries/CW.svelte";
import CXIcon from "components/icons/countries/CX.svelte";
import CYIcon from "components/icons/countries/CY.svelte";
import CZIcon from "components/icons/countries/CZ.svelte";
import DEIcon from "components/icons/countries/DE.svelte";
import DJIcon from "components/icons/countries/DJ.svelte";
import DKIcon from "components/icons/countries/DK.svelte";
import DMIcon from "components/icons/countries/DM.svelte";
import DOIcon from "components/icons/countries/DO.svelte";
import DZIcon from "components/icons/countries/DZ.svelte";
import ECIcon from "components/icons/countries/EC.svelte";
import EEIcon from "components/icons/countries/EE.svelte";
import EGIcon from "components/icons/countries/EG.svelte";
import EHIcon from "components/icons/countries/EH.svelte";
import ERIcon from "components/icons/countries/ER.svelte";
import ESIcon from "components/icons/countries/ES.svelte";
import ETIcon from "components/icons/countries/ET.svelte";
import FIIcon from "components/icons/countries/FI.svelte";
import FJIcon from "components/icons/countries/FJ.svelte";
import FKIcon from "components/icons/countries/FK.svelte";
import FMIcon from "components/icons/countries/FM.svelte";
import FOIcon from "components/icons/countries/FO.svelte";
import FRIcon from "components/icons/countries/FR.svelte";
import GAIcon from "components/icons/countries/GA.svelte";
import GBIcon from "components/icons/countries/GB.svelte";
import GBENGIcon from "components/icons/countries/GB-ENG.svelte";
import GBSCTIcon from "components/icons/countries/GB-SCT.svelte";
import GDIcon from "components/icons/countries/GD.svelte";
import GEIcon from "components/icons/countries/GE.svelte";
import GFIcon from "components/icons/countries/GF.svelte";
import GGIcon from "components/icons/countries/GG.svelte";
import GHIcon from "components/icons/countries/GH.svelte";
import GIIcon from "components/icons/countries/GI.svelte";
import GLIcon from "components/icons/countries/GL.svelte";
import GMIcon from "components/icons/countries/GM.svelte";
import GNIcon from "components/icons/countries/GN.svelte";
import GPIcon from "components/icons/countries/GP.svelte";
import GQIcon from "components/icons/countries/GQ.svelte";
import GRIcon from "components/icons/countries/GR.svelte";
import GSIcon from "components/icons/countries/GS.svelte";
import GTIcon from "components/icons/countries/GT.svelte";
import GUIcon from "components/icons/countries/GU.svelte";
import GWIcon from "components/icons/countries/GW.svelte";
import GYIcon from "components/icons/countries/GY.svelte";
import HKIcon from "components/icons/countries/HK.svelte";
import HMIcon from "components/icons/countries/HM.svelte";
import HNIcon from "components/icons/countries/HN.svelte";
import HRIcon from "components/icons/countries/HR.svelte";
import HTIcon from "components/icons/countries/HT.svelte";
import HUIcon from "components/icons/countries/HU.svelte";
import IDIcon from "components/icons/countries/ID.svelte";
import IEIcon from "components/icons/countries/IE.svelte";
import ILIcon from "components/icons/countries/IL.svelte";
import IMIcon from "components/icons/countries/IM.svelte";
import INIcon from "components/icons/countries/IN.svelte";
import IOIcon from "components/icons/countries/IO.svelte";
import IQIcon from "components/icons/countries/IQ.svelte";
import IRIcon from "components/icons/countries/IR.svelte";
import ISIcon from "components/icons/countries/IS.svelte";
import ITIcon from "components/icons/countries/IT.svelte";
import JEIcon from "components/icons/countries/JE.svelte";
import JMIcon from "components/icons/countries/JM.svelte";
import JOIcon from "components/icons/countries/JO.svelte";
import JPIcon from "components/icons/countries/JP.svelte";
import KEIcon from "components/icons/countries/KE.svelte";
import KGIcon from "components/icons/countries/KG.svelte";
import KHIcon from "components/icons/countries/KH.svelte";
import KIIcon from "components/icons/countries/KI.svelte";
import KMIcon from "components/icons/countries/KM.svelte";
import KNIcon from "components/icons/countries/KN.svelte";
import KPIcon from "components/icons/countries/KP.svelte";
import KRIcon from "components/icons/countries/KR.svelte";
import KWIcon from "components/icons/countries/KW.svelte";
import KYIcon from "components/icons/countries/KY.svelte";
import KZIcon from "components/icons/countries/KZ.svelte";
import LAIcon from "components/icons/countries/LA.svelte";
import LBIcon from "components/icons/countries/LB.svelte";
import LCIcon from "components/icons/countries/LC.svelte";
import LIIcon from "components/icons/countries/LI.svelte";
import LKIcon from "components/icons/countries/LK.svelte";
import LRIcon from "components/icons/countries/LR.svelte";
import LSIcon from "components/icons/countries/LS.svelte";
import LTIcon from "components/icons/countries/LT.svelte";
import LUIcon from "components/icons/countries/LU.svelte";
import LVIcon from "components/icons/countries/LV.svelte";
import LYIcon from "components/icons/countries/LY.svelte";
import MAIcon from "components/icons/countries/MA.svelte";
import MCIcon from "components/icons/countries/MC.svelte";
import MDIcon from "components/icons/countries/MD.svelte";
import MEIcon from "components/icons/countries/ME.svelte";
import MFIcon from "components/icons/countries/MF.svelte";
import MGIcon from "components/icons/countries/MG.svelte";
import MHIcon from "components/icons/countries/MH.svelte";
import MKIcon from "components/icons/countries/MK.svelte";
import MLIcon from "components/icons/countries/ML.svelte";
import MMIcon from "components/icons/countries/MM.svelte";
import MNIcon from "components/icons/countries/MN.svelte";
import MOIcon from "components/icons/countries/MO.svelte";
import MPIcon from "components/icons/countries/MP.svelte";
import MQIcon from "components/icons/countries/MQ.svelte";
import MRIcon from "components/icons/countries/MR.svelte";
import MSIcon from "components/icons/countries/MS.svelte";
import MTIcon from "components/icons/countries/MT.svelte";
import MUIcon from "components/icons/countries/MU.svelte";
import MVIcon from "components/icons/countries/MV.svelte";
import MWIcon from "components/icons/countries/MW.svelte";
import MXIcon from "components/icons/countries/MX.svelte";
import MYIcon from "components/icons/countries/MY.svelte";
import MZIcon from "components/icons/countries/MZ.svelte";
import NAIcon from "components/icons/countries/NA.svelte";
import NCIcon from "components/icons/countries/NC.svelte";
import NEIcon from "components/icons/countries/NE.svelte";
import NFIcon from "components/icons/countries/NF.svelte";
import NGIcon from "components/icons/countries/NG.svelte";
import NIIcon from "components/icons/countries/NI.svelte";
import NLIcon from "components/icons/countries/NL.svelte";
import NOIcon from "components/icons/countries/NO.svelte";
import NPIcon from "components/icons/countries/NP.svelte";
import NRIcon from "components/icons/countries/NR.svelte";
import NUIcon from "components/icons/countries/NU.svelte";
import NZIcon from "components/icons/countries/NZ.svelte";
import OMIcon from "components/icons/countries/OM.svelte";
import PAIcon from "components/icons/countries/PA.svelte";
import PEIcon from "components/icons/countries/PE.svelte";
import PFIcon from "components/icons/countries/PF.svelte";
import PGIcon from "components/icons/countries/PG.svelte";
import PHIcon from "components/icons/countries/PH.svelte";
import PKIcon from "components/icons/countries/PK.svelte";
import PLIcon from "components/icons/countries/PL.svelte";
import PMIcon from "components/icons/countries/PM.svelte";
import PNIcon from "components/icons/countries/PN.svelte";
import PRIcon from "components/icons/countries/PR.svelte";
import PSIcon from "components/icons/countries/PS.svelte";
import PTIcon from "components/icons/countries/PT.svelte";
import PWIcon from "components/icons/countries/PW.svelte";
import PYIcon from "components/icons/countries/PY.svelte";
import QAIcon from "components/icons/countries/QA.svelte";
import REIcon from "components/icons/countries/RE.svelte";
import ROIcon from "components/icons/countries/RO.svelte";
import RSIcon from "components/icons/countries/RS.svelte";
import RUIcon from "components/icons/countries/RU.svelte";
import RWIcon from "components/icons/countries/RW.svelte";
import SAIcon from "components/icons/countries/SA.svelte";
import SBIcon from "components/icons/countries/SB.svelte";
import SCIcon from "components/icons/countries/SC.svelte";
import SDIcon from "components/icons/countries/SD.svelte";
import SEIcon from "components/icons/countries/SE.svelte";
import SGIcon from "components/icons/countries/SG.svelte";
import SHIcon from "components/icons/countries/SH.svelte";
import SIIcon from "components/icons/countries/SI.svelte";
import SJIcon from "components/icons/countries/SJ.svelte";
import SKIcon from "components/icons/countries/SK.svelte";
import SLIcon from "components/icons/countries/SL.svelte";
import SMIcon from "components/icons/countries/SM.svelte";
import SNIcon from "components/icons/countries/SN.svelte";
import SOIcon from "components/icons/countries/SO.svelte";
import SRIcon from "components/icons/countries/SR.svelte";
import SSIcon from "components/icons/countries/SS.svelte";
import STIcon from "components/icons/countries/ST.svelte";
import SVIcon from "components/icons/countries/SV.svelte";
import SXIcon from "components/icons/countries/SX.svelte";
import SYIcon from "components/icons/countries/SY.svelte";
import SZIcon from "components/icons/countries/SZ.svelte";
import TCIcon from "components/icons/countries/TC.svelte";
import TDIcon from "components/icons/countries/TD.svelte";
import TFIcon from "components/icons/countries/TF.svelte";
import TGIcon from "components/icons/countries/TG.svelte";
import THIcon from "components/icons/countries/TH.svelte";
import TJIcon from "components/icons/countries/TJ.svelte";
import TKIcon from "components/icons/countries/TK.svelte";
import TLIcon from "components/icons/countries/TL.svelte";
import TMIcon from "components/icons/countries/TM.svelte";
import TNIcon from "components/icons/countries/TN.svelte";
import TOIcon from "components/icons/countries/TO.svelte";
import TRIcon from "components/icons/countries/TR.svelte";
import TTIcon from "components/icons/countries/TT.svelte";
import TVIcon from "components/icons/countries/TV.svelte";
import TWIcon from "components/icons/countries/TW.svelte";
import TZIcon from "components/icons/countries/TZ.svelte";
import UAIcon from "components/icons/countries/UA.svelte";
import UGIcon from "components/icons/countries/UG.svelte";
import UKIcon from "components/icons/countries/UK.svelte";
import UMIcon from "components/icons/countries/UM.svelte";
import USIcon from "components/icons/countries/US.svelte";
import UYIcon from "components/icons/countries/UY.svelte";
import UZIcon from "components/icons/countries/UZ.svelte";
import VAIcon from "components/icons/countries/VA.svelte";
import VCIcon from "components/icons/countries/VC.svelte";
import VEIcon from "components/icons/countries/VE.svelte";
import VGIcon from "components/icons/countries/VG.svelte";
import VIIcon from "components/icons/countries/VI.svelte";
import VNIcon from "components/icons/countries/VN.svelte";
import VUIcon from "components/icons/countries/VU.svelte";
import WFIcon from "components/icons/countries/WF.svelte";
import WSIcon from "components/icons/countries/WS.svelte";
import XKIcon from "components/icons/countries/XK.svelte";
import YEIcon from "components/icons/countries/YE.svelte";
import YTIcon from "components/icons/countries/YT.svelte";
import ZAIcon from "components/icons/countries/ZA.svelte";
import ZMIcon from "components/icons/countries/ZM.svelte";
import ZWIcon from "components/icons/countries/ZW.svelte";
import ZZIcon from "components/icons/countries/ZZ.svelte";

export const iconByKey = {
  ad: ADIcon,
  ae: AEIcon,
  af: AFIcon,
  ag: AGIcon,
  ai: AIIcon,
  al: ALIcon,
  am: AMIcon,
  ao: AOIcon,
  aq: AQIcon,
  ar: ARIcon,
  as: ASIcon,
  at: ATIcon,
  au: AUIcon,
  aw: AWIcon,
  ax: AXIcon,
  az: AZIcon,
  ba: BAIcon,
  bb: BBIcon,
  bd: BDIcon,
  be: BEIcon,
  bf: BFIcon,
  bg: BGIcon,
  bh: BHIcon,
  bi: BIIcon,
  bj: BJIcon,
  bl: BLIcon,
  bm: BMIcon,
  bn: BNIcon,
  bo: BOIcon,
  bq: BQIcon,
  br: BRIcon,
  bs: BSIcon,
  bt: BTIcon,
  bv: BVIcon,
  bw: BWIcon,
  by: BYIcon,
  bz: BZIcon,
  ca: CAIcon,
  cc: CCIcon,
  cd: CDIcon,
  cf: CFIcon,
  cg: CGIcon,
  ch: CHIcon,
  ci: CIIcon,
  ck: CKIcon,
  cl: CLIcon,
  cm: CMIcon,
  cn: CNIcon,
  co: COIcon,
  cr: CRIcon,
  cu: CUIcon,
  cv: CVIcon,
  cw: CWIcon,
  cx: CXIcon,
  cy: CYIcon,
  cz: CZIcon,
  de: DEIcon,
  dj: DJIcon,
  dk: DKIcon,
  dm: DMIcon,
  do: DOIcon,
  dz: DZIcon,
  ec: ECIcon,
  ee: EEIcon,
  eg: EGIcon,
  eh: EHIcon,
  er: ERIcon,
  es: ESIcon,
  et: ETIcon,
  fi: FIIcon,
  fj: FJIcon,
  fk: FKIcon,
  fm: FMIcon,
  fo: FOIcon,
  fr: FRIcon,
  ga: GAIcon,
  gb: GBIcon,
  "gb-eng": GBENGIcon,
  "gb-sct": GBSCTIcon,
  gd: GDIcon,
  ge: GEIcon,
  gf: GFIcon,
  gg: GGIcon,
  gh: GHIcon,
  gi: GIIcon,
  gl: GLIcon,
  gm: GMIcon,
  gn: GNIcon,
  gp: GPIcon,
  gq: GQIcon,
  gr: GRIcon,
  gs: GSIcon,
  gt: GTIcon,
  gu: GUIcon,
  gw: GWIcon,
  gy: GYIcon,
  hk: HKIcon,
  hm: HMIcon,
  hn: HNIcon,
  hr: HRIcon,
  ht: HTIcon,
  hu: HUIcon,
  id: IDIcon,
  ie: IEIcon,
  il: ILIcon,
  im: IMIcon,
  in: INIcon,
  io: IOIcon,
  iq: IQIcon,
  ir: IRIcon,
  is: ISIcon,
  it: ITIcon,
  je: JEIcon,
  jm: JMIcon,
  jo: JOIcon,
  jp: JPIcon,
  ke: KEIcon,
  kg: KGIcon,
  kh: KHIcon,
  ki: KIIcon,
  km: KMIcon,
  kn: KNIcon,
  kp: KPIcon,
  kr: KRIcon,
  kw: KWIcon,
  ky: KYIcon,
  kz: KZIcon,
  la: LAIcon,
  lb: LBIcon,
  lc: LCIcon,
  li: LIIcon,
  lk: LKIcon,
  lr: LRIcon,
  ls: LSIcon,
  lt: LTIcon,
  lu: LUIcon,
  lv: LVIcon,
  ly: LYIcon,
  ma: MAIcon,
  mc: MCIcon,
  md: MDIcon,
  me: MEIcon,
  mf: MFIcon,
  mg: MGIcon,
  mh: MHIcon,
  mk: MKIcon,
  ml: MLIcon,
  mm: MMIcon,
  mn: MNIcon,
  mo: MOIcon,
  mp: MPIcon,
  mq: MQIcon,
  mr: MRIcon,
  ms: MSIcon,
  mt: MTIcon,
  mu: MUIcon,
  mv: MVIcon,
  mw: MWIcon,
  mx: MXIcon,
  my: MYIcon,
  mz: MZIcon,
  na: NAIcon,
  nc: NCIcon,
  ne: NEIcon,
  nf: NFIcon,
  ng: NGIcon,
  ni: NIIcon,
  nl: NLIcon,
  no: NOIcon,
  np: NPIcon,
  nr: NRIcon,
  nu: NUIcon,
  nz: NZIcon,
  om: OMIcon,
  pa: PAIcon,
  pe: PEIcon,
  pf: PFIcon,
  pg: PGIcon,
  ph: PHIcon,
  pk: PKIcon,
  pl: PLIcon,
  pm: PMIcon,
  pn: PNIcon,
  pr: PRIcon,
  ps: PSIcon,
  pt: PTIcon,
  pw: PWIcon,
  py: PYIcon,
  qa: QAIcon,
  re: REIcon,
  ro: ROIcon,
  rs: RSIcon,
  ru: RUIcon,
  rw: RWIcon,
  sa: SAIcon,
  sb: SBIcon,
  sc: SCIcon,
  sd: SDIcon,
  se: SEIcon,
  sg: SGIcon,
  sh: SHIcon,
  si: SIIcon,
  sj: SJIcon,
  sk: SKIcon,
  sl: SLIcon,
  sm: SMIcon,
  sn: SNIcon,
  so: SOIcon,
  sr: SRIcon,
  ss: SSIcon,
  st: STIcon,
  sv: SVIcon,
  sx: SXIcon,
  sy: SYIcon,
  sz: SZIcon,
  tc: TCIcon,
  td: TDIcon,
  tf: TFIcon,
  tg: TGIcon,
  th: THIcon,
  tj: TJIcon,
  tk: TKIcon,
  tl: TLIcon,
  tm: TMIcon,
  tn: TNIcon,
  to: TOIcon,
  tr: TRIcon,
  tt: TTIcon,
  tv: TVIcon,
  tw: TWIcon,
  tz: TZIcon,
  ua: UAIcon,
  ug: UGIcon,
  uk: UKIcon,
  um: UMIcon,
  us: USIcon,
  uy: UYIcon,
  uz: UZIcon,
  va: VAIcon,
  vc: VCIcon,
  ve: VEIcon,
  vg: VGIcon,
  vi: VIIcon,
  vn: VNIcon,
  vu: VUIcon,
  wf: WFIcon,
  ws: WSIcon,
  xk: XKIcon,
  ye: YEIcon,
  yt: YTIcon,
  za: ZAIcon,
  zm: ZMIcon,
  zw: ZWIcon,
  zz: ZZIcon,
};
