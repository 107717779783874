<script>
  import styles from "styleguide/StyleguideColorItem.json";
  export let colors;
</script>

<div class={styles.swatches}>
  {#each colors as color}
    <div>
      <span>{color}</span>
      <div class={styles.swatch} style="background-color: var({color})"></div>
    </div>
  {/each}
</div>

<style lang="scss">
  .swatches {
    display: flex;
    overflow: hidden;
    margin-bottom: 1em;
    display: flex;
    width: 100%;

    div {
      flex: 1;
    }
  }
  .swatch {
    height: 100px;
    flex: 1;
  }
</style>
