<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(0%,0%,40%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 0 L 0 20.964844 L 230.277344 187.5 L 259.261719 187.5 L 259.261719 166.539062 L 28.984375 0 Z M 259.261719 0 L 259.261719 20.960938 L 28.984375 187.5 L 0 187.5 L 0 166.535156 L 230.277344 0 Z M 259.261719 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 108.027344 0 L 108.027344 187.5 L 151.238281 187.5 L 151.238281 0 Z M 0 62.5 L 0 125 L 259.261719 125 L 259.261719 62.5 Z M 0 62.5 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 0 75 L 0 112.5 L 259.261719 112.5 L 259.261719 75 Z M 116.667969 0 L 116.667969 187.5 L 142.59375 187.5 L 142.59375 0 Z M 116.667969 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 0 187.5 L 86.421875 125 L 105.746094 125 L 19.324219 187.5 Z M 0 0 L 86.421875 62.5 L 67.097656 62.5 L 0 13.976562 Z M 153.519531 62.5 L 239.9375 0 L 259.261719 0 L 172.84375 62.5 Z M 259.261719 187.5 L 172.84375 125 L 192.164062 125 L 259.261719 173.523438 Z M 259.261719 187.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 224.820312 294.390625 L 192.082031 297.238281 L 196.59375 329.792969 L 173.953125 305.96875 L 151.3125 329.792969 L 155.824219 297.238281 L 123.082031 294.390625 L 151.339844 277.621094 L 133.160156 250.25 L 163.886719 261.882812 L 173.953125 230.601562 L 184.015625 261.882812 L 214.746094 250.25 L 196.5625 277.621094 Z M 224.820312 294.390625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 364.894531 324.394531 L 356.992188 312.503906 L 370.347656 317.5625 L 374.71875 303.96875 L 379.085938 317.5625 L 392.441406 312.503906 L 384.539062 324.394531 L 396.816406 331.683594 L 382.59375 332.921875 L 384.554688 347.058594 L 374.71875 336.714844 L 364.878906 347.058594 L 366.84375 332.921875 L 352.621094 331.683594 Z M 364.894531 324.394531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 364.894531 112.824219 L 356.992188 100.933594 L 370.347656 105.988281 L 374.71875 92.398438 L 379.085938 105.988281 L 392.441406 100.933594 L 384.539062 112.824219 L 396.816406 120.113281 L 382.59375 121.351562 L 384.554688 135.488281 L 374.71875 125.144531 L 364.878906 135.488281 L 366.84375 121.351562 L 352.621094 120.113281 Z M 364.894531 112.824219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 285.554688 198.773438 L 277.652344 186.882812 L 291.007812 191.941406 L 295.378906 178.347656 L 299.746094 191.941406 L 313.101562 186.882812 L 305.199219 198.773438 L 317.476562 206.0625 L 303.253906 207.300781 L 305.214844 221.441406 L 295.378906 211.09375 L 285.539062 221.441406 L 287.503906 207.300781 L 273.28125 206.0625 Z M 285.554688 198.773438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 435.4375 177.617188 L 427.519531 165.726562 L 440.886719 170.785156 L 445.222656 157.191406 L 449.613281 170.785156 L 462.945312 165.726562 L 455.0625 177.617188 L 467.332031 184.90625 L 453.105469 186.144531 L 455.0625 200.28125 L 445.222656 189.9375 L 435.386719 200.28125 L 437.34375 186.144531 L 423.140625 184.90625 Z M 435.4375 177.617188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 406.453125 239.953125 L 398.679688 244.769531 L 400.863281 235.890625 L 393.875 229.992188 L 403 229.320312 L 406.453125 220.851562 L 409.90625 229.320312 L 419.03125 229.992188 L 412.042969 235.890625 L 414.230469 244.769531 Z M 406.453125 239.953125 "
    />
  </g>
</svg>
