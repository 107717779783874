<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(19.215686%,57.254902%,3.137255%);fill-opacity:1;"
      d="M 0 308.574219 L 720 308.574219 L 720 360.003906 L 0 360.003906 Z M 0 308.574219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(87.058824%,12.54902%,6.27451%);fill-opacity:1;"
      d="M 0 205.714844 L 720 205.714844 L 720 257.140625 L 0 257.140625 Z M 0 205.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,82.352941%,0%);fill-opacity:1;"
      d="M 0 257.140625 L 720 257.140625 L 720 308.570312 L 0 308.570312 Z M 0 257.140625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(87.058824%,12.54902%,6.27451%);fill-opacity:1;"
      d="M 0 102.859375 L 720 102.859375 L 720 154.289062 L 0 154.289062 Z M 0 102.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,82.352941%,0%);fill-opacity:1;"
      d="M 0 51.429688 L 720 51.429688 L 720 102.855469 L 0 102.855469 Z M 0 51.429688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(19.215686%,57.254902%,3.137255%);fill-opacity:1;"
      d="M 0 0 L 720 0 L 720 51.429688 L 0 51.429688 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 20.3125 0 L 20.3125 360 L 262.027344 180 Z M 20.3125 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 0 0 L 20.570312 0 L 20.570312 360 L 0 360 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 0 0 L 0 360 L 262.285156 180 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 0 154.285156 L 720 154.285156 L 720 205.714844 L 0 205.714844 Z M 0 154.285156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 0 L 0 360 L 241.714844 180 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,14.117647%,9.803922%);fill-opacity:1;"
      d="M 92.78125 80.621094 L 111.957031 144.160156 L 180.445312 144.761719 L 125.9375 182.632812 L 146.535156 247.945312 L 93.675781 207.808594 L 37.921875 247.582031 L 59.753906 184.902344 L 4.695312 144.171875 L 71.058594 145.566406 Z M 92.78125 80.621094 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(96.862745%,87.45098%,0%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 542.727341 175.701767 C 544.336789 172.805838 544.654372 171.201773 553.013812 167.665294 C 563.93545 135.853131 591.89355 148.389599 607.315183 192.409881 C 621.455716 198.513941 705.965184 261.492335 704.678703 265.028814 C 704.038153 268.242327 693.434099 265.669363 693.434099 265.669363 C 693.434099 265.669363 676.725985 337.007196 676.403019 337.007196 C 645.231405 332.830168 596.711128 332.189618 563.612484 332.830168 C 563.93545 327.366658 554.617877 291.700432 554.617877 291.700432 C 554.617877 291.700432 546.581404 289.127469 560.076005 261.815301 C 574.539504 227.435557 577.430051 171.519356 542.727341 175.701767 Z M 542.727341 175.701767 "
      transform="matrix(0.725695,0,0,0.725695,-358.671094,-10.627043)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 571.320608 162.842333 C 571.320608 164.790895 569.738074 166.373429 567.784129 166.373429 C 565.835567 166.373429 564.253033 164.790895 564.253033 162.842333 C 564.253033 160.888388 565.835567 159.305854 567.784129 159.305854 C 569.738074 159.305854 571.320608 160.888388 571.320608 162.842333 Z M 571.320608 162.842333 "
      transform="matrix(0.725695,0,0,0.725695,-358.671094,-10.627043)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 606.351667 192.409881 C 606.351667 192.409881 578.716533 208.15448 570.680059 207.831514 "
      transform="matrix(0.725695,0,0,0.725695,-358.671094,-10.627043)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 693.428716 265.669363 C 691.501685 264.065298 590.930035 259.242337 595.101681 225.180177 "
      transform="matrix(0.725695,0,0,0.725695,-358.671094,-10.627043)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 611.169245 266.309913 C 611.169245 266.309913 622.096265 272.736938 631.413838 256.028824 "
      transform="matrix(0.725695,0,0,0.725695,-358.671094,-10.627043)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 623.377364 265.992329 C 623.377364 265.992329 631.090872 273.059904 642.340858 257.638272 "
      transform="matrix(0.725695,0,0,0.725695,-358.671094,-10.627043)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 554.940843 291.700432 C 554.940843 291.700432 562.008419 292.663948 563.2949 288.48692 "
      transform="matrix(0.725695,0,0,0.725695,-358.671094,-10.627043)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 588.351689 333.147751 C 588.351689 332.830168 581.278731 287.523404 581.278731 287.523404 C 616.196751 290.629261 652.401253 290.844572 686.032792 296.840977 "
      transform="matrix(0.725695,0,0,0.725695,-358.671094,-10.627043)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 584.820592 288.158571 L 594.461131 299.408557 L 604.419253 289.768018 L 612.77331 298.762625 "
      transform="matrix(0.725695,0,0,0.725695,-358.671094,-10.627043)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 615.351656 299.090974 L 623.705713 291.700432 L 632.70032 300.377456 "
      transform="matrix(0.725695,0,0,0.725695,-358.671094,-10.627043)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 634.299002 300.372073 L 643.616575 292.018016 L 650.689533 300.372073 "
      transform="matrix(0.725695,0,0,0.725695,-358.671094,-10.627043)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 654.548978 300.700422 L 662.903035 294.913945 L 670.293576 301.986903 "
      transform="matrix(0.725695,0,0,0.725695,-358.671094,-10.627043)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 676.080053 301.658555 L 682.184112 295.877461 "
      transform="matrix(0.725695,0,0,0.725695,-358.671094,-10.627043)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 583.211144 298.439659 L 593.492233 310.330195 L 603.773321 301.012622 L 612.77331 310.653161 L 623.054398 301.653172 L 631.731421 311.93426 L 642.335476 302.939653 C 642.335476 302.939653 650.366567 312.897776 651.330082 312.897776 C 652.293598 312.897776 661.934136 304.543719 661.934136 304.543719 L 671.257092 314.830189 L 684.105761 304.543719 "
      transform="matrix(0.725695,0,0,0.725695,-358.671094,-10.627043)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 584.820592 310.012611 C 584.820592 310.012611 667.720613 313.226124 681.220597 316.439637 "
      transform="matrix(0.725695,0,0,0.725695,-358.671094,-10.627043)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 598.63816 272.419355 C 598.63816 272.419355 677.366534 276.596384 689.89762 281.419344 "
      transform="matrix(0.725695,0,0,0.725695,-358.671094,-10.627043)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 582.570595 239.638294 C 583.211144 244.461254 577.430051 261.169368 571.325991 266.950462 C 562.966551 273.700454 563.93545 287.840987 563.93545 287.840987 C 564.898965 293.622081 577.430051 292.981531 579.034116 290.090985 C 580.320598 276.913967 594.461131 274.02342 594.461131 274.02342 C 594.461131 274.02342 614.059792 268.55991 622.413849 252.169379 "
      transform="matrix(0.725695,0,0,0.725695,-358.671094,-10.627043)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 622.090883 281.408579 C 622.090883 283.717786 620.217679 285.585607 617.913854 285.585607 C 615.604646 285.585607 613.736826 283.717786 613.736826 281.408579 C 613.736826 279.104754 615.604646 277.23155 617.913854 277.23155 C 620.217679 277.23155 622.090883 279.104754 622.090883 281.408579 Z M 622.090883 281.408579 "
      transform="matrix(0.725695,0,0,0.725695,-358.671094,-10.627043)"
    />
    <path
      style="fill:none;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 640.731411 283.018027 C 640.731411 285.327234 638.858207 287.195055 636.554382 287.195055 C 634.245174 287.195055 632.377354 285.327234 632.377354 283.018027 C 632.377354 280.714202 634.245174 278.840998 636.554382 278.840998 C 638.858207 278.840998 640.731411 280.714202 640.731411 283.018027 Z M 640.731411 283.018027 "
      transform="matrix(0.725695,0,0,0.725695,-358.671094,-10.627043)"
    />
  </g>
</svg>
