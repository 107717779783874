<script>
  import styles from "styleguide/BreadcrumbIconButton.json";

  export let type;
  export let click;

  function clickHandler(event) {
    click?.(event);
    event.target.blur();
  }
</script>

<button
  data-component="IconButton"
  class={styles.button}
  on:click={clickHandler}
>
  <!-- <img src={iconPath(icon)} alt="" /> -->
  {#if type == "history"}
    <svg
      width="30"
      height="30"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.750488 9.01213C0.751266 13.5677 4.44425 17.2607 9.00051 17.2623C13.5565 17.2615 17.2503 13.5678 17.251 9.01213C17.2495 4.45548 13.5565 0.762497 9.0009 0.761719C7.29749 0.761719 5.67587 1.28046 4.31882 2.21746L3.42102 1.25497C2.99187 0.794729 2.22088 1.03577 2.1303 1.6585L1.65219 4.95725C1.58364 5.43191 1.97207 5.84807 2.45033 5.81237L5.77329 5.56437C6.40099 5.51754 6.69527 4.76498 6.26584 4.30478L5.35554 3.32888C6.42663 2.6402 7.68293 2.26172 9.00077 2.26172C12.7281 2.26236 15.7498 5.28406 15.751 9.01226C15.7504 12.7393 12.7281 15.7616 9.00064 15.7623C5.27283 15.761 2.25112 12.7393 2.25049 9.01187C2.25042 8.59766 1.91457 8.26193 1.50036 8.262C1.08615 8.26207 0.750418 8.59792 0.750488 9.01213ZM9.00035 5.0773C9.38005 5.0773 9.69384 5.35946 9.74351 5.72553L9.75035 5.8273V8.65584L12.719 11.0819C13.0398 11.344 13.0873 11.8165 12.8252 12.1372C12.5869 12.4288 12.1748 12.4946 11.8608 12.3072L11.7699 12.2433L8.52587 9.59235C8.43083 9.51468 8.35978 9.41854 8.31366 9.31336C8.28603 9.25063 8.26674 9.1834 8.2572 9.11307L8.25705 9.11091C8.2527 9.07829 8.25049 9.0454 8.25043 9.01246L8.25035 9.0113V5.8273C8.25035 5.41309 8.58614 5.0773 9.00035 5.0773Z"
        fill="#3883FA"
      />
    </svg>
  {:else if type == "notification-on"}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g fill="none" fill-rule="evenodd">
        <g fill="#3883FA">
          <path
            d="M10.28 15.013c.578 0 .939.626.649 1.126-.618 1.065-1.982 1.427-3.047.81-.335-.194-.614-.473-.81-.809-.292-.5.07-1.127.648-1.127zM9 .75c2.912 0 5.272 2.36 5.272 5.272 0 2.186.394 3.829 1.039 5.01.33.607.641.958.834 1.11l.054.04c.588.391.352 1.283-.313 1.367l-.103.006H2.217c-.707 0-1.005-.872-.498-1.311l.082-.063c.185-.123.525-.482.888-1.148.605-1.108.989-2.621 1.035-4.607l.004-.404C3.728 3.11 6.088.75 9 .75z"
          />
        </g>
      </g>
    </svg>
  {:else if type == "notification-off"}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g fill="none" fill-rule="evenodd">
        <g fill="#3883FA">
          <path
            d="M10.28 15.013c.578 0 .939.626.649 1.126-.618 1.065-1.982 1.427-3.047.81-.335-.194-.614-.473-.81-.809-.292-.5.07-1.127.648-1.127zM9 .75c2.912 0 5.272 2.36 5.272 5.272 0 2.186.394 3.829 1.039 5.01.33.607.641.958.834 1.11l.054.04c.588.391.352 1.283-.313 1.367l-.103.006H2.217c-.707 0-1.005-.872-.498-1.311l.082-.063c.185-.123.525-.482.888-1.148.605-1.108.989-2.621 1.035-4.607l.004-.404C3.728 3.11 6.088.75 9 .75zm0 1.5c-2.084 0-3.772 1.689-3.772 3.772 0 2.43-.453 4.32-1.222 5.729l-.121.215-.056.089H14.17l-.054-.09-.122-.214c-.723-1.326-1.167-3.078-1.217-5.305l-.005-.424-.005-.194C12.667 3.835 11.02 2.25 9 2.25z"
          />
        </g>
      </g>
    </svg>
  {:else if type == "notification-on-muted"}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path
        fill="#A5B3C8"
        fill-rule="evenodd"
        d="M10.28 15.013c.578 0 .939.626.649 1.126-.618 1.065-1.982 1.427-3.047.81-.335-.194-.614-.473-.81-.809-.292-.5.07-1.127.648-1.127zM9 .75c2.912 0 5.272 2.36 5.272 5.272 0 2.186.394 3.829 1.039 5.01.33.607.641.958.834 1.11l.054.04c.588.391.352 1.283-.313 1.367l-.103.006H2.217c-.707 0-1.005-.872-.498-1.311l.082-.063c.185-.123.525-.482.888-1.148.605-1.108.989-2.621 1.035-4.607l.004-.404C3.728 3.11 6.088.75 9 .75zm0 1.5c-2.084 0-3.772 1.689-3.772 3.772 0 2.43-.453 4.32-1.222 5.729l-.121.215-.056.089H14.17l-.054-.09-.122-.214c-.723-1.326-1.167-3.078-1.217-5.305l-.005-.424-.005-.194C12.667 3.835 11.02 2.25 9 2.25z"
      />
    </svg>
  {/if}
  <slot />
</button>

<style scoped lang="scss">
  button {
    all: unset;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    height: 30px;
    width: 30px;
    background: transparent;
    border-radius: var(--border-radius);
    border: 2px solid transparent;
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;

    svg {
      height: 24px;
      width: 24px;
      path {
        fill: var(--breadcrumb-button-text-color);
      }
    }
  }

  button:hover,
  button:focus:not(:active),
  button:active {
    color: var(
      --breadcrumb-button-text-highlight-color,
      var(--breadcrumb-button-active-text-color)
    );
    background-color: var(
      --breadcrumb-button-background-highlight-color,
      var(--breadcrumb-button-hover-background-color)
    );
    font-weight: 400;
    outline: none;

    path {
      fill: var(
        --breadcrumb-button-text-highlight-color,
        var(--breadcrumb-button-active-text-color)
      );
    }
  }
</style>
