<script>
  import {
    SAMPLE_BALANCE_REVIEW_FIELDS,
    SAMPLE_SINGLE_REVIEW_FIELDS,
    SAMPLE_REVIEW_TYPE,
  } from "apps/sample/constants";
  import { sample } from "apps/sample/stores";
  import clsx from "clsx";
  import { t } from "stores/i18n.js";

  import styles from "styleguide/SampleDetailsCardBalances.json";

  let isSingleReview = $sample.review_type === SAMPLE_REVIEW_TYPE.single;
</script>

<div class={styles.wrapper} data-component="SampleDetailsCardBalances">
  {#if isSingleReview}
    {#each SAMPLE_SINGLE_REVIEW_FIELDS as field}
      <div class={styles.balance}>
        <div>{$t(field.label)}</div>
        <div class={field.key === "item" ? styles["balance-value"] : ""}>
          {$sample[field.key] ?? "-"}
        </div>
      </div>
    {/each}
  {:else}
    {#each SAMPLE_BALANCE_REVIEW_FIELDS as field}
      <div
        class={clsx(
          styles.balance,
          field.key === SAMPLE_REVIEW_TYPE.balance ? styles.headline : "",
        )}
      >
        <div>{$t(field.label)}</div>
        <div class={styles["balance-value"]}>{$sample[field.key] ?? "-"}</div>
      </div>
    {/each}
  {/if}
</div>

<style lang="scss">
  .wrapper {
    display: grid;
    gap: 5px;
  }

  .balance {
    display: flex;
    justify-content: space-between;

    &-value {
      font-family: Courier, sans-serif, serif;
    }
  }

  .headline {
    padding-bottom: 10px;
    font-weight: 600;
  }
</style>
