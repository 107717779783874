<script>
  import { onMount } from "svelte";

  import Icons from "components/icons/Icons.svelte";
  import { AdministrationTeamsModal } from "components/modals";
  import { client, currentTeam, teams } from "stores/client_store";
  import { t } from "stores/i18n.js";
  import TeamsApi from "apis/clients/teams_api.js";

  import { Box, Button, Tag, Tooltip } from "components";
  import styles from "styleguide/AdministrationTeams.json";

  export let client_id;
  export let client_users;

  let isContentOverflowing;
  let showAdministrationTeamsModal = false;

  onMount(async () => {
    client.set({ id: client_id });
    TeamsApi.getAll();
  });

  function checkOverflow(permalink) {
    const contentDiv = document.getElementById(permalink);
    const parentDiv = contentDiv.parentElement;

    if (contentDiv && parentDiv) {
      isContentOverflowing = contentDiv.offsetWidth >= parentDiv.offsetWidth;
    }
  }

  function onCreateNewTeam() {
    showAdministrationTeamsModal = true;

    currentTeam.set({
      name: "",
      description: "",
      client_user_ids: [],
    });
  }

  function onEditTeam(team) {
    showAdministrationTeamsModal = true;

    team.client_user_ids = team.client_users.map((cl) => cl.permalink);
    currentTeam.set(team);
  }
</script>

<div data-component="AdministrationTeams" class={styles.wrapper}>
  <Box>
    <slot name="header">
      <header class={styles.header}>
        <div class={styles["header-top"]}>
          <h3>{$t("administration_teams.title")}</h3>
          <Tag
            label={$t("administration_teams.tag")}
            color={"blue"}
            size="small"
            style="semi-bold"
          />
        </div>
        <div class={styles["header-intro"]}>
          <p>{$t("administration_teams.intro")}</p>
        </div>
        <Button
          style="primary-text"
          click={() => {
            onCreateNewTeam();
          }}
        >
          {$t("administration_teams.create")}
        </Button>
      </header>
      <dic class={styles.button}> </dic>
    </slot>

    {#if $teams.length}
      <table class={styles.table}>
        <thead>
          <tr>
            <th>{$t("administration_teams.name")}</th>
            <th>{$t("administration_teams.members")}</th>
            <th>{$t("administration_teams.description")}</th>
          </tr>
        </thead>
        <tbody>
          {#each $teams as team}
            <tr>
              <td>
                <Tooltip
                  style={"small"}
                  placement="bottom"
                  nowrap={false}
                  message={isContentOverflowing && team.name}
                  maxWidth="600px"
                >
                  <div
                    id={`name_${team.permalink}`}
                    slot="content"
                    class={styles.ellipsis}
                  >
                    <span
                      class={styles.name}
                      tabindex="0"
                      role="button"
                      on:mouseenter={checkOverflow(`name_${team.permalink}`)}
                      on:keydown={onEditTeam(team)}
                      on:click={onEditTeam(team)}
                    >
                      {team.name}
                    </span>
                  </div>
                </Tooltip>
              </td>
              <td>
                <Tooltip placement="bottom">
                  <div slot="content">
                    <Icons
                      icon="userMulti"
                      width="16"
                      height="16"
                      fill={"var(--blue-200)"}
                    />
                  </div>
                  <div slot="tooltip">
                    <div class={styles["tooltip-header"]}>
                      {team.client_users.length}
                      {$t("administration_teams.members")}
                    </div>
                    {#if team.client_users.length > 0}
                      <ul>
                        {#each team.client_users as client_user}
                          <li>{client_user.email}</li>
                        {/each}
                      </ul>
                    {/if}
                  </div>
                </Tooltip>
              </td>
              <td>
                <Tooltip
                  style="small"
                  placement="bottom"
                  nowrap={false}
                  message={isContentOverflowing && team.description}
                >
                  <div
                    id={`description_${team.permalink}`}
                    slot="content"
                    class={styles.ellipsis}
                  >
                    <span
                      role="button"
                      tabindex="0"
                      class={styles.description}
                      on:mouseenter={checkOverflow(
                        `description_${team.permalink}`,
                      )}
                    >
                      {team.description}
                    </span>
                  </div>
                </Tooltip>
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
    {/if}
  </Box>
  {#if showAdministrationTeamsModal}
    <AdministrationTeamsModal
      bind:showAdministrationTeamsModal
      clientUsers={client_users}
    />
  {/if}
</div>

<style scoped lang="scss">
  .wrapper {
    margin-top: 40px;
  }
  .header {
    padding: 1em 1.5em;

    h3 {
      margin: 0;
      color: var(--primary-500);
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
    p {
      margin: 0;
    }
    div {
      display: flex;
      align-items: center;
    }

    &-top {
      gap: 0.5em;
    }

    &-intro {
      padding: 1em 0;
    }
  }

  .name {
    max-width: 480px;
    white-space: nowrap;
    color: var(--blue-200);
    font-weight: 500;
  }

  .description {
    max-width: 380px;
    white-space: nowrap;
  }

  .table th:nth-child(1),
  .table td:nth-child(1) {
    width: 50%;
  }

  .table th:nth-child(2),
  .table td:nth-child(2) {
    width: 112px;
  }

  .table th:nth-child(3),
  .table td:nth-child(3) {
    width: calc(50% - 1px);
  }

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .table {
    margin-bottom: 0;
    border-collapse: collapse;

    th {
      text-transform: capitalize;
    }

    tr th,
    tr td {
      padding-left: 1.5em;
      padding-right: 1.5em;
    }
    tr:last-child td {
      border-bottom: 0;
    }
  }

  .tooltip-header {
    border-bottom: 1px solid var(--primary-050);
    padding-bottom: 8px;
    margin-bottom: 8px;
  }
</style>
