<script>
  import styles from "styleguide/ControlDownloadUnsafeItem.json";
  import { t } from "stores/i18n.js";
  import { activeDownloadUnsafe } from "stores/pbc.js";
  import { fileIcon } from "lib/helpers.js";
  import { Boundary, Checkbox, LegacyButton as Button } from "components";

  export let virus;
  export let report;
  export let checked;
  export let filename;
  export let permalink;
  export let onReport;

  $: icon = filename ? fileIcon(filename) : null;

  function onCheckboxToggle(toggled) {
    activeDownloadUnsafe.update((data) => {
      let newData = { ...data };

      newData.files = data.files.map((file) => {
        if (file.permalink === permalink) {
          file.checked = toggled;
        }

        return file;
      });

      return newData;
    });
  }
</script>

<Boundary>
  <div data-component="ControlDownloadUnsafeItem" class={styles.file}>
    <div class={styles.checkbox}>
      <Checkbox toggle={onCheckboxToggle} toggled={checked} />
    </div>

    <img class={styles.icon} src={icon} alt={icon} />

    <div class={styles.content}>
      <div class={styles.name}>{filename}</div>
      {#if virus}
        <div class={styles.virus}>
          {$t("control_download_unsafe_item.virus")}
        </div>
      {/if}
    </div>

    {#if virus && report && report.preview_url}
      <div class={styles.report}>
        <Button click={onReport.bind(this, permalink)} style="border medium"
          >{$t("control_upload_item.report")}</Button
        >
      </div>
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .file {
    display: flex;
    align-items: center;
    text-align: left;
    margin: 0 -20px;
    padding: 5px 20px;
    height: 50px;

    &:hover {
      background: var(--primary-025);

      .report {
        display: block;
      }
    }
  }

  .content {
    margin-left: 8px;
  }

  .icon {
    margin-left: 16px;
  }

  .name {
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .virus {
    font-size: 12px;
    line-height: 18px;
    color: var(--red-200);
  }

  .report {
    display: none;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);
  }
</style>
