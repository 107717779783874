import { writable } from "svelte/store";

const team_user = writable(null);
const settingsPage = writable(null);
const team_user_createable_roles = writable([]);
const teamUserRole = writable(null);
const scimActive = writable(false);

export {
  team_user,
  settingsPage,
  team_user_createable_roles,
  scimActive,
  teamUserRole,
};
