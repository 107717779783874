<script>
  import styles from "styleguide/ResponderCounterparties.json";

  import { t } from "stores/i18n.js";
  import { sender, recipient, auditor } from "stores/responder.js";

  import { sanitizeStrict } from "lib/sanitize.js";

  function lineBreaker(string) {
    return sanitizeStrict(string).split(",").join("<br>");
  }
</script>

<div data-component="ResponderCounterparties" class={styles.counterparties}>
  <div class={styles.left}>
    <div class={styles.counterpartiesItem}>
      <p class={styles.counterpartiesLabel}>
        {$t("responder_counterparties.sender")}
      </p>
      <p class={styles.counterpartiesName}>{$sender.name}</p>
      <p class={styles.counterpartiesAddress}>
        {@html lineBreaker($sender.address)}
      </p>
    </div>
    <div class={styles.counterpartiesItem}>
      <p class={styles.counterpartiesLabel}>
        {$t("responder_counterparties.recipient")}
      </p>
      <p class={styles.counterpartiesName}>{$recipient.name}</p>
      <p class={styles.counterpartiesAddress}>
        {@html lineBreaker($recipient.address)}
      </p>
    </div>
  </div>
  <div class={styles.right}>
    <div class={styles.counterpartiesItem}>
      <p class={styles.counterpartiesLabel}>
        {$t("responder_counterparties.auditor")}
      </p>
      <p class={styles.counterpartiesName}>{$auditor.name}</p>
      <p class={styles.counterpartiesAddress}>
        {@html lineBreaker($auditor.address)}
      </p>
    </div>
  </div>
</div>

<style lang="scss">
  .counterparties {
    display: flex;
  }

  .counterpartiesItem {
    padding-right: 25px;

    &:nth-child(2) {
      margin-top: 20px;
    }
  }

  .counterpartiesLabel {
    color: var(--primary-300);
    margin: 0;
  }

  .counterpartiesName {
    margin: 0;
    font-weight: 600;
    line-height: 1.57;
  }

  .counterpartiesAddress {
    margin: 5px 0 0;
    line-height: 1.57;
  }

  .left,
  .right {
    margin: -35px 0;
    padding: 35px 0;
  }

  .left {
    width: 50%;
    margin-right: 35px;
    border-right: 1px solid var(--primary-050);
  }
</style>
