import Turbolinks from "turbolinks";

Turbolinks.scroll = {};

document.addEventListener("turbolinks:load", () => {
  const elements = document.querySelectorAll("[data-turbolinks-scroll]");

  elements.forEach((element) => {
    element.addEventListener("click", (e) => {
      Turbolinks.scroll["top"] = document.scrollingElement.scrollTop;
      Turbolinks.scroll["url"] = window.location.href;
    });

    element.addEventListener("submit", (e) => {
      Turbolinks.scroll["top"] = document.scrollingElement.scrollTop;
      Turbolinks.scroll["url"] = window.location.href;
    });
  });

  if (Turbolinks.scroll["top"]) {
    document.scrollingElement.scrollTo(0, Turbolinks.scroll["top"]);
  }

  if (Turbolinks.scroll["url"] == window.location.href) {
    Turbolinks.scroll = {};
  }
});
