import { ContactsApi } from "apps/sample/api";
import { t } from "stores/i18n.js";
import { toasts } from "stores/toasts.js";
import { get } from "svelte/store";

class ContactsService {
  constructor() {}

  resetContacts({ onSuccess, onError, params = {} }) {
    ContactsApi.resetContacts({
      params,
      success: (responce) => {
        toasts.send({
          title: get(t)("sample_edit_modal.reset_data_success"),
          type: "success",
        });
        onSuccess?.(responce.data);
      },
      error: (error) => {
        toasts.send({
          title: get(t)("sample_edit_modal.reset_data_error"),
          type: "error",
        });
        onError?.(error);
      },
    });
  }
}

export default new ContactsService();
