<script>
  import { t } from "stores/i18n.js";
  import { Tag } from "components";

  export let label;
  export let status;
  export let size = "small";
  export let close = null;
  export let count = false;

  function calculateStatusColor(newStatus) {
    if (newStatus === "open") {
      return "blue";
    } else if (newStatus === "pending") {
      return "blue";
    } else if (newStatus === "accepted") {
      return "green";
    } else if (newStatus === "rejected") {
      return "red";
    } else if (newStatus === "overdue") {
      return "red";
    } else if (newStatus === "wfr" || newStatus === "waiting_for_review") {
      return "yellow";
    } else if (newStatus === "this-week") {
      return "yellow";
    } else if (newStatus === "new-comments") {
      return "blue";
    } else if (newStatus === "new-documents") {
      return "blue";
    } else {
      return "grey";
    }
  }

  function calculateLabel($t, label, status) {
    if (label) return label;

    if (status === "by-client:not-assigned") {
      return $t("control_status.not_assigned");
    }

    if (status?.includes("by-client:")) {
      return status.replace("by-client:", "");
    }

    return $t(`control_status.${status}`);
  }

  $: tagLabel = calculateLabel($t, label, status);
  $: statusColor = calculateStatusColor(status && status.toLowerCase());
</script>

<span data-component="ControlStatus">
  <Tag
    {close}
    label={tagLabel}
    color={statusColor}
    {size}
    style={`semi-bold ${count ? "count" : ""}`}
  />
</span>
