<script>
  import { onMount } from "svelte";
  import { Modal } from "components/modals";
  import { t } from "stores/i18n.js";
  import { permissions } from "stores/permission.js";
  import { reviewClientUsers } from "stores/review/review.js";
  import { toasts } from "stores/toasts.js";
  import ReviewClientAccessApi from "apis/review/review_client_access.js";

  import { copyToClickboard } from "lib/helpers.js";
  import { validate } from "lib/validate.js";

  import {
    Boundary,
    Button,
    TextInput,
    Toast,
    Tooltip,
    Switch,
  } from "components";

  import styles from "styleguide/ReviewClientUserModal.json";

  export let reviewId;
  export let editClientAccessPath;

  export let show;
  export let onHide;
  let loading = false;
  let newClientEmail = "";
  $: disableGrantAccessButton =
    !!validate(newClientEmail, ["email"])?.length || !newClientEmail;

  $: sbaLink = `/sba/${reviewId}`;
  $: link = window.location.origin + sbaLink;

  function handleClick() {
    if (disableGrantAccessButton) {
      return;
    }
    ReviewClientAccessApi.grantAccess({
      params: {
        review_id: reviewId,
        email: newClientEmail,
        creditor: false,
        debitor: false,
      },
      success: onGrantAccessSuccess,
      error: onGrantAccessError,
    });
  }

  function onGrantAccessError() {
    toasts.send({
      title: $t("review_client_access_modal.client_user_granted_error"),
      type: "error",
    });
  }

  function onGrantAccessSuccess() {
    getClients();
    toasts.send({
      title: $t("review_client_access_modal.client_user_granted_success"),
      type: "success",
    });
    newClientEmail = "";
  }

  function onCopy() {
    copyToClickboard(link);
  }

  onMount(() => {
    getClients();
  });

  const getClients = () => {
    ReviewClientAccessApi.getClients({
      params: {
        review_id: reviewId,
      },
      success: onGetClientsSuccess,
      error: onGetClientsError,
    });
  };

  const onGetClientsSuccess = (result) => {
    reviewClientUsers.set(result?.data?.review_client_users ?? []);
    loading = false;
  };

  const onGetClientsError = (error) => {
    loading = false;
    toasts.send({
      title: $t("review_client_access_modal.cannot_get_data"),
      type: "error",
    });
  };

  function onUpdateClient(client) {
    loading = true;
    ReviewClientAccessApi.updateClientAccess({
      params: client,
      success: () => {
        getClients();
      },
    });
  }

  function handleClientSettingsClick() {
    window.location = editClientAccessPath;
  }
</script>

<Boundary>
  <div data-component="ReviewClientUserModal">
    <Modal
      shown={show}
      {onHide}
      isWide
      title={$t("review_client_access_modal.title")}
    >
      {#if $permissions["client"]["update"] && $permissions["client_user"]["update"]}
        <Toast
          message={$t("review_client_access_modal.info")}
          action={{
            style: "primary-text",
            click: handleClientSettingsClick,
            button: $t("review_client_access_modal.client_settings"),
          }}
        />
      {/if}
      <div class={styles.connection}>
        <div>
          <div class={styles["connection-name"]}>
            {$t("review_client_access_modal.internet_address")}
          </div>
          <div class={styles["connection-link"]}>{link}</div>
        </div>

        <div class={styles.buttons}>
          <div class={styles.button}>
            <Button style={"primary-tonal"} click={onCopy}>
              {$t("review_client_access_modal.copy_link")}
            </Button>
          </div>
        </div>
      </div>

      <div class={styles.addresses}>
        <div class={styles["addresses-item"]}>
          <strong>
            {$t("review_client_access_modal.email_address")}
          </strong>
        </div>
        <div class={styles["addresses-actions"]}>
          <div class={styles["delegate-title"]}>
            <strong>
              {$t("review_client_access_modal.access_to_debtors")}
            </strong>
          </div>
        </div>
        <div class={styles["addresses-actions"]}>
          <div class={styles["delegate-title"]}>
            <strong>
              {$t("review_client_access_modal.access_to_creditors")}
            </strong>
          </div>
        </div>
      </div>
      {#each $reviewClientUsers as { creditor, debitor, client_user } (client_user.email)}
        <div class={styles.addresses}>
          <div class={styles["addresses-item"]}>
            <Tooltip showArrow message={client_user.email} overflowingShow>
              {client_user.email}
            </Tooltip>
          </div>

          <div class={styles["addresses-actions"]}>
            <Switch
              bgColor="signal-green-body"
              toggled={debitor}
              toggle={() =>
                onUpdateClient({
                  review_id: reviewId,
                  id: client_user.permalink,
                  debitor: !debitor,
                })}
              disabled={loading}
            />
          </div>
          <div class={styles["addresses-actions"]}>
            <Switch
              bgColor="signal-green-body"
              toggled={creditor}
              toggle={() =>
                onUpdateClient({
                  review_id: reviewId,
                  id: client_user.permalink,
                  creditor: !creditor,
                })}
              disabled={loading}
            />
          </div>
        </div>
      {:else}
        <div class={styles.empty}>
          {$t("review_client_access_modal.no_client_users_added")}
        </div>
      {/each}
      <div class={styles["footer"]}>
        <TextInput
          bind:value={newClientEmail}
          placeholder={$t("review_client_access_modal.place_holder")}
          footnotes={[$t("review_client_access_modal.footnote")]}
        />
        <Button
          click={handleClick}
          disabled={disableGrantAccessButton}
          style={"primary"}
        >
          {$t("review_client_access_modal.add_user")}
        </Button>
      </div>
    </Modal>
  </div>
</Boundary>

<style lang="scss">
  .connection {
    margin-bottom: 16px;
  }

  .connection-name {
    font-weight: 600;
  }

  .connection-link {
    font-weight: 500;
    color: var(--primary-500);
  }

  .buttons {
    display: flex;
    margin-top: 16px;
  }

  .button {
    margin-right: 10px;
  }

  .addresses {
    display: grid;
    grid-template-columns: 1fr 172px 172px;
    gap: 24px;
    padding: 12px 0;
    border-bottom: 1px solid var(--primary-020, #d3d9e3);

    &:last-child {
      .addresses-item {
        padding-bottom: 0;
      }
    }
  }

  strong {
    font-weight: 600;
  }

  .addresses-item {
    flex: 1;
    display: flex;
    gap: 8px;
    max-width: 325px;
    overflow: hidden;
  }

  .addresses-actions {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }

  .delegate-title {
    display: flex;
    gap: 8px;
    width: 150px;
  }

  .empty {
    padding: 20px 20px 10px;
    text-align: center;
  }

  .footer {
    gap: 16px;
    display: flex;
    width: 100%;
    margin: 20px 0 8px;
  }
</style>
