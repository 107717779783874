import { Controller } from "stimulus";
import { t } from "stores/i18n.js";
import { get } from "svelte/store";

import Api from "../../general/api";
import Helper from "../../general/helper";

export default class extends Controller {
  static targets = [
    "formInput",
    "form",
    "rowHeader",
    "company",
    "street",
    "zip",
    "city",
    "country",
    "email",
    "contactName",
    "language",
  ];

  connect() {
    this.expanded = false;
  }

  fillForm(e) {
    e.preventDefault();
    this.formInputTargets.forEach((el, i) => {
      el.value = el.getAttribute("data-new-value");
    });
  }

  submit(e) {
    e.preventDefault();

    let params = {
        sample_contact: {
          company: this.companyTarget.value,
          street: this.streetTarget.value,
          zip: this.zipTarget.value,
          city: this.cityTarget.value,
          country: this.countryTarget.value,
          email: this.emailTarget.value,
          contact_name: this.contactNameTarget.value,
          language: this.languageTarget.value,
        },
      },
      manageMasterData = e.target.dataset.manageMasterData;

    if (manageMasterData) {
      this.eventTarget = e.target;
      params["sample_contact"]["manage_master_data"] = true;
      Api.patch(
        this.formTarget.getAttribute("action"),
        params,
        this.manageMasterDataCallback.bind(this),
      );
    } else {
      Api.patch(
        this.formTarget.getAttribute("action"),
        params,
        this.updateContactResponse.bind(this),
      );
    }
  }

  manageMasterDataCallback(response) {
    this.eventTarget.classList.remove("loading");
    this.eventTarget.value = get(t)(
      "confirmation.master_data.updated_master_data",
    );
    this.eventTarget.removeAttribute("style");
  }

  updateContactResponse(response) {
    let self = this;
    if (response["missingFields"].length > 0) {
      this.unloadSubmitButton();
      this.removePreviousErrors();
      response["missingFields"].forEach((field) => {
        if (self[`has${Helper.titleize(field)}Target`]) {
          self[`${field}Target`].closest(".field").classList.add("error");
        }
      });
    } else {
      if (document.querySelectorAll(".contact-address-item").length === 1) {
        document.location.reload(); // Reload page when no items left to complete
      } else {
        this.element.remove();
      }
    }
  }

  unloadSubmitButton() {
    let submitButton = this.formTarget.querySelector(`input[type="submit"]`);
    submitButton.classList.remove("loading");
    submitButton.value = submitButton.getAttribute("data-disable-with");
  }

  removePreviousErrors() {
    this.formInputTargets.forEach((el) => {
      el.closest(".field").classList.remove("error");
    });
  }

  verify(e) {
    e.preventDefault();
    this.eventTarget = e.target;
    let url = this.eventTarget.dataset.url,
      verified = this.eventTarget.dataset.verified;
    Api.post(
      url,
      { address_validated: verified },
      this.verifyCallback.bind(this),
    );
  }

  verifyCallback(response) {
    if (response.address_validated) {
      this.eventTarget.classList.add("verified");
    } else {
      this.eventTarget.classList.remove("verified");
    }
    this.eventTarget.dataset.verified = !response.address_validated;
  }

  verifyAll(e) {
    e.preventDefault();
    let url = e.target.dataset.url,
      samples = Array.from(document.querySelectorAll("form.contact-form")).map(
        (form) => {
          return form.dataset.sampleId;
        },
      );
    Api.post(url, { samples: samples }, this.verifyAllCallback.bind(this), {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
  }

  verifyAllCallback() {
    document
      .querySelectorAll(".contact-address-item--header .btn")
      .forEach(function (btn) {
        if (!btn.classList.contains("verified")) {
          btn.classList.add("verified");
        }

        btn.dataset.verified = false;
      });
  }
}
