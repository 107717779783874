<script>
  import styles from "styleguide/HistoryList.json";
  import { getContext } from "svelte";
  import { t } from "stores/i18n.js";
  import { pageHistory } from "stores/page_history.js";

  const config = getContext("config");

  const goToPage = (path, idx) => () => {
    if (!idx) return;

    window.location.href = path;
  };
</script>

<div class={styles["history-list"]}>
  {#each $pageHistory as { section, titles, time, path }, idx}
    <div class={styles["history-list-item"]}>
      <div class={styles["history-list-link"]} on:click={goToPage(path, idx)}>
        <div>
          {#each titles as { name, translation }}
            <div class={styles["history-list-title"]}>
              {#if name}{name}{/if}

              {#if translation}{$t(`historyList.${translation}`)}{/if}
            </div>
          {/each}
        </div>
      </div>
    </div>
  {/each}
</div>

<style lang="scss">
  .history-list {
    overflow-x: hidden;
    &-item {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        width: calc(100% + 16px);
        left: -8px;
        bottom: 0;
        height: 1px;
        background-color: var(--primary-025);
      }

      &:first-of-type {
        display: none;
      }
    }

    &-link {
      color: var(--primary-300);
      padding: 12px 24px;
      cursor: pointer;

      &:hover {
        background-color: var(--blue-010);
      }

      &:focus,
      &:active {
        background-color: var(--blue-025);
      }
    }

    &-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:last-of-type {
        color: var(--blue-200);
        font-weight: 500;
      }
    }
  }
</style>
