<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(100%,100%,100%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(84.313725%,15.686275%,15.686275%);fill-opacity:1;"
      d="M -39.742188 -58.378906 L 124.296875 -58.378906 L 124.296875 148.816406 L -39.742188 148.816406 Z M -39.742188 -58.378906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(84.313725%,15.686275%,15.686275%);fill-opacity:1;"
      d="M 217.066406 -0.429688 L 502.746094 -0.429688 L 502.746094 148.820312 L 217.066406 148.820312 Z M 217.066406 -0.429688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(84.313725%,15.686275%,15.686275%);fill-opacity:1;"
      d="M -20.660156 240.007812 L 122.089844 240.007812 L 122.089844 382.753906 L -20.660156 382.753906 Z M -20.660156 240.007812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(84.313725%,15.686275%,15.686275%);fill-opacity:1;"
      d="M 219 241.589844 L 502.753906 241.589844 L 502.753906 382.746094 L 219 382.746094 Z M 219 241.589844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,21.960784%,59.215686%);fill-opacity:1;"
      d="M 147.488281 -19.085938 L 195.804688 -19.085938 L 195.804688 382.75 L 147.488281 382.75 Z M 147.488281 -19.085938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,21.960784%,59.215686%);fill-opacity:1;"
      d="M -20.660156 168.628906 L 502.75 168.628906 L 502.75 216.214844 L -20.660156 216.214844 Z M -20.660156 168.628906 "
    />
  </g>
</svg>
