<script>
  import { iconPath } from "lib/helpers.js";
  import styles from "styleguide/IconButton.json";

  export let style = "";
  export let icon;
  export let click;
  export let close = false;
  export let disabled = false;

  $: calculatedStyles = function () {
    let s = style.split(" ");
    if (icon !== null) {
      s.push("icon");
    }
    if (close) {
      s.push("close");
    }
    if (disabled) {
      s.push("disabled");
    }

    const res = s.map((x) => styles[x]).join(" ");
    return res;
  };

  function clickHandler(event) {
    if (!disabled && click) {
      click?.(event);
      event.target.blur();
    }
    event.target.blur();
  }
</script>

<button
  data-component="IconButton"
  {disabled}
  class={calculatedStyles()}
  on:click={clickHandler}
>
  {#if close}
    <img class={styles.close} src={iconPath("blue_close")} alt={"close"} />
  {:else}
    <img src={iconPath(icon)} alt="" />
  {/if}

  <slot />
</button>

<style scoped lang="scss">
  button,
  button:hover,
  button:focus {
    all: unset;
    position: relative;
    box-sizing: border-box;
    font-family: var(--default-font);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 40px;
    padding: 0 10px;
    background: transparent;
    color: var(--blue-200);
    font-weight: 500;
    line-height: 20px;
    border-radius: var(--border-radius);
    border: 2px solid transparent;
    display: flex;
    gap: 8px;
  }

  button:focus:not(:active) {
    outline-offset: 2px;
    outline: 2px solid var(--brand-100);
  }

  .primary {
    background: var(--brand-100);
    color: #fff;

    &:hover {
      background: var(--brand-200);
      color: #fff;
    }
    &:focus:not(:active) {
      background: var(--brand-100);
      color: var(--white);
    }

    &:active {
      background: var(--brand-300);
      color: #fff;
    }

    &.disabled {
      background: var(--primary-025);
      color: var(--primary-200);
    }
  }

  .primary-tonal {
    background: var(--brand-010);
    color: var(--brand-200);

    &:hover {
      background: var(--brand-025);
      color: var(--brand-200);
    }

    &:focus:not(:active) {
      background: var(--brand-010);
      color: var(--brand-200);
    }

    &:active {
      background: var(--brand-050);
      color: var(--brand-200);
    }

    &.disabled {
      background: var(--primary-025);
      color: var(--primary-200);
    }
  }

  .primary-text {
    background: transparent;
    color: var(--blue-200);

    &:hover {
      background: var(--blue-010);
      color: var(--blue-200);
    }

    &:focus:not(:active) {
      background: transparent;
      color: var(--blue-200);
    }

    &:active {
      background: var(--blue-025);
      color: var(--blue-200);
    }

    &.disabled {
      background: transparent;
      color: var(--primary-100);
    }
  }

  .error {
    background: var(--red-100);
    color: #fff;

    &:hover {
      background: var(--red-200);
      color: #fff;
    }

    &:focus:not(:active) {
      background: var(--red-100);
      color: #fff;
    }

    &:active {
      background: var(--red-300);
      color: #fff;
    }

    &.disabled {
      background: var(--primary-025);
      color: var(--primary-200);
    }
  }

  .error-tonal {
    background: var(--red-010);
    color: var(--red-200);

    &:hover {
      background: var(--red-025);
      color: var(--red-200);
    }

    &:focus:not(:active) {
      background: var(--red-010);
      color: var(--red-200);
    }

    &:active {
      background: var(--red-050);
      color: var(--red-200);
    }

    &.disabled {
      background: var(--primary-025);
      color: var(--primary-200);
    }
  }

  .error-text {
    background: transparent;
    color: var(--red-200);

    &:hover {
      background: var(--red-010);
      color: var(--red-200);
    }

    &:focus:not(:active) {
      background: transparent;
      color: var(--red-200);
    }

    &:active {
      background: var(--red-025);
      color: var(--red-200);
    }

    &.disabled {
      background: transparent;
      color: var(--primary-100);
    }
  }

  .small,
  .small:hover,
  .small:focus {
    height: 30px;
    width: 30px;
  }

  .disabled,
  .disabled:hover,
  .disabled:focus {
    img {
      filter: saturate(0.5) !important;
      opacity: 0.75;
    }
  }

  // Same Style as Error-Text icon size chanend to 24px
  .close,
  .close:focus,
  .close:hover {
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;

    background: transparent;
    color: var(--blue-200);

    &:hover {
      background: var(--blue-010);
      color: var(--blue-200);
    }

    &:focus:not(:active) {
      background: transparent;
      color: var(--blue-200);
    }

    &:active {
      background: var(--blue-025);
      color: var(--blue-200);
    }

    img {
      height: 24px;
      width: 24px;
    }
  }
</style>
