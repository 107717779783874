import Api from "./attachments.js";

class AttachmentUploadHandler {
  constructor() {
    this.onFileUpload = () => {};
    this.onVirusesScanSuccess = () => {};
    this.mutexPool = {};
    this.parent_id = null;
    this.parent_type = null;
    this.intervalTime = 1000;
  }

  create(params, id, releaseFunc) {
    let self = this,
      parent_params = {
        parent_id: this.parent_id,
        parent_type: this.parent_type,
      };
    Api.create({
      params: { ...params, ...parent_params },
      success: (attachments) => {
        self.uploadResponse(attachments, id, releaseFunc);
      },
    });
  }

  virusesScanResultPolling(permalink, release) {
    let params = {
      id: permalink,
      parent_id: this.parent_id,
      parent_type: this.parent_type,
    };

    let success = (response) => {
      if (response.finished) {
        this.intervalTime = 1000;
        this.onVirusesScanSuccess(permalink, response.virus);
        release();
      } else {
        this.intervalTime = 2 * this.intervalTime;
        this.makePolling({ params, success, error }, this.intervalTime);
      }
    };

    let error = () => {
      self.intervalTime = 1000;
    };

    this.makePolling({ params, success, error }, this.intervalTime);
  }

  makePolling(options, timeout) {
    setTimeout(() => {
      Api.virusesScanResult(options);
    }, timeout);
  }

  uploadResponse(attachments, id, release) {
    let self = this;
    release();

    attachments.forEach((item) => {
      item.virus_scanning = true;
      self.onFileUpload(item, id);
      self.sendToVirusCheck(item.permalink);
    });
  }

  sendToVirusCheck(id, pickMutexFunc) {
    let self = this;

    this.mutexPool
      .pickMutex("virus")
      .acquire()
      .then((releaseFunc) => {
        self.virusesScanResultPolling(id, releaseFunc);
      });
  }
}

export default new AttachmentUploadHandler();
