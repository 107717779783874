<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(100%,100%,100%);fill-opacity:1;stroke:none;"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(80.784314%,0%,0%);fill-opacity:1;stroke-width:13.26;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,38.823529%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 6.270412 489.831927 L 369.373258 489.821276 L 131.712428 248.810711 L 378.320088 249.098287 L 6.291714 11.480061 Z M 6.270412 489.831927 "
      transform="matrix(0.7335,0,0,0.7335,-0.00169109,-3.862031)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 127.082031 261.394531 L 108.105469 267.675781 L 122.949219 281.644531 L 102.953125 280.359375 L 111.191406 298.800781 L 93.226562 290.160156 L 93.597656 310.269531 L 80.386719 295.574219 L 72.835938 314.296875 L 66.402344 295.792969 L 52.082031 310.269531 L 53.390625 290.769531 L 34.488281 298.800781 L 43.347656 281.28125 L 22.722656 281.644531 L 37.792969 268.757812 L 18.601562 261.394531 L 37.574219 255.121094 L 22.722656 241.15625 L 42.71875 242.429688 L 34.488281 223.996094 L 52.453125 232.636719 L 52.082031 212.519531 L 65.292969 227.214844 L 72.835938 208.5 L 79.277344 227.003906 L 93.597656 212.519531 L 92.28125 232.019531 L 111.191406 223.996094 L 102.332031 241.515625 L 122.949219 241.15625 L 107.878906 254.039062 Z M 127.082031 261.394531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 104.289062 131.542969 L 96.320312 137.4375 L 100.246094 140.683594 C 109.78125 133.3125 116.566406 126.578125 121.871094 115.398438 C 123.113281 130.320312 109.4375 163.8125 73.542969 164.183594 C 35.128906 164.152344 21.800781 128.613281 23.285156 114.769531 C 30.339844 127.574219 34.648438 133.816406 45.726562 140.480469 L 49.113281 137.371094 L 41.609375 131.121094 L 51.269531 128.609375 L 46.074219 119.863281 L 56.191406 120.597656 L 54.921875 110.414062 L 63.792969 115.605469 L 66.566406 106.125 L 72.941406 113.761719 L 78.910156 106.519531 L 82.164062 116.359375 L 90.453125 110.589844 L 89.390625 120.59375 L 99.328125 119.425781 L 94.667969 128.675781 Z M 104.289062 131.542969 "
    />
  </g>
</svg>
