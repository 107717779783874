import { letterhead } from "stores/confirmation/confirmation.js";
import { getConfirmation } from "stores/confirmation/confirmation.js";
import { toasts } from "stores/toasts.js";
import Api from "./confirmation";

class LetterheadUploadHandler {
  constructor() {
    this.id = null;
    this.loadingStateFunc = null;
    this.successMessage = null;
    this.errorMessage = null;
  }

  create(params) {
    this.loadingStateFunc(true);
    Api.uploadLetterhead({
      params: { id: this.id, letterhead: params.files[0] },
      success: (response) => {
        this.loadingStateFunc(false);
        letterhead.set(response.data.letterhead);
        toasts.send({ title: this.successMessage, type: "success" });
        getConfirmation();
      },
      error: () => {
        this.loadingStateFunc(false);
        toasts.send({ title: this.errorMessage, type: "error" });
      },
    });
  }
}

export default new LetterheadUploadHandler();
