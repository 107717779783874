<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(89.411765%,6.27451%,30.196078%);fill-opacity:1;"
      d="M 0 179.871094 L 540.382812 179.871094 L 540.382812 359.996094 L 0 359.996094 Z M 0 179.871094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(54.117647%,52.156863%,100%);fill-opacity:1;"
      d="M 0 0 L 540.382812 0 L 540.382812 179.875 L 0 179.875 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 112.296875 125.691406 L 174.0625 125.457031 C 174.0625 125.457031 196.871094 98.050781 184.796875 80.09375 C 172.71875 62.136719 125.375 79.527344 125.183594 79.527344 C 124.992188 79.527344 124.800781 69.886719 113.109375 69.699219 C 101.417969 69.886719 101.226562 79.527344 101.035156 79.527344 C 100.84375 79.527344 53.496094 62.136719 41.425781 80.09375 C 29.347656 98.050781 52.15625 125.457031 52.15625 125.457031 L 112.300781 125.691406 Z M 112.296875 125.691406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 112.328125 125.6875 L 171.625 125.460938 C 171.625 125.460938 193.523438 98.835938 181.929688 81.394531 C 170.335938 63.949219 124.886719 80.84375 124.703125 80.84375 C 124.519531 80.84375 124.335938 71.476562 113.109375 71.292969 C 101.886719 71.476562 101.699219 80.84375 101.515625 80.84375 C 101.332031 80.84375 55.882812 63.949219 44.292969 81.394531 C 32.699219 98.835938 54.597656 125.460938 54.597656 125.460938 L 112.332031 125.6875 Z M 112.328125 125.6875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 181.265625 88.4375 L 180.5625 88.4375 L 180.5625 96.871094 L 181.265625 96.871094 Z M 181.265625 88.4375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 178.710938 83.863281 L 178.007812 83.863281 L 178.007812 101.199219 L 178.710938 101.199219 Z M 178.710938 83.863281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 176.269531 81.492188 L 175.445312 81.492188 L 175.445312 104.296875 L 176.269531 104.296875 Z M 176.269531 81.492188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 173.140625 79.828125 L 172.246094 79.828125 L 172.246094 107.101562 L 173.140625 107.101562 Z M 173.140625 79.828125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 169.59375 78.851562 L 168.699219 78.851562 L 168.699219 109.417969 L 169.59375 109.417969 Z M 169.59375 78.851562 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 165.96875 77.664062 L 165.082031 77.664062 L 165.082031 110.972656 L 165.96875 110.972656 Z M 165.96875 77.664062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 162.890625 77.40625 L 162 77.40625 L 162 112.121094 L 162.890625 112.121094 Z M 162.890625 77.40625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 159.800781 77.40625 L 158.910156 77.40625 L 158.910156 113.097656 L 159.800781 113.097656 Z M 159.800781 77.40625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 155.9375 77.484375 L 155.113281 77.484375 L 155.113281 114.710938 L 155.9375 114.710938 Z M 155.9375 77.484375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 152.3125 77.84375 L 151.296875 77.84375 L 151.296875 115.089844 L 152.3125 115.089844 Z M 152.3125 77.84375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 148.347656 78.757812 L 147.332031 78.757812 L 147.332031 115.453125 L 148.347656 115.453125 Z M 148.347656 78.757812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 144.46875 80.179688 L 143.515625 80.179688 L 143.515625 115.714844 L 144.46875 115.714844 Z M 144.46875 80.179688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 140.414062 81.808594 L 139.519531 81.808594 L 139.519531 115.453125 L 140.414062 115.453125 Z M 140.414062 81.808594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 137.054688 82.90625 L 136.164062 82.90625 L 136.164062 114.292969 L 137.054688 114.292969 Z M 137.054688 82.90625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 133.484375 85.027344 L 132.71875 85.027344 L 132.71875 113.335938 L 133.484375 113.335938 Z M 133.484375 85.027344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 130.355469 87.777344 L 129.714844 87.777344 L 129.714844 111.964844 L 130.355469 111.964844 Z M 130.355469 87.777344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 127.546875 90.460938 L 126.78125 90.460938 L 126.78125 110.136719 L 127.546875 110.136719 Z M 127.546875 90.460938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 124.796875 94.570312 L 124.113281 94.570312 L 124.113281 107.132812 L 124.796875 107.132812 Z M 124.796875 94.570312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 44.59375 87.597656 L 45.296875 87.597656 L 45.296875 96.027344 L 44.59375 96.027344 Z M 44.59375 87.597656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 47.148438 83.019531 L 47.851562 83.019531 L 47.851562 100.355469 L 47.148438 100.355469 Z M 47.148438 83.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 49.589844 80.648438 L 50.414062 80.648438 L 50.414062 103.453125 L 49.589844 103.453125 Z M 49.589844 80.648438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 52.71875 78.984375 L 53.613281 78.984375 L 53.613281 106.257812 L 52.71875 106.257812 Z M 52.71875 78.984375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 56.265625 78.007812 L 57.160156 78.007812 L 57.160156 108.574219 L 56.265625 108.574219 Z M 56.265625 78.007812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 59.890625 76.824219 L 60.777344 76.824219 L 60.777344 110.128906 L 59.890625 110.128906 Z M 59.890625 76.824219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 62.96875 76.566406 L 63.859375 76.566406 L 63.859375 111.277344 L 62.96875 111.277344 Z M 62.96875 76.566406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 66.054688 76.566406 L 66.949219 76.566406 L 66.949219 112.253906 L 66.054688 112.253906 Z M 66.054688 76.566406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 69.921875 76.640625 L 70.746094 76.640625 L 70.746094 113.867188 L 69.921875 113.867188 Z M 69.921875 76.640625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 73.546875 77 L 74.5625 77 L 74.5625 114.246094 L 73.546875 114.246094 Z M 73.546875 77 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 77.511719 77.914062 L 78.527344 77.914062 L 78.527344 114.609375 L 77.511719 114.609375 Z M 77.511719 77.914062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 81.390625 79.339844 L 82.34375 79.339844 L 82.34375 114.871094 L 81.390625 114.871094 Z M 81.390625 79.339844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 85.445312 80.964844 L 86.339844 80.964844 L 86.339844 114.609375 L 85.445312 114.609375 Z M 85.445312 80.964844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 88.804688 82.0625 L 89.695312 82.0625 L 89.695312 113.453125 L 88.804688 113.453125 Z M 88.804688 82.0625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 92.375 84.1875 L 93.140625 84.1875 L 93.140625 112.492188 L 92.375 112.492188 Z M 92.375 84.1875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 95.503906 86.933594 L 96.144531 86.933594 L 96.144531 111.121094 L 95.503906 111.121094 Z M 95.503906 86.933594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 98.3125 89.617188 L 99.078125 89.617188 L 99.078125 109.292969 L 98.3125 109.292969 Z M 98.3125 89.617188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 101.058594 93.730469 L 101.746094 93.730469 L 101.746094 106.289062 L 101.058594 106.289062 Z M 101.058594 93.730469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 112.054688 49.332031 L 111.972656 48.058594 C 111.972656 47.800781 110.183594 46.355469 110.183594 42.703125 C 110.183594 39.046875 111.730469 37.601562 112.542969 37.601562 C 113.359375 37.601562 115.148438 38.792969 115.230469 42.617188 C 115.3125 46.441406 113.4375 47.886719 113.4375 47.886719 L 113.277344 49.417969 C 113.277344 49.417969 114.578125 49.503906 114.660156 49.503906 C 114.742188 49.503906 116.453125 47.972656 120.847656 48.140625 C 125.246094 48.3125 125.488281 50.183594 125.570312 50.777344 C 125.652344 51.371094 124.917969 53.15625 120.523438 53.15625 C 116.125 53.15625 114.824219 51.03125 114.742188 51.03125 C 114.660156 51.03125 113.359375 50.945312 113.359375 50.945312 L 113.359375 52.050781 C 113.359375 52.050781 115.066406 53.414062 114.984375 57.917969 C 114.90625 62.421875 113.195312 62.679688 112.707031 62.679688 C 112.054688 62.679688 110.671875 62.421875 110.589844 58.003906 C 110.753906 53.496094 111.890625 52.222656 111.890625 52.136719 L 111.890625 51.03125 L 110.671875 51.03125 C 110.671875 51.03125 109.367188 52.136719 104.890625 52.222656 C 100.410156 52.308594 100.167969 50.945312 100.085938 50.183594 C 100.003906 49.417969 100.898438 48.058594 104.808594 47.972656 C 108.714844 47.886719 110.589844 49.332031 110.589844 49.332031 Z M 112.054688 49.332031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 99.046875 72.933594 C 100.414062 72.933594 101.519531 74.113281 101.519531 75.566406 C 101.519531 77.019531 100.414062 78.195312 99.046875 78.195312 C 97.679688 78.195312 96.570312 77.019531 96.570312 75.566406 C 96.570312 74.113281 97.679688 72.933594 99.046875 72.933594 Z M 99.046875 72.933594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 94.050781 71.867188 C 95.414062 71.867188 96.523438 73.046875 96.523438 74.5 C 96.523438 75.953125 95.414062 77.128906 94.050781 77.128906 C 92.683594 77.128906 91.574219 75.953125 91.574219 74.5 C 91.574219 73.046875 92.683594 71.867188 94.050781 71.867188 Z M 94.050781 71.867188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 88.800781 70.277344 C 90.167969 70.277344 91.277344 71.453125 91.277344 72.90625 C 91.277344 74.359375 90.167969 75.539062 88.800781 75.539062 C 87.433594 75.539062 86.328125 74.359375 86.328125 72.90625 C 86.328125 71.453125 87.433594 70.277344 88.800781 70.277344 Z M 88.800781 70.277344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 83.550781 68.945312 C 84.917969 68.945312 86.023438 70.125 86.023438 71.578125 C 86.023438 73.03125 84.917969 74.210938 83.550781 74.210938 C 82.183594 74.210938 81.074219 73.03125 81.074219 71.578125 C 81.074219 70.125 82.183594 68.945312 83.550781 68.945312 Z M 83.550781 68.945312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 78.050781 67.882812 C 79.417969 67.882812 80.527344 69.0625 80.527344 70.515625 C 80.527344 71.96875 79.417969 73.144531 78.050781 73.144531 C 76.6875 73.144531 75.578125 71.96875 75.578125 70.515625 C 75.578125 69.0625 76.6875 67.882812 78.050781 67.882812 Z M 78.050781 67.882812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 72.554688 67.085938 C 73.921875 67.085938 75.027344 68.265625 75.027344 69.71875 C 75.027344 71.171875 73.921875 72.347656 72.554688 72.347656 C 71.1875 72.347656 70.078125 71.171875 70.078125 69.71875 C 70.078125 68.265625 71.1875 67.085938 72.554688 67.085938 Z M 72.554688 67.085938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 67.308594 66.554688 C 68.671875 66.554688 69.78125 67.730469 69.78125 69.1875 C 69.78125 70.640625 68.671875 71.816406 67.308594 71.816406 C 65.941406 71.816406 64.832031 70.640625 64.832031 69.1875 C 64.832031 67.730469 65.941406 66.554688 67.308594 66.554688 Z M 67.308594 66.554688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 61.804688 66.289062 C 63.171875 66.289062 64.277344 67.464844 64.277344 68.917969 C 64.277344 70.371094 63.171875 71.550781 61.804688 71.550781 C 60.4375 71.550781 59.332031 70.371094 59.332031 68.917969 C 59.332031 67.464844 60.4375 66.289062 61.804688 66.289062 Z M 61.804688 66.289062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 56.558594 67.085938 C 57.925781 67.085938 59.03125 68.265625 59.03125 69.71875 C 59.03125 71.171875 57.925781 72.347656 56.558594 72.347656 C 55.191406 72.347656 54.082031 71.171875 54.082031 69.71875 C 54.082031 68.265625 55.191406 67.085938 56.558594 67.085938 Z M 56.558594 67.085938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 51.058594 67.882812 C 52.425781 67.882812 53.535156 69.0625 53.535156 70.515625 C 53.535156 71.96875 52.425781 73.144531 51.058594 73.144531 C 49.691406 73.144531 48.585938 71.96875 48.585938 70.515625 C 48.585938 69.0625 49.691406 67.882812 51.058594 67.882812 Z M 51.058594 67.882812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 46.058594 69.742188 C 47.425781 69.742188 48.535156 70.921875 48.535156 72.375 C 48.535156 73.828125 47.425781 75.007812 46.058594 75.007812 C 44.695312 75.007812 43.585938 73.828125 43.585938 72.375 C 43.585938 70.921875 44.695312 69.742188 46.058594 69.742188 Z M 46.058594 69.742188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 41.3125 73.199219 C 42.679688 73.199219 43.785156 74.378906 43.785156 75.832031 C 43.785156 77.285156 42.679688 78.460938 41.3125 78.460938 C 39.945312 78.460938 38.835938 77.285156 38.835938 75.832031 C 38.835938 74.378906 39.945312 73.199219 41.3125 73.199219 Z M 41.3125 73.199219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 38.0625 77.984375 C 39.429688 77.984375 40.535156 79.164062 40.535156 80.617188 C 40.535156 82.070312 39.429688 83.25 38.0625 83.25 C 36.695312 83.25 35.589844 82.070312 35.589844 80.617188 C 35.589844 79.164062 36.695312 77.984375 38.0625 77.984375 Z M 38.0625 77.984375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 36.0625 83.035156 C 37.429688 83.035156 38.539062 84.210938 38.539062 85.664062 C 38.539062 87.117188 37.429688 88.296875 36.0625 88.296875 C 34.695312 88.296875 33.589844 87.117188 33.589844 85.664062 C 33.589844 84.210938 34.695312 83.035156 36.0625 83.035156 Z M 36.0625 83.035156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 35.3125 88.613281 C 36.679688 88.613281 37.789062 89.792969 37.789062 91.246094 C 37.789062 92.699219 36.679688 93.875 35.3125 93.875 C 33.945312 93.875 32.839844 92.699219 32.839844 91.246094 C 32.839844 89.792969 33.945312 88.613281 35.3125 88.613281 Z M 35.3125 88.613281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 35.5625 94.464844 C 36.929688 94.464844 38.039062 95.644531 38.039062 97.097656 C 38.039062 98.550781 36.929688 99.726562 35.5625 99.726562 C 34.195312 99.726562 33.089844 98.550781 33.089844 97.097656 C 33.089844 95.644531 34.195312 94.464844 35.5625 94.464844 Z M 35.5625 94.464844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 36.8125 100.046875 C 38.179688 100.046875 39.289062 101.222656 39.289062 102.675781 C 39.289062 104.128906 38.179688 105.308594 36.8125 105.308594 C 35.445312 105.308594 34.339844 104.128906 34.339844 102.675781 C 34.339844 101.222656 35.445312 100.046875 36.8125 100.046875 Z M 36.8125 100.046875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 38.8125 105.625 C 40.179688 105.625 41.285156 106.804688 41.285156 108.257812 C 41.285156 109.710938 40.179688 110.890625 38.8125 110.890625 C 37.445312 110.890625 36.339844 109.710938 36.339844 108.257812 C 36.339844 106.804688 37.445312 105.625 38.8125 105.625 Z M 38.8125 105.625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 41.0625 110.414062 C 42.429688 110.414062 43.535156 111.589844 43.535156 113.042969 C 43.535156 114.496094 42.429688 115.675781 41.0625 115.675781 C 39.695312 115.675781 38.585938 114.496094 38.585938 113.042969 C 38.585938 111.589844 39.695312 110.414062 41.0625 110.414062 Z M 41.0625 110.414062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 127.292969 72.933594 C 125.925781 72.933594 124.816406 74.113281 124.816406 75.566406 C 124.816406 77.019531 125.925781 78.195312 127.292969 78.195312 C 128.65625 78.195312 129.765625 77.019531 129.765625 75.566406 C 129.765625 74.113281 128.65625 72.933594 127.292969 72.933594 Z M 127.292969 72.933594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 132.289062 71.867188 C 130.921875 71.867188 129.8125 73.046875 129.8125 74.5 C 129.8125 75.953125 130.921875 77.128906 132.289062 77.128906 C 133.65625 77.128906 134.761719 75.953125 134.761719 74.5 C 134.761719 73.046875 133.65625 71.867188 132.289062 71.867188 Z M 132.289062 71.867188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 137.535156 70.277344 C 136.167969 70.277344 135.0625 71.453125 135.0625 72.90625 C 135.0625 74.359375 136.167969 75.539062 137.535156 75.539062 C 138.902344 75.539062 140.007812 74.359375 140.007812 72.90625 C 140.007812 71.453125 138.902344 70.277344 137.535156 70.277344 Z M 137.535156 70.277344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 142.785156 68.945312 C 141.421875 68.945312 140.3125 70.125 140.3125 71.578125 C 140.3125 73.03125 141.421875 74.210938 142.785156 74.210938 C 144.152344 74.210938 145.261719 73.03125 145.261719 71.578125 C 145.261719 70.125 144.152344 68.945312 142.785156 68.945312 Z M 142.785156 68.945312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 148.285156 67.882812 C 146.917969 67.882812 145.8125 69.0625 145.8125 70.515625 C 145.8125 71.96875 146.917969 73.144531 148.285156 73.144531 C 149.652344 73.144531 150.757812 71.96875 150.757812 70.515625 C 150.757812 69.0625 149.652344 67.882812 148.285156 67.882812 Z M 148.285156 67.882812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 153.78125 67.085938 C 152.417969 67.085938 151.308594 68.265625 151.308594 69.71875 C 151.308594 71.171875 152.417969 72.347656 153.78125 72.347656 C 155.148438 72.347656 156.257812 71.171875 156.257812 69.71875 C 156.257812 68.265625 155.148438 67.085938 153.78125 67.085938 Z M 153.78125 67.085938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 159.03125 66.554688 C 157.664062 66.554688 156.554688 67.730469 156.554688 69.1875 C 156.554688 70.640625 157.664062 71.816406 159.03125 71.816406 C 160.398438 71.816406 161.503906 70.640625 161.503906 69.1875 C 161.503906 67.730469 160.398438 66.554688 159.03125 66.554688 Z M 159.03125 66.554688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 164.53125 66.289062 C 163.164062 66.289062 162.058594 67.464844 162.058594 68.917969 C 162.058594 70.371094 163.164062 71.550781 164.53125 71.550781 C 165.898438 71.550781 167.007812 70.371094 167.007812 68.917969 C 167.007812 67.464844 165.898438 66.289062 164.53125 66.289062 Z M 164.53125 66.289062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 169.777344 67.085938 C 168.414062 67.085938 167.304688 68.265625 167.304688 69.71875 C 167.304688 71.171875 168.414062 72.347656 169.777344 72.347656 C 171.144531 72.347656 172.253906 71.171875 172.253906 69.71875 C 172.253906 68.265625 171.144531 67.085938 169.777344 67.085938 Z M 169.777344 67.085938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 175.277344 67.882812 C 173.910156 67.882812 172.804688 69.0625 172.804688 70.515625 C 172.804688 71.96875 173.910156 73.144531 175.277344 73.144531 C 176.644531 73.144531 177.75 71.96875 177.75 70.515625 C 177.75 69.0625 176.644531 67.882812 175.277344 67.882812 Z M 175.277344 67.882812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 180.277344 69.742188 C 178.910156 69.742188 177.800781 70.921875 177.800781 72.375 C 177.800781 73.828125 178.910156 75.007812 180.277344 75.007812 C 181.644531 75.007812 182.75 73.828125 182.75 72.375 C 182.75 70.921875 181.644531 69.742188 180.277344 69.742188 Z M 180.277344 69.742188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 185.023438 73.199219 C 183.660156 73.199219 182.550781 74.378906 182.550781 75.832031 C 182.550781 77.285156 183.660156 78.460938 185.023438 78.460938 C 186.390625 78.460938 187.5 77.285156 187.5 75.832031 C 187.5 74.378906 186.390625 73.199219 185.023438 73.199219 Z M 185.023438 73.199219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 188.273438 77.984375 C 186.90625 77.984375 185.800781 79.164062 185.800781 80.617188 C 185.800781 82.070312 186.90625 83.25 188.273438 83.25 C 189.640625 83.25 190.75 82.070312 190.75 80.617188 C 190.75 79.164062 189.640625 77.984375 188.273438 77.984375 Z M 188.273438 77.984375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 190.273438 83.035156 C 188.90625 83.035156 187.800781 84.210938 187.800781 85.664062 C 187.800781 87.117188 188.90625 88.296875 190.273438 88.296875 C 191.640625 88.296875 192.746094 87.117188 192.746094 85.664062 C 192.746094 84.210938 191.640625 83.035156 190.273438 83.035156 Z M 190.273438 83.035156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 191.023438 88.613281 C 189.65625 88.613281 188.550781 89.792969 188.550781 91.246094 C 188.550781 92.699219 189.65625 93.875 191.023438 93.875 C 192.390625 93.875 193.496094 92.699219 193.496094 91.246094 C 193.496094 89.792969 192.390625 88.613281 191.023438 88.613281 Z M 191.023438 88.613281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 190.773438 94.464844 C 189.40625 94.464844 188.300781 95.644531 188.300781 97.097656 C 188.300781 98.550781 189.40625 99.726562 190.773438 99.726562 C 192.140625 99.726562 193.25 98.550781 193.25 97.097656 C 193.25 95.644531 192.140625 94.464844 190.773438 94.464844 Z M 190.773438 94.464844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 189.523438 100.046875 C 188.15625 100.046875 187.050781 101.222656 187.050781 102.675781 C 187.050781 104.128906 188.15625 105.308594 189.523438 105.308594 C 190.890625 105.308594 192 104.128906 192 102.675781 C 192 101.222656 190.890625 100.046875 189.523438 100.046875 Z M 189.523438 100.046875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 187.523438 105.625 C 186.15625 105.625 185.050781 106.804688 185.050781 108.257812 C 185.050781 109.710938 186.15625 110.890625 187.523438 110.890625 C 188.890625 110.890625 190 109.710938 190 108.257812 C 190 106.804688 188.890625 105.625 187.523438 105.625 Z M 187.523438 105.625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 185.273438 110.414062 C 183.910156 110.414062 182.800781 111.589844 182.800781 113.042969 C 182.800781 114.496094 183.910156 115.675781 185.273438 115.675781 C 186.640625 115.675781 187.75 114.496094 187.75 113.042969 C 187.75 111.589844 186.640625 110.414062 185.273438 110.414062 Z M 185.273438 110.414062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 195.519531 115.441406 L 181.398438 157.148438 C 181.398438 157.148438 158.054688 147.390625 114.289062 147.390625 C 70.519531 147.390625 50.578125 155.929688 50.578125 155.929688 L 30.042969 114.941406 C 48.308594 111.546875 53.878906 127.113281 53.878906 127.113281 C 53.878906 127.113281 55.121094 108.933594 69.46875 105.710938 C 81.585938 103.609375 92.296875 119.332031 92.296875 119.332031 C 92.296875 119.332031 94.820312 101.636719 112.726562 101.152344 C 130.636719 101.636719 133.160156 119.332031 133.160156 119.332031 C 133.160156 119.332031 143.867188 103.609375 155.988281 105.710938 C 170.332031 108.933594 171.578125 127.113281 171.578125 127.113281 C 171.578125 127.113281 177.144531 111.546875 195.515625 115.4375 Z M 195.519531 115.441406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 116.363281 145.109375 L 116.367188 145.109375 C 152.480469 145.109375 181.757812 149.738281 181.757812 155.445312 C 181.757812 161.15625 152.480469 165.785156 116.367188 165.785156 L 116.363281 165.785156 C 80.25 165.785156 50.972656 161.15625 50.972656 155.445312 C 50.972656 149.738281 80.25 145.109375 116.363281 145.109375 Z M 116.363281 145.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 114.785156 104.300781 C 115.589844 104.300781 116.246094 105.238281 116.246094 106.398438 C 116.246094 107.558594 115.589844 108.5 114.785156 108.5 C 113.980469 108.5 113.328125 107.558594 113.328125 106.398438 C 113.328125 105.238281 113.980469 104.300781 114.785156 104.300781 Z M 114.785156 104.300781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 118.761719 105.359375 C 119.566406 105.359375 120.21875 106.300781 120.21875 107.460938 C 120.21875 108.621094 119.566406 109.558594 118.761719 109.558594 C 117.953125 109.558594 117.300781 108.621094 117.300781 107.460938 C 117.300781 106.300781 117.953125 105.359375 118.761719 105.359375 Z M 118.761719 105.359375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 111.140625 104.300781 C 111.945312 104.300781 112.597656 105.238281 112.597656 106.398438 C 112.597656 107.558594 111.945312 108.5 111.140625 108.5 C 110.335938 108.5 109.683594 107.558594 109.683594 106.398438 C 109.683594 105.238281 110.335938 104.300781 111.140625 104.300781 Z M 111.140625 104.300781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 104.179688 107.488281 C 104.988281 107.488281 105.640625 108.425781 105.640625 109.585938 C 105.640625 110.746094 104.988281 111.6875 104.179688 111.6875 C 103.375 111.6875 102.722656 110.746094 102.722656 109.585938 C 102.722656 108.425781 103.375 107.488281 104.179688 107.488281 Z M 104.179688 107.488281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 107.492188 105.359375 C 108.296875 105.359375 108.949219 106.300781 108.949219 107.460938 C 108.949219 108.621094 108.296875 109.558594 107.492188 109.558594 C 106.6875 109.558594 106.035156 108.621094 106.035156 107.460938 C 106.035156 106.300781 106.6875 105.359375 107.492188 105.359375 Z M 107.492188 105.359375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 101.527344 110.679688 C 102.332031 110.679688 102.984375 111.617188 102.984375 112.777344 C 102.984375 113.9375 102.332031 114.878906 101.527344 114.878906 C 100.722656 114.878906 100.070312 113.9375 100.070312 112.777344 C 100.070312 111.617188 100.722656 110.679688 101.527344 110.679688 Z M 101.527344 110.679688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 122.074219 107.753906 C 122.882812 107.753906 123.535156 108.695312 123.535156 109.851562 C 123.535156 111.011719 122.882812 111.953125 122.074219 111.953125 C 121.269531 111.953125 120.617188 111.011719 120.617188 109.851562 C 120.617188 108.695312 121.269531 107.753906 122.074219 107.753906 Z M 122.074219 107.753906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 125.054688 110.679688 C 125.859375 110.679688 126.515625 111.617188 126.515625 112.777344 C 126.515625 113.9375 125.859375 114.878906 125.054688 114.878906 C 124.25 114.878906 123.597656 113.9375 123.597656 112.777344 C 123.597656 111.617188 124.25 110.679688 125.054688 110.679688 Z M 125.054688 110.679688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 157.054688 110.21875 C 157.851562 110.351562 158.359375 111.390625 158.1875 112.535156 C 158.019531 113.679688 157.238281 114.5 156.441406 114.367188 C 155.648438 114.234375 155.140625 113.199219 155.308594 112.050781 C 155.480469 110.90625 156.261719 110.085938 157.054688 110.21875 Z M 157.054688 110.21875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 160.828125 111.925781 C 161.621094 112.058594 162.128906 113.09375 161.960938 114.238281 C 161.789062 115.386719 161.007812 116.207031 160.210938 116.074219 C 159.417969 115.941406 158.910156 114.902344 159.078125 113.757812 C 159.25 112.613281 160.03125 111.792969 160.828125 111.925781 Z M 160.828125 111.925781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 153.453125 109.617188 C 154.25 109.75 154.757812 110.785156 154.585938 111.933594 C 154.417969 113.078125 153.636719 113.898438 152.839844 113.765625 C 152.042969 113.632812 151.539062 112.59375 151.707031 111.449219 C 151.875 110.304688 152.660156 109.484375 153.453125 109.617188 Z M 153.453125 109.617188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 146.113281 111.613281 C 146.910156 111.746094 147.417969 112.785156 147.246094 113.929688 C 147.078125 115.074219 146.296875 115.894531 145.5 115.761719 C 144.703125 115.628906 144.199219 114.59375 144.367188 113.449219 C 144.535156 112.300781 145.320312 111.480469 146.113281 111.613281 Z M 146.113281 111.613281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 149.695312 110.0625 C 150.492188 110.195312 151 111.230469 150.828125 112.375 C 150.660156 113.523438 149.878906 114.34375 149.082031 114.210938 C 148.285156 114.078125 147.78125 113.039062 147.949219 111.894531 C 148.117188 110.75 148.902344 109.929688 149.695312 110.0625 Z M 149.695312 110.0625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 143.027344 114.328125 C 143.820312 114.460938 144.328125 115.5 144.160156 116.644531 C 143.988281 117.789062 143.207031 118.609375 142.414062 118.476562 C 141.617188 118.34375 141.109375 117.308594 141.277344 116.164062 C 141.449219 115.015625 142.230469 114.195312 143.027344 114.328125 Z M 143.027344 114.328125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 163.753906 114.835938 C 164.546875 114.96875 165.054688 116.007812 164.886719 117.152344 C 164.714844 118.296875 163.933594 119.117188 163.136719 118.984375 C 162.34375 118.851562 161.835938 117.816406 162.003906 116.667969 C 162.175781 115.523438 162.957031 114.703125 163.753906 114.835938 Z M 163.753906 114.835938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 166.269531 118.21875 C 167.0625 118.351562 167.570312 119.390625 167.402344 120.535156 C 167.230469 121.679688 166.449219 122.5 165.65625 122.367188 C 164.859375 122.234375 164.351562 121.199219 164.519531 120.054688 L 164.519531 120.050781 C 164.691406 118.90625 165.472656 118.085938 166.269531 118.21875 Z M 166.269531 118.21875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 71.625 108.617188 C 72.417969 108.460938 73.21875 109.261719 73.414062 110.402344 C 73.613281 111.542969 73.128906 112.59375 72.335938 112.746094 C 71.546875 112.902344 70.746094 112.101562 70.546875 110.960938 C 70.351562 109.820312 70.832031 108.769531 71.625 108.617188 Z M 71.625 108.617188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 75.714844 108.898438 C 76.503906 108.742188 77.308594 109.542969 77.503906 110.683594 C 77.699219 111.824219 77.21875 112.875 76.425781 113.027344 C 75.632812 113.183594 74.832031 112.382812 74.636719 111.242188 C 74.4375 110.101562 74.921875 109.050781 75.714844 108.898438 Z M 75.714844 108.898438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 68.039062 109.316406 C 68.832031 109.160156 69.632812 109.960938 69.828125 111.101562 C 70.023438 112.242188 69.542969 113.292969 68.75 113.445312 C 67.957031 113.601562 67.15625 112.800781 66.960938 111.660156 C 66.765625 110.519531 67.246094 109.472656 68.039062 109.316406 Z M 68.039062 109.316406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 61.734375 113.785156 C 62.527344 113.632812 63.328125 114.433594 63.523438 115.574219 C 63.722656 116.714844 63.238281 117.761719 62.445312 117.917969 C 61.65625 118.070312 60.855469 117.273438 60.65625 116.132812 C 60.460938 114.992188 60.941406 113.941406 61.734375 113.785156 Z M 61.734375 113.785156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 64.632812 111.058594 C 65.421875 110.90625 66.226562 111.703125 66.421875 112.84375 C 66.617188 113.984375 66.136719 115.035156 65.34375 115.191406 C 64.550781 115.34375 63.75 114.546875 63.554688 113.40625 C 63.355469 112.265625 63.839844 111.214844 64.632812 111.058594 Z M 64.632812 111.058594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 59.667969 117.4375 C 60.457031 117.28125 61.261719 118.082031 61.457031 119.222656 C 61.652344 120.363281 61.171875 121.410156 60.378906 121.566406 C 59.585938 121.722656 58.785156 120.921875 58.589844 119.78125 C 58.390625 118.640625 58.875 117.589844 59.667969 117.4375 Z M 59.667969 117.4375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 79.382812 110.617188 C 80.171875 110.460938 80.972656 111.261719 81.171875 112.402344 C 81.367188 113.542969 80.886719 114.589844 80.09375 114.746094 C 79.300781 114.902344 78.5 114.101562 78.304688 112.960938 C 78.105469 111.820312 78.589844 110.769531 79.382812 110.617188 Z M 79.382812 110.617188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 82.808594 112.921875 C 83.601562 112.765625 84.402344 113.566406 84.597656 114.707031 C 84.796875 115.847656 84.3125 116.898438 83.519531 117.050781 C 82.730469 117.207031 81.925781 116.40625 81.730469 115.265625 C 81.535156 114.125 82.015625 113.074219 82.808594 112.921875 Z M 82.808594 112.921875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 33.761719 116.578125 C 34.550781 116.40625 35.371094 117.183594 35.589844 118.320312 C 35.8125 119.453125 35.351562 120.515625 34.566406 120.691406 C 33.777344 120.863281 32.957031 120.085938 32.734375 118.949219 C 32.515625 117.8125 32.972656 116.753906 33.761719 116.578125 Z M 33.761719 116.578125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 37.859375 116.765625 C 38.648438 116.59375 39.46875 117.371094 39.6875 118.507812 C 39.910156 119.644531 39.449219 120.707031 38.664062 120.878906 C 37.875 121.054688 37.054688 120.273438 36.835938 119.136719 C 36.613281 118.003906 37.070312 116.941406 37.859375 116.765625 Z M 37.859375 116.765625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 41.558594 118.386719 C 42.347656 118.210938 43.167969 118.992188 43.386719 120.125 C 43.609375 121.261719 43.152344 122.324219 42.363281 122.496094 C 41.574219 122.671875 40.753906 121.890625 40.535156 120.757812 C 40.3125 119.621094 40.773438 118.558594 41.558594 118.386719 Z M 41.558594 118.386719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 45.039062 120.609375 C 45.828125 120.4375 46.648438 121.214844 46.867188 122.351562 C 47.089844 123.488281 46.632812 124.546875 45.84375 124.722656 C 45.054688 124.894531 44.234375 124.117188 44.015625 122.980469 C 43.792969 121.847656 44.25 120.785156 45.039062 120.609375 Z M 45.039062 120.609375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 192.777344 117.433594 C 193.523438 117.757812 193.792969 118.890625 193.382812 119.964844 C 192.96875 121.039062 192.03125 121.644531 191.285156 121.320312 C 190.539062 120.996094 190.269531 119.863281 190.683594 118.792969 C 191.09375 117.71875 192.035156 117.109375 192.777344 117.433594 Z M 192.777344 117.433594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 185.203125 117.585938 C 185.949219 117.910156 186.21875 119.042969 185.804688 120.117188 C 185.394531 121.191406 184.457031 121.796875 183.710938 121.472656 C 182.964844 121.148438 182.695312 120.015625 183.105469 118.945312 L 183.105469 118.941406 C 183.519531 117.871094 184.457031 117.261719 185.203125 117.585938 Z M 185.203125 117.585938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 189.023438 116.949219 C 189.769531 117.273438 190.039062 118.40625 189.628906 119.480469 C 189.214844 120.554688 188.277344 121.160156 187.53125 120.835938 C 186.785156 120.511719 186.515625 119.378906 186.929688 118.304688 C 187.339844 117.234375 188.28125 116.625 189.023438 116.949219 Z M 189.023438 116.949219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 181.613281 119.472656 C 182.359375 119.796875 182.628906 120.929688 182.214844 122.003906 C 181.800781 123.078125 180.863281 123.683594 180.117188 123.363281 C 179.375 123.039062 179.101562 121.90625 179.515625 120.832031 C 179.929688 119.757812 180.867188 119.148438 181.613281 119.472656 Z M 181.613281 119.472656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 102.816406 119.242188 C 113.050781 127.375 113.234375 142.648438 113.234375 142.648438 C 113.234375 142.648438 111.589844 126.582031 122.558594 120.035156 C 116.890625 121.820312 117.074219 121.421875 113.96875 121.226562 L 113.234375 116.066406 C 113.234375 116.066406 112.6875 118.644531 111.589844 121.027344 C 109.214844 121.820312 108.664062 121.820312 102.816406 119.242188 Z M 102.816406 119.242188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 62.363281 125.183594 C 74.113281 130.527344 77.671875 145.328125 77.671875 145.328125 C 77.671875 145.328125 72.515625 130.121094 81.730469 121.015625 C 76.617188 124.167969 76.707031 123.738281 73.640625 124.320312 L 71.789062 119.492188 C 71.789062 119.492188 71.828125 122.136719 71.289062 124.722656 C 69.152344 126.089844 68.621094 126.226562 62.363281 125.183594 Z M 62.363281 125.183594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 145.480469 120.40625 C 153.496094 130.988281 150.101562 145.832031 150.101562 145.832031 C 150.101562 145.832031 152.265625 129.832031 164.421875 126.394531 C 158.515625 126.625 158.785156 126.289062 155.820312 125.273438 L 156.316406 120.085938 C 156.316406 120.085938 155.183594 122.4375 153.566406 124.453125 C 151.078125 124.59375 150.546875 124.449219 145.480469 120.40625 Z M 145.480469 120.40625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 40.246094 132.761719 C 49.078125 137.113281 53.664062 150.023438 53.664062 150.023438 C 53.664062 150.023438 47.921875 136.804688 52.921875 128.527344 C 49.871094 131.449219 49.871094 131.066406 47.855469 131.671875 L 45.882812 127.480469 C 45.882812 127.480469 46.292969 129.800781 46.296875 132.09375 C 45.03125 133.359375 44.6875 133.492188 40.246094 132.761719 Z M 40.246094 132.761719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 175.957031 127.078125 C 177.644531 137.277344 170.304688 148.65625 170.304688 148.65625 C 170.304688 148.65625 177.226562 136.089844 186.46875 135.722656 C 182.472656 134.730469 182.765625 134.511719 181.144531 133.097656 L 183.253906 128.980469 C 183.253906 128.980469 181.695312 130.667969 179.929688 131.980469 C 178.234375 131.597656 177.929688 131.375 175.957031 127.078125 Z M 175.957031 127.078125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 112.667969 62.507812 C 115.03125 62.507812 116.945312 64.503906 116.945312 66.96875 C 116.945312 69.433594 115.03125 71.429688 112.667969 71.429688 C 110.308594 71.429688 108.394531 69.433594 108.394531 66.96875 C 108.394531 64.503906 110.308594 62.507812 112.667969 62.507812 Z M 112.667969 62.507812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 112.386719 62.507812 L 113.28125 62.507812 L 113.28125 71.429688 L 112.386719 71.429688 Z M 112.386719 62.507812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 113.039062 90.742188 C 114.128906 90.742188 115.011719 91.738281 115.011719 92.972656 C 115.011719 94.207031 114.128906 95.207031 113.039062 95.207031 C 111.949219 95.207031 111.0625 94.207031 111.0625 92.972656 C 111.0625 91.738281 111.949219 90.742188 113.039062 90.742188 Z M 113.039062 90.742188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 112.914062 86.226562 C 113.933594 86.226562 114.761719 87.167969 114.761719 88.328125 C 114.761719 89.484375 113.933594 90.425781 112.914062 90.425781 C 111.890625 90.425781 111.0625 89.484375 111.0625 88.328125 C 111.0625 87.167969 111.890625 86.226562 112.914062 86.226562 Z M 112.914062 86.226562 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 112.914062 81.96875 C 113.933594 81.96875 114.761719 82.847656 114.761719 83.933594 C 114.761719 85.019531 113.933594 85.902344 112.914062 85.902344 C 111.890625 85.902344 111.0625 85.019531 111.0625 83.933594 C 111.0625 82.847656 111.890625 81.96875 112.914062 81.96875 Z M 112.914062 81.96875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 112.917969 78.523438 C 113.800781 78.523438 114.515625 79.222656 114.515625 80.089844 C 114.515625 80.957031 113.800781 81.65625 112.917969 81.65625 C 112.035156 81.65625 111.320312 80.957031 111.320312 80.089844 C 111.320312 79.222656 112.035156 78.523438 112.917969 78.523438 Z M 112.917969 78.523438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 112.917969 74.527344 C 113.800781 74.527344 114.515625 75.351562 114.515625 76.363281 C 114.515625 77.375 113.800781 78.195312 112.917969 78.195312 C 112.035156 78.195312 111.320312 77.375 111.320312 76.363281 C 111.320312 75.351562 112.035156 74.527344 112.917969 74.527344 Z M 112.917969 74.527344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 112.921875 71.605469 C 113.667969 71.605469 114.273438 72.1875 114.273438 72.90625 C 114.273438 73.625 113.667969 74.210938 112.921875 74.210938 C 112.175781 74.210938 111.574219 73.625 111.574219 72.90625 C 111.574219 72.1875 112.175781 71.605469 112.921875 71.605469 Z M 112.921875 71.605469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 117.105469 66.46875 L 117.109375 67.402344 L 108.558594 67.457031 L 108.554688 66.519531 Z M 117.105469 66.46875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,93.72549%,49.019608%);fill-opacity:1;"
      d="M 113.042969 95.265625 C 114.410156 95.265625 115.515625 96.445312 115.515625 97.898438 C 115.515625 99.351562 114.410156 100.53125 113.042969 100.53125 C 111.675781 100.53125 110.570312 99.351562 110.570312 97.898438 C 110.570312 96.445312 111.675781 95.265625 113.042969 95.265625 Z M 113.042969 95.265625 "
    />
  </g>
</svg>
