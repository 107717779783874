<script>
  import styles from "styleguide/ThemeAccountFavicon.json";
  import { t } from "stores/i18n.js";
  import { account, accountRemoveFavicon } from "stores/account.js";
  import { Button } from "components";

  let hiddenFaviconInput;
  let newFavicon;

  function onFavicon() {
    hiddenFaviconInput.click();
  }

  function setFavicon(event) {
    newFavicon = event.target.files[0];
    let reader = new FileReader();
    reader.onload = () => {
      $account.favicon = reader.result;
      const link = document.querySelector(`link[rel="shortcut icon"]`);
      link.href = $account.favicon;
    };
    reader.readAsDataURL(newFavicon);
  }

  function removeFavicon() {
    $account.favicon = null;
    $accountRemoveFavicon = true;
  }
</script>

<div data-component="ThemeAccountFavicon" class={styles.content}>
  <div class={styles.header}>
    {$t("theme_account_favicon.title")}
  </div>
  <div class={styles.buttons}>
    <Button click={onFavicon} style="primary-tonal" type="file">
      {#if $account.favicon}
        {$t("theme_account_favicon.update")}
      {:else}
        {$t("theme_account_favicon.create")}
      {/if}
    </Button>
    {#if $account.favicon}
      <Button click={removeFavicon} style={"error-text"} icon={"trash"} />
    {/if}
  </div>
  <input
    type="file"
    bind:this={hiddenFaviconInput}
    class={styles.hidden}
    on:change={setFavicon}
    accept="image/*"
  />
</div>

<style scoped lang="scss">
  .content {
    display: flex;
    flex-direction: column;
  }
  .header {
    margin: 0px 0px 5px 0px;
    font-weight: bold;
  }
  .buttons {
    display: flex;
    gap: 8px;
  }
  .hidden {
    display: none;
  }
</style>
