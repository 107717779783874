<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(0%,0%,40%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 0 L 0 18.609375 L 239.8125 166.4375 L 270 166.4375 L 270 147.828125 L 30.1875 0 Z M 270.003906 0 L 270.003906 18.609375 L 30.183594 166.4375 L 0 166.4375 L 0 147.824219 L 239.8125 -0.00390625 L 270 -0.00390625 Z M 270.003906 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 112.503906 0 L 112.503906 166.4375 L 157.503906 166.4375 L 157.503906 0 Z M 0 55.480469 L 0 110.960938 L 270.003906 110.960938 L 270.003906 55.480469 Z M 0 55.480469 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 0 66.574219 L 0 99.863281 L 270.003906 99.863281 L 270.003906 66.574219 Z M 121.503906 0 L 121.503906 166.4375 L 148.503906 166.4375 L 148.503906 0 Z M 121.503906 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 0 166.4375 L 90.003906 110.960938 L 110.128906 110.960938 L 20.125 166.4375 L -0.00390625 166.4375 Z M 0 0 L 90.003906 55.480469 L 69.878906 55.480469 L 0.00390625 12.40625 L 0.00390625 0 Z M 159.878906 55.480469 L 249.875 0 L 270 0 L 179.996094 55.480469 L 159.871094 55.480469 Z M 270.003906 166.441406 L 180 110.960938 L 200.125 110.960938 L 270 154.035156 L 270 166.441406 Z M 270.003906 166.441406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 353.699219 159.75 L 357.601562 147.25 L 347.089844 139.265625 L 360.332031 139.09375 L 364.742188 126.765625 L 369.023438 139.164062 L 382.265625 139.523438 L 371.671875 147.355469 L 375.441406 159.910156 L 364.613281 152.351562 Z M 353.699219 159.75 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 384.898438 162.207031 L 392.707031 151.753906 L 385.445312 140.65625 L 397.976562 145.003906 L 406.207031 134.890625 L 406.144531 148.023438 L 418.5 152.871094 L 405.929688 156.640625 L 405.328125 169.753906 L 397.625 158.949219 Z M 384.898438 162.207031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 323.828125 170.179688 L 323.308594 157.078125 L 310.75 153.191406 L 323.144531 148.46875 L 323.164062 135.351562 L 331.34375 145.535156 L 343.921875 141.3125 L 336.578125 152.328125 L 344.328125 162.839844 L 331.613281 159.460938 Z M 323.828125 170.179688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 301.84375 190.113281 L 296.347656 178.246094 L 283.285156 179.613281 L 292.898438 170.378906 L 287.890625 158.285156 L 299.328125 164.441406 L 309.296875 155.59375 L 306.753906 168.636719 L 317.921875 175.265625 L 304.910156 177.167969 Z M 301.84375 190.113281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 291.808594 218.855469 L 281.824219 210.523438 L 270.601562 217.441406 L 275.382812 204.914062 L 265.75 196.167969 L 278.683594 196.757812 L 283.953125 184.429688 L 287.164062 197.320312 L 300.058594 198.449219 L 289.105469 205.828125 Z M 291.808594 218.855469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 291.5625 251.140625 L 279.136719 247.527344 L 271.554688 258.402344 L 271.027344 244.980469 L 258.757812 240.84375 L 270.855469 236.164062 L 270.851562 222.726562 L 278.855469 233.257812 L 291.128906 229.089844 L 283.976562 240.277344 Z M 291.5625 251.140625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 306.121094 278.417969 L 293.3125 280.214844 L 290.71875 293.269531 L 284.882812 281.210938 L 272.011719 282.464844 L 281.207031 273.21875 L 275.84375 260.933594 L 287.367188 267.277344 L 296.925781 258.429688 L 294.847656 271.597656 Z M 306.121094 278.417969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 328.628906 300.0625 L 317.222656 306.234375 L 319.226562 319.394531 L 309.648438 310.125 L 297.996094 315.804688 L 303.480469 303.910156 L 294.265625 294.257812 L 307.234375 296.175781 L 313.199219 284.527344 L 315.726562 297.609375 Z M 328.628906 300.0625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 356.101562 304.332031 L 349.878906 315.824219 L 358.652344 325.734375 L 345.632812 323.285156 L 338.9375 334.503906 L 337.117188 321.5 L 324.199219 318.527344 L 336.09375 312.941406 L 334.808594 299.882812 L 343.980469 309.433594 Z M 356.101562 304.332031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 386.980469 299.59375 L 386.589844 312.699219 L 398.851562 317.472656 L 386.15625 321.296875 L 385.230469 334.378906 L 377.777344 323.640625 L 364.9375 326.957031 L 373.027344 316.492188 L 366.019531 305.457031 L 378.472656 309.730469 Z M 386.980469 299.59375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 419.269531 157.316406 L 428.230469 166.769531 L 440.167969 161.226562 L 433.980469 173.101562 L 442.542969 182.929688 L 429.765625 180.816406 L 423.117188 192.4375 L 421.40625 179.253906 L 408.730469 176.605469 L 420.457031 170.574219 Z M 419.269531 157.316406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 446.140625 186.402344 L 450.203125 198.847656 L 463.335938 199.070312 L 452.703125 207.078125 L 456.253906 219.691406 L 445.621094 212.195312 L 434.683594 219.773438 L 438.746094 207.132812 L 428.433594 199.199219 L 441.574219 198.882812 Z M 446.140625 186.402344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 456.207031 223.789062 L 454.703125 236.8125 L 466.507812 242.640625 L 453.535156 245.339844 L 451.496094 258.292969 L 444.988281 246.9375 L 431.914062 249.117188 L 440.863281 239.402344 L 434.828125 227.789062 L 446.867188 233.140625 Z M 456.207031 223.789062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 449.066406 262.984375 L 443.242188 274.691406 L 452.351562 284.28125 L 439.253906 282.292969 L 432.953125 293.746094 L 430.683594 280.8125 L 417.671875 278.300781 L 429.367188 272.296875 L 427.632812 259.289062 L 437.125 268.511719 Z M 449.066406 262.984375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 429.753906 297.84375 L 418.746094 304.71875 L 421.554688 317.730469 L 411.425781 309.082031 L 400.144531 315.488281 L 404.890625 303.265625 L 395.097656 294.214844 L 408.164062 295.3125 L 413.398438 283.308594 L 416.726562 296.207031 Z M 429.753906 297.84375 "
    />
  </g>
</svg>
