<script>
  import styles from "styleguide/TwoFactorAuth.json";
  import { onMount } from "svelte";
  import { isAfter, endOfDay, parse, format } from "date-fns";

  import { t } from "stores/i18n.js";
  import { twoFactorAuth } from "stores/account.js";

  import { dateFormat } from "lib/helpers";
  import TwoFactorAuthApi from "apis/compliance_package/two_factor_auth_api";

  import { Button, DateInput, Tag } from "components";

  const defaultTwoFactorAuth = {
    enforce_team_user_2fa_at: "",
    enforce_client_user_2fa_at: "",
  };

  $twoFactorAuth = $twoFactorAuth ?? setDefaultFactorAuth();

  onMount(async () => {
    TwoFactorAuthApi.getTwoFactorAuth();
  });

  function setDate(date, user) {
    const formattedDate = format(date, dateFormat.default) ?? null;
    $twoFactorAuth[user] = formattedDate;
  }

  function getLabel(date) {
    let label = $t("two_factor_auth.inactive");
    let color = "grey";

    if (date) {
      if (
        isAfter(
          parse(date, dateFormat.default, new Date()),
          endOfDay(new Date()),
        )
      ) {
        label = $t("two_factor_auth.pending");
        color = "orange";
      } else {
        label = $t("two_factor_auth.active");
        color = "green";
      }
    }
    return {
      label,
      color,
    };
  }

  function updateTwoFactorAuth() {
    TwoFactorAuthApi.updateTwoFactorAuth($twoFactorAuth);
  }

  function resetTwoFactor(key) {
    $twoFactorAuth[key] = "";
  }

  function setDefaultFactorAuth() {
    $twoFactorAuth = { ...defaultTwoFactorAuth };
  }
</script>

<div data-component="TwoFactorAuth">
  <div class={styles["section"]}>
    <div class={styles["section-header"]}>
      {$t("two_factor_auth.header")}
    </div>
    <div class={styles["section-description"]}>
      {$t("two_factor_auth.description")}
    </div>
    {$t("two_factor_auth.subtitle")}
    <div class={styles.items}>
      {#each ["enforce_team_user_2fa_at", "enforce_client_user_2fa_at"] as key}
        <div class={styles["item"]}>
          <div class={styles["item-title"]}>
            {$t(`two_factor_auth.${key}`)}
            <Tag
              label={getLabel($twoFactorAuth[key]).label}
              color={getLabel($twoFactorAuth[key]).color}
              size="small"
            />
          </div>
          <div class={styles["item-datepicker"]}>
            <DateInput
              onRemove={() => resetTwoFactor(key)}
              onSelect={(date) => setDate(date, key)}
              dynamicPosition={false}
              value={$twoFactorAuth[key]}
              label={$t("two_factor_auth.activation_date")}
            />
          </div>
        </div>
      {/each}
    </div>
    <div class={styles["buttons"]}>
      <Button click={updateTwoFactorAuth} style="primary">
        {$t("two_factor_auth.save")}
      </Button>
    </div>
  </div>
</div>

<style lang="scss">
  .section {
    &-header {
      display: flex;
      gap: 8px;
      align-items: center;
      padding-top: 24px;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: var(--primary-500, #052861);
    }

    &-description {
      padding-top: 8px;
      padding-bottom: 16px;
      font-size: 14px;
      line-height: 20px;
      color: var(--primary-300);
    }
  }
  .items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    padding-top: 24px;

    .item {
      width: 100%;

      &-title {
        display: flex;
        align-items: center;
        gap: 8px;
        color: var(--primary-500, #052861);
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        padding-bottom: 16px;
      }

      &-datepicker {
        font-weight: 500;
      }
    }
  }

  .buttons {
    display: flex;
    gap: 8px;
    padding: 24px 0;
  }
</style>
