<script>
  import styles from "styleguide/RespondSuccess.json";
  import { iconPath } from "lib/helpers.js";
  import { t } from "stores/i18n.js";
</script>

<div data-component="RespondSuccess" class={styles.container}>
  <div class={styles.block}>
    <div class={styles.checkmark}>
      <img
        src={iconPath("checkmark_big_green")}
        class={styles.logo}
        alt="Auditi"
      />
    </div>
    <div>
      <h2 class={styles.title}>{$t("responder_success.title")}</h2>
      <p class={styles.subtitle}>{$t("responder_success.subtitle")}</p>
    </div>
  </div>
</div>

<style lang="scss">
  .block {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 120px 0;
    text-align: center;
  }

  .container {
    padding: 0 35px;
  }

  .title {
    max-width: 330px;
    margin: 0 auto 16px;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.3;
    color: var(--primary-500);
  }

  .subtitle {
    margin: 0;
    line-height: 1.6;
    color: var(--primary-500);
  }

  .checkmark {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 12px;
    margin-bottom: 24px;
    background: var(--green-010);

    img {
      width: 24px;
      height: 24px;
    }
  }
</style>
