<script>
  import styles from "styleguide/AdministrationCompliancePackage.json";
  import { onMount } from "svelte";

  import { updatePageHistory } from "stores/page_history.js";
  import { currentAdminPage } from "stores/admin.js";
  import { breadcrumb } from "stores/breadcrumb.js";
  import { theme } from "stores/theme";

  import { userPath } from "lib/helpers.js";
  import { can } from "stores/permission";

  import {
    AllowList,
    CustomPasswordRule,
    EmailWhiteList,
    TwoFactorAuth,
  } from "apps/administration";

  onMount(async () => {
    if (!$can("manage_compliance_package", "account")) {
      window.history.back();
    }
    breadcrumb.set([
      {
        translation: "administration",
        click: () => {
          currentAdminPage.set("dashboard");
          window.history.pushState("", userPath + "/account/");
        },
      },
      { translation: "compliance_package" },
    ]);

    updatePageHistory([
      {
        path: userPath + "/account/compliance_package",
        titles: [
          { translation: "administration" },
          { translation: "compliance_package" },
        ],
      },
    ]);
  });

  const components = [
    $theme !== "eyde" && CustomPasswordRule,
    // EmailSignature,
    TwoFactorAuth,
    AllowList,
    EmailWhiteList,
  ].filter(Boolean);
</script>

<div data-component="AdministrationCompliancePackage">
  <div class={styles["content"]}>
    {#each components as component (component.key)}
      {#if component}
        <svelte:component this={component} />
        {#if component !== components[components.length - 1]}
          <div class={styles["divider"]} />
        {/if}
      {/if}
    {/each}
  </div>
</div>

<style scoped lang="scss">
  .content {
    width: 679px;
    padding: 0 24px 24px 24px;
    background: var(--white);
    box-shadow:
      0 6px 8px -6px rgba(24, 39, 75, 0.12),
      0 8px 16px -6px rgba(24, 39, 75, 0.08);
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
  }

  .divider {
    height: 4px;
    width: 100%;
    background: var(--primary-025, #d3d9e3);
    border-radius: 10px;
  }
</style>
