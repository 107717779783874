import { Mutex } from "async-mutex";

class MutexPool {
  constructor(mutexCount, mutexKeys) {
    let self = this;
    this.mutexes = {};
    this.pickMutexIndex = {};
    mutexKeys.forEach((mutexKey) => {
      self.mutexes[mutexKey] = new Array(mutexCount).fill().map(() => {
        return new Mutex();
      });
      self.pickMutexIndex[mutexKey] = 0;
    });
  }

  pickMutex(type) {
    let mutex = this.mutexes[type].find((mutex) => {
      return mutex.isLocked() === false;
    });
    if (!mutex) {
      mutex = this.mutexes[type][this.pickMutexIndex[type]];
    }
    this.pickMutexIndex[type] += 1;
    if (this.pickMutexIndex[type] >= this.mutexes[type].length) {
      this.pickMutexIndex[type] = 0;
    }
    return mutex;
  }
}

export default MutexPool;
