<script>
  import { onMount } from "svelte";
  import { Dropdown } from "components/index.js";
  import { changeLanguage, currentLanguage } from "stores/i18n.js";
  import styles from "styleguide/Devtools.json";

  let active = false;
  let showDevtools = false;

  $: languages = {
    en: "English",
    de: "German",
  };
  let newLanguage;

  window.devtools = function () {
    showDevtools = !showDevtools;
    document.cookie = `devtools=${showDevtools};path=/`;
  };

  onMount(async () => {
    showDevtools = document.cookie.includes("devtools=true");
  });

  $: calculatedStyles = function () {
    let s = ["devtools"];
    if (active) {
      s.push("active");
    }
    return s.map((x) => styles[x]).join(" ");
  };
  function toggle() {
    active = !active;
  }
  function switchLanguage() {
    changeLanguage(newLanguage);
  }
</script>

{#if showDevtools}
  <div data-component="Devtools" class={calculatedStyles()}>
    {#if active}
      <Dropdown
        label="Language"
        items={languages}
        initial={$currentLanguage}
        bind:selected={newLanguage}
        click={switchLanguage}
      />
    {/if}
    <h4 on:click={toggle}>Devtools</h4>
  </div>
{/if}

<style lang="scss">
  .devtools {
    position: absolute;
    margin-left: calc(50% - 100px);
    width: 200px;
    top: 0;
    padding: 0.5em;
    background-color: var(--yellow-100est);
    border: 1px solid var(--yellow);
    z-index: 10000;

    h4 {
      margin: 0;
      cursor: pointer;
    }
  }
</style>
