<script>
  import styles from "styleguide/Popover.json";
  import { onDestroy } from "svelte";
  import { createPopperActions } from "svelte-popperjs";
  import { IconButton } from "components";

  export let placement = "auto";
  export let strategy = "fixed";
  export let showCloseButton = true;
  export let open = false;
  export let style = "";
  export let offsetPosition = [0, 8];

  let popoverElement = null;

  const [popperRef, popperContent] = createPopperActions({
    placement: placement,
    strategy: strategy,
  });
  const extraOpts = {
    modifiers: [{ name: "offset", options: { offset: offsetPosition } }],
  };

  function getStyles() {
    return style
      .split(" ")
      .map((x) => styles[x])
      .join(" ");
  }

  function toggleOnOpen() {
    open = !open;
  }

  function handleOnClose() {
    open = false;
  }

  function handleOutsideClick(event) {
    if (!popoverElement.contains(event.target)) {
      handleOnClose();
    }
  }

  $: if (open && popoverElement) {
    window.addEventListener("click", handleOutsideClick);
  }

  onDestroy(() => {
    window.removeEventListener("click", handleOutsideClick);
  });
</script>

<div
  data-component="Popover"
  use:popperRef
  bind:this={popoverElement}
  class={getStyles()}
>
  <div
    class={styles.wrapper}
    on:click={toggleOnOpen}
    on:keydown={toggleOnOpen}
    role="button"
    tabindex="0"
  >
    <slot />
  </div>
  {#if open && $$slots.content}
    <div use:popperContent={extraOpts} class={styles.popover}>
      {#if showCloseButton}
        <div class={styles["popover-close"]}>
          <IconButton icon="x" click={handleOnClose} />
        </div>
      {/if}
      <slot name="content" />
    </div>
  {/if}
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    position: relative;
    cursor: pointer;
  }
  .popover {
    width: max-content;
    max-width: 325px;
    padding: 24px;
    background: #fff;
    z-index: var(--z-index-popover);
    border: 1px solid var(--primary-050);
    border-radius: var(--border-radius);
    box-shadow:
      0 8px 24px -4px rgba(24, 39, 75, 0.08),
      0px 6px 12px -6px rgba(24, 39, 75, 0.12);
    &:after {
      content: "";
      height: 12px;
      width: 12px;
      position: absolute;
      left: 50%;
      background: white;
      border: 1px solid var(--primary-050);
      transform: translate(-6px, -6px) rotate(45deg);
      transform-origin: center;
      border-color: transparent var(--primary-050) var(--primary-050)
        transparent;
      box-shadow:
        0 8px 24px -4px rgba(24, 39, 75, 0.08),
        0px 6px 12px -6px rgba(24, 39, 75, 0.12);
      .no-arrow & {
        content: none;
      }
    }

    .popover-close {
      position: absolute;
      top: 16px;
      right: 8px;
    }
    .no-padding & {
      padding: 0;
      border: none;
    }
  }
</style>
