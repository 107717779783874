<script>
  import styles from "styleguide/ResponderQrcodeExample.json";
  import { t } from "stores/i18n.js";
</script>

<div data-component="ResponderQrcodeExample" class={styles.qrcodeExample}>
  <p class={styles.qrcodeExampleText}>{$t("responder_qrcode_example.title")}</p>
  <img
    src="/assets/responder/qr-code.png"
    class={styles.logo}
    alt="Example QR Code"
  />
</div>

<style lang="scss">
  .qrcodeExample {
    margin-left: 10px;
    padding: 5px;
    border-radius: var(--border-radius);
    border: 1px solid var(--primary-050);
  }

  .qrcodeExampleText {
    margin: 0;
    font-size: 12px;
    line-height: 1.5;
    color: var(--primary-300);
    text-align: center;
  }
</style>
