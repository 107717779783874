<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 240 0 L 480 0 L 480 360 L 240 360 Z M 240 0 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 282.423256 -735.120309 C 282.423256 -734.492482 338.390168 -512.268105 400.606971 -434.574592 C 469.985109 -347.306726 513.777753 -347.228247 513.777753 -347.228247 L 584.65326 -364.964342 C 584.65326 -364.964342 543.139223 -362.871588 460.848983 -469.157361 C 377.386888 -576.934222 351.432477 -755.132274 352.669434 -751.993142 L 282.444957 -735.120309 Z M 282.423256 -735.120309 "
      transform="matrix(-0.180003,0,0,0.149325,400.567536,250.767731)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 284.995805 -727.638656 C 283.455033 -737.003733 318.892786 -506.696091 425.206048 -400.410318 C 478.351828 -347.254352 513.789582 -347.254352 513.789582 -347.254352 L 602.373116 -364.964287 C 602.373116 -364.964287 550.659602 -361.223488 468.347661 -467.509262 C 384.885566 -575.286122 359.755793 -730.777788 360.385123 -731.379455 Z M 284.995805 -727.638656 "
      transform="matrix(0.180003,0,0,0.149325,286.418616,252.127098)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 282.435085 -735.120253 C 282.435085 -734.492427 338.380296 -512.26805 400.597099 -434.574536 C 469.975237 -347.30667 513.789582 -347.228192 513.789582 -347.228192 L 584.643388 -364.964287 C 584.643388 -364.964287 543.151052 -362.871533 460.839111 -469.157306 C 377.398717 -576.934167 351.422605 -755.132219 352.681263 -751.993087 Z M 282.435085 -735.120253 "
      transform="matrix(0.180003,0,0,0.149325,286.418616,252.127098)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 285.005677 -727.638711 C 283.464905 -737.003788 318.902658 -506.696146 425.194219 -400.410373 C 478.339999 -347.254407 513.777753 -347.254407 513.777753 -347.254407 L 602.361287 -364.964342 C 602.361287 -364.964342 550.647773 -361.223544 468.335832 -467.509317 C 384.895438 -575.286178 359.765665 -730.777843 360.394995 -731.37951 Z M 285.005677 -727.638711 "
      transform="matrix(-0.180003,0,0,0.149325,400.567536,250.767731)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 344.5407 -596.2951 L 353.698937 -574.146611 C 353.698937 -574.146611 355.138753 -583.911093 362.220112 -585.685516 L 369.279776 -587.459473 C 369.279776 -587.459473 396.148293 -538.113177 401.460689 -529.278751 C 406.751389 -520.44386 397.914568 -512.482837 399.694181 -512.494912 C 401.45266 -512.480368 426.210679 -523.975693 426.210679 -523.975693 C 426.210679 -523.975693 415.593816 -523.093419 411.187465 -530.168064 C 406.759418 -537.242243 376.364286 -590.097423 376.364286 -590.097423 C 376.364286 -590.097423 382.537012 -592.741947 387.841347 -595.393975 C 393.145682 -598.046003 398.45234 -587.45837 398.45234 -587.45837 L 385.206547 -613.967149 C 385.206547 -613.967149 385.201239 -605.123117 380.789579 -603.35373 C 376.356224 -601.583877 372.83409 -599.833605 372.83409 -599.833605 C 372.83409 -599.833605 369.293543 -606.011064 364.865496 -613.085243 C 360.459145 -620.159888 369.293378 -627.23123 369.293378 -627.23123 L 346.314972 -615.747981 C 346.314972 -615.747981 354.26774 -611.313755 356.923938 -606.896542 C 359.558441 -602.478863 362.214639 -598.06165 362.214639 -598.06165 C 362.214639 -598.06165 358.996807 -596.291751 353.997677 -594.588237 C 350.413265 -592.705799 346.315136 -594.527815 344.5407 -596.2951 Z M 344.5407 -596.2951 "
      transform="matrix(0.179964,0.00320799,-0.00386712,0.14929,285.009528,256.728383)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.552189 -453.637303 C 177.044389 -453.533375 141.784451 -417.974939 141.772385 -382.581989 C 141.760319 -347.189038 177.256053 -311.900015 212.536674 -311.609696 L 407.488808 -311.589928 C 442.73668 -311.755412 478.256547 -347.252291 478.268613 -382.645242 C 478.409609 -419.799852 443.012123 -453.721463 407.504323 -453.617535 Z M 212.552189 -453.637303 "
      transform="matrix(0.0132236,0.00206469,0.0076199,-0.00871837,308.270961,247.223355)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.443855 -453.389054 C 177.256761 -453.45758 141.652433 -418.142676 141.753535 -382.619241 C 141.595469 -347.165809 177.115307 -311.9606 212.561569 -311.822072 L 407.34514 -311.780298 C 442.791402 -311.64177 478.368205 -347.413427 478.294628 -382.480108 C 478.342596 -419.760551 442.905331 -453.595503 407.459069 -453.734031 Z M 212.443855 -453.389054 "
      transform="matrix(0.0129735,0.00234822,0.00777042,-0.00869373,307.032515,248.207931)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.469712 -453.382568 C 177.189091 -453.672888 141.669224 -418.176009 141.657158 -382.783058 C 141.905021 -347.328551 177.173576 -311.645281 212.681376 -311.749209 L 407.373582 -311.790998 C 442.881382 -311.894926 478.401249 -347.391804 478.413315 -382.784755 C 478.327132 -419.545118 442.929647 -453.466728 407.421846 -453.3628 Z M 212.469712 -453.382568 "
      transform="matrix(0.0132236,0.00206469,0.0076199,-0.00871837,305.469329,249.417152)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.770335 -453.738846 C 177.170756 -453.523494 141.688127 -417.974802 141.805077 -382.641463 C 141.622171 -347.283409 177.198295 -311.765466 212.797874 -311.980818 L 407.504431 -311.71598 C 442.804155 -311.906619 478.146379 -347.055354 478.46969 -382.813364 C 478.314649 -419.697102 443.076472 -453.689361 407.476893 -453.474009 Z M 212.770335 -453.738846 "
      transform="matrix(0.0134152,-0.000828934,0.00470943,-0.0100577,322.141862,234.272964)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.672847 -453.701765 C 177.262548 -453.482888 141.565123 -418.041762 141.873236 -382.836346 C 141.731729 -347.226946 177.298141 -311.84484 212.556439 -311.66815 L 407.452321 -311.702103 C 442.86262 -311.92098 478.262425 -347.353689 478.251932 -382.567522 C 478.406199 -419.742355 442.827027 -453.559028 407.568728 -453.735718 Z M 212.672847 -453.701765 "
      transform="matrix(0.0132692,-0.000282338,0.0050988,-0.00998356,321.260869,235.38986)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.593426 -453.385401 C 177.186658 -453.579488 141.749681 -418.147845 141.71947 -382.522114 C 141.836692 -347.296828 177.063212 -311.859351 212.469979 -311.665264 L 407.352027 -311.781013 C 442.906227 -311.98737 478.343205 -347.419013 478.225983 -382.6443 C 478.250983 -419.835599 443.029674 -453.707507 407.475474 -453.50115 Z M 212.593426 -453.385401 "
      transform="matrix(0.0134325,-0.000607252,0.0049455,-0.00997838,320.081861,236.874577)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.655954 -453.362801 C 177.167793 -453.696192 141.779585 -418.157635 141.764775 -382.693683 C 141.749964 -347.22973 177.10855 -311.840382 212.770919 -311.902426 L 407.439367 -311.904754 C 443.101736 -311.966799 478.315736 -347.10992 478.330547 -382.573873 C 478.464246 -419.644685 442.986771 -453.427174 407.324403 -453.365129 Z M 212.655954 -453.362801 "
      transform="matrix(0.0132638,0.000421274,0.00584333,-0.00969277,319.336335,237.289512)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.601872 -453.486421 C 177.157454 -453.38226 141.787045 -418.050418 141.861055 -382.822736 C 141.833415 -347.200488 177.250193 -311.6824 212.694612 -311.786561 L 407.537264 -311.96488 C 442.880032 -311.674473 478.352091 -347.400883 478.278081 -382.628565 C 478.401705 -419.769029 442.787292 -453.374334 407.444524 -453.66474 Z M 212.601872 -453.486421 "
      transform="matrix(0.013235,-0.00201436,0.00340965,-0.010419,325.427769,229.410397)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.534014 -453.5305 C 177.087656 -453.456271 141.622468 -418.21008 141.808731 -382.696882 C 141.683743 -347.133752 177.005114 -311.644852 212.451472 -311.719081 L 407.508985 -311.956722 C 442.849185 -311.639784 478.42053 -347.277142 478.234268 -382.79034 C 478.472636 -419.868209 442.931727 -453.451202 407.485369 -453.376973 Z M 212.534014 -453.5305 "
      transform="matrix(0.0131215,-0.00167489,0.00356099,-0.0104407,324.689542,230.698931)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.760164 -453.643083 C 177.005127 -453.478869 141.634719 -418.147027 141.607078 -382.524778 C 141.681089 -347.297096 177.097867 -311.779009 212.542285 -311.88317 L 407.593905 -311.726974 C 443.038323 -311.831135 478.408732 -347.162978 478.436372 -382.785226 C 478.249379 -419.865638 442.945584 -453.530996 407.501165 -453.426835 Z M 212.760164 -453.643083 "
      transform="matrix(0.013235,-0.00201436,0.00340965,-0.010419,323.742615,232.295802)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.694701 -453.594507 C 177.033553 -453.470893 141.853007 -418.040585 141.714684 -382.646839 C 141.885824 -347.29662 177.099187 -311.982962 212.64693 -311.714304 L 407.375287 -311.631668 C 443.036434 -311.755282 478.216981 -347.185589 478.355303 -382.579335 C 478.441724 -419.847392 443.084205 -453.635485 407.423058 -453.511871 Z M 212.694701 -453.594507 "
      transform="matrix(0.0131577,-0.00145997,0.00380385,-0.0103801,322.856511,233.4225)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.602977 -453.533461 C 177.180931 -453.341233 141.515501 -417.960584 141.821002 -382.702814 C 141.650737 -347.080827 177.176959 -311.616394 212.599006 -311.808622 L 407.603054 -311.781961 C 443.025101 -311.974188 478.416088 -347.389512 478.385029 -382.612607 C 478.335941 -419.53529 443.029072 -453.699028 407.607026 -453.5068 Z M 212.602977 -453.533461 "
      transform="matrix(0.0133802,0.00116309,0.00675312,-0.00920575,313.464186,242.97214)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.711432 -453.472253 C 177.156682 -453.605073 141.57153 -418.042051 141.746981 -382.73846 C 141.716579 -347.042619 177.172792 -311.649809 212.659406 -311.95284 L 407.697346 -311.94151 C 442.978107 -311.852292 478.563259 -347.415313 478.387808 -382.718904 C 478.213804 -419.722297 442.756144 -453.415305 407.475383 -453.504524 Z M 212.711432 -453.472253 "
      transform="matrix(0.0131581,0.00146259,0.00690539,-0.009191,312.324714,244.036651)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.529056 -453.458183 C 177.308334 -453.664847 141.917346 -418.249523 141.747081 -382.627536 C 141.77814 -347.404441 177.304362 -311.940007 212.525085 -311.733344 L 407.529133 -311.706683 C 442.95118 -311.89891 478.342167 -347.314234 478.311108 -382.537329 C 478.463344 -419.858904 442.955151 -453.62375 407.533105 -453.431522 Z M 212.529056 -453.458183 "
      transform="matrix(0.0133802,0.00116309,0.00675312,-0.00920575,310.882635,245.347919)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.713186 -453.584507 C 177.266924 -453.723035 141.690121 -417.951378 141.763698 -382.884696 C 141.864799 -347.361262 177.152995 -311.769302 212.599257 -311.630774 L 407.614471 -311.97575 C 443.060733 -311.837222 478.405893 -347.222129 478.304792 -382.745563 C 478.380284 -419.569253 442.943019 -453.404205 407.496757 -453.542733 Z M 212.713186 -453.584507 "
      transform="matrix(0.0129735,0.00234822,0.00777042,-0.00869373,309.835227,246.014193)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.698168 -453.486254 C 177.262546 -453.469469 141.642135 -418.004354 141.74641 -382.552875 C 141.56162 -347.104545 177.101518 -311.669851 212.53714 -311.686637 L 407.517873 -311.979456 C 442.953494 -311.996242 478.404279 -347.06036 478.300004 -382.511839 C 478.296103 -419.573604 442.944896 -453.394862 407.509274 -453.378077 Z M 212.698168 -453.486254 "
      transform="matrix(0.0134514,0.000105642,0.0056901,-0.00969666,318.047422,238.709133)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.676321 -453.578931 C 177.013953 -453.516886 141.625745 -417.978329 141.610934 -382.514377 C 141.596123 -347.050424 177.243681 -311.648516 212.617078 -311.723121 L 407.574498 -311.712889 C 442.947895 -311.787493 478.336103 -347.32605 478.350914 -382.790002 C 478.484614 -419.860815 443.007138 -453.643303 407.633741 -453.568699 Z M 212.676321 -453.578931 "
      transform="matrix(0.0132638,0.000421274,0.00584333,-0.00969277,317.03264,239.857721)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.755872 -453.605224 C 177.059384 -453.447671 141.668397 -418.032347 141.699456 -382.809252 C 141.803632 -347.152591 177.055413 -311.722831 212.751901 -311.880385 L 407.481508 -311.888398 C 442.976672 -311.64706 478.367659 -347.062384 478.263483 -382.719045 C 478.214395 -419.641728 442.706201 -453.406574 407.485479 -453.613237 Z M 212.755872 -453.605224 "
      transform="matrix(0.0133802,0.00116309,0.00675312,-0.00920575,316.044656,240.596302)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.512212 -453.588709 C 177.231451 -453.677928 141.646298 -418.114906 141.82175 -382.811315 C 141.791347 -347.115474 177.24756 -311.722664 212.528321 -311.633445 L 407.292272 -311.665716 C 443.052875 -311.925147 478.158185 -347.139519 478.462576 -382.791759 C 478.288573 -419.795152 442.830912 -453.48816 407.550151 -453.577379 Z M 212.512212 -453.588709 "
      transform="matrix(0.0131581,0.00146259,0.00690539,-0.009191,314.906264,241.660872)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.60912 -453.632233 C 177.314231 -453.485198 141.629128 -418.024449 141.854214 -382.584614 C 141.785253 -347.394323 177.278022 -311.728558 212.572911 -311.875593 L 407.402711 -311.687463 C 443.018852 -311.957921 478.382702 -347.295248 478.478869 -382.858505 C 478.362607 -419.790202 443.055061 -453.714561 407.43892 -453.444103 Z M 212.60912 -453.632233 "
      transform="matrix(0.01251,-0.00413985,0.000912544,-0.0107755,332.738586,213.167854)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.447671 -453.415982 C 177.064887 -453.540792 141.663872 -417.938499 141.707768 -382.59297 C 141.751664 -347.24744 177.178344 -311.7771 212.561128 -311.65229 L 407.641243 -312.007505 C 443.024027 -311.882695 478.362915 -347.103414 478.381146 -382.830517 C 478.266273 -419.614599 442.91057 -453.646386 407.465659 -453.389624 Z M 212.447671 -453.415982 "
      transform="matrix(0.012799,-0.00294299,0.00208391,-0.0107164,332.717944,213.609992)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.510002 -453.5992 C 177.305361 -453.722981 141.913705 -418.314075 141.726691 -382.781388 C 141.858095 -347.346818 177.19414 -311.788468 212.7172 -311.762804 L 407.355716 -311.759874 C 442.878776 -311.73421 478.270431 -347.143116 478.457446 -382.675803 C 478.238556 -419.546304 442.989996 -453.668723 407.466936 -453.694387 Z M 212.510002 -453.5992 "
      transform="matrix(0.0128644,-0.0032912,0.00193659,-0.0106809,332.000096,215.28815)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.68705 -453.577264 C 177.304266 -453.702074 141.583766 -418.012043 141.627662 -382.666513 C 141.671558 -347.320984 177.098238 -311.850644 212.481022 -311.725834 L 407.49901 -311.699476 C 442.943921 -311.956239 478.282809 -347.176958 478.238913 -382.522488 C 478.505652 -419.775881 442.768338 -453.338357 407.385553 -453.463167 Z M 212.68705 -453.577264 "
      transform="matrix(0.012799,-0.00294299,0.00208391,-0.0107164,331.445685,216.640218)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.756701 -453.691641 C 177.049881 -453.464529 141.806568 -418.02339 141.624861 -382.583481 C 141.765761 -347.256512 177.259393 -311.671711 212.643606 -311.785882 L 407.35512 -311.726286 C 443.06194 -311.953398 478.305254 -347.394537 478.486961 -382.834446 C 478.471986 -419.64943 442.820947 -453.374212 407.468215 -453.632044 Z M 212.756701 -453.691641 "
      transform="matrix(0.0124781,-0.00378836,0.00105597,-0.0108212,334.273511,208.341841)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.755957 -453.677263 C 177.139815 -453.406805 141.775965 -418.069479 141.679799 -382.506221 C 141.610838 -347.31593 177.103606 -311.650165 212.719748 -311.920623 L 407.549548 -311.732493 C 442.844437 -311.879528 478.208287 -347.216855 478.304454 -382.780112 C 478.509444 -419.835232 442.880646 -453.636169 407.585757 -453.489133 Z M 212.755957 -453.677263 "
      transform="matrix(0.01251,-0.00413985,0.000912544,-0.0107755,333.717259,210.062507)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.609376 -453.651399 C 177.225163 -453.537228 141.659242 -417.983148 141.800142 -382.65618 C 141.618435 -347.216271 177.112067 -311.63147 212.49628 -311.745641 L 407.530401 -311.798985 C 442.914615 -311.913156 478.480535 -347.467236 478.339635 -382.794205 C 478.32466 -419.609188 442.996229 -453.446911 407.643497 -453.704744 Z M 212.609376 -453.651399 "
      transform="matrix(0.0124781,-0.00378836,0.00105597,-0.0108212,333.294838,211.447187)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.590689 -453.658382 C 177.210188 -453.67665 141.86447 -418.050242 141.673526 -382.724938 C 141.70831 -347.080263 177.211418 -311.808937 212.59192 -311.790669 L 407.341452 -311.726318 C 443.035505 -311.780296 478.381223 -347.406703 478.258615 -382.659761 C 478.261576 -419.798662 443.034274 -453.648008 407.653773 -453.666277 Z M 212.590689 -453.658382 "
      transform="matrix(0.0131369,-0.00242352,0.00294607,-0.0105181,328.543729,223.72795)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.567693 -453.609838 C 177.230781 -453.675331 141.833455 -418.161886 141.773041 -382.582947 C 141.804935 -347.392716 177.081434 -311.748285 212.732674 -311.744826 L 407.446174 -311.641002 C 442.875395 -311.964216 478.180413 -347.088954 478.240827 -382.667892 C 478.356146 -419.739625 442.932434 -453.502554 407.595522 -453.568047 Z M 212.567693 -453.609838 "
      transform="matrix(0.0130383,-0.00208078,0.00309629,-0.0105435,327.863446,225.038587)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.70656 -453.483355 C 177.012507 -453.429377 141.754614 -418.194587 141.789397 -382.549912 C 141.598453 -347.224608 177.101562 -311.953282 212.482063 -311.935014 L 407.545147 -311.942909 C 442.925648 -311.924641 478.183542 -347.159431 478.46231 -382.876352 C 478.377447 -419.623636 442.836594 -453.400736 407.456093 -453.419004 Z M 212.70656 -453.483355 "
      transform="matrix(0.0131369,-0.00242352,0.00294607,-0.0105181,326.987004,226.663666)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.685204 -453.428956 C 177.345004 -453.745895 141.773659 -418.108537 141.648671 -382.545407 C 141.629842 -347.373446 177.262462 -311.934476 212.70882 -312.008705 L 407.348926 -311.805248 C 442.795283 -311.879477 478.260471 -347.125668 478.385458 -382.688797 C 478.312577 -419.716735 442.877825 -453.690896 407.431468 -453.616667 Z M 212.685204 -453.428956 "
      transform="matrix(0.0131215,-0.00167489,0.00356099,-0.0104407,326.374697,227.813526)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.74597 -453.750836 C 177.165177 -453.392988 141.773522 -417.984081 141.586507 -382.451394 C 141.775645 -347.400337 177.11169 -311.841986 212.63475 -311.816323 L 407.591684 -311.911509 C 442.796325 -311.787729 478.187981 -347.196635 478.374995 -382.729322 C 478.474525 -419.69794 442.849812 -453.33873 407.326752 -453.364394 Z M 212.74597 -453.750836 "
      transform="matrix(0.0128644,-0.0032912,0.00193659,-0.0106809,330.727823,218.318557)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.737472 -453.679758 C 177.086232 -453.683218 141.688905 -418.169773 141.628492 -382.590835 C 141.660386 -347.400604 177.251213 -311.818206 212.588125 -311.752713 L 407.301625 -311.648889 C 442.952866 -311.64543 478.350192 -347.158875 478.410606 -382.737813 C 478.211597 -419.747512 442.787885 -453.510441 407.450973 -453.575934 Z M 212.737472 -453.679758 "
      transform="matrix(0.0130383,-0.00208078,0.00309629,-0.0105435,330.97473,219.16711)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.473594 -453.458802 C 177.093093 -453.47707 141.835199 -418.242281 141.556431 -382.525359 C 141.679039 -347.272301 177.094324 -311.609358 212.562649 -311.982708 L 407.625733 -311.990603 C 443.006234 -311.972335 478.264128 -347.207124 478.455072 -382.532428 C 478.458033 -419.671329 442.917179 -453.448429 407.536678 -453.466697 Z M 212.473594 -453.458802 "
      transform="matrix(0.0131369,-0.00242352,0.00294607,-0.0105181,330.099367,220.792266)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.448638 -453.415873 C 177.111726 -453.481366 141.7144 -417.967921 141.746294 -382.77769 C 141.68588 -347.198752 177.054687 -311.943028 212.705928 -311.939568 L 407.419428 -311.835744 C 442.75634 -311.770251 478.467995 -347.34573 478.4361 -382.535961 C 478.237091 -419.54566 442.905687 -453.697296 407.476467 -453.374082 Z M 212.448638 -453.415873 "
      transform="matrix(0.0130383,-0.00208078,0.00309629,-0.0105435,329.419085,222.102885)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.588539 -453.378673 C 177.086226 -453.620447 141.78139 -418.025368 141.589323 -382.70615 C 141.752407 -347.213947 177.062653 -311.652956 212.530573 -311.755832 L 407.478143 -311.891167 C 442.946062 -311.994043 478.285292 -347.244473 478.477359 -382.563691 C 478.497454 -419.606152 443.004029 -453.616885 407.53611 -453.514009 Z M 212.588539 -453.378673 "
      transform="matrix(0.0115608,-0.00575821,-0.00115371,-0.0107594,336.644237,199.881222)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.449674 -453.310136 C 177.242674 -453.672331 141.632491 -418.026322 141.571225 -382.517344 C 141.831563 -347.137358 177.319215 -311.765411 212.54653 -311.77345 L 407.552702 -311.778282 C 442.780016 -311.786321 478.3902 -347.432331 478.43115 -382.571075 C 478.272388 -419.802231 443.004764 -453.452 407.476161 -453.685203 Z M 212.449674 -453.310136 "
      transform="matrix(0.0124195,-0.00432705,0.000681472,-0.0107882,336.678213,201.036698)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.564943 -453.452635 C 177.270715 -453.482675 141.830807 -418.130642 141.685126 -382.748569 C 141.837907 -347.115491 177.309476 -311.821879 212.603703 -311.791839 L 407.367999 -311.863622 C 442.985248 -311.952083 478.425156 -347.304117 478.247816 -382.567689 C 478.193272 -419.67879 442.946487 -453.61288 407.329238 -453.524418 Z M 212.564943 -453.452635 "
      transform="matrix(0.0123951,-0.00397514,0.000823849,-0.0108358,336.285689,202.427566)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.660228 -453.634302 C 177.288901 -453.648457 141.673522 -418.173062 141.752136 -382.844698 C 141.861185 -347.170644 177.311127 -311.828126 212.682454 -311.81397 L 407.401303 -311.643864 C 442.77263 -311.629709 478.388009 -347.105104 478.27896 -382.779158 C 478.401273 -419.651471 443.073071 -453.611228 407.379077 -453.464196 Z M 212.660228 -453.634302 "
      transform="matrix(0.0115961,-0.00540701,-0.00102093,-0.0108238,337.238802,194.91168)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.669283 -453.559035 C 177.201364 -453.45616 141.862134 -418.205729 141.704461 -382.541863 C 141.833151 -347.394309 177.143397 -311.833318 212.611316 -311.936193 L 407.593281 -311.72688 C 443.0612 -311.829756 478.366036 -347.424835 478.237346 -382.572389 C 478.257442 -419.614851 442.764016 -453.625583 407.616854 -453.694371 Z M 212.669283 -453.559035 "
      transform="matrix(0.0115608,-0.00575821,-0.00115371,-0.0107594,337.018095,196.684434)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.564512 -453.533714 C 177.193185 -453.54787 141.577806 -418.072474 141.65642 -382.744111 C 141.735035 -347.415748 177.215411 -311.727538 212.586738 -311.713383 L 407.628254 -311.704464 C 442.999581 -311.690309 478.261859 -347.350208 478.183244 -382.678571 C 478.305557 -419.550884 442.977355 -453.51064 407.606028 -453.524796 Z M 212.564512 -453.533714 "
      transform="matrix(0.0115961,-0.00540701,-0.00102093,-0.0108238,336.865015,198.107563)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.562862 -453.461755 C 177.013944 -453.324723 141.745679 -418.17794 141.684413 -382.668962 C 141.623147 -347.159983 177.1108 -311.788037 212.659718 -311.925068 L 407.344286 -311.800908 C 442.893204 -311.937939 478.483073 -347.213715 478.222735 -382.593701 C 478.365261 -419.583615 442.796348 -453.474626 407.569033 -453.466587 Z M 212.562862 -453.461755 "
      transform="matrix(0.0124195,-0.00432705,0.000681472,-0.0107882,335.766754,204.156646)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.487212 -453.627901 C 177.102999 -453.51373 141.859686 -418.072591 141.677979 -382.632682 C 141.818879 -347.305713 177.312511 -311.720912 212.696724 -311.835083 L 407.408238 -311.775487 C 442.792451 -311.889658 478.32689 -347.071734 478.217472 -382.770707 C 478.202497 -419.58569 442.874065 -453.423413 407.521333 -453.681245 Z M 212.487212 -453.627901 "
      transform="matrix(0.0124781,-0.00378836,0.00105597,-0.0108212,335.276807,205.157916)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.719231 -453.688366 C 177.103089 -453.417909 141.739239 -418.080582 141.643072 -382.517325 C 141.895364 -347.450456 177.06688 -311.661268 212.683022 -311.931726 L 407.512822 -311.743596 C 442.807711 -311.890632 478.492813 -347.351381 478.267727 -382.791215 C 478.472718 -419.846335 442.84392 -453.647272 407.549031 -453.500237 Z M 212.719231 -453.688366 "
      transform="matrix(0.01251,-0.00413985,0.000912544,-0.0107755,334.721635,206.878642)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.727871 -453.564591 C 177.087111 -453.636366 141.721123 -418.110929 141.724051 -382.553475 C 141.933284 -347.394247 177.305127 -311.804776 212.674042 -311.772759 L 407.545305 -311.975012 C 442.914221 -311.942995 478.345749 -347.030448 478.342821 -382.587902 C 478.349578 -419.857532 442.96805 -453.734827 407.392829 -453.368619 Z M 212.727871 -453.564591 "
      transform="matrix(-0.0133573,0.00133357,-0.00691992,-0.00911828,377.366912,242.986836)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.59175 -453.507174 C 177.141055 -453.419181 141.768385 -418.125619 141.63559 -382.528643 C 141.713614 -347.323073 177.031514 -311.814089 212.753541 -311.853485 L 407.551801 -311.726036 C 443.002496 -311.814029 478.375167 -347.107591 478.507962 -382.704568 C 478.187889 -419.670153 442.840706 -453.467718 407.390011 -453.379725 Z M 212.59175 -453.507174 "
      transform="matrix(-0.01313,0.00163023,-0.00707205,-0.00910196,378.525501,244.037341)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.669823 -453.653571 C 177.300907 -453.685588 141.663075 -418.199909 141.666002 -382.642455 C 141.66893 -347.085001 177.247079 -311.893756 212.615994 -311.861739 L 407.280952 -311.665766 C 442.921712 -311.593991 478.2877 -347.119428 478.284772 -382.676882 C 478.357069 -419.508528 442.975541 -453.385824 407.334781 -453.457599 Z M 212.669823 -453.653571 "
      transform="matrix(-0.0133573,0.00133357,-0.00691992,-0.00911828,379.990521,245.329852)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.52392 -453.717102 C 177.326072 -453.680793 141.720822 -418.294629 141.810667 -382.640893 C 141.710182 -347.337676 176.997875 -311.720251 212.50264 -311.803198 L 407.508804 -311.838932 C 443.013569 -311.92188 478.311903 -347.261406 478.412388 -382.564623 C 478.481973 -419.760346 442.918262 -453.438628 407.413497 -453.355681 Z M 212.52392 -453.717102 "
      transform="matrix(-0.0133216,-0.00156434,-0.00391062,-0.0102948,364.541222,229.302172)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.442745 -453.439771 C 177.264354 -453.400186 141.881649 -418.252751 141.854326 -382.780983 C 141.827002 -347.309215 177.283394 -311.907167 212.767108 -311.976887 L 407.429715 -311.739133 C 442.913429 -311.808853 478.424467 -347.350342 478.45179 -382.822111 C 478.381797 -419.809825 443.022722 -453.695927 407.539007 -453.626206 Z M 212.442745 -453.439771 "
      transform="matrix(-0.0132187,-0.0010109,-0.00430497,-0.0102422,365.332744,230.465069)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.480206 -453.385882 C 177.193757 -453.713716 141.858442 -418.137017 141.628439 -382.590576 C 141.70336 -347.084204 177.188515 -311.648159 212.598751 -311.718487 L 407.383721 -311.72716 C 442.793956 -311.797487 478.434194 -347.414255 478.235487 -382.522466 C 478.35079 -419.581414 442.980334 -453.504951 407.570098 -453.434624 Z M 212.480206 -453.385882 "
      transform="matrix(-0.0133563,-0.00134408,-0.00415241,-0.0102286,366.392799,232.011369)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.703151 -453.604014 C 177.109071 -453.677837 141.760423 -418.187251 141.707939 -382.613644 C 141.806626 -347.435606 177.050308 -311.778981 212.644387 -311.705158 L 407.595879 -311.860296 C 442.892044 -311.777276 478.240693 -347.267863 478.293176 -382.841469 C 478.203344 -419.583384 442.950808 -453.676133 407.503472 -453.363584 Z M 212.703151 -453.604014 "
      transform="matrix(-0.0132686,-0.000308508,-0.00507072,-0.00999294,367.104194,232.466376)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.533404 -453.567095 C 177.228133 -453.663023 141.781052 -418.291124 141.639242 -382.823296 C 141.812958 -347.437072 177.291945 -311.95492 212.597215 -311.858992 L 407.445792 -311.689098 C 442.751063 -311.59317 478.275216 -347.354076 478.417026 -382.821904 C 478.236072 -419.682554 443.002778 -453.382876 407.381981 -453.397201 Z M 212.533404 -453.567095 "
      transform="matrix(-0.0130487,-0.0027373,-0.00258528,-0.0105839,361.643666,224.269536)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.476751 -453.63522 C 177.169359 -453.405759 141.711276 -418.179064 141.79547 -382.866738 C 141.798144 -347.168004 177.10821 -311.69873 212.732007 -311.999508 L 407.346836 -311.700997 C 442.970633 -312.001775 478.428715 -347.22847 478.344522 -382.540795 C 478.351523 -419.713848 443.031781 -453.708803 407.407985 -453.408026 Z M 212.476751 -453.63522 "
      transform="matrix(-0.0129621,-0.00239232,-0.0027346,-0.0106138,362.279266,225.595855)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.72436 -453.699438 C 177.026492 -453.324754 141.579411 -417.952855 141.753127 -382.566631 C 141.611316 -347.098804 177.090303 -311.616652 212.472646 -311.909731 L 407.321223 -311.739837 C 442.94202 -311.725512 478.3891 -347.097412 478.215385 -382.483635 C 478.427029 -419.814897 442.878208 -453.433615 407.572937 -453.529543 Z M 212.72436 -453.699438 "
      transform="matrix(-0.0130487,-0.0027373,-0.00258528,-0.0105839,363.098941,227.241314)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.544686 -453.428266 C 177.150339 -453.52007 141.72792 -418.117186 141.699848 -382.622498 C 141.671776 -347.127811 177.126943 -311.929333 212.52129 -311.837528 L 407.59411 -311.785607 C 442.988457 -311.693803 478.410877 -347.096686 478.438948 -382.591374 C 478.192549 -419.508144 442.785727 -453.607567 407.391379 -453.699371 Z M 212.544686 -453.428266 "
      transform="matrix(-0.0130152,-0.00217987,-0.00298169,-0.0105667,363.894794,228.414273)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.416074 -453.391446 C 177.119398 -453.736055 141.708199 -418.11724 141.773044 -382.554565 C 141.73235 -347.405321 177.273239 -311.898788 212.749283 -311.954929 L 407.403249 -311.875634 C 442.879292 -311.931775 478.39603 -347.137161 478.331185 -382.699835 C 478.413999 -419.890871 442.83099 -453.355613 407.534314 -453.700222 Z M 212.416074 -453.391446 "
      transform="matrix(-0.0134429,0.000425314,-0.00601676,-0.00955697,372.518162,238.459422)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.648407 -453.72753 C 177.196585 -453.597891 141.870751 -418.28413 141.812816 -382.705185 C 141.754881 -347.12624 177.148768 -311.676935 212.60059 -311.806574 L 407.59443 -311.905404 C 442.862329 -311.640219 478.372086 -347.348804 478.246098 -382.532925 C 478.470319 -419.735068 442.910146 -453.561176 407.458323 -453.431536 Z M 212.648407 -453.72753 "
      transform="matrix(-0.0132447,0.000736369,-0.00616987,-0.00955062,373.57172,239.584251)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.778369 -453.701174 C 177.302325 -453.645033 141.60622 -418.038897 141.671065 -382.476222 C 141.630371 -347.326978 177.17126 -311.820445 212.647303 -311.876586 L 407.586175 -311.784612 C 443.062219 -311.840753 478.29405 -347.058818 478.229205 -382.621492 C 478.312019 -419.812528 442.908378 -453.67802 407.432335 -453.621879 Z M 212.778369 -453.701174 "
      transform="matrix(-0.0134429,0.000425314,-0.00601676,-0.00955697,374.907888,240.971932)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.573556 -453.429798 C 177.122861 -453.341805 141.75019 -418.048243 141.828214 -382.842673 C 141.69542 -347.245697 177.01332 -311.736714 212.464015 -311.824706 L 407.533607 -311.64866 C 442.984302 -311.736653 478.356973 -347.030216 478.218436 -382.675789 C 478.169695 -419.592777 442.822511 -453.390342 407.582635 -453.693756 Z M 212.573556 -453.429798 "
      transform="matrix(-0.01313,0.00163023,-0.00707205,-0.00910196,375.900809,241.694325)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.660989 -453.392787 C 177.214286 -453.49016 141.826764 -418.003163 141.73478 -382.800279 C 141.793961 -347.215909 177.14868 -311.915652 212.595383 -311.818279 L 407.401084 -311.664212 C 442.994378 -311.967219 478.235309 -347.053836 478.473884 -382.657099 C 478.405558 -419.805203 442.762228 -453.522834 407.613282 -453.6391 Z M 212.660989 -453.392787 "
      transform="matrix(-0.013431,-0.000633765,-0.00491751,-0.00998841,368.279025,233.953761)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.613426 -453.425446 C 177.019347 -453.499269 141.670698 -418.008682 141.769385 -382.830644 C 141.716901 -347.257038 177.111753 -311.99598 212.705833 -311.922158 L 407.506154 -311.681728 C 442.95349 -311.994276 478.150968 -347.089295 478.203452 -382.662901 C 478.26479 -419.800384 442.861083 -453.497564 407.564918 -453.580584 Z M 212.613426 -453.425446 "
      transform="matrix(-0.0132686,-0.000308508,-0.00507072,-0.00999294,369.201565,235.155633)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.597028 -453.466274 C 177.120984 -453.410133 141.889153 -418.192068 141.669092 -382.642073 C 141.733937 -347.079399 177.169287 -311.986296 212.465962 -311.641686 L 407.584203 -311.950463 C 442.880878 -311.605853 478.292077 -347.224669 478.512139 -382.774664 C 478.415585 -419.564949 442.727038 -453.443121 407.5359 -453.3743 Z M 212.597028 -453.466274 "
      transform="matrix(-0.0134429,0.000425314,-0.00601676,-0.00955697,370.129519,235.946911)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.56734 -453.407713 C 177.299441 -453.672897 141.789684 -417.964313 141.915672 -382.780191 C 141.857737 -347.201246 177.251625 -311.751941 212.703447 -311.88158 L 407.697287 -311.98041 C 442.965186 -311.715226 478.474943 -347.42381 478.348954 -382.607931 C 478.288472 -419.832025 443.013002 -453.636182 407.56118 -453.506542 Z M 212.56734 -453.407713 "
      transform="matrix(-0.0132447,0.000736369,-0.00616987,-0.00955062,371.181995,237.07174)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.652145 -453.556567 C 177.312991 -453.704503 141.780393 -417.96664 141.588904 -382.443324 C 141.719462 -347.063657 177.191129 -311.898536 212.530284 -311.750601 L 407.543636 -311.949215 C 442.882791 -311.801279 478.413434 -347.176659 478.282876 -382.556326 C 478.484135 -419.892056 443.004652 -453.607246 407.343451 -453.611533 Z M 212.652145 -453.556567 "
      transform="matrix(-0.0121587,-0.00481836,-0.0000655415,-0.0108023,355.622251,207.660317)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.601319 -453.533844 C 177.08929 -453.500866 141.823729 -418.130826 141.747996 -382.685249 C 141.672263 -347.239672 177.108559 -311.827073 212.620588 -311.860051 L 407.59608 -311.745977 C 442.785908 -311.67044 478.37367 -347.148995 478.449404 -382.594572 C 478.387627 -419.800983 442.766639 -453.344233 407.576811 -453.41977 Z M 212.601319 -453.533844 "
      transform="matrix(-0.0125409,-0.00363997,-0.00123904,-0.0108078,355.608175,208.102635)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.574249 -453.660933 C 177.166912 -453.597191 141.770042 -417.9741 141.813148 -382.789742 C 141.823616 -347.230392 177.241422 -311.734786 212.648759 -311.798528 L 407.356478 -311.77412 C 442.763815 -311.837863 478.448944 -347.20494 478.438476 -382.764289 C 478.237662 -419.704625 442.977565 -453.444254 407.602865 -453.755503 Z M 212.574249 -453.660933 "
      transform="matrix(-0.0125788,-0.00399101,-0.00109481,-0.0107643,356.192883,209.816617)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.656571 -453.566612 C 177.144541 -453.533635 141.87898 -418.163595 141.803247 -382.718018 C 141.727514 -347.272441 177.16381 -311.859841 212.67584 -311.892819 L 407.32913 -311.670231 C 442.841159 -311.703209 478.428922 -347.181763 478.182453 -382.518825 C 478.442878 -419.833751 442.82189 -453.377002 407.309861 -453.344024 Z M 212.656571 -453.566612 "
      transform="matrix(-0.0125409,-0.00363997,-0.00123904,-0.0108078,356.640077,211.196232)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.72486 -453.581427 C 177.052245 -453.659448 141.745255 -418.264718 141.787249 -382.658832 C 141.835357 -347.415291 177.232447 -311.960593 212.581429 -311.749436 L 407.339752 -311.627072 C 443.018481 -311.911397 478.325471 -347.306126 478.277363 -382.549667 C 478.259825 -419.604933 442.838279 -453.610251 407.483183 -453.459063 Z M 212.72486 -453.581427 "
      transform="matrix(-0.0121544,-0.00446587,-0.000205081,-0.0108558,354.469076,202.760377)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.439271 -453.468823 C 177.100117 -453.616759 141.889566 -418.022545 141.698077 -382.499229 C 141.828635 -347.119562 177.300302 -311.954441 212.639457 -311.806505 L 407.330762 -311.861472 C 442.991964 -311.857184 478.20056 -347.088915 478.392049 -382.612231 C 478.271261 -419.804313 442.791778 -453.519502 407.452624 -453.667438 Z M 212.439271 -453.468823 "
      transform="matrix(-0.0121587,-0.00481836,-0.0000655415,-0.0108023,354.8892,204.507895)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.615067 -453.529087 C 177.266084 -453.740244 141.635462 -418.212379 141.677456 -382.606492 C 141.725563 -347.362951 177.122653 -311.908253 212.471636 -311.697096 L 407.553592 -311.707869 C 442.908688 -311.859057 478.215677 -347.253786 478.491203 -382.630464 C 478.473665 -419.68573 443.052119 -453.691048 407.37339 -453.406723 Z M 212.615067 -453.529087 "
      transform="matrix(-0.0121544,-0.00446587,-0.000205081,-0.0108558,355.202127,205.912798)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.696496 -453.540366 C 177.036961 -453.662695 141.552858 -417.916582 141.854381 -382.818357 C 141.775166 -347.241415 177.100838 -311.833644 212.442681 -311.617707 L 407.520012 -311.918344 C 442.861855 -311.702406 478.282912 -347.063411 478.362128 -382.640353 C 478.37583 -419.664119 442.797977 -453.531457 407.393089 -453.362286 Z M 212.696496 -453.540366 "
      transform="matrix(-0.0129188,-0.00314019,-0.00211491,-0.0106573,358.979994,218.42813)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.535327 -453.432648 C 177.318273 -453.732857 141.864531 -418.163782 141.695303 -382.677503 C 141.84479 -347.274481 177.27879 -311.954255 212.495844 -311.654045 L 407.415624 -311.784686 C 443.018852 -311.950739 478.405135 -347.136809 478.255648 -382.539831 C 478.443456 -419.857875 443.058335 -453.729341 407.455107 -453.563288 Z M 212.535327 -453.432648 "
      transform="matrix(-0.0128473,-0.00279277,-0.00226282,-0.0106909,359.55604,219.77332)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.622849 -453.584594 C 177.217962 -453.415423 141.796904 -418.054419 141.780734 -382.862586 C 141.701519 -347.285644 177.344885 -311.971482 212.686727 -311.755544 L 407.446365 -311.962572 C 442.788208 -311.746635 478.209265 -347.107639 478.288481 -382.684581 C 478.302183 -419.708348 443.042024 -453.669294 407.319442 -453.406514 Z M 212.622849 -453.584594 "
      transform="matrix(-0.0129188,-0.00314019,-0.00211491,-0.0106573,360.303168,221.443052)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.600716 -453.580439 C 177.058439 -453.66607 141.835241 -418.124283 141.60303 -382.740641 C 141.605704 -347.041906 177.232175 -311.643949 212.539567 -311.873411 L 407.470801 -311.646216 C 442.778193 -311.875677 478.236276 -347.102372 478.233602 -382.801107 C 478.475488 -419.659068 442.839342 -453.582706 407.613469 -453.739654 Z M 212.600716 -453.580439 "
      transform="matrix(-0.0129621,-0.00239232,-0.0027346,-0.0106138,360.823991,222.624077)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.6276 -453.678091 C 177.220262 -453.614348 141.823393 -417.991257 141.866499 -382.806899 C 141.876967 -347.24755 177.294772 -311.751943 212.70211 -311.815685 L 407.409828 -311.791277 C 442.817166 -311.85502 478.181397 -347.10312 478.491826 -382.781447 C 478.291012 -419.721782 443.030915 -453.461411 407.335318 -453.653683 Z M 212.6276 -453.678091 "
      transform="matrix(-0.0125788,-0.00399101,-0.00109481,-0.0107643,357.224785,212.910395)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.605764 -453.678204 C 177.321251 -453.595409 141.867509 -418.026334 141.698281 -382.540055 C 141.847768 -347.137033 177.281768 -311.816806 212.566281 -311.899602 L 407.418602 -311.647238 C 443.02183 -311.813291 478.475572 -347.382365 478.326085 -382.785387 C 478.446434 -419.720427 443.061313 -453.591893 407.458084 -453.42584 Z M 212.605764 -453.678204 "
      transform="matrix(-0.0128473,-0.00279277,-0.00226282,-0.0106909,356.911858,213.743548)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.53905 -453.419541 C 177.197208 -453.635478 141.77615 -418.274474 141.696935 -382.697532 C 141.61772 -347.12059 177.261086 -311.806428 212.665973 -311.975599 L 407.362566 -311.797519 C 443.022102 -311.67519 478.506205 -347.421303 478.204681 -382.519528 C 478.218384 -419.543294 442.958224 -453.50424 407.616382 -453.720178 Z M 212.53905 -453.419541 "
      transform="matrix(-0.0129188,-0.00314019,-0.00211491,-0.0106573,357.657903,215.413298)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.695547 -453.401998 C 177.159778 -453.618949 141.706036 -418.049874 141.855523 -382.646853 C 141.686295 -347.160573 177.120295 -311.840347 212.723524 -312.0064 L 407.575844 -311.754036 C 442.860357 -311.836831 478.314099 -347.405906 478.483327 -382.892185 C 478.284962 -419.743967 442.89984 -453.615433 407.615327 -453.532638 Z M 212.695547 -453.401998 "
      transform="matrix(-0.0128473,-0.00279277,-0.00226282,-0.0106909,358.233949,216.75847)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.727374 -453.637858 C 177.143 -453.590644 141.600672 -418.066298 141.642717 -382.589165 C 141.684763 -347.112033 177.251707 -312.012558 212.517914 -311.869544 L 407.436552 -311.653652 C 443.020925 -311.700865 478.245087 -347.034984 478.203042 -382.512117 C 478.241264 -419.501252 442.852744 -453.609396 407.586537 -453.752409 Z M 212.727374 -453.637858 "
      transform="matrix(-0.0110846,-0.00638107,0.00199505,-0.0106727,352.766924,194.187748)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.647391 -453.623285 C 177.324642 -453.545608 141.83796 -418.315759 141.678977 -382.804266 C 141.842012 -347.44183 177.010728 -311.648693 212.655496 -311.875426 L 407.426019 -311.62793 C 443.070787 -311.854662 478.230501 -347.294776 478.389484 -382.806268 C 478.20665 -419.605986 443.062682 -453.602521 407.417915 -453.375789 Z M 212.647391 -453.623285 "
      transform="matrix(-0.0120537,-0.00500019,0.000166036,-0.0108023,352.642403,195.338862)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.724266 -453.503257 C 177.086311 -453.358499 141.852927 -418.129476 141.610164 -382.768115 C 141.691893 -347.185886 177.08791 -311.609867 212.725864 -311.754625 L 407.440773 -311.637187 C 442.755061 -311.643398 478.311287 -347.370385 478.230383 -382.593198 C 478.469019 -419.751641 442.753463 -453.39203 407.439175 -453.385819 Z M 212.724266 -453.503257 "
      transform="matrix(-0.0120569,-0.00464769,0.0000276935,-0.0108576,352.925012,196.748297)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.519187 -453.348508 C 177.107782 -453.462259 141.707444 -417.952992 141.662839 -382.662279 C 141.673908 -347.039261 177.040708 -311.634796 212.716731 -312.033182 L 407.556098 -311.995924 C 442.967504 -311.882172 478.367841 -347.391439 478.412446 -382.682152 C 478.498974 -419.814222 443.034577 -453.709635 407.358554 -453.311249 Z M 212.519187 -453.348508 "
      transform="matrix(-0.0111474,-0.00603256,0.0018676,-0.0107443,352.563359,189.196262)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.632308 -453.533518 C 177.047934 -453.486304 141.823773 -418.152185 141.865818 -382.675052 C 141.907863 -347.19792 177.156641 -311.908218 212.741015 -311.955431 L 407.659653 -311.739539 C 442.866385 -311.926969 478.408713 -347.451315 478.426143 -382.598004 C 478.40489 -419.917582 442.757678 -453.505055 407.491471 -453.648069 Z M 212.632308 -453.533518 "
      transform="matrix(-0.0110846,-0.00638107,0.00199505,-0.0106727,352.644568,190.977317)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.602515 -453.526838 C 177.191109 -453.640589 141.790772 -418.131322 141.746166 -382.840609 C 141.757235 -347.217591 177.124035 -311.813126 212.535441 -311.699375 L 407.374808 -311.662117 C 442.73054 -311.880671 478.506842 -347.237464 478.175482 -382.680651 C 478.26201 -419.81272 442.853287 -453.375828 407.441881 -453.489579 Z M 212.602515 -453.526838 "
      transform="matrix(-0.0111474,-0.00603256,0.0018676,-0.0107443,352.685715,192.405787)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.710128 -453.723069 C 177.06536 -453.496337 141.583627 -417.907167 141.746663 -382.544731 C 141.58768 -347.033238 177.073464 -311.748478 212.718232 -311.97521 L 407.488755 -311.727714 C 442.811505 -311.805391 478.293237 -347.39456 478.45717 -382.546733 C 478.269387 -419.70577 442.8034 -453.55325 407.480651 -453.475573 Z M 212.710128 -453.723069 "
      transform="matrix(-0.0120537,-0.00500019,0.000166036,-0.0108023,353.307238,198.50216)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.606687 -453.690803 C 177.25159 -453.539615 141.620968 -418.011749 141.669075 -382.768208 C 141.711069 -347.162322 177.108159 -311.707624 212.463255 -311.858812 L 407.545211 -311.869585 C 442.894194 -311.658428 478.201183 -347.053157 478.482822 -382.792179 C 478.465284 -419.847446 443.037625 -453.490419 407.365009 -453.568439 Z M 212.606687 -453.690803 "
      transform="matrix(-0.0121544,-0.00446587,-0.000205081,-0.0108558,353.717617,199.528193)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.55264 -453.678164 C 177.211532 -453.463617 141.680888 -418.088237 141.811446 -382.70857 C 141.619957 -347.185254 177.08967 -311.657651 212.752826 -312.015846 L 407.442177 -311.708329 C 442.783286 -311.922876 478.313929 -347.298256 478.505418 -382.821572 C 478.382676 -419.651171 442.903194 -453.36636 407.564039 -453.514296 Z M 212.55264 -453.678164 "
      transform="matrix(-0.0121587,-0.00481836,-0.0000655415,-0.0108023,354.136658,201.275711)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 344.550568 -596.295367 L 353.68711 -574.146412 C 353.68711 -574.146412 355.148621 -583.91136 362.208285 -585.685317 L 369.289644 -587.459741 C 369.289644 -587.459741 396.158161 -538.113444 401.448862 -529.278552 C 406.761258 -520.444127 397.924437 -512.483104 399.682354 -512.494713 C 401.462529 -512.480635 426.198852 -523.975494 426.198852 -523.975494 C 426.198852 -523.975494 415.603684 -523.093686 411.175638 -530.167865 C 406.769286 -537.24251 376.352458 -590.097225 376.352458 -590.097225 C 376.352458 -590.097225 382.54688 -592.742214 387.851215 -595.394242 C 393.15555 -598.04627 398.462208 -587.458638 398.462208 -587.458638 L 385.19472 -613.96695 C 385.19472 -613.96695 385.189412 -605.122918 380.777752 -603.353531 C 376.366093 -601.584144 372.822263 -599.833406 372.822263 -599.833406 C 372.822263 -599.833406 369.281715 -606.010865 364.875364 -613.08551 C 360.447318 -620.159689 369.281551 -627.231031 369.281551 -627.231031 L 346.303144 -615.747782 C 346.303144 -615.747782 354.255913 -611.313556 356.912111 -606.896343 C 359.568309 -602.47913 362.224507 -598.061918 362.224507 -598.061918 C 362.224507 -598.061918 358.98498 -596.291553 354.007545 -594.588504 C 350.423134 -592.706067 346.303309 -594.527616 344.550568 -596.295367 Z M 344.550568 -596.295367 "
      transform="matrix(-0.179964,0.00320799,0.00386712,0.14929,401.976624,255.369016)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;"
      d="M 352.875 198.320312 L 347.449219 202.863281 L 366.4375 218.761719 L 371.863281 214.21875 Z M 352.875 198.320312 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 389.776674 -1427.984408 C 389.759616 -1447.592556 373.913177 -1463.438995 354.335503 -1463.425579 C 334.75783 -1463.412162 318.916597 -1447.570929 318.903181 -1427.993256 C 318.889764 -1408.415582 334.736203 -1392.569143 354.344351 -1392.552085 C 373.922025 -1392.565502 389.763258 -1408.406734 389.776674 -1427.984408 Z M 389.776674 -1427.984408 "
      transform="matrix(-0.0765654,0.0640917,0.0765654,0.0640917,407.947169,203.544391)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 336.613493 -914.170491 L 336.606133 -861.016612 L 372.070871 -861.009706 L 372.047758 -914.194059 C 372.062024 -931.883199 336.597286 -931.890106 336.613493 -914.170491 Z M 336.613493 -914.170491 "
      transform="matrix(-0.0765654,0.0640917,0.0765654,0.0640917,369.966018,171.750977)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.889919 -595.272751 L 389.763412 -595.263903 C 399.533448 -605.033939 399.529391 -621.363904 389.756506 -630.728641 C 363.768365 -641.309206 344.900835 -640.177498 318.913487 -630.707015 C 310.605349 -621.362764 309.47622 -605.910892 318.889919 -595.272751 Z M 318.889919 -595.272751 "
      transform="matrix(-0.0765654,0.0640917,0.0765654,0.0640917,350.196372,155.202123)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.916993 -914.162392 L 318.909632 -861.008513 L 389.752652 -861.030139 L 389.760012 -914.184018 C 372.04882 -924.752625 336.609591 -924.785041 318.916993 -914.162392 Z M 318.916993 -914.162392 "
      transform="matrix(-0.0765654,0.0640917,0.0765654,0.0640917,374.036239,175.158094)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.908068 -595.290285 L 389.781561 -595.281437 C 399.526088 -605.025964 399.522031 -621.355929 389.749146 -630.720666 C 363.786514 -641.32674 344.893475 -640.169523 318.906126 -630.69904 C 310.597989 -621.354789 309.46886 -605.902917 318.908068 -595.290285 Z M 318.908068 -595.290285 "
      transform="matrix(-0.0765654,0.0640917,0.0765654,0.0640917,354.26551,158.608334)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.879159 -949.610779 L 318.910482 -464.802016 L 389.753501 -464.823642 L 389.752652 -949.601932 C 372.036495 -960.226522 336.62774 -960.228463 318.879159 -949.610779 Z M 318.879159 -949.610779 "
      transform="matrix(-0.0765654,0.0640917,0.0765654,0.0640917,380.817775,180.834809)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.882208 -595.289513 L 389.755702 -595.280665 C 399.525737 -605.050701 399.521681 -621.380666 389.77927 -630.714929 C 363.760655 -641.325968 344.893125 -640.19426 318.905776 -630.723777 C 310.597639 -621.379526 309.473475 -605.871671 318.882208 -595.289513 Z M 318.882208 -595.289513 "
      transform="matrix(-0.0765654,0.0640917,0.0765654,0.0640917,393.02519,191.055254)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.892815 -949.622643 L 318.904637 -630.694406 L 389.747656 -630.716032 L 389.766308 -949.613796 C 372.050152 -960.238386 336.615887 -960.214818 318.892815 -949.622643 Z M 318.892815 -949.622643 "
      transform="matrix(-0.0765654,0.0640917,0.0765654,0.0640917,420.155667,213.764382)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.163792 -595.289267 L 407.502023 -595.273513 C 417.24655 -605.01804 417.212019 -621.378479 407.469608 -630.712742 C 407.469608 -630.712742 389.76426 -648.418091 354.325031 -648.450506 C 318.890767 -648.426938 301.18736 -630.723531 301.18736 -630.723531 C 294.044939 -621.386988 294.902787 -606.703136 301.163792 -595.289267 Z M 301.163792 -595.289267 "
      transform="matrix(-0.0765654,0.0640917,0.0765654,0.0640917,406.590428,202.409591)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.163792 -595.289267 L 318.908462 -506.679951 L 389.751481 -506.701578 L 407.502023 -595.273513 C 407.75473 -599.000247 389.766201 -613.009336 354.331937 -612.985768 C 318.892708 -613.018183 302.381213 -595.653418 301.163792 -595.289267 Z M 301.163792 -595.289267 "
      transform="matrix(-0.0765654,0.0640917,0.0765654,0.0640917,406.590428,202.409591)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 336.604673 -506.698396 C 336.618804 -542.11547 336.595826 -593.361182 354.339008 -636.669721 C 372.060564 -593.352737 372.027559 -542.113096 372.043902 -506.658759 Z M 336.604673 -506.698396 "
      transform="matrix(-0.0765654,0.0640917,0.0626158,0.0524147,399.523035,196.492683)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.900638 -506.707396 L 336.615288 -506.658759 L 336.622194 -550.024379 C 336.598626 -593.352737 301.175605 -614.980314 301.175605 -614.980314 Z M 318.900638 -506.707396 "
      transform="matrix(0.0765654,-0.0640917,0.0626158,0.0524147,345.264247,241.911845)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.915533 -506.680951 L 336.604673 -506.698396 L 336.628241 -550.026753 C 336.6263 -593.323919 301.201337 -614.993507 301.201337 -614.993507 Z M 318.915533 -506.680951 "
      transform="matrix(-0.0765654,0.0640917,0.0626158,0.0524147,399.523035,196.492683)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.879159 -949.610779 L 318.897308 -896.48241 C 318.904214 -861.017671 336.611049 -719.284951 336.611049 -719.284951 C 336.611049 -719.284951 318.894728 -571.140247 318.901634 -535.675509 L 318.919783 -482.547139 C 318.917842 -517.955894 354.316397 -701.579603 354.316397 -701.579603 C 354.316397 -701.579603 389.775127 -535.666661 389.793276 -482.538292 L 389.775127 -535.666661 C 389.768221 -571.1314 372.045313 -719.308519 372.045313 -719.308519 C 372.045313 -719.308519 389.763441 -843.319683 389.787009 -878.753948 L 389.778161 -949.627441 C 389.754593 -914.193177 354.339965 -737.013867 354.339965 -737.013867 C 354.339965 -737.013867 318.911574 -914.17155 318.904668 -949.636288 Z M 318.879159 -949.610779 "
      transform="matrix(-0.0765654,0.0640917,0.0765654,0.0640917,380.817775,180.834809)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 354.344213 -382.676147 C 372.058409 -294.097306 372.067256 -223.223812 372.05299 -205.534672 C 372.094485 -171.87201 354.356489 -152.366527 354.356489 -152.366527 C 354.356489 -152.366527 336.620667 -170.102349 336.618726 -205.511104 C 336.602518 -223.230718 336.593671 -294.104212 354.344213 -382.676147 Z M 318.877988 -506.710425 C 318.879475 -382.683053 283.455999 -276.377237 283.450581 -187.814603 C 283.459428 -116.94111 335.121099 -114.359201 353.586817 -115.637628 C 372.052536 -116.916055 425.883177 -116.807855 425.197567 -187.796908 C 425.202986 -276.359542 389.752968 -382.674206 389.751481 -506.701578 C 372.046133 -524.406926 336.606904 -524.439341 318.877988 -506.710425 Z M 318.877988 -506.710425 "
      transform="matrix(-0.0765654,0.0640917,0.0765654,0.0640917,406.590428,202.409591)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 218.274123 257.886284 L 241.220323 257.879779 L 241.217297 330.920323 L 218.271096 330.926828 Z M 218.274123 257.886284 "
      transform="matrix(-0.135208,-0.128834,-0.147184,0.119417,443.996616,212.165058)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:0.56103;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.177094 396.85178 L 318.89577 414.560701 L 301.177094 432.288829 L 310.044293 441.143289 L 327.747248 423.434368 L 363.184601 458.85221 L 345.465924 476.580337 L 354.333123 485.434798 L 372.0518 467.725877 L 389.770477 485.434798 L 336.614447 538.580767 L 301.177094 503.143718 L 318.89577 485.434798 L 327.747248 494.289258 L 336.614447 485.434798 L 301.177094 449.997749 L 292.325616 458.85221 L 301.177094 467.725877 L 283.458417 485.434798 L 265.73974 467.725877 L 274.60694 458.85221 L 265.73974 449.997749 L 230.318109 485.434798 L 239.169587 494.289258 L 248.036786 485.434798 L 265.73974 503.143718 L 248.036786 520.871846 L 239.169587 512.017386 L 230.318109 520.871846 L 265.73974 556.289687 L 274.60694 547.435227 L 265.73974 538.580767 L 283.458417 520.871846 L 318.89577 556.289687 L 265.73974 609.454864 L 248.036786 591.726736 L 265.73974 574.017815 L 256.888263 565.163355 L 239.169587 582.872275 L 203.732233 547.435227 L 221.45091 529.726306 L 212.599433 520.871846 L 194.880756 538.580767 L 177.162079 520.871846 Z M 301.177094 396.85178 "
      transform="matrix(-0.248458,0,0,0.203376,356.775116,64.325184)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.903572 786.603472 L 318.903572 822.059727 L 407.481233 910.642745 L 407.481233 839.768648 L 389.778278 822.059727 L 354.340925 822.059727 Z M 318.903572 786.603472 "
      transform="matrix(-0.124229,0,0,0.101688,334.765481,104.679989)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 230.42496 698.772631 L 300.796563 769.147344 L 301.614106 804.3347 L 230.42496 733.114876 Z M 230.42496 698.772631 "
      transform="matrix(-0.124229,0,0,0.101688,334.777785,104.677721)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.169173 804.312392 L 336.606526 768.894551 L 336.606526 733.47671 L 301.169173 768.894551 Z M 301.169173 804.312392 "
      transform="matrix(-0.124229,0,0,0.101688,334.765481,104.679989)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.174512 804.312375 L 336.611865 768.894533 L 336.611865 733.476692 L 301.174512 768.894533 Z M 301.174512 804.312375 "
      transform="matrix(-0.124229,0,0,0.101688,345.77005,95.672179)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.028865 644.893692 L 248.028865 680.311533 L 265.73182 662.602613 Z M 248.028865 644.893692 "
      transform="matrix(-0.124229,0,0,0.101688,334.765481,104.679989)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.023526 644.89371 L 248.023526 680.311551 L 265.757925 662.60263 Z M 248.023526 644.89371 "
      transform="matrix(-0.124229,0,0,0.101688,323.760911,113.6878)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 230.409275 698.793692 L 266.192512 734.211533 L 266.192512 769.629375 L 230.409275 733.135937 Z M 230.409275 698.793692 "
      transform="matrix(-0.124229,0,0,0.101688,341.424274,99.199017)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.195278 804.317437 L 336.601187 768.899595 L 336.601187 733.481754 L 301.195278 768.899595 Z M 301.195278 804.317437 "
      transform="matrix(-0.124229,0,0,0.101688,323.760911,84.86307)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.186572 804.331533 L 336.623925 768.913692 L 336.623925 733.457437 L 301.186572 768.913692 Z M 301.186572 804.331533 "
      transform="matrix(-0.124229,0,0,0.101688,332.564517,92.068668)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 283.452173 822.048516 L 336.623925 768.88334 L 336.623925 733.465498 L 265.749219 804.339595 Z M 283.452173 822.048516 "
      transform="matrix(-0.124229,0,0,0.101688,332.564517,81.259254)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.040925 644.875498 L 248.040925 680.331754 L 265.74388 662.584419 Z M 248.040925 644.875498 "
      transform="matrix(-0.124229,0,0,0.101688,321.559947,90.267777)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.195278 804.317437 L 265.757925 768.899595 L 265.757925 733.481754 L 301.195278 768.899595 Z M 301.195278 804.317437 "
      transform="matrix(-0.124229,0,0,0.101688,323.760911,84.86307)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 389.772939 822.048516 L 336.601187 768.88334 L 336.601187 733.465498 L 407.475894 804.339595 Z M 389.772939 822.048516 "
      transform="matrix(-0.124229,0,0,0.101688,323.760911,81.259254)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 230.404187 698.042437 L 301.278894 768.916533 L 336.716247 768.916533 L 354.419201 786.625454 L 354.419201 857.499551 L 230.404187 733.114551 Z M 230.404187 698.042437 "
      transform="matrix(-0.124229,0,0,0.101688,310.568173,95.669941)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 354.340925 822.059727 L 460.62154 715.767789 L 496.058894 715.767789 L 389.778278 822.059727 Z M 354.340925 822.059727 "
      transform="matrix(-0.124229,0,0,0.101688,334.765481,104.679989)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 407.481233 839.768648 L 513.793292 733.47671 L 496.058894 715.767789 L 389.746834 822.059727 Z M 407.481233 839.768648 "
      transform="matrix(-0.124229,0,0,0.101688,334.765481,104.679989)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 407.480928 839.769136 L 407.480928 910.643233 L 513.792987 804.312881 L 513.792987 733.477198 Z M 407.480928 839.769136 "
      transform="matrix(-0.124229,0,0,0.101688,334.765443,104.67994)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 354.328894 822.041533 L 460.640953 715.749595 L 496.078306 715.749595 L 389.766247 822.041533 Z M 354.328894 822.041533 "
      transform="matrix(-0.124229,0,0,0.101688,317.158517,90.267777)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 407.469201 839.750454 L 513.781261 733.458516 L 496.046862 715.749595 L 389.766247 822.041533 Z M 407.469201 839.750454 "
      transform="matrix(-0.124229,0,0,0.101688,317.158517,90.267777)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 407.469201 839.750454 L 407.469201 910.624551 L 513.781261 804.332613 L 513.781261 733.458516 Z M 407.469201 839.750454 "
      transform="matrix(-0.124229,0,0,0.101688,317.158517,90.267777)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.896247 786.619595 L 336.630645 768.910675 L 336.630645 733.454419 L 301.193292 768.910675 Z M 318.896247 786.619595 "
      transform="matrix(-0.124229,0,0,0.101688,308.354414,83.061162)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.898586 786.616578 L 336.60154 768.907657 L 336.60154 733.451402 L 301.195631 768.907657 Z M 318.898586 786.616578 "
      transform="matrix(-0.124229,0,0,0.101688,319.358611,74.053657)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 265.757925 768.917789 L 265.757925 733.461533 L 283.46088 751.170454 Z M 265.757925 768.917789 "
      transform="matrix(-0.124229,0,0,0.101688,323.760911,99.275283)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.186572 804.331533 L 265.749219 768.913692 L 283.452173 751.166357 L 301.186572 768.913692 Z M 301.186572 804.331533 "
      transform="matrix(-0.124229,0,0,0.101688,332.564517,92.068668)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.195631 804.31334 L 283.461233 786.604419 L 301.195631 768.895498 Z M 301.195631 804.31334 "
      transform="matrix(-0.124229,0,0,0.101688,319.358611,77.656455)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 196.496855 254.144358 L 221.700161 254.146794 L 221.694731 334.417093 L 196.491425 334.414656 Z M 196.496855 254.144358 "
      transform="matrix(-0.135447,-0.128653,-0.147412,0.119217,443.996616,212.165058)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 336.616311 166.533207 C 336.616311 181.199608 372.042341 201.965586 372.042341 148.828441 L 336.616311 148.828441 Z M 336.616311 166.533207 "
      transform="matrix(-0.205644,0,0,0.17099,443.996616,212.165058)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 242.096243 24.803694 C 95.035478 24.803694 128.960888 189.035395 141.725656 148.828441 C 151.090285 119.312881 177.170682 77.963684 206.670212 68.597291 C 246.883031 55.827015 233.358455 77.963684 248.022742 77.963684 C 262.706024 77.963684 249.162454 55.827015 289.394267 68.597291 C 318.893798 77.963684 344.974194 119.312881 354.319828 148.828441 C 367.084596 189.05824 407.487366 24.803694 242.096243 24.803694 Z M 242.096243 24.803694 "
      transform="matrix(-0.205644,0,0,0.17099,443.996616,212.165058)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 389.764854 166.533207 C 389.764854 244.776854 326.263932 308.26272 248.022742 308.26272 C 169.800548 308.26272 106.299626 244.776854 106.299626 166.533207 L 106.299626 148.828441 C 106.299626 119.701244 141.725656 272.853187 248.022742 272.853187 C 354.319828 272.853187 389.764854 148.07456 389.764854 148.828441 Z M 389.764854 166.533207 "
      transform="matrix(-0.205644,0,0,0.17099,443.996616,212.165058)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 336.6028 166.546375 C 336.6028 181.212776 372.047825 201.978754 372.047825 148.818764 L 336.6028 148.818764 Z M 336.6028 166.546375 "
      transform="matrix(0.205644,0,0,0.17099,341.986631,212.358119)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 389.764854 148.828441 C 389.764854 227.049242 326.263932 290.557954 248.022742 290.557954 C 169.800548 290.557954 106.299626 227.049242 106.299626 148.828441 C 106.299626 70.584794 169.800548 7.098928 248.022742 7.098928 C 326.263932 7.098928 389.764854 70.584794 389.764854 148.828441 Z M 336.616311 148.828441 C 336.616311 163.494841 359.429534 151.866807 345.961944 191.868157 C 336.616311 219.693197 318.893798 237.397964 289.394267 246.764357 C 249.162454 259.511789 262.706024 237.397964 248.022742 237.397964 C 233.358455 237.397964 246.883031 259.511789 206.670212 246.764357 C 177.151686 237.397964 159.448169 219.693197 150.08354 190.177638 C 137.318772 149.970684 159.448169 163.494841 159.448169 148.828441 C 159.448169 134.139195 137.318772 147.686198 150.08354 107.456399 C 159.448169 77.963684 177.151686 60.236073 206.670212 50.86968 C 246.883031 38.122248 233.358455 60.236073 248.022742 60.236073 C 262.706024 60.236073 249.162454 38.122248 289.394267 50.86968 C 318.893798 60.236073 336.616311 77.963684 345.961944 107.456399 C 358.726712 147.686198 336.616311 134.139195 336.616311 148.828441 Z M 336.616311 148.828441 "
      transform="matrix(-0.205644,0,0,0.17099,443.996616,212.165058)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 126.550392 157.689472 C 106.296092 157.689472 124.61831 225.270743 117.45441 209.385106 C 110.2688 193.499468 106.296092 176.022819 106.296092 157.689472 C 106.296092 139.331647 110.2688 121.879476 117.45441 105.969361 C 124.61831 90.083723 106.296092 157.689472 126.550392 157.689472 Z M 126.550392 157.689472 "
      transform="matrix(-0.179939,0,0,0.159588,437.618962,212.447942)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 372.042341 139.949673 C 372.042341 155.859607 369.914879 171.599835 362.297808 184.77326 C 354.338823 198.540656 372.042341 139.949673 354.338823 139.949673 C 372.042341 139.949673 356.029395 81.379903 362.297808 95.1473 C 368.585216 108.914696 372.042341 124.060953 372.042341 139.949673 Z M 372.042341 139.949673 "
      transform="matrix(-0.205644,0,0,0.184142,443.996616,211.838758)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 296.289521 263.075587 C 281.454277 269.357923 265.137409 272.830342 248.022742 272.830342 C 230.927071 272.830342 214.610202 269.357923 199.774958 263.075587 C 184.939715 256.79325 248.022742 272.830342 248.022742 255.102731 C 248.022742 272.830342 311.124765 256.79325 296.289521 263.075587 Z M 296.289521 263.075587 "
      transform="matrix(-0.205644,0,0,0.17099,443.996616,212.165058)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.022742 42.531306 C 248.022742 24.803694 184.939715 40.840786 199.774958 34.55845 C 214.610202 28.276113 230.908076 24.803694 248.022742 24.803694 C 265.137409 24.803694 281.454277 28.276113 296.289521 34.55845 C 311.124765 40.840786 248.022742 24.803694 248.022742 42.531306 Z M 248.022742 42.531306 "
      transform="matrix(-0.205644,0,0,0.17099,443.996616,212.165058)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 100.547267 160.852036 C 100.552011 149.749018 92.287824 140.755426 82.112768 140.746593 C 71.920978 140.753312 63.690728 149.750455 63.685984 160.853472 C 63.681241 171.95649 71.928694 180.965634 82.103749 180.974467 C 92.295539 180.967748 100.555454 171.968968 100.547267 160.852036 Z M 100.547267 160.852036 "
      transform="matrix(-0.139994,-0.125254,-0.150638,0.116399,453.904178,210.589754)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 276.583947 287.680244 C 267.356677 289.555284 257.794711 290.548789 248.031161 290.55108 C 238.237945 290.551735 228.701049 289.571825 219.470674 287.658006 L 218.04383 294.630006 C 227.726786 296.62732 237.755055 297.648982 248.03584 297.660613 C 258.29989 297.656693 268.328887 296.630456 278.011777 294.634285 Z M 276.583947 287.680244 "
      transform="matrix(-0.139994,-0.125254,0.150638,-0.116399,405.318146,286.00768)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 276.577298 287.676604 C 267.350727 289.560957 257.797097 290.556419 248.033546 290.554127 C 238.240331 290.553472 228.70803 289.55833 219.467829 287.669376 L 218.052929 294.637331 C 227.722889 296.619588 237.764817 297.659739 248.028867 297.663659 C 258.292918 297.66758 268.324991 296.616452 278.020877 294.633052 Z M 276.577298 287.676604 "
      transform="matrix(-0.139994,-0.125254,-0.150638,0.116399,450.179368,251.368204)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 267.704118 308.499514 C 258.490477 310.397781 247.512203 309.423945 237.731919 309.437205 C 227.951634 309.450465 218.437615 306.830591 209.227079 304.939999 L 218.039999 294.623417 C 227.722889 296.619588 237.764817 297.659739 248.028867 297.663659 C 258.292918 297.66758 268.324991 296.616452 278.020877 294.633052 Z M 267.704118 308.499514 "
      transform="matrix(-0.139994,-0.125254,-0.150638,0.116399,450.179368,251.368204)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 276.571035 287.664558 C 267.357158 289.553073 257.801132 290.54327 248.029822 290.548436 C 238.258511 290.553602 228.695085 289.554729 219.477871 287.672761 L 218.033326 294.628773 C 227.73919 296.617999 237.758524 297.660996 248.027485 297.661163 C 258.296446 297.661329 268.317383 296.613332 278.008446 294.62442 Z M 276.571035 287.664558 "
      transform="matrix(-0.150638,0.116399,0.139994,0.125254,409.492315,190.178776)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 100.547706 160.864994 C 100.539519 149.748063 92.292066 140.738919 82.113207 140.759552 C 71.938151 140.750719 63.678236 149.749499 63.673493 160.852517 C 63.68168 171.969448 71.929133 180.978592 82.120923 180.971873 C 92.283048 180.966792 100.542963 171.968012 100.547706 160.864994 Z M 100.547706 160.864994 "
      transform="matrix(-0.139994,-0.125254,-0.150638,0.116399,403.179629,249.963301)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 267.593548 281.480505 C 258.36412 283.352285 250.479481 284.410966 240.708171 284.416132 C 230.936861 284.421298 217.614865 283.70304 208.382099 281.804338 L 218.042307 294.626571 C 227.734256 296.628728 237.767505 297.658794 248.036466 297.65896 C 258.305427 297.659127 268.326364 296.61113 278.017427 294.622218 Z M 267.593548 281.480505 "
      transform="matrix(-0.150638,0.116399,0.139994,0.125254,409.837726,192.900662)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 173.941499 271.779479 L 173.105711 286.605793 L 169.857533 291.10623 L 170.845283 276.028623 Z M 173.941499 271.779479 "
      transform="matrix(-0.205644,0,0,0.17099,443.996616,212.165058)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.53785 265.611366 L 334.108945 269.791976 L 335.153681 283.407512 L 332.209426 280.48337 Z M 330.53785 265.611366 "
      transform="matrix(-0.205644,0,0,0.17099,443.996616,212.165058)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 100.543155 160.871667 C 100.534968 149.754735 92.287515 140.745591 82.12539 140.750673 C 71.9336 140.757392 63.673685 149.756172 63.668941 160.859189 C 63.677128 171.976121 71.941316 180.969713 82.103441 180.964632 C 92.295231 180.957913 100.538411 171.974685 100.543155 160.871667 Z M 100.543155 160.871667 "
      transform="matrix(-0.139994,-0.125254,-0.150638,0.116399,451.73859,251.368204)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 513.777753 -790.159754 L 549.215507 -807.86969 L 584.65326 -790.159754 C 584.65326 -790.159754 511.282136 -754.739883 511.282136 -630.718015 C 511.282136 -542.142177 636.540382 -524.406082 636.540382 -418.120309 C 636.540382 -372.472099 602.361287 -329.518312 566.923533 -329.518312 C 531.485779 -329.518312 478.339999 -347.254407 425.194219 -400.384214 C 460.631973 -382.674278 501.27797 -363.080863 531.485779 -364.336516 C 551.081794 -364.728908 586.519548 -382.046452 586.519548 -417.492482 C 586.519548 -488.358384 463.756918 -524.406082 463.756918 -630.718015 C 463.756918 -754.713723 513.777753 -790.159754 513.777753 -790.159754 Z M 513.777753 -790.159754 "
      transform="matrix(-0.180003,0,0,0.149325,400.567536,250.767731)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 520.244655 -790.159754 L 566.923533 -807.86969 L 576.9277 -790.159754 C 576.9277 -790.159754 506.290903 -754.739883 506.290903 -630.718015 C 506.290903 -542.142177 632.178478 -524.406082 632.178478 -418.120309 C 632.178478 -372.472099 605.464532 -330.747805 566.923533 -329.518312 C 493.530708 -327.163963 476.473712 -353.506511 425.194219 -400.384214 C 468.357533 -368.077315 530.205419 -353.637308 554.228441 -366.219995 C 583.56821 -381.575582 589.253875 -402.267693 589.253875 -419.977628 C 589.253875 -490.84353 468.357533 -524.406082 468.357533 -630.718015 C 468.357533 -754.713723 520.266356 -790.159754 520.266356 -790.159754 Z M 520.244655 -790.159754 "
      transform="matrix(-0.180003,0,0,0.149325,400.567536,250.767731)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 513.343733 -789.688884 C 520.35316 -792.121712 549.215507 -807.86969 584.65326 -772.449818 C 602.361287 -754.713723 602.361287 -719.293852 602.361287 -701.583917 L 655.507067 -754.713723 C 655.507067 -754.713723 626.449411 -841.589198 547.891745 -808.3144 C 536.759125 -803.422586 519.745531 -793.769756 513.343733 -789.688884 Z M 513.343733 -789.688884 "
      transform="matrix(-0.180003,0,0,0.149325,400.567536,250.767731)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 523.803621 -795.940989 C 532.635933 -798.818526 560.478332 -802.271571 588.385835 -777.44627 C 606.115562 -759.710175 607.35252 -734.518642 607.35252 -716.782547 L 648.020218 -754.713723 C 651.448978 -757.931334 623.172558 -835.96492 548.781486 -808.75911 C 544.267676 -806.95411 533.981396 -802.454687 523.803621 -795.940989 Z M 523.803621 -795.940989 "
      transform="matrix(-0.180003,0,0,0.149325,400.567536,250.767731)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 344.542405 -596.298144 L 353.699027 -574.149753 C 353.699027 -574.149753 355.136095 -583.904968 362.220033 -585.677178 L 369.278352 -587.452444 C 369.278352 -587.452444 396.168414 -538.13211 401.468041 -529.288099 C 406.767667 -520.444087 397.925017 -512.50453 399.684968 -512.505209 C 401.470538 -512.502834 426.215397 -523.990547 426.215397 -523.990547 C 426.215397 -523.990547 415.590261 -523.091854 411.182648 -530.175055 C 406.765169 -537.229352 376.366614 -590.096475 376.366614 -590.096475 C 376.366614 -590.096475 382.554214 -592.757674 387.856906 -595.404081 C 393.159597 -598.050488 398.450576 -587.462834 398.450576 -587.462834 L 385.21231 -613.97838 C 385.21231 -613.97838 385.206463 -605.135385 380.783138 -603.346687 C 376.369679 -601.586893 372.831262 -599.812987 372.831262 -599.812987 C 372.831262 -599.812987 369.294369 -606.010255 364.861137 -613.096511 C 360.443658 -620.150808 369.281984 -627.218545 369.281984 -627.218545 L 346.297077 -615.731511 C 346.297077 -615.731511 354.257297 -611.322942 356.902176 -606.886484 C 359.556923 -602.47893 362.211669 -598.071377 362.211669 -598.071377 C 362.211669 -598.071377 358.990553 -596.289711 354.0036 -594.56823 C 350.409963 -592.710667 346.309461 -594.523221 344.542405 -596.298144 Z M 344.542405 -596.298144 "
      transform="matrix(-0.146508,-0.0154865,-0.0500128,0.12986,329.659512,252.841051)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 344.54068 -596.304709 L 353.677386 -574.141875 C 353.677386 -574.141875 355.137258 -583.930392 362.229141 -585.664456 L 369.292497 -587.442982 C 369.292497 -587.442982 396.152377 -538.138627 401.448079 -529.281327 C 406.773673 -520.457864 397.932141 -512.483352 399.690133 -512.477764 C 401.448808 -512.511325 426.203572 -523.992838 426.203572 -523.992838 C 426.203572 -523.992838 415.610033 -523.092093 411.19183 -530.179554 C 406.772945 -537.227866 376.370865 -590.0828 376.370865 -590.0828 C 376.370865 -590.0828 382.541711 -592.769874 387.862086 -595.41528 C 393.153253 -598.065999 398.447459 -587.441655 398.447459 -587.441655 L 385.196658 -613.966022 C 385.196658 -613.966022 385.189175 -605.130801 380.792698 -603.377728 C 376.36633 -601.590819 372.81964 -599.840265 372.81964 -599.840265 C 372.81964 -599.840265 369.293948 -606.019178 364.875062 -613.06749 C 360.456859 -620.154951 369.282698 -627.229022 369.282698 -627.229022 L 346.315135 -615.736607 C 346.315135 -615.736607 354.266513 -611.305453 356.914705 -606.896378 C 359.562898 -602.487303 362.21109 -598.078228 362.21109 -598.078228 C 362.21109 -598.078228 358.98638 -596.30838 354.004361 -594.583269 C 350.425733 -592.68143 346.297176 -594.532076 344.54068 -596.304709 Z M 344.54068 -596.304709 "
      transform="matrix(0.133312,0.0180921,0.00232352,-0.0994622,240.454008,71.421564)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 389.763759 -1427.980136 C 389.777175 -1447.55781 373.900262 -1463.434723 354.322588 -1463.421306 C 334.770424 -1463.433399 318.903682 -1447.566657 318.890265 -1427.988984 C 318.902358 -1408.436819 334.779271 -1392.559906 354.331436 -1392.547813 C 373.909109 -1392.561229 389.775852 -1408.427972 389.763759 -1427.980136 Z M 389.763759 -1427.980136 "
      transform="matrix(0.0765654,0.0640917,-0.0765654,0.0640917,282.054147,203.544945)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 336.600578 -914.167652 L 336.618727 -861.039283 L 372.057956 -861.006867 L 372.065316 -914.160746 C 372.049109 -931.880361 336.640354 -931.882302 336.600578 -914.167652 Z M 336.600578 -914.167652 "
      transform="matrix(0.0765654,0.0640917,-0.0765654,0.0640917,320.035189,171.751623)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.902513 -595.291358 L 389.776006 -595.28251 C 399.520533 -605.027037 399.516476 -621.357002 389.774065 -630.691265 C 363.780959 -641.327813 344.913429 -640.196105 318.900571 -630.700113 C 310.592434 -621.355862 309.493779 -605.873516 318.902513 -595.291358 Z M 318.902513 -595.291358 "
      transform="matrix(0.0765654,0.0640917,-0.0765654,0.0640917,339.805145,155.202508)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.904077 -914.149507 L 318.922226 -861.021138 L 389.765246 -861.042764 L 389.747097 -914.171134 C 372.061414 -924.76525 336.596676 -924.772156 318.904077 -914.149507 Z M 318.904077 -914.149507 "
      transform="matrix(0.0765654,0.0640917,-0.0765654,0.0640917,315.965737,175.158096)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.895152 -595.287479 L 389.768646 -595.278631 C 399.513172 -605.023158 399.534625 -621.378632 389.766704 -630.687387 C 363.773599 -641.323934 344.906069 -640.192227 318.893211 -630.696234 C 310.610583 -621.377492 309.486419 -605.869637 318.895152 -595.287479 Z M 318.895152 -595.287479 "
      transform="matrix(0.0765654,0.0640917,-0.0765654,0.0640917,335.735693,158.608982)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;"
      d="M 337.125 198.320312 L 342.554688 202.863281 L 323.5625 218.761719 L 318.136719 214.21875 Z M 337.125 198.320312 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.922226 -949.605138 L 318.923076 -464.826848 L 389.796569 -464.818 L 389.765246 -949.626764 C 372.054054 -960.195371 336.614825 -960.227786 318.922226 -949.605138 Z M 318.922226 -949.605138 "
      transform="matrix(0.0765654,0.0640917,-0.0765654,0.0640917,309.183266,180.835594)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.903909 -595.254508 L 389.746929 -595.276135 C 399.516964 -605.046171 399.512908 -621.376136 389.770496 -630.710399 C 363.751882 -641.321438 344.884352 -640.18973 318.897003 -630.719247 C 310.588866 -621.374996 309.490211 -605.89265 318.903909 -595.254508 Z M 318.903909 -595.254508 "
      transform="matrix(0.0765654,0.0640917,-0.0765654,0.0640917,296.975828,191.055526)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.873435 -949.612982 L 318.910766 -630.710254 L 389.78426 -630.701407 L 389.746929 -949.604135 C 372.030772 -960.228725 336.622017 -960.230666 318.873435 -949.612982 Z M 318.873435 -949.612982 "
      transform="matrix(0.0765654,0.0640917,-0.0765654,0.0640917,269.846557,213.765005)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.157948 -595.287939 L 407.496179 -595.272186 C 417.240706 -605.016712 417.262158 -621.372186 407.463764 -630.711415 C 407.463764 -630.711415 389.758416 -648.416763 354.349661 -648.418704 C 318.884922 -648.42561 301.181516 -630.722203 301.181516 -630.722203 C 294.069569 -621.355186 294.896943 -606.701809 301.188422 -595.257465 Z M 301.157948 -595.287939 "
      transform="matrix(0.0765654,0.0640917,-0.0765654,0.0640917,283.410121,202.409881)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.157948 -595.287939 L 318.902617 -506.678624 L 389.745637 -506.70025 L 407.496179 -595.272186 C 407.748886 -598.998919 389.760357 -613.008008 354.326093 -612.98444 C 318.917338 -612.986381 302.400878 -595.6776 301.157948 -595.287939 Z M 301.157948 -595.287939 "
      transform="matrix(0.0765654,0.0640917,-0.0765654,0.0640917,283.410121,202.409881)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 336.591758 -506.692047 C 336.605889 -542.108589 336.613384 -593.316267 354.326093 -636.661417 C 372.047649 -593.345085 372.040153 -542.137407 372.056496 -506.683603 Z M 336.591758 -506.692047 "
      transform="matrix(0.0765654,0.0640917,-0.0626167,0.0524155,290.477874,196.493577)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.914363 -506.701047 L 336.603504 -506.683603 L 336.635919 -550.017379 C 336.612351 -593.345085 301.163821 -615.003528 301.163821 -615.003528 Z M 318.914363 -506.701047 "
      transform="matrix(-0.0765654,-0.0640917,-0.0626167,0.0524155,344.736724,241.912792)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.902617 -506.674603 L 336.591758 -506.692047 L 336.615326 -550.019753 C 336.613384 -593.316267 301.188422 -614.985529 301.188422 -614.985529 Z M 318.902617 -506.674603 "
      transform="matrix(0.0765654,0.0640917,-0.0626167,0.0524155,290.477874,196.493577)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 354.338369 -382.674819 C 372.052565 -294.095978 372.061412 -223.222485 372.047146 -205.533344 C 372.08864 -171.870682 354.350645 -152.365199 354.350645 -152.365199 C 354.350645 -152.365199 336.614823 -170.101021 336.612881 -205.509776 C 336.596674 -223.229391 336.587826 -294.102884 354.338369 -382.674819 Z M 318.902617 -506.678624 C 318.873631 -382.681725 283.450155 -276.375909 283.47521 -187.782801 C 283.453584 -116.939782 335.115255 -114.357873 353.580973 -115.6363 C 372.046692 -116.914727 425.902842 -116.832037 425.191723 -187.79558 C 425.197142 -276.358214 389.747124 -382.672878 389.745637 -506.70025 C 372.040288 -524.405598 336.631533 -524.40754 318.902617 -506.678624 Z M 318.902617 -506.678624 "
      transform="matrix(0.0765654,0.0640917,-0.0765654,0.0640917,283.410121,202.409881)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.922226 -949.605138 L 318.914866 -896.451259 C 318.891298 -861.016994 336.623643 -719.309783 336.623643 -719.309783 C 336.623643 -719.309783 318.912287 -571.109096 318.914228 -535.700341 L 318.906868 -482.546462 C 318.904926 -517.955217 354.328991 -701.604435 354.328991 -701.604435 C 354.328991 -701.604435 389.787721 -535.691494 389.780361 -482.537615 L 389.787721 -535.691494 C 389.755306 -571.130723 372.062872 -719.277368 372.032398 -719.307842 C 372.062872 -719.277368 389.750525 -843.319006 389.774093 -878.753271 L 389.765246 -949.626764 C 389.772152 -914.162026 354.32705 -737.01319 354.32705 -737.01319 C 354.32705 -737.01319 318.898659 -914.170873 318.891753 -949.635611 Z M 318.922226 -949.605138 "
      transform="matrix(0.0765654,0.0640917,-0.0765654,0.0640917,309.183266,180.835594)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 218.277495 257.889923 L 241.223696 257.883418 L 241.220669 330.923962 L 218.274469 330.930467 Z M 218.277495 257.889923 "
      transform="matrix(0.135208,-0.128834,0.147184,0.119417,246.002392,212.165058)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 336.602137 166.533207 C 336.602137 181.199608 372.047162 201.965586 372.047162 148.828441 L 336.602137 148.828441 Z M 336.602137 166.533207 "
      transform="matrix(0.205644,0,0,0.17099,246.002392,212.165058)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 242.101064 24.803694 C 95.0403 24.803694 128.96571 189.035395 141.730478 148.828441 C 151.095107 119.312881 177.156508 77.963684 206.656039 68.597291 C 246.887852 55.827015 233.363277 77.963684 248.027564 77.963684 C 262.691851 77.963684 249.167275 55.827015 289.399089 68.597291 C 318.898619 77.963684 344.979016 119.312881 354.32465 148.828441 C 367.089418 189.05824 407.473193 24.803694 242.101064 24.803694 Z M 242.101064 24.803694 "
      transform="matrix(0.205644,0,0,0.17099,246.002392,212.165058)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 389.75068 166.533207 C 389.75068 244.776854 326.268753 308.26272 248.027564 308.26272 C 169.786374 308.26272 106.304448 244.776854 106.304448 166.533207 L 106.304448 148.828441 C 106.304448 119.701244 141.730478 272.853187 248.027564 272.853187 C 354.32465 272.853187 389.75068 148.07456 389.75068 148.828441 Z M 389.75068 166.533207 "
      transform="matrix(0.205644,0,0,0.17099,246.002392,212.165058)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 100.545559 160.850197 C 100.550302 149.74718 92.286115 140.753588 82.111059 140.744755 C 71.919269 140.751473 63.689019 149.748616 63.684276 160.851634 C 63.679532 171.954651 71.926985 180.963795 82.102041 180.972628 C 92.293831 180.965909 100.553746 171.967129 100.545559 160.850197 Z M 100.545559 160.850197 "
      transform="matrix(0.139994,-0.125254,0.150638,0.116399,236.100245,210.589754)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 196.500222 254.147991 L 221.703527 254.150427 L 221.698097 334.420726 L 196.494792 334.418289 Z M 196.500222 254.147991 "
      transform="matrix(0.135447,-0.128653,0.147412,0.119217,246.002392,212.165058)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 336.608509 166.546375 C 336.608509 181.212776 372.034539 201.978754 372.034539 148.818764 L 336.608509 148.818764 Z M 336.608509 166.546375 "
      transform="matrix(-0.205644,0,0,0.17099,348.014543,212.358119)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 389.75068 148.828441 C 389.75068 227.049242 326.268753 290.557954 248.027564 290.557954 C 169.786374 290.557954 106.304448 227.049242 106.304448 148.828441 C 106.304448 70.584794 169.805369 7.098928 248.027564 7.098928 C 326.268753 7.098928 389.75068 70.584794 389.75068 148.828441 Z M 336.602137 148.828441 C 336.602137 163.494841 359.415361 151.866807 345.966766 191.868157 C 336.602137 219.693197 318.898619 237.397964 289.399089 246.764357 C 249.167275 259.511789 262.691851 237.397964 248.027564 237.397964 C 233.363277 237.397964 246.887852 259.511789 206.656039 246.764357 C 177.156508 237.397964 159.452991 219.693197 150.088362 190.177638 C 137.323594 149.970684 159.452991 163.494841 159.452991 148.828441 C 159.452991 134.139195 137.323594 147.686198 150.088362 107.456399 C 159.452991 77.963684 177.156508 60.236073 206.656039 50.86968 C 246.887852 38.122248 233.363277 60.236073 248.027564 60.236073 C 262.691851 60.236073 249.167275 38.122248 289.399089 50.86968 C 318.898619 60.236073 336.602137 77.963684 345.966766 107.456399 C 358.731534 147.686198 336.602137 134.139195 336.602137 148.828441 Z M 336.602137 148.828441 "
      transform="matrix(0.205644,0,0,0.17099,246.002392,212.165058)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 126.546229 157.689472 C 106.291929 157.689472 124.614147 225.270743 117.450246 209.385106 C 110.264637 193.499468 106.291929 176.022819 106.291929 157.689472 C 106.291929 139.331647 110.264637 121.879476 117.450246 105.969361 C 124.614147 90.083723 106.291929 157.689472 126.546229 157.689472 Z M 126.546229 157.689472 "
      transform="matrix(0.179939,0,0,0.159588,252.377881,212.447942)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 372.047162 139.949673 C 372.047162 155.859607 369.919701 171.599835 362.30263 184.77326 C 354.32465 198.540656 372.047162 139.949673 354.32465 139.949673 C 372.047162 139.949673 356.015222 81.379903 362.30263 95.1473 C 368.571043 108.914696 372.047162 124.060953 372.047162 139.949673 Z M 372.047162 139.949673 "
      transform="matrix(0.205644,0,0,0.184142,246.002392,211.838758)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 296.294343 263.075587 C 281.459099 269.357923 265.14223 272.830342 248.027564 272.830342 C 230.912897 272.830342 214.615024 269.357923 199.77978 263.075587 C 184.944536 256.79325 248.027564 272.830342 248.027564 255.102731 C 248.027564 272.830342 311.129587 256.79325 296.294343 263.075587 Z M 296.294343 263.075587 "
      transform="matrix(0.205644,0,0,0.17099,246.002392,212.165058)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.027564 42.531306 C 248.027564 24.803694 184.944536 40.840786 199.77978 34.55845 C 214.615024 28.276113 230.912897 24.803694 248.027564 24.803694 C 265.14223 24.803694 281.459099 28.276113 296.294343 34.55845 C 311.129587 40.840786 248.027564 24.803694 248.027564 42.531306 Z M 248.027564 42.531306 "
      transform="matrix(0.205644,0,0,0.17099,246.002392,212.165058)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 276.592991 287.670512 C 267.35279 289.559466 257.803755 290.539056 248.027274 290.555262 C 238.246989 290.542002 228.710093 289.562092 219.466788 287.662188 L 218.039944 294.634188 C 227.73583 296.617587 237.751168 297.653164 248.031953 297.664795 C 258.296004 297.660875 268.337931 296.620723 278.007891 294.638467 Z M 276.592991 287.670512 "
      transform="matrix(0.139994,-0.125254,-0.150638,-0.116399,284.683028,286.00768)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 276.571234 287.670078 C 267.357594 289.568345 257.803963 290.563807 248.027482 290.547601 C 238.247197 290.560861 228.701966 289.551805 219.474696 287.676765 L 218.046865 294.630806 C 227.729756 296.626977 237.758753 297.653213 248.022803 297.657134 C 258.286854 297.661054 268.331857 296.62384 278.014813 294.626526 Z M 276.571234 287.670078 "
      transform="matrix(0.139994,-0.125254,0.150638,0.116399,239.818558,251.368204)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 267.710984 308.506903 C 258.484413 310.391256 247.51907 309.431334 237.725854 309.430679 C 227.94557 309.443939 218.444481 306.837979 209.221014 304.933473 L 218.046865 294.630806 C 227.729756 296.626977 237.758753 297.653213 248.022803 297.657134 C 258.286854 297.661054 268.331857 296.62384 278.014813 294.626526 Z M 267.710984 308.506903 "
      transform="matrix(0.139994,-0.125254,0.150638,0.116399,239.818558,251.368204)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 276.57071 287.66486 C 267.356833 289.553376 257.800807 290.543572 248.029496 290.548738 C 238.258186 290.553904 228.69476 289.555031 219.477546 287.673063 L 218.033001 294.629075 C 227.738865 296.618301 237.758199 297.661298 248.02716 297.661465 C 258.296121 297.661631 268.317058 296.613634 278.008121 294.624722 Z M 276.57071 287.66486 "
      transform="matrix(0.150638,0.116399,-0.139994,0.125254,280.507776,190.178776)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 100.54382 160.860812 C 100.548563 149.757795 92.30111 140.748651 82.10932 140.75537 C 71.934265 140.746537 63.68728 149.759231 63.682537 160.862249 C 63.677793 171.965266 71.925246 180.97441 82.117036 180.967691 C 92.292092 180.976524 100.539076 171.96383 100.54382 160.860812 Z M 100.54382 160.860812 "
      transform="matrix(0.139994,-0.125254,0.150638,0.116399,286.821546,249.963301)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 267.603281 281.47146 C 258.359938 283.356172 250.489213 284.401922 240.717903 284.407088 C 230.932679 284.425185 217.610683 283.706927 208.377917 281.808225 L 218.038125 294.630457 C 227.743988 296.619683 237.763323 297.66268 248.032284 297.662847 C 258.301245 297.663013 268.322182 296.615016 278.013245 294.626104 Z M 267.603281 281.47146 "
      transform="matrix(0.150638,0.116399,-0.139994,0.125254,280.163448,192.900662)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 173.946321 271.779479 L 173.110533 286.605793 L 169.862355 291.10623 L 170.83111 276.028623 Z M 173.946321 271.779479 "
      transform="matrix(0.205644,0,0,0.17099,246.002392,212.165058)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.542671 265.611366 L 334.094772 269.791976 L 335.139507 283.407512 L 332.214248 280.48337 Z M 330.542671 265.611366 "
      transform="matrix(0.205644,0,0,0.17099,246.002392,212.165058)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(75.294118%,75.294118%,75.294118%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 100.537862 160.865972 C 100.542606 149.762954 92.295153 140.75381 82.120097 140.744977 C 71.928307 140.751696 63.681323 149.76439 63.676579 160.867408 C 63.671835 171.970426 71.936023 180.964018 82.111078 180.972851 C 92.302869 180.966132 100.533118 171.968989 100.537862 160.865972 Z M 100.537862 160.865972 "
      transform="matrix(0.139994,-0.125254,0.150638,0.116399,238.266915,251.368204)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:0.56103;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.172368 396.85178 L 318.891045 414.560701 L 301.172368 432.288829 L 310.039568 441.143289 L 327.758244 423.434368 L 363.195597 458.85221 L 345.476921 476.580337 L 354.328398 485.434798 L 372.047075 467.725877 L 389.765751 485.434798 L 336.609721 538.580767 L 301.172368 503.143718 L 318.891045 485.434798 L 327.758244 494.289258 L 336.609721 485.434798 L 301.172368 449.997749 L 292.320891 458.85221 L 301.172368 467.725877 L 283.469414 485.434798 L 265.750737 467.725877 L 274.602214 458.85221 L 265.750737 449.997749 L 230.313384 485.434798 L 239.164861 494.289258 L 248.03206 485.434798 L 265.750737 503.143718 L 248.03206 520.871846 L 239.180583 512.017386 L 230.313384 520.871846 L 265.750737 556.289687 L 274.602214 547.435227 L 265.750737 538.580767 L 283.469414 520.871846 L 318.891045 556.289687 L 265.750737 609.454864 L 248.03206 591.726736 L 265.750737 574.017815 L 256.883538 565.163355 L 239.164861 582.872275 L 203.74323 547.435227 L 221.461907 529.726306 L 212.594707 520.871846 L 194.876031 538.580767 L 177.157354 520.871846 L 301.18809 396.85178 Z M 301.172368 396.85178 "
      transform="matrix(0.248458,0,0,0.203376,333.226058,64.325184)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.894121 786.603472 L 318.894121 822.059727 L 407.471782 910.642745 L 407.471782 839.768648 L 389.768827 822.059727 L 354.331474 822.059727 Z M 318.894121 786.603472 "
      transform="matrix(0.124229,0,0,0.101688,355.235694,104.679989)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 230.415509 698.772631 L 300.787112 769.147344 L 301.636099 804.3347 L 230.415509 733.114876 Z M 230.415509 698.772631 "
      transform="matrix(0.124229,0,0,0.101688,355.223389,104.677721)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.191166 804.312392 L 336.62852 768.894551 L 336.62852 733.47671 L 301.191166 768.894551 Z M 301.191166 804.312392 "
      transform="matrix(0.124229,0,0,0.101688,355.235694,104.679989)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.165061 804.312375 L 336.602415 768.894533 L 336.602415 733.476692 L 301.165061 768.894533 Z M 301.165061 804.312375 "
      transform="matrix(0.124229,0,0,0.101688,344.231124,95.672179)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.019415 644.893692 L 248.019415 680.311533 L 265.753813 662.602613 Z M 248.019415 644.893692 "
      transform="matrix(0.124229,0,0,0.101688,355.235694,104.679989)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.04552 644.89371 L 248.04552 680.311551 L 265.748474 662.60263 Z M 248.04552 644.89371 "
      transform="matrix(0.124229,0,0,0.101688,366.240263,113.6878)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 230.399825 698.793692 L 266.183061 734.211533 L 266.183061 769.629375 L 230.399825 733.135937 Z M 230.399825 698.793692 "
      transform="matrix(0.124229,0,0,0.101688,348.5769,99.199017)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.185827 804.317437 L 336.623181 768.899595 L 336.623181 733.481754 L 301.185827 768.899595 Z M 301.185827 804.317437 "
      transform="matrix(0.124229,0,0,0.101688,366.240263,84.86307)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.177121 804.331533 L 336.614474 768.913692 L 336.614474 733.457437 L 301.177121 768.913692 Z M 301.177121 804.331533 "
      transform="matrix(0.124229,0,0,0.101688,357.436657,92.068668)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 283.474166 822.048516 L 336.614474 768.88334 L 336.614474 733.465498 L 265.739768 804.339595 Z M 283.474166 822.048516 "
      transform="matrix(0.124229,0,0,0.101688,357.436657,81.259254)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.031474 644.875498 L 248.031474 680.331754 L 265.734429 662.584419 Z M 248.031474 644.875498 "
      transform="matrix(0.124229,0,0,0.101688,368.441227,90.267777)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.185827 804.317437 L 265.748474 768.899595 L 265.748474 733.481754 L 301.185827 768.899595 Z M 301.185827 804.317437 "
      transform="matrix(0.124229,0,0,0.101688,366.240263,84.86307)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 389.763488 822.048516 L 336.623181 768.88334 L 336.623181 733.465498 L 407.466443 804.339595 Z M 389.763488 822.048516 "
      transform="matrix(0.124229,0,0,0.101688,366.240263,81.259254)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 230.394737 698.042437 L 301.269443 768.916533 L 336.706796 768.916533 L 354.441195 786.625454 L 354.441195 857.499551 L 230.394737 733.114551 Z M 230.394737 698.042437 "
      transform="matrix(0.124229,0,0,0.101688,379.433001,95.669941)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 354.331474 822.059727 L 460.643534 715.767789 L 496.049443 715.767789 L 389.768827 822.059727 Z M 354.331474 822.059727 "
      transform="matrix(0.124229,0,0,0.101688,355.235694,104.679989)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 407.471782 839.768648 L 513.783842 733.47671 L 496.049443 715.767789 L 389.768827 822.059727 Z M 407.471782 839.768648 "
      transform="matrix(0.124229,0,0,0.101688,355.235694,104.679989)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 407.471477 839.769136 L 407.471477 910.643233 L 513.783536 804.312881 L 513.783536 733.477198 Z M 407.471477 839.769136 "
      transform="matrix(0.124229,0,0,0.101688,355.235731,104.67994)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 354.319443 822.041533 L 460.631503 715.749595 L 496.068856 715.749595 L 389.756796 822.041533 Z M 354.319443 822.041533 "
      transform="matrix(0.124229,0,0,0.101688,372.842657,90.267777)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 407.491195 839.750454 L 513.77181 733.458516 L 496.068856 715.749595 L 389.756796 822.041533 Z M 407.491195 839.750454 "
      transform="matrix(0.124229,0,0,0.101688,372.842657,90.267777)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 407.491195 839.750454 L 407.491195 910.624551 L 513.77181 804.332613 L 513.77181 733.458516 Z M 407.491195 839.750454 "
      transform="matrix(0.124229,0,0,0.101688,372.842657,90.267777)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.886796 786.619595 L 336.621195 768.910675 L 336.621195 733.454419 L 301.183842 768.910675 Z M 318.886796 786.619595 "
      transform="matrix(0.124229,0,0,0.101688,381.64676,83.061162)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.889135 786.616578 L 336.623534 768.907657 L 336.623534 733.451402 L 301.186181 768.907657 Z M 318.889135 786.616578 "
      transform="matrix(0.124229,0,0,0.101688,370.642563,74.053657)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 265.748474 768.917789 L 265.748474 733.461533 L 283.451429 751.170454 Z M 265.748474 768.917789 "
      transform="matrix(0.124229,0,0,0.101688,366.240263,99.275283)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.177121 804.331533 L 265.739768 768.913692 L 283.474166 751.166357 L 301.177121 768.913692 Z M 301.177121 804.331533 "
      transform="matrix(0.124229,0,0,0.101688,357.436657,92.068668)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.1221;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.186181 804.31334 L 283.451782 786.604419 L 301.186181 768.895498 Z M 301.186181 804.31334 "
      transform="matrix(0.124229,0,0,0.101688,370.642563,77.656455)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.669329 -453.494614 C 177.145821 -453.343269 141.785968 -418.274241 141.708616 -382.834311 C 141.706137 -347.073447 177.152293 -311.784862 212.6758 -311.936207 L 407.535643 -311.884581 C 442.743271 -311.834616 478.419004 -347.104955 478.421482 -382.865819 C 478.199339 -419.58949 443.052679 -453.594333 407.529171 -453.442988 Z M 212.669329 -453.494614 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,339.051955,209.45896)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.595368 -453.694408 C 177.289301 -453.728749 141.888205 -418.201849 141.793176 -382.640608 C 141.698147 -347.079367 177.227327 -311.674772 212.533394 -311.640431 L 407.353047 -311.83353 C 442.977255 -311.990176 478.449527 -347.193782 478.226415 -382.564036 C 478.35488 -419.609441 442.792264 -453.529872 407.486196 -453.564213 Z M 212.595368 -453.694408 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,339.0799,208.03064)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.518227 -453.372081 C 177.235725 -453.742982 141.634866 -418.151707 141.557514 -382.711778 C 141.870915 -347.152223 177.317071 -311.863639 212.524699 -311.813674 L 407.384541 -311.762048 C 442.908049 -311.913393 478.193028 -347.303357 478.27038 -382.743286 C 478.364117 -419.668267 442.901577 -453.4718 407.378069 -453.320455 Z M 212.518227 -453.372081 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,339.085139,206.247744)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.458635 -453.501348 C 177.152568 -453.535689 141.751472 -418.008789 141.656443 -382.447548 C 141.808379 -347.400588 177.019418 -311.805006 212.643626 -311.961652 L 407.534455 -311.831457 C 442.840522 -311.797117 478.241618 -347.324017 478.407823 -382.561964 C 478.465112 -419.930662 442.973672 -453.527799 407.349463 -453.371153 Z M 212.458635 -453.501348 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,339.113094,204.818518)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.640699 -453.605824 C 177.117192 -453.45448 141.832212 -418.064515 141.75486 -382.624586 C 141.677508 -347.184657 177.123663 -311.896072 212.722045 -311.726482 L 407.507013 -311.995791 C 442.789515 -311.624891 478.390375 -347.216165 478.467726 -382.656094 C 478.17071 -419.7007 443.024049 -453.705543 407.500542 -453.554198 Z M 212.640699 -453.605824 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,338.985577,215.882206)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.647072 -453.445493 C 177.341005 -453.479834 141.868733 -418.276228 141.773704 -382.714987 C 141.678676 -347.153746 177.207855 -311.749151 212.513923 -311.71481 L 407.404751 -311.584616 C 443.028959 -311.741262 478.430055 -347.268162 478.206943 -382.638416 C 478.335408 -419.68382 442.772792 -453.604252 407.466725 -453.638593 Z M 212.647072 -453.445493 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,339.013527,214.453433)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.487936 -453.490786 C 177.280308 -453.540751 141.679448 -417.949477 141.602096 -382.509547 C 141.840624 -347.270928 177.28678 -311.982344 212.569281 -311.611443 L 407.35425 -311.880753 C 442.952631 -311.711162 478.237611 -347.101126 478.314963 -382.541056 C 478.333826 -419.786972 442.871286 -453.590505 407.347778 -453.43916 Z M 212.487936 -453.490786 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,339.018761,212.671081)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.500888 -453.297716 C 177.123645 -453.655351 141.722549 -418.128451 141.62752 -382.56721 C 141.850633 -347.196956 177.061671 -311.601374 212.68588 -311.75802 L 407.505532 -311.951119 C 442.811599 -311.916778 478.283871 -347.120384 478.3789 -382.681625 C 478.507365 -419.72703 442.944749 -453.647461 407.32054 -453.490815 Z M 212.500888 -453.297716 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,339.046715,211.241855)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.638422 -453.653088 C 177.114914 -453.501743 141.829934 -418.111779 141.752582 -382.67185 C 141.67523 -347.23192 177.121386 -311.943336 212.719767 -311.773746 L 407.57961 -311.722119 C 442.787238 -311.672154 478.388097 -347.263429 478.465449 -382.703358 C 478.168432 -419.747964 443.021772 -453.752807 407.498264 -453.601462 Z M 212.638422 -453.653088 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,339.184702,196.613191)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.637462 -453.530555 C 177.331395 -453.564896 141.540981 -418.170303 141.764094 -382.800049 C 141.669065 -347.238808 177.198245 -311.834213 212.504312 -311.799872 L 407.395141 -311.669677 C 443.019349 -311.826323 478.420445 -347.353224 478.197332 -382.723478 C 478.325798 -419.768882 442.834358 -453.366019 407.52829 -453.40036 Z M 212.637462 -453.530555 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,339.212647,195.184871)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.48732 -453.530555 C 177.279692 -453.58052 141.678832 -417.989246 141.60148 -382.549316 C 141.840008 -347.310697 177.286164 -312.022113 212.568665 -311.651212 L 407.428508 -311.599586 C 442.952015 -311.750931 478.236995 -347.140895 478.314347 -382.580824 C 478.33321 -419.826741 442.87067 -453.630273 407.347162 -453.478929 Z M 212.48732 -453.530555 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,339.217886,193.401976)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.500729 -453.337495 C 177.123486 -453.69513 141.72239 -418.16823 141.627361 -382.606989 C 141.850473 -347.236735 177.061512 -311.641153 212.68572 -311.797799 L 407.505373 -311.990899 C 442.882616 -311.633264 478.283712 -347.160164 478.378741 -382.721405 C 478.507206 -419.766809 442.94459 -453.687241 407.320381 -453.530594 Z M 212.500729 -453.337495 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,339.245841,191.97275)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.683004 -453.450858 C 177.084623 -453.620448 141.799643 -418.230484 141.722291 -382.790555 C 141.644939 -347.350625 177.165969 -311.741106 212.689476 -311.892451 L 407.549319 -311.840824 C 442.756947 -311.790859 478.357806 -347.382133 478.435158 -382.822063 C 478.213015 -419.545734 443.066354 -453.550577 407.542847 -453.399232 Z M 212.683004 -453.450858 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,339.118324,203.036529)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.624291 -453.574746 C 177.318224 -453.609087 141.917128 -418.082187 141.750923 -382.84424 C 141.655894 -347.282999 177.185074 -311.878404 212.491141 -311.844063 L 407.38197 -311.713868 C 443.006178 -311.870514 478.407274 -347.397415 478.255338 -382.444375 C 478.312627 -419.813073 442.821187 -453.41021 407.515119 -453.444551 Z M 212.624291 -453.574746 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,339.146278,201.607302)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.473644 -453.574311 C 177.266016 -453.624277 141.665157 -418.033002 141.587805 -382.593073 C 141.826332 -347.354454 177.347362 -311.744934 212.55499 -311.694969 L 407.414832 -311.643343 C 442.93834 -311.794688 478.223319 -347.184652 478.300671 -382.624581 C 478.394408 -419.549562 442.856994 -453.67403 407.333486 -453.522685 Z M 212.473644 -453.574311 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,339.151517,199.824407)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.471806 -453.457157 C 177.165739 -453.491498 141.764643 -417.964598 141.598438 -382.726651 C 141.82155 -347.356397 177.032589 -311.760815 212.656797 -311.917461 L 407.547626 -311.787266 C 442.853693 -311.752925 478.254789 -347.279826 478.349818 -382.841067 C 478.478283 -419.886471 442.986843 -453.483608 407.362634 -453.326962 Z M 212.471806 -453.457157 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,339.179463,198.396087)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.639653 -453.573551 C 177.116146 -453.422206 141.831166 -418.032241 141.753814 -382.592312 C 141.676462 -347.152383 177.122618 -311.863798 212.646125 -312.015143 L 407.505968 -311.963517 C 442.788469 -311.592617 478.389329 -347.183891 478.466681 -382.62382 C 478.485544 -419.869737 443.023003 -453.673269 407.499496 -453.521924 Z M 212.639653 -453.573551 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,338.786451,235.151403)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.582356 -453.69882 C 177.276289 -453.733161 141.875193 -418.20626 141.780164 -382.64502 C 141.685135 -347.083779 177.214315 -311.679184 212.520382 -311.644843 L 407.340035 -311.837942 C 442.964243 -311.994588 478.436515 -347.198194 478.213403 -382.568448 C 478.341868 -419.613853 442.779252 -453.534284 407.473184 -453.568625 Z M 212.582356 -453.69882 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,338.814405,233.722176)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.498521 -453.406048 C 177.290893 -453.456013 141.61516 -418.185674 141.612681 -382.42481 C 141.851209 -347.186191 177.297365 -311.897606 212.504993 -311.847641 L 407.364835 -311.796015 C 442.888343 -311.94736 478.248196 -347.016389 478.250674 -382.777253 C 478.344411 -419.702234 442.881871 -453.505767 407.358363 -453.354422 Z M 212.498521 -453.406048 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,338.819641,231.939643)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.431446 -453.573684 C 177.125379 -453.608025 141.724283 -418.081124 141.629254 -382.519883 C 141.852367 -347.149629 176.992229 -311.877342 212.687614 -311.710694 L 407.507266 -311.903793 C 442.813333 -311.869452 478.285605 -347.073058 478.380634 -382.634299 C 478.5091 -419.679703 442.946483 -453.600135 407.322274 -453.443489 Z M 212.431446 -453.573684 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,338.847591,230.51087)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.625978 -453.617307 C 177.10247 -453.465962 141.81749 -418.075998 141.740138 -382.636069 C 141.662787 -347.196139 177.108942 -311.907555 212.707323 -311.737965 L 407.567166 -311.686339 C 442.774794 -311.636373 478.375653 -347.227648 478.453005 -382.667577 C 478.471868 -419.913493 443.009328 -453.717026 407.48582 -453.565681 Z M 212.625978 -453.617307 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,338.720082,241.573834)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.624609 -453.495188 C 177.318542 -453.529528 141.84627 -418.325922 141.751241 -382.764681 C 141.656212 -347.20344 177.185392 -311.798846 212.491459 -311.764505 L 407.382288 -311.63431 C 443.006496 -311.790956 478.407592 -347.317856 478.18448 -382.68811 C 478.312945 -419.733514 442.821505 -453.330652 407.444261 -453.688287 Z M 212.624609 -453.495188 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,338.748027,240.145514)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.474876 -453.494774 C 177.267248 -453.544739 141.666388 -417.953465 141.904916 -382.714846 C 141.827564 -347.274916 177.27372 -311.986332 212.556221 -311.615431 L 407.34119 -311.88474 C 442.939571 -311.71515 478.224551 -347.105114 478.301903 -382.545044 C 478.320766 -419.79096 442.858226 -453.594493 407.334718 -453.443148 Z M 212.474876 -453.494774 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,338.753266,238.362618)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.472124 -453.377599 C 177.166057 -453.411939 141.693785 -418.208333 141.598756 -382.647092 C 141.821869 -347.276838 177.032907 -311.681257 212.657116 -311.837903 L 407.547944 -311.707708 C 442.854011 -311.673367 478.255107 -347.200267 478.350136 -382.761508 C 478.478602 -419.806912 442.987161 -453.40405 407.609918 -453.761685 Z M 212.472124 -453.377599 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,338.781212,236.934298)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.616722 -453.696049 C 177.093214 -453.544704 141.808235 -418.15474 141.730883 -382.714811 C 141.653531 -347.274881 177.17456 -311.665362 212.698067 -311.816707 L 407.55791 -311.76508 C 442.765538 -311.715115 478.366397 -347.30639 478.443749 -382.746319 C 478.221606 -419.46999 443.074946 -453.474833 407.476564 -453.644423 Z M 212.616722 -453.696049 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,338.919203,222.305199)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.62445 -453.534967 C 177.318383 -453.569308 141.917287 -418.042408 141.751082 -382.804461 C 141.656053 -347.24322 177.185233 -311.838625 212.4913 -311.804284 L 407.382129 -311.674089 C 443.006337 -311.830735 478.407433 -347.357635 478.18432 -382.727889 C 478.312786 -419.773294 442.821346 -453.370431 407.515278 -453.404772 Z M 212.62445 -453.534967 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,338.947153,220.876408)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.470937 -453.549532 C 177.263309 -453.599497 141.662449 -418.008223 141.585098 -382.568294 C 141.823625 -347.329675 177.269781 -312.04109 212.552282 -311.67019 L 407.412125 -311.618564 C 442.935632 -311.769909 478.220612 -347.159873 478.297964 -382.599802 C 478.316827 -419.845718 442.854287 -453.649251 407.330779 -453.497906 Z M 212.470937 -453.549532 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,338.95239,219.093694)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.476691 -453.394737 C 177.170623 -453.429077 141.698351 -418.225471 141.603323 -382.66423 C 141.826435 -347.293976 177.037474 -311.698395 212.661682 -311.855041 L 407.55251 -311.724846 C 442.858578 -311.690505 478.259674 -347.217405 478.354702 -382.778646 C 478.483168 -419.82405 442.991727 -453.421188 407.296343 -453.587836 Z M 212.476691 -453.394737 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,338.98034,217.664921)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.668283 -453.46234 C 177.069902 -453.631931 141.784922 -418.241967 141.70757 -382.802037 C 141.705092 -347.041173 177.151247 -311.752588 212.674755 -311.903933 L 407.534597 -311.852307 C 442.742225 -311.802342 478.343085 -347.393616 478.420437 -382.833546 C 478.198294 -419.557216 443.051633 -453.562059 407.528125 -453.410714 Z M 212.668283 -453.46234 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,338.852828,228.728156)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.601828 -453.62444 C 177.295761 -453.658781 141.894665 -418.131881 141.799636 -382.57064 C 141.633431 -347.332693 177.162611 -311.928098 212.468678 -311.893758 L 407.359506 -311.763563 C 442.983715 -311.920209 478.384811 -347.447109 478.232874 -382.494069 C 478.36134 -419.539473 442.798723 -453.459905 407.492656 -453.494246 Z M 212.601828 -453.62444 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,338.880778,227.299383)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.522166 -453.317322 C 177.239664 -453.688223 141.638805 -418.096949 141.561453 -382.657019 C 141.79998 -347.4184 177.32101 -311.808881 212.528637 -311.758915 L 407.38848 -311.707289 C 442.911988 -311.858634 478.196967 -347.248598 478.274319 -382.688528 C 478.368056 -419.613509 442.905516 -453.417041 407.307134 -453.586632 Z M 212.522166 -453.317322 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,338.886015,225.516669)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.454068 -453.48421 C 177.148001 -453.518551 141.746905 -417.991651 141.5807 -382.753704 C 141.803813 -347.38345 177.014851 -311.787868 212.63906 -311.944514 L 407.529888 -311.814319 C 442.835955 -311.779978 478.237051 -347.306879 478.403256 -382.544825 C 478.460546 -419.913524 442.969105 -453.510661 407.344897 -453.354015 Z M 212.454068 -453.48421 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,338.913965,224.087895)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.626594 -453.577538 C 177.103086 -453.426193 141.818106 -418.036229 141.740754 -382.5963 C 141.663402 -347.156371 177.109558 -311.867786 212.707939 -311.698196 L 407.492908 -311.967505 C 443.091289 -311.797915 478.376269 -347.187879 478.453621 -382.627808 C 478.472484 -419.873725 443.009944 -453.677257 407.486436 -453.525912 Z M 212.626594 -453.577538 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,338.520956,260.842939)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.624768 -453.455408 C 177.318701 -453.489749 141.846429 -418.286143 141.7514 -382.724902 C 141.656371 -347.163661 177.185551 -311.759066 212.491618 -311.724725 L 407.382447 -311.594531 C 443.006655 -311.751177 478.407751 -347.278077 478.184639 -382.648331 C 478.313104 -419.693735 442.750488 -453.614167 407.44442 -453.648507 Z M 212.624768 -453.455408 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,338.548902,259.414619)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.475492 -453.455005 C 177.267864 -453.50497 141.667004 -417.913696 141.905532 -382.675077 C 141.82818 -347.235147 177.274336 -311.946563 212.481963 -311.896598 L 407.341806 -311.844972 C 442.940187 -311.675381 478.225167 -347.065345 478.302519 -382.505275 C 478.321382 -419.751191 442.858842 -453.554724 407.335334 -453.403379 Z M 212.475492 -453.455005 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,338.554141,257.631724)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.416859 -453.585643 C 177.110792 -453.619983 141.709696 -418.093083 141.614667 -382.531842 C 141.83778 -347.161588 177.048818 -311.566006 212.673027 -311.722652 L 407.492679 -311.915752 C 442.798746 -311.881411 478.271018 -347.085017 478.366047 -382.646258 C 478.494513 -419.691662 442.931896 -453.612094 407.625829 -453.646435 Z M 212.416859 -453.585643 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,338.582096,256.202498)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.611597 -453.499599 C 177.30553 -453.53394 141.833258 -418.330334 141.738229 -382.769093 C 141.6432 -347.207852 177.17238 -311.803257 212.478447 -311.768916 L 407.369276 -311.638722 C 442.993484 -311.795368 478.39458 -347.322268 478.171468 -382.692522 C 478.299933 -419.737926 442.808493 -453.335064 407.431249 -453.692699 Z M 212.611597 -453.499599 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,338.482533,265.83705)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.461816 -453.498762 C 177.254188 -453.548727 141.653329 -417.957453 141.891856 -382.718833 C 141.814504 -347.278904 177.26066 -311.99032 212.543161 -311.619419 L 407.32813 -311.888728 C 442.926512 -311.719138 478.211491 -347.109102 478.288843 -382.549031 C 478.307706 -419.794948 442.845166 -453.59848 407.321658 -453.447136 Z M 212.461816 -453.498762 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,338.487772,264.054155)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.459112 -453.38201 C 177.153045 -453.416351 141.680773 -418.212745 141.585744 -382.651504 C 141.808857 -347.28125 177.019895 -311.685668 212.644104 -311.842314 L 407.534932 -311.712119 C 442.840999 -311.677779 478.242095 -347.204679 478.337124 -382.76592 C 478.46559 -419.811324 442.974149 -453.408462 407.596906 -453.766096 Z M 212.459112 -453.38201 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,338.515717,262.625835)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.67056 -453.415077 C 177.072179 -453.584667 141.787199 -418.194703 141.709847 -382.754774 C 141.632495 -347.314844 177.153525 -311.705325 212.677032 -311.85667 L 407.536875 -311.805044 C 442.744503 -311.755078 478.345362 -347.346353 478.422714 -382.786282 C 478.516451 -419.711263 443.05391 -453.514796 407.455529 -453.684386 Z M 212.67056 -453.415077 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,338.653704,247.997171)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.611438 -453.539379 C 177.305371 -453.573719 141.904275 -418.046819 141.73807 -382.808872 C 141.643041 -347.247631 177.172221 -311.843037 212.478288 -311.808696 L 407.369117 -311.678501 C 442.993325 -311.835147 478.394421 -347.362047 478.171309 -382.732301 C 478.299774 -419.777705 442.808334 -453.374843 407.502266 -453.409184 Z M 212.611438 -453.539379 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,338.681658,246.567945)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.444585 -453.613479 C 177.236957 -453.663444 141.636097 -418.07217 141.874625 -382.833551 C 141.797273 -347.393621 177.318302 -311.784101 212.52593 -311.734136 L 407.385773 -311.68251 C 442.90928 -311.833855 478.19426 -347.223819 478.271612 -382.663748 C 478.365349 -419.588729 442.902808 -453.392262 407.304427 -453.561853 Z M 212.444585 -453.613479 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,338.686888,244.785956)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.458953 -453.42179 C 177.152886 -453.45613 141.680614 -418.252524 141.585585 -382.691283 C 141.808698 -347.321029 177.019736 -311.725448 212.643945 -311.882094 L 407.534773 -311.751899 C 442.84084 -311.717558 478.241936 -347.244458 478.336965 -382.805699 C 478.46543 -419.851103 442.97399 -453.448241 407.667923 -453.482582 Z M 212.458953 -453.42179 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,338.714843,243.356729)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.640269 -453.533782 C 177.116762 -453.382437 141.831782 -417.992473 141.75443 -382.552543 C 141.677078 -347.112614 177.123234 -311.82403 212.646741 -311.975375 L 407.506584 -311.923748 C 443.104965 -311.754158 478.389945 -347.144122 478.467297 -382.584052 C 478.48616 -419.829968 443.023619 -453.633501 407.500112 -453.482156 Z M 212.640269 -453.533782 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,338.587325,254.420508)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.582515 -453.65904 C 177.276448 -453.693381 141.875352 -418.166481 141.780323 -382.60524 C 141.685294 -347.043999 177.143298 -311.962698 212.520541 -311.605064 L 407.340194 -311.798163 C 442.964402 -311.954809 478.436674 -347.158415 478.213562 -382.528669 C 478.342027 -419.574073 442.779411 -453.494505 407.473343 -453.528846 Z M 212.582515 -453.65904 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,338.61528,252.991282)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.505783 -453.3363 C 177.223281 -453.707201 141.622422 -418.115926 141.860949 -382.877307 C 141.783597 -347.437378 177.304627 -311.827858 212.512255 -311.777893 L 407.372097 -311.726267 C 442.895605 -311.877612 478.180584 -347.267576 478.257936 -382.707505 C 478.351673 -419.632486 442.889133 -453.436019 407.365625 -453.284674 Z M 212.505783 -453.3363 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,338.620519,251.208387)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.43003 -453.541452 C 177.123963 -453.575792 141.722867 -418.048892 141.627838 -382.487651 C 141.779775 -347.440691 176.990813 -311.845109 212.686198 -311.678461 L 407.50585 -311.871561 C 442.811917 -311.83722 478.284189 -347.040826 478.379218 -382.602067 C 478.507684 -419.647471 442.945067 -453.567903 407.639 -453.602244 Z M 212.43003 -453.541452 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,338.648464,249.780067)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.553077 -453.420942 C 177.345449 -453.470907 141.669716 -418.200568 141.592364 -382.760639 C 141.905766 -347.201085 177.036042 -311.71119 212.559549 -311.862535 L 407.419392 -311.810909 C 442.942899 -311.962254 478.302753 -347.031282 478.305231 -382.792147 C 478.398968 -419.717128 442.936427 -453.520661 407.41292 -453.369316 Z M 212.553077 -453.420942 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,338.354172,276.982402)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.494041 -453.549064 C 177.187974 -453.583405 141.786878 -418.056505 141.691849 -382.495264 C 141.843786 -347.448304 177.054824 -311.852722 212.679033 -312.009368 L 407.569861 -311.879173 C 442.875928 -311.844832 478.277024 -347.371732 478.443229 -382.609679 C 478.253553 -419.464097 443.009078 -453.575515 407.384869 -453.418869 Z M 212.494041 -453.549064 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,338.382127,275.553175)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.717855 -453.499719 C 177.119474 -453.669309 141.834494 -418.279345 141.757142 -382.839416 C 141.754664 -347.078551 177.200819 -311.789967 212.724327 -311.941312 L 407.584169 -311.889686 C 442.791797 -311.83972 478.392657 -347.430995 478.470009 -382.870924 C 478.247866 -419.594595 442.785325 -453.398127 407.577698 -453.448093 Z M 212.717855 -453.499719 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,338.387357,273.771186)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.659697 -453.622462 C 177.035489 -453.465816 141.634393 -417.938916 141.857505 -382.568662 C 141.691301 -347.330715 177.22048 -311.92612 212.526548 -311.891779 L 407.417376 -311.761584 C 443.041584 -311.91823 478.44268 -347.44513 478.290744 -382.49209 C 478.348033 -419.860789 442.856593 -453.457926 407.550526 -453.492267 Z M 212.659697 -453.622462 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,338.415312,272.34196)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.536294 -453.345432 C 177.159051 -453.703067 141.757955 -418.176167 141.662926 -382.614926 C 141.886039 -347.244672 177.097077 -311.64909 212.721286 -311.805736 L 407.540938 -311.998835 C 442.918181 -311.6412 478.319277 -347.1681 478.414306 -382.729341 C 478.22463 -419.583759 442.980155 -453.695177 407.355946 -453.538531 Z M 212.536294 -453.345432 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,338.315749,281.976513)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.704179 -453.543475 C 177.180672 -453.392131 141.820818 -418.323102 141.81834 -382.562237 C 141.740988 -347.122308 177.187144 -311.833723 212.710651 -311.985068 L 407.570494 -311.933442 C 442.778122 -311.883477 478.453855 -347.153816 478.456333 -382.914681 C 478.23419 -419.638351 442.77165 -453.441884 407.564022 -453.491849 Z M 212.704179 -453.543475 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,338.320988,280.193617)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.70195 -453.41883 C 177.006566 -453.585478 141.60547 -418.058578 141.828582 -382.688324 C 141.733554 -347.127083 177.262733 -311.722488 212.568801 -311.688147 L 407.388453 -311.881246 C 443.012661 -312.037892 478.484933 -347.241498 478.261821 -382.611752 C 478.390287 -419.657157 442.82767 -453.577588 407.521603 -453.611929 Z M 212.70195 -453.41883 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,338.348933,278.765297)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.508495 -453.623172 C 177.300867 -453.673138 141.700007 -418.081863 141.622655 -382.641934 C 141.861183 -347.403315 177.066333 -311.592485 212.58984 -311.74383 L 407.449683 -311.692204 C 442.97319 -311.843549 478.25817 -347.233513 478.335522 -382.673442 C 478.429259 -419.598423 442.966718 -453.401956 407.368337 -453.571546 Z M 212.508495 -453.623172 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,338.420551,270.559064)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.507212 -453.504873 C 177.201145 -453.539214 141.800049 -418.012314 141.633844 -382.774367 C 141.856957 -347.404113 177.067995 -311.808531 212.692204 -311.965177 L 407.583032 -311.834982 C 442.889099 -311.800641 478.290195 -347.327541 478.4564 -382.565488 C 478.195548 -419.7432 443.022249 -453.531324 407.39804 -453.374678 Z M 212.507212 -453.504873 "
      transform="matrix(0.010845,0.00640672,-0.00238763,0.0106722,338.448496,269.130744)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.673272 -453.701949 C 177.149765 -453.550604 141.864785 -418.16064 141.787433 -382.720711 C 141.710081 -347.280781 177.23111 -311.671262 212.754618 -311.822607 L 407.614461 -311.770981 C 442.822088 -311.721015 478.422948 -347.31229 478.5003 -382.752219 C 478.278157 -419.47589 442.740743 -453.600358 407.533115 -453.650323 Z M 212.673272 -453.701949 "
      transform="matrix(0.0107656,0.00675285,-0.0025116,0.010596,338.453735,267.347849)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.546031 -453.553767 C 177.285363 -453.541049 141.83871 -418.098171 141.652725 -382.668011 C 141.783312 -347.43612 177.245177 -311.893318 212.505844 -311.906036 L 407.530453 -311.923535 C 442.861731 -311.612625 478.308384 -347.055503 478.423759 -382.809291 C 478.327303 -419.533961 442.831308 -453.583983 407.57064 -453.571265 Z M 212.546031 -453.553767 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.255094,209.668373)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.635373 -453.507093 C 177.262016 -453.475896 141.614608 -418.216989 141.726238 -382.851357 C 141.90475 -347.159877 177.070937 -311.637518 212.763093 -311.856639 L 407.509394 -311.959262 C 442.949633 -311.664613 478.21136 -347.061442 478.418529 -382.614999 C 478.291286 -419.79779 442.821913 -453.315066 407.381674 -453.609716 Z M 212.635373 -453.507093 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.263783,208.239875)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.739244 -453.637164 C 177.162005 -453.426177 141.715352 -417.983299 141.845939 -382.751408 C 141.659953 -347.321247 177.121818 -311.778446 212.699058 -311.989433 L 407.407095 -311.808662 C 442.984334 -312.019649 478.430987 -347.462527 478.300401 -382.694418 C 478.449906 -419.940985 443.024521 -453.66738 407.447281 -453.456393 Z M 212.739244 -453.637164 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.244987,206.457036)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.526467 -453.32079 C 177.086228 -453.61544 141.824501 -418.21861 141.617332 -382.665054 C 141.728961 -347.299421 177.213947 -311.964986 212.654187 -311.670336 L 407.400488 -311.772959 C 442.773845 -311.804157 478.35437 -347.38891 478.242741 -382.754543 C 478.501178 -419.799411 442.964923 -453.642534 407.591567 -453.611337 Z M 212.526467 -453.32079 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.253672,205.027631)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.531832 -453.329068 C 177.200554 -453.639978 141.753901 -418.1971 141.638526 -382.443312 C 141.769113 -347.211421 177.230978 -311.66862 212.491646 -311.681338 L 407.516255 -311.698836 C 442.776922 -311.711554 478.223575 -347.154432 478.40956 -382.584592 C 478.242494 -419.63289 442.817109 -453.359285 407.485831 -453.670195 Z M 212.531832 -453.329068 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.275312,216.091863)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.559589 -453.571022 C 177.186233 -453.539825 141.605707 -417.955071 141.717337 -382.589438 C 141.828967 -347.223806 177.313953 -311.889371 212.687309 -311.920568 L 407.500493 -311.697344 C 442.87385 -311.728541 478.454375 -347.313295 478.342746 -382.678927 C 478.282385 -419.535871 443.064929 -453.566919 407.372773 -453.347798 Z M 212.559589 -453.571022 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.283999,214.662911)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.652774 -453.743587 C 177.075534 -453.532601 141.628881 -418.089723 141.759468 -382.857832 C 141.644093 -347.104044 177.035348 -311.88487 212.683198 -311.772229 L 407.391235 -311.591459 C 442.968474 -311.802445 478.415127 -347.245323 478.284541 -382.477215 C 478.434046 -419.723782 443.008661 -453.450176 407.360811 -453.562817 Z M 212.652774 -453.743587 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.265205,212.880616)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.760031 -453.617925 C 177.067876 -453.398804 141.806149 -418.001974 141.917778 -382.636342 C 141.710609 -347.082786 177.195595 -311.74835 212.568952 -311.779548 L 407.315254 -311.882171 C 443.074291 -311.775445 478.336018 -347.172274 478.224389 -382.537907 C 478.415944 -419.908622 442.946571 -453.425898 407.506332 -453.720548 Z M 212.760031 -453.617925 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.27389,211.451212)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.631746 -453.739495 C 177.054507 -453.528508 141.607854 -418.08563 141.73844 -382.853739 C 141.623066 -347.099951 177.01432 -311.880777 212.66217 -311.768137 L 407.370207 -311.587366 C 442.947447 -311.798353 478.3941 -347.241231 478.263513 -382.473122 C 478.413018 -419.719689 442.987633 -453.446084 407.339783 -453.558725 Z M 212.631746 -453.739495 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.214661,196.822119)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.723208 -453.689989 C 177.031052 -453.470867 141.769325 -418.074038 141.880955 -382.708405 C 141.673786 -347.154849 177.158772 -311.820414 212.532129 -311.851611 L 407.345313 -311.628387 C 443.037468 -311.847508 478.299195 -347.244338 478.187565 -382.60997 C 478.446003 -419.654838 442.909748 -453.497962 407.536392 -453.466765 Z M 212.723208 -453.689989 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.22335,195.393621)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.508388 -453.624623 C 177.24772 -453.611905 141.801067 -418.169027 141.615082 -382.738867 C 141.816279 -347.183348 177.207533 -311.964174 212.538812 -311.653264 L 407.49281 -311.99439 C 442.824088 -311.683481 478.270741 -347.126358 478.386116 -382.880146 C 478.28966 -419.604817 442.864275 -453.331211 407.532997 -453.642121 Z M 212.508388 -453.624623 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.204554,193.610782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.614302 -453.503686 C 177.240945 -453.472488 141.660419 -417.887735 141.772049 -382.522102 C 141.883679 -347.15647 177.049866 -311.634111 212.742021 -311.853232 L 407.488323 -311.955855 C 442.928562 -311.661205 478.509087 -347.245959 478.397458 -382.611591 C 478.270214 -419.794382 442.800842 -453.311659 407.360603 -453.606309 Z M 212.614302 -453.503686 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.213239,192.181377)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.615886 -453.522291 C 177.038646 -453.311305 141.908565 -418.066696 141.72258 -382.636535 C 141.853167 -347.404644 177.315032 -311.861843 212.575699 -311.87456 L 407.600308 -311.892059 C 442.860976 -311.904777 478.307629 -347.347655 478.493614 -382.777815 C 478.326548 -419.826113 442.901163 -453.552507 407.640495 -453.53979 Z M 212.615886 -453.522291 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.234879,203.245699)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.653725 -453.723729 C 177.280368 -453.692532 141.699843 -418.107778 141.811472 -382.742146 C 141.604303 -347.188589 177.08929 -311.854154 212.462646 -311.885351 L 407.594629 -311.850051 C 442.967985 -311.881248 478.229712 -347.278078 478.436881 -382.831634 C 478.37652 -419.688579 442.840266 -453.531702 407.466909 -453.500505 Z M 212.653725 -453.723729 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.243565,201.816294)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.509143 -453.332471 C 177.177865 -453.643381 141.731212 -418.200503 141.615837 -382.446715 C 141.746424 -347.214824 177.208289 -311.672022 212.468956 -311.68474 L 407.493565 -311.702239 C 442.754233 -311.714956 478.200886 -347.157834 478.386871 -382.587994 C 478.219804 -419.636292 442.79442 -453.362687 407.463142 -453.673597 Z M 212.509143 -453.332471 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.224769,200.033456)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.529067 -453.612897 C 177.15571 -453.581699 141.893983 -418.18487 141.686814 -382.631313 C 141.798444 -347.265681 177.28343 -311.931246 212.656787 -311.962443 L 407.469971 -311.739219 C 442.843327 -311.770416 478.423853 -347.35517 478.312223 -382.720802 C 478.251862 -419.577746 443.034406 -453.608794 407.342251 -453.389673 Z M 212.529067 -453.612897 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.233457,198.604957)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.674557 -453.455528 C 177.026707 -453.568169 141.580054 -418.125291 141.781251 -382.569772 C 141.595266 -347.139612 177.057131 -311.596811 212.63437 -311.807797 L 407.342407 -311.627027 C 442.919647 -311.838013 478.3663 -347.280891 478.235713 -382.512783 C 478.385218 -419.75935 442.959833 -453.485744 407.311983 -453.598385 Z M 212.674557 -453.455528 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.335963,235.361788)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.71162 -453.655073 C 177.019464 -453.435952 141.757737 -418.039122 141.869367 -382.67349 C 141.662198 -347.119933 177.147184 -311.785498 212.520541 -311.816695 L 407.652523 -311.781395 C 443.02588 -311.812593 478.287607 -347.209422 478.494776 -382.762979 C 478.434415 -419.619923 442.89816 -453.463046 407.524804 -453.431849 Z M 212.71162 -453.655073 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.344648,233.932383)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.490557 -453.619314 C 177.22989 -453.606597 141.783237 -418.163719 141.597251 -382.733558 C 141.798448 -347.17804 177.189703 -311.958866 212.520981 -311.647956 L 407.54559 -311.665455 C 442.806258 -311.678172 478.252911 -347.12105 478.438896 -382.55121 C 478.271829 -419.599508 442.846444 -453.325903 407.515166 -453.636813 Z M 212.490557 -453.619314 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.325854,232.149907)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.588537 -453.536694 C 177.21518 -453.505497 141.634655 -417.920743 141.746284 -382.555111 C 141.857914 -347.189478 177.024101 -311.667119 212.716256 -311.88624 L 407.529441 -311.663016 C 442.902797 -311.694213 478.483323 -347.278967 478.371693 -382.644599 C 478.311332 -419.501544 442.775077 -453.344667 407.401721 -453.31347 Z M 212.588537 -453.536694 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.334541,230.720955)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.604702 -453.487004 C 177.344034 -453.474286 141.897381 -418.031408 141.711396 -382.601248 C 141.841982 -347.369356 177.303847 -311.826555 212.564515 -311.839273 L 407.589124 -311.856771 C 442.849792 -311.869489 478.296445 -347.312367 478.48243 -382.742527 C 478.315363 -419.790825 442.889978 -453.51722 407.629311 -453.504502 Z M 212.604702 -453.487004 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.356178,241.784461)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.693268 -453.438437 C 177.253028 -453.733087 141.672503 -418.148333 141.784133 -382.782701 C 141.576964 -347.229145 177.06195 -311.894709 212.502189 -311.60006 L 407.567289 -311.890607 C 442.940645 -311.921804 478.202372 -347.318633 478.409541 -382.87219 C 478.34918 -419.729134 442.812926 -453.572257 407.439569 -453.54106 Z M 212.693268 -453.438437 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.364866,240.355963)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.481343 -453.372131 C 177.150065 -453.683041 141.703412 -418.240163 141.904609 -382.684644 C 141.718624 -347.254484 177.180489 -311.711683 212.441157 -311.7244 L 407.465766 -311.741899 C 443.043005 -311.952886 478.489658 -347.395764 478.359071 -382.627655 C 478.192005 -419.675953 442.76662 -453.402347 407.505952 -453.38963 Z M 212.481343 -453.372131 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.34607,238.573124)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.501727 -453.653452 C 177.128371 -453.622255 141.866644 -418.225425 141.659475 -382.671869 C 141.771104 -347.306236 177.25609 -311.971801 212.629447 -312.002998 L 407.442631 -311.779774 C 442.815987 -311.810972 478.396513 -347.395725 478.284883 -382.761358 C 478.224522 -419.618302 443.007066 -453.649349 407.63371 -453.618152 Z M 212.501727 -453.653452 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.354759,237.144626)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.451676 -453.407012 C 177.191008 -453.394294 141.744355 -417.951416 141.874942 -382.719525 C 141.688956 -347.289365 177.150821 -311.746563 212.728061 -311.95755 L 407.436098 -311.77678 C 443.013338 -311.987766 478.45999 -347.430644 478.329404 -382.662536 C 478.478909 -419.909103 443.053524 -453.635497 407.476285 -453.424511 Z M 212.451676 -453.407012 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.295529,222.515098)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.480656 -453.650045 C 177.107299 -453.618847 141.845572 -418.222018 141.638403 -382.668461 C 141.750033 -347.302829 177.235019 -311.968394 212.675258 -311.673744 L 407.421559 -311.776367 C 442.794916 -311.807564 478.375441 -347.392318 478.263812 -382.75795 C 478.203451 -419.614894 442.985995 -453.645942 407.612638 -453.614745 Z M 212.480656 -453.650045 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.304215,221.086129)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.650206 -453.466425 C 177.002356 -453.579066 141.555703 -418.136188 141.7569 -382.580669 C 141.887487 -347.348778 177.03278 -311.607708 212.610019 -311.818694 L 407.634628 -311.836193 C 442.895296 -311.848911 478.341949 -347.291788 478.211362 -382.52368 C 478.360867 -419.770247 442.935483 -453.496641 407.358243 -453.285655 Z M 212.650206 -453.466425 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.28542,219.303471)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.679522 -453.704495 C 177.306165 -453.673298 141.72564 -418.088544 141.837269 -382.722912 C 141.6301 -347.169355 177.115086 -311.83492 212.488443 -311.866117 L 407.620426 -311.830817 C 442.993782 -311.862015 478.255509 -347.258844 478.462678 -382.812401 C 478.402317 -419.669345 442.866062 -453.512468 407.492706 -453.481271 Z M 212.679522 -453.704495 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.294107,217.87452)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.688755 -453.680226 C 177.111516 -453.46924 141.664863 -418.026362 141.79545 -382.794471 C 141.680075 -347.040683 177.071329 -311.821509 212.719179 -311.708868 L 407.356606 -311.851725 C 443.004456 -311.739084 478.451109 -347.181962 478.249912 -382.737481 C 478.470027 -419.660421 442.974032 -453.710443 407.396792 -453.499456 Z M 212.688755 -453.680226 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.315745,228.938298)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.468604 -453.40322 C 177.095248 -453.372023 141.766638 -418.301041 141.559469 -382.747484 C 141.671099 -347.381852 177.222968 -311.72157 212.596324 -311.752767 L 407.342626 -311.85539 C 443.101663 -311.748664 478.36339 -347.145494 478.251761 -382.511126 C 478.443316 -419.881841 442.973944 -453.399118 407.533704 -453.693767 Z M 212.468604 -453.40322 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.324432,227.509346)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.570382 -453.54287 C 177.309714 -453.530152 141.863061 -418.087274 141.677076 -382.657114 C 141.807662 -347.425223 177.269527 -311.882421 212.530195 -311.895139 L 407.554804 -311.912638 C 442.815472 -311.925355 478.262125 -347.368233 478.44811 -382.798393 C 478.351653 -419.523064 442.855658 -453.573086 407.594991 -453.560368 Z M 212.570382 -453.54287 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.305637,225.726689)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.667471 -453.457671 C 177.227232 -453.752321 141.646706 -418.167567 141.758336 -382.801935 C 141.869965 -347.436302 177.103035 -311.588096 212.476392 -311.619293 L 407.541492 -311.90984 C 442.914848 -311.941037 478.243458 -347.01202 478.450627 -382.565577 C 478.323383 -419.748368 442.787129 -453.591491 407.413772 -453.560294 Z M 212.667471 -453.457671 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.314324,224.297737)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.749088 -453.605968 C 177.171848 -453.394982 141.725195 -417.952104 141.855782 -382.720213 C 141.669797 -347.290052 177.131661 -311.747251 212.708901 -311.958238 L 407.416938 -311.777467 C 442.994178 -311.988454 478.440831 -347.431332 478.310244 -382.663223 C 478.459749 -419.90979 443.034364 -453.636185 407.457125 -453.425198 Z M 212.749088 -453.605968 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.416829,261.054296)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.520198 -453.364753 C 177.079959 -453.659403 141.818232 -418.262573 141.611063 -382.709017 C 141.722693 -347.343384 177.207679 -312.008949 212.647918 -311.714299 L 407.39422 -311.816922 C 442.767576 -311.848119 478.414984 -347.107026 478.303355 -382.472659 C 478.49491 -419.843374 442.958655 -453.686497 407.585298 -453.6553 Z M 212.520198 -453.364753 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.425517,259.625797)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.625729 -453.491096 C 176.977879 -453.603737 141.847798 -418.359128 141.732423 -382.60534 C 141.86301 -347.373449 177.324875 -311.830648 212.585543 -311.843365 L 407.610152 -311.860864 C 442.870819 -311.873582 478.317472 -347.316459 478.503457 -382.74662 C 478.336391 -419.794918 442.911006 -453.521312 407.650338 -453.508595 Z M 212.625729 -453.491096 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.406721,257.842959)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.663208 -453.692221 C 177.289852 -453.661024 141.709326 -418.07627 141.820956 -382.710638 C 141.613787 -347.157081 177.098773 -311.822646 212.472129 -311.853843 L 407.604112 -311.818543 C 442.977469 -311.84974 478.239196 -347.24657 478.446365 -382.800126 C 478.386004 -419.657071 442.849749 -453.500194 407.476392 -453.468997 Z M 212.663208 -453.692221 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.415407,256.413554)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.450716 -453.398493 C 177.077359 -453.367296 141.74875 -418.296313 141.541581 -382.742757 C 141.720093 -347.051278 177.205079 -311.716842 212.578435 -311.74804 L 407.324737 -311.850663 C 443.083774 -311.743937 478.345501 -347.140766 478.233872 -382.506399 C 478.425427 -419.877114 442.956055 -453.394391 407.515816 -453.68904 Z M 212.450716 -453.398493 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.445732,266.048471)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.555874 -453.522572 C 177.295206 -453.509854 141.848553 -418.066976 141.662568 -382.636816 C 141.793155 -347.404924 177.25502 -311.862123 212.515687 -311.874841 L 407.540296 -311.89234 C 442.800964 -311.905057 478.247617 -347.347935 478.433602 -382.778095 C 478.266536 -419.826393 442.841151 -453.552788 407.580483 -453.54007 Z M 212.555874 -453.522572 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.426936,264.265633)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.644856 -453.475585 C 177.2715 -453.444388 141.624092 -418.185481 141.735721 -382.819849 C 141.847351 -347.454216 177.080421 -311.60601 212.772576 -311.825131 L 407.518877 -311.927754 C 442.892234 -311.958952 478.220844 -347.029934 478.428012 -382.583491 C 478.300769 -419.766282 442.764514 -453.609405 407.391158 -453.578208 Z M 212.644856 -453.475585 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.435625,262.837134)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.518231 -453.593427 C 177.257563 -453.58071 141.81091 -418.137832 141.624925 -382.707672 C 141.826122 -347.152153 177.217377 -311.932979 212.548655 -311.622069 L 407.502653 -311.963195 C 442.833931 -311.652285 478.280584 -347.095163 478.395959 -382.848951 C 478.299503 -419.573622 442.803508 -453.623644 407.54284 -453.610926 Z M 212.518231 -453.593427 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.376396,248.208042)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.623785 -453.472178 C 177.250428 -453.44098 141.60302 -418.182074 141.71465 -382.816441 C 141.826279 -347.450809 177.059349 -311.602603 212.751505 -311.821724 L 407.497806 -311.924347 C 442.871163 -311.955544 478.518571 -347.214451 478.406941 -382.580083 C 478.279698 -419.762874 442.743443 -453.605998 407.370086 -453.574801 Z M 212.623785 -453.472178 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.385081,246.778637)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.711445 -453.676824 C 177.134205 -453.465838 141.687552 -418.02296 141.818139 -382.791068 C 141.632154 -347.360908 177.094018 -311.818107 212.671258 -312.029093 L 407.379295 -311.848323 C 443.027145 -311.735682 478.473798 -347.17856 478.272601 -382.734079 C 478.492716 -419.657018 442.996721 -453.70704 407.419482 -453.496054 Z M 212.711445 -453.676824 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.366288,244.996705)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.499127 -453.361345 C 177.058888 -453.655995 141.797161 -418.259166 141.589992 -382.705609 C 141.701621 -347.339977 177.25349 -311.679695 212.626847 -311.710892 L 407.373148 -311.813515 C 442.746505 -311.844712 478.393913 -347.103619 478.282283 -382.469251 C 478.473838 -419.839966 442.937584 -453.68309 407.564227 -453.651892 Z M 212.499127 -453.361345 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.374974,243.5673)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.502371 -453.376224 C 177.171093 -453.687134 141.72444 -418.244256 141.609065 -382.490468 C 141.739652 -347.258577 177.201517 -311.715775 212.462184 -311.728493 L 407.486793 -311.745992 C 442.747461 -311.758709 478.194114 -347.201587 478.380099 -382.631747 C 478.213032 -419.680045 442.787648 -453.40644 407.52698 -453.393722 Z M 212.502371 -453.376224 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.396614,254.631622)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.53855 -453.581389 C 177.165194 -453.550192 141.584668 -417.965438 141.696298 -382.599806 C 141.807927 -347.234173 177.292913 -311.899738 212.66627 -311.930935 L 407.479454 -311.707711 C 442.852811 -311.738908 478.433336 -347.323662 478.321707 -382.689294 C 478.261346 -419.546239 443.043889 -453.577286 407.351734 -453.358165 Z M 212.53855 -453.581389 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.405299,253.202217)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.641589 -453.7083 C 177.06435 -453.497313 141.617697 -418.054435 141.748284 -382.822544 C 141.632909 -347.068756 177.024163 -311.849582 212.672013 -311.736941 L 407.30944 -311.879799 C 442.95729 -311.767158 478.403943 -347.210036 478.202746 -382.765554 C 478.422861 -419.688494 442.997476 -453.414888 407.349626 -453.527529 Z M 212.641589 -453.7083 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.386503,251.419379)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.732691 -453.658481 C 177.040536 -453.439359 141.778809 -418.04253 141.890438 -382.676897 C 141.683269 -347.123341 177.168256 -311.788906 212.541612 -311.820103 L 407.354796 -311.596879 C 443.046951 -311.816 478.308678 -347.21283 478.197049 -382.578462 C 478.455486 -419.62333 442.919231 -453.466454 407.545875 -453.435257 Z M 212.732691 -453.658481 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.395192,249.99088)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.757554 -453.559231 C 177.109704 -453.671872 141.663051 -418.228994 141.864248 -382.673476 C 141.678263 -347.243315 177.140128 -311.700514 212.717368 -311.911501 L 407.425405 -311.73073 C 443.002644 -311.941717 478.449297 -347.384595 478.31871 -382.616486 C 478.468216 -419.863053 443.042831 -453.589448 407.465591 -453.378461 Z M 212.757554 -453.559231 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.467629,277.194368)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.476996 -453.568128 C 177.10364 -453.536931 141.841913 -418.140101 141.634744 -382.586545 C 141.746373 -347.220912 177.231359 -311.886477 212.604716 -311.917674 L 407.4179 -311.69445 C 442.791257 -311.725648 478.371782 -347.310401 478.260152 -382.676034 C 478.51859 -419.720902 442.982335 -453.564025 407.608979 -453.532828 Z M 212.476996 -453.568128 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.476314,275.764963)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.634196 -453.444359 C 176.986346 -453.557 141.856265 -418.312391 141.74089 -382.558603 C 141.871477 -347.326712 177.333342 -311.783911 212.594009 -311.796628 L 407.618618 -311.814127 C 442.879286 -311.826845 478.325939 -347.269723 478.511924 -382.699883 C 478.344857 -419.748181 442.919473 -453.474575 407.588194 -453.785485 Z M 212.634196 -453.444359 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.457521,273.983031)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.671137 -453.64522 C 177.29778 -453.614023 141.717255 -418.029269 141.828884 -382.663637 C 141.621715 -347.11008 177.106701 -311.775645 212.480058 -311.806842 L 407.612041 -311.771542 C 442.985397 -311.802739 478.247124 -347.199569 478.454293 -382.753125 C 478.393932 -419.61007 442.857677 -453.453193 407.484321 -453.421996 Z M 212.671137 -453.64522 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.466207,272.553626)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.777443 -453.539416 C 177.085288 -453.320295 141.756678 -418.249312 141.868308 -382.88368 C 141.661139 -347.330124 177.213007 -311.669841 212.586364 -311.701038 L 407.332665 -311.803662 C 443.024821 -312.022783 478.35343 -347.093765 478.2418 -382.459398 C 478.433356 -419.830113 442.897101 -453.673236 407.523744 -453.642039 Z M 212.777443 -453.539416 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.496532,282.188543)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.564341 -453.475835 C 177.303673 -453.463117 141.85702 -418.020239 141.671035 -382.590079 C 141.801622 -347.358188 177.263486 -311.815386 212.524154 -311.828104 L 407.548763 -311.845603 C 442.809431 -311.85832 478.256084 -347.301198 478.442069 -382.731358 C 478.275002 -419.779656 442.849617 -453.506051 407.58895 -453.493333 Z M 212.564341 -453.475835 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.477736,280.405705)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.652785 -453.428584 C 177.212546 -453.723234 141.63202 -418.13848 141.74365 -382.772848 C 141.855279 -347.407215 177.021467 -311.884856 212.780504 -311.77813 L 407.526806 -311.880753 C 442.900163 -311.911951 478.16189 -347.30878 478.369059 -382.862337 C 478.308698 -419.719281 442.772443 -453.562404 407.399086 -453.531207 Z M 212.652785 -453.428584 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.486425,278.977206)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.456842 -453.578166 C 177.196175 -453.565449 141.749522 -418.122571 141.563537 -382.69241 C 141.764734 -347.136891 177.155988 -311.917718 212.487266 -311.606808 L 407.441265 -311.947934 C 442.772543 -311.637024 478.219196 -347.079902 478.334571 -382.83369 C 478.238114 -419.55836 442.742119 -453.608382 407.481451 -453.595665 Z M 212.456842 -453.578166 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.447411,270.770788)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.546479 -453.534388 C 177.173122 -453.50319 141.911395 -418.106361 141.704226 -382.552804 C 141.815856 -347.187172 177.300842 -311.852737 212.674199 -311.883934 L 407.487383 -311.66071 C 442.860739 -311.691907 478.441265 -347.276661 478.329635 -382.642293 C 478.269274 -419.499237 443.051818 -453.530285 407.359663 -453.311164 Z M 212.546479 -453.534388 "
      transform="matrix(0.0109305,0.00630389,-0.00224357,0.0106941,341.456099,269.342289)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.650056 -453.661563 C 177.072816 -453.450576 141.626163 -418.007698 141.75675 -382.775807 C 141.641375 -347.022019 177.03263 -311.802845 212.68048 -311.690205 L 407.317906 -311.833062 C 442.965756 -311.720421 478.412409 -347.163299 478.211212 -382.718817 C 478.431328 -419.641757 442.935333 -453.691779 407.358093 -453.480792 Z M 212.650056 -453.661563 "
      transform="matrix(0.0108558,0.00665075,-0.00236856,0.0106191,341.437303,267.559451)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.531331 -453.429797 C 177.042082 -453.550507 141.62988 -417.894157 141.816413 -382.882304 C 141.645876 -347.3924 177.266915 -311.877235 212.453836 -311.661078 L 407.6304 -311.90596 C 442.817322 -311.689803 478.229524 -347.346153 478.400062 -382.836056 C 478.487245 -419.760925 442.894817 -453.458523 407.405568 -453.579232 Z M 212.531331 -453.429797 "
      transform="matrix(0.0135318,0.00337576,0.00193615,0.0106927,350.009782,199.830142)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.51317 -453.445824 C 177.252303 -453.445091 141.634617 -418.190483 141.85831 -382.723642 C 141.791746 -347.363285 177.276304 -311.897177 212.537171 -311.89791 L 407.543167 -311.889434 C 442.804033 -311.890167 478.421719 -347.144775 478.198027 -382.611615 C 478.396132 -419.826124 442.780032 -453.43808 407.519166 -453.437348 Z M 212.51317 -453.445824 "
      transform="matrix(0.00376636,0.0108567,-0.0102666,0.0070905,347.29816,197.021241)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.663939 -453.426756 C 177.01018 -453.495079 141.729529 -418.008244 141.682772 -382.503453 C 141.922493 -347.227336 177.109628 -311.704589 212.629999 -311.915308 L 407.466077 -311.690634 C 442.986448 -311.901353 478.400487 -347.109147 478.447244 -382.613938 C 478.533401 -419.692243 442.886999 -453.691843 407.366629 -453.481124 Z M 212.663939 -453.426756 "
      transform="matrix(0.0098695,0.00808807,-0.00471788,0.0101325,348.316588,198.729785)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.556171 -453.593946 C 177.212858 -453.424464 141.730583 -418.201532 141.696686 -382.629888 C 141.662789 -347.058243 177.351192 -311.889125 212.747037 -311.799509 L 407.534882 -311.811792 C 442.930727 -311.722176 478.36047 -347.204206 478.394367 -382.77585 C 478.165606 -419.642982 442.739862 -453.516613 407.396548 -453.347131 Z M 212.556171 -453.593946 "
      transform="matrix(-0.00824299,0.0091645,-0.0134051,-0.0018581,347.030994,194.353738)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.427248 -453.375654 C 177.23775 -453.574588 141.655571 -417.949827 141.59493 -382.607541 C 141.534289 -347.265256 177.09428 -311.68394 212.665365 -311.705662 L 407.468839 -311.77495 C 443.039924 -311.796672 478.240517 -347.200776 478.301158 -382.543062 C 478.446102 -419.670519 442.801807 -453.466664 407.612309 -453.665598 Z M 212.427248 -453.375654 "
      transform="matrix(-0.00780513,0.00922342,-0.0134976,-0.00175259,348.831511,194.343768)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.566913 -453.390016 C 177.060312 -453.459595 141.729307 -418.130017 141.904902 -382.730861 C 141.704219 -347.086726 177.322769 -311.870054 212.389445 -311.807558 L 407.39214 -311.680481 C 442.835094 -311.862965 478.166099 -347.192542 478.430429 -382.584616 C 478.249236 -419.741126 442.948916 -453.697486 407.505962 -453.515001 Z M 212.566913 -453.390016 "
      transform="matrix(-0.00866509,0.00891561,-0.0133092,-0.0022512,339.155305,194.291197)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.496872 -453.47432 C 177.240679 -453.683123 141.814643 -418.239884 141.6448 -382.587842 C 141.793234 -347.42257 177.258604 -311.794411 212.454055 -311.839693 L 407.598572 -311.886804 C 442.854766 -311.678001 478.280802 -347.12124 478.450644 -382.773282 C 478.316777 -419.695747 442.83684 -453.566713 407.641389 -453.521431 Z M 212.496872 -453.47432 "
      transform="matrix(-0.00823031,0.00898721,-0.0134063,-0.0021485,340.95468,194.334704)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.703587 -453.60727 C 177.196986 -453.676849 141.929627 -418.095209 141.665298 -382.703136 C 141.840893 -347.30398 177.083164 -311.842328 212.589765 -311.77275 L 407.528814 -311.897735 C 443.035414 -311.828156 478.36642 -347.157734 478.190824 -382.55689 C 478.449556 -419.706318 442.772958 -453.417697 407.642636 -453.732255 Z M 212.703587 -453.60727 "
      transform="matrix(-0.00866509,0.00891561,-0.0133092,-0.0022512,343.200473,194.363708)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.462768 -453.559116 C 177.322817 -453.497298 141.691091 -418.13329 141.679997 -382.530252 C 141.619356 -347.187966 177.1298 -311.867402 212.700885 -311.889124 L 407.504359 -311.958412 C 443.075444 -311.980135 478.325584 -347.123487 478.336678 -382.726525 C 478.481623 -419.853981 442.886874 -453.389374 407.31579 -453.367652 Z M 212.462768 -453.559116 "
      transform="matrix(-0.00780513,0.00922342,-0.0134976,-0.00175259,344.786343,194.389994)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.649076 -453.513616 C 177.142476 -453.583195 141.81147 -418.253617 141.610787 -382.609482 C 141.786383 -347.210326 177.028654 -311.748674 212.535254 -311.679096 L 407.474303 -311.804081 C 442.980904 -311.734502 478.248263 -347.316142 478.512592 -382.708216 C 478.395045 -419.612664 443.094726 -453.569023 407.588125 -453.638601 Z M 212.649076 -453.513616 "
      transform="matrix(-0.00866509,0.00891561,-0.0133092,-0.0022512,336.556716,193.610499)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.580678 -453.453276 C 177.056855 -453.605745 141.55725 -417.996175 141.852892 -382.733508 C 141.781218 -347.201942 177.137477 -311.748379 212.56541 -311.826382 L 407.610819 -311.794457 C 443.038752 -311.872461 478.171042 -347.213131 478.242716 -382.744697 C 478.202251 -419.697522 443.05402 -453.499355 407.530197 -453.651823 Z M 212.580678 -453.453276 "
      transform="matrix(-0.00951088,0.0081517,-0.0129918,-0.00339162,337.810341,193.881509)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.638623 -453.684232 C 177.012929 -453.464285 141.593598 -418.018341 141.799961 -382.792344 C 141.698033 -347.211054 177.221799 -311.849711 212.539203 -311.714365 L 407.555649 -311.858195 C 442.873052 -311.722849 478.292384 -347.168793 478.394312 -382.750083 C 478.382024 -419.632626 442.972473 -453.692716 407.346779 -453.472769 Z M 212.638623 -453.684232 "
      transform="matrix(-0.0125671,0.00522316,-0.0109046,-0.00646228,337.092189,192.723144)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.742485 -453.639773 C 177.192 -453.497852 141.534996 -418.067506 141.582982 -382.583161 C 141.630967 -347.098817 177.229438 -311.756393 212.779924 -311.898313 L 407.37212 -311.604021 C 442.922605 -311.745941 478.425104 -347.372206 478.377119 -382.856551 C 478.386263 -419.775835 442.885167 -453.487401 407.672257 -453.671112 Z M 212.742485 -453.639773 "
      transform="matrix(-0.0109232,-0.00657205,-0.0113239,0.00518282,375.671257,267.182882)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.559805 -453.452375 C 177.130434 -453.549641 141.836174 -418.279381 141.630049 -382.597247 C 141.765159 -347.22972 177.17741 -311.965846 212.60678 -311.86858 L 407.486705 -311.691837 C 442.763846 -311.795489 478.399341 -347.380357 478.264231 -382.747884 C 478.20267 -419.548302 443.058105 -453.693891 407.2875 -453.476551 Z M 212.559805 -453.452375 "
      transform="matrix(-0.010553,-0.00673955,-0.0114462,0.00510632,377.291116,266.732407)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.602696 -453.703317 C 177.052211 -453.561397 141.887286 -418.260764 141.935272 -382.776419 C 141.828753 -347.487993 177.089649 -311.819937 212.640134 -311.961858 L 407.569905 -311.993197 C 442.782816 -311.809486 478.43982 -347.239832 478.391834 -382.724177 C 478.400978 -419.643461 442.899882 -453.355027 407.532467 -453.734657 Z M 212.602696 -453.703317 "
      transform="matrix(-0.0109232,-0.00657205,-0.0113239,0.00518282,379.321354,266.194012)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.682828 -453.762066 C 177.064452 -453.343806 141.770192 -418.073546 141.905302 -382.706019 C 141.546948 -347.224804 177.111428 -311.760011 212.540799 -311.662744 L 407.609728 -312.001527 C 443.039098 -311.904261 478.333359 -347.174521 478.198249 -382.542048 C 478.477923 -419.657073 442.992123 -453.488056 407.562752 -453.585322 Z M 212.682828 -453.762066 "
      transform="matrix(-0.010553,-0.00673955,-0.0114462,0.00510632,380.941214,265.74263)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.486547 -453.527065 C 177.075094 -453.405009 141.873987 -418.170035 141.679126 -382.605209 C 141.79894 -347.103947 177.015531 -311.661177 212.741658 -311.846798 L 407.36885 -311.935591 C 443.004445 -311.732867 478.296085 -347.356185 478.400413 -382.532667 C 478.328054 -419.523742 442.749334 -453.413134 407.428413 -453.679423 Z M 212.486547 -453.527065 "
      transform="matrix(-0.0130271,0.00213228,-0.00303691,0.0105558,392.968885,260.510045)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.528165 -453.601439 C 177.186043 -453.465612 141.611658 -418.119249 141.539758 -382.652326 C 141.760598 -347.443533 177.191183 -311.856049 212.693669 -311.735488 L 407.556428 -311.713374 C 442.898549 -311.849201 478.472935 -347.195564 478.544835 -382.662487 C 478.400396 -419.927611 442.893409 -453.458764 407.390924 -453.579325 Z M 212.528165 -453.601439 "
      transform="matrix(-0.00860041,0.00865883,0.00537932,0.00981982,394.533522,256.000759)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.492845 -453.434131 C 177.11971 -453.65094 141.646156 -418.229995 141.859623 -382.66603 C 141.84526 -347.419486 177.204031 -311.956133 212.577165 -311.739323 L 407.587545 -311.953006 C 442.960679 -311.736196 478.434233 -347.157142 478.220767 -382.721107 C 478.351528 -419.849918 442.876359 -453.431004 407.503225 -453.647814 Z M 212.492845 -453.434131 "
      transform="matrix(-0.0131235,0.00247535,-0.00288684,0.0105295,393.83512,258.881264)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.462859 -453.334215 C 177.167311 -453.593474 141.774741 -418.063541 141.900389 -382.797289 C 141.673811 -347.226797 177.095007 -311.701285 212.613225 -311.964966 L 407.601082 -311.779812 C 442.767075 -311.739253 478.2892 -347.050486 478.386222 -382.839678 C 478.317248 -419.80791 442.839379 -453.631441 407.321161 -453.36776 Z M 212.462859 -453.334215 "
      transform="matrix(-0.0102058,-0.00733631,-0.0118154,0.00434595,368.077626,268.692927)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.490694 -453.593029 C 177.001382 -453.523925 141.842491 -418.037472 141.690236 -382.550276 C 141.893634 -347.355807 177.23069 -311.937716 212.720001 -312.00682 L 407.619069 -311.98273 C 442.752727 -311.759109 478.394293 -347.315409 478.190894 -382.509879 C 478.419353 -419.696919 442.879073 -453.638044 407.389762 -453.568939 Z M 212.490694 -453.593029 "
      transform="matrix(-0.00981931,-0.00747635,-0.0119302,0.00426084,369.737548,268.361188)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.55868 -453.653708 C 177.089229 -453.413534 141.867408 -418.213796 141.948842 -382.734386 C 141.865742 -347.440996 177.085896 -311.867932 212.71988 -311.922087 L 407.5509 -311.757407 C 443.020351 -311.997582 478.242172 -347.197319 478.325272 -382.49071 C 478.413358 -419.901703 443.023684 -453.543184 407.3897 -453.489029 Z M 212.55868 -453.653708 "
      transform="matrix(-0.0111983,-0.00624004,-0.0110943,0.00551928,372.339501,267.916151)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.470787 -453.449099 C 177.123621 -453.426052 141.924405 -418.152337 141.71015 -382.621404 C 141.858101 -347.370737 177.104312 -311.629018 212.451478 -311.652066 L 407.574154 -311.848475 C 442.921321 -311.871523 478.482743 -347.425503 478.334792 -382.67617 C 478.344755 -419.656534 442.94063 -453.668556 407.593463 -453.645508 Z M 212.470787 -453.449099 "
      transform="matrix(-0.00940136,-0.0078438,-0.01215,0.00380058,372.988095,267.40948)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.539082 -453.495385 C 177.326172 -453.679096 141.823672 -418.052831 141.717153 -382.764406 C 141.765139 -347.280061 177.026035 -311.612005 212.57652 -311.753926 L 407.506291 -311.785265 C 443.056777 -311.927185 478.221701 -347.227819 478.32822 -382.516244 C 478.18286 -419.631447 443.019338 -453.668645 407.468853 -453.526725 Z M 212.539082 -453.495385 "
      transform="matrix(-0.0109232,-0.00657205,-0.0113239,0.00518282,382.971452,265.204235)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.696024 -453.62923 C 177.266654 -453.726497 141.631159 -418.14163 141.766269 -382.774103 C 141.560144 -347.091969 177.124625 -311.627176 212.401765 -311.730829 L 407.622925 -311.868692 C 443.052295 -311.771425 478.194326 -347.242605 478.55268 -382.723819 C 478.33889 -419.725157 442.853089 -453.55614 407.423719 -453.653407 Z M 212.696024 -453.62923 "
      transform="matrix(-0.010553,-0.00673955,-0.0114462,0.00510632,384.591311,264.753759)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.62139 -453.707508 C 177.171535 -453.521182 141.856914 -418.139598 141.615884 -382.699271 C 141.818315 -347.238278 177.094336 -311.718541 212.544191 -311.904868 L 407.599047 -311.744187 C 443.048901 -311.930513 478.363523 -347.312097 478.22829 -382.507354 C 478.442398 -419.787165 442.749838 -453.488083 407.676246 -453.546827 Z M 212.62139 -453.707508 "
      transform="matrix(-0.00822046,-0.00891361,-0.012621,0.00225401,344.045022,270.050698)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.534529 -453.436003 C 177.281923 -453.599842 141.824795 -418.302902 141.684409 -382.574253 C 141.858888 -347.346252 177.285974 -311.954413 212.53858 -311.790574 L 407.343348 -311.605341 C 442.91082 -311.942151 478.367947 -347.239091 478.193468 -382.467091 C 478.392181 -419.767876 442.906769 -453.58758 407.275162 -453.518641 Z M 212.534529 -453.436003 "
      transform="matrix(-0.00780824,-0.0089854,-0.0127131,0.00215131,345.750422,270.006285)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.666715 -453.481609 C 177.149664 -453.561018 141.835042 -418.179435 141.66121 -382.473371 C 141.796443 -347.278114 177.072465 -311.758378 212.589516 -311.678969 L 407.577175 -311.784023 C 443.02703 -311.970349 478.341651 -347.351933 478.206418 -382.54719 C 478.420527 -419.827001 442.727966 -453.527919 407.654374 -453.586663 Z M 212.666715 -453.481609 "
      transform="matrix(-0.00822046,-0.00891361,-0.012621,0.00225401,347.880277,269.976374)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.618838 -453.456303 C 176.987232 -453.387364 141.530104 -418.090425 141.768718 -382.594553 C 141.564197 -347.133775 176.991283 -311.741935 212.622889 -311.810875 L 407.427657 -311.625641 C 442.995129 -311.962452 478.452256 -347.259391 478.277777 -382.487392 C 478.47649 -419.788177 442.991078 -453.607881 407.359471 -453.538942 Z M 212.618838 -453.456303 "
      transform="matrix(-0.00780824,-0.0089854,-0.0127131,0.00215131,349.586759,269.932867)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.5592 -453.644631 C 177.109346 -453.458305 141.794724 -418.076721 141.553695 -382.636394 C 141.756125 -347.175401 177.032147 -311.655664 212.482001 -311.841991 L 407.46966 -311.947045 C 442.986711 -311.867636 478.301333 -347.24922 478.542362 -382.689548 C 478.380209 -419.724288 443.06391 -453.670277 407.614056 -453.48395 Z M 212.5592 -453.644631 "
      transform="matrix(-0.00822046,-0.00891361,-0.012621,0.00225401,336.373429,270.198439)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.425273 -453.568781 C 177.236802 -453.464748 141.779674 -418.167809 141.575153 -382.70703 C 141.813767 -347.211159 177.240853 -311.81932 212.493459 -311.655481 L 407.613092 -311.970897 C 442.865698 -311.807058 478.322826 -347.103997 478.463212 -382.832647 C 478.34706 -419.632783 442.861647 -453.452487 407.609041 -453.616326 Z M 212.425273 -453.568781 "
      transform="matrix(-0.00780824,-0.0089854,-0.0127131,0.00215131,338.079912,270.154026)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.604526 -453.418732 C 177.087474 -453.498141 141.772853 -418.116558 141.531823 -382.67623 C 141.734253 -347.215237 177.010275 -311.695501 212.527327 -311.616092 L 407.514985 -311.721146 C 442.96484 -311.907472 478.279461 -347.289056 478.520491 -382.729384 C 478.358337 -419.764124 443.042039 -453.710113 407.592184 -453.523786 Z M 212.604526 -453.418732 "
      transform="matrix(-0.00822046,-0.00891361,-0.012621,0.00225401,340.208684,270.124115)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.555939 -453.395462 C 177.303333 -453.559301 141.846206 -418.262362 141.705819 -382.533712 C 141.880299 -347.305712 177.307384 -311.913873 212.55999 -311.750034 L 407.300623 -311.832672 C 442.93223 -311.901611 478.389357 -347.198551 478.150743 -382.694422 C 478.413591 -419.727336 442.928179 -453.54704 407.296572 -453.478101 Z M 212.555939 -453.395462 "
      transform="matrix(-0.00780824,-0.0089854,-0.0127131,0.00215131,341.915167,270.080609)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.439997 -453.549519 C 177.280845 -453.550661 141.556632 -418.053186 141.823237 -382.869187 C 141.825694 -347.165203 177.25145 -311.980062 212.511726 -311.737136 L 407.570005 -311.737424 C 442.729157 -311.736282 478.45337 -347.233758 478.552036 -382.695958 C 478.409232 -419.88706 443.123823 -453.585082 407.498276 -453.549807 Z M 212.439997 -453.549519 "
      transform="matrix(-0.00933216,-0.00811055,-0.0122529,0.00339216,359.944763,269.699019)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.470308 -453.527877 C 177.156126 -453.690892 141.902799 -418.121293 141.855704 -382.626599 C 141.700658 -347.369829 176.967744 -311.71212 212.646458 -311.824416 L 407.495572 -311.716376 C 442.701804 -311.791283 478.427614 -347.398272 478.474709 -382.892965 C 478.346586 -419.852583 442.998137 -453.532133 407.319422 -453.419836 Z M 212.470308 -453.527877 "
      transform="matrix(-0.00923515,-0.00798094,-0.012228,0.00362112,361.846148,269.505958)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.577797 -453.551582 C 177.297519 -453.55549 141.794871 -418.251374 141.683161 -382.708984 C 141.571451 -347.166594 177.101326 -311.90742 212.492265 -311.669147 L 407.603052 -312.013915 C 442.993991 -311.775641 478.385979 -347.314123 478.497689 -382.856513 C 478.4174 -419.857852 443.079524 -453.658076 407.437939 -453.37486 Z M 212.577797 -453.551582 "
      transform="matrix(-0.00963134,-0.00786157,-0.0121197,0.00371203,363.948934,269.225884)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.482186 -453.545983 C 176.992875 -453.476878 141.706963 -418.213304 141.681728 -382.50323 C 141.885127 -347.30876 177.222183 -311.890669 212.711494 -311.959774 L 407.610562 -311.935683 C 442.744219 -311.712063 478.385786 -347.268362 478.538041 -382.755557 C 478.410846 -419.649872 442.870565 -453.590997 407.381254 -453.521892 Z M 212.482186 -453.545983 "
      transform="matrix(-0.00981931,-0.00747635,-0.0119302,0.00426084,365.999745,269.083581)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.73215 -453.578311 C 177.215099 -453.657719 141.900477 -418.276136 141.726645 -382.570073 C 141.861878 -347.374816 177.1379 -311.855079 212.654951 -311.77567 L 407.64261 -311.880724 C 442.716202 -311.82198 478.474283 -347.182899 478.271853 -382.643891 C 478.485962 -419.923703 442.860598 -453.358885 407.343547 -453.438294 Z M 212.73215 -453.578311 "
      transform="matrix(-0.00822046,-0.00891361,-0.012621,0.00225401,351.715532,269.902956)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.48164 -453.563324 C 177.244152 -453.722544 141.911553 -418.256395 141.816442 -382.631026 C 141.637022 -347.260508 177.152719 -311.728701 212.763528 -311.822264 L 407.460858 -311.705936 C 443.071666 -311.799498 478.404265 -347.265647 478.499376 -382.891016 C 478.257251 -419.535789 442.789778 -453.540558 407.55229 -453.699778 Z M 212.48164 -453.563324 "
      transform="matrix(-0.00847925,-0.00854861,-0.0125225,0.00282802,353.707872,269.89208)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.804976 -453.546139 C 176.991752 -453.545256 141.735949 -418.205933 141.826054 -382.828463 C 141.551498 -347.171346 176.987511 -311.755728 212.698081 -311.997228 L 407.334234 -311.806931 C 443.044803 -312.048431 478.403261 -347.147137 478.313155 -382.524607 C 478.439102 -419.664453 442.787037 -453.317696 407.441129 -453.355843 Z M 212.804976 -453.546139 "
      transform="matrix(-0.00938057,-0.00807131,-0.0122323,0.00344346,356.088935,269.834071)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.587492 -453.54453 C 177.038286 -453.362347 141.794209 -418.287386 141.882411 -382.630224 C 141.891592 -347.231449 177.075009 -311.767247 212.624215 -311.949431 L 407.404976 -311.686447 C 442.954182 -311.868631 478.494207 -347.449589 478.485026 -382.848364 C 478.455708 -419.786684 442.917459 -453.46373 407.289232 -453.539934 Z M 212.587492 -453.54453 "
      transform="matrix(-0.00830568,-0.00866717,-0.0125777,0.00265064,357.447841,269.560341)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.787583 -453.677288 C 177.253033 -453.616069 141.692711 -418.111859 141.66694 -382.66887 C 141.641169 -347.22588 177.149949 -311.844111 212.6845 -311.905331 L 407.547693 -311.965761 C 443.082244 -312.02698 478.258008 -347.347004 478.283779 -382.789993 C 478.261349 -419.724027 442.80077 -453.614752 407.650777 -453.737718 Z M 212.787583 -453.677288 "
      transform="matrix(-0.0061782,-0.0100029,-0.0128993,0.000323363,312.52111,268.62676)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.545529 -453.5791 C 177.043215 -453.480912 141.881578 -418.289716 141.841887 -382.726598 C 141.802196 -347.163481 177.258603 -311.997024 212.767132 -311.796738 L 407.456198 -311.780841 C 442.958511 -311.879028 478.506732 -347.24186 478.159839 -382.633342 C 478.168451 -419.688826 442.743123 -453.362917 407.621177 -453.734838 Z M 212.545529 -453.5791 "
      transform="matrix(-0.00575732,-0.010012,-0.0129675,0.000208506,314.209077,268.839762)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.637241 -453.597806 C 176.987827 -453.516363 141.702646 -418.2226 141.68553 -382.790551 C 141.711004 -347.077677 177.300713 -312.007895 212.568779 -311.869609 L 407.475217 -311.798771 C 442.743283 -311.660485 478.409813 -347.173977 478.426928 -382.606026 C 478.188506 -419.723019 442.811745 -453.388682 407.543679 -453.526968 Z M 212.637241 -453.597806 "
      transform="matrix(-0.00737068,-0.0094201,-0.0127921,0.00142865,316.776522,269.397191)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.761238 -453.717852 C 177.273879 -453.667235 141.785801 -418.117046 141.785081 -382.617474 C 141.784361 -347.117902 177.23164 -311.951102 212.718999 -312.001718 L 407.535341 -311.931748 C 443.0227 -311.982365 478.550137 -347.250398 478.167047 -382.542687 C 478.354782 -419.660319 443.064939 -453.698498 407.57758 -453.647881 Z M 212.761238 -453.717852 "
      transform="matrix(-0.00695305,-0.00946452,-0.0128744,0.00132025,318.481737,269.464264)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.714556 -453.714335 C 177.212242 -453.616148 141.670238 -417.954843 141.624331 -382.690199 C 141.58464 -347.127081 177.047263 -311.66215 212.549576 -311.760338 L 407.625225 -311.916076 C 442.740955 -311.842628 478.289176 -347.20546 478.328866 -382.768577 C 478.337478 -419.824061 442.912151 -453.498152 407.409837 -453.399965 Z M 212.714556 -453.714335 "
      transform="matrix(-0.00575732,-0.010012,-0.0129675,0.000208506,306.630172,267.83367)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.487019 -453.555191 C 177.337025 -453.678158 141.776704 -418.173948 141.750933 -382.730959 C 141.725162 -347.287969 177.233942 -311.9062 212.768492 -311.96742 L 407.631686 -312.02785 C 442.791319 -311.606675 478.351641 -347.110884 478.377412 -382.553873 C 478.345342 -419.786116 442.884763 -453.676841 407.350212 -453.615621 Z M 212.487019 -453.555191 "
      transform="matrix(-0.0061782,-0.0100029,-0.0128993,0.000323363,308.731766,268.123714)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.630387 -453.63017 C 177.128073 -453.531983 141.579853 -418.169151 141.926745 -382.777669 C 141.887054 -347.214551 177.349677 -311.749621 212.465407 -311.676173 L 407.541056 -311.831912 C 443.043369 -311.930099 478.205007 -347.121295 478.244697 -382.684413 C 478.253309 -419.739897 442.827982 -453.413987 407.319452 -453.614274 Z M 212.630387 -453.63017 "
      transform="matrix(-0.00575732,-0.010012,-0.0129675,0.000208506,310.419841,268.336716)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.484521 -453.45825 C 177.216455 -453.596536 141.549925 -418.083044 141.53281 -382.650995 C 141.897042 -347.438674 177.147993 -311.868339 212.416059 -311.730053 L 407.322497 -311.659215 C 442.971911 -311.740657 478.214503 -347.315245 478.231618 -382.747294 C 478.417133 -419.803192 443.040373 -453.468854 407.390959 -453.387412 Z M 212.484521 -453.45825 "
      transform="matrix(-0.00737068,-0.0094201,-0.0127921,0.00142865,328.269369,269.922897)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.602248 -453.61692 C 177.114889 -453.566304 141.626811 -418.016115 141.626091 -382.516543 C 141.586012 -347.299126 177.07265 -311.850171 212.560009 -311.900787 L 407.376351 -311.830817 C 442.86371 -311.881434 478.391148 -347.149467 478.391867 -382.649039 C 478.195792 -419.559388 442.905949 -453.597567 407.41859 -453.54695 Z M 212.602248 -453.61692 "
      transform="matrix(-0.00695305,-0.00946452,-0.0128744,0.00132025,329.974119,269.98997)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.493765 -453.54793 C 177.043911 -453.361604 141.72929 -417.98002 141.864522 -382.784763 C 141.69069 -347.0787 177.342974 -311.804034 212.416566 -311.74529 L 407.404225 -311.850344 C 442.921277 -311.770935 478.235898 -347.152518 478.40973 -382.858582 C 478.314774 -419.627587 442.998476 -453.573575 407.481424 -453.652984 Z M 212.493765 -453.54793 "
      transform="matrix(-0.00822046,-0.00891361,-0.012621,0.00225401,332.538174,270.271857)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.49304 -453.334622 C 177.240434 -453.498461 141.783307 -418.201521 141.64292 -382.472871 C 141.817399 -347.244871 177.244485 -311.853032 212.497091 -311.689193 L 407.616725 -312.004609 C 442.869331 -311.84077 478.326458 -347.13771 478.466845 -382.866359 C 478.350692 -419.666495 442.86528 -453.486199 407.612674 -453.650038 Z M 212.49304 -453.334622 "
      transform="matrix(-0.00780824,-0.0089854,-0.0127131,0.00215131,334.243574,270.22835)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.543193 -453.624268 C 177.317717 -453.48173 141.651187 -417.968238 141.634072 -382.536188 C 141.616956 -347.104139 177.249255 -311.753533 212.517321 -311.615247 L 407.381169 -311.825232 C 443.030583 -311.906675 478.315765 -347.200439 478.33288 -382.632488 C 478.518395 -419.688385 443.099045 -453.634872 407.449631 -453.553429 Z M 212.543193 -453.624268 "
      transform="matrix(-0.00737068,-0.0094201,-0.0127921,0.00142865,320.607521,269.572124)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.70654 -453.467558 C 177.219182 -453.416941 141.691744 -418.148908 141.691024 -382.649336 C 141.690304 -347.149764 177.176943 -311.700808 212.664302 -311.751425 L 407.480643 -311.681455 C 442.968002 -311.732071 478.456081 -347.28226 478.456801 -382.781832 C 478.260725 -419.692181 443.010241 -453.448204 407.483523 -453.679743 Z M 212.70654 -453.467558 "
      transform="matrix(-0.00695305,-0.00946452,-0.0128744,0.00132025,322.312704,269.639197)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.490909 -453.375354 C 177.222843 -453.51364 141.556313 -418.000148 141.920546 -382.787827 C 141.90343 -347.355778 177.154381 -311.785443 212.422447 -311.647158 L 407.328885 -311.576319 C 442.935709 -311.938586 478.220891 -347.232349 478.238006 -382.664399 C 478.423521 -419.720296 443.004171 -453.666783 407.354757 -453.58534 Z M 212.490909 -453.375354 "
      transform="matrix(-0.00737068,-0.0094201,-0.0127921,0.00142865,324.438445,269.747057)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.612047 -453.502548 C 177.124689 -453.451932 141.597251 -418.183898 141.596531 -382.684326 C 141.595811 -347.184754 177.08245 -311.735798 212.569809 -311.786415 L 407.38615 -311.716445 C 442.873509 -311.767061 478.361588 -347.31725 478.362308 -382.816822 C 478.166232 -419.727171 442.915748 -453.483195 407.428389 -453.432578 Z M 212.612047 -453.502548 "
      transform="matrix(-0.00695305,-0.00946452,-0.0128744,0.00132025,326.143628,269.81413)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.633375 -453.474866 C 177.162733 -453.713224 141.798634 -418.051862 141.709528 -382.498165 C 141.732775 -347.34095 177.226664 -311.945376 212.697307 -311.707018 L 407.465247 -311.855828 C 443.048242 -312.013951 478.299988 -347.278832 478.389094 -382.832528 C 478.311607 -419.871236 442.871957 -453.385317 407.401315 -453.623676 Z M 212.633375 -453.474866 "
      transform="matrix(0.0133,-0.00169259,0.00376888,-0.0103319,299.900609,253.944162)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.653294 -453.449444 C 177.191365 -453.466492 141.595583 -418.013038 141.777644 -382.742691 C 141.643791 -347.27219 177.287781 -311.984794 212.433795 -311.767592 L 407.432321 -311.831466 C 442.89425 -311.814418 478.490032 -347.267871 478.307971 -382.538219 C 478.336909 -419.785254 442.797834 -453.296115 407.65182 -453.513317 Z M 212.653294 -453.449444 "
      transform="matrix(0.0105761,-0.00671426,-0.00282338,-0.0105975,297.837888,256.977846)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.452943 -453.532743 C 177.292565 -453.501976 141.970729 -418.145168 141.809272 -382.819127 C 141.854525 -347.281297 177.060156 -311.774234 212.698858 -311.878283 L 407.451008 -311.770488 C 443.089711 -311.874537 478.411547 -347.231345 478.366294 -382.769175 C 478.515753 -419.797579 442.843795 -453.528997 407.476707 -453.710018 Z M 212.452943 -453.532743 "
      transform="matrix(0.00710421,-0.00956241,-0.00693389,-0.00911102,297.720946,260.05957)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.510381 -453.529366 C 177.149597 -453.357162 141.66396 -418.204959 141.654166 -382.817177 C 141.953665 -347.345279 177.11042 -311.80603 212.780496 -311.89412 L 407.30069 -311.702005 C 442.970767 -311.790095 478.341345 -347.350082 478.351139 -382.737865 C 478.209994 -419.672673 442.700651 -453.425341 407.534102 -453.576807 Z M 212.510381 -453.529366 "
      transform="matrix(0.00282165,-0.0108576,-0.0103753,-0.00651567,298.26126,262.660907)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.558438 -453.600548 C 177.064075 -453.365988 141.795289 -417.990344 141.735758 -382.87034 C 141.778306 -347.334365 177.132188 -311.63806 212.626551 -311.87262 L 407.490054 -311.759503 C 442.984417 -311.994063 478.538312 -347.348627 478.312733 -382.489712 C 478.432085 -419.64741 442.916303 -453.721992 407.42194 -453.487432 Z M 212.558438 -453.600548 "
      transform="matrix(0.0132469,0.000707165,0.00613987,-0.00956422,304.074779,249.683226)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.771148 -453.605348 C 177.250175 -453.441615 141.78729 -418.17436 141.666909 -382.670065 C 141.831832 -347.153973 177.232424 -311.813411 212.753397 -311.977143 L 407.298037 -311.687154 C 442.81901 -311.850887 478.281895 -347.118142 478.402276 -382.622436 C 478.380621 -419.765215 442.836761 -453.479091 407.494257 -453.716131 Z M 212.771148 -453.605348 "
      transform="matrix(0.013444,0.000395766,0.00598677,-0.00957056,302.74186,251.074534)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.684385 -453.579033 C 177.062865 -453.441216 141.677923 -418.231881 141.797611 -382.767602 C 141.608764 -347.262898 177.349971 -311.936437 212.546066 -311.641068 L 407.421929 -311.885028 C 442.926559 -311.630084 478.428391 -347.232181 478.308703 -382.69646 C 478.348036 -419.691359 443.064877 -453.56805 407.443358 -453.430232 Z M 212.684385 -453.579033 "
      transform="matrix(0.0131743,-0.00135596,0.0039208,-0.0103492,300.902193,252.371577)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.745243 -453.691842 C 177.207323 -453.422522 141.679976 -418.116232 141.923309 -382.76644 C 141.811003 -347.39429 177.113738 -311.956282 212.528778 -311.890422 L 407.372942 -311.695786 C 442.910862 -311.965106 478.438209 -347.271396 478.194876 -382.621189 C 478.2103 -419.624521 443.004448 -453.431347 407.589407 -453.497206 Z M 212.745243 -453.691842 "
      transform="matrix(-0.00074991,-0.0112429,-0.0119291,-0.00412171,299.802075,265.1)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.641027 -453.419401 C 177.089614 -453.451794 141.687089 -418.016169 141.835977 -382.54815 C 141.662168 -347.303164 176.982022 -311.704831 212.533435 -311.672438 L 407.420815 -311.940342 C 442.972229 -311.907949 478.374754 -347.343575 478.168114 -382.49064 C 478.307984 -419.563426 443.079821 -453.654912 407.528408 -453.687305 Z M 212.641027 -453.419401 "
      transform="matrix(-0.00328465,-0.0107661,-0.0127618,-0.00193723,302.017471,266.520312)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.477825 -453.49801 C 177.327832 -453.620976 141.76751 -418.116767 141.741739 -382.673778 C 141.715968 -347.230788 177.224748 -311.849019 212.759299 -311.910238 L 407.622492 -311.970668 C 442.772486 -311.847702 478.332807 -347.351912 478.358578 -382.794901 C 478.336148 -419.728935 442.875569 -453.61966 407.341019 -453.55844 Z M 212.477825 -453.49801 "
      transform="matrix(-0.0061782,-0.0100029,-0.0128993,0.000323363,304.845728,267.607979)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.72885 -453.697523 C 177.053616 -453.582691 141.875668 -418.133754 141.63131 -382.572028 C 141.880275 -347.318732 177.311152 -311.871838 212.493064 -311.678241 L 407.470227 -312.008788 C 442.90046 -311.734384 478.326729 -347.102513 478.322766 -382.745046 C 478.308843 -419.797711 442.891244 -453.364429 407.461012 -453.638834 Z M 212.72885 -453.697523 "
      transform="matrix(-0.0130563,-0.00271056,-0.00821819,0.00832954,387.533261,263.777578)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.774445 -453.58478 C 177.126479 -453.619555 141.637426 -418.140051 141.796339 -382.625773 C 141.718704 -347.216255 177.289035 -311.771963 212.700453 -311.841949 L 407.435893 -311.746714 C 442.847312 -311.8167 478.336365 -347.296204 478.413999 -382.705721 C 478.337171 -419.660476 442.921303 -453.559531 407.509885 -453.489545 Z M 212.774445 -453.58478 "
      transform="matrix(-0.0125929,-0.00351425,-0.00885292,0.00793507,387.640458,264.104785)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.526369 -453.515206 C 177.214547 -453.724383 141.738115 -418.181454 141.800055 -382.823723 C 141.635444 -347.071617 177.235758 -311.899085 212.54758 -311.689908 L 407.442253 -311.722561 C 442.980626 -311.907759 478.230508 -347.056313 478.395118 -382.808419 C 478.231984 -419.531975 442.959416 -453.733058 407.421042 -453.547859 Z M 212.526369 -453.515206 "
      transform="matrix(-0.0132296,-0.00204291,-0.0075998,0.00873134,391.282975,262.034592)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.733516 -453.627313 C 177.131506 -453.655709 141.611958 -417.979526 141.732439 -382.759001 C 141.583081 -347.165815 177.305572 -311.916893 212.675762 -311.99989 L 407.521518 -311.805228 C 442.891708 -311.888225 478.373237 -347.080354 478.290775 -382.784934 C 478.554192 -419.830283 442.949462 -453.515649 407.347452 -453.544045 Z M 212.733516 -453.627313 "
      transform="matrix(-0.0125009,-0.00373668,-0.00905172,0.00777637,388.971211,263.197489)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.659466 -453.536866 C 177.299478 -453.566542 141.802914 -418.255809 141.873396 -382.776908 C 141.73682 -347.4365 177.167291 -311.927923 212.527279 -311.898247 L 407.612479 -311.892602 C 442.972467 -311.862926 478.261973 -347.312152 478.398549 -382.652561 C 478.383869 -419.781364 442.897596 -453.640039 407.537608 -453.669714 Z M 212.659466 -453.536866 "
      transform="matrix(0.012254,-0.00464561,0.00988471,0.00694557,383.642784,255.766911)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.663987 -453.465235 C 177.124526 -453.643585 141.65921 -418.056271 141.826742 -382.791474 C 141.69546 -347.171015 177.197024 -311.873074 212.531057 -311.839929 L 407.37248 -311.868091 C 442.911942 -311.689741 478.377257 -347.277055 478.209726 -382.541852 C 478.415735 -419.810121 442.839443 -453.460252 407.50541 -453.493396 Z M 212.663987 -453.465235 "
      transform="matrix(0.0119302,-0.00487075,0.0100233,0.00689082,382.160439,255.059021)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.789207 -453.661588 C 177.238887 -453.619557 141.685197 -417.95434 141.73091 -382.822567 C 141.727541 -347.199381 177.048942 -311.732608 212.599263 -311.774639 L 407.375755 -311.611837 C 442.926076 -311.653868 478.479765 -347.319084 478.208503 -382.565241 C 478.359116 -419.662664 442.89047 -453.655199 407.565699 -453.498786 Z M 212.789207 -453.661588 "
      transform="matrix(0.012647,-0.00383684,0.00921219,0.00756581,379.816191,254.147193)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.663837 -453.598844 C 177.329804 -453.631988 141.659061 -418.18988 141.527779 -382.569421 C 141.69531 -347.304624 177.196874 -312.006683 212.736336 -311.828332 L 407.577759 -311.856494 C 442.911792 -311.82335 478.377107 -347.410664 478.209576 -382.675461 C 478.116772 -419.588068 442.839294 -453.593861 407.505261 -453.627006 Z M 212.663837 -453.598844 "
      transform="matrix(0.0119302,-0.00487075,0.0100233,0.00689082,388.853186,258.200566)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.801832 -453.638368 C 176.940106 -453.441226 141.650601 -417.992001 141.721083 -382.513099 C 141.584507 -347.172691 177.014978 -311.664114 212.669645 -311.999749 L 407.460166 -311.628794 C 442.820154 -311.599118 478.404339 -347.413654 478.540914 -382.754062 C 478.231556 -419.517555 442.745283 -453.37623 407.385295 -453.405906 Z M 212.801832 -453.638368 "
      transform="matrix(0.012254,-0.00464561,0.00988471,0.00694557,386.989699,257.338589)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:12.791;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.58967 -453.584517 C 177.255637 -453.617661 141.790322 -418.030347 141.957853 -382.76555 C 141.621143 -347.290297 177.328135 -311.84715 212.662168 -311.814006 L 407.503592 -311.842167 C 442.837625 -311.809023 478.508368 -347.251131 478.340837 -382.515928 C 478.546846 -419.784197 442.970555 -453.434328 407.431093 -453.612679 Z M 212.58967 -453.584517 "
      transform="matrix(0.0119302,-0.00487075,0.0100233,0.00689082,385.506271,256.629793)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 124.042103 -1977.180915 L 124.042103 -1924.061839 L 70.878189 -1924.061839 L 70.878189 -1888.59464 L 124.042103 -1888.59464 L 124.042103 -1782.302006 L 159.484712 -1782.302006 L 159.484712 -1888.59464 L 212.58395 -1888.59464 L 212.58395 -1924.061839 L 159.484712 -1924.061839 L 159.484712 -1977.180915 Z M 124.042103 -1977.180915 "
      transform="matrix(0.0603969,0,0,0.0716992,334.176206,214.797389)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.138093 -1813.477942 C 301.138093 -1845.118159 273.408202 -1870.853377 239.113682 -1870.853377 C 204.932346 -1870.853377 177.202455 -1845.118159 177.202455 -1813.477942 C 177.202455 -1781.837726 204.932346 -1756.190642 239.113682 -1756.190642 C 273.408202 -1756.190642 301.138093 -1781.837726 301.138093 -1813.477942 Z M 301.138093 -1813.477942 "
      transform="matrix(0.0345126,0,0,0.0443216,334.481935,167.387889)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 602.344228 -1119.409393 C 602.344228 -1050.9521 591.948076 -973.708844 573.112696 -914.36292 C 453.006567 -940.842835 305.075444 -954.062455 234.075843 -954.062455 C 146.503554 -954.062455 -12.068336 -940.842835 -111.1987 -927.623215 C -130.034081 -986.928464 -141.714463 -1050.9521 -141.714463 -1119.409393 C -141.714463 -1393.197889 -36.468716 -1509.611896 225.758922 -1615.40953 C 459.060796 -1509.611896 602.344228 -1393.197889 602.344228 -1119.409393 Z M 602.344228 -1119.409393 "
      transform="matrix(0.0638758,0,0,0.0960339,328.477906,242.145747)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 566.941896 -657.305443 C 566.941896 -701.27781 416.113356 -736.982494 230.338085 -736.982494 C 44.507606 -736.982494 -106.265727 -701.27781 -106.265727 -657.305443 L -106.265727 -630.673261 C -106.265727 -674.718793 44.507606 -701.570472 230.338085 -701.570472 C 416.113356 -701.570472 566.941896 -657.012782 566.941896 -612.96725 Z M 566.941896 -657.305443 "
      transform="matrix(0.070755,0,0,0.0533894,326.843051,170.546238)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 230.335245 -825.558637 L 177.160023 -772.440603 L 212.628558 -737.028581 L 194.86671 -719.322569 L 159.453336 -754.734592 L 106.278114 -701.543393 L 159.453336 -648.425359 L 194.86671 -683.837381 L 212.628558 -666.13137 L 212.628558 -613.013336 L 248.041932 -613.013336 L 248.041932 -666.13137 L 265.748619 -683.837381 L 301.161993 -648.425359 L 354.337214 -701.543393 L 301.161993 -754.734592 L 265.748619 -719.322569 L 248.041932 -737.028581 L 283.455306 -772.440603 Z M 230.335245 -825.558637 "
      transform="matrix(0.0708154,0,0,0.0533894,326.829341,161.087761)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 220.95636 -825.601206 L 212.620677 -772.437935 L 212.574007 -737.006588 L 194.887118 -719.286702 L 194.880383 -754.731859 L 177.146824 -701.580627 L 194.900587 -648.396388 L 194.893852 -683.841545 L 212.587476 -666.116274 L 212.580035 -612.990862 L 230.306153 -613.016681 L 230.313594 -666.142094 L 248.053888 -683.848169 L 248.007219 -648.416823 L 265.740778 -701.568055 L 248.040419 -754.738483 L 248.047154 -719.293326 L 230.300125 -737.032408 L 230.346795 -772.463754 Z M 220.95636 -825.601206 "
      transform="matrix(0.0685599,-0.0133671,0.0177301,0.0516889,317.348575,165.616246)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 220.964143 -825.619288 L 212.582458 -772.405877 L 212.60211 -737.016626 L 194.892221 -719.26599 L 194.909587 -754.73906 L 177.165643 -701.586591 L 194.894507 -648.403669 L 194.858166 -683.864157 L 212.604396 -666.154304 L 212.588511 -613.022227 L 230.33326 -612.971382 L 230.332455 -666.174696 L 248.059033 -683.854095 L 248.024978 -648.452261 L 265.768921 -701.60473 L 248.056747 -754.716416 L 248.022692 -719.314582 L 230.330169 -737.037017 L 230.310517 -772.426268 Z M 220.964143 -825.619288 "
      transform="matrix(0.0689487,0.0121786,-0.0161537,0.051982,339.830342,160.257556)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 141.756874 -1020.522055 C 141.706589 -1038.242164 159.46604 -1055.956014 177.139641 -1055.967366 C 194.88131 -1055.969913 212.590597 -1038.18893 212.572814 -1020.477626 C 212.623099 -1002.757518 194.863648 -985.043667 194.863648 -985.043667 L 265.759193 -914.208642 L 230.343923 -914.194743 L 177.172265 -967.321012 L 124.044646 -914.234078 L 88.561309 -914.228984 L 159.462978 -985.101995 C 159.462978 -985.101995 141.739092 -1002.810752 141.756874 -1020.522055 Z M 141.756874 -1020.522055 "
      transform="matrix(0.0559258,-0.00681782,0.0113043,0.0527049,330.565421,178.647519)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 141.745765 -1020.475835 C 141.732164 -1038.242254 159.459772 -1055.907334 177.173043 -1055.950609 C 194.900283 -1055.921577 212.627154 -1038.198432 212.626786 -1020.50432 C 212.558275 -1002.801782 194.912779 -985.072821 194.912779 -985.072821 L 265.752122 -914.171819 L 230.297642 -914.229882 L 177.19914 -967.335434 L 124.044254 -914.19558 L 88.603743 -914.181338 L 159.472268 -985.058578 C 159.472268 -985.058578 141.745397 -1002.781723 141.745765 -1020.475835 Z M 141.745765 -1020.475835 "
      transform="matrix(0.0559874,0.00652418,-0.0108163,0.0527631,336.366052,176.340565)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 566.927772 -657.28312 C 566.927772 -701.255487 416.117548 -737.033336 230.335238 -737.033336 C 44.497766 -737.033336 -106.312458 -701.255487 -106.312458 -657.28312 L -106.312458 -630.724103 C -106.312458 -674.69647 44.497766 -701.548148 230.335238 -701.548148 C 416.117548 -701.548148 566.927772 -656.990459 566.927772 -613.018092 Z M 566.927772 -657.28312 "
      transform="matrix(0.0708154,0,0,0.0533894,326.829341,167.709108)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 566.910541 -657.275271 C 566.910541 -701.320803 416.109643 -737.025487 230.304621 -737.025487 C 44.499599 -737.025487 -106.3013 -701.320803 -106.3013 -657.275271 L -106.3013 -630.716254 C -106.3013 -674.688621 44.499599 -701.540299 230.304621 -701.540299 C 416.109643 -701.540299 566.910541 -656.98261 566.910541 -613.010243 Z M 566.910541 -657.275271 "
      transform="matrix(0.0648361,0,0,0.0533894,328.267163,188.329783)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 230.300726 -825.60163 L 177.175026 -772.410431 L 212.611684 -736.998408 L 194.864068 -719.292397 L 159.42741 -754.70442 L 106.301709 -701.586386 L 159.42741 -648.395187 L 194.864068 -683.880374 L 212.611684 -666.174363 L 212.611684 -612.983164 L 248.048342 -612.983164 L 248.048342 -666.174363 L 265.737385 -683.880374 L 301.174043 -648.395187 L 354.358316 -701.586386 L 301.174043 -754.70442 L 265.737385 -719.292397 L 248.048342 -736.998408 L 283.485 -772.410431 Z M 230.300726 -825.60163 "
      transform="matrix(0.0666903,0,0,0.0533894,328.047426,178.871306)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 220.971847 -825.566036 L 212.609733 -772.4096 L 212.599543 -737.044392 L 194.871773 -719.259506 L 194.900282 -754.695549 L 177.162321 -701.545454 L 194.889781 -648.44359 L 194.91829 -683.879633 L 212.599233 -666.157642 L 212.575664 -612.960827 L 230.308374 -612.984942 L 230.313625 -666.110921 L 248.023076 -683.824973 L 248.012886 -648.459765 L 265.750846 -701.609859 L 248.023386 -754.711723 L 248.051585 -719.261015 L 230.313935 -736.997672 L 230.342443 -772.433715 Z M 220.971847 -825.566036 "
      transform="matrix(0.0645662,-0.0133671,0.0166972,0.0516889,319.11893,183.399791)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 220.978084 -825.591565 L 212.61184 -772.456918 L 212.619397 -736.989687 L 194.865153 -719.303806 L 194.874286 -754.699801 L 177.184628 -701.56005 L 194.863578 -648.44058 L 194.87271 -683.836575 L 212.617821 -666.126461 L 212.612467 -612.99685 L 230.29677 -613.006991 L 230.302125 -666.136601 L 248.039679 -683.893718 L 248.047236 -648.426487 L 265.736894 -701.566238 L 248.041255 -754.756944 L 248.048812 -719.289713 L 230.303701 -736.999827 L 230.296144 -772.467058 Z M 220.978084 -825.591565 "
      transform="matrix(0.0649323,0.0121786,-0.0152127,0.051982,340.291098,178.041102)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 141.756224 -1020.491575 C 141.703837 -1038.211956 159.450644 -1055.927441 177.177562 -1055.931897 C 194.904479 -1055.936352 212.611505 -1038.229776 212.591615 -1020.518746 C 212.571724 -1002.807715 194.897194 -985.082879 194.897194 -985.082879 L 265.710701 -914.184351 L 230.329144 -914.16609 L 177.150386 -967.367394 L 123.982241 -914.211586 L 88.600684 -914.193326 L 159.44336 -985.073969 C 159.44336 -985.073969 141.736333 -1002.780544 141.756224 -1020.491575 Z M 141.756224 -1020.491575 "
      transform="matrix(0.052668,-0.00681782,0.0106458,0.0527049,331.565873,196.431065)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 141.761465 -1020.52128 C 141.751293 -1038.214088 159.439624 -1055.948346 177.152096 -1055.917488 C 194.850599 -1055.958936 212.573243 -1038.23527 212.597384 -1020.470154 C 212.607556 -1002.777345 194.919225 -985.043088 194.919225 -985.043088 L 265.723476 -914.21178 L 230.312501 -914.201189 L 177.144568 -967.37219 L 123.993249 -914.232777 L 88.582274 -914.222186 L 159.421924 -985.095857 C 159.421924 -985.095857 141.713249 -1002.747217 141.761465 -1020.52128 Z M 141.761465 -1020.52128 "
      transform="matrix(0.052726,0.00652418,-0.0101863,0.0527631,337.028609,194.124111)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 566.919686 -657.252948 C 566.919686 -701.29848 416.152812 -737.003164 230.300719 -737.003164 C 44.507199 -737.003164 -106.318247 -701.29848 -106.318247 -657.252948 L -106.318247 -630.693931 C -106.318247 -674.739463 44.507199 -701.591141 230.300719 -701.591141 C 416.152812 -701.591141 566.919686 -656.960286 566.919686 -612.987919 Z M 566.919686 -657.252948 "
      transform="matrix(0.0666903,0,0,0.0533894,328.047427,185.492654)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 566.901028 -657.28077 C 566.901028 -701.253137 416.126246 -737.030986 230.291646 -737.030986 C 44.515575 -737.030986 -106.317737 -701.253137 -106.317737 -657.28077 L -106.317737 -630.721753 C -106.317737 -674.69412 44.515575 -701.545798 230.291646 -701.545798 C 416.126246 -701.545798 566.901028 -656.988108 566.901028 -613.015741 Z M 566.901028 -657.28077 "
      transform="matrix(0.0667386,0,0,0.0533894,327.853311,154.033202)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 230.334323 -825.606767 L 177.151112 -772.473767 L 212.606586 -737.025912 L 194.878849 -719.263201 L 159.483266 -754.711056 L 106.300055 -701.578056 L 159.483266 -648.445057 L 194.878849 -683.892912 L 212.606586 -666.130201 L 212.606586 -612.997201 L 248.06206 -612.997201 L 248.06206 -666.130201 L 265.729906 -683.892912 L 301.185379 -648.445057 L 354.308699 -701.578056 L 301.185379 -754.711056 L 265.729906 -719.263201 L 248.06206 -737.025912 L 283.457642 -772.473767 Z M 230.334323 -825.606767 "
      transform="matrix(0.0652227,0,0,0.0503601,328.453547,142.120578)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 221.013028 -825.61739 L 212.580173 -772.436515 L 212.577952 -737.024851 L 194.886603 -719.327095 L 194.888823 -754.738759 L 177.175833 -701.554243 L 194.862741 -648.42867 L 194.864962 -683.840334 L 212.612074 -666.111431 L 212.608743 -612.993934 L 230.298982 -612.985858 L 230.321733 -666.178451 L 248.013082 -683.876207 L 248.068845 -648.449547 L 265.762415 -701.558968 L 248.017524 -754.699536 L 248.015303 -719.287871 L 230.326174 -737.00178 L 230.328395 -772.413444 Z M 221.013028 -825.61739 "
      transform="matrix(0.0631453,-0.0126086,0.0163298,0.0487561,319.721539,146.392119)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 220.952394 -825.597478 L 212.619954 -772.419791 L 212.603789 -737.044109 L 194.870398 -719.266468 L 194.868869 -754.717671 L 177.177624 -701.57801 L 194.855762 -648.439583 L 194.871927 -683.815265 L 212.606847 -666.141703 L 212.571138 -612.995828 L 230.284985 -613.003276 L 230.320694 -666.149151 L 248.013467 -683.837609 L 248.014996 -648.386406 L 265.764553 -701.539729 L 248.01041 -754.740015 L 248.011939 -719.288812 L 230.33533 -736.976036 L 230.333801 -772.427239 Z M 220.952394 -825.597478 "
      transform="matrix(0.0635034,0.0114876,-0.0148779,0.0490326,340.427774,141.337479)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 141.735479 -1020.496955 C 141.759885 -1038.251463 159.437285 -1055.922473 177.17966 -1055.905987 C 194.863608 -1055.975632 212.581576 -1038.204638 212.572647 -1020.526702 C 212.622144 -1002.762634 194.87084 -985.101184 194.87084 -985.101184 L 265.773667 -914.170352 L 230.288918 -914.203324 L 177.177963 -967.353603 L 124.040904 -914.196991 L 88.556155 -914.229963 L 159.444518 -985.048025 C 159.444518 -985.048025 141.72655 -1002.819019 141.735479 -1020.496955 Z M 141.735479 -1020.496955 "
      transform="matrix(0.0515089,-0.00643098,0.0104115,0.0497144,331.894564,158.684003)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 141.710704 -1020.511593 C 141.693139 -1038.2475 159.417654 -1055.901098 177.130117 -1055.9721 C 194.857388 -1055.966447 212.602225 -1038.224886 212.61979 -1020.488979 C 212.637356 -1002.753071 194.838855 -985.090325 194.838855 -985.090325 L 265.729408 -914.191589 L 230.34885 -914.212045 L 177.129148 -967.360071 L 124.00003 -914.16931 L 88.619472 -914.189766 L 159.473106 -985.034125 C 159.473106 -985.034125 141.728269 -1002.775686 141.710704 -1020.511593 Z M 141.710704 -1020.511593 "
      transform="matrix(0.0515657,0.006154,-0.00996211,0.0497693,337.237082,156.507945)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 566.921753 -657.278918 C 566.921753 -701.25908 416.116207 -737.017201 230.334316 -737.017201 C 44.492533 -737.017201 -106.313012 -701.25908 -106.313012 -657.278918 L -106.313012 -630.673635 C -106.313012 -674.731364 44.492533 -701.569346 230.334316 -701.569346 C 416.116207 -701.569346 566.921753 -656.968653 566.921753 -612.988491 Z M 566.921753 -657.278918 "
      transform="matrix(0.0652227,0,0,0.0503601,328.453548,148.366233)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(72.54902%,0%,0%);fill-opacity:1;"
      d="M 365.207031 154.566406 C 365.207031 152.589844 355.386719 150.988281 343.269531 150.988281 C 331.15625 150.988281 321.335938 152.589844 321.335938 154.566406 C 321.335938 156.542969 331.15625 158.144531 343.269531 158.144531 C 355.386719 158.144531 365.207031 156.542969 365.207031 154.566406 Z M 365.207031 154.566406 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 513.789582 -790.159699 L 549.205635 -807.869635 L 584.643388 -790.159699 C 584.643388 -790.159699 511.272264 -754.739828 511.272264 -630.717959 C 511.272264 -542.142122 636.53051 -524.406027 636.53051 -418.120253 C 636.53051 -372.472044 602.351415 -329.518256 566.913661 -329.518256 C 531.497608 -329.518256 478.330127 -347.254352 425.184347 -400.384158 C 460.622101 -382.674223 501.268097 -363.080808 531.497608 -364.336461 C 551.071922 -364.728852 586.509675 -382.046396 586.509675 -417.492427 C 586.509675 -488.358329 463.747046 -524.406027 463.747046 -630.717959 C 463.747046 -754.713668 513.767881 -790.159699 513.767881 -790.159699 Z M 513.789582 -790.159699 "
      transform="matrix(0.180003,0,0,0.149325,286.418616,252.127098)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.627292 -453.515195 C 177.17245 -453.526599 141.876274 -418.156781 141.687467 -382.565775 C 141.60201 -347.200654 177.215519 -311.808029 212.670361 -311.796625 L 407.549931 -311.741955 C 442.76065 -311.746653 478.404299 -347.326255 478.245633 -382.707477 C 478.322177 -419.702102 442.961704 -453.449121 407.506862 -453.460525 Z M 212.627292 -453.515195 "
      transform="matrix(0.0155324,-0.00110715,0.00710664,0.0167865,340.783632,288.000672)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 520.256484 -790.159699 L 566.935362 -807.869635 L 576.939529 -790.159699 C 576.939529 -790.159699 506.281031 -754.739828 506.281031 -630.717959 C 506.281031 -542.142122 632.168606 -524.406027 632.168606 -418.120253 C 632.168606 -372.472044 605.45466 -330.74775 566.935362 -329.518256 C 493.520836 -327.163908 476.485541 -353.506456 425.206048 -400.384158 C 468.347661 -368.07726 530.195547 -353.637253 554.218569 -366.21994 C 583.580039 -381.575527 589.244003 -402.267638 589.244003 -419.977573 C 589.244003 -490.843475 468.347661 -524.406027 468.347661 -630.717959 C 468.347661 -754.713668 520.256484 -790.159699 520.256484 -790.159699 Z M 520.256484 -790.159699 "
      transform="matrix(0.180003,0,0,0.149325,286.418616,252.127098)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 513.333861 -789.688829 C 520.343288 -792.121656 549.205635 -807.869635 584.643388 -772.449763 C 602.373116 -754.713668 602.373116 -719.293797 602.373116 -701.583861 L 655.518896 -754.713668 C 655.518896 -754.713668 626.439539 -841.589143 547.903574 -808.314345 C 536.770954 -803.422531 519.75736 -793.769701 513.355562 -789.688829 Z M 513.333861 -789.688829 "
      transform="matrix(0.180003,0,0,0.149325,286.418616,252.127098)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 523.793749 -795.940933 C 532.647762 -798.818471 560.46846 -802.271516 588.397664 -777.446215 C 606.10569 -759.71012 607.364349 -734.518586 607.364349 -716.782491 L 648.010346 -754.713668 C 651.460807 -757.931278 623.184387 -835.964865 548.771614 -808.759055 C 544.279505 -806.954054 533.993225 -802.454632 523.793749 -795.940933 Z M 523.793749 -795.940933 "
      transform="matrix(0.180003,0,0,0.149325,286.418616,252.127098)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 344.523748 -596.279021 L 353.690237 -574.159535 C 353.690237 -574.159535 355.152924 -583.911694 362.211243 -585.686959 L 369.295181 -587.45917 C 369.295181 -587.45917 396.149757 -538.112987 401.459251 -529.29788 C 406.758877 -520.453869 397.90636 -512.485407 399.69193 -512.483032 C 401.451881 -512.483711 426.19674 -523.971424 426.19674 -523.971424 C 426.19674 -523.971424 415.60709 -523.09858 411.163991 -530.155932 C 406.756379 -537.239133 376.357824 -590.106256 376.357824 -590.106256 C 376.357824 -590.106256 382.545424 -592.767455 387.848116 -595.413862 C 393.150807 -598.06027 398.457539 -587.440657 398.457539 -587.440657 L 385.193653 -613.959258 C 385.193653 -613.959258 385.187806 -605.116263 380.774348 -603.356469 C 376.360889 -601.596675 372.822472 -599.822769 372.822472 -599.822769 C 372.822472 -599.822769 369.285579 -606.020036 364.8681 -613.074333 C 360.460487 -620.157534 369.298814 -627.225271 369.298814 -627.225271 L 346.313907 -615.738237 C 346.313907 -615.738237 354.274126 -611.329668 356.919006 -606.89321 C 359.573752 -602.485657 362.218632 -598.049199 362.218632 -598.049199 C 362.218632 -598.049199 359.007382 -596.296438 353.99481 -594.578011 C 350.416926 -592.688489 346.300671 -594.533003 344.523748 -596.279021 Z M 344.523748 -596.279021 "
      transform="matrix(0.146508,-0.0154865,0.0500128,0.12986,357.32664,254.20156)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,20%);fill-opacity:1;stroke-width:1.122062;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 344.554192 -596.302334 L 353.690898 -574.1395 C 353.690898 -574.1395 355.150769 -583.928017 362.213443 -585.667394 L 369.2768 -587.445921 C 369.2768 -587.445921 396.165888 -538.136252 401.461591 -529.278952 C 406.757976 -520.460802 397.916443 -512.486291 399.674436 -512.480702 C 401.462319 -512.50895 426.217083 -523.990464 426.217083 -523.990464 C 426.217083 -523.990464 415.594336 -523.095031 411.176133 -530.182492 C 406.757247 -537.230804 376.355168 -590.085738 376.355168 -590.085738 C 376.355168 -590.085738 382.555222 -592.767499 387.846389 -595.418218 C 393.166765 -598.063624 398.460971 -587.43928 398.460971 -587.43928 L 385.210169 -613.963647 C 385.210169 -613.963647 385.202686 -605.128426 380.777 -603.380666 C 376.350632 -601.593757 372.833151 -599.83789 372.833151 -599.83789 C 372.833151 -599.83789 369.27825 -606.022117 364.859365 -613.070428 C 360.441162 -620.15789 369.296209 -627.226647 369.296209 -627.226647 L 346.299437 -615.739545 C 346.299437 -615.739545 354.250815 -611.308392 356.928217 -606.894004 C 359.576409 -602.484928 362.224601 -598.075853 362.224601 -598.075853 C 362.224601 -598.075853 358.999892 -596.306005 354.017872 -594.580894 C 350.410035 -592.684368 346.310687 -594.529702 344.554192 -596.302334 Z M 344.554192 -596.302334 "
      transform="matrix(-0.133312,0.0180921,-0.00232352,-0.0994622,446.53608,72.78093)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.492767 -453.537814 C 177.282047 -453.533116 141.741749 -418.1794 141.797064 -382.572292 C 141.711608 -347.207172 177.080993 -311.830648 212.535835 -311.819244 L 407.415405 -311.764574 C 442.870248 -311.753171 478.269774 -347.348874 478.355231 -382.713995 C 478.431774 -419.708619 442.827179 -453.47174 407.372337 -453.483144 Z M 212.492767 -453.537814 "
      transform="matrix(0.0155324,-0.00110715,0.00710664,0.0167865,343.406976,287.840747)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.580856 -453.62507 C 177.1827 -453.632735 141.776014 -418.037504 141.725841 -382.670056 C 141.820666 -347.293045 177.168648 -311.917933 212.711803 -311.900704 L 407.474161 -311.853765 C 442.872317 -311.8461 478.320646 -347.205883 478.37082 -382.57333 C 478.377845 -419.805242 442.886369 -453.560902 407.488213 -453.568567 Z M 212.580856 -453.62507 "
      transform="matrix(0.0261508,-0.00110715,0.0119654,0.0167865,339.50148,290.045434)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.617344 -453.63641 C 177.219188 -453.644075 141.667503 -418.058407 141.762328 -382.681396 C 141.712155 -347.313948 177.101781 -311.703387 212.603292 -311.921607 L 407.510649 -311.865105 C 442.908805 -311.85744 478.357134 -347.217222 478.262309 -382.594233 C 478.310977 -419.590696 442.922856 -453.572242 407.5247 -453.579907 Z M 212.617344 -453.63641 "
      transform="matrix(0.0261508,-0.00110715,0.0119654,0.0167865,341.977224,289.885508)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.526078 -453.492904 C 177.109466 -453.501786 141.77148 -418.134726 141.733157 -382.766497 C 141.708431 -347.164669 177.20367 -311.779844 212.620282 -311.770962 L 407.411649 -311.722109 C 442.828261 -311.713227 478.386548 -347.298459 478.307922 -382.674402 C 478.368014 -419.670112 442.954359 -453.653341 407.434394 -453.436338 Z M 212.526078 -453.492904 "
      transform="matrix(0.0324228,-0.00110715,0.0148348,0.0167865,338.51256,292.398623)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(73.333333%,0%,0%);fill-opacity:1;stroke-width:17.958;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.568604 -453.503845 C 177.151992 -453.512727 141.697057 -418.153381 141.775683 -382.777438 C 141.750957 -347.17561 177.129246 -311.798499 212.545859 -311.789616 L 407.454175 -311.73305 C 442.870787 -311.724168 478.429074 -347.309401 478.350448 -382.685343 C 478.293591 -419.688766 442.893533 -453.438397 407.47692 -453.447279 Z M 212.568604 -453.503845 "
      transform="matrix(0.0324228,-0.00110715,0.0148348,0.0167865,341.581656,292.238698)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,87.843137%,0%);fill-opacity:1;"
      d="M 0 0 L 240 0 L 240 360 L 0 360 Z M 0 0 "
    />
  </g>
</svg>
