<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 0 0 L 540.542969 0 L 540.542969 90 L 0 90 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 90 L 540.542969 90 L 540.542969 180 L 0 180 Z M 0 90 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(74.509804%,0%,15.294118%);fill-opacity:1;"
      d="M 0 180 L 540.542969 180 L 540.542969 270 L 0 270 Z M 0 180 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.137255%,35.294118%,63.921569%);fill-opacity:1;"
      d="M 0 270 L 540.542969 270 L 540.542969 360 L 0 360 Z M 0 270 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(13.72549%,61.960784%,27.45098%);fill-opacity:1;"
      d="M 0 0 L 0 360 L 268.496094 180.507812 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 110.539062 99.441406 C 50.703125 96.414062 23.441406 144.070312 23.503906 180.914062 C 23.367188 224.488281 64.660156 260.421875 102.824219 258.25 C 82.246094 248.519531 57.117188 221.222656 56.886719 180.730469 C 56.675781 144.042969 77.625 112.140625 110.539062 99.441406 Z M 110.539062 99.441406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 109.636719 138.554688 L 101.203125 131.957031 L 90.980469 135.175781 L 94.648438 125.109375 L 88.4375 116.382812 L 99.144531 116.753906 L 105.519531 108.148438 L 108.46875 118.449219 L 118.628906 121.855469 L 109.738281 127.851562 Z M 109.636719 138.554688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 109.457031 175.164062 L 101.023438 168.566406 L 90.800781 171.78125 L 94.46875 161.714844 L 88.257812 152.988281 L 98.964844 153.359375 L 105.339844 144.753906 L 108.289062 155.054688 L 118.449219 158.464844 L 109.5625 164.457031 Z M 109.457031 175.164062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 109.636719 212.132812 L 101.203125 205.53125 L 90.980469 208.75 L 94.648438 198.683594 L 88.4375 189.957031 L 99.144531 190.328125 L 105.519531 181.722656 L 108.46875 192.023438 L 118.628906 195.429688 L 109.738281 201.425781 Z M 109.636719 212.132812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 109.457031 249.453125 L 101.023438 242.855469 L 90.800781 246.070312 L 94.46875 236.003906 L 88.257812 227.277344 L 98.964844 227.652344 L 105.339844 219.046875 L 108.289062 229.347656 L 118.449219 232.753906 L 109.5625 238.746094 Z M 109.457031 249.453125 "
    />
  </g>
</svg>
