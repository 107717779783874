<script>
  import styles from "styleguide/FlexFormBox.json";
</script>

<div data-component="FlexFormBox" class={styles["flex-form-box"]}>
  <slot />
</div>

<style scoped lang="scss">
  .flex-form-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
</style>
