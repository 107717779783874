<script>
  import { afterUpdate, onDestroy } from "svelte";

  import Icons from "components/icons/Icons.svelte";
  import { Modal } from "components/modals";
  import { SandboxLoadingCard } from "components/modals/sandbox/SandboxLoadingModal";
  import { sandboxType, sandboxStatus } from "stores/dashboard/sandbox.js";
  import { t } from "stores/i18n.js";
  import { toasts } from "stores/toasts.js";
  import SandboxApi from "apis/dashboard/sandbox_api.js";
  import { platformRedirectByType } from "lib/helpers.js";

  import { Button } from "components";

  import styles from "styleguide/SandboxLoadingModal.json";

  export let showModal;
  export let onHide;

  let intervalId;
  let sandboxPermalink = "";

  $: isLoaded = $sandboxStatus === "processed";
  $: loadingIcon = isLoaded ? "checkmarkIcon" : "spinner";
  $: loadingText = isLoaded ? "loading_completed_text" : "loading_text";
  $: benefitsIcon = $sandboxType === "pbc" ? "workGroup" : "rush";

  function redirectToTestEnv() {
    if (sandboxPermalink) {
      platformRedirectByType(sandboxPermalink, $sandboxType);
    }
  }

  async function checkStatusApiCall() {
    let formData = new FormData();
    formData.append("type", $sandboxType);

    SandboxApi.creationStatus({
      params: formData,
      success: (response) => {
        sandboxStatus.set(response.data.status);
        sandboxPermalink = response.data.permalink;

        if ($sandboxStatus === "processed") {
          clearInterval(intervalId);
          intervalId = undefined;
        }
      },
      error: (error) => {
        toasts.send({ title: $t("errors.unknown"), type: "error" });
        console.log(error);
      },
    });
  }

  afterUpdate(() => {
    if ($sandboxType !== null && !intervalId && showModal) {
      intervalId = setInterval(checkStatusApiCall, 8000);
    }
  });

  onDestroy(() => {
    clearInterval(intervalId);
  });
</script>

<Modal
  bind:shown={showModal}
  {onHide}
  close={true}
  title={$t("sandbox_loading_modal.title").replace(
    "%{product}",
    $sandboxType?.toUpperCase(),
  )}
>
  <div data-component="SandboxLoadingModal" class={styles.wrapper}>
    <div class={styles["loading-progress"]}>
      <Icons icon={loadingIcon} width="40" height="40" />
      <div class={styles["text"]}>
        {@html $t(`sandbox_loading_modal.${loadingText}`).replace(
          "%{product}",
          $sandboxType?.toUpperCase(),
        )}
      </div>
      <Button click={redirectToTestEnv} disabled={!isLoaded} style="primary">
        {$t("sandbox_loading_modal.button")}
      </Button>
    </div>

    <div class={styles["overview-cards"]}>
      <SandboxLoadingCard icon="info" product="overview" />
      <SandboxLoadingCard icon={benefitsIcon} product={$sandboxType} />
    </div>
  </div>
</Modal>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .loading-progress {
    display: flex;
    flex-direction: column;
    align-items: center;

    .text {
      margin: 20px 0 16px;
      text-align: center;
    }
  }

  .overview-cards {
    display: flex;
    gap: 16px;
  }
</style>
