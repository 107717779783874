import Api from "apis/api";
import { buildFormData } from "lib/helpers.js";

class RollForwardsApi {
  constructor() {
    this.endpoint = "/pbc/roll_forward";
  }

  create(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params, null, true);

    Api.post(this.endpoint, {
      body: formData,
      success,
      error,
    });
  }

  check(options) {
    const { params, success, error } = options;

    Api.get(`${this.endpoint}/${params.id}/check`, {
      params,
      success,
      error,
    });
  }
}

export default new RollForwardsApi();
