<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 480.003906 359.996094 L 0 359.996094 L 0 0 L 480.003906 0 Z M 480.003906 359.996094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(21.960784%,55.294118%,0%);fill-opacity:1;"
      d="M 480.003906 359.996094 L 0 359.996094 L 0 239.996094 L 480.003906 239.996094 Z M 480.003906 359.996094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.137255%,20.784314%,8.627451%);fill-opacity:1;"
      d="M 480.003906 120.09375 L 0 120.09375 L 0 0.0976562 L 480.003906 0.0976562 Z M 480.003906 120.09375 "
    />
  </g>
</svg>
