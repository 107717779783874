<script>
  import { onMount, setContext } from "svelte";
  import { showWidget, currentUser } from "stores/quickshare.js";
  import { user } from "stores/user.js";
  import oksend from "apis/oksend-sdk.min.js";
  import { QuickshareWidget } from "components/quickshare";

  let widget;

  onMount(async () => {
    console.log("Mounting Quickshare");
  });

  currentUser.set({
    platform: $user.quickshare.platform, // permalink of the oksend.platform
    endpoint: $user.quickshare.endpoint,
    email: $user.email,
    token: $user.quickshare.token,
    authenticated: false,
  });

  const api = new oksend({ endpoint: $user.quickshare.endpoint });
  setContext("oksend", api);
</script>

<div>
  {#if $showWidget}
    <QuickshareWidget bind:this={widget} />
  {/if}
</div>

<style lang="scss">
</style>
