<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M -15 0 L 705 0 L 705 180 L -15 180 Z M -15 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(6.27451%,48.235294%,0%);fill-opacity:1;"
      d="M -15 180 L 705 180 L 705 360 L -15 360 Z M -15 180 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M -15 119.046875 L 705 119.046875 L 705 242.886719 L -15 242.886719 Z M -15 119.046875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,100%);fill-opacity:1;"
      d="M 329.515625 137.496094 C 297.734375 123.839844 270.378906 152.011719 270.410156 178.335938 C 270.441406 205.871094 297.144531 235.234375 330.996094 222.234375 C 307.03125 215.175781 297.136719 197.472656 297.140625 179.566406 C 296.96875 161.835938 308.3125 141.21875 329.515625 137.496094 Z M 329.515625 137.496094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,9.411765%,0%);fill-opacity:1;"
      d="M 366.898438 206.871094 L 348.734375 193.601562 L 330.457031 206.890625 L 337.460938 185.511719 L 319.179688 172.246094 L 341.671875 172.292969 L 348.652344 150.8125 L 355.554688 172.210938 L 378.136719 172.214844 L 359.90625 185.386719 Z M 366.898438 206.871094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 332.582031 221.941406 C 297.0625 234.269531 270.316406 207.542969 269.851562 180.136719 C 269.386719 152.730469 296.285156 124.472656 332.761719 137.339844 C 312.125 140.402344 296.964844 158.492188 296.785156 178.945312 C 296.648438 194.410156 305.792969 216.453125 332.582031 221.941406 Z M 332.582031 221.941406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M -14.984375 0 L 230.128906 180.4375 L -15 360 L -15 1.84375 L -14.988281 0 Z M -14.984375 0 "
    />
  </g>
</svg>
