<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0.320312 0.316406 L 302.324219 0.316406 L 302.324219 180.363281 L 0.320312 180.363281 Z M 0.320312 0.316406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 0.320312 0.316406 L 0.308594 13.828125 L 72.378906 58.28125 L 99.492188 59.226562 L 0.316406 0.316406 Z M 0.320312 0.316406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,40%);fill-opacity:1;"
      d="M 31.179688 0.316406 L 118.550781 55.101562 L 118.550781 0.316406 Z M 31.179688 0.316406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 130.207031 0.316406 L 130.207031 70.253906 L 0.316406 70.253906 L 0.316406 110.21875 L 130.207031 110.21875 L 130.207031 180.15625 L 170.171875 180.15625 L 170.171875 110.21875 L 300.058594 110.21875 L 300.058594 70.253906 L 170.171875 70.253906 L 170.171875 0.316406 Z M 130.207031 0.316406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,40%);fill-opacity:1;"
      d="M 181.828125 0.316406 L 181.828125 51.9375 L 267.304688 0.714844 Z M 181.828125 0.316406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 184.523438 57.566406 L 208.5625 57.332031 L 301.953125 0.714844 L 277.175781 1.199219 Z M 184.523438 57.566406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,40%);fill-opacity:1;"
      d="M 301.003906 15.816406 L 229.664062 58.28125 L 300.054688 58.597656 L 300.054688 121.875 L 240.109375 121.875 L 299.5 160.507812 L 300.371094 180.15625 L 268.648438 179.703125 L 181.828125 128.535156 L 181.828125 180.160156 L 118.550781 180.160156 L 118.550781 128.535156 L 37.105469 180.019531 L 0.324219 180.15625 L 0.324219 360.003906 L 599.792969 360.003906 L 599.792969 0.320312 L 301.640625 -0.00390625 M 0.324219 20.292969 L 0.0078125 57.644531 L 63.117188 58.59375 Z M 0.324219 20.292969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,40%);fill-opacity:1;"
      d="M 0.320312 121.871094 L 0.320312 160.171875 L 60.265625 122.273438 Z M 0.320312 121.871094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 231.164062 123.453125 L 206.171875 123.222656 L 300.367188 179.523438 L 299.75 166.640625 L 231.160156 123.453125 Z M 23.726562 180.472656 L 112.054688 124.804688 L 88.855469 124.988281 L 0.316406 180.386719 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(28.627451%,28.627451%,49.019608%);fill-opacity:1;"
      d="M 394.03125 185.851562 L 395.554688 187.375 Z M 394.03125 185.851562 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.490196%,5.490196%,43.137255%);fill-opacity:1;"
      d="M 395.554688 185.851562 L 397.078125 187.375 Z M 395.554688 185.851562 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(14.901961%,14.901961%,47.058824%);fill-opacity:1;"
      d="M 390.988281 187.375 L 392.507812 188.894531 Z M 390.988281 187.375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(50.196078%,50.196078%,40.392157%);fill-opacity:1;"
      d="M 392.507812 187.375 L 394.03125 188.894531 Z M 392.507812 187.375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(34.509804%,34.509804%,48.235294%);fill-opacity:1;"
      d="M 397.078125 187.375 L 398.601562 188.894531 Z M 397.078125 187.375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.490196%,5.490196%,43.137255%);fill-opacity:1;"
      d="M 340.738281 188.894531 L 342.261719 190.417969 Z M 340.738281 188.894531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(10.588235%,10.588235%,45.490196%);fill-opacity:1;"
      d="M 387.941406 188.894531 L 389.464844 190.417969 Z M 387.941406 188.894531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(43.137255%,42.352941%,43.921569%);fill-opacity:1;"
      d="M 389.464844 188.894531 L 390.988281 190.417969 Z M 389.464844 188.894531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,80%,20%);fill-opacity:1;"
      d="M 343.417969 191.652344 C 343.417969 230.996094 338.882812 275.328125 368.207031 306.140625 C 374.242188 312.480469 385.714844 326.933594 395.554688 326.378906 C 405.855469 325.800781 419.097656 310.558594 424.988281 303.09375 C 450.515625 269.515625 446.457031 229.46875 447.324219 190.496094 C 435.796875 195.671875 430.058594 197.375 419.976562 197.347656 C 412.511719 198.113281 400.996094 193.097656 394.109375 189.8125 C 389.605469 192.820312 383.078125 196.402344 371.34375 196.804688 C 357.792969 197.402344 353.5 195.105469 343.417969 191.652344 Z M 343.417969 191.652344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(60%,60%,30.588235%);fill-opacity:1;"
      d="M 398.601562 188.894531 L 400.121094 190.417969 Z M 398.601562 188.894531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(28.627451%,28.627451%,49.019608%);fill-opacity:1;"
      d="M 400.121094 188.894531 L 401.644531 190.417969 Z M 400.121094 188.894531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.490196%,5.490196%,43.137255%);fill-opacity:1;"
      d="M 447.324219 188.894531 L 448.847656 190.417969 Z M 447.324219 188.894531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 342.261719 190.417969 L 343.785156 191.941406 Z M 342.261719 190.417969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(43.137255%,42.352941%,43.921569%);fill-opacity:1;"
      d="M 343.785156 190.417969 L 345.308594 191.941406 Z M 343.785156 190.417969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(22.745098%,22.745098%,48.627451%);fill-opacity:1;"
      d="M 345.308594 190.417969 L 346.828125 191.941406 Z M 345.308594 190.417969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(10.588235%,10.588235%,45.490196%);fill-opacity:1;"
      d="M 384.894531 190.417969 L 386.417969 191.941406 Z M 384.894531 190.417969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(43.137255%,42.352941%,43.921569%);fill-opacity:1;"
      d="M 386.417969 190.417969 L 387.941406 191.941406 Z M 386.417969 190.417969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 387.941406 190.417969 L 389.464844 191.941406 Z M 387.941406 190.417969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(81.568627%,81.568627%,27.058824%);fill-opacity:1;"
      d="M 394.03125 190.417969 L 395.554688 191.941406 Z M 394.03125 190.417969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 400.121094 190.417969 L 401.644531 191.941406 Z M 400.121094 190.417969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(55.294118%,55.294118%,35.686275%);fill-opacity:1;"
      d="M 401.644531 190.417969 L 403.167969 191.941406 Z M 401.644531 190.417969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(22.745098%,22.745098%,48.627451%);fill-opacity:1;"
      d="M 403.167969 190.417969 L 404.691406 191.941406 Z M 403.167969 190.417969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(14.901961%,14.901961%,47.058824%);fill-opacity:1;"
      d="M 442.757812 190.417969 L 444.28125 191.941406 Z M 442.757812 190.417969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(32.54902%,32.156863%,48.627451%);fill-opacity:1;"
      d="M 444.28125 190.417969 L 445.800781 191.941406 Z M 444.28125 190.417969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(55.294118%,55.294118%,35.686275%);fill-opacity:1;"
      d="M 445.800781 190.417969 L 447.324219 191.941406 Z M 445.800781 190.417969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(45.098039%,45.098039%,43.921569%);fill-opacity:1;"
      d="M 348.351562 191.941406 L 349.875 193.464844 Z M 348.351562 191.941406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(32.54902%,32.156863%,48.627451%);fill-opacity:1;"
      d="M 349.875 191.941406 L 351.398438 193.464844 Z M 349.875 191.941406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(10.588235%,10.588235%,45.490196%);fill-opacity:1;"
      d="M 351.398438 191.941406 L 352.921875 193.464844 Z M 351.398438 191.941406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(14.901961%,14.901961%,47.058824%);fill-opacity:1;"
      d="M 381.851562 191.941406 L 383.375 193.464844 Z M 381.851562 191.941406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(43.137255%,42.352941%,43.921569%);fill-opacity:1;"
      d="M 383.375 191.941406 L 384.894531 193.464844 Z M 383.375 191.941406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 384.894531 191.941406 L 386.417969 193.464844 Z M 384.894531 191.941406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(89.803922%,89.803922%,61.568627%);fill-opacity:1;"
      d="M 392.507812 191.941406 L 394.03125 193.464844 Z M 392.507812 191.941406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 346.539062 195.929688 C 345.722656 216.597656 346.351562 239.871094 349.582031 261.984375 C 353.152344 273.535156 352.675781 279.882812 357.984375 286.804688 L 432.285156 286.226562 C 436.761719 278.941406 440.082031 267.898438 440.511719 263.507812 C 444.660156 241.171875 444.757812 216.527344 444.859375 194.484375 C 437.386719 198.457031 427.601562 201.269531 418.105469 200.035156 C 410.492188 199.453125 403.457031 197.71875 395.265625 193.085938 C 388.160156 197.34375 385.679688 199.289062 373.949219 199.785156 C 364.8125 200.234375 357.414062 200.976562 346.539062 195.929688 Z M 346.539062 195.929688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(94.901961%,94.509804%,84.313725%);fill-opacity:1;"
      d="M 395.554688 191.941406 L 397.078125 193.464844 Z M 395.554688 191.941406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(85.098039%,84.705882%,40.784314%);fill-opacity:1;"
      d="M 397.078125 191.941406 L 398.601562 193.464844 Z M 397.078125 191.941406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 403.167969 191.941406 L 404.691406 193.464844 Z M 403.167969 191.941406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(60%,60%,30.588235%);fill-opacity:1;"
      d="M 404.691406 191.941406 L 406.214844 193.464844 Z M 404.691406 191.941406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(28.627451%,28.627451%,49.019608%);fill-opacity:1;"
      d="M 406.214844 191.941406 L 407.734375 193.464844 Z M 406.214844 191.941406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.490196%,5.490196%,43.137255%);fill-opacity:1;"
      d="M 407.734375 191.941406 L 409.257812 193.464844 Z M 407.734375 191.941406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(22.745098%,22.745098%,48.627451%);fill-opacity:1;"
      d="M 438.1875 191.941406 L 439.710938 193.464844 Z M 438.1875 191.941406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(40%,40%,46.666667%);fill-opacity:1;"
      d="M 439.710938 191.941406 L 441.234375 193.464844 Z M 439.710938 191.941406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(60%,60%,30.588235%);fill-opacity:1;"
      d="M 441.234375 191.941406 L 442.757812 193.464844 Z M 441.234375 191.941406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 442.757812 191.941406 L 444.28125 193.464844 L 442.757812 191.941406 M 351.398438 193.464844 L 352.921875 194.988281 Z M 351.398438 193.464844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(60%,60%,30.588235%);fill-opacity:1;"
      d="M 352.921875 193.464844 L 354.441406 194.988281 Z M 352.921875 193.464844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(43.137255%,42.352941%,43.921569%);fill-opacity:1;"
      d="M 354.441406 193.464844 L 355.964844 194.988281 Z M 354.441406 193.464844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(28.627451%,28.627451%,49.019608%);fill-opacity:1;"
      d="M 355.964844 193.464844 L 357.488281 194.988281 Z M 355.964844 193.464844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(10.588235%,10.588235%,45.490196%);fill-opacity:1;"
      d="M 357.488281 193.464844 L 359.011719 194.988281 L 357.488281 193.464844 M 377.28125 193.464844 L 378.804688 194.988281 Z M 377.28125 193.464844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(32.54902%,32.156863%,48.627451%);fill-opacity:1;"
      d="M 378.804688 193.464844 L 380.328125 194.988281 Z M 378.804688 193.464844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(55.294118%,55.294118%,35.686275%);fill-opacity:1;"
      d="M 380.328125 193.464844 L 381.851562 194.988281 Z M 380.328125 193.464844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 381.851562 193.464844 L 383.375 194.988281 Z M 381.851562 193.464844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(89.803922%,89.803922%,61.568627%);fill-opacity:1;"
      d="M 389.464844 193.464844 L 390.988281 194.988281 Z M 389.464844 193.464844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,98.039216%,94.901961%);fill-opacity:1;"
      d="M 390.988281 193.464844 L 392.507812 194.988281 Z M 390.988281 193.464844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(94.901961%,94.509804%,82.352941%);fill-opacity:1;"
      d="M 398.601562 193.464844 L 400.121094 194.988281 Z M 398.601562 193.464844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(85.098039%,84.705882%,40.784314%);fill-opacity:1;"
      d="M 400.121094 193.464844 L 401.644531 194.988281 Z M 400.121094 193.464844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 407.734375 193.464844 L 409.257812 194.988281 Z M 407.734375 193.464844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(43.137255%,42.352941%,43.921569%);fill-opacity:1;"
      d="M 409.257812 193.464844 L 410.78125 194.988281 Z M 409.257812 193.464844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(22.745098%,22.745098%,48.627451%);fill-opacity:1;"
      d="M 410.78125 193.464844 L 412.304688 194.988281 Z M 410.78125 193.464844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.490196%,5.490196%,43.137255%);fill-opacity:1;"
      d="M 430.574219 193.464844 L 432.097656 194.988281 Z M 430.574219 193.464844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(19.607843%,19.607843%,48.235294%);fill-opacity:1;"
      d="M 432.097656 193.464844 L 433.621094 194.988281 Z M 432.097656 193.464844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(34.509804%,34.509804%,48.235294%);fill-opacity:1;"
      d="M 433.621094 193.464844 L 435.144531 194.988281 Z M 433.621094 193.464844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(50.196078%,50.196078%,40.392157%);fill-opacity:1;"
      d="M 435.144531 193.464844 L 436.667969 194.988281 Z M 435.144531 193.464844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 437.6875 193.964844 L 438.699219 194.472656 Z M 437.6875 193.964844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(86.666667%,86.27451%,47.843137%);fill-opacity:1;"
      d="M 345.308594 194.988281 L 346.828125 196.507812 Z M 345.308594 194.988281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(81.568627%,81.568627%,27.058824%);fill-opacity:1;"
      d="M 346.828125 194.988281 L 348.351562 196.507812 Z M 346.828125 194.988281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 358.507812 195.488281 L 359.523438 195.996094 Z M 358.507812 195.488281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(50.196078%,50.196078%,40.392157%);fill-opacity:1;"
      d="M 360.535156 194.988281 L 362.054688 196.507812 Z M 360.535156 194.988281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(40%,40%,46.666667%);fill-opacity:1;"
      d="M 362.054688 194.988281 L 363.578125 196.507812 Z M 362.054688 194.988281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(34.509804%,34.509804%,48.235294%);fill-opacity:1;"
      d="M 363.578125 194.988281 L 365.101562 196.507812 Z M 363.578125 194.988281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(28.627451%,28.627451%,49.019608%);fill-opacity:1;"
      d="M 365.101562 194.988281 L 366.625 196.507812 Z M 365.101562 194.988281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(45.098039%,45.098039%,43.921569%);fill-opacity:1;"
      d="M 374.238281 194.988281 L 375.761719 196.507812 Z M 374.238281 194.988281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(60%,60%,30.588235%);fill-opacity:1;"
      d="M 375.761719 194.988281 L 377.28125 196.507812 Z M 375.761719 194.988281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 377.28125 194.988281 L 378.804688 196.507812 Z M 377.28125 194.988281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(89.803922%,89.803922%,61.568627%);fill-opacity:1;"
      d="M 386.417969 194.988281 L 387.941406 196.507812 Z M 386.417969 194.988281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,98.039216%,94.901961%);fill-opacity:1;"
      d="M 387.941406 194.988281 L 389.464844 196.507812 Z M 387.941406 194.988281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(94.901961%,94.509804%,82.352941%);fill-opacity:1;"
      d="M 401.644531 194.988281 L 403.167969 196.507812 Z M 401.644531 194.988281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(85.098039%,84.705882%,40.784314%);fill-opacity:1;"
      d="M 403.167969 194.988281 L 404.691406 196.507812 Z M 403.167969 194.988281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 411.800781 195.488281 L 412.816406 195.996094 Z M 411.800781 195.488281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(50.196078%,50.196078%,40.392157%);fill-opacity:1;"
      d="M 413.828125 194.988281 L 415.347656 196.507812 Z M 413.828125 194.988281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(40%,40%,46.666667%);fill-opacity:1;"
      d="M 415.347656 194.988281 L 416.871094 196.507812 Z M 415.347656 194.988281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(34.509804%,34.509804%,48.235294%);fill-opacity:1;"
      d="M 416.871094 194.988281 L 418.394531 196.507812 Z M 416.871094 194.988281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(22.745098%,22.745098%,48.627451%);fill-opacity:1;"
      d="M 418.394531 194.988281 L 419.917969 196.507812 Z M 418.394531 194.988281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(34.509804%,34.509804%,48.235294%);fill-opacity:1;"
      d="M 425.503906 195.488281 L 426.519531 195.996094 Z M 425.503906 195.488281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(45.098039%,45.098039%,43.921569%);fill-opacity:1;"
      d="M 427.53125 194.988281 L 429.054688 196.507812 Z M 427.53125 194.988281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(60%,60%,30.588235%);fill-opacity:1;"
      d="M 429.054688 194.988281 L 430.574219 196.507812 Z M 429.054688 194.988281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 430.574219 194.988281 L 432.097656 196.507812 Z M 430.574219 194.988281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(86.666667%,86.27451%,47.843137%);fill-opacity:1;"
      d="M 442.757812 194.988281 L 444.28125 196.507812 Z M 442.757812 194.988281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(81.568627%,81.568627%,27.058824%);fill-opacity:1;"
      d="M 444.28125 194.988281 L 445.800781 196.507812 Z M 444.28125 194.988281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(94.901961%,94.509804%,84.313725%);fill-opacity:1;"
      d="M 348.351562 196.507812 L 349.875 198.03125 Z M 348.351562 196.507812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(87.843137%,87.058824%,63.137255%);fill-opacity:1;"
      d="M 349.875 196.507812 L 351.398438 198.03125 Z M 349.875 196.507812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(86.666667%,86.27451%,47.843137%);fill-opacity:1;"
      d="M 351.398438 196.507812 L 352.921875 198.03125 Z M 351.398438 196.507812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(85.098039%,84.705882%,40.784314%);fill-opacity:1;"
      d="M 381.851562 196.507812 L 383.375 198.03125 Z M 381.851562 196.507812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(89.803922%,89.019608%,68.627451%);fill-opacity:1;"
      d="M 383.375 196.507812 L 384.894531 198.03125 Z M 383.375 196.507812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(96.470588%,96.470588%,89.411765%);fill-opacity:1;"
      d="M 404.691406 196.507812 L 406.214844 198.03125 Z M 404.691406 196.507812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(88.235294%,88.235294%,54.901961%);fill-opacity:1;"
      d="M 406.214844 196.507812 L 407.734375 198.03125 Z M 406.214844 196.507812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.137255%,83.137255%,33.72549%);fill-opacity:1;"
      d="M 436.667969 196.507812 L 438.1875 198.03125 Z M 436.667969 196.507812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(88.235294%,88.235294%,54.901961%);fill-opacity:1;"
      d="M 438.1875 196.507812 L 439.710938 198.03125 Z M 438.1875 196.507812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(93.333333%,92.941176%,75.686275%);fill-opacity:1;"
      d="M 439.710938 196.507812 L 441.234375 198.03125 Z M 439.710938 196.507812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(94.901961%,94.509804%,82.352941%);fill-opacity:1;"
      d="M 354.441406 198.03125 L 355.964844 199.554688 Z M 354.441406 198.03125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(87.843137%,87.058824%,63.137255%);fill-opacity:1;"
      d="M 355.964844 198.03125 L 357.488281 199.554688 Z M 355.964844 198.03125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(86.666667%,86.27451%,47.843137%);fill-opacity:1;"
      d="M 357.488281 198.03125 L 359.011719 199.554688 Z M 357.488281 198.03125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(81.568627%,81.568627%,27.058824%);fill-opacity:1;"
      d="M 359.011719 198.03125 L 360.535156 199.554688 Z M 359.011719 198.03125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(86.666667%,86.27451%,47.843137%);fill-opacity:1;"
      d="M 377.28125 198.03125 L 378.804688 199.554688 Z M 377.28125 198.03125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(89.803922%,89.019608%,68.627451%);fill-opacity:1;"
      d="M 378.804688 198.03125 L 380.328125 199.554688 Z M 378.804688 198.03125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(96.470588%,96.470588%,89.411765%);fill-opacity:1;"
      d="M 380.328125 198.03125 L 381.851562 199.554688 Z M 380.328125 198.03125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(93.333333%,92.941176%,75.686275%);fill-opacity:1;"
      d="M 409.257812 198.03125 L 410.78125 199.554688 Z M 409.257812 198.03125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(88.235294%,88.235294%,54.901961%);fill-opacity:1;"
      d="M 410.78125 198.03125 L 412.304688 199.554688 Z M 410.78125 198.03125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.137255%,83.137255%,33.72549%);fill-opacity:1;"
      d="M 412.304688 198.03125 L 413.828125 199.554688 Z M 412.304688 198.03125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(85.098039%,84.705882%,40.784314%);fill-opacity:1;"
      d="M 430.574219 198.03125 L 432.097656 199.554688 Z M 430.574219 198.03125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(88.235294%,88.235294%,54.901961%);fill-opacity:1;"
      d="M 432.097656 198.03125 L 433.621094 199.554688 Z M 432.097656 198.03125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(93.333333%,92.941176%,75.686275%);fill-opacity:1;"
      d="M 433.621094 198.03125 L 435.144531 199.554688 Z M 433.621094 198.03125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(96.470588%,96.470588%,89.411765%);fill-opacity:1;"
      d="M 435.144531 198.03125 L 436.667969 199.554688 Z M 435.144531 198.03125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(94.901961%,94.509804%,84.313725%);fill-opacity:1;"
      d="M 362.054688 199.554688 L 363.578125 201.078125 Z M 362.054688 199.554688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(94.901961%,94.509804%,82.352941%);fill-opacity:1;"
      d="M 363.578125 199.554688 L 365.101562 201.078125 Z M 363.578125 199.554688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(93.333333%,92.941176%,75.686275%);fill-opacity:1;"
      d="M 365.101562 199.554688 L 366.625 201.078125 Z M 365.101562 199.554688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(94.901961%,94.509804%,82.352941%);fill-opacity:1;"
      d="M 372.210938 200.058594 L 373.226562 200.566406 Z M 372.210938 200.058594 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,98.039216%,94.901961%);fill-opacity:1;"
      d="M 374.238281 199.554688 L 375.761719 201.078125 Z M 374.238281 199.554688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 381.851562 199.554688 L 384.894531 202.601562 L 384.894531 199.554688 Z M 381.851562 199.554688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(94.901961%,94.509804%,84.313725%);fill-opacity:1;"
      d="M 415.347656 199.554688 L 416.871094 201.078125 Z M 415.347656 199.554688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(94.901961%,94.509804%,82.352941%);fill-opacity:1;"
      d="M 416.871094 199.554688 L 418.394531 201.078125 Z M 416.871094 199.554688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(89.803922%,89.019608%,68.627451%);fill-opacity:1;"
      d="M 418.394531 199.554688 L 419.917969 201.078125 Z M 418.394531 199.554688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(89.803922%,89.803922%,61.568627%);fill-opacity:1;"
      d="M 420.9375 200.058594 L 421.949219 200.566406 Z M 420.9375 200.058594 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(87.843137%,87.058824%,63.137255%);fill-opacity:1;"
      d="M 422.960938 199.554688 L 424.484375 201.078125 Z M 422.960938 199.554688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(94.901961%,94.509804%,82.352941%);fill-opacity:1;"
      d="M 425.503906 200.058594 L 426.519531 200.566406 Z M 425.503906 200.058594 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,98.039216%,94.901961%);fill-opacity:1;"
      d="M 427.53125 199.554688 L 429.054688 201.078125 Z M 427.53125 199.554688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 378.804688 201.078125 L 380.328125 202.601562 Z M 378.804688 201.078125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,74.509804%,40%);fill-opacity:1;"
      d="M 380.328125 201.078125 L 381.851562 202.601562 Z M 380.328125 201.078125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 378.804688 202.601562 L 380.328125 204.121094 Z M 378.804688 202.601562 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 381.851562 202.601562 L 383.375 204.121094 Z M 381.851562 202.601562 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 378.804688 204.121094 L 380.328125 205.644531 Z M 378.804688 204.121094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 381.851562 204.121094 L 383.375 205.644531 Z M 381.851562 204.121094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,89.019608%,78.823529%);fill-opacity:1;"
      d="M 377.28125 205.644531 L 378.804688 207.167969 L 377.28125 205.644531 M 383.375 205.644531 L 384.894531 207.167969 Z M 383.375 205.644531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 390.988281 205.644531 L 392.507812 207.167969 Z M 390.988281 205.644531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,83.921569%,66.666667%);fill-opacity:1;"
      d="M 392.507812 205.644531 L 394.03125 207.167969 Z M 392.507812 205.644531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,89.019608%,78.823529%);fill-opacity:1;"
      d="M 398.601562 205.644531 L 400.121094 207.167969 Z M 398.601562 205.644531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 400.121094 205.644531 L 401.644531 207.167969 Z M 400.121094 205.644531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,81.568627%,60%);fill-opacity:1;"
      d="M 377.28125 207.167969 L 378.804688 208.691406 Z M 377.28125 207.167969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.215686%,67.058824%,20%);fill-opacity:1;"
      d="M 383.375 207.167969 L 384.894531 208.691406 Z M 383.375 207.167969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,94.509804%,89.411765%);fill-opacity:1;"
      d="M 386.417969 207.167969 L 387.941406 208.691406 Z M 386.417969 207.167969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 387.941406 207.167969 L 389.464844 208.691406 Z M 387.941406 207.167969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 389.464844 207.167969 L 390.988281 208.691406 Z M 389.464844 207.167969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,71.764706%,33.333333%);fill-opacity:1;"
      d="M 401.644531 207.167969 L 403.167969 208.691406 Z M 401.644531 207.167969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,83.921569%,66.666667%);fill-opacity:1;"
      d="M 403.167969 207.167969 L 404.691406 208.691406 Z M 403.167969 207.167969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,79.215686%,53.333333%);fill-opacity:1;"
      d="M 377.28125 208.691406 L 378.804688 210.214844 Z M 377.28125 208.691406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 384.894531 208.691406 L 386.417969 210.214844 L 384.894531 208.691406 M 404.691406 208.691406 L 406.214844 210.214844 Z M 404.691406 208.691406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.254902%,86.27451%,73.333333%);fill-opacity:1;"
      d="M 406.214844 208.691406 L 407.734375 210.214844 Z M 406.214844 208.691406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(96.470588%,96.470588%,89.411765%);fill-opacity:1;"
      d="M 345.308594 210.214844 L 346.828125 211.734375 Z M 345.308594 210.214844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 377.28125 210.214844 L 378.804688 211.734375 Z M 377.28125 210.214844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,74.509804%,40%);fill-opacity:1;"
      d="M 407.734375 210.214844 L 409.257812 211.734375 Z M 407.734375 210.214844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.254902%,86.27451%,73.333333%);fill-opacity:1;"
      d="M 409.257812 210.214844 L 410.78125 211.734375 Z M 409.257812 210.214844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,79.215686%,53.333333%);fill-opacity:1;"
      d="M 377.28125 211.734375 L 378.804688 213.257812 Z M 377.28125 211.734375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,71.764706%,33.333333%);fill-opacity:1;"
      d="M 412.304688 211.734375 L 413.828125 213.257812 Z M 412.304688 211.734375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.254902%,86.27451%,73.333333%);fill-opacity:1;"
      d="M 413.828125 211.734375 L 415.347656 213.257812 Z M 413.828125 211.734375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 375.761719 213.257812 L 377.28125 214.78125 Z M 375.761719 213.257812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 377.28125 213.257812 L 378.804688 214.78125 Z M 377.28125 213.257812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.215686%,67.058824%,20%);fill-opacity:1;"
      d="M 419.917969 213.257812 L 421.441406 214.78125 Z M 419.917969 213.257812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 421.441406 213.257812 L 422.960938 214.78125 Z M 421.441406 213.257812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 422.960938 213.257812 L 424.484375 214.78125 Z M 422.960938 213.257812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,83.921569%,66.666667%);fill-opacity:1;"
      d="M 424.484375 213.257812 L 427.53125 216.304688 Z M 424.484375 213.257812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 426.007812 213.257812 L 427.53125 214.78125 Z M 426.007812 213.257812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 375.761719 214.78125 L 377.28125 216.304688 Z M 375.761719 214.78125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.215686%,67.058824%,20%);fill-opacity:1;"
      d="M 397.078125 214.78125 L 398.601562 216.304688 Z M 397.078125 214.78125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 398.601562 214.78125 L 400.121094 216.304688 Z M 398.601562 214.78125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 404.691406 214.78125 L 406.214844 216.304688 Z M 404.691406 214.78125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 406.214844 214.78125 L 407.734375 216.304688 Z M 406.214844 214.78125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,89.019608%,78.823529%);fill-opacity:1;"
      d="M 374.238281 216.304688 L 375.761719 217.828125 Z M 374.238281 216.304688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 394.03125 216.304688 L 395.554688 217.828125 Z M 394.03125 216.304688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,89.019608%,78.823529%);fill-opacity:1;"
      d="M 395.554688 216.304688 L 397.078125 217.828125 Z M 395.554688 216.304688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.254902%,86.27451%,73.333333%);fill-opacity:1;"
      d="M 407.734375 216.304688 L 409.257812 217.828125 Z M 407.734375 216.304688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.215686%,67.058824%,20%);fill-opacity:1;"
      d="M 409.257812 216.304688 L 410.78125 217.828125 Z M 409.257812 216.304688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 418.394531 216.304688 L 419.917969 217.828125 Z M 418.394531 216.304688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,71.764706%,33.333333%);fill-opacity:1;"
      d="M 419.917969 216.304688 L 421.441406 217.828125 Z M 419.917969 216.304688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,81.568627%,60%);fill-opacity:1;"
      d="M 421.441406 216.304688 L 422.960938 217.828125 Z M 421.441406 216.304688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 422.960938 216.304688 L 424.484375 217.828125 Z M 422.960938 216.304688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 374.238281 217.828125 L 375.761719 219.347656 Z M 374.238281 217.828125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,74.509804%,40%);fill-opacity:1;"
      d="M 392.507812 217.828125 L 394.03125 219.347656 Z M 392.507812 217.828125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,81.568627%,60%);fill-opacity:1;"
      d="M 410.78125 217.828125 L 412.304688 219.347656 Z M 410.78125 217.828125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 416.871094 217.828125 L 418.394531 219.347656 Z M 416.871094 217.828125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,94.509804%,89.411765%);fill-opacity:1;"
      d="M 372.714844 219.347656 L 374.238281 220.871094 Z M 372.714844 219.347656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,74.509804%,40%);fill-opacity:1;"
      d="M 390.988281 219.347656 L 392.507812 220.871094 Z M 390.988281 219.347656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,81.568627%,60%);fill-opacity:1;"
      d="M 412.304688 219.347656 L 413.828125 220.871094 Z M 412.304688 219.347656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,89.019608%,78.823529%);fill-opacity:1;"
      d="M 416.871094 219.347656 L 418.394531 220.871094 Z M 416.871094 219.347656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 372.714844 220.871094 L 374.238281 222.394531 Z M 372.714844 220.871094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 389.464844 220.871094 L 390.988281 222.394531 L 389.464844 220.871094 M 413.828125 220.871094 L 415.347656 222.394531 Z M 413.828125 220.871094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,74.509804%,40%);fill-opacity:1;"
      d="M 416.871094 220.871094 L 418.394531 222.394531 Z M 416.871094 220.871094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(96.470588%,96.470588%,89.411765%);fill-opacity:1;"
      d="M 345.308594 222.394531 L 346.828125 223.917969 Z M 345.308594 222.394531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 372.714844 222.394531 L 374.238281 223.917969 Z M 372.714844 222.394531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 389.464844 222.394531 L 390.988281 223.917969 Z M 389.464844 222.394531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,94.509804%,89.411765%);fill-opacity:1;"
      d="M 413.828125 222.394531 L 415.347656 223.917969 Z M 413.828125 222.394531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 418.394531 222.394531 L 419.917969 223.917969 Z M 418.394531 222.394531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,94.509804%,89.411765%);fill-opacity:1;"
      d="M 371.191406 223.917969 L 372.714844 225.441406 Z M 371.191406 223.917969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,74.509804%,40%);fill-opacity:1;"
      d="M 387.941406 223.917969 L 389.464844 225.441406 Z M 387.941406 223.917969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,79.215686%,53.333333%);fill-opacity:1;"
      d="M 415.347656 223.917969 L 416.871094 225.441406 Z M 415.347656 223.917969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,81.568627%,60%);fill-opacity:1;"
      d="M 418.394531 223.917969 L 419.917969 225.441406 Z M 418.394531 223.917969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,83.921569%,66.666667%);fill-opacity:1;"
      d="M 371.191406 225.441406 L 372.714844 226.960938 Z M 371.191406 225.441406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,94.509804%,89.411765%);fill-opacity:1;"
      d="M 387.941406 225.441406 L 389.464844 226.960938 Z M 387.941406 225.441406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,89.019608%,78.823529%);fill-opacity:1;"
      d="M 395.554688 225.441406 L 397.078125 226.960938 Z M 395.554688 225.441406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 397.078125 225.441406 L 398.601562 226.960938 Z M 397.078125 225.441406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 398.601562 225.441406 L 400.121094 226.960938 Z M 398.601562 225.441406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,83.921569%,66.666667%);fill-opacity:1;"
      d="M 400.121094 225.441406 L 401.644531 226.960938 Z M 400.121094 225.441406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 415.347656 225.441406 L 416.871094 226.960938 Z M 415.347656 225.441406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 416.871094 225.441406 L 418.394531 226.960938 Z M 416.871094 225.441406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.215686%,67.058824%,20%);fill-opacity:1;"
      d="M 418.394531 225.441406 L 419.917969 226.960938 Z M 418.394531 225.441406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,79.215686%,53.333333%);fill-opacity:1;"
      d="M 371.191406 226.960938 L 369.667969 231.53125 Z M 371.191406 226.960938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 386.417969 226.960938 L 387.941406 228.484375 Z M 386.417969 226.960938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 387.941406 226.960938 L 389.464844 228.484375 Z M 387.941406 226.960938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,81.568627%,60%);fill-opacity:1;"
      d="M 395.554688 226.960938 L 397.078125 228.484375 Z M 395.554688 226.960938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.215686%,67.058824%,20%);fill-opacity:1;"
      d="M 401.644531 226.960938 L 403.167969 228.484375 Z M 401.644531 226.960938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,89.019608%,78.823529%);fill-opacity:1;"
      d="M 403.167969 226.960938 L 404.691406 228.484375 Z M 403.167969 226.960938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.254902%,86.27451%,73.333333%);fill-opacity:1;"
      d="M 416.871094 226.960938 L 418.394531 228.484375 Z M 416.871094 226.960938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,60%,0%);fill-opacity:1;"
      d="M 418.394531 226.960938 L 419.917969 228.484375 Z M 418.394531 226.960938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 420.417969 227.984375 L 420.929688 228.996094 Z M 420.417969 227.984375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 389.464844 228.484375 L 390.988281 230.007812 Z M 389.464844 228.484375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,74.509804%,40%);fill-opacity:1;"
      d="M 390.988281 228.484375 L 392.507812 230.007812 Z M 390.988281 228.484375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,79.215686%,53.333333%);fill-opacity:1;"
      d="M 392.507812 228.484375 L 394.03125 230.007812 Z M 392.507812 228.484375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 394.03125 228.484375 L 395.554688 230.007812 Z M 394.03125 228.484375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,89.019608%,78.823529%);fill-opacity:1;"
      d="M 395.554688 228.484375 L 397.078125 230.007812 Z M 395.554688 228.484375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 397.078125 228.484375 L 398.601562 230.007812 Z M 397.078125 228.484375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.215686%,67.058824%,20%);fill-opacity:1;"
      d="M 404.691406 228.484375 L 406.214844 230.007812 Z M 404.691406 228.484375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 406.214844 228.484375 L 407.734375 230.007812 Z M 406.214844 228.484375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,79.215686%,53.333333%);fill-opacity:1;"
      d="M 407.734375 228.484375 L 409.257812 230.007812 Z M 407.734375 228.484375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,83.921569%,66.666667%);fill-opacity:1;"
      d="M 409.257812 228.484375 L 410.78125 230.007812 Z M 409.257812 228.484375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,89.019608%,78.823529%);fill-opacity:1;"
      d="M 411.800781 228.988281 L 412.816406 229.496094 Z M 411.800781 228.988281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 413.828125 228.484375 L 415.347656 230.007812 Z M 413.828125 228.484375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 418.394531 228.484375 L 419.917969 230.007812 Z M 418.394531 228.484375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 352.921875 230.007812 L 354.441406 231.53125 Z M 352.921875 230.007812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,94.509804%,89.411765%);fill-opacity:1;"
      d="M 354.441406 230.007812 L 355.964844 231.53125 Z M 354.441406 230.007812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,71.764706%,33.333333%);fill-opacity:1;"
      d="M 394.03125 230.007812 L 395.554688 231.53125 Z M 394.03125 230.007812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 397.078125 230.007812 L 398.601562 231.53125 Z M 397.078125 230.007812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 398.601562 230.007812 L 400.121094 231.53125 Z M 398.601562 230.007812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 410.78125 230.007812 L 412.304688 231.53125 Z M 410.78125 230.007812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 412.304688 230.007812 L 410.78125 233.054688 Z M 412.304688 230.007812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 415.347656 230.007812 L 416.871094 231.53125 Z M 415.347656 230.007812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,74.509804%,40%);fill-opacity:1;"
      d="M 416.871094 230.007812 L 418.394531 231.53125 Z M 416.871094 230.007812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,94.509804%,89.411765%);fill-opacity:1;"
      d="M 418.394531 230.007812 L 419.917969 231.53125 Z M 418.394531 230.007812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,89.019608%,78.823529%);fill-opacity:1;"
      d="M 352.921875 231.53125 L 354.441406 233.054688 Z M 352.921875 231.53125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 354.441406 231.53125 L 357.488281 234.574219 Z M 354.441406 231.53125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 355.964844 231.53125 L 357.488281 233.054688 Z M 355.964844 231.53125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 369.667969 231.53125 L 371.191406 233.054688 Z M 369.667969 231.53125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,89.019608%,78.823529%);fill-opacity:1;"
      d="M 371.191406 231.53125 L 372.714844 233.054688 Z M 371.191406 231.53125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 384.894531 231.53125 L 386.417969 233.054688 Z M 384.894531 231.53125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 386.417969 231.53125 L 387.941406 233.054688 Z M 386.417969 231.53125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 387.941406 231.53125 L 389.464844 233.054688 Z M 387.941406 231.53125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 392.507812 231.53125 L 394.03125 233.054688 Z M 392.507812 231.53125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 394.03125 231.53125 L 395.554688 233.054688 Z M 394.03125 231.53125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 400.121094 231.53125 L 401.644531 233.054688 Z M 400.121094 231.53125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 412.304688 231.53125 L 413.828125 233.054688 Z M 412.304688 231.53125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.215686%,67.058824%,20%);fill-opacity:1;"
      d="M 413.828125 231.53125 L 415.347656 233.054688 Z M 413.828125 231.53125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 419.917969 231.53125 L 421.441406 233.054688 Z M 419.917969 231.53125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 352.921875 233.054688 L 354.441406 234.574219 Z M 352.921875 233.054688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 357.488281 233.054688 L 359.011719 234.574219 Z M 357.488281 233.054688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,83.921569%,66.666667%);fill-opacity:1;"
      d="M 365.101562 233.054688 L 366.625 234.574219 Z M 365.101562 233.054688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 372.714844 233.054688 L 374.238281 234.574219 Z M 372.714844 233.054688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,83.921569%,66.666667%);fill-opacity:1;"
      d="M 375.257812 233.554688 L 376.273438 234.0625 Z M 375.257812 233.554688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.254902%,86.27451%,73.333333%);fill-opacity:1;"
      d="M 384.894531 233.054688 L 386.417969 234.574219 Z M 384.894531 233.054688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,94.509804%,89.411765%);fill-opacity:1;"
      d="M 389.464844 233.054688 L 390.988281 234.574219 Z M 389.464844 233.054688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,83.921569%,66.666667%);fill-opacity:1;"
      d="M 401.644531 233.054688 L 403.167969 234.574219 Z M 401.644531 233.054688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.215686%,67.058824%,20%);fill-opacity:1;"
      d="M 412.304688 233.054688 L 413.828125 234.574219 Z M 412.304688 233.054688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,71.764706%,33.333333%);fill-opacity:1;"
      d="M 421.441406 233.054688 L 422.960938 234.574219 Z M 421.441406 233.054688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 422.960938 233.054688 L 424.484375 234.574219 Z M 422.960938 233.054688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(32.54902%,32.156863%,48.627451%);fill-opacity:1;"
      d="M 340.738281 234.574219 L 342.261719 236.097656 Z M 340.738281 234.574219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,71.764706%,33.333333%);fill-opacity:1;"
      d="M 354.441406 234.574219 L 355.964844 236.097656 Z M 354.441406 234.574219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 357.488281 234.574219 L 359.011719 236.097656 Z M 357.488281 234.574219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 363.578125 234.574219 L 365.101562 236.097656 Z M 363.578125 234.574219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 366.625 234.574219 L 368.148438 236.097656 Z M 366.625 234.574219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,94.509804%,89.411765%);fill-opacity:1;"
      d="M 368.148438 234.574219 L 369.667969 236.097656 Z M 368.148438 234.574219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,74.509804%,40%);fill-opacity:1;"
      d="M 372.714844 234.574219 L 374.238281 236.097656 Z M 372.714844 234.574219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 374.238281 234.574219 L 375.761719 236.097656 Z M 374.238281 234.574219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,74.509804%,40%);fill-opacity:1;"
      d="M 375.761719 234.574219 L 377.28125 236.097656 Z M 375.761719 234.574219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 383.375 234.574219 L 384.894531 236.097656 Z M 383.375 234.574219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,83.921569%,66.666667%);fill-opacity:1;"
      d="M 403.167969 234.574219 L 404.691406 236.097656 Z M 403.167969 234.574219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 422.960938 234.574219 L 424.484375 236.097656 Z M 422.960938 234.574219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(55.294118%,55.294118%,35.686275%);fill-opacity:1;"
      d="M 447.324219 234.574219 L 448.847656 236.097656 Z M 447.324219 234.574219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(89.803922%,89.019608%,68.627451%);fill-opacity:1;"
      d="M 345.308594 236.097656 L 346.828125 237.621094 Z M 345.308594 236.097656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.254902%,86.27451%,73.333333%);fill-opacity:1;"
      d="M 354.441406 236.097656 L 355.964844 237.621094 Z M 354.441406 236.097656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.215686%,67.058824%,20%);fill-opacity:1;"
      d="M 359.011719 236.097656 L 360.535156 237.621094 Z M 359.011719 236.097656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 363.578125 236.097656 L 365.101562 237.621094 Z M 363.578125 236.097656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,79.215686%,53.333333%);fill-opacity:1;"
      d="M 366.625 236.097656 L 368.148438 237.621094 Z M 366.625 236.097656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,94.509804%,89.411765%);fill-opacity:1;"
      d="M 372.714844 236.097656 L 374.238281 237.621094 Z M 372.714844 236.097656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,81.568627%,60%);fill-opacity:1;"
      d="M 383.375 236.097656 L 384.894531 237.621094 L 383.375 236.097656 M 404.691406 236.097656 L 406.214844 237.621094 Z M 404.691406 236.097656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,74.509804%,40%);fill-opacity:1;"
      d="M 424.484375 236.097656 L 426.007812 237.621094 Z M 424.484375 236.097656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 355.964844 237.621094 L 357.488281 239.144531 Z M 355.964844 237.621094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.215686%,67.058824%,20%);fill-opacity:1;"
      d="M 360.535156 237.621094 L 362.054688 239.144531 Z M 360.535156 237.621094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 362.054688 237.621094 L 363.578125 239.144531 Z M 362.054688 237.621094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 365.101562 237.621094 L 366.625 239.144531 Z M 365.101562 237.621094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 366.625 237.621094 L 368.148438 239.144531 Z M 366.625 237.621094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,74.509804%,40%);fill-opacity:1;"
      d="M 374.238281 237.621094 L 375.761719 239.144531 Z M 374.238281 237.621094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 383.375 237.621094 L 384.894531 239.144531 Z M 383.375 237.621094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,74.509804%,40%);fill-opacity:1;"
      d="M 406.214844 237.621094 L 407.734375 239.144531 Z M 406.214844 237.621094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,81.568627%,60%);fill-opacity:1;"
      d="M 426.007812 237.621094 L 427.53125 239.144531 Z M 426.007812 237.621094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,83.921569%,66.666667%);fill-opacity:1;"
      d="M 355.964844 239.144531 L 357.488281 240.667969 Z M 355.964844 239.144531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,81.568627%,60%);fill-opacity:1;"
      d="M 365.101562 239.144531 L 366.625 240.667969 Z M 365.101562 239.144531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,94.509804%,89.411765%);fill-opacity:1;"
      d="M 374.238281 239.144531 L 375.761719 240.667969 Z M 374.238281 239.144531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.215686%,67.058824%,20%);fill-opacity:1;"
      d="M 381.851562 239.144531 L 383.375 240.667969 L 381.851562 239.144531 M 407.734375 239.144531 L 409.257812 240.667969 Z M 407.734375 239.144531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 427.53125 239.144531 L 429.054688 240.667969 Z M 427.53125 239.144531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 357.488281 240.667969 L 359.011719 242.1875 Z M 357.488281 240.667969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 363.578125 240.667969 L 365.101562 242.1875 Z M 363.578125 240.667969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 375.761719 240.667969 L 377.28125 242.1875 Z M 375.761719 240.667969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,79.215686%,53.333333%);fill-opacity:1;"
      d="M 381.851562 240.667969 L 383.375 242.1875 Z M 381.851562 240.667969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.254902%,86.27451%,73.333333%);fill-opacity:1;"
      d="M 407.734375 240.667969 L 409.257812 242.1875 Z M 407.734375 240.667969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 427.53125 240.667969 L 429.054688 242.1875 Z M 427.53125 240.667969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(82.745098%,81.568627%,47.45098%);fill-opacity:1;"
      d="M 345.308594 242.1875 L 346.828125 243.710938 Z M 345.308594 242.1875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,79.215686%,53.333333%);fill-opacity:1;"
      d="M 357.488281 242.1875 L 359.011719 243.710938 Z M 357.488281 242.1875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,79.215686%,53.333333%);fill-opacity:1;"
      d="M 375.761719 242.1875 L 377.28125 243.710938 Z M 375.761719 242.1875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,89.019608%,78.823529%);fill-opacity:1;"
      d="M 381.851562 242.1875 L 383.375 243.710938 L 381.851562 242.1875 M 407.734375 242.1875 L 409.257812 243.710938 Z M 407.734375 242.1875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.254902%,86.27451%,73.333333%);fill-opacity:1;"
      d="M 429.054688 242.1875 L 430.574219 243.710938 Z M 429.054688 242.1875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(94.901961%,94.509804%,84.313725%);fill-opacity:1;"
      d="M 442.757812 242.1875 L 444.28125 243.710938 Z M 442.757812 242.1875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(34.509804%,34.509804%,48.235294%);fill-opacity:1;"
      d="M 447.828125 243.210938 L 448.335938 244.222656 Z M 447.828125 243.210938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(85.098039%,84.705882%,40.784314%);fill-opacity:1;"
      d="M 345.808594 244.730469 L 346.316406 245.746094 Z M 345.808594 244.730469 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.254902%,86.27451%,73.333333%);fill-opacity:1;"
      d="M 357.488281 243.710938 L 359.011719 245.234375 Z M 357.488281 243.710938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,83.921569%,66.666667%);fill-opacity:1;"
      d="M 406.214844 243.710938 L 407.734375 245.234375 Z M 406.214844 243.710938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 407.734375 243.710938 L 409.257812 245.234375 Z M 407.734375 243.710938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 429.054688 243.710938 L 430.574219 245.234375 Z M 429.054688 243.710938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(94.901961%,94.509804%,82.352941%);fill-opacity:1;"
      d="M 443.257812 244.730469 L 443.769531 245.746094 Z M 443.257812 244.730469 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,94.509804%,89.411765%);fill-opacity:1;"
      d="M 357.488281 245.234375 L 359.011719 246.757812 Z M 357.488281 245.234375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 404.691406 245.234375 L 406.214844 246.757812 Z M 404.691406 245.234375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 406.214844 245.234375 L 407.734375 246.757812 Z M 406.214844 245.234375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.215686%,67.058824%,20%);fill-opacity:1;"
      d="M 410.78125 245.234375 L 409.257812 248.28125 Z M 410.78125 245.234375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,71.764706%,33.333333%);fill-opacity:1;"
      d="M 412.304688 245.234375 L 413.828125 246.757812 Z M 412.304688 245.234375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 430.574219 245.234375 L 432.097656 246.757812 Z M 430.574219 245.234375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,81.568627%,60%);fill-opacity:1;"
      d="M 432.097656 245.234375 L 433.621094 246.757812 Z M 432.097656 245.234375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(32.54902%,32.156863%,48.627451%);fill-opacity:1;"
      d="M 447.324219 245.234375 L 448.847656 246.757812 Z M 447.324219 245.234375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(50.196078%,50.196078%,40.392157%);fill-opacity:1;"
      d="M 342.765625 247.777344 L 343.273438 248.792969 Z M 342.765625 247.777344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 359.011719 246.757812 L 360.535156 248.28125 L 359.011719 246.757812 M 363.578125 246.757812 L 365.101562 248.28125 Z M 363.578125 246.757812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 380.328125 246.757812 L 381.851562 248.28125 Z M 380.328125 246.757812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,89.019608%,78.823529%);fill-opacity:1;"
      d="M 404.691406 246.757812 L 406.214844 248.28125 Z M 404.691406 246.757812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 410.78125 246.757812 L 412.304688 248.28125 Z M 410.78125 246.757812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 413.828125 246.757812 L 415.347656 248.28125 Z M 413.828125 246.757812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,71.764706%,33.333333%);fill-opacity:1;"
      d="M 433.621094 246.757812 L 435.144531 248.28125 Z M 433.621094 246.757812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 435.144531 246.757812 L 438.1875 249.800781 Z M 435.144531 246.757812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(89.803922%,89.803922%,61.568627%);fill-opacity:1;"
      d="M 443.257812 247.777344 L 443.769531 248.792969 Z M 443.257812 247.777344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(19.607843%,19.607843%,48.235294%);fill-opacity:1;"
      d="M 447.828125 247.777344 L 448.335938 248.792969 Z M 447.828125 247.777344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,71.764706%,33.333333%);fill-opacity:1;"
      d="M 359.511719 249.300781 L 360.023438 250.3125 Z M 359.511719 249.300781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 365.101562 248.28125 L 366.625 249.800781 Z M 365.101562 248.28125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,74.509804%,40%);fill-opacity:1;"
      d="M 380.328125 248.28125 L 381.851562 249.800781 Z M 380.328125 248.28125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 404.691406 248.28125 L 406.214844 249.800781 Z M 404.691406 248.28125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 407.734375 248.28125 L 409.257812 249.800781 Z M 407.734375 248.28125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,94.509804%,89.411765%);fill-opacity:1;"
      d="M 409.257812 248.28125 L 410.78125 249.800781 Z M 409.257812 248.28125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 413.828125 248.28125 L 415.347656 249.800781 Z M 413.828125 248.28125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.215686%,67.058824%,20%);fill-opacity:1;"
      d="M 435.144531 248.28125 L 436.667969 249.800781 Z M 435.144531 248.28125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(40%,40%,46.666667%);fill-opacity:1;"
      d="M 342.261719 249.800781 L 343.785156 251.324219 Z M 342.261719 249.800781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(96.470588%,96.470588%,89.411765%);fill-opacity:1;"
      d="M 346.828125 249.800781 L 348.351562 251.324219 Z M 346.828125 249.800781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,83.921569%,66.666667%);fill-opacity:1;"
      d="M 365.101562 249.800781 L 366.625 251.324219 Z M 365.101562 249.800781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.215686%,67.058824%,20%);fill-opacity:1;"
      d="M 377.28125 249.800781 L 378.804688 251.324219 Z M 377.28125 249.800781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 378.804688 249.800781 L 380.328125 251.324219 Z M 378.804688 249.800781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,94.509804%,89.411765%);fill-opacity:1;"
      d="M 380.328125 249.800781 L 381.851562 251.324219 Z M 380.328125 249.800781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 406.214844 249.800781 L 407.734375 251.324219 Z M 406.214844 249.800781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,79.215686%,53.333333%);fill-opacity:1;"
      d="M 407.734375 249.800781 L 409.257812 251.324219 L 407.734375 249.800781 M 415.347656 249.800781 L 416.871094 251.324219 Z M 415.347656 249.800781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 436.667969 249.800781 L 438.1875 251.324219 Z M 436.667969 249.800781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(86.666667%,86.27451%,47.843137%);fill-opacity:1;"
      d="M 442.757812 249.800781 L 444.28125 251.324219 Z M 442.757812 249.800781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(34.509804%,34.509804%,48.235294%);fill-opacity:1;"
      d="M 342.261719 251.324219 L 343.785156 252.847656 Z M 342.261719 251.324219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(94.901961%,94.509804%,82.352941%);fill-opacity:1;"
      d="M 346.828125 251.324219 L 348.351562 252.847656 Z M 346.828125 251.324219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 359.511719 252.34375 L 360.023438 253.359375 Z M 359.511719 252.34375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 365.101562 251.324219 L 366.625 252.847656 Z M 365.101562 251.324219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 380.328125 251.324219 L 381.851562 252.847656 Z M 380.328125 251.324219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 381.851562 251.324219 L 383.375 252.847656 Z M 381.851562 251.324219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 384.894531 251.324219 L 386.417969 252.847656 Z M 384.894531 251.324219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 386.417969 251.324219 L 387.941406 252.847656 Z M 386.417969 251.324219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.254902%,86.27451%,73.333333%);fill-opacity:1;"
      d="M 406.214844 251.324219 L 407.734375 252.847656 Z M 406.214844 251.324219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,94.509804%,89.411765%);fill-opacity:1;"
      d="M 407.734375 251.324219 L 409.257812 252.847656 Z M 407.734375 251.324219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 415.347656 251.324219 L 416.871094 252.847656 Z M 415.347656 251.324219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 416.871094 251.324219 L 418.394531 252.847656 Z M 416.871094 251.324219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 438.1875 251.324219 L 439.710938 252.847656 Z M 438.1875 251.324219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(85.098039%,84.705882%,40.784314%);fill-opacity:1;"
      d="M 442.757812 251.324219 L 444.28125 252.847656 Z M 442.757812 251.324219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(22.745098%,22.745098%,48.627451%);fill-opacity:1;"
      d="M 342.261719 252.847656 L 343.785156 254.371094 Z M 342.261719 252.847656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(89.803922%,89.019608%,68.627451%);fill-opacity:1;"
      d="M 346.828125 252.847656 L 348.351562 254.371094 Z M 346.828125 252.847656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,79.215686%,53.333333%);fill-opacity:1;"
      d="M 366.625 252.847656 L 368.148438 254.371094 Z M 366.625 252.847656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 381.851562 252.847656 L 383.375 254.371094 Z M 381.851562 252.847656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 386.417969 252.847656 L 387.941406 254.371094 Z M 386.417969 252.847656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,94.509804%,89.411765%);fill-opacity:1;"
      d="M 387.941406 252.847656 L 389.464844 254.371094 Z M 387.941406 252.847656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 404.691406 252.847656 L 406.214844 254.371094 Z M 404.691406 252.847656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,89.019608%,78.823529%);fill-opacity:1;"
      d="M 406.214844 252.847656 L 407.734375 254.371094 Z M 406.214844 252.847656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 407.734375 252.847656 L 409.257812 254.371094 Z M 407.734375 252.847656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,74.509804%,40%);fill-opacity:1;"
      d="M 416.871094 252.847656 L 418.394531 254.371094 L 416.871094 252.847656 M 429.054688 252.847656 L 430.574219 254.371094 Z M 429.054688 252.847656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,94.509804%,89.411765%);fill-opacity:1;"
      d="M 430.574219 252.847656 L 432.097656 254.371094 Z M 430.574219 252.847656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 432.097656 252.847656 L 433.621094 254.371094 Z M 432.097656 252.847656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.254902%,86.27451%,73.333333%);fill-opacity:1;"
      d="M 433.621094 252.847656 L 435.144531 254.371094 Z M 433.621094 252.847656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,71.764706%,33.333333%);fill-opacity:1;"
      d="M 435.144531 252.847656 L 436.667969 254.371094 Z M 435.144531 252.847656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,89.019608%,78.823529%);fill-opacity:1;"
      d="M 438.1875 252.847656 L 439.710938 254.371094 Z M 438.1875 252.847656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(50.196078%,50.196078%,40.392157%);fill-opacity:1;"
      d="M 445.800781 252.847656 L 447.324219 254.371094 Z M 445.800781 252.847656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(19.607843%,19.607843%,48.235294%);fill-opacity:1;"
      d="M 342.261719 254.371094 L 343.785156 255.894531 Z M 342.261719 254.371094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 344.285156 255.390625 L 344.796875 256.40625 Z M 344.285156 255.390625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(89.803922%,89.803922%,61.568627%);fill-opacity:1;"
      d="M 346.828125 254.371094 L 348.351562 255.894531 Z M 346.828125 254.371094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 359.011719 254.371094 L 360.535156 255.894531 Z M 359.011719 254.371094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,83.921569%,66.666667%);fill-opacity:1;"
      d="M 368.148438 254.371094 L 369.667969 255.894531 Z M 368.148438 254.371094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,74.509804%,40%);fill-opacity:1;"
      d="M 383.375 254.371094 L 384.894531 255.894531 Z M 383.375 254.371094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,81.568627%,60%);fill-opacity:1;"
      d="M 387.941406 254.371094 L 389.464844 255.894531 Z M 387.941406 254.371094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,89.019608%,78.823529%);fill-opacity:1;"
      d="M 401.644531 254.371094 L 403.167969 255.894531 Z M 401.644531 254.371094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 403.167969 254.371094 L 404.691406 255.894531 Z M 403.167969 254.371094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,89.019608%,78.823529%);fill-opacity:1;"
      d="M 409.257812 254.371094 L 410.78125 255.894531 Z M 409.257812 254.371094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.254902%,86.27451%,73.333333%);fill-opacity:1;"
      d="M 416.871094 254.371094 L 418.394531 255.894531 Z M 416.871094 254.371094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,81.568627%,60%);fill-opacity:1;"
      d="M 429.054688 254.371094 L 430.574219 255.894531 Z M 429.054688 254.371094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 436.667969 254.371094 L 438.1875 255.894531 Z M 436.667969 254.371094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 438.1875 254.371094 L 439.710938 255.894531 Z M 438.1875 254.371094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(45.098039%,45.098039%,43.921569%);fill-opacity:1;"
      d="M 445.800781 254.371094 L 447.324219 255.894531 Z M 445.800781 254.371094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(85.098039%,84.705882%,40.784314%);fill-opacity:1;"
      d="M 346.828125 255.894531 L 348.351562 257.414062 Z M 346.828125 255.894531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,81.568627%,60%);fill-opacity:1;"
      d="M 359.011719 255.894531 L 360.535156 257.414062 Z M 359.011719 255.894531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,83.921569%,66.666667%);fill-opacity:1;"
      d="M 369.667969 255.894531 L 371.191406 257.414062 L 369.667969 255.894531 M 383.375 255.894531 L 384.894531 257.414062 Z M 383.375 255.894531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 387.941406 255.894531 L 389.464844 257.414062 Z M 387.941406 255.894531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 395.554688 255.894531 L 397.078125 257.414062 Z M 395.554688 255.894531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.254902%,86.27451%,73.333333%);fill-opacity:1;"
      d="M 397.078125 255.894531 L 398.601562 257.414062 Z M 397.078125 255.894531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 398.601562 255.894531 L 400.121094 257.414062 Z M 398.601562 255.894531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 400.121094 255.894531 L 401.644531 257.414062 Z M 400.121094 255.894531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 409.257812 255.894531 L 410.78125 257.414062 Z M 409.257812 255.894531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(94.901961%,94.509804%,82.352941%);fill-opacity:1;"
      d="M 441.234375 255.894531 L 442.757812 257.414062 Z M 441.234375 255.894531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(34.509804%,34.509804%,48.235294%);fill-opacity:1;"
      d="M 445.800781 255.894531 L 447.324219 257.414062 Z M 445.800781 255.894531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(60%,60%,30.588235%);fill-opacity:1;"
      d="M 343.785156 257.414062 L 345.308594 258.9375 Z M 343.785156 257.414062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(81.568627%,81.568627%,27.058824%);fill-opacity:1;"
      d="M 346.828125 257.414062 L 348.351562 258.9375 Z M 346.828125 257.414062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 371.191406 257.414062 L 372.714844 258.9375 Z M 371.191406 257.414062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,89.019608%,78.823529%);fill-opacity:1;"
      d="M 372.714844 257.414062 L 374.238281 258.9375 Z M 372.714844 257.414062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 383.375 257.414062 L 384.894531 258.9375 Z M 383.375 257.414062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,71.764706%,33.333333%);fill-opacity:1;"
      d="M 389.464844 257.414062 L 390.988281 258.9375 Z M 389.464844 257.414062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 390.988281 257.414062 L 392.507812 258.9375 Z M 390.988281 257.414062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 392.507812 257.414062 L 394.03125 258.9375 Z M 392.507812 257.414062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 394.03125 257.414062 L 395.554688 258.9375 Z M 394.03125 257.414062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 406.214844 257.414062 L 407.734375 258.9375 Z M 406.214844 257.414062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,83.921569%,66.666667%);fill-opacity:1;"
      d="M 407.734375 257.414062 L 409.257812 258.9375 Z M 407.734375 257.414062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 429.054688 257.414062 L 430.574219 258.9375 Z M 429.054688 257.414062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(87.843137%,87.058824%,63.137255%);fill-opacity:1;"
      d="M 441.234375 257.414062 L 442.757812 258.9375 Z M 441.234375 257.414062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(22.745098%,22.745098%,48.627451%);fill-opacity:1;"
      d="M 445.800781 257.414062 L 447.324219 258.9375 Z M 445.800781 257.414062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(45.098039%,45.098039%,43.921569%);fill-opacity:1;"
      d="M 343.785156 258.9375 L 345.308594 260.460938 Z M 343.785156 258.9375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,98.039216%,94.901961%);fill-opacity:1;"
      d="M 348.351562 258.9375 L 349.875 260.460938 Z M 348.351562 258.9375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 360.535156 258.9375 L 362.054688 260.460938 Z M 360.535156 258.9375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 374.238281 258.9375 L 375.761719 260.460938 Z M 374.238281 258.9375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 375.761719 258.9375 L 377.28125 260.460938 Z M 375.761719 258.9375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 377.28125 258.9375 L 378.804688 260.460938 Z M 377.28125 258.9375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,79.215686%,53.333333%);fill-opacity:1;"
      d="M 378.804688 258.9375 L 380.328125 260.460938 Z M 378.804688 258.9375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 380.328125 258.9375 L 381.851562 260.460938 Z M 380.328125 258.9375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 381.851562 258.9375 L 383.375 260.460938 Z M 381.851562 258.9375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.215686%,67.058824%,20%);fill-opacity:1;"
      d="M 383.375 258.9375 L 384.894531 260.460938 Z M 383.375 258.9375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 404.691406 258.9375 L 406.214844 260.460938 Z M 404.691406 258.9375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 406.214844 258.9375 L 407.734375 260.460938 Z M 406.214844 258.9375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.215686%,67.058824%,20%);fill-opacity:1;"
      d="M 427.53125 258.9375 L 429.054688 260.460938 Z M 427.53125 258.9375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(88.235294%,88.235294%,54.901961%);fill-opacity:1;"
      d="M 441.234375 258.9375 L 442.757812 260.460938 Z M 441.234375 258.9375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 444.78125 259.957031 L 445.289062 260.972656 Z M 444.78125 259.957031 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(14.901961%,14.901961%,47.058824%);fill-opacity:1;"
      d="M 445.800781 258.9375 L 447.324219 260.460938 Z M 445.800781 258.9375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(34.509804%,34.509804%,48.235294%);fill-opacity:1;"
      d="M 343.785156 260.460938 L 345.308594 261.984375 Z M 343.785156 260.460938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(94.901961%,94.509804%,82.352941%);fill-opacity:1;"
      d="M 348.351562 260.460938 L 349.875 261.984375 Z M 348.351562 260.460938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,79.215686%,53.333333%);fill-opacity:1;"
      d="M 360.535156 260.460938 L 362.054688 261.984375 Z M 360.535156 260.460938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 401.644531 260.460938 L 403.167969 261.984375 Z M 401.644531 260.460938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 403.167969 260.460938 L 404.691406 261.984375 Z M 403.167969 260.460938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 416.871094 260.460938 L 418.394531 261.984375 Z M 416.871094 260.460938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,79.215686%,53.333333%);fill-opacity:1;"
      d="M 427.53125 260.460938 L 429.054688 261.984375 Z M 427.53125 260.460938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.137255%,83.137255%,33.72549%);fill-opacity:1;"
      d="M 441.234375 260.460938 L 442.757812 261.984375 Z M 441.234375 260.460938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(19.607843%,19.607843%,48.235294%);fill-opacity:1;"
      d="M 343.785156 261.984375 L 345.308594 263.507812 Z M 343.785156 261.984375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(89.803922%,89.803922%,61.568627%);fill-opacity:1;"
      d="M 348.351562 261.984375 L 349.875 263.507812 Z M 348.351562 261.984375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 360.535156 261.984375 L 362.054688 263.507812 Z M 360.535156 261.984375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 362.054688 261.984375 L 363.578125 263.507812 Z M 362.054688 261.984375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 401.644531 261.984375 L 403.167969 263.507812 Z M 401.644531 261.984375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 416.871094 261.984375 L 418.394531 263.507812 Z M 416.871094 261.984375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,94.509804%,89.411765%);fill-opacity:1;"
      d="M 427.53125 261.984375 L 429.054688 263.507812 Z M 427.53125 261.984375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(50.196078%,50.196078%,40.392157%);fill-opacity:1;"
      d="M 444.28125 261.984375 L 445.800781 263.507812 Z M 444.28125 261.984375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.490196%,5.490196%,43.137255%);fill-opacity:1;"
      d="M 343.785156 263.507812 L 345.308594 265.027344 Z M 343.785156 263.507812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 345.308594 263.507812 L 346.828125 265.027344 Z M 345.308594 263.507812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(85.098039%,84.705882%,40.784314%);fill-opacity:1;"
      d="M 348.351562 263.507812 L 349.875 265.027344 Z M 348.351562 263.507812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.254902%,86.27451%,73.333333%);fill-opacity:1;"
      d="M 362.054688 263.507812 L 363.578125 265.027344 Z M 362.054688 263.507812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,83.921569%,66.666667%);fill-opacity:1;"
      d="M 415.347656 263.507812 L 416.871094 265.027344 Z M 415.347656 263.507812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,79.215686%,53.333333%);fill-opacity:1;"
      d="M 426.007812 263.507812 L 427.53125 265.027344 Z M 426.007812 263.507812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(94.901961%,94.509804%,82.352941%);fill-opacity:1;"
      d="M 439.710938 263.507812 L 441.234375 265.027344 Z M 439.710938 263.507812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(34.509804%,34.509804%,48.235294%);fill-opacity:1;"
      d="M 444.28125 263.507812 L 445.800781 265.027344 Z M 444.28125 263.507812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(55.294118%,55.294118%,35.686275%);fill-opacity:1;"
      d="M 345.308594 265.027344 L 346.828125 266.550781 Z M 345.308594 265.027344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,83.921569%,66.666667%);fill-opacity:1;"
      d="M 363.578125 265.027344 L 365.101562 266.550781 Z M 363.578125 265.027344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.215686%,67.058824%,20%);fill-opacity:1;"
      d="M 394.03125 265.027344 L 395.554688 266.550781 Z M 394.03125 265.027344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 395.554688 265.027344 L 397.078125 266.550781 Z M 395.554688 265.027344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 398.097656 265.53125 L 399.113281 266.039062 Z M 398.097656 265.53125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 413.828125 265.027344 L 412.304688 268.074219 Z M 413.828125 265.027344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 415.347656 265.027344 L 416.871094 266.550781 Z M 415.347656 265.027344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.215686%,67.058824%,20%);fill-opacity:1;"
      d="M 424.484375 265.027344 L 422.960938 268.074219 Z M 424.484375 265.027344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(89.803922%,89.803922%,61.568627%);fill-opacity:1;"
      d="M 439.710938 265.027344 L 441.234375 266.550781 Z M 439.710938 265.027344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(22.745098%,22.745098%,48.627451%);fill-opacity:1;"
      d="M 444.28125 265.027344 L 445.800781 266.550781 Z M 444.28125 265.027344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(40%,40%,46.666667%);fill-opacity:1;"
      d="M 345.308594 266.550781 L 346.828125 268.074219 Z M 345.308594 266.550781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(94.901961%,94.509804%,82.352941%);fill-opacity:1;"
      d="M 349.875 266.550781 L 351.398438 268.074219 Z M 349.875 266.550781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,83.921569%,66.666667%);fill-opacity:1;"
      d="M 365.101562 266.550781 L 366.625 268.074219 Z M 365.101562 266.550781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 394.03125 266.550781 L 395.554688 268.074219 Z M 394.03125 266.550781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,79.215686%,53.333333%);fill-opacity:1;"
      d="M 395.554688 266.550781 L 397.078125 268.074219 Z M 395.554688 266.550781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 397.078125 266.550781 L 398.601562 268.074219 Z M 397.078125 266.550781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,94.509804%,89.411765%);fill-opacity:1;"
      d="M 398.601562 266.550781 L 400.121094 268.074219 Z M 398.601562 266.550781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.215686%,67.058824%,20%);fill-opacity:1;"
      d="M 413.828125 266.550781 L 415.347656 268.074219 Z M 413.828125 266.550781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 424.484375 266.550781 L 426.007812 268.074219 Z M 424.484375 266.550781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(85.098039%,84.705882%,40.784314%);fill-opacity:1;"
      d="M 439.710938 266.550781 L 441.234375 268.074219 Z M 439.710938 266.550781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 442.757812 266.550781 L 444.28125 268.074219 Z M 442.757812 266.550781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.490196%,5.490196%,43.137255%);fill-opacity:1;"
      d="M 444.28125 266.550781 L 445.800781 268.074219 Z M 444.28125 266.550781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(22.745098%,22.745098%,48.627451%);fill-opacity:1;"
      d="M 345.308594 268.074219 L 346.828125 269.597656 Z M 345.308594 268.074219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(89.803922%,89.803922%,61.568627%);fill-opacity:1;"
      d="M 349.875 268.074219 L 351.398438 269.597656 Z M 349.875 268.074219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,89.019608%,78.823529%);fill-opacity:1;"
      d="M 366.625 268.074219 L 369.667969 271.121094 Z M 366.625 268.074219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 368.148438 268.074219 L 369.667969 269.597656 Z M 368.148438 268.074219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.254902%,86.27451%,73.333333%);fill-opacity:1;"
      d="M 397.078125 268.074219 L 398.601562 269.597656 Z M 397.078125 268.074219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,94.509804%,89.411765%);fill-opacity:1;"
      d="M 410.78125 268.074219 L 412.304688 269.597656 Z M 410.78125 268.074219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.215686%,67.058824%,20%);fill-opacity:1;"
      d="M 412.304688 268.074219 L 413.828125 269.597656 Z M 412.304688 268.074219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 421.441406 268.074219 L 422.960938 269.597656 Z M 421.441406 268.074219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 422.960938 268.074219 L 424.484375 269.597656 Z M 422.960938 268.074219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,98.039216%,94.901961%);fill-opacity:1;"
      d="M 438.1875 268.074219 L 439.710938 269.597656 Z M 438.1875 268.074219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(55.294118%,55.294118%,35.686275%);fill-opacity:1;"
      d="M 442.757812 268.074219 L 444.28125 269.597656 Z M 442.757812 268.074219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.490196%,5.490196%,43.137255%);fill-opacity:1;"
      d="M 345.308594 269.597656 L 346.828125 271.121094 Z M 345.308594 269.597656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 346.828125 269.597656 L 348.351562 271.121094 Z M 346.828125 269.597656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.137255%,83.137255%,33.72549%);fill-opacity:1;"
      d="M 349.875 269.597656 L 351.398438 271.121094 Z M 349.875 269.597656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,83.921569%,66.666667%);fill-opacity:1;"
      d="M 395.554688 269.597656 L 397.078125 271.121094 Z M 395.554688 269.597656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,81.568627%,60%);fill-opacity:1;"
      d="M 409.257812 269.597656 L 410.78125 271.121094 Z M 409.257812 269.597656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 410.78125 269.597656 L 412.304688 271.121094 Z M 410.78125 269.597656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,79.215686%,53.333333%);fill-opacity:1;"
      d="M 419.917969 269.597656 L 421.441406 271.121094 Z M 419.917969 269.597656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(93.333333%,92.941176%,75.686275%);fill-opacity:1;"
      d="M 438.1875 269.597656 L 439.710938 271.121094 Z M 438.1875 269.597656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(34.509804%,34.509804%,48.235294%);fill-opacity:1;"
      d="M 442.757812 269.597656 L 444.28125 271.121094 Z M 442.757812 269.597656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(45.098039%,45.098039%,43.921569%);fill-opacity:1;"
      d="M 346.828125 271.121094 L 348.351562 272.640625 Z M 346.828125 271.121094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(96.470588%,96.470588%,89.411765%);fill-opacity:1;"
      d="M 351.398438 271.121094 L 352.921875 272.640625 Z M 351.398438 271.121094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,74.509804%,40%);fill-opacity:1;"
      d="M 368.148438 271.121094 L 369.667969 272.640625 Z M 368.148438 271.121094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 392.507812 271.121094 L 394.03125 272.640625 Z M 392.507812 271.121094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.254902%,86.27451%,73.333333%);fill-opacity:1;"
      d="M 394.535156 272.140625 L 395.042969 273.152344 Z M 394.535156 272.140625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,74.509804%,40%);fill-opacity:1;"
      d="M 406.214844 271.121094 L 407.734375 272.640625 Z M 406.214844 271.121094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 407.734375 271.121094 L 409.257812 272.640625 Z M 407.734375 271.121094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 416.871094 271.121094 L 418.394531 272.640625 Z M 416.871094 271.121094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,94.509804%,89.411765%);fill-opacity:1;"
      d="M 418.394531 271.121094 L 419.917969 272.640625 Z M 418.394531 271.121094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(82.745098%,81.568627%,47.45098%);fill-opacity:1;"
      d="M 438.1875 271.121094 L 439.710938 272.640625 Z M 438.1875 271.121094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 441.234375 271.121094 L 442.757812 272.640625 Z M 441.234375 271.121094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(14.901961%,14.901961%,47.058824%);fill-opacity:1;"
      d="M 442.757812 271.121094 L 444.28125 272.640625 Z M 442.757812 271.121094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(28.627451%,28.627451%,49.019608%);fill-opacity:1;"
      d="M 346.828125 272.640625 L 348.351562 274.164062 Z M 346.828125 272.640625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(87.843137%,87.058824%,63.137255%);fill-opacity:1;"
      d="M 351.398438 272.640625 L 352.921875 274.164062 Z M 351.398438 272.640625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,89.019608%,78.823529%);fill-opacity:1;"
      d="M 366.625 272.640625 L 368.148438 274.164062 Z M 366.625 272.640625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.215686%,67.058824%,20%);fill-opacity:1;"
      d="M 387.941406 272.640625 L 389.464844 274.164062 Z M 387.941406 272.640625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 389.464844 272.640625 L 390.988281 274.164062 Z M 389.464844 272.640625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 390.988281 272.640625 L 392.507812 274.164062 Z M 390.988281 272.640625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 395.554688 272.640625 L 397.078125 274.164062 Z M 395.554688 272.640625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,83.921569%,66.666667%);fill-opacity:1;"
      d="M 415.347656 272.640625 L 416.871094 274.164062 Z M 415.347656 272.640625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(60%,60%,30.588235%);fill-opacity:1;"
      d="M 441.234375 272.640625 L 442.757812 274.164062 Z M 441.234375 272.640625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.490196%,5.490196%,43.137255%);fill-opacity:1;"
      d="M 346.828125 274.164062 L 348.351562 275.6875 Z M 346.828125 274.164062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 348.351562 274.164062 L 349.875 275.6875 Z M 348.351562 274.164062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(83.137255%,83.137255%,33.72549%);fill-opacity:1;"
      d="M 351.398438 274.164062 L 352.921875 275.6875 Z M 351.398438 274.164062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,81.568627%,60%);fill-opacity:1;"
      d="M 365.101562 274.164062 L 366.625 275.6875 Z M 365.101562 274.164062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 366.625 274.164062 L 368.148438 275.6875 L 366.625 274.164062 M 374.238281 274.164062 L 375.761719 275.6875 Z M 374.238281 274.164062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,83.921569%,66.666667%);fill-opacity:1;"
      d="M 375.761719 274.164062 L 377.28125 275.6875 Z M 375.761719 274.164062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,81.568627%,60%);fill-opacity:1;"
      d="M 377.28125 274.164062 L 378.804688 275.6875 Z M 377.28125 274.164062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,83.921569%,66.666667%);fill-opacity:1;"
      d="M 383.375 274.164062 L 384.894531 275.6875 Z M 383.375 274.164062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,89.019608%,78.823529%);fill-opacity:1;"
      d="M 384.894531 274.164062 L 386.417969 275.6875 Z M 384.894531 274.164062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 386.417969 274.164062 L 387.941406 275.6875 Z M 386.417969 274.164062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 398.601562 274.164062 L 400.121094 275.6875 Z M 398.601562 274.164062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,89.019608%,78.823529%);fill-opacity:1;"
      d="M 400.121094 274.164062 L 401.644531 275.6875 Z M 400.121094 274.164062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,79.215686%,53.333333%);fill-opacity:1;"
      d="M 401.644531 274.164062 L 403.167969 275.6875 Z M 401.644531 274.164062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,76.862745%,46.666667%);fill-opacity:1;"
      d="M 403.167969 274.164062 L 404.691406 275.6875 Z M 403.167969 274.164062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.215686%,67.058824%,20%);fill-opacity:1;"
      d="M 404.691406 274.164062 L 406.214844 275.6875 Z M 404.691406 274.164062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 412.304688 274.164062 L 413.828125 275.6875 Z M 412.304688 274.164062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,83.921569%,66.666667%);fill-opacity:1;"
      d="M 413.828125 274.164062 L 415.347656 275.6875 Z M 413.828125 274.164062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(89.803922%,89.019608%,68.627451%);fill-opacity:1;"
      d="M 436.667969 274.164062 L 438.1875 275.6875 Z M 436.667969 274.164062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(40%,40%,46.666667%);fill-opacity:1;"
      d="M 441.234375 274.164062 L 442.757812 275.6875 Z M 441.234375 274.164062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(45.098039%,45.098039%,43.921569%);fill-opacity:1;"
      d="M 348.351562 275.6875 L 349.875 277.210938 Z M 348.351562 275.6875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(94.901961%,94.509804%,84.313725%);fill-opacity:1;"
      d="M 352.921875 275.6875 L 354.441406 277.210938 Z M 352.921875 275.6875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 363.578125 275.6875 L 365.101562 277.210938 Z M 363.578125 275.6875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 371.191406 275.6875 L 372.714844 277.210938 Z M 371.191406 275.6875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,74.509804%,40%);fill-opacity:1;"
      d="M 372.714844 275.6875 L 374.238281 277.210938 Z M 372.714844 275.6875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,94.509804%,89.411765%);fill-opacity:1;"
      d="M 374.238281 275.6875 L 375.761719 277.210938 Z M 374.238281 275.6875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,64.705882%,13.333333%);fill-opacity:1;"
      d="M 410.78125 275.6875 L 412.304688 277.210938 Z M 410.78125 275.6875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 412.304688 275.6875 L 413.828125 277.210938 Z M 412.304688 275.6875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(86.666667%,86.27451%,47.843137%);fill-opacity:1;"
      d="M 436.667969 275.6875 L 438.1875 277.210938 Z M 436.667969 275.6875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 439.710938 275.6875 L 441.234375 277.210938 Z M 439.710938 275.6875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(14.901961%,14.901961%,47.058824%);fill-opacity:1;"
      d="M 441.234375 275.6875 L 442.757812 277.210938 Z M 441.234375 275.6875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(28.627451%,28.627451%,49.019608%);fill-opacity:1;"
      d="M 348.351562 277.210938 L 349.875 278.734375 Z M 348.351562 277.210938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 350.378906 278.230469 L 350.886719 279.246094 Z M 350.378906 278.230469 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(82.745098%,81.568627%,47.45098%);fill-opacity:1;"
      d="M 352.921875 277.210938 L 354.441406 278.734375 Z M 352.921875 277.210938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,81.568627%,60%);fill-opacity:1;"
      d="M 365.101562 277.210938 L 366.625 278.734375 Z M 365.101562 277.210938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,69.411765%,26.666667%);fill-opacity:1;"
      d="M 366.625 277.210938 L 368.148438 278.734375 Z M 366.625 277.210938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,79.215686%,53.333333%);fill-opacity:1;"
      d="M 368.148438 277.210938 L 369.667969 278.734375 Z M 368.148438 277.210938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.254902%,86.27451%,73.333333%);fill-opacity:1;"
      d="M 369.667969 277.210938 L 371.191406 278.734375 Z M 369.667969 277.210938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,97.254902%,94.509804%);fill-opacity:1;"
      d="M 371.191406 277.210938 L 372.714844 278.734375 Z M 371.191406 277.210938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.254902%,86.27451%,73.333333%);fill-opacity:1;"
      d="M 404.691406 277.210938 L 406.214844 278.734375 Z M 404.691406 277.210938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.823529%,94.509804%,89.411765%);fill-opacity:1;"
      d="M 410.78125 277.210938 L 412.304688 278.734375 Z M 410.78125 277.210938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(96.470588%,96.470588%,89.411765%);fill-opacity:1;"
      d="M 435.144531 277.210938 L 436.667969 278.734375 Z M 435.144531 277.210938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(55.294118%,55.294118%,35.686275%);fill-opacity:1;"
      d="M 439.710938 277.210938 L 441.234375 278.734375 Z M 439.710938 277.210938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,98.039216%,94.901961%);fill-opacity:1;"
      d="M 354.441406 278.734375 L 355.964844 280.253906 Z M 354.441406 278.734375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,74.509804%,40%);fill-opacity:1;"
      d="M 404.691406 278.734375 L 406.214844 280.253906 Z M 404.691406 278.734375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,79.215686%,53.333333%);fill-opacity:1;"
      d="M 409.257812 278.734375 L 410.78125 280.253906 Z M 409.257812 278.734375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(88.235294%,88.235294%,54.901961%);fill-opacity:1;"
      d="M 435.144531 278.734375 L 436.667969 280.253906 Z M 435.144531 278.734375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(28.627451%,28.627451%,49.019608%);fill-opacity:1;"
      d="M 439.710938 278.734375 L 441.234375 280.253906 Z M 439.710938 278.734375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(34.509804%,34.509804%,48.235294%);fill-opacity:1;"
      d="M 349.875 280.253906 L 351.398438 281.777344 Z M 349.875 280.253906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(89.803922%,89.803922%,61.568627%);fill-opacity:1;"
      d="M 354.441406 280.253906 L 355.964844 281.777344 Z M 354.441406 280.253906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 404.691406 280.253906 L 406.214844 281.777344 Z M 404.691406 280.253906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.215686%,67.058824%,20%);fill-opacity:1;"
      d="M 407.734375 280.253906 L 409.257812 281.777344 Z M 407.734375 280.253906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,98.039216%,94.901961%);fill-opacity:1;"
      d="M 433.621094 280.253906 L 435.144531 281.777344 Z M 433.621094 280.253906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 438.1875 280.253906 L 439.710938 281.777344 Z M 438.1875 280.253906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.490196%,5.490196%,43.137255%);fill-opacity:1;"
      d="M 439.710938 280.253906 L 441.234375 281.777344 Z M 439.710938 280.253906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(10.588235%,10.588235%,45.490196%);fill-opacity:1;"
      d="M 349.875 281.777344 L 351.398438 283.300781 Z M 349.875 281.777344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 351.398438 281.777344 L 352.921875 283.300781 Z M 351.398438 281.777344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(81.568627%,81.568627%,27.058824%);fill-opacity:1;"
      d="M 354.441406 281.777344 L 355.964844 283.300781 Z M 354.441406 281.777344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 403.167969 281.777344 L 404.691406 283.300781 Z M 403.167969 281.777344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.607843%,62.352941%,6.666667%);fill-opacity:1;"
      d="M 406.214844 281.777344 L 407.734375 283.300781 Z M 406.214844 281.777344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,91.764706%,83.921569%);fill-opacity:1;"
      d="M 407.734375 281.777344 L 409.257812 283.300781 Z M 407.734375 281.777344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(89.803922%,89.803922%,61.568627%);fill-opacity:1;"
      d="M 433.621094 281.777344 L 435.144531 283.300781 Z M 433.621094 281.777344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(40%,40%,46.666667%);fill-opacity:1;"
      d="M 438.1875 281.777344 L 439.710938 283.300781 Z M 438.1875 281.777344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(43.137255%,42.352941%,43.921569%);fill-opacity:1;"
      d="M 351.398438 283.300781 L 352.921875 284.824219 Z M 351.398438 283.300781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(89.803922%,89.019608%,68.627451%);fill-opacity:1;"
      d="M 355.964844 283.300781 L 357.488281 284.824219 Z M 355.964844 283.300781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,79.215686%,53.333333%);fill-opacity:1;"
      d="M 403.671875 284.320312 L 404.179688 285.335938 Z M 403.671875 284.320312 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.039216%,89.019608%,78.823529%);fill-opacity:1;"
      d="M 406.214844 283.300781 L 407.734375 284.824219 Z M 406.214844 283.300781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,98.039216%,94.901961%);fill-opacity:1;"
      d="M 432.097656 283.300781 L 433.621094 284.824219 Z M 432.097656 283.300781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 436.667969 283.300781 L 438.1875 284.824219 Z M 436.667969 283.300781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(10.588235%,10.588235%,45.490196%);fill-opacity:1;"
      d="M 438.1875 283.300781 L 439.710938 284.824219 L 438.1875 283.300781 M 351.398438 284.824219 L 352.921875 286.347656 Z M 351.398438 284.824219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 352.921875 284.824219 L 354.441406 286.347656 Z M 352.921875 284.824219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(81.568627%,81.568627%,27.058824%);fill-opacity:1;"
      d="M 355.964844 284.824219 L 357.488281 286.347656 Z M 355.964844 284.824219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(98.431373%,98.039216%,94.901961%);fill-opacity:1;"
      d="M 357.488281 284.824219 L 359.011719 286.347656 Z M 357.488281 284.824219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(97.647059%,83.921569%,66.666667%);fill-opacity:1;"
      d="M 404.691406 284.824219 L 406.214844 286.347656 Z M 404.691406 284.824219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(89.803922%,89.803922%,61.568627%);fill-opacity:1;"
      d="M 432.097656 284.824219 L 433.621094 286.347656 Z M 432.097656 284.824219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(43.137255%,42.352941%,43.921569%);fill-opacity:1;"
      d="M 436.667969 284.824219 L 438.1875 286.347656 L 436.667969 284.824219 M 352.921875 286.347656 L 354.441406 287.867188 Z M 352.921875 286.347656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(54.901961%,74.901961%,51.764706%);fill-opacity:1;"
      d="M 357.488281 286.347656 L 359.011719 287.867188 Z M 357.488281 286.347656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,80%,100%);fill-opacity:1;"
      d="M 358.144531 286.347656 C 363.429688 297.476562 382.136719 323.730469 396.421875 323.21875 C 410.296875 322.722656 425.96875 297.105469 432.097656 286.347656 Z M 358.144531 286.347656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 435.144531 286.347656 L 436.667969 287.867188 Z M 435.144531 286.347656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(10.588235%,10.588235%,45.490196%);fill-opacity:1;"
      d="M 436.667969 286.347656 L 438.1875 287.867188 L 436.667969 286.347656 M 352.921875 287.867188 L 354.441406 289.390625 Z M 352.921875 287.867188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 354.441406 287.867188 L 355.964844 289.390625 Z M 354.441406 287.867188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(67.843137%,70.196078%,20%);fill-opacity:1;"
      d="M 357.488281 287.867188 L 359.011719 289.390625 Z M 357.488281 287.867188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(10.196078%,77.254902%,70.980392%);fill-opacity:1;"
      d="M 359.011719 287.867188 L 360.535156 289.390625 Z M 359.011719 287.867188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(40.784314%,69.019608%,43.921569%);fill-opacity:1;"
      d="M 430.574219 287.867188 L 432.097656 289.390625 Z M 430.574219 287.867188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(40%,40%,46.666667%);fill-opacity:1;"
      d="M 435.144531 287.867188 L 436.667969 289.390625 Z M 435.144531 287.867188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(34.509804%,34.509804%,48.235294%);fill-opacity:1;"
      d="M 354.441406 289.390625 L 355.964844 290.914062 Z M 354.441406 289.390625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(49.803922%,69.411765%,36.078431%);fill-opacity:1;"
      d="M 359.011719 289.390625 L 360.535156 290.914062 Z M 359.011719 289.390625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(15.294118%,76.078431%,66.666667%);fill-opacity:1;"
      d="M 429.054688 289.390625 L 430.574219 290.914062 Z M 429.054688 289.390625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 433.621094 289.390625 L 432.097656 292.4375 Z M 433.621094 289.390625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.490196%,5.490196%,43.137255%);fill-opacity:1;"
      d="M 435.144531 289.390625 L 436.667969 290.914062 L 435.144531 289.390625 M 354.441406 290.914062 L 355.964844 292.4375 Z M 354.441406 290.914062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 355.964844 290.914062 L 359.011719 293.960938 Z M 355.964844 290.914062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(20.392157%,74.509804%,61.960784%);fill-opacity:1;"
      d="M 360.535156 290.914062 L 362.054688 292.4375 Z M 360.535156 290.914062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(58.823529%,69.803922%,27.843137%);fill-opacity:1;"
      d="M 429.054688 290.914062 L 430.574219 292.4375 Z M 429.054688 290.914062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(32.54902%,32.156863%,48.627451%);fill-opacity:1;"
      d="M 433.621094 290.914062 L 435.144531 292.4375 Z M 433.621094 290.914062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(22.745098%,22.745098%,48.627451%);fill-opacity:1;"
      d="M 355.964844 292.4375 L 357.488281 293.960938 Z M 355.964844 292.4375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.529412%,69.803922%,23.921569%);fill-opacity:1;"
      d="M 360.535156 292.4375 L 362.054688 293.960938 Z M 360.535156 292.4375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.098039%,78.823529%,75.686275%);fill-opacity:1;"
      d="M 362.054688 292.4375 L 363.578125 293.960938 Z M 362.054688 292.4375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(35.686275%,70.588235%,48.627451%);fill-opacity:1;"
      d="M 427.53125 292.4375 L 429.054688 293.960938 Z M 427.53125 292.4375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(55.294118%,55.294118%,35.686275%);fill-opacity:1;"
      d="M 432.097656 292.4375 L 433.621094 293.960938 Z M 432.097656 292.4375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(45.098039%,45.098039%,43.921569%);fill-opacity:1;"
      d="M 357.488281 293.960938 L 359.011719 295.480469 Z M 357.488281 293.960938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(45.490196%,69.411765%,40%);fill-opacity:1;"
      d="M 362.054688 293.960938 L 363.578125 295.480469 Z M 362.054688 293.960938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(15.294118%,76.078431%,66.666667%);fill-opacity:1;"
      d="M 426.007812 293.960938 L 427.53125 295.480469 Z M 426.007812 293.960938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 430.574219 293.960938 L 429.054688 297.003906 Z M 430.574219 293.960938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(14.901961%,14.901961%,47.058824%);fill-opacity:1;"
      d="M 432.097656 293.960938 L 433.621094 295.480469 Z M 432.097656 293.960938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.490196%,5.490196%,43.137255%);fill-opacity:1;"
      d="M 357.488281 295.480469 L 359.011719 297.003906 Z M 357.488281 295.480469 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 359.011719 295.480469 L 362.054688 298.527344 Z M 359.011719 295.480469 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(25.882353%,73.333333%,57.254902%);fill-opacity:1;"
      d="M 363.578125 295.480469 L 365.101562 297.003906 Z M 363.578125 295.480469 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.098039%,78.823529%,75.686275%);fill-opacity:1;"
      d="M 424.484375 295.480469 L 426.007812 297.003906 Z M 424.484375 295.480469 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(58.823529%,69.803922%,27.843137%);fill-opacity:1;"
      d="M 426.007812 295.480469 L 427.53125 297.003906 Z M 426.007812 295.480469 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(34.509804%,34.509804%,48.235294%);fill-opacity:1;"
      d="M 430.574219 295.480469 L 432.097656 297.003906 Z M 430.574219 295.480469 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(22.745098%,22.745098%,48.627451%);fill-opacity:1;"
      d="M 359.011719 297.003906 L 360.535156 298.527344 Z M 359.011719 297.003906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(67.843137%,70.196078%,20%);fill-opacity:1;"
      d="M 363.578125 297.003906 L 365.101562 298.527344 Z M 363.578125 297.003906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(15.294118%,76.078431%,66.666667%);fill-opacity:1;"
      d="M 365.101562 297.003906 L 366.625 298.527344 Z M 365.101562 297.003906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(45.490196%,69.411765%,40%);fill-opacity:1;"
      d="M 424.484375 297.003906 L 426.007812 298.527344 Z M 424.484375 297.003906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(55.294118%,55.294118%,35.686275%);fill-opacity:1;"
      d="M 429.054688 297.003906 L 430.574219 298.527344 Z M 429.054688 297.003906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(43.137255%,42.352941%,43.921569%);fill-opacity:1;"
      d="M 360.535156 298.527344 L 362.054688 300.050781 Z M 360.535156 298.527344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(58.823529%,69.803922%,27.843137%);fill-opacity:1;"
      d="M 365.101562 298.527344 L 366.625 300.050781 Z M 365.101562 298.527344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.098039%,78.823529%,75.686275%);fill-opacity:1;"
      d="M 366.625 298.527344 L 368.148438 300.050781 Z M 366.625 298.527344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(25.882353%,73.333333%,57.254902%);fill-opacity:1;"
      d="M 422.960938 298.527344 L 424.484375 300.050781 Z M 422.960938 298.527344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 427.53125 298.527344 L 424.484375 303.09375 Z M 427.53125 298.527344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(10.588235%,10.588235%,45.490196%);fill-opacity:1;"
      d="M 429.054688 298.527344 L 430.574219 300.050781 Z M 429.054688 298.527344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.490196%,5.490196%,43.137255%);fill-opacity:1;"
      d="M 360.535156 300.050781 L 362.054688 301.574219 Z M 360.535156 300.050781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(55.294118%,55.294118%,35.686275%);fill-opacity:1;"
      d="M 362.054688 300.050781 L 363.578125 301.574219 Z M 362.054688 300.050781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(49.803922%,69.411765%,36.078431%);fill-opacity:1;"
      d="M 366.625 300.050781 L 368.148438 301.574219 Z M 366.625 300.050781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(20.392157%,74.509804%,61.960784%);fill-opacity:1;"
      d="M 421.441406 300.050781 L 422.960938 301.574219 Z M 421.441406 300.050781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(22.745098%,22.745098%,48.627451%);fill-opacity:1;"
      d="M 427.53125 300.050781 L 429.054688 301.574219 Z M 427.53125 300.050781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(10.588235%,10.588235%,45.490196%);fill-opacity:1;"
      d="M 362.054688 301.574219 L 363.578125 303.09375 Z M 362.054688 301.574219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 363.578125 301.574219 L 380.328125 318.320312 Z M 363.578125 301.574219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(45.490196%,69.411765%,40%);fill-opacity:1;"
      d="M 368.148438 301.574219 L 369.667969 303.09375 Z M 368.148438 301.574219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(15.294118%,76.078431%,66.666667%);fill-opacity:1;"
      d="M 419.917969 301.574219 L 421.441406 303.09375 Z M 419.917969 301.574219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(67.843137%,70.196078%,20%);fill-opacity:1;"
      d="M 421.441406 301.574219 L 422.960938 303.09375 Z M 421.441406 301.574219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(40%,40%,46.666667%);fill-opacity:1;"
      d="M 426.007812 301.574219 L 427.53125 303.09375 Z M 426.007812 301.574219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(19.607843%,19.607843%,48.235294%);fill-opacity:1;"
      d="M 363.578125 303.09375 L 365.101562 304.617188 Z M 363.578125 303.09375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(25.882353%,73.333333%,57.254902%);fill-opacity:1;"
      d="M 369.667969 303.09375 L 371.191406 304.617188 Z M 369.667969 303.09375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.098039%,78.823529%,75.686275%);fill-opacity:1;"
      d="M 418.394531 303.09375 L 412.304688 310.707031 Z M 418.394531 303.09375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(67.843137%,70.196078%,20%);fill-opacity:1;"
      d="M 419.917969 303.09375 L 421.441406 304.617188 Z M 419.917969 303.09375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(45.098039%,45.098039%,43.921569%);fill-opacity:1;"
      d="M 424.484375 303.09375 L 426.007812 304.617188 Z M 424.484375 303.09375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(28.627451%,28.627451%,49.019608%);fill-opacity:1;"
      d="M 365.101562 304.617188 L 366.625 306.140625 Z M 365.101562 304.617188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(25.882353%,73.333333%,57.254902%);fill-opacity:1;"
      d="M 371.191406 304.617188 L 372.714844 306.140625 Z M 371.191406 304.617188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(58.823529%,69.803922%,27.843137%);fill-opacity:1;"
      d="M 418.394531 304.617188 L 419.917969 306.140625 Z M 418.394531 304.617188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(55.294118%,55.294118%,35.686275%);fill-opacity:1;"
      d="M 422.960938 304.617188 L 421.441406 307.664062 Z M 422.960938 304.617188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.490196%,5.490196%,43.137255%);fill-opacity:1;"
      d="M 424.484375 304.617188 L 426.007812 306.140625 Z M 424.484375 304.617188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(32.54902%,32.156863%,48.627451%);fill-opacity:1;"
      d="M 366.625 306.140625 L 368.148438 307.664062 Z M 366.625 306.140625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(25.882353%,73.333333%,57.254902%);fill-opacity:1;"
      d="M 372.714844 306.140625 L 374.238281 307.664062 Z M 372.714844 306.140625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(58.823529%,69.803922%,27.843137%);fill-opacity:1;"
      d="M 416.871094 306.140625 L 418.394531 307.664062 Z M 416.871094 306.140625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.490196%,5.490196%,43.137255%);fill-opacity:1;"
      d="M 422.960938 306.140625 L 424.484375 307.664062 Z M 422.960938 306.140625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(43.137255%,42.352941%,43.921569%);fill-opacity:1;"
      d="M 368.148438 307.664062 L 369.667969 309.1875 Z M 368.148438 307.664062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(25.882353%,73.333333%,57.254902%);fill-opacity:1;"
      d="M 374.238281 307.664062 L 375.761719 309.1875 Z M 374.238281 307.664062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(58.823529%,69.803922%,27.843137%);fill-opacity:1;"
      d="M 415.347656 307.664062 L 416.871094 309.1875 Z M 415.347656 307.664062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 419.917969 307.664062 L 416.871094 312.230469 Z M 419.917969 307.664062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(14.901961%,14.901961%,47.058824%);fill-opacity:1;"
      d="M 421.441406 307.664062 L 422.960938 309.1875 Z M 421.441406 307.664062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(43.137255%,42.352941%,43.921569%);fill-opacity:1;"
      d="M 369.667969 309.1875 L 371.191406 310.707031 Z M 369.667969 309.1875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(25.882353%,73.333333%,57.254902%);fill-opacity:1;"
      d="M 375.761719 309.1875 L 377.28125 310.707031 Z M 375.761719 309.1875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(58.823529%,69.803922%,27.843137%);fill-opacity:1;"
      d="M 413.828125 309.1875 L 415.347656 310.707031 Z M 413.828125 309.1875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(14.901961%,14.901961%,47.058824%);fill-opacity:1;"
      d="M 419.917969 309.1875 L 421.441406 310.707031 Z M 419.917969 309.1875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(43.137255%,42.352941%,43.921569%);fill-opacity:1;"
      d="M 371.191406 310.707031 L 372.714844 312.230469 Z M 371.191406 310.707031 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(40.784314%,69.019608%,43.921569%);fill-opacity:1;"
      d="M 377.28125 310.707031 L 378.804688 312.230469 Z M 377.28125 310.707031 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(15.294118%,76.078431%,66.666667%);fill-opacity:1;"
      d="M 410.78125 310.707031 L 412.304688 312.230469 Z M 410.78125 310.707031 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(67.843137%,70.196078%,20%);fill-opacity:1;"
      d="M 412.304688 310.707031 L 413.828125 312.230469 Z M 412.304688 310.707031 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(14.901961%,14.901961%,47.058824%);fill-opacity:1;"
      d="M 418.394531 310.707031 L 419.917969 312.230469 Z M 418.394531 310.707031 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(40%,40%,46.666667%);fill-opacity:1;"
      d="M 372.714844 312.230469 L 374.238281 313.753906 Z M 372.714844 312.230469 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(45.490196%,69.411765%,40%);fill-opacity:1;"
      d="M 378.804688 312.230469 L 380.328125 313.753906 Z M 378.804688 312.230469 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(20.392157%,74.509804%,61.960784%);fill-opacity:1;"
      d="M 409.257812 312.230469 L 410.78125 313.753906 Z M 409.257812 312.230469 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(67.843137%,70.196078%,20%);fill-opacity:1;"
      d="M 410.78125 312.230469 L 412.304688 313.753906 Z M 410.78125 312.230469 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(55.294118%,55.294118%,35.686275%);fill-opacity:1;"
      d="M 415.347656 312.230469 L 413.828125 315.277344 Z M 415.347656 312.230469 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(14.901961%,14.901961%,47.058824%);fill-opacity:1;"
      d="M 416.871094 312.230469 L 418.394531 313.753906 Z M 416.871094 312.230469 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(28.627451%,28.627451%,49.019608%);fill-opacity:1;"
      d="M 374.238281 313.753906 L 375.761719 315.277344 Z M 374.238281 313.753906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(58.823529%,69.803922%,27.843137%);fill-opacity:1;"
      d="M 380.328125 313.753906 L 381.851562 315.277344 Z M 380.328125 313.753906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.098039%,78.823529%,75.686275%);fill-opacity:1;"
      d="M 381.851562 313.753906 L 383.375 315.277344 Z M 381.851562 313.753906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(25.882353%,73.333333%,57.254902%);fill-opacity:1;"
      d="M 407.734375 313.753906 L 409.257812 315.277344 Z M 407.734375 313.753906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.490196%,5.490196%,43.137255%);fill-opacity:1;"
      d="M 415.347656 313.753906 L 416.871094 315.277344 Z M 415.347656 313.753906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(28.627451%,28.627451%,49.019608%);fill-opacity:1;"
      d="M 375.761719 315.277344 L 377.28125 316.796875 Z M 375.761719 315.277344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(63.529412%,69.803922%,23.921569%);fill-opacity:1;"
      d="M 381.851562 315.277344 L 383.375 316.796875 Z M 381.851562 315.277344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(15.294118%,76.078431%,66.666667%);fill-opacity:1;"
      d="M 383.375 315.277344 L 384.894531 316.796875 Z M 383.375 315.277344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(45.490196%,69.411765%,40%);fill-opacity:1;"
      d="M 406.214844 315.277344 L 407.734375 316.796875 Z M 406.214844 315.277344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 410.78125 315.277344 L 406.214844 321.367188 Z M 410.78125 315.277344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(50.196078%,50.196078%,40.392157%);fill-opacity:1;"
      d="M 412.304688 315.277344 L 413.828125 316.796875 Z M 412.304688 315.277344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.490196%,5.490196%,43.137255%);fill-opacity:1;"
      d="M 413.828125 315.277344 L 415.347656 316.796875 Z M 413.828125 315.277344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(14.901961%,14.901961%,47.058824%);fill-opacity:1;"
      d="M 377.28125 316.796875 L 378.804688 318.320312 Z M 377.28125 316.796875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(67.843137%,70.196078%,20%);fill-opacity:1;"
      d="M 383.375 316.796875 L 384.894531 318.320312 Z M 383.375 316.796875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(25.882353%,73.333333%,57.254902%);fill-opacity:1;"
      d="M 384.894531 316.796875 L 386.417969 318.320312 Z M 384.894531 316.796875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.098039%,78.823529%,75.686275%);fill-opacity:1;"
      d="M 403.167969 316.796875 L 404.691406 318.320312 Z M 403.167969 316.796875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(58.823529%,69.803922%,27.843137%);fill-opacity:1;"
      d="M 404.691406 316.796875 L 406.214844 318.320312 Z M 404.691406 316.796875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(43.137255%,42.352941%,43.921569%);fill-opacity:1;"
      d="M 410.78125 316.796875 L 412.304688 318.320312 Z M 410.78125 316.796875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(10.588235%,10.588235%,45.490196%);fill-opacity:1;"
      d="M 378.804688 318.320312 L 380.328125 319.84375 Z M 378.804688 318.320312 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(55.294118%,55.294118%,35.686275%);fill-opacity:1;"
      d="M 380.328125 318.320312 L 381.851562 319.84375 Z M 380.328125 318.320312 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(45.490196%,69.411765%,40%);fill-opacity:1;"
      d="M 386.417969 318.320312 L 387.941406 319.84375 Z M 386.417969 318.320312 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.098039%,78.823529%,75.686275%);fill-opacity:1;"
      d="M 387.941406 318.320312 L 389.464844 319.84375 Z M 387.941406 318.320312 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(20.392157%,74.509804%,61.960784%);fill-opacity:1;"
      d="M 401.644531 318.320312 L 403.167969 319.84375 Z M 401.644531 318.320312 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(67.843137%,70.196078%,20%);fill-opacity:1;"
      d="M 403.167969 318.320312 L 404.691406 319.84375 Z M 403.167969 318.320312 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(28.627451%,28.627451%,49.019608%);fill-opacity:1;"
      d="M 409.257812 318.320312 L 410.78125 319.84375 Z M 409.257812 318.320312 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.490196%,5.490196%,43.137255%);fill-opacity:1;"
      d="M 380.328125 319.84375 L 381.851562 321.367188 Z M 380.328125 319.84375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(43.137255%,42.352941%,43.921569%);fill-opacity:1;"
      d="M 381.851562 319.84375 L 383.375 321.367188 Z M 381.851562 319.84375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 383.375 319.84375 L 386.417969 322.890625 Z M 383.375 319.84375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(58.823529%,69.803922%,27.843137%);fill-opacity:1;"
      d="M 387.941406 319.84375 L 389.464844 321.367188 Z M 387.941406 319.84375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(15.294118%,76.078431%,66.666667%);fill-opacity:1;"
      d="M 389.464844 319.84375 L 390.988281 321.367188 Z M 389.464844 319.84375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(40.784314%,69.019608%,43.921569%);fill-opacity:1;"
      d="M 400.121094 319.84375 L 401.644531 321.367188 Z M 400.121094 319.84375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(19.607843%,19.607843%,48.235294%);fill-opacity:1;"
      d="M 407.734375 319.84375 L 409.257812 321.367188 Z M 407.734375 319.84375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(28.627451%,28.627451%,49.019608%);fill-opacity:1;"
      d="M 383.375 321.367188 L 384.894531 322.890625 Z M 383.375 321.367188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(35.686275%,70.588235%,48.627451%);fill-opacity:1;"
      d="M 390.988281 321.367188 L 392.507812 322.890625 Z M 390.988281 321.367188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(15.294118%,76.078431%,66.666667%);fill-opacity:1;"
      d="M 397.078125 321.367188 L 398.601562 322.890625 Z M 397.078125 321.367188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(58.823529%,69.803922%,27.843137%);fill-opacity:1;"
      d="M 398.601562 321.367188 L 400.121094 322.890625 Z M 398.601562 321.367188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 403.167969 321.367188 L 401.644531 324.410156 Z M 403.167969 321.367188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(50.196078%,50.196078%,40.392157%);fill-opacity:1;"
      d="M 404.691406 321.367188 L 406.214844 322.890625 Z M 404.691406 321.367188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.490196%,5.490196%,43.137255%);fill-opacity:1;"
      d="M 406.214844 321.367188 L 407.734375 322.890625 Z M 406.214844 321.367188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(14.901961%,14.901961%,47.058824%);fill-opacity:1;"
      d="M 384.894531 322.890625 L 386.417969 324.410156 Z M 384.894531 322.890625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(55.294118%,55.294118%,35.686275%);fill-opacity:1;"
      d="M 386.417969 322.890625 L 387.941406 324.410156 Z M 386.417969 322.890625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(54.509804%,69.803922%,32.156863%);fill-opacity:1;"
      d="M 392.507812 322.890625 L 394.03125 324.410156 Z M 392.507812 322.890625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(10.196078%,77.254902%,70.980392%);fill-opacity:1;"
      d="M 394.03125 322.890625 L 395.554688 324.410156 Z M 394.03125 322.890625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(35.686275%,70.588235%,48.627451%);fill-opacity:1;"
      d="M 395.554688 322.890625 L 397.078125 324.410156 Z M 395.554688 322.890625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(34.509804%,34.509804%,48.235294%);fill-opacity:1;"
      d="M 403.167969 322.890625 L 404.691406 324.410156 Z M 403.167969 322.890625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.490196%,5.490196%,43.137255%);fill-opacity:1;"
      d="M 386.417969 324.410156 L 387.941406 325.933594 Z M 386.417969 324.410156 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(40%,40%,46.666667%);fill-opacity:1;"
      d="M 387.941406 324.410156 L 389.464844 325.933594 Z M 387.941406 324.410156 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 389.464844 324.410156 L 390.988281 325.933594 Z M 389.464844 324.410156 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(60%,60%,30.588235%);fill-opacity:1;"
      d="M 400.121094 324.410156 L 401.644531 325.933594 Z M 400.121094 324.410156 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(19.607843%,19.607843%,48.235294%);fill-opacity:1;"
      d="M 401.644531 324.410156 L 403.167969 325.933594 L 401.644531 324.410156 M 389.464844 325.933594 L 390.988281 327.457031 Z M 389.464844 325.933594 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(60%,60%,30.588235%);fill-opacity:1;"
      d="M 390.988281 325.933594 L 392.507812 327.457031 Z M 390.988281 325.933594 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 397.078125 325.933594 L 398.601562 327.457031 Z M 397.078125 325.933594 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(40%,40%,46.666667%);fill-opacity:1;"
      d="M 398.601562 325.933594 L 400.121094 327.457031 Z M 398.601562 325.933594 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.490196%,5.490196%,43.137255%);fill-opacity:1;"
      d="M 400.121094 325.933594 L 401.644531 327.457031 L 400.121094 325.933594 M 390.988281 327.457031 L 392.507812 328.980469 Z M 390.988281 327.457031 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(40%,40%,46.666667%);fill-opacity:1;"
      d="M 392.507812 327.457031 L 394.03125 328.980469 Z M 392.507812 327.457031 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(64.313725%,64.313725%,23.921569%);fill-opacity:1;"
      d="M 394.03125 327.457031 L 395.554688 328.980469 Z M 394.03125 327.457031 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(60%,60%,30.588235%);fill-opacity:1;"
      d="M 395.554688 327.457031 L 397.078125 328.980469 Z M 395.554688 327.457031 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(19.607843%,19.607843%,48.235294%);fill-opacity:1;"
      d="M 397.078125 327.457031 L 398.601562 328.980469 Z M 397.078125 327.457031 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(14.901961%,14.901961%,47.058824%);fill-opacity:1;"
      d="M 394.03125 328.980469 L 395.554688 330.503906 Z M 394.03125 328.980469 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(5.490196%,5.490196%,43.137255%);fill-opacity:1;"
      d="M 395.554688 328.980469 L 397.078125 330.503906 Z M 395.554688 328.980469 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,60%,0%);fill-opacity:1;"
      d="M 397.078125 226.960938 C 399.425781 232.488281 406.960938 238.589844 409.03125 241.582031 C 406.394531 244.570312 405.867188 244.246094 406.214844 249.800781 C 410.796875 245.007812 410.878906 244.507812 413.828125 245.234375 C 420.285156 251.679688 414.980469 265.515625 409.644531 268.527344 C 404.308594 271.734375 405.277344 268.414062 397.28125 272.4375 C 400.949219 275.574219 405.195312 271.976562 408.667969 272.9375 C 410.554688 275.175781 407.769531 279.257812 409.238281 283.097656 C 412.304688 282.800781 411.9375 276.609375 412.652344 274.363281 C 414.890625 266.132812 428.367188 260.394531 429.054688 252.847656 C 431.894531 251.511719 434.734375 252.429688 438.1875 254.371094 C 436.46875 247.300781 430.777344 247.375 429.25 245.167969 C 425.621094 239.617188 422.398438 233.285156 414.644531 231.644531 C 408.757812 230.398438 409.195312 232.019531 405.421875 229.445312 C 403.078125 227.613281 395.9375 224.15625 397.078125 226.960938 Z M 397.078125 226.960938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 414.007812 233.242188 C 414.007812 233.917969 413.457031 234.46875 412.777344 234.46875 C 412.101562 234.46875 411.550781 233.917969 411.550781 233.242188 C 411.550781 232.5625 412.101562 232.011719 412.777344 232.011719 C 413.457031 232.011719 414.007812 232.5625 414.007812 233.242188 Z M 414.007812 233.242188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,60%,0%);fill-opacity:1;"
      d="M 378.246094 250.40625 C 382.035156 245.746094 383.910156 236.238281 385.585938 233.007812 C 389.464844 233.941406 389.421875 234.558594 394.171875 231.660156 C 387.792969 229.847656 387.3125 230.007812 386.578125 227.0625 C 389.257812 218.34375 403.96875 216.5625 409.125 219.878906 C 414.453125 223.09375 411.066406 223.789062 418.359375 228.980469 C 419.417969 224.277344 414.253906 222.199219 413.480469 218.679688 C 414.578125 215.964844 419.488281 216.523438 422.199219 213.429688 C 420.503906 210.855469 415.199219 214.074219 412.878906 214.492188 C 404.558594 216.355469 393.1875 207.121094 386.195312 210.042969 C 383.6875 208.152344 383.171875 205.214844 383.273438 201.253906 C 377.824219 206.078125 380.550781 211.074219 379.3125 213.457031 C 376.101562 219.257812 372.007812 225.0625 374.179688 232.6875 C 375.828125 238.476562 377.054688 237.328125 376.542969 241.867188 C 376.019531 244.796875 376.296875 252.726562 378.246094 250.40625 Z M 378.246094 250.40625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 375.886719 232.507812 C 376.488281 232.1875 377.230469 232.417969 377.546875 233.019531 C 377.863281 233.617188 377.632812 234.359375 377.035156 234.675781 C 376.433594 234.992188 375.691406 234.761719 375.375 234.164062 C 375.058594 233.566406 375.289062 232.824219 375.886719 232.507812 Z M 375.886719 232.507812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,60%,0%);fill-opacity:1;"
      d="M 409.03125 253.675781 C 403.082031 252.84375 393.972656 256.15625 390.34375 256.394531 C 389.136719 252.59375 389.6875 252.308594 384.746094 249.746094 C 386.496094 256.144531 386.882812 256.46875 384.742188 258.621094 C 375.894531 260.839844 366.761719 249.167969 366.929688 243.042969 C 366.925781 236.816406 369.273438 239.355469 369.945312 230.429688 C 365.371094 231.957031 366.261719 237.449219 363.652344 239.933594 C 360.761719 240.394531 358.699219 235.910156 354.648438 235.1875 C 353.324219 237.96875 358.820312 240.84375 360.378906 242.613281 C 366.277344 248.773438 364.257812 263.277344 370.375 267.753906 C 370.054688 270.875 367.804688 272.839844 364.363281 274.800781 C 371.3125 276.96875 374.175781 272.050781 376.855469 271.878906 C 383.484375 271.625 390.566406 272.125 395.96875 266.324219 C 400.070312 261.917969 398.453125 261.460938 402.605469 259.550781 C 405.378906 258.484375 412.023438 254.144531 409.03125 253.679688 Z M 409.03125 253.675781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 394.929688 264.953125 C 394.347656 264.605469 394.160156 263.851562 394.511719 263.269531 C 394.859375 262.6875 395.613281 262.5 396.195312 262.851562 C 396.773438 263.199219 396.964844 263.953125 396.613281 264.535156 C 396.265625 265.117188 395.511719 265.304688 394.929688 264.953125 Z M 394.929688 264.953125 "
    />
  </g>
</svg>
