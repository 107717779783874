<script>
  import styles from "styleguide/NotificationList.json";

  import { t } from "stores/i18n.js";
  import { filteredNotifications } from "stores/notification.js";

  import { NotificationItem } from "components/notification";
</script>

<div data-component="NotificationList">
  <div class={styles.wrapper}>
    {#each $filteredNotifications as item}
      <NotificationItem {...item} />
    {:else}
      <div class={styles.empty}>{$t("notification_list.empty")}</div>
    {/each}
  </div>
</div>

<style lang="scss">
  .wrapper {
    height: 500px;
    overflow-y: auto;
  }

  .empty {
    padding: 20px 20px 10px;
    text-align: center;
  }
</style>
