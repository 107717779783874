<script>
  import clsx from "clsx";
  import styles from "styleguide/Tag.json";

  export let color;
  export let size = "normal";
  export let label;
  export let close = null;
  export let click = null;
  export let style = "";

  function onClick() {
    if (close) {
      close();
    }
    if (click) {
      click();
    }
  }
</script>

<div
  data-component="Tag"
  class={clsx(
    styles.tag,
    { [styles[color]]: color },
    { [styles[size]]: size },
    style.split(" ").map((x) => styles[x]),
    { [styles["with-close"]]: close },
    { [styles["with-click"]]: click },
  )}
  on:click={onClick}
>
  {#if close}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path
        fill-rule="evenodd"
        d="M14,8 L10,8 L10,4 C10,3.448 9.553,3 9,3 C8.447,3 8,3.448 8,4 L8,8 L4,8 C3.447,8 3,8.448 3,9 C3,9.553 3.447,10 4,10 L8,10 L8,14 C8,14.553 8.447,15 9,15 C9.553,15 10,14.553 10,14 L10,10 L14,10 C14.553,10 15,9.553 15,9 C15,8.448 14.553,8 14,8"
        transform="rotate(-45 9 9)"
      />
    </svg>
  {/if}

  {label}
</div>

<style lang="scss">
  .tag {
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    padding: 0 17px;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
  }

  .with-close,
  .with-click {
    cursor: pointer;
  }

  svg {
    margin-right: 5px;
    margin-left: -5px;
  }

  .small {
    height: 20px;
    border-radius: 10px;
    font-size: 12px;
    padding: 0 12px;
  }

  .medium {
    height: 26px;
    line-height: 26px;
    padding: 0 15px;
    font-weight: 600;
  }

  .semi-bold {
    font-weight: 600;
  }

  .bold {
    font-weight: 700;
  }

  .count {
    display: flex;
    align-items: center;
    min-width: 30px;
    height: 30px;
    padding: 0 5px;
    border-radius: 8px;
    text-align: center;
  }

  .fullsize {
    width: 100%;
  }

  .red {
    background: var(--red-010);
    color: var(--red-200);
    fill: var(--red-200);

    &.selected {
      border: solid 1px var(--red-200);
    }
  }

  .green {
    background: var(--green-010);
    color: var(--green-500);
    fill: var(--green-500);

    &.selected {
      border: solid 1px var(--green-500);
    }
  }

  .yellow {
    background: var(--yellow-010);
    color: var(--orange-300);
    fill: var(--orange-300);

    &.selected {
      border: solid 1px var(--orange-300);
    }
  }

  .orange {
    background: var(--orange-010);
    color: var(--orange-300);
    fill: var(--orange-300);

    &.selected {
      border: solid 1px var(--orange-300);
    }
  }

  .grey {
    background: var(--primary-025);
    color: var(--primary-400);
    fill: var(--primary-400);

    &.selected {
      border: solid 1px var(--primary-400);
    }
  }

  .blue {
    background: var(--blue-010);
    color: var(--blue-200);
    fill: var(--blue-200);

    &.selected {
      border: solid 1px var(--blue-200);
    }

    &.active {
      background: #fff;
      border: solid 1px var(--blue-200);
    }
  }
</style>
