<script>
  import styles from "styleguide/ConfirmationReleaseModal.json";

  import { t } from "stores/i18n.js";
  import { toasts } from "stores/toasts.js";
  import { can } from "stores/permission.js";
  import {
    activeRelease,
    confirmationId,
    getConfirmation,
    getRelease,
    release,
  } from "stores/confirmation/confirmation.js";

  import ConfirmationApi from "apis/confirmation/confirmation.js";

  import { Button, DocumentThumbnails, Toast } from "components";
  import { Modal } from "components/modals";

  $: onShow($activeRelease);

  function onShow(active) {
    if (active) {
      getRelease();
    }
  }

  function onHide() {
    activeRelease.set(false);
  }

  function updateLetter() {
    ConfirmationApi.regenerateMailings({
      params: {
        id: $confirmationId,
      },
      success: () => {
        getRelease();
        activeRelease.set(false);
        toasts.send({
          title: $t("confirmation_release_modal.update_success"),
          type: "success",
        });
      },
      error: () => {
        toasts.send({
          title: $t("confirmation_release_modal.update_error"),
          type: "error",
        });
      },
    });
  }

  function submit() {
    ConfirmationApi.confirmMailings({
      params: {
        id: $confirmationId,
      },
      success: () => {
        getConfirmation();
        activeRelease.set(false);
        toasts.send({
          title: $t("confirmation_release_modal.submit_success"),
          type: "success",
        });
      },
      error: () => {
        toasts.send({
          title: $t("confirmation_release_modal.submit_error"),
          type: "error",
        });
      },
    });
  }
</script>

<Modal
  isWide
  style={"bottom-between"}
  shown={$activeRelease}
  title={$t("confirmation_release_modal.title")}
  fitContent={true}
  lockBodyScroll={false}
  {onHide}
>
  <div class={styles.wrapper} data-component="ConfirmationReleaseModal">
    <div class={styles.inner}>
      <div class={styles.half}>
        <div>
          <div class={styles.label}>
            {$t("confirmation_release_modal.letterhead_preview")}
          </div>
          {#if $release.preview_mailing?.mailing_url}
            <DocumentThumbnails
              documentUrl={$release.preview_mailing?.mailing_url}
              documentId={"permalink"}
              documentTitle={$t(
                "confirmation_release_modal.letterhead_preview",
              ) + ".pdf"}
              showOnlyFirstPage={true}
            />
            <p>{$t("confirmation_release_modal.click_to_enlarge")}</p>
          {/if}
        </div>
      </div>
      <div class={styles.half}>
        {#if $release.outdated_sending_mailings_count > 0}
          <div class={styles.info}>
            <Toast
              type="warning"
              title={$t("confirmation_release_modal.update_date")}
              message={$t(
                "confirmation_release_modal.update_date_description",
              ).replace("%{count}", $release.outdated_sending_mailings_count)}
              isActionShown={$can("regenerate_mailings", "confirmation")}
              action={{
                style: "primary-text small",
                click: updateLetter,
                button: $t("confirmation_release_modal.update_letter"),
              }}
            />
          </div>
        {/if}

        {#if $release.sending_grouped_mailings && $release.sending_grouped_mailings.length > 0}
          <table class={styles.grouped}>
            <tr class={`${styles["grouped-head"]} ${styles["grouped-row"]}`}>
              <th class={styles["grouped-item"]}>
                {$t("confirmation_release_modal.number")}
              </th>
              <th class={styles["grouped-item"]}>
                {$t("confirmation_release_modal.via")}
              </th>
              <th class={styles["grouped-item"]}>
                {$t("confirmation_release_modal.country")}</th
              >
              <th class={styles["grouped-item"]}>
                {$t("confirmation_release_modal.language")}
              </th>
            </tr>
            {#each $release.sending_grouped_mailings as { country_code, item_language, mailings_count, sent_via }}
              <tr class={styles["grouped-row"]}>
                <td class={styles["grouped-item"]}>{mailings_count}</td>
                <td class={styles["grouped-item"]}
                  >{$t(`confirmation.${sent_via}`)}</td
                >
                <td class={styles["grouped-item"]}>{country_code}</td>
                <td class={styles["grouped-item"]}>{item_language}</td>
              </tr>
            {/each}
          </table>
        {/if}
      </div>
    </div>
  </div>

  <svelte:fragment slot="bottom">
    <div />
    <div class={styles.confirm}>{$t("confirmation_release_modal.confirm")}</div>
    <Button style="primary" click={submit}
      >{$t("confirmation_release_modal.submit")}</Button
    >
  </svelte:fragment>
</Modal>

<style lang="scss">
  .title {
    font-weight: 600;
    padding: 0 25px 10px;
    border-bottom: 1px solid var(--primary-050);
  }

  .container {
    padding: 0 25px;

    .signer & {
      padding: 0 20px;
    }
  }

  .inner {
    display: flex;
    gap: 1em;
  }

  .half {
    flex: 1;
  }

  .label {
    font-weight: 500;
    margin-bottom: 1em;
  }

  .info {
    margin: 12px;
  }

  .grouped {
    border-collapse: collapse;
  }

  .grouped-row {
    border-bottom: 1px solid var(--primary-050);
  }

  .grouped-item {
    text-align: left;
    padding: 15px;

    &:first-child {
      padding-left: 24px;
    }

    &:last-child {
      padding-right: 24px;
    }
  }

  .preview {
    display: flex;
    justify-content: center;

    .image {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: calc(100vh - 300px);
      box-shadow: 0 3px 8px 0 rgba(41, 49, 61, 0.06);
      border: solid 1px var(--primary-050);
      margin-top: 15px;
      border-radius: 3px;
    }
  }

  .pages {
    margin-top: 8px;
  }
</style>
