<script>
  import { getContext } from "svelte";
  import * as confirmationStore from "stores/confirmation/confirmation.js";
  import * as confirmationItemStore from "stores/confirmation/confirmation_item.js";

  import { Activities } from "components";

  let parent_type = getContext("parent_type");
  let store =
    parent_type === "confirmation" ? confirmationStore : confirmationItemStore;
</script>

<div data-component="ConfirmationSidebarActivity">
  <Activities
    style={"max-height"}
    activitiesStore={store.activities}
    getActivities={store.getActivities}
  />
</div>
