import {
  controls,
  controlGroups,
  selectedControls,
  setActiveControl,
  activeControlComments,
  activeControlActivities,
} from "stores/pbc.js";
import { toasts } from "stores/toasts.js";
import { get } from "svelte/store";
import Api from "apis/api";
import AuditApi from "apis/pbc/audit.js";

class ControlApi {
  constructor() {
    this.endpoint = "/controls";
  }

  controlParams(params) {
    const formData = new FormData();

    formData.append("name", params.name);
    formData.append("control_group_id", params.control_group_id);

    return formData;
  }

  get(id, success) {
    Api.get(`${this.endpoint}/${id}`, {
      success: success,
    });
  }

  create(params) {
    Api.post(this.endpoint, {
      body: this.controlParams(params),
      success: (response) => {
        const newControl = response.data.control;
        newControl.id = newControl.permalink;
        newControl.control_group_id = params.control_group_id;
        newControl.control_group_name = params.control_group_name;
        const newControls = get(controls).filter(
          (c) => c.permalink !== newControl.permalink,
        );
        newControls.push(newControl);
        controls.set(newControls);

        const newGroups = get(controlGroups);
        const group = newGroups.find(
          (group) => group.permalink === newControl.control_group_id,
        );
        group.controls = [...group.controls, newControl];

        controlGroups.set(newGroups);

        AuditApi.getStatuses();
      },
      error: (response) => {
        console.error("ERROR", response);
      },
    });
  }

  readyForReview(id, status) {
    const formData = new FormData();

    formData.append("id", id);
    formData.append("ready_for_review", status);

    Api.post(this.endpoint + `/${id}/status_change`, {
      body: formData,
      success: () => {
        AuditApi.getAudits();
        AuditApi.getStatuses();
      },
      error: (response) => {
        console.error("ERROR", response);
      },
    });
  }

  updateStatusBatch(permalinks, status, success, error) {
    const formData = new FormData();

    for (let id of permalinks) {
      formData.append("ids[]", id);
    }

    formData.append("status", status);

    Api.post(this.endpoint + "/batch_status_update", {
      body: formData,
      success: () => {
        // AuditApi.getAudits() // from API
        AuditApi.getStatuses();

        // from front-end
        let oldControls = get(controls).filter(
          (c) => !permalinks.includes(c.permalink),
        );
        let newControls = get(controls).filter((c) =>
          permalinks.includes(c.permalink),
        );

        newControls = newControls.map((c) => {
          c.status = status;
          return c;
        });

        controls.set([...oldControls, ...newControls]);
        toasts.send({ title: success, type: "success" });
      },
      error: () => {
        toasts.send({ title: error, type: "error" });
      },
    });
  }

  edit(options) {
    const { params, success, error } = options;

    const formData = new FormData();

    if (params.name) formData.append("name", params.name);
    if (params.description !== undefined)
      formData.append("description", params.description);
    if (params.due_on !== undefined) formData.append("due_on", params.due_on);
    if (params.status) formData.append("status", params.status);

    Api.patch(this.endpoint + `/${params.id}`, {
      body: formData,
      success: () => {
        AuditApi.getAudits();
        AuditApi.getStatuses();
        success();
      },
      error,
    });
  }

  reorder(params) {
    const formData = new FormData();

    formData.append("position", params.position);
    formData.append("control_group_id", params.control_group_id);

    Api.post(`${this.endpoint}/${params.id}/reorder`, {
      body: formData,
      success: () => {
        AuditApi.getAudits();
      },
      error: (response) => {
        console.error("ERROR", response);
      },
    });
  }

  updateDueOnBatch(options) {
    const { params, success, error } = options;
    const { ids, date } = params;
    const formData = new FormData();

    for (let id of ids) {
      formData.append("ids[]", id);
    }

    formData.append("date", date);

    Api.post(this.endpoint + "/batch_due_on_update", {
      body: formData,
      success,
      error,
    });
  }

  remindBatch(options) {
    const { params, success, error } = options;
    const { ids } = params;
    const formData = new FormData();

    for (let id of ids) {
      formData.append("ids[]", id);
    }

    Api.post(this.endpoint + "/bulk_create_reminders", {
      body: formData,
      success,
      error,
    });
  }

  downloadBatchAttachment(options) {
    const { params, success, error } = options;
    const { id, attachment_ids } = params;

    const formData = new FormData();

    for (let id of attachment_ids) {
      formData.append("attachment_ids[]", id);
    }

    Api.downloadBatchAttachment(
      `${this.endpoint}/${id}/batch_attachment_download`,
      {
        body: formData,
        success: success,
        error: error,
      },
    );
  }

  downloadAll(id) {
    Api.download(`${this.endpoint}/${id}/download`, (response) => {});
  }

  delete(id) {
    Api.delete(this.endpoint + `/${id}`, {
      success: () => {
        AuditApi.getAudits();
        AuditApi.getStatuses();
        setActiveControl(null);
      },
      error: (response) => {
        console.error("ERROR", response);
      },
    });
  }

  deleteBatch(permalinks, success, error) {
    const formData = new FormData();

    for (let id of permalinks) {
      formData.append("ids[]", id);
    }

    Api.delete(this.endpoint + "/bulk_destroy", {
      body: formData,
      success: () => {
        AuditApi.getAudits();
        AuditApi.getStatuses();
        selectedControls.set([]);
        toasts.send({ title: success, type: "success" });
      },
      error: () => {
        toasts.send({ title: error, type: "error" });
      },
    });
  }

  getComments(id) {
    Api.get(`${this.endpoint}/${id}/comments`, {
      success: (response) => {
        const comments = response.data.sidebar_comments.filter(
          (item) => !item.is_deleted,
        );

        activeControlComments.set(comments);
      },
      error: (response) => {
        console.error("ERROR", response);
      },
    });
  }

  getActivities(id, page = 1) {
    Api.get(`${this.endpoint}/${id}/activities`, {
      params: {
        page,
      },
      success: (response) => {
        activeControlActivities.set(response.data);
      },
      error: (response) => {
        console.error("ERROR", response);
      },
    });
  }
}

export default new ControlApi();
