<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(40.392157%,59.215686%,83.921569%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 239.851562 86.472656 L 217.039062 102.09375 L 225.78125 76.851562 L 202.949219 61.257812 L 231.160156 61.285156 L 239.855469 36.027344 L 248.550781 61.285156 L 276.761719 61.265625 L 253.921875 76.851562 L 262.660156 102.097656 Z M 239.851562 86.472656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 239.851562 274.253906 L 217.039062 258.636719 L 225.78125 283.878906 L 202.949219 299.46875 L 231.160156 299.445312 L 239.855469 324.699219 L 248.550781 299.445312 L 276.761719 299.464844 L 253.921875 283.878906 L 262.660156 258.632812 Z M 239.851562 274.253906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 130.203125 193.617188 L 112.957031 215.222656 L 113.9375 188.527344 L 87.542969 180.292969 L 114.527344 172.070312 L 115.457031 145.375 L 131.15625 166.984375 L 158.128906 158.71875 L 140.847656 180.300781 L 156.585938 201.890625 Z M 130.203125 193.617188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 349.4375 193.617188 L 366.683594 215.222656 L 365.703125 188.527344 L 392.101562 180.292969 L 365.113281 172.070312 L 364.183594 145.375 L 348.484375 166.984375 L 321.511719 158.71875 L 338.792969 180.300781 L 323.058594 201.890625 Z M 349.4375 193.617188 "
    />
  </g>
</svg>
