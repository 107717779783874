<script>
  export let width = "640px";
  export let height = "480px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  id="flag-icons-gb-sct"
  {width}
  {height}
  {style}
  viewBox="0 0 640 480"
>
  <path fill="#0065bd" d="M0 0h640v480H0z" />
  <path
    stroke="#fff"
    stroke-width=".6"
    d="m0 0 5 3M0 3l5-3"
    transform="scale(128 160)"
  />
</svg>
