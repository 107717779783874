<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(6.27451%,54.901961%,0%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,83.921569%,0%);fill-opacity:1;"
      d="M -123.039062 125.269531 L 603.007812 125.269531 L 603.007812 160.539062 L -123.039062 160.539062 Z M -123.039062 125.269531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,83.921569%,0%);fill-opacity:1;"
      d="M 187.09375 0 L 221.148438 0 L 221.148438 360 L 187.09375 360 Z M 187.09375 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 219.929688 0 L 253.984375 0 L 253.984375 360 L 219.929688 360 Z M 219.929688 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M -123.039062 159.320312 L 603.007812 159.320312 L 603.007812 194.589844 L -123.039062 194.589844 Z M -123.039062 159.320312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M -123.039062 194.597656 L 603.007812 194.597656 L 603.007812 229.867188 L -123.039062 229.867188 Z M -123.039062 194.597656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 253.988281 0 L 288.042969 0 L 288.042969 360 L 253.988281 360 Z M 253.988281 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.588235%,16.078431%,6.27451%);fill-opacity:1;"
      d="M 240.957031 276.941406 C 187.804688 276.941406 144.714844 233.652344 144.714844 180.253906 L 144.714844 180.246094 C 144.714844 126.847656 187.804688 83.558594 240.957031 83.558594 C 294.109375 83.558594 337.195312 126.847656 337.195312 180.246094 L 337.195312 180.253906 C 337.195312 233.652344 294.109375 276.941406 240.957031 276.941406 Z M 240.957031 276.941406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 235.914062 96.273438 C 235.914062 96.089844 239.855469 85.023438 239.855469 85.023438 L 243.441406 96.089844 C 243.441406 96.089844 255.449219 96.453125 255.449219 96.273438 C 255.449219 96.089844 245.949219 103.527344 245.949219 103.527344 C 245.949219 103.527344 250.433594 116.226562 250.253906 115.863281 C 250.074219 115.503906 239.855469 108.246094 239.855469 108.246094 C 239.855469 108.246094 229.460938 115.503906 229.636719 115.503906 C 229.816406 115.503906 233.582031 103.527344 233.582031 103.527344 L 224.261719 96.453125 L 235.914062 96.269531 Z M 235.914062 96.273438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,90.588235%,0%);fill-opacity:1;"
      d="M 236.449219 96.835938 C 236.449219 96.675781 239.863281 87.09375 239.863281 87.09375 L 242.96875 96.675781 C 242.96875 96.675781 253.371094 96.992188 253.371094 96.835938 C 253.371094 96.675781 245.144531 103.121094 245.144531 103.121094 C 245.144531 103.121094 249.023438 114.117188 248.867188 113.804688 C 248.714844 113.488281 239.863281 107.203125 239.863281 107.203125 C 239.863281 107.203125 230.859375 113.488281 231.015625 113.488281 C 231.171875 113.488281 234.429688 103.121094 234.429688 103.121094 L 226.359375 96.992188 L 236.449219 96.832031 Z M 236.449219 96.835938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(6.27451%,54.901961%,0%);fill-opacity:1;"
      d="M 237.894531 98.429688 C 237.894531 98.335938 239.863281 92.8125 239.863281 92.8125 L 241.652344 98.335938 C 241.652344 98.335938 247.652344 98.519531 247.652344 98.429688 C 247.652344 98.335938 242.90625 102.050781 242.90625 102.050781 C 242.90625 102.050781 245.144531 108.394531 245.054688 108.210938 C 244.964844 108.03125 239.863281 104.40625 239.863281 104.40625 C 239.863281 104.40625 234.671875 108.03125 234.761719 108.03125 C 234.851562 108.03125 236.730469 102.050781 236.730469 102.050781 L 232.074219 98.519531 Z M 237.894531 98.429688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 310.699219 148.921875 C 310.699219 148.742188 314.644531 137.671875 314.644531 137.671875 L 318.226562 148.742188 C 318.226562 148.742188 330.238281 149.101562 330.238281 148.921875 C 330.238281 148.742188 320.738281 156.179688 320.738281 156.179688 C 320.738281 156.179688 325.21875 168.878906 325.039062 168.515625 C 324.859375 168.152344 314.644531 160.894531 314.644531 160.894531 C 314.644531 160.894531 304.246094 168.152344 304.425781 168.152344 C 304.605469 168.152344 308.367188 156.179688 308.367188 156.179688 L 299.046875 149.101562 Z M 310.699219 148.921875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,90.588235%,0%);fill-opacity:1;"
      d="M 311.234375 149.484375 C 311.234375 149.328125 314.652344 139.742188 314.652344 139.742188 L 317.757812 149.328125 C 317.757812 149.328125 328.160156 149.640625 328.160156 149.484375 C 328.160156 149.328125 319.929688 155.769531 319.929688 155.769531 C 319.929688 155.769531 323.8125 166.769531 323.65625 166.453125 C 323.5 166.140625 314.652344 159.855469 314.652344 159.855469 C 314.652344 159.855469 305.648438 166.140625 305.800781 166.140625 C 305.957031 166.140625 309.21875 155.769531 309.21875 155.769531 L 301.144531 149.640625 Z M 311.234375 149.484375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(6.27451%,54.901961%,0%);fill-opacity:1;"
      d="M 312.679688 151.078125 C 312.679688 150.988281 314.648438 145.460938 314.648438 145.460938 L 316.441406 150.988281 C 316.441406 150.988281 322.4375 151.167969 322.4375 151.078125 C 322.4375 150.988281 317.695312 154.703125 317.695312 154.703125 C 317.695312 154.703125 319.929688 161.042969 319.84375 160.863281 C 319.753906 160.679688 314.648438 157.058594 314.648438 157.058594 C 314.648438 157.058594 309.457031 160.679688 309.546875 160.679688 C 309.636719 160.679688 311.515625 154.703125 311.515625 154.703125 L 306.863281 151.167969 Z M 312.679688 151.078125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 288.902344 232.488281 C 288.902344 232.308594 292.847656 221.238281 292.847656 221.238281 L 296.429688 232.308594 C 296.429688 232.308594 308.441406 232.667969 308.441406 232.488281 C 308.441406 232.308594 298.941406 239.746094 298.941406 239.746094 C 298.941406 239.746094 303.421875 252.445312 303.242188 252.082031 C 303.0625 251.71875 292.847656 244.460938 292.847656 244.460938 C 292.847656 244.460938 282.449219 251.71875 282.628906 251.71875 C 282.808594 251.71875 286.570312 239.746094 286.570312 239.746094 L 277.25 232.667969 Z M 288.902344 232.488281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,90.588235%,0%);fill-opacity:1;"
      d="M 289.4375 233.050781 C 289.4375 232.894531 292.855469 223.308594 292.855469 223.308594 L 295.960938 232.894531 C 295.960938 232.894531 306.363281 233.207031 306.363281 233.050781 C 306.363281 232.894531 298.132812 239.335938 298.132812 239.335938 C 298.132812 239.335938 302.015625 250.335938 301.859375 250.019531 C 301.703125 249.707031 292.855469 243.421875 292.855469 243.421875 C 292.855469 243.421875 283.851562 249.707031 284.003906 249.707031 C 284.160156 249.707031 287.421875 239.335938 287.421875 239.335938 L 279.347656 233.207031 Z M 289.4375 233.050781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(6.27451%,54.901961%,0%);fill-opacity:1;"
      d="M 290.882812 234.644531 C 290.882812 234.554688 292.851562 229.027344 292.851562 229.027344 L 294.644531 234.554688 C 294.644531 234.554688 300.640625 234.734375 300.640625 234.644531 C 300.640625 234.554688 295.898438 238.269531 295.898438 238.269531 C 295.898438 238.269531 298.132812 244.609375 298.046875 244.429688 C 297.957031 244.246094 292.851562 240.625 292.851562 240.625 C 292.851562 240.625 287.660156 244.246094 287.75 244.246094 C 287.839844 244.246094 289.71875 238.269531 289.71875 238.269531 L 285.066406 234.734375 Z M 290.882812 234.644531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 184.34375 232.488281 C 184.34375 232.308594 188.285156 221.238281 188.285156 221.238281 L 191.871094 232.308594 C 191.871094 232.308594 203.882812 232.667969 203.882812 232.488281 C 203.882812 232.308594 194.378906 239.746094 194.378906 239.746094 C 194.378906 239.746094 198.863281 252.445312 198.683594 252.082031 C 198.503906 251.71875 188.285156 244.460938 188.285156 244.460938 C 188.285156 244.460938 177.890625 251.71875 178.070312 251.71875 C 178.246094 251.71875 182.011719 239.746094 182.011719 239.746094 L 172.691406 232.667969 Z M 184.34375 232.488281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,90.588235%,0%);fill-opacity:1;"
      d="M 184.878906 233.050781 C 184.878906 232.894531 188.292969 223.308594 188.292969 223.308594 L 191.398438 232.894531 C 191.398438 232.894531 201.800781 233.207031 201.800781 233.050781 C 201.800781 232.894531 193.574219 239.335938 193.574219 239.335938 C 193.574219 239.335938 197.453125 250.335938 197.300781 250.019531 C 197.144531 249.707031 188.292969 243.421875 188.292969 243.421875 C 188.292969 243.421875 179.289062 249.707031 179.445312 249.707031 C 179.601562 249.707031 182.859375 239.335938 182.859375 239.335938 L 174.789062 233.207031 Z M 184.878906 233.050781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(6.27451%,54.901961%,0%);fill-opacity:1;"
      d="M 186.324219 234.644531 C 186.324219 234.554688 188.292969 229.027344 188.292969 229.027344 L 190.082031 234.554688 C 190.082031 234.554688 196.082031 234.734375 196.082031 234.644531 C 196.082031 234.554688 191.335938 238.269531 191.335938 238.269531 C 191.335938 238.269531 193.574219 244.609375 193.484375 244.429688 C 193.394531 244.246094 188.292969 240.625 188.292969 240.625 C 188.292969 240.625 183.101562 244.246094 183.191406 244.246094 C 183.28125 244.246094 185.160156 238.269531 185.160156 238.269531 L 180.503906 234.734375 Z M 186.324219 234.644531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 165.246094 146.769531 C 165.246094 146.589844 169.191406 135.523438 169.191406 135.523438 L 172.777344 146.589844 C 172.777344 146.589844 184.785156 146.953125 184.785156 146.769531 C 184.785156 146.589844 175.285156 154.027344 175.285156 154.027344 C 175.285156 154.027344 179.765625 166.726562 179.589844 166.363281 C 179.410156 166 169.191406 158.742188 169.191406 158.742188 C 169.191406 158.742188 158.792969 166 158.972656 166 C 159.152344 166 162.917969 154.027344 162.917969 154.027344 L 153.597656 146.953125 Z M 165.246094 146.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,90.588235%,0%);fill-opacity:1;"
      d="M 165.785156 147.332031 C 165.785156 147.175781 169.199219 137.589844 169.199219 137.589844 L 172.304688 147.175781 C 172.304688 147.175781 182.707031 147.488281 182.707031 147.332031 C 182.707031 147.175781 174.476562 153.617188 174.476562 153.617188 C 174.476562 153.617188 178.359375 164.617188 178.203125 164.300781 C 178.050781 163.988281 169.199219 157.703125 169.199219 157.703125 C 169.199219 157.703125 160.195312 163.988281 160.351562 163.988281 C 160.503906 163.988281 163.765625 153.617188 163.765625 153.617188 L 155.691406 147.488281 Z M 165.785156 147.332031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(6.27451%,54.901961%,0%);fill-opacity:1;"
      d="M 167.230469 148.925781 C 167.230469 148.835938 169.199219 143.308594 169.199219 143.308594 L 170.988281 148.835938 C 170.988281 148.835938 176.984375 149.015625 176.984375 148.925781 C 176.984375 148.835938 172.242188 152.550781 172.242188 152.550781 C 172.242188 152.550781 174.480469 158.890625 174.390625 158.710938 C 174.300781 158.53125 169.199219 154.90625 169.199219 154.90625 C 169.199219 154.90625 164.007812 158.53125 164.097656 158.53125 C 164.183594 158.53125 166.066406 152.550781 166.066406 152.550781 L 161.410156 149.015625 Z M 167.230469 148.925781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 288.015625 122.441406 C 288.015625 122.625 284.074219 133.691406 284.074219 133.691406 L 280.488281 122.625 C 280.488281 122.625 268.476562 122.261719 268.476562 122.441406 C 268.476562 122.625 277.980469 115.183594 277.980469 115.183594 C 277.980469 115.183594 273.496094 102.484375 273.675781 102.847656 C 273.855469 103.210938 284.074219 110.46875 284.074219 110.46875 C 284.074219 110.46875 294.46875 103.210938 294.289062 103.210938 C 294.113281 103.210938 290.347656 115.183594 290.347656 115.183594 L 299.667969 122.261719 Z M 288.015625 122.441406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,90.588235%,0%);fill-opacity:1;"
      d="M 287.480469 121.878906 C 287.480469 122.039062 284.066406 131.621094 284.066406 131.621094 L 280.960938 122.039062 C 280.960938 122.039062 270.558594 121.722656 270.558594 121.878906 C 270.558594 122.039062 278.785156 115.59375 278.785156 115.59375 C 278.785156 115.59375 274.90625 104.597656 275.058594 104.910156 C 275.214844 105.222656 284.066406 111.507812 284.066406 111.507812 C 284.066406 111.507812 293.070312 105.222656 292.914062 105.222656 C 292.757812 105.222656 289.5 115.59375 289.5 115.59375 L 297.570312 121.722656 Z M 287.480469 121.878906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(6.27451%,54.901961%,0%);fill-opacity:1;"
      d="M 286.035156 120.285156 C 286.035156 120.375 284.066406 125.902344 284.066406 125.902344 L 282.277344 120.375 C 282.277344 120.375 276.277344 120.195312 276.277344 120.285156 C 276.277344 120.375 281.023438 116.660156 281.023438 116.660156 C 281.023438 116.660156 278.785156 110.320312 278.875 110.5 C 278.964844 110.683594 284.066406 114.304688 284.066406 114.304688 C 284.066406 114.304688 289.257812 110.683594 289.167969 110.683594 C 289.078125 110.683594 287.199219 116.660156 287.199219 116.660156 L 291.855469 120.195312 Z M 286.035156 120.285156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 318.039062 204.101562 C 318.039062 204.285156 314.097656 215.351562 314.097656 215.351562 L 310.511719 204.285156 C 310.511719 204.285156 298.5 203.921875 298.5 204.101562 C 298.5 204.285156 308.003906 196.847656 308.003906 196.847656 C 308.003906 196.847656 303.519531 184.148438 303.699219 184.511719 C 303.878906 184.871094 314.097656 192.128906 314.097656 192.128906 C 314.097656 192.128906 324.492188 184.871094 324.3125 184.871094 C 324.136719 184.871094 320.371094 196.847656 320.371094 196.847656 L 329.691406 203.921875 L 318.039062 204.105469 Z M 318.039062 204.101562 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,90.588235%,0%);fill-opacity:1;"
      d="M 317.503906 203.539062 C 317.503906 203.699219 314.089844 213.28125 314.089844 213.28125 L 310.984375 203.699219 C 310.984375 203.699219 300.582031 203.382812 300.582031 203.539062 C 300.582031 203.699219 308.808594 197.253906 308.808594 197.253906 C 308.808594 197.253906 304.929688 186.257812 305.082031 186.570312 C 305.238281 186.886719 314.089844 193.171875 314.089844 193.171875 C 314.089844 193.171875 323.09375 186.886719 322.9375 186.886719 C 322.78125 186.886719 319.523438 197.253906 319.523438 197.253906 L 327.59375 203.382812 L 317.503906 203.542969 Z M 317.503906 203.539062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(6.27451%,54.901961%,0%);fill-opacity:1;"
      d="M 316.058594 201.945312 C 316.058594 202.039062 314.089844 207.5625 314.089844 207.5625 L 312.300781 202.039062 C 312.300781 202.039062 306.300781 201.855469 306.300781 201.945312 C 306.300781 202.039062 311.046875 198.324219 311.046875 198.324219 C 311.046875 198.324219 308.808594 191.980469 308.898438 192.164062 C 308.988281 192.34375 314.089844 195.96875 314.089844 195.96875 C 314.089844 195.96875 319.28125 192.34375 319.191406 192.34375 C 319.101562 192.34375 317.222656 198.324219 317.222656 198.324219 L 321.878906 201.855469 Z M 316.058594 201.945312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 243.585938 263.847656 C 243.585938 264.027344 239.644531 275.097656 239.644531 275.097656 L 236.058594 264.027344 C 236.058594 264.027344 224.046875 263.667969 224.046875 263.847656 C 224.046875 264.027344 233.546875 256.589844 233.546875 256.589844 C 233.546875 256.589844 229.066406 243.890625 229.246094 244.253906 C 229.425781 244.617188 239.644531 251.875 239.644531 251.875 C 239.644531 251.875 250.039062 244.617188 249.859375 244.617188 C 249.679688 244.617188 245.917969 256.589844 245.917969 256.589844 L 255.238281 263.667969 Z M 243.585938 263.847656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,90.588235%,0%);fill-opacity:1;"
      d="M 243.050781 263.285156 C 243.050781 263.441406 239.632812 273.027344 239.632812 273.027344 L 236.53125 263.441406 C 236.53125 263.441406 226.128906 263.128906 226.128906 263.285156 C 226.128906 263.441406 234.355469 257 234.355469 257 C 234.355469 257 230.476562 246 230.628906 246.316406 C 230.785156 246.628906 239.632812 252.914062 239.632812 252.914062 C 239.632812 252.914062 248.640625 246.628906 248.484375 246.628906 C 248.328125 246.628906 245.070312 257 245.070312 257 L 253.140625 263.128906 Z M 243.050781 263.285156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(6.27451%,54.901961%,0%);fill-opacity:1;"
      d="M 241.605469 261.691406 C 241.605469 261.78125 239.636719 267.308594 239.636719 267.308594 L 237.847656 261.78125 C 237.847656 261.78125 231.847656 261.601562 231.847656 261.691406 C 231.847656 261.78125 236.59375 258.066406 236.59375 258.066406 C 236.59375 258.066406 234.355469 251.726562 234.445312 251.90625 C 234.535156 252.089844 239.636719 255.710938 239.636719 255.710938 C 239.636719 255.710938 244.828125 252.089844 244.738281 252.089844 C 244.648438 252.089844 242.769531 258.066406 242.769531 258.066406 L 247.425781 261.601562 Z M 241.605469 261.691406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 173.632812 204.101562 C 173.632812 204.285156 169.6875 215.351562 169.6875 215.351562 L 166.105469 204.285156 C 166.105469 204.285156 154.09375 203.921875 154.09375 204.101562 C 154.09375 204.285156 163.59375 196.847656 163.59375 196.847656 C 163.59375 196.847656 159.113281 184.148438 159.292969 184.511719 C 159.472656 184.871094 169.6875 192.128906 169.6875 192.128906 C 169.6875 192.128906 180.085938 184.871094 179.90625 184.871094 C 179.726562 184.871094 175.964844 196.847656 175.964844 196.847656 L 185.285156 203.921875 L 173.632812 204.105469 Z M 173.632812 204.101562 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,90.588235%,0%);fill-opacity:1;"
      d="M 173.097656 203.539062 C 173.097656 203.699219 169.679688 213.28125 169.679688 213.28125 L 166.574219 203.699219 C 166.574219 203.699219 156.171875 203.382812 156.171875 203.539062 C 156.171875 203.699219 164.402344 197.253906 164.402344 197.253906 C 164.402344 197.253906 160.519531 186.257812 160.675781 186.570312 C 160.832031 186.886719 169.679688 193.171875 169.679688 193.171875 C 169.679688 193.171875 178.683594 186.886719 178.53125 186.886719 C 178.375 186.886719 175.113281 197.253906 175.113281 197.253906 L 183.1875 203.382812 L 173.097656 203.542969 Z M 173.097656 203.539062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(6.27451%,54.901961%,0%);fill-opacity:1;"
      d="M 171.652344 201.945312 C 171.652344 202.039062 169.683594 207.5625 169.683594 207.5625 L 167.890625 202.039062 C 167.890625 202.039062 161.894531 201.855469 161.894531 201.945312 C 161.894531 202.039062 166.640625 198.324219 166.640625 198.324219 C 166.640625 198.324219 164.402344 191.980469 164.492188 192.164062 C 164.578125 192.34375 169.683594 195.96875 169.683594 195.96875 C 169.683594 195.96875 174.875 192.34375 174.785156 192.34375 C 174.695312 192.34375 172.816406 198.324219 172.816406 198.324219 L 177.46875 201.855469 Z M 171.652344 201.945312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 199.453125 123.644531 C 199.453125 123.824219 195.507812 134.894531 195.507812 134.894531 L 191.921875 123.824219 C 191.921875 123.824219 179.914062 123.464844 179.914062 123.644531 C 179.914062 123.824219 189.414062 116.386719 189.414062 116.386719 C 189.414062 116.386719 184.933594 103.6875 185.109375 104.050781 C 185.289062 104.414062 195.507812 111.671875 195.507812 111.671875 C 195.507812 111.671875 205.90625 104.414062 205.726562 104.414062 C 205.546875 104.414062 201.78125 116.386719 201.78125 116.386719 L 211.101562 123.464844 Z M 199.453125 123.644531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,90.588235%,0%);fill-opacity:1;"
      d="M 198.914062 123.082031 C 198.914062 123.238281 195.5 132.824219 195.5 132.824219 L 192.394531 123.238281 C 192.394531 123.238281 181.992188 122.925781 181.992188 123.082031 C 181.992188 123.238281 190.222656 116.796875 190.222656 116.796875 C 190.222656 116.796875 186.339844 105.796875 186.496094 106.113281 C 186.648438 106.425781 195.5 112.710938 195.5 112.710938 C 195.5 112.710938 204.503906 106.425781 204.347656 106.425781 C 204.195312 106.425781 200.933594 116.796875 200.933594 116.796875 L 209.007812 122.925781 Z M 198.914062 123.082031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(6.27451%,54.901961%,0%);fill-opacity:1;"
      d="M 197.46875 121.488281 C 197.46875 121.578125 195.5 127.105469 195.5 127.105469 L 193.710938 121.578125 C 193.710938 121.578125 187.714844 121.398438 187.714844 121.488281 C 187.714844 121.578125 192.457031 117.863281 192.457031 117.863281 C 192.457031 117.863281 190.21875 111.523438 190.308594 111.703125 C 190.398438 111.886719 195.5 115.507812 195.5 115.507812 C 195.5 115.507812 200.691406 111.886719 200.601562 111.886719 C 200.515625 111.886719 198.632812 117.863281 198.632812 117.863281 L 203.289062 121.398438 Z M 197.46875 121.488281 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 711.00812 586.139818 C 711.010641 481.298191 697.214877 396.301712 680.208234 396.305056 C 663.201591 396.3084 649.408766 481.301885 649.406244 586.143512 C 649.405379 690.956232 663.201144 775.95271 680.207786 775.949367 C 697.214429 775.946023 711.007255 690.952538 711.00812 586.139818 Z M 711.00812 586.139818 "
      transform="matrix(0.167216,-0.01743,0.00957948,0.13413,135.196309,137.45729)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 711.008624 586.142965 C 711.022304 481.298524 697.232704 396.317004 680.205129 396.312121 C 663.199476 396.310244 649.404214 481.295478 649.412455 586.142924 C 649.40035 690.958458 663.18995 775.939977 680.217525 775.94486 C 697.223178 775.946737 711.018441 690.961504 711.008624 586.142965 Z M 711.008624 586.142965 "
      transform="matrix(0.17687,-0.018391,0.00963553,0.134125,133.685758,138.057491)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 711.010743 586.118402 C 711.020912 481.289915 697.212924 396.302633 680.206088 396.300131 C 663.199253 396.29763 649.397822 481.293419 649.41506 586.124669 C 649.404891 690.953156 663.185471 775.937675 680.219714 775.94294 C 697.22655 775.945441 711.02607 690.975805 711.010743 586.118402 Z M 711.010743 586.118402 "
      transform="matrix(0.141486,-0.0149515,0.0103356,0.148269,154.93017,128.789953)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(66.27451%,33.72549%,0%);fill-opacity:1;stroke-width:2.5054;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 238.339754 971.785778 C 268.307754 970.118082 255.003768 955.131569 269.974654 946.809131 C 284.972033 938.48626 306.615334 943.477189 313.276358 953.47911 C 319.936894 963.455951 314.936767 973.447274 323.25873 973.462133 C 331.580206 973.451913 557.821175 960.782061 566.14561 969.101048 C 574.470044 977.420035 576.119062 994.076753 567.794363 1000.735378 C 559.469663 1007.394003 273.316228 1015.069608 263.327895 1008.408475 C 253.313068 1001.747773 238.345937 973.466582 238.339754 971.785778 Z M 238.339754 971.785778 "
      transform="matrix(0.147394,0.00253833,-0.00286626,0.155701,184.796229,65.528232)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:3.8527;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 529.598819 405.48021 C 529.581807 445.464712 575.144102 433.358392 576.417664 466.719308 C 575.662918 502.12802 499.698032 470.179514 497.768239 405.460427 C 499.680927 340.722725 572.874623 307.545094 574.468092 344.458875 C 575.728233 375.151284 529.57959 365.496326 529.598819 405.48021 Z M 529.598819 405.48021 "
      transform="matrix(0.107749,0.00142079,-0.00200573,0.0834314,184.475872,186.113097)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:3.8527;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 529.577759 405.43943 C 529.596988 445.423314 575.159283 433.316995 576.397474 466.725332 C 575.67897 502.133428 499.677842 470.185539 497.748049 405.466452 C 499.696979 340.728132 572.854433 307.551118 574.484144 344.464283 C 575.708043 375.157308 529.594771 365.454928 529.577759 405.43943 Z M 529.577759 405.43943 "
      transform="matrix(0.107749,0.00142079,-0.00200573,0.0834314,187.388215,186.151686)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:3.8527;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 529.591951 405.448326 C 529.574938 445.432828 575.137234 433.326508 576.410795 466.687424 C 575.692291 502.095519 499.692034 470.194435 497.762241 405.475348 C 499.711171 340.737029 572.867754 307.51321 574.461223 344.426991 C 575.721364 375.1194 529.572721 365.464442 529.591951 405.448326 Z M 529.591951 405.448326 "
      transform="matrix(0.107749,0.00142079,-0.00200573,0.0834314,190.511704,186.033736)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:3.8527;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 529.608003 405.453734 C 529.59099 445.438235 575.153286 433.331916 576.390605 466.693448 C 575.672102 502.101544 499.707215 470.153037 497.778293 405.480756 C 499.690981 340.743053 572.883806 307.518617 574.477275 344.432399 C 575.701174 375.125424 529.588773 365.469849 529.608003 405.453734 Z M 529.608003 405.453734 "
      transform="matrix(0.107749,0.00142079,-0.00200573,0.0834314,193.424048,186.072324)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(66.27451%,33.72549%,0%);fill-opacity:1;stroke-width:0.39908;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 481.916201 -41.08467 C 481.913729 -42.963371 480.330879 -44.489319 478.379935 -44.489348 C 476.428991 -44.489377 474.845206 -42.96469 474.847677 -41.085989 C 474.845249 -39.207218 476.428099 -37.681269 478.379043 -37.681241 C 480.329987 -37.681212 481.913772 -39.205899 481.916201 -41.08467 Z M 481.916201 -41.08467 "
      transform="matrix(0.79689,0.012001,-0.0176823,0.839993,-118.401326,248.99686)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 447.937586 905.000739 C 447.915616 891.663966 438.605306 880.861197 427.094888 880.856927 C 415.616563 880.855198 406.303682 891.67244 406.29356 905.006671 C 406.283437 918.340903 415.625839 929.146213 427.104165 929.147942 C 438.614583 929.152212 447.927463 918.33497 447.937586 905.000739 Z M 447.937586 905.000739 "
      transform="matrix(0.119787,-0.0180711,0.0244057,0.228194,173.38839,11.860619)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 447.932874 905.004675 C 447.910905 891.667902 438.604027 880.848287 427.122269 880.863405 C 415.611851 880.859135 406.302403 891.65953 406.29228 904.993761 C 406.282158 918.327993 415.621128 929.150149 427.102886 929.135032 C 438.613303 929.139302 447.922751 918.338907 447.932874 905.004675 Z M 447.932874 905.004675 "
      transform="matrix(0.119787,-0.0180711,0.0244057,0.228194,169.138859,11.523698)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 447.911283 904.996993 C 447.921406 891.662762 438.611095 880.859993 427.100678 880.855723 C 415.622352 880.853994 406.309472 891.671236 406.299349 905.005467 C 406.289226 918.339698 415.599536 929.142467 427.109954 929.146737 C 438.58828 929.148466 447.933252 918.333766 447.911283 904.996993 Z M 447.911283 904.996993 "
      transform="matrix(0.119787,-0.0180711,0.0244057,0.228194,166.098664,6.087561)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 447.924831 905.000739 C 447.934077 891.673065 438.610646 880.851077 427.111285 880.857094 C 415.611923 880.863111 406.284283 891.670282 406.308393 905.000369 C 406.299147 918.328043 415.622578 929.150031 427.121939 929.144014 C 438.621301 929.137998 447.915585 918.328413 447.924831 905.000739 Z M 447.924831 905.000739 "
      transform="matrix(0.11517,-0.0182117,0.0267514,0.251754,170.25333,-15.410908)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 447.924331 905.005304 C 447.920946 891.667055 438.618579 880.85415 427.12257 880.863863 C 415.630115 880.857296 406.284652 891.669872 406.291592 904.991841 C 406.294978 918.33009 415.6307 929.145512 427.123155 929.152079 C 438.619164 929.142365 447.931271 918.327273 447.924331 905.005304 Z M 447.924331 905.005304 "
      transform="matrix(0.115211,-0.0178095,0.0251568,0.23605,177.619493,-1.317425)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 447.929857 904.995362 C 447.922917 891.673393 438.62055 880.860488 427.09474 880.851404 C 415.59873 880.861118 406.290178 891.65993 406.293563 904.998179 C 406.296948 918.336429 415.599315 929.149333 427.095324 929.13962 C 438.621135 929.148704 447.933242 918.333612 447.929857 904.995362 Z M 447.929857 904.995362 "
      transform="matrix(0.115211,-0.0178095,0.0251568,0.23605,174.353482,-3.28373)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 447.914589 905.006001 C 447.911203 891.667752 438.608837 880.854847 427.116382 880.84828 C 415.620373 880.857993 406.308266 891.673085 406.28185 904.992538 C 406.285235 918.330787 415.620958 929.146209 427.116967 929.136496 C 438.609421 929.143063 447.921529 918.32797 447.914589 905.006001 Z M 447.914589 905.006001 "
      transform="matrix(0.115211,-0.0178095,0.0251568,0.23605,170.237786,-6.122451)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 447.940395 904.996129 C 447.933455 891.674159 438.597733 880.858738 427.105278 880.852171 C 415.609269 880.861884 406.300716 891.660696 406.304102 904.998946 C 406.307487 918.337195 415.609854 929.1501 427.105863 929.140387 C 438.598318 929.146954 447.910425 918.331861 447.940395 904.996129 Z M 447.940395 904.996129 "
      transform="matrix(0.115211,-0.0178095,0.0251568,0.23605,166.418655,-7.842317)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(50.196078%,29.411765%,100%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 173.178642 346.346076 C 173.178642 346.346076 118.982999 411.252607 151.916192 555.159245 C 185.883639 699.765922 330.881755 744.955566 330.881755 744.955566 C 330.881755 744.955566 357.367667 697.532389 332.614953 580.895957 C 292.647016 411.046721 216.461292 346.35492 216.461292 346.35492 Z M 173.178642 346.346076 "
      transform="matrix(0.128791,0.0234378,-0.0228779,0.132774,219.79168,100.838216)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 447.92005 904.999954 C 447.92558 891.67132 438.599719 880.860294 427.114358 880.85157 C 415.63134 880.859877 406.311634 891.671378 406.306104 905.000012 C 406.300573 918.328646 415.626434 929.139672 427.111795 929.148395 C 438.594814 929.140089 447.91452 918.328587 447.92005 904.999954 Z M 447.92005 904.999954 "
      transform="matrix(0.121091,0.00356733,-0.0166549,0.228891,216.583704,2.627092)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 447.922541 904.995806 C 447.928071 891.667172 438.60221 880.856146 427.11685 880.847422 C 415.601641 880.85623 406.281935 891.667732 406.308595 904.995864 C 406.305407 918.341527 415.628925 929.135524 427.114286 929.144247 C 438.597305 929.135941 447.919353 918.341469 447.922541 904.995806 Z M 447.922541 904.995806 "
      transform="matrix(0.121091,0.00356733,-0.0166549,0.228891,212.462239,1.538189)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 447.916454 904.997387 C 447.921984 891.668753 438.596123 880.857727 427.110762 880.849003 C 415.627744 880.85731 406.308038 891.668811 406.302508 904.997445 C 406.296977 918.326079 415.622838 929.137105 427.108199 929.145828 C 438.591218 929.137522 447.913266 918.34305 447.916454 904.997387 Z M 447.916454 904.997387 "
      transform="matrix(0.121091,0.00356733,-0.0166549,0.228891,210.439565,-4.352776)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 447.909311 904.999727 C 447.93223 891.663699 438.605724 880.853077 427.113683 880.863393 C 415.619185 880.858264 406.279676 891.660422 406.290212 904.996105 C 406.300748 918.331788 415.627254 929.14241 427.121752 929.147539 C 438.613793 929.137223 447.919847 918.33541 447.909311 904.999727 Z M 447.909311 904.999727 "
      transform="matrix(0.116572,0.00260602,-0.0185459,0.252493,218.359307,-24.766665)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 447.915837 905.00705 C 447.910167 891.659365 438.595648 880.855354 427.098507 880.852985 C 415.601367 880.850615 406.280563 891.660483 406.286233 905.008168 C 406.289457 918.339386 415.603976 929.143396 427.101116 929.145765 C 438.598256 929.148135 447.91906 918.338267 447.915837 905.00705 Z M 447.915837 905.00705 "
      transform="matrix(0.116541,0.00300903,-0.0173161,0.236756,223.095747,-9.585929)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 447.914266 904.996034 C 447.911043 891.664816 438.596524 880.860806 427.099384 880.858436 C 415.602243 880.856067 406.281439 891.665934 406.284663 904.997152 C 406.287886 918.328369 415.604852 929.148847 427.101992 929.151217 C 438.596686 929.137118 447.91749 918.327251 447.914266 904.996034 Z M 447.914266 904.996034 "
      transform="matrix(0.116541,0.00300903,-0.0173161,0.236756,220.232458,-12.102847)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 447.921789 904.993665 C 447.918565 891.662447 438.604046 880.858437 427.106906 880.856067 C 415.609766 880.853698 406.288962 891.663565 406.292185 904.994783 C 406.297855 918.342468 415.612374 929.146478 427.109515 929.148848 C 438.606655 929.151218 447.927459 918.34135 447.921789 904.993665 Z M 447.921789 904.993665 "
      transform="matrix(0.116541,0.00300903,-0.0173161,0.236756,216.688571,-15.629653)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 447.90887 905.001899 C 447.939102 891.670256 438.58868 880.850203 427.127442 880.863876 C 415.630302 880.861507 406.309498 891.671374 406.312721 905.002592 C 406.282489 918.334234 415.630463 929.137819 427.094149 929.140614 C 438.591289 929.142984 447.912093 918.333116 447.90887 905.001899 Z M 447.90887 905.001899 "
      transform="matrix(0.116541,0.00300903,-0.0173161,0.236756,213.237094,-18.002657)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(78.823529%,0%,0%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 682.707251 606.117217 C 682.701571 503.115201 656.606143 419.605906 624.433185 419.621488 C 592.230901 419.625089 566.13903 503.107627 566.14471 606.109642 C 566.15039 709.111658 592.216493 792.608973 624.418776 792.605372 C 656.62106 792.60177 682.683606 709.107252 682.707251 606.117217 Z M 682.707251 606.117217 "
      transform="matrix(0.114273,-0.0441424,0.0463368,0.108048,152.676718,147.115358)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:1.4635;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 228.953392 356.749113 C 228.954395 349.617706 224.113739 343.849338 218.127401 343.846625 C 212.154633 343.843585 207.303352 349.625013 207.302349 356.756421 C 207.301105 363.869501 212.155572 369.655869 218.12834 369.658909 C 224.101109 369.661949 228.952389 363.88052 228.953392 356.749113 Z M 228.953392 356.749113 "
      transform="matrix(0.287766,0.00512364,-0.0037938,0.213077,185.20664,87.304206)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:1.5462;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 228.956576 356.742011 C 228.957965 349.628931 224.114878 343.84849 218.129483 343.849093 C 212.159235 343.84937 207.304288 349.618725 207.303168 356.750132 C 207.302048 363.881539 212.160283 369.661654 218.130531 369.661377 C 224.100779 369.6611 228.955456 363.873418 228.956576 356.742011 Z M 228.956576 356.742011 "
      transform="matrix(0.257789,0.00458989,-0.0037938,0.213077,194.084932,83.537283)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:1.5462;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 228.948773 356.745014 C 228.950162 349.631934 224.107075 343.851493 218.136827 343.85177 C 212.151432 343.852373 207.311633 349.621402 207.310513 356.752809 C 207.309393 363.884216 212.15221 369.646331 218.122458 369.646054 C 224.108124 369.663777 228.947653 363.876422 228.948773 356.745014 Z M 228.948773 356.745014 "
      transform="matrix(0.257789,0.00458989,-0.0037938,0.213077,190.32133,83.470273)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:1.56;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 228.946596 356.740775 C 228.954109 349.632337 224.114035 343.851052 218.13384 343.838429 C 212.153903 343.845328 207.307177 349.624884 207.299922 356.752844 C 207.307141 363.880457 212.147215 369.661742 218.127153 369.654843 C 224.10709 369.647944 228.953816 363.868387 228.946596 356.740775 Z M 228.946596 356.740775 "
      transform="matrix(0.269778,0.00480336,-0.00356153,0.200031,188.454962,85.228415)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:1.56;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 228.945915 356.756191 C 228.95317 349.628231 224.113096 343.846946 218.133159 343.853845 C 212.152964 343.841222 207.306238 349.620779 207.313458 356.748391 C 207.306203 363.876351 212.146277 369.657636 218.126214 369.650737 C 224.106409 369.66336 228.953135 363.883803 228.945915 356.756191 Z M 228.945915 356.756191 "
      transform="matrix(0.269778,0.00480336,-0.00356153,0.200031,188.127075,81.967522)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:1.5462;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 228.952193 356.755807 C 228.953312 349.6244 224.110225 343.843959 218.124829 343.844562 C 212.154582 343.844839 207.299635 349.614194 207.313663 356.745275 C 207.312543 363.876682 212.15563 369.657123 218.125878 369.656846 C 224.111274 369.656243 228.950803 363.868887 228.952193 356.755807 Z M 228.952193 356.755807 "
      transform="matrix(0.257789,0.00458989,-0.0037938,0.213077,186.726739,81.272645)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:1.5623;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 228.959334 356.75806 C 228.947463 349.62817 224.105994 343.839625 218.135747 343.839908 C 212.150351 343.840524 207.308933 349.617419 207.305656 356.747642 C 207.302379 363.877865 212.158726 369.647369 218.128974 369.647086 C 224.11437 369.64647 228.955787 363.869575 228.959334 356.75806 Z M 228.959334 356.75806 "
      transform="matrix(0.257789,0.00458989,-0.00371642,0.208731,187.541051,79.299157)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:1.4635;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 228.956971 356.752274 C 228.957974 349.620867 224.103748 343.852825 218.13098 343.849786 C 212.158212 343.846746 207.306931 349.628174 207.305687 356.741255 C 207.304684 363.872662 212.159151 369.65903 218.13192 369.66207 C 224.104446 369.646783 228.955968 363.883681 228.956971 356.752274 Z M 228.956971 356.752274 "
      transform="matrix(0.287766,0.00512364,-0.0037938,0.213077,176.420466,77.717576)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:1.56;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 228.949451 356.743469 C 228.956706 349.615509 224.102415 343.854093 218.136695 343.841123 C 212.156758 343.848022 207.310032 349.627579 207.302777 356.755538 C 207.309996 363.883151 212.15007 369.664436 218.130008 369.657537 C 224.109945 369.650638 228.956671 363.871081 228.949451 356.743469 Z M 228.949451 356.743469 "
      transform="matrix(0.269778,0.00480336,-0.00356153,0.200031,185.993264,77.888018)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:1.5623;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 228.953852 356.748606 C 228.95713 349.618383 224.100783 343.848879 218.130535 343.849162 C 212.145139 343.849778 207.303722 349.626673 207.300444 356.756896 C 207.312045 363.868078 212.153514 369.656623 218.123762 369.65634 C 224.109158 369.655724 228.950575 363.878829 228.953852 356.748606 Z M 228.953852 356.748606 "
      transform="matrix(0.257789,0.00458989,-0.00371642,0.208731,184.757273,75.324593)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:1.5462;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 228.958304 356.758313 C 228.959424 349.626906 224.101189 343.846791 218.130941 343.847068 C 212.145545 343.847671 207.305746 349.6167 207.304626 356.748107 C 207.303507 363.879514 212.146594 369.659955 218.131989 369.659352 C 224.102237 369.659075 228.956915 363.871393 228.958304 356.758313 Z M 228.958304 356.758313 "
      transform="matrix(0.257789,0.00458989,-0.0037938,0.213077,178.869705,77.986927)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:1.5623;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 228.958846 356.758062 C 228.946976 349.628172 224.105507 343.839627 218.135259 343.839909 C 212.149863 343.840525 207.308446 349.617421 207.305169 356.747644 C 207.301891 363.877867 212.158238 369.64737 218.128486 369.647088 C 224.113882 369.646472 228.955299 363.869576 228.958846 356.758062 Z M 228.958846 356.758062 "
      transform="matrix(0.257789,0.00458989,-0.00371642,0.208731,179.810708,77.486659)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:1.5623;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 228.95877 356.757343 C 228.946899 349.627453 224.10543 343.838908 218.135183 343.839191 C 212.149787 343.839807 207.30837 349.616702 207.305092 356.746925 C 207.301815 363.877148 212.158162 369.646652 218.12841 369.646369 C 224.113806 369.645753 228.955223 363.868858 228.95877 356.757343 Z M 228.95877 356.757343 "
      transform="matrix(0.257789,0.00458989,-0.00371642,0.208731,180.994319,75.221184)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.5449;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 596.132114 564.478266 C 596.128817 450.909048 565.93005 358.846287 528.679947 358.832555 C 491.418336 358.847894 461.233961 450.903319 461.237258 564.472537 C 461.240555 678.041754 491.427813 770.133586 528.689424 770.118247 C 565.912749 770.121715 596.108633 678.03722 596.132114 564.478266 Z M 596.132114 564.478266 "
      transform="matrix(0.126659,-0.0447157,0.0501447,0.11667,158.931082,139.408029)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 542.004052 646.058252 C 542.01701 623.534658 536.029652 605.261552 528.687088 605.28638 C 521.329527 605.272374 515.351761 623.542457 515.365916 646.078569 C 515.352958 668.602163 521.313203 686.862752 528.670764 686.876759 C 536.040441 686.864447 542.018207 668.594363 542.004052 646.058252 Z M 542.004052 646.058252 "
      transform="matrix(0.118821,-0.0565102,0.0546986,0.122409,163.490463,136.608197)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:1.5327;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 139.878934 643.984885 C 139.869336 701.673317 121.11274 730.160405 105.318333 754.303795 C 112.832191 721.847396 118.21801 701.673303 118.216928 644.005252 C 118.226526 586.316819 147.808609 558.667418 158.596234 542.008375 C 154.420434 558.655331 139.877852 586.316833 139.868254 644.005266 Z M 139.878934 643.984885 "
      transform="matrix(0.244825,-0.0676562,0.128289,0.156202,143.989613,94.684215)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:1.5327;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 139.865146 643.986431 C 139.879232 701.660115 121.111956 730.167584 105.317549 754.310974 C 112.842087 721.834194 118.217226 701.680482 118.226824 643.992049 C 118.225742 586.323998 147.794821 558.668965 158.59545 542.015555 C 154.41965 558.662511 139.864064 586.31838 139.87815 643.992064 Z M 139.865146 643.986431 "
      transform="matrix(0.244825,-0.0676562,0.128289,0.156202,144.656853,101.960385)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:1.5327;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 139.866566 643.983138 C 139.862444 701.66839 121.111826 730.164657 105.318632 754.311401 C 112.83599 721.831836 118.224059 701.681322 118.22818 643.99607 C 118.221363 586.331083 147.800664 558.67296 158.607144 542.011106 C 154.419889 558.658424 139.872523 586.324215 139.868401 644.009467 Z M 139.866566 643.983138 "
      transform="matrix(0.243425,-0.07284,0.131388,0.153441,143.079848,110.323916)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:1.3887;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 542.005661 646.078286 C 541.991783 623.544261 536.038431 605.270132 528.674974 605.270214 C 521.326424 605.274392 515.362135 623.532735 515.361106 646.062664 C 515.360076 668.592592 521.328336 686.870818 528.676885 686.866639 C 536.040342 686.866558 542.004631 668.608215 542.005661 646.078286 Z M 542.005661 646.078286 "
      transform="matrix(0.235539,-0.0599431,0.0964103,0.21811,69.672802,68.100766)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:1.6398;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 542.00145 646.065916 C 542.004055 623.544371 536.042611 605.271052 528.68429 605.278643 C 521.333112 605.26604 515.368979 623.533751 515.359231 646.075489 C 515.356626 668.597034 521.31807 686.870353 528.676392 686.862763 C 536.034713 686.855172 541.991703 668.607654 542.00145 646.065916 Z M 542.00145 646.065916 "
      transform="matrix(0.211891,-0.0364432,0.0749528,0.180552,93.101396,84.939673)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:1.6398;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 542.011459 646.064691 C 542.002322 623.53816 536.031163 605.264409 528.675855 605.282628 C 521.327331 605.280585 515.36053 623.540886 515.352314 646.064372 C 515.361451 668.590902 521.33261 686.864653 528.681134 686.866696 C 536.029658 686.868739 541.996459 668.608439 542.011459 646.064691 Z M 542.011459 646.064691 "
      transform="matrix(0.212608,-0.0319448,0.0711839,0.18209,91.645753,84.969436)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.0966;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 542.009749 646.065367 C 541.994708 623.541618 536.048985 605.265986 528.683799 605.262016 C 521.308579 605.284728 515.35494 623.544608 515.369981 646.068358 C 515.354019 668.613904 521.330745 686.867739 528.674962 686.866823 C 536.040148 686.870794 541.993787 668.610914 542.009749 646.065367 Z M 542.009749 646.065367 "
      transform="matrix(0.171274,-0.0313642,0.0644009,0.134601,120.783458,103.034679)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.0966;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 542.014396 646.058567 C 542.001526 623.530714 536.047644 605.270676 528.680242 605.287957 C 521.32155 605.27829 515.351875 623.538709 515.364745 646.066562 C 515.356137 668.590996 521.331496 686.854453 528.690189 686.86412 C 536.027404 686.870368 541.997079 668.60995 542.014396 646.058567 Z M 542.014396 646.058567 "
      transform="matrix(0.172979,-0.0219453,0.0559082,0.137863,122.400404,98.225729)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.0862;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 145.714808 569.467349 C 145.692604 603.461252 138.982178 631.079507 130.702676 631.081274 C 122.447414 631.093807 115.72376 603.474927 115.721726 569.47026 "
      transform="matrix(0.132589,-0.0697925,0.0643215,0.157152,191.711013,92.876248)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.0966;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 542.011879 646.066815 C 541.99901 623.538962 536.045128 605.278924 528.686435 605.269257 C 521.319033 605.286538 515.349358 623.546956 515.362228 646.074809 C 515.353621 668.599243 521.32898 686.862701 528.687672 686.872368 C 536.033597 686.851668 542.003272 668.591249 542.011879 646.066815 Z M 542.011879 646.066815 "
      transform="matrix(0.172979,-0.0219453,0.0559082,0.137863,122.041003,91.947193)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.0966;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 542.006256 646.058551 C 541.995012 623.534817 536.044792 605.279634 528.680021 605.280839 C 521.315249 605.282045 515.348085 623.549423 515.359329 646.073158 C 515.370573 668.596892 521.320793 686.852075 528.677338 686.877889 C 536.04211 686.876684 542.009274 668.609306 542.006256 646.058551 Z M 542.006256 646.058551 "
      transform="matrix(0.173448,-0.0185411,0.052809,0.138925,120.133928,91.225281)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.8797;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 145.694788 569.482523 C 145.712683 603.481924 138.967321 631.08327 130.704915 631.076222 C 122.442509 631.069174 115.721306 603.472875 115.752974 569.467403 "
      transform="matrix(0.0864643,-0.0658062,0.0624556,0.106195,195.686791,114.056961)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.8797;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 145.690475 569.454337 C 145.690017 603.479148 138.994218 631.074422 130.713457 631.092784 C 122.45105 631.085736 115.729848 603.489438 115.730307 569.464626 "
      transform="matrix(0.0864643,-0.0658062,0.0624556,0.106195,197.157674,111.661233)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.0862;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 145.702412 569.470463 C 145.709031 603.484545 138.97678 631.083716 130.721489 631.079544 C 122.430404 631.080099 115.730469 603.470127 115.72385 569.456045 "
      transform="matrix(0.120617,-0.0899778,0.0870269,0.145063,184.796224,93.559402)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,57.254902%,0%);fill-opacity:1;stroke-width:2.7424;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 145.693106 569.474659 C 145.707855 603.467337 138.9987 631.079682 130.722839 631.071715 C 122.430264 631.086755 115.74456 603.473594 115.729811 569.480917 "
      transform="matrix(0.0863166,-0.0723297,0.0627058,0.117244,199.277379,104.243031)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(50.196078%,29.411765%,100%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 276.275393 345.414456 C 263.976494 354.58552 276.669045 370.550809 288.293928 376.090347 C 301.349919 383.756064 374.892278 434.279219 424.625583 388.095467 C 383.823094 388.916345 305.935611 324.899074 276.275393 345.414456 Z M 276.275393 345.414456 "
      transform="matrix(0.113673,0.00539662,-0.00302342,0.163554,216.994736,96.714268)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(50.196078%,29.411765%,100%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 276.26225 345.402556 C 263.997684 354.572487 276.69087 370.561639 288.280785 376.078447 C 301.33741 383.768027 374.879135 434.267319 424.61244 388.083567 C 383.810585 388.928308 305.957436 324.909904 276.26225 345.402556 Z M 276.26225 345.402556 "
      transform="matrix(0.113673,0.00539662,-0.00302342,0.163554,215.976663,92.435036)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(50.196078%,29.411765%,100%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 276.256591 345.405233 C 263.992025 354.575164 276.685211 370.564316 288.275126 376.081124 C 301.366085 383.769571 374.907809 434.268864 424.606781 388.086244 C 383.804926 388.930985 305.951777 324.912581 276.256591 345.405233 Z M 276.256591 345.405233 "
      transform="matrix(0.113673,0.00539662,-0.00302342,0.163554,214.684345,88.344785)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(50.196078%,29.411765%,100%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 276.270261 345.410803 C 264.005696 354.580734 276.663913 370.547156 288.288796 376.086694 C 301.344787 383.752411 374.887146 434.275567 424.620452 388.091814 C 383.817962 388.912693 305.964813 324.894289 276.270261 345.410803 Z M 276.270261 345.410803 "
      transform="matrix(0.113673,0.00539662,-0.00302342,0.163554,211.866402,83.660206)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(50.196078%,29.411765%,100%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 211.163689 247.309411 C 232.933107 235.239995 267.780719 237.562665 290.906335 258.47997 C 310.263598 275.401269 336.211129 333.657366 361.230113 351.29562 C 340.996663 351.317999 329.923756 345.528934 318.970843 336.126878 C 290.221017 351.556389 280.862503 352.832021 255.099935 351.750591 C 219.222249 350.429303 192.647829 334.098773 186.924464 311.15919 C 180.760161 289.341752 191.854057 258.64094 211.163689 247.309411 Z M 211.163689 247.309411 "
      transform="matrix(0.152552,-0.0270752,0.0320958,0.132084,191.970039,110.207836)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.373332 323.020685 C 301.38021 314.751629 295.056245 308.0465 287.222244 308.061441 C 279.409729 308.035737 273.085379 314.752494 273.078502 323.021551 C 273.072013 331.330541 279.417854 338.034958 287.22998 338.020729 C 295.042105 338.006499 301.38872 331.328964 301.373332 323.020685 Z M 301.373332 323.020685 "
      transform="matrix(0.178505,0.00317826,-0.0017411,0.0977881,187.636743,109.009254)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:2.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 227.841584 348.250766 C 227.825623 339.482535 217.412437 332.36314 204.582949 332.380585 C 191.738248 332.360834 181.316287 339.490764 181.332249 348.258996 C 181.315059 357.044252 191.728244 364.163646 204.581915 364.156287 C 217.435585 364.148928 227.824394 357.036022 227.841584 348.250766 Z M 227.841584 348.250766 "
      transform="matrix(0.141949,-0.0528064,0.0469646,0.126618,185.244074,114.151438)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 239.128906 141.429688 C 239.136719 140.996094 238.738281 140.636719 238.234375 140.628906 C 237.734375 140.621094 237.320312 140.964844 237.3125 141.398438 C 237.304688 141.832031 237.707031 142.191406 238.207031 142.199219 C 238.710938 142.210938 239.121094 141.863281 239.128906 141.429688 Z M 239.128906 141.429688 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:6.5598;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 516.82272 260.320707 C 521.216425 278.422862 510.099077 303.382477 483.680867 312.908082 C 456.873658 326.053984 437.580454 354.739776 427.999009 400.925764 C 380.809494 297.619655 404.935453 252.645462 448.606242 240.578803 C 485.991497 226.230718 509.070934 227.187678 516.819358 260.257498 Z M 516.82272 260.320707 "
      transform="matrix(0.0671721,0.00184224,-0.00357226,0.0617002,198.999038,126.462621)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 230.722656 143.925781 C 230.703125 143.5625 230.398438 143.285156 230.046875 143.304688 C 229.691406 143.324219 229.421875 143.632812 229.441406 143.996094 C 229.460938 144.359375 229.765625 144.636719 230.117188 144.617188 C 230.472656 144.597656 230.742188 144.289062 230.722656 143.925781 Z M 230.722656 143.925781 "
    />
  </g>
</svg>
