<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.588235%,24.313725%,17.647059%);fill-opacity:1;"
      d="M -54.8125 0 L 532.851562 0 L 532.851562 215.5 L -54.8125 215.5 Z M -54.8125 0 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(99.607843%,78.039216%,29.019608%);fill-opacity:1;stroke-width:1.6651;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.705882%,66.666667%,24.705882%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 204.302444 282.111089 C 184.863556 266.933312 148.669111 272.066645 142.702444 230.8222 C 170.419111 252.294423 165.391333 229.594423 206.785778 250.055534 Z M 204.302444 282.111089 "
      transform="matrix(0.703125,0,0,0.703125,60.6975,0.0000153113)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(99.607843%,78.039216%,29.019608%);fill-opacity:1;stroke-width:1.6651;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.705882%,66.666667%,24.705882%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 209.469111 263.349978 C 195.619111 242.949978 159.585778 236.749978 166.546889 195.661089 C 186.352444 224.588867 188.519111 201.444423 221.658 233.594423 Z M 209.469111 263.349978 "
      transform="matrix(0.703125,0,0,0.703125,60.6975,0.0000153113)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(99.607843%,78.039216%,29.019608%);fill-opacity:1;stroke-width:1.6651;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.705882%,66.666667%,24.705882%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 215.769111 250.011089 C 210.769111 225.866645 179.846889 206.355534 201.985778 171.049978 C 209.230222 205.355534 220.080222 184.794423 238.408 227.177756 Z M 215.769111 250.011089 "
      transform="matrix(0.703125,0,0,0.703125,60.6975,0.0000153113)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(99.607843%,78.039216%,29.019608%);fill-opacity:1;stroke-width:1.6651;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.705882%,66.666667%,24.705882%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 230.319111 237.611089 C 232.974667 213.094423 209.535778 185.038867 241.441333 158.233312 C 237.802444 193.105534 254.441333 176.866645 258.874667 222.827756 Z M 230.319111 237.611089 "
      transform="matrix(0.703125,0,0,0.703125,60.6975,0.0000153113)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(99.607843%,78.039216%,29.019608%);fill-opacity:1;stroke-width:1.6651;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.705882%,66.666667%,24.705882%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 243.258 227.6722 C 255.719111 206.388867 245.819111 171.194423 285.908 159.805534 C 268.308 190.127756 290.135778 182.127756 275.363556 225.8722 Z M 243.258 227.6722 "
      transform="matrix(0.703125,0,0,0.703125,60.6975,0.0000153113)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(99.607843%,78.039216%,29.019608%);fill-opacity:1;stroke-width:1.6651;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.705882%,66.666667%,24.705882%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 262.069111 230.288867 C 280.630222 214.055534 282.396889 177.538867 324.024667 179.438867 C 297.724667 202.6222 320.963556 201.949978 293.085778 238.761089 Z M 262.069111 230.288867 "
      transform="matrix(0.703125,0,0,0.703125,60.6975,0.0000153113)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(99.607843%,78.039216%,29.019608%);fill-opacity:1;stroke-width:1.6651;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.705882%,66.666667%,24.705882%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 278.569111 239.8722 C 301.074667 229.799978 313.485778 195.405534 352.724667 209.444423 C 320.774667 223.888867 343.185778 230.066645 305.735778 257.0722 Z M 278.569111 239.8722 "
      transform="matrix(0.703125,0,0,0.703125,60.6975,0.0000153113)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(99.607843%,78.039216%,29.019608%);fill-opacity:1;stroke-width:1.6651;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.705882%,66.666667%,24.705882%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 292.491333 254.927756 C 316.919111 251.599978 338.508 222.094423 372.208 246.605534 C 337.485778 251.4722 357.252444 263.711089 313.713556 279.083312 L 292.491333 254.933312 Z M 292.491333 254.927756 "
      transform="matrix(0.703125,0,0,0.703125,60.6975,0.0000153113)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(99.607843%,78.039216%,29.019608%);fill-opacity:1;stroke-width:1.6651;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.705882%,66.666667%,24.705882%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 379.458 259.599978 L 267.402444 262.061089 L 272.102444 292.255534 L 379.463556 259.599978 Z M 379.458 259.599978 "
      transform="matrix(0.703125,0,0,0.703125,60.6975,0.0000153113)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(99.607843%,78.039216%,29.019608%);fill-opacity:1;stroke-width:1.6651;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.705882%,66.666667%,24.705882%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 363.002444 218.411089 L 259.158 258.266645 L 276.546889 291.644423 Z M 363.002444 218.411089 "
      transform="matrix(0.703125,0,0,0.703125,60.6975,0.0000153113)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(99.607843%,78.039216%,29.019608%);fill-opacity:1;stroke-width:1.6651;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.705882%,66.666667%,24.705882%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 280.580222 280.6722 L 334.302444 183.127756 L 251.896889 259.833312 Z M 280.580222 280.6722 "
      transform="matrix(0.703125,0,0,0.703125,60.6975,0.0000153113)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(99.607843%,78.039216%,29.019608%);fill-opacity:1;stroke-width:1.6651;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.705882%,66.666667%,24.705882%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 279.458 272.627756 L 296.730222 163.161089 L 242.919111 260.444423 L 279.458 272.633312 Z M 279.458 272.627756 "
      transform="matrix(0.703125,0,0,0.703125,60.6975,0.0000153113)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(99.607843%,78.039216%,29.019608%);fill-opacity:1;stroke-width:1.6651;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.705882%,66.666667%,24.705882%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 273.008 263.738867 L 254.974667 153.488867 L 234.985778 264.2222 L 273.013556 263.738867 Z M 273.008 263.738867 "
      transform="matrix(0.703125,0,0,0.703125,60.6975,0.0000153113)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(99.607843%,78.039216%,29.019608%);fill-opacity:1;stroke-width:1.6651;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.705882%,66.666667%,24.705882%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 263.680222 254.288867 L 211.385778 162.216645 L 231.485778 274.138867 L 263.680222 254.294423 Z M 263.680222 254.288867 "
      transform="matrix(0.703125,0,0,0.703125,60.6975,0.0000153113)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(99.607843%,78.039216%,29.019608%);fill-opacity:1;stroke-width:1.6651;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.705882%,66.666667%,24.705882%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 255.558 253.599978 L 174.458 185.1222 L 232.124667 283.1222 L 255.563556 253.599978 Z M 255.558 253.599978 "
      transform="matrix(0.703125,0,0,0.703125,60.6975,0.0000153113)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(99.607843%,78.039216%,29.019608%);fill-opacity:1;stroke-width:1.6651;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.705882%,66.666667%,24.705882%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 145.952444 218.888867 L 233.019111 290.783312 L 246.408 253.177756 L 145.946889 218.888867 Z M 145.952444 218.888867 "
      transform="matrix(0.703125,0,0,0.703125,60.6975,0.0000153113)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(99.607843%,78.039216%,29.019608%);fill-opacity:1;stroke-width:1.6651;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.705882%,66.666667%,24.705882%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 232.102444 260.849978 L 129.791333 259.411089 L 231.669111 293.4222 L 232.096889 260.849978 Z M 232.102444 260.849978 "
      transform="matrix(0.703125,0,0,0.703125,60.6975,0.0000153113)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(99.607843%,78.039216%,29.019608%);fill-opacity:1;stroke-width:1.187;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.705882%,66.666667%,24.705882%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 429.7684 602.068863 C 429.7684 626.234816 410.180591 645.822625 386.018598 645.822625 C 361.856604 645.822625 342.268796 626.234816 342.268796 602.068863 C 342.268796 577.906869 361.856604 558.319061 386.018598 558.319061 C 410.180591 558.319061 429.7684 577.906869 429.7684 602.068863 Z M 429.7684 602.068863 "
      transform="matrix(0.986344,0,0,0.986344,-141.63375,-397.518735)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,34.901961%,53.72549%);fill-opacity:1;"
      d="M -55.402344 213.328125 L 535.152344 213.328125 L 535.152344 360 L -55.402344 360 Z M -55.402344 213.328125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M -55.738281 319.21875 C -44.757812 324.300781 -29.027344 337.019531 -12.199219 337.011719 C 15.953125 336.828125 16.703125 317.726562 45.222656 318.636719 C 73.816406 319.363281 68.644531 339.472656 107.253906 339.371094 C 139.15625 339.289062 149.460938 314.996094 176.957031 317.90625 C 197.324219 316.859375 205.65625 340.910156 236.960938 341.214844 C 269.421875 341.910156 281.34375 315.003906 301.710938 317.230469 C 323.40625 317.230469 330.511719 338.902344 360.949219 339.058594 C 399.71875 339.253906 406.53125 316.5 430.785156 317.957031 C 448.046875 317.59375 461.660156 334.632812 486.957031 335.054688 C 507.140625 335.398438 524.039062 320.140625 535.457031 314.683594 L 536.019531 288.789062 C 524.054688 293.238281 506.21875 307.824219 488.378906 307.984375 C 462.707031 308.925781 446.828125 291.148438 428.988281 291.308594 C 407.617188 291.5 399.101562 313.304688 362.84375 313.304688 C 329.71875 313.304688 322.121094 291.308594 300.746094 291.308594 C 279.855469 291.46875 273.453125 315.234375 237.285156 314.792969 C 207.785156 314.4375 196.289062 292.109375 175.394531 292.269531 C 153.246094 292.269531 130.34375 313.980469 105.820312 312.933594 C 71.972656 311.503906 64.660156 292.269531 42.511719 292.269531 C 25.957031 292.269531 8.09375 310.292969 -11.847656 310.59375 C -31.679688 310.898438 -53.960938 292.660156 -56.023438 292.269531 L -55.742188 319.21875 Z M -55.738281 319.21875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M -55.738281 267.941406 C -44.757812 273.023438 -29.027344 285.742188 -12.199219 285.730469 C 15.953125 285.546875 16.703125 266.449219 45.222656 267.355469 C 73.816406 268.085938 68.644531 288.191406 107.253906 288.09375 C 139.15625 288.007812 149.460938 263.71875 176.957031 266.628906 C 197.324219 265.582031 205.65625 289.628906 236.960938 289.9375 C 269.421875 290.628906 281.34375 263.722656 301.710938 265.949219 C 323.40625 265.949219 330.511719 287.621094 360.949219 287.777344 C 399.71875 287.972656 406.53125 265.222656 430.785156 266.679688 C 448.046875 266.3125 461.660156 283.351562 486.957031 283.777344 C 507.140625 284.121094 524.039062 268.859375 535.457031 263.402344 L 536.019531 237.511719 C 524.054688 241.957031 506.21875 256.546875 488.378906 256.707031 C 462.707031 257.644531 446.828125 239.867188 428.988281 240.03125 C 407.617188 240.222656 399.101562 262.027344 362.84375 262.027344 C 329.71875 262.027344 322.121094 240.03125 300.746094 240.03125 C 279.855469 240.191406 273.453125 263.953125 237.285156 263.515625 C 207.785156 263.160156 196.289062 240.828125 175.394531 240.988281 C 153.246094 240.988281 130.34375 262.703125 105.820312 261.65625 C 71.972656 260.222656 64.660156 240.988281 42.511719 240.988281 C 25.957031 240.988281 8.09375 259.015625 -11.847656 259.316406 C -31.679688 259.621094 -53.960938 241.378906 -56.023438 240.988281 L -55.742188 267.941406 Z M -55.738281 267.941406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M -55.738281 217.210938 C -44.769531 222.292969 -29.054688 235.011719 -12.242188 235 C 15.886719 234.816406 16.636719 215.71875 45.128906 216.625 C 73.695312 217.355469 68.527344 237.460938 107.101562 237.363281 C 138.972656 237.277344 149.269531 212.988281 176.742188 215.898438 C 197.085938 214.851562 205.414062 238.898438 236.6875 239.207031 C 269.121094 239.898438 281.03125 212.992188 301.375 215.21875 C 323.050781 215.21875 330.152344 236.890625 360.5625 237.046875 C 399.292969 237.242188 406.101562 214.492188 430.332031 215.949219 C 447.578125 215.582031 461.179688 232.621094 486.449219 233.046875 C 506.617188 233.390625 523.5 218.128906 534.902344 212.671875 L 535.46875 186.78125 C 523.515625 191.226562 505.691406 205.816406 487.871094 205.976562 C 462.226562 206.914062 446.359375 189.136719 428.539062 189.300781 C 407.183594 189.492188 398.675781 211.296875 362.453125 211.296875 C 329.359375 211.296875 321.765625 189.300781 300.414062 189.300781 C 279.542969 189.460938 273.148438 213.222656 237.011719 212.785156 C 207.539062 212.429688 196.050781 190.097656 175.179688 190.257812 C 153.050781 190.257812 130.171875 211.972656 105.667969 210.925781 C 71.851562 209.492188 64.546875 190.257812 42.417969 190.257812 C 25.878906 190.257812 8.035156 208.285156 -11.890625 208.585938 C -31.683594 208.894531 -53.941406 190.65625 -56.003906 190.261719 L -55.722656 217.210938 Z M -55.738281 217.210938 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,78.431373%,29.411765%);fill-opacity:1;stroke-width:1.665125;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.705882%,66.666667%,24.705882%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 136.808 75.955534 C 197.858 71.777756 186.730222 66.561089 211.302444 62.638867 C 242.269111 66.899978 245.808 85.644423 263.063556 97.144423 C 263.063556 97.144423 256.574667 119.0722 235.352444 115.966645 C 232.502444 107.1722 245.630222 104.449978 207.858 81.427756 C 185.902444 80.1222 146.608 85.283312 136.808 75.955534 Z M 136.808 75.955534 "
      transform="matrix(0.703125,0,0,0.703125,60.6975,0.0000153113)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,78.431373%,29.411765%);fill-opacity:1;stroke-width:1.665125;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.705882%,66.666667%,24.705882%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 326.069111 109.888867 L 282.091333 111.699978 L 282.091333 122.566645 C 311.752444 123.516645 317.102444 119.549978 326.069111 109.888867 Z M 326.069111 109.888867 "
      transform="matrix(0.703125,0,0,0.703125,60.6975,0.0000153113)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,78.431373%,29.411765%);fill-opacity:1;stroke-width:1.6651;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(84.705882%,66.666667%,24.705882%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 174.841333 108.0722 C 182.502444 104.149978 186.319111 105.749978 192.958 105.488867 C 197.530222 113.766645 201.841333 114.544423 211.841333 115.577756 C 227.108 137.049978 248.580222 137.311089 251.680222 137.311089 C 281.602444 136.533312 291.091333 115.577756 311.185778 113.249978 L 332.396889 113.249978 C 328.863556 107.216645 326.102444 103.766645 318.424667 103.4222 C 302.646889 102.561089 282.208 102.988867 264.358 107.044423 L 239.263556 113.249978 C 231.935778 109.716645 214.002444 90.916645 202.530222 91.261089 C 195.808 93.0722 195.808 95.399978 192.441333 97.988867 C 185.974667 100.749978 179.508 99.427756 174.841333 108.066645 Z M 174.841333 108.0722 "
      transform="matrix(0.703125,0,0,0.703125,60.6975,0.0000153113)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,78.431373%,29.411765%);fill-opacity:1;stroke-width:1.6303;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.705882%,66.666667%,24.705882%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.538912 451.107391 C 330.538912 452.826165 329.146487 454.218589 327.433153 454.218589 C 325.714379 454.218589 324.327394 452.826165 324.327394 451.107391 C 324.327394 449.394057 325.714379 448.001632 327.433153 448.001632 C 329.146487 448.001632 330.538912 449.394057 330.538912 451.107391 Z M 330.538912 451.107391 "
      transform="matrix(0.718172,0,0,0.718172,-32.473594,-254.425766)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,78.431373%,29.411765%);fill-opacity:1;stroke-width:1.665125;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.705882%,66.666667%,24.705882%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 225.558 107.8222 C 270.485778 53.666645 309.724667 60.305534 360.858 56.599978 C 362.585778 62.894423 361.980222 72.294423 337.580222 80.655534 C 304.208 85.5722 244.446889 120.755534 244.185778 120.755534 C 233.063556 120.238867 225.302444 108.594423 225.563556 107.816645 Z M 225.558 107.8222 "
      transform="matrix(0.703125,0,0,0.703125,60.6975,0.0000153113)"
    />
    <path
      style="fill:none;stroke-width:1.665125;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(85.098039%,64.313725%,24.313725%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 317.869111 72.533312 L 351.002444 73.9722 "
      transform="matrix(0.703125,0,0,0.703125,60.6975,0.0000153113)"
    />
    <path
      style="fill:none;stroke-width:1.665125;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(85.098039%,64.313725%,24.313725%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 314.258 77.944423 L 338.413556 79.911089 "
      transform="matrix(0.703125,0,0,0.703125,60.6975,0.0000153113)"
    />
  </g>
</svg>
