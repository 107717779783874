<script>
  export let width = 40;
  export let height = 40;
  export let fill = "var(--blue-100)";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 40 40"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M35.834 20.6759C35.834 29.4203 28.7451 36.5092 20.0007 36.5092C11.254 36.5092 4.16736 29.4203 4.16736 20.6759C4.16736 11.9292 11.254 4.84253 20.0007 4.84253C28.7451 4.84253 35.834 11.9292 35.834 20.6759Z"
    stroke="#EAECF1"
    stroke-width="6"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M20.0007 4.84253C28.7451 4.84253 35.834 11.9292 35.834 20.6759"
    stroke={fill}
    stroke-width="6"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <animateTransform
      attributeName="transform"
      attributeType="XML"
      type="rotate"
      from="0 20 20.6759"
      to="360 20 20.6759"
      dur="1s"
      repeatCount="indefinite"
    />
  </path>
</svg>
