<script>
  import { t } from "stores/i18n.js";
  import { theme } from "stores/theme.js";
  import styles from "styleguide/FrontendFooter.json";

  export let footerLinks;
</script>

<div data-component="FrontendFooter" class={styles.footer}>
  <ul class={styles.links}>
    <li class={styles.link}>
      <a
        target="blank"
        href={$footerLinks.privacy
          ? $footerLinks.privacy
          : "https://www.auditi.de/privacy"}>{$t("frontend_footer.privacy")}</a
      >
    </li>
    <li class={styles.link}>
      <a
        target="blank"
        href={$footerLinks.imprint
          ? $footerLinks.imprint
          : "https://www.auditi.de/imprint"}>{$t("frontend_footer.imprint")}</a
      >
    </li>
    {#if $theme == "aude"}
      <li class={styles.link}>
        <a
          target="blank"
          href={$footerLinks.root
            ? $footerLinks.root
            : "https://www.auditi.de/"}>{$t("frontend_footer.learnmore")}</a
        >
      </li>
    {/if}
  </ul>
</div>

<style lang="scss">
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 35px;
    background-color: var(--header-background);
  }

  .links {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0 2rem 0 0;
  }

  .link {
    font-size: 0.75rem;
    font-weight: 500;
    color: var(--primary-300);
    margin-right: 1.5rem;
  }

  .link a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: var(--primary-500);
    }
  }
</style>
