<script>
  import styles from "styleguide/Breadcrumb.json";
  import { breadcrumb } from "stores/breadcrumb.js";
  import { t } from "stores/i18n.js";
  import { LegacyButton } from "components";

  let breadcrumbElement;

  function truncate(name) {
    const segmentLength = 45;
    return name?.length > segmentLength
      ? [name.slice(0, segmentLength / 2), name.slice(-segmentLength / 2)].join(
          "…",
        )
      : name;
  }
</script>

<div bind:this={breadcrumbElement}>
  {#if $breadcrumb}
    <ul class={styles.breadcrumb}>
      {#each $breadcrumb as { name, translation, path, click }, index}
        {#if path || click}
          <li>
            <LegacyButton link={path} {click} style={"breadcrumb-button"}>
              {name ? truncate(name) : $t(`breadcrumb.${translation}`)}
            </LegacyButton>
          </li>
        {:else}
          <li>
            <span>
              {name ? truncate(name) : $t(`breadcrumb.${translation}`)}
            </span>
          </li>
        {/if}
        {#if index < $breadcrumb.length - 1}
          <li class={styles.divider}>
            <svg
              width="18px"
              height="18px"
              viewBox="0 0 18 18"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <path
                d="M9.249875,11.749625 C9.057875,11.749625 8.865875,11.676625 8.719875,11.529625 L4.719875,7.529625 C4.426875,7.236625 4.426875,6.762625 4.719875,6.469625 C5.012875,6.176625 5.486875,6.176625 5.779875,6.469625 L9.249875,9.938625 L12.719875,6.469625 C13.012875,6.176625 13.486875,6.176625 13.779875,6.469625 C14.072875,6.762625 14.072875,7.236625 13.779875,7.529625 L9.779875,11.529625 C9.633875,11.676625 9.441875,11.749625 9.249875,11.749625"
                id="icon-color"
                transform="translate(9.249875, 8.999750) rotate(-90.000000) translate(-9.249875, -8.999750) "
              />
            </svg>
          </li>
        {/if}
      {/each}
    </ul>
  {/if}
</div>

<style lang="scss">
  .breadcrumb {
    list-style-type: none;
    display: flex;
    align-items: center;

    li {
      display: flex;
      align-items: center;

      &:last-child {
        pointer-events: none;
        padding-left: 12px;
        span {
          color: var(--breadcrumb-last-item-color);
        }
      }
      &:first-child {
        padding-left: 0;
        span {
          padding-left: 12px;
        }
      }

      &.divider {
        display: block;
        width: 26px;
        height: 18px;
        display: flex;
        justify-content: center;
        svg path {
          fill: var(--breadcrumb-divider-color);
        }
      }
    }
  }
</style>
