import * as confirmationNewStore from "stores/confirmation/confirmation_new.js";
import * as reviewNewStore from "stores/review/review_new.js";
import { writable, derived } from "svelte/store";

const confirmationType = writable(null);

const isConfirmationType = derived(confirmationType, ($confirmationType) => {
  return $confirmationType === "confirmation";
});

const isReviewType = derived(confirmationType, ($confirmationType) => {
  return $confirmationType === "review";
});

const activeStore = derived(confirmationType, ($confirmationType) => {
  return $confirmationType === "confirmation"
    ? confirmationNewStore
    : reviewNewStore;
});

export { confirmationType, isConfirmationType, isReviewType, activeStore };
