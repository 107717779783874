<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 0 0 L 720.070312 0 L 720.070312 239.140625 L 0 239.140625 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(25.882353%,62.352941%,0%);fill-opacity:1;"
      d="M 0 239.140625 L 720.070312 239.140625 L 720.070312 360 L 0 360 Z M 0 239.140625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 0 L 79.722656 0 L 79.722656 360 L 0 360 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 3.792969 6.105469 L 7.589844 6.105469 L 7.589844 12.207031 L 3.792969 12.207031 Z M 3.792969 6.105469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 15.183594 6.105469 L 26.570312 6.105469 L 26.570312 12.207031 L 15.183594 12.207031 Z M 15.183594 6.105469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 18.972656 0 L 22.769531 0 L 22.769531 6.101562 L 18.972656 6.101562 Z M 18.972656 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 37.960938 0 L 41.753906 0 L 41.753906 6.101562 L 37.960938 6.101562 Z M 37.960938 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 53.140625 6.105469 L 64.53125 6.105469 L 64.53125 12.207031 L 53.140625 12.207031 Z M 53.140625 6.105469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 56.945312 0 L 60.742188 0 L 60.742188 6.101562 L 56.945312 6.101562 Z M 56.945312 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 11.390625 12.207031 L 18.980469 12.207031 L 18.980469 18.308594 L 11.390625 18.308594 Z M 11.390625 12.207031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 22.777344 12.207031 L 30.371094 12.207031 L 30.371094 18.308594 L 22.777344 18.308594 Z M 22.777344 12.207031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 49.347656 12.207031 L 56.941406 12.207031 L 56.941406 18.308594 L 49.347656 18.308594 Z M 49.347656 12.207031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 60.738281 12.207031 L 68.328125 12.207031 L 68.328125 18.308594 L 60.738281 18.308594 Z M 60.738281 12.207031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 7.585938 18.308594 L 15.179688 18.308594 L 15.179688 24.410156 L 7.585938 24.410156 Z M 7.585938 18.308594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 26.570312 18.308594 L 34.164062 18.308594 L 34.164062 24.410156 L 26.570312 24.410156 Z M 26.570312 18.308594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 45.554688 18.308594 L 53.148438 18.308594 L 53.148438 24.410156 L 45.554688 24.410156 Z M 45.554688 18.308594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 64.53125 18.308594 L 72.121094 18.308594 L 72.121094 24.410156 L 64.53125 24.410156 Z M 64.53125 18.308594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 72.125 6.105469 L 75.921875 6.105469 L 75.921875 12.207031 L 72.125 12.207031 Z M 72.125 6.105469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 3.792969 24.410156 L 11.386719 24.410156 L 11.386719 30.511719 L 3.792969 30.511719 Z M 3.792969 24.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 30.363281 24.410156 L 37.957031 24.410156 L 37.957031 30.511719 L 30.363281 30.511719 Z M 30.363281 24.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 68.332031 24.410156 L 75.925781 24.410156 L 75.925781 30.511719 L 68.332031 30.511719 Z M 68.332031 24.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 7.585938 42.714844 L 15.179688 42.714844 L 15.179688 48.816406 L 7.585938 48.816406 Z M 7.585938 42.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 11.390625 48.816406 L 18.980469 48.816406 L 18.980469 54.917969 L 11.390625 54.917969 Z M 11.390625 48.816406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 26.570312 42.714844 L 34.164062 42.714844 L 34.164062 48.816406 L 26.570312 48.816406 Z M 26.570312 42.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 22.777344 48.816406 L 30.371094 48.816406 L 30.371094 54.917969 L 22.777344 54.917969 Z M 22.777344 48.816406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 15.183594 54.917969 L 26.570312 54.917969 L 26.570312 61.019531 L 15.183594 61.019531 Z M 15.183594 54.917969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 18.972656 61.019531 L 22.769531 61.019531 L 22.769531 67.121094 L 18.972656 67.121094 Z M 18.972656 61.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 45.554688 42.714844 L 53.148438 42.714844 L 53.148438 48.816406 L 45.554688 48.816406 Z M 45.554688 42.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 49.347656 48.816406 L 56.941406 48.816406 L 56.941406 54.917969 L 49.347656 54.917969 Z M 49.347656 48.816406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 64.53125 42.714844 L 72.121094 42.714844 L 72.121094 48.816406 L 64.53125 48.816406 Z M 64.53125 42.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 60.738281 48.816406 L 68.328125 48.816406 L 68.328125 54.917969 L 60.738281 54.917969 Z M 60.738281 48.816406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 53.140625 54.917969 L 64.53125 54.917969 L 64.53125 61.019531 L 53.140625 61.019531 Z M 53.140625 54.917969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 56.945312 61.019531 L 60.742188 61.019531 L 60.742188 67.121094 L 56.945312 67.121094 Z M 56.945312 61.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 37.960938 54.917969 L 41.753906 54.917969 L 41.753906 61.019531 L 37.960938 61.019531 Z M 37.960938 54.917969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 0 54.917969 L 3.796875 54.917969 L 3.796875 61.019531 L 0 61.019531 Z M 0 54.917969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 75.917969 54.917969 L 79.714844 54.917969 L 79.714844 61.019531 L 75.917969 61.019531 Z M 75.917969 54.917969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 18.972656 85.425781 L 22.769531 85.425781 L 22.769531 91.527344 L 18.972656 91.527344 Z M 18.972656 85.425781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 15.183594 91.527344 L 26.570312 91.527344 L 26.570312 97.628906 L 15.183594 97.628906 Z M 15.183594 91.527344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 11.390625 97.628906 L 30.371094 97.628906 L 30.371094 103.730469 L 11.390625 103.730469 Z M 11.390625 97.628906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 56.945312 85.425781 L 60.742188 85.425781 L 60.742188 91.527344 L 56.945312 91.527344 Z M 56.945312 85.425781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 53.140625 91.527344 L 64.53125 91.527344 L 64.53125 97.628906 L 53.140625 97.628906 Z M 53.140625 91.527344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 49.347656 97.628906 L 68.328125 97.628906 L 68.328125 103.730469 L 49.347656 103.730469 Z M 49.347656 97.628906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 53.140625 128.136719 L 72.121094 128.136719 L 72.121094 134.242188 L 53.140625 134.242188 Z M 53.140625 128.136719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 45.554688 103.730469 L 72.128906 103.730469 L 72.128906 109.832031 L 45.554688 109.832031 Z M 45.554688 103.730469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 7.585938 103.730469 L 34.160156 103.730469 L 34.160156 109.832031 L 7.585938 109.832031 Z M 7.585938 103.730469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 11.390625 134.242188 L 22.777344 134.242188 L 22.777344 140.34375 L 11.390625 140.34375 Z M 11.390625 134.242188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 7.585938 128.136719 L 26.566406 128.136719 L 26.566406 134.242188 L 7.585938 134.242188 Z M 7.585938 128.136719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 60.738281 115.933594 L 79.71875 115.933594 L 79.71875 122.039062 L 60.738281 122.039062 Z M 60.738281 115.933594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 56.945312 134.242188 L 68.332031 134.242188 L 68.332031 140.34375 L 56.945312 140.34375 Z M 56.945312 134.242188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 15.183594 140.339844 L 18.976562 140.339844 L 18.976562 146.445312 L 15.183594 146.445312 Z M 15.183594 140.339844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 60.738281 140.339844 L 64.53125 140.339844 L 64.53125 146.445312 L 60.738281 146.445312 Z M 60.738281 140.339844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 0 115.933594 L 18.980469 115.933594 L 18.980469 122.039062 L 0 122.039062 Z M 0 115.933594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 34.167969 128.136719 L 45.554688 128.136719 L 45.554688 134.242188 L 34.167969 134.242188 Z M 34.167969 128.136719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 37.960938 134.242188 L 41.753906 134.242188 L 41.753906 140.34375 L 37.960938 140.34375 Z M 37.960938 134.242188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 26.570312 115.933594 L 53.144531 115.933594 L 53.144531 122.039062 L 26.570312 122.039062 Z M 26.570312 115.933594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 41.753906 24.410156 L 49.34375 24.410156 L 49.34375 30.511719 L 41.753906 30.511719 Z M 41.753906 24.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 34.167969 30.511719 L 45.554688 30.511719 L 45.554688 36.613281 L 34.167969 36.613281 Z M 34.167969 30.511719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 30.363281 36.613281 L 37.957031 36.613281 L 37.957031 42.714844 L 30.363281 42.714844 Z M 30.363281 36.613281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 41.753906 36.613281 L 49.34375 36.613281 L 49.34375 42.714844 L 41.753906 42.714844 Z M 41.753906 36.613281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 0 30.511719 L 7.59375 30.511719 L 7.59375 36.613281 L 0 36.613281 Z M 0 30.511719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 3.792969 36.613281 L 11.386719 36.613281 L 11.386719 42.714844 L 3.792969 42.714844 Z M 3.792969 36.613281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 72.125 30.511719 L 79.71875 30.511719 L 79.71875 36.613281 L 72.125 36.613281 Z M 72.125 30.511719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 68.332031 36.613281 L 75.925781 36.613281 L 75.925781 42.714844 L 68.332031 42.714844 Z M 68.332031 36.613281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 0 91.527344 L 3.796875 91.527344 L 3.796875 97.628906 L 0 97.628906 Z M 0 91.527344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 75.917969 91.527344 L 79.714844 91.527344 L 79.714844 97.628906 L 75.917969 97.628906 Z M 75.917969 91.527344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 34.167969 140.339844 L 45.554688 140.339844 L 45.554688 146.445312 L 34.167969 146.445312 Z M 34.167969 140.339844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 30.363281 146.445312 L 37.957031 146.445312 L 37.957031 152.546875 L 30.363281 152.546875 Z M 30.363281 146.445312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 41.753906 146.445312 L 49.34375 146.445312 L 49.34375 152.546875 L 41.753906 152.546875 Z M 41.753906 146.445312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 26.570312 152.546875 L 34.164062 152.546875 L 34.164062 158.648438 L 26.570312 158.648438 Z M 26.570312 152.546875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 45.554688 152.546875 L 53.148438 152.546875 L 53.148438 158.648438 L 45.554688 158.648438 Z M 45.554688 152.546875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 22.777344 158.648438 L 30.371094 158.648438 L 30.371094 164.75 L 22.777344 164.75 Z M 22.777344 158.648438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 49.347656 158.648438 L 56.941406 158.648438 L 56.941406 164.75 L 49.347656 164.75 Z M 49.347656 158.648438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 18.972656 164.75 L 26.566406 164.75 L 26.566406 170.851562 L 18.972656 170.851562 Z M 18.972656 164.75 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 53.140625 164.75 L 60.734375 164.75 L 60.734375 170.851562 L 53.140625 170.851562 Z M 53.140625 164.75 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 11.390625 170.851562 L 22.777344 170.851562 L 22.777344 176.953125 L 11.390625 176.953125 Z M 11.390625 170.851562 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 56.945312 170.851562 L 68.332031 170.851562 L 68.332031 176.953125 L 56.945312 176.953125 Z M 56.945312 170.851562 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 64.53125 164.75 L 72.121094 164.75 L 72.121094 170.851562 L 64.53125 170.851562 Z M 64.53125 164.75 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 68.332031 158.648438 L 75.925781 158.648438 L 75.925781 164.75 L 68.332031 164.75 Z M 68.332031 158.648438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 72.125 152.546875 L 79.71875 152.546875 L 79.71875 158.648438 L 72.125 158.648438 Z M 72.125 152.546875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 7.585938 164.75 L 15.179688 164.75 L 15.179688 170.851562 L 7.585938 170.851562 Z M 7.585938 164.75 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 3.792969 158.648438 L 11.386719 158.648438 L 11.386719 164.75 L 3.792969 164.75 Z M 3.792969 158.648438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 0 152.546875 L 7.59375 152.546875 L 7.59375 158.648438 L 0 158.648438 Z M 0 152.546875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 15.183594 176.953125 L 18.976562 176.953125 L 18.976562 183.054688 L 15.183594 183.054688 Z M 15.183594 176.953125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 60.738281 176.953125 L 64.53125 176.953125 L 64.53125 183.054688 L 60.738281 183.054688 Z M 60.738281 176.953125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 37.960938 158.648438 L 41.753906 158.648438 L 41.753906 164.75 L 37.960938 164.75 Z M 37.960938 158.648438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 26.570312 176.953125 L 30.367188 176.953125 L 30.367188 183.054688 L 26.570312 183.054688 Z M 26.570312 176.953125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 49.347656 176.953125 L 53.144531 176.953125 L 53.144531 183.054688 L 49.347656 183.054688 Z M 49.347656 176.953125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 37.960938 176.953125 L 41.753906 176.953125 L 41.753906 183.054688 L 37.960938 183.054688 Z M 37.960938 176.953125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 0 176.953125 L 3.796875 176.953125 L 3.796875 183.054688 L 0 183.054688 Z M 0 176.953125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 75.917969 176.953125 L 79.714844 176.953125 L 79.714844 183.054688 L 75.917969 183.054688 Z M 75.917969 176.953125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 37.960938 91.527344 L 41.753906 91.527344 L 41.753906 97.628906 L 37.960938 97.628906 Z M 37.960938 91.527344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 18.972656 30.511719 L 22.769531 30.511719 L 22.769531 36.613281 L 18.972656 36.613281 Z M 18.972656 30.511719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 56.945312 30.511719 L 60.742188 30.511719 L 60.742188 36.613281 L 56.945312 36.613281 Z M 56.945312 30.511719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 34.167969 73.222656 L 45.554688 73.222656 L 45.554688 79.324219 L 34.167969 79.324219 Z M 34.167969 73.222656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 26.570312 79.324219 L 37.960938 79.324219 L 37.960938 85.425781 L 26.570312 85.425781 Z M 26.570312 79.324219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 30.363281 85.425781 L 34.160156 85.425781 L 34.160156 91.527344 L 30.363281 91.527344 Z M 30.363281 85.425781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 22.777344 73.222656 L 30.371094 73.222656 L 30.371094 79.324219 L 22.777344 79.324219 Z M 22.777344 73.222656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 26.570312 67.121094 L 37.960938 67.121094 L 37.960938 73.222656 L 26.570312 73.222656 Z M 26.570312 67.121094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 30.363281 61.019531 L 34.160156 61.019531 L 34.160156 67.121094 L 30.363281 67.121094 Z M 30.363281 61.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 41.753906 67.121094 L 53.140625 67.121094 L 53.140625 73.222656 L 41.753906 73.222656 Z M 41.753906 67.121094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 45.554688 61.019531 L 49.351562 61.019531 L 49.351562 67.121094 L 45.554688 67.121094 Z M 45.554688 61.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 49.347656 73.222656 L 56.941406 73.222656 L 56.941406 79.324219 L 49.347656 79.324219 Z M 49.347656 73.222656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 41.753906 79.324219 L 53.140625 79.324219 L 53.140625 85.425781 L 41.753906 85.425781 Z M 41.753906 79.324219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 45.554688 85.425781 L 49.351562 85.425781 L 49.351562 91.527344 L 45.554688 91.527344 Z M 45.554688 85.425781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 60.738281 73.222656 L 68.328125 73.222656 L 68.328125 79.324219 L 60.738281 79.324219 Z M 60.738281 73.222656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 64.53125 67.121094 L 75.917969 67.121094 L 75.917969 73.222656 L 64.53125 73.222656 Z M 64.53125 67.121094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 72.125 73.222656 L 79.71875 73.222656 L 79.71875 79.324219 L 72.125 79.324219 Z M 72.125 73.222656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 64.53125 79.324219 L 75.917969 79.324219 L 75.917969 85.425781 L 64.53125 85.425781 Z M 64.53125 79.324219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 68.332031 85.425781 L 72.128906 85.425781 L 72.128906 91.527344 L 68.332031 91.527344 Z M 68.332031 85.425781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 68.332031 61.019531 L 72.128906 61.019531 L 72.128906 67.121094 L 68.332031 67.121094 Z M 68.332031 61.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 0 73.222656 L 7.59375 73.222656 L 7.59375 79.324219 L 0 79.324219 Z M 0 73.222656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 3.792969 67.121094 L 15.183594 67.121094 L 15.183594 73.222656 L 3.792969 73.222656 Z M 3.792969 67.121094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 11.390625 73.222656 L 18.980469 73.222656 L 18.980469 79.324219 L 11.390625 79.324219 Z M 11.390625 73.222656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 3.792969 79.324219 L 15.183594 79.324219 L 15.183594 85.425781 L 3.792969 85.425781 Z M 3.792969 79.324219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 7.585938 85.425781 L 11.382812 85.425781 L 11.382812 91.527344 L 7.585938 91.527344 Z M 7.585938 85.425781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 7.585938 61.019531 L 11.382812 61.019531 L 11.382812 67.121094 L 7.585938 67.121094 Z M 7.585938 61.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 3.792969 109.832031 L 18.976562 109.832031 L 18.976562 115.933594 L 3.792969 115.933594 Z M 3.792969 109.832031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 22.777344 109.832031 L 37.960938 109.832031 L 37.960938 115.933594 L 22.777344 115.933594 Z M 22.777344 109.832031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 41.753906 109.832031 L 56.9375 109.832031 L 56.9375 115.933594 L 41.753906 115.933594 Z M 41.753906 109.832031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 60.738281 109.832031 L 75.921875 109.832031 L 75.921875 115.933594 L 60.738281 115.933594 Z M 60.738281 109.832031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 30.363281 122.035156 L 49.34375 122.035156 L 49.34375 128.136719 L 30.363281 128.136719 Z M 30.363281 122.035156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 3.792969 122.035156 L 22.773438 122.035156 L 22.773438 128.136719 L 3.792969 128.136719 Z M 3.792969 122.035156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 56.945312 122.035156 L 75.925781 122.035156 L 75.925781 128.136719 L 56.945312 128.136719 Z M 56.945312 122.035156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 75.917969 146.445312 L 79.714844 146.445312 L 79.714844 152.546875 L 75.917969 152.546875 Z M 75.917969 146.445312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 0 146.445312 L 3.796875 146.445312 L 3.796875 152.546875 L 0 152.546875 Z M 0 146.445312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 3.792969 353.902344 L 7.589844 353.902344 L 7.589844 347.800781 L 3.792969 347.800781 Z M 3.792969 353.902344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 15.183594 353.902344 L 26.570312 353.902344 L 26.570312 347.800781 L 15.183594 347.800781 Z M 15.183594 353.902344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 18.972656 359.996094 L 22.769531 359.996094 L 22.769531 353.894531 L 18.972656 353.894531 Z M 18.972656 359.996094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 37.960938 359.996094 L 41.753906 359.996094 L 41.753906 353.894531 L 37.960938 353.894531 Z M 37.960938 359.996094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 53.140625 353.902344 L 64.53125 353.902344 L 64.53125 347.800781 L 53.140625 347.800781 Z M 53.140625 353.902344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 56.945312 359.996094 L 60.742188 359.996094 L 60.742188 353.894531 L 56.945312 353.894531 Z M 56.945312 359.996094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 11.390625 347.796875 L 18.980469 347.796875 L 18.980469 341.695312 L 11.390625 341.695312 Z M 11.390625 347.796875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 22.777344 347.796875 L 30.371094 347.796875 L 30.371094 341.695312 L 22.777344 341.695312 Z M 22.777344 347.796875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 49.347656 347.796875 L 56.941406 347.796875 L 56.941406 341.695312 L 49.347656 341.695312 Z M 49.347656 347.796875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 60.738281 347.796875 L 68.328125 347.796875 L 68.328125 341.695312 L 60.738281 341.695312 Z M 60.738281 347.796875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 26.570312 341.691406 L 34.164062 341.691406 L 34.164062 335.589844 L 26.570312 335.589844 Z M 26.570312 341.691406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 45.554688 341.691406 L 53.148438 341.691406 L 53.148438 335.589844 L 45.554688 335.589844 Z M 45.554688 341.691406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 64.53125 341.691406 L 72.121094 341.691406 L 72.121094 335.589844 L 64.53125 335.589844 Z M 64.53125 341.691406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 72.125 353.902344 L 75.921875 353.902344 L 75.921875 347.800781 L 72.125 347.800781 Z M 72.125 353.902344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 3.792969 335.597656 L 11.386719 335.597656 L 11.386719 329.496094 L 3.792969 329.496094 Z M 3.792969 335.597656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 30.363281 335.597656 L 37.957031 335.597656 L 37.957031 329.496094 L 30.363281 329.496094 Z M 30.363281 335.597656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 68.332031 335.597656 L 75.925781 335.597656 L 75.925781 329.496094 L 68.332031 329.496094 Z M 68.332031 335.597656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 7.585938 317.292969 L 15.179688 317.292969 L 15.179688 311.191406 L 7.585938 311.191406 Z M 7.585938 317.292969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 11.390625 311.1875 L 18.980469 311.1875 L 18.980469 305.085938 L 11.390625 305.085938 Z M 11.390625 311.1875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 26.570312 317.292969 L 34.164062 317.292969 L 34.164062 311.191406 L 26.570312 311.191406 Z M 26.570312 317.292969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 22.777344 311.1875 L 30.371094 311.1875 L 30.371094 305.085938 L 22.777344 305.085938 Z M 22.777344 311.1875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 15.183594 305.082031 L 26.570312 305.082031 L 26.570312 298.980469 L 15.183594 298.980469 Z M 15.183594 305.082031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 18.972656 298.988281 L 22.769531 298.988281 L 22.769531 292.886719 L 18.972656 292.886719 Z M 18.972656 298.988281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 45.554688 317.292969 L 53.148438 317.292969 L 53.148438 311.191406 L 45.554688 311.191406 Z M 45.554688 317.292969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 49.347656 311.1875 L 56.941406 311.1875 L 56.941406 305.085938 L 49.347656 305.085938 Z M 49.347656 311.1875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 64.53125 317.292969 L 72.121094 317.292969 L 72.121094 311.191406 L 64.53125 311.191406 Z M 64.53125 317.292969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 60.738281 311.1875 L 68.328125 311.1875 L 68.328125 305.085938 L 60.738281 305.085938 Z M 60.738281 311.1875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 53.140625 305.082031 L 64.53125 305.082031 L 64.53125 298.980469 L 53.140625 298.980469 Z M 53.140625 305.082031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 56.945312 298.988281 L 60.742188 298.988281 L 60.742188 292.886719 L 56.945312 292.886719 Z M 56.945312 298.988281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 37.960938 305.082031 L 41.753906 305.082031 L 41.753906 298.980469 L 37.960938 298.980469 Z M 37.960938 305.082031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 0 305.082031 L 3.796875 305.082031 L 3.796875 298.980469 L 0 298.980469 Z M 0 305.082031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 75.917969 305.082031 L 79.714844 305.082031 L 79.714844 298.980469 L 75.917969 298.980469 Z M 75.917969 305.082031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 18.972656 274.578125 L 22.769531 274.578125 L 22.769531 268.476562 L 18.972656 268.476562 Z M 18.972656 274.578125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 15.183594 268.480469 L 26.570312 268.480469 L 26.570312 262.378906 L 15.183594 262.378906 Z M 15.183594 268.480469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 11.390625 262.375 L 30.371094 262.375 L 30.371094 256.273438 L 11.390625 256.273438 Z M 11.390625 262.375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 56.945312 274.578125 L 60.742188 274.578125 L 60.742188 268.476562 L 56.945312 268.476562 Z M 56.945312 274.578125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 53.140625 268.480469 L 64.53125 268.480469 L 64.53125 262.378906 L 53.140625 262.378906 Z M 53.140625 268.480469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 49.347656 262.375 L 68.328125 262.375 L 68.328125 256.273438 L 49.347656 256.273438 Z M 49.347656 262.375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 53.140625 231.871094 L 72.121094 231.871094 L 72.121094 225.769531 L 53.140625 225.769531 Z M 53.140625 231.871094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 45.554688 256.273438 L 72.128906 256.273438 L 72.128906 250.171875 L 45.554688 250.171875 Z M 45.554688 256.273438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 7.585938 256.273438 L 34.160156 256.273438 L 34.160156 250.171875 L 7.585938 250.171875 Z M 7.585938 256.273438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 11.390625 225.765625 L 22.777344 225.765625 L 22.777344 219.660156 L 11.390625 219.660156 Z M 11.390625 225.765625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 7.585938 231.871094 L 26.566406 231.871094 L 26.566406 225.769531 L 7.585938 225.769531 Z M 7.585938 231.871094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 60.738281 244.070312 L 79.71875 244.070312 L 79.71875 237.96875 L 60.738281 237.96875 Z M 60.738281 244.070312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 56.945312 225.765625 L 68.332031 225.765625 L 68.332031 219.664062 L 56.945312 219.664062 Z M 56.945312 225.765625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 0 244.070312 L 18.980469 244.070312 L 18.980469 237.96875 L 0 237.96875 Z M 0 244.070312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 34.167969 231.871094 L 45.554688 231.871094 L 45.554688 225.769531 L 34.167969 225.769531 Z M 34.167969 231.871094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 37.960938 225.765625 L 41.753906 225.765625 L 41.753906 219.664062 L 37.960938 219.664062 Z M 37.960938 225.765625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 26.570312 244.070312 L 53.144531 244.070312 L 53.144531 237.96875 L 26.570312 237.96875 Z M 26.570312 244.070312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 41.753906 335.597656 L 49.34375 335.597656 L 49.34375 329.496094 L 41.753906 329.496094 Z M 41.753906 335.597656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 34.167969 329.492188 L 45.554688 329.492188 L 45.554688 323.390625 L 34.167969 323.390625 Z M 34.167969 329.492188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 30.363281 323.386719 L 37.957031 323.386719 L 37.957031 317.285156 L 30.363281 317.285156 Z M 30.363281 323.386719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 41.753906 323.386719 L 49.34375 323.386719 L 49.34375 317.285156 L 41.753906 317.285156 Z M 41.753906 323.386719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 0 329.492188 L 7.59375 329.492188 L 7.59375 323.390625 L 0 323.390625 Z M 0 329.492188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 3.792969 323.386719 L 11.386719 323.386719 L 11.386719 317.285156 L 3.792969 317.285156 Z M 3.792969 323.386719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 72.125 329.492188 L 79.71875 329.492188 L 79.71875 323.390625 L 72.125 323.390625 Z M 72.125 329.492188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 68.332031 323.386719 L 75.925781 323.386719 L 75.925781 317.285156 L 68.332031 317.285156 Z M 68.332031 323.386719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 0 268.480469 L 3.796875 268.480469 L 3.796875 262.378906 L 0 262.378906 Z M 0 268.480469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 75.917969 268.480469 L 79.714844 268.480469 L 79.714844 262.378906 L 75.917969 262.378906 Z M 75.917969 268.480469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 30.363281 213.566406 L 37.957031 213.566406 L 37.957031 207.464844 L 30.363281 207.464844 Z M 30.363281 213.566406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 41.753906 213.566406 L 49.34375 213.566406 L 49.34375 207.464844 L 41.753906 207.464844 Z M 41.753906 213.566406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 26.570312 207.460938 L 34.164062 207.460938 L 34.164062 201.359375 L 26.570312 201.359375 Z M 26.570312 207.460938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 45.554688 207.460938 L 53.148438 207.460938 L 53.148438 201.359375 L 45.554688 201.359375 Z M 45.554688 207.460938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 22.777344 201.355469 L 30.371094 201.355469 L 30.371094 195.253906 L 22.777344 195.253906 Z M 22.777344 201.355469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 49.347656 201.355469 L 56.941406 201.355469 L 56.941406 195.253906 L 49.347656 195.253906 Z M 49.347656 201.355469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 18.972656 195.261719 L 26.566406 195.261719 L 26.566406 189.160156 L 18.972656 189.160156 Z M 18.972656 195.261719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 53.140625 195.261719 L 60.734375 195.261719 L 60.734375 189.160156 L 53.140625 189.160156 Z M 53.140625 195.261719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 11.390625 189.15625 L 22.777344 189.15625 L 22.777344 183.054688 L 11.390625 183.054688 Z M 11.390625 189.15625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 56.945312 189.15625 L 68.332031 189.15625 L 68.332031 183.054688 L 56.945312 183.054688 Z M 56.945312 189.15625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 64.53125 195.261719 L 72.121094 195.261719 L 72.121094 189.160156 L 64.53125 189.160156 Z M 64.53125 195.261719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 68.332031 201.355469 L 75.925781 201.355469 L 75.925781 195.253906 L 68.332031 195.253906 Z M 68.332031 201.355469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 72.125 207.460938 L 79.71875 207.460938 L 79.71875 201.359375 L 72.125 201.359375 Z M 72.125 207.460938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 7.585938 195.261719 L 15.179688 195.261719 L 15.179688 189.160156 L 7.585938 189.160156 Z M 7.585938 195.261719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 3.792969 201.355469 L 11.386719 201.355469 L 11.386719 195.253906 L 3.792969 195.253906 Z M 3.792969 201.355469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 0 207.460938 L 7.59375 207.460938 L 7.59375 201.359375 L 0 201.359375 Z M 0 207.460938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 15.183594 183.054688 L 18.976562 183.054688 L 18.976562 176.953125 L 15.183594 176.953125 Z M 15.183594 183.054688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 37.960938 201.355469 L 41.753906 201.355469 L 41.753906 195.253906 L 37.960938 195.253906 Z M 37.960938 201.355469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 37.960938 268.480469 L 41.753906 268.480469 L 41.753906 262.378906 L 37.960938 262.378906 Z M 37.960938 268.480469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 18.972656 329.492188 L 22.769531 329.492188 L 22.769531 323.390625 L 18.972656 323.390625 Z M 18.972656 329.492188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 56.945312 329.492188 L 60.742188 329.492188 L 60.742188 323.390625 L 56.945312 323.390625 Z M 56.945312 329.492188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 34.167969 286.785156 L 45.554688 286.785156 L 45.554688 280.683594 L 34.167969 280.683594 Z M 34.167969 286.785156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 26.570312 280.679688 L 37.960938 280.679688 L 37.960938 274.578125 L 26.570312 274.578125 Z M 26.570312 280.679688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 30.363281 274.578125 L 34.160156 274.578125 L 34.160156 268.476562 L 30.363281 268.476562 Z M 30.363281 274.578125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 22.777344 286.785156 L 30.371094 286.785156 L 30.371094 280.683594 L 22.777344 280.683594 Z M 22.777344 286.785156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 26.570312 292.882812 L 37.960938 292.882812 L 37.960938 286.78125 L 26.570312 286.78125 Z M 26.570312 292.882812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 30.363281 298.988281 L 34.160156 298.988281 L 34.160156 292.886719 L 30.363281 292.886719 Z M 30.363281 298.988281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 41.753906 292.882812 L 53.140625 292.882812 L 53.140625 286.78125 L 41.753906 286.78125 Z M 41.753906 292.882812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 45.554688 298.988281 L 49.351562 298.988281 L 49.351562 292.886719 L 45.554688 292.886719 Z M 45.554688 298.988281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 49.347656 286.785156 L 56.941406 286.785156 L 56.941406 280.683594 L 49.347656 280.683594 Z M 49.347656 286.785156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 41.753906 280.679688 L 53.140625 280.679688 L 53.140625 274.578125 L 41.753906 274.578125 Z M 41.753906 280.679688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 45.554688 274.578125 L 49.351562 274.578125 L 49.351562 268.476562 L 45.554688 268.476562 Z M 45.554688 274.578125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 60.738281 286.785156 L 68.328125 286.785156 L 68.328125 280.683594 L 60.738281 280.683594 Z M 60.738281 286.785156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 64.53125 292.882812 L 75.917969 292.882812 L 75.917969 286.78125 L 64.53125 286.78125 Z M 64.53125 292.882812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 72.125 286.785156 L 79.71875 286.785156 L 79.71875 280.683594 L 72.125 280.683594 Z M 72.125 286.785156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 64.53125 280.679688 L 75.917969 280.679688 L 75.917969 274.578125 L 64.53125 274.578125 Z M 64.53125 280.679688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 68.332031 274.578125 L 72.128906 274.578125 L 72.128906 268.476562 L 68.332031 268.476562 Z M 68.332031 274.578125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 68.332031 298.988281 L 72.128906 298.988281 L 72.128906 292.886719 L 68.332031 292.886719 Z M 68.332031 298.988281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 0 286.785156 L 7.59375 286.785156 L 7.59375 280.683594 L 0 280.683594 Z M 0 286.785156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 3.792969 292.882812 L 15.183594 292.882812 L 15.183594 286.78125 L 3.792969 286.78125 Z M 3.792969 292.882812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 11.390625 286.785156 L 18.980469 286.785156 L 18.980469 280.683594 L 11.390625 280.683594 Z M 11.390625 286.785156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 3.792969 280.679688 L 15.183594 280.679688 L 15.183594 274.578125 L 3.792969 274.578125 Z M 3.792969 280.679688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 7.585938 274.578125 L 11.382812 274.578125 L 11.382812 268.476562 L 7.585938 268.476562 Z M 7.585938 274.578125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 7.585938 298.988281 L 11.382812 298.988281 L 11.382812 292.886719 L 7.585938 292.886719 Z M 7.585938 298.988281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 3.792969 250.175781 L 18.976562 250.175781 L 18.976562 244.074219 L 3.792969 244.074219 Z M 3.792969 250.175781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 22.777344 250.175781 L 37.960938 250.175781 L 37.960938 244.074219 L 22.777344 244.074219 Z M 22.777344 250.175781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 41.753906 250.175781 L 56.9375 250.175781 L 56.9375 244.074219 L 41.753906 244.074219 Z M 41.753906 250.175781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 60.738281 250.175781 L 75.921875 250.175781 L 75.921875 244.074219 L 60.738281 244.074219 Z M 60.738281 250.175781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 30.363281 237.964844 L 49.34375 237.964844 L 49.34375 231.863281 L 30.363281 231.863281 Z M 30.363281 237.964844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 3.792969 237.964844 L 22.773438 237.964844 L 22.773438 231.863281 L 3.792969 231.863281 Z M 3.792969 237.964844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 56.945312 237.964844 L 75.925781 237.964844 L 75.925781 231.863281 L 56.945312 231.863281 Z M 56.945312 237.964844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 75.917969 213.566406 L 79.714844 213.566406 L 79.714844 207.464844 L 75.917969 207.464844 Z M 75.917969 213.566406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 0 213.566406 L 3.796875 213.566406 L 3.796875 207.464844 L 0 207.464844 Z M 0 213.566406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 34.167969 183.054688 L 37.960938 183.054688 L 37.960938 189.15625 L 34.167969 189.15625 Z M 34.167969 183.054688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 41.753906 183.054688 L 45.546875 183.054688 L 45.546875 189.15625 L 41.753906 189.15625 Z M 41.753906 183.054688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 41.753906 170.851562 L 45.546875 170.851562 L 45.546875 176.953125 L 41.753906 176.953125 Z M 41.753906 170.851562 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 34.167969 170.851562 L 37.960938 170.851562 L 37.960938 176.953125 L 34.167969 176.953125 Z M 34.167969 170.851562 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 7.585938 335.597656 L 15.179688 335.597656 L 15.179688 341.699219 L 7.585938 341.699219 Z M 7.585938 335.597656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 60.738281 219.660156 L 64.53125 219.660156 L 64.53125 213.558594 L 60.738281 213.558594 Z M 60.738281 219.660156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 15.183594 219.660156 L 18.976562 219.660156 L 18.976562 213.558594 L 15.183594 213.558594 Z M 15.183594 219.660156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.803922%,0%,0%);fill-opacity:1;"
      d="M 34.164062 213.5625 L 45.554688 213.5625 L 45.554688 219.664062 L 34.164062 219.664062 Z M 34.164062 213.5625 "
    />
  </g>
</svg>
