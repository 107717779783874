<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,95.686275%,11.764706%);fill-opacity:1;"
      d="M 151.960938 -131.4375 L 510.011719 -131.4375 L 510.011719 181.003906 L 151.960938 181.003906 Z M 151.960938 -131.4375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(12.156863%,47.058824%,28.235294%);fill-opacity:1;"
      d="M 197.582031 172.578125 L 487.949219 172.578125 L 487.949219 413.757812 L 197.582031 413.757812 Z M 197.582031 172.578125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.980392%,0%,2.352941%);fill-opacity:1;"
      d="M 0 -73.941406 L 197.582031 -73.941406 L 197.582031 414.007812 L 0 414.007812 Z M 0 -73.941406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 153.066406 236.34375 L 99.828125 198.328125 L 46.855469 236.71875 L 66.554688 174.335938 L 13.679688 135.816406 L 79.097656 135.277344 L 99.390625 73.085938 L 120.113281 135.132812 L 185.535156 135.214844 L 132.929688 174.101562 Z M 153.066406 236.34375 "
    />
  </g>
</svg>
