<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,41.568627%);fill-opacity:1;"
      d="M 0.00390625 0 L 480.003906 0 L 480.003906 360 L 0.00390625 360 Z M 0.00390625 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 0 L 239.238281 228.253906 L 288.3125 228.253906 L 54.238281 0.109375 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 270.386719 228.242188 L 36.148438 0 L 17.921875 0.277344 L 253.136719 228.246094 L 270.386719 228.246094 Z M 270.386719 228.242188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 288.316406 0 L 49.078125 228.253906 L 0.00390625 228.253906 L 234.078125 0.109375 Z M 288.316406 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 270.335938 0.00390625 L 36.191406 228.246094 L 17.96875 228.257812 L 253.09375 0.00390625 Z M 270.335938 0.00390625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 121.089844 0.00390625 L 167.21875 0.00390625 L 167.21875 228.25 L 121.089844 228.25 Z M 121.089844 0.00390625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0.00390625 91.300781 L 288.304688 91.300781 L 288.304688 136.953125 L 0.00390625 136.953125 Z M 0.00390625 91.300781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 131.1875 0.00390625 L 157.132812 0.00390625 L 157.132812 228.25 L 131.1875 228.25 Z M 131.1875 0.00390625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 0.00390625 102.71875 L 288.304688 102.71875 L 288.304688 125.542969 L 0.00390625 125.542969 Z M 0.00390625 102.71875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 390.007812 134.496094 L 375.324219 123.644531 L 360.101562 133.710938 L 365.832031 116.320312 L 351.601562 104.878906 L 369.824219 104.984375 L 376.25 87.84375 L 381.789062 105.296875 L 399.984375 106.144531 L 385.1875 116.828125 Z M 390.007812 134.496094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 384.117188 125.710938 L 375.433594 119.429688 L 366.554688 125.433594 L 369.816406 115.1875 L 361.390625 108.554688 L 372.09375 108.503906 L 375.765625 98.402344 L 379.117188 108.617188 L 389.808594 109.003906 L 381.1875 115.367188 Z M 384.117188 125.710938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 333.660156 228.035156 L 318.976562 217.183594 L 303.753906 227.25 L 309.484375 209.859375 L 295.253906 198.417969 L 313.476562 198.523438 L 319.902344 181.382812 L 325.441406 198.835938 L 343.636719 199.683594 L 328.839844 210.367188 Z M 333.660156 228.035156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 327.769531 219.25 L 319.085938 212.96875 L 310.207031 218.972656 L 313.46875 208.726562 L 305.042969 202.09375 L 315.746094 202.042969 L 319.417969 191.941406 L 322.769531 202.15625 L 333.457031 202.542969 L 324.839844 208.90625 Z M 327.769531 219.25 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 448.976562 218.816406 L 434.292969 207.964844 L 419.070312 218.03125 L 424.800781 200.640625 L 410.574219 189.199219 L 428.792969 189.304688 L 435.21875 172.164062 L 440.757812 189.617188 L 458.953125 190.464844 L 444.15625 201.148438 Z M 448.976562 218.816406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 443.085938 210.03125 L 434.402344 203.75 L 425.523438 209.753906 L 428.785156 199.507812 L 420.359375 192.875 L 431.0625 192.824219 L 434.734375 182.722656 L 438.085938 192.9375 L 448.777344 193.324219 L 440.15625 199.6875 Z M 443.085938 210.03125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 388.695312 351.878906 L 374.011719 341.027344 L 358.789062 351.09375 L 364.523438 333.703125 L 350.292969 322.261719 L 368.515625 322.367188 L 374.941406 305.226562 L 380.476562 322.679688 L 398.671875 323.527344 L 383.878906 334.210938 Z M 388.695312 351.878906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 382.808594 343.09375 L 374.121094 336.8125 L 365.246094 342.816406 L 368.507812 332.570312 L 360.078125 325.9375 L 370.785156 325.886719 L 374.453125 315.785156 L 377.804688 326 L 388.496094 326.386719 L 379.875 332.75 Z M 382.808594 343.09375 "
    />
  </g>
</svg>
