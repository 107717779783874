import { writable } from "svelte/store";
import ConfirmationMailingsApi from "apis/confirmation/confirmation_mailings.js";

const token = writable(null);
const step = writable("intro");
const mailings = writable([]);
const documentsNumber = writable(0);
const signer = writable({});
const client = writable({});
const signature = writable({});
const footerLinks = writable({});
const confirmationSigners = writable({});

function getMailingPage(page) {
  ConfirmationMailingsApi.getByToken({
    params: {
      page,
      token: $token,
    },
    success: () => {},
  });
}

export {
  signature,
  client,
  signer,
  step,
  token,
  mailings,
  documentsNumber,
  getMailingPage,
  footerLinks,
  confirmationSigners,
};
