<script>
  export let width = 18;
  export let height = 18;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 18 18"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M14.53 9.52976C14.677 9.38376 14.75 9.19176 14.75 8.99976C14.75 8.80776 14.677 8.61576 14.53 8.46976L10.53 4.46976C10.237 4.17676 9.763 4.17676 9.47 4.46976C9.177 4.76276 9.177 5.23676 9.47 5.52976L12.189 8.24951H3.99988C3.58566 8.24951 3.24988 8.5853 3.24988 8.99951C3.24988 9.41373 3.58566 9.74951 3.99988 9.74951H12.1895L9.47 12.4698C9.177 12.7628 9.177 13.2368 9.47 13.5298C9.763 13.8228 10.237 13.8228 10.53 13.5298L14.53 9.52976Z"
  />
</svg>
