<script>
  import { ControlFileItem } from "apps/pbc";
  import documentViewerStore from "stores/document_viewer_store.js";
  import { t } from "stores/i18n.js";
  import { activeMentionedFile } from "stores/pbc.js";
  import { can } from "stores/permission.js";
  import { user } from "stores/user.js";

  import { isPreview, isReviewed } from "lib/helpers.js";

  import { Boundary, Button, IconButton, Options, Tooltip } from "components";
  import styles from "styleguide/ControlUploadItem.json";

  export let ids;
  export let file;
  export let index;
  export let onReport;
  export let onArchive;
  export let onDownload;
  export let onMarkAsRead;
  export let onMarkAsReadBatch;
  export let onDeleteBatch;
  export let openSame;
  export let onOpenSame;
  export let last;

  $: progress = file.percentage;
  $: size = file.size;
  $: uploadedSize = file.filesize;
  $: stored = file.is_stored;
  $: archived = file.archived;
  $: virus = file.virus;
  $: name = file.name ? file.name : file.filename;
  $: uploadFailed = file.upload_failed;
  $: versionName = "";
  $: canDelete =
    $user.role !== "client" ||
    ($user.permalink === (file.user && file.user.permalink) &&
      !file.reviewed_as_team_user);
  $: goToFile($activeMentionedFile);
  $: canPreview =
    (!file.virus_scanning || file.is_stored) && isPreview(file.mime_type);

  $: {
    if (file.versionsName && !openSame) {
      versionName = file.versionsName;
    } else {
      versionName = file.versionName;
    }
  }

  let element;
  let hovering = false;
  let loadingDownload = false;

  function enter() {
    hovering = true;
  }

  function leave() {
    hovering = false;
  }

  function isChecked(file) {
    return isReviewed(file, $user.role);
  }

  function onOpenDocument() {
    if (!file.virus_scanning || file.is_stored) {
      documentViewerStore.openDocument({
        title: file.filename,
        url: file.url,
        id: file.permalink,
        is_archived: file.archived,
        is_read: file.reviewed_as_team_user,
      });
    }
  }

  function archive(file) {
    onArchive(file.permalink, file.archived);
  }

  function markAsRead(file) {
    if (onOpenSame && !isChecked(file)) {
      onMarkAsReadBatch(ids, isChecked(file));
    } else {
      onMarkAsRead(file.permalink, file.reviewed_as_team_user);
    }
  }

  function download(file, success) {
    onDownload(
      file,
      () => {
        loadingDownload = true;
      },
      () => {
        if (success) success();
        loadingDownload = false;
      },
    );
  }

  function remove(file) {
    onDeleteBatch([file.permalink]);
  }

  function goToFile(id) {
    if (ids && ids.some((item) => item === id)) {
      openSame = true;
    }

    if (file.permalink === id) {
      setTimeout(() => {
        if (element.scrollIntoView) {
          element.scrollIntoView({ block: "center", behavior: "smooth" });
        }
      }, 100);
    }
  }
</script>

<Boundary>
  <div
    data-component="ControlUploadItem"
    bind:this={element}
    on:mouseenter={enter}
    on:mouseleave={leave}
    class={`${styles.wrapper} ${$activeMentionedFile === file.permalink ? styles.active : ""}`}
  >
    <ControlFileItem
      preview={canPreview}
      {hovering}
      {progress}
      {name}
      {versionName}
      {size}
      {uploadedSize}
      {index}
      {stored}
      {virus}
      {last}
      {file}
      {uploadFailed}
      user={file.user}
      is_stored={file.is_stored}
      created_at={file.created_at}
      virus_scanning={file.virus_scanning}
      checked={isChecked(file)}
    >
      {#if (file.permalink && hovering && !file.virus_scanning) || loadingDownload || uploadFailed}
        <div class={styles.items}>
          <div class={styles.box}>
            <div class={styles.actions}>
              {#if file.upload_failed}
                <Options
                  icon="much"
                  style="bottom icon-only no-border no-padding medium button items-padding inverted-blue"
                  arrow={false}
                >
                  <Button
                    icon="trash"
                    style="attachment medium option red"
                    click={remove.bind(this, file)}
                  >
                    {$t("control_upload_item.delete")}
                  </Button>
                </Options>
              {:else}
                {#if canPreview}
                  <IconButton
                    style={"primary-text small"}
                    icon="eye"
                    click={onOpenDocument.bind(this, file)}
                  />
                {/if}
                {#if $can("mark_as_read", "attachment")}
                  <div class={styles.checkmark}>
                    <Tooltip>
                      <div slot="content">
                        <IconButton
                          style="primary-text small"
                          icon="checkmark_medium"
                          click={markAsRead.bind(this, file)}
                        />
                      </div>

                      <div slot="tooltip">
                        {isChecked(file)
                          ? $t("control_upload_item.uncheck")
                          : $t("control_upload_item.check")}
                      </div>
                    </Tooltip>
                  </div>
                {/if}

                <IconButton
                  icon="download"
                  loading={loadingDownload}
                  style="primary-text small"
                  click={download.bind(this, file, null)}
                />

                <Options
                  icon="much"
                  style="bottom icon-only no-border no-padding medium button acts-as-button left"
                  arrow={false}
                >
                  {#if $can("archive", "attachment")}
                    <Button
                      style="blue-text option"
                      click={archive.bind(this, file)}
                    >
                      {archived
                        ? $t("control_upload_item.unarchive")
                        : $t("control_upload_item.archive")}
                    </Button>
                  {/if}
                  {#if canDelete}
                    <Button
                      style="error-text option"
                      click={remove.bind(this, file)}
                    >
                      {$t("control_upload_item.delete")}
                    </Button>
                  {/if}
                </Options>
              {/if}
            </div>

            {#if file.virus && file.report && file.report.preview_url}
              <div class={styles.virus}>
                <Button
                  style="primary-text small"
                  click={onReport.bind(this, file.permalink)}
                >
                  {$t("control_upload_item.report")}
                </Button>
              </div>
            {/if}
          </div>

          {#if onOpenSame}
            <div class={styles.more}>
              <IconButton
                icon={openSame ? "stroke-1-5-up" : "stroke-1-5-down"}
                style="primary-text small"
                click={onOpenSame}
              />
            </div>
          {/if}
        </div>
      {/if}
    </ControlFileItem>
  </div>
</Boundary>

<style lang="scss">
  .items {
    display: flex;
    align-items: center;
  }

  .box {
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 4px;

    .wrapper:hover & {
      background: #fff;
      border: 1px solid var(--primary-050);

      .actions {
        display: flex;
      }

      .virus {
        padding: 4px 0;
        margin: -4px 0 -4px 4px;
        border-left: 1px solid var(--primary-050);
      }
    }
  }

  .actions {
    display: none;
  }

  .checkmark {
    height: 30px;
  }

  .more {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }

  .active {
    border-radius: 6px;
    background-color: var(--blue-010);
  }
</style>
