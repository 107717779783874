<script>
  import styles from "styleguide/ControlGroup.json";
  import { onDestroy, onMount, tick } from "svelte";
  import Sortable from "sortablejs";

  import { user, isUserClient } from "stores/user.js";
  import { t } from "stores/i18n.js";
  import { can } from "stores/permission.js";
  import {
    audit,
    controlGroups,
    collapsedGroups,
    setCollapsedGroups,
    filteredControls,
    selectedControls,
    selectedGroups,
    isSortAll,
    isNumeration,
  } from "stores/pbc.js";

  import ControlApi from "apis/pbc/controls.js";
  import ControlGroupApi from "apis/pbc/control_groups.js";

  import { ControlItem, ControlStatus } from "apps/pbc";

  import {
    Boundary,
    Button,
    Checkbox,
    Dragicon,
    Options,
    TextInput,
  } from "components";

  export let permalink;
  export let name = "";
  export let numeration = "";
  export let nameNumeration = "";
  export let onSort;
  export let tags = {};
  export let creator_permalink = "";
  export let controls = 0;

  let editGroupInput;
  let isEditGroupName = false;
  let errorEditGroupName = null;

  let items = [];
  let list;
  let isSort = false;
  let isSortGroup = false;
  let isSortParent = false;

  $: itemsShow = !($collapsedGroups[permalink] === 1);

  const unsubscribe = filteredControls.subscribe((value) => {
    if (value && value[permalink]) {
      if (Array.isArray(value[permalink])) {
        items = value[permalink].sort((ca, cb) => ca.position - cb.position);
      }
    }
  });

  onMount(() => {
    if (list) {
      new Sortable.create(list, {
        group: "shared",
        handle: ".dragicon-item",
        animation: 200,
        emptyInsertThreshold: 3,
        onEnd: handleSortEnd,
      });
    }
  });

  onDestroy(() => {
    unsubscribe();
  });

  function handleSortEnd(event) {
    const { oldIndex, newIndex, to } = event;

    const [movedItem] = items.splice(oldIndex, 1);
    items.splice(newIndex, 0, movedItem);

    isSortAll.set(false);
    isSort = false;
    isSortParent = to.id;

    ControlApi.reorder({
      id: movedItem.permalink,
      position: newIndex + 1,
      control_group_id: isSortParent,
    });
  }

  function onSortGroup(value) {
    onSort(value);
    isSortGroup = value;
  }

  function onAddNewElement(result) {
    if (result) {
      let control = {
        name: result,
        control_group_id: permalink,
        control_group_name: name,
      };

      ControlApi.create(control);
    }
  }

  function onEditGroupName(result) {
    if (result) {
      let group = {
        name: result,
        id: permalink,
      };

      ControlGroupApi.edit({
        params: group,
        success: () => {
          onEditGroupSuccess(permalink, result);
        },
        error: onEditGroupError,
      });

      name = result;
      nameNumeration = `${numeration}. ${result}`;
    } else {
      isEditGroupName = false;
      errorEditGroupName = "";
    }
  }

  function onEditGroupSuccess(id, name) {
    isEditGroupName = false;
    errorEditGroupName = "";
    controlGroups.update((cgs) =>
      cgs.map((g) => {
        if (g.permalink === id) {
          g.name = name;
        }
        return g;
      }),
    );
  }

  function onEditGroupError() {
    alert($t("control_group.could_not_updated"));

    errorEditGroupName = $t("control_group.could_not_updated");
  }

  async function onEditGroup() {
    isEditGroupName = true;

    await tick();
    editGroupInput.focus();

    editGroupInput.addEventListener("blur", () => {
      isEditGroupName = false;
    });
  }

  function onDeleteGroup() {
    const isConfirmed = confirm($t("control_status.confirm_deletion"));

    if (isConfirmed) {
      ControlGroupApi.delete(permalink);
    }
  }

  function onCheckboxChange() {
    let controlPermalinks = $filteredControls[permalink].map(
      (c) => c.permalink,
    );

    if (isSelected) {
      selectedControls.update((scs) =>
        scs.filter((cp) => !controlPermalinks.includes(cp)),
      );
      selectedGroups.update((gs) => gs.filter((gp) => gp !== permalink));
    } else {
      // prevents duplicates in list
      controlPermalinks = controlPermalinks.filter(
        (cp) => !$selectedControls.includes(cp),
      );
      selectedControls.update((scs) => [...scs, ...controlPermalinks]);
      selectedGroups.update((gs) => [...gs, permalink]);
    }
  }

  function onToggleItems() {
    itemsShow = !itemsShow;

    const newCollapsedGroups = { ...$collapsedGroups };
    newCollapsedGroups[permalink] = itemsShow ? 0 : 1;
    setCollapsedGroups(newCollapsedGroups);
  }

  $: clientUserHasAccessRights =
    $isUserClient &&
    $audit.can_client_users_add_controls &&
    $user.permalink === creator_permalink;
  $: toggleIcon = itemsShow ? "stroke-1-5-down" : "stroke-1-5-up";
  $: isSelected = $selectedGroups.find((gp) => gp === permalink) !== undefined;
</script>

<Boundary>
  <div data-component="ControlGroup" class={styles["control-group"]}>
    <div class={`${styles.header} ${itemsShow ? "" : styles.collapsed}`}>
      <div class={styles["checkbox-wrapper"]}>
        <Checkbox toggle={onCheckboxChange} bind:toggled={isSelected} />
      </div>
      <div class={styles.name}>
        {#if isEditGroupName}
          <TextInput
            bind:input={editGroupInput}
            value={name}
            onEnter={onEditGroupName}
            error={[errorEditGroupName]}
          />
        {:else if $isNumeration}
          {nameNumeration}
        {:else}
          {name}
        {/if}
      </div>

      <div class={styles.meta}>
        {#if itemsShow}
          {#if $can("update", "control_group") || $can("destroy", "control_group") || clientUserHasAccessRights}
            <Options
              title={$t("control_group.options_label")}
              style="medium no-border acts-as-button"
            >
              {#if $can("update", "control_group") || clientUserHasAccessRights}
                <Button click={onEditGroup} style="blue-text option">
                  {$t("control_group.edit_control_group")}
                </Button>
              {/if}
              {#if $can("destroy", "control_group") || (clientUserHasAccessRights && !controls.length)}
                <Button click={onDeleteGroup} style="error-text option">
                  {$t("control_group.delete_control_group")}
                </Button>
              {/if}
            </Options>
          {/if}
        {:else}
          <div class={styles.tags}>
            {#each Object.entries(tags) as [status, label] (status)}
              <div class={styles.tag}>
                <ControlStatus {status} {label} count={true} size={"medium"} />
              </div>
            {/each}
          </div>
        {/if}

        <div class={styles.collapse}>
          <Button click={onToggleItems} style="primary-text small">
            {itemsShow
              ? $t("control_group.collapse")
              : $t("control_group.expand")}
          </Button>
        </div>
      </div>
      {#if $user.role !== "client"}
        <div class={`${styles.dragicon} dragicon-group`}>
          <Dragicon onSort={onSortGroup} />
        </div>
      {/if}
    </div>

    {#if itemsShow}
      <div bind:this={list} id={permalink}>
        {#each items as item (item.id)}
          <ControlItem
            {...item}
            onSort={(value) => {
              isSort = value;
            }}
          />
        {/each}
      </div>

      {#if $can("create", "control") || ($isUserClient && $audit.can_client_users_add_controls)}
        <div class={styles["new-control"]}>
          <TextInput
            button={true}
            style="no-border"
            clearOnEnter="true"
            onEnter={onAddNewElement}
            onIconClick={onAddNewElement}
            placeholder={$t("control_group.new_control")}
          />
        </div>
      {/if}
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .control-group {
    display: flex;
    flex-direction: column;
    margin-top: 18px;
    border: 1px solid var(--primary-050);
    background: #fff;
    border-radius: var(--border-radius);
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .collapsed {
    position: relative;
  }

  .collapsed:after,
  .collapsed:before {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 100%;
    position: absolute;
    height: 10px;
    content: "";
    border: 1px solid var(--primary-050);
    border-top: none;
    left: -1px;
  }

  .collapsed:before {
    bottom: -4px;
    opacity: 0.8;
  }

  .collapsed:after {
    bottom: -7px;
    opacity: 0.6;
  }

  .checkbox-wrapper {
    width: 50px;
    padding-left: 15px;
  }

  .name {
    display: flex;
    align-items: center;
    min-height: 62px;
    padding: 10px 0;
    font-size: 16px;
    flex: 1;
    margin-right: 10%;
  }

  .new-control {
    padding: 0 9px;
    border-top: 1px solid var(--primary-050);
  }

  .meta {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .shadow {
    height: 40px;
  }

  .tags {
    display: flex;
    margin-right: 20px;
  }

  .tag {
    margin: 0 4px;
  }

  .collapse {
    margin-right: 18px;
  }

  .dragicon {
    display: flex;
    margin-right: 22px;
    align-items: center;
  }
</style>
