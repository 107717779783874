<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
  ><g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(24.313725%,36.862745%,72.54902%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,85.098039%,0%);fill-opacity:1;"
      d="M -30 64.285156 L 510 64.285156 L 510 295.710938 L -30 295.710938 Z M -30 64.285156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(69.411765%,4.705882%,4.705882%);fill-opacity:1;"
      d="M -30 90 L 510 90 L 510 270 L -30 270 Z M -30 90 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,85.098039%,0%);fill-opacity:1;stroke-width:1.4742;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -45.672222 250.511107 L 564.505556 250.511107 C 567.688889 250.511107 570.272222 252.966663 570.272222 255.994441 C 570.272222 259.027774 567.688889 261.483329 564.505556 261.483329 L -45.672222 261.483329 C -48.855556 261.483329 -51.438889 259.027774 -51.438889 255.994441 C -51.438889 252.966663 -48.855556 250.511107 -45.672222 250.511107 Z M -45.672222 250.511107 "
      transform="matrix(0.703125,0,0,0.703125,60,0.00000274628)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:4.1748;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -106.30003 265.750242 L -194.878239 301.186802 L -106.30003 336.623363 L -17.721822 301.186802 Z M -106.30003 265.750242 "
      transform="matrix(0.246736,0,0,0.217709,439.76715,80.999357)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,85.098039%,0%);fill-opacity:1;stroke-width:1.3333;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 765.916309 223.228796 L 1200.612559 223.228796 C 1202.878705 223.228796 1204.719612 224.816713 1204.719612 226.770658 C 1204.719612 228.729986 1202.878705 230.317903 1200.612559 230.317903 L 765.916309 230.317903 C 763.650163 230.317903 761.809256 228.729986 761.809256 226.770658 C 761.809256 224.816713 763.650163 223.228796 765.916309 223.228796 Z M 765.916309 223.228796 "
      transform="matrix(0.725695,0,0,0.725695,-472.286719,-17.999997)"
    />
    <path
      style="fill:none;stroke-width:2.6666;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 1224.398225 279.91704 C 1220.856434 279.91704 1217.314643 276.375178 1217.314643 272.833316 C 1217.314643 269.291454 1220.856434 265.749592 1224.398225 265.749592 "
      transform="matrix(-0.362855,0,0,0.362848,838.536724,47.569477)"
    />
    <path
      style="fill:none;stroke-width:2.6666;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 1224.395824 279.91704 C 1220.864799 279.91704 1217.312243 276.375178 1217.312243 272.833316 C 1217.312243 269.291454 1220.864799 265.749592 1224.395824 265.749592 "
      transform="matrix(-0.362855,0,0,0.362848,835.996791,47.569477)"
    />
    <path
      style="fill:none;stroke-width:2.6666;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 1224.397969 279.91504 C 1220.856178 279.91504 1217.314387 276.373178 1217.314387 272.831316 C 1217.314387 269.289454 1220.856178 265.747592 1224.397969 265.747592 "
      transform="matrix(-0.362855,0,0,0.362848,833.384287,47.570203)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:4.1748;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -106.303197 265.75523 L -194.881405 301.191791 L -106.303197 336.610408 L -17.724989 301.191791 Z M -106.303197 265.75523 "
      transform="matrix(0.246736,0,0,0.217709,419.193712,96.427959)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,85.098039%,0%);fill-opacity:1;stroke-width:1.3333;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 765.920615 223.230293 L 1200.611483 223.230293 C 1202.883011 223.230293 1204.718535 224.818209 1204.718535 226.772154 C 1204.718535 228.731482 1202.883011 230.314016 1200.611483 230.314016 L 765.920615 230.314016 C 763.649087 230.314016 761.80818 228.731482 761.80818 226.772154 C 761.80818 224.818209 763.649087 223.230293 765.920615 223.230293 Z M 765.920615 223.230293 "
      transform="matrix(0.725695,0,0,0.725695,-492.860156,-2.571396)"
    />
    <path
      style="fill:none;stroke-width:2.6666;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 1224.400378 279.920032 C 1220.858587 279.920032 1217.306031 276.378171 1217.306031 272.836309 C 1217.306031 269.294447 1220.858587 265.74182 1224.400378 265.74182 "
      transform="matrix(-0.362855,0,0,0.362848,817.963287,62.998079)"
    />
    <path
      style="fill:none;stroke-width:2.6666;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 1224.397978 279.920032 C 1220.856187 279.920032 1217.314396 276.378171 1217.314396 272.836309 C 1217.314396 269.294447 1220.856187 265.74182 1224.397978 265.74182 "
      transform="matrix(-0.362855,0,0,0.362848,815.423353,62.998079)"
    />
    <path
      style="fill:none;stroke-width:2.6666;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 1224.400122 279.918032 C 1220.858331 279.918032 1217.305775 276.376171 1217.305775 272.834309 C 1217.305775 269.292447 1220.858331 265.750585 1224.400122 265.750585 "
      transform="matrix(-0.362855,0,0,0.362848,812.81085,62.998804)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(23.921569%,36.470588%,65.490196%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 338.067628 -0.413649 C 332.497584 12.027653 342.34712 -4.555947 366.370377 16.278011 C 370.82306 20.146557 374.711476 30.361824 374.711476 37.450557 C 373.582941 36.478017 372.739334 34.309614 371.499064 32.897631 C 369.755981 35.152482 373.158344 45.475809 373.661157 50.352914 C 369.901238 47.644212 370.26438 46.3619 369.018523 43.033654 C 369.264342 47.060688 368.370453 58.147639 369.867717 62.419608 C 366.906711 61.331805 367.102249 58.255698 365.554704 57.636155 C 366.61061 62.470036 364.638468 67.728954 365.163628 73.167971 C 363.431718 70.956344 361.588073 69.227385 360.783573 68.33409 C 360.64949 70.927528 357.459425 77.728101 357.386796 80.170256 C 355.917467 79.032024 355.520804 77.245433 355.274985 76.042366 C 353.453686 79.003208 346.967407 89.917263 346.548397 93.24551 C 341.553794 87.561556 328.760012 73.701067 327.089558 66.345787 C 325.620229 70.524105 323.486069 71.928884 319.273619 74.983378 C 317.608752 63.327312 311.385052 50.223242 314.714787 40.231299 C 312.357156 42.039502 310.412948 43.840501 308.055317 46.059332 C 310.290038 29.180369 321.681532 9.383786 338.067628 -0.413649 Z M 338.067628 -0.413649 "
      transform="matrix(0.699192,0,0,0.542234,193.852303,177.161795)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(65.490196%,0%,0%);fill-opacity:1;"
      d="M 415.695312 210.351562 C 417.3125 207.117188 418.800781 206.089844 419.847656 204.011719 C 421.695312 200.351562 421.957031 197.441406 423.566406 197.867188 C 425.175781 198.292969 425.171875 199.792969 423.089844 203.390625 C 421.003906 206.988281 420.015625 207.753906 415.695312 210.351562 Z M 415.695312 210.351562 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(65.490196%,0%,0%);fill-opacity:1;"
      d="M 426.636719 218.050781 C 426.429688 215.589844 427.140625 214.558594 427.011719 212.976562 C 426.789062 210.1875 425.648438 208.269531 427.132812 208.136719 C 428.613281 208 429.308594 208.949219 429.3125 211.761719 C 429.316406 214.570312 428.882812 215.308594 426.636719 218.050781 Z M 426.636719 218.050781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(65.490196%,0%,0%);fill-opacity:1;"
      d="M 434.9375 222.875 C 434.289062 219.441406 434.773438 217.914062 434.359375 215.703125 C 433.636719 211.804688 432.207031 209.242188 433.582031 208.886719 C 434.957031 208.53125 435.785156 209.789062 436.308594 213.738281 C 436.828125 217.691406 436.554688 218.777344 434.9375 222.875 Z M 434.9375 222.875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(65.490196%,0%,0%);fill-opacity:1;"
      d="M 443.566406 198.796875 C 441.539062 197.257812 440.355469 197.15625 439.046875 196.167969 C 436.746094 194.433594 435.582031 192.578125 434.839844 193.5 C 434.101562 194.421875 434.625 195.425781 437.039062 197.027344 C 439.449219 198.628906 440.269531 198.753906 443.566406 198.796875 Z M 443.566406 198.796875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(65.490196%,0%,0%);fill-opacity:1;"
      d="M 442.136719 210.207031 C 441.070312 207.988281 439.945312 207.425781 439.253906 206 C 438.03125 203.492188 438.03125 201.320312 436.703125 201.875 C 435.378906 202.433594 435.273438 203.570312 436.691406 205.996094 C 438.109375 208.425781 438.847656 208.863281 442.136719 210.207031 Z M 442.136719 210.207031 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(23.921569%,36.470588%,65.490196%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 329.59872 20.7035 C 329.330028 18.040843 329.852756 17.72323 328.343196 15.71168 C 330.541585 16.712161 330.624635 19.364231 333.301784 17.479726 C 334.283731 16.929197 334.713638 16.913316 333.516738 13.95422 C 336.306249 14.097146 345.446661 17.490313 346.907368 17.569717 C 350.752106 17.760285 357.762524 13.197242 362.633177 18.808408 C 367.303532 23.927274 365.745118 29.236707 365.745118 36.324775 C 363.864274 35.350761 364.768056 34.869047 362.779736 32.333436 C 364.269755 38.532186 362.696686 49.701583 362.696686 56.270882 C 361.929692 54.682816 362.042054 55.370978 361.294602 53.729976 C 359.301396 59.727572 356.91248 60.96097 356.91248 68.049037 C 356.19434 65.269922 356.888054 65.857506 356.086863 64.396485 C 354.152281 68.948941 341.162245 72.839702 346.169687 77.429213 C 341.650776 74.560107 339.672226 74.861839 337.620397 72.278586 C 336.741041 72.897931 336.037557 74.062513 335.216825 75.666459 C 327.273313 71.706882 330.111678 63.163088 323.89268 57.461931 C 322.813027 59.854617 323.306443 59.505243 322.138854 63.401297 C 320.88333 57.991286 320.536473 54.555771 319.119733 50.580312 C 317.898406 52.78243 318.005883 51.940755 315.787953 55.2704 C 314.869515 48.679927 313.37461 47.128916 313.965733 42.767028 C 311.606129 44.57213 313.149886 43.84162 310.790282 46.059618 C 313.027754 29.183772 322.676238 16.706868 329.59872 20.7035 Z M 329.59872 20.7035 "
      transform="matrix(0.799591,0,0,0.737926,-220.84113,168.15985)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(65.490196%,0%,0%);fill-opacity:1;"
      d="M 34.894531 203.765625 C 36.53125 199.757812 38.003906 198.496094 39.0625 195.914062 C 40.929688 191.378906 41.234375 187.75 42.800781 188.304688 C 44.371094 188.855469 44.347656 190.726562 42.246094 195.183594 C 40.148438 199.644531 39.164062 200.589844 34.894531 203.765625 Z M 34.894531 203.765625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(65.490196%,0%,0%);fill-opacity:1;"
      d="M 41.089844 213.800781 C 41.746094 210.203125 42.78125 208.894531 43.207031 206.582031 C 43.960938 202.507812 43.542969 199.476562 45 199.636719 C 46.457031 199.796875 46.789062 201.332031 45.816406 205.382812 C 44.847656 209.433594 44.175781 210.394531 41.089844 213.800781 Z M 41.089844 213.800781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(65.490196%,0%,0%);fill-opacity:1;"
      d="M 48.5 220.777344 C 48.6875 217.289062 49.503906 215.929688 49.625 213.683594 C 49.847656 209.734375 49.085938 206.910156 50.476562 206.890625 C 51.867188 206.875 52.363281 208.296875 51.9375 212.25 C 51.511719 216.203125 50.996094 217.191406 48.5 220.777344 Z M 48.5 220.777344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(65.490196%,0%,0%);fill-opacity:1;"
      d="M 64.414062 197.980469 C 61.371094 194.949219 59.53125 194.382812 57.570312 192.433594 C 54.113281 189.011719 52.441406 185.796875 51.210938 186.9375 C 49.980469 188.074219 50.71875 189.78125 54.359375 193.042969 C 58 196.300781 59.265625 196.773438 64.414062 197.980469 Z M 64.414062 197.980469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(65.490196%,0%,0%);fill-opacity:1;"
      d="M 62.566406 209.699219 C 60.582031 206.65625 59.128906 205.867188 57.847656 203.910156 C 55.59375 200.46875 54.816406 197.507812 53.535156 198.242188 C 52.257812 198.972656 52.542969 200.519531 54.988281 203.855469 C 57.433594 207.191406 58.417969 207.804688 62.566406 209.699219 Z M 62.566406 209.699219 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:2.1082;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 637.796866 230.320792 C 584.653057 289.369764 513.778496 407.47668 372.053597 407.47668 C 248.027152 407.47668 159.446062 289.369764 106.302253 230.320792 C 159.446062 171.262848 248.027152 53.146961 372.053597 53.146961 C 513.778496 53.146961 584.653057 171.262848 637.796866 230.320792 Z M 637.796866 230.320792 "
      transform="matrix(0.483799,0,0,0.435417,60.000733,79.710462)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 231.023438 129.6875 C 237.863281 137.382812 232.152344 140.484375 239.394531 140.988281 C 247.03125 141.550781 242.703125 149.117188 249.125 149.382812 C 253.617188 149.578125 248.675781 167.519531 253 173.4375 C 257.402344 179.609375 261.089844 175.246094 261.167969 179.703125 C 261.246094 184.316406 249.183594 183.851562 248.972656 198.054688 C 248.617188 206.242188 238.792969 206.765625 238.324219 212.03125 C 237.742188 216.988281 257.679688 219.769531 257.414062 224.203125 C 257.140625 228.625 235.886719 227.933594 234.960938 232.976562 C 234.484375 237.503906 264.160156 241.21875 266.546875 254.316406 C 262.117188 255.765625 249.519531 257.128906 239.996094 257.136719 C 180 257.183594 137.140625 205.707031 111.429688 179.996094 C 137.140625 154.28125 179.996094 102.855469 239.996094 102.855469 C 239.996094 102.855469 222.1875 119.128906 231.023438 129.6875 Z M 231.023438 129.6875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 159.429688 152.570312 L 165.660156 152.570312 L 165.660156 173.140625 L 159.429688 173.140625 Z M 159.429688 152.570312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 159.429688 186.855469 L 165.660156 186.855469 L 165.660156 207.425781 L 159.429688 207.425781 Z M 159.429688 186.855469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 171.894531 152.570312 L 178.128906 152.570312 L 178.128906 173.140625 L 171.894531 173.140625 Z M 171.894531 152.570312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 171.894531 186.855469 L 178.128906 186.855469 L 178.128906 207.425781 L 171.894531 207.425781 Z M 171.894531 186.855469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 184.359375 152.570312 L 190.59375 152.570312 L 190.59375 173.140625 L 184.359375 173.140625 Z M 184.359375 152.570312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 184.359375 186.855469 L 190.59375 186.855469 L 190.59375 207.425781 L 184.359375 207.425781 Z M 184.359375 186.855469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 196.828125 152.570312 L 203.0625 152.570312 L 203.0625 173.140625 L 196.828125 173.140625 Z M 196.828125 152.570312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 196.828125 186.855469 L 203.0625 186.855469 L 203.0625 207.425781 L 196.828125 207.425781 Z M 196.828125 186.855469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 209.296875 152.570312 L 215.53125 152.570312 L 215.53125 173.140625 L 209.296875 173.140625 Z M 209.296875 152.570312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 209.296875 186.855469 L 215.53125 186.855469 L 215.53125 207.425781 L 209.296875 207.425781 Z M 209.296875 186.855469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 221.761719 152.570312 L 227.996094 152.570312 L 227.996094 173.140625 L 221.761719 173.140625 Z M 221.761719 152.570312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 221.761719 186.855469 L 227.996094 186.855469 L 227.996094 207.425781 L 221.761719 207.425781 Z M 221.761719 186.855469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 253.710938 152.570312 L 259.945312 152.570312 L 259.945312 173.140625 L 253.710938 173.140625 Z M 253.710938 152.570312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 253.710938 186.855469 L 259.945312 186.855469 L 259.945312 207.425781 L 253.710938 207.425781 Z M 253.710938 186.855469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 266.175781 152.570312 L 272.410156 152.570312 L 272.410156 173.140625 L 266.175781 173.140625 Z M 266.175781 152.570312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 266.175781 186.855469 L 272.410156 186.855469 L 272.410156 207.425781 L 266.175781 207.425781 Z M 266.175781 186.855469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 278.640625 152.570312 L 284.875 152.570312 L 284.875 173.140625 L 278.640625 173.140625 Z M 278.640625 152.570312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 278.640625 186.855469 L 284.875 186.855469 L 284.875 207.425781 L 278.640625 207.425781 Z M 278.640625 186.855469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 291.113281 152.570312 L 297.347656 152.570312 L 297.347656 173.140625 L 291.113281 173.140625 Z M 291.113281 152.570312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 291.113281 186.855469 L 297.347656 186.855469 L 297.347656 207.425781 L 291.113281 207.425781 Z M 291.113281 186.855469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 303.578125 152.570312 L 309.8125 152.570312 L 309.8125 173.140625 L 303.578125 173.140625 Z M 303.578125 152.570312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 303.578125 186.855469 L 309.8125 186.855469 L 309.8125 207.425781 L 303.578125 207.425781 Z M 303.578125 186.855469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 316.042969 152.570312 L 322.277344 152.570312 L 322.277344 173.140625 L 316.042969 173.140625 Z M 316.042969 152.570312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 316.042969 186.855469 L 322.277344 186.855469 L 322.277344 207.425781 L 316.042969 207.425781 Z M 316.042969 186.855469 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(23.921569%,36.470588%,65.490196%);fill-opacity:1;stroke-width:1.333375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 338.072359 -0.418295 C 332.500871 12.026301 347.822463 -4.552384 371.849505 16.273267 C 376.30303 20.14633 382.680917 35.433994 382.680917 42.516972 C 377.567611 41.261225 368.507834 35.433994 368.507834 35.433994 C 368.507834 35.433994 379.137646 47.73044 379.137646 61.903452 C 375.380557 59.187369 373.505067 59.596545 372.252705 56.266699 C 372.252705 60.852293 375.594375 63.780018 375.594375 70.862997 C 372.881941 68.365612 370.591033 66.700688 368.294016 65.240353 C 371.837287 72.330387 361.83671 86.073058 366.840053 90.665707 C 358.091839 89.205372 349.135917 82.743212 345.592646 75.660233 C 343.717156 77.120568 343.509447 79.413365 343.436138 81.861367 C 343.711047 82.122393 330.118327 70.206904 331.364581 65.628365 C 329.537964 68.584309 329.281382 70.206904 328.865964 73.53675 C 323.87484 67.843559 319.293024 62.157423 317.625243 54.799309 C 315.407644 58.129155 315.267135 58.129155 313.049537 61.459002 C 311.381756 49.804539 311.381756 50.22077 314.711208 40.23123 C 312.353101 42.037249 310.410411 43.843267 308.052303 46.058462 C 310.288229 29.183477 321.681677 9.387821 338.072359 -0.418295 Z M 338.072359 -0.418295 "
      transform="matrix(0.639416,0,0,0.553704,-17.750998,116.786299)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(65.490196%,0%,0%);fill-opacity:1;"
      d="M 189.640625 150.21875 C 190.207031 145.472656 191.449219 143.683594 191.820312 140.625 C 192.480469 135.25 191.695312 131.320312 193.601562 131.417969 C 195.507812 131.515625 196.058594 133.5 195.113281 138.867188 C 194.171875 144.230469 193.378906 145.535156 189.640625 150.21875 Z M 189.640625 150.21875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(65.490196%,0%,0%);fill-opacity:1;"
      d="M 199.585938 158.910156 C 199.199219 154.90625 199.9375 153.199219 199.691406 150.621094 C 199.269531 146.082031 197.867188 143.003906 199.527344 142.722656 C 201.1875 142.441406 202.027344 143.960938 202.207031 148.546875 C 202.386719 153.128906 201.941406 154.351562 199.585938 158.910156 Z M 199.585938 158.910156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(65.490196%,0%,0%);fill-opacity:1;"
      d="M 215.109375 166.003906 C 213.421875 162.535156 211.972656 161.503906 210.882812 159.273438 C 208.957031 155.351562 208.554688 152.148438 207.070312 152.730469 C 205.585938 153.308594 205.664062 154.964844 207.808594 158.804688 C 209.953125 162.640625 210.925781 163.425781 215.109375 166.003906 Z M 215.109375 166.003906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(65.490196%,0%,0%);fill-opacity:1;"
      d="M 220.476562 134.960938 C 216.175781 132.699219 213.96875 132.667969 211.199219 131.214844 C 206.316406 128.664062 203.476562 125.785156 202.46875 127.371094 C 201.460938 128.957031 202.804688 130.53125 207.835938 132.851562 C 212.867188 135.167969 214.414062 135.277344 220.476562 134.960938 Z M 220.476562 134.960938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(65.490196%,0%,0%);fill-opacity:1;"
      d="M 222.054688 147.925781 C 218.925781 145.320312 217.074219 144.9375 215.054688 143.261719 C 211.496094 140.320312 209.726562 137.433594 208.535156 138.605469 C 207.347656 139.773438 208.140625 141.320312 211.875 144.089844 C 215.609375 146.859375 216.886719 147.203125 222.054688 147.925781 Z M 222.054688 147.925781 "
    />
  </g>
</svg>
