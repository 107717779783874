<script>
  import { iconPath } from "lib/helpers.js";
  import styles from "styleguide/Button.json";

  export let style = "";
  export let newtab = false; // for navigate
  export let click = false;
  export let link = false;
  export let disabled = false;
  export let navigate = false;
  export let loading = false;
  export let icon = null;
  export let close = false;

  $: calculatedStyles = function () {
    let s = style.split(" ");
    if (disabled) {
      s.push("disabled");
    }
    if (icon !== null) {
      s.push("icon");
    }
    if (close) {
      s.push("close");
    }

    const res = s.map((x) => styles[x]).join(" ");
    return res;
  };

  function clickHandler(event) {
    if (!disabled && navigate) {
      if (newtab) {
        window.open(navigate, "_blank");
        event.target.blur();
      } else {
        event.target.blur();
      }
    }

    if (!disabled && !loading && click) {
      click(event);
      event.target.blur();
    }

    if (!disabled && link) {
      window.location = link;
      event.target.blur();
    }
    event.target.blur();
  }
</script>

<button
  data-component="Button"
  {disabled}
  class={calculatedStyles()}
  on:click={clickHandler}
>
  <slot />
  {#if close}
    <img class={styles.close} src={iconPath("red_close")} alt={"close"} />
  {/if}
  {#if icon}
    <img class={styles.icon} src={iconPath(icon)} alt={icon} />
  {/if}
</button>

<style scoped lang="scss">
  @mixin option-styles {
    justify-content: flex-start;
    width: 100%;
    border-radius: 0;
    border-top: 1px solid var(--primary-050);
    height: auto;
    padding: 10px 12px;
  }

  button,
  button:hover,
  button:focus {
    all: unset;
    position: relative;
    box-sizing: border-box;
    font-family: var(--default-font);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 40px;
    padding: 0 10px;
    background: transparent;
    color: var(--brand-200);
    font-weight: 500;
    line-height: 20px;
    border-radius: var(--border-radius);
    border: 2px solid transparent;
    white-space: nowrap;
    display: flex;
    gap: 8px;
    white-space: nowrap;
  }

  .wrap,
  .wrap:hover,
  .wrap:focus {
    text-wrap: wrap;
  }

  .primary {
    background: var(--brand-100);
    color: var(--brand-button-text-color, #fff);

    &:hover {
      background: var(--brand-200);
      color: var(--brand-button-text-color, #fff);
    }
    &:focus:not(:active) {
      background: var(--brand-100);
      color: var(--brand-button-text-color, #fff);
    }

    &:active {
      background: var(--brand-300);
      color: var(--brand-button-text-color, #fff);
    }

    &.disabled {
      background: var(--primary-025);
      color: var(--primary-200);
    }
  }

  .primary-tonal {
    background: var(--brand-010);
    color: var(--brand-tonal-button-text-color, var(--brand-200));

    &:hover {
      background: var(--brand-025);
      color: var(--brand-tonal-button-text-color, var(--brand-200));
    }

    &:focus:not(:active) {
      background: var(--brand-010);
      color: var(--brand-tonal-button-text-color, var(--brand-200));
    }

    &:active {
      background: var(--brand-050);
      color: var(--brand-tonal-button-text-color, var(--brand-200));
    }

    &.disabled {
      background: var(--primary-025);
      color: var(--primary-200);
    }
  }

  .primary-text {
    background: transparent;
    color: var(--admin-button-text-color, var(--blue-200));

    &:hover {
      background: var(--blue-010);
      color: var(--admin-button-text-color, var(--blue-200));
    }

    &:focus:not(:active) {
      background: transparent;
      color: var(--admin-button-text-color, var(--blue-200));
    }

    &:active {
      background: var(--blue-025);
      color: var(--admin-button-text-color, var(--blue-200));
    }

    &.disabled {
      background: transparent;
      color: var(--admin-button-text-color, var(--primary-100));
    }
  }

  .error {
    background: var(--red-100);
    color: #fff;

    &:hover {
      background: var(--red-200);
      color: #fff;
    }

    &:focus:not(:active) {
      background: var(--red-100);
      color: #fff;
    }

    &:active {
      background: var(--red-300);
      color: #fff;
    }

    &.disabled {
      background: var(--primary-025);
      color: var(--primary-200);
    }
  }

  .error-tonal {
    background: var(--red-010);
    color: var(--red-200);

    &:hover {
      background: var(--red-025);
      color: var(--red-200);
    }

    &:focus:not(:active) {
      background: var(--red-010);
      color: var(--red-200);
    }

    &:active {
      background: var(--red-050);
      color: var(--red-200);
    }

    &.disabled {
      background: var(--primary-025);
      color: var(--primary-200);
    }
  }

  .error-text {
    background: transparent;
    color: var(--red-200);

    &:hover {
      background: var(--red-010);
      color: var(--red-200);
    }

    &:focus:not(:active) {
      background: transparent;
      color: var(--red-200);
    }

    &:active {
      background: var(--red-025);
      color: var(--red-200);
    }

    &.disabled {
      background: transparent;
      color: var(--primary-100);
    }
  }

  .small,
  .small:hover,
  .small:focus {
    height: 30px;
  }

  .icon,
  .icon:focus,
  .icon:hover {
    justify-content: center;
    align-items: center;
    &:focus {
      outline: 2px solid var(--brand-300);
    }
    img.icon {
      margin-right: 0;
      &.close {
        background-color: red;
        height: 24px;
      }
    }
  }

  .filter-action,
  .filter-action:hover,
  .filter-action:focus {
    color: var(--blue-200);
    justify-content: flex-start;
    width: 100%;
    border-radius: 0;
    padding: 0;
    height: 40px;
    gap: 14px;
    &.disabled {
      cursor: default;
      background: transparent;
      color: var(--admin-button-text-color, var(--primary-100));
    }
  }

  .sidebar-action,
  .sidebar-action:hover,
  .sidebar-action:focus {
    justify-content: flex-start;
    width: 100%;
    border-radius: 0;
    border-top: 1px solid var(--primary-050);
    padding: 10px 20px;
    height: 50px;

    img {
      padding: 6px;
      margin-right: 6px;
    }

    &:last-child {
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
    }
    &.last-but-only-child {
      border-radius: 0;
    }
  }

  .tooltip-option,
  .tooltip-option:hover,
  .tooltip-option:focus {
    @include option-styles;
  }

  .option,
  .option:hover,
  .option:focus {
    @include option-styles;

    &:first-child {
      border-top: 0;
      border-top-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
    }

    &:last-child {
      border-bottom-left-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }
  }

  .reverse {
    background: var(--white);

    &:focus:not(:active) {
      background: var(--white);
      color: var(--blue-200);

      outline-offset: 2px;
      outline: 2px solid var(--white);
    }
  }

  .blue {
    background: var(--blue-100);
    color: var(--white);

    &:hover {
      background: var(--blue-200);
      color: var(--white);
    }
    &:focus:not(:active) {
      background: var(--blue-100);
      color: var(--white);
    }

    &:active {
      background: var(--blue-300);
      color: var(--white);
    }

    &.disabled {
      background: var(--blue-025);
      color: var(--white);
    }
  }

  .blue-tonal {
    background: var(--blue-010);
    color: var(--blue-200);

    &:hover {
      background: var(--blue-025);
      color: var(--blue-200);
    }

    &:focus:not(:active) {
      background: var(--blue-010);
      color: var(--blue-200);
    }

    &:active {
      background: var(--blue-050);
      color: var(--blue-200);
    }

    &.disabled {
      background: var(--blue-025);
      color: var(--blue-200);
    }
  }

  .blue-text {
    background: transparent;
    color: var(--blue-100);

    &:hover {
      background: var(--blue-010);
      color: var(--blue-200);
    }

    &:focus:not(:active) {
      background: transparent;
      color: var(--blue-200);
    }

    &:active {
      background: var(--blue-025);
      color: var(--blue-200);
    }

    &.disabled {
      background: transparent;
      color: var(--primary-100);
    }
  }
</style>
