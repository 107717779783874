import Api from "apis/api";

class ClientApi {
  constructor() {
    this.endpoint = "/clients";
  }

  search(options) {
    Api.get(`${this.endpoint}/search`, options);
  }
}

export default new ClientApi();
