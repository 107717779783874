<script>
  export let width = 18;
  export let height = 18;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 18 18"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M4.1338,0.6695 L0.8658,6.3295 C0.4818,6.9965 0.9618,7.8295 1.7328,7.8295 L8.2678,7.8295 C9.0378,7.8295 9.5188,6.9965 9.1338,6.3295 L5.8658,0.6695 C5.4818,0.0035 4.5188,0.0035 4.1338,0.6695"
    transform="rotate(90 4.5 8.5)"
  />
</svg>
