<script>
  import { updatePageHistory } from "stores/page_history.js";
  import { App } from "components";

  export let appConfig;
  export let initialState;

  if (initialState?.pageHistoryItem) {
    updatePageHistory([initialState.pageHistoryItem]);
  }
</script>

<App {...appConfig} />

<style global lang="scss">
</style>
