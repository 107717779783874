<script>
  import { user } from "stores/user.js";
  import styles from "styleguide/Header.json";

  export let type;
  export let headline;
  export let client_name;
  export let client_official_name;

  $: _type = buildType(type, client_name, client_official_name);

  function buildType(type, client_name, client_official_name) {
    if ($user.role === "team" && client_name) {
      return `${type} – ${client_name}`;
    } else if ($user.role === "client" && client_official_name) {
      return `${type} – ${client_official_name}`;
    } else {
      return type;
    }
  }
</script>

<header data-component="Header">
  <div class={styles.type}>{_type}</div>
  <div class={styles.headline}>{headline}</div>
</header>

<style lang="scss">
  header {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 25px 0;
  }

  .type {
    color: var(--primary-300);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    line-height: 22px;
  }

  .headline {
    font-size: 22px;
    line-height: 32px;
  }
</style>
