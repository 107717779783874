<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(100%,0%,0%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,41.960784%);fill-opacity:1;"
      d="M 0 0 L 235.804688 0 L 235.804688 117.90625 L 0 117.90625 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 122.078125 108.300781 L 112.726562 101.988281 L 103.765625 108.40625 L 106.609375 97.148438 L 98.007812 90.210938 L 109.121094 89.570312 L 112.765625 78.863281 L 116.785156 89.722656 L 127.640625 90.042969 L 119.015625 97.398438 Z M 122.078125 108.300781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 120.476562 33.429688 L 112.992188 28.625 L 105.648438 33.394531 L 107.6875 24.429688 L 101.101562 18.566406 L 109.847656 17.835938 L 113.113281 9.441406 L 116.484375 17.953125 L 125.09375 18.625 L 118.425781 24.621094 Z M 120.476562 33.429688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 87.414062 60.371094 L 80.132812 55.375 L 72.691406 60.371094 L 75.007812 51.617188 L 68.148438 45.789062 L 76.851562 45.375 L 80.058594 36.78125 L 83.125 45.277344 L 91.960938 45.792969 L 85.152344 51.460938 Z M 87.414062 60.371094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 153.699219 54.351562 L 146.714844 49.84375 L 139.917969 54.40625 L 141.871094 46.09375 L 135.609375 40.773438 L 143.804688 40.140625 L 146.730469 32.292969 L 149.835938 40.214844 L 157.90625 40.683594 L 151.636719 46.210938 Z M 153.699219 54.351562 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 134.160156 74.039062 L 129.503906 71.136719 L 124.863281 74.066406 L 126.074219 68.558594 L 121.960938 64.863281 L 127.367188 64.359375 L 129.46875 59.148438 L 131.601562 64.34375 L 137.011719 64.816406 L 132.921875 68.535156 Z M 134.160156 74.039062 "
    />
  </g>
</svg>
