import { SampleApi } from "apps/sample/api";
import { parentId } from "apps/sample/stores";
import { get } from "svelte/store";

class NavigationService {
  constructor() {}

  navigation({ onSuccess, onError, params = {} }) {
    SampleApi.navigation({
      params: {
        id: get(parentId),
        filterParams: params.filterParams,
      },
      success: (responce) => {
        onSuccess?.(responce.data);
      },
      error: (error) => {
        onError?.(error);
      },
    });
  }
}

export default new NavigationService();
