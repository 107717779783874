<script>
  import styles from "styleguide/ConfirmationSignerHeader.json";
  import { LanguageSwitcher, Logo } from "components";
</script>

<div data-component="ConfirmationSignerHeader" class={styles.header}>
  <Logo />

  <LanguageSwitcher />
</div>

<style lang="scss">
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 15px;
    border-bottom: solid 1px var(--primary-050);
    background: var(--header-background-color);
  }

  @media only screen and (min-width: 1023px) {
    .header {
      padding: 30px 35px;
    }
  }
</style>
