<script>
  import { iconPath } from "lib/helpers";
  import styles from "styleguide/AnnouncementBanner.json";

  export let title;
</script>

<div class={styles.banner}>
  {#if title}
    <div class={styles.title}>
      <img src={iconPath("white_info")} alt="info" />
      <strong>{title}</strong>
    </div>
  {/if}
  <slot />
</div>

<style lang="scss">
  .banner {
    background-color: var(--blue);
    color: white;
    padding: 12px 40px;
    display: flex;
    gap: 8px;
    flex-direction: column;
  }
  .title {
    display: flex;
    gap: 8px;
    align-items: center;
  }
</style>
