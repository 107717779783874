<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(100%,89.019608%,0%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(6.666667%,52.54902%,0%);fill-opacity:1;"
      d="M 0 146.347656 L 539.738281 146.347656 L 539.738281 218.636719 L 0 218.636719 Z M 0 146.347656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(6.666667%,52.54902%,0%);fill-opacity:1;"
      d="M 0 0.175781 L 539.738281 0.175781 L 539.738281 72.460938 L 0 72.460938 Z M 0 0.175781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(84.705882%,0%,0%);fill-opacity:1;"
      d="M 0 0.175781 L 215.515625 0.175781 L 215.515625 218.640625 L 0 218.640625 Z M 0 0.175781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 94.515625 90.300781 C 94.515625 89.699219 107.757812 52.976562 107.757812 52.976562 L 119.796875 89.699219 C 119.796875 89.699219 160.132812 90.90625 160.132812 90.300781 C 160.132812 89.699219 128.226562 114.382812 128.226562 114.382812 C 128.226562 114.382812 143.277344 156.523438 142.675781 155.320312 C 142.074219 154.117188 107.757812 130.035156 107.757812 130.035156 C 107.757812 130.035156 72.84375 154.117188 73.445312 154.117188 C 74.046875 154.117188 86.6875 114.382812 86.6875 114.382812 L 55.382812 90.90625 L 94.515625 90.304688 Z M 94.515625 90.300781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(6.666667%,52.54902%,0%);fill-opacity:1;"
      d="M 0 287.710938 L 539.738281 287.710938 L 539.738281 360 L 0 360 Z M 0 287.710938 "
    />
  </g>
</svg>
