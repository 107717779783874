import { Controller } from "stimulus";
import Uppy from "@uppy/core";
import DragDrop from "@uppy/drag-drop";
import XHR from "@uppy/xhr-upload";
import AwsS3 from "@uppy/aws-s3";
import Api from "../general/api";
import Helper from "../general/helper";
import Flash from "../general/flash";
import { Mutex } from "async-mutex";

export default class extends Controller {
  connect() {
    this.addedFile = false;
    this.uploaded = false;
    this.direct = this.data.get("direct") == "true";

    // For 2 concurrent requests
    this.mutexes = [new Mutex(), new Mutex()];
    this.pickMutexIndex = 0;

    this.setupUppy();
    this.initializeUppyEvents();
  }

  setupUppy() {
    let element = this.element;
    console.log("element", element);
    this.uppy = this.initializeUppy();
    this.setFileDrop(element);

    if (this.direct) {
      console.log("Setting uppy for direct upload");
      this.setAws();
    } else {
      console.log("Setting uppy for xhr upload");
      this.setXHRUpload();
    }
  }

  setXHRUpload() {
    let self = this,
      metaToken = document.querySelector(`meta[name="csrf-token"]`);
    this.uppy.use(XHR, {
      endpoint: self.data.get("url"),
      limit: 1,
      bundle: true,
      headers: {
        "X-CSRF-Token": metaToken ? metaToken.content : "",
      },
    });
  }

  setAws() {
    this.uppy.use(AwsS3, {
      limit: 2,
      timeout: 30 * 1000,
      companionUrl: "/minio",
    });
  }

  initializeUppy() {
    return new Uppy({
      debug: true,
      autoProceed: true,
      restrictions: {
        maxFileSize: 2000 * 1024 * 1024, // # 2000 MB
        maxNumberOfFiles: 500,
        minNumberOfFiles: 1,
      },
    });
  }

  initializeUppyEvents() {
    let self = this;

    this.uppy.on("file-added", (file) => {
      let attachmentsNode = document.querySelector("[data-attachments]");
      if (attachmentsNode) {
        attachmentsNode.prepend(self.fileInfoElement(file));
      }
    });

    this.uppy.on("upload-progress", (file, progress) => {
      const id = file.id.split("/").slice(-1)[0],
        percentage = (
          (progress.bytesUploaded / progress.bytesTotal) *
          100
        ).toFixed(2),
        idNode = document.getElementById(id);

      if (idNode) {
        idNode.style.width = percentage + "%";
      }

      this.removeNoFileMessage();
    });

    this.uppy.on("upload-success", (file, response) => {
      if (this.direct) {
        self
          .pickMutex()
          .acquire()
          .then((releaseFunc) => {
            self.sendFileMeta(file, releaseFunc);
          });
      } else {
        this.uploadResponse(response.body);
      }
    });

    this.uppy.on("complete", (result) => {
      console.log("Complete", result);
      self.addedFile = false;
      self.uploaded = false;
      self.uppy.cancelAll({ reason: "user" });
    });
  }

  setFileDrop(element) {
    this.uppy.use(DragDrop, {
      target: element,
      width: "100%",
      height: "100%",
      note: I18n.t("platform.views.controls.show.or_browse_html"),
      inputName: "filesInput[]",
      locale: {
        strings: {
          dropHereOr: I18n.t("platform.views.controls.show.place_files_here"),
          browse: "Browse",
        },
      },
    });
  }

  sendFileMeta(file, releaseFunc) {
    let params = {
      files: [
        {
          id: file.meta["key"].match(/^cache\/(.+)/)[1], // remove the Shrine storage prefix
          storage: "pbc_cache",
          metadata: {
            size: file.size,
            filename: file.name,
            mime_type: file.type,
          },
        },
      ],
      parent_id: this.data.get("id"),
      parent_type: this.data.get("record"),
      file_id: file.id.split("/").slice(-1)[0],
    };

    Api.post(this.data.get("url"), params, this.uploadResponse.bind(this), {
      funcs: [releaseFunc],
    });
  }

  fileInfoElement(file) {
    const id = file.id.split("/").slice(-1)[0];
    let el = document.createElement("div");
    el.classList.add("file");
    el.classList.add("file--upload");
    el.setAttribute("data-id", id);
    el.innerHTML = `
      <div class="file--progress-fill" id="${id}">
        <div class="file--icon"></div>
        <div class="file--name"><a href="#">${Helper.escapeHtml(file.name)}</a></div>
        <div class="file--meta">
          <div class="file--loading"></div>
        </div>
      </div>
    `;
    return el;
  }

  virusesScanResultPolling(fileItem, release) {
    if (!fileItem) {
      return;
    }

    let pollingUrl = fileItem.dataset.pollingUrl;
    let parentNode = fileItem.parentNode;
    let tempElement = document.createElement("div");

    let polling = setInterval(function () {
      Api.get(pollingUrl, {}, function (response) {
        if (response.finished) {
          clearInterval(polling);

          tempElement.innerHTML = response.template;
          parentNode.replaceWith(tempElement.children[0]);
          document
            .querySelector(".control-modal--content-download-files-link")
            .classList.remove("disabled");
          release();
        }
      });
    }, 2000);
  }

  removeNoFileMessage() {
    let noFileDescriptionElement = document.querySelector(
      ".control-modal--no-comment-files",
    );
    if (noFileDescriptionElement) {
      noFileDescriptionElement.remove();
    }
  }

  uploadResponse(response, releaseFunc) {
    let updateElement = document.querySelector(response["updateBlock"]),
      activityBlock = document.querySelector(
        ".control-modal--activities-block",
      ),
      attachmentList = document.querySelector("[data-attachments]"),
      sidebarBlock = document.querySelector(response["sidebarUpdateBlock"]),
      readyToReview = response["readyToReview"];

    if (activityBlock) {
      activityBlock.innerHTML = response["activity_list"];
    }

    if (updateElement) {
      updateElement.innerHTML = response["template"];
      if (response["check_hidden_controls"]) {
        const nodeList = attachmentList.querySelectorAll(
          ".file:not(.collapse-attachments):not(.hidden)",
        );
        var attachmentCollapseNode = document.querySelector(
          ".collapse-attachments.file",
        );

        if (nodeList[nodeList.length - 1]) {
          nodeList[nodeList.length - 1].classList.add("hidden");
        }
        if (!attachmentCollapseNode) {
          attachmentCollapseNode = document.createElement("div");
          attachmentCollapseNode.innerHTML = response["collapse_template"];
          attachmentList.appendChild(attachmentCollapseNode.children[0]);
        }
      }
      this.virusesScanResultPolling(
        updateElement.querySelector("[data-polling-url]"),
        releaseFunc,
      );
    } else if (attachmentList) {
      if (response["dropLoadingState"]) {
        attachmentList
          .querySelectorAll(".file--progress-fill")
          .forEach((node) => {
            node.parentNode.remove();
          });
        Flash.addMessage(response.message, "error");
      } else {
        attachmentList.innerHTML = "";
      }
    }

    // if(response.body.status === 'unprocessable_entity' || response.body.status === 'warning') {
    //   let fileId = file.id.split('/').slice(-1)[0], fileUploadNode = document.querySelector(`#${CSS.escape(fileId)}`) // CSS API isn't supported by IE
    //   if(fileUploadNode) { fileUploadNode.closest('.file--upload').remove()  }
    // }

    if (sidebarBlock) {
      document.querySelector(response.sidebarUpdateBlock).innerHTML =
        response.sidebar;
    }

    if (readyToReview && !document.querySelector(".ready-to-review-box")) {
      let readyReviewBox = document.createElement("div");
      readyReviewBox.classList.add("ready-to-review-box");
      readyReviewBox.innerHTML = readyToReview;
      document.querySelector("[data-attachments-review]").innerHTML =
        readyReviewBox.outerHTML;
    }

    if (response["reload"]) {
      document.location.reload();
    }
  }

  pickMutex() {
    var mutex = this.mutexes.find((mutex) => {
      return mutex.isLocked() === false;
    });
    if (!mutex) {
      mutex = this.mutexes[this.pickMutexIndex];
    }
    this.pickMutexIndex += 1;
    if (this.pickMutexIndex >= this.mutexes.length) {
      this.pickMutexIndex = 0;
    }
    return mutex;
  }
}
