<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M -22.207031 2.273438 L 503.578125 2.273438 L 503.578125 359.203125 L -22.207031 359.203125 Z M -22.207031 2.273438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,53.72549%,0%);fill-opacity:1;"
      d="M -22.207031 257.035156 L 503.578125 257.035156 L 503.578125 360 L -22.207031 360 Z M -22.207031 257.035156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(76.078431%,21.568627%,26.27451%);fill-opacity:1;"
      d="M -23.582031 131.316406 L 502.207031 131.316406 L 502.207031 234.277344 L -23.582031 234.277344 Z M -23.582031 131.316406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M -22.207031 0 L 503.578125 0 L 503.578125 112.09375 L -22.207031 112.09375 Z M -22.207031 0 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.0805;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 158.628323 436.279637 C 196.589773 365.633963 323.828969 131.639694 322.579393 130.959121 C 301.77172 118.747862 339.398463 99.987486 367.034176 55.995716 C 347.347775 103.273202 349.434121 147.398856 329.223344 134.696692 C 295.607517 198.146147 191.691881 392.087042 165.863591 439.994895 C 163.130143 438.527758 162.321266 437.925284 158.633901 436.285216 Z M 158.628323 436.279637 "
      transform="matrix(0.700237,0,0,0.700237,59.0475,1.4772)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.0805;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 349.010157 439.052134 C 311.048707 368.412039 183.798354 134.412191 185.059087 133.731618 C 205.872338 121.514781 168.240017 102.754405 140.604304 58.762634 C 160.290705 106.045699 158.204359 150.165774 178.415136 137.463611 C 212.030963 200.913065 315.946598 394.85396 341.774889 442.767391 C 344.513915 441.294677 345.317214 440.697781 349.004579 439.052134 Z M 349.010157 439.052134 "
      transform="matrix(0.700237,0,0,0.700237,59.0475,1.4772)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(76.078431%,21.568627%,26.27451%);fill-opacity:1;stroke-width:1.1357;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 252.530615 85.433273 C 372.121735 169.740606 370.158116 373.622325 252.530615 444.206635 C 142.740856 369.706243 127.059792 167.776986 252.530615 85.433273 Z M 252.530615 85.433273 "
      transform="matrix(0.700237,0,0,0.700237,59.0475,1.4772)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,98.431373%);fill-opacity:1;"
      d="M 246.871094 184.175781 C 246.871094 192.894531 242.261719 199.960938 236.574219 199.960938 C 230.886719 199.960938 226.277344 192.894531 226.277344 184.175781 C 226.277344 175.457031 230.886719 168.386719 236.574219 168.386719 C 242.261719 168.386719 246.871094 175.457031 246.871094 184.175781 Z M 246.871094 184.175781 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.3741;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 251.777522 93.276594 C 285.404506 125.810198 284.852238 204.500018 251.777522 231.73966 C 220.911878 202.988254 216.499313 125.057106 251.777522 93.276594 Z M 251.777522 93.276594 "
      transform="matrix(0.700237,0,0,0.700237,59.0475,1.4772)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.3741;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 251.777522 297.169469 C 285.404506 329.703074 284.852238 408.387315 251.777522 435.632535 C 220.911878 406.88113 216.499313 328.949981 251.777522 297.169469 Z M 251.777522 297.169469 "
      transform="matrix(0.700237,0,0,0.700237,59.0475,1.4772)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(76.078431%,21.568627%,26.27451%);fill-opacity:1;"
      d="M 230.386719 66.792969 L 239.996094 66.792969 L 239.996094 165.640625 L 230.386719 165.640625 Z M 230.386719 66.792969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(76.078431%,21.568627%,26.27451%);fill-opacity:1;"
      d="M 231.761719 208.195312 L 241.371094 208.195312 L 241.371094 307.039062 L 231.761719 307.039062 Z M 231.761719 208.195312 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;stroke-width:0.31486;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 179.279798 181.500009 C 199.680242 219.483773 199.345535 311.349926 179.279798 343.15833 C 160.552893 309.59271 157.87523 218.607954 179.279798 181.500009 Z M 179.279798 181.500009 "
      transform="matrix(0.700237,0,0,0.700237,59.0475,1.4772)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;stroke-width:0.31486;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 328.280583 183.45805 C 348.681028 221.447393 348.34632 313.307967 328.280583 345.12195 C 309.553678 311.550751 306.876015 220.571574 328.280583 183.45805 Z M 328.280583 183.45805 "
      transform="matrix(0.700237,0,0,0.700237,59.0475,1.4772)"
    />
  </g>
</svg>
