<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,19.215686%,61.176471%);fill-opacity:1;"
      d="M 0 0 L 160 0 L 160 360 L 0 360 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,87.058824%,0%);fill-opacity:1;"
      d="M 160 0 L 320 0 L 320 360 L 160 360 Z M 160 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(87.058824%,12.941176%,6.27451%);fill-opacity:1;"
      d="M 320 0 L 480 0 L 480 360 L 320 360 Z M 320 0 "
    />
  </g>
</svg>
