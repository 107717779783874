<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 112.636719 0 L 367.363281 0 L 367.363281 360 L 112.636719 360 Z M 112.636719 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(74.901961%,3.921569%,18.823529%);fill-opacity:1;"
      d="M -14.722656 0 L 112.636719 0 L 112.636719 360 L -14.722656 360 Z M -14.722656 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(74.901961%,3.921569%,18.823529%);fill-opacity:1;"
      d="M 367.363281 0 L 494.726562 0 L 494.726562 360 L 367.363281 360 Z M 367.363281 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(74.901961%,3.921569%,18.823529%);fill-opacity:1;"
      d="M 150.726562 173.960938 L 140.835938 177.339844 L 186.859375 217.734375 C 190.34375 228.113281 185.652344 231.171875 182.664062 236.617188 L 232.628906 230.277344 L 231.328125 280.558594 L 241.675781 280.261719 L 239.417969 230.394531 L 289.433594 236.324219 C 286.335938 229.789062 283.582031 226.316406 286.445312 215.867188 L 332.441406 177.597656 L 324.390625 174.683594 C 317.808594 169.605469 327.234375 150.226562 328.65625 137.996094 C 328.65625 137.996094 301.800781 147.230469 300.039062 142.398438 L 293.203125 129.261719 L 268.769531 156.101562 C 266.101562 156.742188 264.960938 155.679688 264.335938 153.421875 L 275.625 97.339844 L 257.75 107.390625 C 256.253906 108.03125 254.757812 107.476562 253.773438 105.734375 L 236.582031 71.238281 L 218.855469 107.0625 C 217.519531 108.347656 216.179688 108.496094 215.070312 107.625 L 198.054688 98.074219 L 208.269531 153.722656 C 207.457031 155.933594 205.511719 156.554688 203.21875 155.359375 L 179.867188 128.824219 C 176.8125 133.71875 174.738281 141.71875 170.703125 143.507812 C 166.664062 145.1875 153.136719 140.117188 144.074219 138.140625 C 147.167969 149.316406 156.851562 167.882812 150.726562 173.96875 Z M 150.726562 173.960938 "
    />
  </g>
</svg>
