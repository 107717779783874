import { SAMPLE_STATUS } from "apps/sample/constants";
import { t } from "stores/i18n.js";
import { get } from "svelte/store";

export function getSampleStatusCalculateLabel(label, status, timeDistance) {
  if (label) return label;

  const translatedStatus = get(t)(`sample.status.${status}`);
  return timeDistance &&
    (status === SAMPLE_STATUS.sent || status === SAMPLE_STATUS.reminder)
    ? `${translatedStatus} ${timeDistance}`
    : translatedStatus;
}
