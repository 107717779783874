<script>
  import { t } from "stores/i18n.js";
  import { step } from "stores/confirmation/confirmation_signer.js";
  import { Button } from "components";
</script>

{#if $step === "intro"}
  <div data-component="ConfirmationSignerNextStep">
    <Button
      click={() => {
        step.set("signature");
      }}
      style="primary">{$t("actions.continue")}</Button
    >
  </div>
{/if}
