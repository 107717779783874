<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 0 0 L 240 180 L 0 360 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 480 0 L 240 180 L 480 360 Z M 480 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,60%,0%);fill-opacity:1;"
      d="M 0 0 L 240 180 L 480 0 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,60%,0%);fill-opacity:1;"
      d="M 0 360 L 240 180 L 480 360 Z M 0 360 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,80%,0%);fill-opacity:1;"
      d="M 480 0 L 435.28125 0 L 0 326.460938 L 0 360 L 44.722656 360 L 480.003906 33.539062 Z M 480 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,80%,0%);fill-opacity:1;"
      d="M 0 0 L 0 33.542969 L 435.28125 360.003906 L 480.003906 360.003906 L 480.003906 326.460938 L 44.722656 0 Z M 0 0 "
    />
  </g>
</svg>
