<script>
  import { InputWrapper } from "components/index.js";
  import { createPopperActions } from "svelte-popperjs";
  import { iconPath } from "lib/helpers.js";
  import styles from "styleguide/Dropdown.json";

  // expects an object
  // where keys are values and values are labels
  export let items = {};
  export let selected;
  export let label;
  export let optional;
  export let footnotes;
  export let description;
  export let errors = [];
  export let initial;
  export let showSelected;
  export let click;
  export let lock;
  export let style = "";
  export let useSameWidth = true;
  export let placement = "auto";

  const [popperRef, popperContent] = createPopperActions({
    strategy: "fixed",
    placement: placement,
  });

  const sameWidth = {
    name: "sameWidth",
    enabled: true,
    phase: "beforeWrite",
    requires: ["computeStyles"],
    fn: ({ state }) => {
      state.styles.popper.width = `${state.rects.reference.width}px`;
    },
    effect: ({ state }) => {
      state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
    },
  };

  const extraOpts = {
    placement: "auto",
    modifiers: [
      { name: "offset", options: { offset: [0, 8] } },
      { name: "flip", options: { allowedAutoPlacements: ["top", "bottom"] } },
    ],
  };

  if (useSameWidth) {
    extraOpts.modifiers.push(sameWidth);
  }

  let show = false;
  $: selectedLabel = getSelectedLabel(items, selected, initial);

  $: calculatedStyles = function () {
    let s = style.split(" ");
    if (lock) s.push("lock");
    if (errors.length) s.push("invalid");
    return s.map((x) => styles[x]).join(" ");
  };

  function getSelectedLabel(items, selected, initial) {
    if (items[selected]) {
      return items[selected];
    } else {
      return initial;
    }
  }

  function onClickItem(key) {
    selected = key;
    show = false;
    if (click) click(key);
  }

  function toggle() {
    if (!lock) {
      show = !show;
    }
  }
</script>

<InputWrapper {label} {optional} {footnotes} {errors}>
  {#if show}
    <div class={styles.backdrop} on:click={toggle} />
  {/if}

  <div
    data-component="Dropdown"
    class="{styles.wrapper} {calculatedStyles()}"
    tabindex="0"
  >
    <div
      class={show
        ? [styles.dropdown, styles.active].join(" ")
        : styles.dropdown}
    >
      <div class={styles.title} use:popperRef on:click={toggle}>
        {#if description}
          <span class={styles.description}>{description}</span>
        {/if}
        {selectedLabel}
        {#if lock}
          <img src={iconPath("grey_lock.svg")} alt="" />
        {:else}
          <img
            class={styles.arrow}
            src={iconPath("stroke-1-5-down.svg")}
            alt=""
          />
        {/if}
      </div>
      {#if show}
        <div use:popperContent={extraOpts} class={styles.items}>
          {#each Object.entries(items) as [value, label] (value)}
            {#if showSelected || selected !== value}
              <div class={styles.item} on:click={(e) => onClickItem(value)}>
                {label}
              </div>
            {/if}
          {/each}
        </div>
      {/if}
    </div>
  </div>
</InputWrapper>

<style lang="scss">
  .dropdown {
    position: relative;
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  .title {
    border: 1px solid var(--primary-050);
    border-radius: var(--border-radius);
    height: 40px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    background: #fff;
    white-space: nowrap;

    .wrapper:not(.lock) {
      cursor: pointer;
    }

    .wrapper:not(.lock) &:hover {
      border-color: var(--blue);
    }

    .wrapper:not(.lock) &:active {
      border-color: var(--blue);
      outline: 3px solid var(--blue-010) !important;
    }

    img {
      margin-left: 10px;
      pointer-events: none;
    }

    .medium & {
      height: 30px;
    }

    .strong & {
      color: var(--blue-200);
      font-weight: 500;
    }

    .auto-content & {
      justify-content: flex-start;
    }

    .no-border & {
      border: 1px solid transparent;
    }

    .invalid & {
      border: 1px solid var(--red-100);
    }
  }

  .description {
    font-weight: 400;
    color: var(--primary-300);
    margin-right: 5px;
  }

  .items {
    display: flex;
    flex-direction: column;
    min-width: 0;
    padding: 2px;
    border: 1px solid var(--primary-050);
    border-radius: var(--border-radius);
    background: #fff;
    z-index: 1;

    .medium & {
      top: 34px;
    }

    .strong & {
      color: var(--blue-200);
      font-weight: 500;
    }

    .max-height & {
      max-height: 200px;
      overflow-y: auto;
    }

    .bottom & {
      top: initial;
      bottom: 48px;
    }
  }

  .item {
    flex: 1;
    padding: 6px 8px;
    border-radius: var(--border-radius);
    cursor: pointer;

    &:hover {
      background: var(--blue-010);
    }

    &:active {
      background: var(--blue-010-002);
    }
  }

  .dropdown.active {
    .title {
      border-color: var(--blue);
      justify-content: space-between;
      outline: 3px solid var(--blue-010) !important;
    }

    .arrow {
      transform: rotate(180deg);
    }
  }

  .user-settings {
    font-weight: 400;
  }
</style>
