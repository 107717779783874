function setLoading(element) {
  var cache = null;
  var width = $(element).outerWidth();

  setTimeout(function () {
    $(element).addClass("loading");

    if ($(element).is("input")) {
      cache = $(element).val();
      $(element).val(" ");
    } else {
      cache = $(element).html();
      $(element).html("&nbsp;");
    }

    $(element).attr("style", "width:" + width + "px;");
    $(element).data("cache", cache);
    $(element).attr("disabled", true);
  }, 1); // because IE11

  // setTimeout(function() {
  //   $(element).attr("disabled", true);
  // }, 10);
}

function doneLoading(element) {
  // $(element).attr("style", "width:auto;"); // disabled because control date select button
  $(element).removeClass("loading");
  $(element).attr("disabled", false);

  if ($(element).is("input")) {
    $(element).val($(element).data("cache"));
  } else {
    $(element).html($(element).data("cache"));
  }
}

window.formHelper = {
  setLoading: setLoading,
  doneLoading: doneLoading,
};

$(document).on(
  "click",
  ".loadable, input[type='submit'], button[type='submit']",
  function (event) {
    setLoading(event.target);
  },
);
