<script>
  import clsx from "clsx";
  import { Modal } from "components/modals";
  import { t } from "stores/i18n.js";
  import { iconPath } from "lib/helpers.js";
  import { Boundary, Button } from "components";
  import styles from "styleguide/ConfirmModal.json";

  export let size = "tiny";
  export let style = "";
  export let onHide;
  export let onConfirm;
  export let onReject = undefined;
  export let onDiscard = undefined;
  export let shown;
  export let title;
  export let description;
  export let confirmLabel;
  export let icon = undefined;

  export function confirm() {
    onConfirm();
    shown = false;
  }

  export function reject() {
    if (onReject) onReject();
    shown = false;
  }

  export function discard() {
    if (onDiscard) onDiscard();
    shown = false;
  }
</script>

<Boundary>
  <Modal
    style={`no-header-border ${size}`}
    {onHide}
    {shown}
    close={false}
    canHide={false}
    coverTop={false}
  >
    <div
      data-component="ConfirmModal"
      class={clsx(style.split(" ").map((x) => styles[x]))}
    >
      <div class={styles.container}>
        {#if icon}
          <div class={styles["icon-wrapper"]}>
            <img class={styles.icon} src={iconPath(icon)} alt={icon} />
          </div>
        {/if}
        <div class={styles.title}>{title}</div>
        <div class={styles.description}>{description}</div>

        <slot />

        <div class={styles.bottom}>
          {#if onDiscard}
            <Button click={discard.bind(this)} style="primary-text"
              >{$t("confirm_modal.discard")}</Button
            >
          {/if}
          <Button click={reject.bind(this)} style="error-tonal"
            >{$t("confirm_modal.cancel")}</Button
          >
          <Button click={confirm.bind(this)} style="primary"
            >{confirmLabel}</Button
          >
        </div>
      </div>
    </div>
  </Modal>
</Boundary>

<style lang="scss">
  .container {
    .text-center & {
      text-align: center;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .bottom {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    gap: 16px;
  }

  .icon-wrapper {
    display: inline-block;
    padding: 12px;
    margin: 5px 0 15px;
    border-radius: 10px;
    background: var(--red-010);
  }

  .icon {
    display: block;
    width: 28px;
    height: 28px;
  }
</style>
