<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <defs>
    <linearGradient
      id="linear0"
      gradientUnits="userSpaceOnUse"
      x1="123.79"
      y1="360.52"
      x2="123.79"
      y2="459.27"
      gradientTransform="matrix(0.7507,-0.024221,-0.045871,1.4224,26.394,-26.59)"
    >
      <stop
        offset="0"
        style="stop-color:rgb(98.039216%,100%,0%);stop-opacity:0.560784;"
      />
      <stop
        offset="1"
        style="stop-color:rgb(96.078431%,76.862745%,0.784314%);stop-opacity:0.560784;"
      />
    </linearGradient>
    <linearGradient
      id="linear1"
      gradientUnits="userSpaceOnUse"
      x1="123.79"
      y1="360.52"
      x2="123.79"
      y2="459.27"
      gradientTransform="matrix(0.72666,0.00062667,0.0011868,1.4679,-0.62419,-48.245)"
    >
      <stop
        offset="0"
        style="stop-color:rgb(98.039216%,100%,0%);stop-opacity:0.560784;"
      />
      <stop
        offset="1"
        style="stop-color:rgb(96.078431%,76.862745%,0.784314%);stop-opacity:0.560784;"
      />
    </linearGradient>
    <linearGradient
      id="linear2"
      gradientUnits="userSpaceOnUse"
      x1="123.79"
      y1="360.52"
      x2="123.79"
      y2="459.27"
      gradientTransform="matrix(0.7514,0.024216,0.045862,1.4211,-30.188,-29.545)"
    >
      <stop
        offset="0"
        style="stop-color:rgb(98.039216%,100%,0%);stop-opacity:0.560784;"
      />
      <stop
        offset="1"
        style="stop-color:rgb(96.078431%,76.862745%,0.784314%);stop-opacity:0.560784;"
      />
    </linearGradient>
    <linearGradient
      id="linear3"
      gradientUnits="userSpaceOnUse"
      x1="123.79"
      y1="360.52"
      x2="123.79"
      y2="459.27"
      gradientTransform="matrix(0.7751,-0.00034739,-0.0006579,1.3762,-23.236,0.16664)"
    >
      <stop
        offset="0"
        style="stop-color:rgb(98.039216%,100%,0%);stop-opacity:0.560784;"
      />
      <stop
        offset="1"
        style="stop-color:rgb(96.078431%,76.862745%,0.784314%);stop-opacity:0.560784;"
      />
    </linearGradient>
    <linearGradient
      id="linear4"
      gradientUnits="userSpaceOnUse"
      x1="123.79"
      y1="360.52"
      x2="123.79"
      y2="459.27"
      gradientTransform="matrix(0.75059,-0.02422,-0.045869,1.4226,-1.5653,1.5838)"
    >
      <stop
        offset="0"
        style="stop-color:rgb(98.039216%,100%,0%);stop-opacity:0.560784;"
      />
      <stop
        offset="1"
        style="stop-color:rgb(96.078431%,76.862745%,0.784314%);stop-opacity:0.560784;"
      />
    </linearGradient>
    <linearGradient
      id="linear5"
      gradientUnits="userSpaceOnUse"
      x1="123.79"
      y1="360.52"
      x2="123.79"
      y2="459.27"
      gradientTransform="matrix(0.75113,0.02422,0.04587,1.4216,-1.8465,-1.8275)"
    >
      <stop
        offset="0"
        style="stop-color:rgb(98.039216%,100%,0%);stop-opacity:0.560784;"
      />
      <stop
        offset="1"
        style="stop-color:rgb(96.078431%,76.862745%,0.784314%);stop-opacity:0.560784;"
      />
    </linearGradient>
    <linearGradient
      id="linear6"
      gradientUnits="userSpaceOnUse"
      x1="123.79"
      y1="360.52"
      x2="123.79"
      y2="459.27"
      gradientTransform="matrix(0.7751,-0.0002084,-0.00039467,1.3762,16.423,-0.070651)"
    >
      <stop
        offset="0"
        style="stop-color:rgb(98.039216%,100%,0%);stop-opacity:0.560784;"
      />
      <stop
        offset="1"
        style="stop-color:rgb(96.078431%,76.862745%,0.784314%);stop-opacity:0.560784;"
      />
    </linearGradient>
    <linearGradient
      id="linear7"
      gradientUnits="userSpaceOnUse"
      x1="123.79"
      y1="360.52"
      x2="123.79"
      y2="459.27"
      gradientTransform="matrix(0.72665,0,0,1.4679,-0.000000000000028422,-8.4634)"
    >
      <stop
        offset="0"
        style="stop-color:rgb(98.039216%,100%,0%);stop-opacity:0.560784;"
      />
      <stop
        offset="1"
        style="stop-color:rgb(96.078431%,76.862745%,0.784314%);stop-opacity:0.560784;"
      />
    </linearGradient>
  </defs>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 0 L 575.425781 0 L 575.425781 359.980469 L 0 359.980469 Z M 0 0 "
    />
    <path
      style="fill-rule:evenodd;fill:url(#linear0);stroke-width:3.2275;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(79.607843%,66.666667%,9.803922%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 101.660931 488.818029 C 111.302014 506.937468 123.51932 521.849903 132.080086 547.511007 C 133.922792 581.820901 130.001339 588.355807 136.709082 604.567373 C 139.401804 610.494841 143.255353 612.943744 144.00287 626.687774 C 142.851055 646.36708 134.355983 647.5848 125.997636 647.0438 C 124.49543 641.002749 125.998525 637.10292 115.350888 626.695515 C 109.582705 620.532579 102.742288 610.342198 99.123502 597.19687 C 97.990093 585.126143 97.361311 569.427424 91.335857 556.305707 C 82.659066 542.138948 81.362399 535.233265 73.121882 527.814373 C 64.629047 517.942934 65.121081 514.480551 60.466949 503.72396 C 71.860258 485.27295 82.654504 483.390586 101.660931 488.818029 Z M 101.660931 488.818029 "
      transform="matrix(-0.257332,0.239474,-0.255439,-0.241249,229.012209,191.030764)"
    />
    <path
      style="fill-rule:evenodd;fill:url(#linear1);stroke-width:3.2275;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(79.607843%,66.666667%,9.803922%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 101.65573 488.818587 C 111.295896 506.932298 123.512853 521.852262 132.07621 547.507426 C 133.920821 581.822847 130.008136 588.355182 136.714651 604.564634 C 139.409482 610.494323 143.255143 612.938418 143.993255 626.685269 C 142.847218 646.363591 134.351505 647.588271 125.994483 647.041328 C 124.494191 641.004245 125.995156 637.100394 115.344898 626.688166 C 109.587309 620.533778 102.732327 610.336771 99.114667 597.201796 C 97.988763 585.12636 97.354646 569.424455 91.33461 556.309069 C 82.65046 542.136374 81.354464 535.234717 73.113215 527.813822 C 64.635477 517.936613 65.118722 514.482641 60.465078 503.717011 C 71.86251 485.273652 82.655323 483.38717 101.65573 488.818587 Z M 101.65573 488.818587 "
      transform="matrix(-0.362558,-0.00439762,0.00469079,-0.339899,124.086638,246.013759)"
    />
    <path
      style="fill-rule:evenodd;fill:url(#linear2);stroke-width:3.2275;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(79.607843%,66.666667%,9.803922%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 101.656779 488.822236 C 111.302037 506.929641 123.516432 521.853371 132.078388 547.507955 C 133.924805 581.815097 130.005231 588.350131 136.71329 604.562757 C 139.415122 610.493198 143.257246 612.941986 143.998539 626.69163 C 142.854813 646.35966 134.356333 647.584283 125.994248 647.043038 C 124.49139 641.00471 125.998883 637.098572 115.347626 626.68937 C 109.591419 620.528231 102.741556 610.342189 99.11531 597.190925 C 97.982265 585.123049 97.361702 569.426392 91.32938 556.302056 C 82.655943 542.130462 81.360264 535.2397 73.118476 527.806665 C 64.638175 517.93936 65.1184 514.474973 60.47014 503.715179 C 71.862002 485.273535 82.660722 483.390805 101.656779 488.822236 Z M 101.656779 488.822236 "
      transform="matrix(-0.253612,-0.242938,0.259134,-0.237761,10.110883,215.376248)"
    />
    <path
      style="fill-rule:evenodd;fill:url(#linear3);stroke-width:3.2275;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(79.607843%,66.666667%,9.803922%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 101.657013 488.820529 C 111.302165 506.93717 123.509976 521.846453 132.082991 547.50152 C 133.926493 581.823714 130.008681 588.359055 136.70974 604.568339 C 139.406861 610.496016 143.262702 612.946288 143.993301 626.688143 C 142.847278 646.358154 134.35211 647.582623 125.993735 647.050018 C 124.50242 641.005958 125.991373 637.106016 115.355681 626.688817 C 109.588546 620.524591 102.735324 610.338833 99.113106 597.199488 C 97.992212 585.119564 97.362542 569.426967 91.327165 556.304929 C 82.652648 542.135232 81.362073 535.238619 73.115129 527.81012 C 64.632315 517.937708 65.11313 514.475939 60.473775 503.724852 C 71.856266 485.274261 82.656536 483.386846 101.657013 488.820529 Z M 101.657013 488.820529 "
      transform="matrix(0.00260019,-0.339916,0.362577,0.00243768,-47.569536,118.480404)"
    />
    <path
      style="fill-rule:evenodd;fill:url(#linear4);stroke-width:3.2275;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(79.607843%,66.666667%,9.803922%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 101.66345 488.821735 C 111.304764 506.92924 123.514819 521.839841 132.076252 547.50769 C 133.92336 581.823905 130.008638 588.359823 136.712434 604.565918 C 139.410098 610.495602 143.260932 612.94419 143.991448 626.688297 C 142.859514 646.363048 134.351845 647.584297 125.992773 647.045935 C 124.501109 640.999448 125.995618 637.096337 115.34939 626.687888 C 109.591505 620.5289 102.736798 610.345448 99.112943 597.199381 C 97.992554 585.121843 97.352655 569.431572 91.330277 556.306234 C 82.654277 542.141693 81.358172 535.238302 73.125622 527.813054 C 64.635361 517.93583 65.111701 514.480558 60.465837 503.717319 C 71.864894 485.271052 82.654106 483.396455 101.66345 488.821735 Z M 101.66345 488.821735 "
      transform="matrix(0.257894,-0.238944,0.254874,0.241776,-15.462413,11.352794)"
    />
    <path
      style="fill-rule:evenodd;fill:url(#linear5);stroke-width:3.2275;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(79.607843%,66.666667%,9.803922%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 101.653055 488.819528 C 111.304972 506.936608 123.518775 521.844488 132.076523 547.503416 C 133.926825 581.817926 130.010861 588.355542 136.712933 604.568607 C 139.404167 610.491167 143.255226 612.938523 143.99804 626.694277 C 142.854123 646.358608 134.350649 647.58272 126.001651 647.04984 C 124.501223 641.002301 125.999135 637.103827 115.353416 626.688562 C 109.585819 620.525389 102.7396 610.338884 99.10969 597.196785 C 97.989599 585.129087 97.355121 569.434254 91.334762 556.306001 C 82.656469 542.138646 81.359836 535.231765 73.115011 527.814239 C 64.629008 517.944969 65.12051 514.475242 60.470918 503.71927 C 71.86117 485.268228 82.66038 483.390847 101.653055 488.819528 Z M 101.653055 488.819528 "
      transform="matrix(0.255062,0.241599,-0.257705,0.239121,202.539696,-13.246954)"
    />
    <path
      style="fill-rule:evenodd;fill:url(#linear6);stroke-width:3.2275;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(79.607843%,66.666667%,9.803922%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 101.66103 488.822179 C 111.299951 506.933841 123.510715 521.845521 132.078906 547.503147 C 133.927288 581.81915 130.002292 588.353924 136.714153 604.560517 C 139.405736 610.495838 143.25447 612.946383 144.00309 626.689981 C 142.846648 646.367129 134.348124 647.588759 126.002878 647.042898 C 124.49449 641.005527 125.994576 637.099114 115.354567 626.694719 C 109.582227 620.524863 102.735394 610.341164 99.116432 597.191675 C 97.984288 585.119599 97.353685 569.425512 91.333117 556.307902 C 82.653451 542.135167 81.359692 535.235003 73.122774 527.815266 C 64.633971 517.940285 65.113223 514.479971 60.470279 503.715802 C 71.859519 485.265892 82.653539 483.387983 101.66103 488.822179 Z M 101.66103 488.822179 "
      transform="matrix(-0.00155982,0.339922,-0.362584,-0.00146233,260.854643,83.73999)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,16.078431%,57.647059%);fill-opacity:1;"
      d="M 212.394531 41.140625 L 575.417969 41.140625 L 575.417969 82.109375 L 212.394531 82.109375 Z M 212.394531 41.140625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,16.078431%,57.647059%);fill-opacity:1;"
      d="M 212.394531 120.902344 L 575.417969 120.902344 L 575.417969 161.867188 L 212.394531 161.867188 Z M 212.394531 120.902344 "
    />
    <path
      style="fill-rule:evenodd;fill:url(#linear7);stroke-width:3.2275;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(79.607843%,66.666667%,9.803922%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 101.664253 488.818814 C 111.306323 506.929385 123.512428 521.845324 132.077171 547.505797 C 133.919399 581.819354 130.008705 588.358005 136.709674 604.560991 C 139.413762 610.490594 143.259817 612.938279 144.003172 626.693579 C 142.850433 646.366992 134.350329 647.585088 126.001051 647.044989 C 124.492795 641.000471 126.001051 637.104859 115.346296 626.693579 C 109.582601 620.534147 102.74158 610.341205 99.11099 597.194953 C 97.990571 585.117408 97.354949 569.431539 91.332696 556.30827 C 82.66022 542.139276 81.356655 535.232898 73.11511 527.809402 C 64.636553 517.938222 65.12135 514.479286 60.467301 503.723262 C 71.854639 485.267947 82.66022 483.394835 101.664253 488.818814 Z M 101.664253 488.818814 "
      transform="matrix(0.362588,0,0,0.339926,87.255,-43.154305)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,16.078431%,57.647059%);fill-opacity:1;"
      d="M 0 199.734375 L 575.425781 199.734375 L 575.425781 240.703125 L 0 240.703125 Z M 0 199.734375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,16.078431%,57.647059%);fill-opacity:1;"
      d="M 0 279.035156 L 575.425781 279.035156 L 575.425781 320.003906 L 0 320.003906 Z M 0 279.035156 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(98.039216%,100%,0%);fill-opacity:1;stroke-width:3.2275;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(79.607843%,66.666667%,9.803922%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 82.66022 496.87434 L 53.540094 656.226681 L 53.540094 496.87434 Z M 82.66022 496.87434 "
      transform="matrix(0.362588,0,0,0.339926,87.255,-43.154305)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(96.078431%,76.862745%,0.784314%);fill-opacity:1;stroke-width:3.2275;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(79.607843%,66.666667%,9.803922%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 82.660032 496.874829 L 53.539906 656.227169 L 53.539906 496.874829 Z M 82.660032 496.874829 "
      transform="matrix(-0.362588,0,0,0.339926,126.08087,-43.154471)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(98.039216%,100%,0%);fill-opacity:1;stroke-width:3.2275;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(79.607843%,66.666667%,9.803922%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 82.660579 496.875318 L 53.541199 656.234966 L 53.540023 496.872772 Z M 82.660579 496.875318 "
      transform="matrix(0.255918,-0.240803,0.256857,0.239923,-16.202091,12.240001)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(96.078431%,76.862745%,0.784314%);fill-opacity:1;stroke-width:3.2275;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(79.607843%,66.666667%,9.803922%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 82.662224 496.869277 L 53.540492 656.234017 L 53.541668 496.871823 Z M 82.662224 496.869277 "
      transform="matrix(-0.255918,0.240803,0.256857,0.239923,11.202272,-13.545409)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(98.039216%,100%,0%);fill-opacity:1;stroke-width:3.2275;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(79.607843%,66.666667%,9.803922%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 82.662604 496.873273 L 53.546196 656.229083 L 53.543201 496.870991 Z M 82.662604 496.873273 "
      transform="matrix(-0.000699141,-0.339926,0.362588,-0.000655445,-47.39912,119.795917)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(96.078431%,76.862745%,0.784314%);fill-opacity:1;stroke-width:3.2275;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(79.607843%,66.666667%,9.803922%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 82.656211 496.873011 L 53.545303 656.233362 L 53.536807 496.875292 Z M 82.656211 496.873011 "
      transform="matrix(0.000699141,0.339926,0.362588,-0.000655445,-47.471637,83.396665)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(98.039216%,100%,0%);fill-opacity:1;stroke-width:3.2275;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(79.607843%,66.666667%,9.803922%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 82.664428 496.868499 L 53.546852 656.230184 L 53.543364 496.87054 Z M 82.664428 496.868499 "
      transform="matrix(-0.255784,-0.240929,0.256991,-0.239797,11.137006,216.134283)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(96.078431%,76.862745%,0.784314%);fill-opacity:1;stroke-width:3.2275;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(79.607843%,66.666667%,9.803922%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 82.662226 496.870171 L 53.537674 656.227774 L 53.541162 496.86813 Z M 82.662226 496.870171 "
      transform="matrix(0.255784,0.240929,0.256991,-0.239797,-16.252854,190.333916)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(98.039216%,100%,0%);fill-opacity:1;stroke-width:3.2275;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(79.607843%,66.666667%,9.803922%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 82.658398 496.873778 L 53.539066 656.22663 L 53.538271 496.874288 Z M 82.658398 496.873778 "
      transform="matrix(-0.362588,0.000664759,-0.000709076,-0.339926,126.381817,245.755415)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(96.078431%,76.862745%,0.784314%);fill-opacity:1;stroke-width:3.2275;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(79.607843%,66.666667%,9.803922%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 82.660687 496.875394 L 53.539765 656.227226 L 53.54056 496.874884 Z M 82.660687 496.875394 "
      transform="matrix(0.362588,-0.000664759,-0.000709076,-0.339926,87.556372,245.8268)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(98.039216%,100%,0%);fill-opacity:1;stroke-width:3.2275;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(79.607843%,66.666667%,9.803922%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 82.656923 496.869423 L 53.544276 656.226929 L 53.543974 496.872862 Z M 82.656923 496.869423 "
      transform="matrix(-0.255903,0.240817,-0.256871,-0.239909,229.541587,190.388304)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(96.078431%,76.862745%,0.784314%);fill-opacity:1;stroke-width:3.2275;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(79.607843%,66.666667%,9.803922%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 82.654034 496.87157 L 53.540782 656.222198 L 53.541085 496.868131 Z M 82.654034 496.87157 "
      transform="matrix(0.255903,-0.240817,-0.256871,-0.239909,202.140849,216.175074)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(98.039216%,100%,0%);fill-opacity:1;stroke-width:3.2275;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(79.607843%,66.666667%,9.803922%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 82.660039 496.868148 L 53.546549 656.226312 L 53.53887 496.869353 Z M 82.660039 496.868148 "
      transform="matrix(0.00400913,0.339905,-0.362566,0.00375856,260.546444,81.520274)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(96.078431%,76.862745%,0.784314%);fill-opacity:1;stroke-width:3.2275;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(79.607843%,66.666667%,9.803922%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 82.657179 496.865857 L 53.539942 656.232257 L 53.53613 496.875425 Z M 82.657179 496.865857 "
      transform="matrix(-0.00400913,-0.339905,-0.362566,0.00375856,260.977923,117.919527)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(98.039216%,100%,0%);fill-opacity:1;stroke-width:3.2275;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(79.607843%,66.666667%,9.803922%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 82.658359 496.869137 L 53.536258 656.228235 L 53.545608 496.869223 Z M 82.658359 496.869137 "
      transform="matrix(0.258289,0.238567,-0.254471,0.242146,200.991447,-14.370409)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(96.078431%,76.862745%,0.784314%);fill-opacity:1;stroke-width:3.2275;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(79.607843%,66.666667%,9.803922%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 82.665479 496.868274 L 53.546338 656.227825 L 53.536988 496.868813 Z M 82.665479 496.868274 "
      transform="matrix(-0.258289,-0.238567,-0.254471,0.242146,228.649621,11.176033)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(98.039216%,100%,0%);fill-opacity:1;stroke-width:3.2275;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(79.607843%,66.666667%,9.803922%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 134.805527 422.28772 C 134.805527 377.402002 97.993292 341.01998 52.583994 341.01998 C 7.174696 341.01998 -29.637539 377.402002 -29.637539 422.28772 C -29.637539 467.173437 7.174696 503.555459 52.583994 503.555459 C 97.993292 503.555459 134.805527 467.173437 134.805527 422.28772 Z M 134.805527 422.28772 "
      transform="matrix(0.362588,0,0,0.339926,87.60167,-42.179295)"
    />
  </g>
</svg>
