<script>
  import styles from "styleguide/CustomPasswordRule.json";
  import { onMount } from "svelte";

  import { accountPasswordRules } from "stores/account.js";
  import { t } from "stores/i18n.js";

  import AccountApi from "apis/admin/accounts.js";

  import { Button, IconButton, TextInput, Tooltip } from "components";

  onMount(async () => {
    editAccountPasswordRules();
  });

  function editAccountPasswordRules() {
    AccountApi.editCustomPasswordRules();
  }

  function updateItemValue(key, value, newValue) {
    $accountPasswordRules[key] = Math.max(0, newValue);
  }

  function adjustItemValue(key, value, delta) {
    $accountPasswordRules[key] = Math.max(0, value + delta);
  }

  function updateAccountStore() {
    AccountApi.updateCustomPasswordRules($accountPasswordRules);
  }
</script>

<div data-component="CustomPasswordRule">
  <div class={styles["section"]}>
    <div class={styles["section-header"]}>
      {$t("custom_password_rule.header")}
    </div>
    <div class={styles["section-description"]}>
      {$t("custom_password_rule.description")}
    </div>

    <div class={styles["items-list"]}>
      {#each Object.entries($accountPasswordRules) as [key, value]}
        <div class={styles["item"]}>
          <div class={styles["item-title"]}>
            {$t(`custom_password_rule.${key}`)}
            {#if key === "non_reuse_count"}
              <Tooltip nowrap={false} placement="top">
                <div slot="content" class={styles.wrapper}>
                  <IconButton icon="info" />
                </div>
                <div slot="tooltip" class={styles["tooltip-content"]}>
                  {@html $t(`custom_password_rule.${key}_popover_subtitle`)}
                </div>
              </Tooltip>
            {/if}
          </div>
          <div class={styles["item-adjuster"]}>
            <IconButton
              style="primary-text"
              click={() => adjustItemValue(key, value, -1)}
              icon="minus"
              disabled={value === 0}
            />
            <div class={styles["item-adjuster-input"]}>
              <TextInput
                bind:value
                onKeyup={(newValue) => updateItemValue(key, value, newValue)}
                style="text-center"
              />
            </div>
            <IconButton
              style="primary-text"
              click={() => adjustItemValue(key, value, +1)}
              icon="plus"
            />
          </div>
        </div>
      {/each}
    </div>

    <div class={styles["buttons"]}>
      <Button click={updateAccountStore} style="primary">
        {$t("custom_password_rule.save")}
      </Button>
    </div>
  </div>
</div>

<style scoped lang="scss">
  .section {
    &-header {
      display: flex;
      gap: 8px;
      align-items: center;
      padding-top: 24px;

      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: var(--primary-500, #052861);
    }

    &-description {
      padding-top: 8px;
      padding-bottom: 24px;
      font-size: 14px;
      line-height: 20px;
      color: var(--primary-300);
    }
  }

  .items-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      display: flex;
      align-items: center;

      color: var(--primary-500, #052861);

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
    }

    &-adjuster {
      display: flex;
      align-items: center;
      max-width: 172px;
      gap: 4px;
    }
  }

  .buttons {
    display: flex;
    gap: 8px;
    padding: 24px 0px;
  }

  .tooltip-content {
    width: 284px;
    font-weight: 400;
    line-height: 22px;
    margin: 0.9rem;
  }
</style>
