<script>
  import styles from "styleguide/InvoiceAccess.json";
  import { t } from "stores/i18n.js";

  import Invoice from "apis/invoice_access/invoice";

  import {
    App,
    Box,
    FlexFormBox,
    LegacyButton as Button,
    TextInput,
  } from "components";

  export let appConfig;

  let customerNumber = "";

  function redirectToNoteTable() {
    Invoice.redirectToNoteTable(customerNumber);
  }
</script>

<App {...appConfig}>
  <div class={styles["invoice-finder"]}>
    <Box title={$t("invoice_access.enter_customer_number")}>
      <div class={styles["pre-flexbox-styles"]}>
        <FlexFormBox>
          <TextInput
            bind:value={customerNumber}
            enter={redirectToNoteTable}
            placeholder={"KD123"}
          />
          <div class={styles["button-margin"]}>
            <Button style="primary" click={redirectToNoteTable}
              >{$t("invoice_access.find")}</Button
            >
          </div>
        </FlexFormBox>
      </div>
    </Box>
  </div>
</App>

<style lang="scss">
  .invoice-finder {
    position: fixed;
    top: 40%;
    left: 40%;
    margin: 0 auto;

    .box {
      .box-header {
        border-bottom: 1px solid var(--primary-050);
      }
    }
  }

  .pre-flexbox-styles {
    margin: 1rem;
  }

  .button-margin {
    margin-left: 0.5rem;
  }
</style>
