import Api from "apis/api";
import { buildFormData } from "lib/helpers.js";

class ConfirmationSigners {
  constructor() {
    this.endpoint = "/confirmation_signers";
  }

  create(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params);

    Api.post(`${this.endpoint}`, {
      body: formData,
      success: (response) => {
        success(response.data.confirmation_signer);
      },
      error,
    });
  }

  delete(options) {
    const { params, success, error } = options;
    const { id, confirmation_id, confirmation_item_id } = params;

    Api.delete(
      this.endpoint +
        `/${id}?confirmation_id=${confirmation_id}&confirmation_item_id=${confirmation_item_id}`,
      {
        params,
        success: () => {
          success(id);
        },
        error,
      },
    );
  }

  deleteFromConfirmation(options) {
    const { params } = options;
    const { id, confirmation_id, confirmation_type } = params;

    Api.delete(
      this.endpoint +
        `/${id}/remove_from_confirmation?confirmation_id=${confirmation_id}&confirmation_type=${confirmation_type}`,
      options,
    );
  }

  batchUpdate(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params, null, true);

    Api.post(`${this.endpoint}/batch_update`, {
      body: formData,
      success: (response) => {
        success(response);
      },
      error,
    });
  }

  getUnsignedSigners(options) {
    Api.get(this.endpoint + "/unsigned", options);
  }

  getAssignments(options) {
    const { params, success, error } = options;

    Api.get(this.endpoint + "/assignments", {
      params,
      success: (response) => {
        success(response.data);
      },
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  remind(options) {
    const { params, success, error } = options;
    const formData = new FormData();
    buildFormData(formData, params, null, true);

    Api.post(this.endpoint + "/remind", {
      body: formData,
      success,
      error,
    });
  }

  removeSignature(options) {
    const { params, success, error } = options;
    const { id, confirmation_id, confirmation_item_id } = params;
    const formData = new FormData();
    buildFormData(formData, params, null, true);

    Api.delete(
      this.endpoint +
        `/${id}/remove_signature_from_confirmation_item?confirmation_id=${confirmation_id}&confirmation_item_id=${confirmation_item_id}`,
      {
        params,
        success: () => {
          success(id);
        },
        error,
      },
    );
  }
}

export default new ConfirmationSigners();
