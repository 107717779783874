<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(100%,100%,100%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(21.176471%,38.431373%,63.529412%);fill-opacity:1;"
      d="M -19.78125 0.167969 L -19.179688 259.324219 L 384.402344 0 L -19.785156 0.167969 Z M -19.78125 0.167969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(21.960784%,63.137255%,0%);fill-opacity:1;"
      d="M 499.777344 359.667969 L 498.832031 90.195312 L 91.734375 359.875 L 499.78125 359.675781 Z M 499.777344 359.667969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(78.039216%,0%,0%);fill-opacity:1;"
      d="M -19.519531 278.867188 L -19.179688 360 L 68.886719 359.886719 L 499.03125 71.507812 L 498.546875 0.96875 L 411.527344 0.167969 L -19.523438 278.867188 Z M -19.519531 278.867188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,90.588235%,0%);fill-opacity:1;"
      d="M 164.667969 128.945312 L 148.367188 119.101562 L 138.9375 135.679688 L 129.761719 118.996094 L 113.3125 128.660156 L 113.714844 109.605469 L 94.65625 109.753906 L 104.53125 93.433594 L 87.96875 84.035156 L 104.671875 74.828125 L 95.042969 58.394531 L 114.097656 58.761719 L 113.984375 39.703125 L 130.285156 49.546875 L 139.714844 32.96875 L 148.890625 49.652344 L 165.339844 39.992188 L 164.9375 59.046875 L 183.996094 58.898438 L 174.121094 75.21875 L 190.679688 84.617188 L 173.980469 93.824219 L 183.609375 110.253906 L 164.554688 109.886719 Z M 164.667969 128.945312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(21.176471%,38.431373%,63.529412%);fill-opacity:1;"
      d="M 174.289062 84.328125 C 174.289062 103.484375 158.636719 119.015625 139.328125 119.015625 C 120.019531 119.015625 104.367188 103.484375 104.367188 84.328125 C 104.367188 65.167969 120.019531 49.636719 139.328125 49.636719 C 158.636719 49.636719 174.289062 65.167969 174.289062 84.328125 Z M 174.289062 84.328125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,90.588235%,0%);fill-opacity:1;"
      d="M 166.699219 84.328125 C 166.699219 99.445312 154.445312 111.699219 139.328125 111.699219 C 124.210938 111.699219 111.957031 99.445312 111.957031 84.328125 C 111.957031 69.210938 124.210938 56.957031 139.328125 56.957031 C 154.445312 56.957031 166.699219 69.210938 166.699219 84.328125 Z M 166.699219 84.328125 "
    />
  </g>
</svg>
