import { Controller } from "stimulus";
import Helper from "../../general/helper";

export default class extends Controller {
  connect() {
    const urlParams = new URLSearchParams(window.location.search);
    const modalUrl = urlParams.get("modal");
    if (modalUrl) {
      console.log(modalUrl);
      Helper.loadModalByUrl(modalUrl);
    }
  }
  showSidebarModal(e) {
    Helper.loadModalByClosestTag(e, "section");
  }

  showSidebarModalByLink(e) {
    Helper.loadModalByClosestTag(e, "a");
  }

  showSidebarReleaseModal(e) {
    Helper.loadModalByClosestTag(e, "a");

    // Clone the DocumentThumbnail into the release modal
    const observer = new MutationObserver(function (mutations, observer) {
      const sourceCanvas = document.querySelector(
        "#sba-letterhead-preview-source canvas",
      );
      if (sourceCanvas) {
        const clonedCanvas = sourceCanvas.cloneNode(true);
        const context = clonedCanvas.getContext("2d");
        context.drawImage(sourceCanvas, 0, 0);
        const target = document.getElementById("sba-letterhead-preview-target");
        target.appendChild(clonedCanvas);
      }

      observer.disconnect();
    });

    const config = { attributes: true, childList: false, subtree: true };
    observer.observe(document, config);
  }
}
