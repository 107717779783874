<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(92.941176%,16.078431%,22.352941%);fill-opacity:1;"
      d="M 0 0 L 540 0 L 540 180 L 239.996094 180 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,13.72549%,58.431373%);fill-opacity:1;"
      d="M 0 360 L 540 360 L 540 180 L 239.996094 180 Z M 0 360 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 0 L 239.996094 180 L 0 360 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 121.160156 135.820312 L 121.234375 133.035156 C 121.234375 133.035156 119.738281 130.652344 121.476562 128.089844 C 121.476562 128.089844 117.785156 126.136719 118.644531 123.082031 C 118.644531 123.082031 115.308594 122.226562 115.550781 118.558594 C 115.550781 118.558594 111.949219 118.308594 111.578125 115.242188 C 111.578125 115.242188 107.976562 115.835938 106.390625 112.847656 C 106.390625 112.847656 102.878906 113.535156 101.886719 111.074219 C 101.886719 111.074219 98.472656 112.253906 96.445312 109.359375 C 96.445312 109.359375 92.839844 110.816406 91.328125 108.085938 C 89.835938 110.808594 86.230469 109.359375 86.230469 109.359375 C 84.226562 112.246094 80.789062 111.046875 80.789062 111.046875 C 79.832031 113.519531 76.328125 112.820312 76.328125 112.820312 C 74.746094 115.808594 71.152344 115.214844 71.152344 115.214844 C 70.796875 118.269531 67.199219 118.519531 67.199219 118.519531 C 67.484375 122.199219 64.152344 123.03125 64.152344 123.03125 C 65.03125 126.089844 61.347656 128.035156 61.347656 128.035156 C 63.128906 130.613281 61.644531 132.976562 61.644531 132.976562 L 61.5625 135.546875 L 121.171875 135.820312 "
    />
    <path
      style="fill:none;stroke-width:0.27124;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 377.169109 843.108504 L 377.261185 839.652338 C 377.261185 839.652338 375.405133 836.695451 377.561642 833.515585 C 377.561642 833.515585 372.982089 831.091906 374.048228 827.301273 C 374.048228 827.301273 369.909669 826.239702 370.210126 821.688034 C 370.210126 821.688034 365.742033 821.377804 365.281655 817.572629 C 365.281655 817.572629 360.813561 818.309427 358.846049 814.601199 C 358.846049 814.601199 354.489416 815.454334 353.25851 812.400499 C 353.25851 812.400499 349.023029 813.864401 346.507909 810.272509 C 346.507909 810.272509 342.034969 812.080573 340.159533 808.692271 C 338.308328 812.070879 333.835388 810.272509 333.835388 810.272509 C 331.349345 813.854706 327.084787 812.366567 327.084787 812.366567 C 325.897496 815.434944 321.550554 814.567267 321.550554 814.567267 C 319.587889 818.275495 315.129487 817.538697 315.129487 817.538697 C 314.688493 821.32933 310.225246 821.639561 310.225246 821.639561 C 310.579011 826.205771 306.445297 827.238258 306.445297 827.238258 C 307.535667 831.033738 302.965806 833.447722 302.965806 833.447722 C 305.175622 836.646977 303.334109 839.579628 303.334109 839.579628 L 303.232341 842.769189 L 377.183647 843.108504 Z M 377.169109 843.108504 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 119.773438 132.339844 C 110.410156 131.402344 99.632812 129.179688 91.308594 133.867188 C 85.730469 130.578125 77.964844 130.78125 71.347656 131.421875 C 67.757812 132.066406 63.53125 132.066406 59.777344 132.679688 L 59.191406 132.761719 C 52.378906 133.214844 46.203125 131.203125 40.285156 128.558594 C 42.164062 139.222656 44.25 150.152344 45.273438 161.316406 C 47.507812 177.855469 37.449219 191.738281 41.574219 208.074219 C 44.039062 215.910156 52.660156 220.46875 60.550781 221.371094 C 68.1875 221.820312 76.09375 223.074219 83.164062 224.609375 C 84.507812 224.886719 86.105469 225.511719 87.03125 225.914062 C 88.980469 226.726562 90.40625 227.925781 91.660156 229.207031 C 95.925781 224.761719 102.183594 223.523438 108.355469 222.871094 C 119.480469 221.570312 133.195312 222.277344 140.003906 212.203125 L 140.003906 211.632812 C 143.703125 206.0625 143.214844 197.558594 141.609375 191.246094 C 141.5 189.042969 140.914062 187.101562 140.59375 185.003906 C 133.796875 167.058594 139.617188 146.875 142.542969 128.789062 C 138.96875 130.742188 134.984375 131.664062 131.011719 132.640625 C 127.839844 133.0625 124.308594 132.871094 121.097656 132.699219 L 119.769531 132.339844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(50.196078%,81.176471%,88.235294%);fill-opacity:1;"
      d="M 133.75 183.84375 C 133.707031 185.023438 134.34375 185.953125 134.574219 187.046875 C 135.875 194.890625 137.132812 205.277344 129.054688 210.144531 C 120.121094 215.824219 108.402344 213.394531 98.0625 216.398438 C 96 216.972656 93.152344 218.871094 91.699219 220.214844 C 90.769531 219.601562 89.832031 218.660156 88.628906 218.105469 C 80.121094 213.585938 69.058594 215.097656 59.457031 212.65625 C 54.632812 211.132812 49.605469 207.375 48.382812 202.4375 C 45.242188 189.488281 52.765625 178.449219 51.878906 165.605469 C 51.304688 155.792969 49.652344 146.375 47.386719 137.175781 C 54.894531 140.671875 63.585938 141.257812 72.023438 139.582031 C 78.277344 138.707031 85.554688 138.6875 91.410156 141.332031 C 94.640625 139.941406 98.417969 139.320312 101.898438 138.9375 C 108.511719 139.582031 115.621094 140.777344 122.433594 140.726562 C 126.980469 140.492188 131.113281 139.175781 135.066406 137.425781 C 132.722656 152.628906 128.21875 168.550781 133.746094 183.84375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 120.25 186.871094 L 120.175781 172.578125 L 121.9375 172.578125 L 100.082031 158.464844 L 100.042969 151.128906 L 102.152344 151.128906 L 91.226562 143.308594 L 80.351562 151.125 L 82.457031 151.125 L 82.496094 158.453125 L 60.757812 172.550781 L 62.496094 172.558594 L 62.570312 186.84375 L 120.246094 186.882812 "
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 376.039971 906.458609 L 375.947895 888.722131 L 378.133481 888.722131 L 351.019617 871.208631 L 350.971156 862.105295 L 353.588044 862.105295 L 340.033535 852.400887 L 326.542025 862.100447 L 329.154067 862.100447 L 329.202528 871.194089 L 302.234047 888.688199 L 304.390556 888.697894 L 304.482632 906.424677 L 376.035124 906.473151 Z M 376.039971 906.458609 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 126.972656 205.648438 L 126.976562 210.121094 L 56.570312 210.042969 L 56.539062 205.589844 L 126.976562 205.648438 "
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 384.380088 929.759853 L 384.384934 935.310076 L 297.03904 935.213129 L 297.000271 929.687142 L 384.384934 929.759853 Z M 384.380088 929.759853 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 302.839808 929.430233 L 302.767116 910.641878 L 306.304761 910.63703 L 306.110917 929.643516 L 306.324145 910.792146 L 301.22606 910.641878 L 301.22606 906.613724 L 379.291696 906.700977 L 379.325618 910.690351 L 374.513453 910.690351 L 374.605528 929.716227 L 374.431069 910.690351 L 378.017175 910.690351 L 378.099558 929.502943 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 319.621811 924.801007 L 319.563658 911.698602 L 309.081569 911.698602 L 309.144569 924.786465 L 319.621811 924.801007 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 311.940761 914.427663 L 311.969838 922.038014 L 316.767465 922.038014 L 316.743235 914.427663 L 311.940761 914.427663 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 370.859502 924.839786 L 370.801349 911.751923 L 360.353183 911.742228 L 360.401644 924.839786 L 370.859502 924.839786 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 363.178452 914.480984 L 363.236605 922.067098 L 368.024541 922.067098 L 367.980926 914.480984 L 363.178452 914.480984 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 370.781965 903.511416 L 370.718966 890.409011 L 360.261107 890.409011 L 360.299876 903.511416 L 370.786811 903.511416 Z M 370.781965 903.511416 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 363.100915 893.162309 L 363.129991 900.758117 L 367.922773 900.758117 L 367.913081 893.162309 L 363.100915 893.162309 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 319.529736 903.458095 L 319.457044 890.384774 L 308.99434 890.370232 L 309.042801 903.453248 L 319.529736 903.453248 Z M 319.529736 903.458095 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 311.848686 893.108989 L 311.872916 900.695102 L 316.67539 900.704797 L 316.641467 893.108989 L 311.848686 893.108989 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 338.502171 903.482332 L 338.45371 890.404163 L 327.981313 890.394469 L 328.024928 903.477485 L 338.502171 903.477485 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.830813 893.128378 L 330.855044 900.714491 L 335.652671 900.738728 L 335.618749 893.128378 L 330.830813 893.128378 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 353.117973 903.511416 L 353.045282 890.409011 L 342.582577 890.399316 L 342.631038 903.482332 L 353.117973 903.511416 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 345.441769 893.138073 L 345.475692 900.748423 L 350.273319 900.748423 L 350.249089 893.138073 L 345.441769 893.138073 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 103.871094 181.253906 L 103.964844 205.589844 L 79.515625 205.550781 L 79.425781 181.238281 L 103.867188 181.253906 "
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 355.720323 899.48811 L 355.836629 929.687142 L 325.504962 929.638669 L 325.393502 899.468721 L 355.715476 899.48811 Z M 355.720323 899.48811 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 325.209351 908.111558 L 355.749399 908.135794 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 329.018376 929.682295 L 328.916609 908.339383 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 352.139063 929.69199 L 352.037295 908.358773 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 366.788788 886.628073 L 349.711173 874.155824 L 330.515818 874.131587 L 313.569047 886.579599 L 366.788788 886.628073 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 331.989028 863.80187 L 332.018105 871.407372 L 348.165271 871.421914 L 348.136194 863.811564 L 331.989028 863.80187 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 336.369892 865.692339 L 336.394123 869.482972 L 343.808638 869.482972 L 343.784407 865.692339 L 336.369892 865.692339 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 340.038381 855.139643 L 349.018183 861.489681 L 331.126425 861.465444 L 340.038381 855.139643 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 376.340428 850.840038 C 378.88947 850.777022 379.548538 861.349107 379.553385 866.584252 C 381.743816 867.195019 386.604443 870.253702 388.75126 873.016695 L 364.108901 872.977916 C 366.226642 870.253702 371.0485 867.185325 373.224393 866.56971 C 373.224393 861.329718 373.757463 850.767327 376.340428 850.830343 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 326.80856 900.33155 L 329.018376 900.33155 L 328.373847 901.514305 C 328.373847 901.514305 329.439986 902.886107 328.393231 904.456651 L 328.969915 905.547306 L 326.842483 905.547306 L 327.419167 904.509972 C 327.419167 904.509972 326.328797 902.692213 327.467628 901.514305 L 326.80856 900.33155 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 331.310576 900.33155 L 333.520392 900.33155 L 332.880709 901.514305 C 332.880709 901.514305 333.946848 902.852176 332.885555 904.456651 L 333.457393 905.547306 L 331.334807 905.547306 L 331.906645 904.509972 C 331.906645 904.509972 330.840506 902.692213 331.959952 901.514305 L 331.310576 900.33155 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 336.229356 900.33155 L 338.434326 900.33155 L 337.775258 901.514305 C 337.775258 901.514305 338.855935 902.886107 337.794642 904.456651 L 338.371327 905.571543 L 336.258432 905.547306 L 336.825425 904.509972 C 336.825425 904.509972 335.749593 902.701908 336.869039 901.514305 L 336.22451 900.33155 Z M 336.229356 900.33155 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 341.768435 900.350939 L 343.992789 900.350939 L 343.338567 901.548237 C 343.338567 901.548237 344.404707 902.895802 343.348259 904.471193 L 343.944328 905.581237 L 341.802357 905.581237 L 342.374196 904.543903 C 342.374196 904.543903 341.308056 902.711602 342.446887 901.548237 L 341.778127 900.350939 Z M 341.768435 900.350939 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 346.701752 900.350939 L 348.901876 900.350939 L 348.252501 901.548237 C 348.252501 901.548237 349.323486 902.895802 348.262193 904.466345 L 348.848569 905.581237 L 346.725983 905.581237 L 347.278437 904.543903 C 347.278437 904.543903 346.207451 902.726144 347.365666 901.548237 L 346.701752 900.350939 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:1.1642;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 351.601148 900.360634 L 353.801272 900.346092 L 353.151896 901.548237 C 353.151896 901.548237 354.242266 902.890954 353.180972 904.471193 L 353.738272 905.57639 L 351.605994 905.57639 L 352.177832 904.543903 C 352.177832 904.543903 351.111693 902.721297 352.250523 901.548237 L 351.601148 900.360634 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:0.27124;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 340.702295 968.398133 C 352.05668 968.436912 366.027952 966.115028 372.575017 962.731573 L 386.943669 961.461566 L 387.229588 978.999303 C 375.056215 985.712892 346.944057 987.152557 340.760448 987.104083 C 334.567147 987.152557 306.110917 985.688655 293.879391 978.950829 L 294.039311 961.39855 L 308.441886 962.697642 C 314.984105 966.061707 329.381833 968.398133 340.731372 968.398133 L 340.697449 968.398133 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 24.199219 176.5 L 34.40625 179.75 L 31.320312 196.269531 L 21.65625 197.769531 C 21.65625 197.769531 20.027344 189.082031 24.199219 176.492188 "
    />
    <path
      style="fill:none;stroke-width:0.27124;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 256.879507 893.588877 L 269.542335 897.621878 L 265.713926 918.121349 L 253.724703 919.982734 C 253.724703 919.982734 251.703885 909.202213 256.879507 893.579182 Z M 256.879507 893.588877 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(49.411765%,49.411765%,49.411765%);fill-opacity:1;stroke-width:0.27124;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 265.70908 917.898371 L 253.72955 919.987582 L 261.308832 909.20706 L 265.70908 917.898371 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 18.097656 188.402344 L 27.9375 189.113281 C 28.15625 197.449219 29.167969 229.257812 67.15625 230.984375 L 65.972656 242.554688 C 19.730469 243.035156 16.21875 203.542969 18.097656 188.402344 "
    />
    <path
      style="fill:none;stroke-width:0.27124;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 249.309917 908.358773 L 261.517213 909.240992 C 261.788594 919.585251 263.043731 959.057277 310.171939 961.199809 L 308.703574 975.557679 C 251.335582 976.153904 246.978949 927.147128 249.309917 908.358773 Z M 249.309917 908.358773 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:0.27124;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 294.112003 978.500025 L 308.495193 975.358938 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 158.523438 176.621094 L 148.355469 179.839844 L 151.578125 196.335938 L 161.257812 197.890625 C 161.257812 197.890625 162.816406 189.210938 158.523438 176.613281 "
    />
    <path
      style="fill:none;stroke-width:0.27124;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 423.521943 893.739145 L 410.907575 897.733367 L 414.905598 918.203754 L 426.914205 920.133002 C 426.914205 920.133002 428.847794 909.362176 423.521943 893.72945 Z M 423.521943 893.739145 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(49.411765%,49.411765%,49.411765%);fill-opacity:1;stroke-width:0.27124;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 414.900752 917.990471 L 426.909359 920.142697 L 419.228309 909.357328 L 414.900752 917.990471 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 164.738281 188.53125 L 154.882812 189.203125 C 154.742188 197.527344 154.015625 229.351562 116.027344 231.039062 L 117.316406 242.601562 C 163.550781 243.132812 166.730469 203.660156 164.738281 188.527344 "
    />
    <path
      style="fill:none;stroke-width:0.27124;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 431.232069 908.518736 L 419.005389 909.352481 C 418.83093 919.682198 417.929557 959.173613 370.801349 961.267672 L 372.400558 975.615848 C 429.758859 976.275088 433.703574 927.292548 431.232069 908.513888 Z M 431.232069 908.518736 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style="fill:none;stroke-width:0.27124;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 387.040591 978.567888 L 372.618632 975.421953 "
      transform="matrix(0.806063,0,0,0.805852,-182.861719,-543.599993)"
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,62.352941%,77.254902%);fill-opacity:1;"
      d="M 85.613281 243.75 C 85.351562 243.914062 85 243.960938 84.59375 243.941406 L 82.757812 243.847656 L 82.914062 241.558594 L 84.734375 241.65625 C 85.09375 241.679688 85.378906 241.75 85.554688 241.855469 C 85.921875 242.046875 86.085938 242.402344 86.050781 242.890625 C 86.027344 243.3125 85.878906 243.585938 85.609375 243.75 M 82.527344 247.355469 L 82.707031 244.5625 L 84.847656 244.710938 C 85.539062 244.765625 86.054688 244.582031 86.402344 244.230469 C 86.765625 243.886719 86.953125 243.4375 86.976562 242.925781 C 87.023438 242.332031 86.871094 241.847656 86.515625 241.488281 C 86.179688 241.144531 85.695312 240.964844 85.09375 240.90625 L 82.035156 240.714844 L 81.613281 247.285156 L 82.523438 247.34375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,62.352941%,77.254902%);fill-opacity:1;"
      d="M 89.183594 241.855469 L 91.421875 241.878906 C 91.746094 241.910156 92 241.949219 92.1875 242.035156 C 92.53125 242.199219 92.691406 242.523438 92.691406 242.992188 C 92.691406 243.433594 92.566406 243.730469 92.316406 243.90625 C 92.074219 244.066406 91.726562 244.140625 91.308594 244.140625 L 89.160156 244.117188 L 89.179688 241.855469 M 89.144531 247.679688 L 89.164062 244.871094 L 91.265625 244.902344 C 91.636719 244.902344 91.914062 244.929688 92.105469 245.023438 C 92.414062 245.167969 92.566406 245.464844 92.578125 245.878906 L 92.632812 246.980469 C 92.648438 247.246094 92.652344 247.410156 92.667969 247.515625 C 92.699219 247.59375 92.710938 247.679688 92.75 247.738281 L 93.878906 247.738281 L 93.859375 247.59375 C 93.734375 247.527344 93.628906 247.394531 93.585938 247.191406 C 93.546875 247.066406 93.527344 246.875 93.515625 246.632812 L 93.496094 245.753906 C 93.484375 245.359375 93.414062 245.082031 93.261719 244.929688 C 93.128906 244.753906 92.949219 244.601562 92.667969 244.507812 C 92.980469 244.34375 93.21875 244.152344 93.390625 243.914062 C 93.550781 243.65625 93.636719 243.328125 93.636719 242.925781 C 93.636719 242.167969 93.324219 241.65625 92.699219 241.375 C 92.359375 241.222656 91.925781 241.144531 91.417969 241.136719 L 88.261719 241.105469 L 88.238281 247.699219 L 89.144531 247.699219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,62.352941%,77.254902%);fill-opacity:1;"
      d="M 96.148438 246.304688 C 95.746094 245.8125 95.53125 245.199219 95.480469 244.472656 C 95.4375 243.5625 95.613281 242.867188 96.03125 242.394531 C 96.453125 241.882812 96.996094 241.640625 97.710938 241.601562 C 98.425781 241.5625 99.015625 241.792969 99.449219 242.242188 C 99.898438 242.683594 100.152344 243.296875 100.195312 244.0625 C 100.222656 244.796875 100.09375 245.449219 99.753906 246.023438 C 99.421875 246.609375 98.867188 246.9375 98.046875 246.957031 C 97.195312 247 96.554688 246.800781 96.148438 246.304688 M 95.070312 242.152344 C 94.648438 242.804688 94.476562 243.5625 94.519531 244.472656 C 94.582031 245.460938 94.886719 246.273438 95.460938 246.886719 C 96.101562 247.5 96.945312 247.808594 97.984375 247.75 C 99.097656 247.703125 99.9375 247.28125 100.507812 246.503906 C 100.96875 245.804688 101.1875 244.988281 101.140625 243.984375 C 101.074219 243.085938 100.816406 242.355469 100.328125 241.808594 C 99.714844 241.078125 98.816406 240.757812 97.601562 240.804688 C 96.453125 240.878906 95.609375 241.300781 95.0625 242.152344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,62.352941%,77.254902%);fill-opacity:1;"
      d="M 126.054688 232.667969 C 126.6875 232.5625 127.234375 232.589844 127.738281 232.761719 C 128.472656 232.992188 128.96875 233.539062 129.277344 234.371094 L 128.371094 234.5 C 128.171875 234.046875 127.894531 233.738281 127.515625 233.566406 C 127.148438 233.40625 126.6875 233.367188 126.15625 233.445312 C 125.539062 233.539062 125.042969 233.847656 124.695312 234.34375 C 124.351562 234.871094 124.246094 235.578125 124.398438 236.480469 C 124.527344 237.226562 124.800781 237.859375 125.253906 238.28125 C 125.671875 238.710938 126.285156 238.863281 127.085938 238.75 C 127.683594 238.652344 128.164062 238.414062 128.503906 238 C 128.855469 237.609375 128.957031 237.015625 128.839844 236.25 L 126.726562 236.574219 L 126.617188 235.847656 L 129.5625 235.394531 L 130.136719 238.882812 L 129.550781 238.972656 L 129.1875 238.167969 C 128.945312 238.53125 128.699219 238.8125 128.484375 238.972656 C 128.121094 239.257812 127.628906 239.4375 127.015625 239.527344 C 126.21875 239.648438 125.496094 239.507812 124.835938 239.085938 C 124.117188 238.550781 123.648438 237.753906 123.472656 236.652344 C 123.28125 235.570312 123.453125 234.640625 123.953125 233.910156 C 124.429688 233.203125 125.121094 232.792969 126.054688 232.660156 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,62.352941%,77.254902%);fill-opacity:1;"
      d="M 132.710938 232.160156 L 134.820312 231.394531 C 135.109375 231.289062 135.363281 231.253906 135.578125 231.277344 C 135.964844 231.308594 136.25 231.539062 136.410156 232 C 136.5625 232.390625 136.5625 232.734375 136.390625 232.976562 C 136.210938 233.226562 135.925781 233.40625 135.511719 233.558594 L 133.53125 234.269531 L 132.710938 232.160156 M 134.820312 237.613281 L 133.792969 234.96875 L 135.753906 234.261719 C 136.121094 234.144531 136.378906 234.078125 136.570312 234.097656 C 136.917969 234.144531 137.183594 234.355469 137.359375 234.757812 L 137.804688 235.761719 C 137.902344 236.011719 137.980469 236.164062 138.046875 236.234375 C 138.074219 236.320312 138.140625 236.375 138.1875 236.433594 L 139.253906 236.042969 L 139.195312 235.898438 C 139.035156 235.898438 138.882812 235.8125 138.773438 235.640625 C 138.6875 235.515625 138.609375 235.339844 138.496094 235.132812 L 138.148438 234.308594 C 137.992188 233.953125 137.828125 233.730469 137.644531 233.597656 C 137.457031 233.496094 137.222656 233.425781 136.941406 233.40625 C 137.15625 233.15625 137.3125 232.90625 137.375 232.613281 C 137.4375 232.3125 137.40625 231.980469 137.265625 231.605469 C 136.996094 230.90625 136.5 230.523438 135.804688 230.472656 C 135.433594 230.445312 135.011719 230.523438 134.527344 230.695312 L 131.5625 231.75 L 133.957031 237.949219 L 134.828125 237.628906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,62.352941%,77.254902%);fill-opacity:1;"
      d="M 142.539062 234.34375 L 138.773438 228.855469 L 142.90625 226.222656 L 143.363281 226.882812 L 139.976562 229.046875 L 141.132812 230.722656 L 144.261719 228.714844 L 144.6875 229.355469 L 141.5625 231.367188 L 142.835938 233.214844 L 146.28125 231.011719 L 146.738281 231.660156 L 142.53125 234.34375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,62.352941%,77.254902%);fill-opacity:1;"
      d="M 145.835938 224.632812 L 146.914062 223.449219 C 147.414062 222.917969 147.945312 222.675781 148.523438 222.746094 C 149.101562 222.792969 149.691406 223.097656 150.320312 223.648438 C 150.492188 223.78125 150.644531 223.953125 150.800781 224.144531 C 151.074219 224.503906 151.238281 224.816406 151.339844 225.128906 C 151.429688 225.523438 151.410156 225.882812 151.269531 226.222656 C 151.1875 226.394531 151.039062 226.597656 150.820312 226.835938 L 149.734375 228.015625 L 145.835938 224.625 M 151.535156 227.210938 C 152.300781 226.367188 152.46875 225.460938 152.023438 224.433594 C 151.761719 223.878906 151.363281 223.339844 150.816406 222.867188 C 150.121094 222.253906 149.402344 221.941406 148.640625 221.882812 C 147.796875 221.820312 147.082031 222.125 146.457031 222.804688 L 144.617188 224.777344 L 149.691406 229.203125 L 151.53125 227.21875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,62.352941%,77.254902%);fill-opacity:1;"
      d="M 155.46875 222.097656 L 149.628906 218.667969 L 150.105469 217.910156 L 155.953125 221.320312 L 155.472656 222.105469 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,62.352941%,77.254902%);fill-opacity:1;"
      d="M 157.757812 218.113281 L 151.449219 215.59375 L 153.3125 211.160156 L 154.074219 211.453125 L 152.558594 215.109375 L 154.457031 215.855469 L 155.875 212.488281 L 156.601562 212.789062 L 155.199219 216.148438 L 157.324219 217.011719 L 158.878906 213.3125 L 159.644531 213.601562 L 157.75 218.113281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,62.352941%,77.254902%);fill-opacity:1;"
      d="M 160.648438 210.371094 L 154.015625 208.902344 L 154.238281 207.878906 L 160.367188 205.808594 L 154.984375 204.621094 L 155.179688 203.816406 L 161.8125 205.242188 L 161.601562 206.238281 L 155.464844 208.355469 L 160.847656 209.515625 L 160.65625 210.371094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,62.352941%,77.254902%);fill-opacity:1;"
      d="M 160.125 201.949219 L 160.210938 201.117188 C 160.59375 201.125 160.914062 201.066406 161.160156 200.933594 C 161.640625 200.675781 161.921875 200.179688 161.996094 199.449219 C 162.023438 199.105469 162.011719 198.804688 161.933594 198.519531 C 161.789062 197.972656 161.480469 197.667969 160.984375 197.617188 C 160.617188 197.578125 160.351562 197.667969 160.160156 197.867188 C 159.988281 198.097656 159.808594 198.433594 159.667969 198.902344 L 159.367188 199.773438 C 159.167969 200.339844 158.988281 200.734375 158.792969 200.953125 C 158.476562 201.367188 158.035156 201.507812 157.472656 201.46875 C 156.867188 201.40625 156.378906 201.164062 156.027344 200.703125 C 155.671875 200.28125 155.523438 199.679688 155.613281 198.933594 C 155.671875 198.25 155.894531 197.675781 156.296875 197.214844 C 156.671875 196.792969 157.25 196.59375 157.992188 196.671875 L 157.921875 197.503906 C 157.558594 197.503906 157.269531 197.570312 157.058594 197.714844 C 156.675781 197.925781 156.464844 198.375 156.402344 199.027344 C 156.347656 199.566406 156.421875 199.929688 156.628906 200.203125 C 156.832031 200.433594 157.09375 200.582031 157.398438 200.605469 C 157.722656 200.636719 157.976562 200.53125 158.152344 200.28125 C 158.28125 200.109375 158.433594 199.738281 158.640625 199.132812 L 158.9375 198.222656 C 159.085938 197.800781 159.261719 197.476562 159.46875 197.246094 C 159.835938 196.863281 160.316406 196.699219 160.921875 196.746094 C 161.65625 196.824219 162.183594 197.128906 162.453125 197.6875 C 162.714844 198.25 162.820312 198.875 162.757812 199.582031 C 162.671875 200.378906 162.414062 201.007812 161.929688 201.429688 C 161.449219 201.863281 160.851562 202.007812 160.125 201.949219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,62.352941%,77.254902%);fill-opacity:1;"
      d="M 22.605469 201.546875 L 22.753906 202.371094 C 22.386719 202.445312 22.09375 202.589844 21.878906 202.78125 C 21.488281 203.144531 21.355469 203.691406 21.488281 204.429688 C 21.546875 204.746094 21.660156 205.054688 21.804688 205.300781 C 22.097656 205.800781 22.480469 206 22.964844 205.921875 C 23.324219 205.859375 23.558594 205.722656 23.671875 205.464844 C 23.78125 205.214844 23.859375 204.832031 23.882812 204.34375 L 23.929688 203.414062 C 23.957031 202.839844 24.023438 202.390625 24.148438 202.140625 C 24.351562 201.6875 24.726562 201.410156 25.292969 201.316406 C 25.894531 201.207031 26.425781 201.34375 26.882812 201.6875 C 27.34375 202.007812 27.648438 202.5625 27.761719 203.296875 C 27.902344 203.96875 27.839844 204.574219 27.574219 205.117188 C 27.320312 205.648438 26.828125 205.972656 26.078125 206.109375 L 25.929688 205.273438 C 26.277344 205.148438 26.539062 205.023438 26.710938 204.851562 C 27.003906 204.523438 27.09375 204.046875 26.980469 203.394531 C 26.890625 202.867188 26.710938 202.523438 26.4375 202.351562 C 26.164062 202.148438 25.894531 202.070312 25.601562 202.121094 C 25.273438 202.167969 25.058594 202.351562 24.953125 202.644531 C 24.890625 202.839844 24.839844 203.25 24.796875 203.871094 L 24.753906 204.832031 C 24.726562 205.285156 24.660156 205.65625 24.507812 205.914062 C 24.265625 206.382812 23.859375 206.671875 23.265625 206.765625 C 22.515625 206.894531 21.9375 206.722656 21.523438 206.242188 C 21.101562 205.769531 20.84375 205.207031 20.710938 204.503906 C 20.5625 203.671875 20.671875 203.023438 21.003906 202.503906 C 21.34375 201.96875 21.878906 201.660156 22.605469 201.554688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,62.352941%,77.254902%);fill-opacity:1;"
      d="M 22.183594 210.242188 L 28.671875 208.289062 L 30.183594 212.855469 L 29.390625 213.105469 L 28.15625 209.351562 L 26.183594 209.949219 L 27.328125 213.414062 L 26.570312 213.65625 L 25.4375 210.179688 L 23.238281 210.835938 L 24.496094 214.671875 L 23.71875 214.890625 L 22.183594 210.242188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,62.352941%,77.254902%);fill-opacity:1;"
      d="M 25.0625 218.164062 L 31.027344 215 L 31.667969 216.113281 L 27.566406 220.449219 L 33.554688 219.425781 L 34.175781 220.527344 L 28.222656 223.671875 L 27.800781 222.941406 L 31.3125 221.09375 C 31.441406 221.019531 31.636719 220.910156 31.921875 220.757812 C 32.203125 220.632812 32.515625 220.460938 32.828125 220.296875 L 26.863281 221.3125 L 26.425781 220.539062 L 30.5 216.214844 L 30.300781 216.316406 C 30.175781 216.386719 29.953125 216.496094 29.65625 216.667969 C 29.359375 216.847656 29.140625 216.960938 28.996094 217.019531 L 25.492188 218.898438 L 25.0625 218.152344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,62.352941%,77.254902%);fill-opacity:1;"
      d="M 36.175781 226.945312 C 35.878906 226.878906 35.582031 226.707031 35.292969 226.40625 L 34.035156 225.109375 L 35.730469 223.550781 L 37.003906 224.847656 C 37.246094 225.089844 37.40625 225.324219 37.488281 225.523438 C 37.605469 225.914062 37.488281 226.265625 37.128906 226.597656 C 36.804688 226.898438 36.480469 227.019531 36.179688 226.945312 M 31.382812 227.550781 L 33.464844 225.640625 L 34.941406 227.136719 C 35.417969 227.625 35.902344 227.84375 36.414062 227.835938 C 36.933594 227.804688 37.378906 227.613281 37.773438 227.242188 C 38.214844 226.847656 38.425781 226.386719 38.425781 225.894531 C 38.421875 225.410156 38.199219 224.960938 37.792969 224.539062 L 35.671875 222.375 L 30.734375 226.90625 L 31.375 227.550781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,62.352941%,77.254902%);fill-opacity:1;"
      d="M 37.339844 232.847656 L 40.914062 227.238281 L 45.144531 229.808594 L 44.703125 230.476562 L 41.230469 228.398438 L 40.15625 230.09375 L 43.347656 232.03125 L 42.9375 232.675781 L 39.738281 230.746094 L 38.519531 232.644531 L 42.039062 234.761719 L 41.617188 235.421875 L 37.339844 232.847656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,62.352941%,77.254902%);fill-opacity:1;"
      d="M 47.921875 231.765625 L 50.070312 232.449219 C 50.375 232.542969 50.597656 232.660156 50.742188 232.792969 C 51.019531 233.050781 51.09375 233.425781 50.929688 233.875 C 50.796875 234.269531 50.597656 234.523438 50.28125 234.632812 C 50.003906 234.679688 49.648438 234.679688 49.238281 234.550781 L 47.210938 233.90625 L 47.921875 231.765625 M 46.066406 237.273438 L 46.960938 234.601562 L 48.960938 235.253906 C 49.320312 235.347656 49.582031 235.476562 49.707031 235.605469 C 49.953125 235.828125 50.027344 236.15625 49.90625 236.5625 L 49.605469 237.628906 C 49.535156 237.886719 49.503906 238.058594 49.472656 238.136719 C 49.472656 238.222656 49.472656 238.3125 49.488281 238.367188 L 50.542969 238.710938 L 50.605469 238.558594 C 50.492188 238.472656 50.429688 238.3125 50.433594 238.109375 C 50.457031 237.984375 50.492188 237.789062 50.5625 237.5625 L 50.816406 236.710938 C 50.921875 236.339844 50.960938 236.058594 50.867188 235.859375 C 50.789062 235.644531 50.65625 235.457031 50.433594 235.273438 C 50.769531 235.214844 51.058594 235.113281 51.304688 234.929688 C 51.535156 234.730469 51.730469 234.449219 51.847656 234.058594 C 52.089844 233.347656 51.957031 232.753906 51.425781 232.300781 C 51.160156 232.066406 50.78125 231.863281 50.289062 231.679688 L 47.285156 230.75 L 45.183594 237.003906 L 46.0625 237.273438 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(73.72549%,44.313725%,37.254902%);fill-opacity:1;"
      d="M 55.183594 123.453125 C 55.960938 121.582031 63.144531 116.484375 86.714844 118.71875 C 86.714844 118.71875 90.699219 121.152344 93.039062 120.835938 C 94.375 120.664062 92.253906 120.921875 90.511719 119.472656 C 88.738281 118.027344 88.359375 115.058594 92.417969 115.136719 C 96.457031 115.238281 111.574219 116.09375 111.859375 117.453125 C 112.121094 118.824219 103.863281 119.398438 100.792969 119.320312 C 97.707031 119.238281 98.066406 120.796875 101.136719 120.679688 C 110.734375 120.335938 121.210938 116.957031 132.273438 124.78125 C 133.59375 125.730469 128.9375 125.882812 125.335938 124.355469 C 125.335938 124.355469 115.578125 124.859375 111.355469 124.167969 C 111.355469 124.167969 108.113281 126.804688 103.890625 126.546875 C 104.4375 127.914062 102.328125 133.023438 89.046875 129.179688 C 86.851562 129.851562 78.859375 131.191406 79.734375 128.816406 C 77.628906 128.816406 73.9375 129.496094 73.386719 128.296875 C 72.859375 127.121094 79.886719 125.339844 81.117188 124.066406 C 81.117188 124.066406 66.253906 124.207031 63.4375 123.179688 C 63.4375 123.179688 54.667969 125.148438 55.183594 123.441406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,52.941176%,21.568627%);fill-opacity:1;"
      d="M 59.949219 144.257812 C 60.265625 143.828125 60.394531 143.050781 61.070312 143.070312 C 62.542969 143.464844 63.546875 146.15625 65.355469 144.410156 C 66 144.269531 66.242188 145.140625 66.773438 145.246094 C 67.113281 146.742188 68.3125 144.941406 69.210938 145.042969 C 71.132812 144.191406 73.503906 143.988281 75.777344 144.410156 C 75.847656 145.878906 74.699219 147.496094 73.253906 148.332031 C 73.285156 149.808594 74.625 150.753906 74.601562 152.238281 C 74.371094 152.792969 74.5625 153.667969 73.722656 153.847656 C 72.65625 153.65625 71.605469 153.542969 70.722656 152.953125 C 70.617188 152.84375 70.527344 152.632812 70.300781 152.699219 C 70.195312 153.195312 70.96875 153.6875 71.273438 154.191406 C 70.738281 155.105469 69.851562 154.269531 69.105469 154.28125 C 68.882812 154.804688 69.09375 155.46875 68.554688 155.804688 C 67.613281 155.777344 67.421875 154.753906 66.84375 154.242188 C 66.25 154.882812 67.257812 155.265625 67.402344 155.777344 C 67.039062 157.710938 67.085938 159.597656 65.15625 160.863281 L 63.859375 161.507812 C 63.503906 160.535156 63.726562 159.0625 63.492188 158.046875 C 62.726562 159.292969 62.128906 160.382812 61.003906 161.4375 C 59.6875 161.007812 58.921875 159.457031 58.632812 158.171875 C 58.082031 156.675781 58.398438 154.882812 59.582031 153.800781 C 59.179688 153.015625 57.800781 153.195312 57.601562 152.078125 C 55.539062 150.734375 54.394531 154.433594 53.058594 152.511719 C 52.628906 150.714844 53.144531 148.480469 54.441406 147.144531 C 54.152344 146.40625 53.191406 146.597656 52.621094 146.175781 C 51.320312 145.074219 49.449219 143.90625 49.542969 141.914062 C 53.097656 140.570312 57.351562 141.554688 59.9375 144.257812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 61.792969 144.058594 C 61.710938 145.683594 62.894531 144.5 63.773438 145.484375 C 63.085938 145.214844 62.585938 146.671875 61.914062 145.835938 L 61.488281 146.242188 C 61.734375 146.800781 62.210938 146.871094 61.714844 147.484375 C 60.980469 147.765625 60.832031 146.84375 60.488281 146.480469 C 60.429688 146.320312 60.601562 146.015625 60.332031 145.90625 C 59.914062 146.257812 59.476562 146.386719 58.90625 146.328125 C 58.746094 146.015625 58.441406 145.601562 58.753906 145.363281 C 59.316406 144.601562 60.191406 145.636719 60.617188 145.101562 C 60.683594 144.632812 60.273438 144.058594 60.808594 143.734375 C 61.175781 143.796875 61.648438 143.597656 61.800781 144.058594 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 66.195312 145.394531 C 66.238281 145.710938 66.035156 146.152344 66.445312 146.371094 C 66.980469 146.082031 67.550781 146.300781 67.859375 146.832031 C 67.855469 147.117188 67.941406 147.488281 67.570312 147.628906 L 66.734375 147.46875 C 66.792969 147.308594 66.527344 147.207031 66.425781 147.046875 C 66.058594 146.9375 65.84375 147.234375 65.566406 147.417969 C 65.773438 147.730469 66.25 147.902344 66.136719 148.304688 C 65.644531 148.792969 65.40625 147.882812 64.867188 148.113281 C 64.496094 147.972656 65 147.207031 64.417969 146.976562 C 63.730469 146.976562 64.1875 148.101562 63.308594 147.660156 C 62.996094 147.308594 62.6875 146.832031 63.027344 146.433594 C 63.394531 146.191406 64.015625 146.253906 64.332031 146.574219 C 64.867188 145.980469 64.917969 143.6875 66.199219 145.394531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 60.714844 147.757812 C 61.074219 148.273438 60.636719 148.972656 61.09375 149.546875 C 61.808594 148.207031 62.5 149.835938 63.355469 149.707031 C 63.613281 150.027344 63.441406 150.46875 63.492188 150.832031 C 62.4375 151.113281 61.164062 151.445312 60.234375 150.601562 L 59.972656 150.902344 C 60.527344 151.417969 61.054688 152 60.609375 152.863281 C 59.820312 153.035156 58.597656 153.054688 58.203125 152.238281 C 57.996094 151.566406 58.683594 151.082031 57.957031 150.542969 C 57.429688 151.195312 57.457031 152.070312 56.363281 151.472656 C 55.683594 151.101562 55.484375 150.332031 55.820312 149.683594 C 56.527344 148.570312 57.765625 149.71875 58.53125 149.175781 C 58.636719 148.21875 58.285156 147.382812 59.164062 146.855469 C 59.914062 146.722656 60.261719 147.285156 60.71875 147.757812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 63.703125 148.34375 C 63.351562 149.972656 62.125 148.261719 61.550781 148.191406 C 62.316406 146.835938 62.796875 148.632812 63.703125 148.34375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 67.527344 150.117188 C 66.671875 150.355469 66.804688 149.292969 66.382812 148.875 L 66.710938 148.578125 C 67.019531 148.949219 67.253906 149.621094 67.527344 150.117188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 66.371094 149.785156 C 66.617188 150.351562 65.816406 150.804688 66.5 151.214844 C 66.714844 151.035156 66.980469 150.78125 67.09375 150.679688 C 67.679688 150.851562 68.355469 150.953125 68.761719 151.535156 C 69.199219 152.730469 67.988281 152.699219 67.300781 153.035156 L 66.617188 153.003906 C 66.78125 152.671875 66.324219 152.527344 66.210938 152.300781 C 65.898438 152.21875 65.636719 152.316406 65.460938 152.582031 C 65.613281 153.34375 66.539062 153.9375 65.582031 154.613281 C 64.773438 154.558594 63.515625 154.875 63.589844 153.753906 C 63.285156 153.121094 64.414062 152.148438 63.207031 152.109375 C 62.808594 152.960938 62.375 153.785156 61.339844 152.976562 L 61.464844 151.757812 C 62.457031 151.789062 63.628906 151.960938 64.078125 150.851562 C 64.359375 150.046875 63.582031 149.308594 64.277344 148.667969 C 65.289062 148.4375 65.859375 149.117188 66.371094 149.785156 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 65.648438 151.617188 C 65.902344 152.21875 65.214844 152.257812 64.890625 152.558594 C 64.453125 152.59375 64.152344 152.421875 63.886719 152.125 C 63.691406 151.394531 64.234375 151.320312 64.652344 151.03125 C 65.136719 150.832031 65.339844 151.394531 65.648438 151.617188 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 65.050781 152.011719 C 64.941406 152.152344 64.789062 152.109375 64.628906 152.148438 L 64.476562 151.417969 C 64.800781 151.445312 65.222656 151.550781 65.050781 152.011719 "
    />
  </g>
</svg>
