<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 0 L 479.984375 0 L 479.984375 360 L 0 360 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(21.176471%,63.137255%,0%);fill-opacity:1;"
      d="M 319.992188 0 L 479.984375 0 L 479.984375 360 L 319.992188 360 Z M 319.992188 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(21.176471%,63.137255%,0%);fill-opacity:1;"
      d="M 0 0 L 159.996094 0 L 159.996094 360 L 0 360 Z M 0 0 "
    />
  </g>
</svg>
