import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    document.querySelector(
      ".formtastic.team_user .new-button.primary-text #team_user_avatar",
    ).onchange = function () {
      document.querySelector(
        ".formtastic.team_user .file.input .file-name",
      ).textContent = this.files[0].name;
    };
  }
}
