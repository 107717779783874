<script>
  import styles from "styleguide/ControlClientSearch.json";
  import { user } from "stores/user.js";
  import { audit } from "stores/pbc.js";
  import { toasts } from "stores/toasts.js";
  import { t } from "stores/i18n.js";

  import AuditApi from "apis/pbc/audit.js";
  import ClientUsers from "apis/users/client_users.js";
  import UsersControlsApi from "apis/pbc/users_controls.js";

  import { Boundary, LegacyButton as Button, Search } from "components";

  export let top;
  export let permalink;
  export let permalinks;
  export let users = [];
  export let lock;
  export let select;

  let usersSearchAll;

  function selectUser(user_id) {
    if (permalink) {
      UsersControlsApi.create({
        params: {
          user_id,
          control_id: permalink,
        },
        success: () => {
          users = [
            ...users,
            usersSearchAll.find((item) => item.permalink === user_id),
          ];
          AuditApi.getAudits();
          AuditApi.getClientUsers();
          toasts.send({
            title: $t("control_client_search.user_add_success"),
            type: "success",
          });
        },
        error: () => {
          toasts.send({ title: $t("errors.check_entries"), type: "error" });
        },
      });
    } else if (permalinks) {
      UsersControlsApi.updateStatusBatch({
        params: {
          user_id,
          permalinks,
        },
        success: () => {
          AuditApi.getAudits();
          AuditApi.getClientUsers();
          toasts.send({
            title: $t("control_client_search.users_add_success").replace(
              "%{count}",
              permalinks.length,
            ),
            type: "success",
          });
        },
        error: () => {
          toasts.send({
            title: $t("control_client_search.users_add_error"),
            type: "error",
          });
        },
      });

      if (select) select();
    }
  }

  function modifySearchResults(response) {
    if (response) {
      usersSearchAll = response;

      let newUsers = usersSearchAll.map((item) => {
        return {
          label: item.email,
          value: item.permalink,
        };
      });

      return newUsers.filter((item) => {
        return !users.some((user) => user.email === item.label);
      });
    } else {
      return [];
    }
  }

  function onDelete(user_id) {
    UsersControlsApi.delete({
      params: {
        user_id,
        control_id: permalink,
      },
      success: () => {
        AuditApi.getAudits();
        AuditApi.getClientUsers();
        users = users.filter((item) => item.permalink !== user_id);
        toasts.send({
          title: $t("audit_client_access.client_user_removed_success"),
          type: "success",
        });
      },
      error: () => {
        toasts.send({
          title: $t("audit_client_access.client_user_removed_error"),
          type: "error",
        });
      },
    });
  }
</script>

<Boundary>
  <div data-component="ControlClientSearch" class={lock ? styles.lock : ""}>
    {#if permalink}
      <div class={styles.items}>
        {#each users as { email, permalink } (email)}
          <div class={styles.item}>
            <div class={styles.email}>{email}</div>

            {#if $user.role !== "client"}
              <div class={styles.delete}>
                <Button
                  click={onDelete.bind(this, permalink)}
                  style="border icon-only medium"
                  icon={"icon-controls-minus"}
                />
              </div>
            {/if}
          </div>
        {/each}
      </div>
    {/if}
    {#if !lock}
      <Search
        icon={false}
        border={false}
        arrows={true}
        searchApi={ClientUsers}
        searchApiParams={{ audit_id: $audit.permalink }}
        {modifySearchResults}
        select={selectUser}
        style={`${users.length > 0 ? "top-side" : ""} ${top ? "top" : ""}`}
        placeholder={$t("control_client_search.add_user_by_email")}
      />
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    border: 1px solid var(--primary-050);
    border-bottom: none;
    position: relative;

    &:hover {
      .delete {
        display: flex;
      }
    }

    &:first-child {
      border-top-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
    }

    .lock &:last-child {
      border-bottom: 1px solid var(--primary-050);
      border-bottom-left-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }
  }

  .email {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .delete {
    display: none;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translate(0%, -50%);
  }
</style>
