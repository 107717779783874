<script>
  import styles from "styleguide/ConfirmationSidebar.json";
  import { isUserTeamUser } from "stores/user.js";
  import { t } from "stores/i18n.js";
  import {
    confirmation,
    authorizedSignersCount,
  } from "stores/confirmation/confirmation.js";

  import {
    ConfirmationSidebarContacts,
    ConfirmationSidebarOverview,
    ConfirmationSidebarSettings,
    ConfirmationSidebarSigners,
  } from "apps/confirmation";

  import {
    Boundary,
    Box,
    LegacyButton as Button,
    SidebarArea,
    SidebarNotifications,
    Smalltabs,
  } from "components";

  let activeContacts = true;
  $: areAvailableSigners = $authorizedSignersCount > 0;

  function toggleSidebarTab(isActiveContacts) {
    if (areAvailableSigners) activeContacts = isActiveContacts;
  }
</script>

<Boundary>
  <div data-component="ConfirmationSidebar" class={styles.wrapper}>
    {#if $isUserTeamUser}
      <SidebarNotifications store={$confirmation} unitType={"Confirmation"} />
    {/if}

    <ConfirmationSidebarOverview />
    <Box style={"padding margin-top"}>
      <SidebarArea style={"all smalltabs padding-bottom"}>
        <Smalltabs>
          <Button
            style={"smalltab fullsize"}
            active={activeContacts}
            click={toggleSidebarTab.bind(this, true)}
          >
            {$t("confirmation_sidebar.assigned_contacts")}
          </Button>
          <Button
            style={`smalltab fullsize ${areAvailableSigners ? "" : "grey"}`}
            active={!activeContacts}
            click={toggleSidebarTab.bind(this, false)}
          >
            {$t("confirmation_sidebar.auth_signers")}
          </Button>
        </Smalltabs>
      </SidebarArea>

      {#if activeContacts}
        <ConfirmationSidebarContacts />
      {:else}
        <ConfirmationSidebarSigners />
      {/if}
    </Box>

    <Box
      style={"padding margin-top"}
      title={$t("confirmation_sidebar.settings")}
    >
      <ConfirmationSidebarSettings />
    </Box>
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    margin: 25px 0;
  }
</style>
