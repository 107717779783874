export function getSampleResponseCircleIconColor(status) {
  switch (status) {
    case "red":
      return "var(--red-100)";

    case "yellow":
      return "var(--orange-400)";

    case "orange":
      return "var(--orange-100)";

    case "green":
      return "var(--green-400)";

    default:
      return "";
  }
}
