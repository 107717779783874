<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(0%,0%,40%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 306.226562 144.1875 L 466.945312 144.1875 L 466.753906 259.269531 C 468.09375 305.53125 439.4375 334.234375 386.714844 347.179688 C 349.269531 337.820312 306.097656 318.46875 306.035156 260.582031 Z M 306.226562 144.1875 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,63.529412%,74.117647%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 44.069837 22.711962 L 213.370811 22.711962 L 213.168437 143.502335 C 214.576444 192.059816 184.392568 222.192155 128.851652 235.77781 C 89.414545 225.957628 43.936356 205.649749 43.867463 144.880155 L 44.069837 22.707683 Z M 44.069837 22.711962 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(64.705882%,23.921569%,3.137255%);fill-opacity:1;"
      d="M 462.1875 270.996094 C 456.597656 313.308594 424.214844 331.796875 386.699219 341.609375 C 353.492188 332.648438 317.765625 318.335938 310.839844 271.210938 L 462.191406 270.996094 Z M 462.1875 270.996094 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,77.647059%,70.980392%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 155.771708 197.168878 C 155.862131 197.263015 156.426195 206.467028 151.45296 212.098121 C 155.862131 213.505895 158.867601 211.815711 160.275608 210.03139 C 161.68792 208.24707 162.157256 205.996344 162.157256 205.996344 C 162.157256 205.996344 163.375806 205.24325 163.565263 203.553066 C 163.659991 201.208203 162.626591 201.208203 161.778343 200.737519 L 161.967799 195.478694 C 161.967799 195.478694 156.89553 192.380737 155.771708 197.168878 Z M 155.771708 197.168878 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,60.392157%,3.137255%);fill-opacity:1;stroke-width:1.25;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 155.491829 210.31808 L 153.989094 209.09858 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,77.647059%,70.980392%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 141.639971 69.390986 C 141.639971 69.390986 141.756228 75.0178 141.40315 75.604016 C 141.054377 76.190232 137.850839 77.675026 137.850839 77.675026 L 140.585042 83.494393 C 140.585042 83.494393 148.279562 82.398983 148.318315 82.398983 C 148.357067 82.398983 151.63811 73.691327 151.63811 73.691327 C 151.63811 73.691327 149.920084 70.798759 150.350667 67.79066 C 147.771474 59.626431 141.756228 69.433776 141.639971 69.390986 Z M 141.639971 69.390986 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,31.764706%,12.941176%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 131.448068 203.09094 C 131.448068 203.09094 135.771123 207.408396 140.744358 206.847854 C 152.012718 205.624076 155.298067 195.57711 158.772873 195.482973 C 162.247678 195.393115 164.4049 201.589029 167.694555 200.835935 C 164.874235 195.671247 161.873071 184.216508 162.342407 176.142136 C 162.811742 168.067765 162.811742 134.080095 162.811742 134.080095 C 162.811742 134.080095 157.175409 116.5193 154.548852 113.609616 C 157.175409 110.79407 158.962329 105.817655 158.867601 100.370557 C 158.772873 94.923458 158.867601 88.915818 158.867601 88.915818 C 158.867601 88.915818 160.180879 87.413908 160.086151 83.186309 C 159.995729 78.962989 152.951389 72.861212 152.012718 73.139343 C 151.074047 73.421753 142.247093 81.02972 141.497878 82.809761 C 140.744358 84.594082 139.710958 76.331437 141.40315 75.582621 C 143.091036 74.829527 137.459008 74.363122 133.889474 78.586442 C 130.324246 82.809761 131.357646 203.274934 131.448068 203.086661 Z M 131.448068 203.09094 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 350.636719 168.089844 L 380.617188 168.199219 L 380.402344 154.300781 L 391.324219 154.410156 L 391.429688 168.199219 L 421.300781 168.199219 L 421.40625 178.972656 L 391.429688 179.082031 L 391.191406 306.136719 L 380.539062 306.207031 L 380.402344 178.976562 L 350.632812 179.082031 L 350.636719 168.09375 Z M 350.636719 168.089844 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,77.647059%,70.980392%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 122.031216 81.958251 C 122.031216 81.958251 119.770654 81.560309 118.044016 82.090899 C 116.321683 82.621488 120.700714 62.506162 120.700714 60.845931 C 122.229284 60.114231 129.863522 58.719294 129.665454 53.344938 C 129.135837 50.08866 117.845948 53.541769 118.044016 57.658116 C 117.31633 59.716289 109.949053 80.695962 111.40873 87.602181 C 113.996535 89.660354 118.776007 89.262412 122.031216 88.731823 Z M 122.031216 81.958251 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:0.625;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 124.218578 53.212291 C 124.218578 53.212291 123.55548 56.930695 126.939863 57.461284 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:0.375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 129.200424 53.143827 C 129.066944 53.212291 124.817088 55.865237 124.817088 55.865237 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:0.375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 128.942075 54.804058 L 125.953828 56.994879 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:0.375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 127.809641 52.347943 L 124.489845 54.341932 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,60.392157%,3.137255%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 95.490072 163.562034 L 125.63089 194.828294 C 136.051002 183.373555 128.821511 140.554141 115.116051 132.668043 C 113.471223 137.738594 110.676739 143.818977 107.710021 145.778734 C 101.17377 150.233117 85.160384 155.436316 90.512532 158.769615 C 91.735388 157.079431 94.926009 155.483384 96.428744 159.240299 C 98.211358 165.158081 89.763317 165.53035 89.763317 165.53035 C 89.763317 165.53035 84.411169 164.875671 83.472498 159.428572 C 82.533827 153.981474 91.442591 149.009338 92.204723 148.632791 C 92.953938 148.350381 104.596905 145.252424 106.568976 134.923047 C 109.010382 124.781945 111.546517 126.283855 112.015852 126.095581 C 127.224048 127.597492 137.179129 154.828705 137.743193 173.981268 C 138.307257 193.138111 129.949639 205.812349 128.446904 206.655302 C 126.944169 207.502533 92.204723 165.436213 92.204723 165.436213 Z M 95.490072 163.562034 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 117.458423 134.448084 L 117.647879 190.878827 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 114.078345 135.47931 C 114.078345 135.573447 114.267802 186.745365 114.267802 186.745365 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 110.418389 142.899003 L 110.607846 183.651687 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 107.421531 145.808687 L 107.606681 179.518225 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 104.217992 147.319155 L 104.217992 175.769868 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 100.751798 149.56988 L 100.751798 172.102811 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 97.742022 151.349922 L 97.742022 168.624028 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(100%,87.45098%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 95.020737 167.130676 L 126.100226 202.710113 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(100%,87.45098%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 127.598655 144.691882 C 127.598655 144.691882 141.683029 175.487458 128.632055 200.840214 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(100%,87.45098%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 91.36078 160.648072 C 91.36078 160.648072 92.389874 157.926662 93.707458 159.052025 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(100%,87.45098%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 88.919374 152.761974 C 88.919374 152.761974 83.283041 157.73411 86.38324 160.930483 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 141.028542 82.998035 C 141.028542 82.998035 141.497878 87.319771 140.559207 90.606001 C 139.620536 93.892232 144.128741 102.99783 142.436549 104.876287 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 142.910191 113.699474 L 154.742615 113.605337 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 138.591442 118.491894 C 138.681865 118.675889 139.999449 124.687808 139.620536 128.816991 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 142.440855 120.648483 C 142.066248 121.872261 137.183435 135.766 137.368586 136.330821 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 145.351597 114.358432 C 145.442019 114.640842 144.97699 123.185898 143.659405 124.405398 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 151.358231 113.699474 C 151.358231 113.699474 159.061363 131.538401 158.966635 138.581546 C 158.871907 145.624692 161.593192 159.894978 159.810578 166.185028 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 153.799638 138.401831 C 153.799638 138.401831 153.235574 151.64089 147.698275 156.801299 C 142.15667 161.965988 160.9344 176.706957 160.9344 176.706957 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 153.610181 175.859726 C 153.610181 175.859726 155.67698 194.0752 159.151786 195.388836 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 136.619371 145.338003 C 136.7141 145.526276 134.742029 157.639973 136.339492 159.612567 C 137.936956 161.585161 151.173081 180.083046 150.609017 199.235609 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 146.850026 193.510379 C 146.850026 193.510379 146.475419 205.059255 135.771123 205.996344 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 143.659405 185.910971 C 143.659405 185.910971 146.854332 197.926252 135.960579 205.534218 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 147.87912 156.891157 C 147.87912 156.891157 160.180879 169.659533 160.744943 175.294905 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 151.448654 113.981885 C 151.448654 113.981885 153.610181 123.087482 152.766238 124.683529 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,77.647059%,70.980392%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 113.509976 132.689438 C 113.509976 132.689438 112.192391 130.383085 112.356013 127.832834 C 112.52394 125.282582 111.946959 121.333114 120.179708 118.119626 C 125.200307 113.019122 130.797888 109.972512 133.84211 108.406418 C 138.285728 105.526687 141.166329 106.185645 143.801498 105.197208 C 145.528136 103.220335 145.446325 98.860089 147.25908 94.991921 C 149.067529 91.123754 152.279679 83.13924 155.737262 84.371577 C 159.194844 85.608192 156.477865 95.980358 155.16028 99.600346 C 153.842696 103.224614 152.693039 107.007202 150.385114 109.232254 C 148.081494 111.453027 137.626936 115.488074 135.568749 116.228331 C 133.510561 116.968589 124.128155 119.108062 121.742725 122.402851 C 119.352989 125.69336 119.520916 130.549964 113.509976 132.689438 Z M 113.509976 132.689438 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 112.851184 125.778939 C 113.096616 125.697639 116.963252 124.461024 118.776007 127.922691 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,77.647059%,70.980392%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 141.911238 55.202 C 141.911238 55.202 140.171682 58.034663 139.689429 59.759078 C 139.314822 61.05132 138.109189 59.673499 137.471926 62.467651 L 138.505325 62.651646 C 138.091966 63.473204 137.958485 64.863861 137.876674 65.19334 C 137.794863 65.522819 137.213576 67.024729 137.299693 68.15865 C 137.347057 68.740586 138.453655 71.286559 147.427008 67.414113 C 156.396054 63.545946 144.542101 52.56617 141.911238 55.202 Z M 141.911238 55.202 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(77.254902%,57.254902%,0%);fill-opacity:1;stroke-width:1.2611;stroke-linecap:round;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 140.899368 55.561432 C 141.820815 55.069353 148.357067 54.491695 147.267692 67.251513 C 148.524994 67.084634 149.278515 66.922034 150.032035 68.154371 C 150.785556 69.390986 150.703745 70.871502 152.042859 70.871502 C 153.381972 70.871502 153.549899 70.627602 153.885754 69.639165 C 154.221609 68.650728 159.418747 70.790202 160.757861 66.181776 C 160.525346 65.283198 157.571546 63.875424 157.235691 62.22803 C 157.993517 59.925957 156.904142 52.433522 147.353808 52.022743 C 142.406408 51.941443 141.40315 53.999617 140.899368 55.561432 Z M 140.899368 55.561432 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 140.42142 58.115963 C 140.503231 58.197263 143.383832 59.018821 143.711075 60.418036 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 398.800781 183.070312 C 398.800781 183.460938 398.5 183.78125 398.125 183.78125 C 397.753906 183.78125 397.453125 183.460938 397.453125 183.070312 C 397.453125 182.675781 397.753906 182.355469 398.125 182.355469 C 398.5 182.355469 398.800781 182.675781 398.800781 183.070312 Z M 398.800781 183.070312 "
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:round;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 154.380925 61.543399 C 154.380925 61.543399 153.325996 66.618229 157.971988 68.612218 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:round;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 150.940566 62.168125 C 150.940566 62.168125 152.662898 62.245146 152.662898 63.845472 C 152.662898 65.450077 151.24628 65.642629 151.366843 66.930592 C 151.4831 68.222834 153.791026 68.650728 153.868531 69.746139 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:round;stroke-linejoin:round;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 143.401055 52.792954 C 143.47856 52.792954 151.642416 53.730043 151.173081 55.762542 C 150.703745 57.790763 149.416302 56.973484 149.455054 58.727852 C 149.493806 60.486499 152.736097 59.433878 152.736097 59.433878 C 152.736097 59.433878 152.92986 57.949084 154.299114 58.064616 C 155.664062 58.184426 155.039717 59.746241 156.917059 59.707731 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 138.410597 65.458635 L 140.343915 65.39445 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 143.44842 73.695606 C 143.44842 73.695606 144.171799 77.054579 141.450514 76.733658 "
      transform="matrix(0.9072,0,0,0.9129,269.805,126.9225)"
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 0.046875 L 0 21.375 L 257.734375 190.824219 L 290.179688 190.824219 L 290.179688 169.496094 L 32.441406 0.046875 Z M 290.179688 0.046875 L 290.179688 21.375 L 32.441406 190.824219 L 0 190.824219 L 0 169.496094 L 257.734375 0.046875 Z M 290.179688 0.046875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 120.90625 0.046875 L 120.90625 190.824219 L 169.269531 190.824219 L 169.269531 0.046875 Z M 0 63.640625 L 0 127.230469 L 290.179688 127.230469 L 290.179688 63.640625 Z M 0 63.640625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 0 76.359375 L 0 114.515625 L 290.179688 114.515625 L 290.179688 76.359375 Z M 130.582031 0.046875 L 130.582031 190.824219 L 159.597656 190.824219 L 159.597656 0.046875 Z M 130.582031 0.046875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 0 190.824219 L 96.726562 127.230469 L 118.355469 127.230469 L 21.628906 190.824219 Z M 0 0.046875 L 96.726562 63.640625 L 75.097656 63.640625 L 0 14.265625 Z M 171.824219 63.640625 L 268.550781 0.046875 L 290.179688 0.046875 L 193.453125 63.640625 Z M 290.179688 190.824219 L 193.453125 127.230469 L 215.078125 127.230469 L 290.179688 176.605469 Z M 290.179688 190.824219 "
    />
  </g>
</svg>
