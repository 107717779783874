<script>
  import { getContext } from "svelte";
  import {
    getSampleResponseCircleIconColor,
    getSampleResponseNonPdfAttachments,
    getSampleResponseToastStatus,
  } from "apps/sample/helpers";
  import { SampleService } from "apps/sample/services";
  import { sample } from "apps/sample/stores";
  import CircleIcon from "components/icons/CircleIcon.svelte";
  import {
    Box,
    Button,
    Tooltip,
    Toast,
    Options,
    DocumentThumbnails,
  } from "components/shared";
  import documentViewerStore from "stores/document_viewer_store.js";
  import { t } from "stores/i18n.js";
  import { isUserTeamUser } from "stores/user";
  import {
    fileIcon,
    downloadFile,
    distanceOfTime,
    dateTimeFormat,
    fixCarrierWaveUrl,
  } from "lib/helpers.js";

  import styles from "styleguide/SampleResponse.json";

  export let response;

  const config = getContext("config");

  $: isAttachmentsPresent = response.attachments?.length > 1;
  $: filteredAttachments = getSampleResponseNonPdfAttachments(
    response.attachments,
  );
  $: sentDistanceTime = distanceOfTime(
    new Date(response.received_at).getTime() / 1000,
    config.language,
  );

  function handleGetSample() {
    SampleService.getSample({
      params: {
        id: $sample.permalink,
      },
    });
  }

  function downloadPdf(responseUrl) {
    const fixedUrl = fixCarrierWaveUrl(responseUrl);

    downloadFile(
      { url: fixedUrl, filename: `${$sample.contact.company}.pdf` },
      null,
      true,
    );
  }

  function downloadAttachments(response) {
    downloadFile(
      {
        url: `${response.response_url}&as=zip`,
        filename: `${response?.zip_filename}.zip`,
      },
      null,
      true,
    );
  }

  function deleteSampleResponse(responseId) {
    if (confirm($t("sample_response.irrevocably_delete_answers"))) {
      SampleService.deleteSampleResponse({
        params: {
          sample_id: $sample.permalink,
          id: responseId,
        },
        onSuccess: () => {
          handleGetSample();
        },
      });
    }
  }

  function onOpenAttachment(url) {
    if (url) {
      window.open(url, "_blank").focus();
    } else {
      documentViewerStore.openDocument({
        title: file.filename,
        url: file.url,
        id: file.permalink,
        is_archived: file.archived,
        is_read: file.reviewed_as_team_user,
      });
    }
  }
</script>

<Box>
  <div class={styles.wrapper} data-component="SampleResponse">
    <div class={styles.header}>
      <div class={styles.title}>
        {#if response.status !== "unknown"}
          <Tooltip
            message={$t(`sample_response.${response.status}_status_circle`)}
          >
            <CircleIcon
              fill={getSampleResponseCircleIconColor(response.status)}
            />
          </Tooltip>
        {/if}
        <div class={styles["title-type"]}>{$t("sample_response.response")}</div>

        <Tooltip message={dateTimeFormat(response.received_at)}>
          <div class={styles["title-status"]}>
            {$t("sample_response.received")}
            {sentDistanceTime}
          </div>
        </Tooltip>

        <div class={styles["title-status"]}>
          {$t(`sample.via.${response.received_via}`)}
        </div>
      </div>

      {#if $isUserTeamUser}
        <Options
          style="no-border acts-as-button left"
          title={$t("actions.options")}
        >
          {#if response.response_url}
            <Button
              style="blue-text option"
              click={downloadPdf.bind(this, response.response_url)}
              disabled={!response.response_url}
              >{$t("actions.download_pdf")}</Button
            >
          {/if}

          {#if isAttachmentsPresent}
            <Button
              style="blue-text option"
              click={downloadAttachments.bind(this, response)}
              >{$t("sample_response.download_as_zip")}</Button
            >
          {/if}

          <Button
            style="error-text option"
            click={deleteSampleResponse.bind(this, response.permalink)}
            >{$t("sample_response.delete_answer")}</Button
          >
        </Options>
      {/if}
    </div>

    {#each getSampleResponseToastStatus(response) as toast}
      {#if toast.condition}
        <Toast {...toast.props} />
      {/if}
    {/each}

    {#if response.response_url}
      <DocumentThumbnails
        documentUrl={fixCarrierWaveUrl(response.response_url)}
        documentId={response.permalink}
        documentTitle={`${$sample.contact.company}.pdf`}
        numberOfPages={response.number_of_pages}
      />
    {/if}

    {#if isAttachmentsPresent}
      <div class={styles.attachments}>
        {#each filteredAttachments as { created_at, filename, permalink, filesize, url }}
          <div class={styles.attachment} on:click={onOpenAttachment(url)}>
            <div class={styles.details}>
              <img
                class={styles.icon}
                src={filename ? fileIcon(filename) : "file.svg"}
                alt={"svg"}
              />

              <div class={styles.info}>
                <div class={styles.name}>{filename}</div>
                <div class={styles.meta}>
                  {dateTimeFormat(created_at)}
                  {#if filesize}({filesize} MB){/if}
                </div>
              </div>
            </div>
          </div>
        {/each}
      </div>
    {/if}
  </div>
</Box>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 14px 28px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      display: flex;
      align-items: center;
      gap: 14px;
      &-type {
        @include typography-headline-semibold;
      }

      &-status {
        @include typography-footnote-medium;
        color: var(--primary-200);
        &--green {
          @include typography-semibold;
          color: var(--green-100);
        }
        &--yellow {
          @include typography-semibold;
          color: var(--orange-400);
        }
        &--orange {
          @include typography-semibold;
          color: var(--orange-100);
        }
      }
    }
  }

  .attachments {
    margin-top: 20px;
  }

  .attachment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .details {
    display: flex;
    align-items: center;
  }

  .icon,
  .info {
    margin-left: 10px;
  }

  .name {
    font-weight: 500;
  }
  .meta {
    font-size: 12px;
    font-weight: 500;
    color: var(--primary-300);
  }
</style>
