<script>
  import { onMount } from "svelte";
  import { ConfirmationSignerSignatureItem } from "apps/confirmationSigner";
  import { step, mailings } from "stores/confirmation/confirmation_signer.js";
  import UploadApiConstructor from "apis/upload.js";

  let current = 0;
  let UploadApi = new UploadApiConstructor();

  $: list = $mailings.map((item) => {
    return {
      ...item,
      signed: false,
    };
  });

  function makeActive(index) {
    current = index;
  }

  function goNext(index) {
    list[index].signed = true;
    let newIndex = list.findIndex((item) => !item.signed);

    if (newIndex > -1) {
      current = newIndex;
    } else {
      step.set("success");
    }
  }

  onMount(async () => {
    UploadApi.setupUppy();
  });
</script>

<div data-component="ConfirmationSignerSignature">
  <div>
    {#each list as item, index}
      <ConfirmationSignerSignatureItem
        {index}
        {goNext}
        {...item}
        {makeActive}
        single={list.length === 1}
        active={current === index}
      />
    {/each}
  </div>
</div>

<style lang="scss">
</style>
