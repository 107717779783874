<script>
  import ArrowLeftIcon from "components/icons/ArrowLeftIcon.svelte";
  import WebIcon from "components/icons/WebIcon.svelte";
  import { t } from "stores/i18n";
  import { imagePath } from "lib/helpers";

  import styles from "styleguide/WhitelabelUpsell.json";
</script>

<div class={styles.wrapper} data-component="WhitelabelUpsell">
  <div class={styles.item}>
    <div>{$t("whitelabelUpsell.description")}</div>
  </div>
  <div class={styles.item}>
    <div class={styles["sub-title"]}>{$t("whitelabelUpsell.domain.title")}</div>
    <div>{$t("whitelabelUpsell.domain.description")}</div>
    <div class={styles["custom-domain"]}>
      <div class={styles["site-item"]}>
        <WebIcon fill="var(--primary-500)" />
        <div class={styles["site-item-text"]}>https://app.auditi.de</div>
      </div>
      <div class={styles["site-item"]}>
        <ArrowLeftIcon fill="var(--primary-500)" width={44} height={44} />
      </div>
      <div class={styles["site-item"]}>
        <WebIcon fill="var(--primary-500)" />
        <div class={styles["site-item-text"]}>https://dataspace.company.de</div>
      </div>
    </div>
  </div>
  <div class={styles.item}>
    <div class={styles["sub-title"]}>
      {$t("whitelabelUpsell.preview.title")}
    </div>
    <div>{$t("whitelabelUpsell.preview.description")}</div>
    <div class={styles.images}>
      <div class={styles["image-item"]}>
        <img src={imagePath("standard-login-page")} alt="standard-login-page" />
        <div class={styles["helper-text"]}>
          {$t("whitelabelUpsell.preview.standard")}
        </div>
      </div>
      <div class={styles["image-item"]}>
        <img
          src={imagePath("example-for-branded-login-page")}
          alt="example-for-branded-login-page"
        />
        <div class={styles["helper-text"]}>
          {$t("whitelabelUpsell.preview.branded")}
        </div>
      </div>
    </div>
  </div>
  <div class={styles.item}>
    <div class={styles["sub-title"]}>{$t("whitelabelUpsell.uniq.title")}</div>
    <div>{$t("whitelabelUpsell.uniq.description")}</div>
    <div class={styles.images}>
      <div class={styles["image-item"]}>
        <img src={imagePath("standard-experience")} alt="standard-experience" />
        <div class={styles["helper-text"]}>
          {$t("whitelabelUpsell.uniq.standard")}
        </div>
      </div>
      <div class={styles["image-item"]}>
        <img
          src={imagePath("example-of-branded-experience")}
          alt="example-of-branded-experience"
        />
        <div class={styles["helper-text"]}>
          {$t("whitelabelUpsell.uniq.branded")}
        </div>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  .wrapper {
    margin-bottom: 32px;
  }

  .item {
    margin-bottom: 24px;
  }

  .sub-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 8px;
    color: var(--primary-500);
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 16px;
    color: var(--primary-500);
  }

  .custom-domain {
    display: flex;
    gap: 16px;
    margin-top: 16px;
  }

  .site-item {
    display: flex;
    background: var(--blue-010);
    border-radius: 100px;
    padding: 18px 24px 18px 24px;
    flex: 1 1 100%;
    align-items: center;
    color: var(--primary-500);

    &:nth-child(2) {
      flex: 1 1 auto;
      padding: 8px;
    }

    .site-item-text {
      margin-left: 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.5;
    }
  }

  .images {
    display: flex;
    gap: 24px;
    margin-top: 16px;
    .image-item {
      flex: 0 0 calc(50% - 16px);

      img {
        width: 100%;
      }
    }
    .helper-text {
      font-size: 12px;
      line-height: 1.5;
      color: var(--primary-300);
      margin-top: 8px;
    }
  }
</style>
