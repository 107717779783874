<script>
  export let key;
  export let event;

  import { onMount, onDestroy } from "svelte";

  function handler(e) {
    let hasOwnEvents = e.target.dataset && e.target.dataset.keyboard;

    if (e.keyCode === key && event && !hasOwnEvents) {
      event();
    }
  }

  onMount(async () => {
    document.addEventListener("keyup", handler);
  });

  onDestroy(async () => {
    document.removeEventListener("keyup", handler);
  });
</script>
