<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(100%,100%,100%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,21.960784%,59.215686%);fill-opacity:1;"
      d="M 1.488281 140.003906 L 140.910156 140.003906 L 140.910156 0 L 220.585938 0 L 220.585938 140.003906 L 499.425781 140.003906 L 499.425781 220.003906 L 220.585938 220.003906 L 220.585938 360.007812 L 140.910156 360.007812 L 140.910156 220.003906 L 1.488281 220.003906 Z M 1.488281 140.003906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(84.313725%,15.686275%,15.686275%);fill-opacity:1;"
      d="M 1.488281 159.996094 L 160.824219 159.996094 L 160.824219 0 L 200.660156 0 L 200.660156 159.996094 L 499.421875 159.996094 L 499.421875 199.996094 L 200.660156 199.996094 L 200.660156 359.996094 L 160.824219 359.996094 L 160.824219 199.996094 L 1.488281 199.996094 Z M 1.488281 159.996094 "
    />
  </g>
</svg>
