<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,77.647059%,12.941176%);fill-opacity:1;"
      d="M -124.226562 2.460938 L 605.546875 2.460938 L 605.546875 352.617188 L -124.226562 352.617188 Z M -124.226562 2.460938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,12.941176%,9.411765%);fill-opacity:1;"
      d="M -125.640625 240.820312 L 605.117188 240.820312 L 605.117188 360 L -125.640625 360 Z M -125.640625 240.820312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(16.078431%,54.901961%,3.137255%);fill-opacity:1;"
      d="M -124.226562 0 L 605.617188 0 L 605.617188 126.5625 L -124.226562 126.5625 Z M -124.226562 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,41.960784%,77.647059%);fill-opacity:1;"
      d="M 303.191406 185.816406 C 303.191406 144.011719 269.304688 110.125 227.5 110.125 C 185.695312 110.125 151.808594 144.011719 151.808594 185.816406 C 151.808594 227.621094 185.695312 261.507812 227.5 261.507812 C 269.304688 261.507812 303.191406 227.621094 303.191406 185.816406 Z M 303.191406 185.816406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,77.647059%,12.941176%);fill-opacity:1;"
      d="M 193.753906 132.328125 L 189.230469 135.507812 L 205.75 158.726562 L 210.15625 155.914062 Z M 214.433594 187.132812 L 207.640625 182.394531 L 210.449219 173.480469 L 176.613281 173.953125 L 166.816406 166.457031 L 213.007812 165.957031 L 221.574219 140.007812 L 226.175781 150.5 Z M 268.238281 137.433594 L 263.839844 134.085938 L 246.699219 156.847656 L 250.710938 160.199219 Z M 222.246094 173.707031 L 224.707031 165.800781 L 234.050781 165.785156 L 223.378906 133.671875 L 227.566406 122.070312 L 241.996094 165.949219 L 269.324219 166.273438 L 260.710938 173.832031 Z M 286 209.683594 L 287.835938 204.46875 L 260.910156 195.144531 L 258.953125 199.988281 Z M 237.359375 177.046875 L 245.636719 176.960938 L 248.523438 185.851562 L 275.808594 165.832031 L 288.132812 166.257812 L 250.804688 193.464844 L 258.886719 219.570312 L 249.050781 213.695312 L 237.355469 177.046875 Z M 223.472656 248.820312 L 229 248.902344 L 229.253906 220.410156 L 224.039062 220.097656 Z M 238.902344 192.316406 L 241.621094 200.136719 L 234.113281 205.703125 L 261.785156 225.183594 L 265.3125 237.003906 L 227.625 210.292969 L 205.457031 226.273438 L 207.894531 215.078125 L 238.902344 192.3125 Z M 166.289062 201.445312 L 167.925781 206.726562 L 195.097656 198.144531 L 193.777344 193.089844 Z M 224.796875 198.617188 L 218.199219 203.625 L 210.582031 198.214844 L 200.628906 230.554688 L 190.480469 237.570312 L 204.210938 193.464844 L 182.148438 177.335938 L 193.550781 176.183594 Z M 224.796875 198.617188 "
    />
  </g>
</svg>
