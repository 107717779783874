<script>
  import { ConfirmationNewBlock } from "components/confirmationNew";
  import { activeStore } from "stores/dashboard/create_confirmation.js";
  import { t } from "stores/i18n.js";
  import { theme } from "stores/theme.js";

  import ClientsApi from "apis/users/clients.js";
  import { validate } from "lib/validate.js";

  import { Dropdown, Search, TextInput } from "components";
  import styles from "styleguide/ConfirmationNewClient.json";

  export let active;
  export let valide;
  export let dirty;
  export let clientLock = false;
  export let clientShow = true;

  const { client, title, senderAddress, billingData } = $activeStore;

  $: titleErrors = validate($title, ["required"]);
  $: clientErrors = clientLock ? [] : validate($client.id, ["required"]);
  $: orderNumberErrors = validate(
    $billingData.order_number,
    ["required", "numeric", "length"],
    { min: 1, max: 6 },
  );
  $: partNumberErrors = validate($billingData.part_number, ["numeric"]);
  $: costErrors = validate(
    $billingData.cost,
    ["required", "numeric", "length"],
    { min: 8, max: 8 },
  );
  $: valide =
    !titleErrors.length &&
    !clientErrors.length &&
    ($theme == "ebde"
      ? !orderNumberErrors.length &&
        !partNumberErrors.length &&
        !costErrors.length
      : true);

  let creationYears = Array.from({ length: 6 }, (v, k) => k + 2019).reduce(
    (o, v) => {
      o[v] = v;
      return o;
    },
    {},
  );

  function modifySearchResults(users) {
    return users.map((item) => {
      const user = {
        label: item.text,
        value: item.id,
      };

      return user;
    });
  }

  function getSelected(id, data) {
    client.set(data.find((item) => item.id === id));
  }
</script>

<ConfirmationNewBlock
  {active}
  edit={() => {
    active = true;
  }}
>
  <div class={styles.wrapper} data-component="ConfirmationNewClient">
    {#if clientShow}
      {#if active}
        <Search
          icon={false}
          clear={false}
          bind:value={$client.text}
          searchApi={ClientsApi}
          getOrigin={getSelected}
          {modifySearchResults}
          lock={clientLock}
          errors={dirty ? clientErrors : []}
          style="border medium round-border fullsize"
          label={$t("confirmation.settings.client")}
          placeholder={$t("confirmation_new_client.client_placeholder")}
        />
      {:else}
        <div>
          <div class={styles.subtitle}>
            {$t("confirmation.settings.client")}
          </div>
          <div>{$client.text}</div>
        </div>
      {/if}
    {/if}

    {#if active}
      <TextInput
        bind:value={$title}
        errors={dirty ? titleErrors : []}
        style="border medium round-border fullsize"
        label={$t("confirmation_new_client.title_label")}
        placeholder={$t("confirmation_new_client.title_label")}
        footnotes={[$t("confirmation_new_client.title_footnote")]}
      />
    {:else}
      <div>
        <div class={styles.subtitle}>
          {$t("confirmation_new_client.title_label")}
        </div>
        <div>{$title}</div>
      </div>
    {/if}

    {#if active}
      <TextInput
        optional={true}
        bind:value={$senderAddress}
        style="border medium round-border fullsize"
        label={$t("confirmation_new_client.sender_address_label")}
        placeholder={$t("confirmation_new_client.sender_address_label")}
        footnotes={[$t("confirmation_new_client.sender_address_footnote")]}
      />
    {:else if $senderAddress}
      <div>
        <div class={styles.subtitle}>
          {$t("confirmation_new_client.sender_address_label")}
        </div>
        <div>{$senderAddress}</div>
      </div>
    {/if}

    {#if $theme === "ebde"}
      {#if active}
        <Dropdown
          items={creationYears}
          initial={2019}
          bind:selected={$billingData.order_year}
          label={$t("confirmation.settings.order_year")}
        />
      {:else}
        <div>
          <div class={styles.subtitle}>
            {$t("confirmation.settings.order_year")}
          </div>
          <div>{$billingData.order_year}</div>
        </div>
      {/if}

      {#if active}
        <TextInput
          maxlength={6}
          bind:value={$billingData.order_number}
          label={$t("confirmation.settings.order_number")}
          footnotes={[$t("confirmation_new_client.ebde_order_number_footnote")]}
          errors={dirty ? orderNumberErrors : []}
        />
      {:else}
        <div>
          <div class={styles.subtitle}>
            {$t("confirmation.settings.order_number")}
          </div>
          <div>{$billingData.order_number}</div>
        </div>
      {/if}

      {#if active}
        <TextInput
          maxlength={2}
          optional={true}
          bind:value={$billingData.part_number}
          label={$t("confirmation.settings.part_number")}
          footnotes={[$t("confirmation_new_client.ebde_part_number_footnote")]}
          errors={dirty ? partNumberErrors : []}
        />
      {:else}
        <div>
          <div class={styles.subtitle}>
            {$t("confirmation.settings.part_number")}
          </div>
          <div>{$billingData.part_number}</div>
        </div>
      {/if}

      {#if active}
        <TextInput
          maxlength={8}
          bind:value={$billingData.cost}
          label={$t("confirmation.settings.cost")}
          footnotes={[$t("confirmation_new_client.ebde_cost_footnote")]}
          errors={dirty ? costErrors : []}
        />
      {:else}
        <div>
          <div class={styles.subtitle}>{$t("confirmation.settings.cost")}</div>
          <div>{$billingData.cost}</div>
        </div>
      {/if}
    {/if}
  </div>
</ConfirmationNewBlock>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .subtitle {
    font-size: 12px;
    line-height: 18px;
    color: var(--primary-300);
  }
</style>
