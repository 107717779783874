import Appsignal from "@appsignal/javascript";

let key = "";
if (typeof API_SIGNAL_KEY !== "undefined") {
  key = API_SIGNAL_KEY;
}
let revision = "";
if (typeof APP_VERSION !== "undefined") {
  revision = APP_VERSION;
}
const api = new Appsignal({ key, revision });

export default function (error, context) {
  if (API_SIGNAL_KEY) {
    api.sendError(error, context);
  }
}
