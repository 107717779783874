<script>
  import styles from "styleguide/ControlGroupNew.json";
  import { t } from "stores/i18n.js";
  import ControlGroupApi from "apis/pbc/control_groups.js";
  import { Box, TextInput } from "components";

  function onSubmit(result) {
    if (result) {
      ControlGroupApi.create({ name: result });
    }
  }
</script>

<div data-component="ControlGroupNew">
  <Box style="margin-top">
    <div class={styles.wrapper}>
      <TextInput
        button={true}
        onEnter={onSubmit}
        onIconClick={onSubmit}
        clearOnEnter={true}
        style="no-border big"
        placeholder={$t("control_group_new.add_control_group")}
      />
    </div>
  </Box>
</div>

<style lang="scss">
  .wrapper {
    padding: 0 9px;
  }
</style>
