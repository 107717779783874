<script>
  import { auditActivities } from "stores/pbc.js";
  import auditApi from "apis/pbc/audit.js";
  import { Activities } from "components";
</script>

<div data-component="AuditSidebarActivity">
  <Activities
    style="max-height"
    activitiesStore={auditActivities}
    getActivities={(page) => {
      auditApi.getActivities(page);
    }}
  />
</div>
