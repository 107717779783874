<script>
  import { setContext } from "svelte";

  import {
    DashboardIndex,
    DashboardProjectEdit,
    DashboardProjectShow,
    DashboardTwoFaActivationBanner,
  } from "apps/dashboard";

  import { App } from "components";
  import styles from "styleguide/Dashboard.json";

  export let appConfig;
  export let initialState;

  const components = {
    DashboardIndex,
    DashboardProjectShow,
    DashboardProjectEdit,
  };

  setContext(
    "available_confirmation_types",
    initialState.available_confirmation_types,
  );
</script>

<App {...appConfig}>
  <DashboardTwoFaActivationBanner
    two_fa_enforcement_date={initialState.two_fa_enforcement_date}
  />
  <div class={styles.wrapper}>
    <svelte:component
      this={components[initialState.component]}
      {initialState}
    />
  </div>
</App>

<style global lang="scss">
  .wrapper {
    padding: 40px 0;
  }
</style>
