<script>
  import styles from "styleguide/DashboardProjectArchive.json";
  import { t } from "stores/i18n.js";
  import {
    project,
    refreshProject,
    shownArchiveModal,
  } from "stores/dashboard/project.js";
  import { can } from "stores/permission.js";

  import Projects from "apis/dashboard/projects.js";

  import { Toast } from "components";

  function archive(id, action) {
    $shownArchiveModal = false;

    let formData = new FormData();

    formData.append("id", id);
    formData.append("archived", action);

    Projects.archive(
      {
        params: formData,
        success: archiveSuccess,
        error: archiveError,
      },
      id,
    );
  }

  function archiveSuccess() {
    refreshProject();
  }

  function archiveError() {
    alert("Projects archive Error");
  }
</script>

{#if $can("archive", "project")}
  {#if $project.status === "active"}
    <div class={styles.infobox}>
      <Toast
        type="error"
        title={$t("dashboard_project_archive.deactivate_title")}
        message={$t("dashboard_project_archive.deactivate_description")}
        action={{
          style: "error",
          click: archive.bind(this, $project.permalink, true),
          button: $t("dashboard_project_archive.deactivate_action"),
        }}
      />
    </div>
  {:else}
    <div class={styles.infobox}>
      <Toast
        type="info"
        title={$t("dashboard_project_archive.activate_title")}
        message={$t("dashboard_project_archive.activate_description")}
        action={{
          style: "primary",
          click: archive.bind(this, $project.permalink, false),
          button: $t("dashboard_project_archive.activate_action"),
        }}
      />
    </div>
  {/if}
{/if}

<style lang="scss">
  .control {
    margin-bottom: 30px;
  }

  .confirmation {
    display: flex;
  }

  .input {
    width: 150px;
    margin-right: 12px;
  }
</style>
