<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 0 0 L 160 0 L 160 360 L 0 360 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,85.098039%,4.705882%);fill-opacity:1;"
      d="M 160 0 L 320.003906 0 L 320.003906 360 L 160 360 Z M 160 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,9.411765%,18.823529%);fill-opacity:1;"
      d="M 320.003906 0 L 480.003906 0 L 480.003906 360 L 320.003906 360 Z M 320.003906 0 "
    />
  </g>
</svg>
