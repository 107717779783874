<script>
  export let width = 18;
  export let height = 18;
  export let fill = "var(--blue-100)";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 18 18"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M11.8191,10.6671 C13.9469375,10.6671 15.6839203,12.338886 15.787238,14.4408213 L15.7921,14.6391 L15.7921,16.2501 C15.7921,16.6643136 15.4563136,17.0001 15.0421,17.0001 C14.6624042,17.0001 14.348609,16.7179461 14.2989466,16.3518706 L14.2921,16.2501 L14.2921,14.6391 C14.2921,13.3281972 13.2719342,12.2560326 11.981728,12.1723569 L11.8191,12.1671 L6.1811,12.1671 C4.86969275,12.1671 3.79707062,13.1868286 3.7133591,14.4765348 L3.7081,14.6391 L3.7081,16.2501 C3.7081,16.6643136 3.37231356,17.0001 2.9581,17.0001 C2.57840423,17.0001 2.26460904,16.7179461 2.21494662,16.3518706 L2.2081,16.2501 L2.2081,14.6391 C2.2081,12.5114929 3.88060285,10.775234 5.98279824,10.6719599 L6.1811,10.6671 L11.8191,10.6671 Z M9.0001,1.0001 C11.193623,1.0001 12.9721,2.77819578 12.9721,4.9721 C12.9721,7.16600422 11.193623,8.9441 9.0001,8.9441 C6.80645812,8.9441 5.0271,7.16588567 5.0271,4.9721 C5.0271,2.77831433 6.80645812,1.0001 9.0001,1.0001 Z M9.0001,2.5001 C7.63466941,2.5001 6.5271,3.60695745 6.5271,4.9721 C6.5271,6.33724255 7.63466941,7.4441 9.0001,7.4441 C10.3652678,7.4441 11.4721,6.3375051 11.4721,4.9721 C11.4721,3.6066949 10.3652678,2.5001 9.0001,2.5001 Z"
  />
</svg>
