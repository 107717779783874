<script>
  import { onMount } from "svelte";

  import { t } from "stores/i18n.js";
  import { currentUser } from "stores/quickshare.js";

  import SignupApi from "apis/quickshare/signup_api.js";

  import { LegacyButton as Button } from "components";
  import styles from "styleguide/QuickshareIntro.json";

  let errorMessage;
  let success = false;
  let token;

  function onSignup() {
    SignupApi.signup({
      success: (response) => onSignupSuccess(response),
      error: (response) => (errorMessage = response.message.details),
    });
  }

  function onSignupSuccess(response) {
    token = response["token"];
    success = true;
  }

  function onContinue() {
    currentUser.set(Object.assign({}, $currentUser, { token: token }));
  }

  onMount(async () => {
    // console.log("Loading Intro", introAnimation)
    // player.load(introAnimation)
  });
</script>

<div data-component="QuickshareIntro" class={styles.wrapper}>
  {#if errorMessage}
    <div class={styles.error}>{errorMessage}</div>
  {:else if success}
    <div class={styles.success}>
      <div class={styles.headline}>{$t("quickshare_intro.headline")}</div>
      <ul class={styles.bullets}>
        <li>{$t("quickshare_intro.bullet_1")}</li>
        <li>{$t("quickshare_intro.bullet_2")}</li>
        <li>{$t("quickshare_intro.bullet_3")}</li>
        <li>{$t("quickshare_intro.bullet_4")}</li>
        <li>{$t("quickshare_intro.bullet_5")}</li>
      </ul>
      <Button style="primary" click={onContinue}
        >{$t("quickshare_intro.accept")}</Button
      >
    </div>
  {:else}
    <div class={styles.animation}>
      <lottie-player
        src="/quickshare/intro.json"
        background="transparent"
        speed="1"
        loop="true"
        autoplay="true"
      ></lottie-player>
    </div>
    <div class={styles.intro}>
      <div class={styles.headline}>{$t("quickshare_intro.intro_headline")}</div>
      <div class={styles.subline}>{$t("quickshare_intro.intro_subline")}</div>
      <Button style="primary" click={onSignup}
        >{$t("quickshare_intro.intro_button")}</Button
      >
    </div>
  {/if}
</div>

<style lang="scss">
  .animation {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
  }

  .wrapper {
    flex: 1;
    display: flex;
    height: 100%;
    padding: 30px;
    align-items: flex-end;
  }

  .headline {
    font-size: 25px;
    line-height: 32px;
    font-weight: 700;
  }

  .subline {
    font-size: 16px;
    margin: 15px 0 30px 0;
    color: var(--primary-400);
  }

  .error {
    width: 100%;
    background: var(--red-100);
    color: #fff;
    font-weight: 600;
    border-radius: var(--border-radius);
    text-align: center;
    align-self: center;
    padding: 15px;
  }

  .success {
    width: 100%;
    border-radius: var(--border-radius);
    text-align: left;
    align-self: flex-start;
    padding: 10px 15px;
  }

  .bullets {
    list-style-type: square;
    list-style-position: outside;
    margin: 0px 0 30px 20px;
    padding: 0;

    li {
      margin-bottom: 10px;
    }
  }
</style>
