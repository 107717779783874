import { SAMPLE_STATUS } from "apps/sample/constants";

export function getSampleStatusTagValue(sample) {
  const { sample_mailings, letterhead_presence, status } = sample;

  const lastMailing = sample_mailings[0];
  const mailingCount = sample_mailings.length;

  switch (status) {
    case 0:
      return SAMPLE_STATUS.notMarkedForShipping;

    case 1:
      if (sample_mailings.some((mailing) => !mailing.address_complete)) {
        return SAMPLE_STATUS.checkAddress;
      }
      if (mailingCount === 0) {
        return SAMPLE_STATUS.waiting;
      }
      if (!letterhead_presence) {
        return SAMPLE_STATUS.waitingLetterhead;
      }
      if (lastMailing?.writing) {
        return SAMPLE_STATUS.creating;
      }
      if (lastMailing?.confirmed === false) {
        return SAMPLE_STATUS.waitingConfirmation;
      }
      return SAMPLE_STATUS.sending;

    case 2:
      if (lastMailing?.bounced || lastMailing?.returned) {
        return SAMPLE_STATUS.returned;
      }
      return mailingCount === 1 ? SAMPLE_STATUS.sent : SAMPLE_STATUS.reminder;

    case 3:
      return SAMPLE_STATUS.newResponse;

    case 4:
      return SAMPLE_STATUS.balanceConfirmed;

    case 5:
      return SAMPLE_STATUS.differenceIsNotSignificant;

    case 6:
      return SAMPLE_STATUS.differenceIsExplained;

    case 7:
      return SAMPLE_STATUS.differenceIsNotExplained;

    case 8:
      return SAMPLE_STATUS.alternativelyReviewed;

    default:
      return null;
  }
}
