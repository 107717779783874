import { Controller } from "stimulus";
import Player from "@vimeo/player";

export default class extends Controller {
  static targets = [];

  connect() {
    this.helpIframe = null;
    this.helpPlayer = null;
    this.initializeVideo();
  }

  pad(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }

  initializeVideo() {
    this.helpIframe = document.querySelector("iframe");
    this.helpPlayer = new Player(this.helpIframe);

    $("#help-video-navigation a").each(
      function () {
        var time = $(this).data("seek");
        var minutes = Math.floor(time / 60);
        var seconds = Math.floor(time - minutes * 60);
        var output =
          this.pad(minutes, "0", 2) + ":" + this.pad(seconds, "0", 2);

        $(this).find("b").html(output);
      }.bind(this),
    );
  }

  seek(event) {
    event.preventDefault();
    this.helpPlayer.setCurrentTime($(event.target).data("seek"));
  }
}
