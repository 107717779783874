<script>
  import styles from "styleguide/AdminstrationSection.json";

  export let title;
  export let description;
  export let style = "";

  $: calculatedStyles = function () {
    let s = style.split(" ");
    s.push("section");
    return s.map((x) => styles[x]).join(" ");
  };
</script>

<div data-component="AdminstrationSection" class={calculatedStyles()}>
  {#if title}
    <div class={styles.header}>{title}</div>
  {/if}
  {#if description}
    <div class={styles.description}>{description}</div>
  {/if}
  <div class={styles.content}>
    <slot />
  </div>
</div>

<style lang="scss">
  .section {
    padding: 24px 0px;
  }
  .border {
    border-bottom: 1px solid #eaecf1;
  }

  .header {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-500);
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: var(--primary-500);
    padding: 8px 0px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
</style>
