<script>
  import { onDestroy, afterUpdate } from "svelte";
  import {
    clearAllBodyScrollLocks,
    disableBodyScroll,
  } from "body-scroll-lock-upgrade";
  import clsx from "clsx";
  import ErrorFilledIcon from "components/icons/ErrorFilledIcon.svelte";
  import { updateModalsContent } from "stores/modals";

  import { Boundary, IconButton, KeyEvent } from "components";
  import styles from "styleguide/Modal.json";

  export let close = true;
  export let title = "";
  export let subtitle = "";
  export let shown = false;
  export let onHide;
  export let onShow;
  export let canHide = true;
  export let style = "";
  export let isWide = false;
  export let isFullHeight = false;
  export let isScrollingInsideModal = true;
  export let storageModalKey;
  export let storageModalContent;
  export let hasUnsavedChanges = false;
  export let coverTop = true;
  export let destroy = () => {};

  let bodyId = crypto.randomUUID();
  let wrapperId = crypto.randomUUID();

  let targetElement;

  afterUpdate(() => {
    targetElement = isScrollingInsideModal
      ? document.getElementById(`${bodyId}`)
      : document.getElementById(`${wrapperId}`);
    if (shown && targetElement) {
      disableBodyScroll(targetElement);
    } else {
      clearAllBodyScrollLocks();
    }
  });

  export function show() {
    shown = true;
    if (onShow) {
      onShow();
    }
  }

  export function hide() {
    if (hasUnsavedChanges) {
      saveChangesToLocalStorage();
      return;
    }

    if (canHide) {
      shown = false;
      if (onHide) {
        onHide();
      }
    }
  }

  export function onClose() {
    if (hasUnsavedChanges) {
      saveChangesToLocalStorage();
      return;
    }

    if (onHide) {
      onHide();
    }
  }

  $: {
    if (shown) {
      if (onShow) {
        onShow();
      }
    }
  }

  onDestroy(() => {
    clearAllBodyScrollLocks();
    destroy();
  });

  function saveChangesToLocalStorage() {
    shown = false;
    hasUnsavedChanges = false;

    updateModalsContent([
      {
        key: storageModalKey,
        state: storageModalContent,
      },
    ]);
  }
</script>

<Boundary>
  {#if shown}
    <div
      data-component="Modal"
      class={clsx(
        styles.modal,
        style.split(" ").map((x) => styles[x]),
        { [styles["with-footer"]]: $$slots.bottom },
        { [styles["scroll-inside"]]: isScrollingInsideModal },
        { [styles["wide"]]: isWide },
        { [styles["full-height"]]: isFullHeight },
      )}
    >
      <div id={wrapperId} class={styles.wrapper}>
        <div class={styles.backdrop} on:click={hide} role={"button"} />
        <div class={styles.box}>
          {#if $$slots.sidebar}
            <div class={styles.sidebar}>
              <slot name="sidebar" />
            </div>
          {/if}
          <div class={styles.content}>
            {#if title || $$slots.header}
              <div class={styles.header}>
                {#if $$slots.header}
                  <slot name="header" />
                {:else}
                  {#if style.includes("error")}
                    <div class={styles["error-icon"]}>
                      <ErrorFilledIcon fill="var(--red-100)" />
                    </div>
                  {/if}
                  <div class={styles.title}>
                    {title}
                  </div>
                {/if}
                {#if close}
                  <IconButton
                    close={true}
                    click={onClose}
                    style="primary-text"
                  />
                {/if}
              </div>
            {/if}
            <div id={bodyId} class={styles.body}>
              {#if coverTop}
                <div class={styles["cover-top"]} />
              {/if}
              <div>
                {#if subtitle}
                  <div class={styles.subtitle}>{subtitle}</div>
                {/if}
                <slot />
              </div>
              {#if $$slots.bottom}
                <div class={styles["cover-bottom"]} />
              {/if}
            </div>
            {#if $$slots.bottom}
              <div class={styles.bottom}>
                <slot name="bottom" />
              </div>
            {/if}
          </div>
        </div>
      </div>
    </div>
    <KeyEvent key={27} event={hide} />
  {/if}
</Boundary>

<style lang="scss">
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: var(--z-index-modal);
    overflow: hidden;
    display: flex;

    &.error {
      .box:before {
        content: "";
        display: block;
        border-radius: 8px 8px 0 0;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 5px;
        background-color: var(--red-100);
      }

      .header {
        margin-top: 5px;
      }
    }
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparentize(#142a4e, 0.5);
    z-index: 2;
    overflow: auto;
  }

  .wrapper {
    position: absolute;
    padding: 80px 0;
    top: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    z-index: 2;
    max-height: 100vh;
    -webkit-overflow-scrolling: touch;

    .full-height & {
      min-height: 100vh;

      .body {
        min-height: calc(100vh - 242px);
      }
    }

    .full-height.with-footer & {
      min-height: 100vh;

      .body {
        min-height: calc(100vh - 314px);
      }
    }

    .scroll-inside & {
      overflow-y: visible;
      -webkit-overflow-scrolling: unset;

      .body {
        max-height: calc(100vh - 242px);
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
      }
    }

    .scroll-inside.with-footer & {
      .body {
        max-height: calc(100vh - 314px);
      }
    }
  }

  .box {
    display: flex;
    background: #ffffff;
    border-radius: var(--border-radius);
    width: 664px;
    z-index: 3;
    pointer-events: all;
    position: relative;

    .wide & {
      width: 1000px;
    }
  }

  .content {
    width: 100%;
  }

  .cover-top,
  .cover-bottom {
    position: absolute;
    width: 100%;
    height: 12px;
    left: 0;
    z-index: 1;
    background: #ffffff;
    display: block;
  }

  .cover-top {
    top: 0;
  }

  .cover-bottom {
    bottom: -24px;
    position: relative;
    margin: -24px;
    width: calc(100% + 48px);
    z-index: 2;

    .no-padding & {
      margin: 0;
      width: 100%;
      bottom: 0;
    }
  }

  .sidebar {
    width: 220px;
    padding: 16px 24px;
    border-right: 1px solid var(--primary-050);
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    gap: 1em;
    align-items: center;
    box-shadow:
      0 2px 4px 0 rgba(24, 39, 75, 0.08),
      1px 6px 8px 0 rgba(24, 39, 75, 0.07),
      0 7px 10px 0 rgba(24, 39, 75, 0.04);
    position: relative;
    z-index: 1;

    .no-header-border & {
      border-bottom: none;
    }
  }

  .close {
    height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .error-icon {
    margin-top: 4px;
  }

  .title {
    flex: 1;
    font-size: 18px;
    line-height: 30px;
    font-weight: 600;
  }

  .subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.25;
    margin-top: 0;
    margin-bottom: 16px;
    color: var(--primary-300);
  }

  .body {
    padding: 12px 24px;
    position: relative;
    display: grid;
    grid-auto-rows: 1fr auto;

    .tiny &,
    .mobile & {
      padding: 10px 15px;
    }

    .no-padding & {
      padding: 0;
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px 24px;
    background: #ffffff;
    border-radius: 0 0 8px 8px;
    box-shadow:
      0 -2px 4px 0 rgba(24, 39, 75, 0.08),
      -1px -6px 8px 0 rgba(24, 39, 75, 0.07),
      0 -7px 10px 0 rgba(24, 39, 75, 0.04);
    position: relative;
    z-index: 1;

    .bottom-between & {
      justify-content: space-between;
    }
  }

  //start fix for strange iOS Safari Modal touch event
  @supports (-webkit-touch-callout: none) {
    .wrapper {
      transform: translateX(50%);
      left: -50%;
      width: 100%;

      .box {
        margin: auto;
      }
    }
  }

  @supports not (-webkit-touch-callout: none) {
    .wrapper {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: center;
    }
  }

  //end fix for strange iOS Safari Modal touch event
</style>
