<script>
  import { LegacyButton as Button } from "components";
</script>

<header data-component="Topbar">
  <div class="left">
    <img src="/images/auditi.png" class="auditi" />
    <div class="account">Muster Wirtschaftsprüfer &amp; Partner</div>
  </div>
  <div class="right">
    <Button style="border">Verwaltung</Button>
  </div>
</header>

<style lang="scss">
  header {
    display: flex;
    align-items: center;
    height: 80px;
    background: #fff;
    padding: 0 var(--page-gutter);
  }

  .left {
    flex: 1;
  }

  .right {
    display: flex;
  }

  .auditi {
    height: 14px;
    margin-bottom: 4px;
  }

  .account {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  }
</style>
