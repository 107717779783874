import { writable, derived, get } from "svelte/store";
import CommentsApi from "apis/comments/comments.js";
import ConfirmationItemsApi from "apis/confirmation/confirmation_items.js";
import ConfirmationAttachmentsApi from "apis/confirmation/confirmation_attachments.js";

const itemId = writable(null);
const confirmationId = writable(null);
const item = writable({ confirmation_signers: [] });
const letterhead = writable({});
const comments = writable([]);
// const commentsCount = writable(0)
const activities = writable([]);
const activeEditListItem = writable(false);
const statusesPossibleDelete = ["imported"];
const confirmationAttachments = writable([]);

item.subscribe((value) => {
  if (value.permalink) {
    itemId.set(value.permalink);
    confirmationId.set(value.confirmation_permalink);
  }
});

const entries = derived([item], ([$item], set) => {
  let mailings = $item.confirmation_mailings ? $item.confirmation_mailings : [];
  mailings.forEach((entry) => (entry.type = "mailings"));
  let responses = $item.confirmation_responses
    ? $item.confirmation_responses
    : [];
  responses.forEach((entry) => (entry.type = "responses"));
  let newEntries = [...mailings, ...responses].sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });
  set(newEntries);
});

function getItem() {
  ConfirmationItemsApi.getItem({
    params: {
      id: get(itemId),
    },
    success: (data) => {
      item.set(data);
    },
    error: () => {},
  });
}

function getConfirmationAttachments() {
  ConfirmationAttachmentsApi.get({
    params: {
      confirmation_id: get(confirmationId),
    },
    success: (response) => {
      confirmationAttachments.set(response.data.attachments);
    },
  });
}

function onPostComment(content, attachments, mentions) {
  CommentsApi.create({
    params: {
      parent_type: "confirmation_items",
      parent_id: get(itemId),
      content,
      attachments,
      mentions,
    },
    success: () => {
      getComments();
    },
  });
}

function getComments() {
  CommentsApi.getComments({
    params: {
      parent_type: "confirmation_items",
      parent_id: get(itemId),
    },
    success: (data) => {
      comments.set(data);
    },
  });
}

function getActivities(page = 1, success) {
  ConfirmationItemsApi.getActivities({
    params: {
      page,
      id: get(itemId),
    },
    success: (data) => {
      activities.set({ ...data, loaded: true });
      success();
    },
  });
}

export {
  item,
  itemId,
  entries,
  getItem,
  getConfirmationAttachments,
  comments,
  letterhead,
  onPostComment,
  getComments,
  getActivities,
  confirmationId,
  activeEditListItem,
  activities,
  statusesPossibleDelete,
  confirmationAttachments,
};
