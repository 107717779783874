<script>
  export let width = 28;
  export let height = 28;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 28 28"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.1913 1.55493C7.77933 1.55493 5.01262 4.32223 5.01262 7.73515C5.01262 11.1466 7.77944 13.9123 11.1913 13.9123C14.6033 13.9123 17.3715 11.1465 17.3715 7.73515C17.3715 4.32241 14.6034 1.55493 11.1913 1.55493ZM11.1913 3.88826C13.3149 3.88826 15.0382 5.61119 15.0382 7.73515C15.0382 9.85734 13.3151 11.5789 11.1913 11.5789C9.06788 11.5789 7.34596 9.85775 7.34596 7.73515C7.34596 5.61078 9.0681 3.88826 11.1913 3.88826ZM22.3749 22.4641C22.2142 19.1931 19.5138 16.5925 16.2038 16.5925H6.17976L5.87126 16.6C2.60093 16.7607 -0.000465393 19.4619 -0.000465393 22.7727V25.2772L0.0101849 25.4355C0.0874376 26.0049 0.575563 26.4438 1.1662 26.4438C1.81053 26.4438 2.33287 25.9215 2.33287 25.2772V22.7727L2.34105 22.5197C2.47125 20.5122 4.13961 18.9258 6.17976 18.9258H16.2038L16.4567 18.934C18.4634 19.0641 20.0491 20.7318 20.0491 22.7727V25.2772L20.0597 25.4355C20.137 26.0049 20.6251 26.4438 21.2158 26.4438C21.8601 26.4438 22.3824 25.9215 22.3824 25.2772V22.7727L22.3749 22.4641ZM17.952 2.6554C18.1729 2.05013 18.8427 1.73855 19.448 1.95947C22.4216 3.04486 24.1129 6.19526 23.3237 9.27226C22.8231 11.2327 21.3693 12.7974 19.4503 13.5025C18.8455 13.7247 18.1751 13.4146 17.9528 12.8098C17.7306 12.205 18.0408 11.5345 18.6456 11.3123C19.8546 10.8681 20.7556 9.89839 21.0632 8.69379C21.5527 6.78508 20.5022 4.82818 18.6479 4.15137C18.0426 3.93044 17.7311 3.26068 17.952 2.6554ZM23.9438 16.9906C23.3389 16.7686 22.6686 17.079 22.4466 17.6838C22.2245 18.2887 22.5349 18.959 23.1398 19.181C24.6547 19.7371 25.6655 21.1651 25.6667 22.7736V25.2772L25.6773 25.4355C25.7546 26.0049 26.2427 26.4438 26.8333 26.4438C27.4777 26.4438 28 25.9215 28 25.2772V22.7727L27.9914 22.4502C27.8612 19.9906 26.2703 17.8445 23.9438 16.9906Z"
  />
</svg>
