<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(4.705882%,34.901961%,4.313725%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M -30 0 L 105.367188 0 L 105.367188 360 L -30 360 Z M -30 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 320.648438 158.484375 L 298.738281 153.875 L 287.214844 173.074219 L 284.828125 150.808594 L 263.003906 145.78125 L 283.441406 136.632812 L 281.480469 114.324219 L 296.5 130.933594 L 317.105469 122.175781 L 305.953125 141.589844 L 320.652344 158.484375 Z M 320.648438 158.484375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 328.914062 215.214844 C 309.460938 258.078125 258.40625 276.800781 215.421875 256.890625 C 172.433594 236.972656 153.703125 185.925781 173.621094 142.933594 C 183.777344 121.007812 200.402344 105.941406 223.867188 97.71875 C 221.167969 100 218.5 102.367188 215.570312 105.386719 C 185.257812 136.667969 186.046875 186.664062 217.328125 216.972656 C 248.613281 247.285156 298.605469 246.496094 328.914062 215.214844 Z M 328.914062 215.214844 "
    />
  </g>
</svg>
