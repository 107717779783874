import { Controller } from "stimulus";
import Helper from "../general/helper";
import Api from "../general/api";

export default class extends Controller {
  deleteComment(e) {
    e.preventDefault();
    Helper.simpleConfirmDelete(
      e.target.getAttribute("data-url"),
      {
        parent_type: this.element.getAttribute("data-record"),
        parent_id: this.element.getAttribute("data-id"),
      },
      this.deleteCommentResponse.bind(this),
    );
  }

  deleteCommentResponse(response) {
    if (response["template"]) {
      let node = document.createElement("div");
      node.innerHTML = response["template"];
      this.element.replaceWith(node.children[0]);
    }
  }

  markCommentsAsRead(e) {
    e.preventDefault();
    let params = {
      parent_type: this.data.get("record"),
      parent_id: this.data.get("id"),
    };
    Api.post(
      e.target.getAttribute("data-url"),
      params,
      this.processMarkCommentsResponse.bind(this),
      {
        flash_alert_class: "notice",
        flash_element: document.querySelector("body"),
      },
    );
  }

  processMarkCommentsResponse(_response) {
    document.querySelector(".mark-comments-block").remove();
  }
}
