<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,91.372549%,0%);fill-opacity:1;"
      d="M -19.503906 0.167969 L 499.15625 0.167969 L 499.15625 359.667969 L -19.503906 359.667969 Z M -19.503906 0.167969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(20.784314%,63.137255%,0%);fill-opacity:1;"
      d="M -19.78125 0.167969 L -19.175781 259.324219 L 384.40625 0 Z M -19.78125 0.167969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(78.039216%,0%,0%);fill-opacity:1;"
      d="M 499.78125 359.667969 L 498.832031 90.195312 L 91.738281 359.875 L 499.78125 359.675781 Z M 499.78125 359.667969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M -19.519531 278.867188 L -19.175781 360 L 68.890625 359.886719 L 499.035156 71.507812 L 498.550781 0.96875 L 411.53125 0.167969 Z M -19.519531 278.867188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 323.832031 110.121094 L 316.863281 80.417969 L 340.316406 99.457031 L 367.042969 82.136719 L 354.785156 111.183594 L 378.269531 130.179688 L 347.242188 129.097656 L 335.027344 158.160156 L 328.113281 128.441406 L 297.078125 127.402344 Z M 323.832031 110.121094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 130.300781 235.917969 L 123.332031 206.214844 L 146.785156 225.253906 L 173.511719 207.929688 L 161.253906 236.976562 L 184.738281 255.976562 L 153.710938 254.894531 L 141.496094 283.957031 L 134.582031 254.238281 L 103.546875 253.199219 Z M 130.300781 235.917969 "
    />
  </g>
</svg>
