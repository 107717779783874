import Api from "apis/api";
import { buildFormData } from "lib/helpers.js";

class ConfirmationClients {
  constructor() {
    this.endpoint = "/confirmation_client_users";
  }

  create(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params);

    Api.post(`${this.endpoint}`, {
      body: formData,
      success: (response) => {
        success(response.data);
      },
      error,
    });
  }

  edit(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params);

    Api.patch(this.endpoint + `/${params.id}`, {
      body: formData,
      success: (response) => {
        success(response.data);
      },
      error,
    });
  }

  delete(options) {
    const { params } = options;
    Api.delete(
      this.endpoint + `/${params.id}?confirmation_id=${params.confirmation_id}`,
      options,
    );
  }

  sendInvitation(options) {
    const { params } = options;
    Api.post(
      this.endpoint +
        `/${params.id}/send_invitation?confirmation_id=${params.confirmation_id}`,
      options,
    );
  }
}

export default new ConfirmationClients();
