<script>
  import { onMount } from "svelte";
  import { ProductSelectionCard } from "components/productSelection";
  import { onCreateNewAudit } from "stores/dashboard/audit_new.js";
  import { confirmationType } from "stores/dashboard/create_confirmation.js";
  import { sandboxType } from "stores/dashboard/sandbox.js";
  import { t } from "stores/i18n.js";
  import { can } from "stores/permission.js";
  import { toasts } from "stores/toasts.js";
  import { isAccountFeature } from "stores/account";
  import SandboxApi from "apis/dashboard/sandbox_api.js";
  import { userPath, platformRedirectByType } from "lib/helpers.js";

  import styles from "styleguide/ProductSelectionOverview.json";

  export let showConfirmationModal;
  export let showNewAuditModal;
  export let showReviewModal;
  export let showSandboxModal;
  export let projectPermalink;
  export let projectName;
  export let onHide;

  let sendBoxData = {};

  onMount(() => {
    SandboxApi.get({
      params: {},
      success: (res) => {
        sendBoxData = res.data;
      },
      error: (err) => {
        toasts.send({ title: $t("errors.unknown"), type: "error" });
        console.log("err", err);
      },
    });
  });

  function loadConfirmationModal(type) {
    confirmationType.set(type);
    showConfirmationModal = true;
  }

  function loadNewAuditModal(projectPermalink, projectName) {
    onCreateNewAudit(projectPermalink, projectName);
    showNewAuditModal = true;
  }

  function loadReviewModal(type) {
    confirmationType.set(type);
    showReviewModal = true;
  }

  function createPermission(feature, action) {
    return $isAccountFeature(feature) && $can("create", action);
  }

  function handleClickTryButton(type) {
    const sandboxPermalink = sendBoxData[`${type}_permalink`];
    if (sandboxPermalink) {
      platformRedirectByType(sandboxPermalink, type);
    } else {
      sandboxType.set(type);
      showSandboxModal = true;
      showConfirmationModal = false;
      showNewAuditModal = false;
      showReviewModal = false;
      setupSandbox(type);
    }
  }

  function setupSandbox(type) {
    let formData = new FormData();
    formData.append("project_id", projectPermalink);
    formData.append("type", type);
    SandboxApi.create({
      params: formData,
      success: () => {
        if (onHide) {
          onHide();
        }
      },
      error: (error) => {
        toasts.send({ title: $t("errors.unknown"), type: "error" });
        console.log("error", error);
      },
    });
  }
</script>

<div data-component="ProductSelectionOverview" class={styles["product-cards"]}>
  {#if createPermission("sba", "review")}
    <ProductSelectionCard
      product="sba"
      onProductClick={loadReviewModal.bind(this, "review")}
      onInitializeSandbox={() => handleClickTryButton("sba")}
    />
  {/if}

  {#if createPermission("xba", "confirmation")}
    <ProductSelectionCard
      product="xba"
      onProductClick={loadConfirmationModal.bind(this, "confirmation")}
      onInitializeSandbox={() => handleClickTryButton("xba")}
    />
  {/if}

  {#if createPermission("pbc", "audit")}
    <ProductSelectionCard
      product="pbc"
      onProductClick={() => loadNewAuditModal(projectPermalink, projectName)}
      onInitializeSandbox={() => handleClickTryButton("pbc")}
    />
  {/if}
</div>

<style lang="scss">
  .product-cards {
    display: grid;
    width: 100%;
    gap: 16px;
    grid-template-columns: repeat(3, 1fr);
  }
</style>
