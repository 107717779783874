<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(88.235294%,0%,6.666667%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 85.6875 359.828125 L -24.375 360 L -24.375 0 L 85.171875 0.0546875 L 155.851562 22.785156 L 85.6875 44.941406 L 155.851562 67.785156 L 85.6875 89.941406 L 155.851562 112.785156 L 85.6875 134.941406 L 155.851562 157.785156 L 85.6875 179.941406 L 155.851562 202.785156 L 85.6875 224.941406 L 155.851562 247.785156 L 85.6875 269.941406 L 155.851562 292.785156 L 85.6875 314.941406 L 155.851562 337.785156 L 85.6875 359.941406 "
    />
  </g>
</svg>
