import { writable } from "svelte/store";

const method = writable({});
const sender = writable({});
const recipient = writable({});
const auditor = writable({});
const samples = writable({});
const footerLinks = writable({});
const addedFiles = writable([]);
const record = writable([]);
const reviewType = writable([]);
const pin = writable(null);

function setReviewType(type, category) {
  if (type === "open_debitor") {
    reviewType.set(category === "debitor" ? "open" : "positive");
  } else if (type === "open_creditor") {
    reviewType.set(category === "creditor" ? "open" : "positive");
  } else {
    reviewType.set(type);
  }
}

export {
  pin,
  method,
  sender,
  recipient,
  auditor,
  samples,
  addedFiles,
  record,
  reviewType,
  setReviewType,
  footerLinks,
};
