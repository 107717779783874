<script>
  import { getContext } from "svelte";

  import { Modal } from "components/modals";
  import {
    confirmationId,
    activeExport,
  } from "stores/confirmation/confirmation.js";
  import { t } from "stores/i18n.js";
  import { can } from "stores/permission.js";

  import ConfirmationExportApi from "apis/confirmation/confirmation_exports.js";
  import {
    dateTimeFormat,
    replaceObjectInArray,
    iconPath,
    downloadFile,
    formatExpiresInDate,
  } from "lib/helpers.js";

  import { Button, IconButton, Tooltip } from "components";
  import styles from "styleguide/ConfirmationExportModal.json";

  let items = [];
  let loadedExports = false;
  $: limit = items.length >= 3;

  const { language } = getContext("config");

  function onCreate() {
    if (limit) return;
    ConfirmationExportApi.create({
      params: {
        confirmation_id: $confirmationId,
      },
      success: setExport,
      error: () => {
        alert("Export Error");
      },
    });
  }

  function checkExport(id) {
    ConfirmationExportApi.checkExport({
      params: {
        id,
        confirmation_id: $confirmationId,
      },
      success: (response) => {
        const status = response.data.status;

        items = items.map((item) => {
          if (item.permalink === id) {
            item.status = status;

            if (status === "processing") {
              setTimeout(() => {
                checkExport(id);
              }, 2000);
            }
          }

          return item;
        });

        if (status === "done") {
          setExport(response);
        }
      },
    });
  }

  function setExport(response) {
    const newExport = response.data.export;
    if (!newExport) return;
    const id = newExport.permalink;

    if (newExport.status === "processing") {
      setTimeout(() => {
        checkExport(id);
      }, 2000);
    }

    items = replaceObjectInArray(items, newExport);
  }

  function onDownload(id) {
    ConfirmationExportApi.download({
      params: {
        id,
        confirmation_id: $confirmationId,
      },
      success: downloadFile,
    });
  }

  function onDelete(id) {
    ConfirmationExportApi.delete({
      params: {
        id,
        confirmation_id: $confirmationId,
      },
      success: () => {
        items = items.filter((item) => item.permalink !== id);
      },
    });
  }

  function onShow() {
    if ($confirmationId && !loadedExports) {
      ConfirmationExportApi.getData({
        params: {
          confirmation_id: $confirmationId,
        },
        success: (data) => {
          items = data;
          loadedExports = true;
        },
      });
    }
  }

  function onHide() {
    activeExport.set(false);
  }
</script>

{#if $activeExport}
  <Modal
    shown={$activeExport}
    {onShow}
    {onHide}
    title={$t("confirmation_export_modal.title")}
  >
    <div data-component="ConfirmationExportModal">
      <div class={styles.top}>
        <div class={styles.description}>
          {@html $t("confirmation_export_modal.description")}
        </div>

        {#if limit}
          <div class={styles.limit}>
            {$t("confirmation_export_modal.limit")}
          </div>
        {/if}

        {#if $can("create", "confirmation_export")}
          <div class={styles["create-button"]}>
            <Button style={"primary"} disabled={limit} click={onCreate}>
              {$t("confirmation_export_modal.create")}
            </Button>
          </div>
        {/if}
      </div>
      <div class={styles.bottom}>
        <div class={styles.previous}>
          {$t("confirmation_export_modal.previous")}
        </div>

        {#each items as { created_at, created_by_name, permalink, status, filesize, expires_in }}
          <div class={styles.item}>
            <div class={styles.details}>
              <Tooltip>
                <div slot="tooltip">
                  {$t(`confirmation_export_modal.${status}`)}
                </div>
                <div
                  slot="content"
                  class="{styles.status} {styles[status]}"
                ></div>
              </Tooltip>
              <img
                class={styles.icon}
                src={iconPath("zip-stroke")}
                alt={"zip-stroke"}
              />

              <div class={styles.info}>
                <div>
                  <span class={styles.created}
                    >{$t("confirmation_export_modal.export_by")}</span
                  > <span class={styles.name}>{created_by_name}</span>
                </div>
                <div class={styles.meta}>
                  {dateTimeFormat(created_at)}
                  {#if filesize}({filesize} MB){/if}
                  {" - "}{formatExpiresInDate(expires_in, language)}
                </div>
              </div>
            </div>

            <div class={styles.buttons}>
              {#if status === "done"}
                {#if $can("download", "confirmation_export")}
                  <IconButton
                    style={"primary-text"}
                    icon="download"
                    click={() => onDownload(permalink)}
                  />
                {/if}
              {/if}
              {#if $can("destroy", "confirmation_export")}
                <IconButton
                  style={"error-text"}
                  icon="trash"
                  click={() => onDelete(permalink)}
                />
              {/if}
            </div>
          </div>
        {:else}
          {#if loadedExports}
            <div class={styles.empty}>
              {$t("confirmation_export_modal.no_yet")}
            </div>
          {:else}
            <div class={styles.empty}>
              {$t("confirmation_export_modal.files_loading")}
            </div>
          {/if}
        {/each}
      </div>
    </div>
  </Modal>
{/if}

<style lang="scss">
  .bottom {
    padding: 24px 0;
  }

  .description {
    margin-bottom: 24px;
  }

  .limit {
    color: var(--red-200);
    margin-bottom: 24px;
  }

  .create-button {
    margin-top: 24px;
  }

  .previous {
    font-weight: 600;
    margin-bottom: 24px;
    font-size: 16px;
  }

  .empty {
    font-size: 12px;
    text-align: center;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .buttons {
    display: flex;
    gap: 8px;
  }

  .details {
    display: flex;
    align-items: center;
  }

  .status {
    width: 10px;
    height: 10px;
    border-radius: 50%;

    &.done {
      background-color: var(--green);
    }

    &.processing {
      background-color: var(--yellow);
    }
  }

  .icon,
  .info {
    margin-left: 10px;
  }

  .name {
    font-weight: 500;
  }

  .meta {
    font-size: 12px;
    line-height: 18px;
    color: var(--primary-300);
  }

  .created {
    color: var(--primary-300);
  }

  .checkbox {
    margin-bottom: 10px;
  }
</style>
