<script>
  export let width = 22;
  export let height = 22;
  export let fill = "#052861";
</script>

<svg
  {width}
  {height}
  viewBox={`0 0 ${width} ${height}`}
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_1311_10441)">
    <path
      opacity="0.964"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.2941 2.03705C15.6178 1.88994 18.2939 3.12015 20.3225 5.72768C22.0836 8.3291 22.467 11.1251 21.4728 14.1155C20.0421 17.5439 17.4778 19.4851 13.78 19.939C11.1823 20.117 8.92161 19.3421 6.99783 17.6144C6.72013 17.0895 6.83995 16.6821 7.35731 16.3922C7.54903 16.3602 7.74075 16.3602 7.93247 16.3922C10.3373 18.4386 13.0214 18.9019 15.9848 17.7822C18.6507 16.502 20.1286 14.385 20.4183 11.4314C20.5016 8.81042 19.519 6.69348 17.4706 5.08063C15.0912 3.42924 12.5669 3.17361 9.89762 4.31374C9.19064 4.66977 8.53558 5.10114 7.93247 5.60786C7.31854 5.74053 6.9511 5.50088 6.83008 4.88891C6.85285 4.70979 6.90878 4.54203 6.99783 4.38564C8.5003 3.01392 10.2658 2.23106 12.2941 2.03705Z"
      {fill}
    />
    <path
      opacity="0.962"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.39 5.72768C12.9464 5.63392 13.2898 5.85761 13.4205 6.3987C13.4364 7.69282 13.4524 8.98693 13.4684 10.2811C14.4909 11.3035 15.5134 12.3261 16.5359 13.3486C16.8428 13.8973 16.723 14.3207 16.1765 14.6187C15.924 14.6699 15.6843 14.638 15.4575 14.5229C14.2992 13.3646 13.1409 12.2063 11.9826 11.0479C11.9506 10.984 11.9187 10.9201 11.8867 10.8562C11.8547 9.32245 11.8547 7.78868 11.8867 6.25491C11.9674 5.99053 12.1352 5.81481 12.39 5.72768Z"
      {fill}
    />
    <path
      opacity="0.963"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.551171 6.63838C2.75602 6.63037 4.96081 6.63838 7.16555 6.66234C7.70194 6.93262 7.83777 7.34003 7.57296 7.88457C7.43981 8.04307 7.27205 8.14694 7.06969 8.19611C4.91283 8.22808 2.75596 8.22808 0.599102 8.19611C-0.00771006 7.94165 -0.159491 7.51828 0.143764 6.92596C0.255049 6.7872 0.390854 6.69134 0.551171 6.63838Z"
      {fill}
    />
    <path
      opacity="0.989"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.50979 10.2331C3.7466 10.2251 5.98337 10.2331 8.22003 10.2571C8.65054 10.4715 8.80234 10.815 8.67537 11.2876C8.58751 11.5033 8.43572 11.6551 8.22003 11.7429C5.98327 11.7749 3.74655 11.7749 1.50979 11.7429C1.1749 11.6084 1.01514 11.3608 1.03049 11C1.021 10.635 1.18077 10.3793 1.50979 10.2331Z"
      {fill}
    />
    <path
      opacity="0.963"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.66011 13.78C4.81703 13.772 6.97389 13.78 9.1307 13.8039C9.7375 14.0584 9.8893 14.4818 9.58604 15.0741C9.48539 15.2163 9.34955 15.3042 9.17863 15.3377C6.97384 15.3697 4.76905 15.3697 2.56425 15.3377C2.02785 15.0674 1.89205 14.66 2.15685 14.1155C2.30053 13.9603 2.46829 13.8485 2.66011 13.78Z"
      {fill}
    />
  </g>
  <defs>
    <clipPath id="clip0_1311_10441">
      <rect {width} {height} fill="white" />
    </clipPath>
  </defs>
</svg>
