<script>
  import { onMount } from "svelte";
  import { toasts } from "stores/toasts.js";
  import styles from "styleguide/Toasts.json";
  import { Toast } from "components";
  import { fly } from "svelte/transition";

  export let flash;

  onMount(() => {
    flash.forEach(([type, title]) => {
      if (type === "notice") type = "success";
      toasts.send({
        title: title,
        type: type,
      });
    });
  });
</script>

<div class={styles.wrapper}>
  {#each $toasts as toast (toast.id)}
    <div transition:fly|global={{ x: 200, duration: 200 }}>
      <Toast {...toast} inline={false} />
    </div>
  {/each}
</div>

<style lang="scss">
  .wrapper {
    width: 380px;
    position: fixed;
    top: 16px;
    right: 16px;
    z-index: 999;
  }
</style>
