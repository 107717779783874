<script>
  import styles from "styleguide/AuditSidebar.json";

  import { user, isUserTeamUser } from "stores/user.js";
  import { t } from "stores/i18n.js";
  import { audit, auditComments } from "stores/pbc.js";

  import {
    AuditSidebarActivity,
    AuditSidebarClientAccess,
    AuditSidebarComments,
    AuditSidebarStatus,
  } from "apps/pbc";

  import {
    Boundary,
    Box,
    LegacyButton as Button,
    SidebarNotifications,
    Smalltabs,
  } from "components";

  $: commentsLength = $auditComments.filter((item) => !item.is_deleted).length;

  let tabs = {
    status: {
      id: "status",
      component: AuditSidebarStatus,
    },
    comments: {
      id: "comments",
      component: AuditSidebarComments,
    },
    activity: {
      id: "activity",
      component: AuditSidebarActivity,
    },
  };

  let activeTab = tabs["status"];

  function changeTab(target) {
    activeTab = tabs[target];
  }
</script>

<Boundary>
  <div data-component="AuditSidebar" class={styles.wrapper}>
    {#if $isUserTeamUser}
      <SidebarNotifications store={$audit} unitType={"Audit"} />
    {/if}

    <Box title={$user.role === "client" ? $t("audit_sidebar.status") : null}>
      {#if $user.role !== "client"}
        <Smalltabs>
          {#each Object.values(tabs) as tab}
            <div class={styles.smalltab}>
              <Button
                style="smalltab fullsize"
                active={activeTab.id == tab.id}
                click={() => changeTab(tab.id)}
              >
                {$t("audit_sidebar." + tab.id)}
                {#if tab.id === "comments"}
                  <span
                    class={commentsLength > 0
                      ? styles.count
                      : styles["empty-count"]}>({commentsLength})</span
                  >
                {/if}
              </Button>
            </div>
          {/each}
        </Smalltabs>
      {/if}

      <Box style="no-border">
        <svelte:component this={activeTab.component} />
      </Box>
    </Box>

    <Box style="padding margin-top" title={$t("audit_sidebar.client_access")}>
      <AuditSidebarClientAccess />
    </Box>

    {#if $user.role !== "client"}
      <div class={styles.info}>
        <div class={styles["info-item"]}>
          <span>{$t("audit_sidebar.used_space")}</span>
          <span>{$audit.audit_size}</span>
        </div>
        <div class={styles["info-item"]}>
          <span>{$t("audit_sidebar.number_of_attachments")}</span>
          <span>{$audit.attachments_count}</span>
        </div>
        <div class={styles["info-item"]}>
          <span>{$t("audit_sidebar.number_of_comment_attachments")}</span>
          <span>{$audit.comments_attachments_count}</span>
        </div>
      </div>
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    width: 335px;
    margin: 25px 0;
  }

  .smalltab {
    flex: 1;
    font-weight: normal;
  }

  .count {
    font-weight: 400;
    color: var(--red-200);
  }

  .empty-count {
    color: var(--primary-300);
  }

  .info {
    color: var(--primary-300);
    font-size: 12px;
    margin-top: 20px;
  }

  .info-item {
    display: flex;
    justify-content: space-between;
  }
</style>
