<script>
  import styles from "styleguide/DashboardProjectSidebar.json";
  import { DashboardProjectActivity } from "apps/dashboard";
</script>

<div data-component="DashboardProjectSidebar">
  <div class={styles.item}>
    <DashboardProjectActivity />
  </div>
</div>

<style lang="scss">
  .item {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }
</style>
