<script>
  import { onMount, onDestroy } from "svelte";
  import CountriesIcons from "components/icons/countries/CountriesIcons.svelte";
  import { account } from "stores/account.js";
  import { t, currentLanguage } from "stores/i18n.js";
  import { createPopperActions } from "svelte-popperjs";
  import { TextInput } from "components";
  import Helper from "../../../general/helper";
  import styles from "styleguide/CountryInput.json";

  export let disabled;
  export let errors;
  export let label;
  export let selected;
  export let useSameWidth = true;
  export let placement = "auto";

  let input;
  let open = false;
  let items = [];
  let commonCountries = [];
  let originalCountries = [];
  let countryInputContainer;

  const [popperRef, popperContent] = createPopperActions({
    strategy: "fixed",
    placement: placement,
  });

  const sameWidth = {
    name: "sameWidth",
    enabled: true,
    phase: "beforeWrite",
    requires: ["computeStyles"],
    fn: ({ state }) => {
      state.styles.popper.width = `${state.rects.reference.width}px`;
    },
    effect: ({ state }) => {
      state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
    },
  };

  const extraOpts = {
    placement: "auto",
    modifiers: [
      { name: "offset", options: { offset: [0, 8] } },
      { name: "flip", options: { allowedAutoPlacements: ["top", "bottom"] } },
    ],
  };

  if (useSameWidth) {
    extraOpts.modifiers.push(sameWidth);
  }

  onMount(async () => {
    items = await fetchCountries();
    resetInitialItems();
  });

  onDestroy(() => {
    window.removeEventListener("click", handleOutsideClick);
  });

  function onFocus() {
    open = true;
    inputSelect();
  }

  function toggle() {
    open = !open;
    inputSelect();
  }

  function inputSelect() {
    if (open) {
      input.select();
    } else {
      setTimeout(() => input.blur());
    }
  }

  function resetInitialItems() {
    commonCountries = items.filter(
      (country) => country.code == $account.country,
    );
    originalCountries = items;
  }

  function handleOutsideClick(event) {
    if (!countryInputContainer.contains(event.target)) {
      open = false;
      resetInitialItems();
    }
  }

  function onClickItem(key) {
    selected = key;
    open = false;
  }

  async function fetchCountries() {
    const response = await fetch(`/countries/${$currentLanguage}.json`);
    return response.json();
  }

  function filterCountries(items, query, startIdx, endIdx = items.length) {
    return items
      .slice(startIdx, endIdx)
      .filter(
        (country) =>
          country.name.toLowerCase().includes(query) ||
          country.code.toLowerCase().includes(query) ||
          (country.aliases &&
            country.aliases.some((alias) =>
              alias.toLowerCase().includes(query),
            )),
      );
  }

  function updateEntities(event) {
    selected = event.target.value;
    const query = selected.toLowerCase();

    commonCountries = filterCountries(items, query, 0, 3);
    originalCountries = filterCountries(items, query, 3);
  }

  $: if (open && countryInputContainer) {
    window.addEventListener("click", handleOutsideClick);
  }
</script>

<div data-component="CountryInput" class={styles.wrapper}>
  <div bind:this={countryInputContainer} use:popperRef>
    <TextInput
      style={"dropdown-border"}
      {disabled}
      {label}
      {errors}
      bind:input
      bind:value={selected}
      enterIcon={`stroke-1-5-${open ? "up" : "down"}.svg`}
      placeholder={$t("country_input.placeholder")}
      onIconClick={toggle}
      {onFocus}
      onInput={Helper.debounce(updateEntities)}
    />
  </div>

  {#if open}
    <div use:popperContent={extraOpts} class={styles.items}>
      {#if !originalCountries.length && !commonCountries.length}
        <div class={styles["no-countries"]}>
          {$t("country_input.invalid_country")}
        </div>
      {:else}
        {#each commonCountries as country}
          <div class={styles.item} on:click={onClickItem(country.name)}>
            <CountriesIcons
              icon={country.code.toLowerCase()}
              width="24"
              height="18"
              style="border-radius: 3px; border: 0.5px solid #d3d9e3;"
            />
            {country.name}
          </div>
        {/each}
        {#if commonCountries.length && originalCountries.length}
          <div class={styles.divider} />
        {/if}
        {#each originalCountries as country}
          <div class={styles.item} on:click={onClickItem(country.name)}>
            <CountriesIcons
              icon={country.code.toLowerCase()}
              width="24"
              height="18"
              style="border-radius: 3px; border: 0.5px solid #d3d9e3;"
            />
            {country.name}
          </div>
        {/each}
      {/if}
    </div>
  {/if}
</div>

<style lang="scss">
  .wrapper {
    position: relative;
    width: 100%;
  }
  .items {
    position: absolute;
    top: 78px;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow: auto;
    padding: 2px;
    border: 1px solid var(--primary-050);
    border-radius: var(--border-radius);
    background: #fff;
    z-index: 3;

    .no-countries {
      padding: 8px;
    }

    .item {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 6px 8px;
      border-radius: var(--border-radius);
      cursor: pointer;

      &:hover {
        background: var(--light-blue);
      }

      &:active {
        background: var(--light-blue-002);
      }
    }

    .divider {
      display: list-item;
      height: 1px;
      width: 100%;
      background-color: var(--primary-050);
    }
  }
</style>
