<script>
  import styles from "styleguide/FileItem.json";
  import {
    fileIcon,
    humanFileSizeFromByte,
    humanFileSizeFromMB,
  } from "lib/helpers.js";
  import { Boundary } from "components";

  export let style = "";
  export let name;
  export let size;
  export let sidebar;
  export let staticButtons;
  export let isUpload = false;

  $: icon = fileIcon(name ? name : "");

  $: calculatedStyles = function () {
    let s = style.split(" ");
    if (sidebar) {
      s.push("sidebar");
    }
    if (staticButtons) {
      s.push("static-buttons");
    }
    return s.map((x) => styles[x]).join(" ");
  };

  $: getSize = function () {
    return isUpload ? humanFileSizeFromByte(size) : humanFileSizeFromMB(size);
  };
</script>

<Boundary>
  <div data-component="FileItem" class="{styles.file} {calculatedStyles()}">
    <div class={styles.icon}><img src={icon} alt={icon} /></div>
    <div class={styles.main}>
      <div class={styles.content}>
        <div class={styles.name}>
          <div class={styles.filename}>{name}</div>
        </div>
        <div class={styles.meta}>{getSize()}</div>
      </div>
      <div class={styles.actions}>
        <slot />
      </div>
    </div>
  </div>
</Boundary>

<style lang="scss">
  .file {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
  }

  .main {
    display: flex;
    align-items: center;
    flex: 1;

    &:not(:last-child) {
      border-bottom: 1px solid var(--primary-010);
    }
  }

  .icon {
    margin-right: 6px;
    position: relative;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;

    .sidebar & {
      width: auto;
    }

    img {
      width: 28px;

      .sidebar & {
        width: 18px;
      }
    }

    .checkmark {
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: -5px;
      right: -2px;
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    // justify-content: space-between;

    .sidebar & {
      align-items: center;
      flex-direction: row;
    }
  }

  .filename {
    width: 300px;
    font-weight: 500;
    line-height: 22px;
    color: var(--primary-500);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .sidebar & {
      width: 170px;
      margin-right: 6px;
      font-weight: 400;
    }
  }

  .meta {
    color: var(--primary-300);
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;

    .sidebar.file:hover &,
    .sidebar.static-buttons & {
      display: none;
    }
  }

  .actions {
    .sidebar:not(.static-buttons) & {
      display: none;
      height: 32px;
    }

    .file:hover & {
      display: block;
    }
  }
</style>
