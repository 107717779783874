<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,61.568627%);fill-opacity:1;"
      d="M 0 0 L 160.003906 0 L 160.003906 360 L 0 360 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,61.568627%);fill-opacity:1;"
      d="M 319.996094 0 L 480 0 L 480 360 L 319.996094 360 Z M 319.996094 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,83.137255%,0%);fill-opacity:1;"
      d="M 160.003906 0 L 320.007812 0 L 320.007812 360 L 160.003906 360 Z M 160.003906 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 240.230469 290.6875 C 248.941406 291.292969 244.128906 290.292969 252.839844 290.898438 L 253.449219 239.234375 L 286.878906 239.234375 C 286.679688 177.535156 310.648438 125.53125 339.246094 81.445312 C 340.964844 79.234375 339.839844 78.28125 337.933594 78.773438 C 316.351562 82.144531 294.144531 93.472656 278.933594 109.203125 C 277.160156 111.382812 279.1875 111.722656 280.191406 111.59375 C 288.523438 109.097656 286.882812 118.425781 286.277344 122.167969 C 277.738281 149.445312 270.289062 175.875 267.550781 207.261719 C 267.550781 209.445312 267.207031 211.03125 266.171875 211.136719 C 262.320312 211.339844 258.554688 211.285156 254.664062 211.273438 C 253.246094 211.285156 252.933594 209.71875 252.839844 208.234375 C 253.042969 176.628906 252.761719 145.023438 252.964844 113.417969 C 253.0625 111.332031 260.613281 102.863281 261.84375 102.832031 C 265.710938 102.730469 267.339844 102.828125 271.667969 102.753906 C 272.792969 102.746094 273.035156 99.273438 271.746094 99.105469 C 269.113281 99.105469 264.226562 98.867188 261.59375 98.867188 C 260.34375 98.914062 242.675781 85.4375 242.632812 82.667969 C 242.429688 78.414062 242.550781 74.238281 242.347656 69.984375 C 242.578125 68.9375 237.414062 68.695312 237.484375 70.066406 L 237.484375 81.890625 C 236.601562 87.527344 240.027344 273.460938 240.230469 290.683594 Z M 240.230469 290.6875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 239.769531 290.6875 C 231.058594 291.292969 235.871094 290.292969 227.160156 290.898438 L 226.554688 239.234375 L 193.125 239.234375 C 193.320312 177.535156 169.355469 125.53125 140.757812 81.445312 C 139.035156 79.234375 140.160156 78.28125 142.066406 78.773438 C 163.652344 82.144531 185.855469 93.472656 201.066406 109.203125 C 202.84375 111.382812 200.8125 111.722656 199.808594 111.59375 C 191.480469 109.097656 193.121094 118.425781 193.726562 122.167969 C 202.261719 149.445312 209.714844 175.875 212.449219 207.261719 C 212.453125 209.445312 212.796875 211.03125 213.832031 211.136719 C 217.679688 211.339844 221.445312 211.285156 225.339844 211.273438 C 226.757812 211.285156 227.066406 209.71875 227.160156 208.234375 C 226.957031 176.628906 227.238281 145.023438 227.039062 113.417969 C 226.941406 111.332031 219.386719 102.863281 218.160156 102.832031 C 214.292969 102.730469 212.660156 102.828125 208.335938 102.753906 C 207.207031 102.746094 206.964844 99.273438 208.253906 99.105469 C 210.890625 99.105469 215.777344 98.867188 218.410156 98.867188 C 219.65625 98.914062 237.328125 85.4375 237.367188 82.667969 C 237.570312 78.414062 237.453125 74.238281 237.652344 69.984375 C 237.421875 68.9375 242.585938 68.695312 242.515625 70.066406 L 242.515625 81.890625 C 243.402344 87.527344 239.972656 273.460938 239.769531 290.683594 Z M 239.769531 290.6875 "
    />
  </g>
</svg>
