<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 0 L 480.007812 0 L 480.007812 359.996094 L 0 359.996094 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,74.901961%,7.45098%);fill-opacity:1;"
      d="M 0 0 L 240.003906 0 L 240.003906 359.996094 L 0 359.996094 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(94.901961%,9.803922%,18.823529%);fill-opacity:1;"
      d="M 334.421875 231.496094 C 304.1875 285.875 237.5 305.199219 184.515625 276.011719 C 131.527344 246.828125 112.210938 180.136719 141.394531 127.148438 C 170.582031 74.160156 237.265625 54.84375 290.253906 84.027344 C 309.84375 94.820312 323.789062 109.003906 334.757812 129.207031 C 330.753906 123.703125 326.285156 119.15625 320.417969 114.546875 C 284.277344 86.128906 231.886719 92.402344 203.472656 128.542969 C 175.058594 164.683594 181.328125 217.078125 217.46875 245.492188 C 253.613281 273.90625 306.003906 267.632812 334.417969 231.492188 Z M 334.421875 231.496094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(94.901961%,9.803922%,18.823529%);fill-opacity:1;"
      d="M 308.527344 241.199219 L 282.140625 203.796875 L 238.625 217.988281 L 266.039062 181.332031 L 239.09375 144.332031 L 282.429688 159.078125 L 309.292969 122.019531 L 308.65625 167.789062 L 352.203125 181.886719 L 308.480469 195.421875 Z M 308.527344 241.199219 "
    />
  </g>
</svg>
