// WARNING: THIS IS THE LEGACY api.js USED FOR STIMULUS/RAILS
// FOR SVELTE USE: javascript/apis/api.js

import Helper from "./helper";
import Flash from "./flash";

class Api {
  get(url, params, callback, options) {
    let updatedUrl =
      url + (url.includes("?") ? "&" : "?") + new URLSearchParams(params);
    this.request(
      updatedUrl,
      { method: "GET", credentials: "same-origin" },
      callback,
      options,
    );
  }

  post(url, params, callback, options) {
    if (!options) {
      options = {};
    }
    var requestBody = {
      method: "POST",
      credentials: "same-origin",
      body: Helper.jsonToFormData(params),
      headers: { ...this.defaultHeaders(), ...options["headers"] },
    };

    if (
      options["headers"] &&
      options["headers"]["Content-Type"] == "application/x-www-form-urlencoded"
    ) {
      requestBody["body"] = new URLSearchParams(requestBody["body"]);
    }

    this.request(url, requestBody, callback, options);
  }

  patch(url, params, callback, options) {
    this.request(
      url,
      {
        method: "PATCH",
        credentials: "same-origin",
        body: Helper.jsonToFormData(params),
        headers: this.defaultHeaders(),
      },
      callback,
      options,
    );
  }

  delete(url, params, callback, options) {
    this.request(
      url,
      {
        method: "DELETE",
        credentials: "same-origin",
        body: Helper.jsonToFormData(params),
        headers: this.defaultHeaders(),
      },
      callback,
      options,
    );
  }

  redirect(url, params) {
    window.location = `${url}?${new URLSearchParams(params)}`;
  }

  getPromise(url, params) {
    let updatedUrl =
      url + (url.includes("?") ? "&" : "?") + new URLSearchParams(params);
    return this.requestPromise(updatedUrl, {
      method: "GET",
      credentials: "same-origin",
    });
  }

  redirect(url) {
    window.location = url;
  }

  // By default if response includes message field it'll be flash message
  request(url, requestBody, callback, options) {
    let self = this;
    if (!options) {
      options = {};
    }

    this.requestPromise(url, requestBody)
      .then((response) => {
        // Probably we need to rewrite it to some generic reponse handler

        if (
          options["modal_status"] &&
          (!response["status"] || response["status"] == "ok")
        ) {
          options["flash_element"] = document.querySelector("body");
        }
        if (response["message"]) {
          Flash.addMessage(
            response["message"],
            Helper.flashAlertClass(
              response["status"],
              options["flash_alert_class"],
            ),
            options["flash_element"],
          );
        }

        if (response["path"]) {
          window.location.href = response["path"];
        }

        if (options["funcs"]) {
          callback(response, ...options["funcs"]);
        } else {
          callback(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  requestPromise(url, requestBody) {
    return fetch(url, requestBody).then((response) => {
      if (response.status == 404) {
        let flashElement = document.querySelector(".modal.show");
        if (!flashElement) flashElement = document.querySelector("body");
        Flash.addMessage(
          I18n.t("platform.views.not_found"),
          "error",
          flashElement,
        );
        return { status: response.status };
      } else if (response.status == 500) {
        return { error: response.statusText };
      } else if (response.headers.get("Content-Type") === "image/jpeg") {
        return response.blob();
      } else {
        return response.json();
      }
    });
  }

  defaultHeaders() {
    return { "X-CSRF-Token": this.csrfToken() };
  }

  csrfToken() {
    let metaToken = document.querySelector(`meta[name="csrf-token"]`);
    return metaToken ? metaToken.content : "";
  }
}

export default new Api();
