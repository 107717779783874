<script>
  import { onMount } from "svelte";
  import styles from "styleguide/ProgressRing.json";
  import { Spinner } from "components";

  export let completedPercent = 0;
  export let acceptedPercent = 0;
  export let pendingPercent = 0;
  export let rejectedPercent = 0;
  export let size = 32;
  export let stroke = 4;
  export let showSpinner = false;
  export let showPercent = false;
  export let showCheckmark = false;
  export let label = null;

  let circleOrangeRoation = 90;
  let circleRedRoation = 90;
  let circleGreen;
  let circleOrange;
  let circleRed;
  let radius = size / 2 - stroke / 2;
  let circumference = radius * 2 * Math.PI;
  let strokeDasharray = `${circumference} ${circumference}`;

  onMount(async () => {
    circleGreen.style.strokeDasharray = strokeDasharray;
    circleGreen.style.strokeDashoffset = getArcOffset(acceptedPercent);

    // For displaying the progress of a Confirmation on Dashboard/Project
    if (acceptedPercent || pendingPercent || rejectedPercent) {
      if (circleOrange) {
        circleOrange.style.strokeDasharray = strokeDasharray;
        circleOrange.style.strokeDashoffset = getArcOffset(pendingPercent);
        circleOrangeRoation = (360 / 100) * acceptedPercent - 90;
      }

      if (circleRed) {
        circleRed.style.strokeDasharray = strokeDasharray;
        circleRed.style.strokeDashoffset = getArcOffset(rejectedPercent);
        circleRedRoation =
          (360 / 100) * (acceptedPercent + pendingPercent) - 90;
      }
    }
  });

  $: if (completedPercent > 0) {
    if (circleGreen) {
      circleGreen.style.strokeDashoffset = getArcOffset(completedPercent);
    }
  }

  function getArcOffset(percent) {
    return circumference - (percent / 100) * circumference;
  }
</script>

<div
  data-component="ProgressRing"
  class={styles.wrapper}
  width={size}
  height={size}
>
  <svg class="progress-ring" width={size} height={size}>
    <circle
      class={styles.background}
      stroke="#EEEEEE"
      stroke-width={stroke}
      fill="transparent"
      r={radius}
      cx={size / 2}
      cy={size / 2}
    />
    <circle
      bind:this={circleGreen}
      class={styles["green-circle"]}
      stroke="transparent"
      stroke-width={stroke}
      fill="transparent"
      r={radius}
      cx={size / 2}
      cy={size / 2}
    />
    {#if pendingPercent}
      <circle
        bind:this={circleOrange}
        class={styles["orange-circle"]}
        stroke="transparent"
        stroke-width={stroke}
        transform="rotate({circleOrangeRoation})"
        fill="transparent"
        r={radius}
        cx={size / 2}
        cy={size / 2}
      />
    {/if}
    {#if rejectedPercent}
      <circle
        bind:this={circleRed}
        class={styles["red-circle"]}
        stroke="transparent"
        stroke-width={stroke}
        transform="rotate({circleRedRoation})"
        fill="transparent"
        r={radius}
        cx={size / 2}
        cy={size / 2}
      />
    {/if}
  </svg>
  {#if showPercent}
    <div class={styles["show-percent"]}>{completedPercent}%</div>
  {:else if showSpinner}
    <div class={styles["show-spinner"]}>
      <Spinner color={"var(--green-010)"} />
    </div>
  {:else if completedPercent == 100 && showCheckmark}
    <div class={styles.checkmark} />
  {:else if label}
    <div class={styles["show-percent"]}>{label}</div>
  {/if}
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    position: relative;
  }

  .foreground {
    transition: 0.5s stroke-dashoffset;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }

  .checkmark {
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    height: 12px;
    width: 7px;
    margin-top: -4px;
    margin-left: -1px;

    &:before {
      display: block;
      content: "";
      transform: rotate(45deg);
      height: 12px;
      width: 7px;
      border-bottom: 3px solid var(--green);
      border-right: 3px solid var(--green);
    }
  }

  .green-circle {
    transition: 0.5s stroke-dashoffset;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    stroke: var(--green);
  }

  .orange-circle {
    transition: 0.5s stroke-dashoffset;
    transform-origin: 50% 50%;
    stroke: var(--orange);
  }

  .red-circle {
    transition: 0.5s stroke-dashoffset;
    transform-origin: 50% 50%;
    stroke: var(--red);
  }

  .show-percent,
  .show-spinner {
    color: var(--primary-500);
    font-size: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
