<script>
  import { getContext, onMount } from "svelte";
  import { ConfirmationFileItem } from "apps/confirmation";
  import {
    ConfirmationMailingMarkAsSendModal,
    MailingAttachmentModal,
  } from "components/modals";
  import {
    item,
    getItem,
    letterhead,
    confirmationId,
    getConfirmationAttachments,
  } from "stores/confirmation/confirmation_item.js";
  import { t } from "stores/i18n.js";
  import { can } from "stores/permission.js";
  import { toasts } from "stores/toasts.js";
  import { isUserTeamUser, isUserClient } from "stores/user.js";
  import { isAccountFeature } from "stores/account";

  import ConfirmationAttachmentsApi from "apis/confirmation/confirmation_attachments.js";
  import ConfirmationMailingsApi from "apis/confirmation/confirmation_mailings.js";
  import {
    imagePath,
    downloadFile,
    distanceOfTime,
    dateTimeFormat,
  } from "lib/helpers.js";

  import {
    Button,
    DocumentThumbnails,
    Options,
    Toast,
    Tooltip,
  } from "components";
  import styles from "styleguide/ConfirmationMailing.json";

  export let sent_via;
  export let sent_at;
  export let permalink;
  export let mailing_url;
  export let is_confirmed;
  export let bounced;
  export let returned;
  export let companies = [];
  export let attachments = [];

  let pollingCount = 0;
  let isMarkAsSendModalVisible = false;
  let shownMailingAttachmentModal = false;
  let isConfirmingMailing = false;

  let isLoadingThumbnails = true;
  let assignedAttachments = [];
  let totalFilesSize = 0;

  const maxPollingCount = 20;
  const config = getContext("config");

  onMount(() => {
    const mailing = $item.confirmation_mailings.find(
      (item) => item.permalink === permalink,
    );
    assignedAttachments = mailing.attachments.map((att) => att.permalink);

    totalFilesSize = mailing.attachments.reduce(
      (sum, att) => sum + att.filesize,
      0,
    );
  });

  $: showReturnedWarning = bounced || returned;
  $: showNoEmailWarning = !$item.email && sent_via === "email";
  $: showNoCompaniesWarning =
    !hasUnacceptedCompanies() && !sent_at && !is_confirmed;

  $: hasUnacceptedCompanies = function () {
    return companies.filter((c) => c.accepted === "false").length > 0;
  };

  $: isRelesed =
    $can("confirm", "confirmation_mailing") &&
    !sent_at &&
    !is_confirmed &&
    $letterhead.url;

  $: warnings = [
    showReturnedWarning && {
      title: $t("confirmation_mailing.returned_warning"),
      message: $t("confirmation_mailing.returned_warning_description"),
    },
    showNoEmailWarning && {
      title: $t("confirmation_mailing.no_email_address_title"),
      message: $t("confirmation_mailing.no_email_address_description"),
    },
    showNoCompaniesWarning && {
      title: $t("confirmation_mailing.no_companies"),
      message: $t("confirmation_mailing.no_companies_description"),
    },
  ].filter(Boolean);

  $: canBeConfirmed =
    $can("confirm", "confirmation_mailing") &&
    !sent_at &&
    !is_confirmed &&
    $letterhead.url;

  function checkStatusPolling(id) {
    let params = {
      id,
      confirmation_item_id: $item.permalink,
    };

    let success = (response) => {
      if (response.stored) {
        clearInterval(polling);
        getItem();
      }
    };

    let error = () => {
      clearInterval(polling);
    };

    let polling = setInterval(() => {
      pollingCount++;

      if (pollingCount <= maxPollingCount) {
        ConfirmationMailingsApi.checkStatus({ params, success, error });
      } else {
        clearInterval(polling);
      }
    }, 2000);

    return "";
  }

  function confirmMailing(id, confirmed) {
    if (isConfirmingMailing) {
      return;
    }
    isConfirmingMailing = true;

    if (confirm($t("questions.sure"))) {
      ConfirmationMailingsApi.confirm({
        params: {
          id,
          confirmed,
          confirmation_item_id: $item.permalink,
        },
        success: () => {
          isConfirmingMailing = false;
          getItem();
        },
      });
    }
  }

  function unConfirmMailing(id, confirmed) {
    if (confirm($t("questions.sure"))) {
      ConfirmationMailingsApi.unconfirm({
        params: {
          id,
          confirmed,
          confirmation_item_id: $item.permalink,
        },
        success: () => {
          getItem();
        },
      });
    }
  }

  function switchMailingChannel(id) {
    if (confirm($t("questions.sure"))) {
      ConfirmationMailingsApi.switchChannel({
        params: {
          id,
          confirmation_item_id: $item.permalink,
        },
        success: () => {
          getItem();
        },
      });
    }
  }

  function deleteMailing(id) {
    if (confirm($t("questions.sure"))) {
      ConfirmationMailingsApi.delete({
        params: {
          id,
          confirmation_item_id: $item.permalink,
        },
        success: () => {
          getItem();
        },
      });
    }
  }

  function handleAttachmentRemove(attachmentId, filesize) {
    if (assignedAttachments.includes(attachmentId)) {
      assignedAttachments = assignedAttachments.filter(
        (item) => item !== attachmentId,
      );
      totalFilesSize -= filesize;
    } else {
      assignedAttachments = [...assignedAttachments, attachmentId];
      totalFilesSize += filesize;
    }

    ConfirmationAttachmentsApi.assign({
      params: {
        confirmation_id: $confirmationId,
        mailing_id: permalink,
        attachment_ids: assignedAttachments,
      },
      success: () => {
        getItem();
        getConfirmationAttachments();
        toasts.send({
          title: $t("confirmation_mailing.success_remove_message"),
          type: "success",
        });
      },
    });
  }

  function downloadPdf(mailingUrl) {
    downloadFile(
      { url: mailingUrl, filename: `${$item.company}.pdf` },
      null,
      true,
    );
  }

  function openMailingAttachmentModal() {
    shownMailingAttachmentModal = true;
  }
</script>

<div data-component="ConfirmationMailing">
  <div class={styles.block}>
    <div class={styles.header}>
      <div class={styles.title}>
        <span class={styles.type}>{$t("confirmation_mailing.title")}</span>
        <Tooltip message={dateTimeFormat(sent_at)}>
          <span class={styles.meta}>
            {#if sent_at}
              {$t("confirmation_mailing.sent")}
              {distanceOfTime(
                new Date(sent_at).getTime() / 1000,
                config.language,
              )}
            {:else}
              {$t("confirmation_mailing.will_sent")}
            {/if}
            {$t(`confirmation.via.${sent_via}`)}
          </span>
        </Tooltip>
      </div>

      <div class={styles.actions}>
        {#if $isUserTeamUser}
          {#if canBeConfirmed}
            <Button
              style="primary"
              click={confirmMailing.bind(this, permalink, true)}
              disabled={!hasUnacceptedCompanies() || showNoEmailWarning}
              >{$t("confirmation_mailing.release")}</Button
            >
          {/if}
          <Options
            style="no-border acts-as-button left"
            title={$t("actions.options")}
          >
            {#if $can("confirm", "confirmation_mailing") && !sent_at}
              {#if is_confirmed}
                <Button
                  style="blue-text option"
                  click={unConfirmMailing.bind(this, permalink, false)}
                  >{$t("confirmation_mailing.unrelease")}</Button
                >
              {/if}
            {/if}
            {#if $isAccountFeature("xba_mailing_attachments") && !sent_at && sent_via === "email" && $can("update", "confirmation_mailing")}
              <Button
                style="blue-text option"
                click={openMailingAttachmentModal}
              >
                {$t("confirmation_mailing.manage_attachments")}
              </Button>
            {/if}
            {#if mailing_url}
              <Button
                style="blue-text option"
                click={downloadPdf.bind(this, mailing_url)}
                >{$t("confirmation_mailing.download_pdf")}</Button
              >
            {/if}
            {#if !sent_at && $can("update", "confirmation_mailing")}
              <Tooltip
                contentWidth="100%"
                message={attachments.length &&
                  $t("confirmation_mailing.switch_to_postal_tooltip")}
                maxWidth="300px"
                nowrap={false}
              >
                <Button
                  style="blue-text tooltip-option"
                  disabled={(sent_via === "postal" && !$item.email) ||
                    attachments.length}
                  click={switchMailingChannel.bind(this, permalink)}
                >
                  {sent_via === "email"
                    ? $t("confirmation_mailing.switch_to_postal")
                    : $t("confirmation_mailing.switch_to_email")}
                </Button>
              </Tooltip>
            {/if}
            {#if canBeConfirmed}
              <Button
                style="blue-text option"
                click={() => {
                  isMarkAsSendModalVisible = true;
                }}>{$t("confirmation_mailing.mark_as_send")}</Button
              >
            {/if}
            {#if !sent_at && $can("destroy", "confirmation_mailing")}
              <Button
                style="error-text option"
                click={deleteMailing.bind(this, permalink)}
                >{$t("actions.delete")}</Button
              >
            {/if}
          </Options>
        {/if}
      </div>
    </div>

    {#each warnings as warning}
      <div class={styles["toast-margin-top"]}>
        <Toast type="warning" title={warning.title} message={warning.message} />
      </div>
    {/each}

    {#if mailing_url}
      <div class={styles.list}>
        <DocumentThumbnails
          bind:isLoadingThumbnails
          documentUrl={mailing_url}
          documentId={permalink}
          documentTitle={`${$item.company}.pdf`}
          addWatermarks={$isUserClient &&
            $isAccountFeature("mailing_watermarks")}
        />

        {#if !isLoadingThumbnails && attachments.length}
          <div class={styles.attachments}>
            <div class={styles.title}>
              {$t("confirmation_mailing.attachments")}
            </div>

            <div class={styles.group}>
              {#each attachments as attachment}
                <ConfirmationFileItem
                  {attachment}
                  disabled={is_confirmed}
                  tooltipMessage={$t(
                    "confirmation_mailing.delete_button_tooltip",
                  )}
                  buttonTitle={$t("confirmation_mailing.delete_button")}
                  handleDelete={() =>
                    handleAttachmentRemove(
                      attachment.permalink,
                      attachment.filesize,
                    )}
                />
              {/each}
            </div>
          </div>
        {/if}
      </div>
    {:else if $letterhead.url}
      <div class={styles.loader}>
        <div class="{styles.preview} {styles.blank}">
          <img
            class={styles.loading}
            src={imagePath("loading-spinner")}
            alt=""
          />
          {checkStatusPolling(permalink)}
        </div>
      </div>
    {:else}
      <div class={styles["toast-margin-top"]}>
        <Toast
          type="warning"
          message={$t("confirmation_mailing.no_letterhead_desc")}
        />
      </div>
    {/if}
  </div>

  <ConfirmationMailingMarkAsSendModal
    bind:shown={isMarkAsSendModalVisible}
    {permalink}
  />

  <MailingAttachmentModal
    bind:shown={shownMailingAttachmentModal}
    bind:assignedAttachments
    bind:totalFilesSize
    mailingId={permalink}
    isConfirmed={is_confirmed}
  />
</div>

<style lang="scss">
  .block {
    padding: 12px 20px 24px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    display: flex;
    align-items: center;
  }

  .actions {
    display: flex;
    gap: 8px;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    padding-top: 15px;

    .attachments {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 14px;
      width: 100%;

      .title {
        font-size: 18px;
        font-weight: 500;
      }

      .group {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
  .type {
    font-size: 18px;
    margin-right: 10px;
    line-height: 40px;
  }

  .meta {
    font-size: 12px;
    font-weight: 500;
    color: var(--primary-300);
  }

  .loader {
    display: flex;
    flex-wrap: wrap;
  }

  .preview {
    flex: 0 0 calc((100% - 30px) / 3);
    margin-right: 15px;
    margin-top: 15px;
    border-radius: 3px;
    box-shadow: 0 3px 8px 0 rgba(41, 49, 61, 0.06);
    border: solid 1px var(--primary-050);
    overflow: hidden;

    &:nth-child(3n) {
      margin-right: 0;
    }

    .image {
      width: 100%;
      height: auto;
    }
  }

  .blank {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 46%;
  }

  .loading {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .toast-margin-top {
    margin-top: 20px;
  }
</style>
