<script>
  export let width = 18;
  export let height = 18;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 18 18"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M2.035 7.272c-.38 0-.693-.283-.743-.649l-.007-.101V3.107c0-1.247.96-2.269 2.181-2.352L3.627.75h10.745c1.241 0 2.254.97 2.337 2.195l.005.162v3.415c0 .414-.336.75-.75.75-.38 0-.693-.283-.743-.649l-.007-.101V3.107c0-.439-.323-.798-.737-.85l-.105-.007H3.627c-.428 0-.783.325-.835.749l-.007.108v3.415c0 .414-.336.75-.75.75zm6.964 9.978c-.38 0-.694-.283-.743-.649l-.007-.101V8.347l-1.701 1.715c-.265.268-.682.294-.976.078l-.085-.073c-.267-.264-.294-.681-.077-.976l.072-.084 2.98-3.008c.025-.027.053-.051.082-.074l-.078.069c.037-.038.078-.07.12-.099.014-.01.029-.018.043-.026l.043-.023.052-.023.044-.015c.017-.006.036-.011.055-.016l.042-.008c.019-.004.039-.007.059-.009l.045-.003H9.027c.016 0 .031.002.047.003l-.075-.003c.047 0 .093.004.137.012l.035.008c.021.004.042.01.063.017l.04.014.05.022.046.025.041.025.036.025c.036.028.064.052.09.079l2.979 3.008c.291.294.289.769-.005 1.06-.268.265-.685.288-.977.068l-.084-.073-1.701-1.718V16.5c0 .414-.336.75-.75.75z"
  />
</svg>
