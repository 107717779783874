<script>
  import styles from "styleguide/DashboardProjectListSidebar.json";
  import { DashboardProjectListOverview } from "apps/dashboard";
</script>

<div data-component="DashboardProjectListSidebar">
  <div class={styles.item}>
    <DashboardProjectListOverview />
  </div>
</div>

<style lang="scss">
  .item {
    margin-bottom: 25px;
  }
</style>
