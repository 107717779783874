<script>
  export let width = 18;
  export let height = 18;
  export let fill = "var(--blue-100)";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 18 18"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M15.6 0c.414 0 .75.336.75.75v16.5c0 .414-.336.75-.75.75H4.462C2.91 18 1.65 16.74 1.65 15.188V2.813C1.65 1.26 2.91 0 4.462 0zm-.75 13.874H4.462c-.68 0-1.24.518-1.307 1.18l-.006.134c.003.728.59 1.312 1.312 1.312H14.85v-2.626zm0-12.374H4.462c-.68 0-1.239.517-1.306 1.179l-.007.134V12.7c.344-.182.731-.296 1.142-.32l.172-.005h10.386L14.85 1.5zm-4.567 5.938c1.21 0 2.217.916 2.217 2.083v.667c0 .414-.336.75-.75.75h-5.5c-.414 0-.75-.336-.75-.75V9.52c0-1.168 1.007-2.083 2.217-2.083zM9 2.938c.967 0 1.75.783 1.75 1.75 0 .966-.784 1.75-1.75 1.75-.967 0-1.75-.784-1.75-1.75 0-.968.783-1.75 1.75-1.75z"
    transform="translate(-931 -582) translate(903 244) translate(0 321) translate(22 11) translate(6 6)"
  />
</svg>
