<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(100%,0%,0%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,60%,0%);fill-opacity:1;"
      d="M 0 360 L 697.585938 239.996094 L 697.585938 360 Z M 0 360 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 360 L 697.585938 120.003906 L 697.585938 240.003906 Z M 0 360 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,60%);fill-opacity:1;"
      d="M 0 360 L 0 0 L 232.53125 0 Z M 0 360 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 0 360 L 232.53125 0 L 465.0625 0 Z M 0 360 "
    />
  </g>
</svg>
