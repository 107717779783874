<script>
  import styles from "styleguide/ConfirmationSignerEmpty.json";
  import { t } from "stores/i18n.js";
</script>

<div data-component="ConfirmationSignerEmpty" class={styles.container}>
  <div class={styles.block}>
    <h2 class={styles.title}>{$t("confirmation_signer_empty.title")}</h2>
  </div>
</div>

<style lang="scss">
  .block {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 60px 0;
    text-align: center;
  }

  .container {
    padding: 0 35px;
  }

  .title {
    font-size: 22px;
    font-weight: 600;
    line-height: 1.3;
    color: var(--primary-500);
  }
</style>
