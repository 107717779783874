<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M -30 118.28125 L 509.984375 118.28125 L 509.984375 239.136719 L -30 239.136719 Z M -30 118.28125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M -30 239.136719 L 509.984375 239.136719 L 509.984375 359.996094 L -30 359.996094 Z M -30 239.136719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M -30 0 L 509.984375 0 L 509.984375 118.28125 L -30 118.28125 Z M -30 0 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(84.313725%,72.54902%,0%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 322.441778 -322.437517 L 329.523418 -322.44355 L 333.280635 -262.202039 L 325.98388 -255.117738 L 318.896787 -262.206381 Z M 322.441778 -322.437517 "
      transform="matrix(0.439663,-0.0232498,0.0415069,0.550607,105.895156,366.06035)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(84.313725%,72.54902%,0%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 322.437167 -322.438593 L 329.529109 -322.441899 L 333.274846 -262.204018 L 325.98187 -255.113958 L 318.895123 -262.205618 Z M 322.437167 -322.438593 "
      transform="matrix(0.440076,0.016781,-0.0299589,0.551129,76.743975,353.173453)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(84.313725%,72.54902%,0%);fill-opacity:1;stroke-width:4.8244;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 177.158232 255.13312 L 159.436243 698.039566 L 194.880221 662.595709 L 212.602211 255.104764 L 177.158232 255.104764 Z M 177.158232 255.13312 "
      transform="matrix(-0.193527,0,0,0.137762,310.226348,114.000819)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(84.313725%,72.54902%,0%);fill-opacity:1;stroke-width:4.8244;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 177.167831 290.559211 L 159.445841 698.0218 L 194.869636 662.606297 L 212.591625 290.559211 Z M 177.167831 290.559211 "
      transform="matrix(-0.193527,0,0,0.137762,303.368831,109.116548)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(84.313725%,72.54902%,0%);fill-opacity:1;stroke-width:4.8244;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 177.17743 325.987718 L 159.45544 698.034805 L 194.879234 662.590947 L 212.601224 325.987718 Z M 177.17743 325.987718 "
      transform="matrix(-0.193527,0,0,0.137762,296.511313,104.239756)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(84.313725%,72.54902%,0%);fill-opacity:1;stroke-width:4.8244;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 177.166844 361.41381 L 159.444855 698.045394 L 194.868649 662.601536 L 212.590638 361.41381 Z M 177.166844 361.41381 "
      transform="matrix(-0.193527,0,0,0.137762,289.653796,99.355485)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(84.313725%,72.54902%,0%);fill-opacity:1;stroke-width:4.8244;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 177.161164 255.126216 L 159.44771 698.032663 L 194.874618 662.588805 L 212.588072 255.126216 Z M 177.161164 255.126216 "
      transform="matrix(0.17223,0,0,0.137762,161.901665,113.958801)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(84.313725%,72.54902%,0%);fill-opacity:1;stroke-width:4.8244;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 177.153692 290.552308 L 159.440238 698.043252 L 194.867146 662.599394 L 212.603281 290.552308 Z M 177.153692 290.552308 "
      transform="matrix(0.17223,0,0,0.137762,168.004514,109.07453)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(84.313725%,72.54902%,0%);fill-opacity:1;stroke-width:4.8244;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 177.171039 325.980815 L 159.457585 698.027901 L 194.884493 662.584044 L 212.597947 325.980815 Z M 177.171039 325.980815 "
      transform="matrix(0.17223,0,0,0.137762,174.099183,104.197739)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(84.313725%,72.54902%,0%);fill-opacity:1;stroke-width:4.8244;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 177.163186 361.416151 L 159.449732 698.047734 L 194.87664 662.603877 L 212.590094 361.416151 Z M 177.163186 361.416151 "
      transform="matrix(0.17223,0,0,0.137762,180.463816,99.956725)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:4.8244;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 181.733374 256.061859 C 180.72564 254.730542 201.573136 237.395098 237.011778 237.395098 C 290.169742 237.395098 298.315591 256.061859 333.754233 256.061859 L 485.775092 256.061859 C 521.213735 256.061859 531.501018 237.395098 584.637987 237.395098 C 620.07663 237.395098 636.809212 255.693622 637.795951 256.061859 C 637.795951 256.061859 620.07663 255.127104 584.637987 255.127104 C 549.220339 255.127104 535.343004 294.160179 499.589444 293.338729 C 472.800518 294.160179 355.357529 293.338729 319.939881 293.338729 C 284.501238 293.338729 272.45042 256.061859 237.011778 256.061859 C 201.573136 256.061859 180.72564 254.730542 181.733374 256.061859 Z M 181.733374 256.061859 "
      transform="matrix(0.186061,0,0,0.137904,158.11619,113.832607)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 400.390526 -109.839963 L 400.390526 -106.298256 L 403.934016 -102.752077 L 400.390526 -99.21037 L 400.390526 -95.668663 C 386.216566 -88.580777 371.542205 -88.580777 357.868647 -88.580777 C 344.199156 -88.580777 329.524795 -88.580777 311.807346 -95.668663 L 311.807346 -99.21037 L 308.263856 -102.752077 L 311.807346 -106.298256 L 311.807346 -109.839963 C 325.981305 -106.298256 340.155265 -102.752077 357.868647 -102.752077 C 375.586096 -102.752077 386.216566 -106.298256 400.390526 -109.839963 Z M 400.390526 -109.839963 "
      transform="matrix(0.960167,0,0,0.873519,-107.430238,311.377031)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.62344;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -70.764555 -406.532148 C -69.439628 -406.529848 -68.369037 -404.514534 -68.371244 -402.026234 L -68.367766 -370.905125 C -68.369973 -368.416825 -69.44233 -366.399785 -70.766295 -366.397428 C -72.086069 -366.40138 -73.15666 -368.416694 -73.159607 -370.903342 L -73.157931 -402.026103 C -73.160878 -404.512751 -72.088521 -406.529791 -70.764555 -406.532148 Z M -70.764555 -406.532148 "
      transform="matrix(0.252186,-0.710789,0.786717,0.146935,538.968347,224.189094)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:4.7128;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.891316 574.020101 L 336.610794 591.737473 L 354.33902 574.015023 L 372.058498 591.732396 L 389.759637 574.026082 L 407.473862 591.731569 C 412.754093 582.310079 414.574356 568.85369 407.381339 555.210958 C 371.19594 541.391537 338.561125 542.573803 300.191137 556.406449 C 297.09472 567.439916 297.212884 580.327184 300.177136 591.834386 Z M 318.891316 574.020101 "
      transform="matrix(0.114157,0.0408176,-0.0504535,0.310616,206.084865,19.617495)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:4.7128;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.90602 574.016881 L 336.625497 591.734253 L 354.321383 574.016053 L 372.040861 591.733426 L 389.77434 574.022862 L 407.493818 591.740234 C 412.736456 582.311109 414.589059 568.85047 407.363702 555.211987 C 371.183557 541.404452 338.543488 542.574832 300.20584 556.403229 C 297.109423 567.436696 297.23284 580.33585 300.197092 591.843051 Z M 318.90602 574.016881 "
      transform="matrix(0.114157,0.0408176,-0.0504535,0.310616,207.036149,13.824927)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:4.7128;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.888383 574.017911 L 336.607861 591.735283 L 354.341339 574.024719 L 372.055564 591.730206 L 389.756703 574.023892 L 407.476181 591.741264 C 412.75116 582.307889 414.571422 568.851499 407.383658 555.220653 C 371.19826 541.401232 338.558191 542.571613 300.188203 556.404259 C 297.091786 567.437726 297.215203 580.33688 300.179455 591.844081 Z M 318.888383 574.017911 "
      transform="matrix(0.114157,0.0408176,-0.0504535,0.310616,207.987433,8.032358)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:4.7128;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.895448 574.025129 L 336.609673 591.730616 L 354.343152 574.020052 L 372.06263 591.737425 L 389.758516 574.019225 L 407.477993 591.736597 C 412.752972 582.303222 414.573235 568.846833 407.385471 555.215986 C 371.200072 541.396565 338.527663 542.571195 300.190016 556.399592 C 297.093599 567.433059 297.217016 580.332213 300.181267 591.839414 Z M 318.895448 574.025129 "
      transform="matrix(0.114157,0.0408176,-0.0504535,0.310616,209.225272,0.88139)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:4.7128;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.909555 574.016111 L 336.629033 591.733484 L 354.324919 574.015284 L 372.044397 591.732656 L 389.777876 574.022092 L 407.497353 591.739465 C 412.739992 582.310339 414.560255 568.85395 407.367238 555.211218 C 371.187092 541.403682 338.547023 542.574063 300.177036 556.406709 C 297.112959 567.435927 297.204036 580.33933 300.200627 591.842282 Z M 318.909555 574.016111 "
      transform="matrix(0.114157,0.0408176,-0.0504535,0.310616,210.176332,-4.909354)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:4.7128;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.891918 574.017141 L 336.611396 591.734513 L 354.344875 574.023949 L 372.0591 591.729436 L 389.760239 574.023122 L 407.479717 591.740494 C 412.754695 582.307119 414.574958 568.85073 407.387194 555.219883 C 371.201795 541.400462 338.529387 542.575093 300.191739 556.403489 C 297.095322 567.436956 297.218739 580.33611 300.18299 591.843312 Z M 318.891918 574.017141 "
      transform="matrix(0.114157,0.0408176,-0.0504535,0.310616,211.127616,-10.701922)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 345.631106 -96.124189 C 345.631118 -92.842151 344.512587 -90.188065 343.130368 -90.185666 C 341.74931 -90.190199 340.628526 -92.843655 340.628513 -96.125693 C 340.628501 -99.407731 341.747032 -102.061817 343.129251 -102.064216 C 344.510309 -102.059683 345.631093 -99.406227 345.631106 -96.124189 Z M 345.631106 -96.124189 "
      transform="matrix(-0.469264,-0.174289,-0.0785508,0.534396,382.39952,334.006594)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 345.627522 -96.126583 C 345.629759 -92.846043 344.513799 -90.189103 343.128167 -90.1908 C 341.750336 -90.189765 340.632706 -92.847278 340.630469 -96.127818 C 340.627417 -99.402018 341.751993 -102.062566 343.129824 -102.063601 C 344.507655 -102.064636 345.633086 -99.404391 345.627522 -96.126583 Z M 345.627522 -96.126583 "
      transform="matrix(-0.479174,-0.206517,-0.0615914,0.589554,375.030915,348.210065)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 345.627069 -96.123398 C 345.632562 -92.843639 344.509954 -90.190688 343.129934 -90.187279 C 341.750983 -90.190307 340.626482 -92.845153 340.628339 -96.122508 C 340.631266 -99.406301 341.746524 -102.061655 343.133894 -102.062661 C 344.512845 -102.059633 345.629996 -99.407191 345.627069 -96.123398 Z M 345.627069 -96.123398 "
      transform="matrix(-0.504049,-0.188227,-0.083718,0.575577,369.400165,338.339618)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:4.8244;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 268.285883 261.841687 C 268.285883 261.841687 301.1799 219.692765 301.1799 166.525071 C 301.1799 166.525071 372.054099 166.525071 372.054099 166.525071 C 372.054099 219.692765 406.346375 261.841687 407.48246 255.128452 C 389.75954 255.128452 354.33118 237.396445 336.608261 201.960758 C 318.90282 237.396445 286.008802 261.841687 268.285883 261.841687 Z M 268.285883 261.841687 "
      transform="matrix(0.223492,0,0,0.137904,157.680773,118.172265)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:4.8244;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 253.467019 240.414219 C 253.467019 240.414219 301.404885 181.694651 301.404885 128.526958 L 301.404885 53.944892 C 301.404885 36.241211 325.364714 35.306457 337.362836 35.306457 C 355.077816 35.306457 373.30258 36.241211 373.30258 53.944892 L 373.30258 128.526958 C 373.30258 181.666325 421.240445 240.414219 421.240445 240.414219 C 385.810485 240.414219 367.057731 201.26784 349.34275 165.832153 C 349.34275 183.535834 354.331347 219.679668 336.616367 237.411675 C 318.901386 219.679668 325.364714 183.535834 325.364714 165.832153 C 307.649734 201.26784 288.89698 240.414219 253.467019 240.414219 Z M 253.467019 240.414219 "
      transform="matrix(0.214552,0,0,0.137904,160.692409,118.556884)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:5.9087;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 283.115692 77.941524 L 336.259186 77.951803 C 336.273611 95.678758 336.259808 113.37871 318.551773 113.371053 C 300.824348 113.376087 283.130117 95.668479 283.115692 77.941524 Z M 283.115692 77.941524 "
      transform="matrix(0.175829,0.0562138,-0.0391462,0.0858933,174.154057,99.913771)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:4.8244;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 183.960786 42.514466 C 195.766039 42.522523 195.775656 60.249479 195.766454 77.949429 C 178.055056 77.963536 166.240186 60.228524 148.519586 77.942581 C 148.519922 58.386739 148.519172 42.515675 166.249388 42.528573 Z M 183.960786 42.514466 "
      transform="matrix(0.263747,0.0843185,-0.0391462,0.0858933,180.953822,103.825269)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:4.8244;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 336.597097 69.084684 C 336.613223 73.989266 332.65217 77.96108 327.75873 77.943501 C 322.854737 77.960846 318.885798 73.992622 318.8979 69.106043 C 318.901165 64.193001 322.862218 60.221186 327.755657 60.238765 C 332.640259 60.229881 336.609199 64.198106 336.597097 69.084684 Z M 336.597097 69.084684 "
      transform="matrix(0.175829,0.0562138,-0.0587189,0.12884,178.38486,98.302728)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(84.313725%,72.54902%,0%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 322.441827 -322.436613 L 329.526634 -322.436613 L 333.277414 -262.204038 L 325.979797 -255.116603 L 318.89499 -262.204038 Z M 322.441827 -322.436613 "
      transform="matrix(0.440533,0,0,0.551703,89.262219,358.826619)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.62344;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -70.765399 -406.527848 C -69.439772 -406.530551 -68.370168 -404.511427 -68.37043 -402.0253 L -68.367934 -370.903078 C -68.368197 -368.416951 -69.443845 -366.400628 -70.764305 -366.399484 C -72.085562 -366.402611 -73.159536 -368.415905 -73.16007 -370.906304 L -73.156603 -402.025811 C -73.15634 -404.511938 -72.086656 -406.530975 -70.765399 -406.527848 Z M -70.765399 -406.527848 "
      transform="matrix(-0.261076,-0.715781,-0.865769,0.133478,-105.129784,218.129538)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:4.7128;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.898447 574.022425 L 336.602152 591.73633 L 354.321176 574.027522 L 372.05445 591.737739 L 389.768866 574.016977 L 407.477179 591.742837 C 412.738282 582.316063 414.580562 568.842654 407.378147 555.219263 C 371.188068 541.402159 338.545017 542.578984 300.180208 556.40999 C 297.11354 567.429972 297.221248 580.334419 300.199066 591.84097 Z M 318.898447 574.022425 "
      transform="matrix(-0.126052,0.0388823,0.0485769,0.311765,264.293897,19.124731)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:4.7128;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.90096 574.014971 L 336.609273 591.740831 L 354.32369 574.020068 L 372.056964 591.730286 L 389.775987 574.021478 L 407.479693 591.735383 C 412.740795 582.308609 414.587683 568.847155 407.380661 555.211809 C 371.190582 541.394705 338.547531 542.571531 300.182722 556.402536 C 297.091092 567.438161 297.223761 580.326965 300.20158 591.833516 Z M 318.90096 574.014971 "
      transform="matrix(-0.126052,0.0388823,0.0485769,0.311765,263.376607,13.310551)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:4.7128;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.908081 574.019472 L 336.611787 591.733377 L 354.33081 574.024569 L 372.034516 591.738474 L 389.778501 574.014024 L 407.486814 591.739884 C 412.747916 582.31311 414.565235 568.855343 407.358214 555.219998 C 371.197703 541.399206 338.554652 542.576032 300.189843 556.407037 C 297.098213 567.442662 297.201314 580.335154 300.179133 591.841705 Z M 318.908081 574.019472 "
      transform="matrix(-0.126052,0.0388823,0.0485769,0.311765,262.459317,7.496371)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:4.7128;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.885966 574.019203 L 336.61924 591.729421 L 354.338264 574.020613 L 372.04197 591.734518 L 389.760993 574.02571 L 407.494267 591.735928 C 412.725801 582.312841 414.572689 568.851387 407.365667 555.216041 C 371.175588 541.398937 338.532537 542.575763 300.197296 556.403081 C 297.105666 567.438705 297.208767 580.331197 300.186586 591.837748 Z M 318.885966 574.019203 "
      transform="matrix(-0.126052,0.0388823,0.0485769,0.311765,261.257324,0.317627)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:4.7128;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.892491 574.017906 L 336.630372 591.740078 L 354.344788 574.019316 L 372.048494 591.73322 L 389.767518 574.024413 L 407.471224 591.738318 C 412.732326 582.311544 414.579213 568.85009 407.372192 555.214744 C 371.182112 541.39764 338.539062 542.574466 300.203821 556.401783 C 297.112191 567.437408 297.215292 580.3299 300.193111 591.836451 Z M 318.892491 574.017906 "
      transform="matrix(-0.126052,0.0388823,0.0485769,0.311765,260.340241,-5.494722)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:4.7128;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.899612 574.022407 L 336.603318 591.736312 L 354.322341 574.027504 L 372.055615 591.737721 L 389.770031 574.016959 L 407.473737 591.730864 C 412.739447 582.316045 414.581727 568.842636 407.379312 555.219245 C 371.189233 541.402141 338.546182 542.578966 300.181374 556.409972 C 297.114705 567.429954 297.222413 580.334401 300.200231 591.840952 Z M 318.899612 574.022407 "
      transform="matrix(-0.126052,0.0388823,0.0485769,0.311765,259.422951,-11.308902)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 345.630091 -96.127741 C 345.632393 -92.841653 344.512208 -90.187864 343.132726 -90.185988 C 341.746028 -90.186349 340.62914 -92.849326 340.63305 -96.126202 C 340.629745 -99.405317 341.74993 -102.059106 343.129413 -102.060983 C 344.508895 -102.062859 345.626786 -99.406856 345.630091 -96.127741 Z M 345.630091 -96.127741 "
      transform="matrix(0.518298,-0.16634,0.0745265,0.536231,64.153428,330.749587)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 345.632507 -96.123976 C 345.633042 -92.846259 344.510839 -90.189898 343.131598 -90.183694 C 341.745892 -90.186269 340.627504 -92.848017 340.62697 -96.125733 C 340.626435 -99.40345 341.748638 -102.059811 343.127879 -102.066014 C 344.513585 -102.06344 345.631973 -99.401692 345.632507 -96.123976 Z M 345.632507 -96.123976 "
      transform="matrix(0.529863,-0.198426,0.0547345,0.591145,71.924306,345.093035)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 345.632776 -96.127471 C 345.628795 -92.848348 344.512841 -90.185737 343.131169 -90.189507 C 341.748575 -90.1868 340.633012 -92.847046 340.630274 -96.12826 C 340.626613 -99.402996 341.749286 -102.063516 343.130958 -102.059746 C 344.513552 -102.062453 345.629115 -99.402207 345.632776 -96.127471 Z M 345.632776 -96.127471 "
      transform="matrix(0.55674,-0.179689,0.0792982,0.577537,78.312266,335.311018)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:4.8244;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(84.313725%,72.54902%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.190761 290.540913 C 301.190761 485.415929 336.604969 467.712893 407.47708 538.581685 C 478.34919 467.712893 513.785246 485.415929 513.785246 290.540913 C 478.34919 290.540913 442.913135 290.540913 407.47708 272.837877 C 372.041024 290.540913 336.604969 290.540913 301.190761 290.540913 Z M 301.190761 290.540913 "
      transform="matrix(0.178799,0,0,0.137909,160.057496,114.119321)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(84.313725%,72.54902%,0%);fill-opacity:1;"
      d="M 215.234375 155.15625 C 215.234375 179.800781 221.128906 177.5625 232.914062 186.523438 C 244.699219 177.558594 250.59375 179.800781 250.59375 155.15625 C 244.699219 155.15625 238.808594 155.15625 232.914062 152.914062 C 227.023438 155.15625 221.128906 155.15625 215.234375 155.15625 Z M 215.234375 155.15625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 226.671875 155.476562 L 226.671875 181.15625 L 233.007812 184.886719 L 239.34375 181.15625 L 239.34375 155.476562 C 237.082031 155.191406 234.902344 154.679688 233.019531 154.089844 C 230.921875 154.71875 228.789062 155.210938 226.671875 155.476562 Z M 226.671875 155.476562 "
    />
  </g>
</svg>
