<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,20%,9.803922%);fill-opacity:1;"
      d="M 159.996094 0 L 480 0 L 480 180 L 159.996094 180 Z M 159.996094 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,80%,15.686275%);fill-opacity:1;"
      d="M 159.996094 180 L 480 180 L 480 360 L 159.996094 360 Z M 159.996094 180 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 0 L 159.996094 0 L 159.996094 360 L 0 360 Z M 0 0 "
    />
  </g>
</svg>
