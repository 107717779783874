<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M -28.335938 0 L 508.335938 0 L 508.335938 359.640625 L -28.335938 359.640625 Z M -28.335938 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M -27.148438 0.433594 L 507.84375 0.433594 L 507.84375 152.371094 L -27.148438 152.371094 Z M -27.148438 0.433594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,38.039216%,100%);fill-opacity:1;"
      d="M 71.011719 142.894531 L 426.097656 142.894531 L 426.097656 222.925781 L 71.011719 222.925781 Z M 71.011719 142.894531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(88.627451%,0%,0%);fill-opacity:1;"
      d="M 507.972656 1.234375 L 507.972656 360.003906 L 240.277344 360.003906 Z M 507.972656 1.234375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(88.627451%,0%,0%);fill-opacity:1;"
      d="M -27.417969 1.234375 L -27.417969 360.003906 L 240.277344 360.003906 Z M -27.417969 1.234375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,83.921569%,0%);fill-opacity:1;"
      d="M 365.671875 142.972656 L 312.015625 129.359375 L 357.664062 94.921875 L 301.605469 102.929688 L 330.4375 54.082031 L 280.785156 82.910156 L 289.59375 26.050781 L 255.960938 70.898438 L 242.34375 18.84375 L 227.128906 72.5 L 193.492188 26.851562 L 203.101562 85.3125 L 153.453125 54.882812 L 182.28125 103.730469 L 127.824219 94.921875 L 172.671875 128.558594 L 116.613281 142.972656 L 365.675781 142.972656 Z M 365.671875 142.972656 "
    />
  </g>
</svg>
