import { Controller } from "stimulus";
import Rails from "rails-ujs";

export default class extends Controller {
  static targets = [];

  fileSelected(event) {
    Rails.fire(this.element, "submit");
    $(this.element).find("label").addClass("loading");
  }
}
