<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(0%,0%,40%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 0 L 0 13.417969 L 213.167969 120.003906 L 240 120.003906 L 240 106.585938 L 26.835938 0 Z M 240 0 L 240 13.417969 L 26.835938 120 L 0 120 L 0 106.585938 L 213.167969 0 Z M 240 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 100 0 L 100 120 L 140 120 L 140 0 Z M 0 40 L 0 80 L 240 80 L 240 40 Z M 0 40 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 0 48 L 0 72 L 240 72 L 240 48 Z M 108 0 L 108 120 L 132 120 L 132 0 Z M 108 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 0 120 L 80 80 L 97.890625 80 L 17.886719 120 Z M 0 0 L 80 40 L 62.109375 40 L 0 8.945312 Z M 142.109375 40 L 222.109375 0 L 240 0 L 160 40 Z M 240 120 L 160 80 L 177.890625 80 L 240 111.054688 Z M 240 120 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 363.214844 93.820312 L 368.28125 109.417969 L 384.683594 109.417969 L 371.414062 119.058594 L 376.480469 134.65625 L 363.214844 125.023438 L 349.945312 134.664062 L 355.011719 119.066406 L 341.746094 109.425781 L 358.144531 109.425781 Z M 363.214844 93.820312 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 363.214844 98.613281 L 367.207031 110.898438 L 380.125 110.898438 L 369.671875 118.492188 L 373.664062 130.777344 L 363.214844 123.1875 L 352.765625 130.777344 L 356.753906 118.492188 L 346.304688 110.898438 L 359.222656 110.898438 Z M 363.214844 98.613281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 285.074219 172.441406 L 290.140625 188.039062 L 306.542969 188.039062 L 293.273438 197.679688 L 298.34375 213.28125 L 285.074219 203.648438 L 271.804688 213.289062 L 276.875 197.6875 L 263.605469 188.046875 L 280.003906 188.046875 Z M 285.074219 172.441406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 285.074219 177.238281 L 289.066406 189.523438 L 301.984375 189.523438 L 291.53125 197.113281 L 295.523438 209.398438 L 285.074219 201.808594 L 274.625 209.398438 L 278.613281 197.113281 L 268.164062 189.519531 L 281.082031 189.519531 Z M 285.074219 177.238281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 430.191406 155.726562 L 434.433594 168.78125 L 448.160156 168.78125 L 437.054688 176.851562 L 441.296875 189.90625 L 430.183594 181.835938 L 419.078125 189.90625 L 423.320312 176.847656 L 412.210938 168.777344 L 425.941406 168.777344 Z M 430.191406 155.726562 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 430.191406 159.800781 L 433.515625 170.039062 L 444.28125 170.039062 L 435.574219 176.367188 L 438.898438 186.605469 L 430.191406 180.28125 L 421.480469 186.605469 L 424.808594 176.371094 L 416.101562 170.042969 L 426.863281 170.042969 Z M 430.191406 159.800781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 363.214844 294.484375 L 368.789062 311.636719 L 386.820312 311.636719 L 372.234375 322.238281 L 377.804688 339.386719 L 363.214844 328.78125 L 348.625 339.378906 L 354.199219 322.230469 L 339.609375 311.628906 L 357.640625 311.628906 Z M 363.214844 294.484375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 363.214844 298.566406 L 367.871094 312.898438 L 382.941406 312.898438 L 370.75 321.757812 L 375.40625 336.089844 L 363.214844 327.234375 L 351.023438 336.089844 L 355.679688 321.757812 L 343.488281 312.902344 L 358.558594 312.902344 Z M 363.214844 298.566406 "
    />
  </g>
</svg>
