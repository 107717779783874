<script>
  export let width = 18;
  export let height = 18;
  export let fill = "#fff";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 18 18"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M0.750371366,4.47355475 L0.750336715,4.46064887 C0.771356966,3.23936745 1.77386255,2.2498 3,2.2498 L3,2.2498 L15,2.2498 C16.2392136,2.2498 17.25,3.26058644 17.25,4.4998 L17.25,4.4998 L17.25,13.4998 C17.25,14.7390136 16.2392136,15.7498 15,15.7498 L15,15.7498 L3,15.7498 C1.76078644,15.7498 0.75,14.7390136 0.75,13.4998 L0.75,13.4998 L0.750371366,4.47355475 L0.750371366,4.47355475 Z M15.75,5.9398 L9.43009676,10.3642239 C9.20413666,10.522396 8.91273616,10.5421675 8.67041596,10.4235385 L8.56990324,10.3642239 L2.25,5.9408 L2.25,13.4998 C2.25,13.8763542 2.53503362,14.1927674 2.89894938,14.2428879 L3,14.2498 L15,14.2498 C15.4107864,14.2498 15.75,13.9105864 15.75,13.4998 L15.75,13.4998 L15.75,5.9398 Z M15,3.7498 L3,3.7498 C2.70882954,3.7498 2.45361835,3.92022592 2.32967804,4.16576615 L9,8.834 L15.670322,4.16576615 C15.5463817,3.92022592 15.2911705,3.7498 15,3.7498 L15,3.7498 Z"
  />
</svg>
