import Cookies from "js-cookie";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["wrapper", "id", "until"];

  connect() {
    this.id = this.idTarget.value;
    this.until = new Date(this.untilTarget.value + 1);

    if (Cookies.get(`announcement_${this.id}`) !== "closed") {
      this.wrapperTarget.style.display = "block";
    }
  }

  hide() {
    this.wrapperTarget.style.display = "none";
    document.cookie = `announcement_${this.id}=closed;expires=${this.until};path=/;secure`;
  }
}
