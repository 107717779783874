<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 735.398438 360 L 1.164062 360 L 1.164062 1.328125 L 735.398438 1.328125 Z M 735.398438 360 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,91.372549%,0%);fill-opacity:1;"
      d="M 735.398438 258.359375 L 1.164062 258.359375 L 1.164062 107.476562 L 735.398438 107.476562 Z M 735.398438 258.359375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(3.137255%,80.784314%,83.921569%);fill-opacity:1;"
      d="M 735.398438 360 L 1.164062 360 L 1.164062 240.441406 L 735.398438 240.441406 Z M 735.398438 360 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(3.137255%,80.784314%,83.921569%);fill-opacity:1;"
      d="M 735.398438 120.984375 L 1.164062 120.984375 L 1.164062 1.425781 L 735.398438 1.425781 Z M 735.398438 120.984375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 0.816406 0 C 2.445312 0 294.597656 177.613281 294.597656 177.613281 L -0.00390625 359.398438 L 0.8125 0 Z M 0.816406 0 "
    />
  </g>
</svg>
