<script>
  import styles from "styleguide/ConfirmationSignersEdit.json";
  import { onMount } from "svelte";
  import { get } from "svelte/store";

  import { t } from "stores/i18n.js";
  import { toasts } from "stores/toasts.js";
  import { confirmationSigners } from "stores/confirmation/confirmation_signer.js";
  import {
    confirmationId,
    confirmationsItems,
    activeSignersEdit,
    getConfirmation,
  } from "stores/confirmation/confirmation.js";

  import { validate } from "lib/validate.js";
  import ConfirmationSignersApi from "apis/confirmation/confirmation_signers.js";

  import { ConfirmationSignersNewEmail } from "apps/confirmation";

  import { Button, Toast } from "components";
  import { Modal } from "components/modals";

  let activeSignerItemId = null;
  let isNewSignerItem = false;
  let usersMaxLimit = [];
  let groupedConfirmations = [];
  let isNewSignerTypesOpened = true;
  let newSignerEmail;
  let newConfirmationSigner = null;
  let dirty = false;
  let selectedCheckboxIds = null;
  let confirmationItemType;

  function handleCheckboxSelected(event) {
    selectedCheckboxIds = event.detail;

    if (newConfirmationSigner) {
      newConfirmationSigner = {
        ...newConfirmationSigner,
        confirmation_item_ids: selectedCheckboxIds,
      };
    }
  }

  function getFilteredItemIdsArray(array) {
    return array.filter((item) => item !== null).length;
  }

  function onModalShow() {
    getConfirmation();
    getConfirmationAssignments();
  }

  function toggleNewSigner() {
    isNewSignerItem = true;
  }

  function toggleSignerTypes(itemId) {
    isNewSignerTypesOpened = false;

    if (activeSignerItemId === itemId) {
      activeSignerItemId = null;
    } else {
      activeSignerItemId = itemId;
    }
  }

  function toggleNewSignerTypes() {
    activeSignerItemId = null;
    isNewSignerTypesOpened = !isNewSignerTypesOpened;
  }

  function onHideNewSigner() {
    isNewSignerItem = false;
    newConfirmationSigner = null;
    newSignerEmail = "";
    groupedConfirmations = [];
  }

  function onInputChange(event) {
    newSignerEmail = event.target.value;

    newConfirmationSigner = {
      ...newConfirmationSigner,
      email: newSignerEmail,
    };
  }

  const onHide = function () {
    activeSignersEdit.set(false);
    dirty = false;
    groupedConfirmations = [];
    usersMaxLimit = [];
  };

  function getConfirmationAssignments() {
    ConfirmationSignersApi.getAssignments({
      params: {
        confirmation_id: $confirmationId,
      },
      success: function (data) {
        confirmationSigners.set(data.confirmation_signers);
      },
    });
  }

  function onRemove(id) {
    if (confirm($t("questions.sure"))) {
      ConfirmationSignersApi.deleteFromConfirmation({
        params: {
          id,
          confirmation_id: $confirmationId,
          confirmation_type: "all",
        },
        success: () => {
          getConfirmation();
          getConfirmationAssignments();
        },
      });
    }
  }

  function checkValid(newSigner) {
    dirty = true;
    return !(
      newSigner &&
      validate(newSigner.email, ["email"]) &&
      validate(newSigner.email, ["email"]).length
    );
  }

  function submit() {
    if (!checkValid(newConfirmationSigner)) return;

    ConfirmationSignersApi.batchUpdate({
      params: {
        confirmation_id: $confirmationId,
        confirmation_signers: [
          ...$confirmationSigners,
          ...(newConfirmationSigner !== null ? [newConfirmationSigner] : []),
        ],
      },
      success: () => {
        activeSignersEdit.set(false);
        usersMaxLimit = [];
        onHideNewSigner();
        getConfirmation();
        getConfirmationAssignments();
        toasts.send({
          title: get(t)("confirmation_signers_edit.success"),
          type: "success",
        });
      },
      error: (error) => {
        usersMaxLimit = $confirmationsItems.filter((item) =>
          error.overflowed_confirmation_items?.includes(item.permalink),
        );
      },
    });
  }

  onMount(async () => {
    getConfirmationAssignments();
  });
</script>

{#if $activeSignersEdit}
  <Modal
    {onHide}
    onShow={onModalShow}
    shown={$activeSignersEdit}
    title={$t("confirmation_signers_edit.title")}
    subtitle={$t("confirmation_signers_edit.subtitle")}
    scrollOverflowInsideModal={true}
  >
    <div class={styles.body} data-component="ConfirmationSignersEdit">
      {#if usersMaxLimit.length > 0}
        <Toast
          type="error"
          title={$t("confirmation_signers_edit.user_max_limit_title")}
        >
          <div>
            {$t("confirmation_signers_edit.user_max_limit_desc_part_one")}
          </div>
          <ul>
            {#each usersMaxLimit as userLimit}
              <li>
                {userLimit.company}, {userLimit.city}
              </li>
            {/each}
          </ul>
          <div>
            {$t("confirmation_signers_edit.user_max_limit_desc_part_two")}
          </div>
        </Toast>
      {/if}

      <div class={styles.content}>
        <Button style={"primary-tonal"} click={() => toggleNewSigner()}>
          {$t("confirmation_signers_edit.add_signer_btn")}
        </Button>
      </div>

      {#each $confirmationSigners as signer}
        <ConfirmationSignersNewEmail
          {signer}
          email={signer.email}
          isTypesOpen={activeSignerItemId === signer.id}
          confirmationCount={getFilteredItemIdsArray(
            signer.confirmation_item_ids,
          )}
          onRemove={() => onRemove(signer.id)}
          onToggleOpen={() => toggleSignerTypes(signer.id)}
          on:selectedIdsChanged={handleCheckboxSelected}
        />
      {/each}

      {#if isNewSignerItem}
        <ConfirmationSignersNewEmail
          email={newSignerEmail}
          {dirty}
          isTypesOpen={isNewSignerTypesOpened}
          onRemove={() => onHideNewSigner()}
          onToggleOpen={() => toggleNewSignerTypes()}
          onInputChange={(event) => onInputChange(event)}
          on:selectedIdsChanged={handleCheckboxSelected}
        />
      {/if}
    </div>

    <svelte:fragment slot="bottom">
      <Button style={"primary"} click={submit}
        >{$t("confirmation_signers_edit.done")}</Button
      >
    </svelte:fragment>
  </Modal>
{/if}

<style lang="scss">
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    font-weight: 500;
    font-size: 16px;
  }

  .inner {
    margin: 16px 0;
    border-radius: 8px;
    border: 1px solid var(--primary-050);
  }

  ul {
    display: block;
    list-style: disc;
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 6px 12px 16px;
  }

  .buttons {
    display: flex;
  }

  .input {
    flex: 1;
    margin-right: 10px;
  }

  ul {
    margin-left: 22px;
    padding: 15px 0;
  }

  .types {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border-top: 1px solid var(--primary-050);
  }

  .checkboxes {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
  }

  .companies {
    margin-left: 6px;
    color: var(--primary-300);
  }
</style>
