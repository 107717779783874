<script>
  import styles from "styleguide/ConfirmationNewCompanies.json";
  import { t } from "stores/i18n.js";
  import { companies } from "stores/confirmation/confirmation_new.js";
  import { Button, IconButton, TextInput } from "components";

  const maxCompaniesLength = 100;

  function onDelete(index) {
    let newCompanies = [...$companies];
    newCompanies.splice(index, 1);
    companies.set(newCompanies);
  }

  function onAdd() {
    let newCompanies = [...$companies, { name: "", accepted: "false" }];
    companies.set(newCompanies);
  }
</script>

<div data-component="ConfirmationNewCompanies">
  <div class={styles.label}>
    {$t("confirmation_new_companies.title")}
  </div>
  <p>
    {$t("confirmation_new_companies.description")}
  </p>

  {#if $companies}
    {#each $companies as company, index (index)}
      <div class={styles.item}>
        <div class={styles.input}>
          {#if company?.name || company?.name === ""}
            <TextInput
              mountFocus={true}
              bind:value={company.name}
              placeholder={$t("confirmation_new_companies.placeholder")}
            />
          {:else}
            <TextInput
              mountFocus={true}
              bind:value={company}
              placeholder={$t("confirmation_new_companies.placeholder")}
            />
          {/if}
        </div>

        <IconButton
          icon={"trash.svg"}
          style="error-text"
          click={onDelete.bind(this, index)}
        />
      </div>
    {/each}
  {/if}

  {#if $companies && $companies.length < maxCompaniesLength}
    <Button style="primary-tonal" click={onAdd}>
      {$t("confirmation_new_companies.add")}
    </Button>
  {/if}
</div>

<style lang="scss">
  .item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 8px;
  }

  .input {
    flex: 1;
  }
  .label {
    font-weight: 500;
  }
  p {
    margin-top: 0;
    color: var(--primary-300);
  }
</style>
