<script>
  import { Box, FrontendFooter, FrontendHeader } from "components";
  import styles from "styleguide/FrontendWrapper.json";

  export let footerLinks;
</script>

<div data-component="FrontendWrapper" class={styles.wrapper}>
  <div class={styles.inner}>
    <Box>
      <FrontendHeader />
      <slot />
      <FrontendFooter {footerLinks} />
    </Box>
  </div>
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    padding: 70px 5% 110px;
    background-color: var(--primary-010);
    box-sizing: border-box;
  }

  .inner {
    width: 700px;
  }
</style>
