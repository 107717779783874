<script>
  import styles from "styleguide/Drawer.json";
  import { onDestroy, onMount } from "svelte";
  import { fly } from "svelte/transition";

  import { t } from "stores/i18n.js";
  import { iconPath } from "lib/helpers.js";

  import { IconButton, KeyEvent } from "components";
  import { HistoryList } from "components/pageHistory";

  export let onClose;

  const body = document.querySelector("body");
  const navBar = document.querySelector(
    `[data-svelte-component="NavigationBar"]`,
  );

  onMount(() => {
    body.style.overflow = "hidden";
    navBar.style["z-index"] = "101";
  });

  onDestroy(() => {
    body.style.overflow = "auto";
    navBar.style = "";
  });
</script>

<div
  data-svelte-component="PageHistoryDrawer"
  class={styles.drawer}
  transition:fly|global={{ x: -345, duration: 100 }}
>
  <div class={styles["drawer-header"]}>
    <div class={styles["drawer-title"]}>
      <span class={styles["history-icon"]}>
        <img src={iconPath("history")} alt="" />
      </span>
      {$t("pageHistoryDrawer.title")}
    </div>

    <IconButton icon="close" click={onClose} />
  </div>
  <div class={styles["drawer-content"]}>
    <HistoryList />
  </div>

  <KeyEvent key={27} event={onClose} />
</div>

<style lang="scss">
  .drawer {
    position: fixed;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    width: 345px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    z-index: 2;
    // transition: all 0.05s ease;

    &-header {
      padding: 20px 16px;
      border-bottom: 1px solid var(--primary-050);

      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-content {
      flex: 1;
      overflow-y: auto;
    }

    &-title {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 18px;
      font-weight: 600;
      color: var(--primary-500);
    }

    .history-icon {
      display: inline-flex;
      padding: 6px;
      border-radius: 50%;
      background-color: var(--blue-010);
    }
  }
</style>
