<script>
  import styles from "styleguide/UpsellFooter.json";
  import { imagePath } from "lib/helpers";
  import { t } from "stores/i18n.js";
  import { currentUpsellModal } from "stores/admin";
  import { Button } from "components";
  import { FeatureItem } from "components/modals/administrationModals/AdministrationUpsellModal";

  function onClickLearnMore(item) {
    currentUpsellModal.set(item);
  }

  $: activeFooterItems = [
    {
      name: "compliance",
      icon: "compliancePackage",
    },
    {
      name: "enterprise",
      icon: "office",
    },
    {
      name: "whitelabel",
      icon: "colorPalette",
    },
  ].filter(({ name }) => name !== $currentUpsellModal);
</script>

<div data-component="UpsellFooter">
  <div class={styles.book}>
    <div class={styles.title}>{$t("upsellFooter.book.title")}</div>
    <div class={styles.description}>
      {#if $currentUpsellModal === "enterprise"}
        {@html $t("upsellFooter.book.enterpriseDescription")}
      {:else}
        {@html $t("upsellFooter.book.defaultDescription")}
      {/if}
    </div>
    <Button
      newtab="true"
      navigate="https://demodesk.com/book/auditi-gmbh/produktvorstellung"
      style="primary-text reverse"
    >
      {$t("upsellFooter.book.button")}
    </Button>
    <img
      class={styles["contact-us"]}
      src={imagePath("contact-us-person")}
      alt="contact-us-person"
    />
  </div>
  <div class={styles.footer}>
    <div class={styles.title}>{$t("upsellFooter.more.title")}</div>
    <div class={styles["footer-items"]}>
      {#each activeFooterItems as { name, icon }}
        <FeatureItem
          {icon}
          onClick={() => onClickLearnMore(name)}
          title={$t(`upsellFooter.more.${name}.title`)}
          description={$t(`upsellFooter.more.${name}.description`)}
          buttonText={$t("upsellFooter.more.learnMore")}
        />
      {/each}
    </div>
  </div>
</div>

<style lang="scss">
  .book {
    background: var(--blue);
    border-radius: 16px;
    padding: 16px;
    color: white;
    position: relative;
    margin-bottom: 24px;

    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 8px;
    }

    .description {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.57;
      margin-bottom: 16px;
      max-width: calc(100% - 150px);
    }
    .contact-us {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .footer {
    background: var(--blue-010);
    display: flex;
    flex-direction: column;
    margin: 0 -24px -25px;
    padding: 16px 24px 24px;
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 1.5;
      margin-bottom: 32px;
    }
  }

  .footer-items {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }
</style>
