<script>
  import { onMount } from "svelte";
  import { isAccountFeature } from "stores/account";
  import { t } from "stores/i18n.js";
  import { user, isUserTeamUser } from "stores/user.js";

  import { isReviewed } from "lib/helpers.js";

  import { Boundary, Button, Comment, TextEditor } from "components";
  import styles from "styleguide/SidebarComments.json";

  export let store;
  export let services;
  export let isCommentsVisible = true;

  let clientUsers = [];

  const { comments, parentType, parentId } = store;
  const { CommentsService } = services;

  $: notRead = !$comments.every(
    (item) => item.is_deleted || isReviewed(item, $user.role),
  );

  onMount(() => {
    getComments();
  });

  function getComments() {
    CommentsService.getComments({
      params: {
        parent_type: $parentType,
        parent_id: $parentId,
      },
    });
  }

  function onMarkAsRead() {
    CommentsService.onMarkAsRead({
      params: {
        parent_type: $parentType,
        parent_id: $parentId,
      },
      onSuccess: () => {
        getComments();
      },
    });
  }

  function onPostComment(content, attachments, mentions) {
    CommentsService.onPostComment({
      params: {
        parent_type: $parentType,
        parent_id: $parentId,
        content,
        attachments,
        mentions,
      },
    });
  }
</script>

<Boundary>
  <div data-component="SidebarComments" class={styles.wrapper}>
    {#if $isUserTeamUser}
      <div class={styles.block}>
        <div class={styles.note}>
          <span>{$t("sidebar_comments.note")}</span>
          {#if isCommentsVisible}
            {$t("sidebar_comments.comments_visible")}
          {:else}
            {$t("sidebar_comments.comments_visible_no")}
          {/if}
        </div>
      </div>
    {/if}

    <div class={styles.block}>
      <TextEditor
        allowMentions={$isAccountFeature("mentions")}
        style="margin toolbar-full toolbar-top"
        sidebar={true}
        under={true}
        border={false}
        parent_type={$parentType}
        parent_id={$parentId}
        {clientUsers}
        {onPostComment}
      />
    </div>

    <div class={styles.comments}>
      {#if notRead}
        <div class={styles.mark}>
          <Button click={onMarkAsRead} style="primary-text small"
            >{$t("sidebar_comments.comments_mark_as_read")}</Button
          >
        </div>
      {/if}

      {#each $comments as comment}
        {#if !comment.is_deleted}
          <div class={styles.item}>
            <Comment
              {...comment}
              sidebar={true}
              picture={false}
              parent_type={$parentType}
              parent_id={$parentId}
              onDeleteSuccess={getComments}
            />
          </div>
        {/if}
      {:else}
        <div class={styles.noyet}>
          {$t("sidebar_comments.no_comments")}
        </div>
      {/each}
    </div>
  </div>
</Boundary>

<style lang="scss">
  .comments {
    height: 380px;
    overflow-y: auto;
  }

  .noyet {
    margin-top: 30px;
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    color: var(--primary-400);
  }

  .item {
    padding: 0 20px;
    border-bottom: solid 1px var(--primary-050);

    &:last-child {
      border-bottom: none;
    }
  }

  .block {
    padding: 4px 10px;
    border-bottom: solid 1px var(--primary-050);
  }

  .note {
    margin: 10px 8px;

    span {
      font-weight: 600;
      color: var(--orange);
    }
  }

  .mark {
    display: flex;
    justify-content: center;
    margin: 10px 0 0 0;
    text-align: center;
  }
</style>
