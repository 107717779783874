<script>
  import styles from "styleguide/ConfirmationRollForward.json";

  import { t } from "stores/i18n.js";
  import { toasts } from "stores/toasts.js";
  import {
    confirmation,
    activeRollForward,
  } from "stores/confirmation/confirmation.js";

  import RollForwardsApi from "apis/confirmation/roll_forwards.js";
  import { imagePath } from "lib/helpers.js";

  import { ConfirmationRollForwardGroup } from "apps/confirmation";

  import { Boundary, Button, Checkbox, TextInput, Toast } from "components";
  import { Modal } from "components/modals";

  let dirty;
  let polling;
  let loading;
  let newTitle = "";
  $: list = makelist($confirmation.confirmation_items);

  const onHide = function () {
    activeRollForward.set(false);
  };

  function updateYearInString(oldTitle) {
    let currentYear = new Date().getFullYear();

    for (let index = 0; index < 10; index++) {
      let year = currentYear - index;

      if (oldTitle.includes(year)) {
        return oldTitle.replace(year, currentYear + 1);
      }
    }

    return `${oldTitle} ${currentYear + 1}`;
  }

  function updateList(checked, permalink) {
    list = list.map((item) => {
      if (item.permalink === permalink) {
        item.checked = checked;
      }

      return item;
    });
  }

  function updateAllList(checked) {
    list = list.map((item) => {
      item.checked = checked;
      return item;
    });
  }

  function makelist(items) {
    if (!items) return [];

    return items.map((item) => {
      return {
        checked: true,
        name: item.company,
        permalink: item.permalink,
      };
    });
  }

  function checkStatus(id) {
    polling = setInterval(() => {
      RollForwardsApi.check({
        params: {
          id,
        },
        success: (response) => {
          if (response.data.status === "done") {
            loading = false;
            clearInterval(polling);
            toasts.send({
              title: $t("confirmation_roll_forward.success"),
              type: "success",
            });
            const path = response.data.url;
            if (path) {
              window.location = path;
            }
          }
        },
        error: () => {
          loading = false;
          clearInterval(polling);
          toasts.send({ title: $t("errors.check_entries"), type: "error" });
        },
      });
    }, 1000);
  }

  function submit() {
    dirty = true;

    if (newTitle) {
      loading = true;

      let confirmation_item_ids = list
        .filter((item) => item.checked)
        .map((item) => item.permalink);

      RollForwardsApi.create({
        params: {
          id: $confirmation.permalink,
          name: newTitle,
          confirmation_item_ids,
        },
        success: (response) => {
          checkStatus(response.data.id);
        },
        error: () => {
          toasts.send({ title: $t("errors.check_entries"), type: "error" });
        },
      });
    }
  }
</script>

<Boundary>
  {#if $activeRollForward}
    <Modal
      {onHide}
      shown={$activeRollForward}
      title={$t("confirmation_roll_forward.title")}
    >
      <div class={styles.wrapper} data-component="ConfirmationRollForward">
        <div class={styles.header}>
          <div class={styles.input}>
            <TextInput
              label={$t("confirmation_roll_forward.client")}
              bind:value={$confirmation.client_name}
              disabled
            />
          </div>
          <div class={styles.input}>
            <TextInput
              label={$t("confirmation_roll_forward.initial_title")}
              bind:value={$confirmation.name}
              disabled
            />
          </div>
          <div class={styles.input}>
            <TextInput
              label={$t("confirmation_roll_forward.new_title")}
              bind:value={newTitle}
              errors={dirty && !newTitle ? [$t("validation.required")] : []}
            />
          </div>

          <Toast
            message={$t("confirmation_roll_forward.description")}
            type="info"
          />
          <div class={styles["client-info"]}>
            <div class={styles["client-description"]} />

            <div class={styles.checkboxes}>
              <div class={styles.description}>
                {$t("confirmation_roll_forward.transfer_item")}
              </div>

              <div class={styles.checkbox}>
                <Checkbox
                  toggle={(value) => {
                    updateAllList(value);
                  }}
                  toggled={list.every((item) => item.checked)}
                />
              </div>
            </div>
          </div>
        </div>

        <div class={styles.list}>
          {#if list}
            {#each list as item}
              <ConfirmationRollForwardGroup {...item} {updateList} />
            {/each}
          {/if}
        </div>
      </div>

      <svelte:fragment slot="bottom">
        {#if loading}
          <div class={styles.loading}>
            <img
              class={styles.loading}
              src={imagePath("loading-spinner")}
              alt={""}
            />
            {$t("confirmation_roll_forward.loading")}
          </div>
        {:else}
          <Button style={"primary"} click={submit}
            >{$t("confirmation_roll_forward.submit")}</Button
          >
        {/if}
      </svelte:fragment>
    </Modal>
  {/if}
</Boundary>

<style lang="scss">
  .header {
    padding: 0 0 16px;
    border-bottom: 1px solid var(--primary-050);
  }

  .new-title {
    display: flex;

    .label {
      margin-top: 9px;
    }
  }

  .input {
    margin-bottom: 16px;
  }

  .client {
    display: flex;
    margin-top: 15px;
  }

  .client-name {
    margin-left: 25px;
  }

  .client-info {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .client-description {
    display: flex;
    align-items: center;
    margin-top: 4px;
  }

  .description {
    margin-left: 10px;
    color: var(--primary-500);

    &.top {
      display: flex;
      align-items: flex-end;
    }
  }

  .list {
    padding: 10px 0 0;
    border-top: 1px solid var(--border-color);

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .checkboxes {
    display: flex;
    align-items: flex-end;

    .description {
      line-height: 18px;
      margin-right: -5px;
    }
  }

  .checkbox {
    margin-left: 15px;
  }

  .loading {
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }
  }
</style>
