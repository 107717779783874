<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,62.352941%,79.215686%);fill-opacity:1;"
      d="M 244.792969 3.820312 L 480.003906 3.820312 L 480.003906 360 L 244.792969 360 Z M 244.792969 3.820312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,62.352941%,79.215686%);fill-opacity:1;"
      d="M 0 0 L 253.269531 0 L 253.269531 360.003906 L 0 360.003906 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0.0117188 0 L 296.90625 0 L 296.90625 147.449219 L 0.0117188 147.449219 Z M 0.0117188 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 0.0117188 0 L 0 11.066406 L 70.847656 47.472656 L 97.507812 48.242188 Z M 0.0117188 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,40%);fill-opacity:1;"
      d="M 30.347656 0 L 116.238281 44.867188 L 116.238281 0 Z M 30.347656 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 127.695312 0 L 127.695312 57.277344 L 0.0117188 57.277344 L 0.0117188 90.003906 L 127.695312 90.003906 L 127.695312 147.28125 L 166.984375 147.28125 L 166.984375 90.003906 L 294.667969 90.003906 L 294.667969 57.277344 L 166.984375 57.277344 L 166.984375 0 Z M 127.695312 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,40%);fill-opacity:1;"
      d="M 178.441406 0 L 178.441406 42.277344 L 262.476562 0.328125 Z M 178.441406 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 181.097656 46.882812 L 204.730469 46.695312 L 296.546875 0.328125 L 272.179688 0.726562 Z M 181.097656 46.882812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,40%);fill-opacity:1;"
      d="M 0.0117188 99.550781 L 0.0117188 130.917969 L 58.945312 99.875 L 0.0117188 99.546875 Z M 0.0117188 99.550781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 226.941406 100.847656 L 202.375 100.65625 L 294.980469 146.761719 L 294.371094 136.214844 Z M 23.027344 147.535156 L 109.859375 101.953125 L 87.054688 102.101562 L 0.015625 147.472656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,40%);fill-opacity:1;"
      d="M 295.914062 12.953125 L 225.785156 47.730469 L 294.980469 47.988281 L 294.980469 99.808594 L 236.046875 99.808594 L 294.433594 131.445312 L 295.289062 147.539062 L 264.105469 147.164062 L 178.753906 105.261719 L 178.753906 147.539062 L 116.550781 147.539062 L 116.550781 105.261719 L 36.488281 147.425781 L 0.328125 147.539062 L 0.328125 294.816406 L 589.640625 294.816406 L 589.640625 0.261719 L 296.523438 0.00390625 M 0.3125 16.628906 L 0 47.214844 L 62.042969 47.992188 Z M 0.3125 16.628906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,62.352941%,79.215686%);fill-opacity:1;"
      d="M 294.816406 0 L 589.628906 0 L 589.628906 297.550781 L 294.816406 297.550781 Z M 294.816406 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,62.352941%,79.215686%);fill-opacity:1;"
      d="M -1.363281 146.722656 L 311.117188 146.722656 L 311.117188 297.550781 L -1.363281 297.550781 Z M -1.363281 146.722656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,95.686275%,5.098039%);fill-opacity:1;"
      d="M 445.003906 92.019531 L 465.683594 92.003906 L 448.945312 103.429688 L 455.347656 121.933594 L 438.628906 110.484375 L 421.910156 121.933594 L 428.316406 103.429688 L 411.582031 92 L 432.257812 92.019531 L 438.632812 73.507812 Z M 445.003906 92.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,95.686275%,5.098039%);fill-opacity:1;"
      d="M 393.105469 239.605469 L 413.785156 239.589844 L 397.042969 251.015625 L 403.449219 269.519531 L 386.730469 258.066406 L 370.007812 269.515625 L 376.417969 251.015625 L 359.679688 239.585938 L 380.359375 239.605469 L 386.730469 221.089844 Z M 393.105469 239.605469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,95.686275%,5.098039%);fill-opacity:1;"
      d="M 445.003906 206.195312 L 465.683594 206.179688 L 448.945312 217.605469 L 455.347656 236.109375 L 438.628906 224.660156 L 421.910156 236.109375 L 428.316406 217.605469 L 411.582031 206.175781 L 432.257812 206.195312 L 438.632812 187.683594 Z M 445.003906 206.195312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,95.686275%,5.098039%);fill-opacity:1;"
      d="M 221.839844 313.234375 L 242.519531 313.21875 L 225.78125 324.644531 L 232.183594 343.148438 L 215.464844 331.699219 L 198.746094 343.148438 L 205.152344 324.644531 L 188.417969 313.214844 L 209.09375 313.234375 L 215.46875 294.722656 Z M 221.839844 313.234375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,95.686275%,5.098039%);fill-opacity:1;"
      d="M 268.769531 255.871094 L 248.09375 255.882812 L 264.832031 244.460938 L 258.425781 225.953125 L 275.148438 237.40625 L 291.867188 225.957031 L 285.457031 244.460938 L 302.195312 255.890625 L 281.519531 255.871094 L 275.144531 274.382812 Z M 268.769531 255.871094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,95.686275%,5.098039%);fill-opacity:1;"
      d="M 329.75 171.535156 L 309.074219 171.550781 L 325.8125 160.125 L 319.40625 141.621094 L 336.128906 153.074219 L 352.847656 141.625 L 346.4375 160.125 L 363.175781 171.554688 L 342.496094 171.535156 L 336.125 190.050781 Z M 329.75 171.535156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,95.686275%,5.098039%);fill-opacity:1;"
      d="M 381.003906 154.015625 L 360.324219 154.03125 L 377.0625 142.605469 L 370.660156 124.101562 L 387.378906 135.550781 L 404.097656 124.101562 L 397.691406 142.605469 L 414.429688 154.035156 L 393.75 154.015625 L 387.375 172.53125 Z M 381.003906 154.015625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,95.686275%,5.098039%);fill-opacity:1;"
      d="M 329.75 299.980469 L 309.074219 299.992188 L 325.8125 288.566406 L 319.40625 270.0625 L 336.128906 281.515625 L 352.847656 270.066406 L 346.4375 288.566406 L 363.175781 299.996094 L 342.496094 299.980469 L 336.125 318.492188 Z M 329.75 299.980469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,95.686275%,5.098039%);fill-opacity:1;"
      d="M 268.769531 314.902344 L 248.09375 314.914062 L 264.832031 303.492188 L 258.425781 284.984375 L 275.148438 296.4375 L 291.867188 284.988281 L 285.457031 303.492188 L 302.195312 314.921875 L 281.519531 314.902344 L 275.144531 333.414062 Z M 268.769531 314.902344 "
    />
  </g>
</svg>
