<script>
  import { t } from "stores/i18n.js";
  import { Toast } from "components";

  export let error = null;
  export let onError = null;

  let context = {
    pageUrl: window.location.href,
    version: document
      .querySelector(`meta[name="version"]`)
      ?.getAttribute("content"),
  };

  $: if ($error && onError) onError($error, context);
</script>

{#if $error}
  <Toast type="error" title={$t("error.title")} message={$t("error.text")} />
{:else}
  <slot />
{/if}
