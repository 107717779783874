<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(100%,100%,100%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(19.607843%,57.254902%,1.176471%);fill-opacity:1;"
      d="M -30 240 L 510 240 L 510 360 L -30 360 Z M -30 240 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.588235%,45.098039%,0%);fill-opacity:1;"
      d="M -30 0 L 510 0 L 510 120 L -30 120 Z M -30 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(3.137255%,22.352941%,61.176471%);fill-opacity:1;"
      d="M 332.242188 179.996094 C 332.242188 129.054688 290.945312 87.757812 240 87.757812 C 189.058594 87.757812 147.761719 129.054688 147.761719 179.996094 C 147.761719 230.941406 189.058594 272.238281 240 272.238281 C 290.945312 272.238281 332.242188 230.941406 332.242188 179.996094 Z M 332.242188 179.996094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 319.933594 159.300781 C 320.441406 161.140625 320.546875 162.125 320.914062 163.886719 C 317.296875 165.007812 315.414062 167.417969 315.851562 169.828125 C 316.210938 172.003906 317.820312 175.09375 322.34375 174.4375 C 322.460938 176.234375 322.515625 177.265625 322.519531 179.175781 C 308.847656 179.207031 294.4375 178.417969 280.992188 177.226562 C 271.074219 176.367188 268.714844 179.121094 263.554688 179.558594 C 257.570312 180.050781 252.859375 179.691406 240.5 180.179688 C 252.613281 177.683594 257.375 176.167969 262.984375 175.261719 C 268.382812 174.425781 270.464844 176.378906 280.511719 172.472656 C 293.203125 167.425781 306.75 162.914062 319.933594 159.300781 Z M 319.933594 159.300781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 220.214844 100.613281 C 222.058594 100.121094 223.042969 100.019531 224.808594 99.664062 C 225.90625 103.289062 228.304688 105.1875 230.71875 104.765625 C 232.894531 104.417969 235.996094 102.832031 235.367188 98.300781 C 237.164062 98.195312 238.195312 98.148438 240.105469 98.15625 C 240.050781 111.828125 239.167969 126.230469 237.890625 139.667969 C 236.964844 149.582031 239.703125 151.960938 240.109375 157.121094 C 240.558594 163.109375 240.171875 167.820312 240.578125 180.183594 C 238.160156 168.050781 236.679688 163.28125 235.808594 157.664062 C 235.003906 152.261719 236.972656 150.195312 233.132812 140.121094 C 228.167969 127.398438 223.742188 113.820312 220.214844 100.613281 Z M 220.214844 100.613281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 241.222656 98.0625 C 243.132812 98.058594 244.109375 98.210938 245.90625 98.320312 C 246.039062 102.105469 247.871094 104.550781 250.316406 104.761719 C 252.507812 104.984375 255.910156 104.242188 256.460938 99.699219 C 258.226562 100.058594 259.234375 100.277344 261.082031 100.773438 C 257.53125 113.976562 252.996094 127.675781 248.324219 140.339844 C 244.898438 149.6875 246.9375 152.6875 246.007812 157.78125 C 244.914062 163.683594 243.335938 168.140625 240.566406 180.195312 C 241.332031 167.847656 241.117188 162.855469 241.710938 157.207031 C 242.316406 151.777344 244.75 150.28125 243.609375 139.558594 C 242.066406 125.992188 241.257812 111.734375 241.222656 98.0625 Z M 241.222656 98.0625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 262.402344 101.023438 C 264.25 101.515625 265.152344 101.914062 266.859375 102.484375 C 266.011719 106.171875 267.152344 109.011719 269.457031 109.847656 C 271.515625 110.625 274.996094 110.789062 276.699219 106.542969 C 278.3125 107.34375 279.230469 107.816406 280.886719 108.773438 C 274.046875 120.613281 266.128906 132.675781 258.34375 143.703125 C 252.617188 151.847656 253.8125 155.273438 251.601562 159.953125 C 249.019531 165.371094 246.34375 169.269531 240.554688 180.199219 C 244.480469 168.46875 245.5625 163.59375 247.597656 158.289062 C 249.585938 153.199219 252.320312 152.382812 253.988281 141.730469 C 256.003906 128.222656 258.902344 114.242188 262.402344 101.023438 Z M 262.402344 101.023438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 282.097656 109.359375 C 283.753906 110.308594 284.523438 110.929688 286.027344 111.917969 C 284.253906 115.265625 284.621094 118.300781 286.632812 119.699219 C 288.421875 120.984375 291.742188 122.042969 294.484375 118.382812 C 295.835938 119.574219 296.601562 120.265625 297.953125 121.617188 C 288.289062 131.289062 277.519531 140.894531 267.152344 149.535156 C 259.515625 155.925781 259.785156 159.542969 256.441406 163.492188 C 252.546875 168.0625 248.953125 171.136719 240.539062 180.203125 C 247.363281 169.882812 249.667969 165.453125 253.003906 160.851562 C 256.238281 156.449219 259.089844 156.367188 263.453125 146.507812 C 268.890625 133.976562 275.304688 121.222656 282.097656 109.355469 Z M 282.097656 109.359375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 298.882812 122.398438 C 300.242188 123.742188 300.824219 124.542969 302.023438 125.886719 C 299.449219 128.664062 299.027344 131.691406 300.609375 133.5625 C 302.007812 135.261719 304.945312 137.136719 308.539062 134.304688 C 309.535156 135.800781 310.097656 136.667969 311.058594 138.320312 C 299.234375 145.183594 286.359375 151.707031 274.121094 157.394531 C 265.101562 161.609375 264.433594 165.175781 260.183594 168.132812 C 255.246094 171.546875 250.984375 173.597656 240.523438 180.199219 C 249.769531 171.976562 253.136719 168.289062 257.542969 164.699219 C 261.796875 161.273438 264.578125 161.925781 271.324219 153.519531 C 279.792969 142.804688 289.269531 132.121094 298.882812 122.402344 Z M 298.882812 122.398438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 312.007812 139.761719 C 312.964844 141.414062 313.320312 142.339844 314.121094 143.949219 C 310.910156 145.953125 309.703125 148.765625 310.742188 150.984375 C 311.644531 152.996094 313.984375 155.574219 318.195312 153.785156 C 318.765625 155.492188 319.078125 156.476562 319.570312 158.324219 C 306.359375 161.839844 292.222656 164.746094 278.917969 167.019531 C 269.109375 168.714844 267.527344 171.980469 262.648438 173.71875 C 256.988281 175.714844 252.339844 176.570312 240.511719 180.191406 C 251.589844 174.6875 255.808594 172.011719 261.003906 169.707031 C 266.011719 167.523438 268.519531 168.882812 277.242188 162.542969 C 288.226562 154.433594 300.175781 146.617188 312.007812 139.761719 Z M 312.007812 139.761719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 161.015625 200.488281 C 160.523438 198.644531 160.421875 197.660156 160.066406 195.894531 C 163.691406 194.796875 165.589844 192.402344 165.167969 189.984375 C 164.820312 187.8125 163.230469 184.710938 158.699219 185.339844 C 158.59375 183.542969 158.546875 182.511719 158.554688 180.597656 C 172.226562 180.648438 186.628906 181.527344 200.070312 182.800781 C 209.984375 183.722656 212.359375 180.984375 217.519531 180.578125 C 223.507812 180.125 228.21875 180.511719 240.582031 180.101562 C 228.449219 182.523438 223.679688 184.007812 218.066406 184.878906 C 212.660156 185.683594 210.59375 183.714844 200.519531 187.5625 C 187.800781 192.527344 174.222656 196.957031 161.015625 200.488281 Z M 161.015625 200.488281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 322.621094 180.269531 C 322.636719 182.179688 322.488281 183.160156 322.394531 184.957031 C 318.613281 185.113281 316.175781 186.964844 315.980469 189.40625 C 315.777344 191.601562 316.539062 195 321.085938 195.519531 C 320.738281 197.285156 320.527344 198.296875 320.042969 200.144531 C 306.816406 196.6875 293.085938 192.246094 280.390625 187.660156 C 271.019531 184.296875 268.035156 186.355469 262.9375 185.460938 C 257.027344 184.40625 252.558594 182.859375 240.484375 180.171875 C 252.835938 180.851562 257.828125 180.605469 263.480469 181.160156 C 268.914062 181.730469 270.425781 184.152344 281.140625 182.9375 C 294.699219 181.300781 308.949219 180.398438 322.621094 180.269531 Z M 322.621094 180.269531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 319.800781 201.488281 C 319.324219 203.335938 318.925781 204.242188 318.371094 205.957031 C 314.675781 205.128906 311.84375 206.289062 311.023438 208.597656 C 310.257812 210.664062 310.117188 214.144531 314.375 215.820312 C 313.582031 217.4375 313.117188 218.359375 312.171875 220.023438 C 300.289062 213.261719 288.175781 205.417969 277.097656 197.707031 C 268.914062 192.03125 265.496094 193.25 260.804688 191.066406 C 255.367188 188.519531 251.453125 185.871094 240.484375 180.152344 C 252.238281 184.003906 257.125 185.054688 262.441406 187.054688 C 267.542969 189.007812 268.378906 191.738281 279.039062 193.339844 C 292.5625 195.261719 306.5625 198.074219 319.800781 201.488281 Z M 319.800781 201.488281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 311.574219 221.273438 C 310.632812 222.9375 310.015625 223.707031 309.035156 225.21875 C 305.679688 223.464844 302.644531 223.851562 301.257812 225.871094 C 299.980469 227.667969 298.945312 230.992188 302.621094 233.714844 C 301.4375 235.070312 300.75 235.839844 299.40625 237.199219 C 289.679688 227.59375 280.007812 216.882812 271.304688 206.566406 C 264.867188 198.972656 261.253906 199.261719 257.285156 195.941406 C 252.691406 192.074219 249.597656 188.5 240.480469 180.136719 C 250.839844 186.898438 255.285156 189.179688 259.90625 192.488281 C 264.328125 195.695312 264.425781 198.546875 274.3125 202.851562 C 286.871094 208.210938 299.667969 214.550781 311.574219 221.273438 Z M 311.574219 221.273438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 298.5 238.265625 C 297.160156 239.628906 296.367188 240.21875 295.027344 241.421875 C 292.238281 238.859375 289.210938 238.445312 287.34375 240.035156 C 285.648438 241.441406 283.785156 244.386719 286.632812 247.964844 C 285.136719 248.972656 284.273438 249.535156 282.625 250.5 C 275.71875 238.703125 269.148438 225.855469 263.414062 213.636719 C 259.164062 204.632812 255.597656 203.976562 252.625 199.738281 C 249.1875 194.816406 247.125 190.5625 240.484375 180.125 C 248.742188 189.339844 252.445312 192.691406 256.050781 197.082031 C 259.492188 201.328125 258.847656 204.109375 267.28125 210.828125 C 278.023438 219.253906 288.742188 228.691406 298.5 238.269531 Z M 298.5 238.265625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 281.324219 251.378906 C 279.675781 252.34375 278.753906 252.703125 277.148438 253.515625 C 275.128906 250.316406 272.3125 249.125 270.09375 250.175781 C 268.089844 251.089844 265.523438 253.445312 267.335938 257.644531 C 265.632812 258.222656 264.652344 258.542969 262.808594 259.046875 C 259.21875 245.855469 256.230469 231.738281 253.882812 218.445312 C 252.128906 208.644531 248.855469 207.078125 247.089844 202.214844 C 245.0625 196.5625 244.179688 191.921875 240.492188 180.109375 C 246.054688 191.160156 248.757812 195.363281 251.089844 200.542969 C 253.304688 205.539062 251.957031 208.054688 258.347656 216.742188 C 266.519531 227.679688 274.40625 239.585938 281.324219 251.378906 Z M 281.324219 251.378906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 261.441406 259.523438 C 259.601562 260.027344 258.617188 260.136719 256.855469 260.503906 C 255.734375 256.886719 253.320312 255.007812 250.910156 255.445312 C 248.738281 255.808594 245.648438 257.417969 246.308594 261.945312 C 244.511719 262.0625 243.480469 262.117188 241.570312 262.121094 C 241.527344 248.453125 242.304688 234.042969 243.484375 220.59375 C 244.335938 210.675781 241.582031 208.316406 241.140625 203.160156 C 240.644531 197.175781 240.996094 192.460938 240.5 180.101562 C 243.007812 192.214844 244.523438 196.976562 245.4375 202.582031 C 246.277344 207.980469 244.324219 210.0625 248.238281 220.109375 C 253.292969 232.796875 257.820312 246.339844 261.441406 259.523438 Z M 261.441406 259.523438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 240.03125 262.222656 C 238.121094 262.230469 237.140625 262.078125 235.34375 261.972656 C 235.203125 258.191406 233.367188 255.746094 230.921875 255.542969 C 228.730469 255.324219 225.328125 256.074219 224.789062 260.617188 C 223.023438 260.261719 222.011719 260.046875 220.167969 259.554688 C 223.6875 246.34375 228.191406 232.632812 232.839844 219.960938 C 236.246094 210.605469 234.199219 207.609375 235.117188 202.515625 C 236.199219 196.609375 237.769531 192.152344 240.511719 180.089844 C 239.773438 192.4375 240 197.425781 239.417969 203.082031 C 238.824219 208.511719 236.394531 210.011719 237.554688 220.730469 C 239.128906 234.296875 239.96875 248.550781 240.03125 262.222656 Z M 240.03125 262.222656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 218.851562 259.304688 C 217.003906 258.820312 216.097656 258.421875 214.386719 257.855469 C 215.230469 254.164062 214.085938 251.328125 211.777344 250.5 C 209.714844 249.722656 206.234375 249.570312 204.539062 253.816406 C 202.925781 253.019531 202.003906 252.546875 200.347656 251.597656 C 207.160156 239.742188 215.054688 227.664062 222.816406 216.617188 C 228.523438 208.460938 227.320312 205.039062 229.523438 200.355469 C 232.09375 194.929688 234.761719 191.027344 240.527344 180.082031 C 236.625 191.820312 235.550781 196.699219 233.53125 202.011719 C 231.554688 207.105469 228.820312 207.925781 227.175781 218.582031 C 225.191406 232.09375 222.320312 246.082031 218.851562 259.304688 Z M 218.851562 259.304688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 199.074219 250.980469 C 197.414062 250.03125 196.644531 249.410156 195.140625 248.421875 C 196.90625 245.078125 196.539062 242.042969 194.523438 240.640625 C 192.734375 239.359375 189.414062 238.308594 186.671875 241.972656 C 185.320312 240.78125 184.554688 240.089844 183.203125 238.738281 C 192.851562 229.058594 203.609375 219.4375 213.96875 210.78125 C 221.59375 204.382812 221.320312 200.765625 224.660156 196.8125 C 228.550781 192.238281 232.136719 189.160156 240.542969 180.082031 C 233.730469 190.410156 231.429688 194.84375 228.101562 199.449219 C 224.871094 203.855469 222.019531 203.941406 217.667969 213.808594 C 212.25 226.34375 205.851562 239.109375 199.074219 250.980469 Z M 199.074219 250.980469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 182.101562 237.789062 C 180.742188 236.441406 180.160156 235.640625 178.964844 234.296875 C 181.542969 231.523438 181.972656 228.496094 180.390625 226.625 C 178.996094 224.917969 176.0625 223.042969 172.464844 225.867188 C 171.46875 224.367188 170.90625 223.5 169.953125 221.847656 C 181.785156 215.003906 194.671875 208.503906 206.921875 202.835938 C 215.949219 198.632812 216.621094 195.070312 220.875 192.121094 C 225.816406 188.710938 230.082031 186.671875 240.554688 180.085938 C 231.296875 188.292969 227.921875 191.976562 223.511719 195.558594 C 219.25 198.976562 216.472656 198.320312 209.710938 206.714844 C 201.222656 217.414062 191.730469 228.082031 182.101562 237.789062 Z M 182.101562 237.789062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 169.058594 220.492188 C 168.101562 218.839844 167.746094 217.914062 166.945312 216.304688 C 170.15625 214.300781 171.363281 211.492188 170.328125 209.269531 C 169.425781 207.261719 167.085938 204.679688 162.875 206.46875 C 162.304688 204.761719 161.992188 203.773438 161.5 201.929688 C 174.714844 198.417969 188.851562 195.515625 202.15625 193.246094 C 211.96875 191.554688 213.550781 188.289062 218.425781 186.554688 C 224.089844 184.558594 228.738281 183.707031 240.570312 180.089844 C 229.488281 185.589844 225.265625 188.261719 220.074219 190.566406 C 215.0625 192.75 212.554688 191.386719 203.832031 197.722656 C 192.84375 205.832031 180.890625 213.644531 169.058594 220.492188 Z M 169.058594 220.492188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 200.503906 108.480469 C 202.164062 107.53125 203.089844 107.179688 204.703125 106.386719 C 206.691406 109.605469 209.496094 110.828125 211.722656 109.800781 C 213.734375 108.90625 216.324219 106.578125 214.558594 102.359375 C 216.265625 101.796875 217.253906 101.488281 219.101562 101.007812 C 222.554688 114.234375 225.394531 128.382812 227.601562 141.699219 C 229.253906 151.519531 232.507812 153.117188 234.222656 158 C 236.195312 163.671875 237.027344 168.324219 240.589844 180.167969 C 235.140625 169.0625 232.484375 164.832031 230.207031 159.628906 C 228.042969 154.609375 229.417969 152.105469 223.121094 143.355469 C 215.0625 132.328125 207.300781 120.34375 200.503906 108.480469 Z M 200.503906 108.480469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 158.460938 179.078125 C 158.464844 177.167969 158.625 176.191406 158.738281 174.394531 C 162.523438 174.277344 164.980469 172.457031 165.203125 170.015625 C 165.433594 167.824219 164.707031 164.417969 160.167969 163.847656 C 160.535156 162.082031 160.757812 161.074219 161.265625 159.230469 C 174.449219 162.84375 188.128906 167.441406 200.769531 172.171875 C 210.101562 175.644531 213.113281 173.617188 218.199219 174.570312 C 224.097656 175.695312 228.546875 177.292969 240.589844 180.117188 C 228.246094 179.296875 223.253906 179.484375 217.605469 178.867188 C 212.179688 178.234375 210.695312 175.796875 199.96875 176.886719 C 186.390625 178.367188 172.128906 179.109375 158.460938 179.078125 Z M 158.460938 179.078125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 161.476562 158.070312 C 161.96875 156.226562 162.375 155.320312 162.945312 153.613281 C 166.632812 154.472656 169.472656 153.34375 170.316406 151.039062 C 171.097656 148.980469 171.269531 145.503906 167.03125 143.785156 C 167.835938 142.175781 168.3125 141.257812 169.273438 139.605469 C 181.09375 146.476562 193.132812 154.429688 204.140625 162.242188 C 212.273438 167.988281 215.699219 166.804688 220.371094 169.027344 C 225.785156 171.625 229.675781 174.308594 240.59375 180.128906 C 228.871094 176.171875 224 175.074219 218.699219 173.027344 C 213.613281 171.023438 212.804688 168.289062 202.160156 166.589844 C 188.65625 164.542969 174.683594 161.605469 161.476562 158.070312 Z M 161.476562 158.070312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 169.898438 138.328125 C 170.851562 136.675781 171.476562 135.910156 172.472656 134.410156 C 175.8125 136.195312 178.847656 135.835938 180.257812 133.832031 C 181.546875 132.046875 182.617188 128.730469 178.964844 125.972656 C 180.160156 124.628906 180.859375 123.863281 182.214844 122.519531 C 191.847656 132.21875 201.417969 143.019531 210.023438 153.417969 C 216.386719 161.074219 220.003906 160.820312 223.941406 164.179688 C 228.496094 168.089844 231.558594 171.691406 240.59375 180.140625 C 230.300781 173.28125 225.875 170.957031 221.289062 167.605469 C 216.898438 164.355469 216.824219 161.503906 206.980469 157.105469 C 194.472656 151.625 181.734375 145.164062 169.898438 138.332031 Z M 169.898438 138.328125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 183.449219 121.164062 C 184.808594 119.820312 185.613281 119.246094 186.96875 118.058594 C 189.71875 120.664062 192.742188 121.121094 194.628906 119.558594 C 196.347656 118.179688 198.253906 115.261719 195.460938 111.640625 C 196.96875 110.660156 197.84375 110.105469 199.503906 109.164062 C 206.238281 121.0625 212.613281 134.011719 218.167969 146.3125 C 222.28125 155.378906 225.839844 156.085938 228.75 160.367188 C 232.109375 165.34375 234.109375 169.625 240.59375 180.160156 C 232.476562 170.824219 228.824219 167.417969 225.285156 162.972656 C 221.90625 158.675781 222.589844 155.90625 214.257812 149.066406 C 203.640625 140.476562 193.0625 130.882812 183.449219 121.164062 Z M 183.449219 121.164062 "
    />
  </g>
</svg>
