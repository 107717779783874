<script>
  import styles from "styleguide/AuditTemplate.json";
  import { user } from "stores/user.js";
  import { t } from "stores/i18n.js";
  import { can } from "stores/permission.js";
  import { audit, activeTemplate, auditTemplates } from "stores/pbc.js";
  import { toasts } from "stores/toasts";
  import { isAccountFeature } from "stores/account";

  import { dateTimeFormat, imagePath } from "lib/helpers.js";

  import AuditApi from "apis/pbc/audit.js";
  import AuditTemplateApi from "apis/pbc/audit_templates.js";

  import { AuditTemplateGroup } from "apps/pbc";
  import {
    Boundary,
    Button,
    IconButton,
    Options,
    Skeleton,
    TextInput,
  } from "components";
  import { Modal } from "components/modals";

  let valueEditName = "";
  let valueEditDescription = "";
  let errorEditName;
  let errorEditNameText;
  let errorEditDescription;
  let editTemplateIndex = null;

  let queryFilter = "";
  let activeFilter = "all";
  let filters;
  $: {
    filters = ["all", "my", "team"];
    if ($activeTemplate !== "overwrite") {
      if ($auditTemplates?.account_templates?.length > 0) {
        filters.push("account");
      }
      if ($isAccountFeature("audit_default_templates")) {
        filters.push("standard");
      }
    }
  }
  $: sort = "name";
  $: sortTypes = {
    name: $t("sort.sort_name"),
    "last-updated-desc": $t("sort.sort_updated"),
    "last-created-desc": $t("sort.sort_created"),
  };

  $: displayedSort = sortTypes[sort];

  activeTemplate.subscribe((value) => {
    if (value === "manage" || value === "overwrite") {
      AuditTemplateApi.get(value);
    }
  });

  $: filteredTemplates = applyFilter(
    $auditTemplates,
    activeFilter,
    queryFilter,
    sort,
  );

  function applyFilter(templates, filter, queryFilter, sort) {
    if (templates) {
      let filtered = [];

      if (!templates.team_templates) {
        templates.team_templates = [];
      }

      if (
        !templates.standard_templates ||
        !$isAccountFeature("audit_default_templates")
      ) {
        templates.standard_templates = [];
      }

      if (!templates.account_templates) {
        templates.account_templates = [];
      }

      switch (filter) {
        case "all":
          filtered = [
            ...templates.team_templates,
            ...templates.standard_templates,
            ...templates.account_templates,
          ];
          break;
        case "my":
          filtered = [...templates.team_templates].filter(
            (item) => item.created_by?.permalink === $user.permalink,
          );
          break;
        case "team":
          filtered = [...templates.team_templates];
          break;
        case "standard":
          filtered = [...templates.standard_templates];
          break;
        case "account":
          filtered = [...templates.account_templates];
          break;
        default:
          filtered = [];
      }

      if (queryFilter) {
        filtered = filtered.filter((item) =>
          item.name.toLowerCase().includes(queryFilter.toLowerCase()),
        );
      }

      filtered = getSorted(sort, filtered);

      return filtered;
    } else {
      return [];
    }
  }

  function getSorted(sort, items) {
    if (sort === "name") {
      return items.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sort === "last-updated-desc") {
      return items.sort((a, b) => {
        return new Date(b.updated_at) - new Date(a.updated_at);
      });
    } else if (sort === "last-created-desc") {
      return items.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
    } else {
      return [];
    }
  }

  function onStartEdit(index, name, description) {
    editTemplateIndex = index;
    valueEditName = name;
    valueEditDescription = description;
  }

  function onToggleItem(index, key, value) {
    let newTemplates = [...filteredTemplates];
    newTemplates[index][key] = value;

    filteredTemplates = newTemplates;
  }

  function onToggleInfo(index, open) {
    onToggleItem(index, "open", open);
  }

  function onToggleLoading(index, loading) {
    onToggleItem(index, "loading", loading);
  }

  function onImport(id, index) {
    errorEditName = false;
    onToggleLoading(index, true);

    AuditTemplateApi.import({
      params: {
        id,
        audit_id: $audit.permalink,
      },
      success: () => {
        AuditApi.getAudits();
        activeTemplate.set(null);
        onToggleLoading(index, false);
      },
      error: () => {
        onToggleLoading(index, false);
        alert($t("audit_template.template_import_error"));
      },
    });
  }

  function onEdit(id) {
    errorEditName = false;

    if (valueEditName) {
      let params = {
        name: valueEditName,
        description: valueEditDescription,
      };

      if (id) {
        params.id = id;

        AuditTemplateApi.edit({
          params,
          success: onEditSuccess,
          error: onEditError,
        });
      } else {
        params.audit_id = $audit.permalink;

        AuditTemplateApi.create({
          params,
          success: onEditSuccess,
          error: onEditError,
        });
      }

      errorEditName = false;
      resetValues();
    } else {
      errorEditName = true;
    }
  }

  function resetValues() {
    valueEditName = "";
    valueEditDescription = "";
    editTemplateIndex = null;
  }

  function onOverwrite(templatePermalink) {
    const isConfirmed = confirm($t("control_status.confirm_override"));
    if (isConfirmed) {
      let template = filteredTemplates.filter(
        (template) => template.permalink === templatePermalink,
      )[0];
      AuditTemplateApi.overwrite({
        params: {
          id: template.permalink,
          name: template.name,
          description: template.description,
          overwrite: true,
          audit_id: $audit.permalink,
        },
        success: onEditSuccess,
        error: onEditError,
      });
    }
  }

  function onEditSuccess() {
    editTemplateIndex = null;
    AuditTemplateApi.get();
    valueEditName = "";
    valueEditDescription = "";
    activeTemplate.set("manage");
    toasts.send({
      message: $t("audit_template.updated"),
      type: "success",
    });
  }

  function onEditError() {
    errorEditName = true;
  }

  function onDelete(id) {
    const isConfirmed = confirm($t("control_status.confirm_deletion"));

    if (isConfirmed) {
      AuditTemplateApi.delete(id);
    }
  }

  const onHide = function () {
    activeTemplate.set(null);
  };
</script>

<Boundary>
  {#if $activeTemplate}
    <Modal shown={$activeTemplate === "create"} {onHide} close={false}>
      <div data-component="AuditTemplate" class={styles.header} slot="header">
        <div class={styles.title}>{$t("audit_template.title_create")}</div>

        <div class={styles.buttons}>
          <IconButton click={() => activeTemplate.set(null)} close={true} />
        </div>
      </div>

      <div data-component="AuditTemplate" class={styles.inputs}>
        <div class={styles.input}>
          <TextInput
            placeholder={$t("audit_template.template_title")}
            bind:value={valueEditName}
            onEnter={onEdit.bind(this, null)}
            invalid={errorEditName}
            errors={errorEditNameText ? [errorEditNameText] : []}
          />
        </div>
        <div class={styles.input}>
          <TextInput
            placeholder={$t("audit_template.template_description")}
            bind:value={valueEditDescription}
            onEnter={onEdit.bind(this, null)}
            invalid={errorEditDescription}
          />
        </div>
      </div>
      <svelte:fragment slot="bottom">
        <div class={styles.button}>
          <Button click={onEdit.bind(this, null)} style="primary">
            {$t("audit_template.save")}
          </Button>
        </div>
      </svelte:fragment>
    </Modal>

    <Modal
      isWide
      isFullHeight
      isScrollingInsideModal
      shown={$activeTemplate === "manage" || $activeTemplate === "overwrite"}
      {onHide}
      style={"no-padding"}
    >
      <div data-component="AuditTemplate" class={styles.sidebar} slot="sidebar">
        <div class={styles["sidebar-title"]}>
          {$t("audit_template.templates")}
        </div>

        {#each filters as filter}
          <div
            on:click={() => {
              activeFilter = filter;
            }}
            class={`${styles["sidebar-item"]} ${activeFilter === filter ? styles.active : ""}`}
          >
            {$t(`audit_template.templates_${filter}`)}
          </div>
        {/each}
      </div>

      <div data-component="AuditTemplate" class={styles.header} slot="header">
        <TextInput
          placeholder={$t("audit_template.search")}
          bind:value={queryFilter}
          icon="search"
          style="small no-border"
        />
      </div>

      <div data-component="AuditTemplate" class={styles.top}>
        <div class={styles.title}>
          {$t(`audit_template.templates_${activeFilter}`)}
        </div>
        <div class={styles.sort}>
          {$t("sort.sort_by")}
          <Options
            style={"medium no-border acts-as-button left"}
            title={displayedSort}
          >
            <Button style="blue-text option" click={() => (sort = "name")}>
              {$t("sort.sort_name")}
            </Button>
            <Button
              style="blue-text option"
              click={() => (sort = "last-updated-desc")}
            >
              {$t("sort.sort_updated")}
            </Button>
            <Button
              style="blue-text option"
              click={() => (sort = "last-created-desc")}
            >
              {$t("sort.sort_created")}
            </Button>
          </Options>
        </div>
      </div>

      <div data-component="AuditTemplate">
        <Skeleton loading={!$auditTemplates}>
          {#each filteredTemplates as { name, permalink, description, updated_at, read_only, control_group_templates, open, loading }, index}
            <div class={styles.template}>
              <div class={styles["template-inner"]}>
                <div class={styles["template-header"]}>
                  <div class={styles["template-content"]}>
                    {#if editTemplateIndex === index}
                      <div class={styles.inputs}>
                        <div class={styles.input}>
                          <TextInput
                            placeholder={$t("audit_template.template_title")}
                            bind:value={valueEditName}
                            onEnter={onEdit.bind(this, permalink)}
                            invalid={errorEditName}
                            errors={errorEditNameText
                              ? [errorEditNameText]
                              : []}
                          />
                          <div class={styles.buttons}>
                            <Button click={resetValues} style="error-text">
                              {$t("audit_template.cancel")}
                            </Button>
                            <Button
                              click={onEdit.bind(this, permalink)}
                              style="primary"
                            >
                              {$t("audit_template.save")}
                            </Button>
                          </div>
                        </div>
                        <div class={styles.input}>
                          <TextInput
                            placeholder={$t(
                              "audit_template.template_description",
                            )}
                            bind:value={valueEditDescription}
                            onEnter={onEdit.bind(this, permalink)}
                            invalid={errorEditDescription}
                          />
                        </div>
                      </div>
                    {:else}
                      <div class={styles["template-name"]}>{name}</div>
                      {#if description}
                        <div class={styles["template-description"]}>
                          {description}
                        </div>
                      {/if}
                    {/if}
                  </div>

                  {#if !(editTemplateIndex === index)}
                    <div class={styles.buttons}>
                      {#if loading}
                        <img
                          class={styles.loading}
                          src={imagePath("loading-spinner")}
                          alt={$t("statuses.checking_for_viruses")}
                        />
                      {:else}
                        {#if $can("update", "audit_template")}
                          {#if !read_only}
                            <div class={styles.options}>
                              <Options
                                title={$t("audit_template.template_options")}
                                style="medium no-border acts-as-button"
                              >
                                <Button
                                  click={onStartEdit.bind(
                                    this,
                                    index,
                                    name,
                                    description,
                                  )}
                                  style="blue-text option"
                                >
                                  {$t("audit_template.template_edit")}
                                </Button>
                                <Button
                                  click={onOverwrite.bind(this, permalink)}
                                  style="primary-text option"
                                >
                                  {$t("audit_template.template_override")}
                                </Button>
                                <Button
                                  click={onDelete.bind(this, permalink)}
                                  style="error-text option"
                                >
                                  {$t("audit_template.template_delete")}
                                </Button>
                              </Options>
                            </div>
                          {/if}
                        {/if}

                        {#if $can("import", "audit_template")}
                          <div class={styles.button}>
                            <Button
                              click={onImport.bind(this, permalink, index)}
                              style={"primary small"}
                              >{$t("audit_template.template_load")}</Button
                            >
                          </div>
                        {/if}
                      {/if}
                    </div>
                  {/if}
                </div>

                {#if open}
                  <div class={styles.info}>
                    <Button
                      click={onToggleInfo.bind(this, index, false)}
                      style="primary-text small"
                    >
                      {$t("audit_template.less_info")}
                    </Button>
                    {#if updated_at}
                      <div class={styles.updated}>
                        {dateTimeFormat(updated_at)}
                      </div>
                    {/if}
                  </div>
                  {#each control_group_templates as { name, control_templates }}
                    <AuditTemplateGroup {name} {control_templates} />
                  {/each}
                {:else}
                  <Button
                    click={onToggleInfo.bind(this, index, true)}
                    style="primary-text small"
                  >
                    {$t("audit_template.more_info")}
                  </Button>
                {/if}
              </div>
            </div>
          {/each}
        </Skeleton>
      </div>
    </Modal>
  {/if}
</Boundary>

<style lang="scss">
  .header {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    font-size: 18px;
  }

  .input {
    display: flex;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
  }

  .button {
    margin-left: 20px;
  }

  .info {
    display: flex;
    align-items: center;
  }

  .updated {
    margin-left: 10px;
    color: var(--primary-300);
  }

  .overwrite {
    padding: 10px 25px;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em 2em;
    border-bottom: 1px solid var(--primary-050);
  }

  .template {
    display: flex;
    flex-wrap: wrap;
    padding: 1em 2em;
    border-bottom: 1px solid var(--primary-050);

    .buttons {
      margin-left: 40px;
      gap: 8px;
    }

    .button {
      margin-left: -10px;
    }

    .options {
      margin-right: 8px;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .template-inner {
    flex: 1;
  }

  .template-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .template-content {
    flex: 1;
  }

  .template-name {
    font-size: 16px;
    color: var(--blue-200);
  }

  .template-description {
    margin: 5px 0 0;
  }

  .sidebar-title {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .sidebar-item {
    font-weight: 500;
    color: var(--blue-200);
    margin: 0 -24px;
    padding: 7px 25px;
    cursor: pointer;

    &.active {
      background-color: var(--blue-010);
    }
  }

  .sort {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .loading {
    height: 30px;
  }
</style>
