<script>
  import clsx from "clsx";
  import styles from "styleguide/SidebarArea.json";

  export let style = "";
</script>

<div
  data-component="SidebarArea"
  class={clsx(
    styles["sidebar-area"],
    style.split(" ").map((x) => styles[x]),
  )}
>
  <slot />
</div>

<style lang="scss">
  .sidebar-area {
    margin: 0px -20px 0 -20px;

    &.all {
      margin: 15px -20px 0 -20px;
    }

    &:first-child {
      margin-top: 0px;
      margin-bottom: 20px;
      border-top: none;
    }

    &.smalltabs,
    &.smalltabs.all {
      margin-top: -15px;
      margin-bottom: 15px;
    }

    &.button {
      margin-bottom: -15px;
    }

    &:last-child {
      margin-bottom: -15px;
    }
  }

  .border-top {
    border-top: 1px solid var(--primary-050);
  }

  .padding {
    padding: 0 20px;

    &.all:not(:first-child) {
      padding: 15px 20px 5px;
    }
  }

  .padding-bottom {
    padding-bottom: 20px;
  }

  .no-padding-bottom {
    padding-bottom: 0px !important;
  }
</style>
