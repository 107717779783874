export const SAMPLE_STATUS = Object.freeze({
  actionRequired: "action_required",
  alternativelyReviewed: "alternatively_reviewed",
  balanceConfirmed: "balance_confirmed",
  checkAddress: "check_address",
  creating: "creating",
  differenceIsExplained: "difference_is_explained",
  differenceIsNotExplained: "difference_is_not_explained",
  differenceIsNotSignificant: "difference_is_not_significant",
  newResponse: "new_response",
  notMarkedForShipping: "not_marked_for_shipping",
  reminder: "reminder",
  returned: "returned",
  sending: "sending",
  sent: "sent",
  waiting: "waiting",
  waitingConfirmation: "waiting_confirmation",
  waitingLetterhead: "waiting_letterhead",
});
