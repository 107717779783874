import Api from "apis/api";
import { buildFormData } from "lib/helpers.js";

class ConfirmationItems {
  constructor() {
    this.endpoint = "/confirmation_items";
  }

  getItem(options) {
    const { params, success, error } = options;

    Api.get(`${this.endpoint}/${params.id}`, {
      success: (response) => {
        success(response.data.confirmation_item);
      },
      error,
    });
  }

  create(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params, null, true);

    Api.post(`${this.endpoint}`, {
      body: formData,
      success: (response) => {
        success(response.data.confirmation_item);
      },
      error,
    });
  }

  createBatch(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params, null, true);

    Api.post(`${this.endpoint}/batch_create`, {
      body: formData,
      success: (response) => {
        success(response.data.confirmation_items);
      },
      error,
    });
  }

  edit(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params, null, true);

    Api.patch(this.endpoint + `/${params.id}`, {
      body: formData,
      success,
      error,
    });
  }

  editStatus(options) {
    const { params, success, error } = options;

    const formData = new FormData();

    formData.append("comment", params.comment);
    formData.append("status", params.status);
    formData.append("action_required", params.action_required);

    Api.patch(this.endpoint + `/${params.id}/status`, {
      body: formData,
      success,
      error,
    });
  }

  delete(options) {
    const { params, success, error } = options;

    Api.delete(this.endpoint + `/${params.id}`, {
      success,
      error,
    });
  }

  deleteBatch(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params, null, true);

    Api.delete(
      this.endpoint +
        `/batch_destroy?confirmation_id=${params.confirmation_id}`,
      {
        body: formData,
        success,
        error,
      },
    );
  }

  batchUndoSending(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params, null, true);

    Api.patch(
      this.endpoint +
        `/batch_undo_sending?confirmation_id=${params.confirmation_id}`,
      {
        body: formData,
        success,
        error,
      },
    );
  }

  getActivities(options) {
    const { params, success, error } = options;

    Api.get(`${this.endpoint}/${params.id}/activities`, {
      params,
      success: (response) => {
        success(response.data);
      },
      error,
    });
  }

  validateAddress(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params);

    Api.patch(this.endpoint + `/${params.id}/validate_address`, {
      body: formData,
      success,
      error,
    });
  }
}

export default new ConfirmationItems();
