import Rails from "rails-ujs";

$(document).on("click", "#samplings-help-close", function (event) {
  event.preventDefault();
  $("#samplings-help").remove();
});

$(document).on("change", "#confidence-level-form input", function (event) {
  var inherent = $(
    "input[name=inherent]:checked",
    "#confidence-level-form",
  ).val();
  var iks = $("input[name=iks]:checked", "#confidence-level-form").val();
  var more = $("input[name=more]:checked", "#confidence-level-form").val();

  if (inherent && iks && more) {
    Rails.fire($("#confidence-level-form")[0], "submit");
  }
});
