<script>
  import { onDestroy, onMount } from "svelte";

  let overlay;

  onMount(() => {
    let container = document.getElementById("overlay-container");
    if (!container) {
      container = document.body.appendChild(document.createElement("div"));
      container.id = "overlay-container";
    }
    container.appendChild(overlay);
  });
  onDestroy(() => {
    let container = document.getElementById("overlay-container");
    if (container) {
      container.remove();
    }
  });
</script>

<div bind:this={overlay} class="overlay">
  <slot />
</div>
