import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["alert"];

  showMessage() {
    let self = this;

    setTimeout(function () {
      if (
        document.querySelector("#samples-list-bar, #sample-status-bar") &&
        !document.querySelector(".modal.show")
      ) {
        self.alertTarget.classList.add("show-more");
      }
      self.alertTarget.classList.add("show");
    }, 20);
  }

  hideMessage() {
    let self = this;

    setTimeout(function () {
      self.alertTarget.classList.remove("show");
      self.alertTarget.classList.remove("show-more");
    }, 4500);
  }

  connect() {
    this.showMessage();
    this.hideMessage();
  }
}
