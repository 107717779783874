export const SAMPLE_BALANCE_REVIEW_FIELDS = Object.freeze([
  { label: "sample_details.card_balances.balance", key: "balance" },
  {
    label: "sample_details.card_balances.turnover_credit",
    key: "turnover_credit",
  },
  {
    label: "sample_details.card_balances.turnover_debit",
    key: "turnover_debit",
  },
  {
    label: "sample_details.card_balances.opening_balance",
    key: "opening_balance",
  },
]);
