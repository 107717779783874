<script>
  import styles from "styleguide/AuditStatusesBars.json";
  import { auditStatuses } from "stores/pbc.js";
  import { Boundary } from "components";
</script>

<Boundary>
  <div data-component="AuditStatusesBars" class={styles.wrapper}>
    <div class={styles["progress-bar"]}>
      {#if $auditStatuses.accepted_percentage}
        <span
          class={styles.accepted}
          style={`flex-grow: ${$auditStatuses.accepted_percentage}`}
        ></span>
      {/if}
      {#if $auditStatuses.waiting_for_review_percentage}
        <span
          class={styles["waiting-for-review"]}
          style={`flex-grow: ${$auditStatuses.waiting_for_review_percentage}`}
        ></span>
      {/if}
      {#if $auditStatuses.due_this_week_percentage}
        <span
          class={styles["this-week"]}
          style={`flex-grow: ${$auditStatuses.due_this_week_percentage}`}
        ></span>
      {/if}
      {#if $auditStatuses.overdue_percentage}
        <span
          class={styles.overdue}
          style={`flex-grow: ${$auditStatuses.overdue_percentage}`}
        ></span>
      {/if}
      {#if $auditStatuses.rejected_percentage}
        <span
          class={styles.rejected}
          style={`flex-grow: ${$auditStatuses.rejected_percentage}`}
        ></span>
      {/if}
      {#if $auditStatuses.open_percentage}
        <span
          class={styles.open}
          style={`flex-grow: ${$auditStatuses.open_percentage}`}
        ></span>
      {/if}
    </div>
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    margin: 5px 0 20px;
  }

  .progress-bar {
    flex: 1;
    display: flex;
    flex-direction: row;
    height: 15px;
    background: var(--blue-010);
    position: relative;
    span {
      border-right: 1px solid #fff;
    }
  }

  .progress-bar:after {
    content: "";
    position: absolute;
    top: -5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    border: 5px solid #fff;
    border-radius: 1.5rem;
  }

  .accepted {
    background: var(--green);
  }

  .waiting-for-review {
    background: var(--yellow);
  }

  .this-week {
    background: var(--yellow);
  }

  .overdue {
    background: var(--red);
  }

  .rejected {
    background: var(--red);
  }

  .open {
    background: var(--blue-010);
  }
</style>
