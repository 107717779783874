<script>
  import styles from "styleguide/ProfilePersonalData.json";
  import { onMount } from "svelte";

  import { t } from "stores/i18n.js";
  import {
    team_user,
    settingsPage,
    team_user_createable_roles,
    scimActive,
    teamUserRole,
  } from "stores/team_user.js";
  import { user } from "stores/user.js";
  import { breadcrumb } from "stores/breadcrumb.js";

  import { userPath } from "lib/helpers.js";
  import { validate } from "lib/validate.js";

  import UploadApiConstructor from "apis/upload.js";
  import TeamUsersApi from "apis/users/team_users";

  import {
    Button,
    Dropdown,
    IconButton,
    TextInput,
    Toast,
    UserPicture,
  } from "components";

  let currentTeamUser;
  let createableAdminRoles;

  let style = "";
  let nameIsValid = true;
  let emailIsValid = true;
  let phoneIsValid = true;

  let cssClasses = "";
  let newAvatar = null;
  let fileInput;
  let uppyWrapper;
  let UploadApi = new UploadApiConstructor();

  $: isDisabled = !nameIsValid || !emailIsValid || !phoneIsValid;
  $: roleTypes = createableAdminRoles;
  $: teamUserRoleName = $teamUserRole;

  team_user.subscribe((team_user) => {
    if (team_user) {
      currentTeamUser = team_user;
      breadcrumb.set([
        { translation: "administration", path: userPath + "/account" },
        { translation: "employees", path: userPath + "/team_users" },
        {
          name: currentTeamUser.name
            ? currentTeamUser.name
            : currentTeamUser.email,
          click: () => {
            settingsPage.set("overview");
            window.history.pushState(
              "",
              "",
              userPath + "/team_users/" + currentTeamUser.permalink + "/edit",
            );
          },
        },
        { translation: "personal_data" },
      ]);
    }
  });

  team_user_createable_roles.subscribe((team_user_createable_roles) => {
    if (team_user_createable_roles) {
      createableAdminRoles = team_user_createable_roles;
    }
  });

  $: cssClasses = calculateClasses([...style], emailIsValid);

  onMount(() => {
    calculateClasses;
    UploadApi.setupUppy(uppyWrapper);
    getCreateableAdminRoles();
  });

  function isNameValid(name) {
    const namePattern = /^[a-zA-ZÀ-ÿ\s]+$/;
    nameIsValid = namePattern.test(name);
  }
  function isEmailValid(email) {
    let valid;
    valid = validate(email, ["email"]);
    emailIsValid = valid.length == 0 ? true : false;
  }

  function isPhoneValid(phone) {
    const phonePattern = /^(?:\+|00)\d{1,3}\s?\d{5,15}$/;
    phoneIsValid = phonePattern.test(phone);
  }

  function calculateClasses(isvalid) {
    let classes = isvalid ? "border medium round-border" : "error";
    return classes;
  }

  function onAvatar() {
    fileInput.click();
  }

  function setAvatar(event) {
    newAvatar = event.target.files[0];

    let reader = new FileReader();
    reader.onload = () => {
      currentTeamUser.avatar = reader.result;
      fileInput.value = "";
      updateTeamUser();
    };
    reader.readAsDataURL(newAvatar);
  }

  function removeUserAvatar() {
    if (confirm($t("personal_data.confirm_delete_avatar"))) {
      TeamUsersApi.removeAvatar(currentTeamUser);
    }
  }

  function updateTeamUser() {
    TeamUsersApi.updateTeamUser(currentTeamUser);
  }

  function getCreateableAdminRoles() {
    TeamUsersApi.getCreateableAdminRoles($user);
  }
</script>

<div class={styles.content}>
  <div class={styles["first-section"]}>
    <div class={styles["section-header"]}>
      {$t("personal_data.avatar")}
    </div>

    <div class={styles["avatar-upload"]}>
      <div class={styles["user-avatar"]}>
        <UserPicture
          name={currentTeamUser.name}
          avatar={currentTeamUser.avatar}
          isAvatarClickable={false}
        />
      </div>
      <div class={styles.buttons}>
        <Button click={onAvatar} style="primary-text">
          {$t("personal_data.upload")}
        </Button>
        {#if currentTeamUser.avatar}
          <IconButton
            click={removeUserAvatar}
            style="error-text"
            icon={"trash"}
          />
        {/if}
      </div>
    </div>
  </div>
  <div class={styles["user-details"]}>
    {#if $scimActive}
      <Toast message={$t("profile_settings.scim_active")} type="info" />
    {/if}
    <div class={styles["input-section"]}>
      <div class={styles.input}>
        <div class={styles["input-header"]}>Name</div>
        <TextInput
          errors={nameIsValid ? [] : [$t("personal_data.name_error")]}
          style={cssClasses}
          bind:value={currentTeamUser.name}
          onKeyup={isNameValid}
          placeholder={currentTeamUser.name}
          type={"text"}
          disabled={$scimActive}
          lock={$scimActive}
        />
        <div class={styles["input-description"]}>
          {$t("personal_data.name_desc")}
        </div>
      </div>
      <div class={styles.input}>
        <div class={styles["input-header"]}>
          {$t("personal_data.email")}
        </div>
        <TextInput
          errors={emailIsValid ? [] : [$t("personal_data.email_error")]}
          style={cssClasses}
          onKeyup={isEmailValid}
          bind:value={currentTeamUser.email}
          placeholder={currentTeamUser.email
            ? currentTeamUser.email
            : $t("personal_data.email_placeholder")}
          type={"text"}
          disabled={$scimActive}
          lock={$scimActive}
        />
        <div class={styles["input-description"]}>
          {$t("personal_data.email_desc")}
        </div>
      </div>
    </div>
    <div class={styles["input-section"]}>
      <div class={styles.input}>
        <div class={styles["input-header"]}>
          {$t("personal_data.phone")}
        </div>
        <TextInput
          errors={phoneIsValid ? [] : [$t("personal_data.phone_error")]}
          style={cssClasses}
          onKeyup={isPhoneValid}
          bind:value={currentTeamUser.phone}
          placeholder={currentTeamUser.phone
            ? currentTeamUser.phone
            : $t("personal_data.phone_placeholder")}
        />
        <div class={styles["input-description"]}>
          {$t("personal_data.phone_desc")}
        </div>
      </div>
      <div class={styles.input}>
        <div class={styles["input-header"]}>
          {$t("personal_data.role")}
        </div>
        <div class={styles.dateInput}>
          <Dropdown
            style="user-settings"
            items={roleTypes}
            placement="bottom"
            initial={teamUserRoleName}
            bind:selected={currentTeamUser.role}
            disabled={$scimActive}
            lock={$scimActive}
          />
        </div>
        <div class={styles["input-description"]} />
      </div>
    </div>
  </div>
  <div class={styles["save-button"]}>
    <Button click={updateTeamUser} style="primary" disabled={isDisabled}
      >{$t("personal_data.save")}</Button
    >
  </div>
</div>
<div class={styles["not-visible"]}>
  <input
    type="file"
    bind:this={fileInput}
    class={styles.fileInput}
    on:change={setAvatar}
    accept="image/*"
  />
</div>

<style scoped lang="scss">
  .content {
    top: 24px;
    padding: 24px;
    background: var(--white);
    width: 679px;
    box-shadow:
      0px 6px 8px -6px rgba(24, 39, 75, 0.12),
      0px 8px 16px -6px rgba(24, 39, 75, 0.08);
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
  }

  .first-section {
    border-bottom: 1px solid #eaecf1;
  }

  .avatar-upload {
    display: flex;
    gap: 12px;
    padding: 16px 0px 24px 0px;
  }

  .buttons {
    display: flex;
    gap: 8px;
  }

  .section-header {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: var(--primary-500);
  }

  .user-details {
    padding-top: 24px;
  }

  .input-section {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    padding-bottom: 24px;
  }

  .input {
    width: -webkit-fill-available;
  }

  .input-header {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: var(--primary-500);
  }

  .input-description {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    padding-top: 4px;
    color: var(--primary-300);
  }

  .dropdown-title {
    font-weight: 400;
  }

  .error {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--red-200);
    padding: 4px 0px;
  }

  .no-display {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .not-visible {
    display: none;
  }
</style>
