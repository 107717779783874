<script>
  import { t } from "stores/i18n.js";
  import { Tooltip } from "components";
  import styles from "styleguide/UserPicture.json";

  export let name = "";
  export let avatar = "";
  export let highlighted;
  export let smallAvatar;
  export let isUserMenu = false;
  export let active = false;
  export let isAvatarClickable = true;
  export let exceedingUsers = [];

  $: initials = getInitials(name);

  function getInitials(name) {
    return (
      name &&
      name
        .split(" ")
        .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
        .join("")
        .toLocaleUpperCase()
    );
  }

  $: calculatedStyles = function () {
    let s = ["wrapper"];
    if (active) {
      s.push("active");
    }
    if (highlighted) {
      s.push("highlighted");
    }
    if (avatar) {
      s.push("has-avatar");
    }
    if (smallAvatar) {
      s.push("small-avatar");
    }
    if (isAvatarClickable) {
      s.push("clickable");
    }
    return s.map((x) => styles[x]).join(" ");
  };

  function toogleActiveState() {
    if (isAvatarClickable) {
      active = !active;
    }
  }
</script>

<Tooltip on:click showArrow={true} forceHide={isUserMenu}>
  <div
    slot="content"
    data-component="UserPicture"
    class={calculatedStyles()}
    on:click={toogleActiveState}
    style="background-image: url({avatar}); background-size: cover;"
  >
    {#if !avatar}
      <div class={styles.initials}>{initials}</div>
    {/if}
  </div>
  <svelte:fragment slot="tooltip">
    {#if !exceedingUsers.length}
      <span class={styles.name}>{name}</span>
    {:else}
      <div class={styles["tooltip-header"]}>
        {name}
        {$t("user_picture.members")}
      </div>
      <ul>
        {#each exceedingUsers as user}
          <li>{user}</li>
        {/each}
      </ul>
    {/if}
  </svelte:fragment>
</Tooltip>

<style lang="scss">
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: solid 1px var(--primary-050);
    background-color: var(--white);
    overflow: hidden;
    object-fit: fill;
    background-position: center;
    background-repeat: no-repeat;

    &.has-avatar {
      background-size: cover;
      background-color: transparent;
    }

    &.small-avatar {
      width: 32px;
      height: 32px;
    }

    &.highlighted {
      background-color: rgba(254, 235, 119, 0.15);
    }

    &.clickable:hover {
      border-color: var(--brand-100);
      img {
        opacity: 0.8;
      }
    }
    &.clickable:active {
      border-color: var(--brand-100);
      img {
        opacity: 0.6;
      }
    }

    &.clickable.active {
      outline: 3px solid var(--white);
      border-color: var(--brand-100);
    }
  }

  .name {
    white-space: nowrap;
  }

  .initials {
    font-weight: 500;
  }

  .tooltip-header {
    border-bottom: 1px solid var(--primary-050);
    padding-bottom: 8px;
    margin-bottom: 8px;
  }
</style>
