<script>
  import { onMount, getContext } from "svelte";
  import { get } from "svelte/store";
  import { addedFiles, uploadedFiles } from "stores/quickshare.js";

  import { LegacyButton as Button } from "components";
  import { QuickshareFileItem } from "components/quickshare";

  export let file;
  export let index;

  const api = getContext("oksend");
  let progress = 0;
  let key;
  let name;
  let size;

  onMount(async () => {
    name = file.name;
    size = file.size;

    uploadFile();
  });

  function onRemoveFile(key) {
    let newUploadedFiles = get(uploadedFiles).filter((f) => f.key !== key);
    uploadedFiles.set(newUploadedFiles);

    let newAddedFiles = get(addedFiles).filter((f, i) => i !== index);
    addedFiles.set(newAddedFiles);
  }

  function uploadFile() {
    api.uploads.upload({
      file: file,
      add: onAdd,
      progress: onProgress,
      complete: onComplete,
      error: onError,
    });
  }

  function onAdd(fileKey, fileName, fileSize) {
    key = fileKey;
  }

  function onProgress(key, uploadProgress) {
    console.log(`Progress for file ${key}: ${uploadProgress}`);
    progress = uploadProgress;
  }

  function onComplete(key) {
    console.log(`Upload Complete for file ${key}`);
    let newUploadedFiles = [
      ...$uploadedFiles,
      {
        key: key,
        name: name,
        size: size,
      },
    ];
    console.log("Setting newUploadedFiles", newUploadedFiles);
    uploadedFiles.set(newUploadedFiles);
  }

  function onError(key, error) {
    console.log(`Error while uploading file ${key}`);
  }
</script>

<QuickshareFileItem {progress} {name} {size} {key}>
  <Button
    style="border icon-only"
    icon="trash.svg"
    click={(e) => onRemoveFile(key)}
  />
</QuickshareFileItem>
