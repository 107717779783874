<script>
  import styles from "styleguide/MailingAttachmentModal.json";
  import { onMount } from "svelte";
  import { toasts } from "stores/toasts.js";
  import { t } from "stores/i18n.js";
  import {
    item,
    confirmationId,
    getItem,
    confirmationAttachments,
    getConfirmationAttachments,
  } from "stores/confirmation/confirmation_item.js";

  import ConfirmationAttachmentsApi from "apis/confirmation/confirmation_attachments.js";

  import { ConfirmationFileItem } from "apps/confirmation";

  import { Button, Checkbox, Dropzone } from "components";
  import { Modal } from "components/modals";

  export let shown = false;
  export let mailingId;
  export let assignedAttachments = [];
  export let totalFilesSize = 0;
  export let isConfirmed;

  let dropzone;
  let loadingState = false;

  onMount(() => {
    getConfirmationAttachments();
  });

  function onHide() {
    shown = false;
  }

  function reset() {
    getItem();
    getConfirmationAttachments();
  }

  function isAttachmentsSelected(attachmentId) {
    return assignedAttachments.includes(attachmentId);
  }

  function toggleAttachmentsSelect(attachment) {
    const { permalink, filesize } = attachment;

    if (isAttachmentsSelected(permalink)) {
      assignedAttachments = assignedAttachments.filter(
        (item) => item !== permalink,
      );
      totalFilesSize -= filesize;
    } else {
      assignedAttachments = [...assignedAttachments, permalink];
      totalFilesSize += filesize;
    }
  }

  function handleAttachmentDelete(id) {
    ConfirmationAttachmentsApi.delete({
      params: {
        confirmation_id: $confirmationId,
        id,
      },
      success: () => {
        reset();
      },
    });
  }

  function handleAttachmentsUpload(files) {
    loadingState = true;

    ConfirmationAttachmentsApi.upload({
      params: {
        confirmation_id: $confirmationId,
        files: [...files],
      },
      success: () => {
        loadingState = false;
        reset();
        toasts.send({
          title: $t("mailing_attachment_modal.success_upload_message"),
          type: "success",
        });
      },
      error: () => {
        loadingState = false;
        toasts.send({
          title: $t("mailing_attachment_modal.error_upload_message_title"),
          message: $t("mailing_attachment_modal.error_upload_message_desc"),
          type: "error",
        });
      },
    });
  }

  function handleSubmit() {
    if (totalFilesSize >= 10) {
      return toasts.send({
        title: $t("mailing_attachment_modal.error_upload_message_title"),
        message: $t("mailing_attachment_modal.error_uploaded_message_desc"),
        type: "error",
      });
    }

    ConfirmationAttachmentsApi.assign({
      params: {
        confirmation_id: $confirmationId,
        mailing_id: mailingId,
        attachment_ids: assignedAttachments,
      },
      success: () => {
        reset();
        onHide();
        toasts.send({
          title: $t("mailing_attachment_modal.success_uploaded_message_title"),
          type: "success",
        });
      },
    });
  }
</script>

<Modal
  style="no-padding"
  {shown}
  {onHide}
  title={$t("mailing_attachment_modal.title")}
>
  <div data-component="MailingAttachmentModal">
    <div class={styles.container}>
      <div class={styles.description}>
        {@html $t("mailing_attachment_modal.description")}
      </div>
    </div>

    <div class={styles.container}>
      <div class={styles.dropzone}>
        <Dropzone
          bind:this={dropzone}
          bind:loadingState
          onFiles={handleAttachmentsUpload}
          accept="application/pdf"
        />
      </div>
    </div>

    {#if $confirmationAttachments.length}
      <div class={styles.container}>
        <div class={styles["uploaded-files"]}>
          <div class={styles.title}>
            {$t("mailing_attachment_modal.uploaded_files_title")}
          </div>
          <div class={styles.desc}>
            {$t("mailing_attachment_modal.uploaded_files_desc")}
          </div>

          <div class={styles["files-group"]}>
            {#each $confirmationAttachments as attachment}
              <div class={styles["file-item"]}>
                <div class={styles["file-item-attachment"]}>
                  {#if !isConfirmed}
                    <div>
                      <Checkbox
                        toggle={() => toggleAttachmentsSelect(attachment)}
                        toggled={isAttachmentsSelected(attachment.permalink)}
                      />
                    </div>
                  {/if}

                  <ConfirmationFileItem
                    {attachment}
                    disabled={attachment.in_use}
                    tooltipMessage={$t(
                      "mailing_attachment_modal.delete_button_tooltip",
                    )}
                    buttonTitle={$t("mailing_attachment_modal.delete_button")}
                    handleDelete={() =>
                      handleAttachmentDelete(attachment.permalink)}
                  />
                </div>
              </div>
            {/each}
          </div>
        </div>
      </div>
    {/if}
  </div>

  <svelte:fragment slot="bottom">
    {#if $confirmationAttachments.length}
      <Button style="primary" click={handleSubmit}>
        {$t("mailing_attachment_modal.submit_button")}
      </Button>
    {/if}
  </svelte:fragment>
</Modal>

<style lang="scss">
  .container {
    padding: 0 24px 8px 24px;

    .description {
      padding-top: 8px;
    }

    .uploaded-files {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .title {
        font-size: 16px;
        font-weight: 600;
      }

      .desc {
        font-size: 14px;
        font-weight: 400;
      }

      .files-group {
        display: flex;
        flex-direction: column;
        margin-top: 8px;

        .file-item {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &-attachment {
            width: 100%;
            display: flex;
            gap: 16px;
            align-items: center;
          }
        }
      }
    }
  }

  .headline {
    font-weight: 500;
    margin-bottom: 5px;
  }

  .dropzone {
    padding: 8px 0;
  }

  .file-list {
    margin-top: 15px;
  }

  .items {
    display: flex;
  }

  .bottom-padding {
    margin-bottom: 15px;
  }
</style>
