import ApiIcon from "components/icons/ApiIcon.svelte";
import ArrowLeftIcon from "components/icons/ArrowLeftIcon.svelte";
import BuildingIcon from "components/icons/BuildingIcon.svelte";
import ChartIcon from "components/icons/ChartIcon.svelte";
import ChatBubbleIcon from "components/icons/ChatBubbleIcon.svelte";
import CheckmarkMediumDoubleIcon from "components/icons/CheckmarkMediumDoubleIcon.svelte";
import CircleIcon from "components/icons/CircleIcon.svelte";
import ClipboardIcon from "components/icons/ClipboardIcon.svelte";
import CloseIcon from "components/icons/CloseIcon.svelte";
import ColorPaletteIcon from "components/icons/ColorPaletteIcon.svelte";
import CompliancePackageIcon from "components/icons/CompliancePackageIcon.svelte";
import ContactBookIcon from "components/icons/ContactBookIcon.svelte";
import CustomDevelopmentIcon from "components/icons/CustomDevelopmentIcon.svelte";
import CustomPasswordRuleIcon from "components/icons/CustomPasswordRuleIcon.svelte";
import CustomPBCTemplatesIcon from "components/icons/CustomPBCTemplatesIcon.svelte";
import DefaultSettingsIcon from "components/icons/DefaultSettingsIcon.svelte";
import EmailSignatureIcon from "components/icons/EmailSignatureIcon.svelte";
import EmployeeBadgeIcon from "components/icons/EmployeeBadgeIcon.svelte";
import ErrorFilledIcon from "components/icons/ErrorFilledIcon.svelte";
import ExportOutlineIcon from "components/icons/ExportOutlineIcon.svelte";
import ExtendedUserRolesIcon from "components/icons/ExtendedUserRolesIcon.svelte";
import ImportIcon from "components/icons/ImportIcon.svelte";
import InfoFilledIcon from "components/icons/InfoFilledIcon.svelte";
import InfoIcon from "components/icons/InfoIcon.svelte";
import InvoiceIcon from "components/icons/InvoiceIcon.svelte";
import IpAllowListIcon from "components/icons/IpAllowListIcon.svelte";
import MailIcon from "components/icons/MailIcon.svelte";
import MuchIcon from "components/icons/MuchIcon.svelte";
import OfficeIcon from "components/icons/OfficeIcon.svelte";
import PersonalIcon from "components/icons/PersonalIcon.svelte";
import PersonalizationIcon from "components/icons/PersonalizationIcon.svelte";
import PlayIcon from "components/icons/PlayIcon.svelte";
import ProjectAccessRulesIcon from "components/icons/ProjectAccessRulesIcon.svelte";
import RushIcon from "components/icons/RushIcon.svelte";
import SecurityIcon from "components/icons/SecurityIcon.svelte";
import SecurityQuestionnairesIcon from "components/icons/SecurityQuestionnairesIcon.svelte";
import SingleSignOnIcon from "components/icons/SingleSignOnIcon.svelte";
import SpinnerIcon from "components/icons/SpinnerIcon.svelte";
import StatisticIcon from "components/icons/StatisticIcon.svelte";
import SynchronizationIcon from "components/icons/SynchronizationIcon.svelte";
import TriangleRight from "components/icons/TriangleRight.svelte";
import TwoFactorAuthIcon from "components/icons/TwoFactorAuthIcon.svelte";
import UserIcon from "components/icons/UserIcon.svelte";
import UserLockIcon from "components/icons/UserLockIcon.svelte";
import UserMultiIcon from "components/icons/UserMultiIcon.svelte";
import WebIcon from "components/icons/WebIcon.svelte";
import WorkGroupIcon from "components/icons/WorkGroupIcon.svelte";
import CheckmarkIcon from "./CheckmarkIcon.svelte";

export const iconByKey = {
  // settings icons
  security: SecurityIcon,
  personalization: PersonalizationIcon,
  employeeBadge: EmployeeBadgeIcon,
  //feature icons
  personal: PersonalIcon,
  userLock: UserLockIcon,
  building: BuildingIcon,
  userMulti: UserMultiIcon,
  invoice: InvoiceIcon,
  clipboard: ClipboardIcon,
  colorPalette: ColorPaletteIcon,
  compliancePackage: CompliancePackageIcon,
  chart: ChartIcon,
  synchronization: SynchronizationIcon,
  defaultSettings: DefaultSettingsIcon,
  singleSignOn: SingleSignOnIcon,
  api: ApiIcon,
  //more from upsell modal
  customDevelopment: CustomDevelopmentIcon,
  customPBCTemplates: CustomPBCTemplatesIcon,
  securityQuestionnaires: SecurityQuestionnairesIcon,
  customPasswordRule: CustomPasswordRuleIcon,
  emailSignature: EmailSignatureIcon,
  twoFactorAuth: TwoFactorAuthIcon,
  ipAllowList: IpAllowListIcon,
  extendedUserRoles: ExtendedUserRolesIcon,
  projectAccessRules: ProjectAccessRulesIcon,
  //shared icon
  arrowLeft: ArrowLeftIcon,
  web: WebIcon,
  office: OfficeIcon,
  spinner: SpinnerIcon,
  checkmarkIcon: CheckmarkIcon,
  close: CloseIcon,
  errorFilled: ErrorFilledIcon,
  user: UserIcon,
  contactBook: ContactBookIcon,
  exportOutline: ExportOutlineIcon,
  mail: MailIcon,
  chatBubble: ChatBubbleIcon,
  import: ImportIcon,
  play: PlayIcon,
  statistic: StatisticIcon,
  much: MuchIcon,
  checkmarkMediumDouble: CheckmarkMediumDoubleIcon,
  triangleRight: TriangleRight,
  infoFilled: InfoFilledIcon,
  info: InfoIcon,
  rush: RushIcon,
  workGroup: WorkGroupIcon,
  circle: CircleIcon,
};
