<script>
  import styles from "styleguide/AuditSidebarStatus.json";

  import { user } from "stores/user.js";
  import { t } from "stores/i18n.js";
  import { can } from "stores/permission.js";
  import {
    audit,
    filter,
    activeAudit,
    activeExport,
    auditStatuses,
    activeRollForward,
    IsHidingAcceptedControls,
    resetSelected,
  } from "stores/pbc.js";

  import { downloadFile } from "lib/helpers.js";
  import AuditApi from "apis/pbc/audit.js";

  import { AuditStatusesBars } from "apps/pbc";
  import { Boundary, Button, Counter, SidebarArea, Switch } from "components";
  import CheckmarkMediumDoubleIcon from "components/icons/CheckmarkMediumDoubleIcon.svelte";

  function downloadAuditExportExcel() {
    AuditApi.getExportExcel({
      params: { id: $audit.id },
      success: downloadFile,
    });
  }

  function setFilter(value) {
    resetSelected();
    filter.set(value);
    AuditApi.goToFilters(value);
    window.scrollTo(0, 0);
  }

  function hideAccepted(value) {
    IsHidingAcceptedControls.set(value);
  }

  let dueThisWeekCount = 0;

  audit.subscribe((value) => {
    dueThisWeekCount = getDueThisWeekCount(value);
  });

  function getDueThisWeekCount(audit) {
    let counter = 0;

    if (audit.control_groups) {
      audit.control_groups.forEach((group) => {
        group.controls.forEach((control) => {
          if (
            control.due_on_status_class === "orange" &&
            control.status !== "accepted"
          ) {
            counter++;
          }
        });
      });
    }

    return counter;
  }

  function markAttachmentsAsRead() {
    if (confirm($t("questions.sure"))) {
      AuditApi.markAttachmentsAsRead();
    }
  }

  function markCommentsAsRead() {
    if (confirm($t("questions.sure"))) {
      AuditApi.markCommentsAsRead();
    }
  }
</script>

<Boundary>
  <div data-component="AuditSidebarStatus" class={styles.wrapper}>
    <AuditStatusesBars />

    <div class={styles.statuses}>
      <div class={styles.status}>
        <Button style={"filter-action"} click={() => setFilter("pending")}>
          <Counter
            count={$auditStatuses.open_control_count}
            variant="info"
            withBorder={$filter === "pending"}
          />
          {$t("control_status.open")}
        </Button>
      </div>
      <div class={styles.status}>
        <Button style={"filter-action"} click={() => setFilter("accepted")}>
          <Counter
            count={$auditStatuses.accepted_control_count}
            variant="success"
            withBorder={$filter === "accepted"}
          />
          {$t("control_status.accepted")}
        </Button>
      </div>
      <div class={styles.status}>
        <Button style={"filter-action"} click={() => setFilter("this-week")}>
          <Counter
            count={dueThisWeekCount}
            variant="warning"
            withBorder={$filter === "this-week"}
          />
          {$t("control_status.this-week")}
        </Button>
      </div>
      <div class={styles.status}>
        <Button style={"filter-action"} click={() => setFilter("wfr")}>
          <Counter
            count={$auditStatuses.waiting_for_review_count}
            variant="warning"
            withBorder={$filter === "wfr"}
          />
          {$t("control_status.wfr")}
        </Button>
      </div>
      <div class={styles.status}>
        <Button style={"filter-action"} click={() => setFilter("overdue")}>
          <Counter
            count={$auditStatuses.overdue_control_count}
            variant="error"
            withBorder={$filter === "overdue"}
          />
          {$t("control_status.overdue")}
        </Button>
      </div>
      <div class={styles.status}>
        <Button style={"filter-action"} click={() => setFilter("rejected")}>
          <Counter
            count={$auditStatuses.rejected_control_count}
            variant="error"
            withBorder={$filter === "rejected"}
          />
          {$t("control_status.rejected")}
        </Button>
      </div>
    </div>

    <SidebarArea style={"border-top padding padding-bottom"}>
      <div class={styles.switch}>
        <Switch toggled={$IsHidingAcceptedControls} toggle={hideAccepted} />
        {$t("audit_sidebar_status.hide_accepted")}
      </div>
    </SidebarArea>

    <SidebarArea style="border-top padding">
      <div class={styles.status}>
        <Button style={"filter-action"} click={() => setFilter("new-comments")}>
          <Counter
            count={$auditStatuses.with_new_comments_count}
            variant="default"
            withBorder={$filter === "new-comments"}
          />
          {$t("control_status.new-comments")}
        </Button>
      </div>
      <div class={styles["read-button"]}>
        <Button
          style={"filter-action"}
          click={() => markCommentsAsRead()}
          disabled={!$auditStatuses.with_new_comments_count}
        >
          <div class={styles["svg-wrapper"]}>
            <CheckmarkMediumDoubleIcon width="30px" />
          </div>
          {@html $t("audit_sidebar_status.mark_all_comments_as_read")}
        </Button>
      </div>
      <div class={styles.status}>
        <Button
          style={"filter-action"}
          click={() => setFilter("new-documents")}
        >
          <Counter
            count={$auditStatuses.with_new_documents_count}
            variant="default"
            withBorder={$filter === "new-documents"}
          />
          {$t("control_status.new-documents")}
        </Button>
      </div>
      <div class={styles["read-button"]}>
        <Button
          style={"filter-action"}
          click={() => markAttachmentsAsRead()}
          disabled={!$auditStatuses.with_new_documents_count}
        >
          <div class={styles["svg-wrapper"]}>
            <CheckmarkMediumDoubleIcon width="30px" />
          </div>
          {@html $t("audit_sidebar_status.mark_all_documents_as_read")}
        </Button>
      </div>
    </SidebarArea>

    <SidebarArea style={"all button"}>
      {#if $can("read", "audit_export")}
        <Button
          click={downloadAuditExportExcel.bind(this)}
          style={"blue-text sidebar-action"}
          >{$t("audit_sidebar_status.export")}</Button
        >

        <Button
          click={() => activeExport.set(true)}
          style={"blue-text sidebar-action"}
          >{$t("audit_sidebar_status.export_documents")}</Button
        >
      {/if}

      {#if $can("update", "audit") || $can("destroy", "audit")}
        <Button
          click={() => activeAudit.set(true)}
          style={"blue-text sidebar-action"}
          >{$t("audit_sidebar_status.edit_pbc")}</Button
        >
      {/if}

      {#if $user.role !== "client"}
        <Button
          click={() => activeRollForward.set(true)}
          style={"blue-text sidebar-action"}
          >{$t("audit_sidebar_status.roll_forward")}</Button
        >
      {/if}
    </SidebarArea>
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    padding: 15px 20px;
  }

  .statuses {
    padding-bottom: 20px;
  }

  .status,
  .read-button {
    display: flex;
    justify-content: space-between;

    &:first-child {
      margin-top: 20px;
    }
    svg {
      margin-right: 12px;
    }
  }

  .switch {
    display: flex;
    align-items: center;
    margin-top: 20px;
    gap: 6px;
    color: var(--blue);
    font-weight: 500;
  }

  .svg-wrapper {
    width: 30px;
  }
</style>
