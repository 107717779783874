<script>
  import styles from "styleguide/ListFilters.json";
</script>

<div data-component="ListFilters" class={styles["list-filters"]}>
  <slot />
</div>

<style lang="scss">
  .list-filters {
    flex: 1;
    display: flex;
    align-items: flex-start;
  }
</style>
