import Api from "apis/api";
import { buildFormData } from "lib/helpers.js";

class ConfirmationMailings {
  constructor() {
    this.endpoint = "/confirmation_mailings";
  }

  create(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params);

    Api.post(`${this.endpoint}`, {
      body: formData,
      success: (response) => {
        success(response.data);
      },
      error,
    });
  }

  delete(options) {
    const { params } = options;
    Api.delete(
      this.endpoint +
        `/${params.id}?confirmation_item_id=${params.confirmation_item_id}`,
      options,
    );
  }

  createBatch(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params);

    Api.post(`${this.endpoint}/batch_creation`, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: params,
      success: (response) => {
        success(response.data);
      },
      error,
    });
  }

  sign(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params);

    Api.post(this.endpoint + `/${params.id}/sign_mailing`, {
      body: formData,
      success: (response) => {
        success(response.data);
      },
      error,
      file: true,
    });
  }

  mark_as_sent(options) {
    const { params, success, error } = options;
    const formData = new FormData();
    buildFormData(formData, params);

    Api.patch(this.endpoint + `/${params.id}/mark_as_sent`, {
      body: formData,
      success: (response) => {
        success(response.data);
      },
      error,
    });
  }

  confirm(options) {
    const { params, success, error } = options;
    const formData = new FormData();
    buildFormData(formData, params);

    Api.patch(this.endpoint + `/${params.id}/confirm`, {
      body: formData,
      success: (response) => {
        success(response.data);
      },
      error,
    });
  }

  unconfirm(options) {
    const { params, success, error } = options;
    const formData = new FormData();
    buildFormData(formData, params);

    Api.patch(this.endpoint + `/${params.id}/unconfirm`, {
      body: formData,
      success: (response) => {
        success(response.data);
      },
      error,
    });
  }

  getByToken(options) {
    const { params, success, error } = options;

    Api.get(this.endpoint + `/${params.token}/by_token`, {
      params,
      success: (response) => {
        success(response.data.confirmation_mailing);
      },
      error,
    });
  }

  checkStatus(options) {
    const { params, success, error } = options;

    Api.get(this.endpoint + `/${params.id}/status`, {
      params,
      success: (response) => {
        success(response.data);
      },
      error,
    });
  }

  signature(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params);

    Api.post(this.endpoint + "/signature", {
      body: formData,
      success,
      error,
      file: true,
    });
  }

  switchChannel(options) {
    const { params, success, error } = options;
    const formData = new FormData();
    buildFormData(formData, params);

    Api.patch(this.endpoint + `/${params.id}/switch_channel`, {
      body: formData,
      success: (response) => {
        success(response.data);
      },
      error,
    });
  }
}

export default new ConfirmationMailings();
