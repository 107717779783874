<script>
  export let width = 20;
  export let height = 20;
  export let fill = "#052861";
</script>

<svg
  {width}
  {height}
  viewBox={`0 0 ${width} ${height}`}
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M8.99969 0.995972C4.02948 0.995972 -0.000305176 5.02576 -0.000305176 9.99597C-0.000305176 14.9669 4.02915 18.996 8.99969 18.996C13.9709 18.996 17.9997 14.9672 17.9997 9.99597C17.9997 5.02543 13.9706 0.995972 8.99969 0.995972Z"
    {fill}
  />
  <path
    d="M8.99969 8.99628C9.51253 8.99628 9.9352 9.38232 9.99297 9.87966L9.99969 9.99628V13.2963C9.99969 13.8486 9.55198 14.2963 8.99969 14.2963C8.48686 14.2963 8.06419 13.9102 8.00642 13.4129L7.99969 13.2963V9.99628C7.99969 9.44399 8.44741 8.99628 8.99969 8.99628Z"
    fill="white"
  />
  <path
    d="M9.00869 5.69617C9.56098 5.69617 10.0087 6.14388 10.0087 6.69617C10.0087 7.209 9.62265 7.63167 9.12532 7.68944L9.00869 7.69617C8.44741 7.69617 7.99969 7.24845 7.99969 6.69617C7.99969 6.18333 8.38574 5.76066 8.88307 5.70289L9.00869 5.69617Z"
    fill="white"
  />
</svg>
