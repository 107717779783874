import { Controller } from "stimulus";
import Api from "../../general/api";
import Helper from "../../general/helper";

export default class extends Controller {
  deleteSampleEvent(e) {
    e.preventDefault();
    let target = e.target.closest("a");
    Helper.confirmDelete(target.getAttribute("data-url"), {}, () => {});
  }

  toggleValidated(e) {
    e.preventDefault();
    if (e.target.tagName === "INPUT") return;
    let element = e.target.closest(".sample-details-address-toggle-validated"),
      checkbox = element.querySelector("input"),
      url = element.dataset.url;
    checkbox.checked = !checkbox.checked;
    Api.post(
      url,
      { address_validated: checkbox.checked },
      this.toggleValidatedCallback.bind(this),
    );
  }

  toggleValidatedCallback(response) {
    if (response.hasOwnProperty("address_validated")) {
      let addressBlock = document.querySelector(
          ".sample-details-address-block",
        ),
        releaseOption = document.querySelector(".sample-release-option"),
        editAction = document.querySelector(".sample-action-edit");

      if (response.address_validated) {
        if (releaseOption) {
          releaseOption.removeAttribute("data-confirm");
        }
        if (editAction) {
          editAction.classList.add("disabled");
        }
        addressBlock.classList.add("validated");
      } else {
        if (releaseOption) {
          releaseOption.setAttribute(
            "data-confirm",
            I18n.t(
              "platform.views.samples.sample_documents.master_data_validation_message",
            ),
          );
        }
        if (editAction) {
          editAction.classList.remove("disabled");
        }
        addressBlock.classList.remove("validated");
      }

      document.querySelector(
        ".sample-details-address-label.contact-address",
      ).textContent = response.label;
    }
  }
}
