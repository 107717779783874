<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(100%,100%,100%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.490196%,0%,0%);fill-opacity:1;"
      d="M -74.988281 241.761719 L 555.011719 241.761719 L 555.011719 360 L -74.988281 360 Z M -74.988281 241.761719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 44.777344 246.769531 L 79.28125 246.769531 L 79.28125 249.140625 L 44.777344 249.140625 Z M 44.777344 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 49.921875 258.640625 L 52.296875 258.640625 L 52.296875 260.945312 L 49.921875 260.945312 Z M 49.921875 258.640625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 79.371094 258.5625 L 79.371094 260.9375 L 72.472656 260.9375 L 72.472656 258.5625 Z M 79.371094 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 83.078125 246.769531 L 85.453125 246.769531 L 85.453125 260.941406 L 83.078125 260.941406 Z M 83.078125 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 96.8125 258.5625 L 96.8125 260.9375 L 83.746094 260.9375 L 83.746094 258.5625 Z M 96.8125 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 72.472656 253.019531 L 74.847656 253.019531 L 74.847656 260.941406 L 72.472656 260.941406 Z M 72.472656 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 94.871094 246.769531 L 97.242188 246.769531 L 97.242188 260.941406 L 94.871094 260.941406 Z M 94.871094 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 88.933594 246.769531 L 91.308594 246.769531 L 91.308594 260.941406 L 88.933594 260.941406 Z M 88.933594 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 57.433594 252.410156 L 57.433594 254.785156 L 44.84375 254.785156 L 44.84375 252.410156 Z M 57.433594 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 85.066406 252.410156 L 85.066406 254.785156 L 72.472656 254.785156 L 72.472656 252.410156 Z M 85.066406 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 72.472656 253.019531 L 74.847656 253.019531 L 74.847656 260.941406 L 72.472656 260.941406 Z M 72.472656 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 72.472656 253.019531 L 74.847656 253.019531 L 74.847656 260.941406 L 72.472656 260.941406 Z M 72.472656 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 72.472656 253.019531 L 74.847656 253.019531 L 74.847656 260.941406 L 72.472656 260.941406 Z M 72.472656 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 44.84375 253.019531 L 47.214844 253.019531 L 47.214844 260.941406 L 44.84375 260.941406 Z M 44.84375 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 65.097656 253.019531 L 67.46875 253.019531 L 67.46875 260.941406 L 65.097656 260.941406 Z M 65.097656 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 55.0625 253.019531 L 57.4375 253.019531 L 57.4375 260.941406 L 55.0625 260.941406 Z M 55.0625 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 66.761719 258.5625 L 66.761719 260.9375 L 56.117188 260.9375 L 56.117188 258.5625 Z M 66.761719 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 67.46875 252.410156 L 67.46875 254.785156 L 60.722656 254.785156 L 60.722656 252.410156 Z M 67.46875 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M -11.894531 246.769531 L 22.609375 246.769531 L 22.609375 249.140625 L -11.894531 249.140625 Z M -11.894531 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 22.699219 258.5625 L 22.699219 260.9375 L 15.800781 260.9375 L 15.800781 258.5625 Z M 22.699219 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 26.40625 246.769531 L 28.78125 246.769531 L 28.78125 260.941406 L 26.40625 260.941406 Z M 26.40625 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 40.140625 258.5625 L 40.140625 260.9375 L 27.074219 260.9375 L 27.074219 258.5625 Z M 40.140625 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 15.800781 253.019531 L 18.175781 253.019531 L 18.175781 260.941406 L 15.800781 260.941406 Z M 15.800781 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 38.199219 246.769531 L 40.570312 246.769531 L 40.570312 260.941406 L 38.199219 260.941406 Z M 38.199219 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 32.261719 246.769531 L 34.636719 246.769531 L 34.636719 260.941406 L 32.261719 260.941406 Z M 32.261719 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0.761719 252.410156 L 0.761719 254.785156 L -11.828125 254.785156 L -11.828125 252.410156 Z M 0.761719 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 28.394531 252.410156 L 28.394531 254.785156 L 15.800781 254.785156 L 15.800781 252.410156 Z M 28.394531 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 15.800781 253.019531 L 18.175781 253.019531 L 18.175781 260.941406 L 15.800781 260.941406 Z M 15.800781 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 15.800781 253.019531 L 18.175781 253.019531 L 18.175781 260.941406 L 15.800781 260.941406 Z M 15.800781 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 15.800781 253.019531 L 18.175781 253.019531 L 18.175781 260.941406 L 15.800781 260.941406 Z M 15.800781 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 8.425781 253.019531 L 10.796875 253.019531 L 10.796875 260.941406 L 8.425781 260.941406 Z M 8.425781 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M -1.609375 253.019531 L 0.765625 253.019531 L 0.765625 260.941406 L -1.609375 260.941406 Z M -1.609375 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 10.089844 258.5625 L 10.089844 260.9375 L -0.554688 260.9375 L -0.554688 258.5625 Z M 10.089844 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 10.796875 252.410156 L 10.796875 254.785156 L 4.050781 254.785156 L 4.050781 252.410156 Z M 10.796875 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 100.976562 246.769531 L 135.476562 246.769531 L 135.476562 249.140625 L 100.976562 249.140625 Z M 100.976562 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 106.117188 258.640625 L 108.492188 258.640625 L 108.492188 260.945312 L 106.117188 260.945312 Z M 106.117188 258.640625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 135.566406 258.5625 L 135.566406 260.9375 L 128.671875 260.9375 L 128.671875 258.5625 Z M 135.566406 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 139.277344 246.769531 L 141.648438 246.769531 L 141.648438 260.941406 L 139.277344 260.941406 Z M 139.277344 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 153.011719 258.5625 L 153.011719 260.9375 L 139.945312 260.9375 L 139.945312 258.5625 Z M 153.011719 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 128.671875 253.019531 L 131.046875 253.019531 L 131.046875 260.941406 L 128.671875 260.941406 Z M 128.671875 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 151.066406 246.769531 L 153.441406 246.769531 L 153.441406 260.941406 L 151.066406 260.941406 Z M 151.066406 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 145.132812 246.769531 L 147.503906 246.769531 L 147.503906 260.941406 L 145.132812 260.941406 Z M 145.132812 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 113.632812 252.410156 L 113.632812 254.785156 L 101.039062 254.785156 L 101.039062 252.410156 Z M 113.632812 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 141.265625 252.410156 L 141.265625 254.785156 L 128.671875 254.785156 L 128.671875 252.410156 Z M 141.265625 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 128.671875 253.019531 L 131.046875 253.019531 L 131.046875 260.941406 L 128.671875 260.941406 Z M 128.671875 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 128.671875 253.019531 L 131.046875 253.019531 L 131.046875 260.941406 L 128.671875 260.941406 Z M 128.671875 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 128.671875 253.019531 L 131.046875 253.019531 L 131.046875 260.941406 L 128.671875 260.941406 Z M 128.671875 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 101.039062 253.019531 L 103.414062 253.019531 L 103.414062 260.941406 L 101.039062 260.941406 Z M 101.039062 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 121.292969 253.019531 L 123.667969 253.019531 L 123.667969 260.941406 L 121.292969 260.941406 Z M 121.292969 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 111.257812 253.019531 L 113.632812 253.019531 L 113.632812 260.941406 L 111.257812 260.941406 Z M 111.257812 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 122.960938 258.5625 L 122.960938 260.9375 L 112.3125 260.9375 L 112.3125 258.5625 Z M 122.960938 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 123.667969 252.410156 L 123.667969 254.785156 L 116.917969 254.785156 L 116.917969 252.410156 Z M 123.667969 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 157.539062 246.769531 L 192.039062 246.769531 L 192.039062 249.140625 L 157.539062 249.140625 Z M 157.539062 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 162.683594 258.640625 L 165.054688 258.640625 L 165.054688 260.945312 L 162.683594 260.945312 Z M 162.683594 258.640625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 192.128906 258.5625 L 192.128906 260.9375 L 185.234375 260.9375 L 185.234375 258.5625 Z M 192.128906 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 195.839844 246.769531 L 198.210938 246.769531 L 198.210938 260.941406 L 195.839844 260.941406 Z M 195.839844 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 209.574219 258.5625 L 209.574219 260.9375 L 196.507812 260.9375 L 196.507812 258.5625 Z M 209.574219 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 185.234375 253.019531 L 187.609375 253.019531 L 187.609375 260.941406 L 185.234375 260.941406 Z M 185.234375 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 207.628906 246.769531 L 210.003906 246.769531 L 210.003906 260.941406 L 207.628906 260.941406 Z M 207.628906 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 201.695312 246.769531 L 204.070312 246.769531 L 204.070312 260.941406 L 201.695312 260.941406 Z M 201.695312 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 170.195312 252.410156 L 170.195312 254.785156 L 157.601562 254.785156 L 157.601562 252.410156 Z M 170.195312 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 197.828125 252.410156 L 197.828125 254.785156 L 185.234375 254.785156 L 185.234375 252.410156 Z M 197.828125 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 185.234375 253.019531 L 187.609375 253.019531 L 187.609375 260.941406 L 185.234375 260.941406 Z M 185.234375 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 185.234375 253.019531 L 187.609375 253.019531 L 187.609375 260.941406 L 185.234375 260.941406 Z M 185.234375 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 185.234375 253.019531 L 187.609375 253.019531 L 187.609375 260.941406 L 185.234375 260.941406 Z M 185.234375 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 157.601562 253.019531 L 159.976562 253.019531 L 159.976562 260.941406 L 157.601562 260.941406 Z M 157.601562 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 177.855469 253.019531 L 180.230469 253.019531 L 180.230469 260.941406 L 177.855469 260.941406 Z M 177.855469 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 167.820312 253.019531 L 170.195312 253.019531 L 170.195312 260.941406 L 167.820312 260.941406 Z M 167.820312 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 179.523438 258.5625 L 179.523438 260.9375 L 168.878906 260.9375 L 168.878906 258.5625 Z M 179.523438 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 180.230469 252.410156 L 180.230469 254.785156 L 173.480469 254.785156 L 173.480469 252.410156 Z M 180.230469 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 214.308594 246.769531 L 248.808594 246.769531 L 248.808594 249.140625 L 214.308594 249.140625 Z M 214.308594 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 219.453125 258.640625 L 221.828125 258.640625 L 221.828125 260.945312 L 219.453125 260.945312 Z M 219.453125 258.640625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 248.898438 258.5625 L 248.898438 260.9375 L 242.003906 260.9375 L 242.003906 258.5625 Z M 248.898438 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 252.609375 246.769531 L 254.984375 246.769531 L 254.984375 260.941406 L 252.609375 260.941406 Z M 252.609375 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 266.34375 258.5625 L 266.34375 260.9375 L 253.277344 260.9375 L 253.277344 258.5625 Z M 266.34375 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 242.003906 253.019531 L 244.378906 253.019531 L 244.378906 260.941406 L 242.003906 260.941406 Z M 242.003906 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 264.402344 246.769531 L 266.773438 246.769531 L 266.773438 260.941406 L 264.402344 260.941406 Z M 264.402344 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 258.464844 246.769531 L 260.839844 246.769531 L 260.839844 260.941406 L 258.464844 260.941406 Z M 258.464844 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 226.964844 252.410156 L 226.964844 254.785156 L 214.371094 254.785156 L 214.371094 252.410156 Z M 226.964844 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 254.597656 252.410156 L 254.597656 254.785156 L 242.003906 254.785156 L 242.003906 252.410156 Z M 254.597656 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 242.003906 253.019531 L 244.378906 253.019531 L 244.378906 260.941406 L 242.003906 260.941406 Z M 242.003906 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 242.003906 253.019531 L 244.378906 253.019531 L 244.378906 260.941406 L 242.003906 260.941406 Z M 242.003906 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 242.003906 253.019531 L 244.378906 253.019531 L 244.378906 260.941406 L 242.003906 260.941406 Z M 242.003906 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 214.371094 253.019531 L 216.746094 253.019531 L 216.746094 260.941406 L 214.371094 260.941406 Z M 214.371094 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 234.625 253.019531 L 237 253.019531 L 237 260.941406 L 234.625 260.941406 Z M 234.625 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 224.59375 253.019531 L 226.964844 253.019531 L 226.964844 260.941406 L 224.59375 260.941406 Z M 224.59375 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 236.292969 258.5625 L 236.292969 260.9375 L 225.648438 260.9375 L 225.648438 258.5625 Z M 236.292969 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 237 252.410156 L 237 254.785156 L 230.253906 254.785156 L 230.253906 252.410156 Z M 237 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(13.72549%,62.352941%,25.098039%);fill-opacity:1;"
      d="M -74.988281 0 L 555.011719 0 L 555.011719 118.238281 L -74.988281 118.238281 Z M -74.988281 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 271.410156 246.769531 L 305.910156 246.769531 L 305.910156 249.140625 L 271.410156 249.140625 Z M 271.410156 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 276.554688 258.640625 L 278.925781 258.640625 L 278.925781 260.945312 L 276.554688 260.945312 Z M 276.554688 258.640625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 306 258.5625 L 306 260.9375 L 299.105469 260.9375 L 299.105469 258.5625 Z M 306 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 309.710938 246.769531 L 312.082031 246.769531 L 312.082031 260.941406 L 309.710938 260.941406 Z M 309.710938 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 323.445312 258.5625 L 323.445312 260.9375 L 310.378906 260.9375 L 310.378906 258.5625 Z M 323.445312 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 299.105469 253.019531 L 301.480469 253.019531 L 301.480469 260.941406 L 299.105469 260.941406 Z M 299.105469 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 321.5 246.769531 L 323.875 246.769531 L 323.875 260.941406 L 321.5 260.941406 Z M 321.5 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 315.566406 246.769531 L 317.941406 246.769531 L 317.941406 260.941406 L 315.566406 260.941406 Z M 315.566406 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 284.066406 252.410156 L 284.066406 254.785156 L 271.472656 254.785156 L 271.472656 252.410156 Z M 284.066406 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 311.699219 252.410156 L 311.699219 254.785156 L 299.105469 254.785156 L 299.105469 252.410156 Z M 311.699219 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 299.105469 253.019531 L 301.480469 253.019531 L 301.480469 260.941406 L 299.105469 260.941406 Z M 299.105469 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 299.105469 253.019531 L 301.480469 253.019531 L 301.480469 260.941406 L 299.105469 260.941406 Z M 299.105469 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 299.105469 253.019531 L 301.480469 253.019531 L 301.480469 260.941406 L 299.105469 260.941406 Z M 299.105469 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 271.472656 253.019531 L 273.847656 253.019531 L 273.847656 260.941406 L 271.472656 260.941406 Z M 271.472656 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 291.726562 253.019531 L 294.101562 253.019531 L 294.101562 260.941406 L 291.726562 260.941406 Z M 291.726562 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 281.691406 253.019531 L 284.066406 253.019531 L 284.066406 260.941406 L 281.691406 260.941406 Z M 281.691406 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 293.394531 258.5625 L 293.394531 260.9375 L 282.75 260.9375 L 282.75 258.5625 Z M 293.394531 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 294.101562 252.410156 L 294.101562 254.785156 L 287.351562 254.785156 L 287.351562 252.410156 Z M 294.101562 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 328.171875 246.769531 L 362.675781 246.769531 L 362.675781 249.140625 L 328.171875 249.140625 Z M 328.171875 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 333.316406 258.640625 L 335.691406 258.640625 L 335.691406 260.945312 L 333.316406 260.945312 Z M 333.316406 258.640625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 362.765625 258.5625 L 362.765625 260.9375 L 355.867188 260.9375 L 355.867188 258.5625 Z M 362.765625 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 366.472656 246.769531 L 368.847656 246.769531 L 368.847656 260.941406 L 366.472656 260.941406 Z M 366.472656 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 380.207031 258.5625 L 380.207031 260.9375 L 367.140625 260.9375 L 367.140625 258.5625 Z M 380.207031 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 355.867188 253.019531 L 358.242188 253.019531 L 358.242188 260.941406 L 355.867188 260.941406 Z M 355.867188 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 378.265625 246.769531 L 380.636719 246.769531 L 380.636719 260.941406 L 378.265625 260.941406 Z M 378.265625 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 372.328125 246.769531 L 374.703125 246.769531 L 374.703125 260.941406 L 372.328125 260.941406 Z M 372.328125 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 340.828125 252.410156 L 340.828125 254.785156 L 328.238281 254.785156 L 328.238281 252.410156 Z M 340.828125 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 368.460938 252.410156 L 368.460938 254.785156 L 355.867188 254.785156 L 355.867188 252.410156 Z M 368.460938 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 355.867188 253.019531 L 358.242188 253.019531 L 358.242188 260.941406 L 355.867188 260.941406 Z M 355.867188 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 355.867188 253.019531 L 358.242188 253.019531 L 358.242188 260.941406 L 355.867188 260.941406 Z M 355.867188 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 355.867188 253.019531 L 358.242188 253.019531 L 358.242188 260.941406 L 355.867188 260.941406 Z M 355.867188 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 328.238281 253.019531 L 330.609375 253.019531 L 330.609375 260.941406 L 328.238281 260.941406 Z M 328.238281 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 348.492188 253.019531 L 350.863281 253.019531 L 350.863281 260.941406 L 348.492188 260.941406 Z M 348.492188 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 338.457031 253.019531 L 340.832031 253.019531 L 340.832031 260.941406 L 338.457031 260.941406 Z M 338.457031 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 350.15625 258.5625 L 350.15625 260.9375 L 339.511719 260.9375 L 339.511719 258.5625 Z M 350.15625 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 350.863281 252.410156 L 350.863281 254.785156 L 344.117188 254.785156 L 344.117188 252.410156 Z M 350.863281 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 384.941406 246.769531 L 419.445312 246.769531 L 419.445312 249.140625 L 384.941406 249.140625 Z M 384.941406 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 390.085938 258.640625 L 392.460938 258.640625 L 392.460938 260.945312 L 390.085938 260.945312 Z M 390.085938 258.640625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 419.535156 258.5625 L 419.535156 260.9375 L 412.640625 260.9375 L 412.640625 258.5625 Z M 419.535156 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 423.242188 246.769531 L 425.617188 246.769531 L 425.617188 260.941406 L 423.242188 260.941406 Z M 423.242188 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 436.980469 258.5625 L 436.980469 260.9375 L 423.910156 260.9375 L 423.910156 258.5625 Z M 436.980469 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 412.640625 253.019531 L 415.011719 253.019531 L 415.011719 260.941406 L 412.640625 260.941406 Z M 412.640625 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 435.035156 246.769531 L 437.410156 246.769531 L 437.410156 260.941406 L 435.035156 260.941406 Z M 435.035156 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 429.097656 246.769531 L 431.472656 246.769531 L 431.472656 260.941406 L 429.097656 260.941406 Z M 429.097656 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 397.601562 252.410156 L 397.601562 254.785156 L 385.007812 254.785156 L 385.007812 252.410156 Z M 397.601562 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 425.230469 252.410156 L 425.230469 254.785156 L 412.640625 254.785156 L 412.640625 252.410156 Z M 425.230469 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 412.640625 253.019531 L 415.011719 253.019531 L 415.011719 260.941406 L 412.640625 260.941406 Z M 412.640625 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 412.640625 253.019531 L 415.011719 253.019531 L 415.011719 260.941406 L 412.640625 260.941406 Z M 412.640625 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 412.640625 253.019531 L 415.011719 253.019531 L 415.011719 260.941406 L 412.640625 260.941406 Z M 412.640625 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 385.007812 253.019531 L 387.378906 253.019531 L 387.378906 260.941406 L 385.007812 260.941406 Z M 385.007812 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 405.261719 253.019531 L 407.636719 253.019531 L 407.636719 260.941406 L 405.261719 260.941406 Z M 405.261719 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 395.226562 253.019531 L 397.601562 253.019531 L 397.601562 260.941406 L 395.226562 260.941406 Z M 395.226562 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 406.925781 258.5625 L 406.925781 260.9375 L 396.28125 260.9375 L 396.28125 258.5625 Z M 406.925781 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 407.636719 252.410156 L 407.636719 254.785156 L 400.886719 254.785156 L 400.886719 252.410156 Z M 407.636719 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 442.042969 246.769531 L 476.546875 246.769531 L 476.546875 249.140625 L 442.042969 249.140625 Z M 442.042969 246.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 447.1875 258.640625 L 449.5625 258.640625 L 449.5625 260.945312 L 447.1875 260.945312 Z M 447.1875 258.640625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 476.636719 258.5625 L 476.636719 260.9375 L 469.738281 260.9375 L 469.738281 258.5625 Z M 476.636719 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 469.738281 253.019531 L 472.113281 253.019531 L 472.113281 260.941406 L 469.738281 260.941406 Z M 469.738281 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 454.699219 252.410156 L 454.699219 254.785156 L 442.109375 254.785156 L 442.109375 252.410156 Z M 454.699219 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 482.332031 252.410156 L 482.332031 254.785156 L 469.738281 254.785156 L 469.738281 252.410156 Z M 482.332031 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 469.738281 253.019531 L 472.113281 253.019531 L 472.113281 260.941406 L 469.738281 260.941406 Z M 469.738281 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 469.738281 253.019531 L 472.113281 253.019531 L 472.113281 260.941406 L 469.738281 260.941406 Z M 469.738281 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 469.738281 253.019531 L 472.113281 253.019531 L 472.113281 260.941406 L 469.738281 260.941406 Z M 469.738281 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 442.109375 253.019531 L 444.480469 253.019531 L 444.480469 260.941406 L 442.109375 260.941406 Z M 442.109375 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 462.363281 253.019531 L 464.734375 253.019531 L 464.734375 260.941406 L 462.363281 260.941406 Z M 462.363281 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 452.328125 253.019531 L 454.703125 253.019531 L 454.703125 260.941406 L 452.328125 260.941406 Z M 452.328125 253.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 464.027344 258.5625 L 464.027344 260.9375 L 453.382812 260.9375 L 453.382812 258.5625 Z M 464.027344 258.5625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 464.734375 252.410156 L 464.734375 254.785156 L 457.988281 254.785156 L 457.988281 252.410156 Z M 464.734375 252.410156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 44.777344 100.859375 L 79.28125 100.859375 L 79.28125 103.230469 L 44.777344 103.230469 Z M 44.777344 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 49.921875 112.730469 L 52.296875 112.730469 L 52.296875 115.035156 L 49.921875 115.035156 Z M 49.921875 112.730469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 79.371094 112.65625 L 79.371094 115.027344 L 72.472656 115.027344 L 72.472656 112.65625 Z M 79.371094 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 83.078125 100.859375 L 85.453125 100.859375 L 85.453125 115.03125 L 83.078125 115.03125 Z M 83.078125 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 96.8125 112.65625 L 96.8125 115.027344 L 83.746094 115.027344 L 83.746094 112.65625 Z M 96.8125 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 72.472656 107.109375 L 74.847656 107.109375 L 74.847656 115.03125 L 72.472656 115.03125 Z M 72.472656 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 94.871094 100.859375 L 97.242188 100.859375 L 97.242188 115.03125 L 94.871094 115.03125 Z M 94.871094 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 88.933594 100.859375 L 91.308594 100.859375 L 91.308594 115.03125 L 88.933594 115.03125 Z M 88.933594 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 57.433594 106.5 L 57.433594 108.875 L 44.84375 108.875 L 44.84375 106.5 Z M 57.433594 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 85.066406 106.5 L 85.066406 108.875 L 72.472656 108.875 L 72.472656 106.5 Z M 85.066406 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 72.472656 107.109375 L 74.847656 107.109375 L 74.847656 115.03125 L 72.472656 115.03125 Z M 72.472656 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 72.472656 107.109375 L 74.847656 107.109375 L 74.847656 115.03125 L 72.472656 115.03125 Z M 72.472656 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 72.472656 107.109375 L 74.847656 107.109375 L 74.847656 115.03125 L 72.472656 115.03125 Z M 72.472656 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 44.84375 107.109375 L 47.214844 107.109375 L 47.214844 115.03125 L 44.84375 115.03125 Z M 44.84375 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 65.097656 107.109375 L 67.46875 107.109375 L 67.46875 115.03125 L 65.097656 115.03125 Z M 65.097656 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 55.0625 107.109375 L 57.4375 107.109375 L 57.4375 115.03125 L 55.0625 115.03125 Z M 55.0625 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 66.761719 112.65625 L 66.761719 115.027344 L 56.117188 115.027344 L 56.117188 112.65625 Z M 66.761719 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 67.46875 106.5 L 67.46875 108.875 L 60.722656 108.875 L 60.722656 106.5 Z M 67.46875 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M -11.894531 100.859375 L 22.609375 100.859375 L 22.609375 103.230469 L -11.894531 103.230469 Z M -11.894531 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 22.699219 112.65625 L 22.699219 115.027344 L 15.800781 115.027344 L 15.800781 112.65625 Z M 22.699219 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 26.40625 100.859375 L 28.78125 100.859375 L 28.78125 115.03125 L 26.40625 115.03125 Z M 26.40625 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 40.140625 112.65625 L 40.140625 115.027344 L 27.074219 115.027344 L 27.074219 112.65625 Z M 40.140625 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 15.800781 107.109375 L 18.175781 107.109375 L 18.175781 115.03125 L 15.800781 115.03125 Z M 15.800781 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 38.199219 100.859375 L 40.570312 100.859375 L 40.570312 115.03125 L 38.199219 115.03125 Z M 38.199219 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 32.261719 100.859375 L 34.636719 100.859375 L 34.636719 115.03125 L 32.261719 115.03125 Z M 32.261719 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0.761719 106.5 L 0.761719 108.875 L -11.828125 108.875 L -11.828125 106.5 Z M 0.761719 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 28.394531 106.5 L 28.394531 108.875 L 15.800781 108.875 L 15.800781 106.5 Z M 28.394531 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 15.800781 107.109375 L 18.175781 107.109375 L 18.175781 115.03125 L 15.800781 115.03125 Z M 15.800781 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 15.800781 107.109375 L 18.175781 107.109375 L 18.175781 115.03125 L 15.800781 115.03125 Z M 15.800781 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 15.800781 107.109375 L 18.175781 107.109375 L 18.175781 115.03125 L 15.800781 115.03125 Z M 15.800781 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 8.425781 107.109375 L 10.796875 107.109375 L 10.796875 115.03125 L 8.425781 115.03125 Z M 8.425781 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M -1.609375 107.109375 L 0.765625 107.109375 L 0.765625 115.03125 L -1.609375 115.03125 Z M -1.609375 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 10.089844 112.65625 L 10.089844 115.027344 L -0.554688 115.027344 L -0.554688 112.65625 Z M 10.089844 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 10.796875 106.5 L 10.796875 108.875 L 4.050781 108.875 L 4.050781 106.5 Z M 10.796875 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 100.976562 100.859375 L 135.476562 100.859375 L 135.476562 103.230469 L 100.976562 103.230469 Z M 100.976562 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 106.117188 112.730469 L 108.492188 112.730469 L 108.492188 115.035156 L 106.117188 115.035156 Z M 106.117188 112.730469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 135.566406 112.65625 L 135.566406 115.027344 L 128.671875 115.027344 L 128.671875 112.65625 Z M 135.566406 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 139.277344 100.859375 L 141.648438 100.859375 L 141.648438 115.03125 L 139.277344 115.03125 Z M 139.277344 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 153.011719 112.65625 L 153.011719 115.027344 L 139.945312 115.027344 L 139.945312 112.65625 Z M 153.011719 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 128.671875 107.109375 L 131.046875 107.109375 L 131.046875 115.03125 L 128.671875 115.03125 Z M 128.671875 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 151.066406 100.859375 L 153.441406 100.859375 L 153.441406 115.03125 L 151.066406 115.03125 Z M 151.066406 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 145.132812 100.859375 L 147.503906 100.859375 L 147.503906 115.03125 L 145.132812 115.03125 Z M 145.132812 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 113.632812 106.5 L 113.632812 108.875 L 101.039062 108.875 L 101.039062 106.5 Z M 113.632812 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 141.265625 106.5 L 141.265625 108.875 L 128.671875 108.875 L 128.671875 106.5 Z M 141.265625 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 128.671875 107.109375 L 131.046875 107.109375 L 131.046875 115.03125 L 128.671875 115.03125 Z M 128.671875 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 128.671875 107.109375 L 131.046875 107.109375 L 131.046875 115.03125 L 128.671875 115.03125 Z M 128.671875 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 128.671875 107.109375 L 131.046875 107.109375 L 131.046875 115.03125 L 128.671875 115.03125 Z M 128.671875 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 101.039062 107.109375 L 103.414062 107.109375 L 103.414062 115.03125 L 101.039062 115.03125 Z M 101.039062 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 121.292969 107.109375 L 123.667969 107.109375 L 123.667969 115.03125 L 121.292969 115.03125 Z M 121.292969 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 111.257812 107.109375 L 113.632812 107.109375 L 113.632812 115.03125 L 111.257812 115.03125 Z M 111.257812 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 122.960938 112.65625 L 122.960938 115.027344 L 112.3125 115.027344 L 112.3125 112.65625 Z M 122.960938 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 123.667969 106.5 L 123.667969 108.875 L 116.917969 108.875 L 116.917969 106.5 Z M 123.667969 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 157.539062 100.859375 L 192.039062 100.859375 L 192.039062 103.230469 L 157.539062 103.230469 Z M 157.539062 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 162.683594 112.730469 L 165.054688 112.730469 L 165.054688 115.035156 L 162.683594 115.035156 Z M 162.683594 112.730469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 192.128906 112.65625 L 192.128906 115.027344 L 185.234375 115.027344 L 185.234375 112.65625 Z M 192.128906 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 195.839844 100.859375 L 198.210938 100.859375 L 198.210938 115.03125 L 195.839844 115.03125 Z M 195.839844 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 209.574219 112.65625 L 209.574219 115.027344 L 196.507812 115.027344 L 196.507812 112.65625 Z M 209.574219 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 185.234375 107.109375 L 187.609375 107.109375 L 187.609375 115.03125 L 185.234375 115.03125 Z M 185.234375 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 207.628906 100.859375 L 210.003906 100.859375 L 210.003906 115.03125 L 207.628906 115.03125 Z M 207.628906 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 201.695312 100.859375 L 204.070312 100.859375 L 204.070312 115.03125 L 201.695312 115.03125 Z M 201.695312 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 170.195312 106.5 L 170.195312 108.875 L 157.601562 108.875 L 157.601562 106.5 Z M 170.195312 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 197.828125 106.5 L 197.828125 108.875 L 185.234375 108.875 L 185.234375 106.5 Z M 197.828125 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 185.234375 107.109375 L 187.609375 107.109375 L 187.609375 115.03125 L 185.234375 115.03125 Z M 185.234375 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 185.234375 107.109375 L 187.609375 107.109375 L 187.609375 115.03125 L 185.234375 115.03125 Z M 185.234375 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 185.234375 107.109375 L 187.609375 107.109375 L 187.609375 115.03125 L 185.234375 115.03125 Z M 185.234375 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 157.601562 107.109375 L 159.976562 107.109375 L 159.976562 115.03125 L 157.601562 115.03125 Z M 157.601562 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 177.855469 107.109375 L 180.230469 107.109375 L 180.230469 115.03125 L 177.855469 115.03125 Z M 177.855469 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 167.820312 107.109375 L 170.195312 107.109375 L 170.195312 115.03125 L 167.820312 115.03125 Z M 167.820312 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 179.523438 112.65625 L 179.523438 115.027344 L 168.878906 115.027344 L 168.878906 112.65625 Z M 179.523438 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 180.230469 106.5 L 180.230469 108.875 L 173.480469 108.875 L 173.480469 106.5 Z M 180.230469 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 214.308594 100.859375 L 248.808594 100.859375 L 248.808594 103.230469 L 214.308594 103.230469 Z M 214.308594 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 219.453125 112.730469 L 221.828125 112.730469 L 221.828125 115.035156 L 219.453125 115.035156 Z M 219.453125 112.730469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 248.898438 112.65625 L 248.898438 115.027344 L 242.003906 115.027344 L 242.003906 112.65625 Z M 248.898438 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 252.609375 100.859375 L 254.984375 100.859375 L 254.984375 115.03125 L 252.609375 115.03125 Z M 252.609375 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 266.34375 112.65625 L 266.34375 115.027344 L 253.277344 115.027344 L 253.277344 112.65625 Z M 266.34375 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 242.003906 107.109375 L 244.378906 107.109375 L 244.378906 115.03125 L 242.003906 115.03125 Z M 242.003906 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 264.402344 100.859375 L 266.773438 100.859375 L 266.773438 115.03125 L 264.402344 115.03125 Z M 264.402344 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 258.464844 100.859375 L 260.839844 100.859375 L 260.839844 115.03125 L 258.464844 115.03125 Z M 258.464844 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 226.964844 106.5 L 226.964844 108.875 L 214.371094 108.875 L 214.371094 106.5 Z M 226.964844 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 254.597656 106.5 L 254.597656 108.875 L 242.003906 108.875 L 242.003906 106.5 Z M 254.597656 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 242.003906 107.109375 L 244.378906 107.109375 L 244.378906 115.03125 L 242.003906 115.03125 Z M 242.003906 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 242.003906 107.109375 L 244.378906 107.109375 L 244.378906 115.03125 L 242.003906 115.03125 Z M 242.003906 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 242.003906 107.109375 L 244.378906 107.109375 L 244.378906 115.03125 L 242.003906 115.03125 Z M 242.003906 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 214.371094 107.109375 L 216.746094 107.109375 L 216.746094 115.03125 L 214.371094 115.03125 Z M 214.371094 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 234.625 107.109375 L 237 107.109375 L 237 115.03125 L 234.625 115.03125 Z M 234.625 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 224.59375 107.109375 L 226.964844 107.109375 L 226.964844 115.03125 L 224.59375 115.03125 Z M 224.59375 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 236.292969 112.65625 L 236.292969 115.027344 L 225.648438 115.027344 L 225.648438 112.65625 Z M 236.292969 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 237 106.5 L 237 108.875 L 230.253906 108.875 L 230.253906 106.5 Z M 237 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 271.410156 100.859375 L 305.910156 100.859375 L 305.910156 103.230469 L 271.410156 103.230469 Z M 271.410156 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 276.554688 112.730469 L 278.925781 112.730469 L 278.925781 115.035156 L 276.554688 115.035156 Z M 276.554688 112.730469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 306 112.65625 L 306 115.027344 L 299.105469 115.027344 L 299.105469 112.65625 Z M 306 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 309.710938 100.859375 L 312.082031 100.859375 L 312.082031 115.03125 L 309.710938 115.03125 Z M 309.710938 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 323.445312 112.65625 L 323.445312 115.027344 L 310.378906 115.027344 L 310.378906 112.65625 Z M 323.445312 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 299.105469 107.109375 L 301.480469 107.109375 L 301.480469 115.03125 L 299.105469 115.03125 Z M 299.105469 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 321.5 100.859375 L 323.875 100.859375 L 323.875 115.03125 L 321.5 115.03125 Z M 321.5 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 315.566406 100.859375 L 317.941406 100.859375 L 317.941406 115.03125 L 315.566406 115.03125 Z M 315.566406 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 284.066406 106.5 L 284.066406 108.875 L 271.472656 108.875 L 271.472656 106.5 Z M 284.066406 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 311.699219 106.5 L 311.699219 108.875 L 299.105469 108.875 L 299.105469 106.5 Z M 311.699219 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 299.105469 107.109375 L 301.480469 107.109375 L 301.480469 115.03125 L 299.105469 115.03125 Z M 299.105469 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 299.105469 107.109375 L 301.480469 107.109375 L 301.480469 115.03125 L 299.105469 115.03125 Z M 299.105469 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 299.105469 107.109375 L 301.480469 107.109375 L 301.480469 115.03125 L 299.105469 115.03125 Z M 299.105469 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 271.472656 107.109375 L 273.847656 107.109375 L 273.847656 115.03125 L 271.472656 115.03125 Z M 271.472656 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 291.726562 107.109375 L 294.101562 107.109375 L 294.101562 115.03125 L 291.726562 115.03125 Z M 291.726562 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 281.691406 107.109375 L 284.066406 107.109375 L 284.066406 115.03125 L 281.691406 115.03125 Z M 281.691406 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 293.394531 112.65625 L 293.394531 115.027344 L 282.75 115.027344 L 282.75 112.65625 Z M 293.394531 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 294.101562 106.5 L 294.101562 108.875 L 287.351562 108.875 L 287.351562 106.5 Z M 294.101562 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 328.171875 100.859375 L 362.675781 100.859375 L 362.675781 103.230469 L 328.171875 103.230469 Z M 328.171875 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 333.316406 112.730469 L 335.691406 112.730469 L 335.691406 115.035156 L 333.316406 115.035156 Z M 333.316406 112.730469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 362.765625 112.65625 L 362.765625 115.027344 L 355.867188 115.027344 L 355.867188 112.65625 Z M 362.765625 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 366.472656 100.859375 L 368.847656 100.859375 L 368.847656 115.03125 L 366.472656 115.03125 Z M 366.472656 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 380.207031 112.65625 L 380.207031 115.027344 L 367.140625 115.027344 L 367.140625 112.65625 Z M 380.207031 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 355.867188 107.109375 L 358.242188 107.109375 L 358.242188 115.03125 L 355.867188 115.03125 Z M 355.867188 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 378.265625 100.859375 L 380.636719 100.859375 L 380.636719 115.03125 L 378.265625 115.03125 Z M 378.265625 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 372.328125 100.859375 L 374.703125 100.859375 L 374.703125 115.03125 L 372.328125 115.03125 Z M 372.328125 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 340.828125 106.5 L 340.828125 108.875 L 328.238281 108.875 L 328.238281 106.5 Z M 340.828125 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 368.460938 106.5 L 368.460938 108.875 L 355.867188 108.875 L 355.867188 106.5 Z M 368.460938 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 355.867188 107.109375 L 358.242188 107.109375 L 358.242188 115.03125 L 355.867188 115.03125 Z M 355.867188 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 355.867188 107.109375 L 358.242188 107.109375 L 358.242188 115.03125 L 355.867188 115.03125 Z M 355.867188 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 355.867188 107.109375 L 358.242188 107.109375 L 358.242188 115.03125 L 355.867188 115.03125 Z M 355.867188 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 328.238281 107.109375 L 330.609375 107.109375 L 330.609375 115.03125 L 328.238281 115.03125 Z M 328.238281 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 348.492188 107.109375 L 350.863281 107.109375 L 350.863281 115.03125 L 348.492188 115.03125 Z M 348.492188 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 338.457031 107.109375 L 340.832031 107.109375 L 340.832031 115.03125 L 338.457031 115.03125 Z M 338.457031 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 350.15625 112.65625 L 350.15625 115.027344 L 339.511719 115.027344 L 339.511719 112.65625 Z M 350.15625 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 350.863281 106.5 L 350.863281 108.875 L 344.117188 108.875 L 344.117188 106.5 Z M 350.863281 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 384.941406 100.859375 L 419.445312 100.859375 L 419.445312 103.230469 L 384.941406 103.230469 Z M 384.941406 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 390.085938 112.730469 L 392.460938 112.730469 L 392.460938 115.035156 L 390.085938 115.035156 Z M 390.085938 112.730469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 419.535156 112.65625 L 419.535156 115.027344 L 412.640625 115.027344 L 412.640625 112.65625 Z M 419.535156 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 423.242188 100.859375 L 425.617188 100.859375 L 425.617188 115.03125 L 423.242188 115.03125 Z M 423.242188 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 436.980469 112.65625 L 436.980469 115.027344 L 423.910156 115.027344 L 423.910156 112.65625 Z M 436.980469 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 412.640625 107.109375 L 415.011719 107.109375 L 415.011719 115.03125 L 412.640625 115.03125 Z M 412.640625 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 435.035156 100.859375 L 437.410156 100.859375 L 437.410156 115.03125 L 435.035156 115.03125 Z M 435.035156 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 429.097656 100.859375 L 431.472656 100.859375 L 431.472656 115.03125 L 429.097656 115.03125 Z M 429.097656 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 397.601562 106.5 L 397.601562 108.875 L 385.007812 108.875 L 385.007812 106.5 Z M 397.601562 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 425.230469 106.5 L 425.230469 108.875 L 412.640625 108.875 L 412.640625 106.5 Z M 425.230469 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 412.640625 107.109375 L 415.011719 107.109375 L 415.011719 115.03125 L 412.640625 115.03125 Z M 412.640625 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 412.640625 107.109375 L 415.011719 107.109375 L 415.011719 115.03125 L 412.640625 115.03125 Z M 412.640625 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 412.640625 107.109375 L 415.011719 107.109375 L 415.011719 115.03125 L 412.640625 115.03125 Z M 412.640625 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 385.007812 107.109375 L 387.378906 107.109375 L 387.378906 115.03125 L 385.007812 115.03125 Z M 385.007812 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 405.261719 107.109375 L 407.636719 107.109375 L 407.636719 115.03125 L 405.261719 115.03125 Z M 405.261719 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 395.226562 107.109375 L 397.601562 107.109375 L 397.601562 115.03125 L 395.226562 115.03125 Z M 395.226562 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 406.925781 112.65625 L 406.925781 115.027344 L 396.28125 115.027344 L 396.28125 112.65625 Z M 406.925781 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 407.636719 106.5 L 407.636719 108.875 L 400.886719 108.875 L 400.886719 106.5 Z M 407.636719 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 442.042969 100.859375 L 476.546875 100.859375 L 476.546875 103.230469 L 442.042969 103.230469 Z M 442.042969 100.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 447.1875 112.730469 L 449.5625 112.730469 L 449.5625 115.035156 L 447.1875 115.035156 Z M 447.1875 112.730469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 476.636719 112.65625 L 476.636719 115.027344 L 469.738281 115.027344 L 469.738281 112.65625 Z M 476.636719 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 469.738281 107.109375 L 472.113281 107.109375 L 472.113281 115.03125 L 469.738281 115.03125 Z M 469.738281 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 454.699219 106.5 L 454.699219 108.875 L 442.109375 108.875 L 442.109375 106.5 Z M 454.699219 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 482.332031 106.5 L 482.332031 108.875 L 469.738281 108.875 L 469.738281 106.5 Z M 482.332031 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 469.738281 107.109375 L 472.113281 107.109375 L 472.113281 115.03125 L 469.738281 115.03125 Z M 469.738281 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 469.738281 107.109375 L 472.113281 107.109375 L 472.113281 115.03125 L 469.738281 115.03125 Z M 469.738281 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 469.738281 107.109375 L 472.113281 107.109375 L 472.113281 115.03125 L 469.738281 115.03125 Z M 469.738281 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 442.109375 107.109375 L 444.480469 107.109375 L 444.480469 115.03125 L 442.109375 115.03125 Z M 442.109375 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 462.363281 107.109375 L 464.734375 107.109375 L 464.734375 115.03125 L 462.363281 115.03125 Z M 462.363281 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 452.328125 107.109375 L 454.703125 107.109375 L 454.703125 115.03125 L 452.328125 115.03125 Z M 452.328125 107.109375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 464.027344 112.65625 L 464.027344 115.027344 L 453.382812 115.027344 L 453.382812 112.65625 Z M 464.027344 112.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 464.734375 106.5 L 464.734375 108.875 L 457.988281 108.875 L 457.988281 106.5 Z M 464.734375 106.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.098039%,0%,0%);fill-opacity:1;"
      d="M 11.589844 238.714844 L 15.839844 238.714844 L 15.839844 246.101562 L 11.589844 246.101562 Z M 11.589844 238.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.098039%,0%,0%);fill-opacity:1;"
      d="M 124.464844 238.714844 L 128.714844 238.714844 L 128.714844 246.101562 L 124.464844 246.101562 Z M 124.464844 238.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.098039%,0%,0%);fill-opacity:1;"
      d="M 39.148438 238.714844 L 43.402344 238.714844 L 43.402344 246.101562 L 39.148438 246.101562 Z M 39.148438 238.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.098039%,0%,0%);fill-opacity:1;"
      d="M 67.292969 238.714844 L 71.542969 238.714844 L 71.542969 246.101562 L 67.292969 246.101562 Z M 67.292969 238.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.098039%,0%,0%);fill-opacity:1;"
      d="M 96.023438 238.714844 L 100.273438 238.714844 L 100.273438 246.101562 L 96.023438 246.101562 Z M 96.023438 238.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.098039%,0%,0%);fill-opacity:1;"
      d="M 152.605469 238.714844 L 156.859375 238.714844 L 156.859375 246.101562 L 152.605469 246.101562 Z M 152.605469 238.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.098039%,0%,0%);fill-opacity:1;"
      d="M 295.378906 238.714844 L 299.632812 238.714844 L 299.632812 246.101562 L 295.378906 246.101562 Z M 295.378906 238.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.098039%,0%,0%);fill-opacity:1;"
      d="M 181.335938 238.714844 L 185.585938 238.714844 L 185.585938 246.101562 L 181.335938 246.101562 Z M 181.335938 238.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.098039%,0%,0%);fill-opacity:1;"
      d="M 209.484375 238.714844 L 213.738281 238.714844 L 213.738281 246.101562 L 209.484375 246.101562 Z M 209.484375 238.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.098039%,0%,0%);fill-opacity:1;"
      d="M 237.917969 238.714844 L 242.167969 238.714844 L 242.167969 246.101562 L 237.917969 246.101562 Z M 237.917969 238.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.098039%,0%,0%);fill-opacity:1;"
      d="M 266.355469 238.714844 L 270.609375 238.714844 L 270.609375 246.101562 L 266.355469 246.101562 Z M 266.355469 238.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.098039%,0%,0%);fill-opacity:1;"
      d="M 322.9375 238.714844 L 327.191406 238.714844 L 327.191406 246.101562 L 322.9375 246.101562 Z M 322.9375 238.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.098039%,0%,0%);fill-opacity:1;"
      d="M 351.667969 238.714844 L 355.921875 238.714844 L 355.921875 246.101562 L 351.667969 246.101562 Z M 351.667969 238.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.098039%,0%,0%);fill-opacity:1;"
      d="M 380.699219 238.714844 L 384.949219 238.714844 L 384.949219 246.101562 L 380.699219 246.101562 Z M 380.699219 238.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.098039%,0%,0%);fill-opacity:1;"
      d="M 407.960938 238.714844 L 412.214844 238.714844 L 412.214844 246.101562 L 407.960938 246.101562 Z M 407.960938 238.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.098039%,0%,0%);fill-opacity:1;"
      d="M 436.984375 238.714844 L 441.234375 238.714844 L 441.234375 246.101562 L 436.984375 246.101562 Z M 436.984375 238.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.098039%,0%,0%);fill-opacity:1;"
      d="M 465.421875 238.714844 L 469.675781 238.714844 L 469.675781 246.101562 L 465.421875 246.101562 Z M 465.421875 238.714844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(13.72549%,61.960784%,24.705882%);fill-opacity:1;"
      d="M 11.589844 114.332031 L 15.839844 114.332031 L 15.839844 121.71875 L 11.589844 121.71875 Z M 11.589844 114.332031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(13.72549%,61.960784%,24.705882%);fill-opacity:1;"
      d="M 124.464844 114.332031 L 128.714844 114.332031 L 128.714844 121.71875 L 124.464844 121.71875 Z M 124.464844 114.332031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(13.72549%,61.960784%,24.705882%);fill-opacity:1;"
      d="M 39.148438 114.332031 L 43.402344 114.332031 L 43.402344 121.71875 L 39.148438 121.71875 Z M 39.148438 114.332031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(13.72549%,61.960784%,24.705882%);fill-opacity:1;"
      d="M 67.292969 114.332031 L 71.542969 114.332031 L 71.542969 121.71875 L 67.292969 121.71875 Z M 67.292969 114.332031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(13.72549%,61.960784%,24.705882%);fill-opacity:1;"
      d="M 96.023438 114.332031 L 100.273438 114.332031 L 100.273438 121.71875 L 96.023438 121.71875 Z M 96.023438 114.332031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(13.72549%,61.960784%,24.705882%);fill-opacity:1;"
      d="M 152.605469 114.332031 L 156.859375 114.332031 L 156.859375 121.71875 L 152.605469 121.71875 Z M 152.605469 114.332031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(13.72549%,61.960784%,24.705882%);fill-opacity:1;"
      d="M 295.378906 114.332031 L 299.632812 114.332031 L 299.632812 121.71875 L 295.378906 121.71875 Z M 295.378906 114.332031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(13.72549%,61.960784%,24.705882%);fill-opacity:1;"
      d="M 181.335938 114.332031 L 185.585938 114.332031 L 185.585938 121.71875 L 181.335938 121.71875 Z M 181.335938 114.332031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(13.72549%,61.960784%,24.705882%);fill-opacity:1;"
      d="M 209.484375 114.332031 L 213.738281 114.332031 L 213.738281 121.71875 L 209.484375 121.71875 Z M 209.484375 114.332031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(13.72549%,61.960784%,24.705882%);fill-opacity:1;"
      d="M 237.917969 114.332031 L 242.167969 114.332031 L 242.167969 121.71875 L 237.917969 121.71875 Z M 237.917969 114.332031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(13.72549%,61.960784%,24.705882%);fill-opacity:1;"
      d="M 266.355469 114.332031 L 270.609375 114.332031 L 270.609375 121.71875 L 266.355469 121.71875 Z M 266.355469 114.332031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(13.72549%,61.960784%,24.705882%);fill-opacity:1;"
      d="M 322.9375 114.332031 L 327.191406 114.332031 L 327.191406 121.71875 L 322.9375 121.71875 Z M 322.9375 114.332031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(13.72549%,61.960784%,24.705882%);fill-opacity:1;"
      d="M 351.667969 114.332031 L 355.921875 114.332031 L 355.921875 121.71875 L 351.667969 121.71875 Z M 351.667969 114.332031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(13.72549%,61.960784%,24.705882%);fill-opacity:1;"
      d="M 380.699219 114.332031 L 384.949219 114.332031 L 384.949219 121.71875 L 380.699219 121.71875 Z M 380.699219 114.332031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(13.72549%,61.960784%,24.705882%);fill-opacity:1;"
      d="M 407.960938 114.332031 L 412.214844 114.332031 L 412.214844 121.71875 L 407.960938 121.71875 Z M 407.960938 114.332031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(13.72549%,61.960784%,24.705882%);fill-opacity:1;"
      d="M 436.984375 114.332031 L 441.234375 114.332031 L 441.234375 121.71875 L 436.984375 121.71875 Z M 436.984375 114.332031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(13.72549%,61.960784%,24.705882%);fill-opacity:1;"
      d="M 465.421875 114.332031 L 469.675781 114.332031 L 469.675781 121.71875 L 465.421875 121.71875 Z M 465.421875 114.332031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.490196%,0%,0%);fill-opacity:1;"
      d="M 256.71875 138.859375 C 262.664062 146.144531 280.984375 186.425781 245.660156 212.816406 C 229.050781 225.3125 239.335938 225.945312 239.828125 228.046875 C 266.519531 213.867188 275.238281 194.652344 275.039062 177.445312 C 274.832031 160.234375 265.710938 145.027344 256.71875 138.863281 Z M 256.71875 138.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.490196%,0%,0%);fill-opacity:1;"
      d="M 260.253906 136.988281 C 273.4375 143.738281 302.125 177.683594 271.246094 215.980469 C 290.433594 211.71875 314.828125 155.21875 260.253906 136.988281 Z M 260.253906 136.988281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.490196%,0%,0%);fill-opacity:1;"
      d="M 219.765625 136.988281 C 206.582031 143.738281 177.894531 177.683594 208.773438 215.980469 C 189.585938 211.71875 165.191406 155.21875 219.765625 136.988281 Z M 219.765625 136.988281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.490196%,0%,0%);fill-opacity:1;"
      d="M 223.25 138.859375 C 217.304688 146.144531 198.984375 186.425781 234.308594 212.816406 C 250.917969 225.3125 240.632812 225.945312 240.144531 228.046875 C 213.449219 213.867188 204.730469 194.652344 204.933594 177.445312 C 205.136719 160.234375 214.257812 145.027344 223.25 138.863281 Z M 223.25 138.859375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.490196%,0%,0%);fill-opacity:1;"
      d="M 273.875 224.375 C 263.425781 224.539062 250.253906 222.933594 240.484375 217.859375 C 242.09375 220.988281 243.429688 222.957031 245.039062 226.085938 C 254.355469 226.945312 267.210938 228.007812 273.875 224.375 Z M 273.875 224.375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.490196%,0%,0%);fill-opacity:1;"
      d="M 207.097656 224.375 C 217.542969 224.539062 230.714844 222.933594 240.484375 217.859375 C 238.875 220.988281 237.539062 222.957031 235.929688 226.085938 C 226.613281 226.945312 213.757812 228.007812 207.097656 224.375 Z M 207.097656 224.375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.490196%,0%,0%);fill-opacity:1;"
      d="M 226.261719 126.839844 C 228.378906 132.46875 233.933594 133.285156 239.878906 129.972656 C 244.210938 132.574219 250.910156 132.738281 253.21875 127.113281 C 254.976562 141.023438 240.347656 137.734375 239.804688 135.011719 C 234.359375 140.273438 224.21875 137.234375 226.261719 126.839844 Z M 226.261719 126.839844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.490196%,0%,0%);fill-opacity:1;"
      d="M 240.269531 233.132812 L 245.761719 226.855469 L 246.546875 142.355469 L 240.007812 136.601562 L 233.464844 142.09375 L 234.773438 227.117188 Z M 240.269531 233.132812 "
    />
  </g>
</svg>
