<script>
  import styles from "styleguide/ControlStatusItem.json";
  import { iconPath } from "lib/helpers.js";
  import { t } from "stores/i18n.js";

  let isDropdownOpen;

  function toggleDropdownOpen() {
    isDropdownOpen = !isDropdownOpen;
  }
</script>

<div data-component="ControlStatusItem" class={styles["status-item"]}>
  <div class={styles["status-label"]}>
    <div>{$t("control_modal.assignees")}</div>
    <div on:click={toggleDropdownOpen}>
      <img src={iconPath("settings")} alt="settings" />
    </div>
  </div>

  <div class={styles["status-context"]}>
    <div class={styles["status-no-data"]}>
      {$t("control_modal.not_assigned")}
    </div>
  </div>
</div>

<style lang="scss">
  .status-item {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .status-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
  }

  .status-no-data {
    color: var(--primary-100, #052861);
    font-style: italic;
    font-weight: 400;
  }
</style>
