<script>
  import { t } from "stores/i18n.js";
  import {
    projectActivities,
    getProjectsActivities,
  } from "stores/dashboard/project.js";

  import { Activities, Box } from "components";
</script>

<div data-component="DashboardProjectActivity">
  <Box title={$t("dashboard_project_activity.activity")}>
    <Activities
      style="max-height"
      activitiesStore={projectActivities}
      getActivities={getProjectsActivities}
    />
  </Box>
</div>
