<script>
  import styles from "styleguide/ConfirmationSidebarContacts.json";
  import { isUserTeamUser } from "stores/user.js";
  import { t } from "stores/i18n.js";
  import { can } from "stores/permission.js";
  import {
    usersByTypes,
    typeEdit,
    confirmation,
  } from "stores/confirmation/confirmation.js";
  import { dateTimeFormat } from "lib/helpers.js";
  import { Button, SidebarArea } from "components";

  function getTitle(type) {
    return (
      $t("confirmation_sidebar_contacts.edit") &&
      $t("confirmation_sidebar_contacts.edit").replace(
        "%{type}",
        $t(`confirmation.types.${type}`),
      )
    );
  }

  function editConfirmationType(type, title) {
    typeEdit.set({
      type,
      title,
    });
  }
</script>

<div data-component="ConfirmationSidebarContacts">
  {#each Object.entries($usersByTypes) as [type, items] (type)}
    {#if items}
      {#if items.length || $isUserTeamUser}
        <SidebarArea style="border-top padding all">
          <div class={styles.title}>{$t(`confirmation.types.${type}`)}</div>

          {#each items as { client_user, can_see_answer, can_manage_confirmation_items }}
            <div class={styles.item}>
              {client_user.email}
              <div
                class="{styles.answer} {can_see_answer
                  ? styles.yes
                  : styles.no}"
              >
                {can_see_answer
                  ? $t("confirmation_sidebar_contacts.can_see_answer")
                  : $t("confirmation_sidebar_contacts.can_not_see_answer")}
              </div>

              <div
                class="{styles.answer} {can_manage_confirmation_items
                  ? styles.yes
                  : styles.no}"
              >
                {can_manage_confirmation_items
                  ? $t(
                      "confirmation_sidebar_contacts.can_manage_confirmation_items",
                    )
                  : $t(
                      "confirmation_sidebar_contacts.cant_manage_confirmation_items",
                    )}
              </div>
            </div>
          {/each}

          {#if $confirmation[`${type}_fiscal_year_beginning_date`]}
            <div class={styles.value}>
              <div class={styles.subtitle}>
                {$t("confirmation.settings.fiscal_year_beginning_date")}
              </div>
              <div>
                {dateTimeFormat(
                  $confirmation[`${type}_fiscal_year_beginning_date`],
                ).split(",")[0]}
              </div>
            </div>
          {/if}
          {#if $confirmation[`${type}_target_date`]}
            <div class={styles.value}>
              <div class={styles.subtitle}>
                {$t("confirmation.settings.target_date")}
              </div>
              <div>
                {dateTimeFormat($confirmation[`${type}_target_date`]).split(
                  ",",
                )[0]}
              </div>
            </div>
          {/if}
          {#if $confirmation[`${type}_deadline`]}
            <div class={styles.value}>
              <div class={styles.subtitle}>
                {$t("confirmation.settings.deadline")}
              </div>
              <div>
                {dateTimeFormat($confirmation[`${type}_deadline`]).split(
                  ",",
                )[0]}
              </div>
            </div>
          {/if}
          {#if $confirmation[`${type}_fixed_response_period`]}
            <div class={styles.value}>
              <div class={styles.subtitle}>
                {$t("confirmation.settings.fixed_response_period")}
              </div>
              <div>
                {dateTimeFormat(
                  $confirmation[`${type}_fixed_response_period`],
                ).split(",")[0]}
              </div>
            </div>
          {/if}
          {#if $confirmation[`${type}_start_sending_at`]}
            <div class={styles.value}>
              <div class={styles.subtitle}>
                {$t("confirmation.settings.start_sending_at")}
              </div>
              <div>
                {dateTimeFormat(
                  $confirmation[`${type}_start_sending_at`],
                ).split(",")[0]}
              </div>
            </div>
          {/if}
        </SidebarArea>
      {/if}

      {#if $can("update", "confirmation_client_user") && $isUserTeamUser}
        <SidebarArea style="button">
          <Button
            style="blue-text sidebar-action last-but-only-child"
            click={() => editConfirmationType(type, getTitle(type, $t))}
            >{getTitle(type, $t)}</Button
          >
        </SidebarArea>
      {/if}
    {/if}
  {/each}
</div>

<style lang="scss">
  .item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .value {
    margin: 12px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .title {
    font-weight: 600;
    margin-bottom: 8px;
  }

  .subtitle {
    font-size: 12px;
    line-height: 18px;
    color: var(--primary-300);
  }

  .answer {
    font-size: 12px;

    &.yes {
      color: var(--green-500);
    }

    &.no {
      color: var(--orange-400);
    }
  }
</style>
