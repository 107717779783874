<script>
  import styles from "styleguide/ControlItem.json";
  import { user } from "stores/user.js";
  import {
    setActiveControl,
    selectedControls,
    selectedGroups,
    isNumeration,
  } from "stores/pbc.js";

  import { ControlTags } from "apps/pbc";
  import { Boundary, Checkbox, Dragicon } from "components";

  export let permalink;
  export let control_group_id;
  export let name;
  export let nameNumeration;
  export let status;
  export let due_on;
  export let documents_count;
  export let has_new_comments;
  export let has_new_documents;
  export let due_on_status_class;
  export let created_by_client;
  export let onSort;

  $: isSelected = $selectedControls.find((cp) => cp == permalink) !== undefined;

  function onCheckboxToggle(toggled) {
    if (toggled) {
      selectedControls.update((scs) => [...scs, permalink]);
    } else {
      selectedControls.update((scs) => scs.filter((cp) => cp !== permalink));
      selectedGroups.update((scs) =>
        scs.filter((cp) => cp !== control_group_id),
      );
    }
  }

  function showControl() {
    setActiveControl(permalink);
  }
</script>

<Boundary>
  <div data-component="ControlItem" class={styles.control}>
    <div class={styles["checkbox-wrapper"]}>
      <Checkbox style="top" toggle={onCheckboxToggle} toggled={isSelected} />
    </div>
    <div class={styles.content} on:click={showControl}>
      <div class={styles.name}>
        {#if $isNumeration}
          {nameNumeration}
        {:else}
          {name}
        {/if}
      </div>
      <div class={styles.tags}>
        <ControlTags
          {status}
          dueOn={due_on}
          documentsCount={documents_count}
          newComments={has_new_comments}
          newDocuments={has_new_documents}
          createdByClient={created_by_client}
          thisWeek={due_on_status_class === "orange"}
        />
      </div>
    </div>
    {#if $user.role !== "client"}
      <div class={`${styles.dragicon} dragicon-item`}>
        <Dragicon {onSort} />
      </div>
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .control {
    display: flex;
    background: #fff;
    border-top: 1px solid var(--primary-050);

    &:last-child {
      border-bottom-left-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }
  }

  .checkbox-wrapper {
    padding: 0 15px;
  }

  .content {
    padding: 10px 0;
    flex: 1;
    cursor: pointer;
  }

  .name {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--blue-200);
  }

  .tags {
    margin-top: 5px;
  }

  .dragicon {
    display: flex;
    padding: 0 22px;
    align-items: center;
  }
</style>
