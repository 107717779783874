<script>
  import styles from "styleguide/Smalltabs.json";
</script>

<div data-component="Smalltabs" class={styles.smalltabs}>
  <slot />
</div>

<style lang="scss">
  .smalltabs {
    display: flex;
    border-bottom: 1px solid var(--primary-050);
  }
</style>
