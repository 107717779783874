<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,19.215686%,61.176471%);fill-opacity:1;"
      d="M 0 0 L 160.003906 0 L 160.003906 360 L 0 360 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,87.058824%,0%);fill-opacity:1;"
      d="M 160.003906 0 L 320.007812 0 L 320.007812 360 L 160.003906 360 Z M 160.003906 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(87.058824%,12.941176%,6.27451%);fill-opacity:1;"
      d="M 319.996094 0 L 480 0 L 480 360 L 319.996094 360 Z M 319.996094 0 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,9.803922%,0%);fill-opacity:1;stroke-width:0.55625;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 289.60912 321.478501 C 289.60912 321.478501 308.236013 348.356261 309.33246 349.939547 C 310.428906 351.5186 311.525353 353.101886 309.870099 354.147532 C 308.214846 355.193178 308.752486 359.405396 309.848933 360.984448 C 312.041826 364.146787 314.789293 364.680193 316.444546 363.634547 C 318.0998 362.584668 329.68234 355.256679 331.337594 354.211033 C 332.992847 353.161153 333.551654 350.536455 331.358761 347.374116 C 330.101445 345.77813 326.414167 343.678371 324.763147 344.724017 C 323.107894 345.769663 322.011447 344.190611 320.915 342.607325 C 319.818554 341.028272 303.380321 317.312851 301.191661 314.150512 "
      transform="matrix(0.922725,0,0,0.922725,3.051675,-56.355)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(65.490196%,48.235294%,23.137255%);fill-opacity:1;stroke-width:2.5401;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 177.171585 696.256966 C 177.166641 720.720311 167.652455 740.553974 155.911914 740.549166 C 144.16628 740.550412 134.652155 720.721338 134.646222 696.25918 C 134.651166 671.795834 144.171135 651.967038 155.911677 651.971845 C 167.651526 651.965733 177.171436 671.799673 177.171585 696.256966 Z M 177.171585 696.256966 "
      transform="matrix(0.395461,-0.317897,0.332688,0.377893,-24.314503,14.938424)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,9.803922%,0%);fill-opacity:1;stroke-width:0.55625;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 209.610847 309.90866 L 192.198935 339.71169 C 191.22949 341.366943 190.260045 343.02643 188.528591 342.099319 C 186.797136 341.176441 183.126792 343.56407 182.16158 345.219323 C 180.226923 348.52983 180.993166 351.112195 182.72462 352.035073 C 184.456074 352.957951 196.588955 359.430796 198.32041 360.353674 C 200.051864 361.280785 202.752764 360.54841 204.68742 357.237903 C 205.593365 355.438714 205.855835 351.345031 204.12438 350.41792 C 202.392926 349.495042 203.358138 347.839788 204.327583 346.184535 C 205.292794 344.529281 219.804838 319.692013 221.739494 316.381506 "
      transform="matrix(0.922725,0,0,0.922725,3.051675,-56.355)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(65.490196%,48.235294%,23.137255%);fill-opacity:1;stroke-width:1.3906;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 265.868298 289.131208 L 249.993105 289.131208 C 249.468165 289.630747 244.955377 318.066393 240.070051 328.950425 C 234.117912 342.22632 216.286895 353.940096 216.286895 353.940096 C 216.286895 353.940096 226.971958 357.174402 230.15123 359.291094 C 230.93864 359.070958 247.978013 355.502215 249.963471 334.640095 C 251.948928 355.502215 238.088827 364.980763 238.088827 364.980763 C 238.088827 364.980763 257.930701 370.670433 257.930701 383.946327 C 257.930701 370.670433 277.776809 364.980763 277.776809 364.980763 C 277.776809 364.980763 267.824121 355.502215 265.838664 334.640095 C 269.809579 355.502215 284.922763 359.070958 285.714406 359.291094 C 288.893678 357.174402 299.578741 353.940096 299.578741 353.940096 C 299.578741 353.940096 281.747724 342.22632 275.795585 328.950425 C 270.910259 318.066393 266.397471 289.630747 265.872531 289.131208 Z M 265.868298 289.131208 "
      transform="matrix(0.922725,0,0,0.922725,3.051675,-56.355)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(65.490196%,48.235294%,23.137255%);fill-opacity:1;stroke-width:1.3906;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 198.37121 194.299155 L 228.136139 203.781937 C 238.059193 203.781937 247.982247 194.311855 247.982247 184.829073 C 248.312451 163.734116 240.04465 156.37226 238.059193 156.37226 L 242.030108 152.583381 L 236.077969 146.893712 C 236.077969 146.893712 238.06766 137.055325 257.905301 137.419396 C 277.742942 137.787701 277.751409 146.902178 277.751409 156.38496 C 277.751409 165.863509 267.828355 165.863509 267.828355 184.829073 C 267.828355 194.311855 277.751409 203.790403 287.674463 203.790403 L 317.439391 194.311855 L 317.439391 289.126974 L 198.37121 289.126974 Z M 198.37121 194.299155 "
      transform="matrix(0.922725,0,0,0.922725,3.051675,-56.355)"
    />
    <path
      style="fill:none;stroke-width:0.49015;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 302.167392 292.240765 L 316.819248 307.206655 L 331.439864 292.240765 L 346.09172 307.206655 L 375.395433 292.240765 L 389.641161 308.05294 L 404.667905 292.240765 L 426.286423 308.05294 L 448.592234 292.240765 L 463.24409 307.206655 L 477.895947 292.240765 "
      transform="matrix(0.125037,0,0,0.350798,190.424781,23.924009)"
    />
    <path
      style="fill:none;stroke-width:0.2032;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 432.282061 435.833269 L 425.192028 474.806861 "
      transform="matrix(0.516791,0,0,0.493824,12.131948,-108.025694)"
    />
    <path
      style="fill:none;stroke-width:0.2032;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 435.827154 435.833269 L 435.827154 474.806861 "
      transform="matrix(0.516791,0,0,0.493824,12.131909,-108.025694)"
    />
    <path
      style="fill:none;stroke-width:0.2032;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.9094 435.833391 L 442.9094 474.806983 "
      transform="matrix(0.516791,0,0,0.493824,12.132027,-108.025755)"
    />
    <path
      style="fill:none;stroke-width:0.2032;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 449.999433 435.833269 L 453.54445 474.806861 "
      transform="matrix(0.516791,0,0,0.493824,12.132027,-108.025694)"
    />
    <path
      style="fill:none;stroke-width:0.2032;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 457.089466 435.833391 L 460.634483 474.806983 "
      transform="matrix(0.516791,0,0,0.493824,12.132027,-108.025755)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(65.490196%,48.235294%,23.137255%);fill-opacity:1;stroke-width:2.5401;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.903238 361.421795 C 354.330729 379.140659 354.330729 414.570478 354.330729 432.289343 C 353.083548 448.402436 354.330729 786.619175 354.330729 786.619175 C 354.330729 786.619175 352.025334 766.590529 336.613204 751.181445 L 318.903238 733.470491 L 301.185714 715.751626 C 288.230152 703.973327 283.468189 680.313897 283.468189 662.602943 L 283.468189 414.570478 C 283.468189 414.570478 283.468189 396.851614 318.903238 361.421795 Z M 318.903238 361.421795 "
      transform="matrix(-0.516791,0,0,0.493824,332.583537,-108.025619)"
    />
    <path
      style="fill:none;stroke-width:2.5401;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 283.460631 574.019618 C 283.876357 574.019618 301.178155 556.300753 301.178155 556.300753 L 318.89568 574.019618 C 318.89568 574.019618 336.605646 556.300753 336.605646 556.300753 L 354.32317 574.019618 "
      transform="matrix(-0.516791,0,0,0.493824,332.583537,-178.015425)"
    />
    <path
      style="fill:none;stroke-width:2.5401;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 283.460631 574.023158 C 283.876357 574.023158 301.178155 556.304294 301.178155 556.304294 L 318.89568 574.023158 C 318.89568 574.023158 336.605646 556.304294 336.605646 556.304294 L 354.32317 574.023158 "
      transform="matrix(-0.516791,0,0,-0.493824,332.583537,397.649986)"
    />
    <path
      style="fill:none;stroke-width:2.5401;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 283.460631 574.023166 C 283.876357 574.023166 301.178155 556.304301 301.178155 556.304301 L 318.89568 574.023166 C 318.89568 574.023166 336.605646 556.304301 336.605646 556.304301 L 354.32317 574.023166 "
      transform="matrix(-0.516791,0,0,0.493824,332.583537,-143.021084)"
    />
    <path
      style="fill:none;stroke-width:2.5401;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.389798 556.989344 L 301.185714 715.748053 "
      transform="matrix(-0.516791,0,0,0.936833,332.583537,-425.095308)"
    />
    <path
      style="fill:none;stroke-width:2.5401;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.393238 539.269277 L 301.181596 715.749549 "
      transform="matrix(-0.516791,0,0,0.840834,323.534533,-347.994572)"
    />
    <path
      style="fill:none;stroke-width:2.5401;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.390763 539.268509 L 301.17912 715.748994 "
      transform="matrix(-0.516791,0,0,0.94192,314.377004,-411.248484)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(65.490196%,48.235294%,23.137255%);fill-opacity:1;stroke-width:2.5401;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.899596 361.421795 C 354.334646 379.140659 354.334646 414.570478 354.334646 432.289343 C 353.079906 448.402436 354.334646 786.619175 354.334646 786.619175 C 354.334646 786.619175 352.029251 766.590529 336.617121 751.181445 L 318.899596 733.470491 L 301.182072 715.751626 C 288.234069 703.973327 283.464547 680.313897 283.464547 662.602943 L 283.464547 414.570478 C 283.464547 414.570478 283.464547 396.851614 318.899596 361.421795 Z M 318.899596 361.421795 "
      transform="matrix(0.516791,0,0,0.493824,149.469127,-108.025619)"
    />
    <path
      style="fill:none;stroke-width:2.5401;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 283.456989 574.019618 C 283.880274 574.019618 301.174513 556.300753 301.174513 556.300753 L 318.892038 574.019618 C 318.892038 574.019618 336.609562 556.300753 336.609562 556.300753 L 354.327087 574.019618 "
      transform="matrix(0.516791,0,0,0.493824,149.469127,-178.015425)"
    />
    <path
      style="fill:none;stroke-width:2.5401;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 283.456989 574.023158 C 283.880274 574.023158 301.174513 556.304294 301.174513 556.304294 L 318.892038 574.023158 C 318.892038 574.023158 336.609562 556.304294 336.609562 556.304294 L 354.327087 574.023158 "
      transform="matrix(0.516791,0,0,-0.493824,149.469127,397.649986)"
    />
    <path
      style="fill:none;stroke-width:2.5401;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 283.456989 574.023166 C 283.880274 574.023166 301.174513 556.304301 301.174513 556.304301 L 318.892038 574.023166 C 318.892038 574.023166 336.609562 556.304301 336.609562 556.304301 L 354.327087 574.023166 "
      transform="matrix(0.516791,0,0,0.493824,149.469127,-143.021084)"
    />
    <path
      style="fill:none;stroke-width:2.5401;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.393715 556.989344 L 301.182072 715.748053 "
      transform="matrix(0.516791,0,0,0.936833,149.469127,-425.095308)"
    />
    <path
      style="fill:none;stroke-width:2.5401;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.389596 539.269277 L 301.185512 715.749549 "
      transform="matrix(0.516791,0,0,0.840834,158.51813,-347.994572)"
    />
    <path
      style="fill:none;stroke-width:2.5401;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 301.389562 539.268509 L 301.185478 715.748994 "
      transform="matrix(0.516791,0,0,0.94192,167.670491,-411.248484)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(65.490196%,48.235294%,23.137255%);fill-opacity:1;stroke-width:2.5401;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 177.169509 696.262075 C 177.172781 720.717869 167.649648 740.547714 155.910106 740.554436 C 144.167521 740.553856 134.65129 720.721497 134.651952 696.255494 C 134.64868 671.7997 144.171813 651.969855 155.914398 651.970435 C 167.646963 651.96662 177.170171 671.796072 177.169509 696.262075 Z M 177.169509 696.262075 "
      transform="matrix(0.47704,0.189924,-0.198764,0.455835,270.863379,-123.90862)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 186.089844 131.691406 L 295.957031 131.691406 L 295.957031 184.183594 L 186.089844 184.183594 Z M 186.089844 131.691406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(33.72549%,30.196078%,100%);fill-opacity:1;"
      d="M 186.089844 184.183594 L 295.960938 184.183594 L 295.960938 210.433594 C 295.960938 227.929688 268.492188 227.929688 241.023438 245.425781 C 213.558594 227.929688 186.089844 227.929688 186.089844 210.433594 Z M 186.089844 184.183594 "
    />
    <path
      style="fill:none;stroke-width:2.0321;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,100%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 336.610229 485.430071 L 549.212966 485.430071 L 549.212966 644.876122 C 549.212966 680.313851 496.060392 680.313851 442.907818 715.74367 C 389.762803 680.313851 336.610229 680.313851 336.610229 644.876122 Z M 336.610229 485.430071 "
      transform="matrix(0.516791,0,0,0.493824,12.132844,-108.025596)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:2.4476;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 385.584763 129.904458 C 385.584763 129.904458 334.974062 185.344641 334.974062 238.493769 C 334.974062 291.642897 388.122316 309.364888 388.122316 309.364888 C 388.122316 309.364888 370.402632 309.364888 370.402632 380.236007 C 370.402632 415.663144 423.550887 397.941152 423.550887 415.663144 C 423.550887 433.385135 422.805818 476.241357 423.550887 504.256254 C 423.550887 521.961399 388.122316 504.256254 388.122316 521.961399 C 388.122316 530.822395 414.696444 575.110527 441.270571 575.110527 C 467.844698 575.110527 494.418826 530.822395 494.418826 521.961399 C 494.418826 504.256254 458.990255 521.961399 458.990255 504.256254 L 458.990255 415.663144 C 458.990255 397.941152 512.13851 415.663144 512.13851 380.236007 C 512.13851 309.364888 494.418826 309.364888 494.418826 309.364888 C 494.418826 309.364888 547.56708 291.642897 547.56708 238.493769 C 547.56708 185.344641 496.956379 129.904458 496.956379 129.904458 C 496.956379 129.904458 525.420174 203.066633 525.420174 238.493769 C 525.420174 256.21576 512.13851 291.642897 476.699141 291.642897 C 476.699141 291.642897 467.747515 273.937752 458.990255 291.642897 C 458.990255 291.642897 448.796848 273.937752 441.270571 291.642897 C 438.668229 297.757995 434.068237 273.937752 423.550887 291.642897 C 419.091271 296.342931 415.57109 273.937752 405.842001 291.642897 C 388.122316 291.642897 352.693746 273.937752 352.682948 238.493769 C 352.682948 203.066633 385.584763 129.904458 385.584763 129.904458 Z M 385.584763 129.904458 "
      transform="matrix(0.361753,0,0,0.23188,81.392302,105.069163)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:2.4476;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 382.677517 248.036547 C 379.13574 251.574207 387.255912 309.305446 389.77187 311.8155 C 393.313647 315.35316 414.575109 315.35316 418.116886 311.8155 C 420.632843 309.2886 418.116886 255.111867 414.575109 251.574207 C 411.022533 248.036547 385.182677 245.526493 382.677517 248.036547 Z M 382.677517 248.036547 "
      transform="matrix(0.361753,0,0,0.23188,82.772133,151.192321)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:2.4476;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 382.68272 248.036547 C 379.140943 251.574207 387.261115 309.305446 389.766275 311.8155 C 393.308052 315.35316 414.569513 315.35316 418.111291 311.8155 C 420.638046 309.2886 418.111291 255.111867 414.569513 251.574207 C 411.027736 248.036547 385.18788 245.526493 382.68272 248.036547 Z M 382.68272 248.036547 "
      transform="matrix(-0.361753,0,0,0.23188,399.28053,151.192321)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:2.4476;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 414.57375 228.545636 C 414.57375 237.342863 407.433978 244.491455 398.621995 244.491455 C 389.819406 244.491455 382.679633 237.342863 382.679633 228.545636 C 382.679633 219.734895 389.819406 212.599817 398.621995 212.599817 C 407.433978 212.599817 414.57375 219.734895 414.57375 228.545636 Z M 414.57375 228.545636 "
      transform="matrix(0.415805,0,0,0.289065,62.016789,137.794877)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:2.4476;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 414.573342 228.541732 C 414.573342 237.352322 407.433657 244.487279 398.621784 244.487279 C 389.819305 244.487279 382.679621 237.352322 382.679621 228.541732 C 382.679621 219.731141 389.819305 212.596185 398.621784 212.596185 C 407.433657 212.596185 414.573342 219.731141 414.573342 228.541732 Z M 414.573342 228.541732 "
      transform="matrix(0.41581,0,0,0.28907,88.534348,137.794877)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 252.566406 230.625 C 252.566406 231.757812 251.128906 232.675781 249.359375 232.675781 C 247.589844 232.675781 246.15625 231.757812 246.15625 230.625 C 246.15625 229.488281 247.589844 228.570312 249.359375 228.570312 C 251.128906 228.570312 252.566406 229.488281 252.566406 230.625 Z M 252.566406 230.625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 235.898438 230.625 C 235.898438 231.757812 234.464844 232.675781 232.695312 232.675781 C 230.925781 232.675781 229.488281 231.757812 229.488281 230.625 C 229.488281 229.488281 230.925781 228.570312 232.695312 228.570312 C 234.464844 228.570312 235.898438 229.488281 235.898438 230.625 Z M 235.898438 230.625 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:1.016025;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 439.373272 549.918683 L 431.586899 539.662113 L 418.818795 541.413433 L 420.577508 528.651503 L 410.319638 520.867861 L 420.577508 513.07611 L 418.818795 500.322288 L 431.586899 502.081716 L 439.373272 491.80893 L 447.151899 502.081716 L 459.920002 500.322288 L 458.161289 513.07611 L 468.419159 520.867861 L 458.161289 528.651503 L 459.920002 541.413433 L 447.151899 539.662113 Z M 439.373272 549.918683 "
      transform="matrix(0.504186,0,0,0.48178,19.501461,-100.002103)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:1.016025;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 496.061821 591.727942 L 517.316803 602.359261 L 538.579345 591.727942 L 517.316803 581.096623 Z M 496.061821 591.727942 "
      transform="matrix(0.516791,0,0,0.493824,12.132106,-108.025845)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:1.016025;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 496.061844 591.727591 L 517.316827 602.35891 L 538.579368 591.727591 L 517.316827 581.096272 Z M 496.061844 591.727591 "
      transform="matrix(0.516791,0,0,0.493824,-66.606187,-108.025672)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:1.016025;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 535.039304 659.063916 C 531.492937 651.973748 520.865981 648.42488 513.782693 648.434467 C 506.690234 648.429667 499.60433 648.428631 492.52388 659.057699 C 492.519664 644.889022 503.021825 637.803102 513.781178 637.797503 C 524.536591 637.798764 535.040503 648.300805 535.039304 659.063916 Z M 535.039304 659.063916 "
      transform="matrix(0.448145,-0.245929,0.257367,0.428229,-123.861417,64.866954)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:1.016025;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 386.217551 651.970034 C 386.217551 655.885587 383.050468 659.05758 379.135077 659.05758 C 375.219685 659.05758 372.045043 655.885587 372.045043 651.970034 C 372.045043 648.054481 375.219685 644.882488 379.135077 644.882488 C 383.050468 644.882488 386.217551 648.054481 386.217551 651.970034 Z M 386.217551 651.970034 "
      transform="matrix(0.516791,0,0,0.493824,10.300935,-116.774834)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:1.016025;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 386.221877 651.973044 C 386.221877 655.880687 383.047235 659.05268 379.131843 659.05268 C 375.216452 659.05268 372.049369 655.880687 372.049369 651.973044 C 372.049369 648.057492 375.216452 644.885498 379.131843 644.885498 C 383.047235 644.885498 386.221877 648.057492 386.221877 651.973044 Z M 386.221877 651.973044 "
      transform="matrix(0.516791,0,0,0.493824,6.638543,-104.52632)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:1.016025;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 386.21986 651.971253 C 386.21986 655.886805 383.045218 659.058798 379.129827 659.058798 C 375.221994 659.058798 372.047352 655.886805 372.047352 651.971253 C 372.047352 648.0557 375.221994 644.883707 379.129827 644.883707 C 383.045218 644.883707 386.21986 648.0557 386.21986 651.971253 Z M 386.21986 651.971253 "
      transform="matrix(0.516791,0,0,0.493824,4.807554,-111.525436)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:1.016025;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 386.220885 651.973044 C 386.220885 655.880687 383.046243 659.05268 379.130851 659.05268 C 375.223018 659.05268 372.048377 655.880687 372.048377 651.973044 C 372.048377 648.057492 375.223018 644.885498 379.130851 644.885498 C 383.046243 644.885498 386.220885 648.057492 386.220885 651.973044 Z M 386.220885 651.973044 "
      transform="matrix(0.516791,0,0,0.493824,13.963275,-104.52632)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:1.016025;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 386.222601 651.971253 C 386.222601 655.886805 383.047959 659.058798 379.132568 659.058798 C 375.217176 659.058798 372.050093 655.886805 372.050093 651.971253 C 372.050093 648.0557 375.217176 644.883707 379.132568 644.883707 C 383.047959 644.883707 386.222601 648.0557 386.222601 651.971253 Z M 386.222601 651.971253 "
      transform="matrix(0.516791,0,0,0.493824,15.794419,-111.525436)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:1.016025;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 386.217451 651.971626 C 386.217451 655.887178 383.050368 659.059171 379.134977 659.059171 C 375.219585 659.059171 372.044943 655.887178 372.044943 651.971626 C 372.044943 648.056073 375.219585 644.88408 379.134977 644.88408 C 383.050368 644.88408 386.217451 648.056073 386.217451 651.971626 Z M 386.217451 651.971626 "
      transform="matrix(0.516791,0,0,0.493824,10.300986,-109.77562)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.490196%,27.058824%,0%);fill-opacity:1;"
      d="M 223.332031 90.285156 C 214.089844 90.285156 212.867188 91.742188 208.679688 93.203125 C 208.679688 89.175781 218.972656 84.453125 228.214844 84.453125 C 228.214844 87.371094 225.773438 90.285156 223.332031 90.285156 Z M 223.332031 90.285156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(79.215686%,78.823529%,78.039216%);fill-opacity:1;"
      d="M 237.363281 80.949219 C 237.363281 82.882812 235.722656 84.449219 233.699219 84.449219 C 231.679688 84.449219 230.039062 82.882812 230.039062 80.949219 C 230.039062 79.015625 231.679688 77.449219 233.699219 77.449219 C 235.722656 77.449219 237.363281 79.015625 237.363281 80.949219 Z M 237.363281 80.949219 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:1.016025;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 361.419369 357.870386 L 361.419369 368.501705 L 379.136893 368.501705 L 379.136893 421.650389 L 389.764385 421.650389 L 389.764385 368.501705 L 407.481909 368.501705 L 407.481909 357.870386 L 389.764385 357.870386 L 389.764385 343.695295 L 379.136893 343.695295 L 379.136893 357.870386 Z M 361.419369 357.870386 "
      transform="matrix(0.516791,0,0,0.493824,12.132027,-108.025755)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.490196%,27.058824%,0%);fill-opacity:1;"
      d="M 228.195312 84.453125 C 217.414062 84.453125 211.105469 85.425781 206.222656 87.371094 C 206.222656 82.003906 212.53125 75.707031 223.3125 75.707031 C 225.753906 75.707031 228.195312 81.539062 228.195312 84.453125 Z M 228.195312 84.453125 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,52.156863%,0%);fill-opacity:1;stroke-width:0.71122;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 67.320837 613.000984 C 53.149938 598.817184 50.69134 591.627999 70.862328 602.896545 L 354.617505 850.382884 L 351.256806 857.35918 L 67.315355 612.985862 Z M 67.320837 613.000984 "
      transform="matrix(0.29116,0.222399,-0.105549,0.177688,178.08899,53.464559)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,56.078431%,0%);fill-opacity:1;"
      d="M 171.300781 247.804688 C 171.875 239.867188 157.339844 231.277344 156.765625 231.835938 C 156.191406 232.394531 157.648438 237.015625 160.40625 239.425781 C 155.128906 236.703125 149.597656 235.03125 149.484375 236.210938 C 149.050781 237.800781 157.6875 241.601562 158.402344 243.070312 C 158.519531 244.121094 152.0625 243.984375 152.179688 245.03125 C 152.296875 246.082031 170.367188 255.210938 171.296875 247.804688 Z M 171.300781 247.804688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,56.078431%,0%);fill-opacity:1;"
      d="M 156.777344 224.679688 C 157.351562 216.738281 142.816406 208.152344 142.242188 208.710938 C 141.667969 209.265625 143.125 213.890625 145.882812 216.296875 C 140.605469 213.578125 135.078125 211.902344 134.960938 213.085938 C 134.53125 214.675781 143.164062 218.476562 143.878906 219.945312 C 143.996094 220.996094 137.539062 220.859375 137.65625 221.90625 C 137.773438 222.957031 155.84375 232.085938 156.777344 224.675781 Z M 156.777344 224.679688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,56.078431%,0%);fill-opacity:1;"
      d="M 145.664062 203.679688 C 146.238281 195.742188 131.703125 187.152344 131.128906 187.710938 C 130.554688 188.269531 132.011719 192.890625 134.769531 195.300781 C 129.492188 192.582031 123.964844 190.90625 123.847656 192.085938 C 123.417969 193.679688 132.050781 197.480469 132.765625 198.949219 C 132.882812 199.996094 126.425781 199.859375 126.542969 200.910156 C 126.660156 201.957031 144.730469 211.089844 145.664062 203.679688 Z M 145.664062 203.679688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,56.078431%,0%);fill-opacity:1;"
      d="M 134.675781 180.929688 C 135.253906 172.992188 120.71875 164.402344 120.144531 164.960938 C 119.566406 165.519531 121.027344 170.140625 123.78125 172.550781 C 118.503906 169.832031 112.976562 168.15625 112.859375 169.335938 C 112.429688 170.929688 121.0625 174.730469 121.78125 176.199219 C 121.898438 177.246094 115.441406 177.109375 115.558594 178.160156 C 115.675781 179.207031 133.746094 188.339844 134.675781 180.929688 Z M 134.675781 180.929688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,56.078431%,0%);fill-opacity:1;"
      d="M 138.453125 179.898438 C 145.484375 175.636719 144.109375 159.359375 143.296875 159.25 C 142.484375 159.136719 139.453125 163 139.058594 166.550781 C 138.222656 160.875 136.355469 155.628906 135.292969 156.238281 C 133.695312 156.851562 135.609375 165.75 134.796875 167.171875 C 133.980469 167.882812 130.269531 162.832031 129.453125 163.542969 C 128.640625 164.257812 131.65625 183.574219 138.453125 179.898438 Z M 138.453125 179.898438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,56.078431%,0%);fill-opacity:1;"
      d="M 148.640625 199.871094 C 156.757812 198.097656 161.144531 182.316406 160.421875 181.953125 C 159.695312 181.589844 155.488281 184.261719 153.875 187.480469 C 155.070312 181.867188 155.144531 176.324219 153.925781 176.5625 C 152.207031 176.628906 150.902344 185.625 149.640625 186.707031 C 148.625 187.117188 146.890625 181.171875 145.875 181.585938 C 144.855469 181.996094 140.949219 201.167969 148.640625 199.871094 Z M 148.640625 199.871094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,56.078431%,0%);fill-opacity:1;"
      d="M 160.808594 220.304688 C 168.210938 216.660156 168.367188 200.332031 167.570312 200.152344 C 166.773438 199.972656 163.390625 203.558594 162.664062 207.058594 C 162.363281 201.335938 160.996094 195.949219 159.878906 196.46875 C 158.230469 196.941406 159.304688 205.964844 158.359375 207.3125 C 157.484375 207.953125 154.257812 202.605469 153.378906 203.242188 C 152.503906 203.882812 153.695312 223.382812 160.808594 220.304688 Z M 160.808594 220.304688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,56.078431%,0%);fill-opacity:1;"
      d="M 173.980469 246.980469 C 181.730469 244.070312 183.613281 227.839844 182.839844 227.585938 C 182.066406 227.328125 178.320312 230.570312 177.226562 233.984375 C 177.53125 228.261719 176.746094 222.769531 175.578125 223.175781 C 173.890625 223.488281 174 232.570312 172.921875 233.820312 C 171.976562 234.371094 169.335938 228.738281 168.394531 229.289062 C 167.453125 229.839844 166.578125 249.355469 173.980469 246.980469 Z M 173.980469 246.980469 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,9.803922%,0%);fill-opacity:1;stroke-width:1.016025;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 209.060266 775.980547 C 209.059035 783.808756 198.750164 790.153242 186.032078 790.151204 C 173.307392 790.152852 162.994764 783.806891 162.995994 775.978682 C 162.997225 768.150473 173.306096 761.805987 186.030782 761.80434 C 198.748868 761.806377 209.061496 768.152338 209.060266 775.980547 Z M 209.060266 775.980547 "
      transform="matrix(0.451231,0.24073,-0.251922,0.431171,292.187554,-110.106499)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:0.50801;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 155.909564 414.57216 L 155.915569 407.486336 L 162.992187 407.48137 L 162.998192 400.395546 L 170.08772 400.397328 L 170.081715 407.483152 L 177.171243 407.484933 L 177.165238 414.570757 L 170.085015 414.565874 L 170.085763 432.280705 L 162.99984 432.288772 L 162.999092 414.573941 Z M 155.909564 414.57216 "
      transform="matrix(0.374156,0.117823,-0.136951,0.353468,338.582171,-2.794515)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:0.51245;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 162.991726 474.794378 L 170.071949 474.799262 L 170.071924 680.307464 L 162.995306 680.31243 Z M 162.991726 474.794378 "
      transform="matrix(0.374156,0.117823,-0.136951,0.353468,338.582167,-2.794505)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:0.50801;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 159.455347 676.773552 L 173.621493 676.770368 L 173.627189 733.461147 L 159.451738 733.467433 Z M 159.455347 676.773552 "
      transform="matrix(0.374156,0.117823,-0.136951,0.353468,338.582167,-2.794505)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:0.50801;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 155.913612 439.369682 L 177.169286 439.368279 C 191.348342 439.371843 173.624818 478.344887 173.624818 478.344887 L 159.458672 478.348072 C 159.458672 478.348072 141.734556 439.366119 155.913612 439.369682 Z M 155.913612 439.369682 "
      transform="matrix(0.374156,0.117823,-0.136951,0.353468,338.582167,-2.794505)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:0.50801;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 173.620098 434.064285 C 173.617064 436.993865 170.448691 439.376134 166.533947 439.377007 C 162.619202 439.37788 159.450918 436.99705 159.450347 434.05762 C 159.440471 431.121292 162.621754 428.745771 166.536499 428.744898 C 170.451243 428.744025 173.619527 431.124855 173.620098 434.064285 Z M 173.620098 434.064285 "
      transform="matrix(0.374156,0.117823,-0.136951,0.353468,338.582167,-2.794505)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,9.803922%,0%);fill-opacity:1;stroke-width:1.016025;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 209.057148 775.978051 C 209.058295 783.806288 198.743894 790.153725 186.028137 790.147851 C 173.308013 790.148573 163.00234 783.807043 163.001193 775.978806 C 163.000047 768.150568 173.31008 761.809727 186.030204 761.809005 C 198.750328 761.808284 209.062304 768.153987 209.057148 775.978051 Z M 209.057148 775.978051 "
      transform="matrix(0.430866,-0.272674,0.285362,0.411711,-1.6939,3.158917)"
    />
  </g>
</svg>
