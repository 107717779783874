<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(95.294118%,9.803922%,18.823529%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 305.234375 185.605469 C 305.234375 235.265625 264.277344 275.523438 213.757812 275.523438 C 163.234375 275.523438 122.28125 235.265625 122.28125 185.605469 C 122.28125 135.945312 163.234375 95.6875 213.757812 95.6875 C 264.277344 95.6875 305.234375 135.945312 305.234375 185.605469 Z M 305.234375 185.605469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,9.411765%,18.823529%);fill-opacity:1;"
      d="M 309.808594 185.601562 C 309.808594 225.328125 277.042969 257.53125 236.625 257.53125 C 196.210938 257.53125 163.445312 225.328125 163.445312 185.601562 C 163.445312 145.875 196.210938 113.667969 236.625 113.667969 C 277.042969 113.667969 309.808594 145.875 309.808594 185.601562 Z M 309.808594 185.601562 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 323.042969 143.597656 L 322.304688 176.835938 L 291.296875 185.257812 L 321.863281 196.140625 L 321.125 226.621094 L 341.058594 202.820312 L 371.183594 213.269531 L 353.757812 187.722656 L 375.023438 162.324219 L 342.386719 171.320312 Z M 323.042969 143.597656 "
    />
  </g>
</svg>
