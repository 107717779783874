<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 0 L 480 0 L 480 359.996094 L 0 359.996094 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,34.117647%,0%);fill-opacity:1;"
      d="M 0 0 L 160 0 L 160 359.996094 L 0 359.996094 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(98.823529%,0%,0%);fill-opacity:1;"
      d="M 319.996094 0 L 479.996094 0 L 479.996094 359.996094 L 319.996094 359.996094 Z M 319.996094 0 "
    />
  </g>
</svg>
