<script>
  import styles from "styleguide/SidebarStatus.json";

  export let state;
</script>

<div data-component="SidebarStatus">
  {state}
</div>

<style lang="scss">
</style>
