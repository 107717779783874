<script>
  import styles from "styleguide/ConfirmationSignerNameModal.json";

  import { t } from "stores/i18n.js";
  import { signer } from "stores/confirmation/confirmation_signer.js";
  import { isDesktopWidth, isVertical } from "stores/device.js";

  import { Button, TextInput } from "components";
  import { Modal } from "components/modals";

  export let shown;

  const onHide = function () {
    shown = false;
  };
</script>

<Modal
  style={$isDesktopWidth || !$isVertical ? "small" : "mobile"}
  {shown}
  {onHide}
  title={$t("confirmation_signer_name_modal.title")}
>
  <div data-component="ConfirmationSignerNameModal">
    <div class={styles.input}>
      <TextInput
        bind:value={$signer.name}
        style="border medium round-border fullsize"
        label={$t("confirmation_signer_name_modal.name")}
      />
    </div>

    <div class={styles.input}>
      <TextInput
        optional={true}
        bind:value={$signer.job_title}
        style="border medium round-border fullsize"
        label={$t("confirmation_signer_name_modal.job_title")}
      />
    </div>

    <div class={styles.submit}>
      <Button style="primary" click={onHide}>
        {$t("actions.submit")}
      </Button>
    </div>
  </div>
</Modal>

<style lang="scss">
  .input {
    margin-bottom: 20px;
  }

  .submit {
    display: flex;
    justify-content: flex-end;
  }
</style>
