<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M -99.34375 97.789062 L 599.242188 97.789062 L 599.242188 249.863281 L -99.34375 249.863281 Z M -99.34375 97.789062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(3.137255%,48.235294%,80.784314%);fill-opacity:1;"
      d="M 597.96875 360 L -97.894531 360 L -97.894531 238.449219 L 597.96875 238.449219 Z M 597.96875 360 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(3.137255%,48.235294%,80.784314%);fill-opacity:1;"
      d="M 599.667969 118.195312 L -99.34375 118.195312 L -99.34375 0 L 599.667969 0 Z M 599.667969 118.195312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(3.137255%,48.235294%,80.784314%);fill-opacity:1;"
      d="M 242.324219 183.316406 L 228.523438 173.816406 L 245.710938 173.746094 L 251.183594 158.222656 L 256.304688 173.695312 L 273.492188 173.601562 L 259.46875 183.234375 L 264.613281 198.703125 L 250.828125 189.179688 L 236.820312 198.835938 Z M 242.324219 183.316406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(3.137255%,48.235294%,80.784314%);fill-opacity:1;"
      d="M 342.832031 209.191406 L 329.03125 199.691406 L 346.21875 199.621094 L 351.691406 184.097656 L 356.8125 199.570312 L 374 199.476562 L 359.976562 209.109375 L 365.121094 224.578125 L 351.335938 215.054688 L 337.328125 224.710938 Z M 342.832031 209.191406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(3.137255%,48.235294%,80.784314%);fill-opacity:1;"
      d="M 342.832031 155.453125 L 329.03125 145.949219 L 346.21875 145.882812 L 351.691406 130.355469 L 356.8125 145.832031 L 374 145.738281 L 359.976562 155.371094 L 365.121094 170.835938 L 351.335938 161.316406 L 337.328125 170.96875 Z M 342.832031 155.453125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(3.137255%,48.235294%,80.784314%);fill-opacity:1;"
      d="M 120.917969 209.191406 L 107.117188 199.691406 L 124.300781 199.621094 L 129.777344 184.097656 L 134.898438 199.570312 L 152.082031 199.476562 L 138.058594 209.109375 L 143.207031 224.578125 L 129.417969 215.054688 L 115.414062 224.710938 Z M 120.917969 209.191406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(3.137255%,48.235294%,80.784314%);fill-opacity:1;"
      d="M 120.917969 155.453125 L 107.117188 145.949219 L 124.300781 145.882812 L 129.777344 130.355469 L 134.898438 145.832031 L 152.082031 145.738281 L 138.058594 155.371094 L 143.207031 170.835938 L 129.417969 161.316406 L 115.414062 170.96875 Z M 120.917969 155.453125 "
    />
  </g>
</svg>
