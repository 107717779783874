<script>
  import {
    SidebarActivity,
    SidebarComments,
    SidebarStatus,
  } from "components/common/SidebarOverview/components";
  import { t } from "stores/i18n.js";

  import {
    Boundary,
    Box,
    IconButton,
    LegacyButton as Button,
    Smalltabs,
  } from "components";
  import styles from "styleguide/SidebarOverview.json";

  export let boxTitle = "";
  export let showSmalltabs = false;

  export let statusesTab = true;
  export let commentsTab = true;
  export let activityTab = true;

  export let store;
  export let services;
  export let isCommentsVisible;

  let tabs = {};

  const { comments } = store;

  $: commentsLength = $comments.filter((item) => !item.is_deleted).length;

  if (statusesTab) {
    tabs["status"] = {
      id: "status",
      component: SidebarStatus,
      state: "SidebarStatus",
    };
  }

  if (commentsTab) {
    tabs["comments"] = {
      id: "comments",
      component: SidebarComments,
      store: store,
      services: services,
    };
  }

  if (activityTab) {
    tabs["activity"] = {
      id: "activity",
      component: SidebarActivity,
      store: store,
      services: services,
    };
  }

  let activeTab = Object.values(tabs)[0];

  function changeTab(target) {
    activeTab = tabs[target];
  }

  function navigation(action) {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const filterParams = Object.fromEntries(params.entries());

    services.NavigationService.navigation({
      params: {
        filterParams,
      },
      onSuccess: (response) => {
        window.location = response[action];
      },
    });
  }
</script>

<Boundary>
  <div data-component="SidebarOverview" class={styles.wrapper}>
    <div class={styles.navigation}>
      <IconButton
        click={() => navigation("previous")}
        icon="stroke_regular_left"
        style="primary-text"
      />

      <IconButton
        click={() => navigation("next")}
        icon="stroke_regular_right"
        style="primary-text"
      />
    </div>

    <Box title={boxTitle}>
      {#if showSmalltabs}
        <Smalltabs>
          {#each Object.values(tabs) as tab}
            <div class={styles.smalltab}>
              <Button
                style={"smalltab fullsize"}
                active={activeTab.id === tab.id}
                click={() => changeTab(tab.id)}
              >
                {$t("sidebar_overview." + tab.id)}
                {#if tab.id === "comments"}
                  <span
                    class={commentsLength > 0
                      ? styles.count
                      : styles["empty-count"]}
                  >
                    ({commentsLength})
                  </span>
                {/if}
              </Button>
            </div>
          {/each}
        </Smalltabs>
      {/if}

      <Box style={"no-border"}>
        <svelte:component
          this={activeTab.component}
          state={activeTab.state}
          {store}
          {services}
          {isCommentsVisible}
        />
      </Box>
    </Box>
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    width: 335px;

    .navigation {
      display: flex;
      height: 108px;
      align-items: center;
      justify-content: end;
    }
  }

  .count {
    color: var(--red-200);
  }

  .empty-count {
    color: var(--primary-300);
  }
</style>
