<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(90.588235%,0%,7.45098%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 331.269531 179.839844 C 331.269531 229.984375 290.621094 270.632812 240.476562 270.632812 C 190.332031 270.632812 149.683594 229.984375 149.683594 179.839844 C 149.683594 129.695312 190.332031 89.046875 240.476562 89.046875 C 290.621094 89.046875 331.269531 129.695312 331.269531 179.839844 Z M 331.269531 179.839844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.588235%,0%,7.45098%);fill-opacity:1;"
      d="M 240.476562 240.054688 C 207.242188 240.054688 180.265625 213.078125 180.265625 179.839844 C 180.265625 146.605469 207.242188 119.628906 240.476562 119.628906 C 248.789062 119.628906 258.246094 121.539062 264.671875 126.257812 C 220.632812 127.917969 209.496094 165.265625 209.496094 180.320312 C 209.496094 195.371094 216.617188 228.941406 264.667969 233.902344 C 259.203125 237.425781 248.789062 240.054688 240.476562 240.054688 Z M 240.476562 240.054688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.588235%,0%,7.45098%);fill-opacity:1;"
      d="M 293.515625 205.160156 L 266.171875 195.191406 L 248.085938 217.984375 L 249.125 188.90625 L 221.851562 178.746094 L 249.835938 170.75 L 251.0625 141.671875 L 267.316406 165.8125 L 295.351562 158 L 277.417969 180.917969 Z M 293.515625 205.160156 "
    />
  </g>
</svg>
