var selectedSamples = [];
var handlerReference = null;

function removeScrollHandler() {
  $(window).off("scroll");
}

function initializeScrollHandler() {
  var scrollTimeout;
  handlerReference = $(window).on("scroll", function () {
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
      scrollTimeout = null;
    }
    scrollTimeout = setTimeout(scrollHandler, 20);
  });

  scrollHandler = function () {
    var container = $(".paper.current");
    var offset = container.height() + container.offset().top;
    if ($(window).scrollTop() + $(window).height() < offset) {
      $(".bar").addClass("sticky");
    } else {
      $(".bar").removeClass("sticky");
    }
  };

  scrollHandler();
}

function percent(a, b) {
  if (b === 0) {
    return 0;
  } else {
    return Math.round((a / b) * 100);
  }
}

function updateSampleBar(samples) {
  if (samples.length === 0) {
    $("#submit-samples button").prop("disabled", true);
  } else {
    $("#submit-samples button").prop("disabled", false);
  }

  var totalDebitorValue = parseInt(
    $("#samples-list-bar").data("total-debitor-value"),
  );
  var selectedDebitorValue = parseInt(
    $("#samples-list-bar").data("selected-debitor-value"),
  );

  var totalCreditorValue = parseInt(
    $("#samples-list-bar").data("total-creditor-value"),
  );
  var selectedCreditorValue = parseInt(
    $("#samples-list-bar").data("selected-creditor-value"),
  );

  samples.forEach(function (permalink) {
    var sample = $("#sample-" + permalink)[0];
    if ($(sample).data("type") == "debitor") {
      selectedDebitorValue += parseInt($(sample).data("value"));
    } else {
      selectedCreditorValue += parseInt($(sample).data("value"));
    }
  });

  var debitorPercent = percent(selectedDebitorValue, totalDebitorValue);
  var creditorPercent = percent(selectedCreditorValue, totalCreditorValue);

  $("#debitor-value").html(debitorPercent + "%");
  $("#creditor-value").html(creditorPercent + "%");
}

function selectSample(checkboxLink, selectedSamples) {
  $(checkboxLink).toggleClass("checked");
  var id = $(checkboxLink).data("sample-permalink");
  if (selectedSamples.indexOf(id) >= 0) {
    selectedSamples.splice(selectedSamples.indexOf(id), 1);
  } else {
    selectedSamples.push(id);
  }

  $("#selected-samples").val(selectedSamples.join(","));
}

$(document).on("turbolinks:load", function () {
  if ($("#samples-list").length) {
    // initializeScrollHandler();
    updateSampleBar(selectedSamples);
  }
});

// prevent accidental leave if selected samples
$(document).on("turbolinks:before-visit", function (event) {
  if (selectedSamples.length > 0) {
    var doContinue = confirm(
      I18n.t("platform.views.samples.sample_details_bar.still_selected_items"),
    );
    if (doContinue) {
      selectedSamples = [];
    } else {
      event.preventDefault();
    }
  }
});

// cleanup
$(document).on("page:before-unload", function () {
  removeScrollHandler();
});

$(document).on("click", "#select-all", function (event) {
  event.preventDefault();
  $("td.checkbox a").each(function () {
    selectSample(this, selectedSamples);
  });

  updateSampleBar(selectedSamples);
});

$(document).on("click", "td.action a[data-remote]", function (event) {
  $(this).addClass("loading");
  $(this).parents("tr").css("pointer-events", "none");

  $(".tooltip").each(function (index) {
    $(this).off("mouseout");
    $(this).off("scroll");
    $(this).remove();
  });
});

$(document).on("click", "td.checkbox a", function (event) {
  event.preventDefault();
  selectSample(this, selectedSamples);
  updateSampleBar(selectedSamples);
});

$(document).on("click", "#sample-status-bar-status--toggle", function (event) {
  var bar = $("#sample-status-bar");
  var btn = $("#sample-status-bar-status--toggle");

  $(bar).toggleClass("expanded");
  $(btn).text(
    $(bar).hasClass("expanded")
      ? I18n.t("platform.views.samples.sample_details_bar.collapse")
      : I18n.t("platform.views.samples.sample_details_bar.change_status"),
  );

  return event.preventDefault();
});

$(document).on("change", "#sample-status-bar-status-select", function (event) {
  var sampleStatus = parseInt($(this).val());
  var inputField = $("#sample-status-bar-confirmation-amount-input");

  if (sampleStatus == 4) {
    var valueData = $("#sample-status-bar").data("balance");
    if ($(inputField).val().length == 0 && valueData) {
      $(inputField).val(valueData);
    }
  } else if (sampleStatus == 8) {
    $(inputField).attr("disabled", false);
    $(inputField).attr("readonly", false);
    $(inputField).attr(
      "placeholder",
      I18n.t("platform.views.samples.sample_details_bar.amount_in_euro"),
    );

    var valueData = $("#sample-status-bar").data("balance");
    if ($(inputField).val().length == 0 && valueData) {
      $(inputField).val(valueData);
    }
  } else if (sampleStatus < 3) {
    $(inputField).attr("disabled", false);
    $(inputField).attr("readonly", true);
    $(inputField).attr("placeholder", "-");
    $(inputField).attr("value", "");
  }
});
