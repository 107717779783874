<script>
  import styles from "styleguide/DashboardProjectHeader.json";

  import { t } from "stores/i18n.js";
  import { can } from "stores/permission.js";
  import { project, shownArchiveModal } from "stores/dashboard/project.js";

  import { DashboardProjectArchive, DashboardStatus } from "apps/dashboard";

  import { Boundary, LegacyButton as Button } from "components";
  import { Modal } from "components/modals";

  export let navigationTo;
  $: titleArchiveModal =
    $project.status === "active"
      ? $t("dashboard_project_header.deactivate")
      : $t("dashboard_project_header.activate");
  $: styleArchiveModal =
    $project.status === "active"
      ? "border margin-right red"
      : "border margin-right";
</script>

<Boundary>
  <div data-component="DashboardProjectHeader" class={styles.wrapper}>
    <div class={styles.info}>
      <div class={styles.type}>{$t("dashboard_project_header.project")}</div>
      <div class={styles.title}>
        <div class={styles.name}>{$project.name}</div>
        <div class={styles.status}>
          <DashboardStatus status={$project.status} type="group" />
        </div>
      </div>
    </div>

    <div class={styles.buttons}>
      {#if navigationTo === "show" && $can("archive", "project")}
        <Button
          click={() => {
            $shownArchiveModal = true;
          }}
          style={styleArchiveModal}
        >
          {titleArchiveModal}
        </Button>
      {/if}
      {#if navigationTo === "show" && $can("update", "project")}
        <Button link={$project.edit_project_path} style={"border"}>
          {$t("dashboard_project_header.go_to_settings")}
        </Button>
      {:else if navigationTo === "edit" && $can("read", "project")}
        <Button link={$project.project_path} style={"border"}>
          {$t("dashboard_project_header.go_to_overview")}
        </Button>
      {/if}
    </div>
  </div>
</Boundary>

<Modal
  shown={$shownArchiveModal}
  title={titleArchiveModal}
  onHide={() => {
    $shownArchiveModal = false;
  }}
>
  <DashboardProjectArchive />
</Modal>

<style lang="scss">
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--page-gutter);
    margin-bottom: 25px;
  }

  .name {
    font-size: 22px;
    color: var(--primary-500);
  }

  .type {
    font-weight: 500;
    color: var(--primary-300);
    margin-bottom: 5px;
  }

  .title {
    display: flex;
  }

  .status {
    margin-left: 15px;
  }

  .buttons {
    display: flex;
    align-items: center;
  }
</style>
