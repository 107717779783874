<script>
  import { t } from "stores/i18n.js";
  import { validate } from "lib/validate.js";

  import {
    isConfirmationType,
    isReviewType,
  } from "stores/dashboard/create_confirmation.js";

  import {
    ConfirmationNewBlock,
    ConfirmationNewTypeItem,
    ReviewNewTypeItem,
  } from "components/confirmationNew";

  export let active;
  export let dirty;
  export let valide;
  export let types;

  $: {
    valide = checkEmails($types);
  }

  function checkEmails(types) {
    const contacts = [].concat.apply([], Object.values(types));
    const emails = contacts.map((contact) => contact.email);
    const emailsWithErrors = emails.filter(
      (email) => validate(email, ["email"]).length,
    );
    return emailsWithErrors.length === 0;
  }
</script>

<ConfirmationNewBlock
  {active}
  edit={() => {
    active = true;
  }}
  title={$t("confirmation_new_type.title")}
>
  {#if $isConfirmationType}
    <div data-component="ConfirmationNewType">
      {#each Object.entries($types) as [type, items] (type)}
        <ConfirmationNewTypeItem {type} {items} {active} bind:dirty />
      {:else}
        {#if !active}
          {$t("confirmation_new_type.empty")}
        {/if}
      {/each}
    </div>
  {/if}

  {#if $isReviewType}
    <div data-component="ReviewNewType">
      <ReviewNewTypeItem {types} {active} bind:dirty />
    </div>
  {/if}
</ConfirmationNewBlock>
