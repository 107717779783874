import { Controller } from "stimulus";
import Api from "../general/api";

export default class extends Controller {
  static targets = [];

  connect() {
    this.checkExportStatus();
  }

  checkExportStatus() {
    Api.get(
      this.element.getAttribute("data-url"),
      {},
      this.checkStatusResponse.bind(this),
    );
  }

  checkStatusResponse(response) {
    let self = this;

    if (response["status"] === "done" && response["template"]) {
      let node = document.createElement("div");
      node.innerHTML = response["template"];
      this.element.replaceWith(node);
      let noExportBlock = document.querySelector(
        ".audit-export-modal--no-exports",
      );
      if (noExportBlock) {
        noExportBlock.remove();
      }
    } else if (response["status"] === "processing") {
      setTimeout(() => {
        self.checkExportStatus();
      }, 3000);
    }
  }
}
