<script>
  import styles from "styleguide/QuickshareFolder.json";
  import { showUpload } from "stores/quickshare.js";
  import { iconPath, dateTimeFormat } from "lib/helpers.js";

  export let upload;

  function getIcon() {
    return iconPath("icon-folder.svg");
  }

  function onClickFolder() {
    showUpload.set(upload.id);
  }
</script>

<div
  data-component="QuickshareFolder"
  class={styles.folder}
  on:click={onClickFolder}
>
  <div class={styles.icon}>
    <img src={getIcon()} alt="" />
  </div>
  <div class={styles.content}>
    <div class={styles.name}>{upload.name}</div>
    <div class={styles.meta}>{dateTimeFormat(upload["created_at"])}</div>
  </div>
</div>

<style lang="scss">
  .folder {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 20px;
    cursor: pointer;

    &:hover {
      background: var(--primary-010);
    }
  }

  .icon {
    flex: 0 0 40px;
    margin-right: 5px;

    img {
      width: 28px;
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .name {
    color: var(--blue-200);
    font-weight: 500;
    line-height: 22px;
  }

  .meta {
    color: var(--primary-100);
    font-size: 12px;
    line-height: 18px;
  }
</style>
