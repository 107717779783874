import { breadcrumb } from "stores/breadcrumb.js";
import { updatePageHistory } from "stores/page_history.js";

export function initializeNavigation({ state, path, type = "" }) {
  const { name, [type + "_name"]: typeName } = state;
  const company = state.company ?? (state.contact?.company || state.reference);

  const breadcrumbItems = [
    { translation: "projects", path: state.dashboard_path },
    {
      name: state.project_name,
      path: state.project_path,
    },
    {
      name: state.confirmation_name,
      path: state.apps_confirmation_path,
    },
    {
      name: state.review_name,
      path: state.review_path,
    },
    { name: state.name },
    { name: company },
  ];

  const filteredBreadcrumbs = breadcrumbItems.filter((item) =>
    Object.values(item).every((value) => value !== undefined),
  );

  breadcrumb.set(filteredBreadcrumbs);

  updatePageHistory([
    {
      path,
      titles: [
        { name: state.project_name },
        { name: type ? typeName : name },
        { name: company },
      ],
    },
  ]);
}
