<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(100%,100%,100%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,80%);fill-opacity:1;"
      d="M 497.144531 81.019531 L -17.140625 81.019531 L -17.140625 33.753906 L 497.144531 33.753906 Z M 497.144531 81.019531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,80%);fill-opacity:1;"
      d="M 497.144531 327.429688 L -17.140625 327.429688 L -17.140625 280.164062 L 497.144531 280.164062 Z M 497.144531 327.429688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,80%);fill-opacity:1;"
      d="M 157.535156 134.140625 L 234.964844 268.820312 L 314.058594 134.699219 Z M 157.535156 134.140625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 220.339844 223.460938 L 235.070312 248.425781 L 250.117188 223.5625 L 220.335938 223.460938 Z M 220.339844 223.460938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,80%);fill-opacity:1;"
      d="M 157.246094 225.40625 L 234.683594 90.730469 L 313.769531 224.851562 Z M 157.246094 225.40625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 220.339844 134.726562 L 235.070312 109.761719 L 250.117188 134.625 L 220.335938 134.726562 Z M 220.339844 134.726562 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 189.554688 190.625 L 174.339844 215.925781 L 203.097656 215.835938 Z M 189.554688 190.625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 174.601562 143.867188 L 203.585938 144.070312 L 189.640625 169.566406 Z M 174.601562 143.867188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 280.941406 190.914062 L 295.585938 215.925781 L 266.257812 215.550781 Z M 280.941406 190.914062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 295.320312 143.867188 L 266.332031 144.070312 L 280.277344 169.566406 Z M 295.320312 143.867188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 214.972656 143.839844 L 195.007812 180.058594 L 215.257812 215.425781 L 252.335938 216.28125 L 274.867188 180.058594 L 254.046875 143.554688 Z M 214.972656 143.839844 "
    />
  </g>
</svg>
