import Api from "apis/api";

class SampleResponseSubmissions {
  constructor() {
    this.endpointCreate = "/responder/submit";
    this.endpointCheck = "/responder/status_check";
    this.endpointPinSubmit = "/responder/pin_check";
  }

  create(options) {
    Api.post(this.endpointCreate, {
      body: options.body,
      success: options.success,
      error: options.error,
    });
  }

  check(options) {
    Api.get(this.endpointCheck, {
      params: options.params,
      success: options.success,
      error: options.error,
    });
  }

  pinSubmit(options) {
    Api.post(this.endpointPinSubmit, {
      body: options.body,
      success: options.success,
      error: options.error,
    });
  }
}

export default new SampleResponseSubmissions();
