import Api from "apis/api";
import { buildFormData } from "lib/helpers.js";

class ProjectUnits {
  constructor() {
    this.endpoint = "/project_units";
  }

  settings(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params);

    Api.patch(`${this.endpoint}/${params.project_unit_id}`, {
      body: formData,
      success,
      error,
    });
  }
}

export default new ProjectUnits();
