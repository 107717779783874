import Api from "apis/api";

class SampleResponses {
  constructor() {
    this.endpoint = "/responder/submit_specific_method";
  }

  sendData(options) {
    Api.post(this.endpoint, {
      body: options.body,
      success: options.success,
      error: options.error,
    });
  }
}

export default new SampleResponses();
