<script>
  import styles from "styleguide/DefaultSettingsListItem.json";
  import { t } from "stores/i18n.js";
  import { Checkbox, CheckboxRound, IconButton, Popover } from "components";

  export let isHeader = false;
  export let HeaderTitle = "";
  export let items = [];

  export let changeAllowed;
  export let setDefault;
</script>

<div data-component="DefaultSettingsListItem" class={styles["list-item"]}>
  {#if isHeader}
    <div class={styles["header"]}>
      <div class={styles["header-title"]}>
        {$t(`default_settings_list_item.${HeaderTitle}`)}
      </div>
      <div class={styles["header-section"]}>
        <div class={styles["header-section-allowed"]}>
          {$t("default_settings_list_item.allowed")}
        </div>
        <div class={styles["header-section-allowed"]}>
          {$t("default_settings_list_item.default")}
        </div>
      </div>
    </div>
  {/if}

  {#each items as item}
    <div class={styles["item"]}>
      <div class={styles["item-title"]}>
        {$t(`default_settings_list_item.${item.title}`)}
        <div class={styles["item-title-popover"]}>
          <Popover placement="top">
            <IconButton icon="info" />

            <div slot="content" class={styles["popover-content"]}>
              <div class={styles["popover-title"]}>
                {@html $t(`default_settings_list_item.${item.title}`)}
              </div>
              <div class={styles["popover-subtitle"]}>
                {@html $t(
                  `default_settings_list_item.${item.popover_subtitle}`,
                )}
              </div>
            </div>
          </Popover>
        </div>
      </div>
      <div class={styles["item-checkboxes"]}>
        <div class={styles["item-checkboxes-allowed"]}>
          <!-- <Checkbox toggled={item.allowed} toggle={changeAllowed}/> -->
          <Checkbox
            bind:toggled={item.allowed}
            toggle={changeAllowed}
            disabled={item.default}
          />
        </div>
        <div class={styles["item-checkboxes-allowed"]}>
          <CheckboxRound
            toggled={item.default}
            toggle={setDefault}
            uid={item.uid}
          />
        </div>
      </div>
    </div>
  {/each}
</div>

<style lang="scss">
  .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 14px;

    font-weight: 600;
    line-height: 22px;
    color: var(--primary-500, #052861);

    border-bottom: 1px solid var(--primary-050, #d3d9e3);

    &-section {
      display: flex;
      align-items: center;

      &-allowed {
        padding: 0 24px;
      }
    }
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 54px;

    border-bottom: 1px solid var(--primary-050, #d3d9e3);

    &-title {
      display: flex;
      align-items: center;

      color: var(--primary-500, #052861);
      text-align: center;

      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }

    &-checkboxes {
      display: flex;
      align-items: center;
      justify-content: space-between;

      gap: 34px;
      padding-right: 12px;

      &-allowed {
        padding: 0 24px;
      }
    }
  }

  .popover {
    &-content {
      text-align: left;
    }

    &-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--primary-500);
      padding-bottom: 8px;
    }
  }
</style>
