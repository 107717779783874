<script>
  import styles from "styleguide/QuickshareShow.json";
  import { onMount, getContext } from "svelte";

  import { showUpload, editUpload } from "stores/quickshare.js";
  import { t } from "stores/i18n.js";

  import { distanceOfTime } from "lib/helpers.js";

  import { LegacyButton as Button } from "components";
  import { QuickshareFileItem, QuickshareHeader } from "components/quickshare";

  const api = getContext("oksend");
  const config = getContext("config");

  let upload = {};
  let successfullyCopied = false;

  $: files = upload.files || [];

  onMount(async () => {
    getUploadData();
  });

  function onBack() {
    showUpload.set(null);
  }

  function onEdit() {
    editUpload.set($showUpload);
  }

  function onDelete() {
    api.uploads.delete({
      id: $showUpload,
      success: () => showUpload.set(null),
      error: () => console.log("ERROR"),
    });
  }

  function onSuccess(response) {
    upload = response;
  }

  function onCopyLink() {
    navigator.clipboard.writeText(upload.url).then(function () {
      successfullyCopied = true;
      setTimeout(function () {
        successfullyCopied = false;
      }, 1200);
    });
  }

  function getUploadData() {
    api.uploads.get({
      id: $showUpload,
      success: onSuccess,
      error: () => console.log("ERROR"),
    });
  }
</script>

<div data-component="QuickshareShow" class={styles.wrapper}>
  <QuickshareHeader>
    <Button
      click={onBack}
      style="border icon-only"
      icon="stroke_regular_left.svg"
    />
    {upload.name}
  </QuickshareHeader>

  <div class={styles.files}>
    {#each files as file}
      <QuickshareFileItem
        name={file.filename}
        size={file.filesize}
        key={file.id}
      />
    {/each}
  </div>

  <div class={styles.options}>
    <div class={styles.meta}>
      <span class={styles.access}>{$t("quickshare_show.access_link")}</span>
      <span class={styles.deletes}>
        {$t("quickshare_show.deletes_on")}
        {distanceOfTime(upload["self_destruct_at_timestamp"], config.language)}
      </span>
    </div>
    <div class={styles.link}>{upload.url}</div>
    <div class={styles.buttons}>
      <div class={styles.buttonwrapper}>
        <Button
          click={onCopyLink}
          style={successfullyCopied ? ["success"].join(" ") : "primary"}
        >
          {#if successfullyCopied}
            {$t("quickshare_show.copied_link")}
          {:else}
            {$t("quickshare_show.copy_link")}
          {/if}
        </Button>
      </div>
      <div class={styles.buttonwrapper}>
        <Button navigate={upload.url} newtab="true" style="border">
          {$t("quickshare_show.open_link")}
        </Button>
      </div>
      <div class={styles.buttonwrapper}>
        <Button click={onEdit} style="border"
          >{$t("quickshare_show.edit_link")}</Button
        >
      </div>
      <div class={styles.buttonwrapper}>
        <Button click={onDelete} style="border icon-only" icon="trash.svg" />
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }

  .files {
    flex: 1;
    padding: 20px;
    overflow-y: scroll;
  }

  .options {
    border-top: 1px solid var(--primary-050);
    background: var(--primary-010);
    padding: 15px 20px;
  }

  .meta {
    display: flex;
    justify-content: space-between;
  }

  .access {
    font-weight: 600;
  }

  .deletes {
    color: var(--primary-300);
    font-size: 13px;
  }

  .link {
    margin: 5px 0 10px 0;
    font-weight: 500;
    font-size: 13px;
    color: var(--blue-200);
  }

  .buttons {
    display: flex;
    align-self: center;
  }

  .buttonwrapper {
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
</style>
