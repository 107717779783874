<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(100%,100%,100%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0.392157%,0.392157%,49.411765%);fill-opacity:1;"
      d="M -30 120.003906 L 510 120.003906 L 510 360 L -30 360 Z M -30 120.003906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,0.392157%,0.392157%);fill-opacity:1;"
      d="M -30 239.996094 L 510 239.996094 L 510 360 L -30 360 Z M -30 239.996094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 64.035156 81.703125 L 64.035156 182.652344 C 70.949219 222.0625 89.269531 262.167969 151.152344 282.558594 C 212.6875 262.160156 232.390625 223.785156 239.652344 181.953125 L 239.652344 81.695312 L 64.035156 81.695312 Z M 64.035156 81.703125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,0.392157%,0.392157%);fill-opacity:1;"
      d="M 70.949219 89.65625 L 70.949219 177.8125 C 77.167969 209.964844 89.269531 253.871094 151.152344 274.269531 C 212.691406 253.871094 226.867188 209.964844 232.390625 177.8125 L 232.390625 89.65625 Z M 70.949219 89.65625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 160.835938 142.894531 L 160.835938 157.070312 L 189.183594 157.070312 C 192.984375 157.070312 196.558594 155.226562 200.246094 154.304688 L 200.246094 180.925781 C 196.445312 180.117188 192.726562 178.503906 188.839844 178.503906 L 161.179688 178.503906 L 161.179688 211 L 142.167969 210.308594 L 142.167969 178.503906 L 115.203125 178.503906 C 111.148438 178.503906 107.367188 180.578125 103.449219 181.617188 L 103.449219 153.269531 C 107.136719 154.421875 110.648438 156.726562 114.511719 156.726562 L 142.167969 156.726562 L 142.167969 142.550781 L 121.769531 142.550781 C 118.195312 142.550781 115.085938 145.085938 111.746094 146.351562 L 111.746094 119.386719 C 115.546875 121.003906 119.023438 124.226562 123.152344 124.226562 L 142.511719 124.226562 L 142.511719 112.128906 C 142.511719 106.476562 139.285156 101.296875 137.671875 95.878906 L 164.984375 95.878906 C 163.601562 101.410156 160.835938 106.773438 160.835938 112.472656 L 160.835938 124.226562 L 179.160156 124.226562 C 183.9375 124.226562 188.378906 121.695312 192.988281 120.425781 L 192.988281 147.046875 C 188.839844 145.664062 184.914062 142.898438 180.542969 142.898438 L 160.835938 142.898438 Z M 160.835938 142.894531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0.392157%,0.392157%,49.411765%);fill-opacity:1;"
      d="M 125.917969 214.804688 C 109.667969 206.679688 94.148438 211.21875 86.722656 224.226562 C 98.261719 246.265625 120.214844 264.070312 151.15625 274.265625 C 181.925781 264.066406 204.308594 246.265625 216.582031 225.261719 C 207.019531 207.023438 188.839844 209.445312 177.085938 215.152344 C 168.789062 194.753906 134.90625 195.789062 125.917969 214.804688 Z M 125.917969 214.804688 "
    />
  </g>
</svg>
