<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,25.098039%,45.098039%);fill-opacity:1;"
      d="M -47.589844 0.210938 L 132.410156 0.210938 L 132.410156 144.210938 L -47.589844 144.210938 Z M -47.589844 0.210938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,24.705882%,45.098039%);fill-opacity:1;"
      d="M -47.589844 215.984375 L 132.410156 215.984375 L 132.410156 359.984375 L -47.589844 359.984375 Z M -47.589844 215.984375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,80%,0%);fill-opacity:1;"
      d="M -47.589844 144.128906 L 132.410156 144.128906 L 132.410156 216.128906 L -47.589844 216.128906 Z M -47.589844 144.128906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,80%,0%);fill-opacity:1;"
      d="M 131.667969 0.0078125 L 203.667969 0.0078125 L 203.667969 360.003906 L 131.667969 360.003906 Z M 131.667969 0.0078125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,80%,0%);fill-opacity:1;"
      d="M 201.386719 144.148438 L 525.382812 144.148438 L 525.382812 216.148438 L 201.386719 216.148438 Z M 201.386719 144.148438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,24.705882%,45.098039%);fill-opacity:1;"
      d="M 203.609375 216.003906 L 527.605469 216.003906 L 527.605469 360.003906 L 203.609375 360.003906 Z M 203.609375 216.003906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,24.705882%,45.098039%);fill-opacity:1;"
      d="M 203.609375 0.210938 L 527.605469 0.210938 L 527.605469 144.210938 L 203.609375 144.210938 Z M 203.609375 0.210938 "
    />
  </g>
</svg>
