<script>
  import clsx from "clsx";
  import styles from "styleguide/Box.json";
  export let style = "";
  export let title;
</script>

<div
  data-component="Box"
  class={clsx(
    styles.box,
    style.split(" ").map((x) => styles[x]),
  )}
>
  {#if title || $$slots.header}
    <div class={styles.header}>
      {#if $$slots.header}
        <slot name="header" />
      {:else}
        <div class={styles.title}>{title}</div>
      {/if}
    </div>
  {/if}
  <div class={styles.content}>
    <slot />
  </div>
</div>

<style scoped lang="scss">
  .box {
    display: flex;
    flex-direction: column;
    flex: 1;
    border: 1px solid var(--primary-050);
    border-radius: var(--border-radius);
    background: #fff;
  }

  .shadow {
    box-shadow:
      0px 8px 24px -4px rgba(24, 39, 75, 0.08),
      0px 6px 12px -6px rgba(24, 39, 75, 0.12);
  }

  .title {
    border-bottom: 1px solid var(--primary-050);
    font-weight: 600;
    padding: 15px 20px;
  }

  .no-title-border {
    .title {
      border-bottom: 0;
      padding-bottom: 9px;
    }
  }

  .padding {
    .content {
      padding: 15px 20px;
    }
  }

  .margin-top {
    margin-top: 20px;
  }

  .margin-bottom {
    margin-bottom: 20px;
  }

  .no-border {
    border: 0;
    border-radius: 0;
    background: transparent;
  }

  .overflow-hidden {
    overflow: hidden;
  }
</style>
