<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(100%,100%,100%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(67.058824%,13.72549%,11.372549%);fill-opacity:1;"
      d="M 0 0 L 480 0 L 480 144 L 0 144 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(67.058824%,13.72549%,11.372549%);fill-opacity:1;"
      d="M 0 216 L 480 216 L 480 360.003906 L 0 360.003906 Z M 0 216 "
    />
  </g>
</svg>
