<script>
  import styles from "styleguide/DashboardProjectEditContent.json";
  import { getContext } from "svelte";

  import { t } from "stores/i18n.js";
  import { couldSaveUsers, project } from "stores/dashboard/project.js";
  import { can } from "stores/permission.js";

  import Projects from "apis/dashboard/projects.js";
  import { enterYesConfirmation } from "lib/helpers.js";

  import {
    DashboardProjectArchive,
    DashboardProjectControl,
  } from "apps/dashboard";
  import { Boundary, Button, TextInput, Toast } from "components";

  let dirty;
  let confirmationText;
  let dashboardProjectControl;
  $: isValid = $couldSaveUsers;

  const { language } = getContext("config");

  function deleteProject(id) {
    if (enterYesConfirmation(confirmationText, language)) {
      let formData = new FormData();

      formData.append("id", id);
      formData.append("confirm_deletion", "JA");

      Projects.delete(
        {
          params: formData,
          success: deleteSuccess,
          error: deleteError,
        },
        id,
      );
    } else {
      dirty = true;
    }
  }

  function deleteSuccess(response) {
    const path = response.data.path;

    if (path) {
      window.location = path;
    }
  }

  function deleteError() {
    alert("Projects delete Error");
  }
</script>

<Boundary>
  <div data-component="DashboardProjectEditContent" class={styles.wrapper}>
    <div class={styles.control}>
      <DashboardProjectControl
        type="edit"
        wrapped={true}
        {...$project}
        bind:this={dashboardProjectControl}
      >
        <svelte.fragment slot="bottom">
          {#if !!dashboardProjectControl}
            <Button
              style={"primary"}
              click={dashboardProjectControl.onSubmit}
              disabled={!isValid}
            >
              {$t("dashboard_new_project.save_changes")}
            </Button>
          {/if}
        </svelte.fragment>
      </DashboardProjectControl>
    </div>

    <DashboardProjectArchive />

    {#if $can("destroy", "project")}
      <div class={styles.infobox}>
        <Toast
          type="error"
          title={$t("dashboard_project_edit.delete_title")}
          message={$t("dashboard_project_edit.delete_description")}
        >
          <div class={styles.confirmation}>
            <div class={styles.input}>
              <TextInput
                placeholder={$t("dashboard_project_edit.delete_placeholder")}
                bind:value={confirmationText}
                errors={dirty
                  ? [$t("dashboard_project_edit.confirmation_error")]
                  : []}
                style="border medium round-border"
              />
            </div>
            <Button
              style="error"
              click={deleteProject.bind(this, $project.permalink)}
            >
              {$t("dashboard_project_edit.delete_button")}
            </Button>
          </div>
        </Toast>
      </div>
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .control {
    margin-bottom: 30px;
  }

  .infobox {
    margin-top: 25px;
  }

  .confirmation {
    display: flex;
  }

  .input {
    width: 150px;
    margin-right: 12px;
  }
</style>
