import { writable } from "svelte/store";

const newAuditProjectId = writable(null);
const newAuditProjectName = writable("");

function onCreateNewAudit(id, name) {
  newAuditProjectId.set(id);
  newAuditProjectName.set(name);
}

export { newAuditProjectId, newAuditProjectName, onCreateNewAudit };
