<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(74.117647%,23.921569%,26.666667%);fill-opacity:1;"
      d="M 0 0 L 684.007812 0 L 684.007812 27.691406 L 0 27.691406 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(74.117647%,23.921569%,26.666667%);fill-opacity:1;"
      d="M 0 55.386719 L 684.007812 55.386719 L 684.007812 83.078125 L 0 83.078125 Z M 0 55.386719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(74.117647%,23.921569%,26.666667%);fill-opacity:1;"
      d="M 0 110.769531 L 684.007812 110.769531 L 684.007812 138.460938 L 0 138.460938 Z M 0 110.769531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(74.117647%,23.921569%,26.666667%);fill-opacity:1;"
      d="M 0 166.15625 L 684.007812 166.15625 L 684.007812 193.847656 L 0 193.847656 Z M 0 166.15625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(74.117647%,23.921569%,26.666667%);fill-opacity:1;"
      d="M 0 221.539062 L 684.007812 221.539062 L 684.007812 249.234375 L 0 249.234375 Z M 0 221.539062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(74.117647%,23.921569%,26.666667%);fill-opacity:1;"
      d="M 0 276.925781 L 684.007812 276.925781 L 684.007812 304.617188 L 0 304.617188 Z M 0 276.925781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(74.117647%,23.921569%,26.666667%);fill-opacity:1;"
      d="M 0 332.3125 L 684.007812 332.3125 L 684.007812 360.003906 L 0 360.003906 Z M 0 332.3125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 27.691406 L 684.007812 27.691406 L 684.007812 55.386719 L 0 55.386719 Z M 0 27.691406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 83.078125 L 684.007812 83.078125 L 684.007812 110.769531 L 0 110.769531 Z M 0 83.078125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 138.460938 L 684.007812 138.460938 L 684.007812 166.15625 L 0 166.15625 Z M 0 138.460938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 193.847656 L 684.007812 193.847656 L 684.007812 221.539062 L 0 221.539062 Z M 0 193.847656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 249.234375 L 684.007812 249.234375 L 684.007812 276.925781 L 0 276.925781 Z M 0 249.234375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 304.617188 L 684.007812 304.617188 L 684.007812 332.3125 L 0 332.3125 Z M 0 304.617188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(9.803922%,18.431373%,36.470588%);fill-opacity:1;"
      d="M 0 0 L 273.601562 0 L 273.601562 193.84375 L 0 193.84375 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 22.800781 8.296875 L 25.289062 15.957031 L 33.34375 15.957031 L 26.828125 20.695312 L 29.316406 28.355469 L 22.800781 23.621094 L 16.28125 28.355469 L 18.773438 20.695312 L 12.253906 15.957031 L 20.3125 15.957031 Z M 22.800781 8.296875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 68.402344 8.296875 L 70.890625 15.957031 L 78.945312 15.957031 L 72.429688 20.695312 L 74.917969 28.355469 L 68.402344 23.621094 L 61.882812 28.355469 L 64.371094 20.695312 L 57.855469 15.957031 L 65.910156 15.957031 Z M 68.402344 8.296875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 114.003906 8.296875 L 116.492188 15.957031 L 124.546875 15.957031 L 118.03125 20.695312 L 120.519531 28.355469 L 114.003906 23.621094 L 107.484375 28.355469 L 109.972656 20.695312 L 103.457031 15.957031 L 111.511719 15.957031 Z M 114.003906 8.296875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 159.601562 8.296875 L 162.089844 15.957031 L 170.144531 15.957031 L 163.628906 20.695312 L 166.117188 28.355469 L 159.601562 23.621094 L 153.082031 28.355469 L 155.574219 20.695312 L 149.054688 15.957031 L 157.109375 15.957031 Z M 159.601562 8.296875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 205.203125 8.296875 L 207.691406 15.957031 L 215.746094 15.957031 L 209.230469 20.695312 L 211.71875 28.355469 L 205.203125 23.621094 L 198.683594 28.355469 L 201.175781 20.695312 L 194.65625 15.957031 L 202.710938 15.957031 Z M 205.203125 8.296875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 250.804688 8.296875 L 253.292969 15.957031 L 261.347656 15.957031 L 254.832031 20.695312 L 257.320312 28.355469 L 250.804688 23.621094 L 244.285156 28.355469 L 246.777344 20.695312 L 240.257812 15.957031 L 248.3125 15.957031 Z M 250.804688 8.296875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 45.601562 27.679688 L 48.089844 35.34375 L 56.148438 35.34375 L 49.628906 40.078125 L 52.117188 47.738281 L 45.601562 43.003906 L 39.082031 47.738281 L 41.574219 40.078125 L 35.054688 35.34375 L 43.113281 35.34375 Z M 45.601562 27.679688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 91.199219 27.679688 L 93.6875 35.34375 L 101.746094 35.34375 L 95.226562 40.078125 L 97.71875 47.738281 L 91.199219 43.003906 L 84.683594 47.738281 L 87.171875 40.078125 L 80.65625 35.34375 L 88.710938 35.34375 Z M 91.199219 27.679688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 136.800781 27.679688 L 139.289062 35.34375 L 147.347656 35.34375 L 140.828125 40.078125 L 143.320312 47.738281 L 136.800781 43.003906 L 130.285156 47.738281 L 132.773438 40.078125 L 126.257812 35.34375 L 134.3125 35.34375 Z M 136.800781 27.679688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 182.402344 27.679688 L 184.890625 35.34375 L 192.949219 35.34375 L 186.429688 40.078125 L 188.921875 47.738281 L 182.402344 43.003906 L 175.886719 47.738281 L 178.375 40.078125 L 171.855469 35.34375 L 179.914062 35.34375 Z M 182.402344 27.679688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 228 27.679688 L 230.492188 35.34375 L 238.546875 35.34375 L 232.027344 40.078125 L 234.519531 47.738281 L 228 43.003906 L 221.484375 47.738281 L 223.972656 40.078125 L 217.457031 35.34375 L 225.511719 35.34375 Z M 228 27.679688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 22.800781 47.066406 L 25.289062 54.726562 L 33.34375 54.726562 L 26.828125 59.464844 L 29.316406 67.125 L 22.800781 62.390625 L 16.28125 67.125 L 18.773438 59.464844 L 12.253906 54.726562 L 20.3125 54.726562 Z M 22.800781 47.066406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 68.402344 47.066406 L 70.890625 54.726562 L 78.945312 54.726562 L 72.429688 59.464844 L 74.917969 67.125 L 68.402344 62.390625 L 61.882812 67.125 L 64.371094 59.464844 L 57.855469 54.726562 L 65.910156 54.726562 Z M 68.402344 47.066406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 114.003906 47.066406 L 116.492188 54.726562 L 124.546875 54.726562 L 118.03125 59.464844 L 120.519531 67.125 L 114.003906 62.390625 L 107.484375 67.125 L 109.972656 59.464844 L 103.457031 54.726562 L 111.511719 54.726562 Z M 114.003906 47.066406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 159.601562 47.066406 L 162.089844 54.726562 L 170.144531 54.726562 L 163.628906 59.464844 L 166.117188 67.125 L 159.601562 62.390625 L 153.082031 67.125 L 155.574219 59.464844 L 149.054688 54.726562 L 157.109375 54.726562 Z M 159.601562 47.066406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 205.203125 47.066406 L 207.691406 54.726562 L 215.746094 54.726562 L 209.230469 59.464844 L 211.71875 67.125 L 205.203125 62.390625 L 198.683594 67.125 L 201.175781 59.464844 L 194.65625 54.726562 L 202.710938 54.726562 Z M 205.203125 47.066406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 250.804688 47.066406 L 253.292969 54.726562 L 261.347656 54.726562 L 254.832031 59.464844 L 257.320312 67.125 L 250.804688 62.390625 L 244.285156 67.125 L 246.777344 59.464844 L 240.257812 54.726562 L 248.3125 54.726562 Z M 250.804688 47.066406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 45.601562 66.449219 L 48.089844 74.113281 L 56.148438 74.113281 L 49.628906 78.847656 L 52.117188 86.507812 L 45.601562 81.773438 L 39.082031 86.507812 L 41.574219 78.847656 L 35.054688 74.113281 L 43.113281 74.113281 Z M 45.601562 66.449219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 91.199219 66.449219 L 93.6875 74.113281 L 101.746094 74.113281 L 95.226562 78.847656 L 97.71875 86.507812 L 91.199219 81.773438 L 84.683594 86.507812 L 87.171875 78.847656 L 80.65625 74.113281 L 88.710938 74.113281 Z M 91.199219 66.449219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 136.800781 66.449219 L 139.289062 74.113281 L 147.347656 74.113281 L 140.828125 78.847656 L 143.320312 86.507812 L 136.800781 81.773438 L 130.285156 86.507812 L 132.773438 78.847656 L 126.257812 74.113281 L 134.3125 74.113281 Z M 136.800781 66.449219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 182.402344 66.449219 L 184.890625 74.113281 L 192.949219 74.113281 L 186.429688 78.847656 L 188.921875 86.507812 L 182.402344 81.773438 L 175.886719 86.507812 L 178.375 78.847656 L 171.855469 74.113281 L 179.914062 74.113281 Z M 182.402344 66.449219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 228 66.449219 L 230.492188 74.113281 L 238.546875 74.113281 L 232.027344 78.847656 L 234.519531 86.507812 L 228 81.773438 L 221.484375 86.507812 L 223.972656 78.847656 L 217.457031 74.113281 L 225.511719 74.113281 Z M 228 66.449219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 22.800781 85.835938 L 25.289062 93.496094 L 33.34375 93.496094 L 26.828125 98.234375 L 29.316406 105.894531 L 22.800781 101.160156 L 16.28125 105.894531 L 18.773438 98.234375 L 12.253906 93.496094 L 20.3125 93.496094 Z M 22.800781 85.835938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 68.402344 85.835938 L 70.890625 93.496094 L 78.945312 93.496094 L 72.429688 98.234375 L 74.917969 105.894531 L 68.402344 101.160156 L 61.882812 105.894531 L 64.371094 98.234375 L 57.855469 93.496094 L 65.910156 93.496094 Z M 68.402344 85.835938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 114.003906 85.835938 L 116.492188 93.496094 L 124.546875 93.496094 L 118.03125 98.234375 L 120.519531 105.894531 L 114.003906 101.160156 L 107.484375 105.894531 L 109.972656 98.234375 L 103.457031 93.496094 L 111.511719 93.496094 Z M 114.003906 85.835938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 159.601562 85.835938 L 162.089844 93.496094 L 170.144531 93.496094 L 163.628906 98.234375 L 166.117188 105.894531 L 159.601562 101.160156 L 153.082031 105.894531 L 155.574219 98.234375 L 149.054688 93.496094 L 157.109375 93.496094 Z M 159.601562 85.835938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 205.203125 85.835938 L 207.691406 93.496094 L 215.746094 93.496094 L 209.230469 98.234375 L 211.71875 105.894531 L 205.203125 101.160156 L 198.683594 105.894531 L 201.175781 98.234375 L 194.65625 93.496094 L 202.710938 93.496094 Z M 205.203125 85.835938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 250.804688 85.835938 L 253.292969 93.496094 L 261.347656 93.496094 L 254.832031 98.234375 L 257.320312 105.894531 L 250.804688 101.160156 L 244.285156 105.894531 L 246.777344 98.234375 L 240.257812 93.496094 L 248.3125 93.496094 Z M 250.804688 85.835938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 45.601562 105.222656 L 48.089844 112.882812 L 56.148438 112.882812 L 49.628906 117.617188 L 52.117188 125.277344 L 45.601562 120.542969 L 39.082031 125.277344 L 41.574219 117.617188 L 35.054688 112.882812 L 43.113281 112.882812 Z M 45.601562 105.222656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 91.199219 105.222656 L 93.6875 112.882812 L 101.746094 112.882812 L 95.226562 117.617188 L 97.71875 125.277344 L 91.199219 120.542969 L 84.683594 125.277344 L 87.171875 117.617188 L 80.65625 112.882812 L 88.710938 112.882812 Z M 91.199219 105.222656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 136.800781 105.222656 L 139.289062 112.882812 L 147.347656 112.882812 L 140.828125 117.617188 L 143.320312 125.277344 L 136.800781 120.542969 L 130.285156 125.277344 L 132.773438 117.617188 L 126.257812 112.882812 L 134.3125 112.882812 Z M 136.800781 105.222656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 182.402344 105.222656 L 184.890625 112.882812 L 192.949219 112.882812 L 186.429688 117.617188 L 188.921875 125.277344 L 182.402344 120.542969 L 175.886719 125.277344 L 178.375 117.617188 L 171.855469 112.882812 L 179.914062 112.882812 Z M 182.402344 105.222656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 228 105.222656 L 230.492188 112.882812 L 238.546875 112.882812 L 232.027344 117.617188 L 234.519531 125.277344 L 228 120.542969 L 221.484375 125.277344 L 223.972656 117.617188 L 217.457031 112.882812 L 225.511719 112.882812 Z M 228 105.222656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 22.800781 124.605469 L 25.289062 132.265625 L 33.34375 132.265625 L 26.828125 137.003906 L 29.316406 144.664062 L 22.800781 139.929688 L 16.28125 144.664062 L 18.773438 137.003906 L 12.253906 132.265625 L 20.3125 132.265625 Z M 22.800781 124.605469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 68.402344 124.605469 L 70.890625 132.265625 L 78.945312 132.265625 L 72.429688 137.003906 L 74.917969 144.664062 L 68.402344 139.929688 L 61.882812 144.664062 L 64.371094 137.003906 L 57.855469 132.265625 L 65.910156 132.265625 Z M 68.402344 124.605469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 114.003906 124.605469 L 116.492188 132.265625 L 124.546875 132.265625 L 118.03125 137.003906 L 120.519531 144.664062 L 114.003906 139.929688 L 107.484375 144.664062 L 109.972656 137.003906 L 103.457031 132.265625 L 111.511719 132.265625 Z M 114.003906 124.605469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 159.601562 124.605469 L 162.089844 132.265625 L 170.144531 132.265625 L 163.628906 137.003906 L 166.117188 144.664062 L 159.601562 139.929688 L 153.082031 144.664062 L 155.574219 137.003906 L 149.054688 132.265625 L 157.109375 132.265625 Z M 159.601562 124.605469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 205.203125 124.605469 L 207.691406 132.265625 L 215.746094 132.265625 L 209.230469 137.003906 L 211.71875 144.664062 L 205.203125 139.929688 L 198.683594 144.664062 L 201.175781 137.003906 L 194.65625 132.265625 L 202.710938 132.265625 Z M 205.203125 124.605469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 250.804688 124.605469 L 253.292969 132.265625 L 261.347656 132.265625 L 254.832031 137.003906 L 257.320312 144.664062 L 250.804688 139.929688 L 244.285156 144.664062 L 246.777344 137.003906 L 240.257812 132.265625 L 248.3125 132.265625 Z M 250.804688 124.605469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 45.601562 143.992188 L 48.089844 151.652344 L 56.148438 151.652344 L 49.628906 156.386719 L 52.117188 164.050781 L 45.601562 159.3125 L 39.082031 164.050781 L 41.574219 156.386719 L 35.054688 151.652344 L 43.113281 151.652344 Z M 45.601562 143.992188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 91.199219 143.992188 L 93.6875 151.652344 L 101.746094 151.652344 L 95.226562 156.386719 L 97.71875 164.050781 L 91.199219 159.3125 L 84.683594 164.050781 L 87.171875 156.386719 L 80.65625 151.652344 L 88.710938 151.652344 Z M 91.199219 143.992188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 136.800781 143.992188 L 139.289062 151.652344 L 147.347656 151.652344 L 140.828125 156.386719 L 143.320312 164.050781 L 136.800781 159.3125 L 130.285156 164.050781 L 132.773438 156.386719 L 126.257812 151.652344 L 134.3125 151.652344 Z M 136.800781 143.992188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 182.402344 143.992188 L 184.890625 151.652344 L 192.949219 151.652344 L 186.429688 156.386719 L 188.921875 164.050781 L 182.402344 159.3125 L 175.886719 164.050781 L 178.375 156.386719 L 171.855469 151.652344 L 179.914062 151.652344 Z M 182.402344 143.992188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 228 143.992188 L 230.492188 151.652344 L 238.546875 151.652344 L 232.027344 156.386719 L 234.519531 164.050781 L 228 159.3125 L 221.484375 164.050781 L 223.972656 156.386719 L 217.457031 151.652344 L 225.511719 151.652344 Z M 228 143.992188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 22.800781 163.375 L 25.289062 171.035156 L 33.34375 171.035156 L 26.828125 175.773438 L 29.316406 183.433594 L 22.800781 178.699219 L 16.28125 183.433594 L 18.773438 175.773438 L 12.253906 171.035156 L 20.3125 171.035156 Z M 22.800781 163.375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 68.402344 163.375 L 70.890625 171.035156 L 78.945312 171.035156 L 72.429688 175.773438 L 74.917969 183.433594 L 68.402344 178.699219 L 61.882812 183.433594 L 64.371094 175.773438 L 57.855469 171.035156 L 65.910156 171.035156 Z M 68.402344 163.375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 114.003906 163.375 L 116.492188 171.035156 L 124.546875 171.035156 L 118.03125 175.773438 L 120.519531 183.433594 L 114.003906 178.699219 L 107.484375 183.433594 L 109.972656 175.773438 L 103.457031 171.035156 L 111.511719 171.035156 Z M 114.003906 163.375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 159.601562 163.375 L 162.089844 171.035156 L 170.144531 171.035156 L 163.628906 175.773438 L 166.117188 183.433594 L 159.601562 178.699219 L 153.082031 183.433594 L 155.574219 175.773438 L 149.054688 171.035156 L 157.109375 171.035156 Z M 159.601562 163.375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 205.203125 163.375 L 207.691406 171.035156 L 215.746094 171.035156 L 209.230469 175.773438 L 211.71875 183.433594 L 205.203125 178.699219 L 198.683594 183.433594 L 201.175781 175.773438 L 194.65625 171.035156 L 202.710938 171.035156 Z M 205.203125 163.375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 250.804688 163.375 L 253.292969 171.035156 L 261.347656 171.035156 L 254.832031 175.773438 L 257.320312 183.433594 L 250.804688 178.699219 L 244.285156 183.433594 L 246.777344 175.773438 L 240.257812 171.035156 L 248.3125 171.035156 Z M 250.804688 163.375 "
    />
  </g>
</svg>
