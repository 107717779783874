<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(98.039216%,0%,4.313725%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 127.5 146.246094 L 352.496094 146.246094 L 352.496094 213.746094 L 127.5 213.746094 Z M 127.5 146.246094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 206.25 67.496094 L 273.746094 67.496094 L 273.746094 292.496094 L 206.25 292.496094 Z M 206.25 67.496094 "
    />
  </g>
</svg>
