<script>
  import styles from "styleguide/QuickshareUploader.json";
  import { getContext } from "svelte";

  import { t } from "stores/i18n.js";
  import { addedFiles, uploadedFiles, showUpload } from "stores/quickshare.js";

  import { Dropzone, LegacyButton as Button } from "components";
  import {
    QuickshareHeader,
    QuickshareUploadItem,
    QuickshareUploadSettings,
  } from "components/quickshare";

  const api = getContext("oksend");
  let dropzone;
  let settingsComponent;
  let uploadSettings = {
    name: null,
    deleteAfter: 172800,
    passwordPresent: false,
    numberDownloads: null,
  };

  $: ready = isReady($addedFiles, $uploadedFiles);

  function isReady(added, uploaded) {
    console.log("Ready?", `${added.length} / ${uploaded.length}`);
    return added.length == uploaded.length;
  }

  function addFiles(files) {
    const newFilesArray = [...$addedFiles, ...files];
    addedFiles.set(Array.from(newFilesArray));
  }

  function onBack() {
    addedFiles.set([]);
    uploadedFiles.set([]);
  }

  function onCreate(response) {
    showUpload.set(response.id);
    uploadedFiles.set([]);
    addedFiles.set([]);
  }

  function submit() {
    api.uploads.create({
      files: $uploadedFiles,
      settings: settingsComponent.getSettings(),
      success: onCreate,
      error: () => console.log("ERROR"),
    });
  }
</script>

<div data-component="QuickshareUploader" class={styles.wrapper}>
  <QuickshareHeader>
    <Button
      click={onBack}
      style="border icon-only"
      icon="stroke_regular_left.svg"
    />
    {$t("quickshare_uploader.title")}
  </QuickshareHeader>

  <div class={styles.body}>
    <Dropzone class={styles.test} bind:this={dropzone} onFiles={addFiles}>
      <div class={styles["dropzone-body"]}>
        {#each $addedFiles as addedFile, index (addedFile)}
          <QuickshareUploadItem file={addedFile} {index} />
        {/each}

        <div class={styles["dropzone-description"]} on:click={dropzone.click()}>
          {$t("quickshare_uploader.dropzone_description")}
        </div>
      </div>
    </Dropzone>
  </div>

  <div class={styles.options}>
    <QuickshareUploadSettings
      bind:this={settingsComponent}
      {...uploadSettings}
    />

    {#if ready}
      <Button style="primary" click={submit}
        >{$t("quickshare_uploader.create")}</Button
      >
    {:else}
      <Button disabled>{$t("quickshare_uploader.loading")}</Button>
    {/if}
  </div>
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }

  .header {
    border-bottom: 1px solid var(--primary-050);
    padding: 10px 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .body {
    flex: 1;
    overflow-y: scroll;
  }

  .dropzone-body {
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 20px;
  }

  .dropzone-description {
    margin-top: 20px;
    font-size: 12px;
    color: var(--primary-300);
  }

  .options {
    border-top: 1px solid var(--primary-050);
    box-shadow: 0 -3px 8px 0 rgba(41, 49, 61, 0.06);
    padding: 10px 20px 20px 20px;
  }
</style>
