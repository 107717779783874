<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(87.058824%,12.941176%,6.27451%);fill-opacity:1;"
      d="M 0 239.996094 L 480 239.996094 L 480 360 L 0 360 Z M 0 239.996094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 0 L 480 0 L 480 120.003906 L 0 120.003906 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(19.215686%,58.039216%,0%);fill-opacity:1;"
      d="M 0 120.003906 L 480 120.003906 L 480 240.003906 L 0 240.003906 Z M 0 120.003906 "
    />
  </g>
</svg>
