import { SAMPLE_STATUS } from "apps/sample/constants";

export function getSampleStatusTagColor(newStatus) {
  switch (newStatus) {
    case SAMPLE_STATUS.creating:
    case SAMPLE_STATUS.notMarkedForShipping:
    case SAMPLE_STATUS.reminder:
    case SAMPLE_STATUS.sent:
    case SAMPLE_STATUS.waiting:
      return "grey";

    case SAMPLE_STATUS.checkAddress:
    case SAMPLE_STATUS.differenceIsExplained:
    case SAMPLE_STATUS.differenceIsNotSignificant:
      return "yellow";

    case SAMPLE_STATUS.actionRequired:
    case SAMPLE_STATUS.differenceIsNotExplained:
    case SAMPLE_STATUS.returned:
    case SAMPLE_STATUS.waitingLetterhead:
      return "red";

    case SAMPLE_STATUS.alternativelyReviewed:
    case SAMPLE_STATUS.balanceConfirmed:
    case SAMPLE_STATUS.sending:
    case SAMPLE_STATUS.waitingConfirmation:
      return "green";

    case SAMPLE_STATUS.newResponse:
      return "blue";

    default:
      return "grey";
  }
}
