<script>
  import styles from "styleguide/ConfirmationSignerWrapper.json";
  import { isDesktopWidth } from "stores/device.js";

  import {
    ConfirmationSignerFooter,
    ConfirmationSignerHeader,
  } from "apps/confirmationSigner";
  import { Box } from "components";
</script>

<div data-component="ConfirmationSignerWrapper" class={styles.wrapper}>
  <div class={styles.box}>
    <Box style={$isDesktopWidth ? "overflow-hidden" : "no-border"}>
      <div class={styles.inner}>
        <ConfirmationSignerHeader />
        <div class={styles.content}>
          <slot />
        </div>
        <ConfirmationSignerFooter />
      </div>
    </Box>
  </div>
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    min-height: 100vh;
  }

  @media only screen and (max-width: 1024px) {
    .wrapper {
      flex-direction: column;
    }

    .inner {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
    }

    .content {
      flex: 1 0 auto;
    }
  }

  @media only screen and (min-width: 1023px) {
    .wrapper {
      align-items: center;
      justify-content: center;
      padding: 70px 5% 110px;
      background-color: var(--primary-010);
    }

    .box {
      width: 870px;
    }
  }
</style>
