import { Controller } from "stimulus";
import camelCase from "lodash.camelcase";
import Api from "../../apis/api";
import Helper from "../../general/helper";

const COUNT_KEYS = ["files", "not_imported_yet"];

export default class extends Controller {
  static targets = ["files", "notImportedYet"];

  connect() {
    const permalink = this.data.get("permalink");

    this.fetchCounts({
      url: `/reviews/${permalink}/upload_counts`,
      params: {},
    });
  }

  fetchCounts({ url, params }) {
    Api.get(url, {
      params,
      success: (response) => {
        const counts = Helper.dig(["data", "counts"], response);

        if (!counts) {
          return;
        }

        for (const key of COUNT_KEYS) {
          const targetName = camelCase(camelCase(`${key}_target`));

          this.setCount(targetName, counts[key]);

          if (key === "not_imported_yet" && counts[key] > 0) {
            this.showTarget(targetName);
          }
        }
      },
      error: (response) => {
        console.error("Error", response);
      },
    });
  }

  setCount(targetName, value) {
    if (value !== undefined && value !== null) {
      this[targetName].textContent = value;
    }
  }

  showTarget(targetName) {
    this[targetName].closest("li").classList.remove("hidden");
  }
}
