<script>
  import {
    confirmation,
    confirmationId,
    getConfirmation,
    activeSettingsEdit,
    activeDelete,
    activeArchive,
  } from "stores/confirmation/confirmation.js";
  import { confirmationType } from "stores/dashboard/create_confirmation.js";
  import { t } from "stores/i18n.js";
  import { can } from "stores/permission.js";
  import { toasts } from "stores/toasts.js";
  import { isUserTeamUser } from "stores/user.js";

  import ConfirmationApi from "apis/dashboard/confirmations.js";
  import { dateTimeFormat } from "lib/helpers.js";

  import { Button, SidebarArea } from "components";
  import styles from "styleguide/ConfirmationSidebarSettings.json";

  function unarchive() {
    let formData = new FormData();

    formData.append("id", $confirmationId);
    formData.append("archived", false);

    const params = {
      params: formData,
      success: unarchiveSuccess,
      error: unarchiveError,
    };

    ConfirmationApi.archive(params, $confirmationId);
  }

  function unarchiveSuccess() {
    getConfirmation();
    toasts.send({
      title: $t("confirmation_settings_contacts.unarchive_success"),
      type: "success",
    });
  }

  function unarchiveError() {
    toasts.send({ title: $t("errors.check_entries"), type: "error" });
  }

  function onEditSettingModal() {
    activeSettingsEdit.set(true);
    confirmationType.set("confirmation");
  }
</script>

<div data-component="ConfirmationSidebarSettings">
  <div class={styles.item}>
    <div class={styles.subtitle}>{$t("confirmation.settings.client")}</div>
    <div>
      {$isUserTeamUser
        ? $confirmation.client_name
        : $confirmation.client_official_name}
    </div>
  </div>

  {#if $confirmation.fiscal_year_beginning_date}
    <div class={styles.item}>
      <div class={styles.subtitle}>
        {$t("confirmation.settings.fiscal_year_beginning_date")}
      </div>
      <div>
        {dateTimeFormat($confirmation.fiscal_year_beginning_date).split(",")[0]}
      </div>
    </div>
  {/if}

  {#if $confirmation.target_date}
    <div class={styles.item}>
      <div class={styles.subtitle}>
        {$t("confirmation.settings.target_date")}
      </div>
      <div>{dateTimeFormat($confirmation.target_date).split(",")[0]}</div>
    </div>
  {/if}

  {#if $confirmation.deadline}
    <div class={styles.item}>
      <div class={styles.subtitle}>{$t("confirmation.settings.deadline")}</div>
      <div>{dateTimeFormat($confirmation.deadline).split(",")[0]}</div>
    </div>
  {/if}

  {#if $confirmation.fixed_response_period}
    <div class={styles.item}>
      <div class={styles.subtitle}>
        {$t("confirmation.settings.fixed_response_period")}
      </div>
      <div>
        {dateTimeFormat($confirmation.fixed_response_period).split(",")[0]}
      </div>
    </div>
  {/if}

  {#if $confirmation.start_sending_at}
    <div class={styles.item}>
      <div class={styles.subtitle}>
        {$t("confirmation.settings.start_sending_at")}
      </div>
      <div>{dateTimeFormat($confirmation.start_sending_at).split(",")[0]}</div>
    </div>
  {/if}

  <SidebarArea>
    {#if $can("update", "confirmation_client_user")}
      <Button style="blue-text sidebar-action" click={onEditSettingModal}
        >{$t("confirmation_settings_contacts.edit")}</Button
      >
    {/if}

    {#if $can("archive", "confirmation")}
      {#if $confirmation.archived}
        <Button
          style="blue-text sidebar-action"
          click={() => {
            unarchive();
          }}>{$t("confirmation_settings_contacts.unarchive")}</Button
        >
      {:else}
        <Button
          style="blue-text sidebar-action"
          click={() => activeArchive.set(true)}
          >{$t("confirmation_settings_contacts.archive")}</Button
        >
      {/if}
    {/if}

    {#if $can("destroy", "confirmation")}
      <Button
        style="error-text sidebar-action"
        click={() => activeDelete.set(true)}
        >{$t("confirmation_settings_contacts.delete")}</Button
      >
    {/if}
  </SidebarArea>
</div>

<style lang="scss">
  .item {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .subtitle {
    font-size: 12px;
    line-height: 18px;
    color: var(--primary-300);
  }
</style>
