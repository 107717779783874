<script>
  export let width = 18;
  export let height = 19;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 18 19"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M12.7071068,4.54289322 C13.0675907,4.90337718 13.0953203,5.47060824 12.7902954,5.86289944 L12.7071068,5.95710678 L8.414,10.25 L9.499,11.335 L16.2928932,4.54289322 C16.6834175,4.15236893 17.3165825,4.15236893 17.7071068,4.54289322 C18.0675907,4.90337718 18.0953203,5.47060824 17.7902954,5.86289944 L17.7071068,5.95710678 L10.2071068,13.4571068 L10.2071068,13.4571068 C10.0569051,13.6073084 9.87080989,13.6997402 9.67643151,13.7344021 L9.55903139,13.7482669 L9.55903139,13.7482669 L9.44096861,13.7482669 C9.2443814,13.7367129 9.05055523,13.6673891 8.88710056,13.5402954 L8.79289322,13.4571068 L7,11.664 L5.20710678,13.4571068 L5.20710678,13.4571068 C5.05690513,13.6073084 4.87080989,13.6997402 4.67643151,13.7344021 L4.55903139,13.7482669 L4.55903139,13.7482669 L4.44096861,13.7482669 C4.2443814,13.7367129 4.05055523,13.6673891 3.88710056,13.5402954 L3.79289322,13.4571068 L0.292893219,9.95710678 C-0.0976310729,9.56658249 -0.0976310729,8.93341751 0.292893219,8.54289322 C0.65337718,8.18240926 1.22060824,8.15467972 1.61289944,8.45970461 L1.70710678,8.54289322 L4.5,11.335 L11.2928932,4.54289322 C11.6834175,4.15236893 12.3165825,4.15236893 12.7071068,4.54289322 Z"
  ></path>
</svg>
