<script>
  import {
    AdministrationApi,
    AdministrationCompliancePackage,
    AdministrationDashboard,
    AdministrationDefaultSettings,
    AdministrationLogs,
    AdministrationSidebar,
    AdministrationSsoCredential,
    AdministrationTheme,
    AdministrationUserSynchronization,
  } from "apps/administration";
  import { AdministrationUpsellModal } from "components/modals";
  import { account } from "stores/account.js";
  import DefaultSettingsStore from "stores/admin/default_settings_store.js";
  import { currentAdminPage } from "stores/admin.js";
  import { t } from "stores/i18n.js";

  import { App, ContentSidebar, Header } from "components";

  export let appConfig;
  export let initialState;

  currentAdminPage.set(initialState.adminPage);

  if (initialState) {
    DefaultSettingsStore.update({
      project_allowed: initialState.projectsSettings.allowed_accesses
        .split(",")
        .map((item) => item.trim()),
      project_default: initialState.projectsSettings.default_access,
    });
  }
</script>

<App {...appConfig}>
  <ContentSidebar>
    <div slot="content">
      <Header
        type={$account.official_name}
        headline={$t(`administration.${$currentAdminPage}.title`)}
      />
      {#if $currentAdminPage === "dashboard"}
        <AdministrationDashboard />
      {:else if $currentAdminPage === "logs"}
        <AdministrationLogs />
      {:else if $currentAdminPage === "api"}
        <AdministrationApi />
      {:else if $currentAdminPage === "theme"}
        <AdministrationTheme />
      {:else if $currentAdminPage === "default_settings"}
        <AdministrationDefaultSettings />
      {:else if $currentAdminPage === "compliance_package"}
        <AdministrationCompliancePackage />
      {:else if $currentAdminPage === "scim"}
        <AdministrationUserSynchronization />
      {:else if $currentAdminPage === "sso"}
        <AdministrationSsoCredential />
      {/if}
    </div>
    <div slot="sidebar">
      <AdministrationSidebar />
    </div>
  </ContentSidebar>
  <AdministrationUpsellModal />
</App>

<style global lang="scss">
  .wrapper {
    padding: 40px 0;
  }
</style>
