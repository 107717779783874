<script>
  import styles from "styleguide/NotificationItem.json";
  import { getContext } from "svelte";
  import { t } from "stores/i18n.js";
  import { distanceOfTime } from "lib/helpers.js";
  import NotificationApi from "apis/notification/notification.js";
  import { IconButton, Tag } from "components";

  export let content;
  export let permalink;
  export let updated_at;
  export let tags;

  let contentContainer;
  const config = getContext("config");

  $: seconds = Date.parse(updated_at) / 1000;

  function go(event) {
    if (!event.target.closest(`.${styles.clear}`)) {
      let element = contentContainer.querySelector("a");
      if (element) element.click();
    }
  }

  function clear() {
    NotificationApi.clear(permalink);
  }
</script>

<div data-component="NotificationItem" class={styles.wrapper} on:click={go}>
  <div class={styles.item}>
    <div class={styles.content} bind:this={contentContainer}>
      {@html content}
    </div>
    <div class={styles.meta}>{distanceOfTime(seconds, config.language)}</div>
  </div>

  <div class={styles.tags}>
    {#each tags as tag}
      <div class={styles.tag}>
        <Tag style="semi-bold" color={"grey"} label={tag} size="small" />
      </div>
    {/each}
  </div>

  <div class={styles.clear}>
    <IconButton
      style="primary-text small"
      icon="checkmark_medium"
      click={clear}
    />
  </div>
</div>

<style lang="scss">
  .content {
    color: var(--primary-500);
  }
  .wrapper {
    padding: 8px 20px;
    position: relative;
    cursor: pointer;

    &:hover {
      background-color: var(--blue-010);

      & .clear {
        display: block;
      }
    }
  }

  .item {
    overflow-wrap: break-word;
  }

  .meta {
    color: var(--primary-300);
  }

  .clear {
    display: none;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
  }

  .tag {
    padding: 4px 6px 0 0;
  }
</style>
