import { Controller } from "stimulus";
import Api from "../general/api";

export default class extends Controller {
  static targets = [];

  connect() {
    let self = this;
    this.element.querySelectorAll("a").forEach((node) => {
      node.addEventListener("click", self.tabListenerEvent.bind(self));
    });

    let autoLoadTarget = this.element.querySelector("a[data-autoload]");
    if (autoLoadTarget) {
      autoLoadTarget.dispatchEvent(new Event("click"));
    }
  }

  tabListenerEvent(e) {
    e.preventDefault();
    e.stopPropagation();

    if (document.querySelector("[data-replace-tab]")) {
      this.element.querySelector(".active").classList.remove("active");
      e.target.classList.add("active");
      Api.get(
        e.target.getAttribute("data-link"),
        {},
        this.replaceWithNewView.bind(this),
      );

      let editor = document.querySelector("[data-sidebar-editor]");
      if (editor) {
        editor.classList.add("hidden");
      }
    } else {
      window.location.href = e.target.getAttribute("data-link");
    }
  }

  replaceWithNewView(response) {
    document.querySelector("[data-replace-tab]").innerHTML =
      response["template"];
  }
}
