<script>
  export let width = 28;
  export let height = 28;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 28 28"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M13.8124 0H14.0454C16.5771 0.0853125 18.6904 1.01773 20.3853 2.79727C21.8794 4.36516 22.703 6.40664 22.7959 8.57992C22.7966 8.59256 22.8017 8.60457 22.8103 8.61386C22.8188 8.62315 22.8304 8.62914 22.843 8.63078C25.7759 9.01359 27.8939 11.3799 28 14.332V14.5228C27.9054 17.5634 25.655 19.9828 22.639 20.2606C21.636 20.3536 20.5242 20.288 19.4966 20.2896C19.4892 20.2896 19.4821 20.2866 19.4769 20.2813C19.4717 20.2759 19.4688 20.2687 19.4688 20.2612V18.1295C19.4688 18.1219 19.4717 18.1147 19.4771 18.1093C19.4824 18.104 19.4896 18.101 19.4972 18.101C20.238 18.1021 20.9794 18.1021 21.7213 18.101C22.2157 18.1003 22.5788 18.0782 22.8107 18.0348C25.6134 17.5115 26.8089 14.0689 24.8385 11.9591C24.1221 11.1918 23.1667 10.7795 22.1069 10.7756C21.6165 10.7734 21.132 10.7727 20.6533 10.7734C20.6292 10.7734 20.6174 10.7614 20.6177 10.7373C20.6203 10.0836 20.615 9.42703 20.6019 8.7675C20.5368 5.43648 18.0786 2.63812 14.7312 2.23891C11.2262 1.82055 8.03195 4.18633 7.38609 7.6568C7.33578 7.92768 7.28109 8.38979 7.22203 9.04313C7.22021 9.06245 7.20964 9.07302 7.19031 9.07484C6.86255 9.10401 6.53516 9.13646 6.20812 9.17219C4.25906 9.38602 2.67203 10.8057 2.28102 12.7345C1.74672 15.3716 3.75648 18.1065 6.51438 18.1016C7.17682 18.1005 7.84 18.1007 8.50391 18.1021C8.52214 18.1021 8.53125 18.1112 8.53125 18.1295V20.2606C8.53125 20.2679 8.52839 20.2749 8.52328 20.2802C8.51816 20.2854 8.51121 20.2884 8.50391 20.2885C7.87464 20.29 7.245 20.29 6.615 20.2885C2.95094 20.2776 0.115391 17.3343 0 13.7227V13.4909C0.111562 10.3846 2.18586 7.80391 5.22156 7.10773C5.23465 7.10465 5.24657 7.09798 5.25596 7.0885C5.26535 7.07901 5.27182 7.0671 5.27461 7.05414C6.15945 2.95313 9.62391 0.130156 13.8124 0Z"
  />
  <path
    d="M14.5157 28H13.4225L10.7466 25.1847C10.7287 25.1661 10.7188 25.1412 10.7187 25.1153V17.9113C10.7187 17.9037 10.7172 17.8961 10.7142 17.8892C10.7112 17.8822 10.7068 17.876 10.7012 17.8708C10.5284 17.7095 10.3162 17.5454 10.1713 17.3994C7.08148 14.2877 8.62258 9.024 12.9336 8.149C14.6699 7.79627 16.4675 8.33002 17.7516 9.55119C19.9943 11.684 20.0195 15.1495 17.879 17.3491C17.7133 17.5192 17.4956 17.6963 17.3009 17.867C17.2878 17.8786 17.2812 17.893 17.2812 17.9102V25.1284C17.2812 25.1505 17.2726 25.1717 17.2572 25.1874L14.5157 28ZM10.733 13.7156C10.8254 15.0746 11.6648 16.0552 12.8674 16.6321C12.879 16.6377 12.8888 16.6464 12.8957 16.6572C12.9026 16.6681 12.9062 16.6806 12.9062 16.6934V19.6744C12.9062 19.6926 12.9133 19.7102 12.9259 19.7236L14.3905 21.2642C14.3932 21.267 14.3953 21.2703 14.3967 21.274C14.3982 21.2776 14.3989 21.2815 14.3987 21.2854C14.3986 21.2894 14.3978 21.2932 14.3962 21.2968C14.3945 21.3004 14.3922 21.3036 14.3894 21.3063L12.9227 22.6981C12.9117 22.7083 12.9062 22.721 12.9062 22.7363V24.2539C12.9062 24.2721 12.9133 24.2898 12.9259 24.3031L13.9606 25.3903C13.9635 25.3934 13.9669 25.3958 13.9708 25.3975C13.9746 25.3992 13.9787 25.4001 13.9829 25.4001C13.9871 25.4002 13.9913 25.3994 13.9951 25.3978C13.999 25.3962 14.0025 25.3939 14.0055 25.3909L15.0735 24.2928C15.087 24.2789 15.0937 24.2623 15.0937 24.243V16.7196C15.0937 16.7018 15.0986 16.6842 15.1077 16.6688C15.1168 16.6534 15.13 16.6408 15.1457 16.6321C15.3346 16.529 15.526 16.4163 15.7199 16.2942C17.932 14.898 17.7434 11.5888 15.3508 10.5175C13.1152 9.51564 10.5656 11.247 10.733 13.7156Z"
  />
  <path
    d="M14 13.5013C14.6007 13.5013 15.0877 13.0143 15.0877 12.4135C15.0877 11.8128 14.6007 11.3258 14 11.3258C13.3993 11.3258 12.9123 11.8128 12.9123 12.4135C12.9123 13.0143 13.3993 13.5013 14 13.5013Z"
  />
</svg>
