<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(100%,100%,100%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(87.058824%,16.078431%,9.411765%);fill-opacity:1;"
      d="M -33 239.996094 L 687 239.996094 L 687 360 L -33 360 Z M -33 239.996094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(3.137255%,22.352941%,61.176471%);fill-opacity:1;"
      d="M -33 120.003906 L 687 120.003906 L 687 240.003906 L -33 240.003906 Z M -33 120.003906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(87.058824%,16.078431%,9.411765%);fill-opacity:1;"
      d="M 171.167969 65.375 C 168.339844 108.722656 166.722656 132.476562 160.121094 143.46875 C 152.984375 155.273438 146.039062 163.917969 118.195312 174.324219 C 90.34375 163.910156 83.402344 155.269531 76.265625 143.464844 C 69.664062 132.472656 68.042969 108.722656 65.21875 65.375 L 69.335938 63.988281 C 77.617188 61.460938 83.800781 59.429688 88.371094 58.527344 C 94.921875 57.140625 100.484375 55.574219 118.085938 55.207031 C 135.683594 55.511719 141.277344 57.175781 147.824219 58.5625 C 152.359375 59.527344 158.804688 61.429688 167.027344 64.003906 L 171.164062 65.375 Z M 171.167969 65.375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(3.137255%,22.352941%,61.176471%);fill-opacity:1;"
      d="M 167.109375 63.953125 C 164.4375 107.207031 162.199219 127.015625 158.710938 136.542969 C 151.945312 152.851562 141.226562 161.792969 118.167969 170.320312 C 95.105469 161.785156 84.386719 152.839844 77.621094 136.535156 C 74.132812 127.015625 71.929688 107.257812 69.332031 63.902344 C 77.441406 61.371094 83.800781 59.390625 88.375 58.488281 C 94.921875 57.101562 100.488281 55.46875 118.085938 55.167969 C 135.683594 55.46875 141.316406 57.101562 147.863281 58.488281 C 152.433594 59.390625 158.925781 61.367188 167.109375 63.945312 Z M 167.109375 63.953125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,80%,0%);fill-opacity:1;"
      d="M 118.144531 77.195312 L 119.25 79.8125 L 124.109375 80.480469 L 120.960938 82.378906 L 124.003906 84.414062 L 119.574219 85.15625 L 118.230469 87.527344 L 116.714844 85.085938 L 112.496094 84.484375 L 115.367188 82.40625 L 112.414062 80.515625 L 117.078125 79.800781 Z M 118.144531 77.195312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 157.039062 126.296875 L 154.347656 124.167969 L 152.402344 120.964844 L 148.589844 117.65625 L 146.601562 114.316406 L 142.789062 110.902344 L 140.921875 107.5625 L 138.894531 105.925781 L 137.558594 104.636719 L 134.113281 107.65625 L 132.234375 110.9375 L 129.898438 113.058594 L 127.328125 111.050781 L 125.382812 107.628906 L 118.171875 94.820312 L 110.957031 107.628906 L 109.015625 111.050781 L 106.445312 113.058594 L 104.105469 110.9375 L 102.230469 107.65625 L 98.785156 104.636719 L 97.445312 105.925781 L 95.417969 107.5625 L 93.554688 110.902344 L 89.742188 114.316406 L 87.753906 117.65625 L 83.941406 120.964844 L 81.996094 124.167969 L 79.308594 126.34375 C 80.703125 138.21875 88.273438 147.519531 92.394531 151.777344 C 96.996094 156.246094 106.484375 162.222656 118.089844 166.179688 C 129.722656 162.308594 139.34375 156.246094 143.945312 151.777344 C 148.070312 147.519531 155.636719 138.21875 157.039062 126.296875 Z M 157.039062 126.296875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,80%,0%);fill-opacity:1;"
      d="M 129.738281 58.984375 L 130.84375 61.601562 L 135.703125 62.269531 L 132.554688 64.167969 L 135.597656 66.203125 L 131.171875 66.945312 L 129.824219 69.316406 L 128.308594 66.875 L 124.09375 66.273438 L 126.960938 64.195312 L 124.007812 62.304688 L 128.671875 61.589844 Z M 129.738281 58.984375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,80%,0%);fill-opacity:1;"
      d="M 106.550781 58.945312 L 107.65625 61.5625 L 112.515625 62.230469 L 109.367188 64.128906 L 112.410156 66.164062 L 107.980469 66.90625 L 106.632812 69.277344 L 105.117188 66.835938 L 100.902344 66.234375 L 103.773438 64.15625 L 100.820312 62.265625 L 105.484375 61.550781 Z M 106.550781 58.945312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(3.137255%,22.352941%,61.176471%);fill-opacity:1;"
      d="M 151.023438 142.753906 L 145.769531 142.769531 L 140.925781 142.410156 L 135.054688 139.53125 L 128.441406 139.570312 L 122.710938 142.371094 L 118.191406 142.769531 L 113.671875 142.371094 L 107.941406 139.570312 L 101.328125 139.53125 L 95.457031 142.410156 L 90.609375 142.769531 L 85.292969 142.699219 L 82.726562 138.347656 L 82.820312 138.25 L 90.675781 139.546875 L 95.523438 139.1875 L 101.390625 136.308594 L 108.007812 136.347656 L 113.738281 139.148438 L 118.257812 139.546875 L 122.777344 139.148438 L 128.503906 136.347656 L 135.121094 136.308594 L 140.992188 139.1875 L 145.835938 139.546875 L 153.480469 138.207031 L 153.582031 138.410156 Z M 151.023438 142.753906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(3.137255%,22.352941%,61.176471%);fill-opacity:1;"
      d="M 90.222656 149.441406 L 95.421875 149.082031 L 101.289062 146.203125 L 107.90625 146.242188 L 113.636719 149.042969 L 118.15625 149.441406 L 122.675781 149.042969 L 128.402344 146.242188 L 135.019531 146.203125 L 140.890625 149.082031 L 146.15625 149.441406 L 149.558594 145.25 L 149.441406 145.15625 L 145.800781 146.21875 L 140.953125 145.859375 L 135.085938 142.980469 L 128.46875 143.019531 L 122.738281 145.820312 L 118.222656 146.21875 L 113.703125 145.820312 L 107.972656 143.019531 L 101.355469 142.980469 L 95.488281 145.859375 L 90.640625 146.21875 L 87.070312 145.320312 L 87.027344 145.496094 Z M 90.222656 149.441406 "
    />
  </g>
</svg>
