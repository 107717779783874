<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(10.588235%,0%,45.098039%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.196078%,0%,2.352941%);fill-opacity:1;"
      d="M -30 90 L 510 90 L 510 270 L -30 270 Z M -30 90 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.1702;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 214.368763 -113.978836 L 529.718266 -113.978836 L 529.718266 -62.987869 L 214.368763 -62.987869 Z M 214.368763 -113.978836 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 219.999479 279.997164 L 215.000854 294.997153 L 534.99739 294.997153 L 529.998765 279.997164 Z M 219.999479 279.997164 "
      transform="matrix(0.739266,0,0,0.580469,-37.231844,48.896061)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.1225;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 245.002012 280.002643 L 240.002078 295.002632 L 300.001286 295.002632 L 295.001352 280.002643 Z M 245.002012 280.002643 "
      transform="matrix(0.896887,0,0,0.580469,86.452327,48.896787)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.5196;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 244.997636 280.002643 L 239.999258 295.002632 L 299.997088 295.002632 L 294.99871 280.002643 Z M 244.997636 280.002643 "
      transform="matrix(0.451709,0,0,0.580469,208.492507,48.896787)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:2.0241;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 245.001117 280.002643 L 239.996877 295.002632 L 299.994229 295.002632 L 295.00337 280.002643 Z M 245.001117 280.002643 "
      transform="matrix(0.29194,0,0,0.580469,251.642351,48.896787)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.0977;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 245.001985 279.997164 L 240.001214 294.997153 L 299.998038 294.997153 L 295.00141 279.997164 Z M 245.001985 279.997164 "
      transform="matrix(0.942823,0,0,0.580469,-14.571718,48.896061)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.186063;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.597832 239.581428 L 531.499962 239.581428 L 531.499962 251.579619 L 212.597832 251.579619 Z M 212.597832 239.581428 "
      transform="matrix(0.725695,0,0,0.725695,-30,0.00016218)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.11675;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 462.78892 -113.871181 L 525.288247 -113.871181 L 525.288247 -63.079376 L 462.78892 -63.079376 Z M 462.78892 -113.871181 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.80466;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 507.498197 -90.068578 L 513.747591 -90.068578 L 513.747591 -63.079376 L 507.498197 -63.079376 Z M 507.498197 -90.068578 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.80467;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 479.997632 -90.068578 L 486.252409 -90.068578 L 486.252409 -63.079376 L 479.997632 -63.079376 Z M 479.997632 -90.068578 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.5543;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 245.002537 279.997164 L 239.995963 294.997153 L 300.000624 294.997153 L 295.002298 279.997164 Z M 245.002537 279.997164 "
      transform="matrix(0.473596,0,0,0.580469,111.967774,48.896061)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.9557;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 245.006383 279.997164 L 239.995914 294.997153 L 300.003179 294.997153 L 295.005861 279.997164 Z M 245.006383 279.997164 "
      transform="matrix(0.297034,0,0,0.580469,159.791095,48.896061)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.148575;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 338.802226 -113.978836 L 404.655018 -113.978836 L 404.655018 -62.987869 L 338.802226 -62.987869 Z M 338.802226 -113.978836 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.0398;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 391.268073 -90.073961 L 397.517467 -90.073961 L 397.517467 -63.084758 L 391.268073 -63.084758 Z M 391.268073 -90.073961 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.0402;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 347.780684 -90.073961 L 354.035462 -90.073961 L 354.035462 -63.090141 L 347.780684 -63.090141 Z M 347.780684 -90.073961 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.89065;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 356.102445 -126.321525 L 387.989967 -126.321525 L 387.989967 -62.998634 L 356.102445 -62.998634 Z M 356.102445 -126.321525 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.0398;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 380.018086 -90.073961 L 386.267481 -90.073961 L 386.267481 -63.090141 L 380.018086 -63.090141 Z M 380.018086 -90.073961 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.0402;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 359.030671 -90.073961 L 365.285448 -90.073961 L 365.285448 -63.084758 L 359.030671 -63.084758 Z M 359.030671 -90.073961 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 358.180194 217.131253 L 371.653264 240.11106 L 385.917601 217.131253 Z M 358.180194 217.131253 "
      transform="matrix(0.725695,0,0,0.61433,-30,39.461296)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.1785;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 487.587335 -113.978836 L 505.30741 -113.978836 L 505.30741 -50.989677 L 487.587335 -50.989677 Z M 487.587335 -113.978836 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.1779;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 490.542475 -113.978836 L 502.341504 -113.978836 L 502.341504 -50.989677 L 490.542475 -50.989677 Z M 490.542475 -113.978836 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.1225;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 245.001248 280.002643 L 240.001719 295.002632 L 300.000427 295.002632 L 295.000898 280.002643 Z M 245.001248 280.002643 "
      transform="matrix(0.896959,0,0,0.580469,-91.283518,48.896787)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.6368;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 244.998454 280.002643 L 239.996397 295.002632 L 300.00255 295.002632 L 295.000493 280.002643 Z M 244.998454 280.002643 "
      transform="matrix(0.421702,0,0,0.580469,35.933773,48.896787)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:2.0493;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 244.996742 280.002643 L 240.002615 295.002632 L 300.004727 295.002632 L 294.996082 280.002643 Z M 244.996742 280.002643 "
      transform="matrix(0.269066,0,0,0.580469,77.0836,48.896787)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.114525;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 217.910625 -113.978836 L 279.92012 -113.978836 L 279.92012 -62.987869 L 217.910625 -62.987869 Z M 217.910625 -113.978836 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.80467;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 230.000323 -90.068578 L 236.249717 -90.068578 L 236.249717 -63.079376 L 230.000323 -63.079376 Z M 230.000323 -90.068578 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.80467;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 258.74969 -90.063195 L 264.999085 -90.063195 L 264.999085 -63.079376 L 258.74969 -63.079376 Z M 258.74969 -90.063195 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.1785;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 239.172561 -113.978836 L 256.887253 -113.978836 L 256.887253 -50.989677 L 239.172561 -50.989677 Z M 239.172561 -113.978836 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.1779;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 242.111552 -113.978836 L 253.910581 -113.978836 L 253.910581 -50.989677 L 242.111552 -50.989677 Z M 242.111552 -113.978836 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.199163;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 209.061353 228.347589 L 535.041824 228.347589 L 535.041824 240.345781 L 209.061353 240.345781 Z M 209.061353 228.347589 "
      transform="matrix(0.725695,0,0,0.725695,-30,0.00016218)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 187.501425 328.560569 L 593.749767 328.560569 L 593.749767 346.061395 L 187.501425 346.061395 Z M 187.501425 328.560569 "
      transform="matrix(0.759513,0,0,0.639702,-56.702685,35.760572)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.122325;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 226.249542 284.808504 L 553.751877 284.808504 L 553.751877 302.30933 L 226.249542 302.30933 Z M 226.249542 284.808504 "
      transform="matrix(0.759513,0,0,0.639702,-56.702685,35.760572)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.139325;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 221.250448 288.557809 L 558.75097 288.557809 L 558.75097 306.058635 L 221.250448 306.058635 Z M 221.250448 288.557809 "
      transform="matrix(0.759513,0,0,0.639702,-56.702685,35.760572)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 187.501425 328.560569 L 593.749767 328.560569 L 593.749767 346.061395 L 187.501425 346.061395 Z M 187.501425 328.560569 "
      transform="matrix(0.759513,0,0,0.639702,-56.702685,35.760572)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.17055;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 212.502035 298.560025 L 568.749157 298.560025 L 568.749157 316.060851 L 212.502035 316.060851 Z M 212.502035 298.560025 "
      transform="matrix(0.759513,0,0,0.639702,-56.702685,35.760572)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.210925;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 199.999158 311.059743 L 581.252034 311.059743 L 581.252034 328.560569 L 199.999158 328.560569 Z M 199.999158 311.059743 "
      transform="matrix(0.759513,0,0,0.639702,-56.702685,35.760572)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.287125;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 287.499535 283.55772 L 306.247621 283.55772 L 306.247621 346.059078 L 287.499535 346.059078 Z M 287.499535 283.55772 "
      transform="matrix(0.685695,0,0,0.626924,-53.570584,40.187926)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.050963;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 290.621368 283.55772 L 303.120092 283.55772 L 303.120092 346.059078 L 290.621368 346.059078 Z M 290.621368 283.55772 "
      transform="matrix(0.685695,0,0,0.626924,-53.570584,40.187926)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.287125;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 287.502285 283.557426 L 306.250371 283.557426 L 306.250371 346.058784 L 287.502285 346.058784 Z M 287.502285 283.557426 "
      transform="matrix(0.685695,0,0,0.626924,126.423625,40.18811)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.050963;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 290.618421 283.557426 L 303.122842 283.557426 L 303.122842 346.058784 L 290.618421 346.058784 Z M 290.618421 283.557426 "
      transform="matrix(0.685695,0,0,0.626924,126.423625,40.18811)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.2011;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 365.500759 240.351164 L 379.738182 240.351164 L 379.738182 354.33129 L 365.500759 354.33129 Z M 365.500759 240.351164 "
      transform="matrix(0.725695,0,0,0.725695,-30,0.00016218)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 217.921241 220.297812 L 218.103776 183.386417 C 218.103776 183.386417 221.46355 190.622449 247.617308 192.205729 C 272.367834 191.271021 276.46345 186.845469 277.587177 182.922242 L 276.560421 220.4377 Z M 217.921241 220.297812 "
      transform="matrix(0.684802,0,0,0.61433,159.544359,39.461296)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:3.5278;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.923335 208.560911 L 312.503954 188.103662 C 318.643748 182.8099 330.040658 191.890047 330.040658 191.890047 C 330.040658 191.890047 336.180452 168.418046 362.504979 158.566266 C 388.816526 168.418046 394.956319 191.890047 394.956319 191.890047 C 394.956319 191.890047 406.35323 182.8099 412.506004 188.103662 L 394.073643 208.560911 Z M 330.923335 208.560911 "
      transform="matrix(0.300931,0,0,0.21768,220.774175,102.846645)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:2.615;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.920953 208.566097 L 312.49844 188.108653 C 318.639278 182.802023 330.042264 191.902766 330.042264 191.902766 C 330.042264 191.902766 336.183102 168.420287 362.50383 158.563285 C 388.814573 168.420287 394.955411 191.902766 394.955411 191.902766 C 394.955411 191.902766 406.358397 182.802023 412.499235 188.108653 L 394.076722 208.566097 Z M 330.920953 208.566097 "
      transform="matrix(0.391208,0,0,0.304748,188.048945,96.221047)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:3.3833;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 0.00204048 280.522479 C 0.793373 280.522479 30.113596 332.826756 0.00204048 333.616357 C -30.109515 334.405958 0.00204048 282.101682 0.00204048 280.522479 Z M 0.00204048 280.522479 "
      transform="matrix(0.286305,0,0,0.286933,329.975978,32.977649)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:5.9629;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.923481 208.570158 L 312.491784 188.094164 C 318.635683 182.795702 330.045781 191.888547 330.045781 191.888547 C 330.045781 191.888547 336.18968 168.404394 362.503797 158.559509 C 388.817914 168.404394 394.961813 191.888547 394.961813 191.888547 C 394.961813 191.888547 406.355033 182.795702 412.498932 188.094164 L 394.084114 208.570158 Z M 330.923481 208.570158 "
      transform="matrix(0.231429,0,0,0.114273,246.106192,108.681784)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:8.3211;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.92484 208.555659 L 330.037351 191.908232 C 330.037351 191.908232 336.184029 168.424079 362.512848 158.545011 C 388.808796 168.424079 394.955474 191.908232 394.955474 191.908232 L 394.067986 208.555659 Z M 330.92484 208.555659 "
      transform="matrix(0.11884,0,0,0.114273,286.919118,110.585784)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:5.0956;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.917247 208.557836 L 330.046194 191.893909 C 330.046194 191.893909 336.176437 168.410592 362.505255 158.566057 C 388.817639 168.410592 394.947882 191.893909 394.947882 191.893909 L 394.076829 208.557836 Z M 330.917247 208.557836 "
      transform="matrix(0.237679,0,0,0.152369,243.840041,108.355105)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:5.0956;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.917247 208.551059 L 330.046194 191.887132 C 330.046194 191.887132 336.176437 168.403814 362.505255 158.559279 C 388.817639 168.403814 394.947882 191.887132 394.947882 191.887132 L 394.076829 208.551059 Z M 330.917247 208.551059 "
      transform="matrix(0.237679,0,0,0.152369,243.840041,112.164731)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:3.6515;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.923481 208.563515 L 312.491784 188.105341 C 318.635683 182.81134 330.045781 191.899589 330.045781 191.899589 C 330.045781 191.899589 336.18968 168.416271 362.503797 158.558918 C 388.817914 168.416271 394.961813 191.899589 394.961813 191.899589 C 394.961813 191.899589 406.355033 182.81134 412.498932 188.105341 L 394.084114 208.563515 Z M 330.923481 208.563515 "
      transform="matrix(0.231429,0,0,0.304738,246.106192,91.813948)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:3.6515;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.923481 208.559405 L 312.491784 188.114049 C 318.635683 182.80723 330.045781 191.895479 330.045781 191.895479 C 330.045781 191.895479 336.18968 168.412161 362.503797 158.567626 C 388.817914 168.412161 394.961813 191.895479 394.961813 191.895479 C 394.961813 191.895479 406.355033 182.80723 412.498932 188.114049 L 394.084114 208.559405 Z M 330.923481 208.559405 "
      transform="matrix(0.231429,0,0,0.304738,246.106192,99.432388)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:3.2227;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.917247 208.556725 L 330.046194 191.892798 C 330.046194 191.892798 336.176437 168.409481 362.505255 158.564946 C 388.817639 168.409481 394.947882 191.892798 394.947882 191.892798 L 394.076829 208.556725 Z M 330.917247 208.556725 "
      transform="matrix(0.237679,0,0,0.380922,243.840041,94.970217)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:4.2164;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.923481 208.568077 L 312.491784 188.109903 C 318.635683 182.811629 330.045781 191.904151 330.045781 191.904151 C 330.045781 191.904151 336.18968 168.420833 362.503797 158.559207 C 388.817914 168.420833 394.961813 191.904151 394.961813 191.904151 C 394.961813 191.904151 406.355033 182.811629 412.498932 188.109903 L 394.084114 208.568077 Z M 330.923481 208.568077 "
      transform="matrix(0.231429,0,0,0.228553,246.106192,126.749067)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:3.5278;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.920314 208.567856 L 312.500934 188.110608 C 318.640727 182.798901 330.037638 191.896993 330.037638 191.896993 C 330.037638 191.896993 336.177432 168.407047 362.501959 158.573212 C 388.813505 168.407047 394.953299 191.896993 394.953299 191.896993 C 394.953299 191.896993 406.36319 182.798901 412.502983 188.110608 L 394.083603 208.567856 Z M 330.920314 208.567856 "
      transform="matrix(0.300931,0,0,0.21768,41.040709,101.220133)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:2.615;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.91863 208.55824 L 312.496117 188.113615 C 318.636954 182.806985 330.039941 191.894909 330.039941 191.894909 C 330.039941 191.894909 336.180778 168.41243 362.501507 158.568247 C 388.81225 168.41243 394.953087 191.894909 394.953087 191.894909 C 394.953087 191.894909 406.356074 182.806985 412.496911 188.113615 L 394.074399 208.571058 L 330.91863 208.571058 Z M 330.91863 208.55824 "
      transform="matrix(0.391208,0,0,0.304748,8.315479,94.594535)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 217.918972 220.297812 L 218.101506 183.386417 C 218.101506 183.386417 221.466984 190.622449 247.615039 192.205729 C 272.365564 191.271021 276.46118 186.845469 277.584908 182.922242 L 276.558152 220.4377 Z M 217.918972 220.297812 "
      transform="matrix(0.684802,0,0,0.61433,-18.6494,39.461296)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:3.3833;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 0.00559399 280.522479 C 0.796926 280.522479 30.11715 332.826756 0.00559399 333.616357 C -30.105962 334.405958 0.00559399 282.101682 0.00559399 280.522479 Z M 0.00559399 280.522479 "
      transform="matrix(0.286305,0,0,0.286933,149.978867,32.977649)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:5.9629;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.927877 208.570158 L 312.49618 188.094164 C 318.640079 182.795702 330.050177 191.888547 330.050177 191.888547 C 330.050177 191.888547 336.177198 168.404394 362.508194 158.559509 C 388.822311 168.404394 394.949331 191.888547 394.949331 191.888547 C 394.949331 191.888547 406.359429 182.795702 412.503328 188.094164 L 394.071631 208.570158 Z M 330.927877 208.570158 "
      transform="matrix(0.231429,0,0,0.114273,66.109081,108.681784)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:8.3211;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.933401 208.555659 L 330.045912 191.908232 C 330.045912 191.908232 336.19259 168.424079 362.488539 158.545011 C 388.817357 168.424079 394.964035 191.908232 394.964035 191.908232 L 394.076547 208.555659 Z M 330.933401 208.555659 "
      transform="matrix(0.11884,0,0,0.114273,106.922007,110.585784)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:5.0956;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.921528 208.557836 L 330.034039 191.893909 C 330.034039 191.893909 336.180717 168.410592 362.493101 158.566057 C 388.82192 168.410592 394.952163 191.893909 394.952163 191.893909 L 394.081109 208.557836 Z M 330.921528 208.557836 "
      transform="matrix(0.237679,0,0,0.152369,63.84293,108.355105)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:5.0956;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.921528 208.551059 L 330.034039 191.887132 C 330.034039 191.887132 336.180717 168.403814 362.493101 158.559279 C 388.82192 168.403814 394.952163 191.887132 394.952163 191.887132 L 394.081109 208.551059 Z M 330.921528 208.551059 "
      transform="matrix(0.237679,0,0,0.152369,63.84293,112.164731)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:3.6515;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.927877 208.563515 L 312.49618 188.105341 C 318.640079 182.81134 330.050177 191.899589 330.050177 191.899589 C 330.050177 191.899589 336.177198 168.416271 362.508194 158.558918 C 388.822311 168.416271 394.949331 191.899589 394.949331 191.899589 C 394.949331 191.899589 406.359429 182.81134 412.503328 188.105341 L 394.071631 208.563515 Z M 330.927877 208.563515 "
      transform="matrix(0.231429,0,0,0.304738,66.109081,91.813948)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:3.6515;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.927877 208.559405 L 312.49618 188.114049 C 318.640079 182.80723 330.050177 191.895479 330.050177 191.895479 C 330.050177 191.895479 336.177198 168.412161 362.508194 158.567626 C 388.822311 168.412161 394.949331 191.895479 394.949331 191.895479 C 394.949331 191.895479 406.359429 182.80723 412.503328 188.114049 L 394.071631 208.559405 Z M 330.927877 208.559405 "
      transform="matrix(0.231429,0,0,0.304738,66.109081,99.432388)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:3.2227;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.921528 208.556725 L 330.034039 191.892798 C 330.034039 191.892798 336.180717 168.409481 362.493101 158.564946 C 388.82192 168.409481 394.952163 191.892798 394.952163 191.892798 L 394.081109 208.556725 Z M 330.921528 208.556725 "
      transform="matrix(0.237679,0,0,0.380922,63.84293,94.970217)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:4.2164;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.927877 208.568077 L 312.49618 188.109903 C 318.640079 182.811629 330.050177 191.904151 330.050177 191.904151 C 330.050177 191.904151 336.177198 168.420833 362.508194 158.559207 C 388.822311 168.420833 394.949331 191.904151 394.949331 191.904151 C 394.949331 191.904151 406.359429 182.811629 412.503328 188.109903 L 394.071631 208.568077 Z M 330.927877 208.568077 "
      transform="matrix(0.231429,0,0,0.228553,66.109081,126.749067)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.2256;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 335.976273 226.280606 L 408.121521 226.280606 C 411.679531 226.280606 414.570078 229.16577 414.570078 232.729163 C 414.570078 236.292556 411.679531 239.17772 408.121521 239.17772 L 335.976273 239.17772 C 332.418263 239.17772 329.527716 236.292556 329.527716 232.729163 C 329.527716 229.16577 332.418263 226.280606 335.976273 226.280606 Z M 335.976273 226.280606 "
      transform="matrix(0.725695,0,0,0.725695,-30,0.00016218)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.5789;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 353.243934 -212.600607 L 354.331253 -269.291927 C 354.331253 -269.291927 351.047764 -255.39003 341.208063 -255.39003 L 190.285994 -255.39003 C 180.446293 -255.39003 177.162804 -269.291927 177.162804 -269.291927 L 178.260889 -212.600607 Z M 353.243934 -212.600607 "
      transform="matrix(0.362848,0,0,0.544271,143.564548,285.423386)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:2.3995;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 510.496284 70.214744 L 510.899992 44.610182 C 510.899992 44.610182 534.463062 41.611112 531.19303 35.275576 C 527.922998 28.940041 499.58272 43.260601 499.58272 43.260601 L 386.248526 43.260601 C 386.248526 43.260601 357.908249 28.949413 354.638217 35.275576 C 351.368185 41.611112 374.931255 44.610182 374.931255 44.610182 L 375.334962 70.214744 Z M 510.496284 70.214744 "
      transform="matrix(0.290278,0,0,0.416796,111.423471,126.437911)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:2.1904;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 510.485028 70.220967 L 510.89995 44.607033 C 510.89995 44.607033 534.460777 41.607963 531.186259 35.281799 C 527.922956 28.946264 499.584921 43.266824 499.584921 43.266824 L 386.243998 43.266824 C 386.243998 43.266824 357.905964 28.946264 354.64266 35.281799 C 351.368143 41.607963 374.92897 44.607033 374.92897 44.607033 L 375.332678 70.220967 Z M 510.485028 70.220967 "
      transform="matrix(0.348334,0,0,0.416796,85.712086,135.146255)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:6.2465;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 510.508408 70.22551 L 510.881491 44.616843 C 510.881491 44.616843 534.468672 41.606484 531.193826 35.276484 C 527.91898 28.946483 503.917262 16.925667 469.386293 4.203809 C 445.094399 -1.713814 500.642416 -22.147138 448.327791 -23.507986 C 400.282902 -22.745086 442.897351 -0.188016 427.227836 5.11104 C 388.385677 16.925667 357.917175 28.946483 354.642329 35.276484 C 351.367484 41.606484 374.91321 44.616843 374.91321 44.616843 L 375.327748 70.22551 Z M 510.508408 70.22551 "
      transform="matrix(0.0942315,0,0,0.18945,197.991665,97.301245)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:3.152;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 510.489877 70.220368 L 510.907853 44.607773 C 510.907853 44.607773 534.46649 41.606655 531.17968 35.283688 C 527.911869 28.949267 499.584508 43.267579 499.584508 43.267579 L 386.237063 43.267579 C 386.237063 43.267579 357.909702 28.949267 354.641891 35.283688 C 351.37408 41.606655 374.932717 44.607773 374.932717 44.607773 L 375.331694 70.220368 Z M 510.489877 70.220368 "
      transform="matrix(0.205604,0,0,0.341019,148.920148,113.752757)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:2.8773;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 510.486143 70.216474 L 510.897767 44.615333 C 510.897767 44.615333 534.455296 41.614215 531.193971 35.279794 C 527.916815 28.945373 499.578121 43.263685 499.578121 43.263685 L 386.239177 43.263685 C 386.239177 43.263685 357.916315 28.945373 354.639158 35.279794 C 351.377834 41.614215 374.935363 44.615333 374.935363 44.615333 L 375.331155 70.216474 Z M 510.486143 70.216474 "
      transform="matrix(0.246736,0,0,0.341019,130.712452,120.879085)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:4.7552;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 510.485915 70.212209 L 510.89697 44.604076 C 510.89697 44.604076 534.444565 41.618148 531.185484 35.275159 C 527.926403 28.949039 499.59295 43.271374 499.59295 43.271374 L 386.229781 43.271374 C 386.229781 43.271374 357.896329 28.949039 354.637248 35.275159 C 351.378167 41.618148 374.925761 44.604076 374.925761 44.604076 L 375.336816 70.212209 Z M 510.485915 70.212209 "
      transform="matrix(0.133042,0,0,0.231555,181.06845,108.484209)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:4.3408;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 510.494723 70.226729 L 510.910665 44.601727 C 510.910665 44.601727 534.448044 41.615799 531.193916 35.272809 C 527.91532 28.94669 499.582383 43.269025 499.582383 43.269025 L 386.250635 43.269025 C 386.250635 43.269025 357.917698 28.94669 354.639103 35.272809 C 351.360507 41.615799 374.922354 44.601727 374.922354 44.601727 L 375.338295 70.226729 Z M 510.494723 70.226729 "
      transform="matrix(0.159653,0,0,0.231555,169.283158,113.324597)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:6.2465;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 510.471925 70.226859 L 510.886462 44.618191 C 510.886462 44.618191 534.473643 41.607833 531.198797 35.277832 C 527.923951 28.947831 499.56959 43.257344 499.56959 43.257344 L 386.235054 43.257344 C 386.235054 43.257344 357.922146 28.947831 354.647301 35.277832 C 351.372455 41.607833 374.918182 44.618191 374.918182 44.618191 L 375.332719 70.226859 Z M 510.471925 70.226859 "
      transform="matrix(0.0942315,0,0,0.18945,198.252915,101.437708)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:5.7021;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 510.494305 70.219686 L 510.908816 44.611019 C 510.908816 44.611019 534.466849 41.621279 531.185305 35.270659 C 527.903761 28.940658 499.578853 43.27079 499.578853 43.27079 L 386.244681 43.27079 C 386.244681 43.27079 357.919774 28.940658 354.638229 35.270659 C 351.356685 41.621279 374.914718 44.611019 374.914718 44.611019 L 375.329229 70.219686 Z M 510.494305 70.219686 "
      transform="matrix(0.113085,0,0,0.18945,189.907419,105.400004)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:5.4435;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.914741 208.56577 L 312.503285 188.10834 C 318.640437 182.814531 330.033665 191.906722 330.033665 191.906722 C 330.033665 191.906722 336.170817 168.428531 362.511961 158.558718 C 388.822723 168.428531 394.959875 191.906722 394.959875 191.906722 C 394.959875 191.906722 406.353104 182.814531 412.490256 188.10834 L 394.078799 208.56577 Z M 330.914741 208.56577 "
      transform="matrix(0.128571,0,0,0.130607,193.383503,91.681813)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:5.4435;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.926495 208.553667 L 312.501423 188.096237 C 318.643113 182.802428 330.036709 191.89462 330.036709 191.89462 C 330.036709 191.89462 336.1784 168.416429 362.503032 158.576524 C 388.805961 168.416429 394.947652 191.89462 394.947652 191.89462 C 394.947652 191.89462 406.36295 182.802428 412.504641 188.096237 L 394.079569 208.553667 Z M 330.926495 208.553667 "
      transform="matrix(0.179994,0,0,0.130607,174.747648,96.034956)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:4.4097;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.927467 208.552627 L 312.507635 188.117148 C 318.641249 182.800783 330.03496 191.908142 330.03496 191.908142 C 330.03496 191.908142 336.187564 168.421922 362.507035 158.574309 C 388.807517 168.421922 394.960121 191.908142 394.960121 191.908142 C 394.960121 191.908142 406.353831 182.800783 412.506435 188.117148 L 394.086603 208.575059 L 330.927467 208.575059 Z M 330.927467 208.552627 "
      transform="matrix(0.205706,0,0,0.174138,165.422463,94.569779)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:3.5278;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.92165 208.566643 L 312.494482 188.10902 C 318.646999 182.815162 330.040548 191.895475 330.040548 191.895475 C 330.040548 191.895475 336.177874 168.405099 362.50457 158.571084 C 388.816074 168.405099 394.9534 191.895475 394.9534 191.895475 C 394.9534 191.895475 406.362141 182.815162 412.499466 188.10902 L 394.072298 208.566643 Z M 330.92165 208.566643 "
      transform="matrix(0.257136,0,0,0.217676,146.77935,94.846227)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.4582;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 358.178078 217.126108 L 371.648412 240.107383 L 385.917635 217.126108 Z M 358.178078 217.126108 "
      transform="matrix(0.669875,0,0,0.489019,-9.231354,41.766508)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:3.0506;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.917771 208.563585 L 312.498372 188.112902 C 318.638172 182.815048 330.044274 191.897083 330.044274 191.897083 C 330.044274 191.897083 336.184074 168.419055 362.502926 158.564081 C 388.821779 168.419055 394.961578 191.897083 394.961578 191.897083 C 394.961578 191.897083 406.355021 182.815048 412.494821 188.112902 L 394.075422 208.563585 Z M 330.917771 208.563585 "
      transform="matrix(0.308566,0,0,0.242581,128.136238,98.597941)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 358.180352 217.129583 L 371.653422 240.109391 L 385.912377 217.129583 Z M 358.180352 217.129583 "
      transform="matrix(0.725695,0,0,0.61433,-30.000115,26.915447)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:2.615;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.920703 208.56638 L 312.504092 188.108675 C 318.639067 182.801977 330.044278 191.902836 330.044278 191.902836 C 330.044278 191.902836 336.179253 168.420057 362.495374 158.562929 C 388.811496 168.420057 394.958156 191.902836 394.958156 191.902836 C 394.958156 191.902836 406.363367 182.801977 412.498342 188.108675 L 394.081731 208.56638 Z M 330.920703 208.56638 "
      transform="matrix(0.334277,0,0,0.304745,118.81831,98.323336)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:2.615;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 330.920703 208.565561 L 312.504092 188.107856 C 318.639067 182.801158 330.044278 191.902017 330.044278 191.902017 C 330.044278 191.902017 336.179253 168.419237 362.495374 158.56211 C 388.811496 168.419237 394.958156 191.902017 394.958156 191.902017 C 394.958156 191.902017 406.363367 182.801158 412.498342 188.107856 L 394.081731 208.565561 Z M 330.920703 208.565561 "
      transform="matrix(0.334277,0,0,0.304745,118.81831,110.858742)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.0398;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 285.340568 -90.068578 L 291.589962 -90.068578 L 291.589962 -63.084758 L 285.340568 -63.084758 Z M 285.340568 -90.068578 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.0398;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 306.591739 -90.073961 L 312.841133 -90.073961 L 312.841133 -63.084758 L 306.591739 -63.084758 Z M 306.591739 -90.073961 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.0398;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 296.590554 -90.073961 L 302.839949 -90.073961 L 302.839949 -63.084758 L 296.590554 -63.084758 Z M 296.590554 -90.073961 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.0398;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 410.14006 -90.068578 L 416.389454 -90.068578 L 416.389454 -63.084758 L 410.14006 -63.084758 Z M 410.14006 -90.068578 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.0398;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 452.642401 -90.073961 L 458.891796 -90.073961 L 458.891796 -63.084758 L 452.642401 -63.084758 Z M 452.642401 -90.073961 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.0398;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 431.39123 -90.073961 L 437.640625 -90.073961 L 437.640625 -63.090141 L 431.39123 -63.090141 Z M 431.39123 -90.073961 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.0398;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 421.390046 -90.073961 L 427.639441 -90.073961 L 427.639441 -63.090141 L 421.390046 -63.090141 Z M 421.390046 -90.073961 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.0398;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 441.392415 -90.073961 L 447.641809 -90.073961 L 447.641809 -63.090141 L 441.392415 -63.090141 Z M 441.392415 -90.073961 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.0398;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 327.837527 -90.068578 L 334.092304 -90.068578 L 334.092304 -63.084758 L 327.837527 -63.084758 Z M 327.837527 -90.068578 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.0398;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(90.196078%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 316.58754 -90.073961 L 322.842317 -90.073961 L 322.842317 -63.084758 L 316.58754 -63.084758 Z M 316.58754 -90.073961 "
      transform="matrix(0.725695,0,0,0.725695,-30,257.135782)"
    />
  </g>
</svg>
