import { SampleApi } from "apps/sample/api";
import { activities } from "apps/sample/stores";

class ActivitiesService {
  constructor() {}

  getActivities({ params = {} }) {
    SampleApi.getActivities({
      params,
      success: (data) => {
        activities.set({ ...data });
      },
    });
  }
}

export default new ActivitiesService();
