<script>
  import {
    ConfirmationDetailBar,
    ConfirmationRemindSigners,
  } from "apps/confirmation";
  import {
    ConfirmationItemModal,
    ConfirmationResponseModal,
  } from "components/modals";
  import { activeRemindSignersId } from "stores/confirmation/confirmation.js";
  import {
    item,
    itemId,
    getItem,
    letterhead,
    confirmationId,
    activeEditListItem,
    statusesPossibleDelete,
  } from "stores/confirmation/confirmation_item.js";
  import { companies } from "stores/confirmation/confirmation_new.js";
  import { t } from "stores/i18n.js";
  import { can } from "stores/permission.js";
  import { toasts } from "stores/toasts.js";
  import { isUserTeamUser } from "stores/user.js";

  import ConfirmationItemsApi from "apis/confirmation/confirmation_items.js";
  import ConfirmationMailingsApi from "apis/confirmation/confirmation_mailings.js";
  import { dateTimeLogFormat, iconPath } from "lib/helpers.js";
  import { validate } from "lib/validate.js";

  import {
    Box,
    Button,
    Checkbox,
    Options,
    Switch,
    Toast,
    Tooltip,
  } from "components";
  import styles from "styleguide/ConfirmationDetail.json";

  let shownCustomResponse = false;

  function checkValid(_item) {
    if (!_item.company) return false;
    if (!_item.street) return false;
    if (!_item.city) return false;
    if (!_item.country) return false;
    if (!_item.confirmation_type) return false;
    if (
      validate(_item.zip, ["required", "zip"], { country: _item.country })
        .length
    )
      return false;
    if (
      validate(_item.email, ["email"]) &&
      validate(_item.email, ["email"]).length
    )
      return false;
    return true;
  }

  function edit() {
    activeEditListItem.set(true);
  }

  function isEditable(item) {
    return (
      item.status !== "ready_to_send" && item.status !== "waiting_signatures"
    );
  }

  function send(sent_via) {
    ConfirmationMailingsApi.create({
      params: {
        sent_via,
        confirmation_item_id: $item.permalink,
      },
      success: () => {
        getItem();
      },
      error: () => {
        toasts.send({ title: $t("errors.check_entries"), type: "error" });
      },
    });
  }

  function sendViaPostal() {
    send("postal");
  }

  function sendViaEmail() {
    send("email");
  }

  function onDelete() {
    const isConfirmed = confirm($t("questions.sure"));

    if (isConfirmed) {
      ConfirmationItemsApi.delete({
        params: {
          id: $item.permalink,
        },
        success: (response) => {
          window.location = response.data.url;
        },
      });
    }
  }

  function validateAddress(value) {
    ConfirmationItemsApi.validateAddress({
      params: {
        id: $item.permalink,
        address_validated: value,
      },
    });
  }

  function onUpdateCompanies(companies) {
    if (!checkValid($item)) return;

    let params = { ...$item };
    params.id = $item.permalink;
    params.companies =
      companies && companies.length ? companies.filter((item) => item) : null;
    params.confirmation_signers = $item.authorized_signers;

    ConfirmationItemsApi.edit({
      params,
      success: getItem,
      error: () => {
        toasts.send({ title: $t("errors.check_entries"), type: "error" });
      },
    });
  }

  function onAccept(name) {
    return (checked) => {
      const updatedCompanies = [...$companies].map((company) => {
        if (company.name === name) {
          return {
            ...company,
            accepted: checked.toString(),
          };
        }

        return company;
      });

      companies.set(updatedCompanies);

      onUpdateCompanies(updatedCompanies);
    };
  }

  function getSignerDetails(signer) {
    let icon, translation;

    if (signer.signed_confimation_item_at) {
      icon = "success_filled";
      translation = "signed_tooltip";
    } else if (signer.notified_at) {
      icon = "warning_filled";
      translation = "waiting_for_signature_tooltip";
    } else {
      icon = "warning_filled_grey";
      translation = "added_tooltip";
    }

    return { icon, translation };
  }
</script>

<Box style="margin-bottom">
  <div class={styles.wrapper} data-component="ConfirmationDetail">
    <div class={styles.half}>
      <div class={styles.box}>
        <div class={styles.title}>{$t("confirmation_detail.company")}</div>
        <div class={styles.bold}>{$item.company}</div>
      </div>
      <div
        class={`${styles.box} ${$item.address_validated ? styles.valid : ""}`}
      >
        <div class={styles.title}>{$t("confirmation_detail.address")}</div>
        <div>{$item.street}</div>
        <div>{$item.zip} {$item.city}</div>
        <div>{$item.country}</div>
        {#if $item.email}
          <div class={!$item.is_valid_email ? styles.invalid : null}>
            {$item.email}
          </div>
        {/if}
        {#if $isUserTeamUser}
          <div class={styles.row}>
            <Checkbox
              toggle={validateAddress}
              bind:toggled={$item.address_validated}
              label={$t("confirmation_detail.validate_address")}
            />
          </div>
        {/if}
      </div>
      <div class={styles.box}>
        <div class={styles.title}>{$t("confirmation_detail.language")}</div>
        <div>
          {$t(
            `confirmation.language.cover_letter_in_${$item.mailing_language}`,
          )}
        </div>
        {#if $item.contact}
          <div class={styles.title}>{$t("confirmation_detail.contact")}</div>
          <div>{$item.contact}</div>
        {/if}
      </div>
    </div>
    <div class={styles.half}>
      <div class={styles.title}>
        {$t("confirmation_detail.authorised_signers")}
      </div>
      <div class={styles.signers}>
        {#if $item.authorized_signers && $item.authorized_signers.length > 0}
          {#each $item.authorized_signers as signer}
            <div class={styles.signer}>
              <div>
                <Tooltip
                  showArrow
                  message={$t(
                    `confirmation_detail.${getSignerDetails(signer).translation}`,
                  )}
                >
                  <img
                    class={styles.icon}
                    src={iconPath(getSignerDetails(signer).icon)}
                    alt={getSignerDetails(signer).icon}
                  />
                </Tooltip>
              </div>
              <div class={styles.email}>
                {signer.email}
                {#if signer.notified_at !== null && !signer.signed_confimation_item_at}
                  <div class={styles.notified}>
                    <span>{$t("confirmation_detail.last_reminded")}:</span>
                    <span>{dateTimeLogFormat(signer.notified_at)}</span>
                  </div>
                {/if}
              </div>
            </div>
          {/each}
        {:else}
          <Toast
            type="error"
            message={$t("confirmation_detail.no_signers_present")}
          />
        {/if}
      </div>

      <div class={styles.title}>{$t("confirmation_detail.companies")}</div>
      <div class={styles.companies}>
        {#if $companies && $companies.length > 0}
          <div class={styles["companies-list-head"]}>
            <div class={styles["companies-list-head-item"]}>
              {$t("confirmation_detail.company_name")}
            </div>
            {#if $isUserTeamUser}
              <div class={styles["companies-list-head-item"]}>
                {$t("confirmation_detail.accepted")}
                <Tooltip
                  showArrow
                  message={$t("confirmation_detail.accepted_info")}
                >
                  <img src={iconPath("question_circle")} alt="" />
                </Tooltip>
              </div>
            {/if}
          </div>

          <div class={styles["companies-list"]}>
            {#each $companies as company, index (company)}
              <div class={styles["companies-list-item"]}>
                <span class={styles["company-name"]}>
                  <img
                    src={iconPath(
                      company["accepted"] === "true"
                        ? "checkmark_round_green_reverse"
                        : "question_circle",
                    )}
                    alt=""
                  />
                  {company["name"]}
                </span>
                <div class={styles.toggler}>
                  {#if $isUserTeamUser}
                    <Switch
                      toggled={company["accepted"] === "true"}
                      toggle={onAccept(company["name"])}
                    />
                  {/if}
                </div>
              </div>
            {/each}
          </div>
        {:else}
          <div class={styles["companies-toast"]}>
            <Toast
              type="warning"
              message={$t("confirmation_detail.no_companies_present")}
            />
          </div>
        {/if}
      </div>
    </div>
    <div class={styles.bottom}>
      <div class={styles["bottom-section"]}>
        {#if $isUserTeamUser}
          {#if $can("create", "confirmation_mailing")}
            <Options
              style="acts-as-button-tonal no-border"
              title={$t("confirmation_detail.new_request")}
              isLeft={true}
            >
              <Button style="blue-text option" click={sendViaPostal}
                >{$t("confirmation_detail.send_via_postal")}</Button
              >
              <Button
                style="blue-text option"
                disabled={!$item.email}
                click={sendViaEmail}
                >{$t("confirmation_detail.send_via_email")}</Button
              >
            </Options>
          {/if}

          {#if $can("create", "confirmation_response")}
            <Button
              style="primary-text"
              click={() => {
                shownCustomResponse = true;
              }}>{$t("confirmation_detail.upload_response")}</Button
            >
          {/if}

          {#if $can("remind_signers", "confirmation_item") && $item.status === "waiting_signatures"}
            <Button
              style="primary-text"
              click={() =>
                activeRemindSignersId.set({
                  confirmation_id: $confirmationId,
                  confirmation_item_id: $itemId,
                })}>{$t("confirmation_detail.remind_signers")}</Button
            >
          {/if}
        {/if}

        {#if isEditable($item)}
          <Button
            disabled={$item.address_validated}
            click={edit}
            style="primary-text">{$t("confirmation_detail.edit")}</Button
          >
        {:else}
          <Tooltip
            style="small"
            nowrap={false}
            message={$t("confirmation_detail.can_not_edit")}
          >
            <Button disabled style="primary-text"
              >{$t("confirmation_detail.edit")}</Button
            >
          </Tooltip>
        {/if}
      </div>
      <div class={styles["bottom-section"]}>
        {#if $isUserTeamUser}
          {#if $can("destroy", "confirmation_item") && statusesPossibleDelete.includes($item.status)}
            <Button click={onDelete} style="error-text"
              >{$t("confirmation_detail.delete_item")}</Button
            >
          {/if}
        {/if}
      </div>
    </div>
  </div>

  <ConfirmationResponseModal bind:shown={shownCustomResponse} />
  <ConfirmationRemindSigners />
</Box>

<ConfirmationDetailBar />

{#if $activeEditListItem}
  <ConfirmationItemModal
    data={$item}
    action={"edit"}
    hide={() => {
      activeEditListItem.set(false);
    }}
    bind:shown={$activeEditListItem}
    success={getItem}
  />
{/if}

<style lang="scss">
  .wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .title {
    color: var(--primary-300);

    &:not(:first-child) {
      margin-top: 5px;
    }

    .valid & {
      color: var(--green-400);
    }
  }

  .signers {
    margin: 6px 20px 20px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .half {
    width: 50%;
    padding: 15px 20px 40px;

    &:nth-child(2) {
      border-left: 1px solid var(--primary-050);
      display: flex;
      flex-direction: column;
      padding: 15px 0 0 20px;
    }
  }

  .button {
    margin-right: 10px;
  }

  .bottom {
    display: flex;
    width: 100%;
    padding: 10px 20px;
    border-top: 1px solid var(--primary-050);
    align-items: center;
    justify-content: space-between;
  }

  .bottom-section {
    display: flex;
    gap: 8px;
  }

  .bold {
    font-weight: 600;
  }

  .box {
    &:last-child {
      padding-top: 10px;
    }

    &:not(:last-child) {
      padding: 10px 20px 15px;
      margin: 0 -20px;
      border-bottom: 1px solid var(--primary-050);
    }

    &:first-child {
      padding-top: 0;
    }

    &.valid {
      background: var(--green-010);
    }
  }

  .row {
    display: flex;
    align-items: center;
    height: 22px;
    margin-top: 10px;
  }

  .companies {
    flex: 1;
  }

  .invalid {
    color: var(--red-200);
  }
  .signer {
    display: flex;
    align-items: flex-start;

    & > div:first-child {
      margin-top: 2px;
    }
  }

  .email {
    font-weight: 500;
    margin-left: 8px;
  }

  .notified {
    font-weight: 400;
  }

  .companies-list {
    max-height: 180px;
    overflow-y: auto;

    &-head {
      margin-top: 8px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--primary-050);

      &-item {
        padding: 0 20px 4px 0;
        font-weight: 400;
        flex: 1;
        display: flex;
        gap: 6px;
        & + & {
          flex: none;
          text-align: left;
        }
      }
    }

    &-item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--primary-050);
      padding: 4px 0;
    }

    &-item:last-child {
      border-bottom: none;
    }

    .company-name {
      display: flex;
      align-items: center;
      gap: 8px;
      flex: 1;
    }
    .toggler {
      margin-top: 8px;
      padding-right: 20px;
      min-height: 28px;
    }
  }

  .companies-toast {
    margin: 20px 20px 0 0;
  }
</style>
