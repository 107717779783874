<script>
  export let width = 18;
  export let height = 18;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 18 19"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.9996 0.42627C4.0296 0.42627 -0.000396729 4.45627 -0.000396729 9.42627C-0.000396729 14.3973 4.0296 18.4263 8.9996 18.4263C13.9706 18.4263 17.9996 14.3973 17.9996 9.42627C17.9996 4.45627 13.9706 0.42627 8.9996 0.42627Z"
    {fill}
  />
</svg>
