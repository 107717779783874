<script>
  import { getContext, onDestroy, onMount } from "svelte";

  import { account } from "stores/account.js";
  import { t } from "stores/i18n.js";
  import { setModalsContentToStorage } from "stores/modals";
  import { setPageHistoryToStorage } from "stores/page_history.js";
  import { theme } from "stores/theme.js";
  import { user, isUserTeamUser } from "stores/user.js";
  import { platformLink, csrfToken } from "lib/helpers.js";

  import { Button, LegacyButton, Options, UserPicture } from "components";
  import styles from "styleguide/MainNavigation.json";

  export let showActions = true;
  let isUserDropdownVisible = false;
  let wasOpenIntent = false;
  let isAvatarActive;

  const config = getContext("config");

  onMount(async () => {
    document.addEventListener("click", onDocumentClick);
  });

  onDestroy(() => {
    document.removeEventListener("click", onDocumentClick);
  });

  function onDocumentClick() {
    if (!wasOpenIntent) {
      isUserDropdownVisible = false;
    }
  }

  function toggleUserDropdown() {
    isUserDropdownVisible = !isUserDropdownVisible;
    wasOpenIntent = true;
    setTimeout(() => {
      wasOpenIntent = false;
    }, 500);
  }

  function logout(event) {
    event.preventDefault();
    fetch("/logins/sign_out", {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": csrfToken(),
      },
    }).then((res) => {
      res.json();
      setPageHistoryToStorage(null);
      setModalsContentToStorage(null);
      window.location.href = "/login";
    });
  }
</script>

<div data-svelte-component="MainNavigation" class={styles.mainnavigation}>
  <div
    class={styles.account}
    on:click={() => (window.location.href = platformLink("dashboard"))}
  >
    {#if $account.has_custom_logo}
      <div class={styles["account-logo-wrapper"]}>
        <img
          src={$account.logo}
          alt={$account.official_name}
          class={styles["account-logo"]}
        />
      </div>
    {/if}
    <div class={styles.names}>
      {#if !$account.has_custom_logo}
        <img
          src={$account.default_logo}
          alt="Auditi"
          class={styles["auditi-logo"]}
        />
      {/if}
      <div class={styles["company-name"]}>{$account.official_name}</div>
    </div>
  </div>
  {#if showActions}
    <div class={styles.actions}>
      <LegacyButton style="main-nav" link={platformLink("dashboard")}>
        {$t("mainnavigation.dashboard")}
      </LegacyButton>
      {#if $isUserTeamUser}
        <LegacyButton style="main-nav" link={platformLink("account")}>
          {$t("mainnavigation.administration")}
        </LegacyButton>
      {/if}
      {#if ["aude", "eyde", "rpde", "sode"].includes($theme) && config.language == "de"}
        <LegacyButton style="main-nav" link={$account.help_link}>
          {$t("mainnavigation.help")}
        </LegacyButton>
      {/if}
      <div>
        <Options style={"left"} bind:show={isAvatarActive}>
          <div slot="avatar">
            <UserPicture
              avatar={$user.avatar}
              name={$user.name}
              isUserMenu="true"
              bind:active={isAvatarActive}
            />
          </div>
          {#if $isUserTeamUser || $user.has_multiple_users}
            {#if $isUserTeamUser}
              <Button
                link={platformLink(`team_users/${$user.permalink}/edit`)}
                style="option blue-text"
              >
                {$t("mainnavigation.settings")}
              </Button>
            {/if}
            {#if $user.has_multiple_users}
              <Button link={"/a/start"} style="option blue-text">
                {$t("mainnavigation.switch_profile")}
              </Button>
            {/if}
          {/if}
          <Button click={logout} style="option error-text">
            {$t("mainnavigation.logout")}
          </Button>
        </Options>
      </div>
    </div>
  {/if}
</div>

<style lang="scss">
  .mainnavigation {
    display: flex;
    justify-content: space-between;
    height: 80px;
    background: var(--header-background-color);
    z-index: var(--z-index-navigation);
    padding: 0 var(--page-gutter);
  }
  .account {
    line-height: 20px;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  }
  .auditi-logo {
    width: 46px;
    height: 14px;
  }

  .account-logo-wrapper {
    height: 80px;
    align-items: var(--logo-position);
    display: flex;
  }
  .account-logo {
    max-height: var(--logo-height);
    margin-right: 20px;
  }

  .names {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
  }
  .company-name {
    display: var(--account-name-display);
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: right;
    gap: 16px;
  }
</style>
