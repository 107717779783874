// Modal managing with page scrolling and scroll position
// When modal comes from backend, it's with show class already (on demand load)
// When there's only render data - toggle modal, instead of removing
import { Controller } from "stimulus";
import ClassWatcher from "../general/class_watcher";

const modalElements = [];

export default class extends Controller {
  static targets = ["edit", "button", "close"];

  connect() {
    if (this.element.classList.contains("show")) {
      this.hideMainPageScroll();
      this.hideModalScrollIfNewModal();
    } else {
      this.showObserver = new ClassWatcher(
        this.element,
        "show",
        this.onModalShow.bind(this),
        this.onModalHide.bind(this),
      );
    }
    modalElements.push(this.element);
    this.closeModalByClickingClose();
    this.closeModalByClickingOutsideEvent();
  }

  disconnect() {
    document.querySelector("html").classList.remove("no-scroll");
    document.querySelector("body").classList.remove("no-scroll");
    this.removeModalFromModalStack();
    this.showPreviousModalScrollIfPresent();
    this.showMainPageScrollIfNoModals();
  }

  closeModalByClickingOutsideEvent() {
    let self = this;
    this.element.addEventListener("click", (e) => {
      if (e.target == self.element) {
        self.removeModal();

        self.closeModalWithReload();
      }
    });
  }

  closeModalByClickingClose() {
    let self = this;
    if (this.hasCloseTarget) {
      this.closeTarget.addEventListener("click", (e) => {
        e.preventDefault();
        self.removeModal();

        self.closeModalWithReload();
      });
    }
  }

  removeModal() {
    let changeUrl = this.data.get("url"),
      hideAttr = this.data.get("hide");

    if (changeUrl) {
      Turbolinks.visit(changeUrl, { action: "advance" });
    } else if (hideAttr) {
      this.element.classList.remove("show");
    } else {
      this.element.remove();
    }
  }

  onModalShow() {
    this.hideMainPageScroll();
    this.hideModalScrollIfNewModal();
  }

  onModalHide() {
    this.removeModalFromModalStack();
    this.showPreviousModalScrollIfPresent();
    this.showMainPageScrollIfNoModals();
  }

  hideMainPageScroll() {
    document.querySelector("body").classList.add("hide-body-scroll");
  }

  hideModalScrollIfNewModal() {
    if (modalElements.length > 1) {
      modalElements[modalElements.length - 2].classList.add("hide-body-scroll");
    }
  }

  removeModalFromModalStack() {
    modalElements.pop();
  }

  showPreviousModalScrollIfPresent() {
    if (modalElements.length) {
      modalElements[modalElements.length - 1].classList.remove(
        "hide-body-scroll",
      );
    }
  }

  showMainPageScrollIfNoModals() {
    if (!document.querySelectorAll(".modal.show").length) {
      document.querySelector("body").classList.remove("hide-body-scroll");
    }
  }

  closeModalWithReload() {
    if (this.element.dataset.reloadOnClose) {
      window.location.reload();
    }
  }
}
