import { Controller } from "stimulus";
import Api from "../general/api";
import Helper from "../general/helper";

export default class extends Controller {
  static targets = [
    "commentForm",
    "commentEditor",
    "commentList",
    "fileUploadItem",
    "fileList",
    "inputSection",
  ];

  showFormClick(e) {
    e.preventDefault();
    let editor = document.querySelector("[data-sidebar-editor]");
    let editorContent = document.querySelector("[data-editor]");
    if (editor) editor.classList.remove("hidden");
    setTimeout(() => {
      if (editorContent) editorContent.focus();
    }, 100);
    this.commentFormTarget.style.display = "block";
    this.commentEditorTarget.focus();
    this.inputSectionTarget.classList.add("hidden");
  }

  cancelCommentForm(e) {
    e.preventDefault();
    this.hideCommentForm();
    let editor = document.querySelector("[data-sidebar-editor]");
    let editorContent = document.querySelector("[data-editor]");
    if (editor) editor.classList.add("hidden");
    if (editorContent) editorContent.innerHTML = "";
  }

  postComment(e) {
    e.preventDefault();
    let mentions = {};
    let link = e.target.closest(".sidebar-comment-form--post");
    let editorContent = document.querySelector("[data-editor]");
    let userElements = [
      ...editorContent.querySelectorAll("[data-mentioned-user]"),
    ];

    let teamUsers = [];
    let clientUsers = [];

    userElements.forEach((item) => {
      let userId = item.dataset.mentionedUser;
      let userType = item.dataset.mentionUserType;

      if (userType === "team_users") {
        teamUsers.push(userId);
      } else {
        clientUsers.push(userId);
      }

      mentions.team_users = [...new Set(teamUsers)];
      mentions.client_users = [...new Set(clientUsers)];
    });

    Api.post(
      link.href,
      {
        parent_type: this.data.get("record"),
        parent_id: this.data.get("id"),
        comment: {
          content: editorContent.innerHTML,
          attachments: this.fileUploadItemTarget.files,
          mentions,
        },
      },
      this.commentResponse.bind(this),
    );
  }

  commentResponse(response) {
    let newNode = document.createElement("div");
    if (response["template"]) {
      newNode.innerHTML = response["template"];
      let editor = document.querySelector("[data-sidebar-editor]");
      let editorContent = document.querySelector("[data-editor]");
      this.commentListTarget.prepend(newNode.children[0]);
      this.hideCommentForm();
      editorContent.innerHTML = "";
      editor.classList.add("hidden");
    }
  }

  fileUploadDialog(e) {
    e.preventDefault();
    this.fileUploadItemTarget.click();
  }

  fileChangeEvent(e) {
    e.preventDefault();
    let self = this;
    self.fileListTarget.innerHTML = "";

    Array.from(this.fileUploadItemTarget.files).forEach((file) => {
      self.fileListTarget.appendChild(self.fileItemBlock(file.name));
    });
  }

  fileItemBlock(filename) {
    let node = document.createElement("div");
    node.innerHTML = `
      <div class="add-files-block--file-name-item">
        <div class="add-files-block--icon"></div>
        <span>${Helper.escapeHtml(filename)}</span>
      </div>
    `;
    return node.children[0];
  }

  hideCommentForm() {
    this.commentFormTarget.style.display = "none";
    this.commentEditorTarget.value = "";
    this.fileListTarget.innerHTML = "";
    this.fileUploadItemTarget.value = "";
    this.inputSectionTarget.classList.remove("hidden");
  }
}
