<script>
  import { Modal } from "components/modals";
  import { t } from "stores/i18n.js";
  import { Button, TextInput } from "components";
  import styles from "styleguide/DeleteModal.json";

  export let shown;
  export let title;
  export let description;
  export let inputValue;
  export let btnTitle;

  export let onDelete;

  function onHide() {
    shown = !shown;
  }
</script>

<Modal {shown} {onHide} {title} style="error">
  <div class={styles.wrapper} data-component="DeleteModal">
    {#if description}
      <p>{@html description}</p>
    {/if}

    <div class={styles.actions}>
      <div class={styles["actions-cancel"]}>
        <Button style="primary-text" click={onHide}
          >{$t("delete_modal.cancel")}</Button
        >
      </div>
      <div class={styles["actions-confirm"]}>
        {#if inputValue}
          <div class={styles.input}>
            <TextInput
              placeholder={$t("delete_modal.delete_placeholder")}
              bind:value={inputValue}
              style="border medium round-border"
            />
          </div>
        {/if}

        <Button style={"error"} click={onDelete}>{btnTitle}</Button>
      </div>
    </div>
  </div>
</Modal>

<style lang="scss">
  .wrapper {
    padding-bottom: 16px;
  }

  .description {
    color: var(--primary-500);
    margin-bottom: 16px;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    &-confirm {
      display: flex;
    }
  }

  .input {
    width: 150px;
    margin-right: 12px;
  }
  p {
    margin-top: 0;
  }
</style>
