<script>
  import styles from "styleguide/FrontendHeader.json";
  import { LanguageSwitcher, LegacyButton, Logo } from "components";
</script>

<div data-component="FrontendHeader" class={styles.header}>
  <Logo />

  <div class={styles.buttons}>
    <a class={styles.login} href="/login">
      <LegacyButton style="main-nav public">Login</LegacyButton>
    </a>

    <LanguageSwitcher />
  </div>
</div>

<style lang="scss">
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 35px;
    border-bottom: solid 1px var(--primary-050);
    background: var(--header-background-color);
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }

  .buttons {
    display: flex;
  }

  .login {
    margin-right: 12px;
    text-decoration: none;
  }
</style>
