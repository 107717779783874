<script>
  import styles from "styleguide/ConfirmationResponseModal.json";
  import { toasts } from "stores/toasts.js";
  import { t } from "stores/i18n.js";
  import { itemId, getItem } from "stores/confirmation/confirmation_item.js";

  import ConfirmationResponsesApi from "apis/confirmation/confirmation_responses.js";

  import { Dropzone } from "components";
  import { Modal } from "components/modals";

  export let shown = false;

  let dropzone;
  let loadingState = false;

  function onAddLetterhead(files) {
    loadingState = true;

    ConfirmationResponsesApi.upload({
      params: {
        confirmation_item_id: $itemId,
        files: [...files],
      },
      success: () => {
        loadingState = false;
        shown = false;
        getItem();
        toasts.send({
          title: $t("confirmation_response_modal.success_send"),
          type: "success",
        });
      },
      error: () => {
        loadingState = false;
        toasts.send({
          title: $t("confirmation_response_modal.error_send"),
          type: "error",
        });
      },
    });
  }

  function onHide() {
    shown = false;
  }
</script>

<Modal
  style="no-padding"
  {shown}
  {onHide}
  title={$t("confirmation_response_modal.title")}
>
  <div data-component="ConfirmationResponseModal">
    <div class={styles.container}>
      <div class={styles.description}>
        {@html $t("confirmation_response_modal.description")}
      </div>
    </div>

    <div class={styles.container}>
      <div class={styles.dropzone}>
        <Dropzone
          bind:this={dropzone}
          bind:loadingState
          onFiles={onAddLetterhead}
          accept="application/pdf"
        />
      </div>
    </div>
  </div>
</Modal>

<style lang="scss">
  .container {
    padding: 0 24px 8px 24px;

    .description {
      padding-top: 8px;
    }
  }

  .headline {
    font-weight: 500;
    margin-bottom: 5px;
  }

  .dropzone {
    padding: 8px 0;
  }

  .file-list {
    margin-top: 15px;
  }

  .items {
    display: flex;
  }

  .green-panel {
    color: var(--green-400);
    background: var(--green-010);
    padding: 15px 0;

    b {
      padding: 0 20px;
    }
  }

  .bottom-padding {
    margin-bottom: 15px;
  }
</style>
