import { Controller } from "stimulus";
import { t } from "stores/i18n.js";

export default class extends Controller {
  static targets = ["openLetterField", "hiddenCheckField"];

  connect() {
    this.reviewType = "balance";
  }

  setReviewType(e) {
    let reviewTypeTarget = e.target.closest(".review-type");
    this.reviewType = reviewTypeTarget.getAttribute("data-type");
    if (this.reviewType === "balance-open") {
      this.openLetterFieldTargets.forEach((field) => {
        field.classList.remove("hidden");
        field.querySelector(`input[type="checkbox"]`).checked = true;
      });
    } else {
      this.openLetterFieldTargets.forEach((field) => {
        field.classList.add("hidden");
        field.querySelector(`input[type="checkbox"]`).checked = false;
      });
    }

    this.hiddenCheckFieldTargets.forEach((field) => {
      field.querySelector(`input[type="checkbox"]`).checked =
        this.reviewType === "balance-open";
    });
  }

  resetSelects(e) {
    e.preventDefault();
    $(
      e.target
        .closest(".fragments")
        .querySelectorAll(".fragments-group .fragment select"),
    )
      .val("")
      .trigger("change");
  }

  submitHandler(e) {
    this.form = e.target.closest("#review-form");
    let isValid = this.validateForm(this.form);

    if (!isValid) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  validateForm() {
    let isValid = true;

    let requiredSelectors =
      "#review_client_id, #review_name, #review_user_id, #review_contact_id";
    let required = this.form.querySelectorAll(requiredSelectors);

    this.hideAllErrors();

    required.forEach((field) => {
      if (!field.value.trim()) {
        isValid = false;

        this.addRequiredMessage(field);
      }
    });

    return isValid;
  }

  addRequiredMessage(field) {
    let requiredBlock = this.form.querySelector(".inline-error");
    let requiredBlockCopy = requiredBlock.cloneNode(true);

    requiredBlockCopy.style.display = "block";
    field.closest("li").after(requiredBlockCopy);
  }

  hideAllErrors() {
    $(".inline-error").css("display", "none");
  }
}
