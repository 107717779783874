import Api from "apis/api";
import { buildFormData } from "lib/helpers.js";

class ConfirmationResponses {
  constructor() {
    this.endpoint = "/confirmation_responses";
  }

  upload(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params, null, true);

    Api.post(this.endpoint + "/upload", {
      body: formData,
      success,
      error,
    });
  }

  delete(options) {
    const { params } = options;
    Api.delete(
      this.endpoint +
        `/${params.id}?confirmation_item_id=${params.confirmation_item_id}`,
      options,
    );
  }
}

export default new ConfirmationResponses();
