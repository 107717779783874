<script>
  export let width = 28;
  export let height = 28;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 28 28"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M19.8034 0C19.9493 0 20.0951 0 20.2409 0C22.0238 0.207083 23.2251 1.18089 23.8448 2.92141C23.8532 2.94479 23.8685 2.96502 23.8887 2.97931C23.9088 2.99361 23.9329 3.00127 23.9575 3.00125C24.6677 3.00781 25.3889 3.01036 26.1209 3.00891C26.7896 3.00818 27.1483 3.01292 27.1972 3.02312C27.6157 3.115 27.8833 3.41286 28 3.91672C28 3.98089 28 4.04469 28 4.10813C27.8549 4.70531 27.4447 5.00391 26.7695 5.00391C25.8398 5.00391 24.8996 5.00391 23.9488 5.00391C23.923 5.00386 23.8978 5.01226 23.8771 5.02784C23.8564 5.04342 23.8412 5.06534 23.8339 5.09031C23.586 5.93031 23.112 6.61755 22.412 7.15203C21.7514 7.65661 21.0332 7.93224 20.2573 7.97891C19.5216 8.02339 18.8176 7.87135 18.1453 7.52281C17.201 7.03354 16.5437 6.2249 16.1733 5.09687C16.1643 5.06982 16.1471 5.04627 16.1242 5.02958C16.1012 5.01289 16.0736 5.00391 16.0453 5.00391C11.0454 5.00391 6.07979 5.00573 1.14844 5.00938C0.511146 5.0101 0.128333 4.70531 0 4.095C0 4.02208 0 3.94917 0 3.87625C0.124688 3.39937 0.388281 3.11609 0.790781 3.02641C0.846198 3.01401 1.19474 3.00781 1.83641 3.00781C6.58182 3.01073 11.3269 3.00854 16.0716 3.00125C16.0875 3.00125 16.103 2.99615 16.1158 2.9867C16.1285 2.97726 16.138 2.96395 16.1427 2.94875C16.7194 1.19073 17.9397 0.207813 19.8034 0ZM21.9931 3.99875C21.9931 3.46993 21.7831 2.96278 21.4091 2.58885C21.0352 2.21492 20.528 2.00484 19.9992 2.00484C19.4704 2.00484 18.9632 2.21492 18.5893 2.58885C18.2154 2.96278 18.0053 3.46993 18.0053 3.99875C18.0053 4.52757 18.2154 5.03472 18.5893 5.40865C18.9632 5.78258 19.4704 5.99266 19.9992 5.99266C20.528 5.99266 21.0352 5.78258 21.4091 5.40865C21.7831 5.03472 21.9931 4.52757 21.9931 3.99875Z"
  />
  <path
    d="M28 13.9245C28 13.9792 28 14.0339 28 14.0886C27.911 14.5888 27.6179 14.879 27.1206 14.9592C26.9055 14.9942 26.5971 15.0117 26.1953 15.0117C21.0977 15.0154 16.0023 15.0132 10.9091 15.0052C10.894 15.0052 10.8794 15.0099 10.8673 15.0185C10.8551 15.0271 10.846 15.0393 10.8413 15.0533C10.3884 16.4227 9.55391 17.3214 8.33766 17.7494C7.6413 17.9944 6.92453 18.0407 6.18734 17.8883C4.7487 17.5908 3.74135 16.6564 3.16531 15.085C3.15695 15.0626 3.14184 15.0432 3.12206 15.0297C3.10228 15.0162 3.0788 15.0092 3.05484 15.0095C2.41536 15.0205 1.80432 15.0172 1.22172 14.9997C0.869531 14.9888 0.613229 14.9308 0.452812 14.8258C0.212917 14.6683 0.0619792 14.4273 0 14.1028C0 14.0299 0 13.957 0 13.8841C0.0685417 13.5443 0.231875 13.3004 0.49 13.1523C0.649688 13.0612 0.915104 13.0131 1.28625 13.008C1.85865 13.0007 2.44417 13.0036 3.04281 13.0167C3.06959 13.0174 3.09586 13.0091 3.11745 12.993C3.13905 12.977 3.15474 12.9542 3.16203 12.9281C3.44276 11.9314 4.03156 11.1515 4.92844 10.5886C5.65469 10.1336 6.43344 9.94073 7.26469 10.01C8.0099 10.072 8.68729 10.3163 9.29688 10.7428C10.0384 11.2627 10.5551 11.9915 10.8467 12.9292C10.8516 12.9448 10.8609 12.9586 10.8735 12.9689C10.8862 12.9792 10.9016 12.9856 10.9178 12.9872C11.1497 13.0113 11.3819 13.0193 11.6145 13.0113C12.0549 12.9959 12.2781 12.9883 12.2839 12.9883C17.1183 12.9832 21.9523 12.9916 26.7859 13.0134C27.1308 13.0149 27.3828 13.067 27.5417 13.1699C27.7918 13.331 27.9446 13.5826 28 13.9245ZM8.99281 14C8.99281 13.4712 8.78274 12.964 8.40881 12.5901C8.03488 12.2162 7.52772 12.0061 6.99891 12.0061C6.47009 12.0061 5.96293 12.2162 5.589 12.5901C5.21507 12.964 5.005 13.4712 5.005 14C5.005 14.5288 5.21507 15.036 5.589 15.4099C5.96293 15.7838 6.47009 15.9939 6.99891 15.9939C7.52772 15.9939 8.03488 15.7838 8.40881 15.4099C8.78274 15.036 8.99281 14.5288 8.99281 14Z"
  />
  <path
    d="M28 23.9323C28 23.987 28 24.0417 28 24.0964C27.93 24.4617 27.7561 24.7177 27.4783 24.8642C27.3171 24.9495 27.0375 24.9922 26.6394 24.9922C25.0921 24.9922 23.5193 24.9922 21.9209 24.9922C21.9048 24.9921 21.889 24.9972 21.876 25.0066C21.863 25.0161 21.8534 25.0294 21.8487 25.0447C21.2727 26.8414 20.0306 27.8265 18.1223 28C18.013 28 17.9036 28 17.7942 28C15.9844 27.8053 14.7773 26.8395 14.1728 25.1027C14.1617 25.0704 14.1407 25.0424 14.1128 25.0226C14.085 25.0028 14.0517 24.9921 14.0175 24.9922C9.76646 24.9922 5.54641 24.9922 1.35734 24.9922C0.962135 24.9922 0.683958 24.9499 0.522813 24.8653C0.247917 24.7209 0.0736458 24.4694 0 24.1106C0 24.0282 0 23.9462 0 23.8645C0.156771 23.2856 0.566927 22.9961 1.23047 22.9961C5.48734 22.9961 9.76682 22.9961 14.0689 22.9961C14.0919 22.9962 14.1143 22.9886 14.1327 22.9746C14.1511 22.9605 14.1644 22.9408 14.1706 22.9184C14.2815 22.5101 14.463 22.1262 14.7153 21.7667C15.3723 20.8334 16.2199 20.2719 17.2583 20.0823C17.9823 19.9496 18.6991 20.0142 19.4086 20.2759C20.6022 20.7164 21.412 21.6038 21.8378 22.9381C21.8429 22.9532 21.8525 22.9662 21.8652 22.9754C21.878 22.9847 21.8933 22.9896 21.9089 22.9895C24.5098 23.0019 26.1483 23.0041 26.8242 22.9961C27.4848 22.9881 27.8768 23.3002 28 23.9323ZM19.9927 23.9991C19.9927 23.4703 19.7826 22.9631 19.4087 22.5892C19.0347 22.2152 18.5276 22.0052 17.9987 22.0052C17.4699 22.0052 16.9628 22.2152 16.5888 22.5892C16.2149 22.9631 16.0048 23.4703 16.0048 23.9991C16.0048 24.5279 16.2149 25.035 16.5888 25.409C16.9628 25.7829 17.4699 25.993 17.9987 25.993C18.5276 25.993 19.0347 25.7829 19.4087 25.409C19.7826 25.035 19.9927 24.5279 19.9927 23.9991Z"
  />
</svg>
