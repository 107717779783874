import { t } from "stores/i18n.js";
import { get } from "svelte/store";

export function getSampleMailingToastStatus(isUserTeamUser, sample, entry) {
  return [
    {
      condition: entry.sent_at === null && !entry.address_complete,
      props: {
        type: "warning",
        title: get(t)("sample_mailing.address_incomplete_title"),
        message: get(t)(
          `sample_mailing.address_incomplete_via_${entry.sent_via}_message`,
        ),
      },
    },
    {
      condition:
        entry.sent_at === null &&
        sample.contact.country &&
        !sample.contact.country_alpha_2_code,
      props: {
        type: "warning",
        title: get(t)("sample_mailing.unknown_country_title"),
        message: get(t)("sample_mailing.unknown_country_message"),
      },
    },
    {
      condition:
        entry.sent_at === null &&
        sample.contact.zip &&
        !sample.contact.valid_zip,
      props: {
        type: "warning",
        title: get(t)("sample_mailing.unknown_zip_title"),
        message: get(t)("sample_mailing.unknown_zip_message"),
      },
    },
    {
      condition: entry.sent_at === null && !sample.letterhead_presence,
      props: {
        type: "warning",
        message: get(t)("sample_mailing.letterhead_missing_message"),
      },
    },
    {
      condition:
        entry.sent_at === null && entry.ready_to_send && entry.waiting_release,
      props: {
        message: get(t)(
          "sample_mailing.earliest_shipping_confirmation_action_message",
        ).replace("%{date}", sample.start_sending_at),
      },
    },
    {
      condition: entry.bounced || entry.returned,
      props: {
        type: "warning",
        title: get(t)("sample_mailing.returned_warning_title"),
        message: get(t)("sample_mailing.returned_warning_message"),
      },
    },
    {
      condition:
        isUserTeamUser &&
        entry.address_complete &&
        (!entry.stored || entry.mailing_url === null),
      props: { message: get(t)("sample_mailing.preview_is_being_created") },
    },
  ];
}
