<script>
  import { onMount } from "svelte";

  import { ResponderUploadItem } from "apps/responder";
  import { t, currentLanguage } from "stores/i18n.js";
  import { sender, reviewType } from "stores/responder.js";

  import onError from "apis/appsignal/appsignal.js";
  import { localizeDate } from "lib/helpers.js";

  import { Button, Dropzone, RadioGroup, TextInput } from "components";
  import styles from "styleguide/ResponderConfirmationItem.json";

  export let index;
  export let itemId;
  export let amount;
  export let deadline;
  export let reference;
  export let onFillData;
  export let item_date = "";
  export let item_number = "";
  export let item_currency = "EUR";

  let files = [];
  let dropzone;
  let comment;
  let value = "";
  let valueNumber = 0;
  let amountNumber = 0;
  let correct = true;
  let wrong = !correct;

  $: if (correct) resetWrongValue();
  $: if (comment || value || correct || wrong || files) combineData();

  $: agreeLabel = getAgreeLabel(amountNumber, $t);

  $: accountingStatusItems = [
    {
      id: `accounting-correct-${itemId}`,
      name: `radio-accounting-${itemId}`,
      label: $t("responder_confirmation_item.correct"),
      checked: correct,
    },
    {
      id: `accounting-wrong-${itemId}`,
      name: `radio-accounting-${itemId}`,
      label: $t("responder_confirmation_item.wrong"),
      checked: wrong,
    },
  ];

  currentLanguage.subscribe((language) => {
    value = valueNumber.toLocaleString(language);
  });

  function getAgreeLabel(amountNumber) {
    if (!$t("responder_confirmation_item")) return;

    if ($reviewType === "positive") {
      return $t("responder_confirmation_item.agree_accounting_positive")
        .replace("%{amount}", amountNumber.toLocaleString($currentLanguage))
        .replace("%{item_currency}", item_currency)
        .replace("%{sender}", $sender.name)
        .replace("%{time}", localizeDate(deadline));
    }

    if ($reviewType === "open") {
      return $t("responder_confirmation_item.agree_accounting_open");
    }

    if ($reviewType === "individual") {
      return $t("responder_confirmation_item.agree_accounting_individual")
        .replace("%{amount}", amountNumber.toLocaleString($currentLanguage))
        .replace("%{item_currency}", item_currency);
    }
  }

  function onChange() {
    valueNumber = getNumber(value);
    value = valueNumber.toLocaleString($currentLanguage);
  }

  function getNumber(value) {
    if (!value) return 0;
    value = value.toString();
    let separator = $currentLanguage === "de" ? "." : ",";
    return (
      parseFloat(
        value.replaceAll(separator, "").replace(",", ".").replace(" ", ""),
      ) || 0
    );
  }

  function combineData() {
    let valid;
    const data = {};
    let _value = getNumber(value);

    data.reference = reference;

    data.id = itemId;

    if ($reviewType === "positive" || $reviewType === "individual") {
      if (correct) {
        data.correct = "1";
        valid = true;
      } else {
        data.wrong = "1";
        data.value = _value;
        valid = value !== "";
      }
    } else if ($reviewType === "open") {
      data.value = _value;
      valid = value !== "";
    } else {
      onError(`Review type name is wrong: ${$reviewType}`);
    }

    if (comment) data.comment = comment;
    data.files = files;

    onFillData(index, data, valid);
  }

  function resetWrongValue() {
    if ($reviewType === "positive" || $reviewType === "individual") {
      value = "";
    }
  }

  let toggleAccountingStatus = (id) => {
    accountingStatusItems = accountingStatusItems.map((item) => {
      item.checked = item.id === id;
      return item;
    });

    correct = id === `accounting-correct-${itemId}`;
    wrong = !correct;

    if (correct) resetWrongValue();
  };

  function addFileButtonHandler() {
    dropzone.click();
  }

  function addFiles(newFiles) {
    if (newFiles) {
      files = [...files, ...newFiles];
    }
  }

  function removeFiles(index) {
    let newFiles = files.filter((f, i) => i !== index);
    files = [...newFiles];
  }

  onMount(async () => {
    amountNumber = getNumber(amount);
  });
</script>

<div data-component="ResponderConfirmationItem">
  <div class={styles.block}>
    <div class={styles.label}>
      {$t("responder_confirmation_item.accountnumber")}
    </div>
    <div class={styles.reference}>{reference}</div>
  </div>
  {#if item_number}
    <div class={styles.block}>
      <div class={styles.label}>
        {$t("responder_confirmation_item.item_number")}
      </div>
      <div class={styles.reference}>{item_number}</div>
    </div>
  {/if}
  {#if item_date}
    <div class={styles.block}>
      <div class={styles.label}>
        {$t("responder_confirmation_item.item_date")}
      </div>
      <div class={styles.reference}>{localizeDate(item_date)}</div>
    </div>
  {/if}
  <div class={styles.blockNoLabel}>
    {#if $reviewType === "positive" || $reviewType === "individual"}
      <div>{agreeLabel}</div>
      <div class={styles.accounting}>
        <div class={styles.accountingRadio}>
          <RadioGroup
            items={accountingStatusItems}
            toggle={toggleAccountingStatus}
          />
        </div>

        <div class={styles.accountingValue}>
          <div class={styles.accountingInput}>
            <TextInput
              {onChange}
              bind:value
              style="border medium round-border fullsize"
              disabled={correct}
            />
          </div>

          <div class={styles.accountingCurrency}>{item_currency}</div>
        </div>
      </div>
    {:else if $reviewType === "open"}
      <div class={styles.accountingValue}>
        <TextInput
          {onChange}
          label={agreeLabel}
          bind:value
          style="border medium round-border fullsize"
        />

        <div class={styles.accountingCurrency}>{item_currency}</div>
      </div>
    {/if}
  </div>
  <div class={styles.blockNoLabel}>
    <TextInput
      bind:value={comment}
      textarea={true}
      optional={true}
      label={$t("responder_confirmation_item.comment_item")}
      placeholder={$t("responder_confirmation_item.write_comment")}
      style="border medium round-border fullsize"
    />
  </div>
  <div class={styles.blockNoLabel}>
    <div>
      {$t("responder_confirmation_item.upload")}
      <span class={styles.optional}
        >({$t("responder_confirmation_item.optional")})</span
      >
    </div>
    <div class={styles.dropzoneWrapper}>
      <Button click={addFileButtonHandler} style="primary-text"
        >{$t("responder_confirmation_item.add_file")}</Button
      >
      <Dropzone style="hidden" bind:this={dropzone} onFiles={addFiles} />

      <div class={styles.dropzoneBody}>
        {#each files as file, index (file)}
          <ResponderUploadItem onRemoveFiles={removeFiles} {file} {index} />
        {/each}
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  .block {
    display: flex;
    margin-bottom: 25px;
  }

  .blockNoLabel {
    margin-bottom: 25px;
    padding-left: 140px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .reference {
    font-weight: 600;
    line-height: 1.57;
  }

  .label {
    width: 140px;
    padding-right: 10px;
    line-height: 1.57;
    color: var(--primary-300);
    box-sizing: border-box;
  }

  .accounting {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 25px 0 35px;
  }

  .accountingRadio {
    margin: 0 10px 10px 0;
  }

  .accountingValue {
    display: flex;
    align-items: flex-end;
  }

  .accountingInput {
    width: 140px;
  }

  .accountingCurrency {
    margin: 0 0 10px 10px;
  }

  .dropzoneWrapper {
    margin-top: 10px;
  }

  .dropzoneBody {
    margin-top: 10px;
  }
</style>
