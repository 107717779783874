import { Controller } from "stimulus";
import Api from "../general/api";

export default class extends Controller {
  connect() {
    this.addClickRedirectEvent();
  }

  addClickRedirectEvent() {
    this.element.addEventListener("click", this.redirectClickEvent.bind(this));
  }

  redirectClickEvent(e) {
    e.preventDefault();
    if (this.element.hasAttribute("data-blank")) {
      window.open(this.element.getAttribute("data-url"), "_blank");
    } else {
      Api.redirect(this.element.getAttribute("data-url"));
    }
  }
}
