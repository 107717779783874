<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(92.54902%,0%,8.235294%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 305.804688 267.925781 L 242.824219 220.890625 L 180.148438 268.386719 L 203.457031 191.207031 L 140.898438 143.546875 L 218.300781 142.878906 L 242.304688 65.929688 L 266.824219 142.703125 L 344.226562 142.804688 L 281.988281 190.914062 L 305.8125 267.929688 Z M 305.804688 267.925781 "
    />
  </g>
</svg>
