<script>
  import styles from "styleguide/ControlUploadDropdown.json";
  import { onDestroy } from "svelte";

  import { t } from "stores/i18n.js";
  import documentViewerStore from "stores/document_viewer_store.js";

  import { Boundary } from "components";

  export let item;
  export let index;
  export let files;
  let documentsCount;
  let versionsCount;

  const document = documentViewerStore.documentReadable;

  $: {
    if (item.itemsShow === undefined && (index === 0 || index === 1)) {
      item.itemsShow = true;
    }
  }
  const unsubscribe = document.subscribe((value) => {
    if (value.id && item.ids.some((id) => id === value.id)) {
      item.itemsShow = true;
    }
  });
  $: documentsCount = files.length;
  $: versionsCount = files
    .map((file) => file.files.length)
    .reduce((partialSum, a) => partialSum + a, 0);

  onDestroy(() => {
    unsubscribe();
  });
</script>

<Boundary>
  <div data-component="ControlUploadDropdown" class={styles.dropdown}>
    <div
      class={`${styles["dropdown-title"]} ${item.itemsShow ? styles.open : ""}`}
      on:click={() => {
        item.itemsShow = !item.itemsShow;
      }}
    >
      <div class={styles.date}>{item.createDay}</div>
      <div class={styles.count}>
        {documentsCount}
        {$t("control_upload_dropdown.files_count", {
          postProcess: "interval",
          count: documentsCount,
        })}
        {#if !item.itemsShow}
          {$t("control_upload_dropdown.click_to_show")}
        {/if}
        {#if versionsCount > documentsCount}
          - {versionsCount}
          {$t("control_upload_dropdown.version_count", {
            postProcess: "interval",
            count: versionsCount,
          })}
        {/if}
      </div>
    </div>
    <slot />
  </div>

  {#if item.itemsShow}
    <slot name="items" />
  {/if}
</Boundary>

<style lang="scss">
  .dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 5px;
    cursor: pointer;
    margin: 10px 0 5px;
    border-bottom: 1px solid var(--primary-050);

    &:hover {
      border-radius: 6px;
      background-color: var(--blue-010);
      border-bottom: 1px solid transparent;
    }

    user-select: none;
  }

  .dropdown:first-child {
    margin-top: 0;
  }

  .dropdown-title {
    display: flex;
    font-size: 12px;
    flex: 1;
  }

  .date {
    font-weight: 500;
    margin-right: 5px;
    color: var(--blue-200);

    .open & {
      color: var(--primary-500);
    }
  }

  .count {
    color: var(--primary-300);
  }
</style>
