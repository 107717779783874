<script>
  import styles from "styleguide/DashboardClientSidebar.json";
  import { getContext } from "svelte";
  import { t } from "stores/i18n.js";
  import { user } from "stores/user.js";
  import { account } from "stores/account.js";
  import { theme } from "stores/theme.js";
  import { changeLanguage } from "stores/i18n.js";

  import { userPath, csrfToken } from "lib/helpers.js";
  import { Boundary, Box, Button, HelpVideo } from "components";

  const { language } = getContext("config");

  $: activeLanguage = language;

  let userPermalink;

  let showVideo = false;
  let videoId;
  let videoNavigation = [];

  user.subscribe((value) => {
    userPermalink = value.permalink;
  });

  function setLanguage(newLanguage) {
    fetch(
      `${userPath}/client_users/${userPermalink}/change_locale?language=${newLanguage}`,
      {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken(),
        },
      },
    ).then(() => {
      changeLanguage(newLanguage);
      activeLanguage = newLanguage;
    });
  }

  function signOut() {
    fetch(`${userPath}/logins/sign_out`, {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": csrfToken(),
      },
    }).then(() => {
      window.location = "/login";
    });
  }

  function startVideo(id, navigation) {
    showVideo = true;
    videoId = id;
    videoNavigation = video[navigation];
  }

  const video = {
    pbc_client: [
      { name: "Anforderungsliste anzeigen", time: "28" },
      { name: "Dateien/Informationen bereitstellen", time: "72" },
      { name: "Filtermöglichkeiten", time: "243" },
    ],
    sba_client: [
      { name: "Briefkopf hochladen", time: "50" },
      { name: "Daten hochladen", time: "180" },
      { name: "Zu versendende Schreiben kontrollieren", time: "310" },
      { name: "Stammdaten nachtragen", time: "500" },
      { name: "Antworten kontrollieren", time: "615" },
      { name: "Handlungsbedarf klären", time: "675" },
    ],
  };
</script>

<Boundary>
  <div data-component="DashboardClientSidebar">
    <div class={styles.item}>
      <Box>
        <div class={styles.container}>
          <div class={styles.note}>
            {$t("dashboard_client_sidebar.logged_in_as")}
          </div>
          <div class={styles.email}>{$user.email}</div>
          <div class={styles.controls}>
            <div class={styles["controls-gap"]}>
              <Button style="error-text small" click={signOut}
                >{$t("dashboard_client_sidebar.logout")}</Button
              >
              {#each $account.languages as language}
                {#if language !== activeLanguage}
                  <Button
                    style="primary-text small"
                    click={setLanguage.bind(this, language)}
                    >{$t(`dashboard_client_sidebar.switch_${language}`)}</Button
                  >
                {/if}
              {/each}
            </div>
          </div>
        </div>

        {#if $theme == "eyde"}
          <div class={styles.container}>
            <div class={styles.note}>
              {$t("dashboard_client_sidebar.help_and_contact")}
            </div>
            <div class="">confirmations@de.ey.com</div>
            <div class="">+49 6196 996 11111</div>
          </div>
        {/if}

        {#if $theme == "made"}
          <Button
            style="blue-text sidebar-action"
            newtab={true}
            navigate={"/Mazars - Anleitung Mandanten.pdf"}
            >{$t("dashboard_client_sidebar.download_instructions")}</Button
          >
        {/if}

        {#if $theme == "btde"}
          <Button
            style="blue-text sidebar-action"
            newtab={true}
            navigate={"/Baker_Tilly_Data_Space_Anleitung.pdf"}
            >{$t("dashboard_client_sidebar.download_instructions")}</Button
          >
        {/if}

        {#if $user.verifyEnabled}
          <div class={styles.container}>
            <div class={styles.text}>
              {@html $t(
                "dashboard_client_sidebar.two_factor_authentication_disable",
              )}
            </div>
          </div>
          <div class={styles.controls}>
            <Button
              style="blue-text sidebar-action"
              link={`${userPath}/2fa-verification/new`}
              >{$t("dashboard_client_sidebar.disable")}</Button
            >
          </div>
        {:else}
          <div class={styles.container}>
            <div class={styles.text}>
              {@html $t(
                "dashboard_client_sidebar.two_factor_authentication_enable",
              )}
            </div>
          </div>
          <div class={styles.controls}>
            <Button
              style="blue-text sidebar-action"
              link={`${userPath}/2fa-verification/new`}
              >{$t("dashboard_client_sidebar.enable")}</Button
            >
          </div>
        {/if}
      </Box>
    </div>

    {#if $theme == "aude"}
      <div class={styles.item}>
        <Box>
          <div class={styles.container}>
            <div class={styles.info}>
              <div class={styles.title}>
                {$t("dashboard_client_sidebar.confirmations_sba_title")}
              </div>
              <div class={styles.text}>
                {$t("dashboard_client_sidebar.confirmations_sba_text")}
              </div>
            </div>
          </div>
          <div class={styles.controls}>
            <Button
              style="blue-text sidebar-action"
              link={"/assets/Auditi_Dokumentation_Mandant_SBA.pdf"}
              >{$t("dashboard_client_sidebar.download_instructions")}</Button
            >
            <Button
              style="blue-text sidebar-action"
              click={startVideo.bind(this, "467629836", "sba_client")}
              >{$t("dashboard_client_sidebar.view_help_video")}</Button
            >
          </div>

          <div class={styles.container}>
            <div class={styles.info}>
              <div class={styles.title}>
                {$t("dashboard_client_sidebar.title_pbc")}
              </div>
              <div class={styles.text}>
                {$t("dashboard_client_sidebar.description_pbc")}
              </div>
            </div>
          </div>
          <div class={styles.controls}>
            <Button
              style="blue-text sidebar-action"
              link={"/assets/Auditi_Dokumentation_Mandant_PBC.pdf"}
              >{$t("dashboard_client_sidebar.download_instructions")}</Button
            >
            <Button
              style="blue-text sidebar-action"
              click={startVideo.bind(this, "467629985", "pbc_client")}
              >{$t("dashboard_client_sidebar.view_help_video")}</Button
            >
          </div>

          <div class={styles.container}>
            <div class={styles.info}>
              <div class={styles.title}>
                {$t("dashboard_client_sidebar.technical_help_title")}
              </div>
              <div class={styles.text}>
                {$t("dashboard_client_sidebar.technical_help_description")}
              </div>
              <div class={styles.description}>
                <div>support@auditi.de</div>
                <div>
                  {$t("dashboard_client_sidebar.technical_help_via_phone")}
                </div>
                <div>+49 211 / 240 922 90</div>
                <div>
                  {$t("dashboard_client_sidebar.technical_help_keyword")}
                </div>
              </div>
            </div>
          </div>

          <div class={styles.container}>
            <div class={styles["title-no-margin"]}>
              {$t("dashboard_client_sidebar.privacy_title")}
            </div>
          </div>
          <Button
            style="blue-text sidebar-action"
            link={"/assets/Auditi - Datenschutz.pdf"}
            >{$t("dashboard_client_sidebar.privacy_policy")}</Button
          >
          <Button
            style="blue-text sidebar-action"
            link={"/assets/Auditi - Auftragsdatenverarbeitung.pdf"}
            >{$t("dashboard_client_sidebar.order_processing")}</Button
          >
          <div class={styles.container}>
            <div class={styles.info}>
              <div class={styles.description}>
                {$t("dashboard_client_sidebar.privacy_notice")}
              </div>
            </div>
          </div>
        </Box>
      </div>
      <HelpVideo
        shown={showVideo}
        id={videoId}
        navigation={videoNavigation}
        onHide={() => {
          showVideo = false;
        }}
      />
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .item {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .note {
    color: var(--primary-300);
  }

  .container {
    padding: 15px 18px;

    &:not(:first-child) {
      border-top: 1px solid var(--primary-050);
    }
  }

  .controls {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    &-gap {
      display: flex;
      gap: 8px;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    &-no-margin {
      margin-bottom: 0;
    }
  }

  .button-wrapper {
    margin: 0 10px 10px 0;

    &:last-child {
      margin: 0;
    }
  }

  .description {
    margin-top: 15px;
  }

  .info {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
</style>
