<script>
  import styles from "styleguide/ConfirmationSidebarOverview.json";
  import { getContext } from "svelte";

  import { user } from "stores/user.js";
  import { t } from "stores/i18n.js";
  import { toasts } from "stores/toasts.js";
  import { can } from "stores/permission.js";
  import {
    confirmation,
    confirmationId,
    activeRelease,
    getConfirmation,
  } from "stores/confirmation/confirmation.js";

  import ConfirmationApi from "apis/confirmation/confirmation.js";

  import {
    Boundary,
    Box,
    Button,
    LegacyButton,
    Smalltabs,
    Toast,
  } from "components";

  import {
    ConfirmationSidebarActivity,
    ConfirmationSidebarComments,
    ConfirmationSidebarStatus,
  } from "apps/confirmation";

  export let statuses = true;
  export let comments = true;
  export let activity = true;
  export let margined;

  let parent = getContext("parent_type");
  let commentsList = getContext("comments");
  $: commentsLength = $commentsList.filter((item) => !item.is_deleted).length;

  let tabs = {};

  if (statuses) {
    tabs["status"] = {
      id: "status",
      component: ConfirmationSidebarStatus,
    };
  }

  if (comments) {
    tabs["comments"] = {
      id: "comments",
      component: ConfirmationSidebarComments,
    };
  }

  if (activity) {
    tabs["activity"] = {
      id: "activity",
      component: ConfirmationSidebarActivity,
    };
  }

  let activeTab = Object.values(tabs)[0];

  function changeTab(target) {
    activeTab = tabs[target];
  }

  function confirmMailings() {
    activeRelease.set(true);
  }

  function unconfirmMailings() {
    const isConfirmed = confirm($t("questions.sure"));

    if (isConfirmed) {
      ConfirmationApi.unconfirmMailings({
        params: {
          id: $confirmationId,
        },
        success: () => {
          getConfirmation();
          activeRelease.set(false);
          toasts.send({
            title: $t("confirmation_sidebar_overview.unconfirm_success"),
            type: "success",
          });
        },
        error: () => {
          toasts.send({
            title: $t("confirmation_sidebar_overview.unconfirm_error"),
            type: "error",
          });
        },
      });
    }
  }
</script>

<Boundary>
  {#if $user.role !== "client" && $can("confirm_mailings", "confirmation")}
    {#if parent === "confirmation" && ($confirmation.counters.confirmed_mailings_count > 0 || $confirmation.counters.unconfirmed_mailings_count > 0)}
      <Toast type="warning" hideIcon={true}>
        <div class={styles.dispatch}>
          {#if $confirmation.counters.unconfirmed_mailings_count > 0}
            <p>
              {$t("confirmation_sidebar_overview.ready_for_dispatch") &&
                $t("confirmation_sidebar_overview.ready_for_dispatch").replace(
                  "%{count}",
                  $confirmation.counters.unconfirmed_mailings_count,
                )}
            </p>
            <Button style={"primary-tonal small"} click={confirmMailings}>
              {$t("confirmation_sidebar_overview.release")}
            </Button>
          {/if}

          {#if $confirmation.counters.confirmed_mailings_count > 0}
            <p>
              {$t("confirmation_sidebar_overview.have_been_approved") &&
                $t("confirmation_sidebar_overview.have_been_approved").replace(
                  "%{count}",
                  $confirmation.counters.confirmed_mailings_count,
                )}
            </p>
            <Button style={"error-tonal small"} click={unconfirmMailings}>
              {$t("confirmation_sidebar_overview.undo")}
            </Button>
          {/if}
        </div>
      </Toast>
    {/if}
  {/if}

  <div
    data-component="ConfirmationSidebarOverview"
    class={`${styles.wrapper} ${margined ? styles.margined : ""}`}
  >
    <Box
      title={$user.role === "client"
        ? $t("confirmation_sidebar_overview.status")
        : null}
    >
      {#if $user.role !== "client" && $can("confirm_mailings", "confirmation")}
        <Smalltabs>
          {#each Object.values(tabs) as tab}
            <div class={styles.smalltab}>
              <LegacyButton
                style={"smalltab fullsize"}
                active={activeTab.id === tab.id}
                click={() => changeTab(tab.id)}
              >
                {$t("confirmation_sidebar_overview." + tab.id)}
                {#if tab.id === "comments"}
                  <span
                    class={commentsLength > 0
                      ? styles.count
                      : styles["empty-count"]}
                  >
                    ({commentsLength})
                  </span>
                {/if}
              </LegacyButton>
            </div>
          {/each}
        </Smalltabs>
      {/if}

      <Box style={"no-border"}>
        <svelte:component this={activeTab.component} />
      </Box>
    </Box>
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    width: 335px;
  }

  .margined {
    margin-top: 110px;
  }

  .smalltab {
    min-width: 33%;
    font-weight: normal;
  }

  .count {
    color: var(--red-200);
  }

  .empty-count {
    color: var(--primary-300);
  }

  .info {
    color: var(--primary-300);
    font-size: 12px;
    margin-top: 20px;
  }

  .info-item {
    display: flex;
    justify-content: space-between;
  }

  .dispatch {
    padding: 4px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;

    p {
      margin: 0;
    }
    Button:first-of-type {
      margin-bottom: 8px;
    }
  }
</style>
