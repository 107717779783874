<script>
  import styles from "styleguide/ConfirmationListItem.json";

  import { isUserTeamUser } from "stores/user.js";
  import { t } from "stores/i18n.js";
  import { selectedItems } from "stores/confirmation/confirmation.js";

  import { ConfirmationStatus } from "apps/confirmation";
  import { Boundary, Checkbox, ProgressRing, Tooltip } from "components";

  export let action_required;
  export let confirmation_type;
  export let company;
  export let companies;
  export let city;
  export let is_confirmation_com;
  export let status;
  export let permalink;
  export let item_path;
  export let sending_scheduled;

  $: typeChecked = $selectedItems.includes(permalink);

  $: acceptedCompaniesCount = companies.filter(
    (c) => c.accepted === "true",
  ).length;
  $: completedPercent = (acceptedCompaniesCount / companies.length) * 100;

  function typeCheck(value) {
    if (value) {
      selectedItems.update((items) => [permalink, ...items]);
    } else {
      selectedItems.update((items) =>
        items.filter((item) => item !== permalink),
      );
    }
  }
</script>

<Boundary>
  <div data-component="ConfirmationListItem" class={styles.wrapper}>
    <div class={styles.inner}>
      {#if $isUserTeamUser}
        <div class={styles.checkbox}>
          <Checkbox style="grey" toggle={typeCheck} toggled={typeChecked} />
        </div>
      {/if}

      <div class={styles.progress}>
        <Tooltip showArrow placement="bottom-start">
          <ProgressRing
            {completedPercent}
            size="40"
            label={`${acceptedCompaniesCount}/${companies.length}`}
          />
          <div slot="tooltip">
            <ul class={styles["company-list"]}>
              {#each companies as company}
                <li class={company.accepted === "true" ? styles.accepted : ""}>
                  {company.name}
                </li>
              {/each}
              {#if companies.length === 0}
                <li class={""}>
                  {$t("confirmation_list_item.no_company_yet")}
                </li>
              {/if}
            </ul>
          </div>
        </Tooltip>
      </div>

      <a class={styles.company} href={item_path}>
        {company}
        <span class={styles.muted}>
          {city}
          {#if is_confirmation_com}
            &bull;
            <span class={styles.warning}>confirmation.com</span>
          {/if}
        </span>
      </a>

      <div class={styles.type}>
        <div class={styles.label}>
          {$t(`confirmation.types.${confirmation_type}`)}
        </div>
      </div>

      <div class={styles.status}>
        {#if action_required}
          <ConfirmationStatus status={"action_required"} />
        {:else if sending_scheduled}
          <ConfirmationStatus status={"sending_scheduled"} />
        {:else}
          <ConfirmationStatus {status} />
        {/if}
      </div>
    </div>
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    position: relative;
    border-top: 1px solid var(--primary-050);
  }

  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
  }

  .company {
    font-weight: 500;
    color: var(--blue-200);
    width: calc(100% - 300px);
    line-height: 20px;
  }
  .progress {
    width: 70px;
  }
  .checkbox {
    width: 40px;
  }

  .type {
    width: 20%;
    color: var(--blue-200);
  }

  .label {
    color: var(--primary-300);
  }

  .status {
    width: 230px;
    color: var(--blue-200);
  }

  .muted {
    display: block;
    color: var(--primary-300);
    font-weight: normal;
  }

  .warning {
    color: var(--orange-400);
  }

  .company-list {
    font-weight: 400;
  }

  .accepted {
    color: var(--green-500) !important;
  }

  .mailing {
    background-color: var(--blue);
    position: absolute;
    top: 0;
    right: 2px;
    height: 100%;
    display: flex;
    align-items: center;
    transform: translate(100%, 0);
    transition: all 0.2s ease-out;

    .wrapper:hover & {
      right: 0px;
      transform: translate(0, 0);
    }

    img {
      margin-left: 16px;

      &:last-child {
        margin-right: 16px;
      }
    }
  }
</style>
