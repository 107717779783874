<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(80%,20%,20%);fill-opacity:1;"
      d="M 0 120 L 479.996094 120 L 479.996094 240 L 0 240 Z M 0 120 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,60%,80%);fill-opacity:1;"
      d="M 0 0 L 479.996094 0 L 479.996094 120 L 0 120 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,60%,20%);fill-opacity:1;"
      d="M 0 240 L 479.996094 240 L 479.996094 360 L 0 360 Z M 0 240 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 325.851562 179.496094 C 325.894531 231.914062 283.386719 274.457031 230.96875 274.457031 C 178.550781 274.539062 135.976562 232.0625 135.933594 179.644531 C 135.808594 127.226562 178.246094 84.621094 230.660156 84.539062 C 283.078125 84.371094 325.722656 126.777344 325.847656 179.195312 L 325.847656 179.496094 Z M 325.851562 179.496094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,20%,20%);fill-opacity:1;"
      d="M 331.125 179.496094 C 331.160156 222.597656 296.210938 257.574219 253.113281 257.574219 C 210.011719 257.644531 175.007812 222.722656 174.972656 179.621094 C 174.867188 136.523438 209.761719 101.488281 252.863281 101.421875 C 295.960938 101.28125 331.023438 136.148438 331.125 179.25 Z M 331.125 179.496094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 336.402344 126.742188 L 346.5 155.125 L 373.710938 142.1875 L 360.773438 169.398438 L 389.15625 179.496094 L 360.773438 189.59375 L 373.710938 216.804688 L 346.5 203.871094 L 336.402344 232.253906 L 326.304688 203.871094 L 299.09375 216.804688 L 312.027344 189.59375 L 283.648438 179.496094 L 312.027344 169.398438 L 299.09375 142.1875 L 326.304688 155.125 Z M 336.402344 126.742188 "
    />
  </g>
</svg>
