<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(40%,0%,34.117647%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 80.0625 359.832031 L -29.996094 360 L -30 0 L 79.546875 0.0585938 L 150.234375 22.789062 L 80.0625 44.941406 L 150.230469 67.785156 L 80.0625 89.941406 L 150.230469 112.785156 L 80.0625 134.941406 L 150.230469 157.785156 L 80.0625 179.941406 L 150.230469 202.789062 L 80.0625 224.945312 L 150.230469 247.789062 L 80.0625 269.945312 L 150.230469 292.789062 L 80.0625 314.945312 L 150.230469 337.789062 L 80.0625 359.945312 "
    />
  </g>
</svg>
