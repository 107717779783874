<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(96.078431%,0%,7.45098%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,9.411765%,60.392157%);fill-opacity:1;"
      d="M 0 0 L 540 0 L 540 180 L 0 180 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(99.607843%,100%,100%);fill-opacity:1;"
      d="M 0 0 L 309.863281 179.765625 L 0 359.308594 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(92.54902%,63.921569%,0%);fill-opacity:1;"
      d="M 14.683594 327.824219 L 17.84375 313.957031 L 5.386719 307.078125 L 19.863281 305.269531 L 23.15625 291.425781 L 28.945312 304.175781 L 43.433594 302.503906 L 32.535156 312.191406 L 38.195312 325 L 25.671875 318.234375 Z M 14.683594 327.824219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(92.54902%,63.921569%,0%);fill-opacity:1;"
      d="M 80.589844 148.875 L 69.46875 129.78125 L 63.074219 129.054688 L 78.300781 150.679688 Z M 71.957031 157.625 L 48.578125 139.632812 L 47.742188 129.160156 L 57.195312 128.839844 L 76.523438 152.480469 Z M 68.40625 161.738281 L 49.164062 150.875 L 48.355469 144.492188 L 70.179688 159.425781 Z M 68.40625 161.738281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(92.54902%,63.921569%,0%);fill-opacity:1;"
      d="M 108.105469 144.953125 L 113.90625 123.632812 L 109.9375 118.566406 L 105.214844 144.585938 Z M 95.816406 144.941406 L 92.230469 115.660156 L 99.105469 107.714844 L 105.964844 114.226562 L 102.683594 144.585938 Z M 90.390625 145.296875 L 84.632812 123.964844 L 88.613281 118.910156 L 93.285156 144.9375 Z M 90.390625 145.296875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(92.54902%,63.921569%,0%);fill-opacity:1;"
      d="M 130.402344 162.140625 L 149.78125 151.527344 L 150.671875 145.15625 L 128.660156 159.808594 L 130.402344 162.144531 Z M 121.882812 153.285156 L 140.480469 130.386719 L 150.96875 129.824219 L 151.042969 139.28125 L 126.902344 157.984375 Z M 117.863281 149.628906 L 129.226562 130.675781 L 135.628906 130.035156 L 120.128906 151.460938 Z M 117.863281 149.628906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(92.54902%,63.921569%,0%);fill-opacity:1;"
      d="M 133.851562 188.859375 L 155.167969 194.671875 L 160.234375 190.703125 L 134.21875 185.964844 Z M 133.871094 176.566406 L 163.152344 173 L 171.09375 179.878906 L 164.578125 186.734375 L 134.222656 183.4375 Z M 133.515625 171.144531 L 154.851562 165.394531 L 159.90625 169.378906 L 133.875 174.039062 Z M 133.515625 171.144531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(92.54902%,63.921569%,0%);fill-opacity:1;"
      d="M 117.621094 210.585938 L 128.824219 229.628906 L 135.222656 230.324219 L 119.902344 208.769531 Z M 126.214844 201.796875 L 149.671875 219.683594 L 150.558594 230.152344 L 141.105469 230.515625 L 121.671875 206.960938 L 126.214844 201.800781 Z M 129.746094 197.667969 L 149.035156 208.445312 L 149.875 214.828125 L 127.984375 199.988281 Z M 129.746094 197.667969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(92.54902%,63.921569%,0%);fill-opacity:1;"
      d="M 90.203125 214.648438 L 84.546875 236.007812 L 88.550781 241.046875 L 93.097656 214.996094 Z M 102.492188 214.578125 L 106.28125 243.832031 L 99.460938 251.824219 L 92.554688 245.359375 L 95.628906 214.980469 Z M 107.914062 214.183594 L 113.820312 235.476562 L 109.875 240.558594 L 105.023438 214.5625 Z M 107.914062 214.183594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(92.54902%,63.921569%,0%);fill-opacity:1;"
      d="M 67.957031 197.902344 L 48.75 208.828125 L 47.960938 215.214844 L 69.738281 200.210938 Z M 76.617188 206.621094 L 58.390625 229.816406 L 47.910156 230.550781 L 47.6875 221.089844 L 71.519531 202.003906 Z M 80.695312 210.214844 L 69.636719 229.34375 L 63.246094 230.089844 L 78.398438 208.417969 L 80.691406 210.214844 Z M 80.695312 210.214844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(92.54902%,63.921569%,0%);fill-opacity:1;"
      d="M 64.15625 170.925781 L 42.796875 165.261719 L 37.761719 169.265625 L 63.8125 173.820312 Z M 64.226562 183.214844 L 34.96875 186.992188 L 26.980469 180.167969 L 33.445312 173.265625 L 63.824219 176.347656 Z M 64.617188 188.632812 L 43.324219 194.535156 L 38.242188 190.589844 L 64.238281 185.746094 Z M 64.617188 188.632812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(92.54902%,63.921569%,0%);fill-opacity:1;"
      d="M 256.289062 198.570312 L 248.046875 186.980469 L 234.621094 191.6875 L 242.910156 179.6875 L 234.773438 168.011719 L 248.144531 172.183594 L 256.539062 160.261719 L 256.511719 174.84375 L 269.835938 179.148438 L 256.453125 183.984375 Z M 256.289062 198.570312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(92.54902%,63.921569%,0%);fill-opacity:1;"
      d="M 22.609375 69.089844 L 20.054688 55.101562 L 5.898438 53.675781 L 18.492188 46.316406 L 16.070312 32.296875 L 26.410156 41.738281 L 39.070312 34.496094 L 32.863281 47.691406 L 43.113281 57.238281 L 28.9375 55.949219 Z M 22.609375 69.089844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(92.54902%,63.921569%,0%);fill-opacity:1;"
      d="M 136.132812 179.769531 C 136.132812 159.28125 119.523438 142.671875 99.035156 142.671875 C 78.542969 142.671875 61.933594 159.28125 61.933594 179.769531 C 61.933594 200.257812 78.542969 216.867188 99.035156 216.867188 C 119.523438 216.867188 136.132812 200.257812 136.132812 179.769531 Z M 136.132812 179.769531 "
    />
  </g>
</svg>
