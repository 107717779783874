<script>
  export let duration;

  import styles from "styleguide/ProgressBar.json";

  import { onMount } from "svelte";

  let interval;
  let startPercent = 3;
  let percent = startPercent;

  function renderLine() {
    if (percent >= 100) {
      clearInterval(interval);
    } else {
      percent++;
    }
  }

  onMount(async () => {
    if (duration) {
      interval = setInterval(renderLine, duration / (100 - startPercent));
    }
  });
</script>

<div data-component="ProgressBar" class={styles.progress}>
  <div class={styles.value} style={`width: ${percent}%;`}></div>
</div>

<style lang="scss">
  .progress {
    width: 100%;
    height: 16px;
    border-radius: var(--border-radius);
    background-color: var(--primary-010);
  }

  .value {
    height: 16px;
    border-radius: var(--border-radius);
    background-color: var(--green);
    transition: width 1s;
  }
</style>
