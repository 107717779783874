<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(100%,100%,100%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(82.745098%,0%,0%);fill-opacity:1;"
      d="M 351.917969 180 C 351.917969 118.191406 301.8125 68.082031 240 68.082031 C 178.1875 68.082031 128.078125 118.191406 128.078125 180 C 128.078125 241.8125 178.1875 291.921875 240 291.921875 C 301.8125 291.921875 351.917969 241.8125 351.917969 180 Z M 351.917969 180 "
    />
  </g>
</svg>
