<script>
  import { onMount } from "svelte";

  import {
    AuditBottomBar,
    AuditRollForward,
    AuditSidebar,
    AuditTemplate,
    ControlDownloadUnsafe,
    ControlGroupList,
  } from "apps/pbc";
  import { Downloads } from "components/downloads";
  import {
    AuditExportModal,
    AuditModal,
    ControlModal,
    SandboxDeleteModal,
  } from "components/modals";
  import { client } from "stores/client_store";
  import { sandboxDelete } from "stores/dashboard/sandbox.js";
  import documentViewerStore from "stores/document_viewer_store.js";
  import { t } from "stores/i18n.js";
  import {
    audit,
    auditStatuses,
    auditComments,
    setActiveControl,
    setUserCanDelegate,
    auditClientUsers,
    selectedControls,
    filter,
  } from "stores/pbc.js";
  import { user } from "stores/user.js";
  import { isAccountFeature } from "stores/account";
  import AuditApi from "apis/pbc/audit.js";
  
  import { App, ContentSidebar, Header, Toast } from "components";
  import styles from "styleguide/PBC.json";
  
  export let appConfig; // required for App
  export let initialState; // props passed from svelte_app

  $: auditBottomBarShown = $selectedControls.length > 0;

  // hydrate the store with initialState props
  onMount(async () => {
    if (initialState.audit) {
      audit.set(initialState.audit);
      auditStatuses.set(initialState.auditSidebar);
      client.set({
        id: initialState.audit.client_id,
        name: initialState.audit.client_official_name,
      });
    }

    if (initialState.filter) {
      filter.set(initialState.filter);
    }

    if (initialState.control) {
      setActiveControl(initialState.control.permalink);
    }

    auditComments.set(initialState.comments);
    auditClientUsers.set(initialState.client_users);

    documentViewerStore.update({
      isAnnotationsEnabled: $isAccountFeature("pdftron_annotations"),
      isPBC: true,
    });

    setUserCanDelegate($user.permalink);
  });

  function deleteAudit() {
    AuditApi.delete({
      params: {
        id: $audit.permalink,
      },
    });
  }
</script>

<App {...appConfig}>
  <div class={styles.content}>
    <ContentSidebar>
      <div slot="content">
        <Header
          type={$t("control_status.header")}
          headline={$audit.name}
          client_name={$audit.client_name}
          client_official_name={$audit.client_official_name}
        />

        {#if $audit.sandbox}
          <Toast
            title={$t("control_status.sandbox_title")}
            isSandboxBtnDelete
            isActionShown={false}
            action={{
              style: "error-text small",
              click: () => sandboxDelete.set(true),
              button: $t("control_status.sandbox_delete"),
            }}
          />
        {/if}

        <ControlGroupList />
      </div>
      <div slot="sidebar">
        <AuditSidebar />
      </div>
    </ContentSidebar>
  </div>

  <Downloads style={auditBottomBarShown ? "gap-bottom" : ""} />
  <AuditBottomBar shown={auditBottomBarShown} />
  <AuditModal />
  <ControlModal audit={$audit} />
  <AuditTemplate />
  <AuditExportModal />
  <AuditRollForward />
  <ControlDownloadUnsafe />
  <SandboxDeleteModal onDelete={deleteAudit} />
</App>

<style lang="scss">
  .content {
    padding-bottom: 60px;
  }
</style>
