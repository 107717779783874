<script>
  import styles from "styleguide/SpinnerAlt.json";

  export let scale = "1";
</script>

<div
  data-component="SpinnerAlt"
  class={styles.wrapper}
  style={`transform: scale(${scale})`}
>
  <div class={styles.loading}></div>
</div>

<style lang="scss">
  .wrapper {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
  }

  .loading {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid #dddddd;
    border-top-color: var(--blue);
    position: relative;
    animation: loading 1.3s infinite linear;
  }

  @keyframes loading {
    to {
      transform: rotate(1turn);
    }
  }
</style>
