import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "list", "buttonContainer"];

  addNewFileInput() {
    var newFileNode = document.createElement("input");
    newFileNode.type = "file";
    newFileNode.name = "comment[attachments][]";
    newFileNode.setAttribute("multiple", true);
    this.buttonContainerTarget.appendChild(newFileNode);
  }

  addFileNameToList(file) {
    var newListElement = document.createElement("li");
    newListElement.innerHTML = file.name;
    this.listTarget.appendChild(newListElement);
  }

  attachFileEventChange() {
    var fileInputs = this.buttonContainerTarget.getElementsByTagName("input");
    var last_file_input = fileInputs[fileInputs.length - 1],
      self = this;
    last_file_input.addEventListener("change", function changeEvent(e) {
      this.removeEventListener("change", changeEvent);
      self.addNewFileInput();
      self.attachFileEventChange();
      last_file_input.files.forEach((file) => {
        self.addFileNameToList(file);
      });
    });
  }

  connect() {
    this.attachFileEventChange();
  }
}
