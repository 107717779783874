<script>
  import { onMount } from "svelte";
  import { get } from "svelte/store";
  import { t } from "stores/i18n.js";
  import { addedFiles } from "stores/responder.js";

  import { ResponderFileItem } from "apps/responder";
  import { Button } from "components";

  export let file;
  export let index;
  export let onRemoveFiles;

  let progress = 0;
  let name;
  let size;

  onMount(async () => {
    name = file.name;
    size = file.size;
  });

  function onRemoveFile(index) {
    if (onRemoveFiles) {
      onRemoveFiles(index);
    } else {
      let newAddedFiles = get(addedFiles).filter((f, i) => i !== index);
      addedFiles.set(newAddedFiles);
    }
  }
</script>

<ResponderFileItem {progress} {name} {size} {index}>
  <Button style="error-text small" click={(e) => onRemoveFile(index)}
    >{$t("actions.delete")}</Button
  >
</ResponderFileItem>
