<script>
  import Overlay from "components/common/Overlay.svelte";
  import UserIcon from "components/icons/UserIcon.svelte";
  import { Button } from "components/index.js";
  import ReviewClientUserModal from "components/review/ReviewClientUserModal.svelte";
  import { t } from "stores/i18n.js";
  import styles from "styleguide/ReviewSidebarSection.json";

  export let reviewId;
  export let editLink;

  function toggleAccessModalVisible() {
    isAccessModalVisible = !isAccessModalVisible;
  }

  let isAccessModalVisible = false;
</script>

<div data-component="ReviewSidebarSection" class={styles["sidebar-section"]}>
  <Button
    click={() => (isAccessModalVisible = true)}
    style={"blue-text sidebar-action"}
  >
    <UserIcon width="23px" height="24px" fill="var(--blue-100)" />
    {$t("review_sidebar_section.client_access")}
  </Button>
</div>
{#if isAccessModalVisible}
  <Overlay>
    <ReviewClientUserModal
      onHide={toggleAccessModalVisible}
      show={isAccessModalVisible}
      {reviewId}
      editClientAccessPath={editLink}
    />
  </Overlay>
{/if}

<style global lang="scss">
  .sidebar-section {
    border-bottom: 1px solid var(--primary-050);
    svg {
      margin-right: 10px;
    }
  }
</style>
