<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(13.333333%,61.960784%,27.058824%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(97.254902%,89.803922%,3.529412%);fill-opacity:1;"
      d="M 241.054688 326.953125 L 467.167969 180.199219 L 239.6875 33.046875 L 12.832031 180.550781 Z M 241.054688 326.953125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(16.862745%,28.627451%,63.921569%);fill-opacity:1;"
      d="M 339.578125 180.003906 C 339.578125 232.75 296.75 275.507812 243.917969 275.507812 C 191.089844 275.507812 148.261719 232.75 148.261719 180.003906 C 148.261719 127.257812 191.089844 84.5 243.917969 84.5 C 296.75 84.5 339.578125 127.257812 339.578125 180.003906 Z M 339.578125 180.003906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 212.472656 237.207031 L 209.519531 235.5 L 206.449219 237.019531 L 207.136719 233.605469 L 204.765625 231.105469 L 208.15625 230.710938 L 209.746094 227.648438 L 211.167969 230.816406 L 214.511719 231.421875 L 212.003906 233.769531 Z M 212.472656 237.207031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 276.578125 253.4375 L 273.625 251.730469 L 270.554688 253.25 L 271.242188 249.839844 L 268.871094 247.339844 L 272.261719 246.941406 L 273.851562 243.882812 L 275.273438 247.046875 L 278.617188 247.65625 L 276.109375 250.003906 Z M 276.578125 253.4375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 248.949219 227.507812 L 246.394531 226.035156 L 243.742188 227.347656 L 244.335938 224.398438 L 242.289062 222.238281 L 245.21875 221.894531 L 246.59375 219.253906 L 247.820312 221.988281 L 250.710938 222.511719 L 248.542969 224.539062 Z M 248.949219 227.507812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 313.691406 218.183594 L 311.183594 216.738281 L 308.578125 218.023438 L 309.164062 215.132812 L 307.152344 213.011719 L 310.027344 212.675781 L 311.378906 210.078125 L 312.582031 212.761719 L 315.421875 213.277344 L 313.292969 215.269531 Z M 313.691406 218.183594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 247.777344 198.773438 L 244.820312 197.066406 L 241.753906 198.585938 L 242.441406 195.175781 L 240.070312 192.675781 L 243.460938 192.277344 L 245.050781 189.21875 L 246.472656 192.382812 L 249.816406 192.992188 L 247.308594 195.339844 Z M 247.777344 198.773438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 168.847656 169.140625 L 165.890625 167.433594 L 162.820312 168.953125 L 163.507812 165.542969 L 161.136719 163.042969 L 164.527344 162.644531 L 166.121094 159.585938 L 167.542969 162.75 L 170.886719 163.355469 L 168.378906 165.707031 Z M 168.847656 169.140625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 178.339844 208.558594 L 175.382812 206.855469 L 172.3125 208.371094 L 173 204.960938 L 170.628906 202.460938 L 174.019531 202.0625 L 175.613281 199.003906 L 177.035156 202.167969 L 180.378906 202.777344 L 177.871094 205.125 Z M 178.339844 208.558594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 276.835938 154.628906 L 274.226562 153.121094 L 271.511719 154.464844 L 272.121094 151.449219 L 270.027344 149.242188 L 273.019531 148.890625 L 274.425781 146.1875 L 275.683594 148.984375 L 278.636719 149.519531 L 276.421875 151.59375 Z M 276.835938 154.628906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 271.421875 180.273438 L 269.367188 179.085938 L 267.230469 180.144531 L 267.707031 177.769531 L 266.058594 176.03125 L 268.417969 175.753906 L 269.527344 173.625 L 270.515625 175.828125 L 272.839844 176.25 L 271.09375 177.886719 Z M 271.421875 180.273438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 164.445312 215.703125 L 162.472656 214.5625 L 160.425781 215.578125 L 160.882812 213.300781 L 159.300781 211.632812 L 161.5625 211.367188 L 162.628906 209.324219 L 163.574219 211.4375 L 165.808594 211.839844 L 164.132812 213.410156 Z M 164.445312 215.703125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 314.238281 224.78125 L 312.628906 223.929688 L 310.960938 224.691406 L 311.335938 222.984375 L 310.042969 221.738281 L 311.886719 221.539062 L 312.753906 220.011719 L 313.527344 221.589844 L 315.347656 221.894531 L 313.984375 223.066406 Z M 314.238281 224.78125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 164.445312 215.703125 L 162.472656 214.5625 L 160.425781 215.578125 L 160.882812 213.300781 L 159.300781 211.632812 L 161.5625 211.367188 L 162.628906 209.324219 L 163.574219 211.4375 L 165.808594 211.839844 L 164.132812 213.410156 Z M 164.445312 215.703125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 164.445312 215.703125 L 162.472656 214.5625 L 160.425781 215.578125 L 160.882812 213.300781 L 159.300781 211.632812 L 161.5625 211.367188 L 162.628906 209.324219 L 163.574219 211.4375 L 165.808594 211.839844 L 164.132812 213.410156 Z M 164.445312 215.703125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 195.855469 215.695312 L 193.882812 214.554688 L 191.835938 215.570312 L 192.292969 213.292969 L 190.710938 211.625 L 192.972656 211.359375 L 194.039062 209.316406 L 194.984375 211.429688 L 197.21875 211.835938 L 195.542969 213.402344 Z M 195.855469 215.695312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 191.953125 226.144531 L 189.980469 225.003906 L 187.933594 226.019531 L 188.390625 223.742188 L 186.808594 222.074219 L 189.070312 221.808594 L 190.132812 219.765625 L 191.082031 221.878906 L 193.316406 222.28125 L 191.640625 223.851562 Z M 191.953125 226.144531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 257.175781 207.121094 L 255.203125 205.984375 L 253.152344 206.996094 L 253.613281 204.71875 L 252.03125 203.050781 L 254.292969 202.785156 L 255.355469 200.742188 L 256.304688 202.855469 L 258.539062 203.261719 L 256.863281 204.828125 Z M 257.175781 207.121094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 238.039062 207.117188 L 236.066406 205.976562 L 234.015625 206.992188 L 234.472656 204.714844 L 232.890625 203.042969 L 235.15625 202.777344 L 236.21875 200.734375 L 237.164062 202.847656 L 239.398438 203.253906 L 237.722656 204.824219 Z M 238.039062 207.117188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 186.125 200.441406 L 184.890625 199.730469 L 183.605469 200.363281 L 183.890625 198.9375 L 182.898438 197.890625 L 184.320312 197.726562 L 184.984375 196.445312 L 185.578125 197.769531 L 186.976562 198.023438 L 185.929688 199.007812 Z M 186.125 200.441406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 311.804688 233.046875 L 309.832031 231.90625 L 307.78125 232.921875 L 308.242188 230.644531 L 306.660156 228.972656 L 308.921875 228.710938 L 309.984375 226.667969 L 310.933594 228.78125 L 313.167969 229.183594 L 311.492188 230.753906 Z M 311.804688 233.046875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 295.867188 235.132812 L 294.234375 234.1875 L 292.535156 235.03125 L 292.917969 233.140625 L 291.605469 231.753906 L 293.480469 231.53125 L 294.359375 229.835938 L 295.144531 231.589844 L 296.996094 231.925781 L 295.609375 233.230469 Z M 295.867188 235.132812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 303.386719 234.953125 L 301.863281 234.074219 L 300.285156 234.855469 L 300.636719 233.097656 L 299.417969 231.808594 L 301.164062 231.601562 L 301.984375 230.027344 L 302.714844 231.65625 L 304.4375 231.972656 L 303.144531 233.179688 Z M 303.386719 234.953125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 324.976562 216.113281 L 323.511719 215.265625 L 321.988281 216.019531 L 322.328125 214.328125 L 321.152344 213.085938 L 322.835938 212.890625 L 323.625 211.371094 L 324.328125 212.941406 L 325.988281 213.242188 L 324.742188 214.40625 Z M 324.976562 216.113281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 295.679688 245.765625 L 293.765625 244.722656 L 291.777344 245.652344 L 292.222656 243.5625 L 290.683594 242.03125 L 292.882812 241.785156 L 293.914062 239.910156 L 294.832031 241.851562 L 297 242.222656 L 295.375 243.660156 Z M 295.679688 245.765625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 295.789062 254.347656 L 294.039062 253.300781 L 292.226562 254.230469 L 292.632812 252.140625 L 291.230469 250.609375 L 293.234375 250.367188 L 294.175781 248.492188 L 295.015625 250.429688 L 296.992188 250.804688 L 295.507812 252.242188 Z M 295.789062 254.347656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 281.3125 234.949219 L 279.847656 234.101562 L 278.324219 234.855469 L 278.664062 233.164062 L 277.488281 231.921875 L 279.171875 231.726562 L 279.960938 230.207031 L 280.664062 231.777344 L 282.324219 232.082031 L 281.078125 233.246094 Z M 281.3125 234.949219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 267.746094 234.949219 L 266.277344 234.101562 L 264.753906 234.855469 L 265.097656 233.164062 L 263.917969 231.921875 L 265.601562 231.726562 L 266.390625 230.207031 L 267.097656 231.777344 L 268.753906 232.082031 L 267.511719 233.246094 Z M 267.746094 234.949219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 244.703125 214.800781 L 243.238281 213.957031 L 241.714844 214.710938 L 242.058594 213.015625 L 240.878906 211.777344 L 242.5625 211.578125 L 243.351562 210.0625 L 244.058594 211.632812 L 245.714844 211.933594 L 244.472656 213.097656 Z M 244.703125 214.800781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 247.273438 255.863281 L 246.03125 255.148438 L 244.742188 255.785156 L 245.027344 254.351562 L 244.03125 253.300781 L 245.457031 253.136719 L 246.125 251.847656 L 246.722656 253.179688 L 248.128906 253.433594 L 247.074219 254.421875 Z M 247.273438 255.863281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,93.72549%);fill-opacity:1;"
      d="M 212.472656 189.449219 L 209.519531 187.742188 L 206.449219 189.261719 L 207.136719 185.851562 L 204.765625 183.351562 L 208.15625 182.953125 L 209.746094 179.894531 L 211.167969 183.058594 L 214.511719 183.664062 L 212.003906 186.011719 Z M 212.472656 189.449219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 333.277344 214.363281 C 334.734375 210.550781 336.613281 204.800781 337.613281 199.523438 C 286.804688 154.890625 230.167969 132.027344 158.601562 136.730469 C 156.035156 141.652344 153.980469 146.800781 152.25 152.371094 C 237.046875 144.28125 299.203125 181.824219 333.277344 214.363281 Z M 333.277344 214.363281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(18.823529%,61.960784%,22.745098%);fill-opacity:1;"
      d="M 310.4375 189.269531 L 312.25 190.261719 C 311.964844 190.90625 311.890625 191.46875 312.019531 191.949219 C 312.152344 192.433594 312.488281 192.855469 313.019531 193.214844 C 313.582031 193.601562 314.089844 193.769531 314.53125 193.726562 C 314.980469 193.679688 315.316406 193.496094 315.53125 193.175781 C 315.667969 192.96875 315.726562 192.753906 315.703125 192.527344 C 315.679688 192.300781 315.558594 192.03125 315.328125 191.722656 C 315.171875 191.511719 314.789062 191.066406 314.183594 190.386719 C 313.402344 189.519531 312.9375 188.785156 312.785156 188.183594 C 312.578125 187.34375 312.707031 186.578125 313.167969 185.890625 C 313.46875 185.445312 313.871094 185.117188 314.378906 184.902344 C 314.894531 184.683594 315.453125 184.636719 316.058594 184.753906 C 316.667969 184.871094 317.308594 185.15625 317.972656 185.609375 C 319.0625 186.351562 319.71875 187.144531 319.941406 187.996094 C 320.171875 188.851562 320.03125 189.6875 319.519531 190.507812 L 317.71875 189.394531 C 317.941406 188.894531 318 188.464844 317.878906 188.101562 C 317.769531 187.738281 317.46875 187.390625 316.972656 187.054688 C 316.464844 186.710938 315.996094 186.542969 315.566406 186.558594 C 315.289062 186.566406 315.078125 186.683594 314.925781 186.910156 C 314.785156 187.113281 314.753906 187.351562 314.828125 187.613281 C 314.925781 187.953125 315.328125 188.507812 316.046875 189.289062 C 316.761719 190.070312 317.25 190.707031 317.507812 191.199219 C 317.773438 191.691406 317.894531 192.207031 317.871094 192.738281 C 317.851562 193.269531 317.65625 193.808594 317.285156 194.359375 C 316.949219 194.859375 316.496094 195.230469 315.929688 195.476562 C 315.359375 195.726562 314.75 195.785156 314.101562 195.65625 C 313.453125 195.523438 312.753906 195.203125 312 194.691406 C 310.90625 193.945312 310.234375 193.121094 309.988281 192.214844 C 309.746094 191.304688 309.890625 190.324219 310.4375 189.269531 Z M 310.4375 189.269531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(18.823529%,61.960784%,22.745098%);fill-opacity:1;"
      d="M 301.828125 183.621094 L 303.679688 184.535156 C 303.421875 185.1875 303.367188 185.757812 303.515625 186.230469 C 303.671875 186.707031 304.019531 187.117188 304.566406 187.457031 C 305.148438 187.816406 305.65625 187.964844 306.097656 187.902344 C 306.546875 187.839844 306.875 187.644531 307.078125 187.3125 C 307.207031 187.105469 307.253906 186.882812 307.21875 186.660156 C 307.191406 186.433594 307.054688 186.171875 306.816406 185.867188 C 306.652344 185.664062 306.253906 185.238281 305.617188 184.582031 C 304.800781 183.746094 304.308594 183.03125 304.132812 182.4375 C 303.890625 181.605469 303.988281 180.835938 304.421875 180.128906 C 304.707031 179.675781 305.09375 179.332031 305.589844 179.09375 C 306.097656 178.859375 306.65625 178.785156 307.265625 178.875 C 307.878906 178.972656 308.527344 179.230469 309.210938 179.65625 C 310.332031 180.351562 311.019531 181.121094 311.277344 181.960938 C 311.539062 182.808594 311.433594 183.648438 310.957031 184.488281 L 309.109375 183.449219 C 309.3125 182.941406 309.351562 182.507812 309.21875 182.152344 C 309.09375 181.792969 308.777344 181.453125 308.273438 181.140625 C 307.75 180.816406 307.273438 180.667969 306.847656 180.699219 C 306.570312 180.71875 306.363281 180.847656 306.21875 181.078125 C 306.089844 181.289062 306.066406 181.523438 306.152344 181.785156 C 306.261719 182.117188 306.6875 182.660156 307.433594 183.414062 C 308.179688 184.160156 308.695312 184.777344 308.972656 185.261719 C 309.257812 185.742188 309.398438 186.25 309.394531 186.78125 C 309.402344 187.3125 309.226562 187.859375 308.878906 188.425781 C 308.5625 188.9375 308.121094 189.328125 307.566406 189.601562 C 307.003906 189.871094 306.398438 189.957031 305.746094 189.851562 C 305.097656 189.746094 304.382812 189.449219 303.609375 188.96875 C 302.484375 188.269531 301.78125 187.472656 301.496094 186.578125 C 301.21875 185.679688 301.324219 184.691406 301.828125 183.621094 Z M 301.828125 183.621094 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(18.823529%,61.960784%,22.745098%);fill-opacity:1;"
      d="M 291.171875 180.765625 L 296.628906 171.789062 L 303.257812 175.851562 L 302.335938 177.371094 L 297.511719 174.414062 L 296.300781 176.40625 L 300.789062 179.15625 L 299.867188 180.667969 L 295.382812 177.917969 L 293.898438 180.359375 L 298.890625 183.417969 L 297.96875 184.933594 Z M 291.171875 180.765625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(18.823529%,61.960784%,22.745098%);fill-opacity:1;"
      d="M 275.652344 168.019531 L 276.460938 166.445312 L 280.511719 168.542969 L 278.605469 172.265625 C 278.011719 172.441406 277.269531 172.484375 276.367188 172.390625 C 275.476562 172.292969 274.644531 172.046875 273.878906 171.652344 C 272.90625 171.148438 272.160156 170.507812 271.648438 169.722656 C 271.136719 168.9375 270.890625 168.070312 270.917969 167.121094 C 270.945312 166.164062 271.191406 165.242188 271.648438 164.34375 C 272.148438 163.371094 272.796875 162.613281 273.59375 162.0625 C 274.386719 161.519531 275.28125 161.246094 276.269531 161.246094 C 277.027344 161.246094 277.847656 161.476562 278.738281 161.933594 C 279.894531 162.53125 280.671875 163.242188 281.066406 164.066406 C 281.46875 164.890625 281.542969 165.777344 281.289062 166.734375 L 279.242188 166.117188 C 279.347656 165.589844 279.285156 165.101562 279.054688 164.648438 C 278.832031 164.195312 278.449219 163.828125 277.910156 163.546875 C 277.085938 163.121094 276.300781 163.046875 275.542969 163.320312 C 274.792969 163.589844 274.15625 164.242188 273.628906 165.269531 C 273.058594 166.378906 272.875 167.339844 273.082031 168.148438 C 273.289062 168.953125 273.789062 169.566406 274.585938 169.976562 C 274.980469 170.179688 275.414062 170.308594 275.882812 170.359375 C 276.363281 170.40625 276.800781 170.394531 277.199219 170.320312 L 277.808594 169.132812 Z M 275.652344 168.019531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(18.823529%,61.960784%,22.745098%);fill-opacity:1;"
      d="M 207.972656 151.234375 L 209.496094 140.835938 L 212.625 141.300781 L 213.46875 148.667969 L 216.363281 141.847656 L 219.503906 142.3125 L 217.980469 152.714844 L 216.035156 152.429688 L 217.238281 144.238281 L 213.980469 152.125 L 211.96875 151.824219 L 211.117188 143.335938 L 209.914062 151.523438 Z M 207.972656 151.234375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(18.823529%,61.960784%,22.745098%);fill-opacity:1;"
      d="M 197.390625 150.007812 L 198.371094 139.539062 L 206.101562 140.269531 L 205.9375 142.039062 L 200.308594 141.507812 L 200.09375 143.828125 L 205.324219 144.320312 L 205.160156 146.085938 L 199.925781 145.59375 L 199.660156 148.441406 L 205.484375 148.988281 L 205.320312 150.753906 Z M 197.390625 150.007812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(18.823529%,61.960784%,22.745098%);fill-opacity:1;"
      d="M 162.375 143.457031 C 162.402344 142.386719 162.585938 141.492188 162.925781 140.777344 C 163.179688 140.246094 163.515625 139.777344 163.9375 139.363281 C 164.359375 138.949219 164.824219 138.644531 165.320312 138.453125 C 165.980469 138.195312 166.734375 138.074219 167.585938 138.097656 C 169.128906 138.140625 170.347656 138.65625 171.246094 139.640625 C 172.148438 140.625 172.578125 141.976562 172.53125 143.6875 C 172.484375 145.382812 171.988281 146.699219 171.042969 147.632812 C 170.097656 148.566406 168.859375 149.011719 167.320312 148.96875 C 165.761719 148.925781 164.539062 148.414062 163.648438 147.4375 C 162.753906 146.457031 162.328125 145.132812 162.375 143.457031 Z M 162.375 143.457031 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(96.862745%,100%,100%);fill-opacity:1;"
      d="M 164.5625 143.4375 C 164.527344 144.628906 164.777344 145.539062 165.308594 146.171875 C 165.839844 146.796875 166.527344 147.125 167.367188 147.144531 C 168.210938 147.167969 168.910156 146.882812 169.464844 146.292969 C 170.023438 145.695312 170.316406 144.789062 170.351562 143.566406 C 170.382812 142.363281 170.144531 141.457031 169.632812 140.851562 C 169.125 140.246094 168.4375 139.929688 167.566406 139.90625 C 166.695312 139.882812 165.984375 140.164062 165.433594 140.75 C 164.882812 141.332031 164.59375 142.230469 164.5625 143.4375 Z M 164.5625 143.4375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(18.823529%,61.960784%,22.745098%);fill-opacity:1;"
      d="M 174.789062 148.882812 L 174.910156 138.371094 L 179.359375 138.421875 C 180.480469 138.4375 181.292969 138.539062 181.792969 138.738281 C 182.300781 138.929688 182.703125 139.269531 183.003906 139.757812 C 183.304688 140.242188 183.449219 140.796875 183.441406 141.417969 C 183.433594 142.207031 183.195312 142.855469 182.726562 143.367188 C 182.257812 143.875 181.5625 144.1875 180.644531 144.3125 C 181.097656 144.585938 181.472656 144.882812 181.761719 145.207031 C 182.058594 145.53125 182.453125 146.105469 182.949219 146.925781 L 184.203125 148.992188 L 181.675781 148.964844 L 180.171875 146.65625 C 179.640625 145.835938 179.273438 145.316406 179.078125 145.101562 C 178.878906 144.886719 178.667969 144.738281 178.445312 144.65625 C 178.222656 144.574219 177.871094 144.53125 177.382812 144.523438 L 176.957031 144.519531 L 176.902344 148.90625 Z M 174.789062 148.882812 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 176.980469 142.839844 L 178.546875 142.859375 C 179.5625 142.871094 180.195312 142.835938 180.449219 142.753906 C 180.703125 142.667969 180.902344 142.523438 181.046875 142.316406 C 181.191406 142.105469 181.265625 141.84375 181.269531 141.53125 C 181.273438 141.175781 181.183594 140.890625 180.992188 140.671875 C 180.8125 140.449219 180.546875 140.308594 180.207031 140.246094 C 180.035156 140.222656 179.519531 140.203125 178.664062 140.195312 L 177.011719 140.175781 Z M 176.980469 142.839844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(18.823529%,61.960784%,22.745098%);fill-opacity:1;"
      d="M 186.753906 138.890625 L 190.613281 139.085938 C 191.484375 139.132812 192.140625 139.230469 192.589844 139.390625 C 193.195312 139.601562 193.707031 139.953125 194.121094 140.4375 C 194.535156 140.921875 194.839844 141.507812 195.035156 142.191406 C 195.226562 142.871094 195.300781 143.703125 195.25 144.6875 C 195.207031 145.550781 195.0625 146.289062 194.816406 146.90625 C 194.515625 147.65625 194.113281 148.253906 193.601562 148.703125 C 193.21875 149.042969 192.710938 149.296875 192.078125 149.464844 C 191.601562 149.589844 190.976562 149.632812 190.195312 149.59375 L 186.222656 149.390625 Z M 186.753906 138.890625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 188.777344 140.765625 L 188.429688 147.71875 L 190.003906 147.796875 C 190.59375 147.828125 191.023438 147.816406 191.285156 147.765625 C 191.632812 147.695312 191.925781 147.5625 192.160156 147.371094 C 192.398438 147.175781 192.597656 146.847656 192.765625 146.386719 C 192.933594 145.921875 193.035156 145.285156 193.078125 144.472656 C 193.117188 143.660156 193.078125 143.035156 192.957031 142.59375 C 192.835938 142.152344 192.652344 141.800781 192.410156 141.542969 C 192.164062 141.289062 191.847656 141.109375 191.457031 141 C 191.164062 140.917969 190.589844 140.855469 189.726562 140.8125 Z M 188.777344 140.765625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(18.823529%,61.960784%,22.745098%);fill-opacity:1;"
      d="M 238.222656 157.664062 L 240.667969 147.441406 L 243.96875 148.238281 C 245.214844 148.539062 246.019531 148.785156 246.375 148.980469 C 246.921875 149.273438 247.332031 149.726562 247.601562 150.335938 C 247.871094 150.945312 247.910156 151.652344 247.714844 152.46875 C 247.566406 153.09375 247.328125 153.59375 246.996094 153.96875 C 246.667969 154.339844 246.296875 154.609375 245.886719 154.769531 C 245.480469 154.929688 245.089844 155.003906 244.707031 154.996094 C 244.195312 154.972656 243.472656 154.851562 242.542969 154.628906 L 241.203125 154.304688 L 240.277344 158.160156 Z M 238.222656 157.664062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 242.3125 149.664062 L 241.621094 152.566406 L 242.746094 152.835938 C 243.554688 153.03125 244.109375 153.109375 244.410156 153.066406 C 244.707031 153.027344 244.960938 152.910156 245.167969 152.71875 C 245.378906 152.53125 245.523438 152.285156 245.59375 151.980469 C 245.683594 151.609375 245.648438 151.277344 245.488281 150.984375 C 245.328125 150.6875 245.089844 150.472656 244.769531 150.332031 C 244.535156 150.222656 244.050781 150.082031 243.308594 149.902344 Z M 242.3125 149.664062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(18.823529%,61.960784%,22.745098%);fill-opacity:1;"
      d="M 247.953125 160.578125 L 251.433594 150.664062 L 255.632812 152.152344 C 256.6875 152.523438 257.421875 152.882812 257.835938 153.234375 C 258.253906 153.578125 258.527344 154.03125 258.65625 154.585938 C 258.78125 155.144531 258.742188 155.714844 258.539062 156.300781 C 258.277344 157.046875 257.84375 157.585938 257.234375 157.917969 C 256.628906 158.246094 255.871094 158.320312 254.960938 158.140625 C 255.304688 158.546875 255.5625 158.949219 255.734375 159.347656 C 255.910156 159.75 256.101562 160.421875 256.308594 161.359375 L 256.835938 163.722656 L 254.449219 162.878906 L 253.765625 160.210938 C 253.523438 159.257812 253.34375 158.648438 253.226562 158.382812 C 253.105469 158.113281 252.957031 157.90625 252.769531 157.757812 C 252.585938 157.609375 252.265625 157.453125 251.804688 157.289062 L 251.402344 157.144531 L 249.949219 161.285156 Z M 247.953125 160.578125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 251.953125 155.570312 L 253.429688 156.089844 C 254.386719 156.429688 254.996094 156.601562 255.261719 156.601562 C 255.527344 156.605469 255.765625 156.53125 255.96875 156.382812 C 256.171875 156.230469 256.328125 156.007812 256.433594 155.710938 C 256.550781 155.375 256.554688 155.074219 256.445312 154.808594 C 256.34375 154.539062 256.140625 154.320312 255.835938 154.152344 C 255.679688 154.070312 255.199219 153.890625 254.390625 153.605469 L 252.835938 153.050781 Z M 251.953125 155.570312 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(18.823529%,61.960784%,22.745098%);fill-opacity:1;"
      d="M 260.257812 160.199219 C 260.574219 159.175781 260.992188 158.367188 261.511719 157.769531 C 261.898438 157.328125 262.351562 156.964844 262.867188 156.683594 C 263.390625 156.398438 263.914062 156.234375 264.445312 156.183594 C 265.148438 156.113281 265.910156 156.207031 266.722656 156.460938 C 268.195312 156.921875 269.230469 157.75 269.828125 158.941406 C 270.429688 160.136719 270.476562 161.550781 269.96875 163.1875 C 269.46875 164.804688 268.632812 165.9375 267.472656 166.582031 C 266.3125 167.21875 264.996094 167.308594 263.527344 166.847656 C 262.042969 166.382812 261 165.5625 260.40625 164.378906 C 259.808594 163.191406 259.761719 161.796875 260.257812 160.199219 Z M 260.257812 160.199219 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 262.371094 160.789062 C 262.015625 161.925781 262.007812 162.867188 262.351562 163.621094 C 262.691406 164.367188 263.265625 164.871094 264.070312 165.121094 C 264.875 165.371094 265.621094 165.289062 266.316406 164.871094 C 267.015625 164.449219 267.546875 163.65625 267.910156 162.492188 C 268.265625 161.339844 268.28125 160.402344 267.949219 159.679688 C 267.628906 158.957031 267.046875 158.464844 266.214844 158.207031 C 265.382812 157.945312 264.625 158.023438 263.9375 158.4375 C 263.25 158.851562 262.726562 159.632812 262.371094 160.789062 Z M 262.371094 160.789062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(18.823529%,61.960784%,22.745098%);fill-opacity:1;"
      d="M 280.730469 174.839844 L 285.539062 165.5 L 289.492188 167.554688 C 290.488281 168.070312 291.164062 168.53125 291.527344 168.933594 C 291.890625 169.332031 292.101562 169.820312 292.152344 170.390625 C 292.199219 170.957031 292.082031 171.519531 291.796875 172.070312 C 291.4375 172.773438 290.933594 173.246094 290.285156 173.488281 C 289.640625 173.730469 288.882812 173.699219 288.003906 173.394531 C 288.289062 173.84375 288.488281 174.277344 288.601562 174.699219 C 288.722656 175.121094 288.820312 175.8125 288.894531 176.769531 L 289.089844 179.183594 L 286.84375 178.015625 L 286.535156 175.28125 C 286.425781 174.300781 286.332031 173.675781 286.25 173.394531 C 286.171875 173.113281 286.046875 172.886719 285.886719 172.714844 C 285.722656 172.539062 285.425781 172.339844 284.996094 172.113281 L 284.617188 171.917969 L 282.605469 175.816406 Z M 280.730469 174.839844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 285.378906 170.417969 L 286.765625 171.140625 C 287.667969 171.609375 288.25 171.863281 288.515625 171.902344 C 288.777344 171.941406 289.019531 171.902344 289.246094 171.78125 C 289.46875 171.660156 289.648438 171.460938 289.796875 171.179688 C 289.957031 170.867188 290.003906 170.570312 289.933594 170.289062 C 289.867188 170.011719 289.695312 169.765625 289.417969 169.554688 C 289.277344 169.453125 288.824219 169.207031 288.0625 168.8125 L 286.597656 168.050781 Z M 285.378906 170.417969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(18.823529%,61.960784%,22.745098%);fill-opacity:1;"
      d="M 319.582031 194.027344 C 320.179688 193.140625 320.8125 192.484375 321.480469 192.0625 C 321.980469 191.753906 322.515625 191.539062 323.089844 191.417969 C 323.671875 191.296875 324.222656 191.289062 324.742188 191.394531 C 325.441406 191.535156 326.140625 191.84375 326.847656 192.320312 C 328.125 193.191406 328.878906 194.28125 329.105469 195.597656 C 329.339844 196.914062 328.976562 198.28125 328.023438 199.699219 C 327.074219 201.105469 325.953125 201.949219 324.65625 202.226562 C 323.359375 202.503906 322.074219 202.207031 320.800781 201.34375 C 319.511719 200.464844 318.75 199.375 318.519531 198.070312 C 318.292969 196.761719 318.648438 195.414062 319.582031 194.027344 Z M 319.582031 194.027344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 321.433594 195.191406 C 320.769531 196.175781 320.492188 197.078125 320.601562 197.898438 C 320.714844 198.714844 321.117188 199.355469 321.816406 199.832031 C 322.515625 200.304688 323.253906 200.441406 324.039062 200.242188 C 324.832031 200.042969 325.566406 199.4375 326.246094 198.425781 C 326.917969 197.429688 327.203125 196.535156 327.09375 195.746094 C 326.992188 194.960938 326.578125 194.324219 325.859375 193.832031 C 325.136719 193.339844 324.386719 193.195312 323.609375 193.394531 C 322.832031 193.589844 322.109375 194.191406 321.433594 195.191406 Z M 321.433594 195.191406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(18.823529%,61.960784%,22.745098%);fill-opacity:1;"
      d="M 226.367188 153.390625 L 228.054688 146.011719 L 233.503906 147.269531 L 233.222656 148.515625 L 229.257812 147.601562 L 228.878906 149.238281 L 232.570312 150.089844 L 232.285156 151.332031 L 228.597656 150.484375 L 228.136719 152.488281 L 232.242188 153.4375 L 231.960938 154.679688 Z M 226.367188 153.390625 "
    />
  </g>
</svg>
