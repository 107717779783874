import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];

  connect() {
    $(this.element).find("input").on("change", this.change.bind(this));
  }

  disconnect() {
    $(this.element).find("input").off("change");
  }

  change(event) {
    this.submit();
  }

  submit() {
    let self = this;
    $.ajax({
      url: $("#mass-mailings-form").attr("action"),
      dataType: "script",
      method: "POST",
      data: $("#mass-mailings-form").serialize(),
      beforeSend: function (data) {
        window.formHelper.setLoading(self.buttonTarget);
      },
    });
  }
}
