import { isAccountFeature } from "stores/account";
import { can } from "stores/permission.js";
import { theme } from "stores/theme.js";
import { user } from "stores/user.js";
import { writable, derived } from "svelte/store";

const adminPages = derived(
  [user, theme, isAccountFeature, can],
  ([$user, $theme, hasFeature, canManage]) => {
    return [
      {
        path: "team_users",
        svelte: false,
        showCard: true,
        hasPermission: true,
        icon: "personal",
      },
      {
        path: "permissions",
        svelte: false,
        showCard: $user.is_admin,
        hasPermission: $user.is_admin,
        icon: "userLock",
      },
      {
        path: "clients",
        svelte: false,
        showCard: true,
        hasPermission: true,
        icon: "building",
      },
      {
        path: "client_users",
        svelte: false,
        showCard: true,
        hasPermission: true,
        icon: "userMulti",
      },
      {
        path: "invoices",
        svelte: false,
        showCard: canManage("index", "delivery_note") && $theme !== "eyde",
        hasPermission: canManage("index", "delivery_note"),
        icon: "invoice",
      },
      {
        path: "logs",
        svelte: true,
        showCard: true,
        hasPermission: true,
        icon: "clipboard",
      },

      {
        path: "theme",
        svelte: true,
        showCard: hasFeature("show_whitelabel_addon"),
        hasPermission: $user.is_admin
          ? hasFeature("admin_theme")
          : hasFeature("admin_theme") && canManage("manage_theme", "account"),
        featureFlag: "whitelabel",
        icon: "colorPalette",
      },

      {
        path: "compliance_package",
        svelte: true,
        showCard: hasFeature("show_compliance_addon"),
        hasPermission: $user.is_admin
          ? hasFeature("compliance_package")
          : hasFeature("compliance_package") &&
            canManage("manage_compliance_package", "account"),
        featureFlag: "compliance",
        icon: "compliancePackage",
      },

      {
        path: "statistics",
        svelte: true,
        showCard: hasFeature("show_enterprise_addon"),
        hasPermission: $user.is_admin
          ? hasFeature("admin_statistics")
          : hasFeature("admin_statistics") &&
            canManage("manage_statistics", "account"),
        featureFlag: "enterprise",
        icon: "chart",
      },
      {
        path: "scim",
        svelte: true,
        showCard: hasFeature("show_enterprise_addon"),
        hasPermission: $user.is_admin
          ? hasFeature("admin_scim")
          : hasFeature("admin_scim") && canManage("manage_scim", "account"),
        featureFlag: "enterprise",
        icon: "synchronization",
      },
      {
        path: "default_settings",
        svelte: true,
        showCard: hasFeature("show_enterprise_addon"),
        hasPermission: $user.is_admin
          ? hasFeature("admin_default_settings")
          : hasFeature("admin_default_settings") &&
            canManage("manage_default_settings", "account"),
        featureFlag: "enterprise",
        icon: "defaultSettings",
      },
      {
        path: "sso",
        svelte: true,
        showCard: hasFeature("show_enterprise_addon"),
        hasPermission: $user.is_admin
          ? hasFeature("admin_sso")
          : hasFeature("admin_sso") && canManage("manage_sso", "account"),
        featureFlag: "enterprise",
        icon: "singleSignOn",
      },
      {
        path: "api",
        svelte: true,
        showCard: hasFeature("show_enterprise_addon"),
        hasPermission: $user.is_admin
          ? hasFeature("admin_api")
          : hasFeature("admin_api") && canManage("manage_api_key", "account"),
        featureFlag: "enterprise",
        icon: "api",
      },
    ];
  },
);

const currentAdminPage = writable("dashboard");
const userDirectory = writable(null);

//"compliance" || "whitelabel" || "enterprise" || null
const currentUpsellModal = writable(null);

export { adminPages, currentAdminPage, currentUpsellModal, userDirectory };
