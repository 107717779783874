<script>
  import { onMount } from "svelte";
  import { tick } from "svelte";

  import {
    ConfirmationSignerCropper,
    ConfirmationSignerDraw,
  } from "apps/confirmationSigner";
  import { ConfirmationSignerNameModal } from "components/modals";
  import {
    token,
    signer,
    signature,
  } from "stores/confirmation/confirmation_signer.js";
  import { isVertical, isDesktopWidth } from "stores/device.js";
  import { t } from "stores/i18n.js";
  import { toasts } from "stores/toasts";

  import ConfirmationMailingsApi from "apis/confirmation/confirmation_mailings.js";
  import { imagePath, blobPromiseFromUrl } from "lib/helpers.js";

  import { Button, Options, PageSwitcher, Tag, Toast } from "components";
  import styles from "styleguide/ConfirmationSignerSignatureItem.json";

  export let page = 1;
  export let pagesNumber = 1;
  export let index;
  export let signed;
  export let mailing_html;
  export let permalink;
  export let active;
  export let goNext;
  export let makeActive;
  export let single;
  export let sign_url;

  let loading;
  let loadingSignature;
  let previewBlock;
  let signatureImg;
  let signatureBlob;
  let signatureBlock;
  let drawShown = false;
  let cropperShown = false;
  let nameModalShown = false;
  let showSignatureWarning = false;
  let pages;
  let dirty = false;

  $: setPagesNumber(mailing_html);
  $: setSignatureField(mailing_html, active);
  $: setPreviousSignature();
  $: setPreviewScale($isDesktopWidth, $isVertical, active);

  async function setPreviewScale(isDesktopWidth) {
    await tick();
    let node = document.querySelector("#document");
    let page = document.querySelector("#document .page.active");

    if (page) {
      let pageWidth = page.clientWidth;
      let windowWidth = window.innerWidth;
      let diff = isDesktopWidth ? 1 : (windowWidth / pageWidth).toFixed(2);
      node.style = `transform: scale(${diff}); transform-origin: left top;`;

      if (previewBlock) {
        previewBlock.style.height = `${page.clientHeight * diff}px`;
      }
    }
  }

  async function setPagesNumber(mailing) {
    if (!mailing) return;
    pagesNumber = (mailing.match(/class="page"/g) || []).length;
  }

  async function setSignatureField(mailing) {
    if (!mailing || !active) return;
    await tick();
    let block = document.querySelector("[data-edit-signature]");

    if (!block) return;
    let fragment = document.createDocumentFragment();
    fragment.appendChild(signatureBlock);
    block.appendChild(fragment);

    pages = Array.from(
      document.querySelectorAll(`.document-${permalink} .page`),
    );
    pages.forEach((item, index) => {
      if (index > 0) {
        item.style.display = "none";
      } else {
        item.classList.add("active");
      }
    });
  }

  async function setPreviousSignature() {
    if (!sign_url) return;

    blobPromiseFromUrl(sign_url).then((blob) => {
      $signature.signatureImg = sign_url;
      $signature.signatureBlob = blob;
    });
  }

  function onMakeActive() {
    if (!signed && !active) makeActive(index);
  }

  function changePage(offset) {
    let oldIndex = page - 1;
    let newIndex = page - 1 + offset;
    if (pages[newIndex]) {
      pages[oldIndex].classList.remove("active");
      pages[oldIndex].style.display = "none";
      pages[newIndex].style.display = "block";
      pages[newIndex].classList.add("active");
      page += offset;
    }

    setPreviewScale($isDesktopWidth);
  }

  function showDraw() {
    drawShown = true;
  }

  function showCropper() {
    cropperShown = true;
  }

  function showNameModal() {
    nameModalShown = true;
  }

  function usePreviousSignature() {
    signatureImg = $signature.signatureImg;
    signatureBlob = $signature.signatureBlob;
  }

  function setSignature(img, blob, signer_token) {
    loadingSignature = true;

    ConfirmationMailingsApi.signature({
      params: {
        signature_image: new File([blob], "signature"),
        token: signer_token,
      },
      success: (data) => {
        data.blob().then((blob) => {
          signatureImg = URL.createObjectURL(blob);
          signatureBlob = blob;
          loadingSignature = false;
          showSignatureWarning = blob.size / 1024 < 2;
        });
      },
    });
  }

  function signConfirmationMailing(signatureImage) {
    ConfirmationMailingsApi.sign({
      params: {
        id: permalink,
        token: $token,
        name: $signer.name,
        job_title: $signer.job_title,
        signature_image: signatureImage,
      },
      success: () => {
        loading = false;
        goNext(index);
      },
    });
  }

  function sign() {
    if (!signatureBlob || !$signer.name) {
      dirty = true;
      page = 1;
      changePage(1);
      toasts.send({
        title: "Please fill in your name and signature",
        type: "error",
      });
      return;
    }
    $signature.signatureImg = signatureImg;
    $signature.signatureBlob = signatureBlob;
    const signatureImage = new File([signatureBlob], "signature_image");

    signConfirmationMailing(signatureImage);
  }

  onMount(async () => {});
</script>

<div
  data-component="ConfirmationSignerSignatureItem"
  class={`${styles.wrapper} ${permalink} ${active ? `document-${permalink}` : ""} ${!signed && !active ? styles.clickable : ""}`}
>
  <div class={styles.header}>
    <div class={styles.title} on:click={onMakeActive}>
      {#if !single && $isDesktopWidth}
        {#if pagesNumber > 1}
          <img class={styles.document} src={imagePath("pages")} alt="pages" />
        {:else}
          <img class={styles.document} src={imagePath("page")} alt="page" />
        {/if}
      {/if}

      <div>
        {#if !single}
          <div>{$t("confirmation_signer_intro.document")} {index + 1}</div>
        {/if}
        {#if signed}
          <div class={styles.description}>
            {pagesNumber}
            {$t("confirmation_signer_intro.pages")}
          </div>
        {:else}
          <div class={styles["toast-margin"]}>
            <Toast
              type="warning"
              message={$t("confirmation_signer_intro.cta_title")}
            />
          </div>
        {/if}
      </div>
    </div>

    {#if signed}
      <Tag
        label={$t("confirmation_signer_intro.signed")}
        style="medium green semi-bold"
      />
    {:else if pagesNumber > 1 && active && $isDesktopWidth}
      <PageSwitcher {page} {pagesNumber} {changePage} />
    {/if}
  </div>

  {#if active}
    <div class={styles["preview-wrapper"]}>
      <div class={styles.preview} bind:this={previewBlock}>
        {@html mailing_html}
      </div>
    </div>

    <div class={styles.actions}>
      <Button style="primary-text" click={showDraw}
        >{$t("confirmation_signer_intro.draw_signature")}</Button
      >

      <Button style="primary-text" click={showCropper}
        >{$t("confirmation_signer_intro.upload_signature")}</Button
      >

      <Button style="primary-text" click={showNameModal}
        >{$t("confirmation_signer_intro.edit_name")}</Button
      >

      {#if $signature.signatureImg && $signature.signatureBlob}
        <Button style="primary-text" click={usePreviousSignature}
          >{$t("confirmation_signer_intro.use_previous_signature")}</Button
        >
      {/if}
    </div>

    {#if showSignatureWarning}
      <div class={styles.warning}>
        <Toast
          type="warning"
          message={$t("confirmation_signer_intro.signature_warning")}
        />
      </div>
    {/if}

    <div class={styles.bottom}>
      {#if $isDesktopWidth}
        <div />
      {/if}

      {#if pagesNumber > 1 && active}
        <PageSwitcher {page} {pagesNumber} {changePage} />
      {/if}

      <Button {loading} style="primary" click={sign}
        >{$t("confirmation_signer_intro.confirm")}
      </Button>
    </div>
  {/if}

  <div class={styles.hidden}>
    <div class={styles["signature-block"]} bind:this={signatureBlock}>
      <Options style="top acts-as-button">
        <div slot="title" class={styles.signature}>
          {#if signatureImg}
            <img
              class={styles["signature-img"]}
              src={signatureImg}
              alt="signature"
            />
          {:else if loadingSignature}
            <div class={styles["signature-loading"]}>
              <img src={imagePath("loading-spinner")} alt={""} />
            </div>
          {:else}
            <div class={styles["signature-empty"]}>
              {$t("confirmation_signer_intro.no_signature")}
            </div>
          {/if}
        </div>

        <Button style="option blue-text" click={showDraw}
          >{$t("confirmation_signer_intro.draw_signature")}</Button
        >

        <Button style="option blue-text" click={showCropper}
          >{$t("confirmation_signer_intro.upload_signature")}</Button
        >

        {#if $signature.signatureImg && $signature.signatureBlob}
          <Button style="option blue-text" click={usePreviousSignature}
            >{$t("confirmation_signer_intro.use_previous_signature")}</Button
          >
        {/if}
      </Options>

      <div class={styles.details} on:click={showNameModal}>
        {#if $signer.name || $signer.job_title}
          <div class={styles.name}>
            {#if $signer.name}
              <p>{$signer.name}</p>
            {/if}
            {#if $signer.job_title}
              <p>{$signer.job_title}</p>
            {/if}
          </div>
        {:else}
          <div class={styles["name-empty"]}>
            {$t("confirmation_signer_intro.no_name")}
          </div>
        {/if}

        {#if dirty && (!$signer.name || !$signer.job_title)}
          <div class={styles.error}>
            {$t("confirmation_signer_intro.error")}
          </div>
        {/if}
      </div>
    </div>
  </div>

  {#if drawShown}
    <ConfirmationSignerDraw {setSignature} bind:shown={drawShown} />
  {/if}

  {#if cropperShown}
    <ConfirmationSignerCropper {setSignature} bind:shown={cropperShown} />
  {/if}

  {#if nameModalShown}
    <ConfirmationSignerNameModal bind:shown={nameModalShown} />
  {/if}
</div>

<style lang="scss">
  .wrapper {
    padding: 25px 15px;

    &:not(:last-child) {
      border-bottom: 1px solid var(--primary-050);
    }
  }

  @media only screen and (min-width: 1023px) {
    .wrapper {
      padding: 15px 35px;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 1.6;
    gap: 1em;
  }

  .title {
    display: flex;
    align-items: center;
  }

  .document {
    width: 42px;
    height: 60px;
    margin-right: 24px;
  }

  .description {
    color: var(--primary-300);
  }

  .preview-wrapper {
    overflow: hidden;
    margin-top: 20px;
    box-shadow: 0 3px 8px 0 rgba(41, 49, 61, 0.06);
  }

  @media only screen and (max-width: 1024px) {
    .preview-wrapper {
      margin-right: -15px;
      margin-left: -15px;
      border-top: 1px solid var(--primary-050);
      border-bottom: 1px solid var(--primary-050);
    }
  }

  @media only screen and (min-width: 1023px) {
    .preview-wrapper {
      border-radius: 3px;
      border: 1px solid var(--primary-050);
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }

  .signature {
    display: flex;
    height: 75px;
    align-items: flex-end;
    justify-content: flex-start;
    border-bottom: 1px solid black;
  }

  .signature-img {
    display: block;
    max-height: 75px;
    max-width: 100%;
    padding-bottom: 5px;
  }

  .warning {
    margin: 16px 0;
  }

  .signature-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 75px;
    margin: 0 auto;
    text-align: center;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    border: solid 1px rgba(70, 132, 230, 0.15);
    background-color: rgba(70, 132, 230, 0.15);
    padding: 10.5% 0;
    color: var(--blue-200);
  }

  .signature-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 75px;
    margin: 0 auto;
  }

  .details {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    p {
      margin: 0;
    }
  }

  .name {
    padding: 4px 0;
    line-height: 1.5;
    // color: var(--blue-200);

    &:hover {
      filter: brightness(0.5);
    }
  }

  .name-empty {
    text-align: center;
    border-radius: var(--border-radius);
    border: solid 1px rgba(70, 132, 230, 0.15);
    background-color: rgba(70, 132, 230, 0.15);
    padding: 5%;
    color: var(--blue-200);
  }

  .clickable {
    cursor: pointer;
  }

  .hidden {
    display: none;
  }

  .toast-margin {
    margin-top: 20px;
  }

  .actions {
    padding-top: 16px;
    gap: 8px;
    display: flex;
    flex-wrap: wrap;
  }

  .error {
    color: var(--red-100);
    padding-top: 8px;
    font-size: 12px;
    z-index: 0;
  }
</style>
