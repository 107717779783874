<script>
  import styles from "styleguide/AuditTemplateGroup.json";

  import { Boundary } from "components";
  import TriangleRight from "components/icons/TriangleRight.svelte";

  export let open = false;
  export let name;
  export let control_templates = [];
</script>

<Boundary>
  <div
    data-component="AuditTemplateGroup"
    class={styles["template-group"]}
    on:click={() => {
      open = !open;
    }}
  >
    <div class={`${styles.icon} ${open ? styles.open : ""}`}>
      <TriangleRight fill="var(--blue-100)" />
    </div>
    {name}
  </div>
  {#if open}
    {#each control_templates as { name }}
      <div class={styles["template-control"]}>{name}</div>
    {/each}
  {/if}
</Boundary>

<style lang="scss">
  .icon {
    margin-right: 10px;
    display: flex;

    &.open {
      transform: rotate(90deg);
    }
  }

  .template-group {
    display: flex;
    align-items: center;
    margin-top: 5px;
    cursor: pointer;
  }

  .template-control {
    margin: 5px 0 0 40px;
  }
</style>
