<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(100%,100%,100%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(13.72549%,62.352941%,25.098039%);fill-opacity:1;"
      d="M -119.777344 261.863281 L 599.238281 261.863281 L 599.238281 359.683594 L -119.777344 359.683594 Z M -119.777344 261.863281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(80.392157%,9.019608%,21.960784%);fill-opacity:1;"
      d="M -119.777344 0 L 599.238281 0 L 599.238281 97.820312 L -119.777344 97.820312 Z M -119.777344 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 172.636719 149.242188 L 175.226562 157.761719 L 183.601562 157.574219 L 176.652344 162.78125 L 179.414062 171.0625 L 172.535156 165.761719 L 165.863281 171.0625 L 168.558594 162.578125 L 161.671875 157.574219 L 170.21875 157.632812 Z M 172.636719 149.242188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 164.3125 185.753906 L 166.898438 194.273438 L 175.277344 194.085938 L 168.328125 199.292969 L 171.089844 207.570312 L 164.210938 202.273438 L 157.535156 207.570312 L 160.230469 199.089844 L 153.347656 194.085938 L 161.890625 194.144531 Z M 164.3125 185.753906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 200.820312 119.453125 L 203.40625 127.976562 L 211.785156 127.785156 L 204.832031 132.996094 L 207.59375 141.273438 L 200.71875 135.972656 L 194.042969 141.273438 L 196.738281 132.789062 L 189.855469 127.785156 L 198.398438 127.847656 Z M 200.820312 119.453125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 238.773438 109.527344 L 241.359375 118.046875 L 249.738281 117.859375 L 242.789062 123.066406 L 245.550781 131.34375 L 238.671875 126.046875 L 231.996094 131.34375 L 234.691406 122.863281 L 227.808594 117.859375 L 236.351562 117.917969 Z M 238.773438 109.527344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 277.046875 119.453125 L 279.632812 127.976562 L 288.011719 127.785156 L 281.0625 132.996094 L 283.820312 141.273438 L 276.945312 135.972656 L 270.269531 141.273438 L 272.964844 132.789062 L 266.082031 127.785156 L 274.625 127.847656 Z M 277.046875 119.453125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 305.230469 149.402344 L 307.816406 157.921875 L 316.195312 157.734375 L 309.246094 162.941406 L 312.007812 171.222656 L 305.128906 165.921875 L 298.453125 171.222656 L 301.148438 162.738281 L 294.265625 157.734375 L 302.808594 157.792969 Z M 305.230469 149.402344 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 313.398438 185.753906 L 315.984375 194.273438 L 324.363281 194.085938 L 317.410156 199.292969 L 320.171875 207.570312 L 313.296875 202.273438 L 306.621094 207.570312 L 309.316406 199.089844 L 302.433594 194.085938 L 310.976562 194.144531 Z M 313.398438 185.753906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 253.382812 165.835938 C 253.382812 171.882812 246.878906 176.789062 238.859375 176.789062 C 230.839844 176.789062 224.335938 171.882812 224.335938 165.835938 C 224.335938 159.785156 230.839844 154.878906 238.859375 154.878906 C 246.878906 154.878906 253.382812 159.785156 253.382812 165.835938 Z M 253.382812 165.835938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 234.671875 173.566406 L 242.96875 173.566406 L 242.96875 212.179688 L 234.671875 212.179688 Z M 234.671875 173.566406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 311.644531 209.265625 C 308.035156 216.925781 303.175781 219.640625 295.25 219.429688 C 281.371094 219.757812 278.238281 209.566406 276.425781 205.066406 C 271.523438 211.53125 268.734375 214.101562 260.59375 213.945312 C 248.992188 214.203125 243.136719 207.601562 238.8125 195.117188 C 234.488281 207.523438 228.632812 214.125 217.03125 213.867188 C 208.890625 214.023438 206.101562 211.453125 201.195312 204.988281 C 199.386719 209.488281 196.253906 219.679688 182.375 219.351562 C 174.445312 219.5625 169.589844 216.84375 166.339844 209.078125 C 170.113281 219.925781 173.847656 225.992188 185.429688 226.371094 C 195.921875 226.496094 200.726562 220.421875 203 216.265625 C 210.558594 220.28125 211.335938 221.171875 222.050781 220.921875 C 230.046875 220.664062 234.640625 215.4375 238.78125 210.464844 C 242.921875 215.4375 247.515625 220.664062 255.511719 220.921875 C 266.230469 221.171875 267.007812 220.28125 274.5625 216.265625 C 276.835938 220.421875 281.640625 226.496094 292.132812 226.371094 C 303.714844 225.992188 307.453125 219.925781 311.644531 209.265625 Z M 311.644531 209.265625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 203.777344 220.445312 L 207.566406 225.691406 L 214.824219 225.476562 L 209.222656 231.828125 L 198.253906 232.109375 L 194.820312 227.511719 L 201.15625 226.488281 L 203.816406 220.457031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 273.703125 220.445312 L 269.917969 225.691406 L 262.65625 225.476562 L 268.261719 231.828125 L 279.230469 232.109375 L 282.660156 227.511719 L 276.324219 226.488281 L 273.664062 220.457031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 229.6875 223.8125 C 229.6875 223.785156 234.671875 223.839844 234.671875 223.839844 L 238.859375 220.5 L 242.949219 223.796875 L 247.890625 223.867188 L 243.445312 230.4375 L 234.105469 230.4375 Z M 229.6875 223.8125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 292.714844 247.203125 C 273.34375 243.207031 260.378906 241.238281 238.789062 241.238281 C 217.199219 241.238281 204.234375 243.207031 184.984375 247.15625 L 184.957031 238.804688 C 204.234375 234.816406 217.199219 232.847656 238.789062 232.847656 C 260.378906 232.847656 273.34375 234.816406 292.621094 238.804688 Z M 292.714844 247.203125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 246.425781 165.871094 C 246.425781 168.25 243.027344 170.175781 238.839844 170.175781 C 234.648438 170.175781 231.25 168.25 231.25 165.871094 C 231.25 163.496094 234.648438 161.570312 238.839844 161.570312 C 243.027344 161.570312 246.425781 163.496094 246.425781 165.871094 Z M 246.425781 165.871094 "
    />
  </g>
</svg>
