import { Controller } from "stimulus";
import Helper from "../../general/helper";

export default class extends Controller {
  deleteUploadEvent(e) {
    e.preventDefault();
    let target = e.target.closest(".btn");
    Helper.confirmDelete(
      target.getAttribute("data-url"),
      {},
      this.deleteUploadResponse.bind(this),
    );
  }

  deleteUploadResponse(response) {
    if (response["template"]) {
      document.querySelector("[data-attachments]").innerHTML =
        response["template"];
    }
  }
}
