<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(29.019608%,67.843137%,83.921569%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,87.058824%,0%);fill-opacity:1;"
      d="M 304.433594 174.089844 C 304.433594 227.980469 258.808594 271.671875 202.527344 271.671875 C 146.246094 271.671875 100.621094 227.980469 100.621094 174.089844 C 100.621094 120.195312 146.246094 76.503906 202.527344 76.503906 C 258.808594 76.503906 304.433594 120.195312 304.433594 174.089844 Z M 304.433594 174.089844 "
    />
  </g>
</svg>
