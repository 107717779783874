<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M -144 120.855469 L 623.984375 120.855469 L 623.984375 239.136719 L -144 239.136719 Z M -144 120.855469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(23.921569%,14.509804%,79.215686%);fill-opacity:1;"
      d="M -144 239.136719 L 623.984375 239.136719 L 623.984375 359.996094 L -144 359.996094 Z M -144 239.136719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(83.921569%,0%,0%);fill-opacity:1;"
      d="M -144 0 L 623.984375 0 L 623.984375 120.855469 L -144 120.855469 Z M -144 0 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:12.91;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 744.133364 567.26646 C 744.133364 759.745469 587.060417 915.781708 393.259385 915.781708 C 199.486114 915.781708 42.413168 759.745469 42.413168 567.26646 C 42.413168 374.787451 199.486114 218.751213 393.259385 218.751213 C 587.060417 218.751213 744.133364 374.787451 744.133364 567.26646 Z M 744.133364 567.26646 "
      transform="matrix(0.140709,0,0,0.132807,184.664902,104.667126)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 259.013414 105.701058 C 259.013414 105.701058 659.356248 176.318231 673.495012 501.31327 C 687.633776 826.308308 485.085067 868.708113 414.428077 920.484359 C 343.790226 972.337425 301.435901 1033.52291 268.447438 1033.491693 C 235.478114 1033.537298 188.390004 1019.349601 188.390004 1019.349601 C 188.390004 1019.349601 197.826229 1071.160113 263.721144 1066.459581 C 329.690857 1061.742584 390.923549 953.453218 428.602525 934.624373 C 466.2815 915.795528 706.462956 835.696088 687.618514 506.064532 C 673.457375 152.793486 263.726303 115.117005 259.013414 105.701058 Z M 259.013414 105.701058 "
      transform="matrix(0.0495081,0.0106115,-0.0123343,0.048205,235.125004,150.624909)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.214664 487.15363 C 320.244646 547.015651 308.626872 595.557283 294.370429 595.499701 C 280.113987 595.44212 268.510985 547.026648 268.481003 487.164626 C 268.45102 427.302605 280.025549 378.936757 294.325237 378.818555 C 308.740736 378.888776 320.343738 427.304248 320.214664 487.15363 Z M 320.214664 487.15363 "
      transform="matrix(0.024088,-0.00173203,0.00592607,0.0217958,245.614616,151.772665)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.315842 487.185363 C 320.331546 546.957259 308.598055 595.552665 294.302225 595.489365 C 280.158067 595.480575 268.490668 547.120533 268.403371 487.134963 C 268.387667 427.363067 280.04108 378.926825 294.416989 378.83096 C 308.712818 378.89426 320.300138 427.413467 320.315842 487.185363 Z M 320.315842 487.185363 "
      transform="matrix(0.0218109,-0.00746962,0.0109735,0.0207841,243.226073,154.266929)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.250132 487.273377 C 320.314586 547.099427 308.618819 595.599368 294.283416 595.493086 C 279.992983 595.556159 268.442312 546.987271 268.377858 487.161221 C 268.477294 427.31849 280.009171 378.83523 294.344575 378.941512 C 308.635007 378.878439 320.349568 427.430645 320.250132 487.273377 Z M 320.250132 487.273377 "
      transform="matrix(0.0022859,-0.0232073,0.0224585,0.00616259,241.992485,167.605062)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.356237 487.232969 C 320.322816 547.026865 308.709613 595.58779 294.303218 595.548457 C 280.046083 595.432363 268.377919 547.003764 268.41134 487.209869 C 268.444761 427.415973 280.057964 378.855048 294.464359 378.894381 C 308.627657 378.867753 320.295821 427.296352 320.356237 487.232969 Z M 320.356237 487.232969 "
      transform="matrix(0.0105189,-0.0195577,0.0204537,0.0128588,240.609793,162.527541)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.305293 487.261988 C 320.328141 547.061758 308.627823 595.569938 294.366338 595.601036 C 279.98135 595.520471 268.414128 546.979079 268.39128 487.17931 C 268.368433 427.37954 280.068751 378.87136 294.330235 378.840262 C 308.715224 378.920826 320.282445 427.462219 320.305293 487.261988 Z M 320.305293 487.261988 "
      transform="matrix(0.0159242,-0.0153016,0.0173696,0.0169243,241.181939,158.873376)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 151.44435 682.806134 C 151.580977 684.313515 109.970618 657.587298 101.549111 649.462198 C 93.275615 641.02838 94.975828 602.829961 68.212214 584.568672 C 41.596613 565.998666 13.204886 574.433839 -0.0137132 585.873575 C -13.380324 597.622029 -8.435187 624.349601 23.262162 647.853997 C 54.815295 671.061745 76.569244 649.586123 93.317374 657.669915 C 109.917492 666.062424 136.544478 682.816332 149.899722 696.18454 C 163.110732 685.955536 196.48932 674.288344 214.801052 665.966899 C 233.112783 657.645455 279.732774 677.507456 311.43009 654.410726 C 337.984943 636.065465 349.76515 597.753319 331.286417 586.10975 C 313.099912 574.454111 279.763065 579.462274 259.823204 591.179618 C 239.739126 602.600313 229.803382 649.611815 209.88629 657.696961 C 194.808048 662.858806 153.178749 685.760546 151.44435 682.806134 Z M 151.44435 682.806134 "
      transform="matrix(0.0136371,-0.013104,0.00653819,0.00637056,249.536821,162.083882)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.203778 487.171355 C 320.229522 546.974234 308.695367 595.483829 294.321793 595.490778 C 280.063596 595.471619 268.485 546.975989 268.459257 487.17311 C 268.433513 427.370231 280.083045 378.834529 294.341242 378.853687 C 308.714817 378.846739 320.293412 427.342369 320.203778 487.171355 Z M 320.203778 487.171355 "
      transform="matrix(0.033971,0.00762574,0.000507926,0.0337012,250.804596,147.409481)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.233283 487.2347 C 320.2074 547.014959 308.616448 595.477745 294.33517 595.516981 C 280.053893 595.556217 268.457938 547.072857 268.510714 487.180156 C 268.418931 427.394602 280.009882 378.931815 294.408826 378.897874 C 308.690104 378.858638 320.286059 427.341998 320.233283 487.2347 Z M 320.233283 487.2347 "
      transform="matrix(0.0328443,-0.00154671,0.00785544,0.03437,246.615666,149.97952)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.242665 487.143367 C 320.190743 546.98838 308.670272 595.538589 294.361152 595.488015 C 280.114506 595.547943 268.435991 547.063404 268.487913 487.218392 C 268.440807 427.412282 280.060305 378.82317 294.369425 378.873744 C 308.678545 378.924318 320.195559 427.337258 320.242665 487.143367 Z M 320.242665 487.143367 "
      transform="matrix(0.0113634,-0.0322773,0.0289257,0.0182484,240.195788,168.564196)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.235735 487.185976 C 320.250164 546.955825 308.634408 595.547749 294.330268 595.488945 C 280.118553 595.510923 268.515385 547.026422 268.40853 487.17579 C 268.475526 427.332417 280.102283 378.894799 294.313998 378.872821 C 308.699563 378.858102 320.221307 427.416126 320.235735 487.185976 Z M 320.235735 487.185976 "
      transform="matrix(0.021476,-0.0235963,0.023784,0.0269973,240.074469,160.712295)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.252464 487.178726 C 320.257751 547.028306 308.647379 595.565512 294.342883 595.468494 C 280.08443 595.51763 268.470852 547.034195 268.465566 487.184615 C 268.46028 427.335035 280.008766 378.891833 294.375147 378.894847 C 308.6336 378.845711 320.247178 427.329146 320.252464 487.178726 Z M 320.252464 487.178726 "
      transform="matrix(0.0274583,-0.0152331,0.0180768,0.0315257,242.157607,155.719023)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 151.499941 682.526526 C 151.516705 684.190833 109.822522 657.77707 101.612147 649.409613 C 93.203477 641.153345 94.836119 602.788686 68.253846 584.429136 C 41.671574 566.069585 13.339317 574.424543 -0.0035852 586.090525 C -13.346487 597.756508 -8.267202 624.407506 23.374725 647.67087 C 54.944387 671.183969 76.511561 649.466874 93.245082 657.657873 C 109.906339 666.098607 136.544113 682.544116 149.940172 695.953932 C 163.101544 686.063445 196.535667 674.429311 214.723167 666.114023 C 233.10896 657.687548 279.799217 677.743253 311.296092 654.433368 C 338.032186 636.094326 349.611291 597.801184 331.420924 586.033595 C 313.032264 574.377194 279.657116 579.492645 259.683238 591.110949 C 239.70936 602.729252 229.835684 649.480717 209.828279 657.770406 C 194.796712 662.642372 153.15508 685.991927 151.499941 682.526526 Z M 151.499941 682.526526 "
      transform="matrix(0.0235147,-0.0130453,0.00680437,0.0118668,252.594138,163.822302)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.24173 487.243711 C 320.319565 547.017214 308.675965 595.550238 294.396005 595.538179 C 280.001778 595.572848 268.502701 547.024027 268.424866 487.250524 C 268.461298 427.430292 280.104898 378.897268 294.384858 378.909327 C 308.664818 378.921386 320.278161 427.423479 320.24173 487.243711 Z M 320.24173 487.243711 "
      transform="matrix(0.0317366,0.0136492,-0.00598825,0.0333765,257.773891,152.999271)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.317377 487.145622 C 320.27876 547.025242 308.678414 595.524775 294.331081 595.537312 C 280.104803 595.534828 268.436129 547.050035 268.474745 487.170414 C 268.509716 427.401716 280.110062 378.902183 294.33634 378.904666 C 308.683673 378.89213 320.231292 427.391943 320.317377 487.145622 Z M 320.317377 487.145622 "
      transform="matrix(0.0324002,0.00438711,0.00106466,0.0353602,253.185028,154.783217)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.305478 487.226732 C 320.271116 547.040569 308.673756 595.537254 294.343556 595.463075 C 280.092891 595.491027 268.409037 547.027809 268.443398 487.213972 C 268.47776 427.400135 280.075119 378.90345 294.325784 378.875498 C 308.655985 378.949677 320.260304 427.310764 320.305478 487.226732 Z M 320.305478 487.226732 "
      transform="matrix(0.0173175,-0.0298324,0.0247612,0.0232321,243.334092,171.982291)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.279445 487.237019 C 320.226858 547.034123 308.590674 595.516528 294.349651 595.533789 C 280.002681 595.484506 268.471819 547.093098 268.418459 487.229449 C 268.471046 427.432345 280.001282 378.883396 294.348253 378.932679 C 308.651078 378.828011 320.226085 427.37337 320.279445 487.237019 Z M 320.279445 487.237019 "
      transform="matrix(0.0255314,-0.0194375,0.0180522,0.030947,244.726359,164.2055)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.247526 487.251678 C 320.311998 547.014263 308.635181 595.491546 294.33182 595.493403 C 280.02846 595.495261 268.481999 547.01258 268.457248 487.148078 C 268.392776 427.385492 280.069593 378.90821 294.372954 378.906352 C 308.676314 378.904495 320.222775 427.387176 320.247526 487.251678 Z M 320.247526 487.251678 "
      transform="matrix(0.0297694,-0.0101002,0.0116025,0.0343917,247.723252,159.649024)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 151.582006 682.706489 C 151.578683 684.514732 109.918725 657.584732 101.537057 649.268799 C 93.292874 641.044727 94.923056 602.907231 68.206027 584.452676 C 41.626484 566.089983 13.391978 574.381961 0.017606 586.266162 C -13.310384 597.879609 -8.357704 624.423038 23.351885 647.722106 C 54.923988 670.929313 76.529393 649.457508 93.216582 657.589667 C 109.857388 665.992578 136.57774 682.63889 149.905586 696.070091 C 163.139149 685.902271 196.518655 674.409576 214.796145 666.103153 C 233.073635 657.796731 279.747754 677.817665 311.359881 654.281309 C 338.052273 636.208391 349.685852 597.814586 331.307432 586.138475 C 313.066499 574.554226 279.745007 579.447318 259.809375 591.172006 C 239.736257 602.804833 229.7856 649.263198 209.810232 657.642154 C 194.842379 662.728531 153.182134 685.887826 151.582006 682.706489 Z M 151.582006 682.706489 "
      transform="matrix(0.0254939,-0.00864963,0.00436735,0.0129456,256.364905,169.53169)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.288194 487.17685 C 320.230645 546.989087 308.719225 595.501343 294.326814 595.49545 C 280.0983 595.483119 268.431143 546.983671 268.488692 487.171435 C 268.448226 427.444643 280.057661 378.846942 294.352057 378.938281 C 308.678586 378.865166 320.247729 427.450059 320.288194 487.17685 Z M 320.288194 487.17685 "
      transform="matrix(0.0230778,0.024958,-0.0192438,0.0286292,267.233606,159.910338)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.269647 487.141247 C 320.238415 547.040623 308.708762 595.521106 294.371214 595.524171 C 280.033666 595.527236 268.398537 546.999473 268.476605 487.194293 C 268.394169 427.351838 280.084326 378.908631 294.421874 378.905565 C 308.598917 378.865225 320.234047 427.392988 320.269647 487.141247 Z M 320.269647 487.141247 "
      transform="matrix(0.0275144,0.0166265,-0.0136805,0.0332024,262.340581,159.785959)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.278606 487.231326 C 320.280811 547.067202 308.609563 595.536859 294.406316 595.514244 C 280.052656 595.515869 268.444661 547.063754 268.442456 487.227878 C 268.44025 427.392003 280.004888 378.850617 294.358547 378.848992 C 308.668405 378.943336 320.2764 427.39545 320.278606 487.231326 Z M 320.278606 487.231326 "
      transform="matrix(0.0280322,-0.0209515,0.0127944,0.0311406,246.299783,171.916527)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.310574 487.198117 C 320.306911 547.058928 308.682231 595.518424 294.389269 595.546629 C 280.096306 595.574834 268.412325 547.060284 268.415988 487.199473 C 268.419651 427.338663 280.044331 378.879166 294.337293 378.850961 C 308.630255 378.822756 320.314236 427.337307 320.310574 487.198117 Z M 320.310574 487.198117 "
      transform="matrix(0.031163,-0.00812853,0.00352571,0.0356943,250.780905,165.244714)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.314584 487.19746 C 320.220364 546.995841 308.614221 595.565933 294.309948 595.561191 C 280.005674 595.55645 268.398518 546.976794 268.492738 487.178413 C 268.462381 427.387471 280.081327 378.923962 294.3856 378.928704 C 308.67707 378.826862 320.284227 427.406518 320.314584 487.19746 Z M 320.314584 487.19746 "
      transform="matrix(0.0311327,0.0021726,-0.00373972,0.0363887,255.380983,162.181066)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 151.575792 682.712774 C 151.520023 684.431456 109.971805 657.849312 101.608106 649.289123 C 93.259356 641.012088 94.907738 602.858647 68.299281 584.513802 C 41.690824 566.168957 13.272388 574.592125 -0.0389789 586.096545 C -13.335396 597.88412 -8.278372 624.289723 23.284137 647.665323 C 54.992115 671.021164 76.553291 649.270229 93.268596 657.836716 C 109.96895 666.120049 136.487706 682.765972 149.790507 696.073763 C 163.176625 685.985112 196.551967 674.321962 214.873155 666.129616 C 233.179393 657.654115 279.796056 677.558914 311.394679 654.444543 C 337.965675 636.005681 349.726255 597.904573 331.376846 586.137841 C 313.027437 574.371109 279.744651 579.462443 259.696802 591.026359 C 239.809374 602.85367 229.806532 649.27155 209.739701 657.701016 C 194.760298 662.587587 153.209897 685.913019 151.575792 682.712774 Z M 151.575792 682.712774 "
      transform="matrix(0.0266614,0.00186057,-0.00140769,0.0136973,259.111237,174.694803)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.302342 487.217866 C 320.309071 547.029476 308.6922 595.510376 294.35051 595.492054 C 280.088944 595.516475 268.428644 547.005138 268.421915 487.193529 C 268.415187 427.381919 280.032058 378.901019 294.373748 378.919341 C 308.635314 378.89492 320.215489 427.363514 320.302342 487.217866 Z M 320.302342 487.217866 "
      transform="matrix(0.0166545,0.0353761,-0.0312197,0.0250748,272.763041,166.639579)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.272893 487.209279 C 320.24852 547.026905 308.643822 595.504158 294.329752 595.558633 C 280.029312 595.479286 268.484898 547.009588 268.509271 487.191961 C 268.382973 427.382612 280.06982 378.83431 294.370261 378.913657 C 308.684331 378.859182 320.297266 427.391652 320.272893 487.209279 Z M 320.272893 487.209279 "
      transform="matrix(0.024459,0.0276843,-0.0266988,0.0320096,267.420447,164.842792)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.280517 487.242383 C 320.229309 547.059856 308.642202 595.553927 294.334391 595.464617 C 280.018451 595.473351 268.493218 547.021392 268.445072 487.168966 C 268.496279 427.351493 280.083386 378.857423 294.399326 378.848689 C 308.707138 378.937998 320.23237 427.389958 320.280517 487.242383 Z M 320.280517 487.242383 "
      transform="matrix(0.0382024,-0.0136194,0.00316724,0.0387129,245.510379,172.792421)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.298175 487.220956 C 320.198626 546.979362 308.666899 595.544427 294.364676 595.529613 C 280.062452 595.514799 268.473868 546.993079 268.469231 487.23877 C 268.442442 427.389109 280.100508 378.915299 294.380578 378.834761 C 308.682801 378.849574 320.271386 427.371295 320.298175 487.220956 Z M 320.298175 487.220956 "
      transform="matrix(0.0371535,0.00159668,-0.00863197,0.0405958,252.782035,166.947743)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.22851 487.199828 C 320.281485 547.033914 308.661038 595.492209 294.346787 595.540427 C 279.989233 595.502647 268.409308 547.03986 268.456162 487.172521 C 268.446492 427.424434 280.023635 378.88014 294.381189 378.91792 C 308.69544 378.869703 320.275365 427.33249 320.22851 487.199828 Z M 320.22851 487.199828 "
      transform="matrix(0.0335089,0.012957,-0.0168731,0.0388982,258.919755,165.126171)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 151.571516 682.750377 C 151.525197 684.386194 109.960171 657.726116 101.583629 649.400892 C 93.207086 641.075667 94.921279 602.693353 68.288967 584.458964 C 41.656655 566.224575 13.249542 574.413063 0.03067 586.031447 C -13.304773 597.738173 -8.356265 624.401638 23.325286 647.613494 C 54.940833 671.142157 76.544341 649.434633 93.194745 657.625748 C 109.895715 666.045328 136.574345 682.643899 149.883556 696.034932 C 163.17268 685.964025 196.526352 674.293667 214.840782 666.016933 C 233.155212 657.740198 279.759435 677.646772 311.385374 654.480019 C 338.08444 636.114145 349.607607 597.768295 331.34759 585.994848 C 313.021568 574.538208 279.686034 579.525171 259.794419 591.131432 C 239.786233 602.826035 229.799728 649.344863 209.767609 657.856172 C 194.798619 662.797271 153.247134 686.015903 151.571516 682.750377 Z M 151.571516 682.750377 "
      transform="matrix(0.0286963,0.0110961,-0.0063513,0.0146419,258.639152,180.204209)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.262833 487.219049 C 320.268504 547.025409 308.651439 595.525017 294.37751 595.488067 C 280.089187 595.539886 268.455587 547.040434 268.449916 487.234073 C 268.458639 427.338944 280.075704 378.839336 294.349633 378.876286 C 308.623562 378.913236 320.257161 427.412689 320.262833 487.219049 Z M 320.262833 487.219049 "
      transform="matrix(-0.00453646,0.0426736,-0.0447403,0.00691934,280.044163,180.438574)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.207806 487.229413 C 320.260949 547.040486 308.714944 595.488874 294.387794 595.539428 C 280.093855 595.507629 268.465803 547.027582 268.412661 487.216509 C 268.485123 427.338637 280.031128 378.890249 294.358278 378.839695 C 308.652217 378.871494 320.280269 427.351541 320.207806 487.229413 Z M 320.207806 487.229413 "
      transform="matrix(0.00747738,0.0395901,-0.0444173,0.0159657,275.934608,175.83303)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.283588 487.205836 C 320.245946 547.022368 308.641037 595.483514 294.359677 595.521469 C 280.078317 595.559424 268.455259 547.01208 268.492901 487.195548 C 268.450387 427.39516 280.014738 378.840812 294.336656 378.896059 C 308.618015 378.858104 320.241074 427.405448 320.283588 487.205836 Z M 320.283588 487.205836 "
      transform="matrix(0.0448056,0.007761,-0.0194977,0.0385342,250.156684,171.513652)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.216143 487.173262 C 320.229826 547.035224 308.69359 595.504847 294.404354 595.533029 C 280.048589 595.488555 268.460338 547.046912 268.446654 487.18495 C 268.499499 427.395644 280.106309 378.880959 294.324972 378.89784 C 308.614208 378.869658 320.268988 427.383957 320.216143 487.173262 Z M 320.216143 487.173262 "
      transform="matrix(0.0349283,0.0216632,-0.0319826,0.0339273,260.580052,169.897927)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 320.279271 487.175806 C 320.213681 547.010388 308.692099 595.540807 294.347457 595.536559 C 280.002815 595.532311 268.427068 547.042633 268.492657 487.208052 C 268.41931 427.385324 280.021909 378.906476 294.366551 378.910724 C 308.630176 378.863401 320.286939 427.40465 320.279271 487.175806 Z M 320.279271 487.175806 "
      transform="matrix(0.0247921,0.0304909,-0.0389475,0.0278445,267.565107,171.493447)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 151.537952 682.674553 C 151.51056 684.233575 109.904296 657.81773 101.578326 649.490215 C 93.252355 641.162701 94.896886 602.777707 68.281629 584.483186 C 41.666373 566.188666 13.355184 574.480961 0.0225738 586.080508 C -13.310036 597.680055 -8.313963 624.366187 23.288743 647.752017 C 54.959083 670.969348 76.648859 649.357544 93.221582 657.637945 C 109.88891 666.055352 136.531558 682.790851 149.912612 696.041175 C 163.21783 686.00065 196.437251 674.425036 214.757733 666.052271 C 233.078214 657.679506 279.742097 677.830995 311.423003 654.435133 C 338.033017 636.21861 349.735889 597.745587 331.379391 586.082552 C 313.022893 574.419518 279.723832 579.485031 259.79382 591.122269 C 239.701569 602.791 229.7949 649.46073 209.825067 657.842918 C 194.831101 662.773288 153.159488 686.08868 151.537952 682.674553 Z M 151.537952 682.674553 "
      transform="matrix(0.0212315,0.0261118,-0.0146604,0.0104811,258.509681,185.680866)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 314.685074 506.231181 C 314.704794 551.286965 304.634035 587.796448 292.263533 587.768822 C 279.826673 587.80539 269.737529 551.291184 269.784168 506.171205 C 269.764449 461.115421 279.835208 424.605938 292.205709 424.633564 C 304.64257 424.596996 314.731714 461.111202 314.685074 506.231181 Z M 314.685074 506.231181 "
      transform="matrix(-0.000179465,0.0596789,-0.0610354,0.000840861,279.747451,182.356768)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 314.741098 506.215393 C 314.743489 551.271202 304.626727 587.80828 292.202149 587.787938 C 279.819043 587.818239 269.722948 551.24537 269.720557 506.18956 C 269.718166 461.133751 279.834929 424.596673 292.259506 424.617016 C 304.642612 424.586714 314.697236 461.108939 314.741098 506.215393 Z M 314.741098 506.215393 "
      transform="matrix(0.0472229,0.0370222,-0.0386698,0.046815,252.849004,162.020227)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 314.724131 506.185365 C 314.676793 551.259811 304.655112 587.767648 292.235222 587.800977 C 279.839929 587.775212 269.726705 551.271486 269.774042 506.19704 C 269.735931 461.157565 279.84306 424.614757 292.238353 424.640522 C 304.658243 424.607193 314.686019 461.14589 314.724131 506.185365 Z M 314.724131 506.185365 "
      transform="matrix(0.0224929,0.0551018,-0.0567401,0.0229367,270.481809,170.559642)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 314.673341 506.250542 C 314.730881 551.236306 304.738624 587.77079 292.259543 587.833155 C 279.797656 587.740525 269.689504 551.263464 269.742331 506.175106 C 269.795158 461.086748 279.880588 424.604664 292.249302 424.644893 C 304.728383 424.582529 314.726168 461.162184 314.673341 506.250542 Z M 314.673341 506.250542 "
      transform="matrix(0.0261214,-0.0133415,0.0281005,0.0237225,227.749712,151.063687)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 314.709067 506.201583 C 314.761845 551.236082 304.605855 587.752423 292.2295 587.772677 C 279.853144 587.792931 269.755608 551.225441 269.702829 506.190941 C 269.799291 461.177272 279.80604 424.640102 292.182396 424.619848 C 304.707992 424.620423 314.656288 461.167084 314.709067 506.201583 Z M 314.709067 506.201583 "
      transform="matrix(-0.00530339,-0.0268203,0.0379979,0.0046286,231.098479,166.171803)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 314.714175 506.144262 C 314.718073 551.219173 304.54695 587.781339 292.245368 587.839099 C 279.876727 587.813613 269.75714 551.229485 269.753242 506.154574 C 269.816403 461.162908 279.7799 424.571785 292.148541 424.597271 C 304.65775 424.568468 314.777337 461.152596 314.714175 506.144262 Z M 314.714175 506.144262 "
      transform="matrix(0.0138223,-0.021195,0.0357899,0.0170741,226.812413,157.344825)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 339.106502 586.052164 C 339.106502 586.052164 452.090973 619.060072 551.010435 675.680451 C 649.924974 732.086119 803.248432 947.869103 843.272726 956.512918 C 887.901193 955.142918 949.063467 918.675298 969.70296 792.243467 C 901.866552 835.523572 861.906106 893.826918 794.821125 832.388587 C 771.271486 818.148603 692.354141 694.37559 607.518634 633.301175 C 522.783004 571.999732 353.247595 510.761556 353.247595 510.761556 Z M 339.106502 586.052164 "
      transform="matrix(0.0371733,0.0021325,-0.000852328,0.0181443,214.725823,185.65899)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.012257 604.976493 C 333.246387 604.974831 353.278087 440.094294 353.278087 440.094294 L 370.828005 604.948618 L 350.913287 633.220012 Z M 332.012257 604.976493 "
      transform="matrix(-0.0127022,-0.0284722,-0.0307784,0.0107557,254.118745,164.590705)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.462479 462.443405 C 277.873402 510.706512 326.161303 583.786689 349.698981 633.21153 C 376.04692 581.418636 424.724591 504.890839 454.492942 457.776612 C 422.754001 493.898201 382.682818 546.420438 349.734404 574.281632 C 316.396175 546.436314 279.389473 496.238875 248.462479 462.443405 Z M 248.462479 462.443405 "
      transform="matrix(-0.0127362,-0.0284604,-0.0307645,0.0107876,253.231164,164.820992)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.040965 604.920343 C 333.207777 604.879093 353.195395 440.098003 353.195395 440.098003 L 370.933602 604.978404 L 350.863358 633.134816 Z M 332.040965 604.920343 "
      transform="matrix(-0.0177352,-0.026237,-0.0281801,0.0154265,253.349603,161.297948)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.481674 462.463446 C 277.846002 510.70898 326.170598 583.697962 349.651907 633.232859 C 375.963967 581.369132 424.60397 504.865177 454.451742 457.832463 C 422.722314 493.833585 382.641631 546.472569 349.715347 574.329586 C 316.315094 546.448562 279.452475 496.245771 248.481674 462.463446 Z M 248.481674 462.463446 "
      transform="matrix(-0.0133579,-0.0294511,-0.0306525,0.0110431,252.471434,164.902432)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.035379 604.920774 C 333.233211 604.914754 353.186452 440.097944 353.186452 440.097944 L 370.828588 604.952187 L 350.882446 633.196843 Z M 332.035379 604.920774 "
      transform="matrix(-0.00994299,-0.0293086,-0.0317774,0.00820728,252.231294,166.934724)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.453524 462.464075 C 277.901711 510.736093 326.212912 583.76329 349.696962 633.225249 C 376.014655 581.393897 424.665716 504.901279 454.450745 457.794313 C 422.69403 493.902512 382.65954 546.458658 349.766919 574.319948 C 316.354549 546.45574 279.474774 496.180856 248.453524 462.464075 Z M 248.453524 462.464075 "
      transform="matrix(-0.0169069,-0.0285736,-0.028933,0.0143562,251.99511,163.190449)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.074069 604.955421 C 333.272959 604.924662 353.256228 440.114031 353.256228 440.114031 L 370.884522 605.015154 L 350.862953 633.234312 Z M 332.074069 604.955421 "
      transform="matrix(-0.0234149,-0.0222754,-0.0236726,0.0207398,251.291668,157.037923)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.409369 462.449962 C 277.842319 510.777172 326.119089 583.771928 349.715684 633.22698 C 376.039156 581.381465 424.712697 504.884618 454.464317 457.790065 C 422.70409 493.897232 382.619693 546.427164 349.67784 574.293844 C 316.354467 546.41621 279.470972 496.234644 248.409369 462.449962 Z M 248.409369 462.449962 "
      transform="matrix(-0.0210399,-0.0300668,-0.0292172,0.0175842,253.120795,162.325316)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 331.983786 604.931189 C 333.201409 604.935605 353.27604 440.12542 353.27604 440.12542 L 370.887903 604.980665 L 350.884929 633.123436 Z M 331.983786 604.931189 "
      transform="matrix(-0.0223552,-0.0289412,-0.0279651,0.0188491,252.846329,161.318918)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.458764 462.489823 C 277.871643 510.728774 326.113607 583.756231 349.687164 633.207821 C 376.041993 581.425827 424.648143 504.88078 454.494798 457.805521 C 422.664329 493.862816 382.661728 546.424808 349.636755 574.306333 C 316.361325 546.466723 279.480329 496.206362 248.458764 462.489823 Z M 248.458764 462.489823 "
      transform="matrix(-0.0160479,-0.0326276,-0.0354003,0.0200561,253.836074,161.944163)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.061833 604.916717 C 333.178135 604.909585 353.228087 440.042227 353.228087 440.042227 L 370.91353 604.893813 L 350.888108 633.205906 Z M 332.061833 604.916717 "
      transform="matrix(-0.0177157,-0.0313567,-0.0343299,0.02142,253.762757,160.84176)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.433777 462.464802 C 277.850968 510.753089 326.142837 583.701031 349.732288 633.229162 C 375.976894 581.448795 424.666224 504.895324 454.435151 457.771102 C 422.668916 493.873541 382.632429 546.47681 349.674932 574.326337 C 316.326856 546.419167 279.413591 496.18824 248.433777 462.464802 Z M 248.433777 462.464802 "
      transform="matrix(-0.0208862,-0.0297131,-0.0320107,0.0241683,252.680168,159.443042)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.056823 604.922487 C 333.202112 604.975866 353.238511 440.114384 353.238511 440.114384 L 370.891721 604.883991 L 350.881219 633.179215 Z M 332.056823 604.922487 "
      transform="matrix(-0.025979,-0.0252185,-0.0280362,0.0288274,251.996383,155.1505)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.420968 462.433956 C 277.83329 510.743736 326.144746 583.79507 349.741004 633.204493 C 375.996103 581.399275 424.665517 504.876461 454.530503 457.789313 C 422.657615 493.86746 382.717766 546.472471 349.729351 574.304748 C 316.334548 546.501451 279.490944 496.239579 248.420968 462.433956 Z M 248.420968 462.433956 "
      transform="matrix(-0.01908,-0.0306478,-0.0334217,0.0226213,251.691286,161.015988)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.011941 604.920749 C 333.262192 604.950266 353.214859 440.148083 353.214859 440.148083 L 370.91058 604.950531 L 350.856147 633.212954 Z M 332.011941 604.920749 "
      transform="matrix(-0.0179496,-0.0317495,-0.0337451,0.0214231,252.048366,162.355374)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.485349 462.467836 C 277.893711 510.747761 326.155292 583.719332 349.656785 633.171508 C 376.011083 581.365947 424.639834 504.868505 454.525656 457.77371 C 422.677405 493.858076 382.660407 546.467704 349.726037 574.295329 C 316.305713 546.417627 279.377432 496.214543 248.485349 462.467836 Z M 248.485349 462.467836 "
      transform="matrix(-0.0227001,-0.0279236,-0.031077,0.0259088,250.868232,159.034745)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.018291 604.983561 C 333.185785 604.9832 353.215703 440.052469 353.215703 440.052469 L 370.847883 604.917691 L 350.838695 633.174343 Z M 332.018291 604.983561 "
      transform="matrix(-0.03012,-0.020065,-0.0239553,0.032379,249.301512,152.065405)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.412907 462.43793 C 277.892998 510.724288 326.123987 583.745444 349.684227 633.21302 C 376.004606 581.348255 424.601081 504.86076 454.527214 457.772393 C 422.648163 493.852175 382.644373 546.490811 349.71591 574.277618 C 316.389104 546.448287 279.425615 496.224878 248.412907 462.43793 Z M 248.412907 462.43793 "
      transform="matrix(-0.0285747,-0.0302646,-0.0307694,0.0302075,252.186618,157.951373)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 331.983681 604.92318 C 333.186436 604.895129 353.266664 440.090699 353.266664 440.090699 L 370.907105 604.919071 L 350.916375 633.204956 Z M 331.983681 604.92318 "
      transform="matrix(-0.0299096,-0.0284982,-0.029148,0.0313701,251.721927,156.80862)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.465719 462.475496 C 277.845157 510.750057 326.116961 583.736962 349.656831 633.187001 C 375.987059 581.420985 424.620167 504.839642 454.478219 457.764025 C 422.651405 493.849918 382.710515 546.46492 349.758556 574.316069 C 316.297099 546.474109 279.481798 496.186598 248.465719 462.475496 Z M 248.465719 462.475496 "
      transform="matrix(-0.029942,-0.0284632,-0.0291143,0.031402,250.837778,157.61195)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.039009 604.938278 C 333.19406 604.909035 353.193474 440.075192 353.193474 440.075192 L 370.841767 604.95659 L 350.857804 633.206439 Z M 332.039009 604.938278 "
      transform="matrix(-0.0344178,-0.0227682,-0.0236558,0.0357469,249.058639,153.107093)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.44743 462.494135 C 277.846594 510.709619 326.204769 583.764066 349.723609 633.166362 C 375.997679 581.336678 424.647453 504.873379 454.470691 457.728638 C 422.710219 493.826658 382.659305 546.44039 349.698079 574.284256 C 316.342235 546.484691 279.401388 496.225205 248.44743 462.494135 Z M 248.44743 462.494135 "
      transform="matrix(-0.0270577,-0.0306689,-0.0320306,0.0290703,250.598872,160.104442)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.050796 604.946867 C 333.213984 604.935445 353.23278 440.116929 353.23278 440.116929 L 370.856388 604.958826 L 350.877416 633.156842 Z M 332.050796 604.946867 "
      transform="matrix(-0.0238463,-0.0333271,-0.0344889,0.0259804,251.352423,163.134704)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.410492 462.465524 C 277.823018 510.745612 326.145448 583.722376 349.706099 633.246775 C 375.991629 581.425242 424.692828 504.875684 454.475485 457.779291 C 422.713867 493.85485 382.698101 546.477686 349.650345 574.318466 C 316.315976 546.461039 279.463399 496.238493 248.410492 462.465524 Z M 248.410492 462.465524 "
      transform="matrix(-0.0244123,-0.0337248,-0.0331873,0.0266135,249.431778,162.86666)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 331.977005 604.951105 C 333.192089 604.942451 353.239403 440.057785 353.239403 440.057785 L 370.899689 604.929429 L 350.865894 633.198789 Z M 331.977005 604.951105 "
      transform="matrix(-0.0323299,-0.025472,-0.0255368,0.0345951,247.728173,154.816837)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.46225 462.482137 C 277.894295 510.748164 326.138005 583.75739 349.648791 633.200942 C 376.026032 581.371036 424.70125 504.865066 454.512189 457.764674 C 422.680323 493.884424 382.703956 546.48053 349.685029 574.29632 C 316.311724 546.431014 279.469628 496.24961 248.46225 462.482137 Z M 248.46225 462.482137 "
      transform="matrix(-0.033395,-0.0305647,-0.031918,0.0387091,251.367489,155.746621)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.069418 604.948272 C 333.18496 604.958141 353.263341 440.096741 353.263341 440.096741 L 370.870654 604.924512 L 350.9156 633.192433 Z M 332.069418 604.948272 "
      transform="matrix(-0.0347507,-0.0283655,-0.0300589,0.0398109,250.782314,154.507662)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.425002 462.49522 C 277.851268 510.765764 326.163972 583.736032 349.713036 633.22123 C 376.00458 581.417741 424.686191 504.842239 454.504458 457.800588 C 422.72621 493.840935 382.6971 546.467973 349.701533 574.289881 C 316.337764 546.456398 279.42113 496.194956 248.425002 462.49522 Z M 248.425002 462.49522 "
      transform="matrix(-0.034784,-0.0283211,-0.0300202,0.0398427,249.861366,155.54612)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.041598 604.968392 C 333.255079 604.93183 353.254869 440.104884 353.254869 440.104884 L 370.872575 604.951188 L 350.861226 633.187284 Z M 332.041598 604.968392 "
      transform="matrix(-0.0393436,-0.0212063,-0.0237332,0.044043,247.612945,150.654564)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.440958 462.465275 C 277.885501 510.778771 326.168574 583.789341 349.657003 633.200924 C 375.974515 581.391742 424.659897 504.856976 454.468505 457.735057 C 422.742334 493.857872 382.675757 546.4799 349.724841 574.332083 C 316.298913 546.447 279.419824 496.24582 248.440958 462.465275 Z M 248.440958 462.465275 "
      transform="matrix(-0.0350008,-0.0280323,-0.0297674,0.0400462,248.719838,156.022508)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.017225 604.959084 C 333.242872 604.955421 353.256939 440.09459 353.256939 440.09459 L 370.87749 604.954281 L 350.89859 633.186063 Z M 332.017225 604.959084 "
      transform="matrix(-0.0319696,-0.0317599,-0.0330013,0.0371591,250.082793,159.240886)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.473138 462.459185 C 277.87938 510.765673 326.181317 583.730014 349.746198 633.183215 C 375.957401 581.391718 424.639888 504.855308 454.510717 457.740112 C 422.715258 493.915667 382.652077 546.427658 349.701636 574.300962 C 316.365507 546.423151 279.396739 496.238028 248.473138 462.459185 Z M 248.473138 462.459185 "
      transform="matrix(-0.0408369,-0.018507,-0.0206672,0.0453906,245.728039,150.83377)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.014246 604.910464 C 333.2205 604.974567 353.220034 440.110264 353.220034 440.110264 L 370.858284 604.904238 L 350.832752 633.166218 Z M 332.014246 604.910464 "
      transform="matrix(-0.0448523,-0.00582482,-0.00911352,0.0486711,240.138838,143.996155)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.404525 462.489426 C 277.88577 510.715431 326.109312 583.759717 349.707397 633.175887 C 376.041624 581.372829 424.611007 504.872107 454.503539 457.729934 C 422.659049 493.890786 382.707019 546.446758 349.746864 574.299998 C 316.35465 546.445288 279.471884 496.195185 248.440965 462.41826 Z M 248.404525 462.489426 "
      transform="matrix(-0.0407385,-0.0187221,-0.0208603,0.0453026,245.169646,151.679152)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.073315 604.943973 C 333.205614 604.924768 353.206672 440.054913 353.206672 440.054913 L 370.858103 604.900847 L 350.867549 633.221143 Z M 332.073315 604.943973 "
      transform="matrix(-0.0417174,-0.0164661,-0.0188265,0.0461702,244.421893,150.420404)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.432863 462.463984 C 277.890603 510.748267 326.196276 583.72754 349.671083 633.182336 C 375.979422 581.422221 424.673043 504.824827 454.459265 457.788292 C 422.69317 493.825589 382.66839 546.442003 349.722182 574.361152 C 316.355846 546.439966 279.399991 496.230904 248.432863 462.463984 Z M 248.432863 462.463984 "
      transform="matrix(-0.0442973,-0.0146103,-0.0161758,0.0487978,243.641902,149.234319)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 331.998296 604.927466 C 333.179049 604.917342 353.218165 440.101754 353.218165 440.101754 L 370.894048 604.971212 L 350.910776 633.199363 Z M 331.998296 604.927466 "
      transform="matrix(-0.0463885,-0.00618302,-0.00848424,0.0505603,239.728571,145.365861)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.43064 462.435214 C 277.844298 510.721624 326.117589 583.776409 349.672184 633.191134 C 375.978728 581.387911 424.69686 504.85964 454.46457 457.737604 C 422.69706 493.830335 382.699991 546.443285 349.71627 574.29467 C 316.34887 546.461972 279.444257 496.177525 248.43064 462.435214 Z M 248.43064 462.435214 "
      transform="matrix(-0.0425705,-0.0188847,-0.0202607,0.0473412,244.441155,152.373516)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.062673 604.918759 C 333.22495 604.914441 353.2222 440.09718 353.2222 440.09718 L 370.900119 604.938729 L 350.828454 633.219593 Z M 332.062673 604.918759 "
      transform="matrix(-0.0404205,-0.0233577,-0.0242675,0.0453017,246.629346,155.219566)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.43362 462.489455 C 277.917777 510.751578 326.115737 583.731482 349.688186 633.158108 C 375.977883 581.408379 424.699551 504.843173 454.497236 457.762283 C 422.749731 493.85353 382.655443 546.476922 349.723317 574.32946 C 316.350912 546.446096 279.486243 496.231062 248.43362 462.489455 Z M 248.43362 462.489455 "
      transform="matrix(-0.0419984,-0.0146926,-0.0152972,0.049526,241.965637,150.354257)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.015116 604.932629 C 333.177243 604.952466 353.192593 440.095319 353.192593 440.095319 L 370.918898 604.906796 L 350.848187 633.234421 Z M 332.015116 604.932629 "
      transform="matrix(-0.0469934,-0.000869836,-0.00378621,0.0509566,236.545281,144.73693)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.456901 462.47375 C 277.878711 510.757731 326.114317 583.742083 349.729125 633.175357 C 375.987522 581.352782 424.680326 504.896601 454.478451 457.79237 C 422.719547 493.87566 382.683474 546.427583 349.7125 574.300318 C 316.337731 546.487122 279.456904 496.202079 248.456901 462.47375 Z M 248.456901 462.47375 "
      transform="matrix(-0.0424039,-0.0143803,-0.0137221,0.0496988,240.369926,150.822859)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.061951 604.926552 C 333.230323 604.970244 353.223548 440.103258 353.223548 440.103258 L 370.906916 604.962627 L 350.850041 633.190043 Z M 332.061951 604.926552 "
      transform="matrix(-0.0430289,-0.0119128,-0.0116153,0.050349,239.459174,149.607734)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.45977 462.441395 C 277.877057 510.730687 326.132593 583.783276 349.732133 633.187435 C 375.981821 581.402416 424.626069 504.861863 454.506866 457.754775 C 422.678608 493.825192 382.670704 546.478232 349.712186 574.300592 C 316.372564 546.475864 279.494073 496.188638 248.45977 462.441395 Z M 248.45977 462.441395 "
      transform="matrix(-0.0434822,-0.0104306,-0.00967016,0.0506834,238.353587,149.719895)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.002217 604.958439 C 333.233117 604.924446 353.182195 440.106482 353.182195 440.106482 L 370.900336 604.911571 L 350.883676 633.164917 Z M 332.002217 604.958439 "
      transform="matrix(-0.0444899,-0.00174529,-0.00220907,0.0517163,234.216506,146.203381)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.429599 462.433877 C 277.880835 510.745597 326.126201 583.722217 349.742422 633.209718 C 376.020225 581.359652 424.638129 504.851286 454.491531 457.752775 C 422.668408 493.838757 382.669499 546.493949 349.67324 574.35097 C 316.375965 546.496639 279.467457 496.215545 248.429599 462.433877 Z M 248.429599 462.433877 "
      transform="matrix(-0.0442519,-0.00836505,-0.00698284,0.0515102,237.121011,149.316637)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.036154 604.922158 C 333.207277 604.920931 353.225058 440.065916 353.225058 440.065916 L 370.851869 604.926047 L 350.849723 633.173548 Z M 332.036154 604.922158 "
      transform="matrix(-0.0433729,-0.013289,-0.0112141,0.0504681,239.864706,151.594079)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.408825 462.490712 C 277.86046 510.765955 326.116243 583.715411 349.666381 633.200885 C 375.970555 581.356664 424.667131 504.828896 454.513668 457.72899 C 422.710048 493.867395 382.669517 546.463891 349.663508 574.300476 C 316.337665 546.443437 279.465943 496.23178 248.408825 462.490712 Z M 248.408825 462.490712 "
      transform="matrix(-0.0447554,-0.00254832,-0.0019874,0.0521273,234.603202,148.384005)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.038448 604.907908 C 333.211276 604.953374 353.205798 440.077557 353.205798 440.077557 L 370.851886 604.926763 L 350.907767 633.218203 Z M 332.038448 604.907908 "
      transform="matrix(-0.0436833,0.0113478,0.00993652,0.05094,226.853238,143.836087)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.421099 462.455891 C 277.858206 510.725954 326.187197 583.748534 349.695159 633.232089 C 376.029549 581.374686 424.685025 504.825439 454.502357 457.723688 C 422.724021 493.905114 382.670773 546.444796 349.726418 574.300538 C 316.30057 546.431958 279.404785 496.218642 248.421099 462.455891 Z M 248.421099 462.455891 "
      transform="matrix(-0.0459656,-0.00254084,-0.00247691,0.0519657,235.079923,149.407937)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.00832 604.969806 C 333.196351 604.969262 353.204985 440.089659 353.204985 440.089659 L 370.873918 604.952003 L 350.889168 633.19034 Z M 332.00832 604.969806 "
      transform="matrix(-0.0460321,0.0000238323,-0.00020816,0.0520295,233.885542,148.472847)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.410688 462.46136 C 277.875354 510.759715 326.116838 583.7476 349.708986 633.224115 C 375.982722 581.367962 424.6593 504.857923 454.497318 457.761034 C 422.65781 493.884427 382.632552 546.454434 349.699571 574.300367 C 316.351252 546.430634 279.456273 496.193641 248.410688 462.46136 Z M 248.410688 462.46136 "
      transform="matrix(-0.0459087,-0.0035547,-0.00337362,0.0519082,235.628445,150.436071)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.023211 604.924128 C 333.21959 604.953989 353.255213 440.076648 353.255213 440.076648 L 370.896516 604.914494 L 350.854971 633.203807 Z M 332.023211 604.924128 "
      transform="matrix(-0.0458208,0.00523814,0.0044062,0.0517636,230.934887,147.58842)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.439825 462.46774 C 277.86192 510.761409 326.115414 583.761047 349.736196 633.212565 C 375.987031 581.36834 424.651448 504.890631 454.479054 457.736605 C 422.674678 493.895005 382.630435 546.491316 349.694928 574.333951 C 316.306796 546.424183 279.435039 496.204252 248.439825 462.46774 Z M 248.439825 462.46774 "
      transform="matrix(-0.0456389,-0.0065309,-0.00600533,0.0516203,236.127519,152.523247)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.01428 604.922914 C 333.223834 604.965457 353.248939 440.080979 353.248939 440.080979 L 370.859026 604.930258 L 350.904277 633.208283 Z M 332.01428 604.922914 "
      transform="matrix(-0.0448481,-0.0114735,-0.0103745,0.0507501,238.767504,154.687613)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.478811 462.492196 C 277.87682 510.723515 326.162061 583.766152 349.681446 633.193253 C 376.027533 581.371727 424.647866 504.852985 454.470706 457.734314 C 422.731487 493.864534 382.706036 546.474507 349.678725 574.327892 C 316.335192 546.465116 279.420473 496.253608 248.478811 462.492196 Z M 248.478811 462.492196 "
      transform="matrix(-0.0460244,-0.00071362,-0.000860541,0.0520255,233.943461,151.108103)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.016185 604.959613 C 333.240115 604.951735 353.258506 440.100315 353.258506 440.100315 L 370.890479 604.938425 L 350.850726 633.187482 Z M 332.016185 604.959613 "
      transform="matrix(-0.0446088,0.0130904,0.0113595,0.0503551,225.227858,147.487849)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.457302 462.434397 C 277.847271 510.742623 326.113777 583.787965 349.672256 633.240986 C 376.030993 581.384988 424.638139 504.875327 454.529834 457.749064 C 422.732525 493.852193 382.648325 546.444866 349.661032 574.297047 C 316.339525 546.458874 279.408847 496.235414 248.457302 462.434397 Z M 248.457302 462.434397 "
      transform="matrix(-0.045418,0.00872951,0.00749723,0.0512918,229.544024,149.740912)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 331.998068 604.949286 C 333.221068 604.901019 353.176339 440.086401 353.176339 440.086401 L 370.880457 604.96708 L 350.881703 633.187966 Z M 331.998068 604.949286 "
      transform="matrix(-0.0442097,0.0147453,0.0128258,0.0498981,226.71936,147.871919)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.404949 462.441652 C 277.906294 510.70106 326.190616 583.740675 349.703578 633.225538 C 375.995402 581.377077 424.692905 504.843813 454.488105 457.783192 C 422.691351 493.837456 382.668067 546.468057 349.70591 574.340878 C 316.342165 546.452405 279.437871 496.23952 248.404949 462.441652 Z M 248.404949 462.441652 "
      transform="matrix(-0.0453509,0.0091755,0.00789234,0.0512128,229.322658,150.49878)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.011695 604.923452 C 333.230755 604.959483 353.213678 440.105488 353.213678 440.105488 L 370.882178 604.906866 L 350.900382 633.185606 Z M 332.011695 604.923452 "
      transform="matrix(-0.0421996,0.0210236,0.0183904,0.047593,222.710215,148.530604)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.39381 462.45631 C 277.903413 510.72052 326.154637 583.745067 349.72342 633.196079 C 375.982994 581.404854 424.642755 504.853228 454.470493 457.786251 C 422.718564 493.890572 382.635567 546.44865 349.671966 574.358465 C 316.373454 546.424594 279.458807 496.180136 248.47489 462.431534 Z M 248.39381 462.45631 "
      transform="matrix(-0.043865,0.0151914,0.0141131,0.0497131,225.779259,150.392659)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.062095 604.959155 C 333.174435 604.934567 353.254652 440.06377 353.254652 440.06377 L 370.868008 604.954732 L 350.912891 633.207923 Z M 332.062095 604.959155 "
      transform="matrix(-0.0454981,0.00815942,0.00699211,0.0513883,230.956366,151.667564)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.400019 462.451573 C 277.893703 510.769273 326.118843 583.731195 349.719443 633.20898 C 376.009429 581.36855 424.656021 504.859501 454.49074 457.789534 C 422.668008 493.846206 382.684951 546.457812 349.689525 574.341854 C 316.375476 546.431905 279.443516 496.258377 248.400019 462.451573 Z M 248.400019 462.451573 "
      transform="matrix(-0.0420098,0.0206586,0.0189331,0.0477002,223.101457,150.848429)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.073842 604.943251 C 333.166118 604.933334 353.210863 440.103416 353.210863 440.103416 L 370.88518 604.90002 L 350.8631 633.225002 Z M 332.073842 604.943251 "
      transform="matrix(-0.0354957,0.0325542,0.0293829,0.0405259,214.191882,150.747951)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.404208 462.467581 C 277.87169 510.756008 326.139968 583.779209 349.708965 633.199829 C 376.027216 581.413356 424.64901 504.8915 454.506171 457.721591 C 422.713234 493.868843 382.651082 546.46368 349.6631 574.295461 C 316.309138 546.465282 279.459704 496.183844 248.404208 462.467581 Z M 248.404208 462.467581 "
      transform="matrix(-0.0420991,0.0204327,0.0187336,0.0477973,223.332964,151.878327)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.045147 604.965217 C 333.163192 604.922125 353.20202 440.104956 353.20202 440.104956 L 370.880726 604.938587 L 350.857812 633.165087 Z M 332.045147 604.965217 "
      transform="matrix(-0.0411247,0.0227641,0.0207861,0.0467321,221.888984,151.630907)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.444854 462.439814 C 277.854683 510.715048 326.163 583.713853 349.713176 633.178775 C 376.00658 581.361585 424.651231 504.826589 454.508957 457.791694 C 422.721309 493.893366 382.665004 546.495559 349.713527 574.300589 C 316.309642 546.459409 279.443254 496.223152 248.444854 462.439814 Z M 248.444854 462.439814 "
      transform="matrix(-0.0411017,0.0228156,0.020832,0.0467067,222.36703,152.986436)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.045787 604.948204 C 333.20587 604.946793 353.210115 440.127635 353.210115 440.127635 L 370.896563 604.95586 L 350.865592 633.15906 Z M 332.045787 604.948204 "
      transform="matrix(-0.037006,0.0303563,0.0274564,0.0421972,217.029506,153.174447)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.437914 462.472001 C 277.851472 510.788684 326.190269 583.767014 349.683967 633.182197 C 376.001945 581.396654 424.671183 504.894739 454.519103 457.731986 C 422.703625 493.871218 382.670124 546.436826 349.741169 574.317081 C 316.371129 546.433812 279.486958 496.210137 248.437914 462.472001 Z M 248.437914 462.472001 "
      transform="matrix(-0.0409495,0.0231529,0.0211288,0.0465404,222.249557,154.325868)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.066859 604.96277 C 333.235688 604.918203 353.227043 440.075586 353.227043 440.075586 L 370.850885 604.93931 L 350.821512 633.191472 Z M 332.066859 604.96277 "
      transform="matrix(-0.0427948,0.0185615,0.0170851,0.0485547,225.706938,154.318037)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.429413 462.470945 C 277.878813 510.723129 326.124271 583.780709 349.735498 633.211249 C 376.016804 581.420458 424.700994 504.861467 454.521127 457.762604 C 422.688306 493.904222 382.641908 546.448988 349.699118 574.360506 C 316.304277 546.463634 279.411985 496.221607 248.429413 462.470945 Z M 248.429413 462.470945 "
      transform="matrix(-0.0383281,0.0282153,0.0255783,0.0436577,219.680894,155.272755)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 331.994622 604.927888 C 333.211021 604.958613 353.220835 440.115546 353.220835 440.115546 L 370.89176 604.962364 L 350.895851 633.212183 Z M 331.994622 604.927888 "
      transform="matrix(-0.0266462,0.0409453,0.037832,0.0317476,208.445124,156.759966)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.445697 462.478483 C 277.913907 510.75761 326.113927 583.75662 349.670762 633.19204 C 376.013419 581.411043 424.653195 504.851522 454.49449 457.800847 C 422.647142 493.86276 382.692419 546.443969 349.697681 574.315159 C 316.341157 546.420476 279.458975 496.211698 248.445697 462.478483 Z M 248.445697 462.478483 "
      transform="matrix(-0.0368051,0.0294799,0.0280476,0.0426077,218.375801,155.763682)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.049764 604.917566 C 333.206632 604.980098 353.216527 440.130691 353.216527 440.130691 L 370.922866 604.926042 L 350.858023 633.249763 Z M 332.049764 604.917566 "
      transform="matrix(-0.035378,0.031544,0.0298278,0.0411025,216.918761,155.845779)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.413453 462.475419 C 277.921518 510.735028 326.159815 583.77543 349.694452 633.205736 C 375.987116 581.401351 424.687675 504.845766 454.512047 457.743896 C 422.726939 493.893547 382.70939 546.458379 349.682072 574.337114 C 316.362285 546.416228 279.474195 496.224116 248.413453 462.475419 Z M 248.413453 462.475419 "
      transform="matrix(-0.0353446,0.0315903,0.0298674,0.0410673,217.662458,157.066158)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 331.988856 604.953811 C 333.25741 604.935893 353.234982 440.123815 353.234982 440.123815 L 370.892173 604.92662 L 350.860972 633.153527 Z M 331.988856 604.953811 "
      transform="matrix(-0.0271847,0.0404875,0.0374473,0.03233,210.722728,159.211389)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.452783 462.479255 C 277.888983 510.771529 326.160007 583.706368 349.656699 633.200655 C 375.993292 581.345493 424.629452 504.861966 454.482896 457.754885 C 422.69056 493.911041 382.659136 546.457831 349.677997 574.338776 C 316.334527 546.448563 279.436286 496.262896 248.452783 462.479255 Z M 248.452783 462.479255 "
      transform="matrix(-0.0328685,0.0347432,0.0325723,0.0384367,216.008488,158.736284)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.010407 604.980785 C 333.186266 604.953085 353.277621 440.10188 353.277621 440.10188 L 370.883755 604.953621 L 350.902994 633.208938 Z M 332.010407 604.980785 "
      transform="matrix(-0.0358533,0.0308785,0.0292544,0.0416052,219.302946,157.679234)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.442023 462.493614 C 277.834313 510.754367 326.112758 583.755077 349.720324 633.211003 C 375.972626 581.412036 424.679873 504.883753 454.449026 457.758148 C 422.728245 493.884584 382.678123 546.496782 349.709123 574.357676 C 316.346502 546.416828 279.419189 496.208345 248.442023 462.493614 Z M 248.442023 462.493614 "
      transform="matrix(-0.0290172,0.0388259,0.0360459,0.0343073,213.811546,160.428544)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 331.986079 604.951976 C 333.189613 604.954624 353.266764 440.075505 353.266764 440.075505 L 370.85093 604.94222 L 350.870613 633.166278 Z M 331.986079 604.951976 "
      transform="matrix(-0.00983828,0.0486501,0.0460431,0.0157561,200.806868,167.430453)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.443046 462.519335 C 277.889746 510.713595 326.163618 583.78429 349.705215 633.161803 C 375.970513 581.408135 424.631824 504.888961 454.448023 457.762144 C 422.732342 493.880756 382.667927 546.493056 349.684645 574.292765 C 316.344797 546.431426 279.42869 496.190779 248.440734 462.408672 Z M 248.443046 462.519335 "
      transform="matrix(-0.0291875,0.0386634,0.0359082,0.034491,214.432255,161.254061)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.021343 604.892244 C 333.209981 604.98589 353.271914 440.084411 353.271914 440.084411 L 370.896171 604.98552 L 350.904317 633.212156 Z M 332.021343 604.892244 "
      transform="matrix(-0.0197971,0.0439487,0.0427179,0.0261452,207.401334,163.475866)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.40794 462.492351 C 277.865711 510.743298 326.112376 583.761958 349.696866 633.228942 C 376.005541 581.409014 424.6533 504.815831 454.533822 457.753855 C 422.712952 493.865719 382.697849 546.480927 349.684969 574.273363 C 316.367254 546.447885 279.466783 496.220089 248.450503 462.420623 Z M 248.40794 462.492351 "
      transform="matrix(-0.0197496,0.0439783,0.0427401,0.0260966,208.541353,164.310659)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.044501 604.97433 C 333.201925 604.943003 353.240116 440.113962 353.240116 440.113962 L 370.904531 604.979396 L 350.845812 633.165804 Z M 332.044501 604.97433 "
      transform="matrix(-0.0122695,0.0477451,0.0454557,0.0183173,204.418222,167.717341)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.403398 462.474434 C 277.901297 510.736107 326.142758 583.709807 349.706374 633.198206 C 375.98939 581.34807 424.697419 504.866536 454.474617 457.788758 C 422.719982 493.85868 382.638788 546.467636 349.65991 574.330689 C 316.30998 546.458441 279.467252 496.233403 248.403398 462.474434 Z M 248.403398 462.474434 "
      transform="matrix(-0.0194404,0.0441656,0.0428817,0.0257779,209.200477,165.494232)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.061601 604.916776 C 333.201826 604.902815 353.248271 440.120743 353.248271 440.120743 L 370.851558 604.933547 L 350.870868 633.160643 Z M 332.061601 604.916776 "
      transform="matrix(-0.0234814,0.0414765,0.040809,0.0299129,211.935414,163.382404)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.453446 462.505088 C 277.895578 510.774423 326.160742 583.723309 349.71393 633.223289 C 375.969138 581.380943 424.645989 504.886166 454.485999 457.781892 C 422.668567 493.866274 382.629509 546.472984 349.683016 574.341801 C 316.334595 546.43588 279.492114 496.203474 248.453446 462.505088 Z M 248.453446 462.505088 "
      transform="matrix(-0.01452,0.0467727,0.0447877,0.0206738,207.697672,167.844756)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.059635 604.970557 C 333.222584 604.940405 353.190827 440.077917 353.190827 440.077917 L 370.915227 604.910862 L 350.856696 633.23258 Z M 332.059635 604.970557 "
      transform="matrix(-0.00213946,0.0505794,0.0470341,0.00754785,201.611668,174.482129)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 331.955561 604.949511 C 333.164767 604.975664 353.225221 440.152393 353.225221 440.152393 L 370.935876 604.987013 L 350.926413 633.209557 Z M 331.955561 604.949511 "
      transform="matrix(0.000734132,-0.0194436,-0.0339433,0.00282376,254.165292,165.168062)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.351125 462.504583 C 277.959354 510.694917 326.086192 583.723256 349.608226 633.202817 C 376.01649 581.349209 424.602134 504.904706 454.569338 457.809941 C 422.756673 493.856351 382.758499 546.430885 349.674817 574.30748 C 316.298237 546.498126 279.381179 496.249825 248.351125 462.504583 Z M 248.351125 462.504583 "
      transform="matrix(0.000494558,-0.0199811,-0.0340243,0.00263042,252.570608,165.292619)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.030545 604.963642 C 333.262765 604.895369 353.231395 440.076498 353.231395 440.076498 L 370.915132 604.954863 L 350.837476 633.208413 Z M 332.030545 604.963642 "
      transform="matrix(-0.00503046,-0.0186837,-0.0335765,0.00607886,255.615656,163.12764)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.521591 462.488718 C 277.958767 510.769367 326.11327 583.715275 349.758537 633.195068 C 375.976748 581.413248 424.65131 504.820835 454.556191 457.776433 C 422.782026 493.884635 382.7229 546.428315 349.691183 574.375984 C 316.286822 546.464134 279.43562 496.226152 248.521591 462.488718 Z M 248.521591 462.488718 "
      transform="matrix(-0.0108364,-0.0233954,-0.0327948,0.0107854,255.075116,162.736295)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 331.964862 604.896306 C 333.160524 604.912337 353.181465 440.091951 353.181465 440.091951 L 370.794441 604.893179 L 350.880643 633.256327 Z M 331.964862 604.896306 "
      transform="matrix(0.00371948,-0.0196169,-0.0337474,0.00110102,250.7961,166.807051)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.442345 462.498643 C 277.809235 510.80509 326.200657 583.701358 349.702294 633.167603 C 376.048344 581.41678 424.727083 504.905429 454.591992 457.777208 C 422.76972 493.884561 382.630621 546.425262 349.613959 574.326539 C 316.299481 546.41361 279.410429 496.193972 248.442345 462.498643 Z M 248.442345 462.498643 "
      transform="matrix(-0.0033508,-0.0189635,-0.0337857,0.00514023,252.251281,164.447277)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.075807 604.916423 C 333.253616 604.956828 353.300123 440.099094 353.300123 440.099094 L 370.856265 604.92748 L 350.832581 633.20658 Z M 332.075807 604.916423 "
      transform="matrix(-0.012179,-0.016925,-0.0316917,0.00997883,254.340142,160.490284)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.431225 462.506493 C 277.900304 510.697523 326.091939 583.792021 349.6835 633.145911 C 375.957846 581.388356 424.692703 504.80375 454.427671 457.74043 C 422.71783 493.913995 382.666034 546.437475 349.736493 574.28674 C 316.267814 546.406469 279.48301 496.24386 248.431225 462.506493 Z M 248.431225 462.506493 "
      transform="matrix(-0.00763623,-0.0269467,-0.0251596,0.00488683,249.162465,167.141248)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.016982 604.99873 C 333.173935 604.899953 353.236729 440.164669 353.236729 440.164669 L 370.941901 604.967321 L 350.908006 633.162339 Z M 332.016982 604.99873 "
      transform="matrix(-0.00885094,-0.0264884,-0.0245768,0.00611667,249.213851,166.351824)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.044869 604.908256 C 333.231637 604.925278 353.188969 440.138748 353.188969 440.138748 L 370.877792 604.928387 L 350.872967 633.222797 Z M 332.044869 604.908256 "
      transform="matrix(0.00259002,0.0492866,0.0489087,0.00599281,197.72657,175.181418)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.005462 604.965934 C 333.189613 604.927095 353.199704 440.116978 353.199704 440.116978 L 370.881093 604.944472 L 350.872246 633.223344 Z M 332.005462 604.965934 "
      transform="matrix(-0.00831325,0.0467328,0.0482661,0.0167663,203.173967,170.001594)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.017213 604.938514 C 333.215433 604.93878 353.188513 440.086493 353.188513 440.086493 L 370.919414 604.947142 L 350.855284 633.176498 Z M 332.017213 604.938514 "
      transform="matrix(-0.0000108622,0.0488988,0.0489772,0.00860204,201.324637,174.729035)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.427823 462.450598 C 277.889258 510.751972 326.128585 583.785549 349.692627 633.223055 C 375.981085 581.356962 424.625133 504.863248 454.491172 457.785157 C 422.711287 493.882107 382.663701 546.486726 349.69193 574.306852 C 316.333881 546.461199 279.466061 496.220386 248.427823 462.450598 Z M 248.427823 462.450598 "
      transform="matrix(-0.00791004,0.0468712,0.0483341,0.016376,205.437158,171.599215)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.048392 604.90508 C 333.231365 604.970621 353.228398 440.061353 353.228398 440.061353 L 370.854123 604.94392 L 350.858846 633.230822 Z M 332.048392 604.90508 "
      transform="matrix(-0.0125294,0.0450756,0.0473475,0.0208153,207.578228,168.992249)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.457759 462.445704 C 277.887935 510.716701 326.131774 583.727891 349.715308 633.223148 C 375.975213 581.354649 424.621278 504.894221 454.48325 457.758081 C 422.697484 493.857435 382.630813 546.471397 349.745768 574.290417 C 316.313294 546.421868 279.424302 496.17992 248.457759 462.445704 Z M 248.457759 462.445704 "
      transform="matrix(-0.00245152,0.0484094,0.0489157,0.011028,204.558542,174.188849)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.032689 604.908075 C 333.216845 604.972555 353.197647 440.108938 353.197647 440.108938 L 370.861136 604.967091 L 350.840971 633.172965 Z M 332.032689 604.908075 "
      transform="matrix(0.0105912,0.0496005,0.0478183,-0.00218668,200.280358,181.873288)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.063378 604.916143 C 333.179821 604.926674 353.251525 440.058653 353.251525 440.058653 L 370.920608 604.917446 L 350.868256 633.169935 Z M 332.063378 604.916143 "
      transform="matrix(0.00653268,0.0489572,0.0492953,0.00280905,199.132316,178.614189)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.06041 604.947443 C 333.213254 604.974577 353.230547 440.127977 353.230547 440.127977 L 370.854818 604.965232 L 350.861578 633.182384 Z M 332.06041 604.947443 "
      transform="matrix(-0.0045538,0.0471172,0.0495173,0.0135886,204.154456,173.098708)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.034012 604.93449 C 333.237987 604.918424 353.200144 440.076571 353.200144 440.076571 L 370.903782 604.944964 L 350.855653 633.237048 Z M 332.034012 604.93449 "
      transform="matrix(0.00390598,0.048739,0.0495731,0.0054046,202.68726,177.930368)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.478975 462.46573 C 277.921755 510.725189 326.172928 583.731761 349.727174 633.233439 C 375.945879 581.426539 424.633256 504.852924 454.496091 457.764905 C 422.73234 493.894581 382.661511 546.435968 349.719919 574.347888 C 316.314335 546.489237 279.419211 496.188192 248.478975 462.46573 Z M 248.478975 462.46573 "
      transform="matrix(-0.0041404,0.0472291,0.0495537,0.0131951,206.541588,174.545097)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.010205 604.957625 C 333.213865 604.936945 353.215052 440.105943 353.215052 440.105943 L 370.884002 604.919582 L 350.888942 633.221111 Z M 332.010205 604.957625 "
      transform="matrix(-0.00889532,0.0457382,0.0489245,0.0176837,208.469384,171.807992)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 248.418607 462.472126 C 277.894342 510.740851 326.121227 583.774998 349.675059 633.235818 C 375.970153 581.397076 424.655406 504.86608 454.455731 457.798864 C 422.726144 493.84078 382.666438 546.468667 349.683004 574.29499 C 316.3335 546.491923 279.432508 496.236482 248.418607 462.472126 Z M 248.418607 462.472126 "
      transform="matrix(0.00143075,0.0484091,0.0497058,0.00782687,205.871888,177.182689)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,40.392157%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.040363 604.944504 C 333.238121 604.906751 353.228504 440.084315 353.228504 440.084315 L 370.902602 604.969569 L 350.879772 633.191259 Z M 332.040363 604.944504 "
      transform="matrix(0.0145441,0.0487533,0.0475519,-0.00527326,202.217042,185.119148)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(23.921569%,14.509804%,79.215686%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 374.637797 434.586778 C 374.637797 500.819552 320.971772 554.492023 254.759663 554.492023 C 188.547554 554.492023 134.881529 500.819552 134.881529 434.586778 C 134.881529 368.385484 188.547554 314.713013 254.759663 314.713013 C 320.971772 314.713013 374.637797 368.385484 374.637797 434.586778 Z M 374.637797 434.586778 "
      transform="matrix(0.130145,0,0,0.124089,207.20363,125.150743)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,85.098039%,0%);fill-opacity:1;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 353.569677 628.456998 L 222.712899 538.986189 L 91.856121 628.456998 L 140.957099 485.2614 L 26.427732 395.790591 L 173.671293 395.790591 L 222.712899 252.594994 L 271.813877 395.790591 L 418.998066 395.790591 L 304.528071 485.2614 Z M 353.569677 628.456998 "
      transform="matrix(0.0657924,0,0,0.0554039,226.245632,153.184941)"
    />
    <path
      style="fill:none;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 17.702519 379.151032 L 230.318612 432.251604 "
      transform="matrix(0.0607389,0,0,0.0559816,226.909144,153.88779)"
    />
    <path
      style="fill:none;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 230.318612 237.433213 L 230.318612 432.251604 "
      transform="matrix(0.0607389,0,0,0.0559816,226.909144,153.88779)"
    />
    <path
      style="fill:none;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 230.318612 432.251604 L 230.318612 520.868851 "
      transform="matrix(0.0607389,0,0,0.0559816,226.909144,153.88779)"
    />
    <path
      style="fill:none;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 230.318612 432.251604 L 318.876494 467.698503 "
      transform="matrix(0.0607389,0,0,0.0559816,226.909144,153.88779)"
    />
    <path
      style="fill:none;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 177.132433 379.151032 L 230.318612 432.251604 "
      transform="matrix(0.0607389,0,0,0.0559816,226.909144,153.88779)"
    />
    <path
      style="fill:none;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 230.318612 432.251604 L 283.440479 379.151032 "
      transform="matrix(0.0607389,0,0,0.0559816,226.909144,153.88779)"
    />
    <path
      style="fill:none;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.934705 379.151032 L 230.318612 432.251604 "
      transform="matrix(0.0607389,0,0,0.0559816,226.909144,153.88779)"
    />
    <path
      style="fill:none;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 230.318612 432.251604 L 372.062674 609.416322 "
      transform="matrix(0.0607389,0,0,0.0559816,226.909144,153.88779)"
    />
    <path
      style="fill:none;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 230.318612 432.251604 L 88.57455 609.416322 "
      transform="matrix(0.0607389,0,0,0.0559816,226.909144,153.88779)"
    />
    <path
      style="fill:none;stroke-width:2.6369;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 141.760729 467.698503 L 230.318612 432.251604 "
      transform="matrix(0.0607389,0,0,0.0559816,226.909144,153.88779)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(83.921569%,0%,0%);fill-opacity:1;stroke-width:1.291;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 637.79042 325.97493 C 637.79042 443.334425 542.556678 538.58393 425.202437 538.58393 C 307.821924 538.58393 212.588183 443.334425 212.588183 325.97493 C 212.588183 208.643457 307.821924 113.393953 425.202437 113.393953 C 542.530406 113.393953 637.79042 208.615434 637.79042 325.97493 Z M 708.644323 325.97493 C 708.644323 482.454257 581.674758 609.453596 425.202437 609.453596 C 268.730115 609.453596 141.734279 482.454257 141.734279 325.97493 C 141.734279 169.523625 268.730115 42.524286 425.202437 42.524286 C 581.674758 42.524286 708.644323 169.523625 708.644323 325.97493 Z M 708.644323 325.97493 "
      transform="matrix(0.148688,0,0,0.139395,176.78122,134.564497)"
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,90.196078%,0%);fill-opacity:1;"
      d="M 272.125 187.089844 L 274.902344 188.050781 L 279.507812 187.261719 L 279.160156 188.140625 L 276.761719 188.527344 C 276.316406 188.597656 275.871094 188.652344 275.433594 188.695312 C 275.714844 188.980469 276.019531 189.3125 276.359375 189.691406 L 277.867188 191.414062 L 277.539062 192.253906 L 274.617188 188.773438 L 271.84375 187.808594 Z M 272.125 187.089844 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,90.196078%,0%);fill-opacity:1;"
      d="M 279.933594 177.105469 L 280.019531 177.871094 L 276.007812 178.28125 C 275.308594 178.355469 274.75 178.347656 274.324219 178.257812 C 273.898438 178.167969 273.535156 177.964844 273.234375 177.648438 C 272.9375 177.332031 272.757812 176.894531 272.695312 176.339844 C 272.628906 175.800781 272.691406 175.34375 272.875 174.980469 C 273.058594 174.613281 273.355469 174.332031 273.761719 174.144531 C 274.167969 173.953125 274.742188 173.820312 275.476562 173.742188 L 279.492188 173.332031 L 279.582031 174.09375 L 275.570312 174.507812 C 274.96875 174.570312 274.527344 174.660156 274.253906 174.78125 C 273.980469 174.90625 273.777344 175.085938 273.648438 175.328125 C 273.523438 175.574219 273.476562 175.859375 273.515625 176.1875 C 273.582031 176.75 273.78125 177.132812 274.113281 177.34375 C 274.449219 177.550781 275.050781 177.609375 275.925781 177.519531 Z M 279.933594 177.105469 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,90.196078%,0%);fill-opacity:1;"
      d="M 274.328125 169.476562 L 275.132812 169.324219 L 275.65625 171.730469 L 273.117188 172.214844 C 272.679688 171.914062 272.332031 171.582031 272.066406 171.222656 C 271.800781 170.863281 271.625 170.476562 271.535156 170.0625 C 271.417969 169.503906 271.449219 168.96875 271.636719 168.457031 C 271.828125 167.945312 272.171875 167.523438 272.664062 167.183594 C 273.160156 166.847656 273.742188 166.617188 274.417969 166.488281 C 275.082031 166.363281 275.730469 166.359375 276.355469 166.480469 C 276.984375 166.601562 277.484375 166.855469 277.859375 167.234375 C 278.234375 167.617188 278.484375 168.089844 278.605469 168.660156 C 278.695312 169.070312 278.695312 169.457031 278.601562 169.816406 C 278.515625 170.183594 278.351562 170.484375 278.105469 170.730469 C 277.859375 170.972656 277.515625 171.1875 277.078125 171.375 L 276.707031 170.738281 C 277.035156 170.585938 277.289062 170.425781 277.460938 170.261719 C 277.632812 170.097656 277.75 169.886719 277.816406 169.628906 C 277.890625 169.371094 277.894531 169.097656 277.832031 168.808594 C 277.757812 168.464844 277.628906 168.179688 277.445312 167.953125 C 277.265625 167.726562 277.058594 167.554688 276.820312 167.4375 C 276.582031 167.324219 276.328125 167.25 276.066406 167.210938 C 275.609375 167.144531 275.136719 167.160156 274.640625 167.253906 C 274.03125 167.371094 273.539062 167.554688 273.164062 167.804688 C 272.792969 168.058594 272.542969 168.371094 272.414062 168.746094 C 272.289062 169.117188 272.265625 169.488281 272.34375 169.863281 C 272.414062 170.191406 272.558594 170.496094 272.777344 170.777344 C 272.996094 171.058594 273.207031 171.261719 273.414062 171.390625 L 274.691406 171.148438 Z M 274.328125 169.476562 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,90.196078%,0%);fill-opacity:1;"
      d="M 245.035156 148.777344 L 246.242188 142.324219 L 247.046875 142.457031 L 245.984375 148.148438 L 248.988281 148.640625 L 248.847656 149.402344 Z M 245.035156 148.777344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,90.196078%,0%);fill-opacity:1;"
      d="M 238.230469 148.34375 L 238.230469 141.792969 L 242.707031 141.792969 L 242.707031 142.566406 L 239.050781 142.566406 L 239.050781 144.574219 L 242.476562 144.574219 L 242.476562 145.339844 L 239.050781 145.339844 L 239.050781 147.570312 L 242.851562 147.570312 L 242.851562 148.34375 Z M 238.230469 148.34375 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,90.196078%,0%);fill-opacity:1;"
      d="M 218.878906 152.457031 L 219.652344 152.125 C 220.015625 152.769531 220.15625 153.371094 220.078125 153.929688 C 219.996094 154.484375 219.710938 154.957031 219.222656 155.351562 C 218.714844 155.757812 218.203125 155.980469 217.683594 156.019531 C 217.167969 156.050781 216.636719 155.929688 216.089844 155.648438 C 215.546875 155.367188 215.0625 154.988281 214.628906 154.511719 C 214.15625 153.992188 213.835938 153.46875 213.671875 152.933594 C 213.507812 152.394531 213.503906 151.886719 213.667969 151.414062 C 213.832031 150.933594 214.121094 150.527344 214.535156 150.195312 C 215.007812 149.816406 215.519531 149.625 216.070312 149.621094 C 216.625 149.617188 217.171875 149.796875 217.71875 150.15625 L 217.242188 150.796875 C 216.804688 150.523438 216.40625 150.386719 216.046875 150.386719 C 215.6875 150.390625 215.351562 150.515625 215.039062 150.769531 C 214.675781 151.058594 214.457031 151.394531 214.378906 151.773438 C 214.304688 152.148438 214.359375 152.53125 214.542969 152.921875 C 214.726562 153.308594 214.96875 153.664062 215.265625 153.992188 C 215.652344 154.417969 216.046875 154.742188 216.453125 154.964844 C 216.855469 155.1875 217.253906 155.277344 217.640625 155.234375 C 218.03125 155.195312 218.367188 155.058594 218.65625 154.828125 C 219.003906 154.546875 219.203125 154.207031 219.25 153.796875 C 219.296875 153.390625 219.175781 152.945312 218.878906 152.457031 Z M 218.878906 152.457031 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,90.196078%,0%);fill-opacity:1;"
      d="M 215.953125 158.21875 L 210.757812 153.84375 L 211.304688 153.269531 L 216.503906 157.648438 Z M 215.953125 158.21875 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,90.196078%,0%);fill-opacity:1;"
      d="M 212.59375 161.941406 L 207.054688 157.949219 L 207.554688 157.339844 L 212.441406 160.859375 L 214.296875 158.59375 L 214.953125 159.0625 Z M 212.59375 161.941406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,90.196078%,0%);fill-opacity:1;"
      d="M 202.203125 165.800781 L 202.503906 165.089844 L 206.257812 166.484375 C 206.910156 166.726562 207.402344 166.980469 207.734375 167.242188 C 208.066406 167.511719 208.285156 167.847656 208.390625 168.257812 C 208.496094 168.667969 208.4375 169.132812 208.21875 169.652344 C 208.003906 170.160156 207.722656 170.53125 207.378906 170.777344 C 207.035156 171.019531 206.632812 171.136719 206.179688 171.128906 C 205.722656 171.117188 205.148438 170.984375 204.460938 170.726562 L 200.710938 169.332031 L 201.011719 168.621094 L 204.757812 170.011719 C 205.324219 170.222656 205.757812 170.332031 206.0625 170.347656 C 206.367188 170.359375 206.636719 170.285156 206.871094 170.125 C 207.105469 169.96875 207.285156 169.734375 207.417969 169.425781 C 207.636719 168.902344 207.652344 168.472656 207.460938 168.144531 C 207.269531 167.816406 206.765625 167.496094 205.949219 167.195312 Z M 202.203125 165.800781 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,90.196078%,0%);fill-opacity:1;"
      d="M 206.28125 181.652344 L 199.296875 181.707031 L 199.257812 177.507812 L 200.082031 177.5 L 200.113281 180.933594 L 202.253906 180.914062 L 202.222656 177.703125 L 203.042969 177.695312 L 203.074219 180.910156 L 205.449219 180.890625 L 205.417969 177.324219 L 206.242188 177.320312 Z M 206.28125 181.652344 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,90.196078%,0%);fill-opacity:1;"
      d="M 232.375 149.269531 L 230.992188 142.851562 L 233.082031 142.453125 C 233.554688 142.367188 233.921875 142.328125 234.183594 142.34375 C 234.550781 142.359375 234.878906 142.457031 235.171875 142.632812 C 235.554688 142.859375 235.871094 143.179688 236.125 143.597656 C 236.382812 144.011719 236.570312 144.503906 236.695312 145.074219 C 236.800781 145.5625 236.839844 146.007812 236.8125 146.402344 C 236.785156 146.800781 236.714844 147.140625 236.597656 147.417969 C 236.484375 147.695312 236.339844 147.925781 236.171875 148.105469 C 236 148.285156 235.78125 148.4375 235.515625 148.5625 C 235.25 148.6875 234.933594 148.785156 234.5625 148.855469 Z M 233.015625 148.363281 L 234.308594 148.117188 C 234.710938 148.039062 235.015625 147.941406 235.222656 147.820312 C 235.4375 147.699219 235.59375 147.550781 235.699219 147.382812 C 235.847656 147.144531 235.9375 146.847656 235.96875 146.488281 C 236.003906 146.128906 235.96875 145.703125 235.863281 145.21875 C 235.71875 144.546875 235.503906 144.050781 235.214844 143.734375 C 234.929688 143.410156 234.625 143.214844 234.300781 143.148438 C 234.066406 143.101562 233.710938 143.125 233.234375 143.214844 L 231.957031 143.457031 Z M 233.015625 148.363281 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,90.196078%,0%);fill-opacity:1;"
      d="M 254.953125 151.882812 L 258.1875 146.078125 L 260.257812 147.089844 C 260.621094 147.269531 260.890625 147.421875 261.0625 147.554688 C 261.304688 147.734375 261.480469 147.933594 261.585938 148.160156 C 261.695312 148.378906 261.738281 148.636719 261.714844 148.929688 C 261.695312 149.226562 261.601562 149.519531 261.441406 149.804688 C 261.167969 150.300781 260.785156 150.644531 260.296875 150.84375 C 259.8125 151.039062 259.179688 150.945312 258.402344 150.566406 L 256.996094 149.878906 L 255.679688 152.238281 Z M 257.375 149.191406 L 258.796875 149.886719 C 259.265625 150.117188 259.652344 150.1875 259.949219 150.097656 C 260.25 150.011719 260.492188 149.796875 260.679688 149.464844 C 260.816406 149.21875 260.871094 148.984375 260.851562 148.753906 C 260.832031 148.523438 260.746094 148.335938 260.585938 148.1875 C 260.480469 148.09375 260.265625 147.964844 259.9375 147.804688 L 258.53125 147.117188 Z M 257.375 149.191406 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,90.196078%,0%);fill-opacity:1;"
      d="M 258.523438 153.609375 L 264.960938 150.226562 L 265.617188 150.777344 L 262.835938 157.238281 L 262.140625 156.65625 L 263.019531 154.726562 L 261.089844 153.105469 L 259.171875 154.15625 Z M 261.769531 152.726562 L 263.332031 154.042969 L 264.144531 152.289062 C 264.390625 151.753906 264.601562 151.324219 264.785156 151 C 264.425781 151.265625 264.046875 151.503906 263.644531 151.722656 Z M 261.769531 152.726562 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,90.196078%,0%);fill-opacity:1;"
      d="M 263.515625 158.011719 L 269.058594 154.027344 L 270.730469 156.066406 C 271.066406 156.480469 271.273438 156.828125 271.347656 157.113281 C 271.421875 157.398438 271.386719 157.695312 271.238281 158.003906 C 271.09375 158.3125 270.867188 158.578125 270.5625 158.796875 C 270.167969 159.078125 269.75 159.210938 269.308594 159.195312 C 268.863281 159.175781 268.425781 158.972656 267.992188 158.585938 C 268.03125 158.8125 268.039062 159 268.015625 159.148438 C 267.964844 159.46875 267.855469 159.816406 267.691406 160.1875 L 266.839844 162.074219 L 266.210938 161.304688 L 266.867188 159.867188 C 267.054688 159.449219 267.1875 159.121094 267.269531 158.878906 C 267.351562 158.636719 267.390625 158.441406 267.386719 158.300781 C 267.382812 158.160156 267.355469 158.03125 267.308594 157.917969 C 267.269531 157.835938 267.183594 157.714844 267.054688 157.558594 L 266.476562 156.851562 L 264.015625 158.625 Z M 267.113281 156.394531 L 268.183594 157.707031 C 268.414062 157.984375 268.625 158.175781 268.820312 158.285156 C 269.019531 158.390625 269.230469 158.429688 269.449219 158.40625 C 269.667969 158.375 269.867188 158.296875 270.046875 158.167969 C 270.308594 157.980469 270.460938 157.746094 270.5 157.464844 C 270.539062 157.183594 270.417969 156.875 270.140625 156.535156 L 268.945312 155.078125 Z M 267.113281 156.394531 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,90.196078%,0%);fill-opacity:1;"
      d="M 267.253906 162.488281 L 274.511719 161.242188 L 274.941406 161.960938 L 270.078125 167.230469 L 269.625 166.46875 L 271.117188 164.910156 L 269.855469 162.789062 L 267.675781 163.199219 Z M 270.628906 162.636719 L 271.652344 164.355469 L 273.027344 162.941406 C 273.445312 162.511719 273.792969 162.171875 274.078125 161.921875 C 273.648438 162.0625 273.207031 162.175781 272.75 162.257812 Z M 270.628906 162.636719 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,90.196078%,0%);fill-opacity:1;"
      d="M 273.398438 179.445312 L 280.101562 182.34375 L 280.007812 183.164062 L 272.796875 184.847656 L 272.894531 183.976562 L 275.070312 183.511719 L 275.339844 181.09375 L 273.308594 180.253906 Z M 276.066406 181.390625 L 275.847656 183.347656 L 277.839844 182.933594 C 278.445312 182.804688 278.941406 182.71875 279.324219 182.664062 C 278.886719 182.546875 278.457031 182.402344 278.035156 182.222656 Z M 276.066406 181.390625 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,90.196078%,0%);fill-opacity:1;"
      d="M 207.386719 187.671875 L 200.5625 189.082031 L 199.972656 186.570312 C 199.851562 186.0625 199.824219 185.667969 199.886719 185.378906 C 199.945312 185.089844 200.113281 184.835938 200.386719 184.613281 C 200.660156 184.390625 200.984375 184.242188 201.359375 184.164062 C 201.84375 184.066406 202.28125 184.109375 202.675781 184.304688 C 203.066406 184.496094 203.371094 184.851562 203.589844 185.378906 C 203.660156 185.15625 203.738281 184.984375 203.824219 184.855469 C 204.015625 184.585938 204.269531 184.316406 204.585938 184.046875 L 206.210938 182.675781 L 206.433594 183.621094 L 205.1875 184.664062 C 204.832031 184.964844 204.558594 185.210938 204.375 185.398438 C 204.191406 185.585938 204.070312 185.746094 204.011719 185.875 C 203.949219 186.003906 203.914062 186.132812 203.910156 186.253906 C 203.90625 186.34375 203.929688 186.484375 203.972656 186.679688 L 204.179688 187.546875 L 207.207031 186.921875 Z M 203.398438 187.710938 L 203.019531 186.097656 C 202.9375 185.753906 202.832031 185.496094 202.703125 185.320312 C 202.570312 185.144531 202.398438 185.023438 202.191406 184.960938 C 201.976562 184.902344 201.761719 184.894531 201.539062 184.9375 C 201.21875 185.003906 200.976562 185.15625 200.8125 185.398438 C 200.652344 185.632812 200.617188 185.960938 200.71875 186.382812 L 201.140625 188.175781 Z M 203.398438 187.710938 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,90.196078%,0%);fill-opacity:1;"
      d="M 206.535156 175.847656 L 199.574219 175.285156 L 199.773438 173.105469 C 199.808594 172.71875 199.859375 172.429688 199.921875 172.230469 C 200.003906 171.949219 200.132812 171.722656 200.3125 171.542969 C 200.488281 171.363281 200.726562 171.226562 201.027344 171.132812 C 201.324219 171.039062 201.648438 171.003906 201.992188 171.035156 C 202.582031 171.082031 203.070312 171.277344 203.453125 171.625 C 203.832031 171.96875 203.984375 172.550781 203.910156 173.371094 L 203.773438 174.855469 L 206.605469 175.082031 Z M 202.953125 174.789062 L 203.089844 173.292969 C 203.136719 172.796875 203.058594 172.4375 202.855469 172.210938 C 202.652344 171.984375 202.351562 171.855469 201.949219 171.824219 C 201.65625 171.800781 201.402344 171.84375 201.183594 171.949219 C 200.964844 172.054688 200.8125 172.203125 200.726562 172.398438 C 200.675781 172.523438 200.632812 172.761719 200.601562 173.109375 L 200.464844 174.585938 Z M 202.953125 174.789062 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,90.196078%,0%);fill-opacity:1;"
      d="M 209.453125 166.167969 L 203.640625 162.53125 L 204.929688 160.71875 C 205.191406 160.351562 205.460938 160.09375 205.738281 159.945312 C 206.015625 159.792969 206.320312 159.734375 206.652344 159.761719 C 206.988281 159.785156 207.289062 159.882812 207.554688 160.050781 C 207.804688 160.203125 207.996094 160.40625 208.136719 160.65625 C 208.277344 160.90625 208.335938 161.183594 208.308594 161.496094 C 208.617188 161.265625 208.953125 161.152344 209.3125 161.152344 C 209.675781 161.148438 210.023438 161.253906 210.363281 161.464844 C 210.636719 161.636719 210.855469 161.84375 211.019531 162.085938 C 211.1875 162.324219 211.28125 162.554688 211.308594 162.777344 C 211.335938 162.996094 211.308594 163.234375 211.222656 163.488281 C 211.136719 163.738281 210.980469 164.015625 210.765625 164.324219 Z M 206.539062 163.421875 L 207.28125 162.375 C 207.480469 162.09375 207.605469 161.875 207.648438 161.722656 C 207.703125 161.527344 207.695312 161.34375 207.617188 161.175781 C 207.542969 161.011719 207.402344 160.863281 207.199219 160.734375 C 207.007812 160.613281 206.808594 160.546875 206.609375 160.535156 C 206.40625 160.515625 206.226562 160.566406 206.074219 160.675781 C 205.917969 160.78125 205.714844 161.011719 205.46875 161.355469 L 204.78125 162.324219 Z M 209.222656 165.097656 L 210.078125 163.894531 C 210.226562 163.6875 210.320312 163.539062 210.359375 163.445312 C 210.433594 163.277344 210.464844 163.121094 210.464844 162.976562 C 210.460938 162.832031 210.410156 162.683594 210.316406 162.53125 C 210.21875 162.378906 210.078125 162.242188 209.890625 162.128906 C 209.675781 161.992188 209.453125 161.921875 209.230469 161.914062 C 209.003906 161.90625 208.800781 161.964844 208.617188 162.089844 C 208.4375 162.210938 208.238281 162.425781 208.019531 162.730469 L 207.222656 163.847656 Z M 209.222656 165.097656 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,90.196078%,0%);fill-opacity:1;"
      d="M 220.96875 153.613281 L 219.265625 146.878906 L 220.015625 146.4375 L 225.875 150.722656 L 225.085938 151.1875 L 223.347656 149.867188 L 221.15625 151.160156 L 221.703125 153.175781 Z M 220.957031 150.441406 L 222.734375 149.394531 L 221.15625 148.179688 C 220.679688 147.8125 220.296875 147.503906 220.015625 147.25 C 220.1875 147.644531 220.328125 148.054688 220.441406 148.476562 Z M 220.957031 150.441406 "
    />
  </g>
</svg>
