<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,81.176471%,0%);fill-opacity:1;"
      d="M -61.5 0 L 240 180 L -61.5 360 Z M -61.5 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,81.176471%,0%);fill-opacity:1;"
      d="M 541.5 0 L 240 180 L 541.5 360 Z M 541.5 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M -61.5 0 L 240 180 L 541.5 0 Z M -61.5 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M -61.5 360 L 240 180 L 541.5 360 Z M -61.5 360 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 541.5 0 L 485.320312 0 L -61.5 326.460938 L -61.5 360.003906 L -5.320312 360.003906 L 541.5 33.542969 Z M 541.5 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 344.101562 180 C 344.101562 237.492188 297.496094 284.097656 240 284.097656 C 182.507812 284.097656 135.902344 237.492188 135.902344 180 C 135.902344 122.507812 182.507812 75.898438 240 75.898438 C 297.496094 75.898438 344.101562 122.507812 344.101562 180 Z M 344.101562 180 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M -61.5 0 L -61.5 33.542969 L 485.320312 360.003906 L 541.5 360.003906 L 541.5 326.460938 L -5.320312 0 Z M -61.5 0 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,87.058824%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 134.280495 105.869074 L 127.700873 105.979209 L 124.299978 111.599896 L 120.910475 105.969715 L 114.340348 105.829197 L 117.519074 100.079386 L 114.359337 94.312485 L 120.929464 94.198553 L 124.339854 88.574068 L 127.729356 94.211845 L 134.299484 94.352362 L 131.109364 100.100274 Z M 134.280495 105.869074 "
      transform="matrix(2.057133,0,0,2.057133,-15.740625,-77.048465)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,87.058824%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 134.280495 105.869765 L 127.700873 105.9799 L 124.299978 111.600587 L 120.910475 105.970406 L 114.340348 105.829889 L 117.519074 100.080078 L 114.359337 94.311278 L 120.929464 94.199244 L 124.339854 88.574759 L 127.729356 94.212536 L 134.299484 94.351154 L 131.109364 100.099066 Z M 134.280495 105.869765 "
      transform="matrix(2.057133,0,0,2.057133,-61.092187,1.434489)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,87.058824%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 134.279356 105.869765 L 127.699734 105.9799 L 124.300737 111.600587 L 120.909336 105.970406 L 114.339208 105.829889 L 117.519834 100.080078 L 114.360096 94.311278 L 120.930224 94.199244 L 124.340614 88.574759 L 127.730116 94.212536 L 134.300243 94.351154 L 131.110124 100.099066 Z M 134.279356 105.869765 "
      transform="matrix(2.057133,0,0,2.057133,30.714844,1.434489)"
    />
  </g>
</svg>
