<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(100%,100%,100%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,14.901961%,49.803922%);fill-opacity:1;"
      d="M 0 0 L 159.996094 0 L 159.996094 360 L 0 360 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(95.294118%,9.411765%,18.823529%);fill-opacity:1;"
      d="M 320.003906 0 L 480 0 L 480 360 L 320.003906 360 Z M 320.003906 0 "
    />
  </g>
</svg>
