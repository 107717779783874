<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(100%,100%,100%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,38.823529%);fill-opacity:1;"
      d="M 720 313.058594 C 711.933594 317.914062 705 329.253906 683.566406 329.253906 C 640.714844 329.253906 630 305.375 597.855469 305.375 C 576.429688 305.375 565.714844 329.253906 544.289062 329.253906 C 501.429688 329.253906 490.714844 305.375 458.578125 305.375 C 437.148438 305.375 426.433594 329.253906 405.003906 329.253906 C 362.148438 329.253906 351.433594 305.375 319.292969 305.375 C 297.867188 305.375 287.152344 329.253906 265.722656 329.253906 C 222.867188 329.253906 212.152344 305.375 180.011719 305.375 C 158.582031 305.375 147.867188 329.253906 126.441406 329.253906 C 83.582031 329.253906 72.867188 305.375 40.722656 305.375 C 19.292969 305.375 12.863281 322.390625 0.0078125 322.390625 L 0.0078125 353.136719 C 12.863281 353.136719 19.292969 336.121094 40.722656 336.121094 C 72.867188 336.121094 83.582031 360 126.433594 360 C 147.863281 360 158.574219 336.117188 180.003906 336.117188 C 212.148438 336.117188 222.863281 360 265.714844 360 C 287.144531 360 297.859375 336.117188 319.289062 336.117188 C 351.429688 336.117188 362.144531 360 405 360 C 426.425781 360 437.140625 336.117188 458.570312 336.117188 C 490.714844 336.117188 501.425781 360 544.28125 360 C 565.710938 360 576.425781 336.117188 597.851562 336.117188 C 629.992188 336.117188 640.710938 360 683.5625 360 C 704.992188 360 711.925781 353.230469 719.992188 348.378906 L 719.992188 313.058594 Z M 720 313.058594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,38.823529%);fill-opacity:1;"
      d="M 720 253.351562 C 711.933594 258.203125 705 269.546875 683.566406 269.546875 C 640.714844 269.546875 630 245.664062 597.855469 245.664062 C 576.429688 245.664062 565.714844 269.546875 544.289062 269.546875 C 501.429688 269.546875 490.714844 245.664062 458.578125 245.664062 C 437.148438 245.664062 426.433594 269.546875 405.003906 269.546875 C 362.148438 269.546875 351.433594 245.664062 319.292969 245.664062 C 297.867188 245.664062 287.152344 269.546875 265.722656 269.546875 C 222.867188 269.546875 212.152344 245.664062 180.011719 245.664062 C 158.582031 245.664062 147.867188 269.546875 126.441406 269.546875 C 83.582031 269.546875 72.867188 245.664062 40.722656 245.664062 C 19.292969 245.664062 12.863281 262.683594 0.0078125 262.683594 L 0.0078125 293.429688 C 12.863281 293.433594 19.292969 276.414062 40.722656 276.414062 C 72.863281 276.414062 83.578125 300.292969 126.441406 300.292969 C 147.867188 300.292969 158.582031 276.414062 180.011719 276.414062 C 212.152344 276.414062 222.867188 300.292969 265.722656 300.292969 C 287.152344 300.292969 297.867188 276.414062 319.292969 276.414062 C 351.4375 276.414062 362.152344 300.292969 405.003906 300.292969 C 426.433594 300.292969 437.148438 276.414062 458.578125 276.414062 C 490.71875 276.414062 501.433594 300.292969 544.289062 300.292969 C 565.71875 300.292969 576.429688 276.414062 597.855469 276.414062 C 630 276.414062 640.71875 300.292969 683.566406 300.292969 C 705 300.292969 711.933594 293.527344 720 288.671875 Z M 720 253.351562 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,38.823529%);fill-opacity:1;"
      d="M 720 193.648438 C 711.933594 198.5 705 209.84375 683.566406 209.84375 C 640.714844 209.84375 630 185.960938 597.855469 185.960938 C 576.429688 185.960938 565.714844 209.84375 544.289062 209.84375 C 501.429688 209.84375 490.714844 185.960938 458.578125 185.960938 C 437.148438 185.960938 426.433594 209.84375 405.003906 209.84375 C 362.148438 209.84375 351.433594 185.960938 319.292969 185.960938 C 297.867188 185.960938 287.152344 209.84375 265.722656 209.84375 C 222.867188 209.84375 212.152344 185.960938 180.011719 185.960938 C 158.582031 185.960938 147.867188 209.84375 126.441406 209.84375 C 83.582031 209.84375 72.867188 185.960938 40.722656 185.960938 C 19.292969 185.960938 12.863281 202.980469 0.0078125 202.980469 L 0.0078125 233.726562 C 12.863281 233.726562 19.292969 216.710938 40.722656 216.710938 C 72.863281 216.710938 83.578125 240.589844 126.441406 240.589844 C 147.867188 240.589844 158.582031 216.710938 180.011719 216.710938 C 212.152344 216.710938 222.867188 240.589844 265.722656 240.589844 C 287.152344 240.589844 297.867188 216.710938 319.292969 216.710938 C 351.4375 216.710938 362.152344 240.589844 405.003906 240.589844 C 426.433594 240.589844 437.148438 216.710938 458.578125 216.710938 C 490.71875 216.710938 501.433594 240.589844 544.289062 240.589844 C 565.71875 240.589844 576.429688 216.710938 597.855469 216.710938 C 630 216.710938 640.71875 240.589844 683.566406 240.589844 C 705 240.589844 711.933594 233.824219 720 228.96875 Z M 720 193.648438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,38.823529%);fill-opacity:1;"
      d="M 720 133.945312 C 711.933594 138.796875 705 150.140625 683.566406 150.140625 C 640.714844 150.140625 630 126.261719 597.855469 126.261719 C 576.429688 126.261719 565.714844 150.140625 544.289062 150.140625 C 501.429688 150.140625 490.714844 126.261719 458.578125 126.261719 C 437.148438 126.261719 426.433594 150.140625 405.003906 150.140625 C 362.148438 150.140625 351.433594 126.261719 319.292969 126.261719 C 297.867188 126.261719 287.152344 150.140625 265.722656 150.140625 C 222.867188 150.140625 212.152344 126.261719 180.011719 126.261719 C 158.582031 126.261719 147.867188 150.140625 126.441406 150.140625 C 83.582031 150.140625 72.867188 126.261719 40.722656 126.261719 C 19.292969 126.261719 12.863281 143.277344 0.0078125 143.277344 L 0.0078125 174.023438 C 12.863281 174.023438 19.292969 157.007812 40.722656 157.007812 C 72.863281 157.007812 83.578125 180.890625 126.441406 180.890625 C 147.867188 180.890625 158.582031 157.007812 180.011719 157.007812 C 212.152344 157.007812 222.867188 180.890625 265.722656 180.890625 C 287.152344 180.890625 297.867188 157.007812 319.292969 157.007812 C 351.4375 157.007812 362.152344 180.890625 405.003906 180.890625 C 426.433594 180.890625 437.148438 157.007812 458.578125 157.007812 C 490.71875 157.007812 501.433594 180.890625 544.289062 180.890625 C 565.71875 180.890625 576.429688 157.007812 597.855469 157.007812 C 630 157.007812 640.71875 180.890625 683.566406 180.890625 C 705 180.890625 711.933594 174.121094 720 169.269531 Z M 720 133.945312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,38.823529%);fill-opacity:1;"
      d="M 720 74.242188 C 711.933594 79.097656 705 90.4375 683.566406 90.4375 C 640.714844 90.4375 630 66.558594 597.855469 66.558594 C 576.429688 66.558594 565.714844 90.4375 544.289062 90.4375 C 501.429688 90.4375 490.714844 66.558594 458.578125 66.558594 C 437.148438 66.558594 426.433594 90.4375 405.003906 90.4375 C 362.148438 90.4375 351.433594 66.558594 319.292969 66.558594 C 297.867188 66.558594 287.152344 90.4375 265.722656 90.4375 C 222.867188 90.4375 212.152344 66.558594 180.011719 66.558594 C 158.582031 66.558594 147.867188 90.4375 126.441406 90.4375 C 83.582031 90.4375 72.867188 66.558594 40.722656 66.558594 C 19.285156 66.554688 12.855469 83.574219 -0.00390625 83.574219 L -0.00390625 114.320312 C 12.855469 114.320312 19.28125 97.300781 40.710938 97.300781 C 72.855469 97.300781 83.570312 121.183594 126.429688 121.183594 C 147.859375 121.183594 158.570312 97.300781 180 97.300781 C 212.144531 97.300781 222.859375 121.183594 265.710938 121.183594 C 287.140625 121.183594 297.855469 97.300781 319.285156 97.300781 C 351.425781 97.300781 362.140625 121.183594 404.996094 121.183594 C 426.421875 121.183594 437.136719 97.300781 458.566406 97.300781 C 490.710938 97.300781 501.421875 121.183594 544.277344 121.183594 C 565.707031 121.183594 576.421875 97.300781 597.847656 97.300781 C 629.988281 97.300781 640.707031 121.183594 683.558594 121.183594 C 704.992188 121.183594 711.921875 114.414062 719.988281 109.5625 L 719.988281 74.238281 Z M 720 74.242188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,38.823529%);fill-opacity:1;"
      d="M 720 14.535156 C 711.933594 19.390625 705 30.734375 683.566406 30.734375 C 640.714844 30.734375 630 6.851562 597.855469 6.851562 C 576.429688 6.851562 565.714844 30.734375 544.289062 30.734375 C 501.429688 30.734375 490.714844 6.851562 458.578125 6.851562 C 437.148438 6.851562 426.433594 30.734375 405.003906 30.734375 C 362.148438 30.734375 351.433594 6.851562 319.292969 6.851562 C 297.867188 6.851562 287.152344 30.734375 265.722656 30.734375 C 222.867188 30.734375 212.152344 6.851562 180.011719 6.851562 C 158.582031 6.851562 147.867188 30.734375 126.441406 30.734375 C 83.582031 30.734375 72.867188 6.851562 40.722656 6.851562 C 19.285156 6.851562 12.855469 23.871094 -0.00390625 23.871094 L -0.00390625 54.613281 C 12.855469 54.613281 19.28125 37.597656 40.710938 37.597656 C 72.855469 37.597656 83.570312 61.480469 126.429688 61.480469 C 147.859375 61.480469 158.570312 37.597656 180 37.597656 C 212.144531 37.597656 222.859375 61.480469 265.710938 61.480469 C 287.140625 61.480469 297.855469 37.597656 319.285156 37.597656 C 351.425781 37.597656 362.140625 61.480469 404.996094 61.480469 C 426.421875 61.480469 437.136719 37.597656 458.566406 37.597656 C 490.710938 37.597656 501.421875 61.480469 544.277344 61.480469 C 565.707031 61.480469 576.421875 37.597656 597.847656 37.597656 C 629.988281 37.597656 640.707031 61.480469 683.558594 61.480469 C 704.992188 61.480469 711.921875 54.710938 719.988281 49.859375 L 719.988281 14.535156 Z M 720 14.535156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,38.823529%);fill-opacity:1;"
      d="M 0 0.0429688 L 183.734375 0.0429688 L 183.734375 111.074219 L 0 111.074219 Z M 0 0.0429688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 0.0429688 L 0 12.457031 L 163.195312 111.070312 L 183.734375 111.070312 L 183.734375 98.660156 L 20.542969 0.0429688 Z M 183.734375 0.0429688 L 183.734375 12.457031 L 20.542969 111.070312 L 0 111.070312 L 0 98.660156 L 163.195312 0.0429688 Z M 183.734375 0.0429688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 76.554688 0.0429688 L 76.554688 111.070312 L 107.179688 111.070312 L 107.179688 0.0429688 Z M 0 37.054688 L 0 74.0625 L 183.734375 74.0625 L 183.734375 37.054688 Z M 0 37.054688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 0 44.457031 L 0 66.660156 L 183.734375 66.660156 L 183.734375 44.457031 Z M 82.679688 0.0429688 L 82.679688 111.070312 L 101.054688 111.070312 L 101.054688 0.0429688 Z M 82.679688 0.0429688 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 0 111.070312 L 61.246094 74.0625 L 74.9375 74.0625 L 13.695312 111.070312 Z M 0 0.0429688 L 61.246094 37.054688 L 47.550781 37.054688 L 0 8.320312 Z M 108.796875 37.054688 L 170.039062 0.0429688 L 183.734375 0.0429688 L 122.488281 37.054688 Z M 183.734375 111.070312 L 122.488281 74.0625 L 136.183594 74.0625 L 183.734375 102.796875 Z M 183.734375 111.070312 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(63.529412%,26.27451%,0%);fill-opacity:1;stroke-width:6.9349;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 814.953001 -301.180581 L 797.243692 407.475081 C 797.243692 444.780501 877.33929 444.780501 885.817151 407.475081 L 868.107842 -301.180581 Z M 814.953001 -301.180581 "
      transform="matrix(0.145139,0,0,0.344705,148.804423,188.041191)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,42.745098%,0%);fill-opacity:1;stroke-width:8.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 496.050739 549.205758 L 513.782358 620.089897 L 549.2151 566.932949 L 531.489508 655.498054 L 566.939922 620.066733 L 531.50155 708.660766 L 566.931478 673.222362 L 531.493107 761.816396 L 566.923034 726.377992 L 531.492721 832.66584 L 566.922649 797.227436 L 531.501949 903.547095 L 566.931876 868.108691 L 531.49069 974.421268 L 513.770728 1027.549286 L 496.066006 1045.27203 L 478.346429 1027.550604 L 460.621608 974.41682 L 425.193711 868.099797 L 460.632866 903.542648 L 425.194096 797.250353 L 460.623639 832.661392 L 425.184869 726.369097 L 460.624024 761.811948 L 425.193313 673.213468 L 460.632468 708.656319 L 425.190883 620.082567 L 460.640911 655.50069 L 442.901233 566.922737 L 478.337574 620.084131 Z M 496.050739 549.205758 "
      transform="matrix(-0.165519,0.0474112,-0.0727785,-0.137119,415.767338,226.792273)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 496.050739 549.205758 L 496.066006 1045.27203 "
      transform="matrix(-0.165519,0.0474112,-0.0727785,-0.137119,415.767338,226.792273)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.193711 868.099797 L 496.045906 974.415503 L 566.931876 868.108691 "
      transform="matrix(-0.165519,0.0474112,-0.0727785,-0.137119,415.767338,226.792273)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.194096 797.250353 L 496.057164 903.54133 L 566.943135 797.234519 "
      transform="matrix(-0.165519,0.0474112,-0.0727785,-0.137119,415.767338,226.792273)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.205354 726.37618 L 496.068423 832.667158 L 566.923034 726.377992 "
      transform="matrix(-0.165519,0.0474112,-0.0727785,-0.137119,415.767338,226.792273)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.213798 673.220551 L 496.068808 761.817714 L 566.931478 673.222362 "
      transform="matrix(-0.165519,0.0474112,-0.0727785,-0.137119,415.767338,226.792273)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.190883 620.082567 L 496.077252 708.662084 L 566.929049 620.091461 "
      transform="matrix(-0.165519,0.0474112,-0.0727785,-0.137119,415.767338,226.792273)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.921719 566.92982 L 496.062395 673.217915 L 549.2151 566.932949 "
      transform="matrix(-0.165519,0.0474112,-0.0727785,-0.137119,415.767338,226.792273)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,42.745098%,0%);fill-opacity:1;stroke-width:8.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 496.045505 549.210884 L 513.769593 620.078931 L 549.211561 566.922525 L 531.50156 655.509331 L 566.920986 620.086666 L 531.489742 708.665802 L 566.927771 673.22822 L 531.496527 761.807356 L 566.934556 726.369774 L 531.499373 832.667733 L 566.918799 797.245068 L 531.483616 903.543028 L 566.921645 868.105446 L 531.486462 974.403405 L 513.784339 1027.552563 L 496.071491 1045.278991 L 478.335586 1027.567415 L 460.626161 974.403275 L 425.201043 868.105185 L 460.623315 903.542897 L 425.198197 797.244807 L 460.620469 832.68252 L 425.195351 726.38443 L 460.636226 761.807225 L 425.194835 673.212685 L 460.629441 708.665671 L 425.18805 620.071131 L 460.641259 655.509201 L 442.92111 566.92233 L 478.339443 620.078866 Z M 496.045505 549.210884 "
      transform="matrix(0.124474,-0.127452,0.15523,0.102925,51.915181,65.760492)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 496.045505 549.210884 L 496.071491 1045.278991 "
      transform="matrix(0.124474,-0.127452,0.15523,0.102925,51.915181,65.760492)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.201043 868.105185 L 496.056311 974.40334 L 566.921645 868.105446 "
      transform="matrix(0.124474,-0.127452,0.15523,0.102925,51.915181,65.760492)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.198197 797.244807 L 496.053465 903.542963 L 566.937402 797.230151 "
      transform="matrix(0.124474,-0.127452,0.15523,0.102925,51.915181,65.760492)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.195351 726.38443 L 496.069222 832.667668 L 566.934556 726.369774 "
      transform="matrix(0.124474,-0.127452,0.15523,0.102925,51.915181,65.760492)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.207169 673.227959 L 496.066377 761.807291 L 566.927771 673.22822 "
      transform="matrix(0.124474,-0.127452,0.15523,0.102925,51.915181,65.760492)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.200384 620.086405 L 496.059592 708.665737 L 566.939589 620.071749 "
      transform="matrix(0.124474,-0.127452,0.15523,0.102925,51.915181,65.760492)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.902507 566.937247 L 496.06747 673.228089 L 549.211561 566.922525 "
      transform="matrix(0.124474,-0.127452,0.15523,0.102925,51.915181,65.760492)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,42.745098%,0%);fill-opacity:1;stroke-width:8.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 496.060646 549.214566 L 513.780545 620.072768 L 549.214485 566.938543 L 531.50402 655.50367 L 566.91971 620.064449 L 531.494121 708.653703 L 566.930807 673.224045 L 531.484223 761.803736 L 566.920908 726.374078 L 531.498064 832.667899 L 566.93475 797.238241 L 531.481844 903.540664 L 566.91853 868.111006 L 531.495686 974.404828 L 513.779731 1027.560821 L 496.067353 1045.289515 L 478.346622 1027.56318 L 425.195161 868.102274 L 460.615627 903.545381 L 425.202315 797.247674 L 460.631847 832.672615 L 425.188473 726.383511 L 460.618005 761.808452 L 425.198372 673.233479 L 460.627904 708.65842 L 425.187275 620.073882 L 460.637802 655.508387 L 442.90323 566.917888 L 478.347436 620.075126 Z M 496.060646 549.214566 "
      transform="matrix(0.0798042,0.151589,-0.175197,0.0756544,409.046903,-40.626758)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 496.060646 549.214566 L 496.067353 1045.289515 "
      transform="matrix(0.0798042,0.151589,-0.175197,0.0756544,409.046903,-40.626758)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.195161 868.102274 L 496.062577 974.407186 L 566.91853 868.111006 "
      transform="matrix(0.0798042,0.151589,-0.175197,0.0756544,409.046903,-40.626758)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.202315 797.247674 L 496.069731 903.552587 L 566.93475 797.238241 "
      transform="matrix(0.0798042,0.151589,-0.175197,0.0756544,409.046903,-40.626758)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.188473 726.383511 L 496.064956 832.670257 L 566.941904 726.383641 "
      transform="matrix(0.0798042,0.151589,-0.175197,0.0756544,409.046903,-40.626758)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.198372 673.233479 L 496.072109 761.815658 L 566.930807 673.224045 "
      transform="matrix(0.0798042,0.151589,-0.175197,0.0756544,409.046903,-40.626758)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.20827 620.083446 L 496.061012 708.656061 L 566.940706 620.074012 "
      transform="matrix(0.0798042,0.151589,-0.175197,0.0756544,409.046903,-40.626758)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.90323 566.917888 L 496.064589 673.228762 L 549.214485 566.938543 "
      transform="matrix(0.0798042,0.151589,-0.175197,0.0756544,409.046903,-40.626758)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,42.745098%,0%);fill-opacity:1;stroke-width:8.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 496.064197 549.20073 L 513.781149 620.073529 L 549.222766 566.924272 L 531.489017 655.502872 L 566.929771 620.067149 L 531.497239 708.659861 L 566.937993 673.224137 L 531.494653 761.800459 L 566.91556 726.37795 L 531.502013 832.670981 L 566.92292 797.248471 L 531.489526 903.554716 L 566.919472 868.102602 L 531.486078 974.408847 L 513.784708 1027.563558 L 496.054407 1045.288027 L 478.334869 1027.555826 L 460.627864 974.41295 L 425.203044 868.110807 L 460.620504 903.542429 L 425.195684 797.240286 L 460.623951 832.688297 L 425.188324 726.369765 L 460.636439 761.804562 L 425.19091 673.229166 L 460.628217 708.647573 L 425.182688 620.072178 L 460.630803 655.506975 L 442.914713 566.920643 L 478.342118 620.082187 Z M 496.064197 549.20073 "
      transform="matrix(-0.110265,0.136767,-0.165619,-0.0901875,496.300842,139.07663)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 496.064197 549.20073 L 496.054407 1045.288027 "
      transform="matrix(-0.110265,0.136767,-0.165619,-0.0901875,496.300842,139.07663)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.203044 868.110807 L 496.066895 974.404292 L 566.93028 868.118992 "
      transform="matrix(-0.110265,0.136767,-0.165619,-0.0901875,496.300842,139.07663)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.195684 797.240286 L 496.059534 903.53377 L 566.942768 797.235257 "
      transform="matrix(-0.110265,0.136767,-0.165619,-0.0901875,496.300842,139.07663)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.199132 726.386155 L 496.062982 832.679639 L 566.946216 726.381126 "
      transform="matrix(-0.110265,0.136767,-0.165619,-0.0901875,496.300842,139.07663)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.19091 673.229166 L 496.055622 761.809118 L 566.937993 673.224137 "
      transform="matrix(-0.110265,0.136767,-0.165619,-0.0901875,496.300842,139.07663)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.193496 620.088568 L 496.058208 708.668519 L 566.940579 620.083539 "
      transform="matrix(-0.110265,0.136767,-0.165619,-0.0901875,496.300842,139.07663)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.914713 566.920643 L 496.059932 673.241454 L 549.202918 566.937486 "
      transform="matrix(-0.110265,0.136767,-0.165619,-0.0901875,496.300842,139.07663)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,42.745098%,0%);fill-opacity:1;stroke-width:8.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 496.06147 549.21293 L 513.781755 620.07823 L 549.217372 566.924803 L 531.485477 655.51058 L 566.912148 620.074189 L 531.493093 708.660565 L 566.919763 673.224174 L 531.500709 761.81055 L 566.927379 726.374159 L 531.499377 832.67757 L 566.926048 797.241179 L 531.498046 903.544591 L 566.924716 868.1082 L 513.782714 1027.563317 L 496.052151 1045.282074 L 478.339482 1027.566759 L 460.626136 974.401041 L 425.202128 868.10339 L 460.627467 903.53402 L 425.203459 797.23637 L 460.628798 832.666999 L 425.204791 726.369349 L 460.630129 761.799979 L 425.197175 673.219365 L 460.622514 708.649994 L 425.189559 620.06938 L 460.633468 655.516342 L 442.90356 566.917674 L 478.338522 620.081672 Z M 496.06147 549.21293 "
      transform="matrix(-0.109318,-0.116834,0.124295,-0.10633,193.947998,278.499584)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 496.06147 549.21293 L 496.052151 1045.282074 "
      transform="matrix(-0.109318,-0.116834,0.124295,-0.10633,193.947998,278.499584)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.202128 868.10339 L 496.053482 974.415054 L 566.924716 868.1082 "
      transform="matrix(-0.109318,-0.116834,0.124295,-0.10633,193.947998,278.499584)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.203459 797.23637 L 496.0707 903.530578 L 566.926048 797.241179 "
      transform="matrix(-0.109318,-0.116834,0.124295,-0.10633,193.947998,278.499584)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.204791 726.369349 L 496.056145 832.681013 L 566.927379 726.374159 "
      transform="matrix(-0.109318,-0.116834,0.124295,-0.10633,193.947998,278.499584)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.197175 673.219365 L 496.057476 761.813992 L 566.938333 673.240506 "
      transform="matrix(-0.109318,-0.116834,0.124295,-0.10633,193.947998,278.499584)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.208129 620.085712 L 496.065746 708.646552 L 566.930718 620.090522 "
      transform="matrix(-0.109318,-0.116834,0.124295,-0.10633,193.947998,278.499584)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 442.92213 566.934006 L 496.067754 673.229935 L 549.217372 566.924803 "
      transform="matrix(-0.109318,-0.116834,0.124295,-0.10633,193.947998,278.499584)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,42.745098%,0%);fill-opacity:1;stroke-width:8.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 460.622017 549.218028 L 489.276817 620.070677 L 531.501228 566.922094 L 521.307744 655.520137 L 549.218071 620.076277 L 531.494061 708.654968 L 556.753236 673.223961 L 531.502718 761.811746 L 566.926222 726.381807 L 531.505801 832.666675 L 566.929305 797.236736 L 531.495551 903.544619 L 566.919055 868.114681 L 531.498634 974.399549 L 513.781758 1027.55349 L 496.054666 1045.277251 L 478.348039 1027.553249 L 460.631196 974.399066 L 425.184179 868.113714 L 460.628114 903.544136 L 425.194429 797.23577 L 460.638363 832.666192 L 425.191346 726.380841 L 460.63528 761.811262 L 415.018361 673.222995 L 460.626624 708.654485 L 407.483195 620.075311 L 450.440306 655.519654 L 425.200072 566.921369 L 460.6251 620.072958 Z M 460.622017 549.218028 "
      transform="matrix(-0.190606,-0.0449793,-0.110418,0.143671,481.022689,-22.661107)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 467.214092 584.64245 L 496.064358 708.683172 L 496.072013 1045.282682 "
      transform="matrix(-0.190606,-0.0449793,-0.110418,0.143671,481.022689,-22.661107)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 436.239555 885.821752 L 496.064915 974.399307 L 556.73582 885.834779 "
      transform="matrix(-0.190606,-0.0449793,-0.110418,0.143671,481.022689,-22.661107)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 434.543301 814.953327 L 496.061832 903.544378 L 556.746069 814.956835 "
      transform="matrix(-0.190606,-0.0449793,-0.110418,0.143671,481.022689,-22.661107)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 435.384797 744.090922 L 496.05875 832.689448 L 555.898409 744.109381 "
      transform="matrix(-0.190606,-0.0449793,-0.110418,0.143671,481.022689,-22.661107)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 432.838391 690.928124 L 496.068999 761.811504 L 549.221153 690.931207 "
      transform="matrix(-0.190606,-0.0449793,-0.110418,0.143671,481.022689,-22.661107)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.20717 637.804745 L 496.07769 708.660157 L 541.472514 637.798256 "
      transform="matrix(-0.190606,-0.0449793,-0.110418,0.143671,481.022689,-22.661107)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 436.127134 593.235268 L 489.264485 680.126835 L 524.60025 584.636598 "
      transform="matrix(-0.190606,-0.0449793,-0.110418,0.143671,481.022689,-22.661107)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,42.745098%,0%);fill-opacity:1;stroke-width:8.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 460.622069 549.217525 L 489.270775 620.065544 L 531.487652 566.924363 L 521.313792 655.500038 L 549.204381 620.081538 L 531.502988 708.650828 L 556.740368 673.23177 L 531.490665 761.80036 L 566.929563 726.38256 L 531.487525 832.677901 L 566.927263 797.237169 L 531.505161 903.549757 L 566.924123 868.11471 L 531.50286 974.404366 L 513.781422 1027.563502 L 496.061552 1045.283868 L 478.343253 1027.565464 L 460.626524 974.408289 L 425.192226 868.116872 L 460.629664 903.530748 L 425.195366 797.239331 L 460.631964 832.67614 L 425.197666 726.384722 L 460.635104 761.798599 L 415.008471 673.233932 L 460.626651 708.654752 L 407.472485 620.0837 L 450.437456 655.503961 L 425.193924 566.924564 L 460.619769 620.072134 Z M 460.622069 549.217525 "
      transform="matrix(0.189922,0.0470765,0.00695261,0.172064,169.289154,-97.325582)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 467.202422 584.64897 L 496.06398 708.675722 L 496.061552 1045.283868 "
      transform="matrix(0.189922,0.0470765,0.00695261,0.172064,169.289154,-97.325582)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 436.226403 885.825158 L 496.064692 974.406328 L 556.752564 885.835776 "
      transform="matrix(0.189922,0.0470765,0.00695261,0.172064,169.289154,-97.325582)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 434.521945 814.960768 L 496.067832 903.528787 L 556.734928 814.963919 "
      transform="matrix(0.189922,0.0470765,0.00695261,0.172064,169.289154,-97.325582)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 435.388432 744.096742 L 496.070133 832.674178 L 555.894656 744.090112 "
      transform="matrix(0.189922,0.0470765,0.00695261,0.172064,169.289154,-97.325582)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 432.829809 690.946847 L 496.073272 761.796637 L 549.222857 690.930463 "
      transform="matrix(0.189922,0.0470765,0.00695261,0.172064,169.289154,-97.325582)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.190784 637.802104 L 496.06398 708.675722 L 541.480792 637.791209 "
      transform="matrix(0.189922,0.0470765,0.00695261,0.172064,169.289154,-97.325582)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 436.118531 593.24431 L 489.272493 680.135445 L 524.606634 584.653233 "
      transform="matrix(0.189922,0.0470765,0.00695261,0.172064,169.289154,-97.325582)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,42.745098%,0%);fill-opacity:1;stroke-width:8.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 354.33352 531.50483 L 442.92198 620.077268 L 460.6244 566.938807 L 460.634897 655.523284 L 496.072703 620.092253 L 478.340558 708.660199 L 513.778364 673.229168 L 496.073269 761.816053 L 531.487971 726.388291 L 513.779565 832.688284 L 549.217371 797.257253 L 513.766323 903.540715 L 549.204129 868.109684 L 531.495723 974.409677 L 513.774144 1027.573616 L 496.055241 1045.289131 L 478.342959 1027.578432 L 460.633353 974.419308 L 407.479121 892.125285 L 442.903953 903.550346 L 407.488685 797.254308 L 442.913249 832.675707 L 389.759285 726.385346 L 425.187795 761.828954 L 372.049679 673.226222 L 425.193781 708.667422 L 354.320914 620.092577 L 407.484174 655.508299 L 336.607997 584.646561 L 407.467691 620.085353 Z M 354.33352 531.50483 "
      transform="matrix(0.0242781,-0.164928,0.171576,0.0292995,81.184496,161.394179)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 378.964388 560.029794 L 442.919305 655.525692 L 460.624966 708.662607 L 469.486071 753.875975 L 478.34838 832.6931 L 487.666845 903.545568 L 496.055241 1045.289131 "
      transform="matrix(0.0242781,-0.164928,0.171576,0.0292995,81.184496,161.394179)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 425.211464 903.549485 L 488.863457 974.408939 L 539.607079 885.814297 "
      transform="matrix(0.0242781,-0.164928,0.171576,0.0292995,81.184496,161.394179)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 417.075168 814.953701 L 487.666845 903.545568 L 538.410467 814.950927 "
      transform="matrix(0.0242781,-0.164928,0.171576,0.0292995,81.184496,161.394179)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 407.487081 752.449298 L 478.344434 832.670891 L 524.306684 744.092577 "
      transform="matrix(0.0242781,-0.164928,0.171576,0.0292995,81.184496,161.394179)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 372.056299 637.800007 L 460.624966 708.662607 L 486.45255 637.800136 "
      transform="matrix(0.0242781,-0.164928,0.171576,0.0292995,81.184496,161.394179)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 360.619744 602.353791 L 442.915359 655.503483 L 453.439803 602.356199 "
      transform="matrix(0.0242781,-0.164928,0.171576,0.0292995,81.184496,161.394179)"
    />
    <path
      style="fill:none;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 389.761961 690.936922 L 470.848356 761.81099 L 504.797794 690.937617 "
      transform="matrix(0.0242781,-0.164928,0.171576,0.0292995,81.184496,161.394179)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(80%,0%,0%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 541.450082 1173.316845 L 539.741806 944.166196 L 478.650625 837.235118 C 463.398166 784.60912 470.922713 759.157756 495.652032 757.434487 C 520.340679 755.755404 544.866633 771.043897 580.496376 772.72298 C 616.12612 774.446249 609.374364 713.336464 661.964842 715.015547 C 714.595993 716.738816 806.273438 747.271616 884.325353 752.353051 C 962.417942 757.434487 1003.131838 725.222604 1093.101008 721.820252 C 1183.070178 718.417899 1206.82334 764.239192 1211.907493 764.239192 C 1217.032319 764.239192 1242.493757 745.592533 1267.955194 742.19018 C 1293.375959 738.787828 1301.876663 752.353051 1301.876663 752.353051 C 1301.876663 752.353051 1300.168387 810.060484 1288.291806 844.039823 C 1276.415225 877.974975 1233.993053 933.959138 1232.284778 933.959138 C 1230.576502 933.959138 1217.032319 1183.479716 1217.032319 1185.202985 C 1217.032319 1186.882068 541.450082 1178.39828 541.450082 1173.316845 Z M 541.450082 1173.316845 "
      transform="matrix(0.0960398,0,0,0.0884039,187.284375,146.383583)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,0%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 531.483944 584.666388 C 531.483944 584.666388 463.518988 532.570627 461.688693 407.47894 C 460.631189 335.543487 496.057566 283.447727 584.643847 283.447727 C 708.656504 283.447727 885.829064 336.603961 885.829064 336.603961 L 885.829064 354.322706 C 885.829064 354.322706 744.082881 301.166471 584.643847 301.166471 C 513.791092 301.166471 478.364714 353.262232 478.364714 406.418466 C 478.364714 495.01219 549.217469 584.666388 549.217469 584.666388 L 549.217469 726.372159 L 531.483944 726.372159 Z M 531.483944 584.666388 "
      transform="matrix(-0.0960398,0,0,0.0884039,357.438166,177.707739)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.330291 549.203029 C 478.330291 559.012408 470.399013 566.921773 460.637439 566.921773 C 450.835192 566.921773 442.903914 559.012408 442.903914 549.203029 C 442.903914 539.437835 450.835192 531.484284 460.637439 531.484284 C 470.399013 531.484284 478.330291 539.437835 478.330291 549.203029 Z M 478.330291 549.203029 "
      transform="matrix(-0.0960398,0,0,0.0884039,355.899704,177.557682)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.353839 549.209704 C 478.353839 558.974897 470.42256 566.928448 460.620314 566.928448 C 450.85874 566.928448 442.927462 558.974897 442.927462 549.209704 C 442.927462 539.44451 450.85874 531.490959 460.620314 531.490959 C 470.42256 531.490959 478.353839 539.44451 478.353839 549.209704 Z M 478.353839 549.209704 "
      transform="matrix(-0.0960398,0,0,0.0884039,357.601184,173.975061)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.350617 549.20761 C 478.350617 558.972803 470.419339 566.926355 460.617092 566.926355 C 450.855518 566.926355 442.92424 559.01699 442.92424 549.20761 C 442.92424 539.442416 450.855518 531.488865 460.617092 531.488865 C 470.419339 531.488865 478.350617 539.442416 478.350617 549.20761 Z M 478.350617 549.20761 "
      transform="matrix(-0.0960398,0,0,0.0884039,358.487594,170.326808)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.348189 549.1944 C 478.348189 559.00378 470.416911 566.913145 460.614664 566.913145 C 450.85309 566.913145 442.921812 559.00378 442.921812 549.1944 C 442.921812 539.429207 450.85309 531.475656 460.614664 531.475656 C 470.416911 531.475656 478.348189 539.429207 478.348189 549.1944 Z M 478.348189 549.1944 "
      transform="matrix(-0.0960398,0,0,0.0884039,359.139704,166.425632)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.358464 549.190534 C 478.358464 558.999913 470.427186 566.909278 460.624939 566.909278 C 450.863366 566.909278 442.932087 558.999913 442.932087 549.190534 C 442.932087 539.42534 450.863366 531.471789 460.624939 531.471789 C 470.427186 531.471789 478.358464 539.42534 478.358464 549.190534 Z M 478.358464 549.190534 "
      transform="matrix(-0.0960398,0,0,0.0884039,358.976628,162.195505)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.330729 549.21705 C 478.330729 558.982244 470.39945 566.935795 460.637877 566.935795 C 450.83563 566.935795 442.904351 558.982244 442.904351 549.21705 C 442.904351 539.40767 450.83563 531.498305 460.637877 531.498305 C 470.39945 531.498305 478.330729 539.40767 478.330729 549.21705 Z M 478.330729 549.21705 "
      transform="matrix(-0.0960398,0,0,0.0884039,353.454433,180.708786)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.342957 549.222037 C 478.342957 558.98723 470.411678 566.940782 460.650105 566.940782 C 450.847858 566.940782 442.91658 558.98723 442.91658 549.222037 C 442.91658 539.412657 450.847858 531.503292 460.650105 531.503292 C 470.411678 531.503292 478.342957 539.412657 478.342957 549.222037 Z M 478.342957 549.222037 "
      transform="matrix(-0.0960398,0,0,0.0884039,357.529827,158.200533)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.347423 549.203615 C 478.347423 559.012995 470.416144 566.92236 460.613898 566.92236 C 450.852324 566.92236 442.921046 559.012995 442.921046 549.203615 C 442.921046 539.438422 450.852324 531.484871 460.613898 531.484871 C 470.416144 531.484871 478.347423 539.438422 478.347423 549.203615 Z M 478.347423 549.203615 "
      transform="matrix(-0.0960398,0,0,0.0884039,354.432599,155.049818)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.34587 549.191334 C 478.34587 559.000714 470.414592 566.910079 460.653018 566.910079 C 450.850771 566.910079 442.919493 559.000714 442.919493 549.191334 C 442.919493 539.426141 450.850771 531.47259 460.653018 531.47259 C 470.414592 531.47259 478.34587 539.426141 478.34587 549.191334 Z M 478.34587 549.191334 "
      transform="matrix(-0.0960398,0,0,0.0884039,350.194169,152.949341)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.343317 549.188982 C 478.343317 558.998362 470.412039 566.907727 460.650465 566.907727 C 450.848218 566.907727 442.91694 558.998362 442.91694 549.188982 C 442.91694 539.423788 450.848218 531.514424 460.650465 531.514424 C 470.412039 531.514424 478.343317 539.423788 478.343317 549.188982 Z M 478.343317 549.188982 "
      transform="matrix(-0.0960398,0,0,0.0884039,345.955642,152.648767)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.370315 549.212251 C 478.370315 558.977445 470.398363 566.930996 460.63679 566.930996 C 450.834543 566.930996 442.903265 558.977445 442.903265 549.212251 C 442.903265 539.447058 450.834543 531.493507 460.63679 531.493507 C 470.398363 531.493507 478.370315 539.447058 478.370315 549.212251 Z M 478.370315 549.212251 "
      transform="matrix(-0.0960398,0,0,0.0884039,341.391829,152.799054)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.34664 549.226956 C 478.34664 558.99215 470.415361 566.945701 460.613115 566.945701 C 450.851541 566.945701 442.920263 558.99215 442.920263 549.226956 C 442.920263 539.417576 450.851541 531.508212 460.613115 531.508212 C 470.415361 531.508212 478.34664 539.417576 478.34664 549.226956 Z M 478.34664 549.226956 "
      transform="matrix(-0.0960398,0,0,0.0884039,336.827055,153.399317)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.363638 549.197475 C 478.363638 559.006855 470.432359 566.91622 460.630113 566.91622 C 450.868539 566.91622 442.896587 559.006855 442.896587 549.197475 C 442.896587 539.432281 450.827866 531.47873 460.630113 531.47873 C 470.432359 531.47873 478.363638 539.432281 478.363638 549.197475 Z M 478.363638 549.197475 "
      transform="matrix(-0.0960398,0,0,0.0884039,332.262282,153.999579)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.366513 549.201263 C 478.366513 559.010643 470.435235 566.920008 460.632988 566.920008 C 450.830741 566.920008 442.899463 559.010643 442.899463 549.201263 C 442.899463 539.436069 450.830741 531.482518 460.632988 531.482518 C 470.435235 531.482518 478.366513 539.436069 478.366513 549.201263 Z M 478.366513 549.201263 "
      transform="matrix(-0.0960398,0,0,0.0884039,327.371933,154.749244)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.36696 549.197403 C 478.36696 559.006783 470.435682 566.916148 460.633435 566.916148 C 450.831188 566.916148 442.89991 559.006783 442.89991 549.197403 C 442.89991 539.43221 450.831188 531.478659 460.633435 531.478659 C 470.435682 531.478659 478.36696 539.43221 478.36696 549.197403 Z M 478.36696 549.197403 "
      transform="matrix(-0.0960398,0,0,0.0884039,323.133694,155.800367)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.331489 549.205629 C 478.331489 559.015008 470.400211 566.924373 460.638637 566.924373 C 450.83639 566.924373 442.905112 559.015008 442.905112 549.205629 C 442.905112 539.440435 450.83639 531.486884 460.638637 531.486884 C 470.400211 531.486884 478.331489 539.440435 478.331489 549.205629 Z M 478.331489 549.205629 "
      transform="matrix(0.0960398,0,0,0.0884039,188.822837,177.557452)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.355437 549.206704 C 478.355437 559.016083 470.424159 566.925448 460.621912 566.925448 C 450.860338 566.925448 442.92906 559.016083 442.92906 549.206704 C 442.92906 539.44151 450.860338 531.487959 460.621912 531.487959 C 470.424159 531.487959 478.355437 539.44151 478.355437 549.206704 Z M 478.355437 549.206704 "
      transform="matrix(0.0960398,0,0,0.0884039,187.121319,173.975326)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.352816 549.20661 C 478.352816 559.01599 470.421537 566.925355 460.61929 566.925355 C 450.857717 566.925355 442.926438 559.01599 442.926438 549.20661 C 442.926438 539.441416 450.857717 531.487865 460.61929 531.487865 C 470.421537 531.487865 478.352816 539.441416 478.352816 549.20661 Z M 478.352816 549.20661 "
      transform="matrix(0.0960398,0,0,0.0884039,186.234852,170.326897)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.349387 549.1944 C 478.349387 559.00378 470.418109 566.913145 460.615862 566.913145 C 450.854288 566.913145 442.92301 559.00378 442.92301 549.1944 C 442.92301 539.429207 450.854288 531.475656 460.615862 531.475656 C 470.418109 531.475656 478.349387 539.429207 478.349387 549.1944 Z M 478.349387 549.1944 "
      transform="matrix(0.0960398,0,0,0.0884039,185.582837,166.425632)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.360663 549.190534 C 478.360663 558.999913 470.429384 566.909278 460.627137 566.909278 C 450.865564 566.909278 442.934285 558.999913 442.934285 549.190534 C 442.934285 539.42534 450.865564 531.471789 460.627137 531.471789 C 470.429384 531.471789 478.360663 539.42534 478.360663 549.190534 Z M 478.360663 549.190534 "
      transform="matrix(0.0960398,0,0,0.0884039,185.745817,162.195505)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.344615 549.222037 C 478.344615 558.98723 470.413337 566.940782 460.651763 566.940782 C 450.849516 566.940782 442.918238 558.98723 442.918238 549.222037 C 442.918238 539.412657 450.849516 531.503292 460.651763 531.503292 C 470.413337 531.503292 478.344615 539.412657 478.344615 549.222037 Z M 478.344615 549.222037 "
      transform="matrix(0.0960398,0,0,0.0884039,187.19267,158.200533)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.348621 549.204615 C 478.348621 559.013995 470.417343 566.92336 460.615096 566.92336 C 450.853522 566.92336 442.922244 559.013995 442.922244 549.204615 C 442.922244 539.439422 450.853522 531.485871 460.615096 531.485871 C 470.417343 531.485871 478.348621 539.439422 478.348621 549.204615 Z M 478.348621 549.204615 "
      transform="matrix(0.0960398,0,0,0.0884039,190.289942,155.049729)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.348068 549.195334 C 478.348068 559.004714 470.41679 566.914079 460.614543 566.914079 C 450.85297 566.914079 442.921691 559.004714 442.921691 549.195334 C 442.921691 539.430141 450.85297 531.47659 460.614543 531.47659 C 470.41679 531.47659 478.348068 539.430141 478.348068 549.195334 Z M 478.348068 549.195334 "
      transform="matrix(0.0960398,0,0,0.0884039,194.528276,152.948987)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.344516 549.187982 C 478.344516 558.997362 470.413237 566.906727 460.651663 566.906727 C 450.849417 566.906727 442.918138 558.997362 442.918138 549.187982 C 442.918138 539.422788 450.849417 531.513424 460.651663 531.513424 C 470.413237 531.513424 478.344516 539.422788 478.344516 549.187982 Z M 478.344516 549.187982 "
      transform="matrix(0.0960398,0,0,0.0884039,198.766899,152.648856)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.33084 549.214251 C 478.33084 558.979445 470.399562 566.932996 460.637988 566.932996 C 450.835741 566.932996 442.904463 558.979445 442.904463 549.214251 C 442.904463 539.404872 450.835741 531.495507 460.637988 531.495507 C 470.399562 531.495507 478.33084 539.404872 478.33084 549.214251 Z M 478.33084 549.214251 "
      transform="matrix(0.0960398,0,0,0.0884039,203.330712,152.798877)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.347838 549.228956 C 478.347838 558.99415 470.41656 566.947701 460.614313 566.947701 C 450.852739 566.947701 442.921461 558.99415 442.921461 549.228956 C 442.921461 539.419576 450.852739 531.510212 460.614313 531.510212 C 470.41656 531.510212 478.347838 539.419576 478.347838 549.228956 Z M 478.347838 549.228956 "
      transform="matrix(0.0960398,0,0,0.0884039,207.895486,153.39914)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.364836 549.200475 C 478.364836 559.009855 470.433558 566.91922 460.631311 566.91922 C 450.829064 566.91922 442.897785 559.009855 442.897785 549.200475 C 442.897785 539.435281 450.829064 531.48173 460.631311 531.48173 C 470.433558 531.48173 478.364836 539.435281 478.364836 549.200475 Z M 478.364836 549.200475 "
      transform="matrix(0.0960398,0,0,0.0884039,212.46026,153.999314)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.367711 549.197263 C 478.367711 559.006643 470.436433 566.916008 460.634186 566.916008 C 450.831939 566.916008 442.900661 559.006643 442.900661 549.197263 C 442.900661 539.432069 450.831939 531.478518 460.634186 531.478518 C 470.436433 531.478518 478.367711 539.432069 478.367711 549.197263 Z M 478.367711 549.197263 "
      transform="matrix(0.0960398,0,0,0.0884039,217.350608,154.749598)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.368159 549.197403 C 478.368159 559.006783 470.43688 566.916148 460.634633 566.916148 C 450.832387 566.916148 442.901108 559.006783 442.901108 549.197403 C 442.901108 539.43221 450.832387 531.478659 460.634633 531.478659 C 470.43688 531.478659 478.368159 539.43221 478.368159 549.197403 Z M 478.368159 549.197403 "
      transform="matrix(0.0960398,0,0,0.0884039,221.588847,155.800367)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.332927 549.21405 C 478.332927 558.979244 470.401648 566.932795 460.640075 566.932795 C 450.837828 566.932795 442.90655 558.979244 442.90655 549.21405 C 442.90655 539.40467 450.837828 531.495305 460.640075 531.495305 C 470.401648 531.495305 478.332927 539.40467 478.332927 549.21405 Z M 478.332927 549.21405 "
      transform="matrix(0.0960398,0,0,0.0884039,191.268012,180.709051)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,0%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 531.4856 584.666388 C 531.4856 584.666388 463.520644 532.570627 461.690349 407.47894 C 460.632845 335.543487 496.059222 283.447727 584.645503 283.447727 C 708.65816 283.447727 885.83072 336.603961 885.83072 336.603961 L 885.83072 354.322706 C 885.83072 354.322706 744.084537 301.166471 584.645503 301.166471 C 513.792748 301.166471 478.36637 353.262232 478.36637 406.418466 C 478.36637 495.01219 549.219125 584.666388 549.219125 584.666388 L 549.219125 726.372159 L 531.4856 726.372159 Z M 531.4856 584.666388 "
      transform="matrix(0.0960398,0,0,0.0884039,187.284331,177.707739)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,0%);fill-opacity:1;stroke-width:2.9915;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 1240.237821 531.513141 C 1240.237821 531.513141 1255.490432 496.020197 1311.009937 496.020197 C 1348.836413 496.020197 1381.904074 531.513141 1381.904074 566.890095 L 1381.904074 637.759994 L 1417.412153 637.759994 L 1417.412153 566.890095 C 1417.412153 531.513141 1452.798211 496.020197 1488.184269 496.020197 C 1541.385377 496.020197 1559.078406 531.513141 1559.078406 531.513141 C 1559.078406 531.513141 1559.078406 425.150298 1488.184269 425.150298 C 1435.105182 425.150298 1417.412153 460.643242 1417.412153 460.643242 C 1417.412153 460.643242 1435.105182 407.519816 1435.105182 354.280399 C 1435.105182 301.156973 1399.597103 265.780018 1399.597103 265.780018 C 1399.597103 272.507439 1364.211045 301.156973 1364.211045 354.280399 C 1364.211045 407.519816 1381.904074 460.643242 1381.904074 460.643242 C 1381.904074 460.643242 1364.211045 425.150298 1311.009937 425.150298 C 1240.237821 425.150298 1240.237821 531.513141 1240.237821 531.513141 Z M 1240.237821 531.513141 "
      transform="matrix(0.032013,0,0,0.0336775,210.538502,217.310921)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,0%);fill-opacity:1;stroke-width:2.9915;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 1240.161162 531.513141 C 1240.161162 531.513141 1255.535794 496.020197 1311.055299 496.020197 C 1348.881774 496.020197 1381.949436 531.513141 1381.949436 566.890095 L 1381.949436 637.759994 L 1417.335494 637.759994 L 1417.335494 566.890095 C 1417.335494 531.513141 1452.843573 496.020197 1488.229631 496.020197 C 1541.430739 496.020197 1559.123768 531.513141 1559.123768 531.513141 C 1559.123768 531.513141 1559.123768 425.150298 1488.229631 425.150298 C 1435.028523 425.150298 1417.335494 460.643242 1417.335494 460.643242 C 1417.335494 460.643242 1435.028523 407.519816 1435.028523 354.280399 C 1435.028523 301.156973 1399.642465 265.780018 1399.642465 265.780018 C 1399.642465 272.507439 1364.256407 301.156973 1364.256407 354.280399 C 1364.256407 407.519816 1381.949436 460.643242 1381.949436 460.643242 C 1381.949436 460.643242 1364.256407 425.150298 1311.055299 425.150298 C 1240.161162 425.150298 1240.161162 531.513141 1240.161162 531.513141 Z M 1240.161162 531.513141 "
      transform="matrix(0.032013,0,0,0.0336775,244.5683,217.310921)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,0%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 531.4856 832.684628 L 531.4856 673.215925 C 531.4856 673.215925 566.95265 726.372159 620.07188 726.372159 C 663.592229 726.372159 708.65816 655.49718 708.65816 655.49718 C 708.65816 655.49718 750.185521 708.653414 797.24444 708.653414 C 839.25988 708.653414 885.749374 640.076128 885.749374 640.076128 C 885.749374 640.076128 928.98501 708.653414 974.417 708.653414 C 1019.848991 708.653414 1063.00328 655.49718 1063.00328 655.49718 C 1063.00328 655.49718 1109.289408 726.372159 1169.282413 726.372159 C 1222.442315 726.372159 1240.175841 673.215925 1240.175841 673.215925 L 1240.175841 832.684628 Z M 531.4856 832.684628 "
      transform="matrix(0.0960398,0,0,0.0884039,187.284331,177.707739)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,0%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 708.655142 832.684628 L 708.655142 708.653414 C 708.655142 708.653414 814.974947 744.090904 814.974947 832.684628 Z M 708.655142 832.684628 "
      transform="matrix(0.0960398,0,0,0.0884039,170.268996,177.707739)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,0%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 708.66529 832.684628 L 708.66529 708.653414 C 708.66529 708.653414 814.944422 744.090904 814.944422 832.684628 Z M 708.66529 832.684628 "
      transform="matrix(-0.0960398,0,0,0.0884039,374.446822,177.707739)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,0%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 602.379028 832.684628 C 602.379028 744.090904 708.739506 688.062554 708.739506 688.062554 C 708.739506 688.062554 814.977965 744.090904 814.977965 832.684628 Z M 602.379028 832.684628 "
      transform="matrix(0.0960398,0,0,0.0884039,187.284331,177.707739)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,0%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 602.353017 832.684628 C 602.353017 744.090904 708.713496 688.062554 708.713496 688.062554 C 708.713496 688.062554 814.951955 744.090904 814.951955 832.684628 Z M 602.353017 832.684628 "
      transform="matrix(0.0960398,0,0,0.0884039,221.314173,177.707739)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,0%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 584.657605 847.534628 C 584.657605 758.940904 708.710936 688.065925 708.710936 688.065925 C 708.710936 688.065925 832.68292 758.940904 832.68292 847.534628 Z M 584.657605 847.534628 "
      transform="matrix(0.0960398,0,0,0.0884039,204.298794,176.394941)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 1275.591079 655.514628 C 1240.173338 637.795883 1109.567849 620.077138 899.323863 620.077138 C 689.079877 620.077138 549.21888 637.795883 513.80114 655.514628 C 478.342263 673.233372 478.342263 708.670862 513.80114 726.389607 C 549.21888 744.108351 689.079877 761.827096 899.323863 761.827096 C 1109.608984 761.827096 1240.173338 744.108351 1275.591079 726.389607 C 1311.049955 708.670862 1311.049955 673.233372 1275.591079 655.514628 Z M 1275.591079 655.514628 "
      transform="matrix(0.0949604,0,0,0.0884039,186.959261,193.370259)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(50.196078%,50.196078%,50.196078%);fill-opacity:1;"
      d="M 306.390625 257.585938 C 306.390625 260.714844 278.085938 260.714844 272.359375 260.714844 C 266.175781 260.714844 238.332031 261.566406 238.332031 257.585938 C 238.332031 254.453125 266.40625 254.453125 272.359375 254.453125 C 278.316406 254.453125 306.390625 255.089844 306.390625 257.585938 Z M 306.390625 257.585938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 241.730469 246.332031 C 241.730469 247.359375 240.96875 248.1875 240.03125 248.1875 C 239.089844 248.1875 238.328125 247.359375 238.328125 246.332031 C 238.328125 245.308594 239.089844 244.480469 240.03125 244.480469 C 240.96875 244.480469 241.730469 245.308594 241.730469 246.332031 Z M 241.730469 246.332031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 306.390625 246.332031 C 306.390625 247.359375 305.628906 248.1875 304.6875 248.1875 C 303.746094 248.1875 302.984375 247.359375 302.984375 246.332031 C 302.984375 245.308594 303.746094 244.480469 304.6875 244.480469 C 305.628906 244.480469 306.390625 245.308594 306.390625 246.332031 Z M 306.390625 246.332031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 275.761719 245.34375 C 275.761719 246.367188 274.238281 247.195312 272.359375 247.195312 C 270.480469 247.195312 268.957031 246.367188 268.957031 245.34375 C 268.957031 244.320312 270.480469 243.488281 272.359375 243.488281 C 274.238281 243.488281 275.761719 244.320312 275.761719 245.34375 Z M 275.761719 245.34375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,38.823529%,0%);fill-opacity:1;"
      d="M 292.085938 245.34375 C 292.085938 246.367188 290.941406 247.195312 289.53125 247.195312 C 288.125 247.195312 286.980469 246.367188 286.980469 245.34375 C 286.980469 244.320312 288.125 243.488281 289.53125 243.488281 C 290.941406 243.488281 292.085938 244.320312 292.085938 245.34375 Z M 292.085938 245.34375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,38.823529%,0%);fill-opacity:1;"
      d="M 257.046875 245.34375 C 257.046875 246.367188 255.902344 247.195312 254.492188 247.195312 C 253.082031 247.195312 251.941406 246.367188 251.941406 245.34375 C 251.941406 244.320312 253.082031 243.488281 254.492188 243.488281 C 255.902344 243.488281 257.046875 244.320312 257.046875 245.34375 Z M 257.046875 245.34375 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,0%);fill-opacity:1;stroke-width:2.1875;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 1257.90327 496.071034 C 1257.90327 496.071034 1293.351136 442.948072 1328.799002 442.948072 L 1364.175688 442.948072 L 1364.175688 478.36338 C 1364.175688 531.486342 1311.075069 549.193996 1311.075069 549.193996 L 1452.795353 549.193996 C 1452.795353 549.193996 1399.623554 531.486342 1399.623554 478.36338 L 1399.623554 442.948072 L 1435.07142 442.948072 C 1470.519286 442.948072 1505.967152 496.071034 1505.967152 496.071034 L 1505.967152 354.332477 C 1505.967152 354.332477 1470.519286 407.455438 1435.07142 407.455438 L 1399.623554 407.455438 L 1399.623554 372.040131 C 1399.623554 318.917169 1452.795353 301.209515 1452.795353 301.209515 L 1311.075069 301.209515 C 1311.075069 301.209515 1364.175688 318.917169 1364.175688 372.040131 L 1364.175688 407.455438 L 1328.799002 407.455438 C 1293.351136 407.455438 1257.90327 354.332477 1257.90327 354.332477 Z M 1257.90327 496.071034 "
      transform="matrix(0.0548781,0,0,0.0505166,196.523312,209.479219)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,0%);fill-opacity:1;stroke-width:3.3072;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 1381.838725 549.186829 L 1452.733216 549.186829 C 1452.733216 549.186829 1399.633527 531.51227 1399.633527 478.31185 L 1399.633527 442.87436 L 1435.080773 442.87436 C 1470.528018 442.87436 1505.975264 496.074781 1505.975264 496.074781 L 1505.975264 354.324823 C 1505.975264 354.324823 1470.528018 407.436871 1435.080773 407.436871 L 1399.633527 407.436871 L 1399.633527 372.087754 C 1399.633527 318.887333 1452.733216 301.212775 1452.733216 301.212775 L 1381.838725 301.212775 Z M 1381.838725 549.186829 "
      transform="matrix(0.0274395,0,0,0.044202,200.411101,216.080338)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,0%);fill-opacity:1;stroke-width:3.3072;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 1381.906888 549.186829 L 1452.80138 549.186829 C 1452.80138 549.186829 1399.559332 531.51227 1399.559332 478.31185 L 1399.559332 442.87436 L 1435.006578 442.87436 C 1470.453823 442.87436 1505.901069 496.074781 1505.901069 496.074781 L 1505.901069 354.324823 C 1505.901069 354.324823 1470.453823 407.436871 1435.006578 407.436871 L 1399.559332 407.436871 L 1399.559332 372.087754 C 1399.559332 318.887333 1452.80138 301.212775 1452.80138 301.212775 L 1381.906888 301.212775 Z M 1381.906888 549.186829 "
      transform="matrix(-0.0274395,0,0,0.044202,344.30952,216.080338)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 903.523572 602.340946 C 903.523572 612.150326 895.592294 620.059691 885.83072 620.059691 C 876.028473 620.059691 868.097195 612.150326 868.097195 602.340946 C 868.097195 592.575752 876.028473 584.622201 885.83072 584.622201 C 895.592294 584.622201 903.523572 592.575752 903.523572 602.340946 Z M 903.523572 602.340946 "
      transform="matrix(0.0960398,0,0,0.0884039,187.284331,177.707739)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,0%);fill-opacity:1;stroke-width:2.1875;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 1257.89802 496.05449 C 1257.89802 496.05449 1293.345886 442.931529 1328.793752 442.931529 L 1364.170438 442.931529 L 1364.170438 478.346837 C 1364.170438 531.469798 1311.069819 549.177452 1311.069819 549.177452 L 1452.790103 549.177452 C 1452.790103 549.177452 1399.618304 531.469798 1399.618304 478.346837 L 1399.618304 442.931529 L 1435.06617 442.931529 C 1470.514036 442.931529 1505.961902 496.05449 1505.961902 496.05449 L 1505.961902 354.315933 C 1505.961902 354.315933 1470.514036 407.516221 1435.06617 407.516221 L 1399.618304 407.516221 L 1399.618304 372.023587 C 1399.618304 318.900626 1452.790103 301.192972 1452.790103 301.192972 L 1311.069819 301.192972 C 1311.069819 301.192972 1364.170438 318.900626 1364.170438 372.023587 L 1364.170438 407.516221 L 1328.793752 407.516221 C 1293.345886 407.516221 1257.89802 354.315933 1257.89802 354.315933 Z M 1257.89802 496.05449 "
      transform="matrix(0.0548781,0,0,0.0505166,196.5236,176.589429)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 903.53449 602.371167 C 903.53449 612.136361 895.603211 620.089912 885.841638 620.089912 C 876.039391 620.089912 868.108113 612.136361 868.108113 602.371167 C 868.108113 592.561787 876.039391 584.652423 885.841638 584.652423 C 895.603211 584.652423 903.53449 592.561787 903.53449 602.371167 Z M 903.53449 602.371167 "
      transform="matrix(0.0960398,0,0,0.0884039,187.447345,145.080067)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,0%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 850.403885 655.496471 L 921.25664 655.496471 L 921.25664 708.652706 L 850.403885 708.652706 Z M 850.403885 655.496471 "
      transform="matrix(0.0960398,0,0,0.0884039,187.284375,146.383583)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,94.509804%,0%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 850.403885 683.289714 L 921.25664 683.289714 L 921.25664 885.840153 L 850.403885 885.840153 Z M 850.403885 683.289714 "
      transform="matrix(0.0960398,0,0,0.0884039,187.284375,146.383583)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.363788 549.222664 C 478.363788 558.987857 470.432509 566.941409 460.630263 566.941409 C 450.868689 566.941409 442.896737 558.987857 442.896737 549.222664 C 442.896737 539.413284 450.828016 531.503919 460.630263 531.503919 C 470.432509 531.503919 478.363788 539.413284 478.363788 549.222664 Z M 478.363788 549.222664 "
      transform="matrix(0.0960398,0,0,0.0884039,228.120517,173.009071)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.363788 549.231371 C 478.363788 558.996565 470.432509 566.90593 460.630263 566.90593 C 450.868689 566.90593 442.896737 558.996565 442.896737 549.231371 C 442.896737 539.421991 450.828016 531.512626 460.630263 531.512626 C 470.432509 531.512626 478.363788 539.421991 478.363788 549.231371 Z M 478.363788 549.231371 "
      transform="matrix(0.0960398,0,0,0.0884039,228.120517,168.95752)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.363788 549.20894 C 478.363788 558.974133 470.432509 566.927685 460.630263 566.927685 C 450.868689 566.927685 442.896737 558.974133 442.896737 549.20894 C 442.896737 539.443746 450.828016 531.490195 460.630263 531.490195 C 470.432509 531.490195 478.363788 539.443746 478.363788 549.20894 Z M 478.363788 549.20894 "
      transform="matrix(0.0960398,0,0,0.0884039,228.120517,165.178253)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:1.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 478.363788 549.19673 C 478.363788 559.00611 470.432509 566.915475 460.630263 566.915475 C 450.868689 566.915475 442.896737 559.00611 442.896737 549.19673 C 442.896737 539.431537 450.828016 531.477986 460.630263 531.477986 C 470.432509 531.477986 478.363788 539.431537 478.363788 549.19673 Z M 478.363788 549.19673 "
      transform="matrix(0.0960398,0,0,0.0884039,228.120517,161.276989)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 275.972656 251.355469 C 275.972656 252.058594 274.425781 252.628906 272.515625 252.628906 C 270.605469 252.628906 269.058594 252.058594 269.058594 251.355469 C 269.058594 250.652344 270.605469 250.082031 272.515625 250.082031 C 274.425781 250.082031 275.972656 250.652344 275.972656 251.355469 Z M 275.972656 251.355469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 307.3125 254.460938 C 307.121094 254.945312 305.4375 254.984375 303.550781 254.550781 C 301.664062 254.121094 300.289062 253.378906 300.480469 252.894531 C 300.671875 252.414062 302.355469 252.375 304.242188 252.808594 C 306.128906 253.238281 307.503906 253.980469 307.3125 254.460938 Z M 307.3125 254.460938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 294.214844 252.351562 C 294.136719 252.972656 292.5 253.363281 290.554688 253.226562 C 288.605469 253.089844 287.089844 252.480469 287.167969 251.859375 C 287.242188 251.238281 288.878906 250.84375 290.828125 250.980469 C 292.773438 251.117188 294.289062 251.730469 294.214844 252.351562 Z M 294.214844 252.351562 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 236.648438 254.460938 C 236.839844 254.945312 238.523438 254.984375 240.410156 254.550781 C 242.296875 254.121094 243.671875 253.378906 243.480469 252.894531 C 243.289062 252.414062 241.605469 252.375 239.71875 252.808594 C 237.832031 253.238281 236.457031 253.980469 236.648438 254.460938 Z M 236.648438 254.460938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 250.402344 252.050781 C 250.476562 252.671875 252.117188 253.0625 254.0625 252.925781 C 256.007812 252.792969 257.527344 252.179688 257.449219 251.558594 C 257.375 250.9375 255.734375 250.546875 253.789062 250.679688 C 251.84375 250.816406 250.328125 251.429688 250.402344 252.050781 Z M 250.402344 252.050781 "
    />
  </g>
</svg>
