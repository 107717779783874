<script>
  import styles from "styleguide/RespondCounterparties.json";
  import { t } from "stores/i18n.js";
  import { sender, recipient, auditor } from "stores/responder.js";
</script>

<div data-component="RespondCounterparties" class={styles.counterparties}>
  <div class={styles.item}>
    <div class={styles.label}>{$t("responder_counterparties.sender")}</div>
    <div class={styles.label}>{$t("responder_counterparties.recipient")}</div>
    <div class={styles.label}>{$t("responder_counterparties.auditor")}</div>
  </div>
  <div class={styles.item}>
    <div class={styles.name}>{$sender.name}</div>
    <div class={styles.name}>{$recipient.name}</div>
    <div class={styles.name}>{$auditor.name}</div>
  </div>
</div>

<style lang="scss">
  .counterparties {
    display: flex;
    padding: 25px 0;
  }

  .item {
    padding-right: 25px;
  }

  .label {
    line-height: 22px;
    color: var(--primary-300);
  }

  .name {
    font-weight: 600;
    line-height: 22px;
    color: var(--primary-500);
  }
</style>
