<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(86.27451%,0%,0%);fill-opacity:1;"
      d="M -30.511719 239.996094 L 510.515625 239.996094 L 510.515625 360 L -30.511719 360 Z M -30.511719 239.996094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,79.607843%,0%);fill-opacity:1;"
      d="M -30.511719 0 L 510.515625 0 L 510.515625 120.003906 L -30.511719 120.003906 Z M -30.511719 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,72.941176%);fill-opacity:1;"
      d="M -30.511719 120.003906 L 510.515625 120.003906 L 510.515625 240.003906 L -30.511719 240.003906 Z M -30.511719 120.003906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 249.035156 160.820312 L 240.320312 154.59375 L 231.648438 160.878906 L 234.871094 150.660156 L 226.214844 144.351562 L 236.925781 144.261719 L 240.246094 134.074219 L 243.640625 144.238281 L 254.351562 144.253906 L 245.738281 150.621094 Z M 249.035156 160.820312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 292.414062 166.148438 L 283.695312 159.921875 L 275.023438 166.210938 L 278.25 155.992188 L 269.589844 149.679688 L 280.300781 149.59375 L 283.625 139.40625 L 287.015625 149.570312 L 297.730469 149.582031 L 289.117188 155.953125 Z M 292.414062 166.148438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 330.007812 189.613281 L 321.292969 183.386719 L 312.617188 189.671875 L 315.84375 179.453125 L 307.1875 173.144531 L 317.898438 173.054688 L 321.21875 162.867188 L 324.613281 173.03125 L 335.324219 173.046875 L 326.710938 179.414062 Z M 330.007812 189.613281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 351.214844 224.804688 L 342.5 218.578125 L 333.824219 224.863281 L 337.050781 214.644531 L 328.390625 208.335938 L 339.105469 208.246094 L 342.425781 198.058594 L 345.820312 208.222656 L 356.53125 208.238281 L 347.917969 214.605469 Z M 351.214844 224.804688 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 188.152344 166.148438 L 196.871094 159.921875 L 205.542969 166.210938 L 202.316406 155.992188 L 210.976562 149.679688 L 200.265625 149.59375 L 196.941406 139.40625 L 193.546875 149.570312 L 182.835938 149.582031 L 191.449219 155.953125 Z M 188.152344 166.148438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 150.5625 189.613281 L 159.28125 183.386719 L 167.953125 189.671875 L 164.730469 179.453125 L 173.386719 173.144531 L 162.675781 173.054688 L 159.351562 162.867188 L 155.960938 173.03125 L 145.25 173.046875 L 153.863281 179.414062 Z M 150.5625 189.613281 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 129.355469 224.804688 L 138.074219 218.578125 L 146.746094 224.863281 L 143.523438 214.644531 L 152.179688 208.335938 L 141.46875 208.246094 L 138.144531 198.058594 L 134.753906 208.222656 L 124.042969 208.238281 L 132.65625 214.605469 Z M 129.355469 224.804688 "
    />
  </g>
</svg>
