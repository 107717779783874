<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(87.058824%,12.941176%,6.27451%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(3.137255%,22.352941%,61.176471%);fill-opacity:1;"
      d="M 0 0 L 271.1875 0 L 271.1875 180 L 0 180 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 198.351562 125.546875 L 165.070312 118.703125 L 172.160156 151.933594 L 146.753906 129.371094 L 136.273438 161.691406 L 125.554688 129.441406 L 100.324219 152.199219 L 107.167969 118.921875 L 73.9375 126.003906 L 96.5 100.605469 L 64.175781 90.125 L 96.417969 79.40625 L 73.667969 54.171875 L 106.949219 61.011719 L 99.867188 27.78125 L 125.265625 50.347656 L 135.746094 18.023438 L 146.464844 50.269531 L 171.695312 27.515625 L 164.851562 60.796875 L 198.082031 53.710938 L 175.519531 79.117188 L 207.84375 89.589844 L 175.597656 100.308594 Z M 198.351562 125.546875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,32.54902%,52.941176%);fill-opacity:1;"
      d="M 167.675781 122.992188 L 157.171875 128.570312 L 146.929688 134.625 L 135.03125 134.214844 L 123.136719 134.351562 L 113.039062 128.066406 L 102.664062 122.246094 L 97.070312 111.765625 L 91 101.558594 L 91.40625 89.691406 L 91.273438 77.820312 L 97.578125 67.753906 L 103.410156 57.40625 L 113.914062 51.828125 L 124.15625 45.777344 L 136.054688 46.183594 L 147.957031 46.046875 L 158.054688 52.332031 L 168.421875 58.152344 L 174.015625 68.628906 L 180.085938 78.839844 L 179.679688 90.703125 L 179.8125 102.574219 L 173.507812 112.644531 Z M 167.675781 122.992188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 172.011719 90.199219 C 172.011719 110.339844 155.683594 126.664062 135.542969 126.664062 C 115.402344 126.664062 99.074219 110.339844 99.074219 90.199219 C 99.074219 70.054688 115.402344 53.730469 135.542969 53.730469 C 155.683594 53.730469 172.011719 70.054688 172.011719 90.199219 Z M 172.011719 90.199219 "
    />
  </g>
</svg>
