<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 0 0 L 480 0 L 480 182.679688 L 0 182.679688 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 0 177.320312 L 480 177.320312 L 480 360 L 0 360 Z M 0 177.320312 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,92.54902%,0%);fill-opacity:1;"
      d="M 171.503906 111.128906 C 295.417969 143.609375 215.746094 302.859375 118.089844 236.574219 C 115.886719 239.960938 113.683594 243.347656 111.476562 246.734375 C 168.949219 287.726562 225.90625 254.726562 241.902344 211.964844 C 258.625 167.875 236.261719 105.855469 172.519531 99.441406 C 172.179688 103.335938 171.839844 107.230469 171.503906 111.125 Z M 171.503906 111.128906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,92.54902%,0%);fill-opacity:1;"
      d="M 127.464844 248.121094 L 143.761719 255.714844 L 136.15625 271.984375 L 119.859375 264.390625 Z M 127.464844 248.121094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,92.54902%,0%);fill-opacity:1;"
      d="M 239.203125 173.511719 L 257.1875 173.511719 L 257.1875 191.464844 L 239.203125 191.464844 Z M 239.203125 173.511719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,92.54902%,0%);fill-opacity:1;"
      d="M 230.441406 144.339844 L 247.199219 137.820312 L 253.730469 154.550781 L 236.972656 161.074219 Z M 230.441406 144.339844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,92.54902%,0%);fill-opacity:1;"
      d="M 211.023438 122.449219 L 223.796875 109.8125 L 236.457031 122.570312 L 223.679688 135.207031 Z M 211.023438 122.449219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,92.54902%,0%);fill-opacity:1;"
      d="M 191.308594 92.609375 L 208.132812 98.953125 L 201.78125 115.75 L 184.957031 109.410156 Z M 191.308594 92.609375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,92.54902%,0%);fill-opacity:1;"
      d="M 237.074219 202.507812 L 253.773438 209.171875 L 247.097656 225.847656 L 230.398438 219.179688 Z M 237.074219 202.507812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,92.54902%,0%);fill-opacity:1;"
      d="M 184.667969 255.046875 L 201.210938 248.003906 L 208.265625 264.523438 L 191.722656 271.5625 Z M 184.667969 255.046875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,92.54902%,0%);fill-opacity:1;"
      d="M 155.039062 257.121094 L 173.023438 257.121094 L 173.023438 275.078125 L 155.039062 275.078125 Z M 155.039062 257.121094 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,92.54902%,0%);fill-opacity:1;"
      d="M 186.007812 170.167969 L 170.746094 158.914062 L 155.5625 169.765625 L 161.585938 152.578125 L 146.328125 141.359375 L 164.660156 141.230469 L 171.085938 124.320312 L 176.925781 141.347656 L 195.449219 141.164062 L 180.761719 152.589844 Z M 186.007812 170.167969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,93.333333%,0%);fill-opacity:1;"
      d="M 252.023438 259.78125 C 251.113281 260.09375 247.351562 269.074219 244.765625 273.46875 C 246.113281 273.851562 246.683594 273.914062 247.484375 274.238281 C 257.726562 277.835938 262.773438 281.121094 267.125 287.367188 C 269.289062 289.457031 272.398438 289.5625 274.773438 287.886719 C 274.773438 287.886719 276.871094 287.050781 279.59375 284.121094 C 281.820312 280.730469 281.242188 278.039062 278.558594 275.75 C 270.347656 269.789062 261.382812 265.324219 252.023438 259.78125 Z M 252.023438 259.78125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 273.933594 279.632812 C 273.933594 281.421875 272.480469 282.875 270.6875 282.875 C 268.894531 282.875 267.4375 281.421875 267.4375 279.632812 C 267.4375 277.839844 268.894531 276.386719 270.6875 276.386719 C 272.480469 276.386719 273.933594 277.839844 273.933594 279.632812 Z M 273.933594 279.632812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 257.902344 269.378906 C 257.902344 271.167969 256.449219 272.621094 254.652344 272.621094 C 252.859375 272.621094 251.40625 271.167969 251.40625 269.378906 C 251.40625 267.585938 252.859375 266.132812 254.652344 266.132812 C 256.449219 266.132812 257.902344 267.585938 257.902344 269.378906 Z M 257.902344 269.378906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 266.074219 274.609375 C 266.074219 276.398438 264.621094 277.851562 262.828125 277.851562 C 261.035156 277.851562 259.578125 276.398438 259.578125 274.609375 C 259.578125 272.816406 261.035156 271.367188 262.828125 271.367188 C 264.621094 271.367188 266.074219 272.816406 266.074219 274.609375 Z M 266.074219 274.609375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,93.333333%,0%);fill-opacity:1;"
      d="M 243.351562 272.75 C 211.425781 254.546875 177.871094 234.859375 145.941406 216.652344 C 131.878906 207.925781 131.179688 191.613281 140.566406 179.246094 C 141.542969 177.492188 142.691406 177.925781 143.199219 178.851562 C 144.296875 184.875 147.675781 191.097656 151.726562 194.976562 C 185.246094 216.261719 217.527344 236.828125 250.511719 259.183594 C 247.925781 263.511719 245.933594 268.425781 243.347656 272.75 Z M 243.351562 272.75 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,92.54902%,0%);fill-opacity:1;"
      d="M 222.878906 229.09375 L 236.269531 241.082031 L 224.261719 254.449219 L 210.871094 242.460938 Z M 222.878906 229.09375 "
    />
    <path
      style="fill:none;stroke-width:2.85975;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 444.701667 64.900217 L 319.738125 -10.625825 "
      transform="matrix(0.75,0,0,0.75,-84.87,212.942025)"
    />
    <path
      style="fill:none;stroke-width:2.85975;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 429.269375 47.467925 L 387.029792 20.191883 "
      transform="matrix(0.75,0,0,0.75,-84.87,212.942025)"
    />
    <path
      style="fill:none;stroke-width:2.85975;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 405.238125 62.879383 L 362.488125 38.124175 "
      transform="matrix(0.75,0,0,0.75,-84.87,212.942025)"
    />
  </g>
</svg>
