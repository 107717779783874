import Api from "apis/api";

class Invoice {
  constructor() {
    this.endpoint = "/d";
  }

  redirectToNoteTable(customerNumber) {
    const splittedPath = window.location.pathname.split("/").filter((n) => n),
      accountToken = splittedPath[splittedPath.length - 1];
    Api.redirect(`${this.endpoint}/${accountToken}/${customerNumber}`);
  }
}

export default new Invoice();
