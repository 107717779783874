<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(83.921569%,8.235294%,9.019608%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0.392157%,55.686275%,87.45098%);fill-opacity:1;"
      d="M 159.996094 0 L 319.996094 0 L 319.996094 360 L 159.996094 360 Z M 159.996094 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:0.866667;"
      d="M 76.90625 183.148438 C 84.339844 183.363281 85.484375 180.785156 85.484375 178.207031 L 85.484375 169.394531 C 85.484375 168.320312 88.34375 167.246094 88.34375 165.3125 C 88.34375 163.378906 86.914062 161.230469 86.339844 159.941406 C 85.484375 163.164062 84.054688 166.386719 81.480469 167.460938 C 80.335938 164.882812 80.050781 164.023438 80.621094 162.734375 C 81.195312 161.445312 80.621094 159.511719 79.476562 157.574219 C 78.621094 161.660156 76.046875 162.734375 75.761719 164.023438 C 75.476562 165.3125 74.617188 168.964844 72.902344 167.03125 C 71.1875 165.097656 70.042969 162.734375 69.183594 160.15625 C 67.46875 165.3125 68.328125 166.386719 68.898438 167.246094 C 69.46875 168.105469 70.042969 170.683594 70.042969 171.757812 C 70.042969 172.835938 68.898438 177.132812 69.46875 178.421875 C 70.042969 179.710938 70.328125 183.578125 76.90625 183.148438 Z M 76.90625 183.148438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:0.866667;"
      d="M 97.59375 201.757812 C 97.59375 209.539062 89.199219 215.847656 78.84375 215.847656 C 68.488281 215.847656 60.097656 209.539062 60.097656 201.757812 C 60.097656 193.976562 68.488281 187.664062 78.84375 187.664062 C 89.199219 187.664062 97.59375 193.976562 97.59375 201.757812 Z M 97.59375 201.757812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:0.866667;"
      d="M 105 207.324219 C 95.101562 221.152344 76.039062 224.992188 61.902344 215.722656 C 58.175781 213.28125 55.453125 210.546875 52.859375 207.042969 C 55.199219 216.984375 62.285156 224.019531 72.089844 226.535156 C 86.523438 230.230469 101.265625 221.625 105 207.324219 Z M 105 207.324219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:0.866667;"
      d="M 50.15625 316.28125 L 105.941406 316.28125 L 105.941406 327.886719 L 50.15625 327.886719 Z M 50.15625 316.28125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:0.866667;"
      d="M 52.882812 234.261719 L 77.761719 249.359375 L 102.925781 234.261719 Z M 52.882812 234.261719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:0.866667;"
      d="M 52.882812 250.605469 L 103.210938 250.605469 L 103.210938 262.210938 L 52.882812 262.210938 Z M 52.882812 250.605469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:0.866667;"
      d="M 52.882812 335.316406 L 77.761719 347.136719 L 102.925781 335.316406 Z M 52.882812 335.316406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:0.866667;"
      d="M 15.238281 234.433594 L 39.519531 234.433594 L 39.519531 346.640625 L 15.238281 346.640625 Z M 15.238281 234.433594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:0.866667;"
      d="M 115.132812 234.433594 L 139.414062 234.433594 L 139.414062 346.640625 L 115.132812 346.640625 Z M 115.132812 234.433594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,84.313725%,0%);fill-opacity:0.866667;"
      d="M 60.863281 273.847656 C 68.289062 268.351562 79.382812 264.234375 83.371094 268.691406 C 87.378906 273.296875 88.332031 277.414062 87.453125 281.117188 C 86.269531 284.332031 80.9375 288.167969 76.996094 286.132812 C 72.472656 283.996094 65.25 291.398438 64.421875 295.488281 C 63.339844 299.847656 63.234375 305.03125 64.476562 309.84375 C 52.621094 296.015625 51.8125 280.019531 60.867188 273.847656 Z M 81.027344 280.679688 C 78.984375 281.882812 76.007812 280.660156 74.386719 277.953125 C 72.765625 275.242188 74.011719 274.234375 76.054688 273.03125 C 78.101562 271.828125 80.175781 270.886719 81.796875 273.59375 C 83.417969 276.304688 83.074219 279.476562 81.027344 280.679688 Z M 92.484375 305.238281 C 85.152344 310.871094 73.074219 313.042969 69.046875 308.636719 C 64.992188 304.078125 68.347656 298.320312 69.179688 294.59375 C 70.320312 291.347656 75.601562 288.507812 79.550781 290.492188 C 84.085938 292.5625 91.367188 284.4375 90.507812 279.230469 C 91.535156 274.839844 90.125 274.089844 87.988281 267.648438 C 99.972656 281.332031 101.429688 298.902344 92.484375 305.238281 Z M 76.144531 296.570312 C 78.167969 295.332031 80.605469 295.964844 82.25 298.65625 C 83.898438 301.351562 83.789062 303.464844 81.765625 304.707031 C 79.742188 305.945312 76.570312 305.839844 74.921875 303.148438 C 73.273438 300.453125 74.121094 297.808594 76.144531 296.570312 Z M 76.144531 296.570312 "
    />
  </g>
</svg>
