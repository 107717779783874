<script>
  import { onMount, createEventDispatcher } from "svelte";
  import WebViewer from "@pdftron/webviewer";
  import styles from "styleguide/WebViewer.json";
  import { user } from "stores/user.js";
  import { licenseKeys } from "stores/license_keys.js";
  import documentViewerStore from "stores/document_viewer_store.js";

  import { currentLanguage } from "stores/i18n.js";

  let viewer;
  const dispatch = createEventDispatcher();

  const isAnnotationsEnabled = documentViewerStore.isAnnotationsEnabledReadable;

  const disabledElements = [
    "thumbnailControl",
    "toggleCompareModeButton",
    "noteState",
    "toolbarGroup-Insert",
    "toolbarGroup-Shapes",
    "toolbarGroup-Edit",
    "toolbarGroup-FillAndSign",
    "toolbarGroup-Forms",
    "addReplyAttachmentButton",
    "downloadButton",
  ];

  const rotationButtonConfig = [
    {
      dataElement: "rotateClockwiseButton",
      title: "action.rotateClockwise",
      img: "clockwise-line",
      rotationFunction: "rotateClockwise",
    },
    {
      dataElement: "rotateCounterClockwiseButton",
      title: "action.rotateCounterClockwise",
      img: "counterclockwise-line",
      rotationFunction: "rotateCounterClockwise",
    },
  ];

  onMount(async () => {
    WebViewer(
      {
        path: "/webviewer",
        annotationUser: $user.name,
        enableAnnotations: true,
        disableMultiViewerComparison: true,
        disabledElements,
        licenseKey: $licenseKeys.pdftron,
        enableReadOnlyMode: !$isAnnotationsEnabled,
      },
      viewer,
    )
      .then((instance) => {
        const contentElement =
          instance.UI.iframeWindow.document.documentElement.getElementsByClassName(
            "content",
          );
        const childElements = contentElement?.[0]?.children;
        for (const child of childElements) {
          child.style.transition = "0ms";
        }
        instance.UI.setLanguage($currentLanguage);
        insertRotationButtons(instance, rotationButtonConfig);

        dispatch("ready", {
          instance,
        });

        dispatch("changed", {
          instance,
        });
      })
      .catch((error) => {
        dispatch("error", error);
      });
  });

  const insertRotationButtons = (instance, buttonConfigs) => {
    instance.UI.setHeaderItems(async function (header) {
      const viewControlsButton = header
        .getHeader("default")
        .get("viewControlsButton");
      const baseImgPath = "icon-header-page-manipulation-page-rotation";

      buttonConfigs.forEach(({ dataElement, title, img, rotationFunction }) => {
        viewControlsButton.insertAfter({
          type: "actionButton",
          element: "pageManipulationOverlay",
          dataElement,
          title,
          img: `${baseImgPath}-${img}`,
          onClick: async () => {
            const pdfViewer = await instance.Core.documentViewer;
            pdfViewer[rotationFunction]();
          },
        });
      });
    });
  };
</script>

<div class={styles.viewer} bind:this={viewer} />

<style>
  .viewer {
    height: 100%;
  }
  .Panel {
    transition: 0ms;
  }
</style>
