import { t } from "stores/i18n.js";
import { get } from "svelte/store";

export function getSampleMailingHeaderStatus(document) {
  if (document.waiting_confirmation) {
    return {
      message: get(t)("sample_mailing.header_status.waiting_confirmation"),
      color: "green",
    };
  }

  if (document.mailing_url === null) {
    if (document.ready_to_write) {
      return {
        message: get(t)("sample_mailing.header_status.is_created"),
        color: "",
      };
    }

    return {
      message: get(t)("sample_mailing.header_status.waiting_for_data"),
      color: "orange",
    };
  }

  return {
    message: get(t)("sample_mailing.header_status.will_send"),
    color: "",
  };
}
