import { get } from "svelte/store";
import { t } from "stores/i18n.js";

const _validators = {
  email: (value) => {
    return (
      !value ||
      /(^(([a-zA-Z0-9_\-\.'’]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z0-9]{2,100})(\]?)(\s*(;|,)\s*|\s*$))*$)/.test(
        value,
      ) ||
      get(t)("validation.email")
    );
  },
  required: (value) => {
    return (
      (value !== undefined && value !== null && value !== "") ||
      get(t)("validation.required")
    );
  },
  numeric: (value) => {
    return /^\d*\.?\d*$/.test(value) || get(t)("validation.numeric");
  },
  length: (value, options) => {
    if (!value) return true;
    return (
      ((options.min ? options.min <= value.length : true) &&
        (options.max ? value.length <= options.max : true)) ||
      get(t)("validation.length")
    );
  },
  zip: (value, options) => {
    if (!value || !options.country) return true;
    let countryCode = options.country.toLowerCase().substring(0, 2);
    let valid =
      countryCode === "de" || countryCode === "ge"
        ? /^(\d{5})$/.test(value)
        : true;
    return !value || valid || get(t)("validation.zip");
  },
};

function validate(value, validators, options) {
  const errors = validators.map((validator) =>
    _validators[validator](value, options),
  );
  const result = errors.filter((error) => error && error !== true);
  return result;
}

export { validate };
