<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(0%,80%,0%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(40%,60%,100%);fill-opacity:1;"
      d="M 0 0 L 540 0 L 540 180 L 0 180 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,99.607843%,99.607843%);fill-opacity:1;"
      d="M 0 0 L 269.105469 179.769531 L 0 359.304688 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 112.359375 205.363281 L 90.6875 189.765625 L 69.125 205.515625 L 77.144531 179.914062 L 55.621094 164.109375 L 82.25 163.890625 L 90.507812 138.367188 L 98.945312 163.832031 L 125.578125 163.867188 L 104.164062 179.824219 L 112.359375 205.367188 Z M 112.359375 205.363281 "
    />
  </g>
</svg>
