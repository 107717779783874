import Api from "apis/api";
import { buildFormData } from "lib/helpers.js";
import { t } from "stores/i18n.js";
import { get } from "svelte/store";
import { toasts } from "stores/toasts.js";
import { account, accountTheme } from "stores/account.js";

import { accountPasswordRules, emailWhiteList } from "stores/account.js";

class AccountApi {
  constructor() {
    this.endpoint = "/accounts";
  }

  getLogs(options) {
    const { params, success, error } = options;

    Api.get(`${this.endpoint}/logs`, {
      params,
      success: (response) => {
        success(response.data);
      },
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  getAdmins(options) {
    const { params, success, error } = options;

    Api.get(`${this.endpoint}/admins`, {
      params,
      success: (response) => {
        success(response.data);
      },
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  getTheme() {
    Api.get(`${this.endpoint}/theme`, {
      success: (response) => {
        let data = response.data;
        accountTheme.set(data.theme);
        let acc = get(account);
        acc.default_logo = data.default_logo;
        if (data.logo) {
          acc.logo = data.logo;
        }
        if (data.favicon) {
          acc.favicon = data.favicon;
        }
        account.set(acc);
      },
      error: this.error,
    });
  }

  getDefaultTheme() {
    Api.get(`${this.endpoint}/default_theme`, {
      success: (response) => {
        accountTheme.set(response.data);
        this.updateTheme(response.data);
      },
      error: this.error,
    });
  }

  resetTheme() {
    Api.delete(`${this.endpoint}/theme`, {
      success: () => {
        window.location.reload();
      },
      error: this.error,
    });
  }

  updateTheme(params) {
    const formData = new FormData();
    buildFormData(formData, params);

    Api.patch(`${this.endpoint}/theme`, {
      body: formData,
      success: (response) => {
        accountTheme.set(response.data);
        toasts.send({
          title: get(t)("success.update_successful"),
          type: "success",
        });
      },
      error: this.error,
    });
  }

  previewTheme(theme) {
    const formData = new FormData();
    buildFormData(formData, theme);
    Api.post(`${this.endpoint}/preview_theme`, {
      body: formData,
      success: (response) => {
        document.getElementById("account-style").innerHTML = response.data;
      },
      error: this.error,
    });
  }

  generateApiKey(options) {
    const { params, success, error } = options;

    Api.post(`${this.endpoint}/api_key`, {
      params,
      success: (response) => {
        success(response.data);
      },
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  hasApiKey(options) {
    const { params, success, error } = options;

    Api.get(`${this.endpoint}/api_key`, {
      params,
      success: (response) => {
        success(response.data);
      },
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  deleteApiKey(options) {
    const { params, success, error } = options;

    Api.delete(`${this.endpoint}/api_key`, {
      params,
      success: (response) => {
        success(response.data);
      },
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  updateProjectsSettings(accesses) {
    const formData = new FormData();
    buildFormData(formData, accesses);

    Api.patch(`${this.endpoint}/project_settings`, {
      body: formData,
      success: (response) => {
        toasts.send({
          title: get(t)("success.update_successful"),
          type: "success",
        });
      },
      error: this.error,
    });
  }

  getCustomPasswordRules() {
    Api.get(`${this.endpoint}/custom_password_rule`, {
      success: (response) => {
        accountPasswordRules.set(response.data);
      },
      error: this.error,
    });
  }

  editCustomPasswordRules() {
    Api.get(`${this.endpoint}/custom_password_rule/edit`, {
      success: (response) => {
        accountPasswordRules.set(response.data);
      },
      error: this.error,
    });
  }

  updateCustomPasswordRules(options) {
    const formData = new FormData();
    buildFormData(formData, options);

    Api.put(`${this.endpoint}/custom_password_rule`, {
      body: formData,
      success: (response) => {
        toasts.send({
          title: get(t)("success.update_successful"),
          type: "success",
        });
        accountPasswordRules.set(response.data);
      },
      error: this.error,
    });
  }

  getCustomEmailWhiteList() {
    Api.get(`${this.endpoint}/email_whitelist`, {
      success: (response) => {
        emailWhiteList.set(response.data.email_whitelist.join(", "));
      },
      error: this.error,
    });
  }

  updateEmailWhiteList() {
    var domains = get(emailWhiteList)
      .split(",")
      .map((i) => i.trim());

    const formData = new FormData();
    buildFormData(formData, domains, "domains", true);

    Api.patch(`${this.endpoint}/email_whitelist`, {
      body: formData,
      success: (response) => {
        emailWhiteList.set(response.data.email_whitelist.join(", "));
        toasts.send({
          title: get(t)("success.update_successful"),
          type: "success",
        });
        this.error;
      },
    });
  }

  error() {
    toasts.send({ title: get(t)("errors.unknown"), type: "error" });
  }
}

export default new AccountApi();
