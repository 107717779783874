import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "image", "filename", "buttons"];

  connect() {
    this.zoom = 1.0;
  }

  close(event) {
    event.preventDefault();
    this.element.classList.add("hidden");
  }

  zoomIn(event) {
    event.preventDefault();
    this.zoom += 0.25;
    if (this.zoom > 2) {
      this.zoom = 2;
    }
    this.setZoomValue();
  }

  zoomOut(event) {
    event.preventDefault();
    this.zoom -= 0.25;
    if (this.zoom < 0.75) {
      this.zoom = 0.75;
    }
    this.setZoomValue();
  }

  setZoomValue() {
    this.containerTarget.setAttribute("data-zoom", this.zoom);
    document.querySelector(".zoom-box .zoom-value").textContent =
      (this.zoom * 100).toString() + "%";
  }

  rotate(event) {
    event.preventDefault();
    let previews = document.getElementsByClassName(
      "attachment-preview-modal--image",
    );
    let currentRotation = Number(previews[0].style.transform.match(/\d+/));
    let newRotation = currentRotation + 90;
    if (typeof this.horizontal == "undefined") {
      this.horizontal =
        previews[0].getBoundingClientRect().height <
        previews[0].getBoundingClientRect().width;
    }
    let rotationClassName = this.horizontal
      ? "rotated-horizontal"
      : "rotated-vertical";
    previews.forEach(function (el) {
      el.style.transform = "rotate(" + newRotation + "deg)";
      el.style.transformOrigin = "center";
    });

    if ((newRotation / 90) % 2) {
      previews[0]
        .closest(".attachment-preview-modal--container")
        .classList.add(rotationClassName);
    } else {
      previews[0]
        .closest(".attachment-preview-modal--container")
        .classList.remove(rotationClassName);
    }
  }
}
