import Api from "apis/api";

class TeamUsers {
  constructor() {
    this.endpoint = "/team_users";
  }

  search(options) {
    Api.get(`${this.endpoint}/search`, {
      params: options.params,
      success: options.success,
      error: options.error,
    });
  }
}

export default new TeamUsers();
