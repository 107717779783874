<script>
  import { user } from "stores/user.js";
  import { t } from "stores/i18n.js";
  import styles from "styleguide/SidebarNotifications.json";

  import ProjectUnits from "apis/dashboard/project_units.js";

  import { Box, IconButton } from "components";

  export let store;
  export let unitType;

  $: notificationStatus = store.notifications_activated ? "on" : "off";
  $: notificationToggled = $user.notificationsToggle ? "" : "-muted";

  function toggle() {
    let value = !store.notifications_activated;

    ProjectUnits.settings({
      params: {
        project_unit_id: store.permalink,
        project_unit_type: unitType,
        receive_notification: value,
      },
      success: () => {
        store.notifications_activated = value;
      },
    });
  }
</script>

<Box style="padding margin-bottom">
  <div class={styles.wrapper} data-component="SidebarNotifications">
    <IconButton
      click={toggle}
      style="primary-text small"
      icon={`notification-${notificationStatus}${notificationToggled}`}
    />
    <div class={styles.text}>
      {$t(`sidebar_notifications.receive_${notificationStatus}`)}
    </div>
  </div>
</Box>

<style lang="scss">
  .wrapper {
    display: flex;
    align-items: center;
  }

  .text {
    margin-left: 15px;
    color: var(--primary-300);
  }
</style>
