<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(0%,0%,40%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M -120 0 L -120 40.25 L 519.515625 360.007812 L 600.015625 360.007812 L 600.015625 319.757812 L -39.503906 0 Z M 600.015625 0 L 600.015625 40.25 L -39.503906 360.007812 L -120 360.007812 L -120 319.757812 L 519.515625 0 Z M 600.015625 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 180.007812 0 L 180.007812 360.007812 L 300.007812 360.007812 L 300.007812 0 Z M -120 120.003906 L -120 240.003906 L 600.015625 240.003906 L 600.015625 120.003906 Z M -120 120.003906 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M -120 144.003906 L -120 216.003906 L 600.015625 216.003906 L 600.015625 144.003906 Z M 204.007812 0 L 204.007812 360.007812 L 276.007812 360.007812 L 276.007812 0 Z M 204.007812 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M -120 360.007812 L 120.003906 240.003906 L 173.667969 240.003906 L -66.335938 360.007812 Z M -120 0 L 120.003906 120.003906 L 66.339844 120.003906 L -120 26.832031 Z M 306.34375 120.003906 L 546.347656 0 L 600.015625 0 L 360.007812 120.003906 Z M 600.015625 360.007812 L 360.007812 240.003906 L 413.675781 240.003906 L 600.015625 333.175781 Z M 600.015625 360.007812 "
    />
  </g>
</svg>
