<script>
  import styles from "styleguide/QuickshareButton.json";

  import { LegacyButton as Button } from "components";

  import { showWidget } from "stores/quickshare.js";
  import { isAccountFeature } from "stores/account";
  import { user } from "stores/user";

  function showQuickshare() {
    showWidget.set(true);
  }
</script>

{#if $isAccountFeature("quickshare") && $user.role === "team"}
  <div class={styles.quickshare}>
    <Button
      click={showQuickshare}
      style="breadcrumb-button quickshare-button"
      icon="import.svg">Quick Share</Button
    >
  </div>
{/if}

<style lang="scss">
  .quickshare {
    padding-right: 12px;
  }

  .new {
    position: absolute;
    top: 45px;
    left: 50%;
    width: 50px;
    margin-left: -25px;
    display: inline-block;
    font-family: var(--default-font);
    background: var(--green);
    color: #fff;
    font-size: 11px;
    font-weight: 800;
    text-align: center;
    padding: 3px 0;
    border-radius: 3px;

    &:after {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: transparent;
      border-bottom-color: var(--green-010);
      border-width: 5px;
      margin-left: -5px;
    }
  }
</style>
