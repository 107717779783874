<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,60.392157%,95.686275%);fill-opacity:1;"
      d="M -60 0 L 480 0 L 480 180 L -60 180 Z M -60 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(10.980392%,56.078431%,19.215686%);fill-opacity:1;"
      d="M -60 270 L 480 270 L 480 360 L -60 360 Z M -60 270 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(93.72549%,93.72549%,0%);fill-opacity:1;"
      d="M -60 180 L 480 180 L 480 270 L -60 270 Z M -60 180 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,85.490196%,0%);fill-opacity:1;"
      d="M 430.300781 125.292969 L 404.164062 110.878906 L 419.699219 136.359375 L 398.191406 115.667969 L 406.601562 144.3125 L 391.171875 118.761719 L 391.890625 148.59375 L 383.601562 119.921875 L 376.570312 148.925781 L 375.992188 119.085938 L 361.6875 145.285156 L 368.851562 116.308594 L 348.253906 137.917969 L 362.667969 111.78125 L 337.1875 127.316406 L 357.871094 105.808594 L 329.234375 114.21875 L 354.785156 98.789062 L 324.953125 99.507812 L 353.625 91.21875 L 324.617188 84.1875 L 354.460938 83.601562 L 328.261719 69.304688 L 357.230469 76.46875 L 335.628906 55.871094 L 361.765625 70.285156 L 346.230469 44.800781 L 367.738281 65.488281 L 359.328125 36.851562 L 374.757812 62.40625 L 374.039062 32.566406 L 382.324219 61.238281 L 389.359375 32.234375 L 389.9375 62.074219 L 404.242188 35.875 L 397.078125 64.851562 L 417.675781 43.246094 L 403.261719 69.382812 L 428.742188 53.84375 L 408.058594 75.355469 L 436.695312 66.945312 L 411.144531 82.375 L 440.976562 81.65625 L 412.304688 89.941406 L 441.3125 96.976562 L 411.46875 97.554688 L 437.667969 111.859375 L 408.699219 104.695312 Z M 430.300781 125.292969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,59.607843%,96.470588%);fill-opacity:1;"
      d="M 405.734375 90.582031 C 405.734375 103.152344 395.542969 113.34375 382.96875 113.34375 C 370.398438 113.34375 360.207031 103.152344 360.207031 90.582031 C 360.207031 78.011719 370.398438 67.820312 382.96875 67.820312 C 395.542969 67.820312 405.734375 78.011719 405.734375 90.582031 Z M 405.734375 90.582031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,85.098039%,0%);fill-opacity:1;"
      d="M 401.28125 90.582031 C 401.28125 100.839844 393.085938 109.152344 382.980469 109.152344 C 372.875 109.152344 364.679688 100.839844 364.679688 90.582031 C 364.679688 80.328125 372.875 72.015625 382.980469 72.015625 C 393.085938 72.015625 401.28125 80.328125 401.28125 90.582031 Z M 401.28125 90.582031 "
    />
  </g>
</svg>
