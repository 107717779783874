<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 0 358.5 L 480 358.5 L 480 360 L 0 360 Z M 0 358.5 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 0 119.25 L 480 119.25 L 480 120.75 L 0 120.75 Z M 0 119.25 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 0 239.25 L 480 239.25 L 480 240.75 L 0 240.75 Z M 0 239.25 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 0 0 L 1.5 0 L 1.5 360 L 0 360 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 478.5 0 L 480 0 L 480 360 L 478.5 360 Z M 478.5 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 239.25 0 L 240.75 0 L 240.75 360 L 239.25 360 Z M 239.25 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 0 0 L 480 0 L 480 1.5 L 0 1.5 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 0 179.25 L 480 179.25 L 480 180.75 L 0 180.75 Z M 0 179.25 "
    />
  </g>
</svg>
