<script>
  import styles from "styleguide/ConfirmationSignerIntro.json";

  import { theme } from "stores/theme.js";
  import { t } from "stores/i18n.js";

  import {
    ConfirmationSignerCounterparties,
    ConfirmationSignerNextStep,
  } from "apps/confirmationSigner";
</script>

<div data-component="ConfirmationSignerIntro">
  <div class={styles.counterparties}>
    <div class={styles.container}>
      <ConfirmationSignerCounterparties />
    </div>
  </div>

  <div class={styles.intro}>
    <div class={styles.container}>
      <h1 class={styles.title}>{$t("confirmation_signer_intro.title")}</h1>
      <div class={styles.content}>
        {$t("confirmation_signer_intro.content")}
      </div>

      <div class={styles.next}>
        <ConfirmationSignerNextStep />
      </div>

      {#if $theme === "aude"}
        <div class={styles.partner}>
          <img src="/assets/datev.png" class={styles.datev} alt="DATEV eG" />
          <p class={styles.cooperation}>
            {$t("confirmation_signer_intro.cooperation")}
          </p>
        </div>
      {/if}
    </div>
  </div>
</div>

<style lang="scss">
  .counterparties {
    padding: 20px 0;
    border-bottom: 1px solid var(--primary-050);
  }

  .intro {
    padding: 25px 0;
  }

  .container {
    padding: 0 15px;
  }

  .title {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.45;
    color: var(--primary-500);
    margin: 0 0 1.5rem;
  }

  .content {
    line-height: 1.57;
    color: var(--primary-500);
    margin: 0 0 1.4rem;
  }

  .partner {
    display: flex;
  }

  .datev {
    width: 42px;
    height: 42px;
  }

  .cooperation {
    font-weight: 600;
    line-height: 1.57;
    color: var(--primary-500);
    margin: 10px 16px;
  }

  .next {
    margin-bottom: 20px;
  }

  @media only screen and (min-width: 1023px) {
    .counterparties {
      padding: 24px 0;
    }

    .container {
      padding: 0 35px;
    }

    .intro {
      padding: 32px 0 42px;
    }
  }
</style>
