<script>
  import styles from "styleguide/ResponderChecking.json";
  import { onMount, createEventDispatcher } from "svelte";
  import { t } from "stores/i18n.js";
  import { ProgressBar } from "components";

  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const dispatch = createEventDispatcher();

  const progressBarDuration = randomIntFromInterval(27, 32) * 1000;

  onMount(async () => {
    setTimeout(() => {
      dispatch("complete");
    }, progressBarDuration + 500);
  });
</script>

<div data-component="ResponderChecking" class={styles.container}>
  <div class={styles.block}>
    <h2 class={styles.title}>{$t("responder_checking.title")}</h2>
    <div class={styles.progress}>
      <ProgressBar duration={progressBarDuration} />
    </div>
  </div>
</div>

<style lang="scss">
  .block {
    min-height: 205px;
  }

  .container {
    padding: 35px;
    border-bottom: solid 1px var(--primary-050);
  }

  .title {
    margin: 0 0 25px;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.45;
    color: var(--primary-500);
  }

  .progress {
    width: 600px;
  }
</style>
