<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M -0.00390625 286.761719 L -0.00390625 73.433594 L 158.78125 180.042969 L -0.00390625 286.769531 Z M -0.00390625 286.761719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,80%);fill-opacity:1;"
      d="M 108.34375 360.085938 L 286.605469 240.105469 L 539.988281 240.105469 L 539.988281 360.085938 Z M 108.34375 360.085938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 96.878906 0.0507812 L 539.972656 0.0625 L 539.972656 120.0625 L 286.59375 120.0625 C 286.59375 120.0625 99.257812 -1.132812 96.878906 0.0507812 Z M 96.878906 0.0507812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,80%,0%);fill-opacity:1;"
      d="M -0.00390625 45.058594 L -0.00390625 73.414062 L 158.78125 180.027344 L -0.00390625 286.753906 L -0.00390625 315.105469 L 199.988281 180.027344 Z M -0.00390625 45.058594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,60%,20%);fill-opacity:1;"
      d="M -0.00390625 45.058594 L -0.00390625 0.0507812 L 66.714844 0.0507812 L 278.570312 143.402344 L 539.972656 143.402344 L 539.972656 216.710938 L 278.570312 216.710938 L 66.714844 360.039062 L -0.00390625 360.039062 L -0.00390625 315.105469 L 199.988281 180.027344 Z M -0.00390625 45.058594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 66.714844 0.0507812 L 108.335938 0.0507812 L 286.601562 120.054688 L 539.980469 120.054688 L 539.980469 143.402344 L 278.578125 143.402344 L 66.722656 0.0507812 Z M 66.714844 0.0507812 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 66.714844 360.085938 L 108.335938 360.085938 L 286.601562 240.105469 L 539.980469 240.105469 L 539.980469 216.757812 L 278.578125 216.757812 L 66.722656 360.085938 Z M 66.714844 360.085938 "
    />
  </g>
</svg>
