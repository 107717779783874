class e {
  constructor(e) {
    this.state = e;
  }
}
class s {
  constructor(e) {
    (this.store = e.store),
      (this.host = e.host),
      (this.queue = []),
      (this.requesting = !1);
  }
  get(e, s) {
    let t = new URLSearchParams(s.params);
    this.queue.push({ endpoint: e + "?" + t, method: "GET", options: s }),
      this.requestNext();
  }
  post(e, s) {
    this.queue.push({ endpoint: e, method: "POST", options: s }),
      this.requestNext();
  }
  put(e, s) {
    this.queue.push({ endpoint: e, method: "PUT", options: s }),
      this.requestNext();
  }
  delete(e, s) {
    this.queue.push({ endpoint: e, method: "DELETE", options: s }),
      this.requestNext();
  }
  requestNext() {
    if (0 == this.queue.length || this.requesting) return;
    const e = this.queue.shift();
    (this.requesting = !0), this.request(e.endpoint, e.method, e.options);
  }
  request(e, s, t) {
    let o,
      r = {
        method: s,
        headers: this.headers(),
        body: JSON.stringify(t.body),
        credentials: "include",
      },
      i = {
        success: t.success || function () {},
        error: t.error || function () {},
      };
    console.log(`Requesting ${e} with ${JSON.stringify(r)}`),
      fetch(this.host + e, r)
        .then((e) => ((o = e.status), e.json()))
        .then((r) => {
          switch (o) {
            case 200:
            case 201:
              i.success(r), (this.requesting = !1), this.requestNext();
              break;
            case 400:
              "access_token_expired" == r.error
                ? this.retryWithNewRefreshToken(e, s, t)
                : (console.log("error", o),
                  (this.requesting = !1),
                  this.requestNext(),
                  i.error(r));
              break;
            case 401:
              "missing_access_token" == r.error
                ? (console.log("MISSING ACCESS TOKEN"),
                  this.retryWithNewRefreshToken(e, s, t))
                : "missing_refresh_token" == r.error
                  ? console.log("MISSING REFRESH TOKEN")
                  : (console.log(r, r.error),
                    (this.requesting = !1),
                    this.requestNext());
              break;
            case 500:
              console.log("server error", r),
                (this.requesting = !1),
                this.requestNext();
              break;
            default:
              console.log("unhandled", o),
                (this.requesting = !1),
                this.requestNext();
          }
        })
        .catch((e) => {});
  }
  headers() {
    let e = { "Content-Type": "application/json;charset=utf-8" };
    return (
      this.store.state.accessToken &&
        (e.Authorization = "Bearer " + this.store.state.accessToken),
      e
    );
  }
  retryWithNewRefreshToken(e, s, t) {
    this.refreshToken({
      success: () => {
        this.request(e, s, t);
      },
    });
  }
  refreshToken(e) {
    this.request("/auth/refresh", "POST", {
      body: {},
      success: (s) => {
        (this.store.state.accessToken = s.access_token), e.success();
      },
      error: (s) => {
        console.log("Could not Refresh", s), e.error();
      },
    });
  }
}
class t {
  constructor(e, s) {
    (this.api = e),
      (this.store = s),
      (this.endpoints = { authenticate: "/auth/authenticate" });
  }
  authenticate(e, s, t) {
    this.api.post(this.endpoints.authenticate, {
      body: { platform: e, email: s, password: t },
      success: (e) => (this.store.state.accessToken = e.access_token),
      error: (e) => console.log("Could not authenticate", e),
    });
  }
  authenticated() {
    return (
      !!this.store.state.accessToken ||
      "true" ==
        ((e = "refresh_token_present"),
        document.cookie.split("; ").reduce((s, t) => {
          const o = t.split("=");
          return o[0] === e ? decodeURIComponent(o[1]) : s;
        }, ""))
    );
    var e;
  }
}
class o {
  constructor(e, s) {
    (this.api = e),
      (this.store = s),
      (this.endpoints = {
        index: "/uploads",
        get: "/uploads",
        presign: "/uploads/presign",
        create: "/uploads",
        update: "/uploads",
        delete: "/uploads",
      });
  }
  index(e) {
    this.api.get(this.endpoints.index, { success: e.success, error: e.error });
  }
  get(e) {
    this.api.get(this.endpoints.get + "/" + e.id, {
      success: (s) => e.success(s),
      error: (s) => e.error(s),
    });
  }
  upload(e) {
    let s = {
      filename: e.file.name,
      contentType: e.file.type,
      filesize: e.file.size,
    };
    this.api.get(this.endpoints.presign, {
      params: s,
      success: (t) => {
        this.uploadFile(e, s, JSON.parse(t));
      },
      error: (s) => e.error(s),
    });
  }
  uploadFile(e, s, t) {
    const o = t.fields.key.replace("cache/", "");
    e.add(o, s.filename, s.filesize);
    let r = new FormData();
    for (const e in t.fields) r.append(e, t.fields[e]);
    r.append("file", e.file);
    let i = new XMLHttpRequest();
    (i.upload.onprogress = function (s) {
      e.progress(o, Math.round((100 / s.total) * s.loaded));
    }),
      (i.onload = function (s) {
        e.complete(o);
      }),
      (i.onerror = function (s) {
        e.error(o, s);
      }),
      i.open(t.method, t.url),
      i.send(r);
  }
  create(e) {
    let s = e.files.map((e) => ({
      id: e.key.replace("cache/", ""),
      storage: "cache",
      metadata: { size: e.size, filename: e.name, mime_type: e.type },
    }));
    this.api.post(this.endpoints.create, {
      body: { files: s, settings: e.settings },
      success: e.success,
      error: e.error,
    });
  }
  update(e) {
    this.api.put(`${this.endpoints.update}/${e.id}`, {
      body: { settings: e.settings },
      success: e.success,
      error: e.error,
    });
  }
  delete(e) {
    this.api.delete(`${this.endpoints.delete}/${e.id}`, {
      body: { settings: e.settings },
      success: e.success,
      error: e.error,
    });
  }
}
export default class {
  constructor(r) {
    this.options = { endpoint: "http://localhost:5000/api/v1", ...r };
    const i = new e({ accessToken: null }),
      n = new s({ store: i, host: this.options.endpoint });
    return { auth: new t(n, i), uploads: new o(n, i) };
  }
}
