import { Controller } from "stimulus";
import camelCase from "lodash.camelcase";
import Api from "../../apis/api";
import Helper from "../../general/helper";

const COUNT_KEYS = [
  "imported",
  "selected",
  "unique_requests",
  "total_sent_mailings",
  "answers",
];

export default class extends Controller {
  static targets = [
    "creditorInitial",
    "creditorImported",
    "creditorSelected",
    "creditorUniqueRequests",
    "creditorTotalSentMailings",
    "creditorAnswers",
    "creditorNone",
    "debitorInitial",
    "debitorImported",
    "debitorSelected",
    "debitorUniqueRequests",
    "debitorTotalSentMailings",
    "debitorAnswers",
    "debitorNone",
  ];

  connect() {
    const permalink = this.data.get("permalink");

    for (const category of ["creditor", "debitor"]) {
      this.fetchCounts({
        url: `/reviews/${permalink}/sample_category_counts`,
        params: { category },
      });
    }
  }

  fetchCounts({ url, params }) {
    Api.get(url, {
      params,
      success: (response) => {
        const counts = Helper.dig(["data", "counts"], response);

        if (!counts) {
          return;
        }

        if (params.initial === false) {
          for (const key of COUNT_KEYS) {
            const targetName = camelCase(`${params.category}_${key}_target`);

            if (key === "answers") {
              this.setCountPercent(
                targetName,
                counts["answers"],
                counts["selected"],
              );
            } else {
              this.setCount(targetName, counts[key]);
            }

            this.hideTarget(`${params.category}InitialTarget`);
            this.showTarget(targetName);
          }
        } else if (counts["imported"] > 0) {
          this.fetchCounts({
            url,
            params: { ...params, initial: false },
          });
        } else {
          this.hideTarget(`${params.category}InitialTarget`);
          this.showTarget(`${params.category}NoneTarget`);
        }
      },
      error: (response) => {
        console.error("Error", response);
      },
    });
  }

  setCount(targetName, value) {
    if (value !== undefined && value !== null) {
      this[targetName].textContent = value;
    }
  }

  setCountPercent(targetName, numerator, denominator) {
    if (
      numerator !== undefined &&
      numerator !== null &&
      denominator !== undefined &&
      denominator !== null
    ) {
      this[targetName].textContent = [
        numerator,
        " (",
        Math.round(denominator > 0 ? (100 * numerator) / denominator : 0),
        "%)",
      ].join("");
    }
  }

  showTarget(targetName) {
    this[targetName].closest("li").classList.remove("hidden");
  }

  hideTarget(targetName) {
    this[targetName].closest("li").classList.add("hidden");
  }
}
