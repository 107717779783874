<script>
  import styles from "styleguide/ResponderDescription.json";
  import { t } from "stores/i18n.js";
</script>

<div data-component="ResponderDescription" class={styles.section}>
  <h1 class={styles.title}>{$t("responder_description.title")}</h1>
  <div class={styles.content}>{$t("responder_description.content")}</div>
  <div class={styles.partner}>
    <img src="/assets/datev.png" class={styles.datev} alt="DATEV eG" />
    <p class={styles.cooperation}>{$t("responder_description.cooperation")}</p>
  </div>
</div>

<style lang="scss">
  .title {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.45;
    color: var(--primary-500);
    margin: 0 0 1.5rem;
  }

  .content {
    line-height: 1.57;
    color: var(--primary-500);
    margin: 0 0 1.4rem;
  }

  .partner {
    display: flex;
  }

  .datev {
    width: 42px;
    height: 42px;
  }

  .cooperation {
    font-weight: 600;
    line-height: 1.57;
    color: var(--primary-500);
    margin: 10px 16px;
  }
</style>
