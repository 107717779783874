import { get } from "svelte/store";
import Api from "apis/api";
import { buildFormData } from "lib/helpers.js";

import { t } from "stores/i18n.js";
import { toasts } from "stores/toasts.js";

class Attachments {
  constructor() {
    this.endpoint = "/attachments";
  }

  get(options) {
    const { params, success, error } = options;

    Api.get(`${this.endpoint}`, {
      params,
      success: (response) => {
        success(response.data.attachments);
      },
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  getById(options) {
    const { params, success, error } = options;

    return Api.get(`${this.endpoint}/${params.id}`, {
      params,
      success,
      error: (response) => {
        if (error) {
          error(response);
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  create(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params.files, "files");
    formData.append("parent_id", params.parent_id);
    formData.append("parent_type", params.parent_type);

    Api.post(`${this.endpoint}`, {
      body: formData,
      success: (response) => {
        success(response.data.attachments);
      },
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  preview(options) {
    const { params, success, error } = options;

    return Api.get(`${this.endpoint}/${params.id}/previews`, {
      params,
      success: (response) => {
        success(response.data);
      },
      error: (response) => {
        if (error) {
          error(error);
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  previewDirect(options) {
    const { params, success, error } = options;

    return Api.get(`${this.endpoint}/${params.id}/preview`, {
      params,
      success,
      error: (response) => {
        if (error) {
          error(response);
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  markAsRead(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    formData.append("parent_id", params.parent_id);
    formData.append("parent_type", params.parent_type);
    formData.append("is_read", params.is_read);

    Api.post(`${this.endpoint}/${params.id}/mark_as_read`, {
      body: formData,
      success: (response) => {
        success(response.data);
        toasts.send({
          title: get(t)("attachments.mark_as_read.success"),
          type: "success",
        });
      },
      error: (response) => {
        if (error) {
          error();
          toasts.send({
            title: get(t)("attachments.mark_as_read.error"),
            type: "error",
          });
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  markAsReadBatch(options) {
    const { params, success, error } = options;

    const formData = new FormData();

    for (let id of params.ids) {
      formData.append("attachment_ids[]", id);
    }

    formData.append("is_read", params.is_read);
    formData.append("parent_id", params.parent_id);
    formData.append("parent_type", params.parent_type);

    Api.post(`${this.endpoint}/batch_attachment_mark`, {
      body: formData,
      success: (response) => {
        success(response.data.attachments);
        toasts.send({
          title: get(t)("attachments.mark_as_read.success"),
          type: "success",
        });
      },
      error: (response) => {
        if (error) {
          error();
          toasts.send({
            title: get(t)("attachments.mark_as_read.error"),
            type: "error",
          });
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  virusesScanResult(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    formData.append("parent_id", params.parent_id);
    formData.append("parent_type", params.parent_type);

    Api.get(`${this.endpoint}/${params.id}/viruses_scan_result`, {
      params,
      success: (response) => {
        success(response.data);
      },
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  delete(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    formData.append("parent_id", params.parent_id);
    formData.append("parent_type", params.parent_type);

    Api.delete(`${this.endpoint}/${params.id}`, {
      body: formData,
      success: (response) => {
        success(response.data);
      },
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  deleteBatch(options) {
    const { params, success, error } = options;

    const formData = new FormData();

    for (let id of params.ids) {
      formData.append("attachment_ids[]", id);
    }

    formData.append("archived", params.archived);
    formData.append("parent_id", params.parent_id);
    formData.append("parent_type", params.parent_type);

    Api.post(`${this.endpoint}/batch_attachment_delete`, {
      body: formData,
      success: (response) => {
        success(response.data.attachments);
      },
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  archiveBatch(options) {
    const { params, success, error } = options;

    const formData = new FormData();

    for (let id of params.ids) {
      formData.append("attachment_ids[]", id);
    }

    formData.append("archived", params.archived);
    formData.append("parent_id", params.parent_id);
    formData.append("parent_type", params.parent_type);

    Api.post(`${this.endpoint}/batch_attachment_archive`, {
      body: formData,
      success: (response) => {
        success(response.data.attachments);

        toasts.send({
          title: params.archived
            ? get(t)("attachments.archive.success")
            : get(t)("attachments.archive.revert"),
          type: "success",
        });
      },
      error: (response) => {
        if (error) {
          error();
          toasts.send({
            title: get(t)("attachments.archive.error"),
            type: "error",
          });
        } else {
          console.error("Error", response);
        }
      },
    });
  }
}

export default new Attachments();
