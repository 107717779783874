<script>
  import styles from "styleguide/QuickshareHeader.json";
  import { showWidget, addedFiles, uploadedFiles } from "stores/quickshare";
  import { LegacyButton as Button } from "components";

  function onClose() {
    showWidget.set(false);
    addedFiles.set([]);
    uploadedFiles.set([]);
    document.body.classList.remove("quickshare-active");
  }
</script>

<div data-component="QuickshareHeader" class={styles.header}>
  <slot />
  <Button click={onClose} style="border icon-only" icon="x.svg" />
</div>

<style lang="scss">
  .header {
    border-bottom: 1px solid var(--primary-050);
    padding: 10px 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>
