<script>
  import { createPopperActions } from "svelte-popperjs";
  import styles from "styleguide/Tooltip.json";

  export let style = "";
  export let nowrap = true;
  export let message = "";
  export let showArrow = false;
  export let contentWidth = "auto";
  export let minWidth = "auto";
  export let placement = "bottom";
  export let forceHide = false;
  export let maxWidth = "auto";
  export let overflowingShow = false;

  let show = false;
  let containerEl;

  const [popperRef, popperContent] = createPopperActions({
    strategy: "fixed",
    placement: placement,
  });

  const extraOpts = {
    modifiers: [
      { name: "offset", options: { offset: [0, 8] } },
      { name: "flip", options: { fallbackPlacements: ["top", "bottom"] } },
      {
        name: "arrow",
        options: {
          padding: ({ popper, reference }) => popper.width / reference.width,
        },
      },
    ],
  };

  function getStyles() {
    let s = style.split(" ");
    if (nowrap) {
      s.push("nowrap");
    }
    return s.map((x) => styles[x]).join(" ");
  }

  function checkOnOverflowingHide(scrollWidth, offsetWidth) {
    forceHide = scrollWidth <= offsetWidth;
  }

  function attachTooltip(event) {
    if (overflowingShow) {
      checkOnOverflowingHide(
        event.target?.scrollWidth,
        containerEl?.offsetWidth,
      );
    }
    show = true;
  }

  function removeTooltip() {
    show = false;
  }

  function init(node) {
    node.addEventListener("mouseover", attachTooltip);
    node.addEventListener("mouseout", removeTooltip);

    return {
      destroy() {
        node.removeEventListener("mouseover", attachTooltip);
        node.removeEventListener("mouseout", removeTooltip);
      },
    };
  }
</script>

<div
  bind:this={containerEl}
  data-component="Tooltip"
  use:init
  on:click
  use:popperRef
  class="{styles.wrapper} {getStyles()}"
  style="width: {contentWidth}"
>
  {#if $$slots.content}
    <slot name="content" />
  {:else}
    <slot />
  {/if}
  {#if show && (message || $$slots.tooltip) && !forceHide}
    <div
      use:popperContent={extraOpts}
      class="{styles.tooltip}  {getStyles()}"
      style="min-width: {minWidth}; max-width: {maxWidth}"
    >
      {#if showArrow}
        <div class={styles.arrow} data-popper-arrow />
      {/if}
      {#if message}
        {@html message}
      {:else}
        <slot name="tooltip" />
      {/if}
    </div>
  {/if}
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    position: relative;
    cursor: pointer;
    [data-popper-placement^="top"] {
      .arrow {
        bottom: -4px;
        &:before {
          border-bottom-width: 1px;
          border-right-width: 1px;
          top: 1px;
        }
      }
    }
    [data-popper-placement^="bottom"] {
      .arrow {
        top: -4px;
        &:before {
          border-top-width: 1px;
          border-left-width: 1px;
          top: -1px;
        }
      }
    }
    [data-popper-placement^="left"] {
      .arrow {
        right: -4px;
        &:before {
          left: 1px;
          border-top-width: 1px;
          border-right-width: 1px;
        }
      }
    }
    [data-popper-placement^="right"] {
      .arrow {
        left: -4px;
        &:before {
          border-bottom-width: 1px;
          border-left-width: 1px;
          left: -1px;
        }
      }
    }

    &.fit-content {
      width: fit-content;
    }
  }

  .tooltip {
    z-index: var(--z-index-tooltip);
    pointer-events: none;
    border: 1px solid var(--primary-050);
    border-radius: var(--border-radius);
    background: #fff;
    padding: 0.5rem 14px;
    box-shadow:
      0 8px 24px -4px rgba(24, 39, 75, 0.08),
      0px 6px 12px -6px rgba(24, 39, 75, 0.12);
    word-break: normal;

    &.small {
      min-width: 200px;
    }

    &.medium {
      min-width: 400px;
    }

    &:empty {
      display: none !important;
    }
  }
  .arrow {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
    visibility: hidden;

    &:before {
      position: absolute;
      width: 8px;
      height: 8px;
      background: inherit;
      visibility: visible;
      content: "";
      transform: rotate(45deg);
      border-color: var(--primary-050);
      border-style: solid;
      border-width: 0;
    }
  }
  .nowrap {
    white-space: nowrap;
    word-break: normal;
  }
</style>
