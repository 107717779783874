import Api from "apis/api";
import { buildFormData } from "lib/helpers.js";

class ConfirmationExports {
  constructor() {
    this.endpoint = "/confirmation_exports";
  }

  getData(options) {
    const { params, success, error } = options;

    Api.get(this.endpoint, {
      params,
      success: (response) => {
        success(response.data.exports);
      },
      error,
    });
  }

  create(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params);

    Api.post(this.endpoint, {
      body: formData,
      success,
      error,
    });
  }

  checkExport(options) {
    const { params, success, error } = options;

    Api.get(`${this.endpoint}/${params.id}/check`, {
      params,
      success,
      error: (response) => {
        console.error("Error", response);
      },
    });
  }

  delete(options) {
    const { params, success, error } = options;

    Api.delete(
      this.endpoint + `/${params.id}?confirmation_id=${params.confirmation_id}`,
      {
        success,
        error,
      },
    );
  }

  download(options) {
    const { params, success, error } = options;

    Api.get(`${this.endpoint}/${params.id}/download`, {
      params,
      success: (response) => {
        success(response.data);
      },
      error,
    });
  }
}

export default new ConfirmationExports();
