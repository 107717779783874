<script>
  import styles from "styleguide/ConfirmationNewBlock.json";
  import { t } from "stores/i18n.js";
  import { Button } from "components";

  export let active;
  export let edit = () => {
    active = true;
  };
  export let title = undefined;
  export let description = undefined;
</script>

<div class={styles.wrapper} data-component="ConfirmationNewBlock">
  {#if title && active}
    <div class="{styles.container} {styles.title}">{title}</div>
  {/if}

  {#if description && active}
    <div class="{styles.container} {styles.description}">{description}</div>
  {/if}

  <div class={styles.container}>
    <slot />

    {#if !active}
      <div class={styles.edit}>
        <Button style={"primary-text small"} click={edit}
          >{$t("confirmation_new_block.edit")}</Button
        >
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  .wrapper {
    min-height: 40px;
  }

  .container {
    padding: 0;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
      position: relative;
    }
  }

  .title {
    font-size: 14px;
    font-weight: 600;
    margin: 20px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--primary-050);
  }

  .description {
    font-size: 14px;
    color: var(--primary-300);
  }

  .edit {
    position: absolute;
    top: 0;
    right: 0;
  }
</style>
