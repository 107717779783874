<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(87.058824%,0%,0%);fill-opacity:1;"
      d="M 480.003906 359.683594 L 0.285156 359.683594 L 0.285156 0 L 480.003906 0 Z M 480.003906 359.683594 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(20.784314%,63.137255%,0%);fill-opacity:1;"
      d="M 479.722656 360 L 0.00390625 360 L 0.00390625 180.160156 L 479.722656 180.160156 Z M 479.722656 360 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,95.294118%,0%);fill-opacity:1;"
      d="M 190.960938 207.140625 L 111.410156 152.816406 L 209.691406 152.90625 L 239.980469 64.914062 L 270.273438 152.90625 L 368.554688 152.84375 L 288.992188 207.140625 L 319.433594 295.09375 L 239.96875 240.664062 L 160.503906 295.082031 Z M 190.960938 207.140625 "
    />
  </g>
</svg>
