import Api from "apis/api";

class usersControlsApi {
  constructor() {
    this.endpoint = "/users_controls";
  }

  create(options) {
    const { params, success, error } = options;

    const formData = new FormData();

    formData.append("user_id", params.user_id);
    formData.append("control_id", params.control_id);

    Api.post(this.endpoint, {
      body: formData,
      success,
      error,
    });
  }

  updateStatusBatch(options) {
    const { params, success, error } = options;

    const formData = new FormData();

    for (let id of params.permalinks) {
      formData.append("ids[]", id);
    }

    formData.append("user_id", params.user_id);

    Api.post(this.endpoint + "/batch_user_add_to_controls", {
      body: formData,
      success,
      error,
    });
  }

  delete(options) {
    const { params, success, error } = options;

    Api.delete(
      `${this.endpoint}/destroy_access?control_id=${params.control_id}&user_id=${params.user_id}`,
      {
        success,
        error,
      },
    );
  }
}

export default new usersControlsApi();
