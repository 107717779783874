import Api from "./api";

class Helper {
  dig(keys, object) {
    return keys.reduce(
      (acc, key) => (acc && acc[key] ? acc[key] : null),
      object,
    );
  }

  encodeFileName(fileName) {
    return encodeURIComponent(fileName).replace(/[!'()*]/g, function (c) {
      // encodeURIComponent doesn't encode !'()* https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
      return "%" + c.charCodeAt(0).toString(16);
    });
  }

  escapeHtml(html) {
    const container = document.createElement("div");
    container.appendChild(document.createTextNode(html));
    return container.innerHTML;
  }

  titleize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  getUrlParams() {
    let params = {};
    for (var el of new URLSearchParams(
      window.location.search.substring(1),
    ).entries()) {
      params[el[0]] = el[1];
    }
    return params;
  }

  buildFormData(formData, data, parentKey) {
    let self = this;
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        self.buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key,
        );
      });
    } else {
      const value = data == null ? "" : data;
      formData.append(parentKey, value);
    }
  }

  jsonToFormData(data) {
    if (Object.keys(data).length == 0) {
      return null;
    }
    const formData = new FormData();
    this.buildFormData(formData, data);
    return formData;
  }

  idsArrayToQueryParams(ids) {
    let queryParam = "";
    ids.forEach((id) => {
      queryParam += `ids[]=${id}&`;
    });
    return queryParam;
  }

  createSpanElement(text, dataTarget) {
    let newElement = document.createElement("span");
    newElement.innerText = text;
    if (dataTarget) {
      newElement.setAttribute("data-target", dataTarget);
    }

    return newElement;
  }

  createInputElement(placeholder, value, dataTarget) {
    let newElement = document.createElement("input");
    newElement.type = "text";
    if (dataTarget) {
      newElement.setAttribute("data-target", dataTarget);
    }
    newElement.placeholder = placeholder;
    newElement.value = value;

    return newElement;
  }

  createTextAreaElement(placeholder, value, dataTarget) {
    let newElement = document.createElement("textarea");
    if (dataTarget) {
      newElement.setAttribute("data-target", dataTarget);
    }
    newElement.placeholder = placeholder;
    newElement.value = value;
    newElement.classList.add("control-description-textarea");
    return newElement;
  }

  createJSLink(linkName, cssClasses, dataTarget, dataAction, dataLink) {
    let newLink = document.createElement("a");
    newLink.href = "#";
    newLink.text = linkName;
    newLink.classList.add(...cssClasses.split(" "));

    if (dataTarget) {
      newLink.setAttribute("data-target", dataTarget);
    }
    if (dataAction) {
      newLink.setAttribute("data-action", dataAction);
    }

    if (dataLink) {
      newLink.setAttribute("data-url", dataLink);
    }

    return newLink;
  }

  confirmDelete(...deleteArgs) {
    let confirmResult = confirm(I18n.t("platform.views.delete_confirm"));
    if (confirmResult) {
      Api.delete(...deleteArgs);
    }
  }

  simpleConfirmDelete(...deleteArgs) {
    if (confirm(I18n.t("platform.views.are_you_sure"))) {
      Api.delete(...deleteArgs);
    }
  }

  loadModalByClosestTag(e, closestTagName) {
    e.preventDefault();
    let target = e.target.closest(closestTagName);
    this.loadModalByUrl(target.getAttribute("data-url"));
  }

  loadModalByUrl(url) {
    Api.get(url, {}, (response) => {
      let node = document.createElement("div");
      node.innerHTML = response["template"];
      document.querySelector("body").appendChild(node);
    });
  }

  plus(first, second) {
    return first + second;
  }

  minus(first, second) {
    return first - second;
  }

  copyToClickboard(target) {
    let tempInput = document.createElement("input");
    if (target.tagName === "INPUT" || tempInput.tagName === "TEXTAREA") {
      tempInput.value = target.value;
    } else {
      tempInput.value = target.textContent;
    }
    tempInput.focus();
    navigator.clipboard.writeText(tempInput.value.trim());
    tempInput.remove();
  }

  flashAlertClass(response_alert_class, options_alert_class) {
    switch (response_alert_class) {
      case "unprocessable_entity":
        return "error";
      case "warning":
        return "warning";
      case "ok":
        return "notice";
      default:
        if (options_alert_class) return options_alert_class;
        else return "notice";
    }
  }

  addLinkLoadingState(node) {
    let nodeWidth = node.getBoundingClientRect().width,
      spinnerNode = document.createElement("div");
    spinnerNode.classList.add("loading-spinner");
    node.setAttribute("data-value-disabled", node.textContent);
    node.textContent = "";
    node.style.width = `${nodeWidth}px`;
    node.classList.add("bordered-disabled-btn");
    node.appendChild(spinnerNode);
  }

  removeLinkLoadingState(node) {
    node.classList.remove("bordered-disabled-btn");
    node.textContent = node.getAttribute("data-value-disabled");
    node.removeAttribute("data-value-disabled");
  }

  debounce = (callback, wait = 450) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        callback(...args);
      }, wait);
    };
  };
}

export default new Helper();
