<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(0%,0%,40%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,40%);fill-opacity:1;"
      d="M 0 0 L 280.261719 0 L 280.261719 174.125 L 0 174.125 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 0 L 0 19.46875 L 248.925781 174.125 L 280.257812 174.125 L 280.257812 154.660156 L 31.332031 0 Z M 280.257812 0 L 280.257812 19.46875 L 31.332031 174.125 L 0 174.125 L 0 154.65625 L 248.925781 0 Z M 280.257812 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 116.773438 0 L 116.773438 174.125 L 163.484375 174.125 L 163.484375 0 Z M 0 58.042969 L 0 116.082031 L 280.257812 116.082031 L 280.257812 58.042969 Z M 0 58.042969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 0 69.648438 L 0 104.476562 L 280.257812 104.476562 L 280.257812 69.648438 Z M 126.117188 0 L 126.117188 174.125 L 154.140625 174.125 L 154.140625 0 Z M 126.117188 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 0 174.125 L 93.417969 116.082031 L 114.308594 116.082031 L 20.886719 174.125 Z M 0 0 L 93.417969 58.042969 L 72.53125 58.042969 L 0 12.980469 Z M 165.949219 58.042969 L 259.371094 0 L 280.257812 0 L 186.839844 58.042969 Z M 280.257812 174.125 L 186.839844 116.082031 L 207.726562 116.082031 L 280.257812 161.148438 Z M 280.257812 174.125 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:0.996078;"
      d="M 459.40625 159.332031 L 459.40625 219.265625 C 459.40625 268.46875 440.078125 313.511719 382.742188 340.890625 C 325.429688 313.511719 306.101562 268.472656 306.101562 219.265625 L 306.101562 159.332031 L 459.433594 159.332031 Z M 459.40625 159.332031 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(99.215686%,76.470588%,0%);fill-opacity:0.996078;"
      d="M 454.269531 165.4375 L 454.269531 221.339844 C 454.269531 267.234375 436.242188 309.246094 382.761719 334.78125 C 329.285156 309.246094 311.257812 267.234375 311.257812 221.339844 L 311.257812 165.4375 L 454.273438 165.4375 Z M 454.269531 165.4375 "
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,61.960784%,23.921569%);fill-opacity:1;stroke-width:0.091102;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 205.959749 52.846486 C 205.606657 52.582698 205.224251 52.420162 204.695279 52.405507 C 204.812532 51.110546 203.870508 50.552328 203.193637 50.316517 C 202.518098 50.080706 202.282259 49.816918 202.165006 49.286677 C 202.04642 48.757768 201.69466 48.434028 201.076416 48.256837 C 200.458171 48.080978 199.927866 47.462808 199.69336 47.168377 C 199.457521 46.873947 199.369581 46.932567 199.192368 47.433498 C 199.016488 47.933097 198.310304 48.875007 198.956529 48.904317 C 198.487516 49.286677 198.222364 49.727656 198.515497 50.316517 C 198.191718 50.581637 197.957212 50.963997 198.398244 51.110546 C 198.840609 51.258427 198.956529 51.375667 198.987175 51.494238 C 198.590113 51.494238 198.487516 52.288267 199.428207 52.464126 C 199.221682 52.493436 199.133742 53.140916 199.51748 53.140916 C 198.987175 53.140916 198.54481 53.936278 197.869272 53.936278 C 197.1924 53.936278 196.839308 54.082827 196.809995 54.377257 C 196.779349 54.671688 196.427589 55.024738 196.250377 55.171287 C 196.751368 55.229906 197.073815 55.171287 197.338967 54.964786 C 197.604119 54.759617 198.045152 54.700997 198.339617 54.935476 C 198.634083 55.171287 199.252328 55.288526 199.545461 55.229906 C 198.899235 55.672218 198.427557 56.259746 198.368931 56.819297 C 198.310304 57.377516 197.927898 58.024996 197.485534 58.202187 C 197.044501 58.378046 196.456902 58.849667 196.250377 59.407886 C 196.809995 60.790776 197.602787 61.133168 198.398244 61.496876 C 199.810613 62.144356 200.692678 62.614645 201.281609 63.262125 C 201.87054 63.909606 202.106379 63.439316 202.988444 64.557086 C 203.870508 65.674855 204.695279 67.793155 205.577343 68.146205 C 206.459408 68.499255 207.578644 68.264776 208.224869 67.145675 C 208.872427 66.027905 209.285479 54.730307 205.959749 52.847818 Z M 205.959749 52.846486 "
      transform="matrix(2.931687,0,0,2.932039,-254.348655,34.989532)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,57.254902%,49.803922%);fill-opacity:1;stroke-width:0.091102;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 207.460058 48.139598 C 206.63662 49.021556 205.576011 49.904847 205.753223 51.492906 C 205.930436 53.082297 206.106316 55.670886 204.63532 56.554177 C 203.164324 57.436135 202.458139 59.673006 204.223601 60.966635 C 205.98773 62.261595 206.046356 63.850986 206.340822 65.321805 C 206.63662 66.792625 207.401431 67.969014 208.577962 66.969816 C 209.754492 67.145675 210.40205 66.675385 210.40205 65.733475 C 210.40205 64.791564 210.460676 64.262656 211.342741 63.968225 C 212.226138 63.673795 212.460645 63.379365 212.872364 62.585335 C 213.285415 61.791306 213.519922 61.438256 213.285415 60.966635 C 213.049576 60.496345 212.961636 59.525125 213.137516 58.819025 C 213.314728 58.112925 212.903009 57.730566 212.66717 57.289586 C 212.432664 56.848607 212.343391 56.466247 212.460645 55.877386 C 212.57923 55.288526 212.284765 54.906166 211.873046 54.611736 C 211.461327 54.317305 211.284114 54.022875 211.373387 53.552586 C 211.461327 53.082297 211.077589 52.787866 210.636556 52.434817 C 210.195524 52.081767 209.871745 51.611477 209.871745 50.905378 C 209.871745 50.197945 208.519335 48.551267 207.460058 48.139598 Z M 207.460058 48.139598 "
      transform="matrix(2.931687,0,0,2.932039,-254.348655,34.989532)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,61.960784%,88.235294%);fill-opacity:1;stroke-width:0.091102;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 207.790499 67.352176 C 209.761154 66.851244 209.585274 63.615175 209.290808 62.261595 C 208.996343 60.908015 208.239526 58.969571 208.820463 57.436135 C 209.643901 55.259216 207.81848 53.199536 208.290158 52.376197 C 208.760504 51.552858 208.701877 51.110546 208.64325 50.846758 C 208.584624 50.581637 208.143591 50.375136 208.114278 50.934687 C 208.083632 51.492906 207.937066 51.699407 207.583973 52.052457 C 207.230881 52.405507 207.318821 53.170226 207.583973 54.024207 C 207.849126 54.876856 208.407411 56.171817 207.525347 57.878447 C 206.643282 59.585077 207.005702 60.344467 207.349467 61.143826 C 207.878439 62.380167 209.144242 66.675385 207.790499 67.352176 Z M 207.790499 67.352176 "
      transform="matrix(2.931687,0,0,2.932039,-254.348655,34.989532)"
    />
    <path
      style="fill:none;stroke-width:0.091102;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 198.960527 48.904317 C 199.180377 48.904317 199.608085 48.815055 199.608085 49.109486 "
      transform="matrix(2.931687,0,0,2.932039,-254.348655,34.989532)"
    />
    <path
      style="fill:none;stroke-width:0.091102;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 198.519494 50.316517 C 198.784647 50.124671 199.256325 50.345827 199.725338 50.066051 "
      transform="matrix(2.931687,0,0,2.932039,-254.348655,34.989532)"
    />
    <path
      style="fill:none;stroke-width:0.091102;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 198.98984 51.492906 C 199.342932 51.492906 199.81461 51.478251 200.255643 51.125201 "
      transform="matrix(2.931687,0,0,2.932039,-254.348655,34.989532)"
    />
    <path
      style="fill:none;stroke-width:0.091102;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 199.42954 52.464126 C 199.754651 52.464126 200.356907 52.257625 200.503473 51.831301 "
      transform="matrix(2.931687,0,0,2.932039,-254.348655,34.989532)"
    />
    <path
      style="fill:none;stroke-width:0.091102;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 199.520145 53.140916 C 200.151714 53.111607 200.991141 53.347417 201.108394 53.126261 "
      transform="matrix(2.931687,0,0,2.932039,-254.348655,34.989532)"
    />
    <path
      style="fill:none;stroke-width:0.091102;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 199.549458 55.229906 C 199.99049 54.818237 201.036443 54.538461 201.27095 54.112137 "
      transform="matrix(2.931687,0,0,2.932039,-254.348655,34.989532)"
    />
    <path
      style="fill:none;stroke-width:0.091102;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 200.670026 48.668506 C 201.405524 48.904317 201.100399 49.358619 201.464151 49.639727 C 202.111709 50.140658 201.522778 50.698877 202.053082 50.846758 C 202.582055 50.993307 202.572728 51.082569 202.435488 51.670097 C 202.272932 52.361542 202.935147 52.390852 202.70064 52.846486 "
      transform="matrix(2.931687,0,0,2.932039,-254.348655,34.989532)"
    />
    <path
      style="fill:none;stroke-width:0.091102;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 204.700608 52.405507 C 204.406143 52.405507 203.537402 52.434817 203.169653 53.215523 "
      transform="matrix(2.931687,0,0,2.932039,-254.348655,34.989532)"
    />
    <path
      style="fill:none;stroke-width:0.091102;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 198.419563 60.89336 C 198.759331 60.953312 198.928549 60.760134 199.023151 60.570952 C 199.125747 60.364451 199.169717 60.217902 199.47884 60.203247 C 199.787962 60.18726 200.25964 60.099331 200.362237 59.864852 C 200.464833 59.629042 200.832582 59.848865 201.141704 59.232027 C 201.450827 58.613857 201.657352 57.878447 202.479458 57.613326 "
      transform="matrix(2.931687,0,0,2.932039,-254.348655,34.989532)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(0%,65.490196%,15.686275%);fill-opacity:1;stroke-width:0.091102;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 221.75963 73.749707 C 222.092736 74.233319 221.747638 74.756898 222.312586 74.966064 C 222.974801 75.2112 222.396529 76.081169 223.178662 76.388922 C 223.87019 76.660704 223.182659 77.493368 223.931481 77.866402 C 224.600358 78.200801 223.819557 78.98284 224.712281 79.429148 C 225.603673 79.875456 224.544396 80.878651 225.214605 81.269004 C 225.883482 81.659358 224.990758 82.663885 225.771558 83.054238 C 226.552359 83.444591 225.43712 84.22663 226.161958 84.783517 C 226.886797 85.341735 225.995406 86.178397 226.720244 86.68066 C 227.445083 87.182924 226.275215 87.853053 226.998721 88.577804 C 227.557006 89.134691 226.797525 89.680919 227.222568 90.529571 C 227.501044 91.087789 226.942759 93.319331 225.381158 93.095511 C 224.822873 94.044083 222.648357 94.880745 221.867557 94.602302 C 221.085424 95.159188 218.57647 95.941227 217.292013 94.71288 C 216.010222 95.941227 213.498603 95.159188 212.717802 94.602302 C 211.937002 94.880745 209.762486 94.044083 209.204201 93.095511 C 207.6426 93.319331 207.084315 91.087789 207.362791 90.529571 C 207.786502 89.680919 207.028353 89.134691 207.586638 88.577804 C 208.310144 87.853053 207.140276 87.182924 207.865115 86.68066 C 208.589953 86.178397 207.698562 85.341735 208.4234 84.783517 C 209.148239 84.22663 208.031668 83.444591 208.812468 83.054238 C 209.594601 82.663885 208.701877 81.659358 209.370754 81.269004 C 210.040963 80.878651 208.980354 79.875456 209.873078 79.429148 C 210.765802 78.98284 209.985001 78.200801 210.653878 77.866402 C 211.401368 77.493368 210.715169 76.660704 211.406697 76.388922 C 212.187498 76.081169 211.610558 75.2112 212.27144 74.966064 C 212.845715 74.752902 212.480631 74.217331 212.841718 73.727058 C 214.50325 74.379868 219.645074 74.848825 221.75963 73.749707 Z M 221.75963 73.749707 "
      transform="matrix(2.931687,0,0,2.932039,-254.348655,34.989532)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(99.215686%,76.470588%,0%);fill-opacity:0.996078;stroke-width:0.091102;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 213.510595 76.667365 C 213.510595 76.919163 213.505265 77.168296 213.273423 77.477381 C 212.771099 78.146178 213.495938 78.480576 212.937652 79.20666 C 212.380699 79.931411 213.049576 80.37772 212.436661 80.991893 C 211.822414 81.604735 212.548585 82.162953 211.934337 82.83175 C 211.321422 83.501879 212.046261 83.948187 211.432013 84.561029 C 210.819098 85.175202 211.543937 85.843999 210.92969 86.458172 C 210.316775 87.072346 211.041613 87.853053 210.371404 88.577804 C 209.702527 89.302556 210.48466 89.860774 209.926375 90.418993 C 209.368089 90.975879 209.870413 91.534098 209.480013 91.924451 C 210.148889 91.422188 210.148889 90.641481 210.651213 90.251128 C 211.153537 89.860774 210.48466 89.302556 210.763137 89.024113 C 211.041613 88.745669 210.819098 87.573277 211.321422 87.016391 C 211.822414 86.458172 211.377384 85.341735 211.822414 84.951382 C 212.268776 84.561029 211.934337 83.389969 212.492623 82.999615 C 213.049576 82.609262 212.492623 81.66069 212.937652 81.158426 C 213.385347 80.656163 212.937652 79.875456 213.385347 79.262615 C 213.830376 78.648441 213.405333 78.091555 213.718453 77.587959 C 213.858357 77.365471 213.858357 75.748103 213.510595 76.667365 Z M 213.510595 76.667365 "
      transform="matrix(2.931687,0,0,2.932039,-254.348655,34.989532)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(99.215686%,76.470588%,0%);fill-opacity:0.996078;stroke-width:0.091102;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 215.120162 77.365471 C 214.729762 78.090223 215.398639 78.258088 214.897648 78.98284 C 214.395324 79.707591 215.176124 80.26581 214.617839 80.990561 C 214.059553 81.716645 214.897648 82.106998 214.395324 82.83175 C 213.893 83.556502 214.617839 83.946855 214.171477 84.505073 C 213.725115 85.063292 214.505915 85.621511 213.948962 86.290307 C 213.390676 86.960436 214.171477 87.461367 213.725115 88.075541 C 213.278753 88.688382 213.893 89.358511 213.5026 89.860774 C 213.1122 90.363038 213.781077 91.087789 213.222791 91.756586 C 212.664505 92.426715 213.334715 92.873023 213.000276 93.319331 C 213.670485 93.095511 213.418657 92.174917 213.670485 91.980406 C 213.920981 91.784563 213.586543 90.501593 213.97561 90.167195 C 214.367343 89.832797 213.725115 88.800292 214.087534 88.353984 C 214.701781 87.601255 214.171477 87.098991 214.533896 86.514127 C 214.897648 85.927931 214.396656 85.175202 214.673801 84.784849 C 215.148143 84.11472 214.46461 83.511205 214.812373 83.027593 C 215.314696 82.329486 214.716438 81.755281 215.064201 81.298314 C 215.594505 80.600208 215.008239 79.903434 215.232086 79.541058 C 215.454601 79.17735 215.81702 77.615937 215.120162 77.365471 Z M 215.120162 77.365471 "
      transform="matrix(2.931687,0,0,2.932039,-254.348655,34.989532)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(99.215686%,76.470588%,0%);fill-opacity:0.996078;stroke-width:0.091102;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 217.289348 77.974316 C 216.900281 78.945536 217.569157 79.334557 217.346643 80.112599 C 217.122795 80.889309 217.625119 81.472841 217.289348 82.314832 C 216.956242 83.158155 217.737043 83.805635 217.289348 84.907417 C 216.844319 86.009199 217.578484 86.283646 217.289348 87.369441 C 216.956242 88.633759 217.847634 89.302556 217.289348 90.195173 C 216.732395 91.087789 217.928912 91.383552 217.346643 92.314805 C 217.066834 92.761113 217.374624 93.905528 217.819653 92.538625 C 218.267347 91.171722 217.486547 91.003857 217.875615 90.445638 C 218.267347 89.888752 217.737043 88.409939 217.903596 87.629232 C 218.071481 86.848526 217.514528 85.788044 217.875615 84.951382 C 218.239367 84.11472 217.318662 83.222103 217.681081 82.497352 C 218.0435 81.7726 217.514528 80.879983 217.847634 80.293787 C 218.183405 79.707591 217.597138 78.926884 217.737043 78.592486 C 217.875615 78.258088 217.597138 77.021747 217.289348 77.974316 Z M 217.289348 77.974316 "
      transform="matrix(2.931687,0,0,2.932039,-254.348655,34.989532)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(93.72549%,2.745098%,17.647059%);fill-opacity:1;stroke-width:0.091102;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 221.470494 74.407845 C 221.470494 68.104905 220.57777 65.929318 217.285351 65.929318 C 213.995597 65.929318 213.102873 68.104905 213.102873 74.407845 Z M 221.470494 74.407845 "
      transform="matrix(2.931687,0,0,2.932039,-254.348655,34.989532)"
    />
    <path
      style="fill:none;stroke-width:0.091102;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 214.330035 73.905582 C 213.715788 74.519755 214.441959 74.966064 213.827711 75.690815 C 213.213464 76.416899 213.77175 76.807253 213.269426 77.476049 C 212.768434 78.144846 213.493273 78.480576 212.934987 79.205328 C 212.378034 79.930079 213.046911 80.376388 212.432664 80.990561 C 211.819749 81.604735 212.544587 82.161621 211.93034 82.83175 C 211.317425 83.500546 212.043596 83.946855 211.429349 84.561029 C 210.816434 85.17387 211.541272 85.843999 210.927025 86.45684 C 210.31411 87.071014 211.038948 87.85172 210.368739 88.576472 C 209.699862 89.302556 210.480663 89.859442 209.922377 90.417661 C 209.365424 90.975879 209.866415 91.534098 209.477348 91.924451 C 209.085615 92.314805 209.030986 92.858368 209.197539 93.095511 "
      transform="matrix(2.931687,0,0,2.932039,-254.348655,34.989532)"
    />
    <path
      style="fill:none;stroke-width:0.091102;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 215.499903 74.129402 C 214.94295 74.854154 215.77838 75.188552 215.277388 75.802726 C 214.775065 76.415567 215.499903 76.639387 215.109503 77.364139 C 214.720435 78.090223 215.389312 78.256756 214.886988 78.98284 C 214.384665 79.707591 215.165465 80.26581 214.608512 80.990561 C 214.050226 81.715313 214.886988 82.105666 214.384665 82.83175 C 213.882341 83.556502 214.608512 83.946855 214.16215 84.505073 C 213.714455 85.06196 214.496588 85.620178 213.939635 86.290307 C 213.381349 86.959104 214.16215 87.461367 213.714455 88.075541 C 213.269426 88.688382 213.882341 89.358511 213.491941 89.859442 C 213.10154 90.361706 213.770417 91.087789 213.213464 91.756586 C 212.655178 92.425382 213.325388 92.871691 212.989617 93.317999 C 212.655178 93.764308 212.391359 94.419781 212.71114 94.600969 "
      transform="matrix(2.931687,0,0,2.932039,-254.348655,34.989532)"
    />
    <path
      style="fill:none;stroke-width:0.091102;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 217.289348 74.799531 C 217.012204 75.965262 217.681081 77.001763 217.289348 77.974316 C 216.900281 78.945536 217.569157 79.334557 217.346643 80.112599 C 217.122795 80.889309 217.625119 81.472841 217.289348 82.314832 C 216.956242 83.158155 217.737043 83.805635 217.289348 84.907417 C 216.844319 86.009199 217.578484 86.283646 217.289348 87.369441 C 216.956242 88.633759 217.847634 89.302556 217.289348 90.195173 C 216.732395 91.087789 217.793005 91.31161 217.346643 92.314805 C 216.900281 93.319331 217.625119 93.709685 217.289348 94.714212 "
      transform="matrix(2.931687,0,0,2.932039,-254.348655,34.989532)"
    />
    <path
      style="fill:none;stroke-width:0.091102;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 220.249994 73.905582 C 220.864241 74.519755 220.13807 74.966064 220.752318 75.690815 C 221.366565 76.416899 220.80828 76.807253 221.310603 77.476049 C 221.811595 78.144846 221.086756 78.480576 221.645042 79.205328 C 222.201995 79.930079 221.533118 80.376388 222.147366 80.990561 C 222.760281 81.604735 222.035442 82.161621 222.648357 82.83175 C 223.262604 83.500546 222.536433 83.946855 223.150681 84.561029 C 223.763596 85.17387 223.038757 85.843999 223.653004 86.45684 C 224.265919 87.071014 223.541081 87.85172 224.21129 88.576472 C 224.880167 89.302556 224.099366 89.859442 224.657652 90.417661 C 225.214605 90.975879 224.713614 91.534098 225.102682 91.924451 C 225.494414 92.314805 225.550376 92.858368 225.382491 93.095511 "
      transform="matrix(2.931687,0,0,2.932039,-254.348655,34.989532)"
    />
    <path
      style="fill:none;stroke-width:0.091102;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 219.080126 74.129402 C 219.637079 74.854154 218.801649 75.188552 219.302641 75.802726 C 219.804965 76.415567 219.080126 76.639387 219.470526 77.364139 C 219.860926 78.090223 219.190717 78.256756 219.693041 78.98284 C 220.195365 79.707591 219.414564 80.26581 219.971518 80.990561 C 220.529803 81.715313 219.693041 82.105666 220.195365 82.83175 C 220.696356 83.556502 219.971518 83.946855 220.417879 84.505073 C 220.865574 85.06196 220.083441 85.620178 220.641727 86.290307 C 221.19868 86.959104 220.417879 87.461367 220.865574 88.075541 C 221.310603 88.688382 220.696356 89.358511 221.088089 89.859442 C 221.478489 90.361706 220.809612 91.087789 221.366565 91.756586 C 221.924851 92.425382 221.254642 92.871691 221.590412 93.317999 C 221.924851 93.764308 222.190003 94.419781 221.868889 94.600969 "
      transform="matrix(2.931687,0,0,2.932039,-254.348655,34.989532)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;stroke-width:0.091102;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 213.109535 74.407845 C 213.109535 68.104905 214.002259 65.929318 217.293346 65.929318 C 215.786374 65.929318 214.867002 67.100378 214.616506 67.881084 C 214.364678 68.661791 214.504583 68.605836 214.839021 68.299415 C 215.173459 67.992994 215.005574 68.661791 214.72843 69.025499 C 214.448621 69.387875 214.170144 70.419047 214.560544 70.084649 C 214.950945 69.750251 215.257402 70.140604 214.839021 70.7268 C 214.42064 71.311664 214.058221 72.678567 214.476602 72.204281 C 214.894983 71.729995 214.977593 72.512034 214.72843 72.902387 C 214.476602 73.29274 214.504583 73.627139 214.72843 73.459273 C 214.950945 73.29274 214.922964 74.101425 214.700449 74.407845 Z M 213.109535 74.407845 "
      transform="matrix(2.931687,0,0,2.932039,-254.348655,34.989532)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;stroke-width:0.091102;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 220.829598 74.045469 C 220.829598 69.109432 220.216684 67.630619 218.989521 67.128355 C 218.295328 66.844583 218.275342 66.905867 218.607116 67.352176 C 218.938889 67.798484 218.888257 68.439303 218.580467 67.992994 C 218.275342 67.546686 218.172745 67.464086 218.044833 67.658596 C 217.91692 67.854439 218.401922 68.775033 218.760344 69.611695 C 219.048148 70.284488 218.861609 70.61489 218.428571 69.946093 C 217.994201 69.275965 217.994201 69.883477 218.375274 70.448357 C 218.864273 71.173108 218.849617 73.376673 218.849617 74.297267 Z M 220.829598 74.045469 "
      transform="matrix(2.931687,0,0,2.932039,-254.348655,34.989532)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(93.72549%,2.745098%,17.647059%);fill-opacity:1;stroke-width:0.091102;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 217.289348 75.32844 C 218.600453 75.32844 220.035474 75.095293 221.000149 74.720927 C 222.143368 74.277283 222.20466 73.88693 221.80893 73.388663 C 221.530453 73.038278 220.916206 73.248776 220.331272 73.505903 C 219.745005 73.76303 218.127443 73.926898 217.289348 73.926898 C 216.453919 73.926898 214.836356 73.76303 214.25009 73.505903 C 213.665156 73.248776 213.050908 73.038278 212.772432 73.388663 C 212.375369 73.88693 212.436661 74.277283 213.581213 74.720927 C 214.544555 75.095293 215.979576 75.32844 217.289348 75.32844 Z M 217.289348 75.32844 "
      transform="matrix(2.931687,0,0,2.932039,-254.348655,34.989532)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:6.3772;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.440098 912.605819 C 779.440098 912.605819 779.270477 1016.207796 779.414285 1091.862307 C 779.486636 1167.473057 799.219147 1196.702084 833.688944 1195.449452 C 868.128783 1194.085296 874.765436 1136.55977 868.175202 1103.359453 C 861.626467 1070.091373 814.95471 904.352543 814.95471 904.352543 Z M 779.440098 912.605819 "
      transform="matrix(0.0397548,-0.0256742,0.0243467,0.041923,366.767183,213.139118)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:6.3772;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.379973 912.603315 C 779.379973 912.603315 779.306433 1016.238487 779.450941 1091.814019 C 779.496981 1167.449876 799.233641 1196.740542 833.68757 1195.444949 C 868.158002 1194.071628 874.794374 1136.588101 868.230601 1103.351989 C 861.584862 1070.098475 814.958243 904.3981 814.958243 904.3981 Z M 779.379973 912.603315 "
      transform="matrix(0.0456011,-0.0102096,0.00968159,0.0480882,374.991148,195.794382)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:6.3772;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.42568 912.620136 C 779.42568 912.620136 779.258093 1016.236733 779.42568 1091.803745 C 779.509473 1167.450218 799.200938 1196.771172 833.640052 1195.420342 C 868.079167 1194.069512 874.782644 1136.619512 868.16296 1103.325529 C 861.62707 1070.111006 814.954109 904.356235 814.954109 904.356235 Z M 779.42568 912.620136 "
      transform="matrix(0.0466176,0,0,0.0491596,381.973642,187.014869)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:8.0953;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.493612 255.112397 C 939.095227 253.761567 956.648705 219.672978 956.648705 219.672978 C 956.648705 219.672978 992.160739 148.79414 974.472235 131.07443 C 956.648705 113.354721 779.493612 113.354721 779.493612 113.354721 Z M 779.493612 255.112397 "
      transform="matrix(0.0289295,0,0,0.0491596,395.762176,219.240028)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:6.3772;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.509473 255.131904 C 885.843382 255.131904 903.523803 219.692485 903.523803 219.692485 C 903.523803 219.692485 938.968439 148.813647 921.288018 131.093937 C 903.523803 113.374228 779.509473 113.374228 779.509473 113.374228 Z M 779.509473 255.131904 "
      transform="matrix(0.0466176,0,0,0.0491596,381.973642,215.754694)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:6.3772;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.509473 255.151411 C 868.079167 255.151411 921.288018 219.711992 921.288018 219.711992 C 921.288018 219.711992 956.64886 148.833154 938.968439 131.113445 C 921.288018 113.393735 779.509473 113.393735 779.509473 113.393735 Z M 779.509473 255.151411 "
      transform="matrix(0.0466176,0,0,0.0491596,381.973642,212.26936)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:5.7245;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 832.676041 113.420284 C 832.676041 113.420284 903.571261 24.821736 1027.536616 -63.776811 C 1151.569491 -152.375358 1241.302583 -220.473076 1257.844801 -205.534487 C 1257.844801 -187.814777 1151.569491 -99.21623 1062.984226 -28.337392 C 974.398961 42.541446 868.123651 148.859702 868.123651 148.859702 Z M 832.676041 113.420284 "
      transform="matrix(0.0578539,0,0,0.0491596,372.924129,187.115711)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:5.7245;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 832.702403 113.400777 C 832.702403 113.400777 902.719872 61.354096 1037.083194 -10.63719 C 1169.285898 -74.523496 1355.976644 -63.796318 1355.976644 -46.076609 C 1338.219079 -28.356899 1178.806114 -28.356899 1072.463284 24.802229 C 970.981841 75.49808 868.082494 148.840195 868.082494 148.840195 L 832.634884 113.400777 Z M 832.702403 113.400777 "
      transform="matrix(0.0578539,0,0,0.0491596,373.403072,190.601045)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:5.7245;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 832.682621 113.399381 C 832.682621 113.399381 894.327702 78.357265 1037.063411 24.800834 C 1178.786331 -28.358294 1366.9625 7.081125 1366.9625 24.800834 C 1349.204936 42.520544 1196.543896 31.713905 1090.201066 60.240253 C 980.212197 87.971996 868.130231 148.8388 868.130231 148.8388 Z M 832.682621 113.399381 "
      transform="matrix(0.0578539,0,0,0.0491596,373.447185,196.601114)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:5.7245;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 832.681028 113.351224 C 832.681028 113.351224 903.778806 42.392926 1055.15698 24.832138 C 1207.480423 7.112428 1331.445778 113.351224 1331.445778 131.070934 C 1313.755733 148.790643 1171.965294 53.120104 1065.757503 66.787324 C 953.067863 80.454544 868.061119 148.790643 868.061119 148.790643 L 832.613509 113.351224 Z M 832.681028 113.351224 "
      transform="matrix(0.0578539,0,0,0.0491596,373.837903,202.689418)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:6.3772;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.509473 255.094997 C 939.136026 253.744167 938.968439 209.643545 938.968439 209.643545 C 938.968439 209.643545 974.413075 138.764707 956.732654 121.044997 C 938.968439 103.325288 779.509473 113.416782 779.509473 113.416782 Z M 779.509473 255.094997 "
      transform="matrix(0.0466176,0,0,0.0491596,381.973642,207.920571)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:6.3772;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.509473 255.114504 C 939.136026 253.763674 956.64886 219.675085 956.64886 219.675085 C 956.64886 219.675085 992.093496 148.796247 974.413075 131.076537 C 956.64886 113.356828 779.509473 113.356828 779.509473 113.356828 Z M 779.509473 255.114504 "
      transform="matrix(0.0466176,0,0,0.0491596,381.973642,204.435237)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:6.3772;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.509473 255.106836 C 939.136026 253.756006 974.413075 219.667417 974.413075 219.667417 C 974.413075 219.667417 1009.857711 148.788579 992.093496 131.068869 C 974.413075 113.34916 779.509473 113.34916 779.509473 113.34916 Z M 779.509473 255.106836 "
      transform="matrix(0.0466176,0,0,0.0491596,381.973642,200.080145)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:6.3772;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.509473 255.099168 C 939.136026 253.748338 992.093496 219.659749 992.093496 219.659749 C 992.093496 219.659749 1009.857711 148.860372 992.093496 131.140662 C 974.413075 113.420953 779.509473 113.420953 779.509473 113.420953 Z M 779.509473 255.099168 "
      transform="matrix(0.0466176,0,0,0.0491596,381.973642,195.725053)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:5.7245;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 832.661037 113.425124 C 832.661037 113.425124 946.903619 24.826576 946.903619 -46.052261 C 946.903619 -116.931099 896.534254 -187.809937 882.287691 -223.249356 C 910.848336 -223.249356 975.464265 -116.931099 975.464265 -46.052261 C 975.464265 31.421805 868.108647 148.785082 868.108647 148.785082 Z M 832.661037 113.425124 "
      transform="matrix(0.0578539,0,0,0.0491596,371.79609,183.529536)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:5.7245;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 832.661037 113.425124 C 832.661037 113.425124 818.414474 60.265995 818.414474 -46.052261 C 818.414474 -170.090228 881.40994 -248.915123 904.028891 -276.408484 C 909.970586 -248.994584 846.975119 -152.370518 846.975119 -46.052261 C 846.975119 31.421805 868.108647 148.785082 868.108647 148.785082 Z M 832.661037 113.425124 "
      transform="matrix(0.0578539,0,0,0.0491596,371.79609,183.529536)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:6.3772;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.509473 255.0915 C 939.136026 253.74067 992.093496 219.652081 992.093496 219.652081 C 992.093496 219.652081 992.093496 148.852704 974.413075 131.132994 C 956.732654 113.413285 779.509473 113.413285 779.509473 113.413285 Z M 779.509473 255.0915 "
      transform="matrix(0.0466176,0,0,0.0491596,381.973642,191.369961)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:6.3772;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.509473 255.083832 C 939.136026 253.733002 974.413075 219.723874 974.413075 219.723874 C 974.413075 219.723874 974.413075 148.845036 956.732654 131.125326 C 938.968439 113.405617 779.509473 113.405617 779.509473 113.405617 Z M 779.509473 255.083832 "
      transform="matrix(0.0466176,0,0,0.0491596,381.973642,187.014869)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:16.071;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(99.215686%,76.470588%,0.392157%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.939136 1318.107478 C 373.991443 1195.817645 389.744615 981.512459 389.744615 946.07304 C 389.744615 846.270551 356.813783 360.925325 354.299979 166.485285 C 351.869968 -27.875295 478.314308 -134.670316 602.328638 -134.670316 C 708.662546 -134.670316 779.551819 -63.791478 779.551819 42.526779 L 726.342968 42.526779 C 726.342968 -28.352059 673.21791 -81.511187 602.328638 -81.511187 C 496.078523 -81.511187 414.379894 2.240265 407.50883 166.485285 C 399.883624 356.396072 428.79237 849.448974 425.189251 981.512459 C 421.586132 1113.496483 373.991443 1231.177603 318.939136 1318.107478 Z M 318.939136 1318.107478 "
      transform="matrix(-0.0466176,0,0,0.0491596,454.653332,187.014869)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:6.3772;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.404752 912.584172 C 779.404752 912.584172 779.306588 1016.229911 779.378939 1091.840661 C 779.522747 1167.495172 799.255259 1196.724199 833.653598 1195.427805 C 868.093436 1194.063649 874.801548 1136.581885 868.211314 1103.381568 C 861.591121 1070.069727 814.990822 904.374659 814.990822 904.374659 Z M 779.404752 912.584172 "
      transform="matrix(-0.0397548,-0.0256742,-0.0243467,0.041923,469.859791,213.139118)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:6.3772;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.509473 32.912049 C 903.69139 32.991509 957.319208 135.257276 956.984034 133.191301 L 921.288018 166.564745 C 902.266901 184.284455 779.509473 184.284455 781.017756 184.204994 Z M 779.509473 32.912049 "
      transform="matrix(0.0466176,0,0,0.0491596,381.973642,187.014869)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:6.3772;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.371127 912.601437 C 779.371127 912.601437 779.297587 1016.236609 779.442095 1091.812141 C 779.570101 1167.4654 799.224794 1196.738663 833.678724 1195.44307 C 868.149156 1194.06975 874.785527 1136.586223 868.221755 1103.350111 C 861.657982 1070.113999 814.949397 904.396222 814.949397 904.396222 Z M 779.371127 912.601437 "
      transform="matrix(-0.0456011,-0.0102096,-0.00968159,0.0480882,461.629524,195.794382)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:6.3772;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.416636 912.620136 C 779.416636 912.620136 779.249049 1016.236733 779.416636 1091.803745 C 779.500429 1167.450218 799.191894 1196.771172 833.631008 1195.420342 C 868.070123 1194.069512 874.7736 1136.619512 868.23771 1103.325529 C 861.618026 1070.111006 814.945066 904.356235 814.945066 904.356235 Z M 779.416636 912.620136 "
      transform="matrix(-0.0466176,0,0,0.0491596,454.64703,187.014869)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:8.0953;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.479039 255.112397 C 939.080654 253.761567 956.634131 219.672978 956.634131 219.672978 C 956.634131 219.672978 992.146166 148.79414 974.457662 131.07443 C 956.634131 113.354721 779.479039 113.354721 779.479039 113.354721 Z M 779.479039 255.112397 "
      transform="matrix(-0.0289295,0,0,0.0491596,440.858496,219.240028)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:6.3772;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.500429 255.131904 C 885.834338 255.131904 903.514759 219.692485 903.514759 219.692485 C 903.514759 219.692485 938.959395 148.813647 921.278974 131.093937 C 903.514759 113.374228 779.500429 113.374228 779.500429 113.374228 Z M 779.500429 255.131904 "
      transform="matrix(-0.0466176,0,0,0.0491596,454.64703,215.754694)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:6.3772;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.500429 255.151411 C 868.153916 255.151411 921.278974 219.711992 921.278974 219.711992 C 921.278974 219.711992 956.72361 148.833154 938.959395 131.113445 C 921.278974 113.393735 779.500429 113.393735 779.500429 113.393735 Z M 779.500429 255.151411 "
      transform="matrix(-0.0466176,0,0,0.0491596,454.64703,212.26936)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:5.7245;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 832.668754 113.420284 C 832.668754 113.420284 903.563973 24.821736 1027.529329 -63.776811 C 1151.562204 -152.375358 1241.295296 -220.473076 1257.837514 -205.534487 C 1257.837514 -187.814777 1151.562204 -99.21623 1062.976939 -28.337392 C 974.391674 42.541446 868.116363 148.859702 868.116363 148.859702 Z M 832.668754 113.420284 "
      transform="matrix(-0.0578539,0,0,0.0491596,463.696544,187.115711)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:5.7245;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 832.669005 113.400777 C 832.669005 113.400777 902.753994 61.354096 1037.049795 -10.63719 C 1169.32002 -74.523496 1355.943246 -63.796318 1355.943246 -46.076609 C 1338.2532 -28.356899 1178.772716 -28.356899 1072.497405 24.802229 C 971.015962 75.49808 868.116615 148.840195 868.116615 148.840195 Z M 832.669005 113.400777 "
      transform="matrix(-0.0578539,0,0,0.0491596,463.223902,190.601045)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:5.7245;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 832.649223 113.399381 C 832.649223 113.399381 894.361824 78.357265 1037.030013 24.800834 C 1178.752933 -28.358294 1366.929102 7.081125 1366.929102 24.800834 C 1349.239057 42.520544 1196.510498 31.713905 1090.235188 60.240253 C 980.246318 87.971996 868.164352 148.8388 868.164352 148.8388 L 832.716742 113.399381 Z M 832.649223 113.399381 "
      transform="matrix(-0.0578539,0,0,0.0491596,463.179789,196.601114)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:5.7245;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 832.64763 113.351224 C 832.64763 113.351224 903.745408 42.392926 1055.123581 24.832138 C 1207.447025 7.112428 1331.4799 113.351224 1331.4799 131.070934 C 1313.722335 148.790643 1171.931896 53.120104 1065.724105 66.787324 C 953.101984 80.454544 868.027721 148.790643 868.027721 148.790643 Z M 832.64763 113.351224 "
      transform="matrix(-0.0578539,0,0,0.0491596,462.789071,202.689418)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:6.3772;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.500429 255.094997 C 939.126982 253.744167 938.959395 209.643545 938.959395 209.643545 C 938.959395 209.643545 974.404031 138.764707 956.72361 121.044997 C 938.959395 103.325288 779.500429 113.416782 779.500429 113.416782 Z M 779.500429 255.094997 "
      transform="matrix(-0.0466176,0,0,0.0491596,454.64703,207.920571)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:6.3772;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.500429 255.114504 C 939.126982 253.763674 956.72361 219.675085 956.72361 219.675085 C 956.72361 219.675085 992.084453 148.796247 974.404031 131.076537 C 956.72361 113.356828 779.500429 113.356828 779.500429 113.356828 Z M 779.500429 255.114504 "
      transform="matrix(-0.0466176,0,0,0.0491596,454.64703,204.435237)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:6.3772;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.500429 255.106836 C 939.126982 253.756006 974.404031 219.667417 974.404031 219.667417 C 974.404031 219.667417 1009.848668 148.788579 992.084453 131.068869 C 974.404031 113.34916 779.500429 113.34916 779.500429 113.34916 Z M 779.500429 255.106836 "
      transform="matrix(-0.0466176,0,0,0.0491596,454.64703,200.080145)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:6.3772;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.500429 255.099168 C 939.126982 253.748338 992.168246 219.659749 992.168246 219.659749 C 992.168246 219.659749 1009.848668 148.860372 992.168246 131.140662 C 974.404031 113.420953 779.500429 113.420953 779.500429 113.420953 Z M 779.500429 255.099168 "
      transform="matrix(-0.0466176,0,0,0.0491596,454.64703,195.725053)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:5.7245;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 832.695158 113.425124 C 832.695158 113.425124 946.870221 24.826576 946.870221 -46.052261 C 946.870221 -116.931099 896.568375 -187.809937 882.254292 -223.249356 C 910.814938 -223.249356 975.430867 -116.931099 975.430867 -46.052261 C 975.430867 31.421805 868.142768 148.785082 868.142768 148.785082 Z M 832.695158 113.425124 "
      transform="matrix(-0.0578539,0,0,0.0491596,464.830884,183.529536)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:5.7245;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 832.695158 113.425124 C 832.695158 113.425124 818.381076 60.265995 818.381076 -46.052261 C 818.381076 -170.090228 881.376542 -248.915123 904.063012 -276.408484 C 909.937188 -248.994584 846.941721 -152.370518 846.941721 -46.052261 C 846.941721 31.421805 868.142768 148.785082 868.142768 148.785082 Z M 832.695158 113.425124 "
      transform="matrix(-0.0578539,0,0,0.0491596,464.830884,183.529536)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:6.3772;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.500429 255.0915 C 939.126982 253.74067 992.168246 219.652081 992.168246 219.652081 C 992.168246 219.652081 992.168246 148.852704 974.404031 131.132994 C 956.72361 113.413285 779.500429 113.413285 779.500429 113.413285 Z M 779.500429 255.0915 "
      transform="matrix(-0.0466176,0,0,0.0491596,454.64703,191.369961)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:6.3772;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.500429 255.083832 C 939.126982 253.733002 974.404031 219.723874 974.404031 219.723874 C 974.404031 219.723874 974.404031 148.845036 956.72361 131.125326 C 938.959395 113.405617 779.500429 113.405617 779.500429 113.405617 Z M 779.500429 255.083832 "
      transform="matrix(-0.0466176,0,0,0.0491596,454.64703,187.014869)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:16.071;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(99.215686%,76.470588%,0.392157%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 318.864386 1318.107478 C 374.000487 1195.817645 389.753658 981.512459 389.753658 946.07304 C 389.753658 846.270551 356.822826 360.925325 354.309022 166.485285 C 351.879012 -27.875295 478.323352 -134.670316 602.337682 -134.670316 C 708.67159 -134.670316 779.560862 -63.791478 779.560862 42.526779 L 726.352011 42.526779 C 726.352011 -28.352059 673.226954 -81.511187 602.337682 -81.511187 C 496.087567 -81.511187 414.388937 2.240265 407.517873 166.485285 C 399.892668 356.396072 428.801414 849.448974 425.198295 981.512459 C 421.595176 1113.496483 374.000487 1231.177603 318.864386 1318.107478 Z M 318.864386 1318.107478 "
      transform="matrix(0.0466176,0,0,0.0491596,381.96734,187.014869)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(72.54902%,35.294118%,11.764706%);fill-opacity:1;stroke-width:6.3772;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 779.500429 32.912049 C 903.682346 32.991509 957.310164 135.257276 956.97499 133.191301 L 921.278974 166.564745 C 902.257857 184.284455 779.500429 184.284455 781.008712 184.204994 Z M 779.500429 32.912049 "
      transform="matrix(-0.0466176,0,0,0.0491596,454.64703,187.014869)"
    />
  </g>
</svg>
