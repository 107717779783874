<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,83.921569%);fill-opacity:1;"
      d="M 0 356.605469 L 694.285156 0 L 0 0 Z M 0 356.605469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,37.647059%,0%);fill-opacity:1;"
      d="M 720 0 L 19.105469 360 L 720 360 Z M 720 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,80%,0%);fill-opacity:1;"
      d="M 720 0 L 681.429688 0 L 0 341.269531 L 0 360 L 38.574219 360 L 720 19.40625 Z M 720 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 50.203125 6.414062 L 58.539062 30.632812 L 85.585938 30.613281 L 63.691406 45.558594 L 72.070312 69.765625 L 50.199219 54.785156 L 28.328125 69.761719 L 36.707031 45.558594 L 14.816406 30.605469 L 41.863281 30.632812 Z M 50.203125 6.414062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 184.597656 6.414062 L 192.933594 30.632812 L 219.984375 30.613281 L 198.085938 45.558594 L 206.464844 69.765625 L 184.59375 54.785156 L 162.722656 69.761719 L 171.105469 45.558594 L 149.210938 30.605469 L 176.261719 30.632812 Z M 184.597656 6.414062 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 184.597656 108.003906 L 192.933594 132.21875 L 219.984375 132.199219 L 198.085938 147.144531 L 206.464844 171.351562 L 184.59375 156.371094 L 162.722656 171.347656 L 171.105469 147.144531 L 149.210938 132.195312 L 176.261719 132.21875 Z M 184.597656 108.003906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 117.792969 57.800781 L 126.128906 82.019531 L 153.179688 82 L 131.28125 96.945312 L 139.660156 121.148438 L 117.789062 106.167969 L 95.917969 121.144531 L 104.300781 96.945312 L 82.40625 81.992188 L 109.457031 82.019531 Z M 117.792969 57.800781 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 50.203125 108.003906 L 58.539062 132.21875 L 85.585938 132.199219 L 63.691406 147.144531 L 72.070312 171.351562 L 50.199219 156.371094 L 28.328125 171.347656 L 36.707031 147.144531 L 14.816406 132.195312 L 41.863281 132.21875 Z M 50.203125 108.003906 "
    />
  </g>
</svg>
