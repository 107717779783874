<script>
  import styles from "styleguide/ProfileSecurity.json";
  import { onMount } from "svelte";
  import { userPath } from "lib/helpers.js";

  import { t } from "stores/i18n.js";
  import { theme } from "stores/theme.js";
  import { team_user, settingsPage, scimActive } from "stores/team_user.js";
  import { user } from "stores/user.js";
  import { breadcrumb } from "stores/breadcrumb.js";
  import { accountPasswordRules } from "stores/account.js";

  import TeamUsersApi from "apis/users/team_users";
  import AccountApi from "apis/admin/accounts.js";

  import { Button, Dropdown, TextInput, Toast } from "components";

  export let newPassword = "";
  export let newPasswordConfirmation = "";

  let currentTeamUser;

  team_user.subscribe((team_user) => {
    if (team_user) {
      currentTeamUser = team_user;
      breadcrumb.set([
        { translation: "administration", path: userPath + "/account" },
        { translation: "employees", path: userPath + "/team_users" },
        {
          name: currentTeamUser.name
            ? currentTeamUser.name
            : currentTeamUser.email,
          click: () => {
            settingsPage.set("overview");
            window.history.pushState(
              "",
              "",
              userPath + "/team_users/" + currentTeamUser.permalink + "/edit",
            );
          },
        },
        { translation: "personal_security" },
      ]);
    }
  });

  let passwordIsMatching = false;
  let passwordComplete = false;
  let currentPassword;

  $: passwordCriteria = checkPassword(newPassword);
  $: passwordComplete = passwordIsComplete(passwordCriteria);
  $: isDisabled = !passwordIsMatching || !passwordComplete || !currentPassword;
  $: accountPasswordRules ?? {
    min_length: 8,
    min_numeric: 1,
    min_uppercase: 1,
    min_lowercase: 1,
    min_special: 1,
    non_reuse_count: 0,
  };

  onMount(async () => {
    getAccountPasswordRules();
  });

  function getAccountPasswordRules() {
    AccountApi.getCustomPasswordRules();
  }

  function userSuspended() {
    return currentTeamUser.suspended
      ? $t("personal_security.account_inactive")
      : $t("personal_security.account_active");
  }

  function checkPassword(newPassword) {
    const numbers = newPassword.match(/\d/g) || [];
    const lowerCases = newPassword.match(/[a-z]/g) || [];
    const upperCases = newPassword.match(/[A-Z]/g) || [];
    const specialChars = newPassword.match(/[$-/:-?{-~!"^_`\[\]]/g) || [];

    passwordCriteria = {
      length: {
        condition: newPassword.length >= $accountPasswordRules.min_length,
      },
      numeric: {
        condition: numbers.length >= $accountPasswordRules.min_numeric,
      },
      lowercase: {
        condition: lowerCases.length >= $accountPasswordRules.min_lowercase,
      },
      uppercase: {
        condition: upperCases.length >= $accountPasswordRules.min_uppercase,
      },
      special: {
        condition: specialChars.length >= $accountPasswordRules.min_special,
      },
    };
    return passwordCriteria;
  }

  function passwordMatching(newPasswordConfirmation) {
    return (passwordIsMatching = newPassword === newPasswordConfirmation);
  }

  function passwordIsComplete(passwordCriteria) {
    return Object.keys(passwordCriteria).every((item) => item);
  }

  function updateTeamUser() {
    TeamUsersApi.updateTeamUser(currentTeamUser);
  }

  function updateTeamUserPassword() {
    currentTeamUser.current_password = currentPassword;
    currentTeamUser.new_password = newPassword;
    currentTeamUser.new_password_confirmation = newPasswordConfirmation;
    TeamUsersApi.changePassword(currentTeamUser);
  }

  $: accessTypes = {
    false: $t("personal_security.account_active"),
    true: $t("personal_security.account_inactive"),
  };
</script>

<div class={styles.content}>
  {#if $scimActive}
    <Toast message={$t("profile_settings.scim_active")} type="info" />
  {/if}

  {#if currentTeamUser.permalink === $user.permalink}
    <div class={styles["first-section"]}>
      <div class={styles["section-header"]}>
        {$t("personal_security.two_factor_header")}
      </div>
      <div class={styles["section-description"]}>
        {$t("personal_security.two_factor_desc_" + $user.verifyEnabled)}
      </div>
      <div class={styles["disable-button"]}>
        <Button
          link={userPath + "/2fa-verification/new"}
          style={"primary-text"}
        >
          {$t("personal_security.two_factor_button_" + $user.verifyEnabled)}
        </Button>
      </div>
    </div>
  {/if}
  <div class={styles.section}>
    <div class={styles["section-header"]}>
      {$t("personal_security.access_header")}
    </div>
    <div class={styles["dropdown-section"]}>
      <Dropdown
        click={updateTeamUser}
        items={accessTypes}
        initial={userSuspended()}
        placement="bottom"
        bind:selected={currentTeamUser.suspended}
        footnotes={[$t("personal_security.access_desc")]}
        disabled={$scimActive}
        lock={$scimActive}
      />
    </div>
  </div>
  {#if currentTeamUser.permalink === $user.permalink}
    {#if $theme !== "eyde"}
      <div class={styles["last-section"]}>
        <div class={styles["section-header"]}>
          {$t("personal_security.password_header")}
        </div>
        <div class={styles["section-description"]}>
          {$t("personal_security.password_desc")}
        </div>
        <div class={styles["password-list"]}>
          <div class={styles["password-requiments-description"]}>
            {$t("personal_security.password_desc_ul")}
          </div>
          <ul class={styles["password-requiments"]}>
            {#each Object.entries(passwordCriteria) as [title, criteria]}
              <li
                class={`${styles["password-requiment"]} {criteria.condition ? styles.valid : ""}`}
              >
                {$t(`personal_security.password_criteria_min_${title}`, {
                  postProcess: "interval",
                  count: $accountPasswordRules[`min_${title}`],
                })}
              </li>
            {/each}
            <li
              class={`${styles["password-requiment"]} ${passwordIsMatching ? styles.valid : ""}`}
            >
              {$t("personal_security.password_criteria_matching")}
            </li>
          </ul>
          {#if $accountPasswordRules.non_reuse_count > 0}
            <p>
              {$t("personal_security.password_non_reuse_count", {
                postProcess: "interval",
                count: $accountPasswordRules.non_reuse_count,
              })}
            </p>
          {/if}
        </div>
        <div class={styles["input-section"]}>
          <div class={styles[("input", "half")]}>
            <TextInput
              label={$t("personal_security.password_old")}
              style={"border medium round-border"}
              bind:value={currentPassword}
              type={"password"}
              footnotes={[$t("personal_security.password_desc_help")]}
            />
          </div>
        </div>
        <div class={styles["input-section"]}>
          <div class={styles.input}>
            <TextInput
              label={$t("personal_security.password_new")}
              style={"border medium round-border"}
              bind:value={newPassword}
              type={"password"}
              footnotes={[$t("personal_security.password_desc_help")]}
            />
          </div>
          <div class={styles.input}>
            <TextInput
              label={$t("personal_security.password_confirm")}
              style={"border medium round-border"}
              bind:value={newPasswordConfirmation}
              onKeyup={passwordMatching}
              type={"password"}
              footnotes={[$t("personal_security.password_desc_help")]}
            />
          </div>
        </div>
      </div>
      <div class={styles["save-button"]}>
        <Button
          click={updateTeamUserPassword}
          style={"primary"}
          disabled={isDisabled}
        >
          {$t("personal_security.save")}
        </Button>
      </div>
    {/if}
  {/if}
</div>

<style scoped lang="scss">
  .content {
    width: 679px;
    top: 24px;
    padding: 24px;

    background: var(--white);
    box-shadow:
      0 6px 8px -6px rgba(24, 39, 75, 0.12),
      0 8px 16px -6px rgba(24, 39, 75, 0.08);
    border-radius: 8px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
  }

  .first-section {
    padding: 0 0 24px 0;
    border-bottom: 1px solid #eaecf1;
  }

  .section {
    padding: 24px 0;
  }

  .last-section {
    border-top: 1px solid #eaecf1;
    padding: 24px 0 12px 0;
  }

  .section-header {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-500);
    padding-bottom: 8px;
  }

  .section-description {
    font-size: 14px;
    line-height: 22px;
    color: var(--primary-500);
  }

  .dropdown-section {
    width: 300px;
    padding: 16px 0 4px 0;
  }

  .dropdown-info {
    font-size: 12px;
    line-height: 18px;
    color: var(--primary-300);
    padding-top: 4px;
  }

  .disable-button {
    padding-top: 24px;
  }

  .input-section {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    padding-bottom: 24px;

    .half {
      width: 49%;
    }
  }

  .input {
    width: -webkit-fill-available;
  }

  .password-requiments-description {
    padding-top: 16px;
  }

  .password-requiments {
    list-style-type: disc;
    padding: 8px 0 16px 0;
  }

  .password-requiment {
    font-size: 14px;
    line-height: 22px;
    color: var(--primary-500);
    margin-left: 20px;
    padding-left: 4px;

    &::marker {
      color: var(--blue);
    }

    &.valid {
      background: url(/assets/svg_icons/checkmark_green_round.svg) no-repeat
        left center;
      list-style: none;
      margin: 0;
      padding-left: 24px;
      text-decoration-line: line-through;
      color: var(--primary-100);
    }
  }

  .save-button {
    padding-bottom: 24px;
  }
</style>
