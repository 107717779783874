<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(92.156863%,0%,7.45098%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(4.313725%,31.372549%,20.784314%);fill-opacity:1;"
      d="M 278.257812 233.78125 L 153.195312 321.238281 L 185.421875 222.5625 L 202.160156 234.683594 L 190.148438 270.730469 L 271.710938 211.957031 L 278.257812 233.785156 Z M 291.085938 176.664062 L 334.742188 323.378906 L 252.765625 262.203125 L 269.628906 250.253906 L 298.800781 272.144531 L 268.3125 175.84375 L 291.089844 176.664062 Z M 239.335938 147.53125 L 392.996094 147.714844 L 309.257812 208.109375 L 302.558594 188.558594 L 333.695312 167.714844 L 232.117188 169.148438 L 239.339844 147.53125 Z M 195.890625 186.757812 L 244.550781 39.824219 L 278.035156 140.84375 L 257.367188 141.105469 L 245.035156 102.410156 L 214.167969 200.367188 L 195.890625 186.753906 Z M 220.824219 239.5625 L 96.859375 148.574219 L 201.503906 147.765625 L 195.402344 167.507812 L 156.5625 167.351562 L 239.398438 226.351562 L 220.828125 239.5625 Z M 220.824219 239.5625 "
    />
  </g>
</svg>
