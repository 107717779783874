<script>
  import styles from "styleguide/DashboardStatusOverview.json";
  import { filterRemove, prevStatusLabel } from "stores/dashboard/project.js";

  import { Tag } from "components";

  export let click;
  export let count;
  export let label;
  export let color;
  export let selected;
  export let size = "medium";

  function onClick() {
    if (click) {
      click();
    }

    if (label === $prevStatusLabel) {
      filterRemove();
      return;
    }

    prevStatusLabel.set(label);
  }
</script>

<div
  data-component="DashboardStatusOverview"
  class={styles.wrapper}
  on:click={onClick}
>
  <Tag
    label={count}
    style={`count ${selected || selected !== undefined ? "selected" : ""}`}
    {color}
    {size}
  />
  <div class={styles.label}>{label}</div>
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .label {
    margin-left: 15px;
    font-weight: 500;
    color: var(--blue-200);
  }
</style>
