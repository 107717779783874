<script>
  import { onMount } from "svelte";
  import clsx from "clsx";
  import { t } from "stores/i18n.js";
  import { dateTimeFormat } from "lib/helpers.js";
  import { sanitizeRelaxed } from "lib/sanitize.js";
  import { Pagination } from "components";
  import styles from "styleguide/Activities.json";

  export let getActivities;
  export let activitiesStore;
  export let style = "";

  let scrollContainer;

  $: activitiesPresent = $activitiesStore?.activities?.length > 0;

  onMount(() => {
    if ($activitiesStore.loaded) return;
    getActivities($activitiesStore.page);
  });
</script>

<div
  data-component="Activities"
  class={clsx(
    styles.wrapper,
    style.split(" ").map((x) => styles[x]),
  )}
  bind:this={scrollContainer}
>
  <div class={styles.item}>
    {#if activitiesPresent}
      {#each $activitiesStore.activities as activity}
        <div class={styles.item}>
          <div class={styles.meta}>
            {dateTimeFormat(activity.created_at)} –
            <span class={styles.user}>{activity.user}</span>
          </div>
          <div class={styles.message}>
            {@html sanitizeRelaxed(activity.message)}
          </div>
        </div>
      {/each}
    {:else if $activitiesStore.loaded}
      <div class={styles.empty}>{$t("activities.empty")}</div>
    {:else}
      <div class={styles.empty}>{$t("activities.loading")}</div>
    {/if}

    <Pagination
      currentPage={$activitiesStore.page}
      pagesNumber={$activitiesStore.pages_num}
      click={getActivities}
      {scrollContainer}
    />
  </div>
</div>

<style global lang="scss">
  .wrapper {
    padding: 15px;
    overflow-y: auto;

    &.max-height {
      max-height: 430px;
    }
    &.no-padding {
      padding: 15px 0;
    }
  }

  .item {
    margin: 15px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .meta {
    font-weight: 500;
    color: var(--primary-300);
  }

  .message {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .message b {
    font-weight: 700;
  }

  .message i {
    font-style: italic;
  }
</style>
