<script>
  import { t, currentLanguage } from "stores/i18n";
  import { user, isUserTeamUser } from "stores/user";
  import { dateTimeFormat, platformLink } from "lib/helpers";

  import { AnnouncementBanner } from "components";

  export let two_fa_enforcement_date;
  let hasToActivate2fa =
    !$user.verifyEnabled &&
    two_fa_enforcement_date &&
    new Date(two_fa_enforcement_date) > new Date();
</script>

{#if hasToActivate2fa}
  <AnnouncementBanner title={$t("two_fa_banner.title")}>
    <div>
      {@html $t("two_fa_banner.message", {
        date: dateTimeFormat(two_fa_enforcement_date, $currentLanguage, {
          month: "long",
          day: "numeric",
        }),
        link: $isUserTeamUser
          ? platformLink(`team_users/${$user.permalink}/edit/security`)
          : platformLink("/2fa-verification/new"),
      })}
    </div>
  </AnnouncementBanner>
{/if}
