<script>
  import styles from "styleguide/RespondQrcode.json";

  import { t } from "stores/i18n.js";
  import { pin, sender, recipient, auditor } from "stores/responder.js";

  import { imagePath } from "lib/helpers.js";
  import { validate } from "lib/validate.js";

  import SampleResponseSubmissions from "apis/responder/general_method.js";

  import { Button, TextInput } from "components";

  export let setStatus;

  let dirty = false;
  let pincode;
  let wrongPincode = false;

  $: errors = validate(pincode, ["required"]);

  function onSubmit() {
    wrongPincode = false;
    if (!pincode) {
      dirty = true;
      return;
    }

    let formData = new FormData();

    formData.append("pin", pincode.toUpperCase().trim().replaceAll(" ", ""));

    SampleResponseSubmissions.pinSubmit({
      body: formData,
      success: onSuccess,
      error: onError,
    });
  }

  function onSuccess(response) {
    if (response.code === "success") {
      if (response.data && response.data.redirect_to) {
        window.location = response.data.redirect_to;
      } else {
        pin.set(pincode);
        sender.set({ name: response.sender });
        auditor.set({ name: response.auditor });
        recipient.set({ name: response.recipient });
        setStatus("upload");
      }
    }
  }

  function onError() {
    wrongPincode = true;
  }
</script>

<div data-component="RespondQrcode" class={styles.section}>
  <div class={styles.block}>
    <div class={styles.inner}>
      <p class={styles.title}>{$t("responder_qrcode.title")}</p>
      <form class={styles.form} on:submit|preventDefault={onSubmit}>
        <div class={styles.input}>
          <TextInput
            bind:value={pincode}
            invalid={wrongPincode}
            style="border medium round-border fullsize"
            placeholder={$t("responder_qrcode.enter_pin_placeholder")}
            errors={dirty ? errors : []}
          />
        </div>
        <Button style="primary">{$t("responder_qrcode.submit")}</Button>
      </form>
      <p class={styles.description}>
        {#if wrongPincode}
          <span class={styles.error}>{$t("responder_qrcode.wrong_pin")}</span>
        {/if}

        {$t("responder_qrcode.description")}
      </p>
    </div>
    <div class={styles.qrcode}>
      <div class={styles.location}>{$t("responder_qrcode.pin_location")}</div>
      <img
        src={imagePath("pin_location")}
        class={styles.datev}
        alt="DATEV eG"
      />
    </div>
  </div>
</div>

<style lang="scss">
  .section {
    padding: 25px 0 0;
  }

  .block {
    display: flex;
    justify-content: space-between;
  }

  .inner {
    flex: 1;
  }

  .title {
    font-weight: 600;
    margin: 0 0 15px;
    color: var(--primary-500);
  }

  .description {
    margin: 15px 0 0;
    line-height: 22px;
    color: var(--primary-300);
  }

  .no_pin_description {
    margin: 10px 0 0;
    line-height: 22px;

    a {
      color: var(--primary-500);
    }
  }

  .error {
    color: var(--red-200);
  }

  .qrcode {
    width: 240px;
    margin-left: 60px;

    img {
      display: block;
      max-width: 100%;
    }
  }

  .location {
    font-size: 12px;
    font-weight: 500;
    margin: 0 0 15px;
    color: var(--primary-300);
  }

  .form {
    display: flex;
  }

  .input {
    flex: 1;
    margin-right: 10px;
  }
</style>
