<script>
  import styles from "styleguide/QuickshareWidget.json";
  import { getContext, onMount } from "svelte";

  import {
    currentUser,
    showWidget,
    addedFiles,
    showUpload,
    editUpload,
    uploadedFiles,
  } from "stores/quickshare.js";

  import {
    QuickshareEdit,
    QuickshareIndex,
    QuickshareIntro,
    QuickshareShow,
    QuickshareUploader,
  } from "components/quickshare";

  const api = getContext("oksend");

  $: activeView = getActiveView(
    $currentUser,
    $addedFiles,
    $showUpload,
    $editUpload,
  );

  onMount(async () => {
    document.body.classList.add("quickshare-active");
  });

  export function show() {
    showWidget.set(true);
    document.body.classList.add("quickshare-active");
  }

  export function hide() {
    showWidget.set(false);
    addedFiles.set([]);
    uploadedFiles.set([]);
    document.body.classList.remove("quickshare-active");
  }

  function getActiveView(user, files, showId, editId) {
    if ($showWidget == false) {
      return;
    } // important: do nothing

    if (!user.authenticated && api.auth.authenticated()) {
      currentUser.set(Object.assign({}, $currentUser, { authenticated: true }));
    }

    if (!user.authenticated && user.token) {
      console.log("Authenticating...");
      api.auth.authenticate(
        $currentUser.platform,
        $currentUser.email,
        $currentUser.token,
      );
    }

    if (!user.authenticated && !user.token) {
      return QuickshareIntro;
    }
    if (editId) {
      return QuickshareEdit;
    }
    if (showId) {
      return QuickshareShow;
    }
    if (files.length > 0) {
      return QuickshareUploader;
    }
    return QuickshareIndex;
  }
</script>

<div data-component="QuickshareWidget" class={styles.widgetContainer}>
  <div class={styles.backdrop} on:click={hide}></div>
  <div class={styles.widget}>
    <svelte:component this={activeView} />
  </div>
</div>

<style lang="scss">
  .widgetContainer {
    all: initial;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: var(--z-index-modal);
    .widget {
      position: relative;
    }
  }

  .backdrop {
    all: initial;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparentize(#142a4e, 0.8);
    z-index: 0;
  }

  .widget {
    all: initial;
    font-size: 14px;
    line-height: 24px;
    font-family: var(--default-font);
    color: var(--primary-500);
    z-index: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: #fff;
    border-radius: var(--border-radius);
    width: 440px;
    height: 550px;
    // height: calc(100vh - 80px);
    box-shadow:
      0 1px 5px 0 rgba(0, 0, 0, 0.15),
      0 1px 35px 0 rgba(0, 0, 0, 0.1);
  }
</style>
