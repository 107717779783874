<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(32.54902%,52.941176%,95.686275%);fill-opacity:1;"
      d="M -59.570312 239.144531 L 539.574219 239.144531 L 539.574219 360 L -59.570312 360 Z M -59.570312 239.144531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(32.54902%,52.941176%,95.686275%);fill-opacity:1;"
      d="M -59.570312 0 L 539.574219 0 L 539.574219 120.855469 L -59.570312 120.855469 Z M -59.570312 0 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M -59.570312 120.855469 L 539.574219 120.855469 L 539.574219 239.136719 L -59.570312 239.136719 Z M -59.570312 120.855469 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,82.352941%,0%);fill-opacity:1;"
      d="M 172.511719 220.875 L 239.433594 105.535156 L 306.351562 220.875 Z M 172.511719 220.875 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,100%,100%);fill-opacity:1;"
      d="M 179.261719 217.769531 L 239.566406 113.253906 L 299.871094 217.769531 Z M 179.261719 217.769531 "
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,82.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 462.727234 556.660385 L 461.545218 448.337419 "
      transform="matrix(0.340387,0,0,0.340597,82.794215,-26.11276)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,82.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 468.614367 563.725175 L 507.471735 509.569427 "
      transform="matrix(0.340387,0,0,0.340597,82.794215,-26.11276)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,82.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 474.512975 569.608678 L 536.907397 543.712092 "
      transform="matrix(0.340387,0,0,0.340597,82.794215,-26.11276)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,82.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 479.21809 577.854756 L 552.216236 580.217332 "
      transform="matrix(0.340387,0,0,0.340597,82.794215,-26.11276)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,82.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 474.512975 587.282154 L 516.893442 624.957214 "
      transform="matrix(0.340387,0,0,0.340597,82.794215,-26.112771)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,82.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 462.727234 583.738259 L 462.727234 617.880924 "
      transform="matrix(0.340387,0,0,0.340597,82.794215,-26.11276)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,82.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 449.782429 575.503649 L 394.44568 623.775895 "
      transform="matrix(0.340387,0,0,0.340597,82.794215,-26.11276)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,82.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 455.669561 579.024576 L 430.939015 624.945714 "
      transform="matrix(0.340387,0,0,0.340597,82.794215,-26.11276)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,82.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 446.247854 569.608678 L 354.406295 601.400235 "
      transform="matrix(0.340387,0,0,0.340597,82.794215,-26.11276)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,82.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 445.065837 564.894995 L 380.307382 562.543887 "
      transform="matrix(0.340387,0,0,0.340597,82.794215,-26.11276)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,82.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 393.263663 537.81712 L 449.782429 556.660385 "
      transform="matrix(0.340387,0,0,0.340597,82.794215,-26.11276)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,82.352941%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 420.335291 489.544875 L 454.487544 555.490566 "
      transform="matrix(0.340387,0,0,0.340597,82.794215,-26.11276)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(76.862745%,32.54902%,84.313725%);fill-opacity:1;"
      d="M 239.585938 157.207031 C 227.527344 157.207031 218.738281 159.421875 211.320312 162.023438 L 213.207031 158.761719 L 216.972656 152.222656 L 218.859375 148.960938 L 220.742188 145.695312 L 222.628906 142.433594 L 224.714844 139.105469 C 227.527344 136.632812 233.558594 135.476562 239.585938 135.476562 C 245.617188 135.476562 251.648438 136.699219 254.664062 139.164062 L 256.550781 142.433594 L 258.433594 145.695312 L 260.320312 148.960938 L 262.203125 152.222656 L 264.085938 155.492188 L 265.972656 158.761719 L 267.855469 162.023438 C 260.035156 159.421875 251.648438 157.207031 239.585938 157.207031 Z M 239.585938 157.207031 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(31.372549%,0%,75.686275%);fill-opacity:1;"
      d="M 239.589844 154.90625 C 227.492188 154.886719 213.964844 158.515625 213.210938 158.761719 L 215.09375 155.496094 L 216.976562 152.226562 L 218.863281 148.964844 L 220.746094 145.695312 L 222.632812 142.433594 L 224.71875 139.105469 C 227.53125 136.636719 233.5625 135.480469 239.589844 135.480469 C 245.621094 135.480469 251.652344 136.699219 254.667969 139.167969 L 256.554688 142.433594 L 258.4375 145.695312 L 260.324219 148.964844 L 262.207031 152.226562 L 264.089844 155.496094 L 265.976562 158.761719 C 265.976562 158.761719 251.652344 154.90625 239.589844 154.90625 Z M 239.589844 154.90625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,32.54902%,84.313725%);fill-opacity:1;"
      d="M 239.585938 152.292969 C 230.652344 152.257812 216.207031 154.726562 215.1875 155.191406 L 216.972656 152.222656 L 218.859375 148.960938 L 220.742188 145.691406 L 222.628906 142.429688 L 224.714844 139.101562 C 227.527344 136.628906 233.558594 135.472656 239.585938 135.472656 C 245.617188 135.472656 251.648438 136.695312 254.664062 139.160156 L 256.550781 142.429688 L 258.433594 145.691406 L 260.320312 148.960938 L 262.203125 152.222656 L 264.085938 155.492188 C 264.085938 155.492188 251.648438 152.292969 239.585938 152.292969 Z M 239.585938 152.292969 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,62.352941%,62.352941%);fill-opacity:1;"
      d="M 239.582031 149.585938 C 227.734375 149.5625 217.894531 151.84375 216.96875 152.222656 L 218.855469 148.957031 L 220.738281 145.691406 L 222.621094 142.429688 L 224.707031 139.101562 C 227.519531 136.628906 233.550781 135.472656 239.582031 135.472656 C 245.613281 135.472656 251.644531 136.695312 254.660156 139.160156 L 256.546875 142.429688 L 258.425781 145.691406 L 262.199219 152.222656 C 262.199219 152.222656 251.644531 149.585938 239.582031 149.585938 Z M 239.582031 149.585938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;"
      d="M 239.585938 147.28125 C 227.46875 147.238281 219.855469 148.800781 218.859375 148.960938 L 220.742188 145.691406 L 222.628906 142.429688 L 224.714844 139.101562 C 227.527344 136.632812 233.558594 135.472656 239.585938 135.472656 C 245.617188 135.472656 251.648438 136.695312 254.664062 139.160156 L 256.550781 142.429688 L 258.433594 145.691406 L 260.320312 148.960938 C 260.320312 148.960938 251.648438 147.28125 239.585938 147.28125 Z M 239.585938 147.28125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,33.333333%,0%);fill-opacity:1;"
      d="M 239.484375 144.269531 C 228.925781 144.042969 221.175781 145.71875 220.738281 145.6875 L 222.625 142.425781 L 224.710938 139.097656 C 227.523438 136.628906 233.554688 135.472656 239.582031 135.472656 C 245.613281 135.472656 251.644531 136.691406 254.660156 139.160156 L 256.546875 142.425781 L 258.429688 145.6875 C 258.429688 145.6875 251.542969 144.269531 239.484375 144.269531 Z M 239.484375 144.269531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,4.313725%,0%);fill-opacity:1;"
      d="M 239.875 140.765625 C 229.28125 140.855469 223.792969 142.246094 222.617188 142.433594 L 224.703125 139.105469 C 227.515625 136.632812 233.546875 135.476562 239.574219 135.476562 C 245.605469 135.476562 251.636719 136.699219 254.652344 139.164062 L 256.539062 142.433594 C 256.539062 142.433594 251.9375 140.765625 239.875 140.765625 Z M 239.875 140.765625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(26.27451%,38.823529%,100%);fill-opacity:1;"
      d="M 179.261719 217.769531 L 196.222656 188.371094 L 282.90625 188.371094 L 299.871094 217.769531 Z M 179.261719 217.769531 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(42.745098%,65.490196%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 334.690637 674.378192 C 334.690637 674.378192 362.99116 669.389257 381.312336 654.399515 C 399.633513 639.409773 399.633513 614.430692 407.951612 614.430692 C 416.281577 614.430692 417.942824 642.747198 437.925247 652.736537 C 457.90767 662.725875 489.554418 676.04117 489.554418 676.04117 Z M 334.690637 674.378192 "
      transform="matrix(0.329196,0,0,0.340597,128.231485,-28.097776)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,74.117647%,0%);fill-opacity:1;"
      d="M 275.683594 197.621094 C 260.882812 190.816406 269.65625 194.21875 265.269531 191.382812 C 263.078125 185.714844 259.789062 181.175781 262.527344 181.175781 C 265.269531 181.175781 265.816406 190.816406 272.394531 194.21875 C 278.972656 197.621094 281.714844 198.472656 281.714844 198.472656 Z M 275.683594 197.621094 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(42.745098%,65.490196%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 334.687661 674.378192 C 334.687661 674.378192 363.000049 669.389257 381.30936 654.399515 C 399.630536 639.409773 399.630536 614.430692 407.960501 614.430692 C 416.2786 614.430692 417.951713 642.747198 437.934136 652.736537 C 457.904694 662.725875 489.551441 676.04117 489.551441 676.04117 Z M 334.687661 674.378192 "
      transform="matrix(0.329196,0,0,0.340597,115.896527,-28.097776)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,74.117647%,0%);fill-opacity:1;"
      d="M 263.351562 197.621094 C 248.550781 190.816406 257.320312 194.21875 252.933594 191.382812 C 250.742188 185.714844 247.453125 181.175781 250.195312 181.175781 C 252.933594 181.175781 253.484375 190.816406 260.0625 194.21875 C 266.636719 197.621094 269.378906 198.472656 269.378906 198.472656 Z M 263.351562 197.621094 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(42.745098%,65.490196%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 334.68755 674.378262 C 334.68755 674.378262 362.999939 669.389327 381.309249 654.399585 C 399.630426 639.409843 399.630426 614.430762 407.960391 614.430762 C 416.27849 614.430762 417.951603 642.747268 437.934026 652.736607 C 457.904583 662.725945 489.551331 676.04124 489.551331 676.04124 Z M 334.68755 674.378262 "
      transform="matrix(0.329196,0,0,0.340597,103.564532,-28.0978)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,74.117647%,0%);fill-opacity:1;"
      d="M 251.019531 197.621094 C 236.21875 190.816406 244.988281 194.21875 240.601562 191.382812 C 238.410156 185.714844 235.121094 181.175781 237.863281 181.175781 C 240.601562 181.175781 241.152344 190.816406 247.730469 194.21875 C 254.304688 197.621094 257.046875 198.472656 257.046875 198.472656 Z M 251.019531 197.621094 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(42.745098%,65.490196%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 334.691834 674.385028 C 334.691834 674.385028 362.992356 669.384624 381.313533 654.394882 C 399.634709 639.416608 399.634709 614.437527 407.952808 614.437527 C 416.282773 614.437527 417.94402 642.742565 437.926443 652.731903 C 457.908867 662.721242 489.543748 676.048006 489.543748 676.048006 Z M 334.691834 674.385028 "
      transform="matrix(0.329196,0,0,0.340597,89.58656,-28.381354)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,74.117647%,0%);fill-opacity:1;"
      d="M 237.039062 197.339844 C 222.238281 190.535156 231.011719 193.9375 226.625 191.101562 C 224.433594 185.429688 221.144531 180.890625 223.882812 180.890625 C 226.625 180.890625 227.171875 190.535156 233.75 193.9375 C 240.328125 197.339844 243.070312 198.191406 243.070312 198.191406 Z M 237.039062 197.339844 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(42.745098%,65.490196%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 334.694952 674.384997 C 334.694952 674.384997 362.995475 669.384594 381.316651 654.394851 C 399.625962 639.416578 399.625962 614.437497 407.955927 614.437497 C 416.285892 614.437497 417.947139 642.742534 437.929562 652.731873 C 457.911985 662.721212 489.546867 676.047976 489.546867 676.047976 L 334.683086 674.384997 Z M 334.694952 674.384997 "
      transform="matrix(0.329196,0,0,0.340597,79.171471,-28.381344)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,74.117647%,0%);fill-opacity:1;"
      d="M 226.625 197.339844 C 211.824219 190.535156 220.59375 193.9375 216.210938 191.101562 C 214.015625 185.429688 210.726562 180.890625 213.46875 180.890625 C 216.210938 180.890625 216.757812 190.535156 223.335938 193.9375 C 229.914062 197.339844 232.65625 198.191406 232.65625 198.191406 Z M 226.625 197.339844 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 438.003152 565.49172 C 438.412755 565.49172 438.737024 563.625873 439.7781 561.957586 C 441.996785 560.355152 444.352004 559.081985 447.424029 558.423451 C 449.096576 557.589307 452.509937 557.83077 455.087025 557.83077 C 458.688121 558.160037 458.927057 559.696617 460.975073 561.957586 C 461.879614 563.801482 464.012965 566.347815 465.088174 569.025855 C 466.265784 570.45268 467.204458 572.647794 468.040731 574.316081 C 468.894072 576.116076 469.798613 578.838018 470.976222 581.384351 C 471.590627 584.216049 472.153832 586.411164 472.153832 589.616031 C 472.153832 592.974557 471.607694 594.401381 470.976222 596.6843 C 469.184207 597.298932 465.685512 597.584297 463.910564 596.6843 C 462.016149 594.994062 458.756389 596.069668 457.442245 594.335528 C 454.04595 593.852602 453.226743 589.879445 451.554196 587.859939 C 450.376587 586.125799 450.393653 584.830681 449.198977 583.162394 C 447.338695 581.823374 447.082693 580.198989 446.246419 577.850216 C 446.980292 575.325834 448.260303 574.777056 450.376587 573.7234 C 451.502996 572.077064 452.134468 571.41853 452.134468 568.433174 C 451.707797 565.0966 450.513121 565.447818 447.424029 564.899039 C 443.618131 564.899039 441.655448 565.29416 438.003152 565.49172 Z M 438.003152 565.49172 "
      transform="matrix(0.22888,0,0,0.177952,135.843607,65.4126)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 314.378618 690.892217 L 315.552069 690.892217 C 312.373499 690.892217 313.991266 690.812038 320.268657 692.07085 C 325.702987 692.712283 330.806927 693.241465 336.753929 693.241465 "
      transform="matrix(0.342872,0,0,0.487191,78.821552,-130.502488)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 308.48858 708.547651 L 308.48858 707.369019 C 308.48858 711.065274 308.180976 708.796206 314.378618 703.841139 C 319.642057 702.927098 322.125671 704.779234 328.505597 705.019772 C 331.889236 706.71155 338.029915 706.158315 341.459125 705.019772 C 343.213604 704.266088 343.23639 703.41619 344.990869 702.662507 "
      transform="matrix(0.342872,0,0,0.487191,78.821552,-130.502488)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 321.442108 710.912934 L 321.442108 709.734302 C 322.228206 710.047 326.694154 709.734302 329.679047 709.734302 C 335.842511 709.734302 340.60467 709.437639 343.806026 712.091567 L 346.16432 712.091567 "
      transform="matrix(0.342872,0,0,0.487191,78.821552,-130.502488)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 366.181337 686.177687 L 372.071376 686.177687 C 375.819582 684.926894 381.265304 684.934912 385.013511 686.177687 C 386.505958 691.060594 388.408543 691.958599 394.435294 692.07085 C 396.417628 690.587537 400.484831 689.537191 402.683626 688.534952 "
      transform="matrix(0.342872,0,0,0.487191,78.821552,-130.502488)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 369.713082 694.420097 L 386.198354 694.420097 "
      transform="matrix(0.342872,0,0,0.487191,78.821552,-130.502488)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 380.308315 705.019772 L 380.308315 703.841139 C 380.308315 707.529377 380.000712 705.268327 386.198354 700.31326 C 390.892157 698.004102 393.352985 696.713219 400.325332 697.955995 C 405.338131 700.633976 407.115396 702.18945 413.27886 702.662507 C 418.884081 702.662507 421.344909 701.764501 426.232388 701.491892 "
      transform="matrix(0.342872,0,0,0.487191,78.821552,-130.502488)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 395.620137 710.912934 L 396.793588 710.912934 C 393.50109 710.912934 395.25557 711.057256 401.510176 708.555669 C 407.366036 710.022946 411.307919 712.011388 417.995448 712.091567 C 421.812011 711.899137 424.56905 710.912934 428.590682 710.912934 "
      transform="matrix(0.342872,0,0,0.487191,78.821552,-130.502488)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 438.012465 686.177687 L 443.902504 686.177687 C 449.65583 686.177687 455.272443 686.081473 460.376384 687.35632 L 476.861656 687.35632 C 478.433852 687.35632 478.046499 687.749197 478.046499 686.177687 "
      transform="matrix(0.342872,0,0,0.487191,78.821552,-130.502488)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 433.295877 695.59873 L 434.480721 695.59873 C 431.188223 695.59873 432.93131 695.743052 439.185916 693.249482 C 444.130358 693.626324 449.712793 694.3319 453.312894 696.777362 C 458.485191 699.04643 460.524489 700.994782 467.451266 701.491892 C 471.393148 701.283426 471.666574 699.73597 475.688205 699.134627 "
      transform="matrix(0.342872,0,0,0.487191,78.821552,-130.502488)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 450.9546 707.369019 C 452.811615 706.446959 458.997864 704.089694 463.908128 706.190386 C 469.012069 707.465234 474.640075 707.369019 480.393401 707.369019 C 484.061858 705.797509 490.2595 704.466536 493.346928 702.662507 L 496.878673 702.662507 "
      transform="matrix(0.342872,0,0,0.487191,78.821552,-130.502488)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 500.410418 692.07085 C 502.780104 692.07085 512.862666 691.509596 518.069141 693.249482 C 524.437674 692.479763 526.693433 689.368815 531.022669 686.185705 C 535.374689 686.185705 539.031754 686.289938 542.791353 687.35632 "
      transform="matrix(0.342872,0,0,0.487191,78.821552,-130.502488)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 522.774336 706.198404 C 522.876871 706.190386 527.490924 705.276345 531.022669 705.019772 C 536.331678 701.042889 537.254489 705.107969 542.791353 706.198404 C 548.943424 704.811306 552.042245 701.780537 554.57143 697.955995 "
      transform="matrix(0.342872,0,0,0.487191,78.821552,-130.502488)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 535.727864 697.947977 L 536.901314 697.947977 C 533.437926 697.947977 535.397475 698.148424 541.617902 694.420097 C 545.753461 692.343459 546.243349 690.250785 552.213136 689.705567 C 555.505634 687.236051 557.943677 689.400887 560.461469 690.8842 "
      transform="matrix(0.342872,0,0,0.487191,78.821552,-130.502488)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 560.461469 707.369019 C 560.655145 706.438941 562.796977 701.34757 563.993213 699.126609 C 569.245259 697.025917 571.751659 696.071786 575.761898 701.483874 C 580.03417 702.646471 584.044409 704.971664 588.715426 706.190386 C 590.44712 706.190386 589.615451 706.471013 591.07372 705.011754 "
      transform="matrix(0.342872,0,0,0.487191,78.821552,-130.502488)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 576.935349 690.892217 L 576.935349 689.713585 C 576.935349 693.401823 576.696101 691.196898 581.651937 684.999055 C 587.109052 686.001293 588.544535 689.304672 591.07372 693.241465 C 596.918187 693.241465 600.768928 693.10516 604.015855 690.892217 "
      transform="matrix(0.342872,0,0,0.487191,78.821552,-130.502488)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 605.200698 703.841139 C 605.91844 702.798811 610.612242 695.125673 614.622481 699.126609 C 619.840349 700.553797 620.660625 702.935115 626.391166 705.019772 C 627.393726 703.26385 628.49882 702.91908 631.107754 702.662507 "
      transform="matrix(0.342872,0,0,0.487191,78.821552,-130.502488)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 501.595261 708.547651 L 502.768712 708.547651 C 499.476214 708.547651 501.230694 708.699991 507.473907 706.198404 C 512.372778 703.368083 514.799429 702.221522 518.069141 697.955995 C 523.537649 698.148424 527.741564 699.134627 533.380963 699.134627 C 533.380963 697.563117 532.99361 697.955995 534.554413 697.955995 "
      transform="matrix(0.342872,0,0,0.487191,78.821552,-130.502488)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 350.880908 694.420097 C 350.880908 693.60227 354.093656 692.335441 357.944397 690.8842 C 360.747007 688.783508 363.082516 690.763931 367.36618 690.8842 "
      transform="matrix(0.342872,0,0,0.487191,78.821552,-130.502488)"
    />
    <path
      style="fill:none;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 356.759554 705.019772 L 357.933004 705.019772 C 354.640507 705.019772 356.394987 705.164094 362.649592 702.662507 C 367.161111 703.295921 369.189016 705.540936 374.418277 706.198404 "
      transform="matrix(0.342872,0,0,0.487191,78.821552,-130.502488)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 749.423801 355.131923 C 749.423477 366.838742 754.153059 372.216432 765.806795 372.2177 C 768.490881 372.221606 771.943796 371.513368 773.99338 370.551635 C 781.222916 367.098616 781.868843 361.464646 781.870393 354.233943 L 781.864521 334.068474 C 781.870014 332.220547 782.824426 330.174135 787.8828 330.175318 L 787.886701 328.958667 L 773.032668 328.950093 L 773.040234 330.166565 C 778.08714 330.167928 779.048319 332.218795 779.054473 334.078016 L 779.048698 354.23219 C 779.052127 360.315089 778.98685 369.337244 767.023496 369.34082 C 759.017287 369.339859 755.953038 365.56588 755.945894 355.580768 L 755.953416 334.072249 C 755.947262 332.213028 756.913141 330.166436 761.581624 330.173721 L 761.585525 328.957069 L 743.783656 328.948717 L 743.791222 330.165189 C 748.459704 330.172473 749.420884 332.22334 749.415391 334.071266 Z M 749.423801 355.131923 "
      transform="matrix(-0.00532604,0.340557,-0.340347,-0.00532933,455.535206,-81.422378)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 735.97831 328.054275 L 734.631992 328.048441 C 733.038318 332.791114 728.04582 328.054113 720.241528 328.051181 C 707.500892 328.050123 698.287276 337.525971 698.285581 350.262745 C 698.28794 365.301203 710.12251 372.211737 721.005844 372.206701 C 726.255213 372.208336 733.415335 369.772487 737.130904 367.669736 L 737.134518 355.502717 C 737.136553 351.853925 737.451007 349.812296 741.608179 349.744088 L 741.617011 348.525253 L 725.286347 348.531903 L 725.29065 349.741215 C 730.609781 349.742369 730.921842 351.860816 730.919806 355.509608 L 730.919323 365.039071 C 730.924201 366.899273 730.921102 367.282284 729.069858 368.183626 C 726.885681 369.206116 724.19946 369.590598 721.831173 369.584245 C 711.087363 369.588321 705.642435 360.630226 705.648293 350.776222 C 705.648916 339.5733 710.251988 330.604593 720.878925 330.605093 C 728.426819 330.613561 732.654102 334.833223 735.090247 341.614064 L 736.553439 341.615321 Z M 735.97831 328.054275 "
      transform="matrix(0.0536825,0.336333,-0.336126,0.0537156,409.188044,-99.222003)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 558.191088 328.122013 L 556.846984 328.123639 C 554.73333 333.309608 551.533693 328.062502 542.769831 328.058416 C 529.90021 328.060767 520.873202 337.464694 520.879416 350.203631 C 520.876119 364.220439 530.408665 372.218298 542.89489 372.222287 C 549.036641 372.219263 555.630371 369.015167 559.533462 364.217735 L 558.444181 362.930911 C 554.026688 367.291261 549.737066 369.333683 543.47339 369.333917 C 532.974677 369.328993 528.307821 359.67431 528.302417 350.714607 C 528.299877 339.197712 532.848537 330.679017 543.404538 330.680934 C 550.702369 330.683855 555.376576 335.476786 557.233191 342.517458 L 558.763859 342.524419 Z M 558.191088 328.122013 "
      transform="matrix(0.261295,0.218282,-0.218148,0.261456,231.042842,-102.675662)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 511.41017 334.064991 C 511.413254 332.211132 512.372748 330.162645 517.687485 330.166454 L 517.680769 328.954076 L 498.608452 328.945508 L 498.605431 330.163996 C 503.920168 330.167805 504.879878 332.215043 504.876795 334.068901 L 504.878587 366.199332 C 504.875503 368.053191 503.916009 370.101678 498.606864 370.107878 L 498.61358 371.320256 L 517.680305 371.318815 L 517.683326 370.100327 C 512.368589 370.096518 511.408879 368.04928 511.411962 366.195422 Z M 511.41017 334.064991 "
      transform="matrix(0.297053,0.181334,-0.165966,0.288973,193.668295,-92.951603)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 490.423206 334.068496 C 490.41592 332.217459 491.383309 330.167636 496.623777 330.163975 L 496.625778 328.956099 L 481.394193 328.947526 L 481.396643 330.165972 C 486.641561 330.172882 487.602821 332.2196 487.610107 334.070637 L 487.605315 359.731464 L 462.96823 328.952284 L 452.020304 328.944661 L 452.022753 330.163108 C 455.150318 330.227678 455.729589 330.805092 457.717182 333.041916 L 458.225189 333.686662 L 458.229099 366.200277 C 458.225808 368.055767 457.268996 370.101138 452.013501 370.098681 L 452.015951 371.317127 L 467.258112 371.321248 L 467.255662 370.102802 C 462.000167 370.100344 461.038907 368.053626 461.042198 366.198137 L 461.045187 336.954297 L 489.461529 372.214948 L 490.41834 372.210336 Z M 490.423206 334.068496 "
      transform="matrix(0.313722,0.132155,-0.132074,0.313915,173.292699,-77.825668)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 431.469528 371.320994 L 434.344526 360.503458 L 432.561304 360.498463 C 429.169574 368.62679 424.297126 368.694256 419.178785 368.698002 L 410.735996 368.698266 C 409.458883 368.693286 409.06846 367.351929 409.070673 366.202241 L 409.067785 350.453781 L 418.097608 350.459515 C 422.37625 350.455484 426.024523 351.666915 426.031499 356.666926 L 427.377307 356.667227 L 427.369387 341.689299 L 426.035028 341.688218 C 426.029775 346.677566 422.381753 347.834165 418.090883 347.827534 L 409.073288 347.832461 L 409.067831 334.072875 C 409.073998 332.474599 410.289544 331.575637 411.888018 331.570225 L 422.194953 331.580939 C 426.669013 331.575094 428.787817 334.649487 429.483485 338.108193 L 430.89799 338.103815 L 430.895911 328.953642 L 396.905658 328.946631 L 396.908121 330.164972 C 401.578378 330.168755 402.546127 332.21799 402.545656 334.068777 L 402.537049 366.198922 C 402.537357 368.061151 401.583713 370.103307 396.913455 370.099525 L 396.904469 371.318646 Z M 431.469528 371.320994 "
      transform="matrix(0.339601,0.0231443,-0.02313,0.339811,114.498587,-40.586781)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 284.459076 328.117936 L 283.115061 328.121485 C 281.005801 333.307055 277.802311 328.053354 269.039743 328.053675 C 256.168411 328.058383 247.152464 337.466748 247.149005 350.204502 C 247.150107 364.219006 256.690358 372.213183 269.164138 372.222353 C 275.311524 372.219635 281.899175 369.010434 285.799487 364.212917 L 284.718525 362.940969 C 280.298605 367.289496 276.01415 369.333071 269.742772 369.341111 C 259.240711 369.333295 254.571703 359.677962 254.573364 350.707432 C 254.575717 339.18935 259.117143 330.67678 269.676293 330.678962 C 276.976798 330.677703 281.648157 335.479907 283.502241 342.517469 L 285.031208 342.51872 Z M 284.459076 328.117936 "
      transform="matrix(0.291106,-0.176521,0.176413,0.291286,52.128365,45.773758)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 237.684142 334.073165 C 237.681136 332.215115 238.637487 330.165714 243.95235 330.169115 L 243.955414 328.954373 L 224.881941 328.955837 L 224.878877 330.170579 C 230.184874 330.166694 231.151384 332.207916 231.15439 334.065965 L 231.153615 366.20396 C 231.147755 368.054724 230.191404 370.104126 224.876541 370.100725 L 224.882343 371.322753 L 243.955816 371.321289 L 243.950014 370.099261 C 238.644017 370.103146 237.684788 368.053063 237.681782 366.195013 Z M 237.684142 334.073165 "
      transform="matrix(0.26298,-0.216249,0.216115,0.263142,44.908746,66.134677)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 219.817631 371.322091 L 222.890084 360.184619 L 221.291499 360.180409 C 217.764504 368.95169 212.07465 368.694895 203.943207 368.69431 C 199.982903 368.69697 197.551315 367.995847 197.543655 366.202136 L 197.547738 334.065398 C 197.549742 332.21515 198.506499 330.172537 203.18084 330.17365 L 203.181731 328.95637 L 185.385134 328.952807 L 185.384242 330.170087 C 190.058583 330.1712 191.016884 332.218775 191.01488 334.069023 L 191.019128 366.197879 C 191.02501 368.056463 190.059922 370.106958 185.385582 370.105845 L 185.38469 371.323125 Z M 219.817631 371.322091 "
      transform="matrix(0.233931,-0.247417,0.247264,0.234076,39.99355,83.75038)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 102.377201 355.128111 C 102.382716 366.838031 107.110638 372.218094 118.7653 372.220105 C 121.449398 372.223493 124.907589 371.511112 126.957422 370.552564 C 134.187816 367.095893 134.83054 361.460713 134.825303 354.232548 L 134.824431 334.077473 C 134.825284 332.213897 135.785657 330.17329 140.845442 330.163446 L 140.845219 328.951888 L 125.993717 328.961533 L 125.993939 330.173091 C 131.053723 330.163247 132.013 332.21427 132.012147 334.077846 L 132.013019 354.232921 C 132.014896 360.316359 131.945506 369.343526 119.977764 369.340433 C 111.982179 369.338873 108.908266 365.558215 108.908174 355.578209 L 108.907922 334.067807 C 108.904107 332.219769 109.869148 330.163624 114.534273 330.170493 L 114.544153 328.953504 L 96.746747 328.957175 L 96.746969 330.168734 C 101.422196 330.170173 102.381473 332.221196 102.375186 334.074664 Z M 102.377201 355.128111 "
      transform="matrix(0.161163,-0.300002,0.299817,0.161263,33.042837,118.917071)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 56.363504 371.320945 L 59.242433 360.499947 L 57.454246 360.50407 C 54.063552 368.630387 49.200516 368.695257 44.078893 368.69448 L 35.637319 368.697826 C 34.35073 368.695935 33.967753 367.356284 33.971431 366.197247 L 33.968336 350.461275 L 42.994723 350.458789 C 47.286358 350.460596 50.928713 351.667753 50.927053 356.66401 L 52.272123 356.665987 L 52.26809 341.690705 L 50.934269 341.686494 C 50.932609 346.682752 47.278355 347.829671 42.98672 347.827864 L 33.971582 347.828116 L 33.963798 334.070386 C 33.972524 332.465744 35.188229 331.580807 36.789724 331.57866 L 47.091451 331.580299 C 51.569779 331.58013 53.675827 334.648498 54.384596 338.09966 L 55.788148 338.101723 L 55.794929 328.950926 L 21.803723 328.950475 L 21.811222 330.165793 C 26.476243 330.163647 27.445403 332.217589 27.438624 334.067546 L 27.435008 366.196642 C 27.441713 368.05562 26.480989 370.106726 21.802483 370.099849 L 21.809982 371.315166 Z M 56.363504 371.320945 "
      transform="matrix(0.0663041,-0.334072,0.333866,0.066345,29.107958,156.842603)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -15.435712 366.198816 C -15.438907 368.058056 -16.403603 370.099656 -20.876692 370.101252 L -20.875243 371.317754 L -3.281058 371.314536 L -3.282386 370.109509 C -7.950557 370.1017 -8.912763 368.057611 -8.909569 366.198371 L -8.91403 351.737927 L -5.325455 351.608005 L 9.908968 371.323658 L 20.208518 371.317569 L 20.207069 370.101067 C 17.326233 369.913606 15.855999 368.632366 14.124569 366.516109 L 1.457023 350.904766 C 7.469669 350.071983 13.044965 346.937052 13.041631 340.142952 C 13.042453 332.660213 6.637503 328.952436 -3.983278 328.950459 L -20.877902 328.946249 L -20.876453 330.162751 C -16.403364 330.161155 -15.441035 332.216719 -15.444351 334.064484 Z M -8.914674 334.396822 C -8.91232 331.378456 -8.338787 331.383842 -4.175339 331.385534 C 1.582312 331.381773 6.070499 333.88191 6.068959 340.216999 C 6.062043 347.125974 0.370616 349.045621 -8.916197 349.373775 Z M -8.914674 334.396822 "
      transform="matrix(0.00361457,-0.340577,0.340367,0.0036168,28.835648,181.191918)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 66.480364 366.201545 C 66.477375 368.060735 65.51523 370.103319 60.853095 370.098698 L 60.853825 371.31774 L 78.645545 371.31618 L 78.644814 370.097137 C 73.974374 370.106453 73.005998 368.054955 73.008987 366.195764 L 73.011255 352.499595 C 74.869983 352.692387 76.725761 352.82674 78.58113 352.81915 C 86.767663 352.820207 94.827976 349.562456 94.834071 340.091185 C 94.829008 332.540356 87.155103 328.9532 77.299541 328.948117 L 61.165732 328.956515 L 61.163648 330.164433 C 65.517001 330.164287 66.485378 332.215786 66.482389 334.074976 Z M 73.011557 334.258452 C 73.012365 331.642235 73.131039 331.316293 75.829295 331.320435 C 81.968491 331.318447 87.857683 333.36833 87.853487 340.601897 C 87.860954 348.021909 83.060819 350.324924 76.139956 350.323371 C 75.124661 350.331567 74.039431 350.203568 73.00444 350.133886 Z M 73.011557 334.258452 "
      transform="matrix(0.0834596,-0.330202,0.329999,0.0835111,29.70704,149.315398)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 149.353228 366.198822 C 149.359732 368.060858 148.396935 370.10006 143.209478 370.103156 L 143.212182 371.322272 L 163.376555 371.315058 C 173.420698 371.317183 180.084902 367.355198 180.075096 359.671087 C 180.085878 353.530334 174.89392 350.140202 169.394936 349.04336 C 174.320526 347.89133 177.900321 345.146497 177.902109 339.769949 C 177.898299 331.576368 170.227658 328.948871 160.37149 328.954753 L 143.214308 328.950023 L 143.217012 330.169138 C 148.404468 330.166043 149.357875 332.217548 149.357773 334.0702 Z M 155.886371 350.455754 L 159.088144 350.461201 C 165.099755 350.4533 172.716969 351.869323 172.71527 359.477326 C 172.716987 366.970645 167.787302 368.953606 161.135679 368.948949 C 156.466968 368.951735 155.893086 367.797913 155.88639 365.557075 Z M 155.893759 347.896238 L 155.892643 333.371112 C 155.885947 331.130275 157.938757 331.256924 163.82375 331.646929 C 168.306996 332.476449 171.375635 335.101872 171.373235 339.959567 C 171.379342 345.148106 167.602194 347.891534 161.138546 347.894884 Z M 155.893759 347.896238 "
      transform="matrix(0.195951,-0.2785,0.278328,0.196072,35.568513,102.817028)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 368.237113 371.323114 C 385.38876 371.317634 394.030837 363.250165 394.032908 350.262551 C 394.030482 335.859619 384.106901 328.952537 367.727163 328.949322 L 351.220681 328.947706 L 351.217487 330.166857 C 355.89113 330.163998 356.847207 332.217371 356.85207 334.06986 L 356.850372 366.199806 C 356.843794 368.051401 355.889048 370.104985 351.215406 370.107844 L 351.213105 371.315562 Z M 363.373442 334.579529 C 363.371773 331.507887 363.950254 331.323022 367.023768 331.321648 C 378.868942 331.315585 387.053842 337.787684 387.052299 350.326692 C 387.05414 362.233255 379.50555 369.270317 366.450838 368.698692 C 365.04002 368.634447 363.379991 367.92952 363.381018 365.55982 Z M 363.373442 334.579529 "
      transform="matrix(0.339353,-0.026538,0.0265216,0.339562,98.369326,-21.459507)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 613.866751 366.195725 C 613.873981 368.053296 612.910592 370.110074 608.427186 370.098135 L 608.422739 371.322145 L 626.025575 371.320905 L 626.024179 370.106772 C 621.359865 370.107826 620.399347 368.059722 620.392118 366.202152 L 620.393706 351.736729 L 623.987081 351.608883 L 639.215917 371.323004 L 649.51637 371.322398 L 649.514975 370.108265 C 646.633751 369.91069 645.16636 368.62942 643.444137 366.517435 L 630.763873 350.897377 C 636.782587 350.070802 642.352687 346.938921 642.355188 340.153876 C 642.351453 332.658492 635.949423 328.951763 625.330266 328.950523 L 608.431721 328.955018 L 608.433116 330.169151 C 612.912494 330.165374 613.873011 332.213478 613.864527 334.075087 Z M 620.393717 334.390435 C 620.401208 331.381596 620.971732 331.385728 625.132406 331.380104 C 630.89647 331.389552 635.37904 333.880944 635.377753 340.213383 C 635.376057 347.132234 629.676637 349.040982 620.39716 349.365489 Z M 620.393717 334.390435 "
      transform="matrix(0.173291,0.293156,-0.292975,0.173398,310.799037,-114.249165)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 584.931985 328.050355 L 583.657148 328.054797 L 570.673226 358.899393 C 568.043872 365.10074 566.381686 369.777462 562.41745 370.098004 L 562.410314 371.316582 L 575.087684 371.316182 L 575.078614 370.098384 C 572.910157 370.097061 570.730986 369.527744 570.735947 367.407777 C 570.729371 365.044718 573.353155 359.923807 574.187736 357.487819 L 590.958164 357.485546 L 593.263131 362.929875 C 595.693845 368.628049 595.751532 369.84351 590.314145 370.096813 L 590.314727 371.306898 L 606.639115 371.317949 L 606.630045 370.10015 C 603.049361 370.288549 601.705672 366.44668 600.557804 363.821753 Z M 582.632444 337.265188 L 590.05415 354.865137 L 575.273147 354.861098 Z M 582.632444 337.265188 "
      transform="matrix(0.228931,0.252056,-0.2519,0.229072,261.82068,-110.488969)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 673.836587 328.04491 L 672.557854 328.047785 L 659.569922 358.904866 C 656.947335 365.110427 655.275102 369.777985 651.315688 370.095975 L 651.309529 371.308873 L 663.980566 371.316819 L 663.986725 370.103921 C 661.808883 370.10079 659.633095 369.52209 659.630553 367.415605 C 659.630318 365.046962 662.255457 359.920268 663.084146 357.489241 L 679.853432 357.490751 L 682.164169 362.928403 C 684.585564 368.62765 684.656252 369.83667 679.21984 370.095608 L 679.213681 371.308506 L 695.531107 371.312267 L 695.537265 370.099369 C 691.946462 370.290858 690.614227 366.448011 689.461035 363.824129 Z M 671.533911 337.260656 L 678.962287 354.869059 L 664.175141 354.860005 Z M 671.533911 337.260656 "
      transform="matrix(0.136764,0.311895,-0.311703,0.136849,342.427844,-111.953538)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 812.457396 328.044889 L 811.175652 328.046498 L 798.188888 358.90274 C 795.563156 365.107396 793.898897 369.776755 789.929316 370.096771 L 789.934397 371.309558 L 802.603784 371.311007 L 802.598703 370.09822 C 800.427844 370.10115 798.25782 369.526178 798.249939 367.412346 C 798.246526 365.043752 800.873879 359.925177 801.705922 357.492281 L 818.475154 357.493409 L 820.782097 362.928833 C 823.212554 368.626971 823.275931 369.844409 817.831579 370.096451 L 817.836661 371.309239 L 834.156316 371.317401 L 834.149884 370.093217 C 830.569473 370.286238 829.225404 366.447052 828.071344 363.821859 Z M 810.153901 337.26218 L 817.574355 354.861327 L 802.792405 354.867458 Z M 810.153901 337.26218 "
      transform="matrix(-0.0400432,0.338234,-0.338025,-0.0400679,481.745044,-67.781448)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 311.222627 328.056855 L 309.939852 328.053385 L 296.948334 358.902422 C 294.324973 365.104235 292.656349 369.772486 288.687467 370.096742 L 288.69927 371.310751 L 301.36501 371.318135 L 301.368106 370.097703 C 299.183984 370.094154 297.013259 369.528284 297.016053 367.406909 C 297.018179 365.03847 299.631677 359.918662 300.473189 357.488675 L 317.238665 357.49525 L 319.539725 362.925486 C 321.967241 368.628061 322.040837 369.841929 316.592965 370.097134 L 316.589869 371.317566 L 332.916355 371.310231 L 332.915218 370.100457 C 329.331666 370.293913 327.991651 366.454645 326.838155 363.824729 Z M 308.909979 337.269622 L 316.343011 354.869007 L 301.55071 354.868591 Z M 308.909979 337.269622 "
      transform="matrix(0.316356,-0.125708,0.12563,0.316551,64.368018,21.768447)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 728.9297 940.015879 L 733.303836 929.326588 L 731.714128 929.133558 C 727.174514 937.424501 721.549889 936.492662 713.477964 935.533558 C 709.541712 935.06512 707.208955 934.076486 707.428071 932.297171 L 711.22924 900.398113 C 711.455731 898.552302 712.644531 896.631595 717.286355 897.180657 L 717.429533 895.978944 L 699.768095 893.859906 L 699.619271 895.076829 C 704.261094 895.625891 704.968734 897.775796 704.752669 899.61683 L 700.941074 931.520665 C 700.725009 933.361699 699.525783 935.287183 694.879179 934.727689 L 694.740781 935.939834 Z M 728.9297 940.015879 "
      transform="matrix(0.141802,-0.309637,0.309446,0.14189,-65.322342,316.766753)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 594.210648 883.963715 L 598.786289 884.511437 C 603.23097 885.031688 606.429019 885.226128 606.839851 893.718234 L 608.364855 893.895403 L 609.275154 883.04728 L 573.429265 878.774515 L 571.760359 889.529845 L 573.285363 889.707015 C 573.965932 887.789493 574.541044 885.664927 576.074498 884.177558 C 578.236168 881.724487 583.150535 882.632702 587.726176 883.180424 L 583.627486 917.506204 C 583.406773 919.349333 582.214563 921.262046 577.574072 920.716802 L 577.433883 921.915522 L 595.091957 924.025492 L 595.239938 922.818356 C 590.607238 922.264696 589.890616 920.116412 590.111329 918.273284 Z M 594.210648 883.963715 "
      transform="matrix(0.249773,-0.231392,0.231249,0.249927,-64.981955,166.73696)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 564.312677 882.840241 C 564.52833 880.997316 565.724362 879.0783 570.937793 879.705903 L 571.083984 878.496251 L 555.959073 876.682049 L 555.812882 877.891701 C 561.026312 878.519304 561.735122 880.663286 561.519469 882.506211 L 558.471005 907.987099 L 537.652324 874.497661 L 526.78559 873.203565 L 526.639398 874.413216 C 529.745975 874.846165 530.251513 875.489584 531.955982 877.948698 L 532.391924 878.644908 L 528.531958 910.919749 C 528.316305 912.762673 527.120273 914.681689 521.901176 914.064058 L 521.760651 915.263738 L 536.885562 917.07794 L 537.031753 915.868288 C 531.8283 915.246355 531.109513 913.096703 531.335144 911.259448 L 534.798163 882.215103 L 558.826479 920.601856 L 559.791711 920.715023 Z M 564.312677 882.840241 "
      transform="matrix(0.295947,-0.168269,0.168165,0.296129,-35.742669,90.361377)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 517.252466 914.726987 L 521.390848 904.334938 L 519.611406 904.118738 C 515.279007 911.786651 510.441068 911.273699 505.355489 910.669417 L 496.96774 909.666293 C 495.701729 909.517208 495.476363 908.138361 495.617628 906.992519 L 497.480804 891.360773 L 506.443754 892.429346 C 510.697416 892.934622 514.178499 894.572941 513.583354 899.536378 L 514.921904 899.687767 L 516.699404 884.818977 L 515.364822 884.656827 C 514.769676 889.620264 511.011724 890.324612 506.758062 889.819336 L 497.795112 888.750764 L 499.422272 875.085588 C 499.617434 873.495115 500.929468 872.756606 502.524733 872.941553 L 512.749727 874.169971 C 517.202301 874.69971 518.932025 878.002441 519.213775 881.516815 L 520.620895 881.681269 L 521.697732 872.592672 L 487.958593 868.559683 L 487.81902 869.767269 C 492.455771 870.328261 493.168971 872.473768 492.944307 874.310062 L 489.139663 906.216993 C 488.915 908.053287 487.714448 909.981956 483.077697 909.420964 L 482.938124 910.62855 Z M 517.252466 914.726987 "
      transform="matrix(0.319362,-0.117854,0.117781,0.319559,-3.361606,43.338486)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 484.064667 867.255531 L 482.728967 867.102426 C 480.015624 871.998299 477.457864 866.40895 468.755064 865.363796 C 455.979134 863.836682 445.907765 872.109281 444.389875 884.762441 C 442.73227 898.671304 451.245734 907.754292 463.646234 909.230391 C 469.738006 909.956153 476.663917 907.565422 481.116656 903.26209 L 480.18668 901.862784 C 475.283086 905.660674 470.781529 907.188536 464.553924 906.440108 C 454.139995 905.190834 450.64309 895.049621 451.709768 886.146592 C 453.070968 874.71142 458.591827 866.795774 469.07556 868.045068 C 476.318146 868.916341 480.387096 874.234608 481.393934 881.448817 L 482.923952 881.622718 Z M 484.064667 867.255531 "
      transform="matrix(0.335745,-0.0560555,0.056021,0.335952,42.366051,0.0441162)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 379.896985 854.825419 L 378.563717 854.662809 C 375.855672 859.560912 373.303845 853.964633 364.594361 852.926873 C 351.815272 851.401852 341.74024 859.672885 340.23711 872.324 C 338.570443 886.237883 347.08905 895.307773 359.488618 896.794404 C 365.580735 897.521626 372.512088 895.12142 376.954904 890.816334 L 376.023117 889.419186 C 371.119745 893.222695 366.620843 894.743274 360.398451 894.003914 C 349.974201 892.753857 346.486274 882.605933 347.540053 873.712617 C 348.905651 862.273148 354.429118 854.355965 364.911865 855.607431 C 372.157805 856.470442 376.233722 861.788443 377.240056 869.00569 L 378.762097 869.181847 Z M 379.896985 854.825419 "
      transform="matrix(0.335295,0.0586952,-0.058659,0.335502,145.660042,-45.902487)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 332.736312 855.183299 C 332.964238 853.337313 334.150193 851.4157 339.424169 852.043466 L 339.576248 850.840641 L 320.631527 848.57575 L 320.493671 849.786381 C 325.767646 850.414148 326.475842 852.561149 326.258935 854.40393 L 322.451207 886.303684 C 322.223281 888.14967 321.037325 890.071283 315.76335 889.443516 L 315.611271 890.646341 L 334.555991 892.911233 L 334.693848 891.700601 C 329.419872 891.072834 328.711676 888.925833 328.928583 887.083053 Z M 332.736312 855.183299 "
      transform="matrix(0.32684,0.0951323,-0.0950736,0.327042,180.866321,-50.861927)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 268.328994 885.006426 L 272.474188 874.6035 L 270.692527 874.396051 C 266.360068 882.059029 261.522249 881.547739 256.441289 880.93338 L 248.047886 879.934147 C 246.777646 879.780557 246.564758 878.40523 246.691256 877.262005 L 248.56348 861.62782 L 257.522036 862.700984 C 261.780782 863.20173 265.257415 864.843311 264.667894 869.79653 L 266.00153 869.964002 L 267.773573 855.088496 L 266.446121 854.930683 C 265.846936 859.890091 262.09372 860.604535 257.838455 860.087941 L 248.870235 859.020966 L 250.508042 845.36173 C 250.700292 843.767856 252.009647 843.024828 253.603825 843.216135 L 263.826437 844.43323 C 268.278849 844.959777 270.011942 848.262448 270.302358 851.780687 L 271.693205 851.952383 L 272.783658 842.865292 L 239.035705 838.830181 L 238.895285 840.036792 C 243.5317 840.595327 244.239328 842.743347 244.026571 844.581864 L 240.209785 876.482139 C 239.987363 878.326844 238.792723 880.249308 234.156309 879.690773 L 234.015889 880.897385 Z M 268.328994 885.006426 "
      transform="matrix(0.286661,0.183657,-0.183544,0.286838,268.291446,-42.196105)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 230.874856 843.011403 C 231.092292 841.172003 232.290669 839.249604 236.929153 839.801045 L 237.073055 838.590968 L 224.416259 837.082931 L 206.548733 867.502945 L 195.699082 833.652039 L 183.12324 832.149228 L 182.971771 833.350687 C 188.061486 833.963773 188.767286 836.106646 188.541227 837.953619 L 184.73891 869.850654 C 184.512852 871.697627 183.314475 873.620026 178.111425 872.999623 L 177.959955 874.201082 L 193.093062 876.007173 L 193.22834 874.804669 C 188.02529 874.184266 187.311922 872.032775 187.529358 870.193375 L 191.069559 840.571827 L 202.877401 877.183454 L 203.764726 877.28951 L 224.359334 843.070409 L 220.643213 874.14384 C 220.417154 875.990812 219.227401 877.905639 214.588916 877.354198 L 214.445014 878.564275 L 232.103004 880.671555 L 232.246905 879.461478 C 227.608421 878.910037 226.902621 876.767164 227.128679 874.920192 Z M 230.874856 843.011403 "
      transform="matrix(0.255764,0.224743,-0.224605,0.255922,309.794894,-26.323764)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 278.84832 881.09519 C 278.62623 882.939394 277.437876 884.862061 272.988238 884.332155 L 272.8418 885.544603 L 290.318994 887.628617 L 290.465433 886.41617 C 285.822152 885.862759 285.114465 883.714028 285.325851 881.873962 L 287.044675 867.511593 L 290.61903 867.814273 L 303.412476 889.194127 L 313.641706 890.416049 L 313.788144 889.203601 C 310.947487 888.66645 309.641121 887.228723 308.170298 884.920073 L 297.439078 867.919595 C 303.507423 867.799099 309.415679 865.355051 310.217683 858.614129 C 311.106772 851.175549 305.194569 846.735308 294.64398 845.477777 L 277.86059 843.469821 L 277.724856 844.67813 C 282.174494 845.208036 282.882181 847.356768 282.660091 849.200972 Z M 289.108432 850.286135 C 289.458959 847.310219 290.03575 847.370035 294.16403 847.864333 C 299.877695 848.544949 304.033727 851.561513 303.279644 857.853545 C 302.461342 864.711434 296.575944 865.941626 287.330254 865.163295 Z M 289.108432 850.286135 "
      transform="matrix(0.317486,0.122819,-0.122744,0.317682,208.192629,-51.331951)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 406.471944 857.92505 L 405.19812 857.775955 L 388.638408 886.872341 C 385.298924 892.721437 383.095553 897.159869 379.113419 897.010774 L 378.975708 898.215 L 391.553285 899.717415 L 391.702471 898.513189 C 389.533528 898.249406 387.444916 897.423652 387.697385 895.324858 C 387.972807 892.973751 391.186056 888.202724 392.299218 885.886023 L 408.950736 887.881597 L 410.591789 893.547192 C 412.336124 899.499507 412.255793 900.715202 406.81622 900.313793 L 406.67851 901.529488 L 422.882469 903.467718 L 423.031655 902.252023 C 419.439701 902.022647 418.567533 898.042968 417.729793 895.301921 L 406.471944 857.947988 Z M 403.086556 866.813382 L 408.376942 885.163488 L 393.699277 883.408759 Z M 403.086556 866.813382 "
      transform="matrix(0.340387,0,0,0.340597,90.81,-28.097752)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 159.328117 828.406888 L 158.053832 828.260408 L 141.4991 857.349527 C 138.162988 863.200195 135.955454 867.63969 131.97357 867.49461 L 131.830283 868.702475 L 144.413724 870.197502 L 144.557012 868.989637 C 142.396157 868.738765 140.298755 867.906155 140.553384 865.801977 C 140.836068 863.453038 144.046152 858.683114 145.168465 856.364898 L 161.815998 858.352714 L 163.457856 864.027515 C 165.192278 869.97375 165.115764 871.185469 159.676095 870.796591 L 159.532807 872.004456 L 175.741793 873.943305 L 175.885081 872.73544 C 172.298695 872.498867 171.424591 868.528002 170.59158 865.779587 L 159.331129 828.422831 Z M 155.953439 837.286799 L 161.23069 855.648161 L 146.550155 853.890174 Z M 155.953439 837.286799 "
      transform="matrix(0.191733,0.281425,-0.281252,0.191852,369.774576,19.186718)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 612.476341 920.947662 C 612.25795 922.795419 611.060035 924.712847 606.613997 924.186874 L 606.467516 925.388856 L 623.943985 927.473782 L 624.090467 926.2718 C 619.451094 925.717657 618.745074 923.5691 618.963465 921.721344 L 620.676314 907.367942 L 624.25116 907.653286 L 637.039192 929.036728 L 647.269412 930.268676 L 647.408291 929.058098 C 644.579945 928.526168 643.270287 927.080018 641.801491 924.778791 L 631.071268 907.764774 C 637.141143 907.651216 643.044631 905.202915 643.853889 898.46626 C 644.730418 891.026652 638.824831 886.582634 628.270738 885.330725 L 611.49721 883.313506 L 611.349741 884.531682 C 615.795778 885.057654 616.510389 887.198613 616.291998 889.046369 Z M 622.735564 890.134342 C 623.087894 887.156979 623.66889 887.225297 627.792042 887.715114 C 633.512438 888.400903 637.660571 891.41181 636.911366 897.697022 C 636.094204 904.563231 630.216239 905.793967 620.95871 905.003965 Z M 622.735564 890.134342 "
      transform="matrix(0.225507,-0.255128,0.254971,0.225646,-70.639195,203.197923)"
    />
    <path
      style="fill-rule:nonzero;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 676.553466 890.189629 L 675.273263 890.034801 L 658.722475 919.129194 C 655.375795 924.98151 653.173588 929.422827 649.192785 929.272634 L 649.050891 930.480732 L 661.630075 931.982218 L 661.771969 930.77412 C 659.619356 930.513474 657.518771 929.685559 657.770439 927.589219 C 658.052039 925.239892 661.261203 920.461938 662.386201 918.142646 L 679.033293 920.139806 L 680.674372 925.805678 C 682.420344 931.754619 682.335274 932.970514 676.905984 932.575232 L 676.754066 933.788903 L 692.962164 935.728139 L 693.104059 934.520041 C 689.519826 934.280664 688.646249 930.302054 687.806826 927.564964 L 676.549019 890.205232 Z M 673.170689 899.068854 L 678.456814 917.427217 L 663.776264 915.662853 Z M 673.170689 899.068854 "
      transform="matrix(0.165404,-0.297682,0.297499,0.165507,-70.914909,286.913382)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 186.494625 676.039819 C 186.494625 670.982071 182.202642 666.876235 176.923731 666.876235 C 171.633345 666.876235 167.341362 670.982071 167.341362 676.039819 C 167.341362 681.097566 171.633345 685.203402 176.923731 685.203402 C 182.202642 685.203402 186.494625 681.097566 186.494625 676.039819 Z M 186.494625 676.039819 "
      transform="matrix(0.340387,0,0,0.340597,91.660186,-23.277003)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,82.352941%,0%);fill-opacity:1;stroke-width:0.688338;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 186.49365 676.035935 C 186.49365 670.978188 182.213142 666.88382 176.922756 666.88382 C 171.63237 666.88382 167.340387 670.978188 167.340387 676.035935 C 167.340387 681.093683 171.63237 685.199519 176.922756 685.199519 C 182.213142 685.199519 186.49365 681.093683 186.49365 676.035935 Z M 186.49365 676.035935 "
      transform="matrix(0.340387,0,0,0.340597,265.94958,-19.306931)"
    />
  </g>
</svg>
