<script>
  import { iconPath } from "lib/helpers.js";
  import styles from "styleguide/Options.json";

  export let title;
  export let style = "";
  export let arrow = true;
  export let icon = null;
  export let background = true;
  export let show = false;
  export let lock;
  export let autoclose = true;
  export let isLeft = false;

  function toggle() {
    if (!lock) {
      show = !show;
    }
  }

  function getStyles() {
    return style
      .split(" ")
      .map((x) => styles[x])
      .join(" ");
  }

  function onAutoclose() {
    if (autoclose) toggle();
  }
</script>

{#if show && !lock}
  <div class={styles.backdrop} on:click={toggle}></div>
{/if}

<div
  data-component="Options"
  class={`${styles.options} ${lock ? styles.lock : ""} ${getStyles()}`}
>
  {#if $$slots.title}
    <div on:click={toggle}>
      <slot name="title" />
    </div>
  {:else if $$slots.avatar}
    <slot name="avatar" />
  {:else}
    <div
      class={`${styles.title} ${show ? styles.active : ""}`}
      on:click={toggle}
    >
      {#if icon}
        <img class={styles.icon} src={iconPath(icon)} alt={icon} />
      {/if}
      {#if title}
        {title}
      {/if}

      {#if lock}
        <div class={styles.arrow}>
          <img src={iconPath("grey_lock.svg")} alt="" />
        </div>
      {:else if arrow}
        <div class={styles.arrow}>
          <svg
            width="18px"
            height="18px"
            viewBox="0 0 18 18"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="DESIGN:-Forms"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Forms"
                transform="translate(-671.000000, -858.000000)"
                fill="currentColor"
              >
                <g id="Group-10" transform="translate(439.000000, 783.000000)">
                  <g
                    id="input/Form/item"
                    transform="translate(0.000000, 38.000000)"
                  >
                    <g
                      id="actions/dropdown/closed"
                      transform="translate(113.000000, 27.000000)"
                    >
                      <g id="Group" transform="translate(12.000000, 8.000000)">
                        <g
                          id="icon/controls/chevron/stroke-1,5.down"
                          transform="translate(107.000000, 2.000000)"
                        >
                          <path
                            d="M8.99975,11.99975 C8.80775,11.99975 8.61575,11.92675 8.46975,11.77975 L4.46975,7.77975 C4.17675,7.48675 4.17675,7.01275 4.46975,6.71975 C4.76275,6.42675 5.23675,6.42675 5.52975,6.71975 L8.99975,10.18875 L12.46975,6.71975 C12.76275,6.42675 13.23675,6.42675 13.52975,6.71975 C13.82275,7.01275 13.82275,7.48675 13.52975,7.77975 L9.52975,11.77975 C9.38375,11.92675 9.19175,11.99975 8.99975,11.99975"
                            id="icon-color"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
      {/if}
    </div>
  {/if}

  {#if show}
    <div
      class={`${styles.items} ${background ? styles.background : ""} ${isLeft ? styles.left : ""}`}
      on:click={onAutoclose}
    >
      <slot />
    </div>
  {/if}
</div>

<style lang="scss">
  .options {
    position: relative;

    &.small {
      height: 20px;
    }
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 11;
  }

  .title {
    border: 1px solid var(--primary-050);
    border-radius: var(--border-radius);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 10px;
    cursor: pointer;
    color: var(--blue-200);
    font-weight: 500;
    user-select: none;
    min-width: 0;
    box-sizing: border-box;

    .border-icon & {
      padding: 0 20px 0 15px;
    }

    .small &,
    .medium &,
    .icon {
      .border-icon {
        & {
          margin-right: 15px;
          width: 30px;
          height: 30px;
          padding: 5px;
          border: 1px solid var(--primary-050);
          border-radius: var(--border-radius);
          box-sizing: border-box;
        }
      }
    }

    .arrow {
      display: flex;
      align-items: center;
      margin: 0 -4px 0 4px;
    }

    &:hover:not(.active) {
      border-color: var(--blue);
    }

    .lock &:hover {
      cursor: inherit;
      border-color: var(--primary-050);
    }

    .button & {
      width: 40px;
      margin: 0;
    }

    .attachment & {
      margin: 0;
    }

    .medium & {
      height: 30px;
      padding: 0 10px;
    }

    .medium.button & {
      width: 30px;
    }

    .small & {
      height: 20px;
    }

    .small.button & {
      width: 30px;
    }

    .plus & {
      border: none;
    }

    .no-border & {
      border: none;
    }

    .no-padding & {
      padding: 0;
    }

    .no-margin & {
      margin: 0;
    }

    .header & {
      color: var(--header-button-text-color);
      background: var(--header-button-background-color);
      border: none;

      &:hover {
        color: var(--header-button-hover-text-color);
        background-color: var(--header-button-hover-background-color);
        border: none;
      }
    }

    .inverted & {
      background: inherit;
      border: 1px solid transparent;
      color: #fff;

      &:hover {
        border-color: #fff;
      }
    }

    .inverted-blue & {
      background: inherit;
      border: 1px solid transparent;
      color: #fff;

      &:hover {
        border: 1px solid transparent;
        background-color: rgba(10, 90, 217, 0.08);
      }
    }

    .primary & {
      font-weight: normal;
      color: var(--primary-500);
    }

    .fullsize & {
      width: 100%;
      justify-content: space-between;
    }

    .acts-as-button-tonal & {
      background: var(--brand-010);
      color: var(--brand-tonal-button-text-color, var(--brand-200));

      &:hover {
        background: var(--brand-025);
        color: var(--brand-tonal-button-text-color, var(--brand-200));
      }
    }

    // style is same as primary-text
    .acts-as-button & {
      &:hover {
        background: var(--blue-025);
        color: var(--blue-200);
      }
    }
  }

  .acts-as-button-tonal .title:active,
  .acts-as-button-tonal .active {
    background: var(--brand-050);
    color: var(--brand-tonal-button-text-color, var(--brand-200));
  }

  .acts-as-button .title:active,
  .acts-as-button .active {
    background: var(--blue-010);
    color: var(--blue-200);
  }

  .border-icon {
    .icon {
      margin-right: 15px;
      width: 30px;
      height: 30px;
      padding: 5px;
      border: 1px solid var(--primary-050);
      border-radius: var(--border-radius);
      box-sizing: border-box;
    }
  }

  .icon-only {
    .icon {
      margin-right: 0;
    }

    .title {
      width: 30px;
    }
  }

  .options:not(.plus) {
    &.active {
      background: var(--primary-500);
      border-color: var(--primary-500);
      color: #fff;
    }
  }

  .top {
    .items {
      bottom: calc(100% + 5px);
      left: 0;
      right: auto;
      top: auto;
    }

    .arrow-icon {
      transform: rotate(180deg);
    }
  }

  .items {
    position: absolute;
    top: calc(100% + 5px);
    max-width: 380px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 11;

    .options:not(.nowrap) & {
      min-width: 220px;
    }

    &.background {
      border: 1px solid var(--primary-050);
      border-radius: var(--border-radius);
      background: #fff;
      box-shadow: 0 3px 8px 0 rgba(41, 49, 61, 0.06);
    }

    .small &,
    .medium & {
      &.options:not(.nowrap) {
        min-width: 160px;
      }
    }

    .options.items_wider & {
      min-width: 380px;
    }

    .items-padding & {
      padding: 5px 0;
    }
  }
  .left {
    .items {
      right: 0px;
    }
  }

  .full-height {
    height: 100%;
  }

  .main-nav .title {
    border-radius: var(--border-radius);
    border: none;
    color: var(--header-button-text-color);
    background-color: var(--header-button-background-color);
    font-size: 14px;
    line-height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 12px;
    gap: 8px;

    &:hover {
      padding: 10px 12px;
      background-color: var(
        --header-button-background-highlight-color,
        var(--header-button-background-hover-color)
      );
      color: var(--header-button-text-highlight-color);
    }

    &:focus:not(:active) {
      padding: 10px 12px;
      background-color: var(
        --header-button-background-highlight-color,
        var(--header-button-background-hover-color)
      );
      color: var(--header-button-text-highlight-color);
    }

    &:active {
      padding: 10px 12px;
      background-color: var(
        --header-button-background-highlight-color,
        var(--header-button-background-active-color)
      );
      color: var(--header-button-text-highlight-color);
    }
  }
</style>
