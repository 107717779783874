var contentContainerID = "html";

// cloned from tables.js
$(document).on("click", "[data-link]", function (event) {
  if (
    $(event.target).attr("href") ||
    $(event.target).closest(".checkbox").length > 0
  ) {
    return;
  } else {
    var link =
      $(event.target).data("link") ||
      $(event.target).parents("[data-link]").data("link");
    if ($(event.target).data("remote")) {
      $.ajax(link);
    } else {
      Turbolinks.visit(link);
    }
  }
});

$(document).on("click", "a.disabled", function (event) {
  event.preventDefault();
});

$(document).on("click", function (event) {
  var userPicture = $(
    "#container-navbar .user-picture, #container-navbar #user-avatar-for-dropdown",
  );
  var userDropdown = $("#container-navbar--dropdown-content");
  if (userPicture.is(event.target)) {
    userDropdown.toggle();
    userPicture.toggleClass("active");
  } else {
    userDropdown.hide();
    userPicture.removeClass("active");
  }
});
