<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(100%,100%,100%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,10.588235%,60.392157%);fill-opacity:1;"
      d="M 0 121.90625 L 480 121.90625 L 480 241.910156 L 0 241.910156 Z M 0 121.90625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.588235%,0%,0%);fill-opacity:1;"
      d="M 0 0.03125 L 480 0.03125 L 480 61.90625 L 0 61.90625 Z M 0 0.03125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(90.588235%,0%,0%);fill-opacity:1;"
      d="M 0 300.003906 L 480 300.003906 L 480 360.003906 L 0 360.003906 Z M 0 300.003906 "
    />
  </g>
</svg>
