<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <defs>
    <clipPath id="clip1">
      <path
        d="M 179 239 L 479.988281 239 L 479.988281 360 L 179 360 Z M 179 239 "
      />
    </clipPath>
    <clipPath id="clip2">
      <path
        d="M 179 121 L 479.988281 121 L 479.988281 241 L 179 241 Z M 179 121 "
      />
    </clipPath>
    <clipPath id="clip3">
      <path d="M 179 0 L 479.988281 0 L 479.988281 122 L 179 122 Z M 179 0 " />
    </clipPath>
  </defs>
  <g id="surface1">
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 0 0 L 179.996094 0 L 179.996094 360 L 0 360 Z M 0 0 "
    />
    <g clip-path="url(#clip1)" clip-rule="nonzero">
      <path
        style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
        d="M 179.996094 239.105469 L 719.980469 239.105469 L 719.980469 360 L 179.996094 360 Z M 179.996094 239.105469 "
      />
    </g>
    <g clip-path="url(#clip2)" clip-rule="nonzero">
      <path
        style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
        d="M 179.996094 121.429688 L 719.980469 121.429688 L 719.980469 240.714844 L 179.996094 240.714844 Z M 179.996094 121.429688 "
      />
    </g>
    <g clip-path="url(#clip3)" clip-rule="nonzero">
      <path
        style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,60.392157%,0%);fill-opacity:1;"
        d="M 179.996094 0 L 719.980469 0 L 719.980469 121.429688 L 179.996094 121.429688 Z M 179.996094 0 "
      />
    </g>
  </g>
</svg>
