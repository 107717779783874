<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(40.392157%,69.411765%,100%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,70.588235%);fill-opacity:1;"
      d="M 0 0 L 323.570312 0 L 323.570312 207.875 L 0 207.875 Z M 0 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 0 0 L 0 23.242188 L 287.394531 207.878906 L 323.570312 207.878906 L 323.570312 184.636719 L 36.175781 0 Z M 323.570312 0 L 323.570312 23.242188 L 36.175781 207.878906 L 0 207.878906 L 0 184.636719 L 287.394531 0 Z M 323.570312 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 134.820312 0 L 134.820312 207.878906 L 188.75 207.878906 L 188.75 0 Z M 0 69.292969 L 0 138.585938 L 323.570312 138.585938 L 323.570312 69.292969 Z M 0 69.292969 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 0 83.152344 L 0 124.726562 L 323.570312 124.726562 L 323.570312 83.152344 Z M 145.605469 0 L 145.605469 207.878906 L 177.964844 207.878906 L 177.964844 0 Z M 145.605469 0 "
    />
    <path
      style=" stroke:none;fill-rule:nonzero;fill:rgb(80%,0%,0%);fill-opacity:1;"
      d="M 0 207.878906 L 107.855469 138.585938 L 131.972656 138.585938 L 24.117188 207.878906 Z M 0 0 L 107.855469 69.292969 L 83.742188 69.292969 L 0 15.496094 Z M 191.597656 69.292969 L 299.453125 0 L 323.570312 0 L 215.714844 69.292969 Z M 323.570312 207.878906 L 215.714844 138.585938 L 239.832031 138.585938 L 323.570312 192.382812 Z M 323.570312 207.878906 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;"
      d="M 293.574219 102.785156 L 293.574219 222.230469 C 308.972656 305.84375 335.921875 317.789062 370.574219 341.679688 C 405.222656 317.789062 432.171875 305.84375 447.570312 222.230469 L 447.570312 102.785156 Z M 293.574219 102.785156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.490196%,0%,0%);fill-opacity:1;"
      d="M 360.304688 102.785156 L 360.46875 334.902344 L 370.734375 341.644531 L 381 334.714844 L 380.839844 102.789062 L 360.304688 102.789062 Z M 360.304688 102.785156 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.490196%,0%,0%);fill-opacity:1;"
      d="M 293.574219 223.492188 L 301.273438 256.027344 L 440.277344 255.9375 L 447.574219 223.492188 Z M 293.574219 223.492188 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(85.490196%,0%,0%);fill-opacity:1;"
      d="M 293.574219 102.785156 L 447.570312 102.785156 L 447.570312 186.398438 L 293.574219 186.398438 Z M 293.574219 102.785156 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(96.470588%,93.72549%,92.54902%);fill-opacity:1;stroke-width:0.75;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 329.306019 -281.679382 C 342.138209 -281.679382 352.533207 -269.147867 352.533207 -253.688301 C 352.533207 -238.228736 342.138209 -225.697221 329.306019 -225.697221 C 316.492292 -225.697221 306.097294 -238.228736 306.097294 -253.688301 C 306.097294 -269.147867 316.492292 -281.679382 329.306019 -281.679382 Z M 329.306019 -281.679382 "
      transform="matrix(0.211565,0,0,0.290829,232.310806,223.416698)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:0.875;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 850.398411 -296.576037 C 850.398411 -296.576037 898.038515 -300.137064 914.723045 -319.193366 C 931.386588 -338.249668 903.411144 -372.801245 921.270936 -376.362272 C 928.406458 -376.958984 938.543097 -374.591383 946.287237 -365.063232 C 945.090988 -372.204533 940.326978 -376.362272 938.543097 -380.539259 C 931.995206 -386.487135 925.426328 -385.890423 915.898307 -385.890423 C 909.350416 -384.696998 908.154167 -387.083848 897.450884 -399.595561 C 892.078255 -405.543437 892.078255 -420.442 896.254635 -430.566864 C 883.746484 -421.038713 886.138982 -407.930287 887.314245 -404.946725 C 890.294375 -391.857547 893.274505 -388.277273 893.274505 -382.329396 C 889.706744 -384.119534 883.158853 -399.595561 849.202162 -401.982411 C 815.266458 -404.350012 729.51427 -378.152409 690.793569 -378.152409 C 652.093854 -378.152409 596.121979 -394.225149 596.121979 -394.225149 C 596.121979 -394.225149 610.41401 -405.543437 593.141849 -410.317137 C 575.869688 -415.071588 537.757604 -393.628436 527.033334 -405.543437 C 519.289194 -416.861726 522.269324 -414.474876 521.073075 -419.845288 C 518.092945 -418.05515 516.309064 -410.913849 520.485443 -401.385698 C 524.640836 -391.857547 527.033334 -394.821861 526.445703 -394.821861 C 525.837085 -394.821861 512.741303 -394.821861 511.545054 -390.06741 C 510.348804 -386.487135 508.564924 -382.329396 515.112815 -369.220971 C 517.505313 -382.329396 508.564924 -384.119534 528.229584 -385.29371 C 547.873256 -386.487135 561.577656 -377.555697 578.241199 -374.591383 C 594.925729 -371.60782 594.925729 -381.732684 595.513361 -381.135971 C 596.121979 -380.539259 646.721226 -366.834121 693.186067 -369.817683 C 739.629922 -372.801245 814.678826 -398.402136 849.202162 -393.050972 C 883.746484 -387.68056 906.370287 -362.676382 906.957918 -339.443093 C 906.370287 -325.16049 891.490624 -317.999941 879.570105 -315.632339 C 865.278073 -313.24549 831.930001 -309.665215 831.930001 -309.665215 Z M 850.398411 -296.576037 "
      transform="matrix(0.186129,0,0,0.202935,256.380568,197.349672)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:0.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 683.068319 -248.925519 C 682.463409 -248.925519 650.31676 -248.321105 640.767822 -234.634487 C 631.240489 -220.934438 627.07093 -207.825372 627.67584 -207.825372 C 628.28075 -207.825372 649.10694 -219.148059 649.10694 -219.148059 C 649.10694 -219.148059 633.638525 -196.516116 641.977642 -176.261533 C 650.31676 -156.020382 619.963237 -157.215779 602.464053 -156.611365 C 594.729846 -162.574916 569.993345 -172.688775 561.178941 -174.475154 C 555.669939 -176.570456 544.84637 -176.839084 533.547514 -164.13296 C 526.893503 -156.302442 543.442114 -171.50681 559.839498 -170.311414 C 561.632624 -168.525035 562.756028 -166.738656 563.080087 -162.574916 C 554.330495 -163.165898 545.23524 -173.293189 534.519691 -152.447625 C 545.23524 -159.59314 546.574684 -159.445395 552.018875 -159.888632 C 559.256191 -160.425888 558.327222 -156.611365 558.024767 -156.611365 C 556.836551 -156.611365 553.876813 -156.020382 549.404799 -151.856642 C 547.028367 -148.874867 541.065682 -142.320333 542.253898 -142.320333 C 543.463718 -142.320333 547.006763 -145.893091 552.580577 -148.874867 C 556.080414 -151.252228 559.385815 -151.252228 569.906929 -148.283884 C 627.67584 -134.583835 658.634273 -144.711126 662.220526 -150.070263 C 664.013652 -152.447625 628.864056 -219.739042 722.365869 -237.602831 C 721.177653 -238.207245 683.068319 -248.321105 683.068319 -248.925519 Z M 683.068319 -248.925519 "
      transform="matrix(0.180812,0,0,0.290829,281.235237,212.332322)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:0.625;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 472.841916 -212.601586 C 505.596313 -185.201488 726.531553 -241.774631 754.522345 -261.438231 C 770.604126 -272.747487 798.594919 -281.088399 798.594919 -242.379045 C 805.740326 -268.583747 805.740326 -275.124849 805.740326 -274.533866 C 806.33116 -273.942884 817.040039 -264.406575 826.585711 -232.251754 C 828.967514 -260.833817 822.412941 -277.515642 825.976413 -275.124849 C 828.358215 -277.515642 828.358215 -260.833817 847.412633 -237.610891 C 843.258327 -256.065663 842.058194 -264.406575 842.058194 -264.406575 C 842.058194 -264.406575 877.785228 -229.874393 898.039779 -225.106238 C 918.29433 -220.338084 947.466792 -234.629116 956.403166 -226.301635 C 965.339541 -217.960723 980.812024 -203.078709 980.221189 -191.165038 C 979.630354 -179.251368 946.875957 -189.37866 943.294022 -179.842351 C 939.730551 -170.319474 945.675824 -170.319474 945.675824 -170.319474 C 945.675824 -170.319474 948.648461 -163.173958 951.048727 -157.210407 C 952.821231 -161.387579 949.25776 -170.319474 955.812332 -170.910456 C 960.575936 -170.319474 960.575936 -162.582975 961.757606 -158.405804 C 963.142374 -163.173958 961.554506 -172.696835 965.930375 -172.696835 C 970.69398 -173.69076 971.284815 -165.161808 974.86675 -159.6012 L 974.86675 -172.696835 C 988.566729 -176.874007 1000.457276 -177.464989 1002.248244 -186.396884 C 1004.039212 -195.328779 981.402858 -215.583361 976.048419 -228.678996 C 970.69398 -241.774631 980.221189 -247.133768 970.103145 -254.292715 C 959.966638 -261.438231 921.266967 -242.970028 903.394218 -250.115544 C 885.539933 -257.261059 892.68534 -308.475066 825.385578 -319.797754 C 758.085817 -331.10701 575.868712 -260.242835 543.70515 -257.261059 C 511.541587 -254.292715 472.251082 -267.38835 472.251082 -267.38835 Z M 472.841916 -212.601586 "
      transform="matrix(0.211565,0,0,0.290829,232.310806,223.416698)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:0.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 420.423713 -263.216657 C 420.423713 -263.216657 391.839296 -300.73642 368.619274 -304.315619 C 345.401932 -307.290863 322.759985 -290.020728 317.994003 -292.396744 C 313.22992 -294.78319 311.44821 -291.799156 308.466112 -286.447296 C 305.500281 -281.0817 309.072982 -277.511168 309.068403 -278.104691 C 310.250778 -278.105036 313.22926 -287.046019 314.425221 -287.636581 C 315.621182 -288.227144 308.477292 -276.919126 315.617029 -275.72754 C 318.004523 -275.131402 319.198463 -284.063089 322.176134 -287.637686 C 322.762818 -287.045989 317.404253 -275.132882 320.392017 -274.535264 C 323.361616 -273.940951 326.932956 -286.448715 332.298121 -289.427174 C 340.029782 -294.78754 351.35231 -296.574067 365.651696 -296.568806 C 379.943011 -292.394636 409.108598 -257.861867 412.089102 -258.461725 Z M 420.423713 -263.216657 "
      transform="matrix(0.208146,0.0659688,0.0378828,-0.36249,245.887573,32.711233)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:0.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 420.42698 -263.222217 C 420.42698 -263.222217 391.845352 -300.735964 368.618164 -304.305303 C 345.390977 -307.291649 322.773087 -290.016051 318.009482 -292.39561 C 313.245878 -294.787067 311.45491 -291.80072 308.46381 -286.446712 C 305.491173 -281.080807 309.073108 -277.511469 309.073108 -278.106358 C 310.254777 -278.106358 313.245878 -287.041602 314.427547 -287.636492 C 315.62768 -288.231382 308.46381 -276.916579 315.62768 -275.726799 C 318.009482 -275.13191 319.191152 -284.067154 322.163789 -287.636492 C 322.773087 -287.041602 317.400184 -275.13191 320.391285 -274.53702 C 323.363921 -273.94213 326.927393 -286.446712 332.300296 -289.421161 C 340.036537 -294.787067 351.354714 -296.571736 365.645528 -296.571736 C 379.936341 -292.39561 409.108803 -257.856311 412.099903 -258.451201 Z M 420.42698 -263.222217 "
      transform="matrix(0.211565,0,0,0.328317,234.704665,235.701616)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:0.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 417.451166 -267.979333 L 385.896902 -252.492905 L 409.12409 -246.542786 L 392.451474 -227.4836 L 416.269497 -235.220098 L 414.478529 -209.61981 L 428.178508 -226.288203 L 437.114882 -203.065277 L 446.642091 -220.338084 L 457.960268 -201.278898 L 462.723873 -219.747102 L 476.423851 -201.278898 L 481.187456 -226.288203 L 497.860072 -210.815207 L 495.47827 -237.006477 L 515.714357 -230.465375 L 503.214511 -250.706526 C 503.214511 -250.706526 525.241566 -251.901922 524.059897 -251.901922 C 522.859764 -251.901922 507.387281 -264.997558 493.687302 -268.570315 Z M 417.451166 -267.979333 "
      transform="matrix(0.211565,0,0,0.290829,232.310806,223.416698)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:0.80806;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 417.45607 -267.985704 L 385.894388 -252.49986 L 409.127293 -246.547414 L 392.436019 -227.480617 L 416.275878 -235.235396 L 414.488732 -209.62328 L 428.178948 -226.29487 L 437.114681 -203.077961 L 446.623649 -220.342425 L 457.953483 -201.275627 L 462.707967 -219.749552 L 476.431903 -201.275627 L 481.186387 -226.29487 L 497.843941 -210.809026 L 495.483559 -237.014015 L 515.715406 -230.468697 L 503.20538 -250.721241 C 503.20538 -250.721241 525.258093 -251.906987 524.044183 -251.906987 C 522.863992 -251.906987 507.386629 -264.997624 493.696412 -268.578577 Z M 417.45607 -267.985704 "
      transform="matrix(0.115845,0,0,0.164717,275.800129,187.641791)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:0.5;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 441.869156 -316.815963 C 441.869156 -316.815963 432.932782 -323.961479 429.36931 -316.815963 C 425.787375 -309.657016 437.105552 -312.638791 437.105552 -311.443395 C 437.105552 -310.26143 429.36931 -301.920518 429.36931 -292.39764 C 429.36931 -282.861332 435.314584 -266.783921 435.314584 -264.40656 C 435.314584 -262.015767 437.696386 -255.474665 446.041926 -253.083872 C 454.369002 -250.706511 457.360103 -260.833802 459.132607 -262.015767 C 460.923574 -263.211163 472.241751 -260.229388 476.414521 -270.356679 C 480.587291 -280.48397 480.587291 -298.938742 480.587291 -298.938742 C 480.587291 -298.938742 487.732697 -293.579605 487.732697 -304.297879 C 487.732697 -315.029584 479.977992 -304.297879 479.977992 -304.297879 C 479.977992 -304.297879 481.178125 -315.620567 462.714542 -320.979703 C 444.250958 -326.33884 442.459991 -315.620567 441.869156 -316.815963 Z M 441.869156 -316.815963 "
      transform="matrix(0.211565,0,0,0.290829,232.56278,223.416693)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,0%);fill-opacity:1;stroke-width:0.54015;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 441.480966 -317.312933 C 439.102616 -322.138711 432.344807 -340.633062 432.344807 -340.633062 L 448.549533 -333.688213 C 443.010038 -335.059466 438.29387 -335.871359 438.881842 -335.864731 C 439.489747 -335.867362 446.036495 -326.93539 446.036495 -326.93539 C 452.480731 -327.308502 458.933843 -325.158736 466.872296 -323.428497 C 466.872296 -323.428497 473.423576 -330.71433 472.835605 -330.720958 C 472.247298 -330.718106 469.269918 -331.908731 469.269918 -331.908731 L 484.157664 -338.740196 L 479.381352 -329.528045 L 476.400956 -329.523704 L 470.459089 -322.837985 C 477.159284 -321.576942 480.418422 -320.591791 486.975558 -316.392281 C 492.789107 -317.22774 497.699425 -320.245246 500.819026 -322.524208 C 495.615059 -323.958067 493.223306 -325.075703 493.831211 -325.078334 C 494.419518 -325.081187 515.712128 -323.959144 515.712128 -323.959144 C 515.712128 -323.959144 494.825227 -311.040106 482.619202 -306.302856 C 471.32278 -309.550472 442.672654 -315.525968 441.480966 -317.312933 Z M 441.480966 -317.312933 "
      transform="matrix(0.199229,-0.0046428,0.00704068,0.411872,239.821367,263.47261)"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 327.578125 136.3125 C 328.304688 136.785156 328.601562 137.121094 328.242188 137.070312 C 327.886719 137.015625 327.007812 136.589844 326.285156 136.121094 C 325.558594 135.648438 325.257812 135.3125 325.617188 135.363281 C 325.972656 135.417969 326.851562 135.84375 327.578125 136.3125 Z M 327.578125 136.3125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 331.335938 138.386719 C 332.0625 138.855469 332.359375 139.195312 332.003906 139.140625 C 331.644531 139.089844 330.769531 138.664062 330.042969 138.191406 C 329.316406 137.722656 329.019531 137.382812 329.375 137.4375 C 329.734375 137.488281 330.609375 137.914062 331.335938 138.386719 Z M 331.335938 138.386719 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 328.925781 144.140625 C 330.339844 144.800781 330.917969 145.277344 330.222656 145.199219 C 329.527344 145.125 327.820312 144.53125 326.410156 143.867188 C 324.996094 143.207031 324.417969 142.734375 325.113281 142.808594 C 325.808594 142.882812 327.515625 143.480469 328.925781 144.140625 Z M 328.925781 144.140625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 328.691406 141.394531 L 328.695312 141.394531 C 329.324219 141.722656 329.585938 141.960938 329.273438 141.921875 C 328.960938 141.886719 328.199219 141.589844 327.566406 141.261719 C 326.933594 140.933594 326.675781 140.695312 326.984375 140.734375 C 327.296875 140.769531 328.0625 141.066406 328.691406 141.394531 Z M 328.691406 141.394531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,0%,0%);fill-opacity:1;"
      d="M 327.773438 142.074219 C 327.960938 141.324219 328.140625 140.984375 328.175781 141.308594 C 328.207031 141.632812 328.078125 142.503906 327.890625 143.253906 C 327.699219 144 327.519531 144.34375 327.488281 144.015625 C 327.457031 143.691406 327.582031 142.820312 327.773438 142.074219 Z M 327.773438 142.074219 "
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,45.098039%,0%);fill-opacity:1;stroke-width:0.375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 737.048753 319.499755 C 738.711162 324.242535 737.861789 319.499755 736.094912 326.843415 C 737.185016 324.657381 738.747499 323.406959 739.374309 323.406959 C 740.309982 323.406959 740.777818 323.406959 742.653706 324.813317 C 740.936792 322.312471 737.966257 322.939153 738.125231 319.187885 C 737.966257 318.328771 737.125969 319.270266 737.048753 319.499755 Z M 737.048753 319.499755 "
      transform="matrix(0.860009,0,0,1.327676,-296.083705,-128.492908)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(69.803922%,50.196078%,18.039216%);fill-opacity:1;stroke-width:0.10288;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 709.484983 717.589518 C 709.862632 717.589877 710.168665 717.815121 710.170791 718.095738 L 710.170757 735.124789 C 710.167791 735.402421 709.863438 735.62849 709.48579 735.628131 C 709.108141 735.627772 708.802108 735.402528 708.799981 735.121912 L 708.799135 718.096514 C 708.802101 717.818882 709.107334 717.589159 709.484983 717.589518 Z M 709.484983 717.589518 "
      transform="matrix(0.672181,-0.549102,0.161949,0.936817,-271.0875,-12.897)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 734.058934 312.160756 C 734.842771 311.692694 740.62282 301.065179 768.122926 302.628172 C 752.494058 307.939839 742.806792 319.190044 740.467249 319.658106 C 738.749988 320.126168 734.998342 313.878376 734.058934 312.160756 Z M 734.058934 312.160756 "
      transform="matrix(0.652838,0,0,0.934705,-145.58904,-9.16892)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3819.304577 -4447.103369 L -3826.719172 -4449.858058 L -3811.577484 -4438.456816 L -3814.500675 -4443.83284 Z M -3819.304577 -4447.103369 "
      transform="matrix(-0.420538,-1.001322,0.257004,0.907041,-139.754143,479.097212)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3819.308329 -4447.107357 L -3824.607752 -4446.828692 L -3811.911136 -4439.244411 L -3814.495006 -4443.824235 Z M -3819.308329 -4447.107357 "
      transform="matrix(0.0603737,-0.646814,-0.239617,0.511626,-512.247413,74.073574)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3819.91572 -4447.613788 L -3815.233423 -4440.177436 L -3810.835947 -4438.937547 L -3814.422267 -4443.901138 Z M -3819.91572 -4447.613788 "
      transform="matrix(-1.114311,-0.4506,1.245827,0.615179,1607.836732,1282.557929)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3821.793494 -4448.693322 L -3804.275981 -4426.301834 L -3810.934392 -4438.775265 L -3814.401579 -4443.861773 Z M -3821.793494 -4448.693322 "
      transform="matrix(-0.449951,0.301185,0.626947,-0.143864,1391.853279,777.529789)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3822.216139 -4449.91152 L -3834.69398 -4456.406731 L -3810.638863 -4437.741332 L -3814.449277 -4443.872203 Z M -3822.216139 -4449.91152 "
      transform="matrix(0.804039,0.0656891,-0.965473,-0.233244,-900.835695,-517.963122)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3823.496893 -4450.197031 L -3828.730152 -4451.420374 L -3810.925422 -4438.106086 L -3814.483569 -4443.892028 Z M -3823.496893 -4450.197031 "
      transform="matrix(1.229804,0.622021,-1.342972,-0.780171,-954.248597,-826.211797)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3824.68981 -4451.690568 L -3838.105376 -4461.445704 L -3813.733193 -4440.464221 L -3814.472805 -4443.79268 Z M -3824.68981 -4451.690568 "
      transform="matrix(1.436467,1.182499,-1.451711,-1.281439,-649.313353,-915.861642)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3822.168133 -4449.266716 L -3811.585277 -4434.874918 L -3809.872699 -4437.472972 L -3814.450788 -4443.852088 Z M -3822.168133 -4449.266716 "
      transform="matrix(1.124904,1.332382,-1.032653,-1.333415,24.384699,-575.189362)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3820.18107 -4446.978435 L -3804.595262 -4427.743715 L -3807.94456 -4435.484608 L -3814.48396 -4443.81259 Z M -3820.18107 -4446.978435 "
      transform="matrix(0.754962,1.195409,-0.615804,-1.139428,465.636201,-235.635897)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3821.007431 -4448.705997 L -3808.790305 -4431.571333 L -3808.909862 -4436.830634 L -3814.465751 -4443.805854 Z M -3821.007431 -4448.705997 "
      transform="matrix(0.156717,0.816761,0.0179766,-0.698167,999.989236,280.686559)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3818.917845 -4446.91967 L -3816.926278 -4442.636685 L -3812.720134 -4440.542248 L -3814.485733 -4443.884058 Z M -3818.917845 -4446.91967 "
      transform="matrix(-0.933502,-1.275184,0.813482,1.24415,376.475084,932.578497)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3819.27803 -4447.074226 L -3819.301279 -4441.319457 L -3812.649954 -4439.954946 L -3814.501559 -4443.834164 Z M -3819.27803 -4447.074226 "
      transform="matrix(-0.411584,-0.69663,0.280033,0.607413,-3.695827,309.261159)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3819.292042 -4447.094007 L -3819.305807 -4441.322981 L -3812.689493 -4439.99799 L -3814.472895 -4443.802856 Z M -3819.292042 -4447.094007 "
      transform="matrix(-0.692173,-0.267118,0.738171,0.375704,961.851565,918.094119)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3819.322161 -4447.118043 L -3819.300027 -4441.31891 L -3812.642879 -4439.957765 L -3814.496319 -4443.825593 Z M -3819.322161 -4447.118043 "
      transform="matrix(0.581332,0.13194,-0.676485,-0.234914,-466.597508,-273.00891)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3819.301608 -4447.101517 L -3803.616133 -4428.148513 L -3813.82188 -4441.884391 L -3814.490282 -4443.817952 Z M -3819.301608 -4447.101517 "
      transform="matrix(0.204615,0.594188,-0.107096,-0.53874,626.636459,140.109323)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3819.291781 -4447.091483 L -3808.359379 -4433.93004 L -3812.662842 -4439.973153 L -3814.46903 -4443.799833 Z M -3819.291781 -4447.091483 "
      transform="matrix(1.350935,1.318258,-1.312384,-1.372499,-357.07654,-803.200478)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3819.291907 -4447.093309 L -3833.727368 -4459.686269 L -3815.440914 -4442.874889 L -3814.512283 -4443.839616 Z M -3819.291907 -4447.093309 "
      transform="matrix(1.197968,0.572308,-1.316789,-0.732661,-960.196789,-805.417129)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3819.275816 -4447.070742 L -3819.289408 -4441.308733 L -3812.663732 -4439.970841 L -3814.49943 -4443.829296 Z M -3819.275816 -4447.070742 "
      transform="matrix(-0.298858,-0.701125,0.183693,0.634246,-1.406127,411.888352)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.375;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 759.391386 305.011145 C 756.152868 306.076211 754.563132 306.582265 752.923434 306.791159 C 753.75918 307.150104 755.6169 306.932383 757.733519 307.503164 C 755.844005 307.544355 752.360213 308.983077 750.906741 309.018383 C 751.910545 309.618586 755.485179 309.038978 756.143784 309.197855 C 754.231559 309.315542 751.152014 310.798396 749.235247 310.798396 C 748.358622 311.469211 753.695591 310.339418 755.839463 311.604551 C 753.622917 311.489807 749.321547 312.763767 746.986907 312.457781 C 748.767411 313.49931 751.94234 312.345978 754.590385 313.475772 C 753.82277 313.558153 747.677307 314.246621 745.506182 313.92004 C 746.805223 314.57026 750.720515 314.464342 751.68344 315.041007 C 750.06191 314.829171 745.755998 315.729475 744.257104 315.370531 C 745.606108 316.344389 749.671289 315.797145 750.420736 316.379695 C 747.763607 317.306479 743.734763 316.567994 741.886127 316.818079 C 743.126121 317.400629 744.152636 317.986121 745.251824 318.530423 C 746.855186 319.657274 751.860582 322.823051 754.849285 323.776314 C 751.542635 323.340873 750.384399 323.284971 748.245069 323.111383 L 753.114202 328.26901 C 751.083883 327.174522 747.504707 325.109118 745.124646 324.041109 C 739.86035 321.463767 736.067695 321.908035 732.420388 319.727886 C 727.301439 314.761501 724.458084 308.841852 724.199184 307.261906 C 723.944826 305.684902 723.717721 302.854534 719.048441 304.328561 C 723.558748 303.748954 725.452804 298.538368 725.807088 298.747262 C 725.966062 298.835528 726.383935 302.230793 727.442245 303.598903 C 728.150812 304.581588 734.155471 307.947432 735.150191 308.126905 C 744.093589 302.813343 751.347325 302.160181 759.391386 305.011145 Z M 759.391386 305.011145 "
      transform="matrix(0.860009,0,0,1.327676,-295.411861,-127.241105)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 803.76203 -352.620961 C 804.526967 -352.618612 805.149436 -350.12944 805.152268 -347.056117 C 805.152225 -343.985701 804.527192 -341.493039 803.758873 -341.492044 C 802.993936 -341.494392 802.371467 -343.983564 802.368635 -347.056887 C 802.368678 -350.127304 802.993711 -352.619965 803.76203 -352.620961 Z M 803.76203 -352.620961 "
      transform="matrix(0.583929,0.671989,-0.57758,0.679376,-268.811773,-12.897015)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,37.647059%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 801.328734 335.451221 C 801.119469 334.278007 800.981544 333.685268 801.576048 332.148236 C 802.43689 331.064955 802.89347 330.104309 804.467717 328.984238 C 806.175133 328.420114 806.75537 328.927008 808.995462 326.850379 C 809.718379 325.480948 810.817023 324.442633 811.440063 322.966918 C 812.900166 320.788093 813.390038 319.712987 814.560022 318.041056 C 816.110489 316.29963 817.361326 314.73807 818.269729 313.40543 C 819.23996 311.69262 820.110314 310.637954 821.513344 309.231733 C 822.084069 307.625206 823.301613 306.435641 824.75696 305.058035 C 825.869872 303.026372 828.005331 300.696296 828.932758 299.03254 C 830.57359 297.152127 831.215655 296.334557 832.637709 294.396915 C 833.893302 293.56708 836.009737 291.752074 837.740934 291.151159 C 840.099927 290.161899 841.360276 289.180814 842.910743 290.685144 C 843.938047 291.715283 845.788144 293.366776 844.504015 294.589044 C 843.828658 295.333033 842.725258 295.427054 841.450641 294.858842 C 840.137975 294.012656 839.500667 291.543593 837.740934 293.468972 C 836.466317 294.924248 835.81474 296.085198 834.031227 297.64267 C 833.146605 298.946695 832.50454 299.919604 831.715038 301.81228 C 830.663955 303.022284 829.798356 304.383539 828.932758 306.451993 C 827.810334 307.379935 826.840103 309.219469 826.150478 310.621603 C 825.037566 311.786641 824.233796 312.984382 823.368198 314.7953 C 821.617977 316.47132 820.961644 317.889805 819.658491 318.968998 C 818.797649 320.788093 817.442179 322.52543 816.414876 323.604623 C 815.601594 324.925 814.797824 326.298518 814.093931 327.774233 C 812.61956 329.282651 812.557732 329.605591 810.38898 331.947931 C 808.581687 334.302534 809.190459 334.020472 806.360619 336.74707 C 804.814907 337.258052 803.259684 337.43383 801.328734 335.451221 Z M 801.328734 335.451221 "
      transform="matrix(0.821325,0,0,0.955575,-273.995073,-13.962863)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 863.680715 -20.840134 C 864.4509 -20.840535 865.071155 -18.347658 865.070004 -15.274947 C 865.073257 -12.204032 864.450248 -9.712224 863.680063 -9.711823 C 862.914283 -9.713216 862.289623 -12.204299 862.290775 -15.277009 C 862.291926 -18.349719 862.91053 -20.839733 863.680715 -20.840134 Z M 863.680715 -20.840134 "
      transform="matrix(0.760614,0.360561,-0.309905,0.88494,-268.811773,-12.897015)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 864.828534 -48.487476 C 865.599599 -48.486667 866.220152 -45.997302 866.221488 -42.922981 C 866.221158 -39.852393 865.598784 -37.359314 864.832062 -37.36206 C 864.062662 -37.359135 863.440443 -39.852233 863.440773 -42.922821 C 863.439438 -45.997142 864.063477 -48.486488 864.828534 -48.487476 Z M 864.828534 -48.487476 "
      transform="matrix(0.750125,0.38917,-0.334495,0.872737,-268.811773,-12.897015)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 841.872048 -224.519123 C 842.640403 -224.519512 843.261197 -222.027894 843.261879 -218.957126 C 843.262561 -215.886358 842.639948 -213.394059 841.871594 -213.393671 C 841.103239 -213.393282 840.47859 -215.882115 840.481763 -218.955668 C 840.478687 -222.029749 841.103694 -224.518734 841.872048 -224.519123 Z M 841.872048 -224.519123 "
      transform="matrix(0.66575,0.559623,-0.480993,0.77457,-268.811773,-12.897015)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 774.992944 -420.330163 C 775.761775 -420.330605 776.381436 -417.838667 776.383149 -414.767385 C 776.381774 -411.692484 775.758268 -409.204714 774.989438 -409.204273 C 774.223717 -409.201178 773.600946 -411.695769 773.599233 -414.767051 C 773.597521 -417.838334 774.224114 -420.329722 774.992944 -420.330163 Z M 774.992944 -420.330163 "
      transform="matrix(0.533122,0.726906,-0.624782,0.620264,-268.811773,-12.897015)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 801.08224 -363.860582 C 801.849634 -363.859413 802.473908 -361.369972 802.471477 -358.296163 C 802.472382 -355.225744 801.84724 -352.731391 801.079846 -352.73256 C 800.312453 -352.733729 799.688178 -355.223171 799.690609 -358.29698 C 799.689704 -361.367399 800.31151 -363.858362 801.08224 -363.860582 Z M 801.08224 -363.860582 "
      transform="matrix(0.576094,0.681077,-0.585391,0.670259,-268.811773,-12.897015)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 845.548487 -224.23015 C 846.316842 -224.230539 846.943885 -221.738392 846.940712 -218.664839 C 846.941394 -215.594071 846.318781 -213.101772 845.550427 -213.101383 C 844.782072 -213.100994 844.161278 -215.592613 844.158202 -218.666694 C 844.161375 -221.740247 844.780133 -224.229761 845.548487 -224.23015 Z M 845.548487 -224.23015 "
      transform="matrix(0.66575,0.559623,-0.480993,0.77457,-268.811773,-12.897015)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 843.681694 -224.621277 C 844.450048 -224.621666 845.070843 -222.130048 845.073919 -219.055966 C 845.070745 -215.982413 844.448133 -213.490115 843.681239 -213.495825 C 842.912885 -213.495436 842.288235 -215.984269 842.291409 -219.057822 C 842.290727 -222.12859 842.913339 -224.620888 843.681694 -224.621277 Z M 843.681694 -224.621277 "
      transform="matrix(0.66575,0.559623,-0.480993,0.77457,-268.811773,-12.897015)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 840.181519 -244.569129 C 840.949879 -244.569519 841.570702 -242.077898 841.571412 -239.007126 C 841.572123 -235.936355 840.94953 -233.444052 840.18117 -233.443662 C 839.41281 -233.443272 838.791988 -235.934893 838.791277 -239.005665 C 838.788172 -242.07975 839.413159 -244.568739 840.181519 -244.569129 Z M 840.181519 -244.569129 "
      transform="matrix(0.665744,0.55962,-0.480998,0.774564,-273.995073,-13.962863)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 842.242198 -244.68118 C 843.010558 -244.68157 843.63138 -242.189949 843.632091 -239.119177 L 843.634485 -239.115864 C 843.631341 -236.042307 843.006353 -233.553318 842.237993 -233.552928 C 841.473489 -233.555323 840.848811 -236.044159 840.8481 -239.11493 L 840.851955 -239.117716 C 840.851244 -242.188487 841.473838 -244.68079 842.242198 -244.68118 Z M 842.242198 -244.68118 "
      transform="matrix(0.665744,0.55962,-0.480998,0.774564,-273.995073,-13.962863)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 844.392387 -244.661219 C 845.156892 -244.658823 845.781569 -242.169988 845.78228 -239.099216 C 845.78153 -236.022346 845.156542 -233.533357 844.388183 -233.532967 C 843.623678 -233.535362 842.999 -236.024198 842.998289 -239.094969 L 843.002144 -239.097755 C 843.001434 -242.168526 843.624027 -244.660829 844.392387 -244.661219 Z M 844.392387 -244.661219 "
      transform="matrix(0.665744,0.55962,-0.480998,0.774564,-273.995073,-13.962863)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 846.68988 -244.697253 C 847.45824 -244.697643 848.082917 -242.208807 848.079773 -239.13525 C 848.080484 -236.064479 847.457891 -233.572176 846.689531 -233.571786 C 845.921171 -233.571396 845.300348 -236.063017 845.299637 -239.133789 L 845.297243 -239.137102 C 845.300388 -242.210659 845.925375 -244.699648 846.68988 -244.697253 Z M 846.68988 -244.697253 "
      transform="matrix(0.665744,0.55962,-0.480998,0.774564,-273.995073,-13.962863)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 848.539617 -244.540907 C 849.307977 -244.541297 849.9288 -242.049676 849.931905 -238.975591 C 849.932616 -235.904819 849.310022 -233.412517 848.541662 -233.412127 C 847.773302 -233.411737 847.15248 -235.903358 847.149375 -238.977443 C 847.148664 -242.048214 847.771258 -244.540517 848.539617 -244.540907 Z M 848.539617 -244.540907 "
      transform="matrix(0.665744,0.55962,-0.480998,0.774564,-273.995073,-13.962863)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 859.231095 -245.749703 C 859.999455 -245.750093 860.620277 -243.258472 860.620988 -240.187701 C 860.624093 -237.113616 859.999105 -234.624627 859.230746 -234.624237 C 858.462386 -234.623847 857.841563 -237.115467 857.840852 -240.186239 C 857.840141 -243.257011 858.462735 -245.749313 859.231095 -245.749703 Z M 859.231095 -245.749703 "
      transform="matrix(0.665744,0.55962,-0.480998,0.774564,-273.995073,-13.962863)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 857.763745 -245.319942 C 858.52825 -245.317547 859.152927 -242.828712 859.149783 -239.755155 C 859.150494 -236.684383 858.527901 -234.192081 857.759541 -234.191691 C 856.991181 -234.191301 856.370359 -236.682921 856.369648 -239.753693 L 856.367254 -239.757007 C 856.370398 -242.830564 856.995385 -245.319552 857.763745 -245.319942 Z M 857.763745 -245.319942 "
      transform="matrix(0.665744,0.55962,-0.480998,0.774564,-273.995073,-13.962863)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 856.331181 -245.101911 C 857.099541 -245.102301 857.720364 -242.610681 857.723469 -239.536596 C 857.720324 -236.463039 857.097731 -233.970736 856.333226 -233.973132 C 855.564866 -233.972742 854.940189 -236.461577 854.940939 -239.538447 C 854.940228 -242.609219 855.562821 -245.101521 856.331181 -245.101911 Z M 856.331181 -245.101911 "
      transform="matrix(0.665744,0.55962,-0.480998,0.774564,-273.995073,-13.962863)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 854.220105 -245.019009 C 854.988465 -245.019399 855.613143 -242.530564 855.609999 -239.457007 C 855.613103 -236.382922 854.988116 -233.893933 854.219756 -233.893543 C 853.451396 -233.893153 852.830574 -236.384774 852.829863 -239.455545 C 852.833007 -242.529102 853.451745 -245.018619 854.220105 -245.019009 Z M 854.220105 -245.019009 "
      transform="matrix(0.665744,0.55962,-0.480998,0.774564,-273.995073,-13.962863)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 852.152964 -244.68039 C 852.917469 -244.677995 853.542147 -242.189159 853.542858 -239.118388 C 853.539713 -236.044831 852.91712 -233.552528 852.14876 -233.552138 C 851.384255 -233.554534 850.759578 -236.043369 850.758867 -239.114141 L 850.762722 -239.116926 C 850.759617 -242.191011 851.384604 -244.68 852.152964 -244.68039 Z M 852.152964 -244.68039 "
      transform="matrix(0.665744,0.55962,-0.480998,0.774564,-273.995073,-13.962863)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 850.351669 -244.63975 C 851.120029 -244.64014 851.740851 -242.148519 851.741562 -239.077747 C 851.740812 -236.000877 851.11968 -233.514673 850.35132 -233.514283 C 849.58296 -233.513893 848.958282 -236.002729 848.961426 -239.076286 C 848.958321 -242.150371 849.583309 -244.63936 850.351669 -244.63975 Z M 850.351669 -244.63975 "
      transform="matrix(0.665744,0.55962,-0.480998,0.774564,-273.995073,-13.962863)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 861.199861 -115.048883 C 861.966973 -115.051055 862.593641 -112.558868 862.592262 -109.488882 C 862.592832 -106.415303 861.969219 -103.923961 861.202108 -103.921789 C 860.433048 -103.92321 859.812509 -106.414072 859.811939 -109.487651 C 859.811369 -112.56123 860.430801 -115.050305 861.199861 -115.048883 Z M 861.199861 -115.048883 "
      transform="matrix(0.721978,0.45558,-0.391575,0.839989,-269.133609,-12.897029)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 840.179822 -244.56882 C 840.948182 -244.56921 841.572859 -242.080374 841.569715 -239.006817 C 841.57282 -235.932732 840.947832 -233.443743 840.179473 -233.443353 C 839.411113 -233.442963 838.79029 -235.934584 838.789579 -239.005356 C 838.792723 -242.078912 839.411462 -244.56843 840.179822 -244.56882 Z M 840.179822 -244.56882 "
      transform="matrix(0.665744,0.55962,-0.480998,0.774564,-278.626606,-7.387934)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 842.2405 -244.680871 C 843.00886 -244.681261 843.629683 -242.18964 843.632788 -239.115555 C 843.633498 -236.044783 843.010905 -233.552481 842.242545 -233.552091 C 841.474185 -233.551701 840.847114 -236.04385 840.850258 -239.117407 C 840.849547 -242.188178 841.47214 -244.680481 842.2405 -244.680871 Z M 842.2405 -244.680871 "
      transform="matrix(0.665744,0.55962,-0.480998,0.774564,-278.626606,-7.387934)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 844.39069 -244.66091 C 845.159049 -244.6613 845.779872 -242.169679 845.782977 -239.095594 C 845.783688 -236.024822 845.161094 -233.53252 844.392734 -233.53213 C 843.624374 -233.53174 842.997303 -236.023889 843.000447 -239.097446 C 842.999736 -242.168217 843.62233 -244.66052 844.39069 -244.66091 Z M 844.39069 -244.66091 "
      transform="matrix(0.665744,0.55962,-0.480998,0.774564,-278.626606,-7.387934)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 846.692038 -244.699729 C 847.460397 -244.700119 848.08122 -242.208498 848.081931 -239.137727 C 848.078787 -236.06417 847.460048 -233.574653 846.691688 -233.574263 C 845.923328 -233.573873 845.298651 -236.062708 845.301795 -239.136265 C 845.29869 -242.21035 845.923678 -244.699339 846.692038 -244.699729 Z M 846.692038 -244.699729 "
      transform="matrix(0.665744,0.55962,-0.480998,0.774564,-278.626606,-7.387934)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 848.540314 -244.537284 C 849.308674 -244.537674 849.933351 -242.048839 849.930207 -238.975282 C 849.930918 -235.90451 849.308325 -233.412208 848.539965 -233.411818 C 847.771605 -233.411428 847.150782 -235.903049 847.147677 -238.977134 C 847.150822 -242.050691 847.773415 -244.542993 848.540314 -244.537284 Z M 848.540314 -244.537284 "
      transform="matrix(0.665744,0.55962,-0.480998,0.774564,-278.626606,-7.387934)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 859.229397 -245.749394 C 859.997757 -245.749784 860.622435 -243.260949 860.619291 -240.187392 L 860.621685 -240.184078 C 860.622396 -237.113307 859.997408 -234.624318 859.229048 -234.623928 C 858.460688 -234.623538 857.839866 -237.115158 857.839155 -240.18593 C 857.842299 -243.259487 858.461038 -245.749004 859.229397 -245.749394 Z M 859.229397 -245.749394 "
      transform="matrix(0.665744,0.55962,-0.480998,0.774564,-278.626606,-7.387934)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 857.762048 -245.319633 C 858.530408 -245.320023 859.15123 -242.828403 859.151941 -239.757631 C 859.148797 -236.684074 858.530058 -234.194557 857.761698 -234.194167 C 856.993339 -234.193777 856.368661 -236.682613 856.371805 -239.756169 C 856.3687 -242.830255 856.993688 -245.319243 857.762048 -245.319633 Z M 857.762048 -245.319633 "
      transform="matrix(0.665744,0.55962,-0.480998,0.774564,-278.626606,-7.387934)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 856.331878 -245.098289 C 857.100238 -245.098679 857.72106 -242.607058 857.721771 -239.536287 C 857.722482 -236.465515 857.099888 -233.973213 856.331529 -233.972823 C 855.563169 -233.972433 854.942346 -236.464053 854.939241 -239.538138 C 854.93853 -242.60891 855.561124 -245.101212 856.331878 -245.098289 Z M 856.331878 -245.098289 "
      transform="matrix(0.665744,0.55962,-0.480998,0.774564,-278.626606,-7.387934)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 854.222263 -245.021486 C 854.986768 -245.01909 855.611445 -242.530255 855.612156 -239.459483 L 855.61455 -239.45617 C 855.611406 -236.382613 854.986418 -233.893624 854.218059 -233.893234 C 853.453554 -233.895629 852.828876 -236.384465 852.828165 -239.455236 C 852.831309 -242.528793 853.453903 -245.021096 854.222263 -245.021486 Z M 854.222263 -245.021486 "
      transform="matrix(0.665744,0.55962,-0.480998,0.774564,-278.626606,-7.387934)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 852.151267 -244.680081 C 852.919627 -244.680471 853.540449 -242.18885 853.54116 -239.118079 C 853.544265 -236.043994 852.919277 -233.555005 852.150918 -233.554615 C 851.382558 -233.554225 850.75788 -236.04306 850.761024 -239.116617 C 850.760313 -242.187389 851.382907 -244.679691 852.151267 -244.680081 Z M 852.151267 -244.680081 "
      transform="matrix(0.665744,0.55962,-0.480998,0.774564,-278.626606,-7.387934)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(89.411765%,80%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 850.349971 -244.639441 C 851.118331 -244.639831 851.743009 -242.150995 851.739865 -239.077438 C 851.74297 -236.003353 851.117982 -233.514364 850.349622 -233.513974 C 849.581262 -233.513584 848.96044 -236.005205 848.959729 -239.075977 C 848.959018 -242.146748 849.581612 -244.639051 850.349971 -244.639441 Z M 850.349971 -244.639441 "
      transform="matrix(0.665744,0.55962,-0.480998,0.774564,-278.626606,-7.387934)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(69.803922%,50.196078%,18.039216%);fill-opacity:1;stroke-width:0.1723;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 776.320363 -28.089027 C 777.110159 -28.090592 777.755093 -27.784821 777.752384 -27.411262 L 777.752852 -4.580266 C 777.751601 -4.202426 777.111658 -3.898391 776.320404 -3.901106 C 775.530607 -3.899541 774.89179 -4.202619 774.888382 -4.578872 L 774.892573 -27.411455 C 774.889165 -27.787708 775.530566 -28.087462 776.320363 -28.089027 Z M 776.320363 -28.089027 "
      transform="matrix(0.751269,0.278591,-0.255972,0.817653,-251.915311,9.22265)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(69.803922%,50.196078%,18.039216%);fill-opacity:1;stroke-width:0.1723;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 728.870719 221.74173 C 729.663112 221.74173 730.302933 222.044711 730.302933 222.420047 L 730.302933 245.252186 C 730.302933 245.627521 729.663112 245.930503 728.870719 245.930503 C 728.083247 245.930503 727.438504 245.627521 727.438504 245.252186 L 727.438504 222.420047 C 727.438504 222.044711 728.083247 221.74173 728.870719 221.74173 Z M 728.870719 221.74173 "
      transform="matrix(0.793679,0,0,0.863811,-251.915311,9.22265)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(69.803922%,50.196078%,18.039216%);fill-opacity:1;stroke-width:0.1723;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 709.400478 221.280475 C 710.192872 221.280475 710.832692 221.583456 710.832692 221.958791 L 710.832692 244.790931 C 710.832692 245.166266 710.192872 245.469247 709.400478 245.469247 C 708.613006 245.469247 707.968264 245.166266 707.968264 244.790931 L 707.968264 221.958791 C 707.968264 221.583456 708.613006 221.280475 709.400478 221.280475 Z M 709.400478 221.280475 "
      transform="matrix(0.793679,0,0,0.863811,-251.915311,9.22265)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,27.058824%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 692.67937 226.062086 C 692.980247 221.162826 696.821531 219.65479 703.081714 221.842955 C 703.608127 222.144228 704.061843 221.237113 703.908434 221.164493 C 693.884751 215.891027 689.419006 201.700909 705.467592 204.263054 C 707.874554 205.017386 703.293105 206.40728 699.961108 207.020845 C 695.43564 207.595392 698.255075 213.850492 707.221892 219.6542 C 709.18112 221.162233 713.555232 224.250559 713.555232 224.250559 C 713.555232 224.250559 711.97079 227.042086 711.819666 227.042266 C 711.668541 227.042447 699.615995 222.66729 696.750046 226.436836 C 694.936328 229.451957 697.43636 229.88492 696.834581 234.406946 C 695.778359 238.321956 695.255711 237.87305 695.027138 234.334282 C 695.808448 231.864396 692.677136 228.017085 692.67937 226.062086 Z M 692.67937 226.062086 "
      transform="matrix(0.335369,0.517196,-0.547092,0.198401,210.986835,-213.004195)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,27.058824%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 692.68002 226.062314 C 692.981632 221.159709 696.825746 219.657243 703.08271 221.839839 C 703.60853 222.142133 704.060127 221.240138 703.908838 221.164204 C 693.887204 215.887255 697.711566 201.987948 713.761316 204.548475 C 716.174652 205.303779 715.707161 206.162013 712.368081 206.77776 C 707.852359 207.351254 698.253833 213.851125 707.226099 219.653283 C 709.182125 221.160249 713.550366 224.249041 713.550366 224.249041 C 713.550366 224.249041 711.973278 227.043446 711.822632 227.037962 C 711.670182 227.038498 699.613603 222.671695 696.752006 226.437183 C 694.941453 229.454075 697.845076 232.25161 697.242561 236.778959 C 696.185748 240.692745 695.660444 240.243532 695.432192 236.697697 C 695.509171 236.102023 692.681172 228.018632 692.68002 226.062314 Z M 692.68002 226.062314 "
      transform="matrix(0.305517,0.538655,-0.557269,0.161419,234.528108,-215.910841)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,27.058824%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 692.68068 226.058193 C 692.981504 221.165947 696.823588 219.653879 703.076182 221.841241 C 703.605205 222.143131 704.061867 221.24089 703.910918 221.163581 C 693.885844 215.887468 701.265474 209.237677 715.894568 212.062908 C 717.483865 212.26051 713.801166 214.225588 710.462641 214.843525 C 705.941867 215.41246 698.255115 213.850289 707.225921 219.653449 C 709.184311 221.166083 713.553555 224.250986 713.553555 224.250986 C 713.553555 224.250986 711.973422 227.038663 711.820121 227.042871 C 711.668072 227.040779 699.612688 222.669372 696.749958 226.435217 C 694.937199 229.451705 703.819423 235.874453 703.217495 240.399032 C 702.161881 244.312641 701.633805 243.866618 701.409279 240.326483 C 701.48142 239.719879 692.678701 228.019362 692.68068 226.058193 Z M 692.68068 226.058193 "
      transform="matrix(0.264716,0.563655,-0.567395,0.112109,265.806955,-217.760724)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,27.058824%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 692.68046 226.059264 C 692.981593 221.163315 696.820876 219.655242 703.081522 221.843125 C 703.60896 222.142359 704.056821 221.239469 703.90592 221.159983 C 693.887057 215.891305 693.712912 204.757643 709.766044 207.32683 C 712.173198 208.078618 707.666859 209.48704 704.331036 210.105308 C 699.813686 210.6764 698.254963 213.852717 707.226063 219.658527 C 709.184256 221.162174 713.554517 224.251149 713.554517 224.251149 C 713.554517 224.251149 711.973615 227.041392 711.822129 227.040295 C 711.670643 227.039198 699.609908 222.670514 696.748521 226.433129 C 694.939769 229.45402 695.017747 229.602725 694.415856 234.12557 C 693.355108 238.041005 692.832669 237.594531 692.600164 234.049306 C 692.678743 233.446651 692.680288 228.02082 692.68046 226.059264 Z M 692.68046 226.059264 "
      transform="matrix(0.339171,0.514253,-0.545615,0.203168,192.693301,-212.351816)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,27.058824%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 692.678393 226.060432 C 692.980419 221.163586 696.822508 219.655965 703.076812 221.837156 C 703.609446 222.140615 704.057176 221.237716 703.906157 221.160692 C 693.888413 215.887451 693.711081 204.759702 709.765833 207.323308 C 712.177998 208.075693 707.673002 209.488348 704.333938 210.10277 C 699.812067 210.677097 698.258691 213.85188 707.22271 219.655632 C 709.182666 221.160901 713.551158 224.252496 713.551158 224.252496 C 713.551158 224.252496 711.971979 227.0406 711.819163 227.039849 C 711.672561 227.042557 699.611832 222.66952 696.751955 226.437855 C 694.943123 229.449391 695.012829 229.600583 694.412299 234.120552 C 693.355911 238.042596 692.833087 237.59005 692.605155 234.049105 C 692.676691 233.44263 692.675735 228.018893 692.678393 226.060432 Z M 692.678393 226.060432 "
      transform="matrix(0.309471,0.535978,-0.55606,0.166275,216.210016,-215.461536)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,27.058824%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 692.6799 226.060309 C 692.977785 221.161593 696.826506 219.65789 703.079921 221.839549 C 703.607692 222.14517 704.061585 221.236723 703.909612 221.164602 C 693.882524 215.888971 693.711636 204.76244 709.766696 207.327767 C 712.177863 208.079024 707.666717 209.488994 704.330187 210.105021 C 699.812718 210.677723 698.252694 213.853621 707.223649 219.655369 C 709.18438 221.165027 713.551243 224.250928 713.551243 224.250928 C 713.551243 224.250928 711.97453 227.039693 711.820829 227.042594 C 711.668436 227.039221 699.615069 222.67047 696.748291 226.439707 C 694.937805 229.44848 695.016878 229.603217 694.415207 234.123343 C 693.361231 238.042714 692.829275 237.590317 692.607009 234.051237 C 692.678031 233.443728 692.681917 228.019383 692.6799 226.060309 Z M 692.6799 226.060309 "
      transform="matrix(0.268867,0.56133,-0.566552,0.117064,247.473039,-217.586511)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,27.058824%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 692.682865 226.059642 C 692.980279 221.159324 696.823279 219.657039 703.079288 221.838179 C 703.605179 222.141999 704.057057 221.24019 703.907864 221.159477 C 693.883221 215.886306 689.416911 201.697998 705.463963 204.265257 C 707.876323 205.014611 703.294464 206.406598 699.961333 207.022645 C 695.436477 207.596521 698.257568 213.855888 707.225964 219.658547 C 709.182812 221.159667 713.553173 224.250643 713.553173 224.250643 C 713.553173 224.250643 711.969042 227.037009 711.821832 227.038137 C 711.668178 227.037536 699.610512 222.668509 696.750721 226.440406 C 694.941561 229.450933 697.435871 229.883503 696.834787 234.404152 C 695.77737 238.321199 695.254453 237.875888 695.027787 234.332294 C 695.806639 231.864996 692.679058 228.016811 692.682865 226.059642 Z M 692.682865 226.059642 "
      transform="matrix(0.154791,0.610196,-0.576505,-0.0147798,359.376648,-231.784448)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,27.058824%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 692.678852 226.059814 C 692.982269 221.160368 696.826101 219.658671 703.081008 221.842287 C 703.60504 222.14211 704.056873 221.237132 703.905589 221.164739 C 693.884381 215.889703 697.714092 201.99158 713.764305 204.555786 C 716.174767 205.304063 715.706514 206.164821 712.372909 206.780536 C 707.849839 207.351729 698.255995 213.85241 707.225332 219.655538 C 709.184288 221.166096 713.555782 224.254768 713.555782 224.254768 C 713.555782 224.254768 711.9728 227.037756 711.821651 227.040174 C 711.670502 227.042591 699.614136 222.668107 696.747242 226.433795 C 694.938239 229.448302 697.847293 232.251873 697.240309 236.775179 C 696.184031 240.694751 695.65973 240.245307 695.430423 236.70303 C 695.511046 236.101218 692.677119 228.01739 692.678852 226.059814 Z M 692.678852 226.059814 "
      transform="matrix(0.120028,0.619396,-0.574584,-0.0533291,382.433029,-225.850066)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,27.058824%,0%);fill-opacity:1;stroke-width:0.25;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 692.68182 226.056849 C 692.983147 221.16494 696.822176 219.658091 703.080072 221.844282 C 703.607735 222.13944 704.056139 221.236185 703.907029 221.16188 C 693.88722 215.891165 701.270857 209.240536 715.894797 212.059861 C 717.482941 212.259227 713.800561 214.225954 710.468307 214.843996 C 705.945529 215.410455 698.25501 213.855486 707.223617 219.655865 C 709.182302 221.160146 713.555658 224.255394 713.555658 224.255394 C 713.555658 224.255394 711.973505 227.037405 711.818044 227.037821 C 711.66894 227.039061 699.614332 222.671626 696.750418 226.434102 C 694.940003 229.454314 703.81792 235.874748 703.219515 240.398427 C 702.159884 244.312792 701.638567 243.867369 701.408879 240.321258 C 701.483966 239.71978 692.68196 228.021042 692.68182 226.056849 Z M 692.68182 226.056849 "
      transform="matrix(0.0738614,0.627896,-0.568782,-0.103459,412.442039,-216.071724)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(69.803922%,50.196078%,18.039216%);fill-opacity:1;stroke-width:0.1723;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M 827.480557 222.488063 C 828.274815 222.488063 828.912124 222.794652 828.912124 223.166647 L 828.912124 246.001393 C 828.912124 246.377476 828.274815 246.679977 827.480557 246.679977 C 826.691056 246.679977 826.048991 246.377476 826.048991 246.001393 L 826.048991 223.166647 C 826.048991 222.794652 826.691056 222.488063 827.480557 222.488063 Z M 827.480557 222.488063 "
      transform="matrix(0.821325,0,0,0.955575,-271.0875,-12.897)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3819.300909 -4447.101261 L -3826.720067 -4449.859341 L -3811.595424 -4438.4758 L -3814.492423 -4443.822148 Z M -3819.300909 -4447.101261 "
      transform="matrix(2.323809,-1.18823,-2.503469,0.90616,-1852.643937,-303.709338)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3819.301534 -4447.101436 L -3824.606492 -4446.826028 L -3811.907798 -4439.237829 L -3814.491123 -4443.821217 Z M -3819.301534 -4447.101436 "
      transform="matrix(2.568547,-0.311617,-2.648543,-0.0169756,-1563.37432,-1062.255212)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3819.900065 -4447.600517 L -3815.257425 -4440.197541 L -3810.84539 -4438.945294 L -3814.4218 -4443.901347 Z M -3819.900065 -4447.600517 "
      transform="matrix(-1.928367,-1.805001,1.747025,2.085982,816.37282,2586.130114)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3821.803523 -4448.703486 L -3804.264208 -4426.294587 L -3810.936074 -4438.776455 L -3814.409868 -4443.869628 Z M -3821.803523 -4448.703486 "
      transform="matrix(-2.552241,-0.434161,2.543759,0.773167,1975.937427,1981.50451)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3822.200324 -4449.900003 L -3834.695944 -4456.405907 L -3810.650636 -4437.750609 L -3814.464498 -4443.884155 Z M -3822.200324 -4449.900003 "
      transform="matrix(2.343844,1.144164,-2.247762,-1.468043,-639.327536,-1956.970309)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3823.493586 -4450.193708 L -3828.732088 -4451.421997 L -3810.920169 -4438.099635 L -3814.473407 -4443.883628 Z M -3823.493586 -4450.193708 "
      transform="matrix(1.670393,2.068153,-1.45366,-2.320015,320.526965,-2218.331133)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3824.699118 -4451.699799 L -3838.096033 -4461.435838 L -3813.752996 -4440.483086 L -3814.493298 -4443.812834 Z M -3824.699118 -4451.699799 "
      transform="matrix(0.277307,2.693799,0.0368899,-2.77508,1629.998459,-1854.27894)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3822.197603 -4449.296899 L -3811.598233 -4434.888115 L -3809.886652 -4437.486185 L -3814.435939 -4443.836518 Z M -3822.197603 -4449.296899 "
      transform="matrix(-1.274585,2.357386,1.574969,-2.231338,2545.336443,-721.372809)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3820.196023 -4446.996007 L -3804.599058 -4427.749583 L -3807.949434 -4435.48943 L -3814.484765 -4443.814414 Z M -3820.196023 -4446.996007 "
      transform="matrix(-1.961125,1.76584,2.203012,-1.540604,2717.465027,91.657883)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3821.004029 -4448.703611 L -3808.791259 -4431.571898 L -3808.901962 -4436.82249 L -3814.46614 -4443.805809 Z M -3821.004029 -4448.703611 "
      transform="matrix(-2.494475,0.713382,2.620756,-0.401158,2539.998556,1140.353978)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3818.896853 -4446.896802 L -3816.929802 -4442.640208 L -3812.686071 -4440.506655 L -3814.488547 -4443.889149 Z M -3818.896853 -4446.896802 "
      transform="matrix(1.679972,-2.059667,-1.951912,1.875928,-1857.689337,681.76967)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3819.300194 -4447.100838 L -3819.299313 -4441.320707 L -3812.671583 -4439.981344 L -3814.490723 -4443.820632 Z M -3819.300194 -4447.100838 "
      transform="matrix(1.234076,-0.990088,-1.3295,0.755027,-791.749811,-220.466266)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3819.294523 -4447.095012 L -3819.296047 -4441.31624 L -3812.678769 -4439.989405 L -3814.481449 -4443.810843 Z M -3819.294523 -4447.095012 "
      transform="matrix(-1.244751,-1.113411,0.994017,1.242807,77.47029,1476.767477)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3819.303024 -4447.103435 L -3819.294398 -4441.314729 L -3812.667613 -4439.978543 L -3814.480003 -4443.810079 Z M -3819.303024 -4447.103435 "
      transform="matrix(1.384633,0.878755,-1.312535,-1.072226,-142.474064,-1211.270588)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3819.302517 -4447.101751 L -3803.6233 -4428.151991 L -3813.799848 -4441.859563 L -3814.493236 -4443.823979 Z M -3819.302517 -4447.101751 "
      transform="matrix(-1.523418,0.643398,1.633394,-0.475666,1855.725281,541.925669)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3819.295903 -4447.09624 L -3808.348165 -4433.918625 L -3812.669037 -4439.978477 L -3814.486349 -4443.816383 Z M -3819.295903 -4447.09624 "
      transform="matrix(-0.496051,2.659154,0.81911,-2.639301,2155.963159,-1384.265449)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3819.304079 -4447.104291 L -3833.722467 -4459.682611 L -3815.464085 -4442.894551 L -3814.496273 -4443.826368 Z M -3819.304079 -4447.104291 "
      transform="matrix(1.750535,1.994112,-1.543892,-2.255084,225.066529,-2213.815515)"
    />
    <path
      style="fill-rule:evenodd;fill:rgb(0%,45.882353%,0%);fill-opacity:1;stroke-width:0.33302;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;"
      d="M -3819.293558 -4447.094041 L -3819.300667 -4441.321306 L -3812.669547 -4439.978949 L -3814.487925 -4443.818026 Z M -3819.293558 -4447.094041 "
      transform="matrix(1.612993,-0.838036,-1.737738,0.6391,-1161.13161,-154.725104)"
    />
  </g>
</svg>
