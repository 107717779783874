<script>
  export let width = "480px";
  export let height = "360px";
  export let style = "";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  {width}
  {height}
  {style}
  viewBox="0 0 480 360"
>
  <g id="surface1">
    <rect
      x="0"
      y="0"
      width="480"
      height="360"
      style="fill:rgb(100%,0%,0%);fill-opacity:1;stroke:none;"
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(0%,60.392157%,0%);fill-opacity:1;"
      d="M 109.496094 62.589844 L 380.179688 62.589844 L 380.179688 298.398438 L 109.496094 298.398438 Z M 109.496094 62.589844 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,79.215686%,0%);fill-opacity:1;"
      d="M 109.496094 298.398438 L 380.179688 298.398438 L 244.835938 180.507812 L 109.488281 298.398438 Z M 109.496094 298.398438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,79.215686%,0%);fill-opacity:1;"
      d="M 109.496094 62.644531 L 244.84375 180.539062 L 380.179688 62.644531 Z M 109.496094 62.644531 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,80.784314%,0%);fill-opacity:1;"
      d="M 133.28125 46.023438 L 120.945312 37.230469 L 108.597656 46.003906 L 113.152344 31.558594 L 100.996094 22.523438 L 116.140625 22.390625 L 120.976562 8.035156 L 125.78125 22.398438 L 140.925781 22.558594 L 128.75 31.570312 Z M 133.28125 46.023438 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,80.784314%,0%);fill-opacity:1;"
      d="M 256.949219 46.390625 L 244.613281 37.597656 L 232.261719 46.371094 L 236.816406 31.921875 L 224.664062 22.890625 L 239.804688 22.757812 L 244.640625 8.402344 L 249.449219 22.765625 L 264.589844 22.925781 L 252.414062 31.9375 Z M 256.949219 46.390625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,80.784314%,0%);fill-opacity:1;"
      d="M 380.691406 46.191406 L 368.355469 37.398438 L 356.007812 46.171875 L 360.5625 31.726562 L 348.40625 22.691406 L 363.550781 22.558594 L 368.386719 8.203125 L 373.191406 22.566406 L 388.335938 22.726562 L 376.160156 31.738281 Z M 380.691406 46.191406 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,80.784314%,0%);fill-opacity:1;"
      d="M 133.28125 351.59375 L 120.945312 342.800781 L 108.597656 351.574219 L 113.152344 337.125 L 100.996094 328.09375 L 116.140625 327.960938 L 120.976562 313.605469 L 125.78125 327.96875 L 140.925781 328.128906 L 128.75 337.140625 Z M 133.28125 351.59375 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,80.784314%,0%);fill-opacity:1;"
      d="M 256.949219 351.960938 L 244.613281 343.167969 L 232.261719 351.941406 L 236.816406 337.492188 L 224.664062 328.460938 L 239.804688 328.328125 L 244.640625 313.972656 L 249.449219 328.335938 L 264.589844 328.496094 L 252.414062 337.507812 Z M 256.949219 351.960938 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,80.784314%,0%);fill-opacity:1;"
      d="M 380.691406 351.765625 L 368.355469 342.972656 L 356.007812 351.746094 L 360.5625 337.300781 L 348.40625 328.265625 L 363.550781 328.132812 L 368.386719 313.777344 L 373.191406 328.140625 L 388.335938 328.300781 L 376.160156 337.3125 Z M 380.691406 351.765625 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 277.292969 182.324219 C 277.292969 200.453125 262.597656 215.148438 244.46875 215.148438 C 226.339844 215.148438 211.644531 200.449219 211.644531 182.324219 C 211.644531 164.195312 226.339844 149.5 244.46875 149.5 C 262.597656 149.5 277.292969 164.195312 277.292969 182.324219 Z M 277.292969 182.324219 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,80.784314%,0%);fill-opacity:1;"
      d="M 259.316406 202.53125 L 244.664062 192.082031 L 229.996094 202.503906 L 235.40625 185.34375 L 220.96875 174.613281 L 238.957031 174.457031 L 244.699219 157.402344 L 250.40625 174.46875 L 268.394531 174.65625 L 253.933594 185.359375 Z M 259.316406 202.53125 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,0%,0%);fill-opacity:1;"
      d="M 127.480469 205.097656 C 127.480469 205.097656 129.144531 191.300781 128.191406 182.027344 C 127.242188 172.75 103.457031 170.132812 103.457031 170.132812 L 103.21875 191.777344 C 103.21875 191.777344 122.246094 207.238281 127.480469 205.097656 Z M 127.480469 205.097656 "
    />
    <path
      style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,80.784314%,0%);fill-opacity:1;"
      d="M 92.753906 150.394531 C 92.753906 150.394531 95.042969 162.550781 91.800781 186.785156 C 90.066406 199.945312 104.40625 208.90625 122.007812 212.949219 C 118.441406 206.765625 108.925781 196.773438 111.304688 178.460938 C 124.148438 180.125 136.277344 205.101562 136.277344 205.101562 C 136.277344 205.101562 145.890625 169.6875 126.527344 164.191406 C 107.710938 158.902344 92.753906 150.632812 92.753906 150.394531 Z M 92.753906 150.394531 "
    />
  </g>
</svg>
