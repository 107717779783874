<script>
  import styles from "styleguide/ConfirmationEmptyState.json";
  import { t } from "stores/i18n.js";
  import { Toast } from "components";
</script>

<div data-component="ConfirmationEmptyState">
  <Toast type="info" title={$t("confirmation_empty_state.title")}>
    <div class={styles.top}>
      <div class={styles.title}>
        {$t("confirmation_empty_state.invite_title")}
      </div>
      <div class={styles.description}>
        {@html $t("confirmation_empty_state.invite_description")}
      </div>
    </div>
  </Toast>
</div>

<style lang="scss">
  .top {
    counter-reset: steps;
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bottom-image {
    height: 60px;
    margin: 0 10px 0 -10px;
  }

  .bottom-left {
    display: flex;
    align-items: center;
  }

  .bottom-text {
    max-width: 90%;
  }

  .title {
    margin: 0 0 10px;
    font-weight: 600;

    &::before {
      counter-increment: steps;
      content: counter(steps) ". ";
    }
  }

  .description {
    margin: 0 0 15px;

    &:last-child {
      margin: 0;
    }
  }
</style>
