<script>
  import {
    DashboardFilters,
    DashboardProjectControl,
    DashboardProjectGroup,
  } from "apps/dashboard";
  import { Modal } from "components/modals";
  import {
    page,
    pagesNum,
    getProjects,
    sortProjects,
    projectsLoading,
    projectsToShow,
    collapsedGroups,
    setCollapsedGroups,
    couldSaveUsers,
  } from "stores/dashboard/project.js";
  import { t } from "stores/i18n.js";
  import { can } from "stores/permission.js";

  import Projects from "apis/dashboard/projects.js";

  import {
    Boundary,
    Button,
    Options,
    Pagination,
    Search,
    Toast,
  } from "components";
  import styles from "styleguide/DashboardProjectList.json";

  let newProject = false;
  let dashboardProjectControl;
  $: sort = "last-updated-desc";
  $: isValid = $couldSaveUsers;
  $: sortTypes = {
    name: $t("sort.sort_name"),
    "last-updated-desc": $t("sort.sort_updated"),
    "last-created-desc": $t("sort.sort_created"),
  };

  $: displayedSort = sortTypes[sort];

  function setSort(newSort) {
    sort = newSort;
    sortProjects(sort);
  }

  function modifySearchResults(response) {
    return response.data.projects.map((item) => {
      return {
        value: item.path,
        label: item.text,
      };
    });
  }
  $: isAllCollapsed = Object.keys($collapsedGroups).every(
    (key) => $collapsedGroups[key] === 0,
  );

  function onCollapseAllItems() {
    const newCollapsedGroups = {};
    const oldCollapsedGroups = { ...$collapsedGroups };

    let isCollapsed = $projectsToShow.every(
      (project) => oldCollapsedGroups[project.permalink] === 0,
    )
      ? 1
      : 0;

    $projectsToShow.forEach((project) => {
      newCollapsedGroups[project.permalink] = isCollapsed;
    });

    setCollapsedGroups(newCollapsedGroups);
  }

  function goToProject(path) {
    window.location = path;
  }
</script>

<Boundary>
  <div data-component="DashboardProjectList" class={styles.wrapper}>
    <div class={styles.header}>
      <Search
        searchApi={Projects}
        {modifySearchResults}
        select={goToProject}
        style="big right-side no-right-border"
        placeholder={$t("dashboard_content.search_project")}
      />

      {#if $can("create", "project")}
        <div class={styles["header-button"]}>
          <Button
            click={() => {
              newProject = true;
            }}
            style="primary-tonal small"
            >{$t("dashboard_content.create_project")}</Button
          >
        </div>
      {/if}

      <div class={styles["header-button"]}>
        <Button click={onCollapseAllItems} style="primary-text small"
          >{isAllCollapsed
            ? $t("dashboard_content.collapse")
            : $t("dashboard_content.expand")}</Button
        >
      </div>
    </div>

    <div class={styles.filtering}>
      <DashboardFilters />
      <div class={styles.sorting}>
        <Pagination
          currentPage={$page}
          pagesNumber={$pagesNum}
          click={getProjects}
        />
        <div class={styles.sort}>
          {$t("sort.sort_by")}
          <Options
            style={"medium no-border acts-as-button left"}
            title={displayedSort}
          >
            <Button style="blue-text option" click={() => setSort("name")}
              >{$t("sort.sort_name")}</Button
            >
            <Button
              style="blue-text option"
              click={() => setSort("last-updated-desc")}
              >{$t("sort.sort_updated")}</Button
            >
            <Button
              style="blue-text option"
              click={() => setSort("last-created-desc")}
              >{$t("sort.sort_created")}</Button
            >
          </Options>
        </div>
      </div>
    </div>

    <div class={styles.list}>
      {#each $projectsToShow as project (project.permalink)}
        <div class={styles.item}>
          <DashboardProjectGroup
            bind:loading={$projectsLoading}
            {...project}
            users={project.team_users}
          />
        </div>
      {:else}
        <div class={styles.item}>
          <Toast type="info" message={$t("dashboard_content.projects_empty")} />
        </div>
      {/each}
    </div>

    <div class={styles.pagination}>
      <Pagination
        currentPage={$page}
        pagesNumber={$pagesNum}
        click={getProjects}
      />
    </div>

    <Modal
      shown={newProject !== false}
      title={$t("dashboard_content.create_project_title")}
      onHide={() => {
        newProject = false;
      }}
    >
      <DashboardProjectControl
        type="create"
        bind:this={dashboardProjectControl}
      />
      <svelte:fragment slot="bottom">
        {#if !!dashboardProjectControl}
          <Button style={"primary"} click={dashboardProjectControl.onSubmit}>
            {$t("dashboard_new_project.save_changes")}
          </Button>
        {/if}
      </svelte:fragment>
    </Modal>
  </div>
</Boundary>

<style lang="scss">
  .header {
    display: flex;
  }

  .header-button {
    display: flex;
    align-items: center;
    border: 1px solid var(--primary-050);
    border-right: None;
    border-left: none;
    background: #fff;
    padding: 0 4px;

    &:last-child {
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
      border-right: 1px solid var(--primary-050);
      padding-right: 12px;
    }
  }

  .filtering {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
  }

  .sorting {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .sort {
    margin-left: 25px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .item {
    margin-top: 25px;

    &:first-child {
      margin-top: 15px;
    }
  }

  .pagination {
    margin: 25px 0;
  }
</style>
