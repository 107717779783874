<script>
  import { t } from "stores/i18n.js";
  import { Toast } from "components";
</script>

<div data-component="SamplePreviewsBlank">
  <Toast
    type="info"
    title={$t("sample_previews_blank.title")}
    description={$t("sample_previews_blank.description")}
  />
</div>
