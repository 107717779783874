<script>
  import Boundary from "./customBoundary";
  import reportError from "apis/appsignal/appsignal.js";

  let handleError = async (error, context) => {
    reportError(error, context);
    throw error;
  };
</script>

<Boundary onError={handleError}>
  <slot />
</Boundary>
