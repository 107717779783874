<script>
  import { onMount } from "svelte";

  import { RespondSuccess } from "apps/respond";
  import {
    ResponderChecking,
    ResponderGeneralMethod,
    ResponderQrcodeMissing,
    ResponderSpecificMethod,
  } from "apps/responder";
  import {
    method,
    sender,
    recipient,
    auditor,
    samples,
    record,
    setReviewType,
    footerLinks,
  } from "stores/responder.js";

  import SampleResponseSubmissions from "apis/responder/general_method.js";

  import { App, FrontendWrapper } from "components";
  import styles from "styleguide/Responder.json";

  export let appConfig;
  export let initialState;

  let mailing = initialState.mailing;
  let deadline = initialState.deadline;
  let previous_response = initialState.previous_response;

  $: status = "init";

  let polling;

  onMount(async () => {
    method.set(initialState.method);
    sender.set(initialState.sender);
    recipient.set(initialState.recipient);
    auditor.set(initialState.auditor);
    samples.set(initialState.samples);
    setReviewType(initialState.review_type, initialState.category);
    footerLinks.set(initialState.footer_links);
    status = initialState.status || "init";
  });

  function setStatus(newStatus) {
    status = newStatus;
  }

  function onGeneralStatusCreated(response) {
    record.set(response.record);

    polling = setInterval(() => {
      SampleResponseSubmissions.check({
        params: `id=${response.record}`,
        success: onCheckStatusSuccess,
        error: onCheckStatusError,
      });
    }, 1000);
  }

  function onCheckStatusSuccess(response) {
    switch (response.status) {
      case "pending":
        break;
      case "completed":
        clearInterval(polling);
        status = "success";
        break;
      case "noqrcode":
        clearInterval(polling);
        status = "noqrcode";
        break;
      case "errored":
        clearInterval(polling);
        // status = 'errored'
        alert("errored");
        break;
      default:
        clearInterval(polling);
        alert("unknown error");
    }
  }

  function onCheckStatusError(response) {
    clearInterval(polling);
    alert("errored");
  }
</script>

<App {...appConfig} container={true}>
  <FrontendWrapper {footerLinks}>
    {#if status === "init" && $method === "specific"}
      <ResponderSpecificMethod
        {deadline}
        {mailing}
        {previous_response}
        on:success={setStatus.bind(this, "success")}
      />
    {:else if status === "init" && $method === "general"}
      <ResponderGeneralMethod
        onChecking={setStatus.bind(this, "checking")}
        onCreated={onGeneralStatusCreated}
      />
    {:else if status === "checking"}
      <ResponderChecking />
    {:else if status === "success" || $method === "success"}
      <div class={styles.container}>
        <RespondSuccess />
      </div>
    {:else if status === "noqrcode"}
      <ResponderQrcodeMissing
        on:restart={setStatus.bind(this, "init")}
        on:success={setStatus.bind(this, "success")}
      />
    {/if}
  </FrontendWrapper>
</App>

<style lang="scss">
  .container {
    padding: 35px;
    border-bottom: solid 1px var(--primary-050);
  }
</style>
